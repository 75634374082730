import React, { useEffect } from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import useSearchFilters, { setSearchFilters } from "../../hooks/useSearchFilters";
import { formatDate } from "../../services/util";
import useStyles from "./styles";


/**
 * Filter Box
 * @param {String} name 
 * @param {String} label 
 * @param {String} type list | date | time
 * @param {String} param 
 * @param {Boolean} isHide 
 * @param {Function} setFilter 
 * @param {Object} model 
 * @returns 
 */
const FilterBox = ({
  name, label="", type="list", param, isHide=false, setFilter=()=>{}, model, 
}) => {

  const classes = useStyles();
  const values = useSearchFilters(name);
  // console.log("[FilterBox]", name, values, model);


  /**
   * handle to remove filter
   * @param {Number|Array} index 
   */
  const handleRemove = (index) => {
    // console.log("[FilterBox] handleRemove", values, index);
    let temp = [...values];
    if (typeof index === 'object' && index.length > 0) {
      if (type === 'list') {
        temp = temp.reduce((s, t) => (index.indexOf(t) >= 0 ? s.concat([t]) : s), []);
      }
      else {
        for (let i of index) {
          temp[i] = undefined;
        }
        if (temp.every(t => t === undefined)) temp = [];
      }
    }
    else if (index >= 0 && index < temp.length) {
      if (type === 'list') {
        temp.splice(index, 1);
      }
      else {
        temp[index] = undefined;
        if (temp.every(t => t === undefined)) temp = [];
      }
    }
    else return;
    setSearchFilters(name, temp);
  };


  useEffect(() => {
    if (typeof setFilter === 'function') setFilter((param ? param : name), values, type);
  }, [values, param, name, type, setFilter]);


  return (
    <>
      {
        (!isHide && values && values.length > 0) ?
          <Grid container className={classes.column} spacing={1}>
            <Grid item>
              <Typography variant="subtitle1" className={classes.label}>
                {label}
              </Typography>
            </Grid>
            <Grid item xs>
              {
                type === 'list'
                ?
                values.map((v, i) => (
                  <Button 
                    variant="contained" 
                    key={i} 
                    className={classes.btn} 
                    endIcon={<CloseIcon />}
                    onClick={() => handleRemove(i)}
                  >
                    {
                      model && typeof model === 'object' && v in model
                      ?
                      model[v]
                      :
                      v
                    }
                  </Button>
                ))
                :
                (
                  values[0] && values[1]
                  ?
                  (<>
                  {
                    <Button 
                      variant="contained" 
                      className={classes.btn} 
                      endIcon={<CloseIcon />}
                      onClick={() => handleRemove([0, 1])}
                    >
                      From {formatDate(values[0])} To {formatDate(values[1])}
                    </Button>
                  }
                  </>)
                  :
                  (<>
                  {
                    values[0] && 
                    <Button 
                      variant="contained" 
                      className={classes.btn} 
                      endIcon={<CloseIcon />}
                      onClick={() => handleRemove(0)}
                    >
                      From {formatDate(values[0])}
                    </Button>
                  }
                  {
                    values[1] && 
                    <Button 
                      variant="contained" 
                      className={classes.btn} 
                      endIcon={<CloseIcon />}
                      onClick={() => handleRemove(1)}
                    >
                      To {formatDate(values[1])}
                    </Button>
                  }
                  </>)
                )
              }
            </Grid>
          </Grid>
        :
        null
      }
    </>
  );
};

export default FilterBox;