import React, { useState, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, AppBar, Tabs, Tab } from "@material-ui/core";
import ContactDetailContents from "../ContactDetailModal/contents";
import AddressDetailContents from "../AddressDetailModal/contents";
import ACHDetailContents from "../ACHDetailModal/contents";
import CustomContents from "../CustomModal/contents";
import { ContactModel } from "../../../models/Contact";
import { AddressModel } from "../../../models/Address";
import { ACHModel } from "../../../models/ACH";
import { getValue } from "../../Contracts/functions";
import TabPanel, { a11yProps } from "./TabPanel";
import { useStyles } from "../styles";



export default function MultiTabsModals({ 
  modaltitle, 
  buttontitle, 
  okButtontitle="Ok", 
  cancelButtontitle="Cancel", 
  modals, 
  setDetailValue, 
  setDefaultValue=null, 
  defaultDetails={},
  isButtonVisible=true,
  isDefaultVisible=false,
  cancelHandler=null }) {

  const classes = useStyles();

  const [modalOpenState, setModalOpenState] = useState(false);
  const [value, setValue] = useState(0);
  const [details, setConductForm] = useState({});
  const [isLoaded, setLoaded] = useState(0);

  useEffect(() => {
    if (isDefaultVisible) setModalOpenState(true);
  }, [isDefaultVisible]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleConductModalOpen = () => {
    setModalOpenState(true);
    setValue(0);
  };

  const handleConductModalClose = () => {
    setModalOpenState(false);
  };

  const handleConductModalAccept = () => {
    // console.log("[MultiTabsModals]", details);
    setModalOpenState(false);
    setDetailValue(details);
  };

  useEffect(() => {
    // console.log("[MultiTabsModals]", defaultDetails);
    setLoaded(0);
  }, [defaultDetails]);

  useEffect(() => {
    if (isLoaded < modals.length) {
      const modal = modals[isLoaded];
      const key = modal[3];
      if (modal[0] === "contactdialog") {
        let valueForContact = getValue(defaultDetails, modal[2], { defaultValue: ContactModel });
        // check modal[4] exist or not
        if (modal.length > 4 && modal[4] === "list") {
          if (!(typeof valueForContact === "object" && valueForContact.length > 0)) valueForContact = [valueForContact];
          setConductForm({ ...details, [key]: valueForContact });
        }
        else
          setConductForm({ ...details, [key]: valueForContact });
      }
      else if (modal[0] === "addressdialog") {
        let valueForAddress = getValue(defaultDetails, modal[2], { defaultValue: AddressModel });
        // check modal[4] exist or not
        if (modal.length > 4 && modal[4] === "list") {
          if (!(typeof valueForAddress === "object" && valueForAddress.length > 0)) valueForAddress = [valueForAddress];
          setConductForm({ ...details, [key]: valueForAddress });
        }
        else
          setConductForm({ ...details, [key]: valueForAddress });
      }
      else if (modal[0] === "achinfodialog") 
        setConductForm({ ...details, [key]: getValue(defaultDetails, modal[2], { defaultValue: ACHModel }) });
      else if (modal[0] === "customdialog")
        setConductForm({ ...details, [key]: getValue(defaultDetails, modal[2], { defaultValue: "" }) });
      setLoaded(isLoaded+1);
    }
  }, [modals, defaultDetails, details, isLoaded]);


  return (
    <>

      <Dialog
        open={modalOpenState}
        onClose={handleConductModalClose}
        classes={{ paper: classes.dialogPaperLg }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" >{modaltitle}</DialogTitle>
        <DialogContent >

          <div className={classes.multiTabs}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable force tabs example"
              >

                {modals.map((modal, index) => {
                  const modalTitle = modal[1];
                  return (
                    <Tab key={index} label={modalTitle} {...a11yProps(index)} />
                  );
                })}
                
              </Tabs>
            </AppBar>

            {modals.map((modal, index) => {
              // console.log("[MultiTabsModals] modal", modal);
              const modalTitle = modal[1];
              const key = modal[3];
              const setEachDetailValue = (data) => {
                if (modal[0] === "customdialog") setConductForm({ ...details, ...data });
                else setConductForm({ ...details, [key]: data });
              };

              // check modal[4] exist or not
              const isMultiple = (modal.length > 4 && modal[4] === "list");
              const isNumber = (modal.length > 4 && modal[4] === "number");
              // check modal[5] exist or not
              const isCustomerService = (modal.length > 5 && modal[5] === "customerservice");
              const isShippingaddress = (modal.length > 5 && modal[5] === "shippingaddress");
              // check modal[6] exist or not
              let mask = null;
              if (modal.length > 6 && modal[5] === "mask" && typeof modal[6] === "function") {
                mask = modal[6];
              }
              
              return (
                <TabPanel key={index} value={value} index={index} className={classes.tabPanel}>
                  {
                    modal[0] === "contactdialog" ?
                    (
                      isMultiple
                      ?
                        <ContactDetailContents 
                          isMultiple={isMultiple}
                          contactList={details[key]}
                          setContactList={setEachDetailValue}
                          isCustomerService={isCustomerService}
                        />
                      :
                        <ContactDetailContents 
                          contactdetail={details[key]}
                          setConductForm={setEachDetailValue}
                        />
                    )
                    :
                    (
                      modal[0] === "addressdialog" ?
                      (
                        isMultiple
                        ?
                          <AddressDetailContents 
                            isMultiple={isMultiple}
                            addressList={details[key]}
                            setAddressList={setEachDetailValue}
                            isShippingaddress={isShippingaddress}
                          />
                        :
                          <AddressDetailContents 
                            addressdetail={details[key]}
                            setConductForm={setEachDetailValue}
                            isShippingaddress={isShippingaddress}
                          />
                      )
                      :
                      (
                        modal[0] === "achinfodialog" ?
                        (
                          <ACHDetailContents 
                            achdetail={details[key]}
                            setConductForm={setEachDetailValue}
                          />
                        )
                        :
                        (
                          modal[0] === "customdialog" ?
                          (
                            <CustomContents 
                              textFields={[{label: modalTitle, key, mask, isNumber}]}
                              customData={{ [key]: details[key] }}
                              setConductForm={setEachDetailValue}
                            />
                          )
                          :
                          <></>
                        )
                      )
                    )
                  }
                </TabPanel>
              );
            })}
          
          </div>

        </DialogContent>
        <DialogActions className={classes.dialogTabsAction}>
          <Button onClick={handleConductModalAccept} color="primary" autoFocus>
            {okButtontitle}
          </Button>
          <Button onClick={() => {
              handleConductModalClose();
              if (typeof cancelHandler === "function" && cancelHandler) cancelHandler();
            }} color="primary">
            {cancelButtontitle}
          </Button>
        </DialogActions>
      </Dialog>

    {isButtonVisible && (
      <Button
        color="primary"
        size="small"
        className={classes.button}
        variant="contained"
        onClick={() => {
          if (typeof setDefaultValue === "function" && setDefaultValue) setDefaultValue(defaultDetails);
          handleConductModalOpen();
        }}
      >
        {buttontitle}
      </Button>
    )}

    </>
  );
}