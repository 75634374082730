import React, { useState } from "react";
import { TableRow, TableCell, Checkbox } from "@material-ui/core";
import useStyles from "../styles";
import { CustomTextField } from "../../../components/Inputs";

export default function PickSetupElement({ rowElement, dispatch }) {
  /*   const [open, setopen] = useState(rowElement?.open);
  const [hold, setHold] = useState(rowElement?.closed); */
  const [isChecked, setIsChecked] = useState(rowElement?.isChecked);
  const classes = useStyles();

  return (
    <>
      <TableRow>
        <TableCell className={classes.cellHeaderBold}>
          {rowElement?.pseudoItem.name}
        </TableCell>
        <TableCell>{rowElement?.pseudoItem.referenceNumber}</TableCell>
        <TableCell className={classes.cellHeaderBold}>
          {rowElement?.note}
        </TableCell>
        <TableCell>{rowElement?.pseudoItem.cost}</TableCell>
        <TableCell>
          <CustomTextField
            type="number"
            value={rowElement.open ?? ""}
            onChange={(val) => {
              if (val >= 0) {
                //setopen(val);
                dispatch({
                  type: "change_quantity",
                  payload: {
                    open: val,
                    productId: rowElement.pseudoItem.id,
                    categoryId: rowElement.pseudoItem.categoryId,
                    hold: rowElement.closed,
                  },
                });
              }
            }}
            width={51}
          />
        </TableCell>

        <TableCell>
          <CustomTextField
            type="number"
            value={rowElement.closed ?? ""}
            onChange={(val) => {
              if (val >= 0) {
                rowElement.closed = val;
                //setHold(val);
                dispatch({
                  type: "change_quantity",
                  payload: {
                    hold: val,
                    categoryId: rowElement.pseudoItem.categoryId,
                    productId: rowElement.pseudoItem.id,
                    open: rowElement.open,
                  },
                });
              }
            }}
            width={51}
          />
        </TableCell>

        <TableCell>
          {!isChecked && (
            <Checkbox
              checked={!isChecked}
              onChange={(val) => {
                rowElement.checked = true;
                setIsChecked(true);
                dispatch({
                  type: "change_product",
                  payload: {
                    productId: rowElement.pseudoItem.id,
                    categoryId: rowElement.pseudoItem.categoryId,
                    isChecked: true,
                  },
                });
              }}
              className={classes.newCheckboxPrimary}
            />
          )}
          {isChecked && (
            <Checkbox
              onChange={(val) => {
                rowElement.checked = false;
                setIsChecked(false);
                dispatch({
                  type: "change_product",
                  payload: {
                    productId: rowElement.pseudoItem.id,
                    categoryId: rowElement.pseudoItem.categoryId,
                    isChecked: false,
                  },
                });
              }}
              checked={isChecked}
              className={classes.checkboxSelected}
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
}
