import React from "react";
import classNames from "classnames";
import Contracts from "../../components/Contracts/Contracts1";
import { UOM } from "../../models/Product";
import { Ownerships } from "../../models/Ownership";
import { contractFilter1 } from "../../services/daml-filters";
import { handleCheckedContract, handleClickedContract } from '../surgicalevent/surgicaleventlistcontracts1';
import { getInventorySortKey } from "./inventorylistcontractshospital";
import useStyles from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";
import { useTranslation } from 'react-i18next';
import withFilters from "../../hoc/withFilters";


/**
 * Inventoy List Historic Contracts
 * Edit Tab in Vendor
 * Old version used InventoryListContractsVendorAdjust instead
 * @param {Object} searchParams 
 * @param {Array} allContracts 
 * @param {Function} setArchived
 * @param {Function} setChecked
 * @param {Function} handleEditInventory
 * @returns 
 */
function InventoryListHistoricContractsVendor({
  searchParams = {},
  allContracts = [],
  setArchived = null,
  setChecked = null,
  handleEditInventory = null,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  allContracts = allContracts
    .map(c => {
      c.payload.iproduct_sepproductmanufacturer = "";
      // c.payload.iproduct_sepuom = "";
      c.payload.count = c.payload.inventorydata.length;
      // let tempinvdata = [];
      c.payload.inventorydata.forEach(({_1: invData}) => {
        if (invData.iproduct.sepproductmanufacturer) c.payload.iproduct_sepproductmanufacturer = invData.iproduct.sepproductmanufacturer;
        // if (invData.iproduct.sepuom) c.payload.iproduct_sepuom = invData.iproduct.sepuom;
        // tempinvdata.push(invData);
      });
      // c.payload.inventorydata = tempinvdata;

      c.newsortkey = getInventorySortKey(c);

      return c;
    });

  let contracts = contractFilter1(allContracts, searchParams);
  // console.log("[InventoryListHistoricContractsVendor]", contracts);


  return (
    <>
      <Contracts
        className={classes.customRoot}
        contracts={contracts}
        columns={[
          ["Reference Number", "payload.inventoryproduct.ireferencenumber", {
            type: "link",
            search: {
              type: "AdaptiveSearch",
              name: "inventoryproduct.ireferencenumber",
              getValue: (c) => c.payload.inventoryproduct.ireferencenumber,
              contracts: allContracts,
            },
            options: {
              flex: 1,
              headerAlign: 'center',
              align: 'center',
              cellClassName: classNames(classes.staticCellelement, classes.primaryColor),
              headerClassName: classes.staticCellelement,
            },
          }],
          ["Quantity", "payload.count", {
            search: {
              type: "AdaptiveSearch",
              name: "count",
              getValue: (c) => c.payload.count,
              contracts: allContracts,
            },
            options: {
              flex: 0.9,
              cellClassName: classNames(classes.primaryColor, classes.centerCell),
            },
          }],
          ["Product Description", "payload.inventoryproduct.iproductdescription", {
            search: {
              type: "AdaptiveSearch",
              name: "inventoryproduct.iproductdescription",
              getValue: (c) => c.payload.inventoryproduct.iproductdescription,
              contracts: allContracts,
            },
            options: {
              flex: 2,
              tooltip: true,
            },
          }],
          ["Distributor", "payload.vendor", {
            search: {
              type: "AdaptiveSearch",
              name: "vendor",
              getValue: (c) => c.payload.vendor,
              contracts: allContracts,
            },
            options: {
              flex: 1,
            },
          }],
          ["Manufacturer", "payload.iproduct_sepproductmanufacturer", {
            search: {
              type: "AdaptiveSearch",
              name: "iproduct_sepproductmanufacturer",
              getValue: (c) => c.payload.iproduct_sepproductmanufacturer,
              contracts: allContracts,
            },
            options: {
              flex: 1,
            },
          }],
          ["Ownership", "payload.inventoryproduct.iownership", {
            search: {
              type: "SelectSearch",
              name: "inventoryproduct.iownership",
              options: Object.keys(Ownerships).map(value => ({ label: Ownerships[value], value: value })),
            },
            options: {
              flex: 0.9,
            },
          }],
          ["Price", "payload.inventoryproduct.iprice", {
            type: "render",
            render: (param) => (param.value ? `$${param.value}` : 'No Contract Price'),
            search: {
              type: "AdaptiveSearch",
              name: "inventoryproduct.iprice",
              getValue: (c) => c.payload.inventoryproduct.iprice,
              contracts: allContracts,
            },
            options: {
              flex: 0.8,
            },
          }],
          [`${t('main.uom.lable')}`, "payload.inventoryproduct.iuom", {
            search: {
              type: "SelectSearch",
              name: "inventoryproduct.iuom",
              options: Object.keys(UOM).map(value => ({ label: UOM[value], value: value })),
            },
            options: {
              flex: 0.7,
            },
          }],
        ]}
        options={{
          statusEmpty: (allContracts.length === 0),
          rowsPerPage: 100,
          sortKey: 'newsortkey',
          checkbox: true,
        }}

        handleArchived={setArchived}
        handleClicked={(e) => handleClickedContract(e, handleEditInventory)}
        handleChecked={(e) => handleCheckedContract(e, contracts, setChecked)}
      />

    </>
  );
}

const InventoryListHistoricContractsVendorWithLoading = withLoading(InventoryListHistoricContractsVendor);
export default withFilters(InventoryListHistoricContractsVendorWithLoading)([
  { name: "inventoryproduct.ireferencenumber", label: "Reference Number" },
  { name: "count", label: "Quantity" },
  { name: "inventoryproduct.iproductdescription", label: "Product Description" },
  { name: "vendor", label: "Distributor" },
  { name: "iproduct_sepproductmanufacturer", label: "Manufacturer" },
  { name: "inventoryproduct.iownership", label: "Ownership" },
  { name: "inventoryproduct.iprice", label: "Price" },
  { name: "inventoryproduct.iuom", label: "UoM" },
]);
