import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  panel: {
    padding: '0px 2px',
    display: 'flex',
    // minHeight: '50px',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingBottom: 0,
    }
  },
  column: {
    // minWidth: '310px',
    maxWidth: '335px',
    marginTop: '10px',
    marginRight: '10px',
    marginBottom: '10px',
  },
  label: {
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '24px',
    color: '#5C5C5C',
  },
  btn: {
    padding: '1px',
    background: 'rgba(204, 204, 204, 0.4)',
    // border: '1px solid #CCCCCC',
    boxSizing: 'border-box',
    borderRadius: '4px',
    marginRight: '5px',
    textTransform: 'inherit',
    '& .MuiButton-label': {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      color: '#000000',
      justifyContent: 'space-between',
    },
    '& .MuiButton-endIcon': {
      margin: 0,
      '& .MuiSvgIcon-root': {
        fontSize: '12px',
      },
    },
  },
}));
