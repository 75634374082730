import { makeStyles } from "@material-ui/styles";

const drawerWidth = 240;

export default makeStyles(theme => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  sidebarList: {
      '& .makeStyles-linkActive-50 span':{
        color:'#183C73',
        fontWeight:500
      },
      '& svg, & object':{
        maxWidth: 20,
      },
      '& .makeStyles-linkActive-50 svg path':{
        fill:'#183C73 !important',
      },
      '& .menuitem':{
        display:'none'
      }
  },
  imageObject:{
    maxWidth: 20,
  },
  linkhead:{
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: 40,
    textTransform: 'uppercase',
    marginTop:'21px;',
    color: 'rgba(0, 0, 0, 0.38)',
    '& .MuiTypography-displayBlock':{
      fontWeight: 'bold',
      fontSize: 14,
  
    }
  },
  link:{
    fontWeight: 'normal',
    paddingTop:0,
    paddingBottom:0,
    fontSize: 14,
   
    '& .menuitem:hover':{
      background:'rgba(33, 150, 243, 0.38);',
      borderRadius:'4px;'
    },
    '& .menuitem':{
        width: '100%',
        display: 'flex',
        position: 'relative',
        boxSizing:'border-box',
        textAlign: 'left',
        alignItems: 'center',
        paddingTop: 8,
        paddingBottom: 8,
        justifyVontent: 'flex-start',
        textDecoration: 'none',
        paddingLeft:8,
        paddingRight:8,
      },
      '& .active-item span':{
        color:'#183C73',
        fontWeight:600,
      },
      '& .menuitem span':{
        fontSize:'14px;'
      },
      '& .active-item svg, .active-item svg path':{
        fill:'#183C73'
      },
      '& svg, & object':{
        maxWidth: 20,
      },
      '& .active-item svg path':{
        fill:'#183C73 !important',
      },
      '& .MuiListItemIcon-root':{
        minWidth:'36px;'
      }
    
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 40,
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
    }
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  mobileBackButton: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(3),
    [theme.breakpoints.only("sm")]: {
      marginTop: theme.spacing(0.625),
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));
