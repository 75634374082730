import React, { useCallback } from "react";
import classNames from "classnames";
import { useLedger } from "@daml/react";
import { Link } from "@material-ui/core";
import Contracts from "../../components/Contracts/Contracts1";
import { contractFilter1 } from "../../services/daml-filters";
import { getTemplate } from "../../services/util";
import { useDamlState } from "../../context/DamlContext";
import { POOwnerships } from "../../models/Ownership";
import { InventoryPOStatusHospital } from '../../models/Po';
import { handleCheckedContract, handleClickedContract } from '../surgicalevent/surgicaleventlistcontracts1';
import { ProductStatusComp } from '../purchaseorder/purchaseorderlistcontracts1';
import { isEnableArchiveRestockInventory } from "./inventoryscanhospitalcontractspo";
import { archiveRestockInventory } from "./handle";
import withLoading from "../../hoc/withLoading";
import withFilters from "../../hoc/withFilters";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "../purchaseorder/styles";


/**
 * Incoming(Restock) Inventory Contracts for Hospital
 * @param {Object} searchParams 
 * @param {Boolean} isIncludeHistory 
 * @param {Array} allContracts 
 * @param {Array} historyContracts 
 * @param {Function} setArchived
 * @param {Function} setChecked
 * @param {Function} handleEditInventory
 * @returns 
 */
function ScanInventoryHospitalContracts({
  searchParams = {},
  isIncludeHistory = false,
  allContracts = [],
  historyContracts = [],
  setArchived = null,
  setChecked = null,
  handleEditInventory = null,
}) {

  const classes = useStyles();
  const classes1 = useStyles1();

  const ledger = useLedger();
  const { roleCid } = useDamlState();


  allContracts = allContracts.concat(isIncludeHistory && historyContracts.length ?
    historyContracts.map(h => ({ ...h, payload: h.payload.inventory })) : [])
    .map(c => {
      // c.payload.inventorydata.forEach(inv => {
      //   if (inv.iproductstatus === 'Received' && inv.ireceivedstockforfacility) {
      //     inv.iproductstatus = 'StockedForFacility';
      //   }
      // });
      // c.payload.pototalamount = getTotalPrice(c.payload.inventorydata);
      c.payload.inventorydata = c.payload.inventorydata.map((inv, index) => ({
        ...inv,
        index,
        contractId: c.contractId,
        vendor: c.payload.vendor,
        hospital: c.payload.hospital,
      }));
      return c;
    });
  let contracts = contractFilter1(allContracts, searchParams);
  // console.log("[ScanInventoryHospitalContracts]", searchParams, allContracts);


  // Click "Scan" in list
  const handleScan = (contract) => {
    if (typeof handleEditInventory === 'function' && 'contractId' in contract) {
      handleEditInventory(contract);
    }
  };

  // Click "Delete" in list
  const handleDelete = useCallback(async (contract) => {
    
    await archiveRestockInventory({
      ledger,
      roleCid,
      inventorycid: contract.contractId,
    });

  }, [ledger, roleCid]);


  return (
    <>
      <Contracts
        className={classes.customRoot}
        contracts={contracts}
        columns={[
          ["PO Number", "payload.ipurchaseorderdata.popurchaseorderid", {
            type: 'link',
            search: {
              type: "AdaptiveSearch",
              name: "ipurchaseorderdata.popurchaseorderid",
              getValue: (c) => c.payload.ipurchaseorderdata.popurchaseorderid,
              contracts: allContracts,
            },
            options: {
              flex: 0.8,
              cellClassName: classes.nobreak
            }
          }],
          ["SEID", "payload.ipurchaseorderdata.poseid", {
            type: 'render',
            render: (param) => (
              param.value
              ?
              <Link component="button" variant="body2">{param.value}</Link>
              :
              "N/A"
            ),
            search: {
              type: "AdaptiveSearch",
              name: "ipurchaseorderdata.poseid",
              getValue: (c) => c.payload.ipurchaseorderdata.poseid,
              contracts: allContracts,
            },
            options: {
              cellClassName: classNames(classes.primaryColor),
              flex: 1.1,
            },
          }],
          ["PO Date", "payload.ipurchaseorderdata.podate", {
            search: {
              type: "DateSearch",
              name: "ipurchaseorderdata.podate",
            },
            options: {
              flex: 1,
            }
          }],
          ["Distributor", "payload.ipurchaseorderdata.povendorid", {
            search: {
              type: "AdaptiveSearch",
              name: "ipurchaseorderdata.povendorid",
              getValue: (c) => c.payload.ipurchaseorderdata.povendorid,
              contracts: allContracts,
            },
            options: {
              flex: 1,
            }
          }],
          ["Manufacturer", "payload.inventorydata.0.iproduct.sepproductmanufacturer", {
            options: {
              flex: 1,
            }
          }],
          ["Ownership", "payload.ipurchaseorderdata.popurchaseordertype", {
            search: {
              type: "SelectSearch",
              name: "ipurchaseorderdata.popurchaseordertype",
              options: Object.keys(POOwnerships).map(value => ({ label: POOwnerships[value], value: value })),
            },
            options: {
              flex: 1,
            }
          }],
          ["Product Status", "payload.ipurchaseorderdata.poinventorystatus", {
            type: "render",
            render: (param) => (<ProductStatusComp value={param.value} classes={classes1} />),
            search: {
              type: "SelectSearch",
              name: "ipurchaseorderdata.poinventorystatus",
              options: Object.keys(InventoryPOStatusHospital).map(value => ({ label: InventoryPOStatusHospital[value], value: value })),
              maxColCnt: 5,
              classes: {
                popoverBox: classes1.newPopoverBox,
                popoverInner: classes1.newInnerPopper,
              },
            },
            options: {
              flex: 1,
            }
          }],
          ["Total", "payload.ipurchaseorderdata.pototalamount", {
            options: {
              beforeText: '$',
              flex: 0.8,
            }
          }],
        ]}

        actions={[
          [
            "Actions",
            [
              [["Scan", checkNotRestockInventoryHistory],
                handleScan],
              [["Delete", isEnableArchiveRestockInventory],
                handleDelete],
            ],
            {
              flex: 1,
              headerAlign: 'center',
              checkVisible: checkNotRestockInventoryHistory,
            }
          ],
        ]}
        options={{
          statusEmpty: (allContracts.length === 0),
          checkbox: true,
        }}
        
        handleArchived={setArchived}
        handleClicked={(e) => handleClickedContract(e, handleScan)}
        handleChecked={(e) => handleCheckedContract(e, contracts, setChecked)}
      />
    </>
  );
}

const ScanInventoryHospitalContractsWithLoading = withLoading(ScanInventoryHospitalContracts);
export default withFilters(ScanInventoryHospitalContractsWithLoading)([
  { name: "ipurchaseorderdata.popurchaseorderid", label: "PO Number" },
  { name: "ipurchaseorderdata.poseid", label: "SE ID" },
  { name: "ipurchaseorderdata.podate", label: "PO Date", type: "date" },
  { name: "ipurchaseorderdata.povendorid", label: "Distributor" },
  { name: "ipurchaseorderdata.popurchaseordertype", label: "Ownership" },
  { name: "ipurchaseorderdata.poinventorystatus", label: "Product Status", model: InventoryPOStatusHospital },
]);

export const checkNotRestockInventoryHistory = (c) => (getTemplate(c) !== "RestockInventoryHistory");