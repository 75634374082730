import React, { forwardRef } from "react";
import classNames from "classnames";
// import { useTheme } from "@material-ui/styles";
import { Dialog, DialogTitle, DialogContent, DialogActions, Slide, IconButton, Grid } from "@material-ui/core";
import closeIcon from "../../../icons/new-close-icon.svg";
import closeIconDM from "../../../icons/new-close-icon-dm.svg";
import useStyles from "./styles";
import { useLayoutState } from "../../../context/LayoutContext";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * New General Modal Dialog
 * @param {Boolean} open 
 * @param {Boolean} isEditable 
 * @param {Function} setOpen 
 * @param {String|Component} title
 * @param {Component} content
 * @param {Array} actions
 * @param {String} size lg|md
 * @param {Number} width Modal Width
 * @param {Number} height Modal Content Height
 * @param {String} className 
 * @param {Function} handleCloseRequest 
 * @returns 
 */
export default function NewGeneralModal ({
  open, setOpen, title, content, actions, size="lg", width, height, className, handleCloseRequest, 
}) {
  const layoutState = useLayoutState();
  const defaultWidth = (width ? width : (size === 'md' ? 702 : 1058));
  const defaultHeight = (height ? height : (size === 'md' ? 680 : 692));
  const classes = useStyles({ width: defaultWidth, height: defaultHeight });

  // const theme = useTheme();
  const windowWidth = window.innerWidth;
  // const breakpointWidth = theme.breakpoints.values.lg;
  // const fullScreen = windowWidth < breakpointWidth;
  const fullScreen = windowWidth < defaultWidth;

  const classNameList = [layoutState.darkMode?classes.dialogDM:classes.dialog];
  if (className) classNameList.push(className);

  const handleClose = () => {
    if(handleCloseRequest && typeof handleCloseRequest === 'function') {
      handleCloseRequest()
    }
    else if (typeof setOpen === 'function') setOpen(false);
  };


  return (
    <Dialog
      open={!!open}
      onClose={handleClose}
      className={classNames(classNameList)}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      maxWidth='lg'
      scroll='body'
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          {title}
        </Grid>
        <IconButton onClick={handleClose}>
        <img src={layoutState.darkMode?closeIconDM:closeIcon} alt="" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers={true}
      >
        {content}
        <DialogActions>
          {actions}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
