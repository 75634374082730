import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { useDamlState } from '../../context/DamlContext';
import { RoutesTable, checkRoute } from "./handle";


/**
 * Routes in Layout
 * @returns 
 */
function Routes() {

  const { role, userlistPayload, rolePayload } = useDamlState();
  const accesslevel = userlistPayload ? userlistPayload.accesslevel : null;
  const uidetaills = rolePayload?.uidetaills || [];


  return (
    <Switch>

      {
        RoutesTable.filter(route => checkRoute(route, role, accesslevel, uidetaills))
        .map((route, index) => (
          <Route key={index} path={route.path} component={route.component} />
        ))
      }

    </Switch>
  );
}

export default withRouter(Routes);
