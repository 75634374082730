import React, { useState, useEffect, useRef } from "react";
import /* ReactToPrint, */ { useReactToPrint } from 'react-to-print';
import { Typography, Accordion,AccordionDetails,AccordionSummary,
  TableContainer, Paper, Badge, Chip, DialogContentText, Drawer } from "@material-ui/core";
import { ExpandMore } from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';
import printerIcon from '../../icons/printer.svg';
import { formatDate } from "../../services/util";
import { checkComplianceStatus, checkComplianceStatusExpiration, 
  checkComplianceStatusSide } from "../../models/SurgicalEventCompliance";
import ProductDetailViewTable from "./product-detail-view-table";
import useStyles from "./styles";
import { validateSurgicalEventDetail } from "../../models/SurgicalEventDetail";
import { validateProduct } from "../../models/Product";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/**
 * Detail View Slide Drawer 
 * @param {Boolean} state
 * @param {Function} toggleDrawer
 * @param {Object} selectedEventData
 * @param {Array} productItemsList
 * @param {Boolean} isPrint
 */
export default function DetailViewDrawer ({
  state, toggleDrawer, selectedEventData, productItemsList, isPrint=true,
}) {
  // console.log("[DetailViewDrawer]", selectedEventData, productItemsList);

  // convert products to se detail
  if (productItemsList && productItemsList.length > 0)
    productItemsList = productItemsList.map(detail => (
      (validateSurgicalEventDetail(detail) && validateProduct(detail.product) && !validateProduct(detail)) ?
      detail.product : detail
    ));

  const classes = useStyles();
  const [printData, setPrintdata] = useState(false);
  const [expanded, setExpanded] = useState(0);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setPrintdata(false);
  };

  // Components used for React-to-Print
  const componentRef = useRef();
  
  const getPageMargins = () => {
    return `@page { margin: 10mm 10mm 10mm 10mm; }`;
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: () => getPageStyle(classes),
  });

  const handlePrintClick = async (e) => {
    await setPrintdata(true);
    handlePrint();
  };


  useEffect(() => {
    if (state) {
      // console.log("[DetailViewDrawer] is opened.");
      setExpanded(0);
      setPrintdata(false);
    }
  }, [state]);


  return (
    <React.Fragment  key={'right'} >
      <style>{getPageMargins()}</style> 
      <Drawer className={classes.customRoot} anchor={'right'} open={state} onClose={()=>{toggleDrawer(false)}}> 
        <div className="viewDetailsSe" ref={componentRef}>   
          <div className="view-heading" >
            <span>SE Id:</span> {selectedEventData && selectedEventData.seid} 

            {
              isPrint &&
              <>
                {/*<ReactToPrint
                  pageStyle={pageStyle}
                  trigger={() => <button className={classes.printButton}><img src={printerIcon} alt="Print" /></button>}
                  content={() => componentRef.current}
                />*/}
    
                <button className={classes.printButton} 
                  onClick={handlePrintClick}
                >
                  <img src={printerIcon} alt="Print" />
                </button>
              </>
            }
          
          </div>

          <DialogContentText component={'div'} className="modelStepDetai">
            <h2 className={classes.formSubHeading}>Patient Details</h2> 
            <table className="table-list"  width="100%"> 
              <thead>
                <tr className="table-list-item">
                  <td className="label">MRN</td>
                  <td className="label">First Name</td>
                  <td className="label">Last Name</td>
                  <td className="label">Gender</td>
                  <td className="label">DOB</td>
                </tr>
              </thead>
              <tbody>
                <tr className="table-list-item">
                  <td className="value">{selectedEventData && (selectedEventData.mrn)}</td>
                  <td className="value">{selectedEventData && selectedEventData.patientfirstname}</td>
                  <td className="value">{selectedEventData && selectedEventData.patientlastname}</td>
                  <td className="value">{selectedEventData && selectedEventData.patientgender}</td>
                  <td className="value">{selectedEventData && formatDate(selectedEventData.patientdob)}</td>
                </tr>
              </tbody>
            </table>
            
            <h2 className={classes.formSubHeading}>Event Details</h2>
            <table className="table-list"  width="100%"> 
              <thead>
                <tr className="table-list-item">
                  <td className="label">Date</td>
                  <td className="label">Procedure</td>
                  <td className="label">Side</td>
                </tr>
              </thead>
              <tbody>
                <tr className="table-list-item">
                  <td className="value">{selectedEventData && formatDate(selectedEventData.eventdate)}</td>
                  <td className="value">{selectedEventData && selectedEventData.surgicalprocedure}</td>
                  <td className="value">{selectedEventData && selectedEventData.surgicalside}</td>
                </tr>
              </tbody>
            </table>
              
            <h2 className={classes.formSubHeading}>Physician Details</h2>
            <table className="table-list" width="100%"> 
              <thead>
                <tr className="table-list-item">
                  <td className="label">Salutation</td>
                  <td className="label">First Name</td>
                  <td className="label">Last Name</td>
                </tr>
              </thead>
              <tbody>
                <tr className="table-list-item">
                  <td className="value">{selectedEventData && selectedEventData.physiciansalutation}</td>
                  <td className="value">{selectedEventData && selectedEventData.physicianfirstname}</td>
                  <td className="value">{selectedEventData && selectedEventData.physicianlastname}</td>
                </tr>
              </tbody>
            </table>
            
            {productItemsList.length !== 0 && 
              <div> 
                <div className={classes.productDetails} >
                  <h2 className={classes.formSubHeading}>Total Added Products 
                    <Badge badgeContent={productItemsList.length} color="primary" overlap="rectangular"></Badge>
                    <span className={classes.productTableHeading}><span className="price-label">Total Price of Products:</span> ${selectedEventData.price}</span>
                  </h2>
                </div>
                  
                <div className={classes.productAccordion} >
                  {productItemsList.map((obj,index) => (
                    <div className={classes.accordingPrint} key={index}>
                      <Accordion key={index} 
                        expanded={(expanded === (index+1) || printData === true)} 
                        onChange={handleChangeAccordion((index+1))} 
                        className={classes.productMainAccordion}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                          className="product-detail-accordion-head"
                        >
                          <Typography className={classes.accordionHeading}>
                            <span className="text-inactive">Reference Number : </span> 
                            <span className="text-active">{obj.sepreferencenumber}</span> 
                            <span className="text-inactive lotcode">Lot Code : </span> 
                            <span className="text-active">{obj.seplotcode}</span> 
                          </Typography>
                          {
                            checkComplianceStatus({surgicalevent: selectedEventData, auditedproductdata: obj}) !== ''
                            && <Chip label="Compliance" className="bg-info-light" />
                          }
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography component={'div'}>
                            <TableContainer  className={classes.productTableContainer} component={Paper}>
                              {
                                checkComplianceStatusSide(selectedEventData, obj) &&  
                                <Alert severity="info"><strong>Compliance</strong>: Product is the incorrect Side based on the Surgical Procedure</Alert> 
                              }
                              {
                                checkComplianceStatusExpiration(selectedEventData, obj) &&  
                                <Alert severity="info"><strong>Compliance</strong>: Product is past expiration date</Alert> 
                              }
                              <ProductDetailViewTable 
                                obj={obj}
                              />
                            </TableContainer>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  ))}
                </div> 
              </div>
            }
          </DialogContentText>
        </div>
      </Drawer>
    </React.Fragment>
  );
 
}

const getPageStyle = (classes) => 
`@page { size:letter; }
  @media print {
    .pagebreak {
      page-break-after: always;
    }
    .viewDetailsSe {
      padding: 10px;
    }
    .viewDetailsSe .view-heading {
      padding: 1px 0px 0px;
      font-weight: 600;
      font-size: 18px;
      border-bottom: solid 1px #cccccc;
      height: auto;
    }
    .view-heading span {
      font-weight: 400;
    }
    .table-list {
      table-layout: fixed;
    }
    .table-list .table-list-item .label {
      font-size: 10px;
      color: rgba(0,0,0,0.54);
    }
    .table-list .table-list-item .value {
      font-size: 13px;
      color: rgba(0,0,0,0.87);
      margin-top: 5px;
    }
    .${classes.accordionHeading} {
      font-size: 13px !important;
      font-weight: bold !important;
    }
    .${classes.formSubHeading} {
      font-size: 12px !important;
      line-height: 15px !important;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
    .${classes.productTableHeading} {
      font-size: 13px;
      color: rgba(0,0,0,0.87);
      font-weight: 400;
    }
    .price-label {
      font-size: 10px;
      color: rgba(0,0,0,0.54);
    }
    .${classes.productMainAccordion} {
      box-shadow: none;
      border-color: #d4d4d4;
      border: 1px;
      border-style: solid;
      background: none !important;
      border-radius: 4px;
    }
    .product-detail-accordion-head {
      align-items: center !important;
      justify-content: space-between !important;
    }
    .MuiAccordionSummary-root.Mui-expanded {
      min-height: auto !important;
      border-bottom: solid 1px #cccccc;
    }
    .MuiAccordionSummary-content {
      justify-content: space-between !important;
      margin: 0px !important;
    }
    .MuiChip-root {
      height: 24px !important;
      display: none !important;
    }
    .product-detail-accordion-head .bg-info-light {
      border: 1px solid #2196F3 !important;
      margin-left: 5px;
      background-color: rgba(33,150,243,0.38) !important;
    }
    .MuiAccordionDetails-root .MuiTypography-root {
      width: 100%;
    }
    .MuiAlert-root {
      margin: 6px 0;
      padding: 1px 16px !important;
      font-size: 13px !important;
      display: none !important;
    }
    .MuiAlert-filledInfo {
      color: rgba(0,0,0,0.87) !important;
      border: solid 1px #2196F3;
      background-color: rgba(33,150,243,0.38) !important;
    }
    .${classes.printButton} {
      display: none;
    }
    .${classes.accordingPrint} {
      width: 49%;
      font-size: 10px;
      display: inline-block;
      vertical-align:top;
      margin: 0.5%;
      padding-bottom: 5px;
    }
    .product-detail-data .MuiTableCell-root {
      padding: 0px 10px 0px 0px !important;
      font-size: 12px !important;
    }
    .product-detail-data .MuiTableCell-root.${classes.tableCellHead} {
      min-width: 150px !important;
    }
    ${classes.customRoot} .text-active {
      font-size: 14px;
    }
    .MuiCollapse-root {
      transition: none !important;
      height: auto !important;
    }
    .${classes.productAccordion} {
      display: inline-block;
      width: 100%;
    }
  }
`;
