import React, { useState } from "react";
import classNames from "classnames";
import { Chip } from "@material-ui/core";
import { RestockInventoryHistory } from "../../services/daml-modules1";
import AdaptiveSearch from "../../components/Search/adaptivesearch";
import SelectSearch from "../../components/Search/selectsearch";
import DateSearch from "../../components/Search/datesearch";
import TreeContracts from "../../components/Contracts/TreeContracts";
import { contractFilter } from "../../services/daml-filters";
import checkIcon from "../../icons/check1.svg";
import completeIcon from "../../icons/check6.svg";
import { /* getInventoryPOStatus, */ InventoryPOStatusHospital } from '../../models/Po';
import { isEnableArchive, isEnableArchivePO } from '../../models/POStatus';
// import { isEnableArchivePurchaseOrder } from '../purchaseorder/purchaseorderlistcontracts';
import { InventoryProductStatus, isEnableConfirmHospital, getTotalPrice } from "../../models/InventoryData";
import { Ownerships, InventoryOwnerships } from "../../models/Ownership";
import DetailViewDrawer from "./detail-view-drawer";
import SEDetailViewDrawer from "./detail-view-drawer-se";
import ReceiptConfirmInventory from './receipt-confirm';
import { confirmRestockInventoryHospital } from "./handle";
import ModalDialogBox from "../../components/Modals/GeneralModal/modalDialogBox";
import archiveIcon from "../../icons/folder-plus.svg";
import archiveDisableIcon from "../../icons/folder-plus-disable.svg";
// import { ArchivePo } from "../purchaseorder/handle";
import { useDamlStream } from "../../hooks/useDAML";
import useStyles from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";


/**
 * Restock Inventory Contracts in PO for Hospital
 * @param {Object} searchParams 
 * @param {Object} searchParamsPO 
 * @param {Object} searchParamsInternal 
 * @param {Boolean} isIncludeHistory 
 * @param {Function} handleChangeSearchValue 
 * @param {Array} allContracts 
 * @param {Object} ledger 
 * @param {String} hospital 
 * @param {String} roleCid 
 * @returns 
 */
function ScanInventoryHospitalContractsPO({
  searchParams = {},
  searchParamsPO = {},
  searchHistoryParamsPO = {},
  searchParamsInternal = {},
  isIncludeHistory = false,
  handleChangeSearchValue = null,
  allContracts = [],
  ledger,
  hospital,
  roleCid,
  userlistPayload,
}) {

  const classes = useStyles();

  // const assetsPO = useStreamQueries(PurchaseOrder, () => [searchParamsPO]);
  let contractsPO = contractFilter(allContracts, searchParamsPO);
  // const assets = useStreamQueries(RestockInventory, () => [searchParams]);
  // const assetsHistory = useStreamQueries(InventoryProductHistory, () => [searchParams]);


  // const [assetsPOHistory, setPOHisotry] = useState({});
  const assetsPOHistory = useDamlStream(RestockInventoryHistory, [searchHistoryParamsPO], isIncludeHistory);
  // const [assetsHistory, setInvPHisotry] = useState({});


  contractsPO = contractsPO.concat(isIncludeHistory && assetsPOHistory.contracts ? 
    assetsPOHistory.contracts.map(h => ({ ...h, payload: h.payload.inventory })) : [])
    .filter(c => (c.payload.ipurchaseorderdata.popurchaseordertype !== 'Loaned'));
  // check InventoryPOStatus, etc
  const newContracts = contractsPO.map(c => {
    return {
      ...c,
      payload: {
        ...c.payload, 
        receivedinventorydatalength: c.payload.inventorydata
          .reduce((s, i) => ((i.iproductstatus === "Received") ? s + parseInt(i.iquantity): s), 0),
        pototalamount: getTotalPrice(c.payload.inventorydata),
        inventorydata: c.payload.inventorydata.map((inv, index) => ({
          ...inv, 
          index,
          contractId: c.contractId,
          vendor: c.payload.vendor,
        })),
      }
    };
  })
  .filter(c => {
    let result = true;
    if (searchParamsInternal && searchParamsInternal.sepproductmanufacturer && searchParamsInternal.sepproductmanufacturer !== '') {
      // search sepproductmanufacturer
      result = result && (c.payload.inventorydata.length && c.payload.inventorydata[0].iproduct.sepproductmanufacturer === searchParamsInternal.sepproductmanufacturer);
    }
    if (searchParamsInternal && searchParamsInternal.receivedinventorydatalength && searchParamsInternal.receivedinventorydatalength !== '') {
      // search status
      result = result && (c.payload.receivedinventorydatalength === searchParamsInternal.receivedinventorydatalength);
    }
    return result;
  });

  console.log("[ScanInventoryHospitalContractsPO]", allContracts, searchParamsPO, searchParams, newContracts);

  const [ContractId, setContractId] = useState('');
  //const [confirmModalContract, setConfirmModalContract] = useState({});
  // SEDetailViewDrawer
  const [seData] = useState({});
  const [poList] = useState([]);
  const [sestate, setSEState] = useState(false);
  // DetailViewDrawer
  const [poData, setPOData] = useState({});
  const [inventoryList, setInventoryList] = useState([]);
  const [detailViewer, setDetailViewer] = useState(false);
  // Confirm Inventory
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [confirmInventoryData, setConfirmInventoryData] = useState({});
  // SE Archive
  const [openWarning, setOpenWarning] = useState(false);
  const [, setConfirmModalStatus] = useState('');


  // Click "Confirm" in list
  const handleConfirm = (inventoryData) => {
    // console.log("[ScanInventoryHospitalContractsPO] handleConfirm", inventoryData);
    if (inventoryData.iproductstatus !== 'Received') {
      let newInventoryData = {
        ...inventoryData,
        iproduct: {...inventoryData.iproduct},
      };
     
      setConfirmInventoryData(newInventoryData);
      setOpenConfirmModal(true);
    }
  };

  // handler to Confirm a Inventory in Confirm Modal
  const handleConfirmItem = async (newInventoryData) => {
    console.log("[ScanInventoryHospitalContractsPO] handleConfirmItem", confirmInventoryData, newInventoryData);

    const result = await confirmRestockInventoryHospital({
      ledger,
      roleCid,
      userlistPayload,
      vendor: newInventoryData.vendor,
      inventoryCid: newInventoryData.contractId,
      inventorydata: newInventoryData, // old case : newInventoryData.datas,
      newiproductstatus: 'Received', // new case
    });

    // setOpenConfirmModal(false);
    return result;
  };

  const handleSecRequest = () => {
    setOpenWarning(false);
  };
 

  // handle Archive PO
  const handleArchivePo = (po) => {
    console.log('[ScanInventoryHospitalContractsPO] handleArchivePO', ContractId);
    setConfirmModalStatus('ArchivePO');
    setContractId(po.contractId);
    setOpenWarning(true);
  };

  // Click "Yes" in "Archive Purchase Order" modal
  const handleModalYes = async () => {
    console.log("[ScanInventoryHospitalContractsPO] handleModalYes to archive Purchase Order", ContractId);
    // archive PO
    // Now, Restock Inventory & Purchase Order are different. So it can't remove POV here.
    /* await ArchivePo({
      ledger, cid: ContractId, roleCid,
    }); */
    setOpenWarning(false);
  };


  return (
    <>
      <div key="formChildStep" className="formsteps">
        <TreeContracts
          className={classes.customRoot}
          contracts={newContracts}
          columns={[
            ["PO Number", "payload.ipurchaseorderdata.popurchaseorderid", {
              type: 'link',
              onClick: (contractId, contract) => {
                if (!!contract) {
                  //console.log("contract.inventorydata", contract.payload);
                  contract.payload.povendorid = contract.payload.vendor;
                  setPOData({ ...contract.payload.ipurchaseorderdata });
                  setInventoryList(contract.payload.inventorydata);
                  setDetailViewer(true);
                }
              },
              search: {
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.ipurchaseorderdata.popurchaseorderid}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'popurchaseorderid')}
                    defaultValue={searchParamsPO.ipurchaseorderdata.popurchaseorderid}
                    label="Search PO Number"
                    contracts={allContracts}
                    isShrink={true}
                  />
              },
              isSearched: searchParamsPO.ipurchaseorderdata.popurchaseorderid,
              handleClear: () => handleChangeSearchValue('', 'popurchaseorderid'),
            }],
            ["PO Date", "payload.ipurchaseorderdata.podate", {
              search: {
                component:
                  <DateSearch
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'podate')}
                    defaultValue={searchParamsPO.ipurchaseorderdata.podate}
                    label="Search PO Date :"
                    isStatic={true}
                  />
              },
              isSearched: searchParamsPO.ipurchaseorderdata.podate,
              handleClear: () => handleChangeSearchValue('', 'podate'),
              options: {
                width: 150
              }
            }],
            ["Status", "payload.ipurchaseorderdata.poinventorystatus", {
              type: "render",
              render: (param) => (InventoryPOStatusHospital[param.value] && <Chip
                label={InventoryPOStatusHospital[param.value]}
                size="small"
                className={classNames(classes.smallChip,
                  (param.value === 'AllReceived' ? classes.backGreen : (
                    param.value === 'SomeReceived' ? classes.backYellow : (
                      param.value === 'AllShipped' ? classes.backBlue : (
                        param.value === 'SomeShipped' ? classes.backPink : classes.backRed
                      )
                    )
                  ))
                )}
              />),
              search: {
                component:
                  <SelectSearch
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'poinventorystatus')}
                    label="Search Status"
                    options={Object.keys(InventoryPOStatusHospital).map(value => ({ label: InventoryPOStatusHospital[value], value: value }))}
                    defaultValue={searchParamsPO.ipurchaseorderdata.poinventorystatus}
                    isStatic={true}
                  />
              },
              isSearched: searchParamsPO.ipurchaseorderdata.poinventorystatus,
              handleClear: () => handleChangeSearchValue('', 'poinventorystatus'),
            }],
            ["Manufacturer", "payload.inventorydata.0.iproduct.sepproductmanufacturer", {
              search: {
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.inventorydata && c.payload.inventorydata.length && 
                      c.payload.inventorydata[0].iproduct.sepproductmanufacturer}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'sepproductmanufacturer')}
                    defaultValue={searchParamsInternal.sepproductmanufacturer}
                    label="Search Manufacturer"
                    contracts={allContracts}
                    isShrink={true}
                  />
              },
              isSearched: searchParamsInternal.sepproductmanufacturer,
              handleClear: () => handleChangeSearchValue('', 'sepproductmanufacturer'),
              options: {
                width: 150,
              },
            }],
            ["Distributor", "payload.ipurchaseorderdata.povendorid", {
              search: {
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.ipurchaseorderdata.povendorid}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'povendorid')}
                    defaultValue={searchParamsPO.ipurchaseorderdata.povendorid}
                    label="Search Manufacturer"
                    contracts={allContracts}
                    isShrink={true}
                  />
              },
              isSearched: searchParamsPO.ipurchaseorderdata.povendorid,
              handleClear: () => handleChangeSearchValue('', 'povendorid'),
            }],
            ["Ownership", "payload.ipurchaseorderdata.popurchaseordertype", {
              type: "render",
              render: (param) => (param.value in Ownerships ? Ownerships[param.value] : null),
              search: {
                component:
                  <SelectSearch
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'popurchaseordertype')}
                    label="Search Ownership"
                    options={Object.keys(InventoryOwnerships).map(value => ({ label: InventoryOwnerships[value], value: value }))}
                    defaultValue={searchParamsPO.ipurchaseorderdata.popurchaseordertype}
                    isStatic={true}
                  />
              },
              isSearched: searchParamsPO.ipurchaseorderdata.popurchaseordertype,
              handleClear: () => handleChangeSearchValue('', 'popurchaseordertype'),
              options: {
                width: 150,
              },
            }],
            ["Received Products", "payload.receivedinventorydatalength", {
              type: "object",
              search: {
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.receivedinventorydatalength}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'receivedinventorydatalength')}
                    defaultValue={searchParamsInternal.receivedinventorydatalength}
                    label="Search Received Products"
                    contracts={allContracts}
                    isShrink={true}
                  />
              },
              isSearched: searchParamsInternal.receivedinventorydatalength,
              handleClear: () => handleChangeSearchValue('', 'receivedinventorydatalength'),
              options: {
                headerClassName: classes.centerCell,
                cellClassName: classes.centerCell,
              },
            }],
            ["Total Amount", "payload.pototalamount", {
              search: {
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.pototalamount}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'pototalamount')}
                    defaultValue={searchParamsPO.pototalamount}
                    label="Search Total Amount"
                    contracts={allContracts}
                    isShrink={true}
                  />
              },
              isSearched: searchParamsPO.pototalamount,
              handleClear: () => handleChangeSearchValue('', 'pototalamount'),
              options: {
                beforeText: '$'
              }
            }],
            ["Inventory Details", "payload.inventorydata", {
              type: "collapse",
              columns: [
                ["Reference Number", "iproduct.sepreferencenumber"],
                ["Product Description", "iproduct.sepproductdescription", {
                  options: {
                    tooltip: true
                  }
                }],
                ["Expiration", "iproduct.sepproductexpiration"],
                ["Price", "iproduct.sepproductprice", {
                  options: {
                    beforeText: '$'
                  }
                }],
                ["Lot Code", "iproduct.seplotcode"],
                ["Product Type", "iproduct.sepproducttype"],
                ["Quantity", "iquantity"],
                ["Receipt Status", "iproductstatus", {
                  type: "render",
                  render: (param) => {
                    const receiptStatus = (param.value === 'ShippedVendor' ? 'Received' : param.value);
                    return (InventoryProductStatus[receiptStatus] && <Chip
                      label={InventoryProductStatus[receiptStatus]}
                      size="small"
                      className={classNames(classes.smallChip, (
                        receiptStatus === 'Received' ? classes.backGreen : classes.backYellow
                      ))}
                    />);
                  },
                }],
              ],
              actions: [
                [
                  "Confirmation",
                  [
                    [["Confirm", [<img alt="" src={checkIcon} />, <img alt="" src={completeIcon} />],
                      (c) => isEnableConfirmHospital(c.iproductstatus)],
                      handleConfirm],
                  ],
                ],
              ],
              options: {
                // sortKey: 'sortkey',
              },
            }],

          ]}

          actions={[[
              "Action",
              [
               [["Archive", [<img alt="" src={archiveIcon} />, <img alt="" src={archiveDisableIcon} />],
               (c) => isEnableArchiveRestockInventory(c)],
                  handleArchivePo],
              ],
              {
                width: 170,
                cellClassName: classes.staticCellRight,
                headerClassName: classes.staticCellRight,
              }
            ]
          ]}
          options={{
            statusEmpty: (allContracts.length === 0)
          }}
        />


        <SEDetailViewDrawer
          state={sestate}
          toggleDrawer={(open) => setSEState(open)}
          selectedEventData={seData}
          poList={poList}
          isPPH={true}
        />

        <DetailViewDrawer
          state={detailViewer}
          toggleDrawer={(open) => setDetailViewer(open)}
          selectedPOData={poData}
          inventoryList={inventoryList}
        />

        <ReceiptConfirmInventory
          openModal={openConfirmModal}
          setOpenModal={setOpenConfirmModal}
          addProductItem={handleConfirmItem}
          inventoryData={confirmInventoryData}
          setConfirmInventoryData={setConfirmInventoryData}
          clearProductParams={[]} // show seplotcode, sepproductexpiration in Confirm Modal
        />

        <ModalDialogBox
          open={openWarning}
          setOpen={setOpenWarning}
          heading="Archive the Surgical Event?"
          type="warning"
          // text={'<p> You will not be able to Recover it.</p>'}
          text={'<p>Archiving  the Surgical Event on this page will impact Surgical Events, Purchase Order and Restock Inventory Records.  Please be aware that you will not be able to recover from this.</p><p>In the future,  to display Archived Contract Information, please check the “Include Archived Contracts” checkmark.</p>'}
          handleRequest={handleModalYes}
          secondBtn={'No'}
          handleSecRequest={handleSecRequest}
        />

      </div>
    </>
  );
}

export default withLoading(ScanInventoryHospitalContractsPO);

export const isEnableArchiveRestockInventory = (c) => {
  return (isEnableArchive(c.payload.ipurchaseorderdata.postatus)
        && isEnableArchivePO(c.payload.ipurchaseorderdata.poinventorystatus));
};