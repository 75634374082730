import React, { useState, useEffect } from "react";
import { useLedger, useParty, useStreamQueries } from "@daml/react";
import { Typography, Grid } from "@material-ui/core";
import { ReturnInventory as ReturnInventoryTemplate } from "../../services/daml-modules1";
import ReturnInventoryContracts from './returninventorycontracts';
import { dispatch, getState } from "../../store";
import { inventorySearch } from "../../store/actions/generalActions";
import { useDamlState } from "../../context/DamlContext";
import useStyles from "../surgicalevent/styles";


/**
 * Return Inventory page in Hospital Side
 * @returns 
 */
export default function ReturnInventory() {

  const classes = useStyles();

  const ledger = useLedger();
  const hospital = useParty();
  const assets = useStreamQueries(ReturnInventoryTemplate, () => [{ hospital }]);
  const { roleCid } = useDamlState();

  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({});


  const [searchInventory, setSearchInventory] = useState({
    ireferencenumber: '',
    ipurchaseordernumber: '',
    sepproductmanufacturer: '',
    ilotcode: '',
    iexpirationdate: '',
    ishipmentdate: '',
    ...getState().inventory,
  });
  const handleChangeSearchValue = (val, key) => {
    if ([
      'ipurchaseordernumber', 'sepproductmanufacturer', 'ishipmentdate',
    ].includes(key)) return;
    setSearchInventory({ ...searchInventory, [key]: val });
    dispatch(inventorySearch({ key, value: val }));
  };


  useEffect(() => {
    setLoading(true);

    let temp = {};

    temp.inventorydata = {iproduct: {}};
    if (searchInventory.ireferencenumber !== "") temp.inventorydata.iproduct.sepreferencenumber = searchInventory.ireferencenumber;
    if (searchInventory.ipurchaseordernumber !== "") temp.inventorydata.ipurchaseordernumber = searchInventory.ipurchaseordernumber;
    if (searchInventory.sepproductmanufacturer !== "") temp.inventorydata.iproduct.sepproductmanufacturer = searchInventory.sepproductmanufacturer;
    // if (searchInventory.ilotcode !== "") temp.inventoryproduct.ilotcode = searchInventory.ilotcode;
    // if (searchInventory.iexpirationdate !== "") temp.inventoryproduct.iexpirationdate = searchInventory.iexpirationdate;
    if (searchInventory.ishipmentdate !== "") temp.inventorydata.ishipmentdate = searchInventory.ishipmentdate;
    temp.hospital = hospital;

    const delay = 0;
    if (delay > 0) setTimeout(() => {
      setSearchParams(temp);
    }, delay);
    else {
      setSearchParams(temp);
    }
  }, [searchInventory, hospital]);

  useEffect(() => {
    setLoading(false);
  }, [searchParams]);


  return (
    <>
      <Grid>
        <Typography variant="h4" className={classes.pageTitle}>Return Inventory</Typography>

        <ReturnInventoryContracts
          searchParams={searchParams}
          handleChangeSearchValue={handleChangeSearchValue}
          allContracts={assets.contracts}
          searchInventory={searchInventory}
          hospital={hospital}
          ledger={ledger}
          roleCid={roleCid}
          loading={loading}
        />

      </Grid>
      
    </>
  );
}
