import { HospitalRole,  OperatorRole } from "../../services/daml-modules1";
import { loggerPush, LOGGER_INFO, LOGGER_ERROR } from '../../services/logger';



export const archivePayment = async ({
  contractId = ' ', ledger, payload, roleCid,
}) => {
  try {
    let res = await ledger.exercise(HospitalRole.DiscoverPaymentArchive, roleCid, {
      discoverpaymentid: contractId,
    });

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'DisPay: DiscoverPaymentArchive', response: res });

    return res;
  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'DisPay: DiscoverPaymentArchive', payload: e });
    console.log(e); 
  }
}

 export const CancelPayment = async ({
  ledger, roleCid, vendor, invinvoicenumber,dpcid
}) => {
  try{
    let res = await ledger.exercise(HospitalRole.CancelPayment, roleCid, {
      vendor,
      invinvoicenumber,
      dpcid
    });

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'DisPay: CancelPayment', response: res });

    return res;
  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'DisPay: CancelPayment', payload: e, params: invinvoicenumber });
    console.log(e); 
  }
};


 export const ModifyPaymentDate = async ({
  ledger, roleCid, dpcid, newPaymentinitiationdate, newPaymentdate, newinvoicedata
}) => {
  try{
    let res = await ledger.exercise(HospitalRole.ModifyPaymentDates, roleCid, {
      dpcid,
      newPaymentinitiationdate,
      newPaymentdate,
      newinvoicedata
    });
    
    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'DisPay: ModifyPaymentDates', response: res });

    return res;
  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'DisPay: ModifyPaymentDates', payload: e, params: {newinvoicedata, newPaymentdate} });
    console.log(e); 
  }
};


export const addHolidays = async ({ year, holidays,roleparties, ledger, roleCid,
}) => {
  try {
    console.log({ year, holidays, country: "US", roleparties });

    let res = await ledger.exercise(OperatorRole.AddHolidays, roleCid, {
      year, holidays, country: "US", roleparties
    });

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'DisPay: AddHolidays', response: res });

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'DisPay: AddHolidays', payload: e, params: holidays });
    console.log(e);
  }
}