import React, { useEffect } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { damlLastPath, ZENDESK_KEY } from "../config";
import Layout from "./Layout/Layout";
import Layout1 from "./Layout/Layout1"; // ! New Design
import Error from "../pages/error/Error";
import Login from "../pages/login/Login";
import { useUserState, useUserDispatch} from "../context/UserContext";
import Themes from "../themes";
import { LOGIN_SUCCESS } from "../store/actions/constants";
import Zendesk from "react-zendesk";
import  UseIdleTimeout  from "../components/Modals/IdleTimout/idleTimer";
import { I18nextProvider } from 'react-i18next';
import i18n from '../hoc/i18n';

/* const setting = {
  position: { horizontal: (isNewDesign ? 'left' : 'right'), vertical: 'bottom' }
} */

// Store
/* const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
); */

// hide all console logs in development
if (process.env.NODE_ENV !== 'development') {
  console.log = () => {};
}

export default function App() {
  const user = useUserState();
  let theme;
  switch (user.role) {
    case 'Vendor':
      theme = Themes.one;
      break;
    case 'Hospital':
      theme = Themes.two;
      break;
    case 'FinancialFirm':
      theme = Themes.three;
      break;
    case 'Operator':
      theme =Themes.operator; 
      break;
    default:
      theme = Themes.default;
      break;
  }
  const {version} = theme;
  const isNewDesign = (version && version.new);

  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <HashRouter>
          <Switch>
            <Route exact path="/" component={RootRoute} />
            {user.role === 'Vendor' && 
              <Route
                exact
                path="/app"
                render={() => <Redirect to="/app/receivedpo" />}
              />
            }
            {user.role === 'Operator' && 
              <Route
                exact
                path="/app"
                render={() => <Redirect to="/app/onboardnetwork" />}
              />
            }
            {user.role === 'Hospital' && 
              <Route
                exact
                path="/app"
                render={() => <Redirect to="/app/surgicalevent" />}
              />
            }
            <PrivateRoute path="/app" component={isNewDesign ? Layout1 : Layout} />
            <PublicRoute path="/login" component={Login} />
            <Route component={Error} />
          </Switch>
          {user.isAuthenticated ?
          <UseIdleTimeout/> : null}
        </HashRouter>
      </ThemeProvider>
    </I18nextProvider>
  );

  // #######################################################################

  function RootRoute(props) {
    var userDispatch = useUserDispatch();

    // start path
    const lastPath = sessionStorage.getItem(damlLastPath);
    if (lastPath) {
      sessionStorage.removeItem(damlLastPath);
    }

    /* [TYD-1958] As a part of this user story removing below logic.
    const startPath = user.isAuthenticated ? 
      (lastPath ? lastPath : (user.last_password_reset ? '/app/welcome' : '/app/setting-password')) 
      : '/login';
    */

      const startPath = user.isAuthenticated ?
      (lastPath ? lastPath : '/app/welcome')
      : '/login';
    console.log("[RootRoute]", user, lastPath, startPath);

    useEffect(() => {
      const url = new URL(window.location.toString());
      const token = url.searchParams.get('token');
      if (token === null) {
        return;
      }
      const party = url.searchParams.get('party');
      if (party === null) {
        throw Error("When 'token' is passed via URL, 'party' must be passed too.");
      }
      userDispatch({ type: LOGIN_SUCCESS, token, party });
    });

    return (
      <>
        <Redirect to={startPath} />
        {
          user.isAuthenticated ? 
            <Zendesk defer zendeskKey={ZENDESK_KEY} onLoaded={() => console.log('Zendesk is loaded')} />
          : 
            null
        }
      </>
    );
  }

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          user.isAuthenticated ? (
            React.createElement(component, props)
          ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    from: props.location,
                  },
                }}
              />
            )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          user.isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
              React.createElement(component, props)
            )
        }
      />
    );
  }
}
