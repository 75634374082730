import React, { useState } from "react";
import classNames from "classnames";
import { useParty, useStreamQueries } from "@daml/react";
import { AppBar, Tabs, Tab, Typography, Grid, Button, ButtonGroup, Badge } from '@material-ui/core';
import { AccountInventory, AccountInventoryHistory, InventoryRequestHistory } from "../../services/daml-modules1";
import TabPanel, { a11yProps } from "../../components/Modals/MultiTabsModals/TabPanel";
import InventoryListContractsVendor from "./inventorylistcontractsvendor1";
import InventoryListHistoricContractsVendor from "./inventorylisthistoriccontractsvendor";
import ReceivedInventoryContracts from "../inventoryreturn/receivedinventorycontracts1";
import HouseInventory from "./house-inventory";
import EditReturnInventory from "./edit-return-inventory";
import { getReturnStatus } from "./accountinventoryactivecontractshospital";
import exportIcon from "../../icons/new-export-icon.svg";
import printIcon from "../../icons/new-print-icon.svg";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "./styles";


/**
 * InventoryListVendor
 * Facility Inventory in Vendor Side
 * @returns 
 */
export default function InventoryListVendor() {

  const classes = useStyles();
  const classes1 = useStyles1();

  // const ledger = useLedger();
  const vendor = useParty();
  const { contracts, loading } = useStreamQueries(AccountInventory, () => [{ vendor }]);
  const { contracts: contractsHistory } = useStreamQueries(AccountInventoryHistory, () => [{ vendor }]);
  const { contracts: contracts2, loading: loading2 } = useStreamQueries(InventoryRequestHistory, () => [{ vendor }]);
  // get AccountInventory with Request Sent Status
  const contracts1 =  contracts.reduce((s, c) => (
    [...s, ...(c.payload.inventorydata.filter(invd => getReturnStatus(invd) === 'Awaiting Approval')
      .map((invd, index) => ({
        id: `${c.contractId}${index}`,
        contractId: c.contractId,
        payload: {
          ...c.payload,
          inventorydata: invd,
        },
      })
    ))]
  ), []);
  // Adjust Count
  const adjustCount = contracts2.reduce((s, c) => s + c.payload.inventorydata.length, 0) + contracts1.length;
  // console.log("[InventoryListVendor]", contracts1);


  const [value, setValue] = useState(0);
  const [isHistory, setHistory] = useState(false);
  // House Inventory in "My Inventory" Tab
  const [contract, setContract] = useState({});
  const [openIncomingModal, setOpenIncomingModal] = useState(false);
  // EditReturn Inventory in "Returns" Tab
  const [contract1, setContract1] = useState({});
  const [openIncomingModal1, setOpenIncomingModal1] = useState(false);
  // EditReturn Inventory in "Edits" Tab
  const [contract2, setContract2] = useState({});
  const [openIncomingModal2, setOpenIncomingModal2] = useState(false);
  // Edit / Return Tab
  const [isEditTab, setEditTab] = useState(true);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEditInventory = (c) => {
    setContract(c);
    setOpenIncomingModal(true);
  };

  const handleEditInventory1 = (c) => {
    setContract1(c);
    setOpenIncomingModal1(true);
  };

  const handleEditInventory2 = (c) => {
    setContract2(c);
    setOpenIncomingModal2(true);
  };


  return (
    <>
      <Grid>

        <Grid container justifyContent="space-between">
          <Grid item></Grid>
          <Grid item>
            <Button
              className={classNames(classes.newIconButton, classes1.iconButton)}
              variant="contained"
              // onClick={handlePrintClick}
              startIcon={<img  alt="" src={printIcon} />}
            />
            <Button
              className={classNames(classes.newIconButton, classes1.iconButton)}
              variant="contained"
              // onClick={handleOpenPopover}
              startIcon={<img  alt="" src={exportIcon} />}
            />
          </Grid>
        </Grid>

        <AppBar position="static" className={classNames(classes.tabPanelMenu, classes1.newTabPanelMenu)}>
          <Tabs indicatorColor="primary" textColor="primary" value={value} onChange={handleChange} 
            aria-label="simple tabs example" centered
          >
            <Tab label="Facility Inventory" {...a11yProps(0)} />
            <Tab 
              label={<div>
                  <span className="badge-title">Returns and Edits</span>
                  <Badge badgeContent={adjustCount} color="primary" overlap="rectangular" className={classes.badgeGreenColor}></Badge>
                </div>} 
              {...a11yProps(1)} 
            />
          </Tabs>
          {
            value === 1 &&
            <ButtonGroup variant="contained" size="small" color="primary" className={classes1.btnGroupTop}>
              <Button onClick={() => setEditTab(true)} className={isEditTab ? "active" : ""}>Edit</Button>
              <Button onClick={() => setEditTab(false)} className={isEditTab ? "" : "active"}>Return</Button>
            </ButtonGroup>
          }
        </AppBar>
        
        <TabPanel value={value} index={0} className={classes.tabPanelbody}>
          <InventoryListContractsVendor
            allContracts={contracts}
            historyContracts={contractsHistory}
            isIncludeHistory={isHistory}
            setArchived={setHistory}
            handleEditInventory={handleEditInventory}
            loading={loading}
          />
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.tabPanelbody}>
          <Grid 
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
          >
            <Grid item className={classes1.subTabPanel}>
              <Typography>
                {isEditTab ? "Edits" : "Returns"}
              </Typography>
            </Grid>
            <Grid item>
              {
                isEditTab
                ?
                <InventoryListHistoricContractsVendor
                  allContracts={contracts2}
                  handleEditInventory={handleEditInventory2}
                  loading={loading2}
                />
                :
                <ReceivedInventoryContracts
                  allContracts={contracts1}
                  handleEditInventory={handleEditInventory1}
                  loading={loading}
                />
              }
            </Grid>
          </Grid>
        </TabPanel>
        
        <HouseInventory
          open={openIncomingModal}
          setOpen={setOpenIncomingModal}
          contract={contract}
        />

        <EditReturnInventory
          open={openIncomingModal1}
          setOpen={setOpenIncomingModal1}
          contract={contract1}
          options={{
            title: 'Returns',
            isReturn: true,
          }}
        />

        <EditReturnInventory
          open={openIncomingModal2}
          setOpen={setOpenIncomingModal2}
          contract={contract2}
        />

      </Grid>
    </>
  );
}
