import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(theme => ({
  dialogPaper: {
    width: '500px',
    height: '550px',
    maxWidth: '70vw',
    maxHeight: '90vh',
  },
  dialogPaperLg: {
    width: '1000px',
    height: '600px',
    maxWidth: '90vw',
    maxHeight: '90vh',
  },
  dialogPaperSm: {
    width: '350px',
    height: '200px',
    maxWidth: '50vw',
    maxHeight: '50vh',
  },
  dialogPaperMd: {
    width: '450px',
    height: '300px',
    maxWidth: '60vw',
    maxHeight: '60vh',
  },
  dialogSwitchOrgPaper: {
    width: '275px',
    height: '300px',
    maxWidth: '60vw',
    maxHeight: '60vh',
    border: '1px solid #dedede',
  },
  dialogTitle: {
    textAlign: 'center'
  },
  dialogContent: {
    paddingTop: '30px'
  },
  dialogField: {
    width: '90%',
    // marginLeft: '10%',
  },
  multiTabs: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: '#fff'
  },
  tabPanel: {
    textAlign: 'center'
  },
  dialogTabsAction: {
    justifyContent: 'center',
    paddingBottom: 12,
  },
  button: {
    backgroundColor: '#000000',
    float: 'right',
    '&:hover': {
      backgroundColor: '#000000',
    },
  },
  topBar: {
    width: '90%',
    marginLeft: '5%',
  },
  contactPagination: {
    marginRight: 10,
  },
  secBottom: {
    marginTop: 20,
    // marginLeft: '10%',
    // width: '80%',
  },
  checkbox: {
  },
  fullWidth: {
    width: '100%'
  },
  seeMoreText: {
    color: '#000000',
    fontWeight: 'bold',
  },
  switchOrgCloseIcon: {
    cursor: 'pointer',
    color: '#1C3C74',
    border: '2px solid #1C3C74',
    borderRadius: '30px',
    background: '',
    fontWeight: 'bold',
    display: 'inline-block',
    lineHeight: '0px',
    padding: '9px 5px', 
  },
  switchOrgDialogTitle: {
    textAlign: 'left',
    fontSize: '1rem',
    border: '1px solid #dedede',
    padding: '4px 14px',
    fontWeight:'bold',
  },
  avtarSwitchOrg:{
    width:'25px',
    height: '25px',
    background: '#F7CD37',
    color: '#ffffff',
    borderRadius: '40px',
    marginRight:'9px',
    textAlign:'center',
    fontSize:'12px',
    lineHeight:'21px',
    padding: '7px 7px 8px 7px'
  },
  primarybtn: {
    background: "#1C3C74",
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    color: "#fff",
    borderRadius: '5px',  
  },
  dialogborder: {
    borderTop:'1px solid #dedede',
  },
  idleTimeoutDialogPaper: {
    width: '500px',
    height: '250px',
    maxWidth: '50vw',
    maxHeight: '60vh',
  },
}));
