import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  inputContainer: {
    borderTop: '1px solid #F7CD37',
    paddingTop: '5px',
    '& .MuiSvgIcon-root':{
      color:'#183C73'
    },
    '& .MuiIconButton-colorSecondary':{
      color:'#183C73'
    }
  },
  inputGrid: {
    width: '80%',
    paddingTop: '5px',
    '& .MuiInput-underline:after':{
      borderColor:'#183C73'
    },
    
  }
}));
