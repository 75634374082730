import React from 'react';
// import { InventoryProductHistory } from "../../services/daml-modules1";
import Contracts from '../../components/Contracts/Contracts';
import AdaptiveSearch from '../../components/Search/adaptivesearch';
import useStyles from "../surgicalevent/styles";


/**
 * Account Inventory in Prior Use Contracts
 * @param {Object} searchInv 
 * @param {String} role 
 * @param {Function} handleChangeSearchValue 
 * @param {Array} allContracts 
 * @returns 
 */
export default function AccountInventoryHistoricContracts({
  searchInv = {},
  role,
  handleChangeSearchValue = () => { },
  allContracts = [],
}) {

  const classes = useStyles();


  let contracts = [];

  // const { contracts: contractsInvHistory } = useStreamQueries(InventoryProductHistory, () => [searchInv]);
  const contractsInvHistory = allContracts;
  if (contractsInvHistory) {
    contractsInvHistory.forEach(c => {
      for (let i in c.payload.inventorydata) {
        let invData = c.payload.inventorydata[i];

        contracts.push({
          ...invData,
        });
      }
    });
  }

  console.log("[AccountInventoryHistoricContracts]", searchInv, contractsInvHistory, contracts, role);


  return (
    <>
      <Contracts
        className={classes.customRoot}
        contracts={contracts}
        columns={[
          ["Lot Code", "iproduct.seplotcode", {
            options: {
              width: 180,
            },
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.seplotcode}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'seplotcode')}
                  defaultValue={searchInv.seplotcode}
                  label="Search Lot Code"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchInv.seplotcode,
            handleClear: () => handleChangeSearchValue('', 'seplotcode'),
          }],
          ["Manufacturer", "iproduct.sepproductmanufacturer", {
            options: {
              width: 200
            }
          }],
          ["Distributor", "iproduct.sepdistributor", {
            options: {
              width: 200
            }
          }],
          ["Price", "iproduct.sepproductprice", {
            options: {
              beforeText: '$'
            }
          }],
          ["SE Id", "ipphseid", {
            options: {
              width: 240
            }
          }
          ],
          ["PO Number", "ipurchaseordernumber", {
            options: {
              width: 180
            }
          }],
          ["Received Date", "ireceiveddate", {
            options: {
              width: 180
            }
          }],
          ["PO Date", "ipurchaseorderdate", {
            options: {
              cellClassName: classes.centerCell,
              width: 150
            }
          }],
          /* ["Reference Number", "iproduct.sepreferencenumber",{
            options:{
              width:180
            }
          }],
          ["Ownership", "iproduct.sepownership"], */
        ]}
        options={{
          statusEmpty: (allContracts.length === 0)
        }}
      />

    </>
  );
}
