import React, { useState } from "react";
import classNames from "classnames";
import { Button, Grid, Chip } from "@material-ui/core";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "./styles";
import ModalDialog from '../../components/Modals/NewGeneralModal';
import MessageLog from "./MessageLogs";

export default function PaymentLogDetail({
  open, setOpen, contract = {}
}) {
  const classes = useStyles();
  const classes1 = useStyles1();
  const [messageLogOpen, setMessageLogOpen] = useState(false);

  const messageLog = async () => {
    setMessageLogOpen(true)
  }
  
  const getColorCode = (value) => {
    switch (value) {
      case 'Sent':
        return classes.backGreen;
      case 'Ready for payment':
        return classes.backBlue;
      case 'Complete':
        return classes.backGreen;
      case 'Available for payment':
        return classes.backBlue;
      case 'Cancelled':
        return classes.backOrange;
      default:
        return classes.backYellow;
    }
  }

  return (
    <>
      <ModalDialog
        className={classes.customRoot}
        width={500}
        height={300}
        open={open}
        setOpen={setOpen}
        title={(
          <Grid
            container
            spacing={1}
            alignItems="left"
            justifyContent="left"
          >
            <Grid item xs={4}>
              <label className={classes1.paymentLogTitle}>Payment Log</label>
            </Grid>
            <Grid item xs={3}>
              <label className={classes1.paymentLogHeader}>{contract.paymentnumber} </label>
            </Grid>
            <Grid item xs={3}>
              <Chip
                label={contract.status}
                size="small"
                className={classNames(classes.smallChip, getColorCode(contract.status))}
              />
            </Grid>
          </Grid>

        )}
        content={(
          <div className={classes1.paymentLogBody}>
            <Grid

              container
              spacing={2}
              alignItems="center"
              justifyContent="center"

            >

              <Grid item xs={3}>
                <label className={classes.label}>Distributor</label>
              </Grid>
              <Grid item xs={4}>
                <div className="value"><b>{contract.vendor}</b></div>
              </Grid>
              <Grid item xs={4}>
                <Button
                  className={classes.newCreateButton}
                  variant="contained"
                  onClick={messageLog}
                >
                  Message Log
                </Button>
              </Grid>
              <Grid item xs={3}>
                <label className={classes.label}>Invoice Id</label>
              </Grid>
              <Grid item xs={8}>
                <div className="value"><b>{contract?.invoicedata?.invinvoicenumber}</b></div>
              </Grid>

              <Grid item xs={3}>
                <label className={classes.label}>PO Number</label>
              </Grid>
              <Grid item xs={8}>
                <div className="value"><b>{contract.paymentnumber}</b></div>
              </Grid>
              <Grid item xs={3}>
                <label className={classes.label}>Payment Sent</label>
              </Grid>
              <Grid item xs={8}>
                <div className="value"><b>{contract.paymentinitiationdate}</b></div>
              </Grid>
              <Grid item xs={3}>
                <label className={classes.label}>Payment Due</label>
              </Grid>
              <Grid item xs={8}>
                <div className="value"><b>{contract.paymentdate}</b></div>
              </Grid>
              <Grid item xs={3}>
                <label className={classes.label}>Type</label>
              </Grid>
              <Grid item xs={8}>
                <div className="value"><b>{contract.payemnttype}</b></div>
              </Grid>
              <Grid item xs={3}>
                <label className={classes.label}>Amount</label>
              </Grid>
              <Grid item xs={8}>
                <div className="value"><b>{contract.invoicedata?.invinvoicetotalamount}</b></div>
              </Grid>
            </Grid>


          </div>
        )}
      />
      <MessageLog
        open={messageLogOpen}
        setOpen={setMessageLogOpen}
        contract={contract}
      />
    </>
  )
}