import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Table, TableBody, TableRow, TableCell, Grid, Typography } from "@material-ui/core";
import { Sides } from "../../models/Product";
import { Genders, Salutations } from "../../models/Contact";
import { requiredSurgicalEvent } from "../../models/SurgicalEvent";
import { CustomTextField, CustomSelect, DateInput } from "../../components/Inputs";
import SnackbarModal from "../../components/Modals/SnackbarModal";
import AdaptiveSearch from "../../components/Search/adaptivesearch1";
import { PRODUCT_CREATE_EVENT, EVENT_REQUIRED, PREF_DELETE_ALL_EVENT } from "../../store/actions/constants";
import { useSurgicalEvent } from "../../hooks/useProduct";
import useEvent from "../../hooks/useEvent";
import useStyles from "./styles";
import useStyles1 from "../showlist/styles";
import { getTodayString } from "../../services/util";
import { useDamlState, useDamlProcedure, useDamlSurgeon } from "../../context/DamlContext";
import patientIcon from "../../icons/new-se-patient-icon.svg";
import detailIcon from "../../icons/new-se-detail-icon.svg";
import physicianIcon from "../../icons/new-se-physician-icon.svg";
import PrefCardPickSetup from "./pickupsetup/prefPickSetup";
import { useTranslation } from 'react-i18next';
import selectIcon from '../../icons/select-indicator.svg';
import PrefDropDownView from "./prefphysiciansection/pref-dropdown-view"
import PrefListView from "./prefphysiciansection/pref-list"

/**
 * New Add, Edit SurgicalEvent Form
 * @param {Object} options 
 * @returns 
 */
export default function AddEditEvent({
  options = {}, 
}) {

  options = {
    isEnableWarnings: true,
    isCreate: true, // normal|edit
    ...options,
  };

  const { t } = useTranslation();
  const classes = useStyles();
  const classes1 = useStyles1();
  const [openToast, setOpenToast] = useState(false);
  const { surgicalevent: surgicalEventData, setSurgicalEvent: setSurgicalEventData,
    surgicaleventErrors: surgicalError, setSurgicalEventErrors: setSurgicalError, loading: loadingSurgicalEvent } = useSurgicalEvent();
  const { event, status, onEventVerify, onEventReject } = useEvent();
  const { procedures } = useDamlProcedure();
  const [selectedProcedures, setFilterProcedures] = useState([surgicalEventData?.surgicalprocedure]);
  const [defaultValue, setDefaultValue] = useState(surgicalEventData?.surgicalprocedure);
  const { rolePayload } = useDamlState();
  const { hospitaldetails } = (rolePayload ? rolePayload : {});

  const handleChange = (value, key) => {
    if (typeof setSurgicalEventData === 'function') {
      setSurgicalEventData({
        [key]: value,
      });
    }
  };

  useEffect(() => {
    if (event === PRODUCT_CREATE_EVENT && status === EVENT_REQUIRED) {
      const newErrors = requiredSurgicalEvent(surgicalEventData);
      if ((Object.keys(newErrors).length > 0)) {
        if (options.isEnableWarnings) setSurgicalError({ ...newErrors });
        onEventReject();
      }
      else {
        onEventVerify();
      }
    }
    setOpenToast(false);
  }, [event, status, surgicalEventData, onEventReject, onEventVerify, setSurgicalError, options.isEnableWarnings]);

  useEffect(() => {
    // console.log('[AddEditEvent] selectedProcedures', selectedProcedures, surgicalEventData?.surgicalprocedure);
    const surgicalprocedure = 'surgicalprocedure';
    if (selectedProcedures && selectedProcedures.length) {
      const temp = selectedProcedures.reverse();
      if (surgicalEventData?.surgicalprocedure !== temp[0]) {
        setSurgicalEventData({
          [surgicalprocedure]: temp[0],
        });
      }
    } else {
      if (surgicalEventData?.surgicalprocedure !== null && surgicalEventData?.surgicalprocedure !== undefined) {
        setSurgicalEventData({
          [surgicalprocedure]: null,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProcedures])

  useEffect(() => {
    if (!loadingSurgicalEvent) {
      setDefaultValue(surgicalEventData?.surgicalprocedure);
    }
  }, [loadingSurgicalEvent, surgicalEventData])


  return (
    <Grid container>
      <Grid item
        className={(options.isCreate ? null : classNames(classes.overview, classes.overviewEdit))}
      >
        {
          options.isCreate
            ?
            <Table className={classes.newSEForm}>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2} className="new-subtitle" style={{ paddingTop: 0 }}>Patient Details</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    <CustomTextField
                      label="MRN"
                      required={true}
                      value={surgicalEventData.mrn || ""}
                      onChange={(val) => handleChange(val, 'mrn')}
                      warning={surgicalError.mrn}
                      width={333}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingRight: '10px' }}>
                    <CustomTextField
                      label="Last Name"
                      required={true}
                      value={surgicalEventData.patientlastname || ""}
                      onChange={(val) => handleChange(val, 'patientlastname')}
                      warning={surgicalError.patientlastname}
                      width={170}
                    />
                  </TableCell>
                  <TableCell>
                    <CustomTextField
                      label="First Name"
                      required={true}
                      value={surgicalEventData.patientfirstname || ""}
                      onChange={(val) => handleChange(val, 'patientfirstname')}
                      warning={surgicalError.patientfirstname}
                      width={170}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <CustomSelect
                      label="Gender"
                      required={true}
                      value={surgicalEventData.patientgender || ""}
                      onChange={(val) => handleChange(val, 'patientgender')}
                      items={Genders}
                      placeholder="Select one"
                      warning={surgicalError.patientgender}
                      width={143}
                    />
                  </TableCell>
                  <TableCell>
                    <DateInput
                      label="DOB"
                      required={true}
                      value={surgicalEventData.patientdob || ""}
                      onChange={(val) => {
                        if (val <= getTodayString()) {
                          handleChange(val, 'patientdob');
                        }
                        else {
                          setOpenToast(true);
                        }
                      }
                      }
                      warning={surgicalError.patientdob}
                      width={143}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={2} className="new-subtitle">Event Details</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    <DateInput
                      label="Date"
                      required={true}
                      value={surgicalEventData.eventdate || ""}
                      onChange={(val) => handleChange(val, 'eventdate')}
                      warning={surgicalError.eventdate}
                      width={143}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    { hospitaldetails?.pref_facilityid ?
                     <Grid item>
                      <AdaptiveSearch
                        contracts={procedures}
                        params={selectedProcedures}
                        setParams={setFilterProcedures}
                        classes={{ wrapper: classes1.adaptiveSearchAutoComplete }}
                        defaultValue={defaultValue}
                        isChangable={true}
                        isMultiple={false}
                        warning={surgicalError.surgicalprocedure}
                        popupIcon={<img src={selectIcon} alt='Select Icon' />}
                        label={'Procedure'}
                        required={true}
                        placeholder="Select One"
                        width={330}
                      />
                     
                    </Grid>
                     :
                    
                     <CustomTextField
                     label="Procedure"
                     required={true}
                     value={surgicalEventData.surgicalprocedure || ""}
                     onChange={(val) => handleChange(val, 'surgicalprocedure')}
                     warning={surgicalError.surgicalprocedure}
                     width={200}
                    
                   />
                   }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    <CustomSelect
                      label="Side"
                      required={true}
                      value={surgicalEventData.surgicalside || ""}
                      onChange={(val) => handleChange(val, 'surgicalside')}
                      items={Sides}
                      placeholder="Select One"
                      warning={surgicalError.surgicalside}
                      width={143}
                    />
                  </TableCell>
                </TableRow>

                <PhysicianDetail
                  surgicalEventData={surgicalEventData}
                  setSurgicalEventData={setSurgicalEventData}
                  handleChange={handleChange}
                  surgicalError={surgicalError}
                  Salutations={Salutations}
                />

              </TableBody>
            </Table>
            :
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className="details" colSpan={6}>
                    <img src={patientIcon} alt="" />
                    <Typography variant="inherit">Patient Details</Typography>
                  </TableCell>
                  <TableCell style={{ width: '246px' }}></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">MRN :</TableCell>
                  <TableCell className="value">
                    <CustomTextField
                      value={surgicalEventData.mrn || ""}
                      onChange={(val) => handleChange(val, 'mrn')}
                      warning={surgicalError.mrn}
                      width={170}
                      options={{
                        readOnly: true,
                      }}
                    />
                  </TableCell>
                  <TableCell className="label">SE ID :</TableCell>
                  <TableCell className="value">{surgicalEventData.seid}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">Last Name :</TableCell>
                  <TableCell className="value">
                    <CustomTextField
                      value={surgicalEventData.patientlastname || ""}
                      onChange={(val) => handleChange(val, 'patientlastname')}
                      warning={surgicalError.patientlastname}
                      width={170}
                      options={{
                        readOnly: true,
                      }}
                    />
                  </TableCell>
                  <TableCell className="label">First Name :</TableCell>
                  <TableCell className="value">
                    <CustomTextField
                      value={surgicalEventData.patientfirstname || ""}
                      onChange={(val) => handleChange(val, 'patientfirstname')}
                      warning={surgicalError.patientfirstname}
                      width={170}
                      options={{
                        readOnly: true,
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow className="border-row">
                  <TableCell className="label">Gender :</TableCell>
                  <TableCell className="value">
                    <CustomSelect
                      value={surgicalEventData.patientgender || ""}
                      onChange={(val) => handleChange(val, 'patientgender')}
                      items={Genders}
                      placeholder="Select one"
                      warning={surgicalError.patientgender}
                      width={143}
                      options={{
                        readOnly: true,
                      }}
                    />
                  </TableCell>
                  <TableCell className="label">DOB :</TableCell>
                  <TableCell className="value">
                    <DateInput
                      value={surgicalEventData.patientdob || ""}
                      onChange={(val) => handleChange(val, 'patientdob')}
                      warning={surgicalError.patientdob}
                      width={143}
                      options={{
                        readOnly: true,
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="details" colSpan={6}>
                    <img src={detailIcon} alt="" />
                    <Typography variant="inherit">Event Details</Typography>
                  </TableCell>
                </TableRow>
                <TableRow className="border-row">
                  <TableCell className="label">Event Date :</TableCell>
                  <TableCell className="value">
                    <DateInput
                      value={surgicalEventData.eventdate || ""}
                      onChange={(val) => handleChange(val, 'eventdate')}
                      warning={surgicalError.eventdate}
                      width={143}
                      options={{
                        readOnly: true,
                      }}
                    />
                  </TableCell>
                  <TableCell className="label">Procedure :</TableCell>
                  <TableCell className="value">
                  { hospitaldetails?.pref_facilityid ?
                   <AdaptiveSearch
                      contracts={procedures}
                      params={selectedProcedures}
                      setParams={setFilterProcedures}
                      classes={{ wrapper: classes1.adaptiveSearchAutoComplete }}
                      defaultValue={surgicalEventData.surgicalprocedure}
                      isChangable={true}
                      isMultiple={false}
                      warning={surgicalError.surgicalprocedure}
                      popupIcon={<img src={selectIcon} alt='Select Icon' />}
                      required={true}
                      placeholder="Select One"
                      isReadOnly={true}
                      width={330}
                    />
                      :
                    
                    <CustomTextField
                    required={true}
                    value={surgicalEventData.surgicalprocedure || ""}
                    onChange={(val) => handleChange(val, 'surgicalprocedure')}
                    warning={surgicalError.surgicalprocedure}
                    width={200}
                    options={{
                      readOnly: true,
                    }}
                   
                  />
                  }
                  </TableCell>
                  <TableCell className="label">Side :</TableCell>
                  <TableCell className="value">
                    <CustomSelect
                      value={surgicalEventData.surgicalside || ""}
                      onChange={(val) => handleChange(val, 'surgicalside')}
                      items={Sides}
                      placeholder="Select One"
                      warning={surgicalError.surgicalside}
                      width={160}
                      options={{
                        readOnly: true,
                      }}
                    />
                  </TableCell>
                </TableRow>
                <PhysicianDetail
                  surgicalEventData={surgicalEventData}
                  setSurgicalEventData={setSurgicalEventData}
                  handleChange={handleChange}
                  surgicalError={surgicalError}
                  Salutations={Salutations}
                  readOnly={true}
                />
              </TableBody>
            </Table>
        }
        <SnackbarModal
          open={openToast}
          setOpen={setOpenToast}
          type="warning"
          title=""
          content={t('warnings.surgicalevent.entervalidatese')}
          vertical="center"
          horizontal="center"
          width={300}
          height={117}
          transition="fade"
          className={classes.toastSmall}
          options={{
            validateULE: true,
          }}
          actions={[
            {
              label: 'OK',
              handle: () => {
                setOpenToast(false);
              },
            },

          ]}
        />
      </Grid>

    </Grid>

  );
}

const PhysicianDetail = ({ surgicalEventData, setSurgicalEventData, handleChange, surgicalError, Salutations, readOnly }) => {
  const { rolePayload } = useDamlState();
  const { hospitaldetails } = (rolePayload ? rolePayload : {});

  return (hospitaldetails?.pref_facilityid ?
    <SurgonPrefCardInfo
      surgicalEventData={surgicalEventData}
      handleChange={handleChange}
      setSurgicalEventData={setSurgicalEventData}
      facilityId={hospitaldetails.pref_facilityid}
      readOnly={readOnly}
    />
    :
    <PhysicianInfo
      surgicalEventData={surgicalEventData}
      handleChange={handleChange}
      surgicalError={surgicalError}
      Salutations={Salutations}
      readOnly={readOnly} />
  )


}

const PhysicianInfo = ({ surgicalEventData, handleChange, surgicalError, Salutations, readOnly }) => {

  return (readOnly ?
    <>
      <TableRow>
        <TableCell className="details" colSpan={6}>
          <img src={physicianIcon} alt="" />
          <Typography variant="inherit">Physician Details</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="label">Salutation :</TableCell>
        <TableCell className="value">
          <CustomSelect
            value={surgicalEventData.physiciansalutation || ""}
            onChange={(val) => handleChange(val, 'physiciansalutation')}
            items={Salutations}
            placeholder="Select One"
            warning={surgicalError.physiciansalutation}
            width={143}
            options={{
              readOnly: true,
            }}
          />
        </TableCell>
        <TableCell className="label">Last Name :</TableCell>
        <TableCell className="value">
          <CustomTextField
            value={surgicalEventData.physicianlastname || ""}
            onChange={(val) => handleChange(val, 'physicianlastname')}
            warning={surgicalError.physicianlastname}
            width={170}
            options={{
              readOnly: true,
            }}
          />
        </TableCell>
        <TableCell className="label">First Name :</TableCell>
        <TableCell className="value">
          <CustomTextField
            value={surgicalEventData.physicianfirstname || ""}
            onChange={(val) => handleChange(val, 'physicianfirstname')}
            warning={surgicalError.physicianfirstname}
            width={170}
            options={{
              readOnly: true,
            }}
          />
        </TableCell>
      </TableRow>
    </>
    :
    <>
      <TableRow>
        <TableCell colSpan={2} className="new-subtitle">Physician Details</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={2}>
          <CustomSelect
            label="Physician Salutation"
            // required={true}
            value={surgicalEventData.physiciansalutation || ""}
            onChange={(val) => handleChange(val, 'physiciansalutation')}
            items={Salutations}
            placeholder="Select One"
            warning={surgicalError.physiciansalutation}
            width={143}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <CustomTextField
            label="Last Name"
            required={true}
            value={surgicalEventData.physicianlastname || ""}
            onChange={(val) => handleChange(val, 'physicianlastname')}
            warning={surgicalError.physicianlastname}
            width={170}
          />
        </TableCell>
        <TableCell>
          <CustomTextField
            label="First Name"
            required={true}
            value={surgicalEventData.physicianfirstname || ""}
            onChange={(val) => handleChange(val, 'physicianfirstname')}
            warning={surgicalError.physicianfirstname}
            width={170}
          />
        </TableCell>
      </TableRow>
    </>)
}

const SurgonPrefCardInfo = ({ surgicalEventData, setSurgicalEventData, readOnly }) => {
  const { surgeonContracts: contracts } = useDamlSurgeon();
  const physicianData = contracts.map((allData, c) => { return { label: allData.payload.firstname + ", " + allData.payload.lastname + ", " + allData.payload.salutation, value: allData.payload.pref_card_id, phyData: allData.payload } });
  const { prefdata } = useSurgicalEvent();

  const classes = useStyles();
  const [detailData, setDetailData] = useState([]);
  const [openCard, setOpenCard] = useState(false);

  const handleEditPrefCard = async (dataPrefElement) => {
    setDetailData(dataPrefElement);
    setOpenCard(true);
  }
  const [openPickedToast, setOpenPickedToast] = useState(false);
  const { t } = useTranslation();
  const { onEventStart } = useEvent();

  const handlePhydata = (phyData) => {
    debugger
    setSurgicalEventData({
      physiciansalutation: phyData.salutation,
      physicianfirstname: phyData.firstname,
      physicianlastname: phyData.lastname,
      surgonid: phyData.pref_card_id
    });
    onEventStart(PREF_DELETE_ALL_EVENT)
  }


  const getView = () => (readOnly ?
    <>
      <TableRow>
        <TableCell className="details" colSpan={6}>
          <img src={physicianIcon} alt="" />
          <Typography variant="inherit">Physician Details</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="label">Physician Name :</TableCell>
        <TableCell className="value">
          {
            (prefdata && prefdata.length > 0)
              ?
              <CustomTextField
                required={true}
                value={`${surgicalEventData.physicianfirstname}, ${surgicalEventData.physicianlastname}, ${surgicalEventData.physiciansalutation}` || ""}
                options={{
                  readOnly: true,
                }}
                width={200}
              />
              :
              <CustomSelect
                items={physicianData}
                value={surgicalEventData.surgonid}
                onChange={(val) => {
                  const selectedPhysician = physicianData?.find(
                    (item) => item.value === val
                  );
                  handlePhydata(selectedPhysician.phyData);
                }}
                placeholder="Select One"
                width={250}
                options={{
                  readOnly: true,
                }}
              />
          }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="label" style={{ verticalAlign: "top" }}>{t('surgicalEvent.createSE.pref.label')} :</TableCell>
        <PrefDropDownView
          surgonid={surgicalEventData.surgonid}
          seid={surgicalEventData.seid}
          width={200}
        />
      </TableRow>
      <TableRow>
        <PrefListView onPrefView={(detail) => handleEditPrefCard(detail)} />
      </TableRow>
    </>
    :
    <>
      <TableRow>
        <TableCell colSpan={2} className="new-subtitle">Physician Details</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={2}>
          <CustomSelect
            label="Physician Name"
            items={physicianData}
            value={surgicalEventData.surgonid}
            onChange={(val) => {
              const selectedPhysician = physicianData?.find(
                (item) => item.value === val
              );
              handlePhydata(selectedPhysician.phyData);
            }}
            placeholder="Select One"
            width={250}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <PrefDropDownView
          surgonid={surgicalEventData.surgonid}
          seid={surgicalEventData.seid}
          width={250}
          dropDownlabel={t('surgicalEvent.createSE.pref.label')}
        />
        <PrefListView
          top={15}
          onPrefView={(detail) => handleEditPrefCard(detail)}
        />
      </TableRow>
    </>)
  return (
    <>
      {
        getView()
      }

      {openCard ? (
        <PrefCardPickSetup
          open={openCard}
          setOpen={setOpenCard}
          detailData={detailData}
          setOpenPickedToast={setOpenPickedToast}
        />
      ) : null}
      <SnackbarModal
        open={openPickedToast}
        options={{
          delay: 500,
        }}
        setOpen={setOpenPickedToast}
        title=""
        content="All items have been picked"
        width={330}
        height={88}
        className={classes.toastMedium}
      />
    </>
  )

}
