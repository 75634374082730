import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  textContainerHeader: {
    marginLeft: '20px',
  },
  leftInnerContainer: {
    display: 'flex',
    padding: '10px',
  },
  onlineIcon: {
    margin: '4px 10px',
    color: '#8a4e0ccf'
  },
  containerItems: {
    padding: '20px',
    
  },
  activeItem: {
    display: 'flex'
  },
  activeIcon: {
    color: '#00ff95', 
    marginLeft: '5px',
  },
  listgrid: {
    maxHeight: '420px',
    overflow:'auto'
  },
  list: {
    width: '100%',
  
  },
  avatarIcon:{
    '& .MuiAvatar-colorDefault':{
      background:'#F7CD37'
    }
    
  }
}));
