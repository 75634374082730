import moment from "moment";
import { NODE_SERVER_ENDPOINT } from "../../config";
import { HospitalRole } from "../../services/daml-modules1";
import { loggerPush, LOGGER_INFO, LOGGER_ERROR } from '../../services/logger';

export const CancelPayment = async ({
  ledger, roleCid, vendor, invinvoicenumber, dpcid
}) => {
  try {
    let res = await ledger.exercise(HospitalRole.CancelPayment, roleCid, {
      dpcid
    });

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'DisPay: CancelPayment', response: res });

    return res;
  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'DisPay: CancelPayment', payload: e, params: invinvoicenumber });
    console.log(e);
  }
};


export const ModifyPaymentDate = async ({
  ledger,vendor, roleCid, userlistPayload, dpcid, newPaymentinitiationdate, newPaymentdate, newPaymentamount, newPaymentType, invoicenumber, note,deletedFiles,uploadedFiles,remainingFiles
}) => {
  console.clear();
  console.log("deletedFiles",deletedFiles,
    "uploadedFiles",uploadedFiles,
    "remainingFiles",remainingFiles);
  const email = userlistPayload?.email;
  const username = userlistPayload?.name;
  try {
    let uploadedFileWithNewName = [];
    if (uploadedFiles.length > 0) {
        uploadedFiles.forEach((e) => {
            let formData = new FormData()
            const file_name = moment(new Date()).format("YYYY-MM-DD HH:MM:ss:SSS Z") + " " + e.name;
            uploadedFileWithNewName.push(file_name);
            formData.append('file_name', file_name);
            formData.append('file', e);
                fetch(NODE_SERVER_ENDPOINT + 'api/sap-upload', {
                    method: 'POST',
                    body: formData,
                })
        })
    }
    if(deletedFiles.length>0)
    {
      deletedFiles.forEach((file)=>{
        fetch(NODE_SERVER_ENDPOINT + `api/sap-deletefile/${file}`, {
          method: 'GET',
      })
      })
    }
    let res = await ledger.exercise(HospitalRole.ModifyPaymentDates, roleCid, {
      vendor,
      dpcid,
      newPaymentinitiationdate,
      newPaymentdate,
      newPaymentamount ,
      newPaymentType,
      invoicenumber,
      note,
      newFileName:[...uploadedFileWithNewName,...remainingFiles],
      useremail: email,
      username: username,
    });

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'DisPay: ModifyPaymentDates', response: res });

    return res;
  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'DisPay: ModifyPaymentDates', payload: e, params: { newPaymentamount, newPaymentdate } });
    console.log(e);
  }
};



export const manualPayment = async ({
  ledger, roleCid,userlistPayload,
  vendor,
  invoicenumber,
  paymentinitiationdate,
  paymentdate,
  paymenttype,
  totalamount,
  fileName,
  note = " "
}) => {
  const email = userlistPayload?.email;
  const username = userlistPayload?.name;
  try {
    const uploadedFileWithNewName = [];
    if (fileName.length > 0) {
      fileName.forEach((e) => {
        let formData = new FormData()
        const file_name = moment(new Date()).format("YYYY-MM-DD HH:MM:ss:SSS Z") + " " + e.name;
        uploadedFileWithNewName.push(file_name);
        formData.append('file_name', file_name);
        formData.append('file', e);
        fetch(NODE_SERVER_ENDPOINT + 'api/sap-upload', {
            method: 'POST',
            body: formData,
          })
      })
    }
    let res = await ledger.exercise(HospitalRole.ManualPayment, roleCid, {
      vendor,
      invoicenumber,
      paymentinitiationdate,
      paymentdate,
      paymenttype,
      totalamount,
      note,
      fileName:uploadedFileWithNewName,
      useremail: email,
      username: username,
    });

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'DisPay: ManualPay', response: res });

    return res;
  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'DisPay: ManualPay', payload: e });
    console.log(e);
  }
};