import { isNewDesign } from "../config";
import { validateOwnership, isImpossibleInventory } from './Ownership';
import { validatePOStatus } from './POStatus';
import { requiredFields, getModelMenu, validateModelItem, getSubModel } from "./handles";
import { getTodayString, getTemplate } from "../services/util";

export const PoModel = {
  id: '',
  popurchaseorderid: '',
  poitemnumber: '',
  popurchaseordertype: '',
  poreferenceid: '',
  poexpenditurecode: '',
  podate: '',
  povendorid: '',
  porequisitionedby: '',
  poreceivedate: '',
  popromisedate: '',
  potax: '',
  pototalamount: '',
  pofreighthandeling: '',
  pocanceled: '',
  pocompleted: '',
  postatus: '',
  poseid: '',
  posecompletedate: '',
  poinventorystatus: '',
  ponotes: '',
  potype: '',
  // sent_vendor_at: '',
  // is_sent_erp: false,
};

export const validatePurData = (purdata) => {
  return ((purdata.popurchaseordertype !== ""
    && validateOwnership(purdata.popurchaseordertype)) || 
    (
      purdata.popurchaseorderid !== ""
      || purdata.poitemnumber !== ""
      || purdata.poreferenceid !== ""
      || purdata.poexpenditurecode !== ""
      || purdata.podate !== ""
      || purdata.povendorid !== ""
      || purdata.requisitionedby !== ""
      || purdata.poreceivedate !== ""
      || purdata.popromisedate !== ""
      || purdata.potax !== ""
      || purdata.pototalamount !== ""
      || purdata.pofreighthandeling !== ""
      || purdata.pocanceled !== ""
      || purdata.pocompleted !== ""
      || (purdata.postatus !== ""
        && validatePOStatus(purdata.postatus))
      || purdata.poseid !== ""
      || (purdata.poinventorystatus !== ""
        && validateInventoryPOStatus(purdata.poinventorystatus))
      || (purdata.potype !== ""
        && validatePoType(purdata.potype))
    )
  );
};

export const getPurData = (purdata) => {
  let newPurdata = {};
  if (typeof purdata.id === "string" && purdata.id !== "") newPurdata.id = purdata.id;
  newPurdata.popurchaseorderid = purdata.popurchaseorderid || "";
  if (typeof purdata.poitemnumber === "string" && purdata.poitemnumber !== "") newPurdata.poitemnumber = purdata.poitemnumber;
  newPurdata.popurchaseordertype = validateOwnership(purdata.popurchaseordertype) ? purdata.popurchaseordertype : "Unknown";
  if (typeof purdata.poreferenceid === "string" && purdata.poreferenceid !== "") newPurdata.poreferenceid = purdata.poreferenceid;
  if (typeof purdata.poexpenditurecode === "string" && purdata.poexpenditurecode !== "") newPurdata.poexpenditurecode = purdata.poexpenditurecode;
  newPurdata.podate = purdata.podate || "";
  newPurdata.povendorid = purdata.povendorid || "";
  if (typeof purdata.porequisitionedby === "string" && purdata.porequisitionedby !== "") newPurdata.porequisitionedby = purdata.porequisitionedby;
  if (typeof purdata.poreceivedate === "string" && purdata.poreceivedate !== "") newPurdata.poreceivedate = purdata.poreceivedate;
  if (typeof purdata.popromisedate === "string" && purdata.popromisedate !== "") newPurdata.popromisedate = purdata.popromisedate;
  if (typeof purdata.potax === "string" && purdata.potax !== "") newPurdata.potax = purdata.potax;
  newPurdata.pototalamount = purdata.pototalamount || "0";
  if (typeof purdata.pofreighthandeling === "string" && purdata.pofreighthandeling !== "") newPurdata.pofreighthandeling = purdata.pofreighthandeling;
  if (typeof purdata.pocanceled === "string" && purdata.pocanceled !== "") newPurdata.pocanceled = purdata.pocanceled;
  if (typeof purdata.pocompleted === "string" && purdata.pocompleted !== "") newPurdata.pocompleted = purdata.pocompleted;
  newPurdata.postatus = purdata.postatus || "";
  if (typeof purdata.poseid === "string" && purdata.poseid !== "") newPurdata.poseid = purdata.poseid;
  if (typeof purdata.posecompletedate === "string" && purdata.posecompletedate !== "") newPurdata.posecompletedate = purdata.posecompletedate;
  if (typeof purdata.poinventorystatus === "string" && validateInventoryPOStatus(purdata.poinventorystatus)) newPurdata.poinventorystatus = purdata.poinventorystatus;
  if (typeof purdata.ponotes === "string" && purdata.ponotes !== "") newPurdata.ponotes = purdata.ponotes;
  if (typeof purdata.potype === "string" && validatePoType(purdata.potype)) newPurdata.potype = purdata.potype;
  // if (typeof purdata.sent_vendor_at === "string" && purdata.sent_vendor_at !== "") newPurdata.sent_vendor_at = purdata.sent_vendor_at;
  // if (typeof purdata.is_sent_erp === "boolean") newPurdata.is_sent_erp = purdata.is_sent_erp;
  return newPurdata;
};

export const requiredPurData = (purdata) => requiredFields(purdata, (
  isNewDesign
  ?
  {
  'povendorid':'Distributor', 
  'popurchaseordertype':'Ownership', 
  }
  :
  {
  'povendorid':'Purchase Order Manufacturer', 
  'popurchaseordertype':'Purchase Order Ownership', 
  }
));

export const createPurData = (purdata, seid="") => {
  if (!purdata.podate) purdata.podate = getTodayString();
  if (!purdata.poreceivedate) purdata.poreceivedate = getTodayString();
  if (!purdata.popromisedate) purdata.popromisedate = getTodayString();
  if (!purdata.potax) purdata.potax = "0";
  if (!purdata.pocanceled) purdata.pocanceled = "No";
  if (!purdata.pocompleted) purdata.pocompleted = "No";
  if (!validatePOStatus(purdata.postatus)) purdata.postatus = "Initiated";
  if (!purdata.poseid && !!seid) purdata.poseid = seid;
  return getPurData(purdata);
};

const csvHeader = [
  { label: "PO number", key: "popurchaseorderid" },
  { label: "PO Date", key: "podate" },
  { label: "PO Distributor", key: "povendorid" },
  { label: "PO Type", key: "potype" },
  { label: "Hospital", key: "hospital" },
];
const csvHeaderNew = [
  { label: "PO number: ", key: "popurchaseorderid" },
  { label: "PO Type:", key: "potype" },
  { label: "PO Date: ", key: "podate" },
  { label: "Hospital: ", key: "hospital" },
];
export const convertPurDataToCSV = (purdata, header=null) => {
  if (header === null) header = (isNewDesign ? csvHeaderNew : csvHeader);
  const data = [header.reduce((d, h) => {
      d[h.key] = (h.key in purdata && purdata[h.key]) ? purdata[h.key] : '';
      return d;
    }, {})];
  return {header, data};
};

export const InventoryPOStatus = {
  Open: 'Open',
  AllShipped: 'All Shipped',
  // OrderReceived: 'Order Received', // Old Case - OrderReceived: 'Sent Vendor', removed and replaced by "Open"
  SomeShipped: 'Some Shipped',
  SomeReceived:'Some Received',
  AllReceived: 'All Received',
  BackOrder: 'Back Order',
};
export const InventoryPOStatusHospital = getSubModel(InventoryPOStatus, [
  'AllShipped', 'SomeShipped', 'SomeReceived', 'AllReceived', 'Open',
]);
export const validateInventoryPOStatus = validateModelItem(InventoryPOStatus);
export const getInventoryPOStatus = (inventoryDatas=[], ownership=null) => {
  if (validateOwnership(ownership) && isImpossibleInventory(ownership)) return null;
  let total = 0, preShip = 0, ship = 0, backOrd = 0, receive = 0, result;
  inventoryDatas.forEach(inv => {
    const status = inv.ireturnstatus ? 'Received' : inv.iproductstatus;
    total ++;
    if (status === 'Opens') preShip ++;
    else if (status === 'Shipped' || status === 'ShippedVendor') ship ++;
    else if (status === 'Received' || status === 'ReceivedVendor') receive ++;
    else backOrd ++;
  });
  if (total === 0) result = "Open";
  else if (total === ship) result = "AllShipped";
  else if (total === receive) result = "AllReceived";
  else if (receive > 0) result = "SomeReceived";
  else if (ship > 0) result = "SomeShipped";
  else if (backOrd > 0) result = "Open";
  else if (preShip > 0) result = "Open";
  else result = "Open";
  return result;
};

export const PoType = {
  NoCharge: 'No Charge',
  Standard: 'Standard',
};
export const validatePoType = validateModelItem(PoType);
export const getPoType = getModelMenu(PoType);

/**
 * check if Contract is not PO History
 * @param {Object} c 
 * @returns {Boolean}
 */
export const checkNotPOHistory = (c) => (getTemplate(c) !== "PurchaseOrderHistory");

