import React, { useState, useEffect } from "react";
import {  Typography, Accordion,AccordionDetails,AccordionSummary,
  TableContainer, Paper, Badge, Chip, DialogContentText, Drawer, Grid } from "@material-ui/core";
import { ExpandMore } from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';
import { formatDate } from "../../services/util";
import { checkComplianceStatus, checkComplianceStatusExpiration, 
  checkComplianceStatusSide } from "../../models/SurgicalEventCompliance";
import POCommentsGrid from './pocomments';
import ProductDetailViewTable from "../surgicalevent/product-detail-view-table";
import useStyles from "../surgicalevent/styles";


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/**
 * Detail View Slide Drawer 
 */
export default function DetailViewDrawer ({
  state, toggleDrawer, selectedEventData, poList, isPPH=false,
}) {
  // console.log("[DetailViewDrawer]", selectedEventData, poList);

  const classes = useStyles();

  const [expanded, setExpanded] = useState(0);
  const [subExpanded, setSubExpanded] = useState(0);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setSubExpanded(false);
  }

  const handleChangeAccordionSub = (panel) => (event, isExpanded) => {
    setSubExpanded(isExpanded ? panel : false);
  }


  useEffect(() => {
    if (state) {
      // console.log("[DetailViewDrawer] is opened.");
      setExpanded(0);
      setSubExpanded(0);
    }
  }, [state]);


  return (
    <React.Fragment  key={'right'} >
      <Drawer className={classes.customRoot} anchor={'right'} open={state} onClose={()=>{toggleDrawer(false)}}>           
        <div className="view-heading"><span>SE Id:</span> {selectedEventData && selectedEventData?.seid}</div>


       <DialogContentText component={'div'} className="modelStepDetai">
          <div className="viewDetailsSe">

        {
          isPPH &&
          <>
        <h2 className={classes.formSubHeading}>Patient Details</h2>
          <div className="grid-list grid-list-5">
            <div className="list-item">
              <div className="label">MRN</div>
              <div className="value">{selectedEventData && selectedEventData?.mrn}</div>
            </div>
            <div className="list-item">
              <div className="label">First Name</div>
              <div className="value">{selectedEventData && selectedEventData?.patientfirstname}</div>
            </div>
            <div className="list-item">
              <div className="label">Last Name</div>
              <div className="value">{selectedEventData && selectedEventData?.patientlastname}</div>
            </div>
            <div className="list-item">
              <div className="label">Gender</div>
              <div className="value">{selectedEventData && selectedEventData?.patientgender}</div>
            </div>
            <div className="list-item">
              <div className="label">DOB</div>
              <div className="value">{selectedEventData && formatDate(selectedEventData?.patientdob)}</div>
            </div>
          </div>
          </>
        }
         
        <h2 className={classes.formSubHeading}>Event Details</h2>
         <div className="grid-list grid-list-5">
          <div className="list-item">
            <div className="label">Date</div>
            <div className="value">{selectedEventData && formatDate(selectedEventData?.eventdate)}</div>
          </div>
          <div className="list-item">
            <div className="label">Procedure</div>
            <div className="value">{selectedEventData && selectedEventData?.surgicalprocedure}</div>
          </div>
          <div className="list-item">
            <div className="label">Side</div>
            <div className="value">{selectedEventData && selectedEventData?.surgicalside}</div>
          </div>
         </div>
        <h2 className={classes.formSubHeading}>Physician Details</h2>
         <div className="grid-list grid-list-5">
          <div className="list-item">
            <div className="label">Salutation</div>
            <div className="value">{selectedEventData && selectedEventData?.physiciansalutation}</div>
          </div>
          <div className="list-item">
            <div className="label">First Name</div>
            <div className="value">{selectedEventData && selectedEventData?.physicianfirstname}</div>
          </div>
          <div className="list-item">
            <div className="label">Last Name</div>
            <div className="value">{selectedEventData && selectedEventData?.physicianlastname}</div>
          </div>
        </div>
          
          {poList.length > 0 && 
            <div> 
              <div className={classes.productDetails}>
                <h2 className={classes.formSubHeading}>Total POs 
                  <Badge badgeContent={poList.length} color="primary" overlap="rectangular"></Badge>
                </h2>
              </div>
              
              <div className={classes.productAccordion}>
                 {poList.map(({payload: po},index)=>(
                <Accordion key={`po-${index}`} expanded={expanded === (index+1)} onChange={handleChangeAccordion((index+1))} className={classes.productMainAccordion}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      className="product-detail-accordion-head"
                    >
                      <Typography className={classes.accordionHeading}>
                        <span className="text-inactive">Purchase Order :</span> <span className="text-active">{po.purchaseorderdata.popurchaseorderid}</span> 
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails style={{backgroundColor: '#fff'}}>

                    <Grid container direction="row">

                    <Grid item style={{width: '100%'}}>
                      <h2 className={classes.formSubHeading}>PO Details</h2>
                      <div className="grid-list grid-list-3">
                        <div className="list-item">
                          <div className="label">PO Date</div>
                          <div className="value">{formatDate(po.purchaseorderdata.podate)}</div>
                        </div>
                        <div className="list-item">
                          <div className="label">PO Distributor</div>
                          <div className="value">{po.purchaseorderdata.povendorid}</div>
                        </div>
                        <div className="list-item">
                          <div className="label">Status</div>
                          <div className="value">{po.purchaseorderdata.postatus}</div>
                        </div>
                      </div>
                    </Grid>

                    <Grid item style={{width: '100%'}}>
                      { po.purchaseordercomment.length > 0 &&
                        <POCommentsGrid  comments={po.purchaseordercomment}/>
                      }
                    </Grid>

                    <Grid item style={{width: '100%'}}>
                    
                    {po.purchaseorderdetaildata.length > 0 && 
                      <div style={{width: '100%'}}> 
                        <div className={classes.productDetails}>
                          <h2 className={classes.formSubHeading}>Total Added Products
                          <Badge badgeContent={po.purchaseorderdetaildata.length} color="primary" overlap="rectangular"></Badge>
                          <span className={classes.productTableHeading}><span className="price-label">Total Price of Products:</span> ${po.purchaseorderdata.pototalamount}</span>
                          </h2>
                        </div>
                        
                        <div className={classes.productAccordion}>
                          {po.purchaseorderdetaildata.map(({ podproduct: obj, podnotes, podquantity }, index) => (
                            <Accordion 
                              key={index} 
                              expanded={subExpanded === (index+1)} 
                              onChange={handleChangeAccordionSub((index+1))} 
                              className={classes.productMainAccordion}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                className="product-detail-accordion-head"
                              >
                                <Typography className={classes.accordionHeading}>
                                  <span className="text-inactive" >Reference Number :</span> <span className="text-active">{obj.sepreferencenumber}</span> 
                                </Typography>
                                <Typography className={classes.accordionHeading}>
                                  <span className="text-inactive">Lot Code :</span> <span className="text-active">{obj.seplotcode}</span> 
                                </Typography>
                                <Typography className={classes.accordionHeading}>
                                  <span className="text-inactive">Qty :</span> <span className="text-active">{podquantity}</span> 
                                </Typography>
                                {
                                  po.purchaseorderdetaildata[index].pricechange === true
                                  && <Chip label="Price Changed" className="bg-info-price-change" />
                                }
                                {
                                  checkComplianceStatus({surgicalevent: selectedEventData, auditedproductdata: obj}) !== ''
                                  && <Chip label="Compliance" className="bg-info-light" />
                                }
                              </AccordionSummary>

                              <AccordionDetails>
                                <Typography component={'div'}>
                                  <TableContainer  className={classes.productTableContainer} component={Paper}>
                                    {
                                      po.purchaseorderdetaildata[index].pricechange === true &&
                                      <div className="price-change"> 
                                        <Alert severity="info" > The Price has been changed from the contract pricing please check before 
                                        Accepting the PO</Alert> 
                                      </div>
                                    }
                                    {
                                      checkComplianceStatusSide(selectedEventData, obj) &&  
                                      <Alert severity="info"><strong>Compliance</strong>: Product is the incorrect Side based on the Surgical Procedure</Alert> }
                                    {
                                      checkComplianceStatusExpiration(selectedEventData, obj) &&  
                                      <Alert severity="info"><strong>Compliance</strong>: Product is past expiration date</Alert> 
                                    }
                                    <ProductDetailViewTable 
                                      obj={obj}
                                      options={{
                                        hiddenParams: ['sepdistributor'],
                                        extraParams: [
                                          {label: 'Product Note', value: podnotes, className: classes.tableRowBorderTop}
                                        ],
                                        redParams: (po.purchaseorderdetaildata[index].pricechange ? 
                                          ['sepproductprice'] : []),
                                      }}
                                    />
                                  </TableContainer>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                          
                        </div> 
                      </div>
                    }

                    </Grid>

                    </Grid>

                    </AccordionDetails>
                </Accordion>
                 ))}{null}
                 
              </div> 
            </div>
          }
        </div>
        </DialogContentText>
      </Drawer>
    </React.Fragment>
  );
}
