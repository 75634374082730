import React, { useState, useRef } from "react";
import classNames from "classnames";
import { useReactToPrint } from 'react-to-print';
import { useParty, useStreamQueries } from "@daml/react";
import { Grid, Typography, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { PurchaseOrder, PurchaseOrderHistory } from "../../services/daml-modules1";
import { arrayToCsv, downloadCSV } from "../../services/download";
import { damlFetchRestockInventory } from "../../services/axios/daml-json-api";
import { getQuantityOnOrder } from "../../services/util";
import { useUserState } from "../../context/UserContext";
import useEvent from "../../hooks/useEvent";
import { convertPurDataToCSV } from "../../models/Po";
import { convertPODetailToCSV } from "../../models/PODetail";
import PurchaseOrderListContracts from "./purchaseorderlistcontracts1";
import PurchaseOrderPrint from "./purchaseorder-prints";
import PurchaseOrderPDF, { styles as pdfstyles } from "./purchaseorder-pdf";
import CreatePurchaseOrder from "./create-purchaseorder1";
import exportIcon from "../../icons/new-export-icon.svg";
import printIcon from "../../icons/new-print-icon.svg";
// import groupIcon from "../../icons/group-icon.svg";
import CustomPrints from "../surgicalevent/surgicalevent-prints";
import { handlePDFGenerator } from "../surgicalevent/surgicalevent-pdf";
import ExportPopover from "../surgicalevent/export-popover";
import useStyles from "../surgicalevent/styles";
// import useStyles1 from "./styles";


/**
 * Purchase Orders page in Hospital
 * @returns 
 */
export default function PurchaseOrderList() {

  const classes = useStyles();
  // const classes1 = useStyles1();

  const hospital = useParty();
  const { token } = useUserState();

  // Component used for React-to-Print
  const componentRef = useRef();

  const { contracts, loading } = useStreamQueries(PurchaseOrder, () => [{ hospital }]);
  const { contracts: contractsHistory } = useStreamQueries(PurchaseOrderHistory, () => [{ hospital }]);
  // console.log("[PurchaseOrderList]", contracts);

  const { onEventClear } = useEvent();

  const [isHistory, setHistory] = useState(false);
  // CreatePurchaseOrder
  const [contract, setContract] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [isSend, setIsSend] = useState(false);
  // checked POs
  const [checkedPO, setCheckedPO] = useState([]);
  // Popover when clicking Export button
  const [anchorEl, setAnchorEl] = useState(null);


  // Click "Create Purchase Order" in PO list page
  const handleCreatePO = () => {
    setContract({});
    setIsSend(true);
    setOpenModal(true);
    onEventClear();
  };

  const handleEditPO = async (c, send = false) => {
    c = await searchRestockForQoO({
      contract: c, token, hospital, 
    });
    setContract(c);
    setIsSend(send);
    setOpenModal(true);
    onEventClear();
  };

  /* const handleGroupClick = () => {
    console.log("[PurchaseOrderList] handleGroupClick");
  }; */

  const handlePrintClick = () => {
    console.log("[PurchaseOrderList] handlePrintClick", checkedPO);
    if (!checkedPO || checkedPO.length === 0) return;
    handlePrint();
  };

  const handleOpenPopover = (event) => {
    if (!checkedPO || checkedPO.length === 0) return;
    setAnchorEl(event.currentTarget);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  return (
    <>

      <Grid>

        <Grid container justifyContent="space-between">
          <Grid item xs>
            <Typography variant="h4" className={classes.newPageTitle}>Purchase Order</Typography>
          </Grid>
          <Grid item>
            {/* <Button
              className={classNames(classes.newCreateButton, classes.newIconButton, classes1.newIconButton)}
              variant="contained"
              onClick={handleGroupClick}
              startIcon={<img  alt="" src={groupIcon} />}
            /> */}
            <Button
              className={classNames(classes.newCreateButton, classes.newIconButton)}
              variant="contained"
              onClick={handlePrintClick}
              startIcon={<img alt="" src={printIcon} />}
            />
            <Button
              className={classNames(classes.newCreateButton, classes.newIconButton)}
              variant="contained"
              onClick={handleOpenPopover}
              startIcon={<img alt="" src={exportIcon} />}
            />
            <Button
              className={classes.newCreateButton}
              variant="contained"
              onClick={handleCreatePO}
              startIcon={<AddIcon />}
            >
              Create Purchase Order
            </Button>

            <ExportPopover
              anchor={anchorEl}
              setAnchor={setAnchorEl}
              handleExportCSV={() => handleExportCSV(checkedPO)}
              handleExportPDF={() => handleExportPDF(checkedPO)}
            />
          </Grid>
        </Grid>

          <PurchaseOrderListContracts
            allContracts={contracts}
            historyContracts={contractsHistory}
            isIncludeHistory={isHistory}
            setArchived={setHistory}
            setChecked={setCheckedPO}
            handleEditPO={(c) => handleEditPO(c, false)}
            handleSendPO={(c) => handleEditPO(c, true)}
            loading={(loading)}
          />
        

      </Grid>

      <CreatePurchaseOrder
        open={openModal}
        setOpen={setOpenModal}
        contract={contract}
        contracts={contracts}
        isSend={isSend}
      />

      <CustomPrints
        ref={componentRef}
        contracts={checkedPO}
        Component={PurchaseOrderPrint}
      />

    </>
  );
}

const getExportData = (checkedPO) => {
  if (!checkedPO || !checkedPO.length) return {};
  const filename = `${checkedPO[0].purchaseorderdata.popurchaseorderid}${(checkedPO.length > 1) ? `(+${checkedPO.length - 1})` : ''}`;
  const runningPOs = [...checkedPO];
  return { filename, runningPOs };
};

export const handleExportCSV = async (checkedPO) => {
  const { filename, runningPOs } = getExportData(checkedPO);
  let header, data, csvData = '';
  runningPOs.forEach(({ purchaseorderdata, purchaseorderdetaildata, hospital }) => {
    ({ header, data } = convertPurDataToCSV({ ...purchaseorderdata, hospital }));
    // customize as redesign
    csvData = csvData + `\n\n\n\n,,${header[0].label} ${data[0][header[0].key]},${header[1].label} ${data[0][header[1].key]}\n`;
    csvData = csvData + `,,${header[2].label} ${data[0][header[2].key]},${header[3].label} ${data[0][header[3].key]}\n\n`;
    ({ header, data } = convertPODetailToCSV(purchaseorderdetaildata));
    csvData = csvData + '\n' + arrayToCsv(header, data);
  });
  await downloadCSV(csvData, filename);
};

export const handleExportPDF = async (checkedPO) => {
  const { filename, runningPOs } = getExportData(checkedPO);
  await handlePDFGenerator({ contracts: runningPOs, fileName: filename, Component: PurchaseOrderPDF, style: pdfstyles });
};

/**
 * Search Restock Inventory with PO contract to fill out QoO
 * @param {Object} contract 
 * @param {String} token 
 * @param {String} hospital 
 * @param {String} vendor 
 * @returns {Object}
 */
export const searchRestockForQoO = async ({ 
  contract, token, hospital, vendor, 
}) => {
  // console.log("[searchRestockForQoO]", contract);
  if (contract && typeof contract === 'object' && contract.payload) {
    // If PO is created from Surgical Event then product quantity should be 1
    if (!!contract.payload.purchaseorderdata?.poseid) {
      contract.payload.purchaseorderdetaildata = contract.payload.purchaseorderdetaildata?.map(data => {
        data.qoo = 1;
        return data;
      });
    }
    else {
      // Get QoO from RestockInventory
      const restock = await damlFetchRestockInventory({
        token, hospital, vendor, popurchaseorderid: contract.payload.purchaseorderdata?.popurchaseorderid,
      });
      if (restock) {
        // console.log("[searchRestockForQoO]", restock);
        // Set QoO per podproduct of PO contract
        contract.payload.purchaseorderdetaildata = contract.payload.purchaseorderdetaildata?.map(data => {
          const matchedProduct = restock.payload.inventorydata.find(c => c && c.iproduct.id === data.podproduct.id);
          if (matchedProduct) {
            data.qoo = `${getQuantityOnOrder(matchedProduct, { isfloor: false })}`;
          }
          return data;
        });
      }
    }
  }
  return contract;
};
