import React from "react";
import classNames from "classnames";
import { Grid, Typography } from "@material-ui/core";
import ModalDialog from '../../components/Modals/NewGeneralModal';
import patientIcon from "../../icons/new-se-patient-icon.svg";
import useStyles from "../surgicalevent/styles";
import AuditInvoice from './auditLogInvoice';
import useStyles1 from "../purchaseorder/styles";

/**
 * Dialog when Create or Edit Purchase Order
 * @param {Boolean} open
 * @param {Function} setOpen
 * @param {Object} contract
 */
export default function AuditDetailInvoice({
  open, setOpen, contract = {},
}) {

  const classes = useStyles();
  const classes1 = useStyles1();
  const invData = contract.payload;

  return (
    <>

      <ModalDialog
        open={open}
        setOpen={setOpen}
        title={(
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            className={classNames(classes.newSETitlePanel, classes1.newPOTitlePanel)}
          >
            <img src={patientIcon} alt="" />
            <Typography variant="inherit" component="span" className="label" >
              Audit Log Details -
            </Typography>
            <Typography variant="inherit" component="span" className="label" >
              Create Invoice
            </Typography>
          </Grid>

        )}
        content={(
          <Grid container
            direction="row"
            justifyContent="space-between"
            className={classes1.createPanel}
          >
            <Grid item xs={12}>
              {
                <AuditInvoice
                  invData={invData}
                
                />
              }
            </Grid>
          </Grid>

        )}
        width={1250}
        height={700}
      />

    </>
  );
}