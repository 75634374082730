import React, { useState } from "react";
import { Grid, FormControlLabel, Checkbox } from "@material-ui/core";
import useStyles from "./styles";


/**
 * Select Search with multiple checked
 * @param {Array} options 
 * @param {Array} params 
 * @param {Function} setParams
 * @param {Number} maxColCnt Max Count of Columns
 * @returns 
 */
export default function SelectSearch({
  options = [],
  params = [],
  setParams = null,
  maxColCnt = 4,
}) {

  const classes = useStyles();

  const [values, setValues] = useState(params);

  const getSubLists = () => {
    if (options.length > maxColCnt) {
      const firstCols = (options.length > (2 * maxColCnt) ? options.length / 2 : maxColCnt);
      const firstLen = options.length - firstCols;
      return {
        column: 2,
        first: options.slice(0, firstLen),
        second: options.slice(firstCols, options.length),
        third: options.slice(firstLen, firstCols),
      }
    }
    else return { column: 1, first: options };
  };
  const { column, first, second, third } = getSubLists();


  const getCheckItems = (arr) => {
    return (
      <>
        {
          arr.map(({label, value}, i) => (
            <FormControlLabel 
              key={i}
              control={<Checkbox checked={values.includes(value)} />}
              label={label}
              value={value}
              className={classes.searchCheckbox}
              onChange={(e) => handleChecked(e, value)}
              labelPlacement="end"
            />
          ))
        }
      </>
    );
  };

  const handleChecked = (e, val) => {
    let newVals = [...values];
    const index = newVals.indexOf(val);
    if (e.target.checked === true) {
      if (index === -1) newVals.push(val);
    } else {
      if (index > -1) newVals.splice(index, 1);
    }
    setValues(newVals);
    setParams(newVals);
  };


  return (
    <>

      {
        column === 2
        ?
          <>
            <Grid container>
              <Grid item xs={6}>
                { getCheckItems(first) }
              </Grid>
              <Grid item xs={6}>
                { getCheckItems(second) }
              </Grid>
            </Grid>
            {
              (third && third.length > 0) &&
              <Grid>
                { getCheckItems(third) }
              </Grid>
            }
          </>
        :
          <Grid className="one-column">
            { getCheckItems(first) }
          </Grid>
      }

    </>
  );
}
