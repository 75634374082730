import React, { useState, useEffect } from "react";
import { getState } from "../../store";
import Query from "./Query";


/**
 * Component for DAML Data Query
 * @param {Object} template
 * @param {Object} searchParams
 * @param {String} indexKey
 * @param {Function} setValues
 * @returns 
 */
export default function DamlQuery({
  template,
  searchParams={},
  indexKey,
  setValues=()=>{},
}) {

  let states = null;
  if (typeof indexKey === 'string') {
    states = getState().queryDAML[indexKey];
  }

  const [queryResult, setQueryResult] = useState(null);
  // console.log("[DamlQuery]", indexKey, queryResult, states);


  useEffect(() => {
    if (!!states) {
      setQueryResult(states);
    }
  }, [states]);

  useEffect(() => {
    if (!!queryResult && typeof setValues === 'function') {
      setValues(queryResult);
    }
  }, [queryResult, setValues]);


  return (
    <>
      {
        !!states ?
        <></>
        :
        <Query 
          template={template}
          searchParams={searchParams}
          indexKey={indexKey}
          setValues={setQueryResult}
        />
      }
    </>
  );
}
