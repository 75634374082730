import React, { useEffect } from "react";
import { useQuery } from "@daml/react";
import { dispatch } from "../../store";
import { setDAMLSearch } from "../../store/actions/generalActions";


/**
 * Component using useQuery
 * @param {Object} template
 * @param {Object} searchParams
 * @param {String} indexKey
 * @param {Function} setValues
 * @returns 
 */
export default function Query({
  template,
  searchParams={},
  indexKey,
  setValues=()=>{},
}) {

  const queryResult = useQuery(template, () => searchParams);
  console.log("[Query]", template.templateId, searchParams, indexKey, queryResult);

  useEffect(() => {
    if (queryResult.loading === false) {
      setValues(queryResult);
      if (typeof indexKey === 'string') {
        dispatch(setDAMLSearch({ key: indexKey, value: queryResult }));
      }
    }
  }, [queryResult, indexKey, setValues]);

  return (<></>);
}
