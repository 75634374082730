import React, { useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import { useLedger, useParty } from "@daml/react";
import { Button, Grid, Typography } from "@material-ui/core";
import { useDamlState } from "../../context/DamlContext";
import { useLayoutDispatch, setLoading, unsetLoading } from "../../context/LayoutContext";
import { getTodayString, getQuantityOnOrder } from "../../services/util";
import ModalDialog from '../../components/Modals/NewGeneralModal';
import SnackbarModal from "../../components/Modals/SnackbarModal";
import { createInventoryData, requiredInventoryData } from "../../models/InventoryData";
import { PRODUCT_DELETE_EVENT, PRODUCT_UPDATE_EVENT, PRODUCT_ADD_EVENT, EVENT_REQUIRED, EVENT_VERIFIED, INVENTORY_CONFIRM_EVENT } from "../../store/actions/constants";
import useProduct, { useItemDetails } from "../../hooks/useProduct";
import { useAccountInventory } from "../../hooks/useInventory";
import useEvent from "../../hooks/useEvent";
import useStyles from "../surgicalevent/styles";
import { defaultProduct } from "../surgicalevent/edit-product1";
import { AddedProductList } from "../surgicalevent/add-edit-product1";
import { createAccountInventoryHospital } from "./handle";
import AddInventory from "./add-inventory";
import useStyles1 from "../purchaseorder/styles";
import useStyles2 from "./styles";
import { useTranslation } from 'react-i18next';



/**
 * Create Inventory Title Component
 * @param {Object} classes 
 * @returns 
 */
const InvTitle = ({
  classes,
}) => {

  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.newInvTitlePanel}
    >
      <Typography variant="inherit" component="span" className="label" >
        {t('inventory.account.addinventory')}
      </Typography>
    </Grid>
  );
};

const productPattern = [
  {
    label: 'Product Code', style: { paddingTop: 0 },
  },
  {
    key: 'sepudi', width: 306, colSpan: 2,
  },
  {
    key: 'sepreferencenumber', width: 306, colSpan: 2,
  },
  {
    key: 'seplotcode', width: 222, colSpan: 2,
  },
  {
    label: 'Product Details',
  },
  {
    key: 'sepproductmanufacturer', width: 152, style: { width: '167px' },
    key1: 'sepdistributor', width1: 152, disabled1: true,
  },
  {
    key: 'sepproductexpiration', width: 152,
    key1: 'sepownership', width1: 152,
  },
  {
    key: 'sepproductname', width: 333, colSpan: 2,
  },
  {
    key: 'sepproductdescription', width: 333, colSpan: 2,
  },
  {
    key: 'sepproductwaste', width: 152,
    key1: 'sepuom', width1: 143,
  },
  {
    key: 'sepproductprice', width: 152,
    key1: 'sepproductside', width1: 152,
  },
  {
    key: 'sepproducttype', width: 152,
    key1: 'iquantityunit', width1: 152, type1: 'number', label1: 'Quantity',
  },
];

const listPattern = [
  {
    key: 'sepudi', key1: 'sepproductprice', details: 'Product Details', detailIsCol: false,
  },
  {
    key: 'sepreferencenumber', key1: 'sepuom',
  },
  {
    key: 'idistributornumber', label: "Distributor #", key1: 'sepproducttype',
  },
  {
    key: 'sepproductmanufacturer', key1: 'sepownership',
  },
  {
    key: 'sepdistributor', key1: 'sepproductside',
  },
  {
    key: 'sepproductname', key1: 'iquantity', label1: 'QoH : ' ,  style1: { right: '10px' },
  },
  {
    key: 'sepproductdescription', key1: 'ilocation', label: "Location : ",
  },
  {
    key: 'sepproductexpiration',
    key1: 'ibin', label1: 'Bin# : '
  },
  {
    key: 'ireceiveddate', label : 'Received :',
    key1: 'idoh', label1: 'DoH : ',
  },
];


/**
 * Create Inventory Body Panel Component
 * @param {Object} classes 
 * @param {Object} classes2 
 * @returns 
 */
const InvPanel = ({
  classes, classes2, options ={}
}) => {
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        className={classes.createPanel}
      >
        <Grid item>
          <AddInventory />
        </Grid>

        <Grid item>
          <Grid item>
            <AddedProductList
              className={classes2.newAddedProductPanel}
              options={{
                isShowFooter: true,
                keyProduct: 'iproduct',
                isEnableEditProduct: true,
                isEnableDeleteProduct: true,
                isEnableCompliance: false,
                edit: {
                  isEnableCompliance: false,
                  designPattern: productPattern,
                },
              }}
              listPattern={listPattern}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

/**
 * Create Inventory Action Buttons Component
 * @param {Object} classes 
 * @param {Object} classes2
 * @returns 
 */
const InvActionButtons = ({
  classes, classes2, setOpen,
}) => {


  const { product, clearProduct } = useProduct();
  const { accountInventoryData, setAccountInventoryErrors } = useAccountInventory();
  const { onEventStart, onEventClear, onEventReject } = useEvent();

  // handle to click "Clear" button
  const handleClearClick = () => {
    // default Product
    clearProduct(defaultProduct);
    onEventClear();
  };

  // handle to click "Add" button
  const handleAddClick = () => {
    //  check required fields in InventoryData
    let newErrors = requiredInventoryData(accountInventoryData);
    if (Object.keys(newErrors).length > 0) {
      setAccountInventoryErrors({ ...newErrors });
      onEventReject();
      return;
    }

    const newinvdata = createInventoryData({
      ...accountInventoryData,
      iproduct: product
    });
    clearProduct(newinvdata.iproduct);
    onEventStart(PRODUCT_ADD_EVENT);
  };

  // handle to click "Cancel" button
  const handleCancelClick = () => {
    onEventClear();
    setOpen(false);
  };

  // handle to click "Receive" button
  const handleReceiveClick = () => {
    onEventStart(INVENTORY_CONFIRM_EVENT);
  };


  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClearClick}
        className={classNames(classes.newCompleteBtn, classes.newInsideAddBtn, "secondary-color", classes2.completeBtn)}
      >
        Clear
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddClick}
        className={classNames(classes.newCompleteBtn, classes.newInsideAddBtn, classes2.completeBtn, classes2.addBtn)}
      >
        Add
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleCancelClick}
        className={classNames(classes.newCompleteBtn, "secondary-color")}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleReceiveClick}
        className={classes.newCompleteBtn}
      >
        SAVE
      </Button>
    </>
  );
};

/**
 * Dialog when click "+" to Create Inventory
 * @param {Boolean} open
 * @param {Function} setOpen
 */
export default function CreateInventory({
  open, setOpen,
}) {

  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();

  const { role, roleCid, userlistPayload } = useDamlState();

  const layoutDispatch = useLayoutDispatch();

  const ledger = useLedger();
  const party = useParty();
  // Hospital
  const hospital = (role === 'Hospital' ? party : null);
  const { t } = useTranslation();
  const { setAll: setDefault, product: productItems, isChanged, clearProduct } = useProduct();
  const { product: productItems1 } = useProduct(true);
  const { details, setDetails, itemIndex } = useItemDetails();
  const { accountInventoryData, setAccountInventoryData } = useAccountInventory();
  const { event, status, onEventRun, onEventEnd } = useEvent();

  // Toast
  const [openToast, setOpenToast] = useState(false);
  const [toastContent, setToastContent] = useState('');

  const [snackbarModel, setSnackbarModel] = useState(false);


  const handleCloseRequest = () => {
    if (isChanged) {
      setSnackbarModel(true);
    }
    else {
      handleClose();
    }
  };

  // handle to close
  const handleClose = () => {
    setSnackbarModel(false);
    setOpen(false);
  };


  // handle to click "Receive" button in confirm dialog
  const handleConfirmAction = useCallback(async () => {

    if (!details.length) {
      onEventEnd();
      return;
    }

    // spinner
    setLoading(layoutDispatch);
    onEventRun();

    for (let i in details) {
      const invdata = details[i];
      const res = await createAccountInventoryHospital({
        ledger,
        roleCid,
        inventorydata: invdata,
        userlistPayload,
      });
      if (res) {
        setToastContent(`Successfully Added in Inventory`);
        setOpenToast(true);
      }
    }

    unsetLoading(layoutDispatch);
    onEventEnd();

    setOpen(false);

  }, [layoutDispatch, onEventRun, onEventEnd, ledger, roleCid, userlistPayload, setOpen, details]);

  // Click "Add" button
  const addCreateInventory = useCallback(() => {
    const defaultInventoryData = {
      iproductstatus: 'Received',
      ireceiveddate: getTodayString(),
    };

    // search in old details
    let newdetails = [...details];
    const newinvdata = createInventoryData({
      ...defaultInventoryData,
      ...accountInventoryData,
      iproduct: productItems
    });
    /**
     * There are 2 options when creating new Account Inventory.
     * 1st : Set iquantity as 1, then please comment the following lines.
     * 2nd : Set iquantityunit as 1, instead of iquantity.
     */
    newinvdata.iquantityunit = 1;
    // newinvdata.iquantity = productItems.iqoh ?? getQuantityOnOrder(newinvdata);
    newinvdata.iquantity = productItems.iquantity ?? getQuantityOnOrder(newinvdata);
    newdetails.push(newinvdata);

    // add into details
    setDetails(newdetails);
    clearProduct(defaultProduct);

    onEventEnd();

  }, [productItems, accountInventoryData, onEventEnd, clearProduct, details, setDetails]);

  const handleInvDeleteProduct = useCallback(() => {
    setLoading(layoutDispatch);
    onEventRun();
    const temp = [...details];
    temp.splice(itemIndex, 1);
    setDetails(temp);
    unsetLoading(layoutDispatch);
    setToastContent("Product Successfully removed")
    setOpenToast(true)
    onEventEnd();
  }, [details, itemIndex, setDetails, onEventRun, onEventEnd, layoutDispatch]);

  const handleInvUpdateProduct = useCallback(() => {
    setLoading(layoutDispatch);
    onEventRun();
    const temp = [...details]
    temp[itemIndex] = {
      ...temp[itemIndex],
      iproduct: {
        ...temp[itemIndex]?.iproduct,
        sepudi: productItems1.sepudi,
        sepreferencenumber: productItems1.sepreferencenumber,
        seplotcode: productItems1.seplotcode,
        sepproductmanufacturer: productItems1.sepproductmanufacturer,
        sepproductexpiration: productItems1.sepproductexpiration,
        sepownership: productItems1.sepownership,
        sepproductname: productItems1.sepproductname,
        sepproductdescription: productItems1.sepproductdescription,
        sepproductwaste: productItems1.sepproductwaste,
        sepuom: productItems1.sepuom,
        sepproductprice: productItems1.sepproductprice,
        sepproductside: productItems1.sepproductside,
        sepproducttype: productItems1.sepproducttype,
      },
      // iquantity: productItems1.iqoh ?? getQuantityOnOrder(productItems1),
      iquantity: productItems1.iquantity ?? getQuantityOnOrder(productItems1),
      iquantityunit: productItems1.iquantityunit,
    }
    setDetails(temp);
    unsetLoading(layoutDispatch);
    onEventEnd();
  }, [details, productItems1, itemIndex, setDetails, onEventRun, onEventEnd, layoutDispatch]);

  useEffect(() => {
    if (!open) return;
    setDefault({
      details: [],
      hospital,
      product: defaultProduct,
    });
    setAccountInventoryData({
    });
    onEventEnd();
  }, [setDefault, setAccountInventoryData, hospital, open, onEventEnd]);

  useEffect(() => {
    if (!open) return;
    if (event) {
      if (status === EVENT_REQUIRED || status === EVENT_VERIFIED) {
        console.log("[CreateInventory] On Start Event", event);
        if (event === PRODUCT_ADD_EVENT && status === EVENT_VERIFIED) {
          addCreateInventory();
        }
        else if (event === INVENTORY_CONFIRM_EVENT && status === EVENT_REQUIRED) {
          // confirm handler
          handleConfirmAction();
        }
        else if (event === PRODUCT_UPDATE_EVENT && status === EVENT_VERIFIED) {
          handleInvUpdateProduct();
        }
        else if (event === PRODUCT_DELETE_EVENT && status === EVENT_REQUIRED) {
          handleInvDeleteProduct();
        }
      }
    }
  }, [open, event, status, addCreateInventory, handleConfirmAction, handleInvUpdateProduct, handleInvDeleteProduct]);


  return (
    <>
      <ModalDialog
        className={classes2.createInventory}
        open={open}
        setOpen={setOpen}
        handleCloseRequest={handleCloseRequest}
        title={(
          <InvTitle
            classes={classes2}
          />
        )}
        content={(
          <InvPanel
            classes={classes1}
            classes2={classes2}
          />
        )}
        actions={(
          <InvActionButtons
            classes={classes}
            classes2={classes2}
            setOpen={setOpen}
          />
        )}
      />

      <SnackbarModal
        open={openToast}
        setOpen={setOpenToast}
        title=""
        content={toastContent}
        width={330}
        height={88}
        className={classes.toastMedium}
      />
      <SnackbarModal
        open={snackbarModel}
        setOpen={setSnackbarModel}
        type="warning"
        title={'Warning'}
        content={t('warnings.surgicalevent.exitmessage')}
        vertical="center"
        horizontal="center"
        width={345}
        height={130}
        transition="fade"
        className={classNames(classes.toastLarge, classes.toastWarning)}
        options={{
          delay: 500,
          
        }}
        actions={[
          {
            label: 'Yes',
            handle: () => {
              handleClose();
            },
          },
          {
            label: 'No',
            handle: () => {
              setSnackbarModel(false);
            },
          }
        ]}
      />
    </>
  );
}
