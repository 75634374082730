import React, { useState, useCallback } from "react";
import classNames from "classnames";
import { useLedger } from "@daml/react";
import { ButtonGroup, Button } from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CustomTooltip from "../../components/Tooltip/CustomTooltip";
import { UOM } from "../../models/Product";
import { InventoryProductStatusHospital, InventoryProductStatusVendor } from "../../models/InventoryData";
import { contractFilter1 } from "../../services/daml-filters";
import { useItemDetails } from "../../hooks/useProduct";
import { useAccountInventory } from "../../hooks/useInventory";
import { useDamlState } from "../../context/DamlContext";
import { useLayoutDispatch, setLoading, unsetLoading } from "../../context/LayoutContext";
import withLoading from "../../hoc/withLoading";
import withFilters from "../../hoc/withFilters";
import Contracts from "../../components/Contracts/Contracts1";
import SnackbarModal from "../../components/Modals/SnackbarModal";
import { handleCheckedContract } from '../surgicalevent/surgicaleventlistcontracts1';
import { ProductDetailTable } from "../surgicalevent/add-edit-product1";
import NewRequest from './newrequest1';
import EditInventory from './edit-inventory1';
import { removeInventoryData } from "./handle";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "../purchaseorder/styles";
import useStyles2 from "./styles";
import { useTranslation } from 'react-i18next';



/**
 * Custom Header Cell with Checkbox
 * @param {Function} handleReturn 
 * @param {Function} handleEdit 
 * @param {Function} handleDelete 
 * @returns 
 */
const CustomCheckboxHeaderCell = ({
  handleReturn, handleEdit, handleDelete, 
}) => {
  
  const classes = useStyles2();

  const { details } = useItemDetails();

  return (
    <>
      {
        (details.length === 0)
        ?
          <Button
            className={classes.newActionCell}
            variant="contained"
            startIcon={<ArrowDropDownIcon />}
          />
        :
        <CustomTooltip 
          title={(
            <ButtonGroup orientation="vertical" >
              <Button 
                onClick={handleReturn} 
                disabled={false}
              >
                Return
              </Button>
              <Button 
                onClick={handleEdit} 
                disabled={false}
              >
                Edit
              </Button>
              <Button 
                onClick={handleDelete} 
                disabled={false}
              >
                Delete
              </Button>
            </ButtonGroup>
          )}
          classes={{ arrow: classes.newTooltipArroww, tooltip: classes.newAddedTooltip }} 
          arrow={true}
        >
          <Button
            className={classes.newActionCell}
            variant="contained"
            startIcon={<ArrowDropDownIcon />}
          />
        </CustomTooltip>
      }
    </>
  );
};

const listPattern = [
  {
    key: 'sepudi', details: 'Product Details', key1: 'sepproductprice',
  },
  {
    key: 'sepreferencenumber', key1: 'sepuom',
  },
  {
    key: 'sepproductmanufacturer', key1: 'sepproducttype',
  },
  {
    key: 'sepdistributor', key1: 'sepownership',
  },
  {
    key: 'sepproductname', key1: 'sepproductside',
  },
  {
    key: 'sepproductdescription', key1: 'iquantity', label1: 'QoH: ',
  },
  {
    key: 'sepproductexpiration', key1: 'seplocation', label1: 'Location: ',
  },
  {
    key: 'ireceiveddate', label: 'Received: ', key1: 'idoh', label1: 'DOH: ',
  },
];

/**
 * Detail Panel
 */
const getDetailPanelContent = ({ row }) => (
  <ProductDetailTable
    product={{
      ...row?.contract?.payload,
      ...row?.contract?.payload?.iproduct,
    }}
    listPattern={listPattern}
    isEnableCompliance={false}
  />
);

/**
 * House Inventory Contracts
 * @param {Object} searchParams 
 * @param {Array} allContracts 
 * @param {Object} options 
 * @returns 
 */
function HouseInventoryContracts({
  searchParams = {},
  allContracts=[], 
  options={}, 
}) {

  options = {
    isCheck: true,
    isCheckboxHeaderCell: true,
    ...options,
  };
  const { t } = useTranslation();

  const classes = useStyles();
  const classes1 = useStyles1();
  
  allContracts = allContracts.map((c, i) => ({
    id: i, 
    payload: {
      ...c, 
      index: i, 
    } 
  }));

  let contracts = contractFilter1(allContracts, searchParams);

  const ledger = useLedger();
  const { role, roleCid, party, userParty, userlistPayload } = useDamlState();

  const layoutDispatch = useLayoutDispatch();

  // checked Inventory
  const { details, setDetails } = useItemDetails();
  const { accountInventoryData, setAccountInventoryData } = useAccountInventory();

  // InventoryProductStatus Model, Items, Options for each role
  const InventoryProductStatusModel = role === 'Hospital' ? InventoryProductStatusHospital : InventoryProductStatusVendor;
  const statusOptions = Object.keys(InventoryProductStatusModel).map(value => ({
    label: InventoryProductStatusModel[value], value: value 
  }));

  // console.log("[HouseInventoryContracts]", searchParams, contracts);


  // Confirm to Return Request Modal
  const [openReturnModal, setOpenReturnModal] = useState(false);
  // Confirm to Edit Modal
  const [openEditModal, setOpenEditModal] = useState(false);
  // Delete Product Toast
  const [openToast, setOpenToast] = useState(false);


  // handle to check rows
  const handleChecked = (e) => {
    handleCheckedContract(e, contracts, setDetails, 'id');
  };

  // handle to click "Return"
  const handleReturn = useCallback(() => {
    if (details.length === 0) return;
    setOpenReturnModal(true);
  }, [details]);

  // handle to click "Edit"
  const handleEdit = useCallback(() => {
    if (details.length === 0) return;
    setOpenEditModal(true);
  }, [details]);

  // handle to click "Delete"
  const handleDeleteClick = useCallback(() => {
    if (details.length === 0) return;
    setOpenToast(true);
  }, [details]);

  // handle to delete items
  const handleDelete = useCallback(async () => {

    // spinner
    setLoading(layoutDispatch);

    if (details.length) {
      const defaultUser = userParty ? userParty : party;
      let ContractId = accountInventoryData.contractId, res;

      for (let i in details) {
        const deleteInventory = details[i];

        res = await removeInventoryData({
          ledger, 
          ContractId, 
          inventorydata: deleteInventory,
          inventoryindex: deleteInventory.index,
          newitemprequestuser: defaultUser,
          newitemprequestmessage: "",
          roleCid,
          userlistPayload
        });

        if (res) {
          ContractId = res.contractId;
        }
      }

      setAccountInventoryData({
        ...accountInventoryData,
        ...res?.inventoryproduct,
        contractId: ContractId,
        inventorydata: res?.inventorydata,
      });

    }

    unsetLoading(layoutDispatch);

    setOpenToast(false);
    
  }, [details, ledger, roleCid, userlistPayload, userParty, party, layoutDispatch, 
    accountInventoryData, setAccountInventoryData]);


  return (
    <>
      <Contracts
        contracts={contracts}
        columns={[
          [`${t('main.lotcode.label')}`, "payload.iproduct.seplotcode", {
            search: {
              type: "AdaptiveSearch",
              name: "iproduct.seplotcode",
              getValue: (c) => c.payload.iproduct.seplotcode,
              contracts: allContracts,
            },
            options: {
              flex: 0.9,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            }
          }],
          ["Expiration", "payload.iproduct.sepproductexpiration", {
            search: {
              type: "DateSearch",
              name: "iproduct.sepproductexpiration",
            },
            options: {
              flex: 1.1,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            },
          }],
          ["Price", "payload.iproduct.sepproductprice", {
            search: {
              type: "AdaptiveSearch",
              name: "iproduct.sepproductprice",
              getValue: (c) => c.payload.iproduct.sepproductprice,
              contracts: allContracts,
            },
            options: {
              beforeText: '$',
              flex: 0.7,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            }
          }],
          [`${t('main.uom.lable')}`, "payload.iproduct.sepuom", {
            type: 'render',
            search: {
              type: "SelectSearch",
              name: "iproduct.sepuom",
              options: Object.keys(UOM).map(value => ({ label: UOM[value], value: value })),
            },
            options: {
              flex: 0.6,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            }
          }],
          ["PO Number", "payload.ipurchaseordernumber", {
            search: {
              type: "AdaptiveSearch",
              name: "ipurchaseordernumber",
              getValue: (c) => c.payload.ipurchaseordernumber,
              contracts: allContracts,
            },
            options: {
              flex: 1,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            },
          }],
          ["Manufacturer", "payload.iproduct.sepproductmanufacturer", {
            search: {
              type: "AdaptiveSearch",
              name: "iproduct.sepproductmanufacturer",
              getValue: (c) => c.payload.iproduct.sepproductmanufacturer,
              contracts: allContracts,
            },
            options: {
              flex: 0.9,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            },
          }],
          ["DOH", "payload.idoh", {
            search: {
              type: "AdaptiveSearch",
              name: "idoh",
              getValue: (c) => c.payload.idoh,
              contracts: allContracts,
            },
            options: {
              flex: 0.7,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            },
          }],
          ["Location", "payload.iproduct.seplocation", {
            search: {
              type: "AdaptiveSearch",
              name: "iproduct.seplocation",
              getValue: (c) => c.payload.iproduct.seplocation,
              contracts: allContracts,
            },
            options: {
              flex: 0.8,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            },
          }],
          [`${t('inventory.account.bin')}`, "payload.ibin", {
            search: {
              type: "AdaptiveSearch",
              name: "ibin",
              getValue: (c) => c.payload.ibin,
              contracts: allContracts,
            },
            options: {
              flex: 0.5,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            },
          }],
          ["Status", "payload.iproductstatus", {
            search: {
              type: "SelectSearch",
              name: "iproductstatus",
              options: statusOptions,
            },
            options: {
              flex: 0.9,
              headerAlign: 'center',
              align: 'center',
              headerClassName: classes1.customText,
            },
          }],
        ]}

        options={{
          sortKey: 'id',
          checkbox: options.isCheck,
          height: '450px', 
          headerHeight: 38,
          rowHeight: 38,
          renderCheckboxHeaderCell: options.isCheckboxHeaderCell ? () => (
            <CustomCheckboxHeaderCell 
              handleReturn={handleReturn} 
              handleEdit={handleEdit} 
              handleDelete={handleDeleteClick} 
            />
          ) : undefined,
          checkboxCellWidth: 78,
          getDetailPanelContent: getDetailPanelContent,
        }}

        handleChecked={handleChecked}
      />

      <NewRequest
        open={openReturnModal}
        setOpen={setOpenReturnModal}
      />

      <EditInventory
        open={openEditModal}
        setOpen={setOpenEditModal}
      />

      <SnackbarModal
        open={openToast}
        setOpen={setOpenToast}
        type="warning"
        title=""
        content={t('warnings.inventory.houseinventory.message')}
        vertical="center"
        horizontal="center"
        width={345}
        height={150}
        transition="fade"
        className={classes.toastLarge}
        options={{
          validateULE: true,
           }}
        actions={[
          {
            label: 'Yes',
            handle: handleDelete,
          },
          {
            label: 'No',
          }
        ]}
      />
    </>
  );
}

const HouseInventoryContractsWithLoading = withLoading(HouseInventoryContracts);
export default withFilters(HouseInventoryContractsWithLoading)([
  { name: "iproduct.seplotcode", label: "Lot/SN" },
  { name: "iproduct.sepproductexpiration", label: "Expiration" },
  { name: "iproduct.sepproductprice", label: "Price" },
  { name: "iproduct.sepuom", label: "UoM" },
  { name: "ipurchaseordernumber", label: "PO Number" },
  { name: "iproduct.sepproductmanufacturer", label: "Manufacturer" },
  { name: "idoh", label: "DOH" },
  { name: "iproduct.seplocation", label: "Location" },
  { name: "ibin", label: "Bin" },
  { name: "iproductstatus", label: "Status" },
]);