import React, { Fragment, useState, useEffect } from 'react';
import { Box, IconButton, Collapse, Typography, Paper, Table, TableBody, 
  TableCell, TableContainer, TableHead, TableRow, TableFooter } from "@material-ui/core";
import { getValueByCol, renderCell, sortContracts } from "./functions";
import { renderActionCells, ToolTipCell } from "./ActionCell";
import { renderHeaderCell } from "./HeaderCell";
import plusCircleIcon from "../../icons/plus-circle.svg";
import minusCircleIcon from "../../icons/minus-circle.svg";
import { getState,dispatch } from "../../store";
import { setToggleStage } from '../../store/actions/generalActions';
import { useStyles } from "./styles";
import { CustomPagination } from "./CustomPagination";


function Row({
  row={}, columns=[], contract={}, collapse=false, collapseTitle="", collapseParams={}, options={},
}) {

  options = {
    extraIcon: null,
    isShowExtraIcon: false,
    ...options,
  };

  const [open, setOpen] = useState(false);
  const classes = useStyles();
  let collapseData = (collapse && 'collapse' in row) ? row['collapse'] : [];
  const isCollapse = collapse && (collapseData.length > 0);
  // const isCollapse = collapse;
  // console.log("[TreeContracts] Row", row);
  const setOpenToggle = (openStatus)=>{
    setOpen(!openStatus);
  };
  const setFirstToggle = () => {
    const stageData = getState().toggleStage;
    if(row && row.collapse && row.collapse.length > 0 ) {
      // console.log("row.collapse",row.collapse)
      // console.log("stageData",row.collapse[0].id);
      if(stageData && typeof stageData.detail !== 'undefined' && stageData.detail === row.collapse[0].id 
        && open === false && stageData.detail !== "") {
        setOpen(!open);
        dispatch(setToggleStage(''));
      }
    }
  };
  setFirstToggle();

  return (
    <Fragment>
      <TableRow className={classes.childInner}>

        {
          columns.map((col,index) => (
            <TableCell className={`tablePaddingSm ${col.cellClassName}`} key={index} >
                {(typeof col.tooltip !== "undefined" && col.tooltip===true) ?
                  <ToolTipCell cellData={col.renderCell({value: row[col.field], row: contract})}/>
                  :
                  col.renderCell({value: row[col.field], row: contract})
                }
               
            </TableCell>
          ))
        }
        {
          collapse && (
          <TableCell key="collapse" className={options.extraIcon ? classes.collapseIconWrapLg : classes.collapseIconWrap} >
            {
              options.isShowExtraIcon ?
              <img  alt="" src={options.extraIcon} style={{verticalAlign: 'middle'}} /> 
              :
              <img  alt="" src={options.extraIcon} style={{visibility: 'hidden'}} /> 
            }
            {
              isCollapse && (
              <IconButton onClick={() => setOpenToggle(open)} > 
                { (open) 
                ? 
                <img  alt="" src={minusCircleIcon} className={classes.toggleSlide}/> 
                : 
                <img  alt="" src={plusCircleIcon} className={classes.toggleSlide}/> }
              </IconButton>)
            }
          </TableCell>)
        }

      </TableRow>

      {
        collapse && (
        <CollapseRow 
          contracts={collapseData}
          parentContract={contract}
          title={collapseTitle}
          open={open}
          colSpan={columns.length + ((collapse) ? 1 : 0)}
          {...collapseParams}
        />)
      }
      
    </Fragment>
  );
}

function CollapseRow({
  contracts, columns, parentContract={}, actions = [], options = {}, title="", open, colSpan, headerShow,
}) {
  // console.log("[TreeContracts] CollapseRow", contracts, columns, parentContract, actions, options, headerShow);

  const classes = useStyles();

  const boxBackgroundColor = headerShow ? '#F4F6F8' : '#ffffff';
  const tableBackgroundColor = headerShow ? '#ffffff' : '#F4F6F8';

  let renderHeadingClone = null;

  if (options.renderHeading && typeof options.renderHeading.props.onClick === 'function') {
    const oldOnClick = options.renderHeading.props.onClick;
    // console.log("[TreeContracts] CollapseRow", oldOnClick);
    renderHeadingClone = {
      ...options.renderHeading,
      props: {
        ...options.renderHeading.props,
        onClick: (e) => {
          oldOnClick(e, parentContract);
        },
      },
    };
    // console.log("[TreeContracts] CollapseRow", parentContract);
  }

  return (
    <TableRow className = "child-tree-grid">
      <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: boxBackgroundColor }} colSpan={colSpan}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box margin={1} >
            {
              headerShow && (
                <Typography  gutterBottom component="div">
                  <div className="tree-heading">
                    <h1  className={classes.poHeading}>{title}</h1>
                    
                    <div className={classes.poArchive}>
                      {renderHeadingClone}
                    </div>
                  </div>
                </Typography>
              )
            }

            <TreeContracts 
              contracts={contracts}
              columns={columns}
              actions={actions}
              options={{
                isPagination: true,
                isPaginationChangeable: false,
                isCollapseHeaderShow: false,
                backgroundColor: tableBackgroundColor,
                rowsPerPage: options.rowsPerPage,
                sortKey: options.sortKey ? options.sortKey : 'index', // collapse sort
              }}
            />

          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}



/**
 * Tree Contracts based on Table
 * @param {Array} contracts 
 * @param {Array} columns 
 * @param {Array} actions 
 * @param {Object} options 
 * @returns 
 */
export default function TreeContracts({
  contracts, columns, actions = [], options = {},
}) {
  
  const classes = useStyles();
  
  options = {
    isPagination: true,
    isPaginationChangeable: true,
    isRow: true,
    rowsPerPage: 10,
    isCollapseHeaderShow: true,
    backgroundColor: '#ffffff',
    sortKey: 'payload.created_at', // old version: 'created_at',
    extraIcon: null,
    checkExtraIcon: () => (false),
    ...options,
  };
  
  const isDefault = !columns;
  columns = columns ? columns : [["TemplateId", "templateId"], ["ContractId", "contractId"]];

  contracts = sortContracts(contracts, options.sortKey);
  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(options.rowsPerPage);

  // check if it needs collapse or not
  const collapseIndex = columns.findIndex(col => col[2] && col[2].type === 'collapse');

  const collapseTitle = (collapseIndex >= 0) ? columns[collapseIndex][0] : "";
  const collapseParams = (collapseIndex >= 0) ? columns[collapseIndex][2] : {};
  if (options.isCollapseHeaderShow) collapseParams.headerShow = true;
  if (!collapseParams.options) collapseParams.options = {};
  collapseParams.options.rowsPerPage = rowsPerPage;
  
  let cols = columns.filter(col => !col[2] || col[2].type !== 'collapse')
    .map(col => ({
      field: col[0],
      isAction: false,
      headerClassName: classes.headerCell,
      cellClassName: classes.headerCell,
      renderHeader: renderHeaderCell(col[2]),
      renderCell: renderCell(col[2]),
      ...((!!col[2] && !!col[2].options) ? col[2].options : {})
    }));
  if (isDefault) cols.push({
    field: 'payload',
    headerName: 'Payload',
  });
  if (actions.length) cols = cols.concat(actions.map(act => ({
    field: act[0],
    isAction: true,
    renderCell: renderActionCells(act[1]),
    ...((!!act[2] && typeof act[2] === 'object') ? act[2] : {})
  }))); 
  
 
  const rows = contracts.map((c, i) => {
    let values = {
      id: (c && c.uniqueKey) ? c.uniqueKey : i, // old id is contractId, new id is uniqueKey
      contract: c,
    };
    
    columns.forEach(col => {
      let val = getValueByCol(c, col[1], col[2]);
      val = typeof val === "boolean" ? (val ? "True" : "False") : val;

      if (col[2] && col[2].type === 'collapse') {
        values['collapse'] = val;
      }
      else if (!!val && typeof val === "object" && val.length >= 0 && typeof col[2] === "object"
        // && 'columns' in col[2]
        ) {
          val = val.length;
      }
      values[col[0]] = val;
    });

    actions.forEach(act => {
      values[act[0]] = c;
    });
    
    return values;
  });
  
  const [order] = useState('asc');
  const [orderBy] = useState('calories');


  useEffect(() => {
    if (options.rowsPerPage) {
      // console.log("[TreeContracts] options rowsPerPage is changed", options.rowsPerPage);
      setRowsPerPage(options.rowsPerPage);
    }
  }, [options.rowsPerPage]);


  // console.log("[TreeContracts] options", options, "rowsPerPage", rowsPerPage, "collapseParams", collapseParams);

  
  return (
    <>
   
      <TableContainer className={classes.customTree} component={Paper} style={{backgroundColor: options.backgroundColor}}>
        <Table className="tree-table" aria-label="collapsible table">

          <TableHead>
            <TableRow>
              {
                cols.map((col, index)=>(
                  <TableCell  
                    style={{minWidth:(typeof col.width !== "undefined" && col.width !== "") ? col.width: 'none' }}  
                    className={col.headerClassName} key={index}
                  >
                    {
                      typeof col.renderHeader === 'function'
                      ?
                      col.renderHeader({field: col.field})
                      :
                      col.field
                    }
                  </TableCell>
                ))
              }
              {
                (collapseIndex >= 0) && <TableCell key="collapse"></TableCell>
              }
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.length > 0 ?
              <>
                {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,index) => (
                options.isRow && 
                  <Row 
                    key={row.id} 
                    row={row} 
                    columns={cols} 
                    contract={row.contract} 
                    collapse={(collapseIndex >= 0)}
                    collapseTitle={collapseTitle}
                    collapseParams={collapseParams}
                    options={{
                      extraIcon: options.extraIcon,
                      isShowExtraIcon: options.checkExtraIcon(row.contract),
                    }}
                  />))}
              </>
            :
            <TableRow>
              <TableCell colSpan={cols.length}>No Records Found</TableCell>
            </TableRow>}
          </TableBody>

          {(options.isPagination === true) && 
          <TableFooter>
            <TableRow>
              <TableCell 
                className="footerpagination" 
                colSpan={cols.length + ((collapseIndex >= 0) ? 1 : 0)} 
              >
                {
                  options.isPagination && (
                    <CustomPagination
                      count={rows.length}
                      page={page}
                      setPage={setPage}
                      rowsPerPage={rowsPerPage}
                      setRowsPerPage={setRowsPerPage}
                      options={{
                        isPaginationChangeable: options.isPaginationChangeable,
                      }}
                    />
                )}
              </TableCell>
            </TableRow>
          </TableFooter>
          }
          
        </Table>
      </TableContainer>
    
    </>
  );
}
