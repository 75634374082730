import { makeStyles } from "@material-ui/styles";
import radioCheckIcon from "../../icons/check1.svg";

export default makeStyles(theme => ({
  "@global": { // global styles
    // scrollbar
    "::-webkit-scrollbar": {
      width: '4px',
      height: '4px',
    },
    "::-webkit-scrollbar-thumb": {
      background: '#F7CD37', // Old color of scrollbar '#183C73'
    },
    ".scroll-width-thin": {
      scrollbarWidth: 'thin',
    },
    // radio
    'input[type="radio"] + div:before': {
      content: '',
      position: 'absolute',
      width: '24px',
      height: '24px',
      borderRadius: '24px',
      zIndex: 99,
      left: '-1px',
      top: '-2px',
    },
    'input[type="radio"]:checked + div:before': {
      // content: "url('../../icons/check1.svg')",
      content: `url(${radioCheckIcon})`,
      border: '1px solid rgba(0,0,0,0)',
    },
    // select dropdown menu
    // 'ul.MuiList-root.MuiMenu-list': (theme.version && theme.version.new) ? {
    'ul.MuiList-root.MuiMenu-list': {
      backgroundColor: '#E1DDDD',
      paddingTop: '1px',
      paddingBottom: '1px',
      '& .MuiMenuItem-root': {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '14px',
        paddingTop: '5px',
        paddingBottom: '5px',
        '&.Mui-selected': {
          backgroundColor: '#5C5C5C',
          color: '#FFFFFF',
          fontWeight: 700,
        },
      },
    },
    // } : {},
  },
  root: {
    display: "flex",
    maxWidth: "100vw",
    overflowX: "hidden",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: `calc(100vw - 240px)`,
    minHeight: "100vh",
    backgroundColor: ((theme.version && theme.version.new) ? '#EDEDED': '#E5E5E5'),  // ! New Design
    '@media (max-width:1024px)':{
      padding: '16px',
    },
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '& .MuiSnackbar-anchorOriginBottomLeft': {
      '@media (min-width: 600px)': {
        left: `${218 + theme.spacing(6)}px`,
      },
    }
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  /** Spinner */
  loadingSpinner: {
    position: 'fixed',
    height: '100%',
    zIndex: 10001,
  },
}));
