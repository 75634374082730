import React from "react";
import { useStreamQueries } from "@daml/react";

export default function withStreamQueries (WrappedComponent) {
  return (template, searchParams) => {
    if (!template || !searchParams) return WrappedComponent;
    
    return ({ ...props }) => {
      const { contracts, loading } = useStreamQueries(template, () => [searchParams]);

      return (
        <WrappedComponent 
          contractsStreamQueries={contracts}
          loadingStreamQueries={loading}
          {...props} 
        />
      );
    }
  }
}