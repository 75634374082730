import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import { ArrowBack, EditSharp, /* Home,LocalHospital, NoteAdd, Healing, */ Extension, ListAlt } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import SidebarLink from "./components/SidebarLink/SidebarLink";
import { useLayoutState, useLayoutDispatch, toggleSidebar } from "../../context/LayoutContext";
import { useDamlState } from '../../context/DamlContext';
import icon1 from '../../icons/Frame 19.svg';
import activeIcon1 from '../../icons/active1.svg';
import icon2 from '../../icons/Frame 18.svg';
import activeIcon2 from '../../icons/active2.svg';
import icon3 from '../../icons/Frame 17.svg';
import activeIcon3 from '../../icons/active3.svg';
import icon4 from '../../icons/Frame 16.svg';
import activeIcon4 from '../../icons/active4.svg';
import icon5 from '../../icons/Frame 15.svg';
import activeIcon5 from '../../icons/active5.svg';
import icon6 from '../../icons/Frame 14.svg';
import activeIcon6 from '../../icons/active6.svg';
import icon66 from '../../icons/recalldis.svg';
import activeIcon66 from '../../icons/recall.svg';
import icon666 from '../../icons/rec.svg';
import activeIcon666 from '../../icons/rec1.svg';
import icon7 from '../../icons/library_books.svg';
import activeIcon7 from '../../icons/active7.svg';
import icon8 from '../../icons/receipt.svg';
import activeIcon8 from '../../icons/active8.svg';
import icon9 from '../../icons/payment.svg';
import activeIcon9 from '../../icons/active9.svg';
import icon10 from '../../icons/textsms.svg';
import activeIcon10 from '../../icons/active10.svg';
import icon11 from '../../icons/remove_red_eye.svg';
import activeIcon11 from '../../icons/active11.svg';
import icon12 from '../../icons/view_carousel.svg';
import activeIcon12 from '../../icons/active12.svg';
import icon13 from '../../icons/view_column.svg';
import activeIcon13 from '../../icons/active13.svg';
import icon14 from '../../icons/view_quilt.svg';
import activeIcon14 from '../../icons/active14.svg';
import icon144 from '../../icons/usersdis.svg';
import activeIcon144 from '../../icons/users.svg';
import icon1444 from '../../icons/auditdis.svg';
import activeIcon1444 from '../../icons/audit.svg';
import icon15 from '../../icons/assessment.svg';
import activeIcon15 from '../../icons/active15.svg';
import networkIcon from '../../icons/network.svg';
import networkDisicon from '../../icons/networkdis.svg';
import vendorIcon from '../../icons/vendor.svg';
import vendorDisIcon from '../../icons/vendordis.svg';
import hosIcon from '../../icons/hos.svg';
import hosDisIcon from '../../icons/hosdis.svg';
import useStyles from "./styles";


/**
 * Side Bar
 * According to role & accesslevel
 * @param {Object} location 
 * @returns 
 */
function Sidebar({ location }) {

  var classes = useStyles();
  var theme = useTheme();
  const { role, userlistPayload } = useDamlState();
  const accesslevel = userlistPayload ? userlistPayload.accesslevel : null;
  // console.log("[Sidebar]", role, userlistPayload, accesslevel);

  const[lastIndex, setLastIndex] = useState(0);
  const[menuToggle, getMenuToggle] = useState(0);
  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  const setMenuToggle = (e,index) => {
    e.preventDefault();
    getMenuToggle(index);
    setLastIndex(index)
  };

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }


  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });


  return (
    role === "Undefined"
      ?
      <></>
      :
      <Drawer
        variant={isPermanent ? "permanent" : "temporary"}
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        })}
        classes={{
          paper: classNames({
            [classes.drawerOpen]: isSidebarOpened,
            [classes.drawerClose]: !isSidebarOpened,
          }),
        }}
        open={isSidebarOpened}
      >
        <div className={classes.toolbar} />
        <div className={classes.mobileBackButton}>
          <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
            <ArrowBack
              classes={{
                root: classNames(classes.headerIcon, classes.headerIconCollapse),
              }}
            />
          </IconButton>
        </div>
        <List className={classes.sidebarList}>

          {role === "Manager" && (
            <>
              <SidebarLink
                key="Manager"
                label="Manager"
                path="/app/welcome"
                icon={<Extension />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
            </>
          )}

          {(role !== 'Undefined' && role !== 'Manager') && (
            <>
              {/*<SidebarLink
                key="UndefinedWelcome"
                label="Welcome"
                path="/app/welcome"
                icon={<Extension />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />*/}
            </>
          )}

          {role === "Operator" && (
            <>
              <SidebarLink
                key="OperatorAdmisisionList"
                label="Admission"
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="OperatorNetwork"
                label="Network"
                path="/app/onboardnetwork"
                icon={<img src={networkDisicon} alt="" />}
                activeIcon={<img src={networkIcon} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="OperatorOnboarding"
                label="Onboarding"
                path="/app/onboarding"
                icon={<img src={icon10} alt="" />}
                activeIcon={<img src={activeIcon10} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="OperatorList"
                label="List"
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="OperatorVendorList"
                label="Vendors"
                path="/app/viewvendordata"
                icon={<img src={vendorDisIcon} alt="" />}
                activeIcon={<img src={vendorIcon} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="OperatorHospitalList"
                label="Hospitals"
                path="/app/viewhospitaldata"
                icon={<img src={hosDisIcon} alt="" />}
                activeIcon={<img src={hosIcon} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="Inventory"
                label="Account Inventory"
                path="/app/inventorylist"
                icon={<img src={icon5} alt="" />}
                activeIcon={<img src={activeIcon5} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="OperatorActivity"
                label="Activity"
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="ScanInventory"
                label="Restock Inventory"
                path="/app/inventoryscan"
                icon={<img src={icon6} alt="" />}
                activeIcon={<img src={activeIcon6} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="OperatorUploadProductData"
                label="Upload Product Data"
                path="/app/importfile"
                icon={<img src={icon13} alt="" />}
                activeIcon={<img src={activeIcon13} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="OperatorTemplateUser"
                label="User Management"
                path="/app/users-list"
                icon={<img src={icon144} alt="" />}
                activeIcon={<img src={activeIcon144} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="OperatorTemplateAudit"
                label="Audit Log"
                path="/app/audit-report"
                icon={<img src={icon1444} alt="" />}
                activeIcon={<img src={activeIcon1444} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              {/* <SidebarLink
                key="OperatorOnboardParties"
                label="Onboard Parties"
                path="/app/onboardparties"
                icon={<img src={icon6} alt="" />}
                activeIcon={<img src={activeIcon6} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              /> */}
              <SidebarLink
                key="Dashboard"
                label="Dashboard"
                path="/app/dashboard"
                icon={<img src={icon15} alt="" />}
                activeIcon={<img src={activeIcon15} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />

              <SidebarLink
                key="PaymentActivity"
                label="Payment Schedule"
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="paymentprocessing"
                label="Holiday"
                path="/app/viewpaymentcalendar"
                icon={<img src={icon6} alt="" />}
                activeIcon={<img src={activeIcon6} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="paymentprocessinglogs"
                label="Payment Logs"
                path="/app/viewpaymentlogs"
                icon={<img src={icon6} alt="" />}
                activeIcon={<img src={activeIcon6} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />

            </>
          )}

          {role === "Vendor" && (
            <>
              <SidebarLink
                key="Procedure"
                label="Procedure"
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="ReceivedPO"
                label="Received PO"
                path="/app/receivedpo"
                icon={<img src={icon7} alt="" />}
                activeIcon={<img src={activeIcon7} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="VendorPostProcedureForm"
                label="Post Procedure Form"
                path="/app/vendorpostprocedurelist"
                icon={<img src={icon2} alt="" />}
                activeIcon={<img src={activeIcon2} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              {false && <SidebarLink
                key="VendorPostPoHistory"
                label="PO History"
                path="/app/vendorpohistory"
                icon={<img src={icon3} alt="" />}
                activeIcon={<img src={activeIcon3} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />}
              <SidebarLink
                key="VendorInventory"
                label="Inventory"
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="VendorInventoryList"
                label="Account Inventory"
                path="/app/inventorylist"
                icon={<img src={icon5} alt="" />}
                activeIcon={<img src={activeIcon5} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="ScanVendorInventoryRstock"
                label="Restock Inventory"
                path="/app/inventoryscan"
                icon={<img src={icon6} alt="" />}
                activeIcon={<img src={activeIcon6} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="ScanVendorlreceivedinventory"
                label="Received Inventory"
                path="/app/received-inventory"
                icon={<img src={icon666} alt="" />}
                activeIcon={<img src={activeIcon666} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="ScanVendorlInventoryRecall"
                label="Recall lookup"
                path="/app/recall-lookup"
                icon={<img src={icon66} alt="" />}
                activeIcon={<img src={activeIcon66} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              {accesslevel === "VManager" && (
                <>
                  <SidebarLink
                    key="VendorFananicial"
                    label="Financial"
                    location={location}
                    isSidebarOpened={isSidebarOpened}
                  />
                  <SidebarLink
                    key="Invoice"
                    label="Invoice"
                    path="/app/invoice"
                    icon={<img src={icon8}  alt="" />}
                    activeIcon={<img src={activeIcon8} alt="" />}
                    location={location}
                    isSidebarOpened={isSidebarOpened}
                  />
                  <SidebarLink
                    key="ReceivedPayments"
                    label="Payments"
                    path="/app/receivedpayments"
                    icon={<img src={icon9}  alt="" />}
                    activeIcon={<img src={activeIcon9} alt="" />}
                    location={location}
                    isSidebarOpened={isSidebarOpened}
                  />
                </>
              )}
              <SidebarLink
                key="VendorAdmin"
                label="Admin"
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              {/* <SidebarLink
                key="VendorOnboarding"
                label="Onboarding"
                path="/app/onboarding"
                icon={<img src={icon10}  alt="" />}
                activeIcon={<img src={activeIcon10} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              /> */}
              <SidebarLink
                key="VendorReceivedInvitation"
                label="Hospital Invitations"
                path="/app/receivedinvitation"
                icon={<img src={icon10} alt="" />}
                activeIcon={<img src={activeIcon10} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="ViewVendorData"
                label="View Vendor Data"
                path="/app/viewvendordata"
                icon={<img src={icon11} alt="" />}
                activeIcon={<img src={activeIcon11} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="AdminVendorData"
                label="Admin Vendor Data"
                path="/app/adminvendorlist"
                icon={<img src={icon12} alt="" />}
                activeIcon={<img src={activeIcon12} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="OneUploadProductData"
                label="Upload Product Data"
                path="/app/importfile"
                icon={<img src={icon13} alt="" />}
                activeIcon={<img src={activeIcon13} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="hospitalTemplate"
                label="Hospital Template"
                path="/app/hospitaltemplate"
                icon={<img src={icon14} alt="" />}
                activeIcon={<img src={activeIcon14} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="VendorTemplateUser"
                label="User Management"
                path="/app/users-list"
                icon={<img src={icon144} alt="" />}
                activeIcon={<img src={activeIcon144} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="VendorTemplateAudit"
                label="Audit Log"
                path="/app/audit-report"
                icon={<img src={icon1444} alt="" />}
                activeIcon={<img src={activeIcon1444} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
            </>
          )}

          {role === "Hospital" && (
            <>
              <SidebarLink
                key="HospitalProcedure"
                label="Procedure"
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="HospitalSurgicalEvent"
                label="Surgical Event"
                path="/app/surgicalevent"
                icon={<img src={icon1} alt="" />}
                activeIcon={<img src={activeIcon1} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              {['HManager', 'HFinanceAssociate', 'HInventoryManager'].includes(accesslevel) && 
              <SidebarLink
                key="HospitalPostProcedureForm"
                label="Post Procedure Form"
                path="/app/postprocedurelist"
                icon={<img src={icon2} alt="" />}
                activeIcon={<img src={activeIcon2} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />}
              {/* <SidebarLink
                key="HospitalSurgicalEventHistory"
                label="Surgical Event History"
                path="/app/sehistory"
                icon={<img src={icon3} alt="" />}
                activeIcon={<img src={activeIcon3} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              /> */}
              {['HManager', 'HInventoryManager'].includes(accesslevel) && 
              <SidebarLink
                key="ProcedureCompliance"
                label="Compliance"
                path="/app/compliancelist"
                icon={<img src={icon4} alt="" />}
                activeIcon={<img src={activeIcon4} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />}
              <SidebarLink
                key="HospitalInventoryProp"
                label="Inventory"
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              {['HManager', 'HInventoryManager', 'HInventoryAssociate'].includes(accesslevel) && 
              <SidebarLink
                key="HospitalInventoryList"
                label="Account Inventory"
                path="/app/inventorylist"
                icon={<img src={icon5} alt="" />}
                activeIcon={<img src={activeIcon5} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />}
              <SidebarLink
                key="ScanHospitalInventory"
                label="Restock Inventory"
                path="/app/inventoryscan"
                icon={<img src={icon6} alt="" />}
                activeIcon={<img src={activeIcon6} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
                hasSubMenu={true}
                menuIndex={lastIndex !== 5 ? 5 : 0}
                setMenuToggle={setMenuToggle}
              />
              {menuToggle === 5 && 
                <div className={classes.childMenu}>
                  <SidebarLink
                    key="RestockChilMenuPO"
                    label="Inventory by SEID"
                    path="/app/inventoryscan-seid"
                    location={location}
                    isSidebarOpened={isSidebarOpened}
                  />
                  <SidebarLink
                    key="RestockChilMenuPO2"
                    label="Inventory by PO"
                    path="/app/inventoryscan-po"
                    location={location}
                    isSidebarOpened={isSidebarOpened}
                  />
                </div>
              }
              <SidebarLink
                key="ScanVendorlReturnInventoryinventory"
                label="Return Inventory"
                path="/app/return-inventory"
                icon={<img src={icon666}  alt="" />}
                activeIcon={<img src={activeIcon666} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="ScanHospitalInventoryRecall"
                label="Recall lookup"
                path="/app/recall-lookup"
                icon={<img src={icon66} alt="" />}
                activeIcon={<img src={activeIcon66} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
                hasSubMenu={true}
                menuIndex={lastIndex !== 6 ? 6 : 0}
                setMenuToggle={setMenuToggle}
              />
              {menuToggle === 6 && 
                <div className={classes.childMenu}>
                  <SidebarLink
                    key="RecallChilMenuPO"
                    label="Upload Recall Products"
                    path="/app/recall-lookup-upload"
                    location={location}
                    isSidebarOpened={isSidebarOpened}
                  />
                  <SidebarLink
                    key="RecallChilMenuPO2"
                    label="Recalled Products"
                    path="/app/recall-lookup-list"
                    location={location}
                    isSidebarOpened={isSidebarOpened}
                  />
                </div>
              }
              <SidebarLink
                key="HospitalFinancial"
                label="Financial"
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="HospitalPO"
                label="Purchase Order"
                path="/app/purchaseorder"
                icon={<img src={icon7} alt="" />}
                activeIcon={<img src={activeIcon7} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
                hasSubMenu={true}
                menuIndex={lastIndex !== 8 ? 8 : 0}
                setMenuToggle={setMenuToggle}
              />
              {menuToggle === 8 && 
                <div className={classes.childMenu}>
                  <SidebarLink
                    key="HospitalChilMenuPO"
                    label="New Purchase Order "
                    path="/app/purchaseorder-new"
                    location={location}
                    isSidebarOpened={isSidebarOpened}
                  />
                  <SidebarLink
                    key="HospitalChilMenuPO2"
                    label="SE Purchase Order"
                    path="/app/purchaseorder-list"
                    location={location}
                    isSidebarOpened={isSidebarOpened}
                  />
                </div>
              }
              {['HManager', 'HInventoryManager'].includes(accesslevel) && 
                <>
                  <SidebarLink
                    key="HospitalInvoice"
                    label="Received Invoices"
                    path="/app/receivedinvoice"
                    icon={<img src={icon8}  alt="" />}
                    activeIcon={<img src={activeIcon8} alt="" />}
                    location={location}
                    isSidebarOpened={isSidebarOpened}
                  />
                  <SidebarLink
                    key="HospitalPayments"
                    label="Payments"
                    path="/app/sentpayments"
                    icon={<img src={icon9}  alt="" />}
                    activeIcon={<img src={activeIcon9} alt="" />}
                    location={location}
                    isSidebarOpened={isSidebarOpened}
                  />
                </>
              }
              <SidebarLink
                key="HospitalAdmin"
                label="Admin"
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="HospitalOnboarding"
                label="Onboarding"
                path="/app/onboarding"
                icon={<img src={icon10} alt="" />}
                activeIcon={<img src={activeIcon10} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="VendorInvitation"
                label="Vendor Invitation"
                path="/app/vendorinvitation"
                icon={<img src={icon10} alt="" />}
                activeIcon={<img src={activeIcon10} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              {['HManager', 'HInventoryManager'].includes(accesslevel) && 
                <>
                  <SidebarLink
                    key="HospitalViewHospitalData"
                    label="View Hospital Data"
                    path="/app/viewhospitaldata"
                    icon={<img src={icon11}  alt="" />}
                    activeIcon={<img src={activeIcon11} alt="" />}
                    location={location}
                    isSidebarOpened={isSidebarOpened}
                  />
                  <SidebarLink
                    key="AdminHospitalData"
                    label="Admin Hospital Data"
                    path="/app/adminhospitallist"
                    icon={<img src={icon12}  alt="" />}
                    activeIcon={<img src={activeIcon12} alt="" />}
                    location={location}
                    isSidebarOpened={isSidebarOpened}
                  />
                </>
              }
              <SidebarLink
                key="HospitalUploadProductData"
                label="Upload Product Data"
                path="/app/importfile"
                icon={<img src={icon13} alt="" />}
                activeIcon={<img src={activeIcon13} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="VendorTemplate"
                label="Vendor Template"
                path="/app/vendortemplate"
                icon={<img src={icon14} alt="" />}
                activeIcon={<img src={activeIcon14} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="VendorTemplateUser"
                label="User Management"
                path="/app/users-list"
                icon={<img src={icon144} alt="" />}
                activeIcon={<img src={activeIcon144} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="VendorTemplateAudit"
                label="Audit Log"
                path="/app/audit-report"
                icon={<img src={icon1444} alt="" />}
                activeIcon={<img src={activeIcon1444} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="DashboardHospital"
                label="Dashboard"
                path="/app/dashboardhospital"
                icon={<img src={icon15} alt="" />}
                activeIcon={<img src={activeIcon15} alt="" />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
            </>
          )}

          {role === "FinancialFirm" && (
            <>
              <SidebarLink
                key="Testlist"
                label="Test List"
                path="/app/testlist"
                icon={<EditSharp />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
              <SidebarLink
                key="VCList"
                label="VC List"
                path="/app/vclist"
                icon={<ListAlt />}
                location={location}
                isSidebarOpened={isSidebarOpened}
              />
            </>
          )}

        </List>
      </Drawer>
  );
}

export default withRouter(Sidebar);
