import { EVENT_SET_ALL, EVENT_SET_STATUS, EVENT_CLEAR_ALL } from "./constants";

export const setAllEvent = (payload) => ({
  type: EVENT_SET_ALL,
  payload
});

export const setStatusEvent = (payload) => ({
  type: EVENT_SET_STATUS,
  payload
});

export const clearAllEvent = () => ({
  type: EVENT_CLEAR_ALL,
});
