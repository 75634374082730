import React from "react";
import classNames from "classnames";
import { Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import useStyles from "../showlist/styles";
import moment from 'moment-timezone';
import { CustomTextField } from "../../components/Inputs";
import { AuditLogsModel as AuditLogs } from "../../models/AuditLogs";


/**
 * Overview Purchase Order
 * @param {Object} options 
 * @param {Object} poData 
 * @returns 
 */
export default function AuditDetails({
  poData,time, className= null, 
}) {

  const classes = useStyles();
  
  return (
    <Table className={classNames( className, classes.auditLogForm , classes.auditLogTop)} >
      <TableBody>
        <TableRow>
          <TableCell colSpan={2} className="new-subtitle" style={{ paddingTop: 0 }}>Audit Details</TableCell>
        </TableRow>

        <>
          <TableRow>
            <TableCell className="label">Date</TableCell>
            <TableCell>
              <CustomTextField
                className={classes.auditLogTextField}
                value={moment(poData.created_at).format("MMMM D, YYYY")}
                width={170}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="label">Time</TableCell>
            <TableCell>
              <CustomTextField
                className={classes.auditLogTextField}
                value={time}
                width={170}
              />
            </TableCell>

          </TableRow>
          <TableRow>
            <TableCell className="label">User</TableCell>
            <TableCell>
              <CustomTextField
                className={classes.auditLogTextField}
                value={poData.user}
                width={170}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="label">Email</TableCell>
            <TableCell>
              <CustomTextField
                className={classes.auditLogTextField}
                value={poData.useremail}
                width={170}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="label">Executed Action</TableCell>
            <TableCell>
              <CustomTextField
                className={classes.auditLogTextField}
                value={AuditLogs[poData.event]}
                width={170}
              />
            </TableCell>
          </TableRow>
        </>
      </TableBody>

    </Table>
  );
}
