import { getModelMenu, validateModelItem, getSubModel } from "./handles";

export const UserAccessLevel = {
    HManager: 'Manager',
    HFinanceAssociate: 'Executive',
    HInventoryManager: 'OR Staff',
    HInventoryAssociate: 'Associate',
    // HOperatingNurse: 'Nurse', // TODO: New  //removed for now
    VManager: 'Administrator',
    VAssociate: 'Associate',
    TManager: 'Tydei Manager',
    TAssociate: 'Tydei Associate',
    CManager: 'Contractor Manager',
    CAssociate: 'Contractor Associate',
    OManager: 'Manager',
};

export const UserAccessLevelHospital = getSubModel(UserAccessLevel, [
    'HManager', 'HFinanceAssociate', 'HInventoryManager', 'HInventoryAssociate', 
]);

export const UserAccessLevelVendor = getSubModel(UserAccessLevel, [
    'VManager', 'VAssociate',
]);

export const UserAccessLevelTydei = getSubModel(UserAccessLevel, [
    'TManager', 'TAssociate',
]);

export const UserAccessLevelContractor = getSubModel(UserAccessLevel, [
    'CManager', 'CAssociate',
]);

export default Object.keys(UserAccessLevel).map(value => ({
    value,
    label: UserAccessLevel[value]
}));

export const validateUserAccessLevel = validateModelItem(UserAccessLevel);

export const validateUserAccessLevelHospital = validateModelItem(UserAccessLevelHospital);
export const getUserAccessLevelHospital = getModelMenu(UserAccessLevelHospital);
export const validateUserAccessLevelVendor = validateModelItem(UserAccessLevelVendor);
export const getUserAccessLevelVendor = getModelMenu(UserAccessLevelVendor);

export const UserJobTitle = {
    Finance : 'Finance Manager',
    Nurse: 'Nurse',
    InventoryManager: 'Inventory Manager',
    Manager: 'Manager',
    Associate: 'PAssociate',
    Purchasing: 'Purchasing Manager',
    Sales : 'Sales',
};

export const UserJobTitleHospital = getSubModel(UserJobTitle, [
    'Manager', 'Purchasing', 'Finance', 'Nurse', 'InventoryManager',
]);

export const UserJobTitleVendor = getSubModel(UserJobTitle, [
    'Finance', 'Purchasing', 'InventoryManager', 'Sales',
]);

export const UserJobTitleTydei = getSubModel(UserJobTitle, [
    'Manager', 'Associate',
]);

export const validateUserJobTitle = validateModelItem(UserJobTitle);

export const validateUserJobTitleHospital = validateModelItem(UserJobTitleHospital);
export const getUserJobTitleHospital = getModelMenu(UserJobTitleHospital);
export const validateUserJobTitleVendor = validateModelItem(UserJobTitleVendor);
export const getUserJobTitleVendor = getModelMenu(UserJobTitleVendor);

export const UserStatus = {
    Active: 'Enabled',
    // Pending: 'Pending',
    // Suspended: 'Suspended',
    Inactive: 'Disabled',
};

export const validateUserStatus = validateModelItem(UserStatus);
export const getUserStatus = getModelMenu(UserStatus);
