import React, { useEffect, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import classnames from "classnames";
import DamlLedger from "@daml/react";
import { wsBaseUrl, httpBaseUrl, damlLastPath, damlReloadTime, WS_RECONNECT_THRESHOLD, bearerTokenKey } from "../../config";
import Header from "../Header/Header1";
import ChatBox from "../ChatBox";
import CustomSnackbar from "../Toast/CustomSnackbar1";
import { post, /* getToken */ } from "../../services/axios/daml-json-api";
import { useLayoutState, useLayoutDispatch, toastOpen } from "../../context/LayoutContext";
import { useUserState,
  useUserDispatch} from "../../context/UserContext";
import { DamlProvider } from "../../context/DamlContext";
import ErrorBoundary from "../ErrorBoundary";
import Routes from "../Routes/Routes";
import { CustomLoading } from "../../hoc/withLoading";
import useStyles from "./styles";
import { useAuth0 } from "@auth0/auth0-react";
import * as jwt from "jsonwebtoken";
import { REFRESH_BEARER_TOKEN } from "../../store/actions/constants";


/**
 * main Layout
 * TODO
 * ChatBox is disabled in bottom right now, it should be fixed in the future.
 * @returns 
 */
function Layout() {

  const classes = useStyles();
  const user = useUserState();
  const { toast, isLoading, darkMode } = useLayoutState();
  const {handleClose: toastHandleClose, ...toastParams} = (toast ? toast : {});
  const layoutDispatch = useLayoutDispatch();
  const location = useLocation();

  const [isHeaderShow, setHeaderShow] = useState(true);
  const [bearerToken, setBearerToken] = useState(user.refreshToken ? user.refreshToken : user.token);
  const userDispatch = useUserDispatch();
  
  const options = {
    ignoreCache: true,
    detailedResponse: true,
  };
  const {
    getAccessTokenSilently,
    loginWithPopup,
  } = useAuth0();

  // toast
  const handleCloseToast = async () => {
    if (toastHandleClose === 'function') toastHandleClose();
    toastOpen(layoutDispatch, {});
  };


  // Show or Hide Header & Sidebar
  useEffect(() => {
    console.log("[route changed]", location.pathname);
      
    // hide sidebar on some paths
    if (location.pathname === '/app/surgicalevent/add-new'
      || location.pathname === '/app/surgicalevent/edit') {
      if (isHeaderShow) setHeaderShow(false);
    }
    else {
      if (!isHeaderShow) setHeaderShow(true);
    }

    // update last path
    if (location.pathname !== '/app/welcome') {
      sessionStorage.setItem(damlLastPath, location.pathname);
    }

  }, [location, isHeaderShow]);


  // To check websocket connection, post request to get Party
  // If status of response is not 200, then reload window.
  useEffect(() => {
    let timestamp = 0, now = (new Date()).getTime();
    try {
      timestamp = parseInt(localStorage.getItem(damlReloadTime));
    }
    catch (e) {
    }
    if (isNaN(timestamp)) timestamp = 0;
    // console.log("[Layout]", timestamp, now);
    const postParty = async () => {
      let res = await post('/v1/parties', {
        body: JSON.stringify(
          [user.party]
        ),
        token: user.token
      });
      // console.log("[Layout]", res.status);
      localStorage.setItem(damlReloadTime, now);
      if (res.status !== 200) {
        window.location.reload();
      }
    };
    if ((now - timestamp) > 60 * 1000) postParty(); // 1min after last try
    localStorage.setItem(bearerTokenKey, user.token);
  }, [user]);

  useEffect(()=>{
    //for every 1 minute
    let intervalTimeout = setInterval(validateTokenExpiry, 60000);
    return () => {
      clearInterval(intervalTimeout);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatchUserRefreshToken = async () => {
    try {
     getRefreshTokenAuth0().then(({ token, expirationTime }) => {
       if(bearerToken !== token && token) {
        const timeToExp = expirationTime * 1000 - new Date().getTime();
        console.log(`Next token refresh scheduled in ${Math.max(timeToExp - 5 * 60 * 1000, 0) / 1000} seconds`);
        userDispatch({ type: REFRESH_BEARER_TOKEN, payload: token });
        setBearerToken(token);
        localStorage.setItem(bearerTokenKey, token);
        }
      });
    } catch (e) {
      await loginWithPopup();
    }
  }

  /**
   * To validate the bearer token expiry;
   * @returns Boolean true/false
   */
  const validateTokenExpiry = async () => {

    const {timeToExp} = getRemainingExpireTime();
    // compare the expiration time with the current time , 5 minutes before
    if (timeToExp <= 5 * 60 * 1000) {
        console.info("Token is about to expire, refreshing...");
      dispatchUserRefreshToken();
      return true;
    } else {
       console.info("Token is still valid");
      return false;
    }
  };

  // To get a fresh access token with its expiration time
  const getRefreshTokenAuth0 = async () => {
    try {
      const {timeToExp, oldToken, oldExpiresIn } = getRemainingExpireTime();
    if ( timeToExp <= 5 * 60 * 1000) {
      const getSilenttoken = await getAccessTokenSilently(options);

      const token = getSilenttoken?.access_token;
      console.log('new silent token [] ', token);
      const decoded = jwt.decode(token, { complete: true });
      const expirationTime = decoded?.payload?.exp;
     
      return { token, expirationTime };
    } else {
      return { oldToken, oldExpiresIn };
    }
    } catch (e) {
      console.error("Error getting token:", e);
      if (e.error === 'login_required' || e.error === 'consent_required') {
        await loginWithPopup();
      }
      return { "Error": 'Login is required', e };
    }
  };
  /**
   * Get reminng expiry time of token that is in context 
   * @return  timeToExp, oldToken, expiresIn 
   */
  const getRemainingExpireTime = () => {

       //its expiration time in seconds since Unix epoch
       const oldToken = bearerToken;
       const oldTokenDecoded = jwt.decode(oldToken, { complete: true });
       const expiresIn = oldTokenDecoded?.payload?.exp; // token expiry time
   
       // convert the expiration time to milliseconds since Unix epoch
       const expirationTimeMs = expiresIn * 1000;
     
       const now = new Date().getTime();
       const timeToExp = expirationTimeMs - now; // calculate time left until token expires
       return { timeToExp, oldToken, expiresIn };
  }

  return (
    <ErrorBoundary>
      <DamlLedger party={user.party} token={user?.refreshToken ? user?.refreshToken : user?.token} httpBaseUrl={httpBaseUrl} wsBaseUrl={wsBaseUrl} reconnectThreshold={WS_RECONNECT_THRESHOLD}>
        <DamlProvider>

          <div className={classes.root}
          style={{ backgroundColor: darkMode ? "#1C1D24" : "" }}
          >
              
            {isHeaderShow && <Header />}

            <div
              className={classnames(classes.content)}
              style={{ backgroundColor: darkMode ? "#1C1D24" : "" }}
            >
              {isHeaderShow && <div className={classes.fakeToolbar} />}

              <Routes />

              <CustomSnackbar 
                handleClose={handleCloseToast}
                {...toastParams}
              />

            </div>

            {false && user.isAuthenticated ? <ChatBox /> : null}

            {
              isLoading &&
              <CustomLoading className={classes.loadingSpinner} />
            }

          </div>
          
        </DamlProvider>
      </DamlLedger>
    </ErrorBoundary>
  );
}

export default withRouter(Layout);
