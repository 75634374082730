import {
  SURGICAL_SET_DETAIL,
  SURGICAL_SEARCH,
  SURGICAL_STATUS,
  PURCHASE_ORDER_SET_DETAIL,
  PURCHASE_ORDER_SET_LAST_ID,
  PURCHASE_ORDER_ADD,
  PURCHASE_ORDER_SEARCH,
  RECEIVEDPO_SET_DETAIL,
  RECEIVEDPO_SEARCH,
  INVOICE_SET_DETAIL,
  INVOICE_SET_LAST_ID,
  INVOICE_ADD,
  INVOICE_SEARCH,
  PAYMENT_SEARCH,
  RECEIVEDINV_SEARCH,
  INVD_SET_DETAIL,
  INVD_SET_LAST_ID,
  INVD_ADD,
  INVENTORY_SEARCH,
  INVENTORY_SET_DETAIL,
  COMPLIANCE_SET_DETAIL,
  PROCEDURE_SET_DETAIL,
  PROCEDURE_SEARCH,
  COMPLIANCE_SEARCH,
  SURGICALEVENTHISTORY_SET_DETAIL,
  SURGICALEVENTHISTORY_SEARCH,
  TEMPLATE_SEARCH,
  INVITE_SEARCH,
  VENDOR_ACCEPT_SEARCH,
  RECEIVED_INVITE_SEARCH,
  HOSPITAL_ACCEPT_SEARCH,
  USER_SEARCH,
  SET_TOGGLE_STAGE,
  RETRIEVE_ES_SEARCH,
  DAML_SEARCH,
  CALLBACK_KEY_SEARCH,
  SEARCH_FILTERS,
  SEARCH_FILTERS_CLEAR,
} from "./constants";

// Actions for SurgicalEvent
export const surgicalEventSetDetail = (payload) => ({
  type: SURGICAL_SET_DETAIL,
  payload
});
export const surgicalEventSearch = (payload) => ({
  type: SURGICAL_SEARCH,
  payload
});
export const surgicalEventStatus = (payload) => ({
  type: SURGICAL_STATUS,
  payload
});

// Actions for SurgicalEventHistory
export const surgicalEventHistorySearch = (payload) => ({
  type: SURGICALEVENTHISTORY_SEARCH,
  payload
});
export const surgicalEventHistorySetDetail = (payload) => ({
  type: SURGICALEVENTHISTORY_SET_DETAIL,
  payload
});

// Actions for PurchaseOrder
export const purchaseOrderSetDetail = (payload) => ({
  type: PURCHASE_ORDER_SET_DETAIL,
  payload
});
export const purchaseOrderSetLastID = (payload) => ({
  type: PURCHASE_ORDER_SET_LAST_ID,
  payload
});
export const purchaseOrderAdd = (payload) => ({
  type: PURCHASE_ORDER_ADD,
  payload
});
export const purchaseOrderSearch = (payload) => ({
  type: PURCHASE_ORDER_SEARCH,
  payload
});

// Actions for ReceivedPO
export const receivedPOSetDetail = (payload) => ({
  type: RECEIVEDPO_SET_DETAIL,
  payload
});
export const receivedPOSearch = (payload) => ({
  type: RECEIVEDPO_SEARCH,
  payload
});

// Actions for Invoice
export const invoiceSetDetail = (payload) => ({
  type: INVOICE_SET_DETAIL,
  payload
});
export const invoiceSetLastID = (payload) => ({
  type: INVOICE_SET_LAST_ID,
  payload
});
export const invoiceAdd = (payload) => ({
  type: INVOICE_ADD,
  payload
});
export const invoiceSearch = (payload) => ({
  type: INVOICE_SEARCH,
  payload
});
export const paymentSearch=(payload)=>({
  type: PAYMENT_SEARCH,
  payload
});
// Actions for Received Invoice
export const receivedInvSearch = (payload) => ({
  type: RECEIVEDINV_SEARCH,
  payload
});

// Actions for Invoice Detail
export const invdSetDetail = (payload) => ({
  type: INVD_SET_DETAIL,
  payload
});
export const invdSetLastID = (payload) => ({
  type: INVD_SET_LAST_ID,
  payload
});
export const invdAdd = (payload) => ({
  type: INVD_ADD,
  payload
});

// Actions for Inventory
export const inventorySearch = (payload) => ({
  type: INVENTORY_SEARCH,
  payload
});
export const inventorySetDetail = (payload) => ({
  type: INVENTORY_SET_DETAIL,
  payload
});

// Actions for Compliance
export const complianceSetDetail = (payload) => ({
  type: COMPLIANCE_SET_DETAIL,
  payload
});
export const complianceSearch = (payload) => ({
  type: COMPLIANCE_SEARCH,
  payload
});

// Actions for PostProcedure
export const procedureSetDetail = (payload) => ({
  type: PROCEDURE_SET_DETAIL,
  payload
});
export const procedureSearch = (payload) => ({
  type: PROCEDURE_SEARCH,
  payload
});

// Actions for Template (Vendor, Hospital)
export const templateSearch = (payload) => ({
  type: TEMPLATE_SEARCH,
  payload
});

// Actions for Vendor Invite Search
export const inviteSearch = (payload) => ({
  type: INVITE_SEARCH,
  payload
});

// Actions for Vendor Accept Search
export const vendoracceptSearch = (payload) => ({
  type: VENDOR_ACCEPT_SEARCH,
  payload
});

// Actions for Vendor Received Search
export const receivedinviteSearch = (payload) => ({
  type: RECEIVED_INVITE_SEARCH,
  payload
});

// Actions for Hospital Accept Search
export const hospitalacceptSearch = (payload) => ({
  type: HOSPITAL_ACCEPT_SEARCH,
  payload
});

// Actions for User Search
export const userSearch = (payload) => ({
  type: USER_SEARCH,
  payload
});

// Actions for Toggle Stage
export const setToggleStage = (payload) => ({
  type: SET_TOGGLE_STAGE,
  payload
});

// Actions for Retrieve ES
export const retrieveESSearch = (payload) => ({
  type: RETRIEVE_ES_SEARCH,
  payload
});

// Actions for DAML Query
export const setDAMLSearch = (payload) => ({
  type: DAML_SEARCH,
  payload
});

// Actions for Callback Key
export const callbackKeySearch = (payload) => ({
  type: CALLBACK_KEY_SEARCH,
  payload
});

// Actions for New Search Filters
export const searchFilters = (payload) => ({
  type: SEARCH_FILTERS,
  payload
});
export const clearFilters = () => ({
  type: SEARCH_FILTERS_CLEAR,
});
