import React, { useEffect, useCallback } from "react";
import { Table, TableBody, TableRow, TableCell, Grid } from "@material-ui/core";
import { Ownerships } from "../../models/Ownership";
import { PoType, requiredPurData } from "../../models/Po";
import { requiredProduct, isEmptyProduct } from "../../models/Product";
import { filterModel } from "../../models/handles";
import { CustomSelect } from "../../components/Inputs";
import { PO_CREATE_EVENT, EVENT_REQUIRED , PRODUCT_ADD_EVENT} from "../../store/actions/constants";
import usePO from "../../hooks/usePO";
import useProduct from "../../hooks/useProduct";
import useEvent from "../../hooks/useEvent";
import EditProduct, { InputDistributor } from "../surgicalevent/edit-product1";
import useStyles from "../surgicalevent/styles";
import { useSearchSEWithModal, SearchSEWithModal } from "./se-details-modal";

const productPattern = (category, addproducts = false) => ([

  {
    label: addproducts ? "" : 'Product Details', style: { paddingTop: 0 },
  },
  {
    key: 'barcode', width: 288, colSpan: 3,
  },
  {
    key: 'sepudi', width: 288, colSpan: 3,
  },
  {
    key: 'sepreferencenumber', width: 288, colSpan: 3,
  },
  {
    key: 'seplotcode', width: 118, style: { width: '123px' },
    key1: 'sepproductexpiration', width1: 118, style1: {position: 'relative', right:"0px"},
  },
  {
    key: 'sepproductmanufacturer', width: 280,  colSpan: 2,
  },
  {
    key: 'sepproductname', width: 333, colSpan: 3,
  },
  {
    key: 'sepproductdescription', width: 333, colSpan: 3,
  },
  {
    key: 'sepproducttype', width: 118,
    key1: (category === 'seid_po' ? 'sepproductwaste' : null), width1: 118,
    key2: (addproducts ? 'sepproductside' : null), width2: 118, style2: {position: 'relative', right:"0px"},
  },
  {
    key: 'sepuom', width: 118,
    key1: 'sepproductprice', width1: 118, style1: {position: 'relative', right:"0px"},
    key2: (category === 'no_seid_po' ? 'podquantity' : null), width2: 118, type2: 'number{iquantity}',
    label2: (category === 'no_seid_po' ? 'Qty /QoO' : null), required2: true,  style2 : (category === 'no_seid_po' ? {position: 'relative' , right:"36px"} : null)
  },
  {
    key: (addproducts ? 'podquantity' : null), width: 118,
    type: (addproducts ? 'number' : null), required : true ,
    label: ( addproducts ? 'Qty': null),
    key1: (addproducts ? 'iquantity' : null), width1: 118, style1: {position: 'relative', right:"0px"},
    type1: (addproducts ? 'number' : null), required1 : true ,
    label1: ( addproducts ? 'QoO': null),
  },
]);

export function useAddEditPO() {
  const { purchaseOrderData, setPurchaseOrder, setPurchaseOrderErrors } = usePO();
  const { product: productItems, setProduct, setErrors: setProductError } = useProduct();
  const { event, status, onEventVerify, onEventReject } = useEvent();

  const handleChange = useCallback((value, key) => {
    setPurchaseOrder({
      [key]: value,
    });
    // povendorid -> sepdistributor
    if (key === 'povendorid') setProduct({
      'sepdistributor': value,
    });
  }, [setPurchaseOrder, setProduct]);  // eslint-disable-line react-hooks/exhaustive-deps

  const handleCreateAction = useCallback(() => {
    if (event === PO_CREATE_EVENT && status === EVENT_REQUIRED) {
      // check out PO data
      let newErrors = requiredPurData(purchaseOrderData);
      if (Object.keys(newErrors).length > 0) {
        setPurchaseOrderErrors({ ...newErrors });
        onEventReject();
      }
      else {
        // check out product
        if (isEmptyProduct({ ...productItems, sepproductside: "", sepdistributor: "", sepproductwaste: "" })) {
          onEventVerify();
          return;
        }
        newErrors = requiredProduct(productItems, {
          extraReqs: (purchaseOrderData.category !== 'no_seid_po' ? {
            'podquantity': 'Quantity',
          } : {}),
        });
        if (Object.keys(newErrors).length > 0) {
          setProductError({ ...newErrors });
          onEventReject();
        }
        else {
          onEventVerify();
        }
      }
    }
  }, [event, status, purchaseOrderData, productItems,
    onEventReject, onEventVerify, setPurchaseOrderErrors, setProductError]);

  const handleUpdateAction = useCallback(() => {
    if (event === PO_CREATE_EVENT && status === EVENT_REQUIRED) {
      // check out PO data
      const newErrors = requiredPurData(purchaseOrderData);
      if (Object.keys(newErrors).length > 0) {
        setPurchaseOrderErrors({ ...newErrors });
        onEventReject();
      }
      else {
        onEventVerify();
      }
    }
  }, [event, status, purchaseOrderData, onEventReject, onEventVerify, setPurchaseOrderErrors]);

  return {
    handleChange,
    handleCreateAction,
    handleUpdateAction,
  };
}

/**
 * Add New | Edit Old Purchase Order Form
 * @param {Object} options 
 * @returns 
 */
export default function AddPO({
  options = {},
}) {

  options = {
    isremovePodetails: false,
    isEnableWarnings: true,       // Show warnings once required event
    ...options
  };

  const { event, status, onEventVerify, onEventReject, onEventClear } = useEvent();

  const classes = useStyles();

  const { purchaseOrderData, purchaseOrderErrors: purchaseOrderError, setPurchaseOrderErrors: setPurchaseOrderError } = usePO();
  const { handleChange, handleCreateAction } = useAddEditPO();


  const {
    searchValue,
    handleChangeSearch,
    handleOpenAddSEModal,
    openSEModal,
    setOpenSEModal,
  } = useSearchSEWithModal();


  useEffect(() => {
    handleCreateAction();
  }, [handleCreateAction]);

  useEffect(() => {
    if (event === PRODUCT_ADD_EVENT && status === EVENT_REQUIRED) {
      const newErrors = requiredPurData(purchaseOrderData);
      if ((Object.keys(newErrors).length > 0)) {
        if (options.isEnableWarnings) setPurchaseOrderError({ ...newErrors });
        onEventReject();
      }
    }
  }, [event, status, purchaseOrderData, setPurchaseOrderError, onEventReject, onEventVerify, purchaseOrderError]); // eslint-disable-line react-hooks/exhaustive-deps
  
  //To Clear the selected values 
  useEffect(() => {
    return () => {
      onEventClear();
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid item>
        {options.isremovePodetails === true ?
          (
            <>
              <EditProduct
                options={{
                  isEnableCompliance: false,
                  isComplianceWarning: false,
                  isEnableAdd: true,
                  isCreateEditPoSE: true,
                  extraReqs: {'podquantity': 'Quantity' , 'sepproductprice' : 'Price','iquantity' : 'Quantity on Order'}
                }}
                designPattern={productPattern(purchaseOrderData.category, true)}
              />
            </>
          ) :
          (
            <>
              <Table className={classes.newSEForm}>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={3} className="new-subtitle" style={{ paddingTop: 0 }}>PO Details</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '123px' }}>
                      <CustomSelect
                        label="PO Type"
                        value={purchaseOrderData.potype || ""}
                        onChange={(val) => handleChange(val, 'potype')}
                        items={PoType}
                        placeholder="Select"
                        warning={purchaseOrderError.potype}
                        width={118}
                      />
                    </TableCell>
                    <TableCell style={{ width: '123px' }}>
                      <CustomSelect
                        label="PO Category"
                        value={purchaseOrderData.category || ""}
                        onChange={(val) => handleChange(val, 'category')}
                        items={[
                          { label: 'SEID PO', value: 'seid_po' },
                          { label: 'NO SEID PO', value: 'no_seid_po' },
                        ]}
                        placeholder="Select"
                        warning={purchaseOrderError.category}
                        width={118}
                      />
                    </TableCell>
                    <TableCell>
                      {
                        purchaseOrderData.category === 'seid_po' &&
                        <SearchSEWithModal
                          searchValue={searchValue}
                          handleChangeSearch={handleChangeSearch}
                          handleOpenAddSEModal={handleOpenAddSEModal}
                          openSEModal={openSEModal}
                          setOpenSEModal={setOpenSEModal}
                          style={{ marginTop: '20px' }}
                          width={118}
                        />
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <InputDistributor
                        label="Distributor"
                        required={true}
                        distributor={purchaseOrderData.povendorid}
                        setDistributor={(val) => handleChange(val, 'povendorid')}
                        placeholder="Select"
                        warning={purchaseOrderError.povendorid}
                        width={118}
                      />
                    </TableCell>
                    <TableCell>
                      <CustomSelect
                        label="Ownership"
                        required={true}
                        value={purchaseOrderData.popurchaseordertype || ""}
                        onChange={(val) => handleChange(val, 'popurchaseordertype')}
                        items={filterModel(Ownerships, ['Unknown'])}
                        placeholder="Select"
                        warning={purchaseOrderError.popurchaseordertype}
                        width={118}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <EditProduct
                options={{
                  isEnableCompliance: false,
                  isComplianceWarning: false,
                  isEnableAdd: true,
                  isCreateEditPoSE: true,
                  extraReqs: {
                    ...(purchaseOrderData.category === 'no_seid_po' ? { 'podquantity': 'Quantity' } : null),
                    'sepproductprice': 'Price'
                  }
                }}
                designPattern={productPattern(purchaseOrderData.category)}
              /></>

          )
        }</Grid>
    </Grid>
  );
}
