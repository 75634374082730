import React, { useState } from "react";
import { Typography, DialogActions, Button, Accordion, AccordionDetails, AccordionSummary,
  Dialog,DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { ExpandMore} from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';
import { requiredProduct } from "../../models/Product";
import EditProduct from './edit-product';
import editIcon  from "../../icons/edit.svg";
import deleteIcon  from "../../icons/delete.svg";
import ModalDialogBox from '../../components/Modals/GeneralModal/modalDialogBox';
import useStyles from "./styles";


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
  
/**
 * Add or Edit Product 
 * It has Barcode Scanner & Retrieve ES, GUDID
 * @param {Boolean} isCreate 
 * @param {Array} productItemsList 
 * @param {Object} productItems 
 * @param {Function} setProductItems 
 * @param {Object} productError 
 * @param {Function} setProductError 
 * @param {Object} surgicalEventData 
 * @param {String} hospital 
 * @param {Boolean} openModal 
 * @param {Function} setOpenModal 
 * @param {Function} setDeclineConfrimModal 
 * @param {Function} handleDeclineProductConfirmed 
 * @param {Function} handleDeleteProduct 
 * @param {Function} handleUpdateProduct 
 * @param {Function} handleValueFill 
 * @param {Object} options 
 * @returns 
 */
export default function AddEditProduct ({
  isCreate, productItemsList, productItems, setProductItems,
  productError,setProductError, surgicalEventData, hospital,
  openModal: openDeclineConfrimModal=false, setOpenModal: setDeclineConfrimModal,
  handleDeclineProductConfirmed, handleDeleteProduct, handleUpdateProduct, handleValueFill,
  options={}, 
}) {

  options = {
    isEnableEditProduct: true,
    isEnableDeleteProduct: true,
    showInfoAlert: true,
    infoAlertText: "You can also add Products from a scanner.",
    topMargin: 0,
    isFetchInventory: false,      // If true, then it fetches Account Inventory with Received and change fields as same
    ...options,
    edit: {
      ...options.edit,
    },
    update: {
      ...options.update,
    },
  };

  const classes = useStyles();
  // const { role, party } = useDamlState();
  // const { token } = useUserState();
  // const user = { role, party, token };

  const [expanded, setExpanded] = useState(0);
  const [itemEditIndex, setitemEditIndex] = useState('');
  const [openEditModal, setOpenEditModal] = useState(false);
  const [itemIndex, setItemIndex] = useState(0);
  const [openDeleteEditModal, setOpenDeleteModal] = useState(false);
  const [retrieveStatus, setRetrieveStatus] = useState('disable');
  const [productEditItems, setEditProductItems] = useState(productItems);


  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const cancelHandler = () => {
    setOpenEditModal(false);
    setProductError({});
  };
  
  const handleEditOpen = (status,index) => {
    console.log("[AddEditProduct] handleEditOpen", productItemsList, productItemsList[index]);
    setOpenEditModal(status);
    setEditProductItems({
      ...productItemsList[index],
      acknowledge: 'Acknowledged',
      acknowledgeProduct: 'Acknowledged',
    });
    setProductError({});
    setitemEditIndex(index);
  };

  const handleClickDeleteOpen = (index) => {
    setItemIndex(index);
    setOpenDeleteModal(true);
  };

  // click "Update" button
  const updateEditedProduct = async () => {
    console.log("[AddEditProduct] updateEditedProduct", productEditItems);

    let newErrors = requiredProduct(productEditItems);
    if ((Object.keys(newErrors).length > 0)) {
      setProductError({ ...newErrors });
      return false;
    }

    if (typeof handleUpdateProduct === 'function') await handleUpdateProduct(productEditItems, itemEditIndex);

    // productItemsList[itemEditIndex] = productEditItems;
    // SetProductItemsList(productItemsList);
    setOpenEditModal(false);
    setProductError({});
  };

  // click Delete Icon in Products
  const handleDeleteItem = async () => {
    if (typeof handleDeleteProduct === 'function') await handleDeleteProduct(itemIndex, productItemsList[itemIndex]);
    setOpenDeleteModal(false);
  };

  const handleDeclineItem = () => {
    handleDeclineProductConfirmed();
    setDeclineConfrimModal(false);
  };
  const setDeclineConfrimModalManual = () => {
    setDeclineConfrimModal(false);
  };
  const setOpenDeleteModalManual = () => {
    setOpenDeleteModal(false);
  };

  /* handleRetrieve Old version
  const handleRetrieve = useCallback(({udi, ref, isForce=false, product={}}) => {
    // check if it's enable to retrieve
    if ((!!udi || !!ref) && ((isForce && retrieveStatus !== 'loading') || retrieveStatus === 'enable')) {
      setRetrieveStatus('loading');
      let searchParams = {}, di = udi;
      if (!!udi) {
        if (udi.length > 13) udi = udi.slice(udi.length - 13, udi.length);
        if (di.length > 14) di = di.slice(di.length - 14, di.length);
        else if (di.length < 14) di = `1${di}`;
        // searchParams = { result: udi };
        searchParams = { query: {Udi: udi, Hospital: hospital} };
      }
      else {
        // searchParams = { result: ref, field: 'ReferenceNumber' };
        searchParams = { query: {ReferenceNumber: ref, Hospital: hospital} };
      }
      // console.log("[AddEditProduct] isUseGUDID: ", isUseGUDID, searchParams, "product", product);

      var tempData = {};
      if (openEditModal) {
        tempData = {
          ...productEditItems,
          ...product,
        };
      }
      else {
        tempData = {
          ...productItems,
          ...product,
        };
      }

      const callRetrieveES = (searchParams, opt) => {
        // Retrieve from ElasticSearch
        retrieveES(searchParams, async (data) => {
          if (data) {
            console.log("[AddEditProduct] retrieveES: ", data, tempData);
            tempData = {
              ...tempData,
              ...data,
            };
            // To get Ownership,
            // check out fetching Account Inventory
            if (options.isFetchInventory) {
              const invproduct = await damlFetchInventory({
                token: user.token,
                hospital,
                referencenumber: tempData.sepreferencenumber,
                lotcode: tempData.seplotcode,
                ownership: tempData.sepownership,
              });
              console.log("[AddEditProduct] retrieveES: damlFetchInventory", invproduct);
              let newownership;
              if (invproduct && 'sepownership' in invproduct) {
                newownership = invproduct.sepownership;
              }
              else {
                if (!tempData.seplotcode) {
                  newownership = "Loaned";
                }
                else {
                  newownership = "Unknown";
                }
              }
              if (newownership) {
                tempData = {
                  ...tempData,
                  sepownership: newownership,
                };
              }
            }
          }
          if (openEditModal) {
            setEditProductItems({
              ...tempData,
            });
          }
          else {
            setProductItems({
              ...tempData,
            });
          }
          setRetrieveStatus('finished');
        }, null, opt);
      };

      const callRetrieveGUDID = (searchParams, opt, nextSearchParams=null) => {
        // Retrieve from accessgudid
        retrieveGUDID(searchParams, (data) => {
          console.log("[AddEditProduct] retrieveGUDID: ", data);
          if (data) {
            // if response is okay, then
            tempData = {
              ...tempData,
              ...data,
            };
            // update searchParams from GUDID response
            if ('sepreferencenumber' in data) {
              searchParams = { query: {ReferenceNumber: data.sepreferencenumber, Hospital: hospital} };
            }
          }
          else {
            // if response is error or none, then
            if (nextSearchParams !== null) {
              // console.log("[AddEditProduct] retrieveGUDID: ", nextSearchParams);
              callRetrieveGUDID(nextSearchParams, opt);
            }
          }

          if (data || nextSearchParams === null) {
            callRetrieveES(searchParams, opt);
          }
        }, opt);
      };

      if (isUseGUDID && !!udi) {
        const opt = {
          // isForce: true,
          user,
          vendors,
        };
        if (options.edit.disableParams) opt.disableParams = options.edit.disableParams;
        
        callRetrieveGUDID({ udi: udi }, opt, { di: di });
      }
      else {
        const opt = {
          isExpLot: true,
          user,
          vendors,
        };
        if (options.edit.disableParams) opt.disableParams = options.edit.disableParams;

        callRetrieveES(searchParams, opt);
      }
    }
  }, [productItems, setProductItems, retrieveStatus, openEditModal, productEditItems, user, options, hospital, vendors]);
  */
  // console.log("[AddEditProduct]", productItemsList);
    

  return (
    <div className="formsteps"> 
      <h1 className={classes.formHeading}>
        {isCreate ? 
        'Add Product' : 
        'Add/Update Product'}
      </h1>

      <div className={classes.mainFormSteps}>

        <div style={{paddingTop: (options.topMargin > 0 ? options.topMargin : 0)}}> 
          
          {productItemsList.length !== 0 && 
            <div className={classes.productAccordion}>
                
              <Accordion expanded={expanded === 0} onChange={handleChangeAccordion(0)}  className={classes.mainAccordion}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  className={classes.accordionParentHeading}
                >
                  <Typography className={classes.accordionHeading}>Added Product</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.itemList}>
                    {productItemsList.map((obj,index)=>(
                      <div key={index}  className={classes.productItemList}>
                        <span className="list-title">Ref Number : {obj.sepreferencenumber} </span> 
                        <span className="list-title">Lot Code : {obj.seplotcode} </span> 
                        {options.isEnableEditProduct && 
                          <span onClick={(e)=>{handleEditOpen(true,index)}}  className={classes.editIcon} >
                            <img src={editIcon} alt="" />
                          </span>
                        } 
                        {options.isEnableDeleteProduct && 
                          <span className={classes.deleteIcon} onClick={(e)=>{handleClickDeleteOpen(index)}}>
                            <img src={deleteIcon} alt=""/>
                          </span>
                        } 
                      </div>
                    ))}{null}
                  </div>
                </AccordionDetails>
              </Accordion>
              
            </div> 
          }

          {options.showInfoAlert ? 
          <Alert severity="info">
            {options.infoAlertText}
          </Alert>
          : ""}

          <EditProduct
            productItems={productItems}
            setProductItems={setProductItems}
            productError={productError}
            surgicalEventData={surgicalEventData}
            // handleRetrieve={handleRetrieve}
            retrieveStatus={retrieveStatus}
            setRetrieveStatus={setRetrieveStatus}
            options={options.edit}
            handleDeclineProduct={setDeclineConfrimModal}
            handleValueFill={handleValueFill}
            hospital={hospital}
          />

        </div>

        <ModalDialogBox
          open={openDeleteEditModal}
          setOpen={setOpenDeleteModal}
          heading="Warning"
          type="warning"
          text={'<p>You can not undo this item after delete!. Please confirm?<p>'}
          handleRequest = {handleDeleteItem}
          secondBtn= {"Go Back"}
          firstBtn={"I Confirm"}
          handleSecRequest= {setOpenDeleteModalManual}
        />


        <Dialog
          className={classes.modalContentManagebox}
          open={openEditModal}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle className="dialog-title-borderbm">
            Edit Product
          </DialogTitle>

          <div className="modalContentManage-edit">
          <DialogContent>
            <DialogContentText component={'div'}>

            <div className="formsteps sub-forms"> 
              <div className={classes.mainFormSteps}>
              <div style={{marginTop: 30}}> 
                <div className="Form-content">

                  <EditProduct 
                    productItems = {productEditItems}
                    setProductItems={setEditProductItems}
                    productError= {productError}
                    surgicalEventData={surgicalEventData}
                    // handleRetrieve={handleRetrieve}
                    retrieveStatus={retrieveStatus}
                    setRetrieveStatus={setRetrieveStatus}
                    options={{
                      isEnableScan: false,
                      ...options.edit, // TODO : decide to show scanner or not once edit product.
                      ...options.update, // Options only for Update Product
                    }}
                    hospital={hospital}
                  />

                </div>
              
              </div>
              </div>
            </div>

            </DialogContentText>
          </DialogContent>
          </div>

          <DialogActions className="dialog-btn-group">
            <Button onClick={cancelHandler} className="noBtn" variant="text"  color="primary">
              Cancel
            </Button>
            <Button onClick={updateEditedProduct} className="yesBtn" variant="contained" color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>

        <ModalDialogBox
          open={openDeclineConfrimModal}
          setOpen={setDeclineConfrimModal}
          heading="Warning"
          type="warning"
          text={'<p>The Product will be removed from the surgical Event.  If the product was opened to the field but wasted, you need to Click "Acknowledge" and choose "Wasted" as a product type.<p>'}
          handleRequest = {handleDeclineItem}
          secondBtn= {"Go Back"}
          firstBtn={"I Confirm"}
          handleSecRequest= {setDeclineConfrimModalManual}
        />
      
        
      </div>
    </div>
  );
}
           