import { HospitalRole, VendorRole } from "../../services/daml-modules1";
import { getTemplateListFromResponse } from "../../services/util";
import { loggerPush, LOGGER_INFO, LOGGER_ERROR } from '../../services/logger';
import { validateReturnType, validateInventoryData, getInventoryData } from "../../models/InventoryData";
import { validateInventoryProduct, getInventoryProduct } from "../../models/InventoryProduct";


/**
 * create Account Inventory
 * exercise MakeAccountInventory choice of HospitalRole
 * @param {Object} ledger 
 * @param {Object} inventorydata 
 * @param {String} roleCid 
 * @param {String} userlistPayload
 * @returns {Object|null}
 */
export const createAccountInventoryHospital = async ({
  ledger, inventorydata = {}, roleCid, userlistPayload
}) => {
  const email = userlistPayload?.email;
  const username = userlistPayload?.name;
  console.log("[createAccountInventory]", roleCid, inventorydata);

  try {

    if (roleCid) {

      let res = await ledger.exercise(HospitalRole.MakeAccountInventory, roleCid, {
        inventorydata: getInventoryData(inventorydata),
        useremail: email,
        username: username,
      });
      console.log("[createAccountInventory]", res);

      // get ContractId, old InventoryData, new InventoryData from res
      /* if (res[0] && '_3' in res[0] && !!res[0]['_3']) {
        return {
          contractId: res[0]['_1'],
          newInventorydata: res[0]['_2'],
          oldInventorydata: res[0]['_3'],
          popurchaseorderid: res[0]['_3'].ipurchaseordernumber,
        };
      } */
      
      // log the response to loggly
      loggerPush({ type: LOGGER_INFO, action: 'Inven: MakeAccountInventory', response: res });
      
      return res[1];

    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Inven: MakeAccountInventory', payload: e, params: inventorydata });
    console.log("[createAccountInventory]", e);
  }

  return null;

};

/**
 * create Restock Inventory
 * exercise MakeRestockInventory choice of HospitalRole
 * It needs to find matched Inventory Data in Restock Inventory before calling.
 * @param {Object} ledger 
 * @param {Object} inventorydata 
 * @param {String} roleCid 
 * @returns {Object|null}
 */
export const createRestockInventoryHospital = async ({
  ledger, inventorydata = {}, roleCid,
}) => {

  console.log("[createRestockInventoryHospital]", roleCid, inventorydata);
  let params = {};

  try {

    if (roleCid) {
      params = {
        inventorydata: getInventoryData(inventorydata),
      };
      if (!!inventorydata.index) params.inventoryindex = inventorydata.index;

      let res = await ledger.exercise(HospitalRole.MakeRestockInventory, roleCid, params);
      console.log("[createRestockInventoryHospital]", res);

      // get ContractId, old InventoryData, new InventoryData from res
      /* if (res[0] && '_3' in res[0] && !!res[0]['_3']) {
        return {
          contractId: res[0]['_1'],
          newInventorydata: res[0]['_2'],
          oldInventorydata: res[0]['_3'],
          popurchaseorderid: res[0]['_3'].ipurchaseordernumber,
        };
      } */
      
      // log the response to loggly
      loggerPush({ type: LOGGER_INFO, action: 'Inven: MakeRestockInventory', response: res });

      return res[1];

    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Inven: MakeRestockInventory', payload: e, params: params });
    console.log("[createRestockInventoryHospital]", e);
  }

  return null;

};

/**
 * create Restock Inventory
 * exercise CreateRestockInventory choice of VendorRole
 * It needs to find matched Inventory Data in Restock Inventory before calling.
 * @param {Object} ledger 
 * @param {String} hospital 
 * @param {Object} inventorydata 
 * @param {String} roleCid 
 * @param {String} newiproductstatus 
 * @returns {Object|null}
 */
export const createRestockInventoryVendor = async ({
  ledger, hospital, inventorydata = {}, roleCid, newiproductstatus, 
}) => {

  console.log("[createRestockInventoryVendor]", roleCid, hospital, inventorydata);
  let params = {};

  try {

    if (roleCid) {
      params = {
        hospital,
        inventorydata: getInventoryData(inventorydata),
        newiproductstatus,
      };
      if (!!inventorydata.index) params.inventoryindex = inventorydata.index;

      let res = await ledger.exercise(VendorRole.CreateRestockInventory, roleCid, params);
      console.log("[createRestockInventoryVendor]", res);

      // log the response to loggly
      loggerPush({ type: LOGGER_INFO, action: 'Inven: CreateRestockInventory', response: res });

      return res;
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Inven: CreateRestockInventory', payload: e, params: params });
    console.log("[createRestockInventoryVendor]", e);
  }

  return null;

};

/**
 * create ReturnRequest using inventorydata
 * exercise HInvenReturnRequestDataWithLog choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} ContractId 
 * @param {String} inventorydata 
 * @param {String} newireturntype 
 * @param {String} newireturnmessage 
 * @param {String} newireturnuser 
 * @param {Number} inventoryindex 
 * @param {String} roleCid 
 * @param {String} userlistPayload
 * @returns {Object}
 */
export const createReturnRequest = async ({
  ledger, ContractId, inventorydata, newireturntype, newireturnmessage, newireturnuser, inventoryindex, roleCid, userlistPayload,
}) => {
  const email = userlistPayload?.email;
  const username = userlistPayload?.name;
  console.log("[createReturnRequest]" + ContractId, inventorydata);

  try {

    if (!ContractId || !validateInventoryData(inventorydata) || !newireturnuser
      || !validateReturnType(newireturntype)) return;

    if (typeof inventoryindex === 'undefined') inventoryindex = inventorydata.index;

    let res = await ledger.exercise(HospitalRole.HInvenReturnRequestDataWithLog, roleCid, {
      inventorycid: ContractId,
      oldinventorydata: getInventoryData(inventorydata),
      oldinventoryindex: inventoryindex,
      newireturntype,
      newireturnmessage: newireturnmessage ?? "",
      newireturnuser,
      useremail: email,
      username: username,
    });
    console.log("[createReturnRequest]", res);

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'Inven: HInvenReturnRequestData', response: res });

    let templatePO = getTemplateListFromResponse(res, "AccountInventory");
    if (templatePO.length) {
      return templatePO[0];
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Inven: HInvenReturnRequestData', payload: e, params: {inventorydata, newireturnmessage} });
    console.log("[createReturnRequest]", e);
  }

  return null;

};

/**
 * create Response to ReturnRequest using inventorydata
 * exercise VInvenReturnResponseData choice of VendorRole
 * @param {Object} ledger 
 * @param {String} ContractId 
 * @param {String} inventorydata 
 * @param {Boolean} newireturnrequestdecision 
 * @param {String} newireturnrequestcomment 
 * @param {String} newireturnrequestdecisionuser 
 * @param {Number} inventoryindex 
 * @param {String} roleCid 
 * @returns {Object}
 */
export const createReturnResponse = async ({
  ledger, ContractId, inventorydata, newireturnrequestdecision, newireturnrequestcomment, newireturnrequestdecisionuser, 
  inventoryindex, roleCid,
}) => {

  console.log("[createReturnResponse]" + ContractId, inventorydata, newireturnrequestdecision);

  try {

    if (!ContractId || !validateInventoryData(inventorydata) || !newireturnrequestdecisionuser
      || typeof newireturnrequestdecision !== 'boolean') return;
      
    if (typeof inventoryindex === 'undefined') inventoryindex = inventorydata.index;

    let res = await ledger.exercise(VendorRole.VInvenReturnResponseData, roleCid, {
      inventorycid: ContractId,
      oldinventorydata: getInventoryData(inventorydata),
      oldinventoryindex: inventoryindex,
      newireturnrequestdecision,
      newireturnrequestdecisionuser,
      newireturnrequestcomment,
    });
    console.log("[createReturnResponse]", res);

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'Inven: VInvenReturnResponseData', response: res });

    let templatePO = getTemplateListFromResponse(res, "AccountInventory");
    if (templatePO.length) {
      return templatePO[0];
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Inven: VInvenReturnResponseData', payload: e, params: {inventorydata, newireturnrequestcomment} });
    console.log("[createReturnResponse]", e);
  }

  return null;

};

/**
 * update InventoryProduct
 * exercise HInvenUpdate choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} ContractId 
 * @param {Object} newinventoryproduct 
 * @param {String} roleCid 
 */
export const updateInventoryProduct = async ({
  ledger, ContractId, newinventoryproduct, roleCid,userlistPayload
}) => {
  const email = userlistPayload?.email;
  const username = userlistPayload?.name;
  console.log("[updateInventoryProduct]", ContractId, newinventoryproduct);

  try {

    if (!ContractId || !validateInventoryProduct(newinventoryproduct)) return;
    
    let res = await ledger.exercise(HospitalRole.HInvenUpdateWithLog, roleCid, {
      inventorycid: ContractId,
      newinventoryproduct: getInventoryProduct(newinventoryproduct),
      useremail: email,
      username: username,
    });
    console.log("[updateInventoryProduct]", res);

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'Inven: HInvenUpdate', response: res });
  
  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Inven: HInvenUpdate', payload: e, params: newinventoryproduct });
    console.log("[updateInventoryProduct]", e);
  }

};

/**
 * add InventoryData
 * exercise HInvenAddData choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} ContractId 
 * @param {Object} inventorydata 
 * @param {String} newitemprequestuser 
 * @param {String} newitemprequestmessage 
 * @param {String} roleCid 
 * @returns {String}
 */
 export const addInventoryData = async ({
  ledger, ContractId, inventorydata, newitemprequestuser, newitemprequestmessage="", roleCid,
}) => {

  console.log("[addInventoryData]", ContractId, inventorydata);

  try {

    if (!ContractId || !validateInventoryData(inventorydata)) return ContractId;

    let res = await ledger.exercise(HospitalRole.HInvenAddData, roleCid, {
      inventorycid: ContractId,
      newinventorydata: getInventoryData(inventorydata),
      newitemprequestuser,
      newitemprequestmessage: newitemprequestmessage ?? "",
    });
    console.log("[addInventoryData]", res);

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'Inven: HInvenAddData', response: res });

    return res[0] ? res[0] : null;

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Inven: HInvenAddData', payload: e, params: inventorydata });
    console.log("[addInventoryData]", e);
  }

};

/**
 * update InventoryData
 * exercise HInvenUpdateData choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} ContractId 
 * @param {Object} updateinventorydata 
 * @param {String} newitemprequestuser 
 * @param {String} newitemprequestmessage 
 * @param {Number} inventoryindex 
 * @param {String} roleCid 
 * @returns {Object}
 */
export const updateInventoryData = async ({
  ledger, ContractId, updateinventorydata, newitemprequestuser, newitemprequestmessage="", inventoryindex, roleCid,userlistPayload,
}) => {
  const email = userlistPayload?.email;
  const username = userlistPayload?.name;
  console.log("[updateInventoryData]", ContractId, updateinventorydata, newitemprequestmessage);

  try {

    if (!ContractId || !validateInventoryData(updateinventorydata)) return;

    if (typeof inventoryindex === 'undefined') inventoryindex = updateinventorydata.index;

    let res = await ledger.exercise(HospitalRole.HInvenUpdateData, roleCid, {
      inventorycid: ContractId,
      updateinventorydata: getInventoryData(updateinventorydata),
      updateinventoryindex: inventoryindex,
      newitemprequestuser,
      newitemprequestmessage: newitemprequestmessage ?? "",
      useremail: email,
      username: username,
    });
    console.log("[updateInventoryData]", res);

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'Inven: HInvenUpdateData', response: res });

    let templatePO = getTemplateListFromResponse(res, "AccountInventory");
    if (templatePO.length) {
      return templatePO[0];
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Inven: HInvenUpdateData', payload: e, params: updateinventorydata });
    console.log("[updateInventoryData]", e);
  }

  return null;

};

/**
 * remove InventoryData
 * exercise HInvenRemoveData choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} ContractId 
 * @param {Object} inventorydata 
 * @param {String} newitemprequestuser 
 * @param {String} newitemprequestmessage 
 * @param {Number} inventoryindex 
 * @param {Object} 
 */
export const removeInventoryData = async ({
  ledger, ContractId, inventorydata, newitemprequestuser, newitemprequestmessage="", inventoryindex, roleCid, userlistPayload
}) => {
  const email = userlistPayload?.email;
  const username = userlistPayload?.name;
  console.log("[removeInventoryData]", ContractId, inventorydata);

  try {

    if (!ContractId || !validateInventoryData(inventorydata)) return;

    if (typeof inventoryindex === 'undefined') inventoryindex = inventorydata.index;

    let res = await ledger.exercise(HospitalRole.HInvenRemoveData, roleCid, {
      inventorycid: ContractId,
      removeinventorydata: getInventoryData(inventorydata),
      removeinventoryindex: inventoryindex,
      newitemprequestuser,
      newitemprequestmessage: newitemprequestmessage ?? "",
      useremail: email,
      username: username,
    });
    console.log("[removeInventoryData]", res);

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'Inven: HInvenRemoveData', response: res });

    let templatePO = getTemplateListFromResponse(res, "AccountInventory");
    if (templatePO.length) {
      return templatePO[0];
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Inven: HInvenRemoveData', payload: e, params: inventorydata });
    console.log("[removeInventoryData]", e);
  }

  return null;

};

/**
 * accept/decline edited InventoryData
 * exercise VInvenAcceptDecline choice of VendorRole
 * @param {Object} ledger 
 * @param {String} ContractId 
 * @param {Object} oldinventorydata 
 * @param {Boolean} newitempresponsedecision 
 * @param {String} newitempresponseuser 
 * @param {String} newitempresponsemessage 
 * @param {Number} inventoryindex 
 * @param {String} roleCid 
 * @returns {Object}
 */
export const acceptDeclineInventoryData = async ({
  ledger, ContractId, inventorydata, newitempresponsedecision, newitempresponseuser, newitempresponsemessage, 
  inventoryindex, roleCid,
}) => {

  console.log("[acceptDeclineInventoryData]", ContractId, inventorydata, newitempresponsedecision);

  try {

    if (!ContractId || !validateInventoryData(inventorydata)) return;

    if (typeof inventoryindex === 'undefined') inventoryindex = inventorydata.index;

    let res = await ledger.exercise(VendorRole.VInvenAcceptDecline, roleCid, {
      inventorycid: ContractId,
      oldinventorydata: getInventoryData(inventorydata),
      oldinventoryindex: inventoryindex,
      // newitempresponsedecision,
      // newitempresponseuser,
      // newitempresponsemessage,
    });
    console.log("[acceptDeclineInventoryData]", res);

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'Inven: VInvenAcceptDecline', response: res });
    
    let templatePO = getTemplateListFromResponse(res, "InventoryRequestHistory");
    if (templatePO.length) {
      return templatePO[0];
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Inven: VInvenAcceptDecline', payload: e, params: {inventorydata, newitempresponsemessage} });
    console.log("[acceptDeclineInventoryData]", e);
  }

  return null;

};

/**
 * remove edited InventoryData
 * exercise HInvenRemoveRequestHistory choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} ContractId 
 * @param {Object} oldinventorydata 
 * @param {Number} inventoryindex 
 * @param {String} roleCid 
 * @returns {Object}
 */
export const removeInventoryRequestData = async ({
  ledger, ContractId, inventorydata, inventoryindex, roleCid,
}) => {

  console.log("[removeInventoryRequestData]", ContractId, inventorydata);

  try {

    if (!ContractId || !validateInventoryData(inventorydata)) return;

    if (typeof inventoryindex === 'undefined') inventoryindex = inventorydata.index;

    let res = await ledger.exercise(HospitalRole.HInvenRemoveRequestHistory, roleCid, {
      inventorycid: ContractId,
      oldinventorydata: getInventoryData(inventorydata),
      oldinventoryindex: inventoryindex,
    });
    console.log("[removeInventoryRequestData]", res);

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'Inven: HInvenRemoveRequestHistory', response: res });
    
    let templatePO = getTemplateListFromResponse(res, "InventoryRequestHistory");
    if (templatePO.length) {
      return templatePO[0];
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Inven: HInvenRemoveRequestHistory', payload: e, params: {inventorydata} });
    console.log("[removeInventoryRequestData]", e);
  }

  return null;

};

/**
 * update Open Orders in Account Inventory
 * exercise HInvenUpdateOpenOrders choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} ContractId 
 * @param {String} newopenorders
 * @param {String} roleCid 
 * @returns {Object}
 */
export const updateOpenOrders = async ({
  ledger, ContractId, newopenorders, roleCid, 
}) => {
  console.log("[updateOpenOrders]" + ContractId, newopenorders);

  try {

    if (!ContractId || newopenorders === undefined || !roleCid) return;

    let res = await ledger.exercise(HospitalRole.HInvenUpdateOpenOrders, roleCid, {
      inventorycid: ContractId,
      newopenorders,
    });
    console.log("[updateOpenOrders]", res);

    // log the response to loggly
    // loggerPush({ type: LOGGER_INFO, action: 'Inven: HInvenUpdateOpenOrders', response: res });

    let templatePO = getTemplateListFromResponse(res, "AccountInventory");
    if (templatePO.length) {
      return templatePO[0];
    }

  }
  catch (e) {
    // log the error to loggly
    // loggerPush({ type: LOGGER_ERROR, action: 'Inven: HInvenUpdateOpenOrders', payload: e, params: {inventorydata, newireturnmessage} });
    console.log("[updateOpenOrders]", e);
  }

  return null;

};

/**
 * accept matched InventoryData & PO
 * exercise HInvenMatchData choice of HospitalRole
 * Now, this choice is removed.
 * @param {Object} ledger 
 * @param {String} ContractId 
 * @param {Object} oldinventorydata 
 * @param {Object} newinventorydata 
 * @param {String} roleCid 
 */
export const matchInventoryData = async ({
  ledger, ContractId, oldinventorydata, newinventorydata, roleCid,
}) => {

  console.log("[matchInventoryData]", ContractId, oldinventorydata, newinventorydata);

  /*
  if (!ContractId || !validateInventoryData(oldinventorydata) || !validateInventoryData(newinventorydata)) return;

  let res = await ledger.exercise(HospitalRole.HInvenMatchData, roleCid, {
    inventorycid: ContractId,
    oldinventorydata: getInventoryData(oldinventorydata),
    newinventorydata: getInventoryData(newinventorydata),
  });
  console.log("[matchInventoryData]", res);
  */
};
