import React, { useState, useEffect } from "react";
import { useParty, useLedger, useStreamQueries } from "@daml/react";
import { Button, Typography } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { HospitalTemplate } from "../../services/daml-modules1";
import AddEditHospitalTemplate from './addedithospitaltemplate';
import HospitalTemplateContracts from "./hospitaltemplatecontracts";
import { dispatch, getState } from "../../store";
import { templateSearch } from "../../store/actions/generalActions";
import { createHospitalTemplate, updateHospitalTemplate } from "./handle";
import { useDamlState } from "../../context/DamlContext";
import useStyles from "../surgicalevent/styles";


/**
 * Hospital Template Page in Vendor
 * @returns 
 */
export default function HospitalTemplateList() {

  const classes = useStyles();
  const ledger = useLedger();
  const vendor = useParty();
  const { contracts } = useStreamQueries(HospitalTemplate, () => [{ vendor }]);
  const { roleCid } = useDamlState();

  const [ContractId, setContractId] = useState('');
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({});

  // AddEditHospitalTemplate
  const [open, setOpen] = useState(false);
  const [isCreate, setCreate] = useState(true);
  const [selectedHospitalTemplate, selectHospitalTemplate] = useState({});
  const [hospital, setHospital] = useState('');


  const [searchHospitalTemplate, setSearchHospitalTemplate] = useState({
    hospital: '',
    ...getState().template,
  });
  const handleChangeSearchValue = (val, key) => {
    setSearchHospitalTemplate({ ...searchHospitalTemplate, [key]: val });
    dispatch(templateSearch({ key, value: val }));
  };


  useEffect(() => {
    setLoading(true);

    let temp = {};

    if (searchHospitalTemplate.hospital !== "") temp.hospital = searchHospitalTemplate.hospital;
    temp.vendor = vendor;

    const delay = 0;
    if (delay > 0) setTimeout(() => {
      setSearchParams(temp);
    }, delay);
    else {
      setSearchParams(temp);
    }
  }, [searchHospitalTemplate, vendor]);

  useEffect(() => {
    setLoading(false);
  }, [searchParams]);


  // handle when click "Create" or "Update" button in Create Hospital Template modal
  const handleRequest = async (newhospital) => {
    if (isCreate) await createHospitalTemplate({ledger, roleCid, newhospital, hospital, setContractId});
    else await updateHospitalTemplate({ledger, roleCid, ContractId, newhospital, hospital});
  };


  return (
    <>
      <div key="formChildStep" className="formsteps">
        <Typography variant="h4" className={classes.pageTitle}>Hospital Template</Typography>

        <Button
          size="small"
          className={classes.newSetupButton}
          onClick={() => {
            setCreate(true);
            setHospital("");
            selectHospitalTemplate({});
            setOpen(true);
          }}
          variant="contained"
        >
          <span className="desktop">Create Hospital Template</span>
          <span className="mobile"><AddIcon /></span>
        </Button>

        <HospitalTemplateContracts
          searchParams={searchParams}
          handleChangeSearchValue={handleChangeSearchValue}
          allContracts={contracts}
          searchHospitalTemplate={searchHospitalTemplate}
          handleRequest={(data) => {
            // console.log("[HospitalTemplateList] handleRequest", data.payload);
            setHospital(data.payload.hospital);
            setContractId(data.contractId);
            selectHospitalTemplate(data.payload);
            setCreate(false);
            setOpen(true);
          }}
          loading={loading}
        />

      </div>

      <AddEditHospitalTemplate
        isCreate={isCreate}
        open={open}
        setOpen={setOpen}
        handleRequest={handleRequest}
        title={"Create Hospital Template"}
        editTitle={"Edit Hospital Template"}
        defaultValue={selectedHospitalTemplate}
        hospital={hospital}
        setHospital={setHospital}
      />

    </>
  );
};