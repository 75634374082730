import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@material-ui/core";
import selectIcon from '../../icons/select-indicator.svg';
import useStyles from "./styles";
import { useLayoutState } from "../../context/LayoutContext";

/**
 * New Custom Select
 * @param {String} value 
 * @param {Function} onChange 
 * @param {Array|Object} items 
 * @param {String} label 
 * @param {String} placeholder 
 * @param {Object} className 
 * @param {Object} options 
 * @param {String} warning 
 * @param {Function} checkWarnings 
 * @param {Number} width 
 * @param {Object} style 
 * @param {Boolean} required 
 * @returns 
 */
export default function CustomSelect ({
  value="", onChange, items, label, placeholder, className, options={}, 
  warning="", checkWarnings, width=200, style={}, required, 
}) {

  options = {
    disabled: false,
    labelLeft: false,       // Label position Left
    labelWidth: null,       // Label width {Number|null}
    readOnly: false,        // Read Only
    disableHelper: false,   // Disable to show Helper Text
    ...options,
  };

  // items from Object
  items = (items ? (items.length === undefined ? (
      Object.entries(items).map(([key, val]) => ({label: val, value: key}))
    ) : items) : [])
    .map(item => (typeof item === 'object' ? item : {label: item, value: item}));
  items = (placeholder ? (
    [{label: placeholder, value: "", disabled: true}].concat(items)
  ): items);

  const classProps = {
    labelWidth: (typeof options.labelWidth === 'number' ? options.labelWidth : 110),
  };
  const classes = useStyles(classProps);

  const [open, setOpen] = useState(false);
  const [helper, setHelper] = useState(warning);
  const [readOnly, setReadOnly] = useState(options.readOnly);

  // class name list
  const layoutState = useLayoutState()
  const classnames = [layoutState.darkMode?classes.inputDM:classes.input, classes.select];
  if (className) classnames.push(className);
  if (readOnly) classnames.push(classes.readOnly);
  if (options.labelLeft) classnames.push("label-left");
  // width
  width = options.labelLeft ? classProps.labelWidth + width : width;


  const handleChange = (event) => {
    const newVal = event.target.value;
    if (typeof onChange === 'function') {
      onChange(newVal, event);
    }
    if (typeof checkWarnings === 'function') {
      const warning = checkWarnings(value);
      // helper
      setHelper(warning);
    }
  };

  const handleSwitch = (value=null) => {
    if (options.readOnly && !options.disabled) {
      setReadOnly(typeof value === 'boolean' ? value : !readOnly);
    }
  };


  useEffect(() => {
    setHelper(warning);
  }, [warning]);


  return (
    <FormControl 
      className={classNames(classnames)}
      style={{
        ...style,
        width: width,
      }}
      disabled={(options.disabled && readOnly)?false:options.disabled}
    >
      {
        !!label && 
        <InputLabel 
          shrink
          htmlFor="custom-select"
        >
          {(!readOnly && required) ? <>{label}<span className={classes.asteriskStyle}>*</span></> : label }
        </InputLabel>
      }
      <Select
        value={value || ""}
        onChange={handleChange}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        IconComponent={() => (
          <img src={selectIcon} alt="" className={`MuiSelect-icon ${(open ? 'MuiSelect-iconOpen' : '')}`} />
        )}
        displayEmpty
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
        }}
        inputProps={{ readOnly: readOnly }}
        onDoubleClick={handleSwitch}
        onBlur={() => handleSwitch(true)}
        className={warning !== "" ? classes.errorBoxOutline : null}
      >
        {
          (items && items.length > 0) ?
          items.map(({label, value, disabled=false}, index) => (
            <MenuItem value={value} key={index} disabled={disabled} className={layoutState.darkMode?classes.menuDM:""}>
              {label}
            </MenuItem>    
          ))
          : null
        }
      </Select>
      <FormHelperText>
        {options.disableHelper?null:helper}
      </FormHelperText>
    </FormControl>
  );
}
