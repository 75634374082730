import React from "react";
import classNames from "classnames";
import { Table, TableBody, TableRow, TableCell, Grid } from "@material-ui/core";
import useStyles from "../showlist/styles";
import moment from 'moment-timezone';
import AuditDetails from "./auditDetails";






/**
 * @param {Object} searchParams 
 * @param {Object} options 
 * @param {Object} invData 
 * @returns 
 */
export default function AuditDetailsInventory({
  options = {}, invData,
}) {

  options = {
    isEditable: false,
    ...options,
  };

  const classes = useStyles();


  const invDetails = invData?.logEvents.value.inventorydata;

  if (invData?.logEvents.value.status === 'Received' && invData?.logEvents.value.stockforfacility) {
    invData.event = 'StockedForFacility';
  }
  const time = moment(invData.created_at).tz(moment.tz.guess()).format('LT z');

  console.clear()
  console.log("invDetails", invDetails);

  return (
    <Grid>
      <Grid container direction="row" alignItems="center" className={classNames(classes.overviewAuditLog, classes.auditLogPanel)}>
        <Grid item xs={6} className={classes.overviewAuditLogPO} >
          <Table className={classes.auditLogForm}>
            <TableBody>
              <TableRow>
                <TableCell className="new-subtitle" style={{ paddingTop: 0 }}>PO Details</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="label">PO Number</TableCell>
                <TableCell className="label">PO Date</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="value">{invDetails.ipurchaseordernumber}</TableCell>
                <TableCell className="value">{invDetails.ipurchaseorderdate}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="label">Ownership</TableCell>
                <TableCell className="label">Distributor</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="value">{invDetails.iproduct.sepownership}</TableCell>
                <TableCell className="value">{invDetails.iproduct.sepdistributor}</TableCell>
              </TableRow>
              <TableRow>
                {(invData?.event === 'InvShipped') ?
                  <> <TableCell className="label">SE ID</TableCell>
                    <TableCell className="value"> {invDetails.iseid} </TableCell> </> : ""
                }
              </TableRow>
            </TableBody>
          </Table>

          <AuditDetails
            poData={invData}
            time={time}
          />

        </Grid>
        <Grid item xs={6}>
            <Table className={classNames(classes.auditLogDetails1, classes.auditLogDetails)}>
              <TableBody>
                  <TableRow>
                    <TableCell colSpan={2} className="new-subtitle" style={{ paddingTop: 0 }}>Product Details</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="label">UDI :</TableCell>
                    <TableCell className="value">{invDetails.iproduct.sepudi}</TableCell>
                    <TableCell className="label">Waste:</TableCell>
                    <TableCell className="value">{invDetails.iproduct.sepproductwaste}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="label">Ref :</TableCell>
                    <TableCell className="value">{invDetails.iproduct.sepreferencenumber}</TableCell>
                    <TableCell className="label">Ownership :</TableCell>
                    <TableCell className="value">{invDetails.iproduct.sepownership}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="label">Manufacture :</TableCell>
                    <TableCell className="value">{invDetails.iproduct.sepproductmanufacturer}</TableCell>
                    <TableCell className="label">Product Side :</TableCell>
                    <TableCell className="value">{invDetails.iproduct.sepproductside}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="label">Distributor :</TableCell>
                    <TableCell className="value">{invDetails.iproduct.sepdistributor}</TableCell>
                    <TableCell className="label">Price :</TableCell>
                    <TableCell className="value">{invDetails.iproduct.sepproductprice}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="label">Name :</TableCell>
                    <TableCell className={classes.textStyle}>{invDetails.iproduct.sepproductname}</TableCell>
                    <TableCell className="label">UoM :</TableCell>
                    <TableCell className="value">{invDetails.iproduct.sepuom}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="label">Description :</TableCell>
                    <TableCell className={classes.textStyle}>{invDetails.iproduct.sepproductdescription}</TableCell>
                    <TableCell className="label">Quantity :</TableCell>
                    <TableCell className="value">{invDetails.iquantity}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="label">Lot/SN :</TableCell>
                    <TableCell className="value">{invDetails.iproduct.seplotcode}</TableCell>
                    <TableCell className="label">Unclassified :</TableCell>
                    <TableCell className="value">{String(invDetails.iunclassified)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="label">Expiration :</TableCell>
                    <TableCell className="value">{invDetails.iproduct.sepproductexpiration}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="label">Product Type :</TableCell>
                    <TableCell className="value">{invDetails.iproduct.sepproducttype}</TableCell>
                  </TableRow>
              </TableBody>
            </Table>
        </Grid>
      </Grid>
    </Grid >
  );
}
