import { useStreamQueries, useQuery } from "@daml/react";

export const useDamlStream = (template, searchParams=[], isLoad=true) => {
  const assets = useStreamQueries(template, () => searchParams);
  if (!isLoad) return {loading:false, contracts:[]};
  return assets;
};

export const useDamlQuery = (template, searchParams={}, isLoad=true) => {
  const assets = useQuery(template, () => searchParams);
  if (!isLoad) return {};
  return assets;
};