export const ACHModel = {
  accountnumber: '',
  routingnumber: '',
  bankname: '',
  typeaccount: '',
};

export const validateACH = (ach) => {
  return (ach.accountnumber !== ""
  || ach.routingnumber !== ""
  || ach.bankname !== ""
  || ach.typeaccount !== "");
};

export const getACH = (ach) => {
  let newACH = {};
  if (typeof ach.accountnumber === "string" && ach.accountnumber !== "") newACH.accountnumber = ach.accountnumber;
  if (typeof ach.routingnumber === "string" && ach.routingnumber !== "") newACH.routingnumber = ach.routingnumber;
  if (typeof ach.bankname === "string" && ach.bankname !== "") newACH.bankname = ach.bankname;
  if (typeof ach.typeaccount === "string" && ach.typeaccount !== "") newACH.typeaccount = ach.typeaccount;
  return newACH;
};