import { getModelMenu, validateModelItem, getSubModel } from "./handles";

export const roles = {
    Vendor: 'Vendor',
    Hospital: 'Hospital',
    FinancialFirm: 'FinancialFirm',
    Operator: 'Operator',
    Tydei: 'Tydei',
    Contractor: 'Contractor',
};

export default Object.keys(roles).map(value => ({
    value,
    label: roles[value]
}));
export const validateRole = validateModelItem(roles);
export const getRoles = getModelMenu(roles);

const rolesNetwork = getSubModel(roles, [
    'Vendor', 'Hospital',
]);
export const validateRoleNetwork = validateModelItem(rolesNetwork);
export const getRolesNetwork = getModelMenu(rolesNetwork);
