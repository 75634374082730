import axios from "axios";
import {
  RETRIEVE_ENDPOINT,
  NODE_SERVER_AUTH_NAME,
  NODE_SERVER_AUTH_PASS,
  LOTCODE_ENDPOINT,
  ACCESS_GUDID_ENDPOINT,
  RETRIEVE_ENDPOINT_3,
  RETRIEVE_ENDPOINT_NEW,
} from "../../config";
import { validateProductType, validateSide } from "../../models/Product";
import { validateOwnership } from "../../models/Ownership";
import { dispatch, getState } from "../../store";
import { retrieveESSearch } from "../../store/actions/generalActions";

/**
 * Make Elastic Search Payload for exact match
 * New version is using match_phrase instead of match
 * @param {*} data
 * @returns
 */
export const createElasticsearchQuery = (data) => {
  const mustClauses = [];
  for (const [key, value] of Object.entries(data)) {
    if (value) {
      const matchClause = { match_phrase: { [key]: value } };
      mustClauses.push(matchClause);
    }
  }
  return {
    query: {
      bool: {
        must: mustClauses,
      },
    },
  };
};

/**
 * Retrieve from Elastic Search
 * @param {Object} state
 * @param {Function} next
 * @param {Function} before
 * @param {Object} options Includes isForce, isExpLot, user
 */
export const retrieveES = async (state, next, before, options = {}) => {
  let searchterm = state.result; // e.g. '6721-1040', '4546540608581',
  let searchField = state.field; // e.g. 'ReferenceNumber'
  let searchQuery = state.query; // e.g. {Udi:'04546540669551', Hospital:'FirstHealth', ReferenceNumber:'6721-1040'}

  // options
  options = {
    isForce: false, // If isForce is true, then some fields are ignored.
    isExpLot: true, // If isExpLot is true, then sepproductexpiration, seplotcode fields are ignored.
    user: {}, // user with role, party, etc
    vendors: [], // all vendors
    disableParams: [], // e.g. 'sepproductmanufacturer', 'sepproductprice'
    enableParams: [], // e.g. 'sepreferencenumber', 'sepproductdescription', 'sepudi', 'sepproductname'
    ...options,
  };
  // console.log("[retrieveES] options:", options);

  // let url = RETRIEVE_ENDPOINT;
  // let url = RETRIEVE_ENDPOINT_2;
  let url = RETRIEVE_ENDPOINT_3;

  let query = { term: { _id: { value: searchterm } } };
  if (!!searchField && typeof searchField === "string") {
    query = { query_string: { default_field: searchField, query: searchterm } };
  }
  console.log("searchQuery", searchQuery);
  if (
    !!searchQuery &&
    typeof searchQuery === "object" &&
    Object.keys(searchQuery).length
  ) {
    query = createElasticsearchQuery(searchQuery);
  }
  console.log("[retrieveES] request: ", query.query);
  if (typeof before === "function") before();

  let result = {};
  const noContractError =  'No Contract Price information found.';
  //final payload
  query = query?.query;

  if(query === undefined || !hasValue(query, 'hospital')) {
    next(null);
  } else { 
  await axios
    .post(url, {
      query,
    })
    .then((res) => {
      try {
        console.log("[retrieveES] response: ", res.data);

        try {

          if (res?.data?.hits?.hits?.length > 0) {
            const hits = res?.data?.hits?.hits[0];


            console.log("options.isForce ", options);

            const source = hits?._source;
            console.log("source []", source);

            if (source.sepreferencenumber && !options.isForce)
              result.sepreferencenumber = source.sepreferencenumber;

            if (
              source.sepproductmanufacturer &&
              !options.isForce &&
              findDistributor(
                source.sepproductmanufacturer,
                options.user,
                options.vendors
              ) &&
              options.disableParams.indexOf("sepproductmanufacturer") === -1
            )
              result.sepproductmanufacturer = source.sepproductmanufacturer;

            if (source.sepproductdescription && !options.isForce)
              result.sepproductdescription = source.sepproductdescription;
            else if (source.gudiddescription && !options.isForce)
              result.sepproductdescription = source.gudiddescription;

            // Before, it's disable to get expiration date, lotcode
            if (!options.isExpLot) {
              if (
                source.sepproductexpiration &&
                !options.isForce &&
                options.disableParams.indexOf("sepproductexpiration") === -1
              )
                result.sepproductexpiration = source.sepproductexpiration;
              else if (
                source.sepproductexpiration &&
                !options.isForce &&
                options.disableParams.indexOf("sepproductexpiration") === -1
              )
                result.sepproductexpiration = source.sepproductexpiration;
              else if (
                source.enddate &&
                !options.isForce &&
                options.disableParams.indexOf("sepproductexpiration") === -1
              )
                result.sepproductexpiration = source.enddate;
              if (
                source.seplotcode &&
                !options.isForce &&
                options.disableParams.indexOf("seplotcode") === -1
              )
                result.seplotcode = source.seplotcode;
            }
            if (source.sepprice || source.seppprice) {
              if (options.disableParams.indexOf("sepproductprice") === -1)
                result.sepproductprice = source.sepprice; //|| source.seppprice;
              if (options.disableParams.indexOf("sepproductesprice") === -1)
                result.sepproductesprice = source.sepprice; // || source.seppprice;
            }

            if (
              validateProductType(source.sepproducttype) &&
              options.disableParams.indexOf("sepproducttype") === -1
            )
              result.sepproducttype = source.sepproducttype;

            if (
              validateOwnership(source.sepownership) &&
              options.disableParams.indexOf("sepownership") === -1
            )
              result.sepownership = source.sepownership;

            if (source.sepudi && !options.isForce)
              result.sepudi = source.sepudi;

            if (
              validateSide(source.sepproductside) &&
              options.disableParams.indexOf("sepproductside") === -1
            )
              result.sepproductside = source.sepproductside;

            if (source.sepproductname && !options.isForce)
              result.sepproductname = source.sepproductname;

            if (source.sepuom && !options.isForce &&
              options.disableParams.indexOf("sepuom") === -1)
              result.sepuom = source.sepuom;
            
            if (source.sepqtyuom && !options.isForce &&
              options.disableParams.indexOf("sepqtyuom") === -1)
            result.sepqtyuom = source.sepqtyuom;

            // packagestring
            if (source.packagestring && !options.isForce) {
              result.packagestring = [];
              if (typeof source.packagestring === 'object' && source.packagestring.length > 0)
                result.packagestring = source.packagestring;
              if (source.sepuom && source.sepqtyuom) {
                // Add default PackageString with sepuom, sepprice, sepqtyuom
                result.packagestring.push({
                  packageuom: source.sepuom,  // from Elastic Search
                  price: source.sepprice,     // from Elastic Search
                  quantity: source.sepqtyuom, // from Elastic Search
                  baseuom: source.sepuom,     // from Elastic Search
                  multiplier: 1,              // Always 1 
                });
              }
            }
            result.units = result.packagestring;
            if (
              source.sepdistributor &&
              findDistributor(
                source.sepdistributor,
                options.user,
                options.vendors
              ) &&
              options.disableParams.indexOf("sepdistributor") === -1
            )
            result.sepdistributor = source.sepdistributor;

            // sepcontractnumber
            if (
              source.contractnumber &&
              options.disableParams.indexOf("sepcontractnumber") === -1
            )
              result.sepcontractnumber = source.contractnumber;

          if(!hasValue(query, 'sepdistributor')) {
            const countDuplicate = countDuplicatePreferenceNumbersWithDifferentDistributor(res.data);
             if(countDuplicate > 0) {
               result = {};
               result.error = `The Reference number matches ${countDuplicate} different distributors. 
               Please choose the Distributor from the drop down and click the REF retrieve button again.`;
             }
            }
          }
        } catch (e) {
          console.log("in catch", e);
          result.error = noContractError;
        }

        console.log("[retrieveES] result: ", result);
        next(result);
      } catch (e) {
        console.warn("[retrieveES] exception: ", e);
        result.error = noContractError;
        result.e = e;
        next(result);
      }
    })
    .catch((err) => {
      console.warn("[retrieveES] error: ", err);
      result.error = noContractError;
      result.e = err;
      next(result);
    });
  }
};

/**
 * Retrieve from Elastic Search
 * @param {Object} state
 * @param {Function} next
 * @param {Function} before
 * @param {Object} options Includes isForce, isExpLot, user
 */
export const retrieveESOld = async (state, next, before, options = {}) => {
  let searchterm = state.result; // e.g. '6721-1040', '4546540608581',
  let searchField = state.field; // e.g. 'ReferenceNumber'
  let searchQuery = state.query; // e.g. {Udi:'04546540669551', Hospital:'FirstHealth', ReferenceNumber:'6721-1040'}

  // options
  options = {
    isForce: false, // If isForce is true, then some fields are ignored.
    isExpLot: true, // If isExpLot is true, then sepproductexpiration, seplotcode fields are ignored.
    user: {}, // user with role, party, etc
    vendors: [], // all vendors
    disableParams: [], // e.g. 'sepproductmanufacturer', 'sepproductprice'
    enableParams: [], // e.g. 'sepreferencenumber', 'sepproductdescription', 'sepudi', 'sepproductname'
    ...options,
  };
  // console.log("[retrieveES] options:", options);

  // let url = RETRIEVE_ENDPOINT;
  // let url = RETRIEVE_ENDPOINT_2;
  let url = RETRIEVE_ENDPOINT_NEW;

  let query = { term: { _id: { value: searchterm } } };
  if (!!searchField && typeof searchField === "string") {
    query = { query_string: { default_field: searchField, query: searchterm } };
  }
  if (
    !!searchQuery &&
    typeof searchQuery === "object" &&
    Object.keys(searchQuery).length
  ) {
    query = {
      query_string: {
        query: Object.keys(searchQuery)
          .map((key) => `(${key}:${searchQuery[key]})`)
          .join(" AND "),
      },
    };
  }

  console.log("[retrieveES] request: ", query);
  if (typeof before === "function") before();

  await axios
    .get(url, {
      params: {
        source: JSON.stringify({ query }),
        source_content_type: "application/json",
      },
      auth: {
        username: NODE_SERVER_AUTH_NAME,
        password: NODE_SERVER_AUTH_PASS,
      },
    })
    .then((res) => {
      try {
        console.log("[retrieveES] response: ", res.data);
        let result = {};

        try {
          /**
           * Manufacturer: "BioMed"
           * Ownership: "Owned"
           * PricingCurrency: "USD"
           * PricingDate: "2020-03-11"
           * PricingUnitCost: 355
           * ProductApplication: "Hip"
           * ProductApplicationDescription: "Modular Hup System"
           * ProductApplicationDescr: ""
           * ProductTypeDescr: ""
           * ProductDescription: "Modular Hip System"
           * ProductSide: "Right"
           * ProductType: 1
           * ReferenceNumber: "6276-4-319"
           * Udi: "07613153071594"
           * ProductName: "Restoration"
           * Distributor: ""
           * Hospital: ""
           * Lotcode: ""
           * ExpirationDate: ""
           */

          const hits = res.data.hits.hits[0];
          const source = hits._source;
          if (
            source.ReferenceNumber &&
            !options.isForce &&
            options.enableParams.indexOf("sepreferencenumber") !== -1
          )
            result.sepreferencenumber = source.ReferenceNumber;
          if (
            source.Manufacturer &&
            !options.isForce &&
            findDistributor(
              source.Manufacturer,
              options.user,
              options.vendors
            ) &&
            options.disableParams.indexOf("sepproductmanufacturer") === -1
          )
            result.sepproductmanufacturer = source.Manufacturer;
          if (
            source.ProductDescription &&
            !options.isForce &&
            options.enableParams.indexOf("sepproductdescription") !== -1
          )
            result.sepproductdescription = source.ProductDescription;
          else if (
            source.ProductTypeDescr &&
            !options.isForce &&
            options.enableParams.indexOf("sepproductdescription") !== -1
          )
            result.sepproductdescription = source.ProductTypeDescr;
          // Before, it's disable to get expiration date, lotcode
          if (!options.isExpLot) {
            if (
              source.ExpirationDate &&
              !options.isForce &&
              options.disableParams.indexOf("sepproductexpiration") === -1
            )
              result.sepproductexpiration = source.ExpirationDate;
            else if (
              source.PricingDate &&
              !options.isForce &&
              options.disableParams.indexOf("sepproductexpiration") === -1
            )
              result.sepproductexpiration = source.PricingDate;
            if (
              source.Lotcode &&
              !options.isForce &&
              options.disableParams.indexOf("seplotcode") === -1
            )
              result.seplotcode = source.Lotcode;
          }
          if (source.PricingUnitCost) {
            if (options.disableParams.indexOf("sepproductprice") === -1)
              result.sepproductprice = source.PricingUnitCost;
            if (options.disableParams.indexOf("sepproductesprice") === -1)
              result.sepproductesprice = source.PricingUnitCost;
          }
          if (
            validateProductType(source.ProductType) &&
            options.disableParams.indexOf("sepproducttype") === -1
          )
            result.sepproducttype = source.ProductType;
          if (
            validateOwnership(source.Ownership) &&
            options.disableParams.indexOf("sepownership") === -1
          )
            result.sepownership = source.Ownership;
          if (
            source.Udi &&
            !options.isForce &&
            options.enableParams.indexOf("sepudi") !== -1
          )
            result.sepudi = source.Udi;
          if (
            validateSide(source.ProductSide) &&
            options.disableParams.indexOf("sepproductside") === -1
          )
            result.sepproductside = source.ProductSide;
          if (
            source.productname &&
            !options.isForce &&
            options.enableParams.indexOf("sepproductname") !== -1
          )
            result.sepproductname = source.productname;
          else if (
            source.ProductName &&
            !options.isForce &&
            options.enableParams.indexOf("sepproductname") !== -1
          )
            result.sepproductname = source.ProductName;
          else if (
            source.productName &&
            !options.isForce &&
            options.enableParams.indexOf("sepproductname") !== -1
          )
            result.sepproductname = source.productName;
          if (
            source.Distributor &&
            findDistributor(
              source.Distributor,
              options.user,
              options.vendors
            ) &&
            options.disableParams.indexOf("sepdistributor") === -1
          )
            result.sepdistributor = source.Distributor;
        } catch (e) {
          result = null;
        }

        // console.log("[retrieveES] result: ", result);
        next(result);
      } catch (e) {
        console.warn("[retrieveES] exception: ", e);
        next(null);
      }
    })
    .catch((err) => {
      console.warn("[retrieveES] error: ", err);
      next(null);
    });
};

/**
 * Retrieve from Elastic Search for ScanProductLot
 * @param {Object} state
 * @param {Boolean} isLotcode
 * @param {Function} next
 * @param {Function} before
 * @param {Object} options
 */
export const retrieveESLot = async (
  state,
  isLotcode,
  next,
  before,
  options = {}
) => {
  let searchterm = state.result;

  let query = { term: {} };
  let url;
  if (isLotcode) {
    query.term.LotCode = searchterm;
    url = LOTCODE_ENDPOINT;
  } else {
    query.term.ReferenceNumber = searchterm;
    url = RETRIEVE_ENDPOINT;
  }

  if (typeof before === "function") before();

  await axios
    .get(url, {
      params: {
        source: JSON.stringify({ query }),
        source_content_type: "application/json",
      },
      auth: {
        username: NODE_SERVER_AUTH_NAME,
        password: NODE_SERVER_AUTH_PASS,
      },
    })
    .then((res) => {
      try {
        // console.log("[retrieveESLot] response: ", res.data);
        const hits = res.data.hits.hits[0];
        const source = hits._source;
        let result = {};
        if (isLotcode) {
          if (source.ExpirationDate)
            result.sepproductexpiration = source.ExpirationDate;
          result.seplotcode = searchterm;
          next(result);
        } else {
          if (source.ReferenceNumber)
            result.sepreferencenumber = source.ReferenceNumber;
          if (source.ProductDescription)
            result.sepproductdescription = source.ProductDescription;
          if (validateProductType(source.ProductDescription))
            result.sepproducttype = source.ProductDescription;
          if (source.Manufacturer)
            result.sepproductmanufacturer = source.Manufacturer;
          if (source.Uri) result.sepudi = source.Uri;
          if (source.PricingUnitCost) {
            result.sepproductprice = source.PricingUnitCost;
            result.sepproductesprice = source.PricingUnitCost;
          }
          if (validateSide(source.ProductSide))
            result.sepproductside = source.ProductSide;
          if (validateOwnership(source.Ownership))
            result.sepownership = source.Ownership;
          next(result);
        }
      } catch (e) {
        console.warn("[retrieveESLot] exception: ", e);
        next(null);
      }
    })
    .catch((err) => {
      console.warn("[retrieveESLot] error: ", err);
      next(null);
    });
};

/**
 * Retrieve from Elastic Search
 * Store in redux and Get from reducers
 * @param {Object} state
 * @param {Function} next
 * @param {Function} before
 * @param {Object} options
 */
export const retrieveESRedux = async (state, next, before, options = {}) => {
  // check if the search state exist in redux
  const data = getState().retrieveES;
  let searchterm = state.result;
  // console.log("[retrieveESRedux]", searchterm, data, (searchterm in data));
  if (searchterm in data) {
    // found
    if (typeof before === "function") before();
    return next(data[searchterm]);
  }

  // call retrieve
  await retrieveES(
    state,
    (result) => {
      if (result) {
        // store in redux
        dispatch(retrieveESSearch({ key: searchterm, value: result }));
      } else {
        // store in redux even if result is null
        dispatch(retrieveESSearch({ key: searchterm, value: {} }));
      }
      next(result);
    },
    before,
    options
  );
};

/**
 * Retrieve from accessgudid
 * @param {Object} searchParams
 * @param {Function} next
 * @param {Object} options Includes isForce, user
 * @returns
 */
export const retrieveGUDID = async (searchParams, next, options = {}) => {
  if (
    !searchParams ||
    !Object.keys(searchParams).length ||
    !(searchParams.udi || searchParams.di)
  )
    return next(null);
  const noDeviceFound = `No device found for DI ${searchParams.di || searchParams.udi}.`;
  // options
  options = {
    isForce: false, // If isForce is true, then some fields are ignored.
    user: {}, // user with role, party, etc
    vendors: [], // all vendors
    disableParams: [], // e.g. 'sepreferencenumber', 'sepproductname', 'sepproductdescription'
    enableParams: [], // e.g. 'sepudi', 'sepproductmanufacturer', 'seplotcode', 'sepproducttype', 'sepproductside'
    ...options,
  };
  console.log("[retrieveGUDID] options:", options, "request: ", searchParams);

  let result = {};
  await axios
    .get(ACCESS_GUDID_ENDPOINT, {
      params: searchParams,
    })
    .then((res) => {
      console.log('res []', res)
      const data = res.data;
      console.log("[retrieveGUDID] response: ", data);

      if (!!data && typeof data === "object") {
        try {
          const udi = data.udi;
          const device = data.gudid.device;
          const productCode = data.productCodes.length
            ? data.productCodes[0]
            : {};
          // console.log("[retrieveGUDID] udi: ", udi, "device: ", device);

          if (!!device && typeof device === "object") {
            if (
              udi &&
              udi.udi &&
              !options.isForce &&
              options.enableParams.indexOf("sepudi") !== -1
            )
              result.sepudi = udi.udi;
            if (
              udi &&
              udi.serialNumber &&
              options.disableParams.indexOf("sepreferencenumber") === -1
            )
              result.sepreferencenumber = udi.serialNumber;
            else if (
              device.catalogNumber &&
              options.disableParams.indexOf("sepreferencenumber") === -1
            )
              result.sepreferencenumber = device.catalogNumber;
            else if (
              device.versionModelNumber &&
              options.disableParams.indexOf("sepreferencenumber") === -1
            )
              result.sepreferencenumber = device.versionModelNumber;
            if (
              device.companyName &&
              findDistributor(
                device.companyName,
                options.user,
                options.vendors
              ) &&
              options.enableParams.indexOf("sepproductmanufacturer") !== -1
            )
              result.sepproductmanufacturer = device.companyName;
            if (
              productCode.deviceName &&
              options.disableParams.indexOf("sepproductname") === -1
            )
              result.sepproductname = productCode.deviceName;
            if (
              device.deviceDescription &&
              options.disableParams.indexOf("sepproductdescription") === -1
            )
              result.sepproductdescription = device.deviceDescription;
            if (
              udi &&
              udi.lotNumber &&
              !options.isForce &&
              options.enableParams.indexOf("seplotcode") !== -1
            )
              result.seplotcode = udi.lotNumber;
            if (
              validateProductType(productCode.productCode) &&
              !options.isForce &&
              options.enableParams.indexOf("sepproducttype") !== -1
            )
              result.sepproducttype = productCode.productCode;
            if (
              validateSide(productCode.targetArea) &&
              !options.isForce &&
              options.enableParams.indexOf("sepproductside") !== -1
            )
              result.sepproductside = productCode.targetArea;

            // console.log("[retrieveGUDID] result: ", result);
            next(result);
          } else  { 
            result.error = noDeviceFound;
            next(result); }
        } catch (e) {
          result.error = e?.error || noDeviceFound;
          next(result);
        }
      } else { 
        result.error = noDeviceFound;
        next(result); }
    })
    .catch((err) => {
      console.warn("[retrieveGUDID] error: ", err);
      result.error = result?.e?.response?.data?.error || noDeviceFound;
      result.e = err;
      next(result);
    });
};

/**
 * find distributor in vendors of relationship
 * @param {String} data
 * @param {Object} user
 * @param {Array} vendors
 * @returns {Boolean}
 */
const findDistributor = (data, user = {}, vendors = []) => {
  // console.log("[findDistributor] Relationship", relationship, data);
  if (user.role === "Vendor") {
    // distributor is same as party of user
    return user.party === data;
  } else if (user.role === "Hospital") {
    // distributors by "Relationship"
    let distributors = [];
    const relationship = getState().queryDAML["Relationship"];
    if (relationship && typeof relationship === "object") {
      if (
        typeof relationship.contracts === "object" &&
        relationship.contracts.length > 0
      ) {
        // console.log("[findDistributor]", relationship.contracts);
        distributors = relationship.contracts.map((c) => c.payload.vendor);
      }
    } else if (vendors && typeof vendors === "object" && vendors.length > 0) {
      distributors = vendors;
    }
    return distributors.indexOf(data) >= 0;
  }
  return true; // if relationship is not loaded yet, then result may be true or false
};

/**
 * @data 
 * return count number
 * Match Distributor if multiple items with the same REF
 */
const  countDuplicatePreferenceNumbersWithDifferentDistributor = (data) => {

   const preferenceNumbers = new Map();
  let count = 0;

  // Iterate over each object in the array
  for (const obj of data?.hits?.hits) {
    const { sepreferencenumber, sepdistributor } = obj._source;

    // Check if the preference number already exists in the map
    if (preferenceNumbers.has(sepreferencenumber)) {
      const distributors = preferenceNumbers.get(sepreferencenumber);

      // Check if the distributor is different from the existing one(s)
      if (!distributors.includes(sepdistributor)) {
        count++; // Increment the count of duplicates with different distributor
      }

      distributors.push(sepdistributor); // Add the distributor to the list
    } else {
      preferenceNumbers.set(sepreferencenumber, [sepdistributor]);
    }
  }

  return count;

}

/**
 * 
 * @param {*} json 
 * @param {*} key 
 * @returns 
 */
function hasValue(json, key) {
  // Convert JSON to string
  const jsonString = JSON.stringify(json);
  
  // Check if the key exists in the string representation of the JSON
  const regex = new RegExp(`"${key}":\\s*[^{}\\[\\],]+`);
  return regex.test(jsonString);
}
