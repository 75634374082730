import axios from "axios";
import { AWS_BUCKET_ENDPOINT, UploadFileExtList, CSVDelimiter } from '../../config';


/**
 * read csv file
 * @param {File} file 
 * @param {Function} callback 
 */
export function readCSV (file, callback) {
  const reader = new FileReader();
  reader.onload = (evt) => {
    /* Parse data */
    const bstr = evt.target.result;
    
    var allTextLines = bstr.split(/\r\n|\n/);
    var entries = allTextLines[0].split(CSVDelimiter);
    var lines = [];
    if (allTextLines.length > 1) {
      lines = allTextLines.splice(1).map(l => l.split(CSVDelimiter)).filter(l => (l.length > 1));
    }

    if (typeof callback === 'function') callback({
      name: file.name,
      text: bstr,
      records: entries,
      data: lines,
    });
  };
  reader.readAsBinaryString(file);
}

/**
 * upload file
 * @param {File} file 
 * @param {Function} callback 
 */
export async function uploadFile(file, callback=()=>{}) {
  // Getting the signed url
  axios(
    AWS_BUCKET_ENDPOINT + "?fileName=" +
    file.name
  ).then(response => {
    // Getting the url from response
    const url = response.data.fileUploadURL;

    axios({
      method: "PUT",
      url: url,
      data: file,
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then(res => {
      console.log("[uploadFile] success", res);
      callback({
        uploadSuccess: "File upload successfull",
        error: undefined
      });
    })
    .catch(err => {
      console.log("[uploadFile] error", err);
      callback({
        error: "Error Occured while uploading the file",
        uploadSuccess: undefined
      });
    });
  });
}

/**
 * validate file
 * @param {String} filename 
 * @returns {Boolean}
 */
export function validateFile(filename) {
  // At least one match
  return (new RegExp(UploadFileExtList.join("|")).test(filename.toLowerCase()));
}
