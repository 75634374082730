import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import IdleTimeoutModal from "../IdleTimout/index";
import { useHistory } from "react-router-dom";
import useAuthLogout from "../../../hooks/useAuthLogout";
import {
  IDLE_SESSION_TIMEOUT,
  SESSION_TIMEOUT_BEFORE_IDLE_PROMPT,
} from "../../../config";

const timeout = IDLE_SESSION_TIMEOUT || 1800_000; //20_000
const promptBeforeIdle = SESSION_TIMEOUT_BEFORE_IDLE_PROMPT || 130_000; //10_000

function UseIdleTimeout() {
  const [state, setState] = useState("Active");
  const [remaining, setRemaining] = useState(timeout);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const authLogout = useAuthLogout({ history });
  const onIdle = async (e) => {
    setState("Idle");
    console.log("OnIdle");
    setOpen(false);

    await authLogout(e);
  };

  const onActive = () => {
    setState("Active");
    setOpen(false);
    console.log("onActive");
  };

  const onPrompt = () => {
    setState("Prompted");
    setOpen(true);
    console.log("Prompted");
  };

  const onAction = async () => {
   // console.log("onAction");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);
    return () => {
      clearInterval(interval);
    };
  });

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    onAction,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });


  const handleStayActive = () => {
    activate();
  };

  const authLogoutHandle = async (e) => {
    console.log("[UseIdleTimeout] authLogoutHandle", state);

    await authLogout(e);
  };

  return (
    <>
      {open ? (
        <IdleTimeoutModal
          openState={open}
          remaining={remaining}
          cancelHandler={handleStayActive}
          logoutHandle={authLogoutHandle}
        />
      ) : null}
    </>
  );
}

export default UseIdleTimeout;
