import React, { useState, useEffect } from "react";
import { useParty, useLedger, useStreamQueries } from "@daml/react";
import { Typography } from "@material-ui/core";
import { RelationshipOffer, Relationship } from "../../services/daml-modules1";
import VendorReceivedInviteContracts from "./vendorreceivedinvitecontracts";
import HospitalAcceptContracts from "./hospitalacceptcontracts";
import { dispatch, getState } from "../../store";
import { receivedinviteSearch, hospitalacceptSearch } from "../../store/actions/generalActions";
import { createRelationship } from "./handle";
import { useDamlState } from "../../context/DamlContext";
import useStyles from "../surgicalevent/styles";


export default function VendorReceivedInvite() {

  const classes = useStyles();
  const ledger = useLedger();
  const vendor = useParty();
  const { contracts: contractsOffer } = useStreamQueries(RelationshipOffer, () => [{ vendor }]);
  const { contracts } = useStreamQueries(Relationship, () => [{ vendor }]);
  const { roleCid } = useDamlState();

  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({});


  const [searchVendorReceivedInvite, setSearchVendorReceivedInvite] = useState({
    hospital: '',
    ...getState().template,
  });
  const handleChangeSearchValue = (val, key) => {
    setSearchVendorReceivedInvite({ ...searchVendorReceivedInvite, [key]: val });
    dispatch(receivedinviteSearch({ key, value: val }));
  };

  //Search Hospital in Vendor Received invite

  useEffect(() => {
    setLoading(true);

    let temp = {};

    if (searchVendorReceivedInvite.hospital !== "") temp.hospital = searchVendorReceivedInvite.hospital;
    temp.vendor = vendor;

    const delay = 0;
    if (delay > 0) setTimeout(() => {
      setSearchParams(temp);
    }, delay);
    else {
      setSearchParams(temp);
    }
  }, [searchVendorReceivedInvite, vendor]);

  useEffect(() => {
    setLoading(false);
  }, [searchParams]);

  // Search Hospital Acceptance

  const [searchHospitalAccept, setSearchHospitalAccept] = useState({
    hospital: '',
    ...getState().template,
  });
  const handleChangeSearchAcceptValue = (val, key) => {
    setSearchHospitalAccept({ ...searchHospitalAccept, [key]: val });
    dispatch(hospitalacceptSearch({ key, value: val }));
  };


  useEffect(() => {
    setLoading(true);

    let temp = {};

    if (searchHospitalAccept.hospital !== "") temp.hospital = searchHospitalAccept.hospital;
    temp.vendor = vendor;

    const delay = 0;
    if (delay > 0) setTimeout(() => {
      setSearchParams(temp);
    }, delay);
    else {
      setSearchParams(temp);
    }
  }, [searchHospitalAccept, vendor]);

  useEffect(() => {
    setLoading(false);
  }, [searchParams]);


  // Accept Vendor Received Invitation
  // Click "Accept" button in RelationshipOffer
  const handleRequest = async (contractId) => {
    await createRelationship({
      ledger, contractId, roleCid,
    })
  };


  return (
    <>
      <div key="formChildStep" className="formsteps">
        <Typography variant="h4" className={classes.pageTitle}>Hospital Invitations</Typography>

        <VendorReceivedInviteContracts
          searchParams={searchParams}
          handleChangeSearchValue={handleChangeSearchValue}
          allContracts={contractsOffer}
          searchVendorReceivedInvite={searchVendorReceivedInvite}
          handleRequest={async (data) => {
            // console.log("[VendorReceivedInviteContracts] handleRequest", data.contractId);
            await handleRequest(data.contractId);
          }}
          loading={loading}
        />

        <div>  </div>
        <div key="formChildStep" className="formsteps">
          <Typography variant="h4" className={classes.pageTitle}>Vendor Accepted Relationship</Typography>
        </div>

        <HospitalAcceptContracts
          searchParams={searchParams}
          handleChangeSearchValue={handleChangeSearchAcceptValue}
          allContracts={contracts}
          searchHospitalAccept={searchHospitalAccept}
          loading={loading}
        />

      </div>

    </>
  );
};
