import React, { forwardRef } from 'react'
import { formatDate } from '../../services/util';

const CompliancePrint = ({ data }, ref) => {
    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }
    const pageStyle = {
        pageHeader: {
            fontSize: "0.7rem",
            display: "flex",
            justifyContent: "space-between",
            color: "gray",
            padding: "5px 5px 0 5px",
        },
        pageTitle: {
            fontSize: "1rem",
            borderBottom: "1px solid gray",
        },
        pageBody: {
            margin: "0.5rem 2rem 2rem 2rem",
        },
        table: {
            margin: "0.5rem 0 0 0",
        },
        tableBody1: {
            display: "grid",
            gridTemplateColumns: "20% 20% 20% 20% 20%"
        },
        tableBody2: {
            display: "grid",
            gridTemplateColumns: "33% 33% 33%"
        },
        tableBody3: {
            display: "grid",
            gridTemplateColumns: "50% 50%",
        },
        tableBody4: {
            display: "grid",
            gridTemplateColumns: "35% 65%",
        },
        tableTitle: {
            fontSize: "0.7rem",
        },
        tableHead: {
            color: "gray",
            fontSize: "0.6rem",
        },
        tableData: {
            wordWrap: "break-word",
            fontSize: "0.7rem",
        },
    }
    const currentDate = new Date();
    const date = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}, ${formatAMPM(currentDate)}`;
    return (
        <div ref={ref} style={{ backgroundColor: "white" }}>
            {
                data.map((d, i) =>
                    <div key={d.contractId} style={{height:i % 2 === 0?"35rem":"34.5rem"}}>
                        {
                            i % 2 === 0 &&
                            <div style={pageStyle.pageHeader}>
                                <div>{date}</div>
                                <div>Tydei Health</div>
                            </div>
                        }
                        <div style={pageStyle.pageBody}>
                            {
                                i % 2 === 0 &&
                                <div style={pageStyle.pageTitle}>
                                    Compliance
                                </div>
                            }
                            <div style={pageStyle.table}>
                                <div style={pageStyle.tableTitle}>
                                    Compliance Details
                                </div>
                                <div style={pageStyle.tableBody1}>
                                    <div>
                                        <div style={pageStyle.tableHead}>Rule Type Level</div>
                                        <div style={pageStyle.tableData}>{d.payload.surgicaleventcompliancedata.ruletypelevel}</div>
                                    </div>
                                    <div>
                                        <div style={pageStyle.tableHead}>Rule Type</div>
                                        <div style={pageStyle.tableData}>{d.payload.surgicaleventcompliancedata.ruletype}</div>
                                    </div>
                                    <div>
                                        <div style={pageStyle.tableHead}>Rule Outcome</div>
                                        <div style={pageStyle.tableData}>{d.payload.surgicaleventcompliancedata.ruleoutcome}</div>
                                    </div>
                                    <div>
                                        <div style={pageStyle.tableHead}>User</div>
                                        <div style={pageStyle.tableData}>{d.payload.surgicaleventcompliancedata.user}</div>
                                    </div>
                                    <div>
                                        <div style={pageStyle.tableHead}>Event Date</div>
                                        <div style={pageStyle.tableData}>{formatDate(d.payload.surgicaleventcompliancedata.date)}</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ fontSize: "0.8rem", margin: "0.5rem 0 0 0" }}>
                                SE ID: {d.payload.surgicaleventdata.seid}
                            </div>
                            <div style={pageStyle.table}>
                                <div style={pageStyle.tableTitle}>
                                    Patient Details
                                </div>
                                <div style={pageStyle.tableBody1}>
                                    <div>
                                        <div style={pageStyle.tableHead}>MRN</div>
                                        <div style={pageStyle.tableData}>{d.payload.surgicaleventdata.mrn}</div>
                                    </div>
                                    <div>
                                        <div style={pageStyle.tableHead}>First Name</div>
                                        <div style={pageStyle.tableData}>{d.payload.surgicaleventdata.patientfirstname}</div>
                                    </div>
                                    <div>
                                        <div style={pageStyle.tableHead}>Last Name</div>
                                        <div style={pageStyle.tableData}>{d.payload.surgicaleventdata.patientlastname}</div>
                                    </div>
                                    <div>
                                        <div style={pageStyle.tableHead}>Gender</div>
                                        <div style={pageStyle.tableData}>{d.payload.surgicaleventdata.patientgender}</div>
                                    </div>
                                    <div>
                                        <div style={pageStyle.tableHead}>DOB</div>
                                        <div style={pageStyle.tableData}>{formatDate(d.payload.surgicaleventdata.patientdob)}</div>
                                    </div>
                                </div>
                            </div>
                            <div style={pageStyle.table}>
                                <div style={pageStyle.tableTitle}>
                                    Event Details
                                </div>
                                <div style={pageStyle.tableBody2}>
                                    <div>
                                        <div style={pageStyle.tableHead}>Date</div>
                                        <div style={pageStyle.tableData}>{formatDate(d.payload.surgicaleventdata.eventdate)}</div>
                                    </div>
                                    <div>
                                        <div style={pageStyle.tableHead}>Procedure</div>
                                        <div style={pageStyle.tableData}>{d.payload.surgicaleventdata.surgicalprocedure}</div>
                                    </div>
                                    <div>
                                        <div style={pageStyle.tableHead}>Side</div>
                                        <div style={pageStyle.tableData}>{d.payload.surgicaleventdata.surgicalside}</div>
                                    </div>
                                </div>
                            </div>
                            <div style={pageStyle.table}>
                                <div style={pageStyle.tableTitle}>
                                    Physician Details
                                </div>
                                <div style={pageStyle.tableBody2}>
                                    <div>
                                        <div style={pageStyle.tableHead}>Salutation</div>
                                        <div style={pageStyle.tableData}>{d.payload.surgicaleventdata.physiciansalutation}</div>
                                    </div>
                                    <div>
                                        <div style={pageStyle.tableHead}>First Name</div>
                                        <div style={pageStyle.tableData}>{d.payload.surgicaleventdata.physicianfirstname}</div>
                                    </div>
                                    <div>
                                        <div style={pageStyle.tableHead}>Last Name</div>
                                        <div style={pageStyle.tableData}>{d.payload.surgicaleventdata.physicianlastname}</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ fontSize: "0.8rem", margin: "0.5rem 0 0 0", borderBottom: "0.5px solid gray" }}>
                                Product Details
                            </div>
                            <div>
                                <span style={pageStyle.tableHead}>
                                    Reference Number:
                                </span>
                                <span style={pageStyle.tableData}>
                                    {d.payload.auditedproductdata.sepreferencenumber}
                                </span>
                                <span style={pageStyle.tableHead}>
                                    &nbsp; Lot Code:
                                </span>
                                <span style={pageStyle.tableData}>
                                    {d.payload.auditedproductdata.seplotcode}
                                </span>
                            </div>
                            <div style={pageStyle.tableBody3}>
                                <div>
                                    <div style={pageStyle.tableBody4}>
                                        <div style={pageStyle.tableHead}>
                                            UDI
                                        </div>
                                        <div style={pageStyle.tableData}>
                                            {d.payload.auditedproductdata.sepudi}
                                        </div>
                                    </div>
                                    <div style={pageStyle.tableBody4}>
                                        <div style={pageStyle.tableHead}>
                                            Product Manufacturer
                                        </div>
                                        <div style={pageStyle.tableData}>
                                            {d.payload.auditedproductdata.sepproductmanufacturer}
                                        </div>
                                    </div>
                                    <div style={pageStyle.tableBody4}>
                                        <div style={pageStyle.tableHead}>
                                            Product Distributor
                                        </div>
                                        <div style={pageStyle.tableData}>
                                            {d.payload.auditedproductdata.sepdistributor}
                                        </div>
                                    </div>
                                    <div style={pageStyle.tableBody4}>
                                        <div style={pageStyle.tableHead}>
                                            Product Name
                                        </div>
                                        <div style={pageStyle.tableData}>
                                            {d.payload.auditedproductdata.sepproductname}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={pageStyle.tableBody4}>
                                        <div style={pageStyle.tableHead}>
                                            Product Description
                                        </div>
                                        <div style={pageStyle.tableData}>
                                            {d.payload.auditedproductdata.sepproductdescription}
                                        </div>
                                    </div>
                                    <div style={pageStyle.tableBody4}>
                                        <div style={pageStyle.tableHead}>
                                            Expiration
                                        </div>
                                        <div style={pageStyle.tableData}>
                                            {formatDate(d.payload.auditedproductdata.sepproductexpiration)}
                                        </div>
                                    </div>
                                    <div style={pageStyle.tableBody4}>
                                        <div style={pageStyle.tableHead}>
                                            Price
                                        </div>
                                        <div style={pageStyle.tableData}>
                                            {d.payload.auditedproductdata.sepproductprice}
                                        </div>
                                    </div>
                                    <div style={pageStyle.tableBody4}>
                                        <div style={pageStyle.tableHead}>
                                            Product Type
                                        </div>
                                        <div style={pageStyle.tableData}>
                                            {d.payload.auditedproductdata.sepproducttype}
                                        </div>
                                    </div>
                                    <div style={pageStyle.tableBody4}>
                                        <div style={pageStyle.tableHead}>
                                            Waste
                                        </div>
                                        <div style={pageStyle.tableData}>
                                            {d.payload.auditedproductdata.sepproductwaste}
                                        </div>
                                    </div>
                                    <div style={pageStyle.tableBody4}>
                                        <div style={pageStyle.tableHead}>
                                            Ownership
                                        </div>
                                        <div style={pageStyle.tableData}>
                                            {d.payload.auditedproductdata.sepownership}
                                        </div>
                                    </div>
                                    <div style={pageStyle.tableBody4}>
                                        <div style={pageStyle.tableHead}>
                                            Product Side
                                        </div>
                                        <div style={pageStyle.tableData}>
                                            {d.payload.auditedproductdata.sepproductside}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ borderBottom: "1px dashed gray", margin: "1rem 0 0 0" }}></div>
                        </div>
                    </div>)
            }

        </div>
    )
}

export default forwardRef(CompliancePrint);




