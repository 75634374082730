import React, { useState, useEffect } from "react";
import moment from "moment";
import { Grid, Typography, TextField, Tooltip, Button } from "@material-ui/core";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDay, pickersDayClasses } from '@mui/x-date-pickers-pro';
import FileUploadButton from "../../components/FileUpload/FileUploadButton";
import isWeekend from 'date-fns/isWeekend';
import { addHolidays } from './handle'
import { Holidays, HospitalRole, VendorRole } from "../../services/daml-modules1";
import { useDamlState } from "../../context/DamlContext";
import { useDamlQuery } from "../../hooks/useDAML"
import DialogueBox from "./DialogueBox";
import useStyles from "./styles";
import { useLedger, useStreamQueries } from "@daml/react";

export default function HolidayCalendar() {


    const classes = useStyles();
    const [dateData, setDateData] = useState([]);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(new Date());
    const [filePathData, setFilePathData] = useState("");
    const [holidays, setHolidays] = useState(null)
    const ledger = useLedger();
    const { roleCid } = useDamlState();

    const vendors = useDamlQuery(VendorRole);
    const hospitals = useDamlQuery(HospitalRole);
    const assets = useStreamQueries(Holidays, () => []);

    useEffect(() => {
        const { contracts } = assets;
        let total_holiday = {}
        contracts.forEach((contract) => {
            let { year, holidays: yholiday } = contract.payload;
            let year_holiday = {}
            // debugger
            // if (holidays)
            //     year_holiday = holidays[year] || {};
            // else
            //     year_holiday = {}

            yholiday.forEach(element => {
                if (year_holiday) {
                    let month = year_holiday[element.month];
                    if (!month)
                        month = year_holiday[element.month] = {};

                    month[element.day] = element.title
                } else {
                    debugger
                    year_holiday = {}
                    year_holiday[element.month] = {}
                    year_holiday[element.month][element.day] = element.title
                }

            });
            total_holiday[year] = year_holiday
        });
        setHolidays(total_holiday)
    }, [assets])


    // console.log(assets);

    const handleRequest = (element, holidayType) => {
        console.log("[holiday calender]", element, holidayType);
        element = new Date(element);
        let fullYear = element.getFullYear()
        let fullMonth = element.getMonth()
        let day = element.getDate()
        if (holidays) {
            let _holidays = holidays;
            let year = _holidays[fullYear];
            if (!year)
                year = _holidays[fullYear] = {}


            let month = year[fullMonth];
            if (!month)
                month = year[fullMonth] = {};

            month[day] = holidayType
            setHolidays(_holidays)
        } else {
            let holidays = {}
            holidays[fullYear] = {}
            holidays[fullYear][fullMonth] = {}
            holidays[fullYear][fullMonth][day] = holidayType
            setHolidays(holidays)
        }

        setOpen(false);
    };

    const getDayPicker = (date,pickersDayProps)=>{
        return <PickersDay
        onClick={() => {
            let dates = date.toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" });
            setDateData(dates);
            setOpen(true);
        }}
        {...pickersDayProps} />;
    }

    //date catch from here
    const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {

        if (isWeekend(date) && !pickersDayProps.outsideCurrentMonth) {
            return <PickersDay
                {...pickersDayProps}
                selected={true}
                sx={{
                    [`&&.${pickersDayClasses.selected}`]: {
                        backgroundColor: '#fbd012'
                    }
                }}

            />
        }
        else if (holidays) {
            try {
                let title = holidays[date.getFullYear()][date.getMonth()][date.getDate()]
                if (title)
                    return <Tooltip title={title} key={title}>
                        <PickersDay
                            {...pickersDayProps}
                            selected={true}
                            sx={{
                                [`&&.${pickersDayClasses.selected}`]: {
                                    backgroundColor: '#ff4d00'
                                }
                            }}
                        />
                    </Tooltip>
            } catch (error) {
                return getDayPicker(date , pickersDayProps);
            }
        }
        return getDayPicker(date ,pickersDayProps);
    };

    const segregate = (data) => {
        if (data && data.length > 0) {
            let mapping = {};
            let temp;
            data.forEach((record, index) => {
                console.log(index, record)
                temp = record[1].split('-');
                let year = mapping[temp[2]];
                if (!year)
                    year = mapping[temp[2]] = {};


                let month = year[parseInt(temp[1]) - 1];
                if (!month)
                    month = year[parseInt(temp[1]) - 1] = {};

                let day = month[temp[0]];
                if (!day)
                    month[temp[0]] = '';

                month[temp[0]] = record[0]
            });
            console.log(mapping)
            delete mapping.NaN
            console.log(mapping)
            return mapping;
        }
        return null;
    }


    return (
        <>
            <Grid>
                <Typography variant="h4" className={classes.pageTitle}>Holiday Calendar</Typography>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        openTo='day'
                        value={value}
                        // shouldDisableDate={isWeekend}
                        onChange={(newValue) => {
                            setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params}
                        />
                        }
                        renderDay={renderWeekPickerDay}
                    />
                </LocalizationProvider>

                <div className={classes.marginBM30}>
                    <FileUploadButton
                        setFilePathData={({ data, name }) => {
                            // console.log("FileUploadButton", data, name);
                            if (data && data.length > 0) {
                                let holidays = segregate(data)
                                setHolidays(holidays);
                                // update(holidays);
                            }
                            setFilePathData(`${name}  |  ${data.length} Uploaded products`);
                        }}
                        uploadFile={(file, callback) => {
                            // setFile(file);
                            // console.log("FileUploadButton", file);
                            // console.log(file)
                        }}
                    />
                    <Typography variant="inherit" style={{ paddingLeft: 10 }}>
                        You can upload only valid CSV here.
                    </Typography>
                    <Typography>
                        {filePathData}
                    </Typography>
                </div>
                <div className={classes.formFooter}>
                    <div className="right-buttons">
                        <Button autoFocus onClick={() => { }} className="noBtn" variant="text" color="primary">
                            Cancel
                        </Button>
                        <Button onClick={(e) => {
                            let roleIds = []
                            let vendor_contracts = vendors.contracts;
                            vendor_contracts.forEach((contract) => {
                                roleIds.push(contract.payload.vendor)
                            });

                            let hospital_contracts = hospitals.contracts;
                            hospital_contracts.forEach((contract) => {
                                roleIds.push(contract.payload.hospital)
                            });
                            console.log(roleIds)
                            let years = Object.keys(holidays);

                            years.forEach(async (year) => {
                                let year_holidays = []
                                // console.log(holidays[year]);
                                let months = Object.keys(holidays[year]);

                                months.forEach((month) => {
                                    let days = Object.keys(holidays[year][month]);
                                    // console.log(days)
                                    days.forEach((day) => {
                                        year_holidays.push({ month: parseInt(month), day: parseInt(day), title: holidays[year][month][day] })
                                    })

                                });
                                console.log(year_holidays)
                                await addHolidays({ year: parseInt(year), holidays: year_holidays, roleparties: roleIds, ledger, roleCid })
                            });
                        }} className="yesBtn" variant="contained" color="primary">
                            Submit
                        </Button>
                    </div>
                    <DialogueBox dateData={dateData} open={open}
                        setOpen={setOpen} handleRequest={handleRequest} />
                </div>
            </Grid>
        </>
    )
}

/**
 * Get Holiday List from BankHolidays contract
 * @param {Object} contract 
 * @returns {Array}
 */
export const getHolidays = (contract) => {
  let { year, holidays: yholiday } = contract.payload;
  let total_holiday = [];
  yholiday.forEach(element => {
    const month = parseInt(element.month) + 1;
    total_holiday.push(moment(year + "-" + month + "-" + element.day, 'YYYY-MM-DD').format('YYYY-MM-DD'));
  });
  return total_holiday;
};
