import React, { useState, useEffect } from "react";
import { useStreamQueries, useParty, useLedger } from "@daml/react";
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, Chip, FormControl, InputLabel, List,
  ListItem, Paper, Select, TableContainer, TextField, Typography
} from "@material-ui/core";
import { ExpandMore } from '@material-ui/icons';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { HospitalRole } from "../../services/daml-modules1";
import { useDamlState, useDamlDispatch } from '../../context/DamlContext';
import { getOperatorParty } from "../../services/axios/daml-json-api";
import { getStates } from "../../models/State";
import { ContactModel, getGenders } from "../../models/Contact";
import { HospitalDetailModel } from "../../models/HospitalDetail";
import { AddressModel } from "../../models/Address";
import { onChangeNumber } from "../../components/Modals/handles";
import { setByPath } from "../../components/Contracts/functions";
import editIcon from '../../icons/edit.svg';
import folderIcon from '../../icons/folder.svg';
import { exerciseRoleTwoAccept } from "./handle";
import { DAML_CLEAR_ROLE } from "../../store/actions/constants";
import useStyles from "./styles";
import { CustomLoading } from "../../hoc/withLoading";


/**
 * View Hospital Data page
 * @returns 
 */
export default function HospitalList() {

  const classes = useStyles();
  const { role } = useDamlState();
  const party = useParty();
  const operator = getOperatorParty();
  const ledger = useLedger();
  const dispatch = useDamlDispatch();

  let searchParams = {};
  if (role === "Operator") searchParams = { operator: operator };
  else if (role === "Hospital") searchParams = { hospital: party };
  const assets = useStreamQueries(HospitalRole, () => [searchParams]);

  const [contractId, setContractId] = useState('');
  const [hospitaldetails, setHospitalDetails] = useState({ ...HospitalDetailModel });
  const [editData, setEditData] = useState(0);
  const [expanded, setExpanded] = useState(0);
  const [selected, setSelected] = useState(0);
  const [count, setCount] = useState(0);


  // handle to update all fields
  const updateField = (key, isNumber = false) => (e) => {
    if (!isNumber || (isNumber && onChangeNumber(e))) setHospitalDetails(setByPath(e.target.value, key, hospitaldetails));
  };

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const selectLess = () => (e) => {
    if (selected > 0) setSelected(selected - 1);
  };
  const selectMore = () => (e) => {
    if (selected < count - 1) setSelected(selected + 1);
  };

  // handle when click Edit button
  const handleEditDetail = (index, key) => {
    setSelected(0);
    setCount(hospitaldetails[key].length);
    setEditData(index);
  };

  // handle when click "Add More ..." button
  const handleAddMore = (key, defaultValue = AddressModel) => {
    setHospitalDetails(setByPath({ ...defaultValue }, `${key}.${count}`, hospitaldetails));
    setSelected(count);
    setCount(count + 1);
  };

  // handle when click "Update" button
  const handleRequest = async () => {
    let res = await exerciseRoleTwoAccept(hospitaldetails, ledger, contractId);
    if (res) dispatch({
      type: DAML_CLEAR_ROLE,
    });
    setEditData(0);
  };

  // handle when click "Cancel" button
  const handleCancel = () => {
    setHospitalDetails({ ...assets.contracts[0].payload.hospitaldetails });
    setEditData(0);
  };

  useEffect(() => {
    if (!assets.loading && assets.contracts.length) {
      setContractId(assets.contracts[0].contractId);
      setHospitalDetails({ ...assets.contracts[0].payload.hospitaldetails });
    }
  }, [assets]);

  console.log("[HospitalList]", party, assets);


  const CustomExpandLessIcon = () => <ExpandLessIcon onClick={selectLess()} style={{ cursor: 'pointer' }} />;
  const CustomExpandMoreIcon = () => <ExpandMoreIcon onClick={selectMore()} style={{ cursor: 'pointer' }} />;


  return (
    <>
      <Typography variant="h4" className={classes.pageTitle}>View Hospital Data</Typography>

      {
        assets.loading
          ?
          <CustomLoading />
          :

          <Card className={classes.cardItem}>
            <CardContent>

              <List className={classes.listItem}>

                <ListItem>
                  <div className="item">
                    <Typography variant="h5" >
                      Name of Facility   {editData !== 1 && <img src={editIcon} alt="edit" onClick={() => { setEditData(1) }} />}
                    </Typography>
                    <Typography component={'div'}>
                      {editData === 1 &&
                        <>
                        <TextField
                          id="outlined-full-width-mrn7"
                          label="Name of Facility"
                          placeholder="eg: FirstHealth"
                          className={classes.customFullWidth}
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          value={hospitaldetails.facilityname}
                          onChange={updateField('facilityname')}
                        />

                        <TextField
                          id="outlined-full-width-mrn7"
                          label="PrefCardID of Facility"
                          className={classes.customFullWidth}
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          value={hospitaldetails.pref_facilityid}
                          onChange={updateField('pref_facilityid')}
                        />
                      </>
                      }

                      {editData !== 1 && <span className="label">{hospitaldetails.facilityname}</span>}
                    </Typography>
                  </div>

                  {editData === 1 &&
                    <div className="form-footer">
                      <div className="right-buttons">
                        <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
                        <Button variant="outlined" color="primary" onClick={handleRequest}>Update</Button>
                      </div>
                    </div>
                  }
                </ListItem>

                <ListItem>
                  <div className="item">
                    <Typography variant="h5" >
                      Physical Address   {editData !== 2 && <img src={editIcon} alt="edit" onClick={() => { setEditData(2) }} />}
                    </Typography>
                    <Typography component={'div'}>
                      {editData === 2 &&
                        <>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined1"
                              label="Address1"
                              placeholder="47 Anyville Rd Nw #2"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.physicaladdress.address1 || ""}
                              onChange={updateField('physicaladdress.address1')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-2"
                              label="Address2"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.physicaladdress.address2 || ""}
                              onChange={updateField('physicaladdress.address2')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-f3"
                              label="City"
                              placeholder="Phoenix"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.physicaladdress.city || ""}
                              onChange={updateField('physicaladdress.city')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <FormControl variant="outlined" className={classes.tableCell} >
                              <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
                                State
                              </InputLabel>
                              <Select
                                // native
                                displayEmpty
                                inputProps={{
                                  name: 'state',
                                  id: 'outlined-age-native-simple1',
                                }}
                                value={hospitaldetails.physicaladdress.state || ""}
                                onChange={updateField('physicaladdress.state')}
                              >
                                {getStates()}
                              </Select>
                            </FormControl>
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-4n"
                              label="Zip Code"
                              placeholder="123456"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.physicaladdress.zipcode || ""}
                              onChange={updateField('physicaladdress.zipcode')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-5"
                              label="Country"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.physicaladdress.country || ""}
                              onChange={updateField('physicaladdress.country')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-6"
                              label="Location"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.physicaladdress.location || ""}
                              onChange={updateField('physicaladdress.location')}
                            />
                          </div>
                        </>
                      }

                      {editData !== 2 && <>
                        <span className="label">{hospitaldetails.physicaladdress.address1}</span>
                        <br />
                        <span className="label">{hospitaldetails.physicaladdress.city} {hospitaldetails.physicaladdress.state} {hospitaldetails.physicaladdress.zipcode}</span>
                      </>}
                    </Typography>
                  </div>

                  {editData === 2 &&
                    <div className="form-footer">
                      <div className="right-buttons">
                        <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
                        <Button variant="outlined" color="primary" onClick={handleRequest}>Update</Button>
                      </div>
                    </div>
                  }
                </ListItem>

                <ListItem>
                  <div className="item">
                    <Typography variant="h5" >
                      Shipping Address  {editData !== 3 && <img src={editIcon} alt="edit" onClick={() => { handleEditDetail(3, 'shippingaddress') }} />}
                    </Typography>
                    <Typography component={'div'}>

                      {editData === 3 &&
                        <>
                          <Button variant="contained" color="primary" className={classes.textFormation}
                            onClick={() => handleAddMore('shippingaddress')}
                          >
                            Add More Address
                          </Button>
                          <div className={classes.shippingItem}>
                            <Chip label={`${selected + 1}/${count}`} className="bg-info-light" />
                            <span> <CustomExpandLessIcon /> <CustomExpandMoreIcon /> </span>
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined1"
                              label="Address 1"
                              placeholder="47 Anyville Rd Nw #2"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.shippingaddress[selected].address1 || ""}
                              onChange={updateField(`shippingaddress.${selected}.address1`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-2"
                              label="Address 2"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.shippingaddress[selected].address2 || ""}
                              onChange={updateField(`shippingaddress.${selected}.address2`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-f3"
                              label="City"
                              placeholder="Phoenix"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.shippingaddress[selected].city || ""}
                              onChange={updateField(`shippingaddress.${selected}.city`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <FormControl variant="outlined" className={classes.formControl}>
                              <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
                                State
                              </InputLabel>
                              <Select
                                // native
                                displayEmpty
                                className={classes.halfWidth}
                                inputProps={{
                                  name: 'state',
                                  id: 'outlined-age-native-simple1',
                                }}
                                value={hospitaldetails.shippingaddress[selected].state || ""}
                                onChange={updateField(`shippingaddress.${selected}.state`)}
                              >
                                {getStates()}
                              </Select>
                            </FormControl>
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-4n"
                              label="Zip Code"
                              placeholder="123456"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.shippingaddress[selected].zipcode || ""}
                              onChange={updateField(`shippingaddress.${selected}.zipcode`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-5"
                              label="Country"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.shippingaddress[selected].country || ""}
                              onChange={updateField(`shippingaddress.${selected}.country`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-6"
                              label="Location"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.shippingaddress[selected].location || ""}
                              onChange={updateField(`shippingaddress.${selected}.location`)}
                            />
                          </div>
                        </>
                      }

                      {editData !== 3 && <>


                        <div className={classes.productAccordion}>
                          {hospitaldetails.shippingaddress.map((obj, index) => (
                            <Accordion key={index} expanded={expanded === (index + 'pics')} onChange={handleChangeAccordion((index + 'pics'))} className={classes.generalMainAccordion}>
                              <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                className="product-detail-accordion-head"
                              >
                                <Typography className={classes.accordionHeading}>{obj.address1}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography component={'div'}>
                                  <TableContainer className={classes.productTableContainer} component={Paper}>
                                    <span className="label label-width">Address 1</span>
                                    <span className="value">{obj.address1}</span> <br />
                                    <span className="label label-width">Address 2</span>
                                    <span className="value">{obj.address2}</span> <br />
                                    <span className="label label-width">City</span>
                                    <span className="value ">{obj.city}</span>  <br />
                                    <span className="label label-width">State</span>
                                    <span className="value">{obj.state}</span> <br />
                                    <span className="label label-width">Zipcode</span>
                                    <span className="value">{obj.zipcode}</span> <br />
                                    <span className="label label-width">Country</span>
                                    <span className="value">{obj.country}</span> <br />
                                    <span className="label label-width">Location</span>
                                    <span className="value">{obj.location}</span> <br />

                                  </TableContainer>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          ))
                          }
                        </div>


                      </>}
                    </Typography>
                  </div>

                  {editData === 3 &&
                    <div className="form-footer">
                      <div className="right-buttons">
                        <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
                        <Button variant="outlined" color="primary" onClick={handleRequest}>Update</Button>
                      </div>
                    </div>
                  }
                </ListItem>

                <ListItem>
                  <div className="item">
                    <Typography variant="h5" >
                      Billing Address  {editData !== 4 && <img src={editIcon} alt="edit" onClick={() => { setEditData(4) }} />}
                    </Typography>
                    <Typography component={'div'}>
                      {editData === 4 &&
                        <>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined1"
                              label="Address1"
                              placeholder="47 Anyville Rd Nw #2"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.billingaddress.address1 || ""}
                              onChange={updateField('billingaddress.address1')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-2"
                              label="Address2"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.billingaddress.address2 || ""}
                              onChange={updateField('billingaddress.address2')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-f3"
                              label="City"
                              placeholder="Phoenix"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.billingaddress.city || ""}
                              onChange={updateField('billingaddress.city')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <FormControl variant="outlined" className={classes.formControl}>
                              <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
                                State
                              </InputLabel>
                              <Select
                                // native
                                displayEmpty
                                className={classes.halfWidth}
                                inputProps={{
                                  name: 'state',
                                  id: 'outlined-age-native-simple1',
                                }}
                                value={hospitaldetails.billingaddress.state || ""}
                                onChange={updateField('billingaddress.state')}
                              >
                                {getStates()}
                              </Select>
                            </FormControl>
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-4n"
                              label="Zip Code"
                              placeholder="123456"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.billingaddress.zipcode || ""}
                              onChange={updateField('billingaddress.zipcode')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-5"
                              label="Country"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.billingaddress.country || ""}
                              onChange={updateField('billingaddress.country')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-6"
                              label="Location"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.billingaddress.location || ""}
                              onChange={updateField('billingaddress.location')}
                            />
                          </div>
                        </>
                      }

                      {editData !== 4 && <>
                        <span className="label">{hospitaldetails.billingaddress.address1}</span>
                        <br />
                        <span className="label">{hospitaldetails.billingaddress.city} {hospitaldetails.billingaddress.state} {hospitaldetails.billingaddress.zipcode}</span>
                      </>}
                    </Typography>
                  </div>

                  {editData === 4 &&
                    <div className="form-footer">
                      <div className="right-buttons">
                        <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
                        <Button variant="outlined" color="primary" onClick={handleRequest}>Update</Button>
                      </div>
                    </div>
                  }
                </ListItem>

                <ListItem>
                  <div className="item">
                    <Typography variant="h5" >
                      Point(s) of Contact  {editData !== 5 && <img src={editIcon} alt="edit" onClick={() => { handleEditDetail(5, 'point_contact') }} />}
                    </Typography>
                    <Typography component={'div'}>
                      {editData === 5 &&
                        <>
                          <Button variant="contained" color="primary" className={classes.textFormation}
                            onClick={() => handleAddMore('point_contact', ContactModel)}
                          >
                            Add More User
                          </Button>
                          <div className={classes.shippingItem}>
                            <Chip label={`${selected + 1}/${count}`} className="bg-info-light" />
                            <span> <CustomExpandLessIcon /> <CustomExpandMoreIcon /> </span>
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full-width8-mrn"
                              label="First Name"
                              placeholder="eg: John"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.point_contact[selected].firstname || ""}
                              onChange={updateField(`point_contact.${selected}.firstname`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full-width88-mrn"
                              label="Last Name"
                              placeholder="eg: Smith"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.point_contact[selected].lastname || ""}
                              onChange={updateField(`point_contact.${selected}.lastname`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <FormControl variant="outlined" className={classes.formControl}>
                              <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
                                Gender
                              </InputLabel>
                              <Select
                                // native
                                displayEmpty
                                className={classes.halfWidth}
                                inputProps={{
                                  name: 'age',
                                  id: 'outlined-age-native-simple1',
                                }}
                                value={hospitaldetails.point_contact[selected].gender || ""}
                                onChange={updateField(`point_contact.${selected}.gender`)}
                              >
                                {getGenders()}
                              </Select>
                            </FormControl>
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full-width9-mrn"
                              label="Phone"
                              placeholder="eg: 0123456789"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.point_contact[selected].phone || ""}
                              onChange={updateField(`point_contact.${selected}.phone`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full-width10-mrn"
                              label="Email"
                              placeholder="eg: info@email.com"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.point_contact[selected].email || ""}
                              onChange={updateField(`point_contact.${selected}.email`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full-width11-mrn"
                              label="Fax"
                              placeholder="eg: 1234567"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.point_contact[selected].fax || ""}
                              onChange={updateField(`point_contact.${selected}.fax`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full-width12-mrn"
                              label="Job"
                              placeholder="eg: job title"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.point_contact[selected].jobtitle || ""}
                              onChange={updateField(`point_contact.${selected}.jobtitle`)}
                            />
                          </div>
                        </>
                      }

                      {editData !== 5 && <>
                        <div className={classes.productAccordion}>
                          {hospitaldetails.point_contact.map((obj, index) => (
                            <Accordion
                              key={index}
                              expanded={expanded === (index + 1)}
                              onChange={handleChangeAccordion((index + 1))}
                              className={classes.generalMainAccordion}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                className="product-detail-accordion-head"
                              >
                                <Typography className={classes.accordionHeading}>{obj.firstname} {obj.lastname}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography component={'div'}>
                                  <TableContainer className={classes.productTableContainer} component={Paper}>
                                    <span className="label label-width">Name</span>
                                    <span className="value">{obj.firstname} {obj.lastname}</span> <br />
                                    <span className="label label-width">Gender</span>
                                    <span className="value ">{obj.gender}</span>  <br />
                                    <span className="label label-width">Phone</span>
                                    <span className="value">{obj.phone}</span> <br />
                                    <span className="label label-width">Email</span>
                                    <span className="value">{obj.email}</span> <br />
                                    <span className="label label-width">Fax</span>
                                    <span className="value ">{obj.fax}</span> <br />
                                    <span className="label label-width">Job Title</span>
                                    <span className="value">{obj.jobtitle}</span>
                                  </TableContainer>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                        </div>
                      </>}

                    </Typography>
                  </div>

                  {editData === 5 &&
                    <div className="form-footer">
                      <div className="right-buttons">
                        <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
                        <Button variant="outlined" color="primary" onClick={handleRequest}>Update</Button>
                      </div>
                    </div>
                  }
                </ListItem>

                <ListItem>
                  <div className="item">
                    <Typography variant="h5" >
                      ACH Information   {editData !== 6 && <img src={editIcon} alt="edit" onClick={() => { setEditData(6) }} />}
                    </Typography>
                    <Typography component={'div'} className={classes.folderItem}>
                      {editData === 6 &&
                        <>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full-width13-mrn"
                              label="Account Number"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              onChange={updateField('ach_info.accountnumber', true)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full-width14-mrn"
                              label="Routing Number"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              onChange={updateField('ach_info.routingnumber', true)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full-width15-mrn"
                              label="Bank"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              onChange={updateField('ach_info.bankname')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full-width16-mrn"
                              label="Type"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              onChange={updateField('ach_info.typeaccount')}
                            />
                          </div>
                        </>
                      }

                      {editData !== 6 && <>

                        <img src={folderIcon} alt="" />
                        <Button className={classes.textFormation} variant="outlined" color="primary"
                          onClick={() => { setEditData(6) }}
                        >
                          Add ACH Information
                        </Button>
                      </>
                      }
                    </Typography>
                  </div>

                  {editData === 6 &&
                    <div className="form-footer">
                      <div className="right-buttons">
                        <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
                        <Button variant="outlined" color="primary" onClick={handleRequest}>Update</Button>
                      </div>
                    </div>
                  }
                </ListItem>

                <ListItem>
                  <div className="item">
                    <Typography variant="h5" >
                      Billing Information Contact   {editData !== 7 && <img src={editIcon} alt="edit" onClick={() => { handleEditDetail(7, 'billinfocontact') }} />}
                    </Typography>
                    <Typography component={'div'} className={classes.folderItem}>
                      {editData === 7 &&
                        <>
                          <Button variant="contained" color="primary" style={{float: 'left'}} className={classes.textFormation}
                            onClick={() => handleAddMore('billinfocontact', ContactModel)}
                          >
                            Add More User
                          </Button>
                          <div className={classes.shippingItem}>
                            <Chip label={`${selected + 1}/${count}`} className="bg-info-light" />
                            <span> <CustomExpandLessIcon /> <CustomExpandMoreIcon /> </span>
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined1"
                              label="First Name"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.billinfocontact[selected].firstname || ""}
                              onChange={updateField(`billinfocontact.${selected}.firstname`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-2"
                              label="Last Name"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.billinfocontact[selected].lastname || ""}
                              onChange={updateField(`billinfocontact.${selected}.lastname`)}
                            />
                          </div>
                          <div className={classes.marginBM30} align="left">
                            <FormControl variant="outlined" className={classes.formControl}>
                              <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
                                Gender
                              </InputLabel>
                              <Select
                                // native
                                displayEmpty
                                className={classes.halfWidth}
                                inputProps={{
                                  name: 'age',
                                  id: 'outlined-age-native-simple1',
                                }}
                                value={hospitaldetails.billinfocontact[selected].gender || ""}
                                onChange={updateField(`billinfocontact.${selected}.gender`)}
                              >
                                {getGenders()}
                              </Select>
                            </FormControl>
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-4n"
                              label="Phone"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.billinfocontact[selected].phone || ""}
                              onChange={updateField(`billinfocontact.${selected}.phone`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-5"
                              label="Email"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.billinfocontact[selected].email || ""}
                              onChange={updateField(`billinfocontact.${selected}.email`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-fax"
                              label="Fax"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.billinfocontact[selected].fax || ""}
                              onChange={updateField(`billinfocontact.${selected}.fax`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-job-title"
                              label="Job Title"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.billinfocontact[selected].jobtitle || ""}
                              onChange={updateField(`billinfocontact.${selected}.jobtitle`)}
                            />
                          </div>
                        </>
                      }
                      {editData !== 7 && <>

                      <img src={folderIcon} alt="" />
                      <Button className={classes.textFormation} variant="outlined" color="primary"
                        onClick={() => { handleEditDetail(7, 'billinfocontact') }}
                      >
                        Add Billing Info Contact
                      </Button>
                      </>
                      }
                      {/* {editData !== 7 &&
                        <>
                          {(hospitaldetails.billinfocontact.length === 0) ? <Typography className={classes.folderItem} component={'div'}>
                            <img src={folderIcon} alt="" />
                            <Button variant="outlined" color="primary" className={classes.textFormation}
                              onClick={() => { handleEditDetail(7, 'billinfocontact') }}>
                              Add Billing Info Contact {hospitaldetails.billinfocontact.length}
                            </Button>
                          </Typography>
                            :
                            <div className={classes.productAccordion}>
                              {hospitaldetails.billinfocontact.map((obj, index) => (
                                <Accordion key={index} expanded={expanded === (index + 'pice')} onChange={handleChangeAccordion((index + 'pice'))} className={classes.generalMainAccordion}>
                                  <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    className="product-detail-accordion-head"
                                  >
                                    <Typography className={classes.accordionHeading}>{obj.firstname} {obj.lastname}</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Typography component={'div'}>
                                      <TableContainer className={classes.productTableContainer} component={Paper}>
                                        <span className="label label-width">Name</span>
                                        <span className="value">{obj.firstname} {obj.lastname}</span> <br />
                                        <span className="label label-width">Gender</span>
                                        <span className="value ">{obj.gender}</span>  <br />
                                        <span className="label label-width">Phone</span>
                                        <span className="value">{obj.phone}</span> <br />
                                        <span className="label label-width">Email</span>
                                        <span className="value">{obj.email}</span> <br />
                                        <span className="label label-width">Fax</span>
                                        <span className="value ">{obj.fax}</span> <br />
                                        <span className="label label-width">Job Title</span>
                                        <span className="value">{obj.jobtitle}</span>
                                      </TableContainer>
                                    </Typography>
                                  </AccordionDetails>
                                </Accordion>
                              ))
                              }
                            </div>
                          } </>} */}
                    </Typography>
                  </div>

                  {editData === 7 &&
                    <div className="form-footer">
                      <div className="right-buttons">
                        <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
                        <Button variant="outlined" color="primary" onClick={handleRequest}>Update</Button>
                      </div>
                    </div>
                  }
                </ListItem>

                <ListItem>
                  <div className="item">
                    <Typography variant="h5" >
                      Purchase Information Contact  {editData !== 8 && <img src={editIcon} alt="edit" onClick={() => { handleEditDetail(8, 'purchinfocontact') }} />}
                    </Typography>
                    <Typography component={'div'}>
                      {editData === 8 &&
                        <>
                          <Button variant="contained" color="primary" className={classes.textFormation}
                            onClick={() => handleAddMore('purchinfocontact', ContactModel)}
                          >
                            Add More User
                          </Button>
                          <div className={classes.shippingItem}>
                            <Chip label={`${selected + 1}/${count}`} className="bg-info-light" />
                            <span> <CustomExpandLessIcon /> <CustomExpandMoreIcon /> </span>
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full-width17-mrn1"
                              label="First Name"
                              placeholder="eg: John"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.purchinfocontact[selected].firstname || ""}
                              onChange={updateField(`purchinfocontact.${selected}.firstname`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full-width77-mrn1"
                              label="Last Name"
                              placeholder="eg: Smith"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.purchinfocontact[selected].lastname || ""}
                              onChange={updateField(`purchinfocontact.${selected}.lastname`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <FormControl variant="outlined" className={classes.formControl}>
                              <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
                                Gender
                              </InputLabel>
                              <Select
                                // native
                                displayEmpty
                                className={classes.halfWidth}
                                inputProps={{
                                  name: 'age',
                                  id: 'outlined-age-native-simple1',
                                }}
                                value={hospitaldetails.purchinfocontact[selected].gender || ""}
                                onChange={updateField(`purchinfocontact.${selected}.gender`)}
                              >
                                {getGenders()}
                              </Select>
                            </FormControl>
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full-width1-mrn2"
                              label="Phone"
                              placeholder="eg: 0123456789"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.purchinfocontact[selected].phone || ""}
                              onChange={updateField(`purchinfocontact.${selected}.phone`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full-width1-mrn3"
                              label="Email"
                              placeholder="eg: info@email.com"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.purchinfocontact[selected].email || ""}
                              onChange={updateField(`purchinfocontact.${selected}.email`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full-width1-mrn4"
                              label="Fax"
                              placeholder="eg: 1234567"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.purchinfocontact[selected].fax || ""}
                              onChange={updateField(`purchinfocontact.${selected}.fax`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full-width1-mrn5"
                              label="Job"
                              placeholder="eg: job title"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={hospitaldetails.purchinfocontact[selected].jobtitle || ""}
                              onChange={updateField(`purchinfocontact.${selected}.jobtitle`)}
                            />
                          </div>
                        </>
                      }

                      {editData !== 8 && <>
                        <div className={classes.productAccordion}>
                          {hospitaldetails.purchinfocontact.map((obj, index) => (
                            <Accordion key={index} expanded={expanded === (index + 'pic')} onChange={handleChangeAccordion((index + 'pic'))} className={classes.generalMainAccordion}>
                              <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                className="product-detail-accordion-head"
                              >
                                <Typography className={classes.accordionHeading}>{obj.firstname} {obj.lastname}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography component={'div'}>
                                  <TableContainer className={classes.productTableContainer} component={Paper}>
                                    <span className="label label-width">Name</span>
                                    <span className="value">{obj.firstname} {obj.lastname}</span> <br />
                                    <span className="label label-width">Gender</span>
                                    <span className="value ">{obj.gender}</span>  <br />
                                    <span className="label label-width">Phone</span>
                                    <span className="value">{obj.phone}</span> <br />
                                    <span className="label label-width">Email</span>
                                    <span className="value">{obj.email}</span> <br />
                                    <span className="label label-width">Fax</span>
                                    <span className="value ">{obj.fax}</span> <br />
                                    <span className="label label-width">Job Title</span>
                                    <span className="value">{obj.jobtitle}</span>
                                  </TableContainer>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          ))
                          }
                        </div>
                      </>}

                    </Typography>
                  </div>

                  {editData === 8 &&
                    <div className="form-footer">
                      <div className="right-buttons">
                        <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
                        <Button variant="outlined" color="primary" onClick={handleRequest}>Update</Button>
                      </div>
                    </div>
                  }
                </ListItem>

                <ListItem>
                  <div className="item">
                    <Typography variant="h5" >
                      Tax ID   {editData !== 9 && <img src={editIcon} alt="edit" onClick={() => { setEditData(9) }} />}
                    </Typography>
                    <Typography component={'div'}>
                      {editData === 9 &&
                        <div className={classes.marginBM30}>
                          <TextField
                            id="outlined-full-width-1mrn6"
                            label="Tax ID"
                            placeholder="eg: 456-785-1496"
                            className={classes.customFullWidth}
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            value={hospitaldetails.taxid}
                            onChange={updateField('taxid')}
                          />
                        </div>
                      }

                      {editData !== 9 && <span className="label">{hospitaldetails.taxid}</span>}
                    </Typography>
                  </div>

                  {editData === 9 &&
                    <div className="form-footer">
                      <div className="right-buttons">
                        <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
                        <Button variant="outlined" color="primary" onClick={handleRequest}>Update</Button>
                      </div>
                    </div>
                  }
                </ListItem>

              </List>
            </CardContent>
          </Card>

      }
    </>
  );
};