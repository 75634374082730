import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import useStyles from "../surgicalevent/styles";
import infoIcon from "../../icons/info_icon.svg";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: (props)=> props.color ,
  },
}))(LinearProgress);



export function CustomizedProgressBars({
  completePercent
}) {
  const classes = useStyles();
  return (
    <div className={classes.progressroot}>
      <BorderLinearProgress variant="determinate" color="#183C73" value={completePercent} /> <label className={classes.progressLabel}>{completePercent}%</label>
    </div>
  );
}

export function NewCustomizedProgressBars({
  completePercent, showLabel = "fd", showIcon = false
}) {

  const classes = useStyles();

  return (
    <div className={classes.progressroot}>
      <BorderLinearProgress
        color= 'primary'
        variant="determinate"
        value={completePercent}
      />
      <label className={classes.progressLabel1}>
        {showLabel}
      </label>
      {
        showIcon && <span ><img src={infoIcon} alt="" /></span>
      }
    </div>
  );
}
