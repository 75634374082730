import React from "react";
import Contracts from "../../components/Contracts/Contracts1";
import { contractFilter1 } from "../../services/daml-filters";
import { AuditLogsModel as AuditLogs } from "../../models/AuditLogs";
import useStyles from "./styles";
import useStyles1 from "../purchaseorder/styles";
import { formatTime } from "../../services/util";
import withLoading from "../../hoc/withLoading";
import withFilters from "../../hoc/withFilters";
import { handleClickedContract } from '../surgicalevent/surgicaleventlistcontracts1';

/**
 * SurgicalEvent List Contracts
 * @param {Object} searchParams 
 * @param {Array} contracts 
 * @param {Function} setArchived
 * @param {Function} handleDetail
 * @returns 
 */
function AuditLogListContarcts({
    searchParams = {},
    allContracts = [],
    handleDetail = null,
}) {

    const classes = useStyles();
    const classes1 = useStyles1();

    let contracts = contractFilter1(allContracts, searchParams)
        .map(c => {
            c.payload.created_at_time = formatTime(c.payload.created_at);
            if (c.payload.event === 'PoAcceptDecline' && (c.payload.logEvents.value.povData.purchaseorderdata.postatus === 'Accepted' || c.payload.logEvents.value.povData.purchaseorderdata.postatus === 'InvoiceCreated')) {
                c.payload.event = 'PoAccept';
            }
            if (c.payload.event === 'PoAcceptDecline' && c.payload.logEvents.value.povData.purchaseorderdata.postatus === 'Rejected') {
                c.payload.event = 'PoDecline';
            }
            if (c.payload.event === 'InvShipped' && c.payload.logEvents.value.status === 'Shipped') {
                c.payload.event = 'Shipped';
            }
            if (c.payload.event === 'InvShipped' && c.payload.logEvents.value.status === 'BackOrder') {
                c.payload.event = 'BackOrder';
            }
            if (c.payload.event === 'InvShipped' && c.payload.logEvents.value.status === 'Received' && c.payload.logEvents.value.stockforfacility) {
                c.payload.event = 'StockedForFacility';
            }
            return c;
        });
    console.log("[AuditLogListContarcts]", searchParams, contracts);


    return (

        <Contracts
            className={classes.customRoot}
            contracts={contracts}
            columns={[
                ["Date", "payload.created_at", {
                    search: {
                        type: "DateSearch",
                        name: "created_at",
                    },
                    options: {
                        flex: 1,
                    },
                }],
                ["Time", "payload.created_at_time", {
                    search: {
                        type: "TimeSearch",
                        name: "created_at_time",
                    },
                    options: {
                        flex: 1,
                    },
                }],
                ["Name", "payload.username", {
                    search: {
                        type: "AdaptiveSearch",
                        name: "username",
                        getValue: (c) => c.payload.username,
                        contracts: allContracts,
                    },
                    options: {
                        flex: 1,
                    },
                }],
                ["User", "payload.user", {
                    search: {
                        type: "AdaptiveSearch",
                        name: "user",
                        getValue: (c) => c.payload.user,
                        contracts: allContracts,
                    },
                    options: {
                        flex: 1,
                    },
                }],
                ["Email", "payload.useremail", {
                    search: {
                        type: "AdaptiveSearch",
                        name: "useremail",
                        getValue: (c) => c.payload.useremail,
                        contracts: allContracts,
                    },
                    options: {
                        flex: 1,
                    },
                }],
                ["Executed Action", "payload.event", {
                    type: "render",
                    render: (param) => AuditLogs[param.value],
                    options: {
                        flex: 1.1,
                    },
                    search: {
                        type: "SelectSearch",
                        name: "event",
                        options: Object.keys(AuditLogs).map(value => ({ label: AuditLogs[value], value: value })),
                        maxColCnt: 25,
                        classes: {
                            popoverBox: classes1.newPopoverBox,
                            popoverInner: classes1.newInnerPopper,
                        },
                    },
                }],
            ]}
            options={{
                checkbox: true,
            }}
            handleClicked={(e) => handleClickedContract(e, handleDetail)}
        />
    );
}

const AuditListContractsWithLoading = withLoading(AuditLogListContarcts);
export default withFilters(AuditListContractsWithLoading)([
    { name: "created_at", label: "Date", type: "date" },
    { name: "created_at_time", label: "Time", type: "time" },
    { name: "username", label: "Name" },
    { name: "user", label: "User" },
    { name: "useremail", label: "Email" },
    { name: "event", label: "Executed Action" },
]);
