import React ,{Fragment} from 'react';
import {
    Typography, Grid, 
} from "@material-ui/core";
import useStyles from "../showlist/styles";


function ContactDetails({obj}) {

    const classes = useStyles() ;
    return (
        <>
            {
                [
                    {
                        title: 'Name', value: `${obj?.firstname} ${obj?.lastname}` || ""
                    },
                    {
                        title: 'Gender', value: obj?.gender || ""
                    },
                    {
                        title: 'Phone', value: obj?.phone || ""
                    },
                    {
                        title: 'Email', value: obj?.email || ""
                    },
                    {
                        title: 'Fax', value: obj?.fax || ""
                    },
                    {
                        title: 'Job Title', value: obj?.jobtitle || ""
                    },
                    {
                        title: 'Department', value: obj?.department || ""
                    },
                    // {
                    //     title: 'Role Type', value: obj?.roletype || ""
                    // },
                ].map((item ,index) => {
                    return (<Fragment key={index}>
                        <Grid item xs={5} className={classes.gridSubItem}>
                            <label className={classes.acordionSubtitle}>{item.title}</label>
                        </Grid>
                        <Grid item xs={7} className={classes.gridSubItem}>
                            <Typography className={classes.acordionDetails}>{item.value}</Typography>
                        </Grid>
                    </Fragment>)
                })
            }
        </>
    )
}

export default ContactDetails;