import React, { useState } from "react";
import {  Dialog, DialogContent, DialogTitle, DialogActions } from "@material-ui/core";
import { Close } from '@material-ui/icons';
import { useLayoutDispatch, setLoading, unsetLoading } from "../../context/LayoutContext";
import { FormControl } from "@material-ui/core";
import { Button,  DialogContentText,} from "@material-ui/core";


import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';



import { isAsync } from "../../services/util";

import useStyles from "../surgicalevent/styles";

/**
 * General Modal Dialog
 * @param {Boolean} open 
 * @param {Function} setOpen 
 * @param {String} type 
 * @param {String} heading Warning text in title of dialog
 * @param {String} text HTML content in body of dialog content
 * @param {String} firstBtn 
 * @param {String} secondBtn 
 * @param {Function} handleRequest 
 * @param {Function} handleSecRequest 
 * @param {Boolean} isRedIcon
 * @returns 
 */
export default function DialogueBox({
    open, setOpen, handleRequest = () => { }, handleSecRequest = () => { }, dateData
}) {

     
    const [holidayType, setHolidayType] = useState("");

    const classes = useStyles();
    const layoutDispatch = useLayoutDispatch();


    // handlers to click first button
    const handleFirstBtnClicked = async (event) => {
        
        await handleRequest(dateData ,holidayType );
        if (typeof handleSecRequest === 'function') {
            if (isAsync(handleRequest)) {
                // spinner
                setLoading(layoutDispatch);
                unsetLoading(layoutDispatch);
            }
            else {
               
                unsetLoading(layoutDispatch);
            }
        }
    };

    // handlers to click second button
    const handleSecondBtnClicked = async (event) => {
        setOpen(false);
        if (typeof handleSecRequest === 'function') {
            if (isAsync(handleSecRequest)) {
                // spinner
                setLoading(layoutDispatch);
                await handleSecRequest(event);
                unsetLoading(layoutDispatch);
            }
            else {
                handleSecRequest(event);
                unsetLoading(layoutDispatch);
            }
        }
    };


    return (
        <Dialog
            open={open}
            className={classes.customRoot}
            style={{ textAlign: 'center' }}
            aria-labelledby="draggable-dialog-title"
        >

            <DialogContent>

                <DialogTitle className={classes.dialogTitle} style={{ cursor: 'move' }} id="draggable-dialog-title1">                    <div className="customClose" onClick={() => { setOpen(false) }}><Close /></div>
                    <div>
                        <span>
                            {
                                dateData ? <h1>{dateData}</h1> : <p> Holiday </p>
                            }
                        </span>
                    </div>

                </DialogTitle>

                <DialogContentText component={'div'}>
                    <div className={classes.marginBM30}>

                        <FormControl
                            variant="outlined"
                            className="radio-btn" >
                            <div className={classes.customCheckbox}>
                                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                                    <FormControlLabel value="bankHoliday"
                                        control={<Radio color="primary" />}
                                        className={classes.customRadio}
                                        label="Bank Holiday"
                                        onChange={(e) => {
                                            setHolidayType(e.target.value);
                                        }}
                                    />
                                    <FormControlLabel value="otherHoliday"
                                        control={<Radio color="primary"  />}
                                        className={classes.customRadio}
                                        label="Other Holiday"
                                        onChange={(e) => {
                                            setHolidayType(e.target.value);
                                        }}
                                    />
                                </RadioGroup>
                            </div>
                        </FormControl>

                    </div>
                    <DialogActions>
                        <Button autoFocus className="yesBtn" variant="outlined" onClick={handleFirstBtnClicked} color="primary">
                            Yes
                        </Button>
                        <Button className="noBtn" variant="contained" color="primary" onClick={handleSecondBtnClicked}>
                            No
                        </Button>
                    </DialogActions>
                </DialogContentText>
            </DialogContent>

        </Dialog>
    );
}
