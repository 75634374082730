import React from "react";
import classNames from "classnames";
import { Table, TableBody, TableRow, TableCell, Grid, Typography } from "@material-ui/core";
import useStyles1 from "./styles";
import AuditDetails from "./auditDetails";
import moment from 'moment-timezone';
import { useDamlState } from "../../context/DamlContext";


/**
 * @param {Object} searchParams 
 * @param {Object} options 
 * @param {Object} invData 
 * @returns 
 */
export default function AuditDetailsInvoice({
     invData,
}) {
    const classes1 = useStyles1();
    const invDetails = invData.logEvents.value.invData
    const invoiceDetails = invData.logEvents.value.invData.invoicedetail
    const { rolePayload } = useDamlState();
    const { hospitaldetails } = (rolePayload ? rolePayload : {});

    const time = moment(invData.created_at).tz(moment.tz.guess()).format('LT z');




    return (
        <Grid>
            <Grid container direction="row" className={classNames(classes1.overviewAuditLog, classes1.auditLogPanel)}>
                <Grid item xs={3} className={classes1.overviewAuditLogPO} >
                    <Table className={classes1.auditLogForm}>
                        <TableBody>
                            <TableRow>
                                <TableCell className="new-subtitle" style={{ paddingTop: 0 }}>Invoice Details</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="label">Invoice Number</TableCell>
                                <TableCell className="value">{invDetails.invoicedata.invinvoicenumber}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="label">Invoice Date</TableCell>
                                <TableCell className="value">{invDetails.invoicedata.invinvoicedate}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <AuditDetails
                        poData={invData}
                        time={time}
                    />
                </Grid>
                <Grid item xs={9} className={classes1.overviewAuditLogPO}>
                    <Grid container className={classes1.girdShipTo1}>
                        <Grid item xs={12} className={classes1.gridInvoiceNo}>
                            <Grid container style={{ padding: "1rem" }} justifyContent='space-between'>
                                <Grid item>
                                    <Typography variant="inherit" component="span" className="label">Invoice number: </Typography>
                                    <Typography variant="inherit" component="span" className="label" style={{ color: "#1C3C74" }}><b>{invDetails.invoicedata.invinvoicenumber}</b></Typography>

                                </Grid>
                                <Grid item>
                                    <b>Payment Type: </b>
                                    <span>Check</span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes1.commentstyle1}>
                            <Grid container style={{ border: "1px solid #CCCCCC", borderRadius: "0.5rem" }}>
                                <Grid item xs={4} className={classes1.commentstyle1}>
                                    <Grid container direction='column'>
                                        <Grid item>
                                            <Typography>Hospital: {invDetails.hospital}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography>{hospitaldetails ? hospitaldetails.physicaladdress?.address1 : null}</Typography>
                                            <Typography>{hospitaldetails ? `${hospitaldetails.physicaladdress?.city} ${hospitaldetails.physicaladdress?.state} ${hospitaldetails.physicaladdress?.zipcode}` : null}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} className={classes1.gridInvoiceTo}>
                                    <Grid container direction='column'>
                                        <Grid item>
                                            <Typography>Vendor: {invDetails.vendor}</Typography>
                                        </Grid>
                                        <Grid item>
                                        
                                            <Typography>{invDetails.vendoraddress?.address1} </Typography>
                                            <Typography>{invDetails.vendoraddress?.address2}</Typography>
                                            <Typography>{invDetails.vendoraddress ? `${invDetails.vendoraddress.city} ${invDetails.vendoraddress.state} ${invDetails.vendoraddress.country} ${invDetails.vendoraddress.zipcode}` : null}</Typography>

                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} className={classes1.commentstyle1}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography>Date: {invDetails.invoicedata.invinvoicedate}</Typography>
                                        </Grid>
                                        <Grid item> <Typography>Buyer:</Typography>
                                        <Typography></Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography>Type:</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes1.girdShipTo}>
                            <Grid container className={classes1.girdShipTo1}>
                                <Grid item xs={4} className={classes1.commentstyle1}>
                                    <Grid container direction='column'>
                                        <Grid item>
                                            <Typography>Ship To:</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography>{hospitaldetails && hospitaldetails.shippingaddress[0]?.address1}</Typography>
                                            <Typography>{hospitaldetails && hospitaldetails.shippingaddress[0]?.city} {hospitaldetails && hospitaldetails.shippingaddress[0]?.location}</Typography>
                                            <Typography>{hospitaldetails && hospitaldetails.shippingaddress[0]?.state} {hospitaldetails && hospitaldetails.shippingaddress[0]?.zipcode}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} className={classes1.gridInvoiceTo}>
                                    <Grid container direction='column'>
                                        <Grid item>
                                            <Typography>Invoice To:</Typography>
                                            <Typography>{invDetails.operator}</Typography>
                                            <Typography>{invDetails.hospital}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography>Contract ID:</Typography>
                                            <Typography></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} className={classes1.commentstyle1}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Grid container direction='column'>
                                                <Grid item>
                                                    <Typography>Terms</Typography>
                                                    <Typography></Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container direction='column'>
                                                <Grid item>
                                                    <Typography>FOB Contact:</Typography>
                                                    <Typography>{hospitaldetails && hospitaldetails.billingaddress.location}</Typography>
                                                </Grid>
                                            </Grid>
                                            <div></div>
                                            <div></div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container direction='column'>
                                                <Grid item>
                                                    <Typography>MA EXEMPT PURCH CERT #</Typography>
                                                    <Typography></Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes1.girdShipTo}>
                            <Grid container className={classes1.girdShipTo1}>
                                <Grid item xs={12} className={classes1.gridInvoiceNo}>
                                    <Grid container className={classes1.gridBottomBorder}>
                                        <Grid item md={1} sm={1} className={classNames(classes1.gridHead, classes1.gridSeriesWidth2)}>Line</Grid>
                                        <Grid item md={1} sm={1} className={classes1.gridHead}>Item</Grid>
                                        <Grid item md={4} sm={1} className={classes1.gridHead}>Description</Grid>
                                        <Grid item md={1} sm={1} className={classNames(classes1.gridHead, classes1.mleft5)}>Manufacturer</Grid>
                                        <Grid item md={1} sm={1} className={classes1.gridHead}>Quantity</Grid>
                                        <Grid item md={1} sm={1} className={classes1.gridHead}>Price</Grid>
                                        <Grid item md={2} sm={1} className={classes1.gridHead}>DEPT or INVEN</Grid>
                                        <Grid item md={1} sm={1} className={classNames(classes1.gridHead, classes1.gridCellAlign)}>G/L Account</Grid>
                                    </Grid>

                                </Grid>
                                <Grid container className={classes1.gridColumn1}>

                                    {(invoiceDetails && invoiceDetails.length > 0)
                                        ?
                                        invoiceDetails.map((invoiceDetails, index) => (
                                            <Grid item md={12} sm={12} key={index} >
                                                <Grid container style={{ height: "30px" }} >
                                                    <Grid item md={1} sm={1} className={classNames(classes1.gridCellCenter1, classes1.gridSeriesWidth)}>{index + 1}</Grid>
                                                    <Grid item md={1} sm={1} className={classNames(classes1.gridCell1, classes1.hideOverflow1)}>{invoiceDetails.invdproductitem}</Grid>
                                                    <Grid item md={4} sm={1} className={classNames(classes1.gridCell1, classes1.gridWidth1, classes1.hideOverflow1)}>{invoiceDetails.invddescription}</Grid>
                                                    <Grid item md={1} sm={1} className={classes1.gridCellCenter1}>{invoiceDetails.invdproduct.sepproductmanufacturer}</Grid>
                                                    <Grid item md={1} sm={1} className={classes1.gridCellCenter1}>{invoiceDetails.invdproductquantity}</Grid>
                                                    <Grid item md={1} sm={1} className={classes1.gridCellCenter1}>${invoiceDetails.invdproductprice}</Grid>
                                                    <Grid item md={2} sm={1} className={classes1.gridCellCenter1}>NA</Grid>
                                                    <Grid item md={1} sm={1} className={classes1.gridCellCenter1}>${invoiceDetails.invdtotalcost}</Grid>
                                                </Grid>
                                            </Grid>
                                        ))
                                        :
                                        null
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={8} className={classes1.gridComment}>
                                            <Grid container className={classes1.commentstyle}>
                                                <Typography>Comments :</Typography>
                                                <Grid item xs={12} className={classes1.commentstyle1}>
                                                    <Typography></Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4} className={classes1.gridComment}>
                                            <Grid container className={classes1.commentstyle}>
                                                <Grid item xs={10}>
                                                <Typography>Total :</Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                  <Typography>{invoiceDetails[0].invdtotalcost}</Typography>
                                                </Grid>
                                                </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Grid >
    );
}