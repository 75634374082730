import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import FiltersPanel from "../components/Filters/FiltersPanel";
import useStyles from "./styles";
import { useLayoutState } from "../context/LayoutContext";

export default function withFilters (WrappedComponent) {
  return (filters=[]) => {
    return ({ ...props }) => {
      const classes = useStyles();
      const [searchParams, setSearchParams] = useState({});
      const layoutState = useLayoutState()
      return (
        <Grid container direction="column" className={layoutState.darkMode?classes.filterWrapperDM:classes.filterWrapper} >
          <Grid item>
            <FiltersPanel 
              keys={filters}
              setFilters={setSearchParams}
            />
          </Grid>
          <Grid item className={classes.tabPostion}>
            <WrappedComponent 
              searchParams={searchParams}
              {...props} 
            />
          </Grid>
        </Grid>
      );
    }
  }
}