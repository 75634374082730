import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Grid, Typography, AppBar, Tabs, Tab } from "@material-ui/core";
import { useDamlState } from "../../context/DamlContext";
import TabPanel, { a11yProps } from "../../components/Modals/MultiTabsModals/TabPanel";
import ProcedurePanel from "./procedure-panel";
import LocationsPanel from "./location-panel";
import HospitalProfile from './hospitalProfile1';
import UserList from "../user/userlist1";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "./styles";
import SurgeonList from "./surgeon-panel";
import { useTranslation } from 'react-i18next';

/**
 * Hospital Profile Tab
 * @returns 
 */


/**
 * Facility Settings Tab
 * @returns 
 */
const FacilitySettings = () => {

  const classes1 = useStyles1();

  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const { t } = useTranslation();
  
  return (
    <Grid container direction="column" alignItems="baseline">
      <Grid item>
        <Typography className={classNames(classes1.newTabTitle,classes1.newTabTitle1)}>{t('admin.settings.tabs.facility')}</Typography>
      </Grid>
      <Grid item className={classes1.facilityPanel}>
        <AppBar position="static" className={classes1.newTabPanelMenu}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={tab}
            onChange={handleChange}
          >
            <Tab label={t('admin.settings.tabs.procedure')} {...a11yProps(0)} />
            <Tab label={t('admin.settings.tabs.inventory')}  {...a11yProps(1)} />
            <Tab label={t('admin.settings.tabs.purchase')}  {...a11yProps(2)} />
            <Tab label={t('admin.settings.tabs.invoice')}  {...a11yProps(3)} />
            <Tab label={t('admin.settings.tabs.payment')} {...a11yProps(4)} />
            <Tab label={t('admin.settings.tabs.surgeon')}  {...a11yProps(5)} />
          </Tabs>
        </AppBar>

        <TabPanel value={tab} index={0} className={classNames(classes1.newTabPanelbody1, classes1.newTabPanelbody3)}>
          <ProcedurePanel />
        </TabPanel>
        <TabPanel value={tab} index={1} className={classes1.newTabPanelbody1}>
          <LocationsPanel />
        </TabPanel>
        <TabPanel value={tab} index={2} className={classes1.newTabPanelbody1}>
        </TabPanel>
        <TabPanel value={tab} index={3} className={classes1.newTabPanelbody1}>
        </TabPanel>
        <TabPanel value={tab} index={4} className={classes1.newTabPanelbody1}>
        </TabPanel>
        <TabPanel value={tab} index={5} className={classes1.newTabPanelbody1}>
          <SurgeonList />
        </TabPanel>
      </Grid>
    </Grid>
  );
};

/**
 * Vendor Management Tab
 * @returns 
 */
const VendorManagement = () => {
  return (
    <></>
  );
};

/**
 * Vendor Integration Tab
 * @returns 
 */
const VendorIntegration = () => {
  return (
    <></>
  );
};

/**
 * User Management Tab
 * @returns 
 */
const UserManagement = () => {
  return (
    <>
      <UserList />
    </>
  );
};

/**
 * Admin Settings Page
 * @param {Number} tab 
 * @returns 
 */
export default function AdminSettings({
  tab = 0
}) {

  const classes = useStyles();
  const classes1 = useStyles1();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(tab);
  const [tabMenus, setTabMenus] = useState([]);
  const [tabPanels, setTabPanels] = useState([]);
  const { rolePayload } = useDamlState();
  const standalone_payment = rolePayload?.hospitaldetails?.standalone_payment;

  
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    console.log('standalone_payment', standalone_payment)
    setTabPanels(standalone_payment === undefined ? [] : (standalone_payment ? [UserManagement] : [FacilitySettings, VendorManagement, VendorIntegration, UserManagement]));
  
    setTabMenus(
      standalone_payment === undefined ? [] : (standalone_payment ? [
        `${t('admin.settings.tabs.hospital')}`, `${t('admin.settings.tabs.user')}`] : 
        [ `${t('admin.settings.tabs.hospital')}`, `${t('admin.settings.tabs.facility')}`, `${t('admin.settings.tabs.vendor')}`, `${t('admin.settings.tabs.vendori')}`, `${t('admin.settings.tabs.user')}`]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [standalone_payment])

  return (
    <>

      <Grid>

        <Grid container justifyContent="space-between" >
          <Grid item xs>
            <Typography variant="h4" className={classes.newPageTitle}>{t('admin.settings.title')}</Typography>
          </Grid>
          <Grid item></Grid>
        </Grid>

        <AppBar position="static" className={classNames(classes.tabPanelMenu, classes.newTabPanelMenu, classes1.newTabPanelMenu1)}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={selectedTab}
            onChange={handleChange}
          >
            {
              tabMenus.map((t, i) => <Tab key={t} label={t} {...a11yProps(i)} />)
            }
          </Tabs>
        </AppBar>

        <TabPanel value={selectedTab} index={0} className={classes1.newTabPanelbody2}>
          <HospitalProfile />
        </TabPanel>
        {
          tabPanels.map((Component, i) =>
            <TabPanel value={selectedTab} key={i} index={i + 1} className={classes1.newTabPanelbody2}>
              <Component />
            </TabPanel>
          )
        }
      </Grid>

    </>
  );
}

export const AdminSettingsHospitalProfile = (props) => (<AdminSettings tab={0} {...props} />);
export const AdminSettingsFacilitySettings = (props) => (<AdminSettings tab={1} {...props} />);
export const AdminSettingsVendorManagement = (props) => (<AdminSettings tab={2} {...props} />);
export const AdminSettingsVendorIntegration = (props) => (<AdminSettings tab={3} {...props} />);
//export const AdminSettingsUserManagement = (props) => (<AdminSettings tab={4} {...props} />);
export const AdminSettingsUserManagement = (props) => {
  const { rolePayload } = useDamlState();
  const standalone_payment = rolePayload?.hospitaldetails?.standalone_payment;
  return <AdminSettings tab={standalone_payment ? 1 : 4} {...props} />
}

/**
 * Admin Settings Page for Vendor
 * @param {Number} tab 
 * @returns 
 */
export function AdminSettingsVendor({
  tab = 0
}) {

  const classes = useStyles();
  const classes1 = useStyles1();

  const [selectedTab, setSelectedTab] = useState(tab);


  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <Grid container>
            <Grid item xs>
              <Typography variant="h4" className={classes.newPageTitle}>Admin Settings</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <AppBar position="static" className={classNames(classes.tabPanelMenu, classes.newTabPanelMenu, classes1.newTabPanelMenu1)}>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value={selectedTab}
              onChange={handleChange}
            >
              <Tab label="Vendor Profile" {...a11yProps(0)} />
              <Tab label="Vendor Settings" {...a11yProps(1)} />
              <Tab label="Hospital Integration" {...a11yProps(2)} />
              <Tab label="User Management" {...a11yProps(3)} />
            </Tabs>
          </AppBar>
          <TabPanel value={selectedTab} index={0} className={classes1.newTabPanelbody4}>
            Vendor Profile
          </TabPanel>
          <TabPanel value={selectedTab} index={1} className={classes1.newTabPanelbody4}>
            Vendor Settings
          </TabPanel>
          <TabPanel value={selectedTab} index={2} className={classes1.newTabPanelbody4}>
            Hospital Integration
          </TabPanel>
          <TabPanel value={selectedTab} index={3} className={classes1.newTabPanelbody4}>
            <UserList />
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
}

export const AdminSettingsVendorProfile = (props) => (<AdminSettingsVendor tab={0} {...props} />);
export const AdminSettingsVendorSettings = (props) => (<AdminSettingsVendor tab={1} {...props} />);
export const AdminSettingsHospitalIntegration = (props) => (<AdminSettingsVendor tab={2} {...props} />);
export const AdminSettingsVendorUserManagement = (props) => (<AdminSettingsVendor tab={3} {...props} />);