import { CONTRACT_SET, CONTRACT_SET_ALL, CONTRACT_SET_DATA } from "../actions/constants";

const default_state = {
  detail: {},
  detailErrors: {},
  serviceterm: [],
  pmtrebate: [],
  spendrebate: [],
  volumerebate: [],
  cptcodetotal: [],
};

export default function contractReducer (state=default_state, action) {
  const { type, payload } = action;
  if (type === CONTRACT_SET) { 
    if (payload && typeof payload === 'object') return { ...state, ...payload };
  }
  else if (type === CONTRACT_SET_ALL) { 
    if (payload && typeof payload === 'object') {
      let newpayload = { ...payload };
      return { ...default_state, ...newpayload };
    }
  }
  else if (type === CONTRACT_SET_DATA) { 
    if (payload && typeof payload === 'object') {
      for (let key in payload) {
        if (payload[key] && state.detailErrors[key]) delete state.detailErrors[key];
      }
      return { ...state, detail: { ...state.detail, ...payload } };
    }
  }
  return state;
}