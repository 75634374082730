import React, { useState, useEffect } from "react";
import { Button, Dialog, TextField, FormControlLabel, Checkbox } from "@material-ui/core";
// import { onChangeNumber } from "../../components/Modals/handles";
import { VendorTemplateModel, requiredVendorTemplate } from "../../models/VendorTemplate";
import { isEmptyObject } from "../../services/util";
import { InputDistributor } from "../surgicalevent/edit-product";
import useStyles from "../surgicalevent/styles";
import { useTranslation } from 'react-i18next';


/**
 * Create Vendor Template Dialog in Hospital
 * @param {Boolean} isCreate 
 * @param {Boolean} open 
 * @param {Function} setOpen 
 * @param {Function} handleRequest 
 * @param {String} title 
 * @param {String} editTitle 
 * @param {Object} defaultValue 
 * @param {String} vendor 
 * @param {Function} setVendor 
 * @returns 
 */
export default function AddEditVendorTemplate ({
  isCreate, 
  open,
  setOpen,
  handleRequest,
  title="",
  editTitle = "",
  defaultValue = {},
  vendor,
  setVendor,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [vendortemplate, setVendorTemplate] = useState({ ...VendorTemplateModel });
  const [productError, setProductError] = useState({ ...VendorTemplateModel });
  const [vendorError, setVendorError] = useState("");
  // disable validation once input each field
  // handleValueFill = () => {};

  const handleCreateUpdate = async () => {
    if (!vendor) {
      setVendorError("Vendor is required.");
      return false;
    }
    let newErrors = requiredVendorTemplate(vendortemplate);
    console.log("[AddEditVendorTemplate] handleCreateUpdate", newErrors);
    if (!isEmptyObject(newErrors)) {
      setProductError({ ...newErrors });
      return false;
    }

    setProductError({ ...VendorTemplateModel });

    await handleRequest(vendortemplate);
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      console.log("[AddEditVendorTemplate] Open AddEditVendorTemplate dialog.");
      setVendorError("");
      setProductError({ ...VendorTemplateModel });
      setVendorTemplate({ ...VendorTemplateModel, ...defaultValue });
    }
  }, [open, defaultValue]);

  // console.log("[AddEditVendorTemplate]", vendortemplate);

  return (
    <Dialog
        className={classes.customRoot}
        open={open}
        onClose={()=>{setOpen(false)}}
        aria-labelledby="draggable-dialog-title"
      >
      <div className="formsteps">
        <h1  className={classes.formHeading}>
          {isCreate ? 
            title : 
            editTitle}
        </h1>
        
        <div className={classes.AddEditVendorForm}>
          <br/>

          <div className={classes.marginBM30}>
            <InputDistributor 
              isDistributorRelation={true}
              distributor={vendor}
              error={vendorError}
              setDistributor={(value) => {
                setVendor(value);
              }}
              label="Vendor"
              placeholder=""
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              id="input-with-icon-adornment1"
              label="API Index"
              placeholder=""
              className={classes.customFullWidth}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              value={vendortemplate.api_index1._index || ""}
              error={!!productError.api_index1._index}
              helperText={productError.api_index1._index}
              onChange={(e) => {
                  setVendorTemplate({
                    ...vendortemplate, 
                    api_index1: { ...vendortemplate.api_index1, _index : e.target.value }
                  });
                }}
              variant="outlined"
            />  
          </div>
          <div className={classes.marginBM30}>
            <TextField
              id="input-with-icon-adornment2"
              label="Id"
              placeholder=""
              className={classes.customFullWidth}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              value={vendortemplate.api_index1._id || ""}
              error={!!productError.api_index1._id}
              helperText={productError.api_index1._id}
              onChange={(e) => {
                  setVendorTemplate({
                    ...vendortemplate, 
                    api_index1: { ...vendortemplate.api_index1, _id : e.target.value }
                  });
                }}
              variant="outlined"
            />  
          </div>
          <div className={classes.marginBM30}>
            <TextField
              id="input-with-icon-adornment3"
              label="Supplier Id"
              placeholder=""
              className={classes.customFullWidth}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              value={vendortemplate.api_payload.referenceId || ""}
              error={!!productError.api_payload.referenceId}
              helperText={productError.api_payload.referenceId}
              onChange={(e) => {
                  setVendorTemplate({
                    ...vendortemplate, 
                    api_payload: { ...vendortemplate.api_payload, referenceId : e.target.value }
                  });
                }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              id="input-with-icon-adornment4"
              label="Procurement BU"
              placeholder=""
              className={classes.customFullWidth}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              value={vendortemplate.api_payload.procurementBU || ""}
              error={!!productError.api_payload.procurementBU}
              helperText={productError.api_payload.procurementBU}
              onChange={(e) => {
                  setVendorTemplate({
                    ...vendortemplate, 
                    api_payload: { ...vendortemplate.api_payload, procurementBU : e.target.value }
                  });
                }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              id="input-with-icon-adornment5"
              label="Requisitioning BU"
              placeholder=""
              className={classes.customFullWidth}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              value={vendortemplate.api_payload.requisitioningBU || ""}
              error={!!productError.api_payload.requisitioningBU}
              helperText={productError.api_payload.requisitioningBU}
              onChange={(e) => {
                  setVendorTemplate({
                    ...vendortemplate, 
                    api_payload: { ...vendortemplate.api_payload, requisitioningBU : e.target.value }
                  });
                }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              id="input-with-icon-adornment6"
              label="Buyer"
              placeholder=""
              className={classes.customFullWidth}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              value={vendortemplate.api_payload.buyer || ""}
              error={!!productError.api_payload.buyer}
              helperText={productError.api_payload.buyer}
              onChange={(e) => {
                  setVendorTemplate({
                    ...vendortemplate, 
                    api_payload: { ...vendortemplate.api_payload, buyer : e.target.value }
                  });
                }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              id="input-with-icon-adornment7"
              label="Supplier"
              placeholder=""
              className={classes.customFullWidth}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              value={vendortemplate.api_payload.supplier || ""}
              error={!!productError.api_payload.supplier}
              helperText={productError.api_payload.supplier}
              onChange={(e) => {
                  setVendorTemplate({
                    ...vendortemplate, 
                    api_payload: { ...vendortemplate.api_payload, supplier : e.target.value }
                  });
                }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              id="input-with-icon-adornment8"
              label="Currency"
              placeholder=""
              className={classes.customFullWidth}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              value={vendortemplate.api_payload.currency || ""}
              error={!!productError.api_payload.currency}
              helperText={productError.api_payload.currency}
              onChange={(e) => {
                  setVendorTemplate({
                    ...vendortemplate, 
                    api_payload: { ...vendortemplate.api_payload, currency : e.target.value }
                  });
                }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              id="input-with-icon-adornment9"
              label="Supplier Site"
              placeholder=""
              className={classes.customFullWidth}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              value={vendortemplate.api_payload.supplierSite || ""}
              error={!!productError.api_payload.supplierSite}
              helperText={productError.api_payload.supplierSite}
              onChange={(e) => {
                  setVendorTemplate({
                    ...vendortemplate, 
                    api_payload: { ...vendortemplate.api_payload, supplierSite : e.target.value }
                  });
                }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              id="input-with-icon-adornment10"
              label="Bill To Location"
              placeholder=""
              className={classes.customFullWidth}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              value={vendortemplate.api_payload.billToLocation || ""}
              error={!!productError.api_payload.billToLocation}
              helperText={productError.api_payload.billToLocation}
              onChange={(e) => {
                  setVendorTemplate({
                    ...vendortemplate, 
                    api_payload: { ...vendortemplate.api_payload, billToLocation : e.target.value }
                  });
                }}
              variant="outlined"
            />  
          </div>
          <div className={classes.marginBM30}>
            <TextField
              id="input-with-icon-adornment11"
              label="Default Ship to Location"
              placeholder=""
              className={classes.customFullWidth}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              value={vendortemplate.api_payload.defaultShipToLocation || ""}
              error={!!productError.api_payload.defaultShipToLocation}
              helperText={productError.api_payload.defaultShipToLocation}
              onChange={(e) => {
                  setVendorTemplate({
                    ...vendortemplate, 
                    api_payload: { ...vendortemplate.api_payload, defaultShipToLocation : e.target.value }
                  });
                }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              id="input-with-icon-adornment12"
              label="Required Acknowledgment"
              placeholder=""
              className={classes.customFullWidth}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              value={vendortemplate.api_payload.requiredAcknowledgment || ""}
              error={!!productError.api_payload.requiredAcknowledgment}
              helperText={productError.api_payload.requiredAcknowledgment}
              onChange={(e) => {
                  setVendorTemplate({
                    ...vendortemplate, 
                    api_payload: { ...vendortemplate.api_payload, requiredAcknowledgment : e.target.value }
                  });
                }}
              variant="outlined"
            />  
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Description"
              placeholder=""
              className={classes.customFullWidth}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
              value={vendortemplate.api_payload.description || ""}
              error={!!productError.api_payload.description}
              helperText={productError.api_payload.description}
              onChange={(e) => setVendorTemplate({
                ...vendortemplate, 
                api_payload: { ...vendortemplate.api_payload, description : e.target.value }
              })}
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Supplier Communication Method"
              placeholder=""
              className={classes.customFullWidth}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
              value={vendortemplate.api_payload.supplierCommunicationMethod || ""}
              error={!!productError.api_payload.supplierCommunicationMethod}
              helperText={productError.api_payload.supplierCommunicationMethod}
              onChange={(e) => setVendorTemplate({
                ...vendortemplate, 
                api_payload: { ...vendortemplate.api_payload, supplierCommunicationMethod : e.target.value }
              })}
            />
          </div>
          <div className={classes.marginBM20}>
            <TextField
              label="Supplier Email Address"
              placeholder=""
              className={classes.customFullWidth}
              value={vendortemplate.api_payload.supplierEmailAddress || ""}
              error={!!productError.api_payload.supplierEmailAddress}
              helperText={productError.api_payload.supplierEmailAddress}
              onChange={(e) => setVendorTemplate({
                ...vendortemplate, 
                api_payload: { ...vendortemplate.api_payload, supplierEmailAddress : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM20}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={vendortemplate.api_payload.buyerManagedTransportFlag}
                  onChange={(e) => setVendorTemplate({
                    ...vendortemplate, 
                    api_payload: { ...vendortemplate.api_payload, buyerManagedTransportFlag : e.target.checked }
                  })}
                  name="buyerManagedTransportFlag"
                  color="primary"
                />
              }
              label="Buyer Managed Transport Flag"
            />
          </div>
          <div className={classes.marginBM30}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={vendortemplate.api_payload.payOnReceiptFlag}
                  onChange={(e) => setVendorTemplate({
                    ...vendortemplate, 
                    api_payload: { ...vendortemplate.api_payload, payOnReceiptFlag : e.target.checked }
                  })}
                  name="payOnReceiptFlag"
                  color="primary"
                />
              }
              label="Pay On Receipt Flag"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Payment Terms"
              placeholder=""
              className={classes.customFullWidth}
              value={vendortemplate.api_payload.paymentTerms || ""}
              error={!!productError.api_payload.paymentTerms}
              helperText={productError.api_payload.paymentTerms}
              onChange={(e) => setVendorTemplate({
                ...vendortemplate, 
                api_payload: { ...vendortemplate.api_payload, paymentTerms : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Freight Terms"
              placeholder=""
              className={classes.customFullWidth}
              value={vendortemplate.api_payload.freightTerms || ""}
              error={!!productError.api_payload.freightTerms}
              helperText={productError.api_payload.freightTerms}
              onChange={(e) => setVendorTemplate({
                ...vendortemplate, 
                api_payload: { ...vendortemplate.api_payload, freightTerms : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="FOB"
              placeholder=""
              className={classes.customFullWidth}
              value={vendortemplate.api_payload.fOB || ""}
              error={!!productError.api_payload.fOB}
              helperText={productError.api_payload.fOB}
              onChange={(e) => setVendorTemplate({
                ...vendortemplate, 
                api_payload: { ...vendortemplate.api_payload, fOB : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Note To Receiver"
              placeholder=""
              className={classes.customFullWidth}
              value={vendortemplate.api_payload.noteToReceiver || ""}
              error={!!productError.api_payload.noteToReceiver}
              helperText={productError.api_payload.noteToReceiver}
              onChange={(e) => setVendorTemplate({
                ...vendortemplate, 
                api_payload: { ...vendortemplate.api_payload, noteToReceiver : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Note To Supplier"
              placeholder=""
              className={classes.customFullWidth}
              value={vendortemplate.api_payload.noteToSupplier || ""}
              error={!!productError.api_payload.noteToSupplier}
              helperText={productError.api_payload.noteToSupplier}
              onChange={(e) => setVendorTemplate({
                ...vendortemplate, 
                api_payload: { ...vendortemplate.api_payload, noteToSupplier : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Carrier"
              placeholder=""
              className={classes.customFullWidth}
              value={vendortemplate.api_payload.carrier || ""}
              error={!!productError.api_payload.carrier}
              helperText={productError.api_payload.carrier}
              onChange={(e) => setVendorTemplate({
                ...vendortemplate, 
                api_payload: { ...vendortemplate.api_payload, carrier : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Service Level"
              placeholder=""
              className={classes.customFullWidth}
              value={vendortemplate.api_payload.serviceLevel || ""}
              error={!!productError.api_payload.serviceLevel}
              helperText={productError.api_payload.serviceLevel}
              onChange={(e) => setVendorTemplate({
                ...vendortemplate, 
                api_payload: { ...vendortemplate.api_payload, serviceLevel : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Mode Of Transport Code"
              placeholder=""
              className={classes.customFullWidth}
              value={vendortemplate.api_payload.modeOfTransportCode || ""}
              error={!!productError.api_payload.modeOfTransportCode}
              helperText={productError.api_payload.modeOfTransportCode}
              onChange={(e) => setVendorTemplate({
                ...vendortemplate, 
                api_payload: { ...vendortemplate.api_payload, modeOfTransportCode : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Default Taxation Country"
              placeholder=""
              className={classes.customFullWidth}
              value={vendortemplate.api_payload.defaultTaxationCountry || ""}
              error={!!productError.api_payload.defaultTaxationCountry}
              helperText={productError.api_payload.defaultTaxationCountry}
              onChange={(e) => setVendorTemplate({
                ...vendortemplate, 
                api_payload: { ...vendortemplate.api_payload, defaultTaxationCountry : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="First Party Registration Number"
              placeholder=""
              className={classes.customFullWidth}
              value={vendortemplate.api_payload.firstPartyRegistrationNumber || ""}
              error={!!productError.api_payload.firstPartyRegistrationNumber}
              helperText={productError.api_payload.firstPartyRegistrationNumber}
              onChange={(e) => setVendorTemplate({
                ...vendortemplate, 
                api_payload: { ...vendortemplate.api_payload, firstPartyRegistrationNumber : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Third Party Registration Id:"
              placeholder=""
              className={classes.customFullWidth}
              value={vendortemplate.api_payload.thirdPartyRegistrationId || ""}
              error={!!productError.api_payload.thirdPartyRegistrationId}
              helperText={productError.api_payload.thirdPartyRegistrationId}
              onChange={(e) => setVendorTemplate({
                ...vendortemplate, 
                api_payload: { ...vendortemplate.api_payload, thirdPartyRegistrationId : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Document Fiscal Classification"
              placeholder=""
              className={classes.customFullWidth}
              value={vendortemplate.api_payload.documentFiscalClassification || ""}
              error={!!productError.api_payload.documentFiscalClassification}
              helperText={productError.api_payload.documentFiscalClassification}
              onChange={(e) => setVendorTemplate({
                ...vendortemplate, 
                api_payload: { ...vendortemplate.api_payload, documentFiscalClassification : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Total Amount"
              placeholder=""
              className={classes.customFullWidth}
              value={vendortemplate.api_payload.totalAmount || ""}
              error={!!productError.api_payload.totalAmount}
              helperText={productError.api_payload.totalAmount}
              onChange={(e) => {
                setVendorTemplate({
                  ...vendortemplate, 
                  api_payload: { ...vendortemplate.api_payload, totalAmount : e.target.value }
                });
              }}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
              type="number"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Tax Amount"
              placeholder=""
              className={classes.customFullWidth}
              value={vendortemplate.api_payload.taxAmount || ""}
              error={!!productError.api_payload.taxAmount}
              helperText={productError.api_payload.taxAmount}
              onChange={(e) => {
                setVendorTemplate({
                  ...vendortemplate, 
                  api_payload: { ...vendortemplate.api_payload, taxAmount : e.target.value }
                });
              }}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
              type="number"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="API Index"
              placeholder=""
              className={classes.customFullWidth}
              value={vendortemplate.api_index2._index || ""}
              error={!!productError.api_index2._index}
              helperText={productError.api_index2._index}
              onChange={(e) => setVendorTemplate({
                ...vendortemplate, 
                api_index2: { ...vendortemplate.api_index2, _index : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Id"
              placeholder=""
              className={classes.customFullWidth}
              value={vendortemplate.api_index2._id || ""}
              error={!!productError.api_index2._id}
              helperText={productError.api_index2._id}
              onChange={(e) => setVendorTemplate({
                ...vendortemplate, 
                api_index2: { ...vendortemplate.api_index2, _id : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Line Number"
              placeholder=""
              className={classes.customFullWidth}
              value={vendortemplate.api_payloadline[0].lineNumber || ""}
              error={!!productError.api_payloadline[0].lineNumber}
              helperText={productError.api_payloadline[0].lineNumber}
              onChange={(e) => {
                setVendorTemplate({
                  ...vendortemplate, 
                  api_payloadline: [{ ...vendortemplate.api_payloadline[0], lineNumber : e.target.value }]
                });
              }}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
              type="number"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Reference Id"
              placeholder=""
              className={classes.customFullWidth}
              value={vendortemplate.api_payloadline[0].referenceId || ""}
              error={!!productError.api_payloadline[0].referenceId}
              helperText={productError.api_payloadline[0].referenceId}
              onChange={(e) => setVendorTemplate({
                ...vendortemplate, 
                api_payloadline: [{ ...vendortemplate.api_payloadline[0], referenceId : e.target.value }]
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Line Type"
              placeholder=""
              className={classes.customFullWidth}
              value={vendortemplate.api_payloadline[0].lineType || ""}
              error={!!productError.api_payloadline[0].lineType}
              helperText={productError.api_payloadline[0].lineType}
              onChange={(e) => setVendorTemplate({
                ...vendortemplate, 
                api_payloadline: [{ ...vendortemplate.api_payloadline[0], lineType : e.target.value }]
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Category"
              placeholder=""
              className={classes.customFullWidth}
              value={vendortemplate.api_payloadline[0].category || ""}
              error={!!productError.api_payloadline[0].category}
              helperText={productError.api_payloadline[0].category}
              onChange={(e) => setVendorTemplate({
                ...vendortemplate, 
                api_payloadline: [{ ...vendortemplate.api_payloadline[0], category : e.target.value }]
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Product Description"
              placeholder=""
              className={classes.customFullWidth}
              value={vendortemplate.api_payloadline[0].description || ""}
              error={!!productError.api_payloadline[0].description}
              helperText={productError.api_payloadline[0].description}
              onChange={(e) => setVendorTemplate({
                ...vendortemplate, 
                api_payloadline: [{ ...vendortemplate.api_payloadline[0], description : e.target.value }]
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Quantity"
              placeholder=""
              className={classes.customFullWidth}
              value={vendortemplate.api_payloadline[0].quantity || ""}
              error={!!productError.api_payloadline[0].quantity}
              helperText={productError.api_payloadline[0].quantity}
              onChange={(e) => {
                setVendorTemplate({
                  ...vendortemplate, 
                  api_payloadline: [{ ...vendortemplate.api_payloadline[0], quantity : e.target.value }]
                });
              }}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
              type="number"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Price"
              placeholder=""
              className={classes.customFullWidth}
              value={vendortemplate.api_payloadline[0].price || ""}
              error={!!productError.api_payloadline[0].price}
              helperText={productError.api_payloadline[0].price}
              onChange={(e) => {
                setVendorTemplate({
                  ...vendortemplate, 
                  api_payloadline: [{ ...vendortemplate.api_payloadline[0], price : e.target.value }]
                });
              }}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
              type="number"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label={t('main.uom.lable')}
              placeholder=""
              className={classes.customFullWidth}
              value={vendortemplate.api_payloadline[0].uOM || ""}
              error={!!productError.api_payloadline[0].uOM}
              helperText={productError.api_payloadline[0].uOM}
              onChange={(e) => setVendorTemplate({
                ...vendortemplate, 
                api_payloadline: [{ ...vendortemplate.api_payloadline[0], uOM : e.target.value }]
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          
        </div>
      </div>
        <div className="form-footer">
        <div className="right-buttons">
          <Button autoFocus onClick={() => setOpen(false)} className="noBtn" variant="outlined"  color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreateUpdate} className="yesBtn" variant="contained" color="primary">
            {isCreate ? "Create" : "Update"}
          </Button>
        </div>
      </div>
    
    </Dialog>
  );
}
           