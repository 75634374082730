import React from "react";
import classNames from "classnames";
import { Chip, Table, TableBody, TableRow, TableCell, Grid } from "@material-ui/core";
import useStyles from "../showlist/styles";
import AuditDetails from "./auditDetails";
import moment from 'moment-timezone';
import useStyles1 from "../surgicalevent/styles";








/**
 * @param {Object} searchParams 
 * @param {Object} options 
 * @param {Object} invData 
 * @returns 
 */
export default function AuditDetailsPayment({
    options = {}, invData, 
}) {

    options = {
        isEditable: false,
        ...options,
    };

    const classes = useStyles();
    const classes1 = useStyles1();



    const invDetails = invData?.logEvents.value.paymentData;

    if (invData?.logEvents.value.status === 'Received' && invData?.logEvents.value.stockforfacility) {
        invData.event = 'StockedForFacility';

    }
    const time = moment(invData.created_at).tz(moment.tz.guess()).format('LT z');



    return (
        <Grid>
            <Grid container direction="row" alignItems="center" className={classNames(classes.overviewAuditLog, classes.auditLogPanel)}>
                <Grid item xs={7}  >
                    <AuditDetails
                        className={classNames(classes.auditLogPayDetails)}
                        poData={invData}
                        time={time}
                    />
                </Grid>
                <Grid item xs={5} className={classes.overviewAuditLogPO}>
                    <Table className={classNames(classes.auditLogPayDetails, classes.auditLogDetails, classes.auditLogForm)}>
                        <TableBody>
                            <TableRow>
                                <TableCell className="new-subtitle" style={{ paddingTop: 0 }}>Payment Details</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="label">Status </TableCell>
                                <TableCell className="value">
                                    <Chip
                                        label={invDetails.status}
                                        size="small"
                                        className={classNames(classes1.smallChip, classes1.backGreen)}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="label">Distributor </TableCell>
                                <TableCell className="value">{invDetails.vendor}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="label">Type</TableCell>
                                <TableCell className="value">{invDetails.paymenttype}</TableCell>

                            </TableRow>
                            <TableRow>
                                <TableCell className="label">Invoice ID</TableCell>
                                <TableCell className="value">{invDetails.invoicedata.invinvoicenumber}</TableCell>

                            </TableRow>
                            <TableRow>
                                <TableCell className="label">Amount </TableCell>
                                <TableCell className="value">${invDetails.paymentamount}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="label">Payment Sent Date</TableCell>
                                <TableCell className="value">{invDetails.paymentinitiationdate}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="label">Payment Due Date</TableCell>
                                <TableCell className="value">{invDetails.paymentdate}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Grid >
    );
}
