import React, { useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import { Table, TableHead, TableBody, TableRow, TableCell, Grid, Typography, FormControlLabel, Checkbox, Button,
  Popover, Box, IconButton, Badge, AppBar, Tabs, Tab } from "@material-ui/core";
import { InventoryProductHistory } from "../../services/daml-modules1";
import { getQuantityOnOrder, getDaysByRecievedDate } from "../../services/util";
import { CustomTextField, CustomSelect } from "../../components/Inputs";
import CustomTooltip from "../../components/Tooltip/CustomTooltip";
import CustomCheckbox from "../../components/CheckBoxList/CustomCheckbox";
import TabPanel, { a11yProps } from "../../components/Modals/MultiTabsModals/TabPanel";
import ModalDialog from '../../components/Modals/NewGeneralModal';
import { UOM } from "../../models/Product";
import { useDamlState, useDamlLocation } from "../../context/DamlContext";
import { INVENTORY_UPDATE_EVENT, EVENT_FINISHED } from "../../store/actions/constants";
import useEvent from "../../hooks/useEvent";
import { useAccountInventory } from "../../hooks/useInventory";
import withStreamQueries from "../../hoc/withStreamQueries";
import HouseInventoryContracts from "./house-inventory-contracts";
import PriorInventoryContracts from "./prior-inventory-contracts";
// import { getProductDisplayContractPrice } from "./inventorylistcontractshospital";
import deleteIcon  from "../../icons/new-delete2.svg";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "../inventoryrestock/styles";
import useStyles2 from "./styles";


export const InventoryLocationsPopover = ({
  anchorEl, handleClose, selectedLocation,
}) => {

  const classes = useStyles2();

  const { accountInventoryData, setAccountInventoryData } = useAccountInventory();

  const [locPars, setLocPars] = useState([]);
  const [isChanged, setIsChanged] = useState(false);

  const handleChange = (value, index) => {
    const temp = [...locPars];
    temp[index].value = value;
    setLocPars(temp);
    setIsChanged(true);
  };

  const handleDelete = (index) => {
    const temp = [...locPars];
    temp.splice(index, 1);
    // console.log("[InventoryLocationsPopover] handleDelete", temp);
    setLocPars(temp);
    setIsChanged(true);
  };

  useEffect(() => {
    if (anchorEl) {
      console.log("[InventoryLocationsPopover] set default data.", accountInventoryData.iparlevels);
      let locParlvls = [];
      if (accountInventoryData.iparlevels && accountInventoryData.iparlevels.length) {
        locParlvls = accountInventoryData.iparlevels.map((pair, i) => ({
          index: i, label: pair._1, value: pair._2
        }));
      }
      if (selectedLocation) {
        // Add new location
        const found = accountInventoryData.iparlevels.find(pair => pair._1 === selectedLocation);
        if (!found) {
          locParlvls.push({
            index: accountInventoryData.iparlevels.length, label: selectedLocation, value: ""
          });
          setIsChanged(true);
        }
      }
      setLocPars(locParlvls);
    }
    else if (isChanged) {
      const locParlvls = locPars.filter(({ value }) => value !== '')
        .map(({ label, value }) => ({ _1: label, _2: value }));
      const strLocParlvls = JSON.stringify(locParlvls);
      const striparlevels = JSON.stringify(accountInventoryData.iparlevels);
      if (strLocParlvls !== striparlevels) {
        console.log("[InventoryLocationsPopover] setAccountInventoryData");
        setAccountInventoryData({
          ...accountInventoryData,
          iparlevels: locParlvls,
        });
      }
    }
    // eslint-disable-next-line
  }, [anchorEl]);

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box className={classes.newPopoverBox} >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Location</TableCell>
              <TableCell>Par Qty</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locPars.map(row => (
              <TableRow key={row.index}>
                <TableCell colSpan={2} >
                  <CustomTextField
                    type="number"
                    label={row.label}
                    value={row.value}
                    onChange={(val) => handleChange(val, row.index)}
                    className={classes.addLocationText}
                    width={42}
                    options={{
                      labelLeft: true,
                      labelWidth: 92,
                    }}
                  />
                </TableCell>
                <TableCell className="icon-cell">
                  <IconButton onClick={() => handleDelete(row.index)} >
                    <img src={deleteIcon} alt="" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Popover>
  );
};

const InventoryLocationsModal = ({
  open, setOpen,
}) => {

  const classes = useStyles();
  const classes1 = useStyles2();

  const { locations } = useDamlLocation();
  const { accountInventoryData, setAccountInventoryData } = useAccountInventory();
  const { onEventStart } = useEvent();

  const [value, setValue] = useState(0);
  const [location, setLocation] = useState('');
  const [locPar, setLocPar] = useState('');
  const [totalPar, setTotalPar] = useState('');
  const [locPars, setLocPars] = useState([]);
  const [purchaseOrderSetting, setPurchaseOrderSetting] = useState({});

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (value, key) => {
    setPurchaseOrderSetting({
      ...purchaseOrderSetting,
      [key]: value,
    });
  };

  const handleAdd = () => {
    // Add new location
    const temp = [...locPars];
    const found = temp.find(({ label }) => label === location);
    if (found) {
      found.value = locPar;
    }
    else {
      temp.push({
        index: temp.length, label: location, value: locPar
      });
    }
    setLocPars(temp);
  };

  const handleDelete = (index) => {
    const temp = [...locPars];
    temp.splice(index, 1);
    setLocPars(temp);
  };

  const handleSaveClick = () => {
    console.log("[InventoryLocationsModal] handleSaveClick");
    const locParlvls = locPars.filter(({ value }) => value !== '')
      .map(({ label, value }) => ({ _1: label, _2: value }));
    const strLocParlvls = JSON.stringify(locParlvls);
    const striparlevels = JSON.stringify(accountInventoryData.iparlevels);
    if (strLocParlvls !== striparlevels) {
      console.log("[InventoryLocationsModal] setAccountInventoryData");
      setAccountInventoryData({
        ...accountInventoryData,
        iparlevels: locParlvls,
      });
    }
    onEventStart(INVENTORY_UPDATE_EVENT);
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      console.log("[InventoryLocationsModal] set default data.");
      let locParlvls = [];
      if (accountInventoryData.iparlevels && accountInventoryData.iparlevels.length) {
        locParlvls = accountInventoryData.iparlevels.map((pair, i) => ({
          index: i, label: pair._1, value: pair._2
        }));
      }
      setLocPars(locParlvls);
      setLocation('');
      setLocPar('');
      setTotalPar(accountInventoryData.parlevel || '');
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <ModalDialog
      open={open}
      setOpen={setOpen}
      title={(
        <Typography variant="inherit" component="span" className={classes1.newInvTitleText} >
          Par Level Settings
        </Typography>
      )}
      content={(
        <>
          <AppBar position="static" className={classNames(classes.tabPanelMenu, classes1.parlevelPanel)}>
            <Tabs indicatorColor="primary" textColor="primary" value={value} onChange={handleChangeTab}
              aria-label="simple tabs example" centered
            >
              <Tab label="Set Par and Location" {...a11yProps(0)} />
              <Tab label="Auto Ordering" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0} className={classes.tabPanelbody}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <Grid item style={{ borderBottom: '1px solid #CCCCCC' }}>
                <Table className={classNames(classes.overview, classes1.parlevelBodyPanel)}>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <CustomSelect
                          label="Location"
                          value={location}
                          onChange={(val) => setLocation(val)}
                          items={locations}
                          placeholder="Select one"
                          width={233}
                          options={{
                            labelLeft: true,
                            labelWidth: 128,
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <CustomTextField
                          type="number"
                          label="Location Par"
                          value={locPar}
                          onChange={(val) => setLocPar(val)}
                          width={77}
                          options={{
                            labelLeft: true,
                            labelWidth: 128,
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <CustomTextField
                          type="number"
                          label="Total Par"
                          value={totalPar}
                          onChange={(val) => setTotalPar(val)}
                          width={77}
                          options={{
                            labelLeft: true,
                            labelWidth: 128,
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ textAlign: 'right' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleAdd}
                          className={classNames(classes.newCompleteBtn, classes1.newSmallBtn)}
                        >
                          ADD
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item className={classes1.locationsBox}>
                <Box className="header-table">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Location</TableCell>
                        <TableCell>Qty/Par</TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </Box>
                <Box className="body-table">
                  <Table>
                    <TableBody>
                      {locPars.map(row => (
                        <TableRow key={row.index}>
                          <TableCell>
                            {row.label}
                          </TableCell>
                          <TableCell>
                            {row.value}
                            <IconButton onClick={() => handleDelete(row.index)} >
                              <img src={deleteIcon} alt="" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1} className={classes.tabPanelbody}>
            <Table className={classNames(classes.overview, classes1.orderingBox)}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <CustomTextField
                      type="number"
                      label="Auto Replenish"
                      value={purchaseOrderSetting.auto_replenish}
                      onChange={(val) => handleChange(val, 'auto_replenish')}
                      width={152}
                    />
                  </TableCell>
                  <TableCell style={{ paddingRight: '55px' }}>
                    <CustomCheckbox
                      checked={!!purchaseOrderSetting.checked}
                      setChecked={(val) => handleChange(val, 'checked')}
                      classnames={{
                        switch: classes1.newSwitchCheck1,
                        label: classNames(classes1.newSwitchLabel, classes1.newSwitchLabelRow),
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    <CustomCheckbox
                      checked={!!purchaseOrderSetting.auto_po_vendor}
                      setChecked={(val) => handleChange(val, 'auto_po_vendor')}
                      label="Automate PO and Send to Vendor"
                      classnames={{
                        switch: classes1.newSwitchCheck1,
                        label: classNames(classes1.newSwitchLabel, classes1.newSwitchLabelRow),
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    <CustomCheckbox
                      checked={!!purchaseOrderSetting.auto_po}
                      setChecked={(val) => handleChange(val, 'auto_po')}
                      label="Automate PO "
                      classnames={{
                        switch: classes1.newSwitchCheck1,
                        label: classNames(classes1.newSwitchLabel, classes1.newSwitchLabelRow),
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TabPanel>
        </>
      )}
      actions={(
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(false)}
            className={classNames(classes.newCompleteBtn, "secondary-color", classes1.newSmallBtn)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveClick}
            className={classNames(classes.newCompleteBtn, classes1.newSmallBtn)}
          >
            Save
          </Button>
        </>
      )}
      width={460}
      height={500}
    />
  );
};

// eslint-disable-next-line
const SettingsPopover = ({
  anchorEl, handleClose,
}) => {

  const classes = useStyles();
  const classes1 = useStyles2();

  const { rolePayload } = useDamlState();
  const { accountInventoryData, setAccountInventoryData } = useAccountInventory();
  const { onEventStart } = useEvent();

  const [purchaseOrderSetting, setPurchaseOrderSetting] = useState({});

  const handleChange = (value, key) => {
    setPurchaseOrderSetting({
      ...purchaseOrderSetting,
      [key]: value,
    });
  };

  const handleSaveClick = () => {
    console.log("[SettingsPopover] handleSaveClick");
    onEventStart(INVENTORY_UPDATE_EVENT);
  };

  useEffect(() => {
    if (anchorEl && rolePayload.admindetails.purchase_order_setting) {
      console.log("[SettingsPopover]", rolePayload);
      setPurchaseOrderSetting(rolePayload.admindetails.purchase_order_setting);
    }
  }, [anchorEl, rolePayload]);

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box className={classes1.newPopoverBoxSettings} >
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
        >
          <Grid item className="header">
            <Typography>Settings</Typography>
          </Grid>
          <Grid item>
            <CustomTextField
              type="number"
              label="Par Level"
              value={accountInventoryData.parlevel || ""}
              onChange={(val) => setAccountInventoryData({
                parlevel: val,
              })}
              width={75}
            />
            <CustomCheckbox
              // checked={checked}
              // setChecked={handleCustomCheckbox}
              classnames={{
                switch: classes1.newSwitchCheck
              }}
            />
          </Grid>
          <Grid item>
            <CustomTextField
              type="number"
              label="Auto Replenish"
              value={purchaseOrderSetting.auto_replenish}
              onChange={(val) => handleChange(val, 'auto_replenish')}
              width={75}
            />
            <CustomCheckbox
              // checked={checked}
              // setChecked={handleCustomCheckbox}
              classnames={{
                switch: classes1.newSwitchCheck
              }}
            />
            <CustomCheckbox
              checked={purchaseOrderSetting.auto_po_vendor}
              setChecked={(val) => handleChange(val, 'auto_po_vendor')}
              label="Automate PO and Send to Vendor"
              classnames={{
                switch: classes1.newSwitchCheck,
                label: classes1.newSwitchLabel
              }}
            />
            <CustomCheckbox
              checked={purchaseOrderSetting.auto_po}
              setChecked={(val) => handleChange(val, 'auto_po')}
              label="Automate PO "
              classnames={{
                switch: classes1.newSwitchCheck,
                label: classNames(classes1.newSwitchLabel, classes1.newSwitchLabelTop),
              }}
            />
          </Grid>
          <Grid item className="actions">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveClick}
              className={classNames(classes.newCompleteBtn, classes1.newSettingBtn)}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Popover>
  );
};

const SettingsModal = ({
  open, setOpen,
}) => {

  const classes = useStyles();
  const classes1 = useStyles2();

  const { accountInventoryData, setAccountInventoryData } = useAccountInventory();
  const { onEventStart, event, status } = useEvent();

  const [displaySettings, setDisplaySettings] = useState('');

  const handleChange = (value, key) => {

    let unitPrice = displaySettings.idisplayprice;
    if (key === "iuom") {
      const unitUOM = getQuantityOnOrder({iproduct: {units: accountInventoryData?.iunits, sepuom: value}}, {isobject: true});
      unitPrice = unitUOM?.price;
    }
    setDisplaySettings({
      ...displaySettings,
      [key]: value,
      idisplayprice: unitPrice,
    });
  };

  const handleSaveClick = () => {
    setAccountInventoryData({
      ...accountInventoryData,
      //idisplaysettings: [displaySettings],
      iproductdescription: displaySettings.iproductdescription,
      iuom: displaySettings.iuom,
      //iprice: displaySettings.iprice,
    });

    onEventStart(INVENTORY_UPDATE_EVENT);
    // setOpen(false);
  };

  useEffect(() => {
    if (event === INVENTORY_UPDATE_EVENT && (status === EVENT_FINISHED || !!status)) {
      setOpen(false);
    }
  }, [event, status, setOpen])

  useEffect(() => {
    if (open) {
      console.log("[SettingsModal] set default data.");
      setDisplaySettings({
        iproductdescription: accountInventoryData.iproductdescription,
        iuom: accountInventoryData.iuom,
        idisplayprice: accountInventoryData.iprice
      })
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <ModalDialog
      open={open}
      setOpen={setOpen}
      title={(
        <>
          <Typography variant="inherit" component="span" className={classes1.newInvTitleText} >
            Display Settings
          </Typography>
          <CustomTooltip
            title={(
              <Box>
                <Typography className={classes.information}>{'These changes will be displayed on the My Inventory list screen'}</Typography>
              </Box>
            )}
            arrow
            open={true}
          >
            <Badge className={classes1.displaySettingBadgeInfo} badgeContent='i' color="primary" overlap="circular" showZero={false} >
            </Badge>
          </CustomTooltip>
        </>
      )}
      content={(
        <Table className={classNames(classes.overview, classes1.settingsPanel)}>
          <TableBody>
            <TableRow>
              <TableCell colSpan={2}>
                <CustomTextField
                  label="Nickname"
                  value={displaySettings.iproductdescription || ""}
                  onChange={(val) => handleChange(val, 'iproductdescription')}
                  width={233}
                  options={{
                    labelLeft: true,
                    labelWidth: 147,
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <CustomSelect
                  label="UoM/Contract Price"
                  value={displaySettings.iuom || ""}
                  onChange={(val) => handleChange(val, 'iuom')}
                  items={UOM}
                  placeholder="Select"
                  width={123}
                  options={{
                    labelLeft: true,
                    labelWidth: 147,
                  }}
                />
              </TableCell>
              <TableCell>
                <CustomTextField
                  type="number"
                  value={displaySettings.idisplayprice || ""}
                  onChange={(val) => handleChange(val, 'idisplayprice')}
                  width={86}
                  startAdornment={"$"}
                  style={{
                    marginLeft: '7px',
                  }}
                  options={{
                    disabled: true
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      actions={(
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(false)}
            className={classNames(classes.newCompleteBtn, "secondary-color", classes1.newSmallBtn)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveClick}
            className={classNames(classes.newCompleteBtn, classes1.newSmallBtn)}
          >
            Save
          </Button>
        </>
      )}
      width={480}
      height={245}
    />
  );
};

export const ContractPrice = ({
  isQuantity=true, 
}) => {

  const { accountInventoryData } = useAccountInventory();
  // const price = getProductDisplayContractPrice({
  //   payload: { inventoryproduct: accountInventoryData }
  // });
  const price = accountInventoryData.iprice ?? "";

  // console.log("[ContractPrice]", accountInventoryData);

  return (
    <Grid 
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item className="contract-price-item">
        <Typography variant="inherit" className="header-value">Contract Price: </Typography>
      </Grid>
      {
        accountInventoryData.iunits && accountInventoryData.iunits.length > 0
        ?
        accountInventoryData.iunits.map(({ packageuom, price, quantity }, index) => {
          const uom = UOM[packageuom] ?? packageuom;
          // calculate quantity
          if (!quantity) quantity = getQuantityOnOrder({
            iproduct: {
              units: accountInventoryData.iunits,
              sepuom: packageuom,
            },
            iquantityunit: 1,
          });
          return (
            <Grid item key={index} className="contract-price-item">
              <Typography variant="inherit" component="p" className="header-value">
                {uom} ${`${!isNaN(price) ? price : ''}`}
              </Typography>
              {
                isQuantity && 
                <Typography variant="inherit" component="p" className="header-value">
                  {parseInt(quantity) !== 1 ? `${!isNaN(quantity) ? quantity : '' }/${uom}` : ''}
                </Typography>
              }
            </Grid>
          );
        })
        :
        <Grid item>
          <Typography variant="inherit" className="header-value">
            {(price !== "" && price !== null && !isNaN(price)) ? `$${price}` : 'No Contract Price'}
          </Typography>
        </Grid>
      }
    </Grid>
  );
};

const HouseInventoryTopTable = () => {
  const classes = useStyles();
  const classes2 = useStyles2();
  const { accountInventoryData } = useAccountInventory();

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell className="header-label">Ref:</TableCell>
          <TableCell className="header-label">UDI:</TableCell>
          <TableCell className="header-label">Ownership:</TableCell>
          <TableCell className="header-label">Distributor:</TableCell>
          <TableCell className="header-label">Description:</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="header-value">{accountInventoryData.ireferencenumber}</TableCell>
          <TableCell className="header-value">{accountInventoryData.iproduct_sepudi}</TableCell>
          <TableCell className="header-value">{accountInventoryData.iownership}</TableCell>
          <TableCell className="header-value">{accountInventoryData.vendor}</TableCell>
          <TableCell className={classNames("header-value", classes2.descLongTxt)}>
            <CustomTooltip
              title={accountInventoryData.iproductdescription}
              classes={{ tooltip: classes.productTooltip }}
            >
              <span>
                {accountInventoryData.iproductdescription}
              </span>
            </CustomTooltip>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const HouseInventoryTopPanel = ({
  isPrior = false,
}) => {

  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();

  const { role } = useDamlState();

  const { accountInventoryData, setAccountInventoryData } = useAccountInventory();

  const [openLocations, setOpenLocations] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);


  const handleOpenLocations = (event) => {
    setOpenLocations(true);
  };

  const handleOpenSettings = (event) => {
    setOpenSettings(true);
  };

  const handleChange = useCallback((value, key) => {
    setAccountInventoryData({
      [key]: value,
    });
  }, [setAccountInventoryData]);

  return (
    <>
      {
        role === 'Vendor'
          ?
          <Grid item className={classNames(classes1.details, classes2.newHeaderPanel1, classes2.newHeaderPanel2)}>
            <Typography component="span">
              {accountInventoryData.ireferencenumber} |
              {/* {accountInventoryData.iproductname} |  */}
              {accountInventoryData.iownership}
            </Typography>
            <Typography component="span" className="label">
              Nickname:
            </Typography>
            <Typography component="span" className={classNames("value", classes2.descLongTxt)}>
              {accountInventoryData.iproductdescription}
            </Typography>
          </Grid>
          :
          <Grid item className={classNames(classes1.details, classes2.newHeaderPanel)}>
            <HouseInventoryTopTable />

            {
              isPrior
                ?
                <Box className="empty-box"></Box>
                :
                <Table className="settings-table">
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpenLocations}
                          className={classNames(classes.newCompleteBtn, classes2.newSmallBtn)}
                        >
                          Par Level Settings
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpenSettings}
                          className={classNames(classes.newCompleteBtn, classes2.newSmallBtn)}
                        >
                          Display Settings
                        </Button>
                      </TableCell>
                      <TableCell>
                        <FormControlLabel
                          className={classes2.newCheckbox}
                          control={
                            <Checkbox
                              color="primary"
                              checked={!!accountInventoryData.unclassified}
                              onChange={(e) => handleChange(e.target.checked, 'unclassified')}
                            />
                          }
                          label={(
                            <CustomTooltip
                              title={`Selecting this will remove the 
                          Lot Code and Expiration date 
                          from being required to intake 
                          this item.`}
                              arrow
                              classes={{ tooltip: classes.productTooltip }}
                            >
                              <Badge badgeContent='i' color="primary" overlap="circular" >
                                <Typography variant="inherit" component="span" className="label" >
                                  Unclassified
                                </Typography>
                              </Badge>
                            </CustomTooltip>
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
            }

            <InventoryLocationsModal
              open={openLocations}
              setOpen={setOpenLocations}
            />

            <SettingsModal
              open={openSettings}
              setOpen={setOpenSettings}
            />
          </Grid>
      }
    </>
  );
};

/**
 * In House / Prior Use Inventory Detail
 * @param {Object} options 
 * @param {Object} props 
 * @returns 
 */
export default function HouseInventoryDetail({
  options = {}, ...props
}) {

  options = {
    isPrior: false,
    ...options,
  };

  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();

  const { role } = useDamlState();
  const { locationContracts } = useDamlLocation();

  const { accountInventoryData } = useAccountInventory();
  let inventorydata = accountInventoryData.inventorydata ?? [];


  // get inventory data from contractsStreamQueries of props
  if (props.loadingStreamQueries === false && props.contractsStreamQueries && typeof props.contractsStreamQueries === 'object') {
    inventorydata = (props.contractsStreamQueries.length) ? props.contractsStreamQueries[0].payload.inventorydata : [];
  }
  // set Bin in every inventory data
  const locationObj = locationContracts.reduce((s, c) => ({...s, [c.payload.iinventorylocation]: c}), {});
  inventorydata = inventorydata.map(invd => {
    invd.ibin = locationObj[invd.iproduct.seplocation]?.payload?.ibin ?? "";
    invd.idoh = getDaysByRecievedDate(invd.ireceiveddate); 
    return invd;
  });
  // console.log("[HouseInventoryDetail]", inventorydata, locationObj);



  return (
    <Grid container
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      className={classNames(classes.overview, classes1.incomingPanel)}
    >
      <HouseInventoryTopPanel
        isPrior={options.isPrior}
      />

      <Grid item className={classNames(classes1.details, classes2.middlePanel)}>
        <Grid container justifyContent="flex-start" alignItems="center">
          <Grid item style={{paddingRight: '200px'}}>
            <Typography variant="inherit" className="header-value">Total Products: </Typography>
            <Typography variant="inherit" className="header-value">{inventorydata?.length}</Typography>
          </Grid>
          <Grid item>
            <ContractPrice />
          </Grid>
        </Grid>
      </Grid>

      <Grid item className={classes1.contractsPanel}>
        {
          options.isPrior
            ?
            <PriorInventoryContracts
              allContracts={inventorydata}
              loading={inventorydata.length === 0 && props.loadingStreamQueries === true}
              options={{
                ...options,
                isCheck: false,
              }}
            />
            :
            <HouseInventoryContracts
              allContracts={inventorydata}
              loading={inventorydata.length === 0 && props.loadingStreamQueries === true}
              options={{
                isCheckboxHeaderCell: (role === 'Hospital'),
                ...options,
              }}
            />
        }
      </Grid>
    </Grid>
  );
}

export const getHouseInventoryDetailInventoryProductHistory = (searchInv) => (
  withStreamQueries(HouseInventoryDetail)(InventoryProductHistory, searchInv)
);
