import React from "react";
// import MaskedInput from 'react-text-mask';
// import InputMask from 'react-input-mask';
import { TextField } from "@material-ui/core";
import { onChangeNumber } from "../handles";
import { useStyles } from "../styles";

export default function CustomContents({
  textFields,
  customData,
  setConductForm }) {

  const classes = useStyles();
  // console.log("[CustomContents]", textFields, customData);

  return (
    <>

      {textFields.map((txt) => (
        <div key={txt.key}>
          <TextField
            label={txt.label}
            placeholder={txt.label}
            value={typeof txt.mask === "function" ? txt.mask(customData[txt.key]) : customData[txt.key]}
            className={ classes.dialogField }
            onChange={(e) => {
              if (!!txt.isNumber) {
                // console.log("[CustomContents] number", onChangeNumber(e));
                if (onChangeNumber(e)) setConductForm({ ...customData, [txt.key]: onChangeNumber(e) });
              }
              else {
                setConductForm({ ...customData, [txt.key]: e.target.value });
              }
            }}
          />
        </div>
      ))}

    </>
  );
}