export const onChangeNumber = (e) => {
  // 1st way
  // const re = /^[-+]?[0-9]+\.[0-9]+$/;
  // if (e.target.value === '' || re.test(e.target.value)) return e.target.value;
  // 2nd way
  if (e.target.value === '') return "0";
  if (!isNaN(e.target.value)) return e.target.value;
  return null;
  // 3rd way
  // return e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
};

export const resetConductForm = (newConduct, conductList, setConductForm, index) => {
  // console.log("[resetConductForm]", newConduct);

  let updatedConductList = conductList;
  updatedConductList[index] = newConduct;
  if (typeof setConductForm === "function") setConductForm(updatedConductList);
};

export const increaseIndexOfList = (index, setIndex, conductList) => {
  setIndex((index+1)%conductList.length);
};

export const decreaseIndexOfList = (index, setIndex, conductList) => {
  setIndex((index+conductList.length-1)%conductList.length);
};

export const addConduct = (data, conductList, setConductList, defaultConduct, increaseIndex) => {
  // console.log("[addConduct]", data);

  let updatedContactList = conductList;
  updatedContactList.push(defaultConduct);
  if (typeof setConductList === "function") setConductList(updatedContactList);
  
  increaseIndex();
};