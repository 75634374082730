import { useCallback } from "react";
import { useSelector } from "react-redux";
import { getCommonObj } from "../services/util";
import { dispatch } from "../store";
import { setProduct as setPro, setProductAll, setProductDetail, setProductSecondary,  
  setProductSEDetail, clearProduct as clearPro, setPrefSEDetail } from "../store/actions/productAction";


/**
 * General Product Hook
 * @param {Boolean} isSecondary
 * @returns 
 */
export default function useProduct (isSecondary=false) {
  const product = useSelector(state => (isSecondary ? state.product.secondaryProduct : state.product.product));
  const errors = useSelector(state => (isSecondary ? state.product.secondaryErrors : state.product.errors));
  const isChanged = useSelector(state => state.product.isChanged);

  const setAll = useCallback((data) => dispatch(setProductAll(data)), []);
  const setProduct = useCallback((data) => {
    if (isSecondary) dispatch(setProductSecondary(data));
    else dispatch(setProductDetail(data));
  }, [isSecondary]);
  const setErrors = useCallback((data) => {
    if (isSecondary) dispatch(setPro({secondaryErrors: data}));
    else dispatch(setPro({errors: data}));
  }, [isSecondary]);
  const clearAll = useCallback(() => dispatch(clearPro()), []);
  const clearProduct = useCallback((data={}) => {
    if (isSecondary) dispatch(setPro({secondaryProduct: data, secondaryErrors: {}}));
    else dispatch(setPro({product: data, errors: {}}));
  }, [isSecondary]);

  return { product, errors, isChanged, setProduct, setErrors, setAll, clearAll, clearProduct };
}

/**
 * Surgical Evnet Hook
 * @returns 
 */
export function useSurgicalEvent () {
  const surgicalevent = useSelector(state => state.product.surgicalevent);
  const prefdata = useSelector(state => state.product.prefdata);
  const surgicaleventErrors = useSelector(state => state.product.surgicaleventErrors);
  const isChanged = useSelector(state => state.product.isChangedSE);

  const setSurgicalEvent = useCallback((data) => dispatch(setProductSEDetail(data)), []);
  const setPrefData = useCallback((data) => dispatch(setPrefSEDetail({data})), []);
  const setPrefDataLoad = useCallback((data) => dispatch(setPrefSEDetail({data, isLoad: true})), []);
  const setSurgicalEventErrors = useCallback((data) => dispatch(setPro({surgicaleventErrors: data})), []);

  return { surgicalevent, surgicaleventErrors, isChanged, prefdata, setPrefData, setSurgicalEvent, setSurgicalEventErrors, 
    setPrefDataLoad };
}

/**
 * Hospital & User Hook for Product, SE
 * @returns 
 */
export function useHospitalUser() {
  const hospital = useSelector(state => state.product.hospital);
  const user = useSelector(state => state.product.user);

  const setHospital = useCallback((data) => dispatch(setPro({hospital: data})), []);
  const setUser = useCallback((data) => dispatch(setPro({user: data})), []);

  return { hospital, user, setHospital, setUser };
}

/**
 * Product Item Detail List Hook for SE
 * @returns 
 */
export function useItemDetails() {
  const details = useSelector(state => state.product.details);
  const itemIndex = useSelector(state => state.product.itemIndex);

  const setDetails = useCallback((data) => dispatch(setPro({details: data})), []);
  const setItemIndex = useCallback((data) => dispatch(setPro({itemIndex: data})), []);

  const common = getCommonObj(details);

  return { details, itemIndex, setDetails, setItemIndex, common };
}
