import React, { useState } from "react";
// import classNames from "classnames";
import { useStreamQueries, useParty, useLedger } from "@daml/react";
import { CircularProgress, /* Chip, */ Typography } from '@material-ui/core';
import { ManagerInvitation, /* OperatorRole */ } from "../../services/daml-modules1";
import { useDamlState } from "../../context/DamlContext";
import Contracts from "../../components/Contracts/Contracts";
// import AdaptiveSearch from "../../components/Search/adaptivesearch";
import icon4 from '../../icons/folderplusdis.svg';
import activeIcon4 from '../../icons/folderplus.svg';
import ModalDialogBox from "../../components/Modals/GeneralModal/modalDialogBox";
import useStyles from "../surgicalevent/styles";


/**
 * Operator Invite Page
 * TODO
 * create Contracts to search some fields
 * @returns 
 */
export default function OperatorInvite() {

  const classes = useStyles();
  const party = useParty();
  const ledger = useLedger();
  const assets = useStreamQueries(ManagerInvitation, () => [{ party }]);
  // const assetsOperatorRole = useStreamQueries(OperatorRole, () => [{ party }]);
  const { roleCid } = useDamlState();
  const [open, setOpen] = useState(false);
  const [ContractId, setContractId] = useState(null);
  console.log("[OperatorInvite]", assets.contracts);


  const exerciseOperatorAccept = (contractId, payload) => {
    console.log("[exerciseOperatorAccept]", contractId, payload);

    ledger.exercise(ManagerInvitation.AcceptOperatorInvitation, contractId, {})
      .then((res) => {
        console.log("[exerciseOperatorAccept]", res);
      })
      .catch(events => {
        console.log("[exerciseOperatorAccept] error", events);
      });

  };
  const handleOpenModal = (c) => {
    console.log("[exerciseOperatorAccept] handleOpenModal", c);
    setContractId(c.contractId);
    setOpen(true);
  };
  const handleRequest = () => {
    exerciseOperatorAccept(ContractId);
    setOpen(false);
    setContractId(null);
  };

  const contracts = assets.contracts;

  return (
    !assets.contracts || !assets.contracts.length || !roleCid
      ?
      <CircularProgress />
      :
      (
        <>
          <div key="formChildStep" className="formsteps">
            <Typography variant="h4" className={classes.pageTitle}>Onboarding


            </Typography>

            <Contracts
              className={classes.customRoot}
              contracts={contracts}
              columns={[
                // ["ContractId", "contractId"],
                ["Contract Id", "contractId", {
                  options: {
                    width: 120,
                  },
                }],
                ["Party", "payload.party"],
                ["Name", "payload.name"],
                ["Email", "payload.email", {
                  options: {
                    width: 180
                  }
                }],
                ["Company", "payload.company", {
                  options: {
                    width: 180
                  }
                }],
                ["Type", "payload.companytype"],
                /* ["Status", "payload.approved", {
                   type: "render",
                   render: (param) => (<Chip 
                       label={
                        param.value
                       } 
                       size="small" 
                       className={classNames(classes.smallChip, (
                               param.value === 'Active' ? classes.backGreen : (
                             param.value === 'Cancelled' ? classes.backRed : classes.backBlue
                           )
                          ))} 
                     />),
                   options: {
                     cellClassName: classes.centerCell,
                     headerClassName: classes.centerCell,
                     width: 90,
                   },
                 }],
                 ["Created", "payload.created_at",{
                   options: {
                     width: 150,
                   },
                 }],*/
              ]}

              actions={[

                [
                  "Actions",
                  [
                    [["Edit", [<img alt="" src={activeIcon4} />, <img alt="" src={icon4} />]],
                      handleOpenModal],
                  ],
                  {
                    width: 110,
                    cellClassName: classes.centerCell,
                    headerClassName: classes.centerCell,
                  }
                ]
              ]}
              options={{
                // statusEmpty: (allContracts.length === 0)
              }}
            />

            <ModalDialogBox
              open={open}
              setOpen={setOpen}
              heading="Successfully Created"
              type="success"
              text={'<p>You have successfully created to Biomed Vendor.</p> <p>Next step, you can approved the user on the Network screen.</p>'}
              handleRequest={handleRequest}
              firstBtn={"Network"}

            />
          </div>
        </>
      )
  )
}