import { requiredFields } from "./handles";

export const API_IndexModel = {
  _index: '',
  _id: '',
};
export const validateAPI_Index = (api_index) => {
  return (api_index._index !== ""
    || api_index._id !== "");
};
export const getAPI_Index = (api_index) => {
  let newAPI_Index = {};
  newAPI_Index._index = api_index._index || "";
  newAPI_Index._id = api_index._id || "";
  return newAPI_Index;
};
export const requiredAPI_Index = (api_index) => requiredFields(api_index, {
  '_index': 'Index', 
  '_id': 'Id',
});
