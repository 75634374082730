import { isNewDesign } from "../config";
import { SurgicalEventProductModel, validateProduct, getProduct, compareProduct, comapreProductsDelta } from "./Product";
import { validateCourierCompany } from "./Courier";
import { requiredFields, getModelMenu, validateModelItem, createPartyFromName, getArray, getSubModel } from "./handles";
import { getQuantityOnOrder } from "../services/util";



export const InventoryLocationModel = {
  iinventorylocation: '',
  iitemnumber: '',
  iinventorycategory: '',
  iinventorysubcategory: '',
  icabinet: '',
  ishelf: '',
  ibin: '',
};
export const validateInventoryLocation = (inventorylocation) => {
  return (inventorylocation.iinventorylocation !== "");
};
export const getInventoryLocation = (inventorylocation) => {
  let newInventory = {};
  newInventory.iinventorylocation = inventorylocation.iinventorylocation || "";
  if (typeof inventorylocation.iitemnumber === "string" && inventorylocation.iitemnumber !== "") newInventory.iitemnumber = inventorylocation.iitemnumber;
  if (typeof inventorylocation.iinventorycategory === "string" && inventorylocation.iinventorycategory !== "") newInventory.iinventorycategory = inventorylocation.iinventorycategory;
  if (typeof inventorylocation.iinventorysubcategory === "string" && inventorylocation.iinventorysubcategory !== "") newInventory.iinventorysubcategory = inventorylocation.iinventorysubcategory;
  if (typeof inventorylocation.icabinet === "string" && inventorylocation.icabinet !== "") newInventory.icabinet = inventorylocation.icabinet;
  if (typeof inventorylocation.ishelf === "string" && inventorylocation.ishelf !== "") newInventory.ishelf = inventorylocation.ishelf;
  if (typeof inventorylocation.ibin === "string" && inventorylocation.ibin !== "") newInventory.ibin = inventorylocation.ibin;
  return newInventory;
};

export const InventoryRequestComment = {
  date: '',
  user: '',
  note: '',
  status: '',
  returntype: '',
};
export const validateInventoryRequestComment = (invrq) => {
  return (invrq.date !== ""
    || invrq.user !== ""
    || invrq.note !== ""
    || validateReturnStatus(invrq.status)
    || validateReturnType(invrq.returntype)
  );
};
export const getInventoryRequestComment = (invrq) => {
  let newinvrq = {};
  if (typeof invrq.date === "string" && invrq.date !== "") newinvrq.date = invrq.date;
  if (typeof invrq.user === "string" && invrq.user !== "") newinvrq.user = invrq.user;
  if (typeof invrq.note === "string" && invrq.note !== "") newinvrq.note = invrq.note;
  newinvrq.status = validateReturnStatus(invrq.status) ? invrq.status : "";
  if (validateReturnType(invrq.returntype)) newinvrq.returntype = invrq.returntype;
  return newinvrq;
};


export const InventoryDataModel = {
  id: '',
  iitemnumber: '',
  iproductitem: '',
  idescription: '',
  iunitprice: '',
  ishipmentdate: '',
  ireceiveddate: '',
  iproductstatus: '',
  ireceivedstockforfacility: false,
  ireturncomments: [],
  ireturnindex: '',
  irecallstatus: false,
  irecallmessage: '',
  irecalldate: '',
  ipurchaseordernumber: '',
  ipodetailid: '',
  ipurchaseorderdate: '',
  // imatched: false,
  iproduct: {...SurgicalEventProductModel},
  // itempproduct: null, // Optional SurgicalEventProductModel
  itempstatus: '',
  itemprequestmessage: '',
  itemprequestdate: '',
  itemprequestuser: '',
  // itempresponsedecision: null, // Optional Boolean
  // itempresponseuser: '',
  // itempresponsedate: '',
  // itempresponsemessage: '',
  // itempponumber: '',
  iseid: '',
  ipphseid: '',
  iexpirationstatus: '',
  icourier: '',
  itrackingstatus: '',
  // iinventorylocation: '',
  iquantity: '',
  iquantityunit: '',
  idoh: '',
  ibin: '',
  idistributornumber: '',
  // iqoh: '',
  ilocation: '',
  // irecqoo: '',
  imergeindex: '',
  iunclassified: false,
};

export const validateInventoryData = (inventorydetail) => {
  return (inventorydetail.iitemnumber !== ""
    || inventorydetail.iproductitem !== ""
    || inventorydetail.idescription !== ""
    || inventorydetail.iunitprice !== ""
    || inventorydetail.ishipmentdate !== ""
    || inventorydetail.ireceiveddate !== ""
    || inventorydetail.iproductstatus !== ""
    || inventorydetail.ipurchaseordernumber !== ""
    || inventorydetail.iseid !== ""
    || inventorydetail.ipphseid !== ""
    || validateProduct(inventorydetail.iproduct)
    || validateExpirationStatus(inventorydetail.iexpirationstatus)
    || validateCourierCompany(inventorydetail.icourier)
  );
};

export const getInventoryData = (inventorydetail, options={}) => {
  // console.log('inventorydetail []', inventorydetail);
  options = {
    unclassified: true,               // set unclassified
    ...options,
  };
  let newInventory = {};
  if (typeof inventorydetail.id === "string" && inventorydetail.id !== "") newInventory.id = inventorydetail.id;
  if (typeof inventorydetail.iitemnumber === "string" && inventorydetail.iitemnumber !== "") newInventory.iitemnumber = inventorydetail.iitemnumber;
  if (typeof inventorydetail.iproductitem === "string" && inventorydetail.iproductitem !== "") newInventory.iproductitem = inventorydetail.iproductitem;
  if (typeof inventorydetail.idescription === "string" && inventorydetail.idescription !== "") newInventory.idescription = inventorydetail.idescription;
  if (typeof inventorydetail.iunitprice === "string" && inventorydetail.iunitprice !== "") newInventory.iunitprice = inventorydetail.iunitprice;
  if (typeof inventorydetail.ishipmentdate === "string" && inventorydetail.ishipmentdate !== "") newInventory.ishipmentdate = inventorydetail.ishipmentdate;
  if (typeof inventorydetail.ireceiveddate === "string" && inventorydetail.ireceiveddate !== "") newInventory.ireceiveddate = inventorydetail.ireceiveddate;
  if (validateInventoryProductStatus(inventorydetail.iproductstatus)) newInventory.iproductstatus = inventorydetail.iproductstatus;
  newInventory.ireturncomments = getArray(inventorydetail.ireturncomments, validateInventoryRequestComment, getInventoryRequestComment);
  if (typeof inventorydetail.ireturnindex === "string" && inventorydetail.ireturnindex !== "") newInventory.ireturnindex = inventorydetail.ireturnindex;
  if (typeof inventorydetail.irecallstatus === "boolean") newInventory.irecallstatus = inventorydetail.irecallstatus;
  if (typeof inventorydetail.irecallmessage === "string" && inventorydetail.irecallmessage !== "") newInventory.irecallmessage = inventorydetail.irecallmessage;
  if (typeof inventorydetail.irecalldate === "string" && inventorydetail.irecalldate !== "") newInventory.irecalldate = inventorydetail.irecalldate;
  if (typeof inventorydetail.ipurchaseordernumber === "string" && inventorydetail.ipurchaseordernumber !== "") newInventory.ipurchaseordernumber = inventorydetail.ipurchaseordernumber;
  if (typeof inventorydetail.ipodetailid === "string" && inventorydetail.ipodetailid !== "") newInventory.ipodetailid = inventorydetail.ipodetailid;
  if (typeof inventorydetail.ipurchaseorderdate === "string" && inventorydetail.ipurchaseorderdate !== "") newInventory.ipurchaseorderdate = inventorydetail.ipurchaseorderdate;
  if (typeof inventorydetail.itrackingstatus === "string" && inventorydetail.itrackingstatus !== "") newInventory.itrackingstatus = inventorydetail.itrackingstatus;
  // if (typeof inventorydetail.imatched === "boolean") newInventory.imatched = inventorydetail.imatched;
  newInventory.iproduct = getProduct(inventorydetail.iproduct);
  if (!options.unclassified) newInventory.iunclassified = inventorydetail.iunclassified;
  else if (inventorydetail.iproduct.sepudi && inventorydetail.iproduct.seplotcode && inventorydetail.iproduct.sepproductexpiration) newInventory.iunclassified = false;
  else newInventory.iunclassified = true;
  // newInventory.itempproduct = (validateProduct(inventorydetail.itempproduct)) ? getProduct(inventorydetail.itempproduct) : null;
  if (validateEditStatus(inventorydetail.itempstatus)) newInventory.itempstatus = inventorydetail.itempstatus;
  if (typeof inventorydetail.itemprequestmessage === "string" && inventorydetail.itemprequestmessage !== "") newInventory.itemprequestmessage = inventorydetail.itemprequestmessage;
  if (typeof inventorydetail.itemprequestdate === "string" && inventorydetail.itemprequestdate !== "") newInventory.itemprequestdate = inventorydetail.itemprequestdate;
  if (typeof inventorydetail.itemprequestuser === "string" && inventorydetail.itemprequestuser !== "") newInventory.itemprequestuser = inventorydetail.itemprequestuser;
  // if (typeof inventorydetail.itempresponsedecision === "boolean") newInventory.itempresponsedecision = inventorydetail.itempresponsedecision;
  // if (typeof inventorydetail.itempresponsedate === "string" && inventorydetail.itempresponsedate !== "") newInventory.itempresponsedate = inventorydetail.itempresponsedate;
  // if (typeof inventorydetail.itempresponseuser === "string" && inventorydetail.itempresponseuser !== "") newInventory.itempresponseuser = inventorydetail.itempresponseuser;
  // if (typeof inventorydetail.itempresponsemessage === "string" && inventorydetail.itempresponsemessage !== "") newInventory.itempresponsemessage = inventorydetail.itempresponsemessage;
  // if (typeof inventorydetail.itempponumber === "string" && inventorydetail.itempponumber !== "") newInventory.itempponumber = inventorydetail.itempponumber;
  if (typeof inventorydetail.iseid === "string" && inventorydetail.iseid !== "") newInventory.iseid = inventorydetail.iseid;
  if (typeof inventorydetail.ipphseid === "string" && inventorydetail.ipphseid !== "") newInventory.ipphseid = inventorydetail.ipphseid;
  if (validateExpirationStatus(inventorydetail.iexpirationstatus)) newInventory.iexpirationstatus = inventorydetail.iexpirationstatus;
  if (validateCourierCompany(inventorydetail.icourier)) newInventory.icourier = inventorydetail.icourier;
  newInventory.iquantity = inventorydetail.iquantity || "1";
  if ((typeof inventorydetail.iquantityunit === "string" && inventorydetail.iquantityunit !== "" && inventorydetail.iquantityunit !== "N/A") || typeof inventorydetail.iquantityunit === "number") newInventory.iquantityunit = inventorydetail.iquantityunit;
  if (typeof inventorydetail.imergeindex === "string" && inventorydetail.imergeindex !== "") newInventory.imergeindex = inventorydetail.imergeindex;
  if ((typeof inventorydetail.idoh === "string" && inventorydetail.idoh !== "") || typeof inventorydetail.idoh === "number") newInventory.idoh = inventorydetail.idoh; //Day on Hand
  if ((typeof inventorydetail.ibin === "string" && inventorydetail.ibin !== "")) newInventory.ibin = inventorydetail.ibin; //Sub location 
  if (typeof inventorydetail.idistributornumber === "string" && inventorydetail.idistributornumber !== "") newInventory.idistributornumber = inventorydetail.idistributornumber; //distibutor number for distributor
  // if ((typeof inventorydetail.iproduct.iqoh === "string" && inventorydetail.iproduct.iqoh !== "")) newInventory.iqoh = inventorydetail.iproduct.iqoh || 1; //Recieved Quantity on Hand
  if ((typeof inventorydetail.ilocation === "string" && inventorydetail.ilocation !== "")) newInventory.ilocation = inventorydetail.ilocation; //Location
  return newInventory;
};

export const compareInventoryData = (inventorydetail1, inventorydetail2, isAll=false) => {
  return ((!isAll || inventorydetail1.id === inventorydetail2.id)
    && (!isAll || inventorydetail1.iitemnumber === inventorydetail2.iitemnumber)
    && (!isAll || inventorydetail1.iproductitem === inventorydetail2.iproductitem)
    && (!isAll || inventorydetail1.idescription === inventorydetail2.idescription)
    && inventorydetail1.iunitprice === inventorydetail2.iunitprice
    && inventorydetail1.ishipmentdate === inventorydetail2.ishipmentdate
    && inventorydetail1.ireceiveddate === inventorydetail2.ireceiveddate
    && inventorydetail1.iproductstatus === inventorydetail2.iproductstatus
    && (!isAll || JSON.stringify(inventorydetail1.ireturncomments) === JSON.stringify(inventorydetail2.ireturncomments))
    && inventorydetail1.ireturnindex === inventorydetail2.ireturnindex
    && inventorydetail1.irecallstatus === inventorydetail2.irecallstatus
    && inventorydetail1.irecallmessage === inventorydetail2.irecallmessage
    && inventorydetail1.irecalldate === inventorydetail2.irecalldate
    && inventorydetail1.ipurchaseordernumber === inventorydetail2.ipurchaseordernumber
    && inventorydetail1.ipodetailid === inventorydetail2.ipodetailid
    && inventorydetail1.ipurchaseorderdate === inventorydetail2.ipurchaseorderdate
    // && inventorydetail1.imatched === inventorydetail2.imatched
    && compareProduct(inventorydetail1.iproduct, inventorydetail2.iproduct, isAll)
    // && compareProduct(inventorydetail1.itempproduct, inventorydetail2.itempproduct, isAll)
    && inventorydetail1.itempstatus === inventorydetail2.itempstatus
    && inventorydetail1.itemprequestmessage === inventorydetail2.itemprequestmessage
    && inventorydetail1.itemprequestdate === inventorydetail2.itemprequestdate
    && inventorydetail1.itemprequestuser === inventorydetail2.itemprequestuser
    // && inventorydetail1.itempresponsedecision === inventorydetail2.itempresponsedecision
    // && inventorydetail1.itempresponseuser === inventorydetail2.itempresponseuser
    // && inventorydetail1.itempresponsedate === inventorydetail2.itempresponsedate
    // && inventorydetail1.itempresponsemessage === inventorydetail2.itempresponsemessage
    // && inventorydetail1.itempponumber === inventorydetail2.itempponumber
    && inventorydetail1.iseid === inventorydetail2.iseid
    && inventorydetail1.ipphseid === inventorydetail2.ipphseid
    && inventorydetail1.iexpirationstatus === inventorydetail2.iexpirationstatus
    && inventorydetail1.icourier === inventorydetail2.icourier
    && inventorydetail1.itrackingstatus === inventorydetail2.itrackingstatus
    // && (!isAll || inventorydetail1.iinventorylocation === inventorydetail2.iinventorylocation)
  );
};

export const requiredInventoryData = (inventorydetail) => requiredFields(inventorydetail, {
  // 'ipurchaseordernumber': 'Purchase Order Number', 
  // 'ishipmentdate': 'Shipment Date', 
  'sepdistributor': 'Distributor', 
  'iownership': 'Ownership', 
});

export const createInventoryData = (inventorydetail, isCreatePartyFromName=false) => {
  let newInventory = {...inventorydetail};
  console.log("[createInventoryData]", newInventory);
  // newInventory.imatched = !!newInventory.ipurchaseordernumber;
  if (newInventory.iproduct) {
    if (!!newInventory.sepdistributor) newInventory.iproduct.sepdistributor = newInventory.sepdistributor;
    if (!!newInventory.iownership) newInventory.iproduct.sepownership = newInventory.iownership;
    if (isCreatePartyFromName) {
      if (newInventory.iproduct.sepdistributor) newInventory.iproduct.sepdistributor = createPartyFromName(newInventory.iproduct.sepdistributor);
      if (newInventory.iproduct.sepproductmanufacturer) newInventory.iproduct.sepproductmanufacturer = createPartyFromName(newInventory.iproduct.sepproductmanufacturer);
    }
  }
  return getInventoryData(newInventory);
};

export const compressInventoryDataList = (invList=[]) => {
  let newInvList = [];
  invList.forEach((inv, index) => {
    inv.index = index;
    // find duplicated inventory data
    const findInv = newInvList.find(v => compareInventoryData(v, inv));
    if (findInv) {
      findInv.quantity ++;
      findInv.datas.push({...inv});
    }
    else {
      newInvList.push({
        ...inv,
        quantity: 1,
        datas: [{...inv}],
      });
    }
  });
  // console.log("[compressInventoryDataList]", invList, newInvList);
  return newInvList;
};

/**
 * find the matched Inventory Data in Inventory Data List
 * It can be called in Restock Inventory, Return Inventory
 * @param {Array} inventorydatalist list of Inventory Data
 * @param {Object|Array} inventorydata search Inventory Data
 * @param {Object} options
 * @return {Object|Array}
 */
export const findMatchedInventoryData = (inventorydatalist, inventorydata, options) => {
  options = {
    limitDelta: 100,        // To compare strict mode or not, limit delta > 0
    isArray: true,          // inventorydata is array or one
    isPONumber: false,      // PO Number is necessary or not
    isReturnExpOld: false,  // Return Expiration date Oldest
    setVirtualMatch: true,  // Set isVirtualMatched to True
    ...options,
  };
  // console.log("[findMatchedInventoryData]", inventorydatalist, inventorydata, options);
  const searchinvds = options.isArray ? inventorydata : [inventorydata];
  const result = searchinvds.map(search => {
    if (!search) return null;
    const purchaseordernumber = search.ipurchaseordernumber;
    const productstatus = search.iproductstatus;
    const product = search.iproduct;
    const expOldProducts = [];
    const {target, delta} = inventorydatalist.reduce(({target, delta}, invd) => {
      // it needs to skip if it's already matching found
      if (!invd.isvirtualmatched) {
        // 1. Status
        if (!productstatus || productstatus === invd.iproductstatus) {
          // 2. PO Number
          if (!purchaseordernumber || purchaseordernumber === invd.ipurchaseordernumber) {
            if (!options.isPONumber || invd.ipurchaseordernumber) {
              // compare
              const cpi = comapreProductsDelta(invd.iproduct, product);
              // console.log("[findMatchedInventoryData]", invd, cpi);
              if (cpi < delta) {
                delta = cpi;
                target = invd;
              }
              else if (cpi === delta && !!target) {
                // console.log("[findMatchedInventoryData]", invd);
                // compare po date
                let invdDate = null, targetDate = null;
                if (invd.podate && target.podate) {
                  invdDate = invd.podate;
                  targetDate = target.podate;
                }
                else if (invd.ipurchaseorderdate && target.ipurchaseorderdate) {
                  invdDate = invd.ipurchaseorderdate;
                  targetDate = target.ipurchaseorderdate;
                }
                else if (invd.ipurchaseordernumber && target.ipurchaseordernumber) {
                  invdDate = invd.ipurchaseordernumber;
                  targetDate = target.ipurchaseordernumber;
                }
                if (!(invdDate > targetDate)) {
                  target = invd;
                }
              }
              // analyze expiration date
              if (options.isReturnExpOld) {
                // console.log("[findMatchedInventoryData]", invd.iproduct.sepproductexpiration, product.sepproductexpiration);
                if (cpi < options.limitDelta && invd.iproduct.sepproductexpiration < product.sepproductexpiration) {
                  expOldProducts.push(invd);
                }
              }
            }
          }
        }
      }
      // console.log("[findMatchedInventoryData]", target, delta);
      return {target, delta};
    }, {target: null, delta: 1000});
    // if it needs strict compare, then delta should be samller than limit delta.
    const targetinvd = (options.limitDelta > 0) ? ((delta < options.limitDelta) ? target : null) : target;
    if (options.setVirtualMatch && !!targetinvd) {
      // Old case: Only changing the flag from false to true
      // targetinvd.isvirtualmatched = true;
      // New case: Check out quantity, and decrease quantity and set flag as true when quantity is zero
      if (!targetinvd.itempquantity) targetinvd.itempquantity = targetinvd.iquantity; // Keep iquantity at first
      if (targetinvd.iquantity > search.iquantity) {
        targetinvd.iquantity = targetinvd.iquantity - search.iquantity;
      }
      else {
        targetinvd.isvirtualmatched = true;
      }
    }
    if (options.isReturnExpOld) return [targetinvd, expOldProducts];
    return targetinvd;
  });
  // console.log("[findMatchedInventoryData]", result);
  return options.isArray ? result : result[0];
};

export const getTotalPrice = (inventorydatalist=[]) => {
  return inventorydatalist.reduce((s, i) => (s + parseFloat(i.iproduct.sepproductprice) * i.iquantity), 0);
};

export const convertInventoryDataToCSV = (inventorydatalist=[]) => {
  const header = [
      { label: "Reference Number", key: "sepreferencenumber" },
      { label: "Lot/SN", key: "seplotcode" },
      // { label: "Name", key: "sepproductname" },
      { label: "Description", key: "sepproductdescription" },
      // { label: "Distributor", key: "sepdistributor" },
      // { label: "Type", key: "sepproducttype" },
      { label: "Price", key: "sepproductprice" },
      { label: "UoM", key: "sepuom" },
      { label: "Quantity", key: "iquantity" },
      { label: "Status", key: "iproductstatus" },
    ];
  const data = inventorydatalist.map(inventorydata => {
    return header.reduce((d, h) => {
      d[h.key] = (h.key in inventorydata) ? (inventorydata[h.key] ? inventorydata[h.key] : '') 
        : ((h.key in inventorydata.iproduct && inventorydata.iproduct[h.key]) ? inventorydata.iproduct[h.key] : '');
      return d;
    }, {});
  });
  return {header, data};
};

// merge inventory data list to virtual one
const mergeToVirtualInventoryData = (inventorydatalist=[]) => {
  // console.log("[mergeToVirtualInventoryData]", inventorydatalist);
  const count = {};
  let result = inventorydatalist.reduce((s, t) => {
    if (t.iquantity === 0) return s;
    if (!count[t.iproductstatus]) count[t.iproductstatus] = { iquantity: 0, items: [] };
    count[t.iproductstatus].iquantity += t.iquantity;
    count[t.iproductstatus].items.push({
      ...t,
    });
    if (!s) s = {...t};
    else s.iquantity = s.iquantity + t.iquantity;
    t.iquantity = 0;
    return s;
  }, null);
  if (result === null) return null;
  if (result.unit) result.iquantityunit = Math.floor((result.iquantity - 1) / result.unit) + 1;
  else result.iquantityunit = 1;
  if (count['Opens']) {
    result.iproductstatus = 'Opens';
    result.itemsopens = count['Opens'].items;
    result.iquantityopens = count['Opens'].iquantity;
  }
  if (count['Shipped']) {
    if (count['Shipped'].items.length > 0) {
      result = {
        ...result,
        ...count['Shipped'].items[0],
        iquantity: result.iquantity,
        iquantityunit: result.iquantityunit,
      };
    }
    if (Object.keys(count).length > 1) result.iproductstatus = 'PartialShipped';
    result.itemsshipped = count['Shipped'].items;
    result.iquantityshipped = count['Shipped'].iquantity;
    result.iquantityvirtual = count['Shipped'].iquantity;
  }
  if (count['Received']) {
    if (count['Received'].items.length > 0) {
      result = {
        ...result,
        ...count['Received'].items[0],
        iquantity: result.iquantity,
        iquantityunit: result.iquantityunit,
      };
    }
    if (Object.keys(count).length > 1) result.iproductstatus = 'PartialReceived';
    result.itemsreceived = count['Received'].items;
    result.iquantityreceived = count['Received'].iquantity;
    result.iquantityvirtual = count['Received'].iquantity;
  }
  // console.log("[mergeToVirtualInventoryData]", result);
  return result;
};

// search mergable items
// Opens items
const searchOpensItem = (items, obj, total) => {
  // console.log("[searchOpensItem]", items, obj, total);
  let temp = -1, tmp = -1, sum = 0, offset = [];
  if (obj['Opens'].length === 0) {
    return { index: -1, diff: -1 };
  }
  for (let i in obj['Opens']) {
    const index = obj['Opens'][i];
    if (items[index].iquantity === total) return { index, diff: 0 };
    if (items[index].iquantity > total) {
      const diff = items[index].iquantity - total;
      if (tmp === -1 || (tmp !== -1 && diff < tmp)) {
        temp = index;
        tmp = diff;
      }
    }
    else if (sum < total) {
      sum = sum + total;
      offset.push(index);
    }
  }
  if (tmp === -1) {
    temp = offset;
  }
  return { index: temp, diff: tmp };
};
// Shipped items
const searchShippedItem = (items, obj, total) => {
  // console.log("[searchShippedItem]", items, obj, total);
  let temp = -1, tmp = -1, tempOpens = -1;
  if (obj['Shipped'].length === 0) {
    const { index: idxOpens, diff } = searchOpensItem(items, obj, total);
    return { index: -1, diff, opens: idxOpens };
  }
  for (let i in obj['Shipped']) {
    const index = obj['Shipped'][i];
    if (items[index].iquantity === total) return { index, diff: 0, opens: -1 };
    if (items[index].iquantity < total) {
      const subtotal = total - items[index].iquantity;
      const { index: idxOpens, diff } = searchOpensItem(items, obj, subtotal);
      if (tmp === -1 || (tmp !== -1 && diff > 0 && diff < tmp)) {
        temp = index;
        if (diff > 0) tmp = diff;
        tempOpens = idxOpens;
      }
    }
  }
  return { index: temp, diff: tmp, opens: tempOpens };
};
// Received items
const searchReceivedItem = (items, obj, total) => {
  // console.log("[searchReceivedItem]", items, obj, total);
  let temp = -1, tmp = -1, tempShipped = -1, tempOpens = -1;
  if (obj['Received'].length === 0) {
    const { index: idxShipped, opens: idxOpens, diff } = searchShippedItem(items, obj, total);
    return { index: -1, diff, shipped: idxShipped, opens: idxOpens };
  }
  for (let i in obj['Received']) {
    const index = obj['Received'][i];
    if (items[index].iquantity === total) return { index, diff: 0, shipped: -1, opens: -1 };
    if (items[index].iquantity < total) {
      const subtotal = total - items[index].iquantity;
      const { index: idxShipped, opens: idxOpens, diff } = searchShippedItem(items, obj, subtotal);
      if (tmp === -1 || (tmp !== -1 && diff > 0 && diff < tmp)) {
        temp = index;
        if (diff > 0) tmp = diff;
        tempShipped = idxShipped;
        tempOpens = idxOpens;
      }
    }
  }
  return { index: temp, diff: tmp, shipped: tempShipped, opens: tempOpens };
};

/**
 * Get "Partial Received" Inventory Data list
 * Check out all inventory data with imergeindex
 * And, set "Stocked for Facility" for Vendor
 * And, add unit value each inventory data
 * @param {Array} inventorydatalist 
 * @param {Object} options 
 * @returns 
 */
export const getPartialReceivedInventoryData = (inventorydatalist=[], options={}) => {
  options = {
    isVendor: false, // to Set Stocked for Facility
    ...options,
  };
  // console.log("[getPartialReceivedInventoryData]", inventorydatalist);
  let result = [], temp = {}, tmp = {};

  inventorydatalist.forEach(invd => {
    const unit = getQuantityOnOrder(invd, {isunit: true});
    if (invd.iproductstatus !== 'Opens' && invd.iproductstatus !== 'Shipped' && invd.iproductstatus !== 'Received') {
      result.push({
        ...invd,
        unit,
      });
    }
    else {
      const iquantity = parseInt(invd.iquantity);
      const iquantityunit = parseInt(invd.iquantityunit);
      const iquantityremain = iquantity % unit;
      // console.log("[getPartialReceivedInventoryData]", invd, iquantity, iquantityunit, unit, iquantityremain);
      if (iquantityremain < iquantity) {
        result.push({
          ...invd,
          iquantity: (iquantity - iquantityremain),
          iquantityunit,
          unit,
        });
      }
      if (iquantityremain > 0) {
        temp[invd.id] = {
          ...invd,
          iquantity: iquantityremain,
          iquantityunit: 0,
          unit,
        };
        // make container with id or imergeindex
        if (!invd.imergeindex) {
          if (!tmp[invd.id]) tmp[invd.id] = {
            'Received': [],
            'Shipped': [],
            'Opens': [],
          };
          tmp[invd.id] = {
            ...tmp[invd.id],
            [invd.iproductstatus]: [...tmp[invd.id][invd.iproductstatus], invd.id]
          };
        }
        else {
          if (!tmp[invd.imergeindex]) tmp[invd.imergeindex] = {
            'Received': [],
            'Shipped': [],
            'Opens': [],
          };
          tmp[invd.imergeindex] = {
            ...tmp[invd.imergeindex],
            [invd.iproductstatus]: [...tmp[invd.imergeindex][invd.iproductstatus], invd.id]
          };
        }
      }
      else {
        temp[invd.id] = {
          ...invd,
          iquantity: 0,
          iquantityunit: 0,
          unit,
        };
      }
    }
  });
  // console.log("[getPartialReceivedInventoryData]", temp, tmp);
  // add item based on imergeindex
  if (Object.keys(tmp).length > 0) {
    for (let rootidx in tmp) {
      const unit = temp[rootidx].unit;
      let totalcount = tmp[rootidx]['Received'].length + tmp[rootidx]['Shipped'].length + tmp[rootidx]['Opens'].length;
      while (totalcount > 0) {
        const { index: received, shipped, opens } = searchReceivedItem(temp, tmp[rootidx], unit);
        // console.log("[getPartialReceivedInventoryData]", received, shipped, opens);
        // merge with received + shipped + opens
        let sum = 0, items = [];
        if (received !== -1) {
          sum += temp[received].iquantity;
          items.push(temp[received]);
          tmp[rootidx]['Received'] = tmp[rootidx]['Received'].filter(idx => idx !== received);
        }
        if (shipped !== -1) {
          sum += temp[shipped].iquantity;
          items.push(temp[shipped]);
          tmp[rootidx]['Shipped'] = tmp[rootidx]['Shipped'].filter(idx => idx !== shipped);
        }
        if (opens !== -1) {
          const openslist = (typeof opens === 'object') ? opens : [opens];
          for (let opensitem of openslist) {
            if ((sum + temp[opensitem].iquantity) > unit) {
              const newtempopens = {
                ...temp[opensitem],
                iquantity: (unit - sum),
              }
              temp[opensitem].iquantity = sum + temp[opensitem].iquantity - unit;
              items.push(newtempopens);
            }
            else {
              items.push(temp[opensitem]);
              tmp[rootidx]['Opens'] = tmp[rootidx]['Opens'].filter(idx => idx !== opensitem);
            }
          }
        }
        if (items.length === 0) {
          for (let idx of [ ...tmp[rootidx]['Received'], ...tmp[rootidx]['Shipped'], ...tmp[rootidx]['Opens'] ]) {
            const virtualinvd = mergeToVirtualInventoryData([temp[idx]]);
            if (virtualinvd) result.push(virtualinvd);
          }
          tmp[rootidx]['Received'] = [];
          tmp[rootidx]['Shipped'] = [];
          tmp[rootidx]['Opens'] = [];
        }
        else {
          const virtualinvd = mergeToVirtualInventoryData(items);
          if (virtualinvd) result.push(virtualinvd);
        }
        const newtotalcount = tmp[rootidx]['Received'].length + tmp[rootidx]['Shipped'].length + tmp[rootidx]['Opens'].length;
        // if (totalcount > newtotalcount) totalcount = newtotalcount;
        // else totalcount = 0;
        totalcount = newtotalcount;
        // console.log("[getPartialReceivedInventoryData]", unit, result, tmp);
      }
    }
  }
  // console.log("[getPartialReceivedInventoryData]", result);

  // Set StockedForFacility
  if (options.isVendor) {
    result = result.map(inv => {
      if (inv.iproductstatus === 'Received' && inv.ireceivedstockforfacility) {
        inv.iproductstatus = 'StockedForFacility';
      }
      return inv;
    });
  }

  return result;
};


export const InventoryProductStatus = {
  Opens: 'Open',
  Shipped: 'Shipped',
  StockedForFacility: 'Stocked for Facility',   // Virtaul Status
  BackOrder: 'Back-Order',
  Received: 'Received',
  ShippedVendor : 'Shipped',
  ReceivedVendor: 'Received',
  PartialReceived: 'Partial Received',          // Virtaul Status
  PartialShipped: 'Partial Shipped',            // Virtaul Status
};
export const MyInventoryProductStatus = {
  Expiring: 'Expiring',
  Expired: 'Expired',
  Shipped:  'Shipped',
  BackOrder: 'Back-Order',
  Recall: 'Recall',
  Edited: 'Edited',
};
export const InventoryProductStatusHospital = getSubModel(InventoryProductStatus, [
  'Shipped', 'BackOrder', 'Received', 'Opens',  'PartialReceived', 'PartialShipped',
]);
export const InventoryProductStatusVendor = getSubModel(InventoryProductStatus, [
  'Shipped', 'BackOrder', 'Received', 'StockedForFacility', 'Opens', 'PartialReceived', 'PartialShipped',
]);

export const validateInventoryProductStatus = validateModelItem(InventoryProductStatus);
export const getInventoryProductStatus = getModelMenu(InventoryProductStatus);

export const isEnableConfirmHospital = (status) => (
  status === "Shipped" || status === "Opens" || status === "BackOrder"
);
export const isReceivedHospital = (status) => (
  status === "Received" || status === "ShippedVendor" || status === "ReceivedVendor"
);
export const isShippedVendor = (status) => (
  status === "Shipped" || status === "Received" || status === "ShippedVendor" || status === "ReceivedVendor" || status === "StockedForFacility"
);
export const isOpenOrder = (status) => (
  status === "Opens" || status === "Shipped" || status === "BackOrder" || status === "PartialReceived" || status === "PartialShipped"
);
export const isEnableConfirmStatus = (oldstatus, newstatus, role) => {
  if (!oldstatus || !newstatus || oldstatus === newstatus) return false;
  if (role === 'Hospital') {
    return ((oldstatus === 'Opens' || oldstatus === 'Shipped' || oldstatus === 'BackOrder' || oldstatus === 'Received') 
      && (newstatus === 'Opens' || newstatus === 'BackOrder' || newstatus === 'Received'));
  }
  else if (role === 'Vendor') {
    return ((oldstatus === 'Opens' || oldstatus === 'Shipped' || oldstatus === 'BackOrder' || oldstatus === 'StockedForFacility') 
      && (newstatus === 'Opens' || newstatus === 'Shipped' || newstatus === 'BackOrder' || newstatus === 'StockedForFacility'));
  }
  return false;
}


export const ReturnType = {
  Expiration: 'Expired',
  Damaged: 'Damaged',
  Lost: 'Lost',
  Other: 'Other',
};
export const validateReturnType = validateModelItem(ReturnType);
export const getReturnType = getModelMenu(ReturnType);


export const ExpirationStatus = {
  Expiring: 'Expiring',
  Expired: 'Expired',
}
export const validateExpirationStatus = validateModelItem(ExpirationStatus);
export const getExpirationStatus = getModelMenu(ExpirationStatus);


export const EditStatus = {
  Add: 'Added',
  Edit: 'Updated',
  Remove: 'Deleted',
}
export const validateEditStatus = validateModelItem(EditStatus);
export const getEditStatus = getModelMenu(EditStatus);


export const ReturnStatus = (isNewDesign ? {
  RequestSent: 'Awaiting Approval',
  RequestAccepted: 'Approved',
  RequestRejected: 'Declined',
} : {
  RequestSent: 'Request Sent',
  RequestAccepted: 'Accepted',
  RequestRejected: 'Declined',
});
export const validateReturnStatus = validateModelItem(ReturnStatus);
export const getReturnStatus = getModelMenu(ReturnStatus);

