import {
  SURGICAL_SET_DETAIL,
  SURGICAL_SEARCH,
  SURGICAL_STATUS,
  PURCHASE_ORDER_SET_DETAIL,
  PURCHASE_ORDER_SET_LAST_ID,
  PURCHASE_ORDER_ADD,
  PURCHASE_ORDER_SEARCH,
  RECEIVEDPO_SET_DETAIL,
  RECEIVEDPO_SEARCH,
  INVOICE_SET_DETAIL,
  INVOICE_SET_LAST_ID,
  INVOICE_ADD,
  INVOICE_SEARCH,
  RECEIVEDINV_SEARCH,
  INVD_SET_DETAIL,
  INVD_SET_LAST_ID,
  INVD_ADD,
  INVENTORY_SEARCH,
  INVENTORY_SET_DETAIL,
  COMPLIANCE_SET_DETAIL,
  PROCEDURE_SET_DETAIL,
  PROCEDURE_SEARCH,
  COMPLIANCE_SEARCH,
  SURGICALEVENTHISTORY_SET_DETAIL,
  SURGICALEVENTHISTORY_SEARCH,
  TEMPLATE_SEARCH,
  INVITE_SEARCH,
  VENDOR_ACCEPT_SEARCH,
  RECEIVED_INVITE_SEARCH,
  HOSPITAL_ACCEPT_SEARCH,
  USER_SEARCH,
  SET_TOGGLE_STAGE,
  RETRIEVE_ES_SEARCH,
  DAML_SEARCH,
  CALLBACK_KEY_SEARCH,
  SEARCH_FILTERS,
  SEARCH_FILTERS_CLEAR,
} from '../actions/constants';
import defaultReducer from "./createReducer";


// Reducers  PurchaseOrder
export const purchaseReducer = defaultReducer("po", PURCHASE_ORDER_SET_DETAIL, PURCHASE_ORDER_SET_LAST_ID, 
  PURCHASE_ORDER_ADD, PURCHASE_ORDER_SEARCH);

// Reducers  Invoice
export const invoiceReducer = defaultReducer("invoice", INVOICE_SET_DETAIL, INVOICE_SET_LAST_ID, INVOICE_ADD, INVOICE_SEARCH);

// Reducers  Received Invoice
export const receivedinvReducer = defaultReducer("", null, null, null, RECEIVEDINV_SEARCH);

// Reducers  Invoice Detail
export const invdReducer = defaultReducer("invd", INVD_SET_DETAIL, INVD_SET_LAST_ID, INVD_ADD, null);

// Reducers  Inventory
export const inventoryReducer = defaultReducer("", INVENTORY_SET_DETAIL, null, null, INVENTORY_SEARCH);

// Reducers  SurgicalEvent
export const surgicalReducer = defaultReducer("", SURGICAL_SET_DETAIL, null, null, SURGICAL_SEARCH, SURGICAL_STATUS);

// Reducers  ReceivedPOEvent
export const receivedpoReducer = defaultReducer("", RECEIVEDPO_SET_DETAIL, null, null, RECEIVEDPO_SEARCH);

// Reducers  Compliance
export const complianceReducer = defaultReducer("", COMPLIANCE_SET_DETAIL, null, null, COMPLIANCE_SEARCH);

// Reducers  PostProcedure
export const procedureReducer = defaultReducer("", PROCEDURE_SET_DETAIL, null, null, PROCEDURE_SEARCH);

// Reducers  Surgicalhistory
export const surgicalhistoryReducer = defaultReducer("", SURGICALEVENTHISTORY_SET_DETAIL, null, null, SURGICALEVENTHISTORY_SEARCH);

// Reducers  Template
export const templateReducer = defaultReducer("", null, null, null, TEMPLATE_SEARCH);

// Reducers  Invite Vendor
export const inviteReducer = defaultReducer("", null, null, null, INVITE_SEARCH);

// Reducers  Accept Vendor
export const acceptReducer = defaultReducer("", null, null, null, VENDOR_ACCEPT_SEARCH);

// Reducers  Received Invite
export const receivedinviteReducer = defaultReducer("", null, null, null, RECEIVED_INVITE_SEARCH);

// Reducers  Accept Hospital
export const accepthospitalReducer = defaultReducer("", null, null, null, HOSPITAL_ACCEPT_SEARCH);

// Reducers  User
export const userReducer = defaultReducer("", null, null, null, USER_SEARCH);

// Reducers  Toggle Stage  
export const toggleStageReducer = defaultReducer("toggleStage", SET_TOGGLE_STAGE, null, null, null);

// Reducers  Retrieve ES
export const retrieveESReducer = defaultReducer("", null, null, null, RETRIEVE_ES_SEARCH);

// Reducers  DAML Query
export const queryDAMLReducer = defaultReducer("", null, null, null, DAML_SEARCH);

// Reducers  Callback Key
export const queryCallbackKeyReducer = defaultReducer("", null, null, null, CALLBACK_KEY_SEARCH);

// Reducers  New Search Filters
export const searchFiltersReducer = defaultReducer("", null, null, null, SEARCH_FILTERS, null, SEARCH_FILTERS_CLEAR);

