import React, { useState, useEffect } from "react";
import { useParty, useLedger, useStreamQueries } from "@daml/react";
import { Button, Typography } from "@material-ui/core";
import { VendorTemplate } from "../../services/daml-modules1";
import AddEditVendorTemplate from './addeditvendortemplate';
import AddIcon from '@material-ui/icons/Add';
import VendorTemplateContracts from "./vendortemplatecontracts";
import { dispatch, getState } from "../../store";
import { templateSearch } from "../../store/actions/generalActions";
import { createVendorTemplate, updateVendorTemplate } from "./handle";
import { useDamlState } from "../../context/DamlContext";
import useStyles from "../surgicalevent/styles";


/**
 * Vendor Template Page in Hospital
 * @returns 
 */
export default function VendorTemplateList() {

  const classes = useStyles();
  const ledger = useLedger();
  const hospital = useParty();
  const { contracts } = useStreamQueries(VendorTemplate, () => [{ hospital }]);
  const { roleCid } = useDamlState();

  const [ContractId, setContractId] = useState('');
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({});

  // AddEditVendorTemplate
  const [open, setOpen] = useState(false);
  const [isCreate, setCreate] = useState(true);
  const [selectedVendorTemplate, selectVendorTemplate] = useState({});
  const [vendor, setVendor] = useState('');


  const [searchVendorTemplate, setSearchVendorTemplate] = useState({
    vendor: '',
    ...getState().template,
  });
  const handleChangeSearchValue = (val, key) => {
    setSearchVendorTemplate({ ...searchVendorTemplate, [key]: val });
    dispatch(templateSearch({ key, value: val }));
  };


  useEffect(() => {
    setLoading(true);

    let temp = {};

    if (searchVendorTemplate.vendor !== "") temp.vendor = searchVendorTemplate.vendor;
    temp.hospital = hospital;

    const delay = 0;
    if (delay > 0) setTimeout(() => {
      setSearchParams(temp);
    }, delay);
    else {
      setSearchParams(temp);
    }
  }, [searchVendorTemplate, hospital]);

  useEffect(() => {
    setLoading(false);
  }, [searchParams]);



  // handle when click "Create" or "Update" button in Create Vendor Template modal
  const handleRequest = async (newvendor) => {
    if (isCreate) await createVendorTemplate({ledger, roleCid, newvendor, vendor, setContractId});
    else await updateVendorTemplate({ledger, roleCid, ContractId, newvendor, vendor});
  };


  return (
    <>
      <div key="formChildStep" className="formsteps">
        <Typography variant="h4" className={classes.pageTitle}>Vendor Template</Typography>

        <Button
          size="small"
          className={classes.newSetupButton}
          onClick={() => {
            setCreate(true);
            setVendor("");
            selectVendorTemplate({});
            setOpen(true);
          }}
          variant="contained"
        >
          <span className="desktop">Create Vendor Template</span>
          <span className="mobile"><AddIcon /></span>
        </Button>

        <VendorTemplateContracts
          searchParams={searchParams}
          handleChangeSearchValue={handleChangeSearchValue}
          allContracts={contracts}
          searchVendorTemplate={searchVendorTemplate}
          handleRequest={(data) => {
            // console.log("[VendorTemplateList] handleRequest", data.payload);
            setVendor(data.payload.vendor);
            setContractId(data.contractId);
            selectVendorTemplate(data.payload);
            setCreate(false);
            setOpen(true);
          }}
          loading={loading}
        />

      </div>

      <AddEditVendorTemplate
        isCreate={isCreate}
        open={open}
        setOpen={setOpen}
        handleRequest={handleRequest}
        title={"Create Vendor Template"}
        editTitle={"Edit Vendor Template"}
        defaultValue={selectedVendorTemplate}
        vendor={vendor}
        setVendor={setVendor}
      />

    </>
  );
};