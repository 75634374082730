import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  logotype: {
    color: "white",
    marginLeft: '0px;',
    marginRight: theme.spacing(2.5),
    fontWeight: 500,
    fontSize: 18,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
    '& img':{
      verticalAlign: 'middle;',

    },
  },
  notificationList:{
    '& .MuiListItem-gutters:hover':{
      background: '#DEF3FB'
    }
  },
  profilemenu: ((theme.version && theme.version.new) ?
    {
      minWidth: 160,
      padding: 0,
      backgroundColor: '#FFFFFF',
      color:'#000000',
      '& .MuiListItem-root': {
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '17px',
        padding: '5px 5px 5px 16px',
      '&:hover':{
          backgroundColor: '#5C5C5C',
          color: '#FFFFFF',
          // fontWeight: 700,
          // fontSize: '15px',
          lineHeight: '18px',
        },
      },
    } :
    {
      minWidth: 170,
    '& .MuiListItem-gutters:hover':{
        background: '#DEF3FB',
      }
    }),
    profilemenuDM: ((theme.version && theme.version.new) ?
    {
      minWidth: 160,
      padding: 0,
      backgroundColor: '#1c3c74',
      color:'#FFFFFF',
      '& .MuiListItem-root': {
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '17px',
        padding: '5px 5px 5px 16px',
      '&:hover':{
          backgroundColor: '#5C5C5C',
          color: '#FFFFFF',
          // fontWeight: 700,
          // fontSize: '15px',
          lineHeight: '18px',
        },
      },
    } :
    {
      minWidth: 170,
    '& .MuiListItem-gutters:hover':{
        background: '#DEF3FB',
      }
    }),
  menuText: {
    '& span':{
      fontSize: "inherit"
    }
  },
  newheadermenu: {
    minWidth: 170,
    backgroundColor: '#FFFFFF',
    '& .MuiListItem-root': {
      lineHeight: '16px',
      fontSize: '14px',
      paddingLeft: '5px',
      '&:hover':{
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '17px',
      },
      '&:not(:first-child)': {
        borderTop: '1px solid #CCCCCC',
      },
    },
  },
  appBar: {
    width: "100vw",
    background:'#ffffff;',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05);',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '& .avtar':{
      width:'40px;',
      height: '40px;',
      background: '#F7CD37',
      color: '#ffffff;',
      borderRadius: '40px;',
      marginRight:'9px;',
      textAlign:'center;',
      fontSize:'16px;',
      lineHeight:'40px;',
    },
    '& .avtarSwitchOrg':{
      width:'25px;',
      height: '25px;',
      background: '#F7CD37',
      color: '#ffffff;',
      borderRadius: '40px;',
      marginRight:'9px;',
      textAlign:'center;',
      fontSize:'12px;',
      lineHeight:'24px;',
    },
    '& .currentOrganizationActive':{
      color: '#1C3C74;',
      // backgroundColor: '#1C3C74',
      borderRadius:'4px',

    },
    '& .currentOrganizationActiveDM':{
      color: '#FFFFFF;',
      // backgroundColor: '#1C3C74',
      borderRadius:'4px',

    },
    '& .plainText': {
      color: '#5C5C5C',
      fontSize: '10px',
      paddingLeft: '10px'
    },

    '& .notiBtn':{
      width:'48px;',
      height:'48px;',
      color:'#183C73;',
      '@media (max-width:1024px)':{
        width:'41px;',
        height:'41px;',
        minWidth:'41px'
      },
    },

    '&  .user':{
      fontSize:'13px;',
      color: 'rgba(0,0,0,0.83;)',
      textAlign: 'left;',
      fontWeight:'300;',
      textTransform:'capitalize;',
    },
    '&  .user p':{
      fontSize:'16px;',
      fontWeight:'400;',
      margin: '3px 0px 0px 0px;',
      lineHeight:'16px;',
    },
  },
  appBarRoleOne: {
    width: "100vw",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#FFB030',
  },
  appBarRoleTwo: {
    width: "100vw",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#B0E0E6',
  },
  appBarRoleThree: {
    width: "100vw",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#560228',
  },
  appBarRoleFour: {
    width: "100vw",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#560228',
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    '@media (min-width:1180px)':{
      marginRight: 30,
    },
    '@media (max-width:750px)':{
      display: 'none',

    },
  },
  messageContent: {
    display: "flex",
    flexDirection: "column",
  },
  headerMenu: {
    marginTop: theme.spacing(5),
  },
  headerMenuList: {
    display: "flex",
    flexDirection: "column",
  },
  headerMenuItem: {
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
  headerMenuButton: {
    marginLeft: '0px',
    padding: theme.spacing(0.5),
  },
  headerMenuButtonCollapse: {
    marginRight: theme.spacing(2),
    width:'56px;',
    height:'56px',
  },
  headerIcon: {
    fontSize: 28,
    color: "rgba(255, 255, 255, 0.35)",
  },
  headerIconCollapse: {
    color:'#183C73;',
  },
  profileMenu: {
    minWidth: 265,
    color: '#000000',
  },
  menuIcon:{
    display: 'flex',
    justifyContent: 'space-around',
    paddingRight:'4px',
    '&:hover':{
      backgroundColor: '#5C5C5C',
      color: '#FFFFFF',
      // fontWeight: 700,
      // fontSize: '15px',
      lineHeight: '18px',
    },
  },
  profileMenuUser: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  profileMenuItem: {
    color: theme.palette.text.hint,
  },
  profileMenuIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.hint,
  },
  profileMenuLink: {
    fontSize: 16,
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
  messageNotification: {
    height: "auto",
    display: "flex",
    alignItems: "center",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.background.light,
    },
  },
  messageNotificationSide: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  messageNotificationBodySide: {
    alignItems: "flex-start",
    marginRight: 0,
  },
  sendMessageButton: {
    margin: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textTransform: "none",
  },
  sendButtonIcon: {
    marginLeft: theme.spacing(2),
  },
  progress: {
    // size: 28,
    color: "rgba(255, 255, 255, 0.35)",
    // margin: theme.spacing(2),
  },
  notifications:{
    maxWidth:'350px',
    '& p':{
      whiteSpace:'break-spaces'
    }
  },
  mobileMenu: {
    '& .MuiPaper-root': {
      minWidth: 200,
      '& .MuiMenuItem-root': {
        justifyContent: 'space-between',
      }
    },
  },

  myProfile:{
    backgroundColor: "#5C5C5C !important",
    fontFamily: 'Lato',
    color:"#FFFFFF",
    fontWeight: "700 !important",
    fontSize:"12px !important",
    cursor: 'default',
    borderTopLeftRadius:'4px',
    borderTopRightRadius:'4px', 
  },
  profileUser:{
    cursor: 'default',
    fontWeight:"700 !important",
    fontSize:"12px !important",
    backgroundColor: '#E1DDDD !important',
    color:"#000000 !important",

  },
  profileEmailId:{
    backgroundColor: '#E1DDDD !important',
    color:"#5C5C5C!important",
  }
}));
