import { validateSide } from "./Product";
import { validateGender, validateSalutation } from "./Contact";
import { requiredFields, getModelMenu, validateModelItem } from "./handles";
import { getTemplate } from "../services/util";

export const SurgicalEventModel = {
  id: '',
  seid: '',
  mrn: '',
  patientfirstname: '',
  patientlastname: '',
  patientgender: '',
  patientdob: '',
  eventdate: '',
  surgicalprocedure: '',
  surgicalside: '',
  physiciansalutation: '',
  physicianfirstname: '',
  physicianlastname: '',
  status: '',
  price: '', 
  account: '',
  checked: false, // TODO it's not used in any components or functions
  completedate: '',
};
export const validateSurgicalEvent = (surgical) => {
  return (surgical.seid !== ""
    || surgical.mrn !== ""
    || surgical.patientfirstname !== ""
    || surgical.patientlastname !== ""
    || validateGender(surgical.patientgender)
    || surgical.patientdob !== ""
    || surgical.eventdate !== ""
    || surgical.surgicalprocedure !== ""
    || validateSide(surgical.surgicalside)
    || validateSalutation(surgical.physiciansalutation)
    || surgical.physicianfirstname !== ""
    || surgical.physicianlastname !== ""
    || validateSurgicalStatus(surgical.status)
    || surgical.price !== ""
    || surgical.account !== ""
  );
};

export const getSurgicalEvent = (surgical) => {
  let newSurgicalEvent = {};
  if (typeof surgical.id === "string" && surgical.id !== "") newSurgicalEvent.id = surgical.id;
  if (typeof surgical.seid === "string" && surgical.seid !== "") newSurgicalEvent.seid = surgical.seid;
  if (typeof surgical.mrn === "string" && surgical.mrn !== "") newSurgicalEvent.mrn = surgical.mrn;
  if (typeof surgical.patientfirstname === "string" && surgical.patientfirstname !== "") newSurgicalEvent.patientfirstname = surgical.patientfirstname;
  if (typeof surgical.patientlastname === "string" && surgical.patientlastname !== "") newSurgicalEvent.patientlastname = surgical.patientlastname;
  if (validateGender(surgical.patientgender)) newSurgicalEvent.patientgender = surgical.patientgender;
  if (typeof surgical.patientdob === "string" && surgical.patientdob !== "") newSurgicalEvent.patientdob = surgical.patientdob;
  newSurgicalEvent.eventdate = surgical.eventdate || "";
  newSurgicalEvent.surgicalprocedure = surgical.surgicalprocedure || "";
  newSurgicalEvent.surgicalside = surgical.surgicalside;
  if (validateSalutation(surgical.physiciansalutation)) newSurgicalEvent.physiciansalutation = surgical.physiciansalutation;
  newSurgicalEvent.physicianfirstname = surgical.physicianfirstname || "";
  newSurgicalEvent.physicianlastname = surgical.physicianlastname || "";
  newSurgicalEvent.status = surgical.status || "Scheduled";
  newSurgicalEvent.price = surgical.price || "0";
  if (typeof surgical.account === "string" && surgical.account !== "") newSurgicalEvent.account = surgical.account;
  if (typeof surgical.checked === "boolean") newSurgicalEvent.checked = surgical.checked;
  if (typeof surgical.completedate === "string" && surgical.completedate !== "") newSurgicalEvent.completedate = surgical.completedate;
  if (typeof surgical.surgonid === "string" && surgical.completedate !== "") newSurgicalEvent.surgonid = surgical.surgonid;
  return newSurgicalEvent;
};

export const isEmptySurgicalEvent = (surgical) => (
  JSON.stringify(getSurgicalEvent(surgical)) === JSON.stringify(getSurgicalEvent({...SurgicalEventModel}))
);

export const compareSurgicalEvent = (surgical1, surgical2, isAll=false) => {
  // console.log("[compareSurgicalEvent]", JSON.stringify(surgical1), JSON.stringify(surgical2));
  return (surgical1.id === surgical2.id
    && surgical1.seid === surgical2.seid
    && (!isAll || surgical1.mrn === surgical2.mrn)
    && (!isAll || surgical1.patientfirstname === surgical2.patientfirstname)
    && (!isAll || surgical1.patientlastname === surgical2.patientlastname)
    && (!isAll || surgical1.patientgender === surgical2.patientgender)
    && (!isAll || surgical1.patientdob === surgical2.patientdob)
    && surgical1.eventdate === surgical2.eventdate
    && surgical1.surgicalprocedure === surgical2.surgicalprocedure
    && surgical1.surgicalside === surgical2.surgicalside
    && (!isAll || ((!surgical1.physiciansalutation && !surgical2.physiciansalutation)
      || surgical1.physiciansalutation === surgical2.physiciansalutation))
    && surgical1.physicianfirstname === surgical2.physicianfirstname
    && surgical1.physicianlastname === surgical2.physicianlastname
    && (!isAll || surgical1.status === surgical2.status)
    && (!isAll || surgical1.price === surgical2.price)
    && (!isAll || ((!surgical1.account && !surgical2.account)
    || surgical1.account === surgical2.account))
    && (!isAll || surgical1.checked === surgical2.checked)
    && (!isAll || surgical1.completedate === surgical2.completedate)
  );
};

export const requiredSurgicalEvent = (surgical) => requiredFields(surgical, {
  'mrn':'MRN', 
  'patientfirstname':'Patient first name', 
  'patientlastname':'Patient last name', 
  'patientgender' : 'Gender', 
  'patientdob':'Patient DOB', 
  'eventdate':'Event date', 
  'surgicalprocedure':'Surgical procedure',
  'surgicalside':'Surgical side', 
  'physicianfirstname':'Physician first name', 
  'physicianlastname':'Physician last name', 
  // 'physiciansalutation':'Physician salutation',
});

export const requiredSurgicalEventPref = (surgical) => requiredFields(surgical, {
  'mrn':'MRN', 
  'patientfirstname':'Patient first name', 
  'patientlastname':'Patient last name', 
  'patientgender' : 'Gender', 
  'patientdob':'Patient DOB', 
  'eventdate':'Event date', 
  'surgicalprocedure':'Surgical procedure',
  'surgicalside':'Surgical side', 
  // 'physiciansalutation':'Physician salutation',
});

export const convertSEDataToCSV = (surgical) => {
  const header = [
    { label: "SE ID", key: "seid" },
    { label: "MRN", key: "mrn" },
    { label: "Patient First Name", key: "patientfirstname" },
    { label: "Patient Last Name", key: "patientlastname" },
    { label: "Gender", key: "patientgender" },
    { label: "DOB", key: "patientdob" },
    { label: "Event Date", key: "eventdate" },
    { label: "Procedure", key: "surgicalprocedure" },
    { label: "Side", key: "surgicalside" },
    { label: "Salutation", key: "physiciansalutation" },
    { label: "Physician First Name", key: "physicianfirstname" },
    { label: "Physician Last Name", key: "physicianlastname" },
  ];
  const data = [header.reduce((d, h) => {
      d[h.key] = (h.key in surgical && surgical[h.key]) ? surgical[h.key] : '';
      return d;
    }, {})];
  return {header, data};
};

export const SurgicalStatus = {
  Complete: 'Completed',
  Scheduled: 'Scheduled',
  Pending : 'Pending',
  Cancelled: 'Cancelled',
};
export const validateSurgicalStatus = validateModelItem(SurgicalStatus);
export const getSurgicalStatus = getModelMenu(SurgicalStatus);

/**
 * check if Contract is not SE History
 * @param {Object} c 
 * @returns {Boolean}
 */
export const checkNotSEHistory = (c) => (getTemplate(c) !== "SurgicalEventHistory");

/**
 * check if Contract is editable
 * @param {Object} c 
 * @returns {Boolean}
 */
export const checkEditableSE = (c) => (c.payload.surgicaleventdata.status === "Scheduled" && checkNotSEHistory(c));
