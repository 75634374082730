import React, { useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import { useLedger } from "@daml/react";
import { Button, Typography, Grid, Box, Table, TableBody, TableRow, TableCell, IconButton } from "@material-ui/core";
import { ReturnType } from "../../models/InventoryData";
import { useDamlState } from "../../context/DamlContext";
import { useLayoutDispatch, setLoading, unsetLoading } from "../../context/LayoutContext";
import { CustomSelect, CustomTextField } from "../../components/Inputs";
import ModalDialog from '../../components/Modals/NewGeneralModal';
import SnackbarModal from "../../components/Modals/SnackbarModal";
import FileUploadDropzone from "../../components/FileUpload/FileUploadDropzone";
import { useItemDetails } from "../../hooks/useProduct";
import { useAccountInventory } from "../../hooks/useInventory";
import { useComment } from "../../hooks/usePO";
import folderIcon  from "../../icons/mdi_folder-upload.svg";
import commentsIcon from '../../icons/new-comments-icon.svg';
import { AddedProductList } from "../surgicalevent/add-edit-product1";
import { handlePreviewIcon } from "../purchaseorder/create-purchaseorder1";
import POCommentPopover from "../purchaseorder/pocomment-popover";
import { AddedProductHeader } from "./newrequest1";
import { createReturnResponse, acceptDeclineInventoryData } from "./handle";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "../purchaseorder/styles";
import useStyles2 from "./styles";


const InvTitle = ({
  title,
}) => {

  const classes = useStyles();
  const classes2 = useStyles2();

  // Popover when clicking Comment button
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenCommentPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classNames(classes.newSETitlePanel, classes2.newTitlePanel)}
    >
      <Typography variant="inherit" component="span" className="label top-label" >
        {title}
      </Typography>

      <IconButton onClick={handleOpenCommentPopover}>
        <img alt="" src={commentsIcon} />
      </IconButton>

      <POCommentPopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        isSendable={false}
      />
    </Grid>
  );
};

const InvPanel = ({
  data, setData, isReturn, 
}) => {

  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();

  const { accountInventoryData } = useAccountInventory();

  // console.log("[ConfirmEdit] InvPanel data", data);

  const handleChange = (value, key) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  // handle to upload
  const handleUpload = (file, callback) => {
  };

  return (
    <Grid 
      container
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      className={classes.newOverview}
    >
      <Grid item className={classes2.confirmTopBody}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classNames(classes.newSETitlePanel, classes2.newTitlePanel)}
        >
          <Typography variant="inherit" component="span" className="label" >
            Reference Number: 
          </Typography>
          <Typography variant="inherit" component="span" className="value" >
            {accountInventoryData.ireferencenumber}
          </Typography>
          <Typography variant="inherit" component="span" className="label" >
            Ownership: 
          </Typography>
          <Typography variant="inherit" component="span" className="value" >
            {accountInventoryData.iownership}
          </Typography>
          <Typography variant="inherit" component="span" className="label" >
            Distributor: 
          </Typography>
          <Typography variant="inherit" component="span" className="value" >
            {accountInventoryData.vendor}
          </Typography>
        </Grid>
      </Grid>

      <Grid item className={classNames(classes2.requestPanel, classes2.requestPanel1)}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className="label" colSpan={2}>
                {
                  isReturn ? 'Return Request' : 'Confirm Edit Request'
                }
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="value">
                <CustomSelect
                  label="Request Type"
                  value={data.newireturntype || ""}
                  onChange={(val) => handleChange(val, 'newireturntype')}
                  items={ReturnType}
                  placeholder="Select One"
                  width={115}
                  options={{
                    // labelLeft: true,
                    // labelWidth: 143,
                    disabled: true,
                    readOnly: true,
                  }}
                />
              </TableCell>
              <TableCell className="value">
                <CustomTextField
                  label="Request Description"
                  value={data.message || ""}
                  onChange={(val) => handleChange(val, 'message')}
                  width={235}
                  options={{
                    // labelLeft: true,
                    // labelWidth: 143,
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>

      <Grid item className={classes2.requestPanel}>
        <AddedProductList
          options={{
            keyProduct: 'iproduct',
            isEnableEditProduct: false,
            isEnableDeleteProduct: false,
          }}
          HeaderComponent={AddedProductHeader}
          className={classes2.addedProductNewPanel}
        />
      </Grid>

      <Grid item className={classes2.requestPanel}>
        <FileUploadDropzone 
          className={classNames(classes1.uploadFile, classes2.newUploadFile)}
          title=""
          uploadLabel={(
            <Box className="drag-drop-btn">
              Drag and Drop file to upload
              <img src={folderIcon} alt="" />
            </Box>
          )}
          changeFile={handleUpload}
          isNonePadding={true}
          isEnableUpload={false}
          handlePreviewIcon={handlePreviewIcon}
        />
      </Grid>
    </Grid>
  );
};

const InvActionButtons = ({
  isReturn, setOpen, data, handleAlert,
}) => {
  
  const classes = useStyles();
  const classes2 = useStyles2();

  const ledger = useLedger();
  const { role, roleCid, party, userParty } = useDamlState();
  
  const layoutDispatch = useLayoutDispatch();

  const { accountInventoryData, setAccountInventoryData } = useAccountInventory();
  const { details } = useItemDetails();
  
  // handle to click "Accept"
  const handleAcceptClick = () => {
    if (isReturn && role === 'Vendor') handleSend(true);
    else setOpen(false);
  };

  // handle to click "Decline"
  /* const handleDeclineClick = () => {
    if (isReturn && role === 'Vendor') handleSend(false);
    else setOpen(false);
  }; */

  // handle to click "Ship"
  const handleShipClick = () => {
    if (isReturn && role === 'Hospital') handleShip();
    else setOpen(false);
  };

  // handle to click "OK"
  const handleOKClick = () => {
    if (!isReturn && role === 'Vendor') handlerEditConfirm();
    else setOpen(false);
  };

  // handle to accept / decline to respond return request
  const handleSend = useCallback(async (decision) => {
    console.log("[ConfirmEdit] handleSend", decision, roleCid, data, details);

    // spinner
    setLoading(layoutDispatch);

    let res;
    if (details.length) {
      const defaultUser = userParty ? userParty : party;
      const ContractId = accountInventoryData.contractId;

      for (let i in details) {
        const inventorydata = details[i];

        // accept / decline return request
        res = await createReturnResponse({
          ledger, 
          ContractId: inventorydata.contractId ?? ContractId,
          inventorydata: inventorydata,
          newireturnrequestdecision: decision,
          newireturnrequestcomment: data.message,
          newireturnrequestdecisionuser: defaultUser,
          roleCid,
        });
      }

    }

    unsetLoading(layoutDispatch);

    if (res) {
      handleAlert(decision);
    }

    setOpen(false);

  }, [data, ledger, roleCid, layoutDispatch, details, setOpen, party, userParty, accountInventoryData, handleAlert]);

  // handle to accept edit request
  const handlerEditConfirm = useCallback(async () => {
    console.log("[ConfirmEdit] handlerEditConfirm", roleCid, data, details);

    // spinner
    setLoading(layoutDispatch);

    if (details.length) {
      let ContractId = accountInventoryData.contractId, res;

      for (let i in details) {
        const inventorydata = details[i];

        // accept edit request
        res = await acceptDeclineInventoryData({
          ledger, 
          ContractId: ContractId, 
          inventorydata: inventorydata,  
          roleCid,
        });
  
        if (res) {
          ContractId = res.contractId;
        }
      }

      setAccountInventoryData({
        ...accountInventoryData,
        ...res?.inventoryproduct,
        contractId: ContractId,
        inventorydata: res?.inventorydata,
      });

    }

    unsetLoading(layoutDispatch);

    setOpen(false);

  }, [data, ledger, roleCid, layoutDispatch, details, setOpen, accountInventoryData, setAccountInventoryData]);

  // handle to ship return request
  const handleShip = useCallback(async () => {

    setOpen(false);

  }, [setOpen]);


  if (isReturn && role === 'Vendor') return (
    <>
      {/* <Button 
        variant="contained" 
        color="primary" 
        onClick={handleDeclineClick} 
        className={classNames(classes.newCompleteBtn, "secondary-color")}  
      >
        Decline
      </Button> */}
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleAcceptClick} 
        className={classNames(classes.newCompleteBtn, classes2.btnCenter)} 
      >
        OK
      </Button>
    </>
  );
  if (isReturn && role === 'Hospital') return (
    <>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleShipClick} 
        className={classNames(classes.newCompleteBtn)} 
      >
        Ship
      </Button>
    </>
  );
  return (
    <>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleOKClick} 
        className={classNames(classes.newCompleteBtn)} 
      >
        OK
      </Button>
    </>
  );
};

/**
 * Confirm Edits & Confirm Returns Dialog
 * in Hospital, Vendor
 * @param {Boolean} open 
 * @param {Function} setOpen 
 * @param {Boolean} isReturn 
 * @returns 
 */
export default function ConfirmEdit ({
  open, setOpen, isReturn=true,
}) {

  const classes = useStyles();

  const { details, common } = useItemDetails();
  const { setComments } = useComment();

  const [data, setData] = useState({});
  // Alert
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("warning");
  const [alertMsg, setAlertMsg] = useState('');


  const handleAlert = (flag) => {
    if (flag) {
      setAlertMsg(`Return Request has been accepted. 
      The Facility has been alerted.`);
      setAlertType("success");
    }
    else {
      setAlertMsg(`You have declined the Return 
      request. The Facility has been 
      alerted.`);
      setAlertType("error");
    }
    setOpenAlert(true);
  };


  useEffect(() => {
    if (open) {
      console.log("[ConfirmEdit] Open ConfirmEdit dialog.", common);
      if (details.length) {
        if (common.ireturncomments && common.ireturncomments.length > 0) {
          setData({ 
            ...common, 
            'newireturntype': common.ireturncomments[common.ireturncomments.length-1].returntype, 
            'message': '', 
          });
          setComments(common.ireturncomments);
        }
        else{
          setData({ 
            ...common, 
            'newireturntype': '', 
            'message': '', 
          });
          setComments([]);
        }
      }
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <>
      <ModalDialog
        open={open}
        setOpen={setOpen}
        title={(
          <InvTitle
            title={isReturn ? "Return Request" : "Confirm Edits"}
          />
        )}
        content={(
          <InvPanel 
            data={data}
            setData={setData}
            isReturn={isReturn}
          />
        )}
        actions={(
          <InvActionButtons
            isReturn={isReturn}
            setOpen={setOpen}
            data={data}
            handleAlert={handleAlert}
          />
        )}
        width={824}
        height={653}
      />

      <SnackbarModal
        open={openAlert}
        setOpen={setOpenAlert}
        type={alertType}
        title=""
        content={alertMsg}
        vertical="bottom"
        horizontal="left"
        width={329}
        height={88}
        className={classes.toastSmall}
        transition="fade"
      />
    </>
  );
}
           
