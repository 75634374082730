var CryptoJS = require("crypto-js");

/**
 * create ID by using Hash Algorithm
 * sha256 (suffix + currentTime + index)
 * @param {String|null} oldID 
 * @param {String|null} suffix 
 * @param {Number|null} index 
 * @returns {String}
 */
export const createHashID = ({
    oldID=null, suffix=null, index=null
}) => {
    if (oldID && typeof oldID === 'string') return oldID;
    const datetime = new Date();
    const currentTime = datetime.toISOString();     // e.g. 2021-01-01T00:00:00Z
    if (!suffix) suffix = "";
    if (!index) index = 0;
    try {
        const data = `${suffix}${currentTime}${index}`;
        let appkey = "****************************************";

        let plaintext = CryptoJS.enc.Utf8.parse(data);
        let secSpec = CryptoJS.enc.Utf8.parse(appkey);    
        let ivSpec = CryptoJS.enc.Utf8.parse(appkey);

        secSpec = CryptoJS.lib.WordArray.create(secSpec.words.slice(0, 16/4));
        ivSpec = CryptoJS.lib.WordArray.create(ivSpec.words.slice(0, 16/4));

        var encrypted = CryptoJS.AES.encrypt(plaintext, secSpec, {iv: ivSpec});

        return encrypted.toString(); 
    }
    catch (e) {
        console.log("[createHashID] error", e);
    }
    return null;
};
