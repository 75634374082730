import React from "react";
import classNames from "classnames";
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { formatDate } from "../../services/util";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "./styles";
import { useTranslation } from 'react-i18next';


/**
 * ContractManagement Print
 * @param {Object} purchaseorderdata 
 * @param {Array} ContractManagementdetaildata 
 * @param {Array} ContractManagementcomment 
 * @param {String} hospital 
 * @returns 
 */
const ContractManagementPrint = ({
  purchaseorderdata={}, ContractManagementdetaildata=[], ContractManagementcomment=[], hospital, 
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const classes1 = useStyles1();


  return (
    <Grid container direction="column" className={classNames(classes.newPrint, classes1.newPrintPO)}>
      <Table className="details-form">
        <TableBody>
          <TableRow>
            <TableCell>PO number: {purchaseorderdata.poContractManagementid}</TableCell>
            <TableCell>PO Type: {purchaseorderdata.potype}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>PO Date: {formatDate(purchaseorderdata.podate)}</TableCell>
            <TableCell>Hospital: {hospital}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Table className="product-form">
        <TableHead>
          <TableRow>
            <TableCell>Ref No</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Distributor</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>{t('main.uom.lable')}</TableCell>
            <TableCell>Quantity</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            ContractManagementdetaildata.map((detail, i) => (
            <TableRow key={i}>
              <TableCell>{detail.podproduct.sepreferencenumber}</TableCell>
              <TableCell>{detail.podproduct.sepproductname}</TableCell>
              <TableCell>{detail.podproduct.sepproductdescription}</TableCell>
              <TableCell>{detail.podproduct.sepdistributor}</TableCell>
              <TableCell>{detail.podproduct.sepproducttype}</TableCell>
              <TableCell>${detail.podproduct.sepproductprice}</TableCell>
              <TableCell>{detail.podproduct.sepuom}</TableCell>
              <TableCell>{detail.podquantity}</TableCell>
            </TableRow>
            ))
          }
        </TableBody>
      </Table>
      
    </Grid>
  );
};

export default ContractManagementPrint;
