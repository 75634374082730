import React from "react";
import classNames from "classnames";
import { Link, Chip } from '@material-ui/core';
import { formatDate, validateDateTime } from "../../services/util";
import CallbackCell from "./CallbackCell";


function getByPath(data, path) {
    if (path.length === 0) return data;
    if (data[path[0]] === undefined) {
        throw new Error("Object doesn't have key '" + path[0] + "': " + JSON.stringify(data));
    }
    const value = getByPath(data[path[0]], path.slice(1));
    return value;
}

export function setByPath(value, path, defaultValue={}) { 
    var obj = {...defaultValue};
    var schema = obj;  // a moving reference to internal objects within obj
    var pList = path.split('.');
    var len = pList.length;
    for(var i = 0; i < len-1; i++) {
        var elem = pList[i].trim();
        if (!schema[elem]) {
            var nextVal = {};
            if (i < len-2) {
                var nextElem = pList[i+1].trim();
                if (!isNaN(nextElem) && parseInt(nextElem).toString() === nextElem) nextVal = [];
            }
            if (!isNaN(elem) && parseInt(elem).toString() === elem) elem = parseInt(elem);
            schema[elem] = nextVal;
        }
        schema = schema[elem];
    }
    schema[pList[len-1]] = value;
    return obj;
}
  
export function getValue(data, path, option={}) {
    // console.log("[Contracts] getValue", path, data);

    const splits = typeof path === "string" && path !== "" ? path.split(".") : [];
    let value = option.defaultValue || "";

    try {
        value = getByPath(data, splits);
        if (!option.isString) value = formatDate(value);
    }
    catch(e) {
        // console.warn(e);
    }
    // console.log("[Contracts] getValue", path, value);

    return value;
}

/**
 * render cell of Contracts & TreeContracts
 * @param {Object} option 
 * @returns 
 */
export function renderCell(option) {
    // console.log("[Contracts] renderCell option", option);
    if (option) {
        // beforeText & afterText
        const beforeText = (option.options && option.options.beforeText) ? option.options.beforeText : "";
        const afterText = (option.options && option.options.afterText) ? option.options.afterText : "";
        // console.log("[Contracts] renderCell", beforeText, afterText);

        // according to type
        if (option.type === "mail") return (param) => (
            <a href={"mailto:" + param.value}>{beforeText}{param.value}{afterText}</a>);
        else if (option.type === "object") {
            if (typeof value === "object") return (param) => (<>{beforeText}{param.value}{afterText}</>);
        }
        else if (option.type === "link") return (param) => (
            <Link component="button" variant="body2" onClick={() => {
                if (typeof option.onClick === 'function') option.onClick(param.row.id, param.row);
            }} >
                {beforeText}{!!param.value && param.value}{afterText}
            </Link>);
        else if (option.type === "chip") return (param) => (<Chip
                label={param.value}
                size="small"
                className={(option.className && typeof option.className === 'function') ?
                    classNames(option.className(param.value)) : null}
            />);
        else if (option.type === "render") return option.render;
        else if (option.type === "callback") return (param) => (<>
            {beforeText}
            <CallbackCell 
                callback={option.callback} 
                initValue={param.value}
            />
            {afterText}
        </>);
        else return (param) => (<>{beforeText}{param.value}{afterText}</>);
    }
    return (param) => (param.value);
}

export function getValues(data, path, option) {
    // console.log("[Contracts] getValues", path, data);
    return path.map((p, i) => getValue(data, p, option)).join(" ");
}

export function getValueByCol(data, path, option) {
    // console.log("[Contracts] getValueByCol", data, path, option);
    return (typeof path === "object") ?
        (typeof option === "undefined" ? getValues(data, path) : getValues(data, path, option))
        :
        (typeof option === "undefined" ? getValue(data, path) : getValue(data, path, option));
}

export function getStrFromAction(action) {
    if (typeof action === "string") return action;
    else if (typeof action === "object" && action.length > 0 && typeof action[0] === "string") return action[0];
    else return "";
}
export function getObjFromAction(action) {
    if (typeof action === "object" && action.length > 1 && typeof action[1] === "object") return action[1];
    else return null;
}
export function getFuncFromAction(action) {
    if (typeof action === "object" && action.length > 2 && typeof action[2] === "function") return action[2];
    else return null;
}

/**
 * sort for contracts by any key
 * @param {Array} contracts 
 * @param {String} sortKey 
 * @returns {Array}
 */
export function sortContracts(contracts, sortKey) {
    if (typeof contracts === "object" && contracts.length) {
        const val = getValue(contracts[0], sortKey, {isString: true});
        // console.log("[sortContracts]", val, sortKey, validateDateTime(val));
        if (val) {
            let getVal = getValue;
            if (validateDateTime(val)) getVal = (data, key) => (new Date(getValue(data, key, {isString: true})));
            // sort by desc
            return contracts.sort((a, b) => {
                const aVal = getVal(a, sortKey, {isString: true});
                const bVal = getVal(b, sortKey, {isString: true});
                // console.log("[sortContracts]", aVal, bVal, (bVal > aVal), (bVal < aVal));
                if (bVal > aVal) return 1;
                if (bVal < aVal) return -1;
                return 0;
            });
        }
        return contracts;
    }
    return [];
}
