import React, { useState, useEffect, Fragment } from "react";
import {
  Button, Dialog, DialogContent, DialogTitle, DialogActions, Typography, Grid, Box, Chip
} from "@material-ui/core";
import ErrorDialog from '../../components/Modals/GeneralModal/error-dialog';
import useStyles from "./styles";
import useStyles1 from "../surgicalevent/styles";
import TextField from '@mui/material/TextField';
import { getSubModel } from "../../models/handles";
import { Paytype } from "../../models/HospitalDetail";
import { setByPath } from "../../components/Contracts/functions";
import { CustomSelect, CustomTextField } from "../../components/Inputs";
import closeIcon from "../../icons/new-close-icon.svg";
import closeIconDM from "../../icons/new-close-icon-dm.svg";
import { useDamlState } from "../../context/DamlContext";
import moment from "moment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { usePaymentDateCalculator } from './utills';
import classNames from "classnames";
import FileUploadDropzone, { handlePreviewIcon } from "../../components/FileUpload/FileUploadDropzone";
import folderIcon from "../../icons/mdi_folder-upload.svg";
import viewFile from "../../icons/view_file.svg";
import downloadFile from "../../icons/download.svg";
import viewFileDM from "../../icons/view_file-dm.svg";
import downloadFileDM from "../../icons/download-dm.svg";
import { NODE_SERVER_ENDPOINT } from "../../config";
import ModalDialog from '../../components/Modals/NewGeneralModal';
import { InputDistributor } from "../surgicalevent/edit-product1";

export default function CreatePayment({
  openModal, setOpenModal, handleModifyNote,
  handleUpdate, options, readOnlyData, setReadOnlyFlow,
}) {
  const classes = useStyles();
  const classes1 = useStyles1();

  options = {
    readOnly: false,
    className: null,
    ...options,
  };

  const classNameList = [];
  if (options.className) classNameList.push(options.className);

  console.log('options', options, readOnlyData);

  const [payload, setPayload] = useState();

  // new added code
  const [confirmInvoiceId, setConfirmInvoiceId] = useState();
  const [confirmAmount, setConfirmAmount] = useState();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const dateCalculator = usePaymentDateCalculator();

  const handleNewConfirmAmount = (e) => {
    if (e === payload?.totalamount) {
      setSuccess1(true);
      return null
    }
  }

  const handleNewConfirmInvoiceId = (e) => {
    if (e === payload?.invoicenumber) {
      setSuccess(true);
      return null
    }
  }

  const handleConfirmInvoiceId = () => {
    if (confirmInvoiceId === payload?.invoicenumber) {
      return true
    } else {
      setSuccess(false);
      return false
    }
  };
  const handleConfirmAmount = () => {
    if (confirmAmount === payload?.totalamount) {
      return true;
    } else {
      setSuccess1(false);
      return false;
    }
  };

  const { rolePayload } = useDamlState();

  const [errorModal, setErrorModal] = useState(false);
  const [success, setSuccess] = useState('');
  const [success1, setSuccess1] = useState('');
  const [openDoc, setOpenDoc] = useState(false);
  const [fileLink, setFileLink] = useState("");

  const handleCancel = () => {
    if (!options.readOnly) {
      setSuccess("");
      setSuccess1("");
      setOpenModal(false);
      setPayload({});
      setConfirmInvoiceId('');
      setConfirmAmount('');
    } else {
      setOpenModal(false);
      setReadOnlyFlow(false);
    }
  };

  const style = (success) => {
    if (success === true) {
      return (classes.custSuccessSelection);
    } else if (success === false) {
      return (classes.custFailSelection);
    }
    return null;
  }

  const getColorCode = (value) => {
    switch (value) {
      case 'Sent':
        return classes1.backGreen;
      case 'Ready for payment':
        return classes1.backBlue;
      case 'Complete':
        return classes1.backGreen;
      case 'Available for payment':
        return classes1.backBlue;
      case 'Cancelled':
        return classes1.backOrange;
      default:
        return classes1.backYellow;
    }
  }

  const [paymentOffset, setPayemtOffset] = useState({ offset: 0, referalDate: new Date() })
  const setPaymentDueOffset = (paymentType) => {
    debugger

    const currentHour = new Date().getHours();
    const currentMinutes = new Date().getMinutes();
    let offset = 0;
    let dateOffset = new Date();
    if (!(currentHour < 16 || (currentHour === 16 && currentMinutes < 30))) {
      let tomorrow = new Date()
      tomorrow.setDate(dateOffset?.getDate() + 1)
      dateOffset = tomorrow;
    }
    switch (paymentType) {
      case 'VCard':
        offset = 1
        break;
      default:
        offset = 2
        break;
    }
    setPayemtOffset({ offset, referalDate: dateOffset })
  }


  const handleFile = async (file) => {
    const response = (await ((await fetch(NODE_SERVER_ENDPOINT + `api/sap-getsignedurls/${file}`, {
      method: 'GET',
    })).json())).data
    if (file.includes(".pdf") || file.includes(".jpg") || file.includes(".png")) {
      setFileLink(response)
      setOpenDoc(true);
    }
    else
      window.open(response);
  }
  useEffect(() => {
    if (options.readOnly) {
      setPayload({ note: readOnlyData?.payload?.note })
    } else {
      setPayload({})
    }
  }, [readOnlyData, options.readOnly])


  return (
    <>
      <Dialog
        open={openModal}
        className={options.layoutState.darkMode?classes.customRootDM:classes.customRoot}
        disableEnforceFocus
        aria-labelledby="draggable-dialog-title"
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >

          <Button
            className={classes.closeButton}
            onClick={handleCancel}
          >
            <img src={options.layoutState.darkMode?closeIconDM:closeIcon} alt="" />
          </Button>
        </Grid>
        <DialogTitle className='MuiDialogTitleRoot' style={{borderBottom:options.layoutState.darkMode?"1px solid #FFFFFF":""}}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            className={classes.newDIATitlePanel}
          >
            <Typography variant="inherit" component="span" className={options.layoutState.darkMode?classes.popUpTitleDM:classes.popUpTitle}>
              Payment
            </Typography>
            {
              options.readOnly &&
              <>
                <Typography variant="inherit" component="span" className={options.layoutState.darkMode?classes.secondaryTitleDM:classes.secondaryTitle}>
                  {readOnlyData?.payload?.paymentnumber}
                </Typography>
                <Typography variant="inherit" component="span" className={classes.chipTitle}>
                  <Chip
                    label={readOnlyData?.payload?.status}
                    size="small"
                    className={classNames(classes1.smallChip, getColorCode(readOnlyData?.payload?.status))}
                  />
                </Typography>
              </>
            }
          </Grid>

        </DialogTitle>
        <DialogContent
          dividers={true}
        >
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="center"
            className={options.readOnly ? classNames(classNameList, classes.labelPaddings) : classNames(classNameList)}
          >
            <Grid item xs={4}>
              <label className={options.layoutState.darkMode?classes.popUpLabelDM:classes.popUpLabel}>Distributor</label>
            </Grid>
            <Grid item xs={8}>
              {/* {
                options.readOnly
                  ? <label className={options.layoutState.darkMode?classes.readOnlyFontDM:classes.readOnlyFont}> {readOnlyData?.payload?.vendor} </label>
                  : <CustomSelect
                    warning={payload?.vendor === "" ? " " : ""}
                    items={vendors}
                    value={payload?.vendor}
                    placeholder="Select One"
                    width={'84%'}
                    onChange={(e) => {
                      setPayload(setByPath(e, 'vendor', payload))
                      setPaymentDueOffset(e);
                    }}
                    options={{
                      disableHelper: true,
                    }}
                  />
              } */}
              <InputDistributor
                label={readOnlyData?.payload?.vendor}
                distributor={payload?.vendor}
                setDistributor={(val) => {
                  setPayload(setByPath(val, 'vendor', payload))
                  setPaymentDueOffset(val);
                }}
                placeholder="Select One"
                warning={payload?.vendor === "" ? " " : ""}
                // width={'84%'}
                readOnly={options.readOnly}
                options={{
                  disableHelper: true,
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <label className={options.layoutState.darkMode?classes.popUpLabelDM:classes.popUpLabel}>Type</label>
            </Grid>
            <Grid item xs={8}>
              {
                options.readOnly
                  ? <label className={options.layoutState.darkMode?classes.readOnlyFontDM:classes.readOnlyFont}> {readOnlyData?.payload?.paymenttype} </label>
                  :
                  <CustomSelect
                    warning={payload?.paymenttype === "" ? " " : ""}
                    items={getSubModel(Paytype, rolePayload?.hospitaldetails?.ach_info?.paymethod)}
                    value={payload?.paymenttype}
                    placeholder="Select One"
                    width={'84%'}
                    onChange={(e) => {
                      setPayload(setByPath(e, 'paymenttype', payload))
                      setPaymentDueOffset(e);
                    }}
                    options={{
                      disableHelper: true,
                    }}
                  />
              }
            </Grid>

            {
              [
                {
                  label: 'Invoice ID', value: payload?.invoicenumber, onChange: (e) => setPayload(setByPath(e, 'invoicenumber', payload)), warning: payload?.invoicenumber === "" ? " " : "", type: "text",
                  confirmlabel: 'Confirm Invoice', confirmvalue: confirmInvoiceId, confirmOnChange: (e) => {
                    setConfirmInvoiceId(e);
                    setSuccess(false);
                    if (handleNewConfirmInvoiceId(e)) setSuccess(true);
                  }, style: style(success), readonlydata: readOnlyData?.payload?.invoicedata?.invinvoicenumber
                },
                {
                  label: 'Amount', value: payload?.totalamount, onChange: (e) => setPayload(setByPath(e, 'totalamount', payload)), warning: payload?.totalamount === "" ? " " : "", type: "number",
                  confirmlabel: 'Confirm Amount', confirmvalue: confirmAmount, confirmOnChange: (e) => {
                    setConfirmAmount(e);
                    setSuccess1(false);
                    if (handleNewConfirmAmount(e)) setSuccess1(true);
                  }, style: style(success1), readonlydata: readOnlyData?.payload?.invoicedata?.invinvoicetotalamount
                },
              ].map((item, index) => {
                return (<Fragment key={index}>
                  <Grid item xs={4}>
                    <label className={options.layoutState.darkMode?classes.popUpLabelDM:classes.popUpLabel}>{item.label}</label>
                  </Grid>
                  <Grid item xs={8}>
                    {
                      options.readOnly
                        ? <label className={options.layoutState.darkMode?classes.readOnlyFontDM:classes.readOnlyFont}> {item.readonlydata} </label>
                        :
                        <CustomTextField
                          type={item.type}
                          warning={item.warning}
                          value={item.value}
                          options={{
                            disabled: false,
                            disableHelper: true,
                          }}
                          width={'84%'}
                          onChange={item.onChange}
                        />
                    }
                  </Grid>

                  {
                    options.readOnly
                      ?
                      null
                      :
                      <>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={3}>
                          <label className={classNames(options.layoutState.darkMode?classes.confirmLabelDM:classes.confirmLabel, classes.newFont)}>{item.confirmlabel}</label>
                        </Grid>
                        <Grid item xs={8}>
                          <CustomTextField
                            type={item.type}
                            className={item.style}
                            value={item.confirmvalue}
                            onChange={item.confirmOnChange}
                            options={{ disabled: false }}
                            width={'84%'}
                          />
                        </Grid>
                      </>
                  }
                </Fragment>)
              })
            }

            {
              options.readOnly &&
              <>
                <Grid item xs={4}>
                  <label className={options.layoutState.darkMode?classes.popUpLabelDM:classes.popUpLabel}>Payment Sent</label>
                </Grid>
                <Grid item xs={6} className={classes.positionRelative}>
                  <label className={options.layoutState.darkMode?classes.readOnlyFontDM:classes.readOnlyFont}> {readOnlyData?.payload?.paymentinitiationdate} </label>
                </Grid>
                <Grid item xs={2}></Grid>

              </>
            }


            <Grid item xs={4}>
              <label className={options.layoutState.darkMode?classes.popUpLabelDM:classes.popUpLabel}>Payment Due</label>
            </Grid>
            <Grid item xs={6} className={classes.positionRelative}>
              {
                options.readOnly
                  ?
                  <label className={options.layoutState.darkMode?classes.readOnlyFontDM:classes.readOnlyFont}> {readOnlyData?.payload?.paymentdate} </label>
                  :
                  <>
                    {/* <CustomSelect
                      className={classes.customSelection}
                      width='93.1%'
                      items={{
                        Pay_now: 'Pay now',
                        thirty_days: '30 days',
                        sixty_days: '60 days'
                      }}
                      value={payload?.paymentdate}
                      placeholder=""
                      onChange={updateDueDate}
                    /> */}

                    <LocalizationProvider dateAdapter={AdapterDateFns} >
                      <DesktopDatePicker
                        PopperProps={{
                          sx: options.layoutState.darkMode?{
                            "& .MuiCalendarPicker-root":{
                              backgroundColor:"#1c3c74 !important",
                              color:"#F7CD37",
                            },
                            "& .MuiPickersDay-root": {
                              color:"#FFFFFF",
                              backgroundColor:"#1c3c74",
                              "&.Mui-selected": {
                                backgroundColor: "#F7CD37 !important",
                                color:"#1c3c74 !important"
                              },
                            },
                            "& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel":{
                              color:"#F7CD37 !important"
                            }
                          }:{}
                        }}
                        className={options.layoutState.darkMode?classes.buttongrpDM:classes.buttongrp}
                        showToolbar={false}
                        shouldDisableDate={dateCalculator?.isOffDay}
                        inputFormat="MM/dd/yyyy"
                        value={moment(payload?.paymentdate).utc()}
                        minDate={dateCalculator?.tydeiDateCalculator(paymentOffset.referalDate, paymentOffset.offset, true)}
                        onChange={(newDate) => {
                          setPayload(setByPath(moment(newDate).format('yyyy-MM-DD'), 'paymentdate', payload));
                        }}
                        renderInput={(params) => <TextField disabled className={classes.customDateField} size="small" {...params} />}
                      />
                    </LocalizationProvider>
                  </>
              }
            </Grid>
            <Grid item xs={2}></Grid>

            <Grid item xs={4}>
              <label className={classNames(options.layoutState.darkMode?classes.noteTitleDM:classes.noteTitle)}>Note</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                className={options.layoutState.darkMode?classes.noteFieldDM:""}
                placeholder="Leave note here."
                multiline
                rows={2}
                inputProps={{ style: { color: options.layoutState.darkMode?"white":"" } }}
                style={{ width: '87%' }}
                value={payload?.note}
                onChange={(e) => {
                  setPayload(setByPath(e.target.value, 'note', payload))
                }}
              />
            </Grid>

            <Grid item xs={2}>
              <label className={classes.noteTitle}></label>
            </Grid>
            <Grid item xs={options.readOnly ? 6 : 10}>
              {
                options.readOnly ?
                  <div className={options.layoutState.darkMode?classes.viewFileDM:classes.viewFile}>
                    {
                      readOnlyData.payload.fileName.map(f => <div key={f} className={classes.singleFile}>
                        <div>
                          {f.slice(30).length < 30 ? (f.slice(30)) : (f.slice(30, 60) + "...")}
                        </div>
                        <div >
                          {
                            (f.includes(".pdf") || f.includes(".jpg") || f.includes(".png")) ?
                              <img style={{ cursor: "pointer" }} src={options.layoutState.darkMode?viewFileDM:viewFile} onClick={() => handleFile(f)} height={15} width={15} alt="view file" /> :
                              <img style={{ cursor: "pointer" }} src={options.layoutState.darkMode?downloadFileDM:downloadFile} onClick={() => handleFile(f)} height={15} width={15} alt="download file" />
                          }
                        </div>
                      </div>)
                    }
                  </div> :
                  <FileUploadDropzone
                    className={options.layoutState.darkMode?classes.uploadFileStyleDM:classes.uploadFileStyle}
                    title=""
                    uploadLabel={(
                      <Box className="drag-drop-btn">
                        Drag and Drop file to upload
                        <img src={folderIcon} alt="" />
                      </Box>
                    )}
                    changeFile={() => { }}
                    isNonePadding={true}
                    isEnableUpload={false}
                    handlePreviewIcon={handlePreviewIcon}
                    setUploadedFiles={setUploadedFiles}
                    filesLimit={3}
                  />
              }
            </Grid>
            <Grid item xs={12}>
              {
                fileLink !== "" &&
                <ModalDialog
                  open={openDoc}
                  setOpen={setOpenDoc}
                  content={
                    <object data={fileLink} style={{ width: "68.5vw", height: "91vh" }}>Alternative Text</object>
                  }
                />
              }
            </Grid>

          </Grid>
          <DialogActions className={classes.actionbtns}>
            {
              options.readOnly &&
              <>
                <Button variant="contained" className={classes.secondarybtn}
                  onClick={handleCancel}>
                  Cancel
                </Button><Button variant="contained" className={options.layoutState.darkMode?classes.primarybtnDM:classes.primarybtn}
                  onClick={() => {
                    if (readOnlyData?.payload.note !== payload?.note) {
                      handleModifyNote(readOnlyData?.payload, payload?.note);
                      setOpenModal(false);
                    }
                    else
                      setOpenModal(false);
                  }}>
                  Save
                </Button>
              </>
            }
            {
              !options.readOnly &&
              <>
                <Button variant="contained" className={options.layoutState.darkMode?classes.primarybtnDM:classes.primarybtn}
                  onClick={() => {
                    if (payload.vendor === undefined || payload.vendor === "")
                      setPayload({ ...payload, vendor: "" })
                    else if (payload.paymenttype === undefined || payload.paymenttype === "")
                      setPayload({ ...payload, paymenttype: "" })
                    else if (!!options.paymentContracts.find(c => c.payload.invoicedata.invinvoicenumber === payload.invoicenumber)) {
                      options.setmsgType("error");
                      options.setOpenToastNotification(true);
                      options.setToastContent("Invoice id already exist");
                    }
                    else if (payload.invoicenumber === undefined || payload.invoicenumber === "")
                      setPayload({ ...payload, invoicenumber: "" })
                    else if (payload.invoicenumber !== confirmInvoiceId)
                      setSuccess(false)
                    else if (payload.totalamount === undefined || payload.totalamount === "")
                      setPayload({ ...payload, totalamount: "" })
                    else if (payload.totalamount !== confirmAmount)
                      setSuccess1(false)
                    else if (payload.paymentdate === undefined);
                    else if (handleConfirmInvoiceId() && handleConfirmAmount()) {
                      handleUpdate({ ...setByPath(moment(dateCalculator.tydeiDateCalculator(new Date(payload.paymentdate), paymentOffset.offset, false)).format('yyyy-MM-DD'), 'paymentinitiationdate', payload), fileName: uploadedFiles });
                      setPayload({});
                      setConfirmInvoiceId('');
                      setConfirmAmount('');
                      setSuccess("");
                      setSuccess1("");
                    }
                  }}>
                  Pay
                </Button>
              </>
            }

          </DialogActions>
        </DialogContent>
      </Dialog>
      <ErrorDialog
        openModal={errorModal}
        setOpenModal={setErrorModal}
      />
    </>
  );
}
