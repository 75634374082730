import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions,
  FormControl, InputLabel, TextareaAutosize, TextField } from "@material-ui/core";
import { useLayoutDispatch, setLoading, unsetLoading } from "../../context/LayoutContext";
import  AddEditProduct  from "../surgicalevent/add-edit-product";
import { requiredProduct, isEmptyProduct, /* combineProduct */ } from "../../models/Product";
import { createInventoryData } from "../../models/InventoryData";
import { useDamlState } from '../../context/DamlContext';
import useStyles from "../surgicalevent/styles";

/**
 * Edit Inventory Dialog
 * Account Inventory in House of Hospital
 * @param {Boolean} openModal
 * @param {Function} setOpenModal
 * @param {String} hospital 
 * @param {Function} addProductItem
 * @param {String} title
 * @param {Object} inventoryData
 * @param {Function} setInventoryData
 * @param {String} btnEdit
 */
export default function EditInventory ({
  openModal, setOpenModal, hospital,
  addProductItem, title="Edit Inventory",
  inventoryData={}, setInventoryData, btnEdit="Save",
}) {

  // console.log("[EditInventory]", inventoryData);
    
  const classes = useStyles();

  const { party, userParty } = useDamlState();
  const layoutDispatch = useLayoutDispatch();

  const [newInventoryData, setNewInventoryData] = useState({});
  const [productItems, setProductItems] = useState({});
  const [productError, setProductError] = useState({});


  // Click "Save"
  const addInventory = async (event) => {
    let newErrors, tempInventoryData;
    if (!isEmptyProduct(productItems)) {
      // check required fields in productItems
      newErrors = requiredProduct(productItems);
      // to skip 'sepdistributor', 'sepownership' in productItems
      // delete newErrors.sepdistributor;
      console.log("[EditInventory] addInventory", productItems, newErrors);
      if ((Object.keys(newErrors).length > 0)) {
        setProductError({ ...newErrors });
        return false;
      }

      tempInventoryData = createInventoryData({
        ...newInventoryData, 
        iproduct: productItems, 
        itemprequestuser: userParty ? userParty : party,
      });
      console.log("[EditInventory] addInventory", tempInventoryData);
    }

    setProductError({});

    // spinner
    setLoading(layoutDispatch);

    await addProductItem(tempInventoryData);
    
    unsetLoading(layoutDispatch);
  };

  const handleCancel = () => {
    setOpenModal(false);
    unsetLoading(layoutDispatch);
  };

  useEffect(() => {
    if (openModal) {
      console.log("[EditInventory] Open EditInventory dialog.");
      setNewInventoryData({...inventoryData});
      // setProductItems(combineProduct(inventoryData.iproduct, inventoryData.itempproduct));
      setProductItems(inventoryData.iproduct);
      setProductError({});
    }
  }, [openModal, inventoryData]);
 

  return (
      <Dialog
        open={openModal}
        className={classes.customRoot}
        aria-labelledby="draggable-dialog-title"
        classes={{paper: classes.overflowHidden}}
      >
       <div className={classes.purchaseContentModal}> 
        <DialogTitle style={{ cursor: 'move', borderBottom: '1px solid #cccccc' }} id="draggable-dialog-title">
          <div className="view-heading">{title}</div> 
        </DialogTitle>
        <DialogContent className={classes.poContentModal}>
          <DialogContentText  component={'div'}>
            <div className="poModel"> 

              <AddEditProduct
                isCreate = {true}
                productItemsList ={[]}
                productItems = {productItems}
                productError= {productError}
                setProductError={setProductError}
                setProductItems = {setProductItems}
                hospital={hospital}
                options={{
                  isEnableEditProduct: false,
                  isEnableDeleteProduct: false,
                  showInfoAlert: false,
                  topMargin: 20,
                  edit: {
                    isEnableScan: false,
                    isEnableRetrieve: false,
                    isEnableCompliance: false,
                    isInventory: true,
                    hiddenParams: [
                      'sepudi',
                      'sepproductname',
                      'sepproductdescription',
                      'sepproducttype',
                      'sepproductwaste',
                      'sepproductside',
                    ],
                    disableParams: [
                      'sepreferencenumber',
                      // 'sepownership', // enable to change ownership at the new version
                      'sepdistributor', // can't change distributor since it should be same as vendor of inventory
                    ],
                  },
                }}
              
              />
            </div>
            
            <div className={classes.marginBM30}>
              <TextField
                label="PO Number"
                placeholder=""
                className={classes.customFullWidth}
                margin="normal"
                value={newInventoryData.itempponumber || ""}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  setNewInventoryData({
                    ...newInventoryData,
                    itempponumber: e.target.value, // old case: ipurchaseordernumber
                  });
                }} 
                variant="outlined"
              />
            </div>
            <div className={classes.marginBM30}>
              <FormControl variant="outlined" className={classes.formControl}>
                <div>
                  <InputLabel className={classes.customLabel} shrink={true}>
                    Request Description
                  </InputLabel>
                  <TextareaAutosize
                    id="input-with-icon-adornment1"
                    label="Request Description"
                    placeholder=""
                    value={newInventoryData.itemprequestmessage || ""}
                    className={classes.customFullWidthTextarea}
                    margin="normal"
                    onChange={(e) => {
                      setNewInventoryData({
                        ...newInventoryData,
                        itemprequestmessage: e.target.value,
                      });
                    }}
                    variant="outlined"
                  />
                </div> 
              </FormControl>   
            </div>
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="form-footer">
            <div className="right-buttons">
              <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
              <Button variant="contained" color="primary" onClick={addInventory} >{btnEdit}</Button> 
            </div>
          </div>
        </DialogActions>
        </div>

      </Dialog>
  );
}