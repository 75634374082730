import React from "react";
import { FormControlLabel, Switch, Tooltip } from "@material-ui/core";
import { styled } from '@material-ui/styles';
import { useStyles } from "./styles";


const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 18,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 0,
    transform: 'translateX(18px)',
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(0px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 18,
  },
  '& .MuiSwitch-track': {
    borderRadius: 18 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


/**
 * Custom Checkbox
 * @param {Boolean} checked 
 * @param {Function} setChecked 
 * @param {String} label 
 * @param {String} title 
 * @param {Object} classnames e.g. {switch: null, label: null, tooltip: null}
 * @returns 
 */
const CustomCheckbox = ({
  checked, setChecked, label, title, classnames={}, 
}) => {

  const classes = useStyles();

  const handleSelected = () => {
    if (typeof setChecked === 'function') setChecked(!checked);
  };

  return (
    title 
    ?
    <Tooltip
      placement="top-end"
      title={title}
      classes={{ popper: classes.tooltip, tooltipPlacementTop: classes.tooltipPlacementTop }}
      leaveDelay={500}
    >
      <FormControlLabel
        control={
          <IOSSwitch
            checked={checked}
            onChange={() => handleSelected()}
            className={classnames.switch}
          />
        }
        label={label}
        className={classnames.label}
      />
    </Tooltip>
    :
    <FormControlLabel
      control={
        <IOSSwitch
          checked={checked}
          onChange={() => handleSelected()}
          className={classnames.switch}
        />
      }
      label={label}
      className={classnames.label}
    />
  );
};

export default CustomCheckbox;