import { HospitalRole, VendorRole } from "../../services/daml-modules1";
import { getTemplateListFromResponse } from "../../services/util";
import { loggerPush, LOGGER_INFO, LOGGER_ERROR } from '../../services/logger';
import { validateInventoryData, getInventoryData, validateInventoryProductStatus } from "../../models/InventoryData";


/**
 * confirm Restock InventoryData in Hospital
 * exercise ChangeRestockInventoryStatus choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} inventoryCid 
 * @param {Object} inventorydata 
 * @param {String} updateddate 
 * @param {Number} inventoryindex 
 * @param {String} roleCid 
 * @param {Object} userlistPayload
 * @param {String} newiproductstatus
 * @returns {Boolean}
 */
export const confirmRestockInventoryHospital = async ({
  ledger, inventoryCid, inventorydata, updateddate=null, inventoryindex, roleCid,userlistPayload, newiproductstatus, 
}) => {
  const email = userlistPayload?.email;
  const username = userlistPayload?.name;

  try {
    if (roleCid && validateInventoryData(inventorydata)) {

      if (!inventoryindex) inventoryindex = inventorydata.index;

      // old choice ChangeRestockInventoryStatus
      let res = await ledger.exercise(HospitalRole.ChangeRestockInventoryStatusWithLog, roleCid, {
        inventorycid: inventoryCid,
        oldinventorydata: getInventoryData(inventorydata),
        oldinventoryindex: inventoryindex,
        updateddate,
        useremail: email,
        username: username,
        newiproductstatus,
      });

      // log the response to loggly
      loggerPush({ type: LOGGER_INFO, action: 'Inven: ChangeRestockInventoryStatus', response: res });

      // get contractId of the created Inventory
      let templatePO = getTemplateListFromResponse(res, "RestockInventory");
      if (templatePO.length) {
        return templatePO[0].contractId;
      }
    }
  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Inven: ChangeRestockInventoryStatus', payload: e, params: {inventorydata, updateddate} });
  }

  return false;

};

/**
 * change Restock Inventory Shipment with selected InventoryData
 * exercise ChangeInventoryStatusV choice of VendorRole
 * @param {Object} ledger 
 * @param {String} hospital 
 * @param {String} inventoryCid 
 * @param {Object} inventorydata 
 * @param {String} newiproductstatus 
 * @param {String} updateddate
 * @param {Number} inventoryindex 
 * @param {String} roleCid 
 * @param {Object} userlistPayload
 * @returns {Boolean}
 */
export const changeInventoryShipment = async ({
  ledger, hospital, inventoryCid, inventorydata, newiproductstatus, updateddate=null, inventoryindex, roleCid,userlistPayload,
}) => {
  const email = userlistPayload?.email;
  const username = userlistPayload?.name;
  
  try {
    if (roleCid && validateInventoryProductStatus(newiproductstatus) && validateInventoryData(inventorydata)) {

      if (!inventoryindex) inventoryindex = inventorydata.index;
      // old choice ChangeInventoryStatusV
      let res = await ledger.exercise(VendorRole.ChangeInventoryStatusVWithLog, roleCid, {
        hospital,
        inventorycid: inventoryCid,
        oldinventorydata: getInventoryData(inventorydata),
        oldinventoryindex: inventoryindex,
        newiproductstatus,
        updateddate,
        useremail: email,
        username: username,
      });
     

      // log the response to loggly
      loggerPush({ type: LOGGER_INFO, action: 'Inven: ChangeInventoryStatusV', response: res });

      // get contractId of the created Inventory
      let templatePO = getTemplateListFromResponse(res, "RestockInventory");
      if (templatePO.length) {
        return true;
      }
    }
  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Inven: ChangeInventoryStatusV', payload: e, params: {inventorydata, newiproductstatus} });

  }

  return false;

};

/**
 * Update Restock Inventory Notes by Hospital
 * exercise HRestockUpdateNotes choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} roleCid
 * @param {String} inventorycid 
 * @param {String} newnotes 
 */
export const updateRestockNotesHospital = async ({
  ledger, roleCid, inventorycid, newnotes, 
}) => {

  try {

    if (roleCid && inventorycid) {
      let res = await ledger.exercise(HospitalRole.HRestockUpdateNotes, roleCid, {
        inventorycid,
        newnotes,
      });

      // log the response to loggly
      loggerPush({ type: LOGGER_INFO, action: 'Inven: HRestockUpdateNotes', response: res });

      return true;
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Inven: HRestockUpdateNotes', payload: e, params: {newnotes, inventorycid} });
    console.warn("[updateRestockNotesHospital]", e);
  }

  return false;
};

/**
 * Update Restock Inventory Notes by Vendor
 * exercise VRestockUpdateNotes choice of VendorRole
 * @param {Object} ledger 
 * @param {String} roleCid
 * @param {String} inventorycid 
 * @param {String} newnotes 
 */
export const updateRestockNotesVendor = async ({
  ledger, roleCid, inventorycid, newnotes, 
}) => {

  try {

    if (roleCid && inventorycid) {
      let res = await ledger.exercise(VendorRole.VRestockUpdateNotes, roleCid, {
        inventorycid,
        newnotes,
      });

      // log the response to loggly
      loggerPush({ type: LOGGER_INFO, action: 'Inven: VRestockUpdateNotes', response: res });

      return true;
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Inven: VRestockUpdateNotes', payload: e, params: {newnotes, inventorycid} });
    console.warn("[updateRestockNotesVendor]", e);
  }

  return false;
};

/**
 * Archive Restock Inventory by Hospital
 * exercise HArchiveRestockInventory choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} roleCid
 * @param {String} inventorycid 
 */
export const archiveRestockInventory = async ({
  ledger, roleCid, inventorycid, 
}) => {

  try {

    if (roleCid && inventorycid) {
      let res = await ledger.exercise(HospitalRole.HArchiveRestockInventory, roleCid, {
        inventorycid,
      });

      // log the response to loggly
      loggerPush({ type: LOGGER_INFO, action: 'Inven: HArchiveRestockInventory', response: res });

      return true;
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Inven: HArchiveRestockInventory', payload: e, params: {inventorycid} });
    console.warn("[archiveRestockInventory]", e);
  }

  return false;
};
