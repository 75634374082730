import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { useLedger, useParty } from "@daml/react";
import { Chip } from "@material-ui/core";
import { /* SurgicalEvent, */ SurgicalEventHistory } from "../../services/daml-modules1";
import AdaptiveSearch from "../../components/Search/adaptivesearch";
import DateSearch from "../../components/Search/datesearch";
import SelectSearch from "../../components/Search/selectsearch";
import { /* getState, */ dispatch } from "../../store";
import { surgicalEventSetDetail } from "../../store/actions/generalActions";
import { contractFilter } from "../../services/daml-filters";
import Contracts from "../../components/Contracts/Contracts";
import { SurgicalStatus, checkNotSEHistory, checkEditableSE } from "../../models/SurgicalEvent";
import editIcon from "../../icons/edit.svg";
import editDisableIcon from "../../icons/edit-disable.svg";
import slashIcon from "../../icons/slash.svg";
import slashDisableIcon from "../../icons/slash-disable.svg";
// import completeIcon from "../../icons/check1.svg";
// import completeDisableIcon from "../../icons/check16.svg";
// import completeGreenIcon from "../../icons/check6.svg";
import { completeSurgicalEvent, cancelSurgicalEvent, archiveSurgicalEvent } from "./handle";
import SECompletedDialog from "./complete-dialog";
import DetailViewDrawer from "./detail-view-drawer";
import WelcomeModal from "./welcomeModal";
import ModalDialogBox from '../../components/Modals/GeneralModal/modalDialogBox';
import { useDamlStream } from "../../hooks/useDAML";
import { useDamlState } from "../../context/DamlContext";
import withLoading from "../../hoc/withLoading";
import useStyles from "./styles";


/**
 * SurgicalEvent List Contracts
 * @param {Object} searchParams 
 * @param {Object} searchHistoryParams 
 * @param {Boolean} isIncludeHistory 
 * @param {Boolean} isAutoPO 
 * @param {Boolean} isAutoVendor 
 * @param {Function} handleChangeSearchValue 
 * @param {Object} searchSurgical 
 * @param {Array} allContracts 
 * @returns 
 */
function SurgicalEventListContracts({
  searchParams = { },
  searchHistoryParams = { },
  isIncludeHistory = false,
  isAutoPO = false,
  isAutoVendor = false,
  handleChangeSearchValue = null,
  searchSurgical = { },
  allContracts = [],
}) {

  const history = useHistory();
  const classes = useStyles();
  const hospital = useParty();

  const ledger = useLedger();
  // const assets = useStreamQueries(SurgicalEvent, () => [searchParams]);
  const { role, party, userParty, roleCid } = useDamlState();

  // const [assetsHistory, setHisotry] = useState({});
  const assetsHistory = useDamlStream(SurgicalEventHistory, [{ hospital }], isIncludeHistory);

  allContracts = allContracts.concat(isIncludeHistory && assetsHistory.contracts ?
    assetsHistory.contracts.map(h => ({ ...h, payload: h.payload.surgicalevent })) : []);
  let contracts = contractFilter(allContracts, searchParams);

  // get current status
  // const currentStatus = getState().surgical.current_status;

  contracts = contracts
      .map(c => {
        // add visual_status into every contract
        c.payload.surgicaleventdata.visual_status = SurgicalStatus[c.payload.surgicaleventdata.status];
        /* if (currentStatus && 'edit' in currentStatus && c.contractId in currentStatus.edit) {
          c.payload.surgicaleventdata.visual_status = 'Pending';
        } */
        if (c.payload.surgicaleventdata.status === 'Scheduled' 
          && c.payload.surgicaleventproductdata.length
          // && !!c.payload.surgicaleventdata.checked
          ) {
          c.payload.surgicaleventdata.visual_status = 'Pending';
        }
        return c;
      });

  console.log("[SurgicalEventListContracts]", searchParams, contracts);
  // console.log("[SurgicalEventListContracts] assetsHistory:", assetsHistory.contracts);


  const [openWarning, setOpenWarning] = useState(false);
  const [selectedEventData, setSelectedEventData] = useState([]);
  const [productItemsList, setProductItemsList] = useState([]);
  const [alertCompleted, setAlertCompleted] = useState(false);
  const [confirmModalContract, setConfirmModalContract] = useState({ });
  const [confirmModalStatus, setConfirmModalStatus] = useState('');
  const [headingText, setHeadingText] = useState('');
  const [bodyText, setBodyText] = useState('<p> You will not be able to Recover it.</p>');
  const [state, setState] = useState(false);


  const handleEditSE = (c) => {
    // console.log("[handleEditSE]", c);
    dispatch(surgicalEventSetDetail(c));
    history.push('/app/surgicalevent/edit');
  };

  /* const handleCompleteSE = (c) => {
    // console.log("[handleCompleteSE]", c);
    setConfirmModalContract(c);
    setConfirmModalStatus('Complete');
    setHeadingText("Complete the Surgical Event?");
    setBodyText('<p> You will not be able to Recover it.</p>');
    setOpenWarning(true);
  }; */

  const handleCancelSE = (c) => {
    // console.log("[handleCancelSE]", c);
    setConfirmModalContract(c);
    setConfirmModalStatus('Cancel');
    setHeadingText("Cancel the Surgical Event?");
    setBodyText('<p> You will not be able to Recover it.</p>');
    setOpenWarning(true);
  };


  /* const handleArchiveSE = (c) => {
    // console.log("[handleArchiveSE]", c);
    setConfirmModalStatus('Archive');
    setHeadingText("Archive the Surgical Event?");
    setBodyText('<p>Archiving  the Surgical Event on this page will impact Surgical Events, Purchase Order and Restock Inventory Records.  Please be aware that you will not be able to recover from this.</p><p>In the future,  to display Archived Contract Information, please check the “Include Archived Contracts” checkmark.</p>');
    setConfirmModalContract(c);
    setOpenWarning(true);
  }; */

  const handleModalYes = () => {
    if (confirmModalStatus === 'Complete') {
      completeSurgicalEvent({
        contractId: confirmModalContract.contractId, 
        payload: confirmModalContract.payload, 
        ledger, 
        isCertificate: true, 
        isAutoPO, 
        isAutoVendor, 
        handleAfter: handleAfterCompletedSE,
        roleCid,
        name: userParty ? userParty : party,
        role: role,
      });
    }
    else if (confirmModalStatus === 'Cancel') {
      cancelSurgicalEvent({
        contractId: confirmModalContract.contractId, 
        payload: confirmModalContract.payload, 
        ledger,
        roleCid,
      });
    }
    else if (confirmModalStatus === 'Archive') {
      archiveSurgicalEvent({
        contractId: confirmModalContract.contractId, 
        payload: confirmModalContract.payload, 
        ledger,
        roleCid,
      });
    }
    setOpenWarning(false);
  }
  const handleAfterCompletedSE = (res) => {
    // setAlertCompleted(getCompleteResult(res, isAutoVendor));

    setConfirmModalContract(null);
    setConfirmModalStatus('Completed');
  };


  const toggleDrawer = (open) => {
    setState(open);
  };
  const handleSecRequest = () => {
    setOpenWarning(false);
  }
  const handlePurchaseOrder = () => {
    // history.push("/app/purchaseorder-list");
    history.push("/app/surgicalevent");
  }


  return (
    <>

      <Contracts
        className={classes.customRoot}
        contracts={contracts}
        columns={[
          // ["ContractId", "contractId"],
          ["SE Id", "payload.surgicaleventdata.seid", {
            options: {
              width: 120,
              cellClassName: classNames(classes.staticCellelement, classes.primaryColor),
              headerClassName: classes.staticCellelement,

            },
            type: "link",
            onClick: (contractId) => {
              const data = contracts.find(c => c.contractId === contractId);
              setSelectedEventData(data.payload.surgicaleventdata);
              toggleDrawer(true);
              setProductItemsList(data.payload.surgicaleventproductdata);
            },
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.surgicaleventdata.seid}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'seid')}
                  defaultValue={searchSurgical.seid}
                  label="Search SE ID"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchSurgical.seid,
            handleClear: () => handleChangeSearchValue('', 'seid'),
          }],
          ["MRN", "payload.surgicaleventdata.mrn", {
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.surgicaleventdata.mrn}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'mrn')}
                  defaultValue={searchSurgical.mrn}
                  label="Search MRN"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchSurgical.mrn,
            handleClear: () => handleChangeSearchValue('', 'mrn'),
            options: {
              width: 150,
            },
          }],
          ["Event Date", "payload.surgicaleventdata.eventdate", {
            search: {
              component:
                <DateSearch
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'eventdate')}
                  defaultValue={searchSurgical.eventdate}
                  label="Search Event Date :"
                  isStatic={true}
                />
            },
            isSearched: searchSurgical.eventdate,
            handleClear: () => handleChangeSearchValue('', 'eventdate'),
            options: {
              width: 160,
            },
          }],
          ["Patient Name", ["payload.surgicaleventdata.patientfirstname", "payload.surgicaleventdata.patientlastname"], {
            search: [
              {
                label: "Last Name",
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.surgicaleventdata.patientlastname}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'patientlastname')}
                    defaultValue={searchSurgical.patientlastname}
                    label="Search Patient Name"
                    isShrink={true}
                    contracts={allContracts}
                  />
              },
              {
                label: "First Name",
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.surgicaleventdata.patientfirstname}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'patientfirstname')}
                    defaultValue={searchSurgical.patientfirstname}
                    label="Search Patient Name"
                    contracts={allContracts}
                    isShrink={true}
                  />
              },
            ],
            isSearched: (!!searchSurgical.patientfirstname || !!searchSurgical.patientlastname),
            handleClear: () => {
              if (!!searchSurgical.patientfirstname && !!searchSurgical.patientlastname) {
                handleChangeSearchValue('', ['patientfirstname', 'patientlastname']);
              }
              else if (!!searchSurgical.patientfirstname) handleChangeSearchValue('', 'patientfirstname');
              else if (!!searchSurgical.patientlastname) handleChangeSearchValue('', 'patientlastname');
            },
            options: {
              width: 200,
            },
          }],
          ["Physician Name", ["payload.surgicaleventdata.physiciansalutation", "payload.surgicaleventdata.physicianfirstname", "payload.surgicaleventdata.physicianlastname"], {
            search: [
              {
                label: "Last Name",
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.surgicaleventdata.physicianlastname}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'physicianlastname')}
                    defaultValue={searchSurgical.physicianlastname}
                    label="Search Physician Name"
                    isShrink={true}
                    contracts={allContracts}
                  />
              },
              {
                label: "First Name",
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.surgicaleventdata.physicianfirstname}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'physicianfirstname')}
                    defaultValue={searchSurgical.physicianfirstname}
                    label="Search Physician Name"
                    isShrink={true}
                    contracts={allContracts}
                  />
              },
            ],
            isSearched: (!!searchSurgical.physicianlastname || !!searchSurgical.physicianfirstname),
            handleClear: () => {
              if (!!searchSurgical.physicianfirstname && !!searchSurgical.physicianlastname) {
                handleChangeSearchValue('', ['physicianfirstname', 'physicianlastname']);
              }
              else if (!!searchSurgical.physicianfirstname) handleChangeSearchValue('', 'physicianfirstname');
              else if (!!searchSurgical.physicianlastname) handleChangeSearchValue('', 'physicianlastname');
            },
            options: {
              width: 180,
            },
          }],
          ["Procedure", "payload.surgicaleventdata.surgicalprocedure", {
            options: {
              width: 180,
            },
          }],
          ["Products", "payload.surgicaleventproductdata", {
            type: "object",
            options: {
              width: 110,
              cellClassName: classes.centerCell,
            },

          }],
          ["Status", "payload.surgicaleventdata.visual_status", {
            type: "render",
            render: (param) => (<Chip
              label={param.value}
              size="small"
              className={classNames(classes.smallChip, (
                param.value === 'Completed' ? classes.backGreen : (
                  param.value === 'Cancelled' ? classes.backRed : (
                    param.value === 'Pending' ? classes.backYellow : classes.backBlue
                  )
                )
              ))}
            />),
            search: {
              component:
                <SelectSearch
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'status')}
                  label="Search Status :"
                  options={Object.keys(SurgicalStatus).map(value => ({ label: SurgicalStatus[value], value: value }))}
                  defaultValue={searchSurgical.status}
                  isStatic={true}
                />
            },
            isSearched: searchSurgical.status,
            handleClear: () => handleChangeSearchValue('', 'status'),
            options: {
              width: 110,
              cellClassName: classes.staticCellRight,
              headerClassName: classes.staticCellRight,
            },
          }],
        ]}

        actions={[
          /* [
            "Complete",
            [
              [["Complete", [
                <img alt="" src={completeIcon} />,
                <img alt="" src={completeGreenIcon} />,
                <img alt="" src={completeDisableIcon} />,
              ],
                (c) => ((c.payload.surgicaleventdata.visual_status === "Pending" && !!c.payload.surgicaleventdata.checked 
                  && checkNotSEHistory(c)) ? 0 :
                  ((c.payload.surgicaleventdata.status === "Complete") ? 1 : 2))
              ],
                handleCompleteSE],
            ],
            {
              width: 100,
              cellClassName: classes.staticCellRight,
              headerClassName: classes.staticCellRight,
            }
          ], */
          [
            "Actions",
            [
              [["Edit", [<img alt="" src={editIcon} />, <img alt="" src={editDisableIcon} />],
                checkEditableSE],
                handleEditSE],
              [["Cancel", [<img alt="" src={slashIcon} />, <img alt="" src={slashDisableIcon} />],
                checkEditableSE],
                handleCancelSE],
              /* [["Archive", [<img alt="" src={archiveIcon} />, <img alt="" src={archiveDisableIcon} />],
                checkEnableArchiveSE],
                handleArchiveSE], */
            ],
            {
              width: 170,
              cellClassName: classes.staticCellRight,
              headerClassName: classes.staticCellRight,
            }
          ]
        ]}

        options={{
          statusEmpty: (allContracts.length === 0)
        }}
      />

      <SECompletedDialog
        open={alertCompleted}
        setOpen={setAlertCompleted}
        handleRequest={handlePurchaseOrder}
      />

      <ModalDialogBox
        open={openWarning}
        setOpen={setOpenWarning}
        heading={headingText}
        type="warning"
        //  text={'<p> You will not be able to Recover it.</p>'}
        text={bodyText}
        handleRequest={handleModalYes}
        secondBtn={'No'}
        handleSecRequest={handleSecRequest}
      />

      <DetailViewDrawer
        state={state}
        toggleDrawer={toggleDrawer}
        selectedEventData={selectedEventData}
        productItemsList={productItemsList}
      />

      <WelcomeModal />

    </>
  );
}

export default withLoading(SurgicalEventListContracts);

/**
 * check to archive SE
 * When all PO's from SEID show "All Received" status then we can allow the user to Archive the SEID, 
 * SEID on PO or SEID on Restock Inventory pages
 * @param {Object} c 
 * @returns {Boolean}
 */
export const checkEnableArchiveSE = (c) => {
  // console.log("[SurgicalEventListContracts] checkEnableArchiveSE", c);
  let enabled = (c.payload.surgicaleventdata.status === "Complete" ||
    c.payload.surgicaleventdata.status === "Canceled") && checkNotSEHistory(c);
  if (enabled && c.payload.purchaseorders && c.payload.purchaseorders.length) {
    for (let i in c.payload.purchaseorders) {
      let p = c.payload.purchaseorders[i];
      let poinventorystatus = (p.payload && p.payload.purchaseorderdata) 
        ? p.payload.purchaseorderdata.poinventorystatus : p.poinventorystatus;
      if (poinventorystatus !== 'AllReceived') {
        enabled = false;
        break;
      }
    }
  }
  return enabled;
};

/**
 * SE List View Modal Dialog Handle
 */

