import { VendorRole, HospitalRole } from "../../services/daml-modules1";
import { getTemplateListFromResponse } from "../../services/util";

/**
 * mask function for Tax ID
 * @param {String} d 
 * @returns {String}
 */
export const maskForTax = (d) => {
  // pattern xxxxxxx99
  return d.replace(/(.{0,7})(\d{2}).*/, ($0, $1, $2) => {
    // console.log("[mask]", $0, $1, $2);
    return 'x'.repeat($1.length).concat($2);
  });
};

/**
 * accept RoleOne
 * exercise UpdateRegistration choice of VendorRole
 * @param {Object} onedetails 
 * @param {Object} ledger 
 * @param {String} contractId 
 * @returns {Boolean}
 */
export const exerciseRoleOneAccept = async (onedetails, ledger, contractId) => {
  debugger
  console.log("cid: " + contractId);
  console.log("onedetails: ", onedetails);

  // if (!validateVendorDetail(onedetails)) {
  //   alert("You must input all fields.");
  //   return false;
  // }
  debugger
  try {

    // const newonedetails = getVendorDetail(onedetails);

    let res = await ledger.exercise(VendorRole.UpdateRegistration, contractId, {
      newvendordetails: onedetails
    });

    // get contractId of the updated VendorRole
    let templateHR = getTemplateListFromResponse(res, "VendorRole");
    return (templateHR.length > 0);

  }
  catch (e) { }

  return false;

};

/**
 * accept RoleOne
 * exercise SetAdminVendorDetails choice of VendorRole
 * @param {Object} admindetails 
 * @param {Object} ledger 
 * @param {String} contractId 
 * @returns {Boolean}
 */
export const exerciseVendorAdminAccept = async (admindetails, ledger, contractId) => {

  // console.log("cid: " + contractId);
  // console.log("admindetails: ", admindetails);

  // if (!validateAdminVendorDetail(admindetails)) {
  //   alert("You must input all fields.");
  //   return false;
  // }

  try {

    // const newadmindetails = getAdminVendorDetail(admindetails);

    let res = await ledger.exercise(VendorRole.SetAdminVendorDetails, contractId, {
      newadmindetails: admindetails
    });

    // get contractId of the updated VendorRole
    let templateHR = getTemplateListFromResponse(res, "VendorRole");
    return (templateHR.length > 0);

  }
  catch (e) { }

  return false;

};

/**
 * accept RoleTwo
 * exercise UpdateRegistration2 choice of HospitalRole
 * @param {Object} twodetails 
 * @param {Object} ledger 
 * @param {String} contractId 
 * @returns {Boolean}
 */
export const exerciseRoleTwoAccept = async (twodetails, ledger, contractId) => {
  debugger
  console.log("cid: " + contractId);
  console.log("twodetails: ", twodetails);

  // Temporary Comment 
  // if (!validateHospitalDetail(twodetails)) {
  //   alert("You must input all fields.");
  //   return false;
  // }

  try {

    // const newtwodetails = getHospitalDetail(twodetails);

    let res = await ledger.exercise(HospitalRole.UpdateRegistration2, contractId, {
      newhospitaldetails: twodetails
    });

    // get contractId of the updated HospitalRole
    let templateHR = getTemplateListFromResponse(res, "HospitalRole");
    return (templateHR.length > 0);

  }
  catch (e) { }

  return false;

};