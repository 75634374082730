import React from "react";
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { formatDate } from "../../services/util";
import { useTranslation } from 'react-i18next';


// Create styles
export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  section: {
    padding: '15px 27px',
    color: '#000000',
    flexGrow: 1,
  },
  table: { 
    borderColor: '#CCCCCC',
    marginBottom: '10px',
  }, 
  tableRow: { 
    flexDirection: 'row',
  }, 
  tableCell: { 
    padding: '1px',
    fontWeight: 400,
    fontSize: '8px',
  },
  tableCellHead: {
    backgroundColor: 'rgba(196, 196, 196, 0.38)',
    fontWeight: 600,
    fontSize: '10px',
  },
  borderCell: {
    border: '1px solid #000000',
  },
  col2: {
    width: '50%',
  },
  col10: {
    width: '10%',
  },
  col15: {
    width: '15%',
  },
  col20: {
    width: '20%',
  },
  detailsForm: {
    margin: '70px 100px 60px',
  },
  detailsCell: { 
    fontWeight: 700,
    fontSize: '14px',
  },
});

/**
 * InventoryScan PDF
 * @param {Object} ipurchaseorderdata 
 * @param {Array} inventorydata 
 * @param {String} hospital 
 * @returns 
 */
const InventoryScanPDF = ({
  ipurchaseorderdata={}, inventorydata=[], hospital, 
}) => {
  const { t } = useTranslation();

  return (
    <View style={styles.section} wrap={true}>

      <View style={[styles.table, styles.detailsForm]}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCell, styles.detailsCell, styles.col2]}>
            <Text>PO number: {ipurchaseorderdata.popurchaseorderid}</Text>
          </View>
          <View style={[styles.tableCell, styles.detailsCell, styles.col2]}>
            <Text>Ownership: {ipurchaseorderdata.popurchaseordertype}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCell, styles.detailsCell, styles.col2]}>
            <Text>Distributor: {ipurchaseorderdata.povendorid}</Text>
          </View>
          <View style={[styles.tableCell, styles.detailsCell, styles.col2]}>
            <Text>PO Date: {formatDate(ipurchaseorderdata.podate)}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCell, styles.detailsCell, styles.col2]}>
            <Text>SEID: {ipurchaseorderdata.poseid}</Text>
          </View>
          <View style={[styles.tableCell, styles.detailsCell, styles.col2]}>
          </View>
        </View>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCell, styles.tableCellHead, styles.borderCell, styles.col20]}>
            <Text>Reference Number</Text>
          </View>
          <View style={[styles.tableCell, styles.tableCellHead, styles.borderCell, styles.col15]}>
            <Text>Lot/SN</Text>
          </View>
          <View style={[styles.tableCell, styles.tableCellHead, styles.borderCell, styles.col20]}>
            <Text>Description</Text>
          </View>
          <View style={[styles.tableCell, styles.tableCellHead, styles.borderCell, styles.col10]}>
            <Text>Price</Text>
          </View>
          <View style={[styles.tableCell, styles.tableCellHead, styles.borderCell, styles.col10]}>
            <Text>{t('main.uom.lable')}</Text>
          </View>
          <View style={[styles.tableCell, styles.tableCellHead, styles.borderCell, styles.col10]}>
            <Text>Quantity</Text>
          </View>
          <View style={[styles.tableCell, styles.tableCellHead, styles.borderCell, styles.col15]}>
            <Text>Status</Text>
          </View>
        </View>
        {
          inventorydata.map((detail, i) => (
            <View style={styles.tableRow} key={i}>
              <View style={[styles.tableCell, styles.borderCell, styles.col20]}>
                <Text>{detail.iproduct.sepreferencenumber}</Text>
              </View>
              <View style={[styles.tableCell, styles.borderCell, styles.col15]}>
                <Text>{detail.iproduct.seplotcode}</Text>
              </View>
              <View style={[styles.tableCell, styles.borderCell, styles.col20]}>
                <Text>{detail.iproduct.sepproductdescription}</Text>
              </View>
              <View style={[styles.tableCell, styles.borderCell, styles.col10]}>
                <Text>${detail.iproduct.sepproductprice}</Text>
              </View>
              <View style={[styles.tableCell, styles.borderCell, styles.col10]}>
                <Text>{detail.iproduct.sepuom}</Text>
              </View>
              <View style={[styles.tableCell, styles.borderCell, styles.col10]}>
                <Text>{detail.iquantity}</Text>
              </View>
              <View style={[styles.tableCell, styles.borderCell, styles.col15]}>
                <Text>{detail.iproductstatus}</Text>
              </View>
            </View>
          ))
        }
      </View>
    </View>
  );
};

export default InventoryScanPDF;
