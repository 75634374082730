import React, { useState, useEffect } from "react";
import { useParty, useLedger, useStreamQueries } from "@daml/react";
import { Relationship, RelationshipOffer } from "../../services/daml-modules1";
import { Button, Typography } from "@material-ui/core";
import AddEditVendorInvite from './addeditvendorinvite';
import AddIcon from '@material-ui/icons/Add';
import VendorInviteContracts from "./vendorinvitecontracts";
import VendorAcceptContracts from "./vendoracceptcontracts";
import { dispatch, getState } from "../../store";
import { inviteSearch, vendoracceptSearch } from "../../store/actions/generalActions";
import { createRelationshipOffer, updateRelationshipOffer } from "./handle";
import { useDamlState } from "../../context/DamlContext";
import useStyles from "../surgicalevent/styles";


export default function VendorInviteRelationship() {

  const classes = useStyles();
  const ledger = useLedger();
  const hospital = useParty();
  const { contracts } = useStreamQueries(Relationship, () => [{ hospital }]);
  const { contracts: contractsOffer } = useStreamQueries(RelationshipOffer, () => [{ hospital }]);
  const { roleCid } = useDamlState();

  const [ContractId, setContractId] = useState('');
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({});

  // AddEditVendorInvite
  const [open, setOpen] = useState(false);
  const [isCreate, setCreate] = useState(true);
  const [selectedVendorInvite, selectVendorInvite] = useState({});


  const [searchVendorInvite, setSearchVendorInvite] = useState({
    vendor: '',
    ...getState().template,
  });
  const handleChangeSearchValue = (val, key) => {
    setSearchVendorInvite({ ...searchVendorInvite, [key]: val });
    dispatch(inviteSearch({ key, value: val }));
  };

  //Search Vendor Invite

  useEffect(() => {
    setLoading(true);

    let temp = {};

    if (searchVendorInvite.vendor !== "") temp.vendor = searchVendorInvite.vendor;
    temp.hospital = hospital;

    const delay = 0;
    if (delay > 0) setTimeout(() => {
      setSearchParams(temp);
    }, delay);
    else {
      setSearchParams(temp);
    }
  }, [searchVendorInvite, hospital]);

  useEffect(() => {
    setLoading(false);
  }, [searchParams]);

  // Search Vendor Acceptance

  const [searchVendorAccept, setSearchVendorAccept] = useState({
    vendor: '',
    ...getState().template,
  });
  const handleChangeSearchAcceptValue = (val, key) => {
    setSearchVendorAccept({ ...searchVendorAccept, [key]: val });
    dispatch(vendoracceptSearch({ key, value: val }));
  };


  useEffect(() => {
    setLoading(true);

    let temp = {};

    if (searchVendorAccept.vendorinvited !== "") temp.vendor = searchVendorAccept.vendorinvited;
    temp.hospital = hospital;

    const delay = 0;
    if (delay > 0) setTimeout(() => {
      setSearchParams(temp);
    }, delay);
    else {
      setSearchParams(temp);
    }
  }, [searchVendorAccept, hospital]);

  useEffect(() => {
    setLoading(false);
  }, [searchParams]);


  // Create new Vendor Invitation
  // handle when click "Create" or "Update" button in Create Vendor Template modal
  const handleRequest = async (newvendor) => {
    if (isCreate) await createRelationshipOffer({
      ledger, vendor: newvendor, roleCid,
    });
    else await updateRelationshipOffer({
      ledger, contractId: ContractId, vendor: newvendor, roleCid,
    });
  };


  return (
    <>
      <div key="formChildStep" className="formsteps">
        <Typography variant="h4" className={classes.pageTitle}>Vendor Invitations</Typography>

        <Button
          size="small"
          className={classes.newSetupButton}
          onClick={() => {
            setCreate(true);
            setOpen(true);
          }}
          variant="contained"
        >
          <span className="desktop">Create Vendor Invitation</span>
          <span className="mobile"><AddIcon /></span>
        </Button>

        <VendorInviteContracts
          searchParams={searchParams}
          handleChangeSearchValue={handleChangeSearchValue}
          allContracts={contractsOffer}
          searchVendorInvite={searchVendorInvite}
          handleRequest={(data) => {
            // console.log("[VendorInviteContracts] handleRequest", data.payload);
            setContractId(data.contractId);
            selectVendorInvite(data.payload);
            setCreate(false);
            setOpen(true);
          }}
          loading={loading}
        />

        <div>  </div>
        <div key="formChildStep" className="formsteps">
          <Typography variant="h4" className={classes.pageTitle}>Vendor Accepted</Typography>
        </div>

        <VendorAcceptContracts
          searchParams={searchParams}
          handleChangeSearchValue={handleChangeSearchAcceptValue}
          allContracts={contracts}
          searchVendorAccept={searchVendorAccept}
          handleRequest={(data) => {
            // console.log("[VendorAcceptContracts] handleRequest", data.payload);
            // setContractId(data.contractId);
            // setCreate(false);
            // setOpen(true);
          }}
          loading={loading}
        />

      </div>

      <AddEditVendorInvite
        isCreate={isCreate}
        open={open}
        setOpen={setOpen}
        handleRequest={handleRequest}
        title={"Create Vendor Invitation"}
        editTitle={"Edit Vendor Invitation"}
        defaultValue={selectedVendorInvite}
      />

    </>
  );
};
