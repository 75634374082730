import React, { useState, useEffect } from "react";
import { useParty } from "@daml/react";
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, FormControl,
  TextField } from "@material-ui/core";
import { useLayoutDispatch, setLoading, unsetLoading } from "../../context/LayoutContext";
import  AddEditProduct  from "../surgicalevent/add-edit-product";
import { requiredProduct } from "../../models/Product";
import { getTodayString, formatDate } from "../../services/util";
import downloadIcon from "../../icons/download.svg";
import { InputHospital } from "../inventoryaccount/create-inventory-vendor";
import ErrorDialog from '../../components/Modals/GeneralModal/error-dialog';
import useStyles from "../surgicalevent/styles";
import InventoryCommentsList from './inventorylistcomment';


/**
 * Receipt Confirm Inventory Dialog
 * 1. Restock Inventory SE Contracts in Hospital
 * 2. Restock Inventory PO Contracts in Hospital
 * 3. Received Inventory Page in Vendor
 * @param {Boolean} openModal
 * @param {Function} setOpenModal
 * @param {Function} addProductItem
 * @param {Object} inventoryData
 * @param {String} title
 * @param {Boolean} enableHospital
 * @param {Function} setConfirmInventoryData
 * @param {Array} clearProductParams
 * @param {Boolean} isHospital
 * @param {Boolean} isQuantiy
 * @param {Number|null} defaultQuantity If null, then total count of inventory data
 * @returns 
 */
export default function ReceiptConfirmInventory ({
  openModal, setOpenModal,
  addProductItem, inventoryData={}, title="Receipt Confirmation", enableHospital=false,
  setConfirmInventoryData=()=>{}, clearProductParams=[], isHospital=true, isQuantiy=false, defaultQuantity=1,
}) {

  // console.log("[ReceiptConfirmInventory]", inventoryData);

  const classes = useStyles();

  const party = useParty();
  const layoutDispatch = useLayoutDispatch();

  const [productItems, setProductItems] = useState({});
  const [productError, setProductError] = useState({});
  const [hospital, setHospital] = useState("");
  const [hospitalError, setHospitalError] = useState("");
  const defaultInventoryData = {
    ...inventoryData,
    ireceiveddate: getTodayString()
  };
  // error modal
  const [errorModal, setErrorModal] = useState(false);
  // quantity
  const [quantity, setQuantity] = useState(1);
  const maxQuantity = (inventoryData.datas && typeof inventoryData === 'object' && inventoryData.datas.length)
      ? inventoryData.datas.length : 1;
  defaultQuantity = (inventoryData.datas && typeof inventoryData === 'object' && inventoryData.datas.length)
      ? ((defaultQuantity === null || defaultQuantity > inventoryData.datas.length) ? inventoryData.datas.length : (
        (defaultQuantity < 1) ? 1 : defaultQuantity
      )) : 1;

 
  // Click "Confirm"
  const confirmInventory = async () => {
    // check required fields in productItems
    if (!isHospital && !productItems.sepdistributor) productItems.sepdistributor = party;
    let newErrors = requiredProduct(productItems);
    console.log("[ReceiptConfirmInventory] confirmInventory", productItems, newErrors);
    if ((Object.keys(newErrors).length > 0)) {
      setProductError({ ...newErrors });
      return false;
    }
    // setProductItems({});
    setProductError({});
   
    const tempInventory = {
      ...defaultInventoryData, 
      iproduct: productItems
    };
    
    if (enableHospital) {
      if (!hospital) {
        setHospitalError("Hospital is required.");
        return false;
      }
      tempInventory.hospital = hospital;
    }

    if (inventoryData.datas && typeof inventoryData === 'object' && inventoryData.datas.length) {
      const count = isQuantiy ? quantity : defaultQuantity;
      tempInventory.datas = inventoryData.datas.slice(0, count)
      .map(inv => ({
        ...inv, 
        hospital: hospital,
        iproduct: productItems,
      }));
    }
    
    // disable now
    // Putting new product parameters again from entered box
    /* if ('iproduct' in inventoryData && clearProductParams.length > 0) {
      clearProductParams.forEach(p => {
        if (p in inventoryData.iproduct && p in productItems) inventoryData.iproduct[p] = productItems[p];
      });
    }
    // Set Confirm Inventory data
    if (typeof setConfirmInventoryData === 'function') setConfirmInventoryData(inventoryData); */
    
    // spinner
    setLoading(layoutDispatch);

    let result = true;
    if (typeof addProductItem === 'function') result = await addProductItem(tempInventory);

    unsetLoading(layoutDispatch);
    setOpenModal(false);

    // error
    if (!result) setErrorModal(true);
  };

  const handleCancel = () => {
    setOpenModal(false);
    unsetLoading(layoutDispatch);
  };


  useEffect(() => {
    if (!!openModal) {
      console.log("[ReceiptConfirmInventory] Open ReceiptConfirmInventory dialog.");

      // clear product parameters
      if ('iproduct' in inventoryData && clearProductParams.length > 0) {
        clearProductParams.forEach(p => {
          if (p in inventoryData.iproduct) inventoryData.iproduct[p] = "";
        });
      }
      
      if ('iproduct' in inventoryData) setProductItems(inventoryData.iproduct);
      else setProductItems({});
      setProductError({});
      setHospital("");
      setHospitalError("");
      setQuantity(1);

      if (isHospital) setHospital(party);
    }
  }, [openModal, inventoryData, clearProductParams, isHospital, party]);
   

  return (
    <>

      <Dialog
        open={openModal}
        className={classes.customRoot}
        aria-labelledby="draggable-dialog-title"
        classes={{paper: classes.overflowHidden}}
      >
        <div className={classes.purchaseContentModal}> 
          <DialogTitle style={{ cursor: 'move', borderBottom: '1px solid #cccccc' }} id="draggable-dialog-title">
            <div className="view-heading">{title}</div> 
          </DialogTitle>

          <DialogContent className={classes.poContentModal}>
            <DialogContentText  component={'div'}>

              <h2 className={classes.formSubHeading}>Inventory Details</h2>
              <div className="grid-list grid-list-4">
              {
                isHospital ?
                (<>
                  <div className="list-item">
                    <div className="label">Shipment Date</div>
                    <div className="value">{formatDate(inventoryData.ishipmentdate)}</div>
                  </div>
                  <div className="list-item">
                    <div className="label">Received Date</div>
                    <div className="value">{formatDate(inventoryData.ireceiveddate)}</div>
                  </div>
                  <div className="list-item">
                    <div className="label">Courier Company</div>
                    <div className="value">{inventoryData.icourier}</div>
                  </div>
                  <div className="list-item">
                    <div className="label">Tracking Number </div>
                    <div className="value">{inventoryData.itrackingstatus}</div>
                  </div>
                  <div className="list-item">
                    <div className="label">Packing Slip</div>
                    <div className="value downloadlink"><img src={downloadIcon} alt=""/> Download</div>
                  </div>
                  <div className="list-item">
                    <div className="label">Distributor </div>
                    <div className="value">{inventoryData.iproduct && inventoryData.iproduct.sepdistributor}</div>
                  </div>
                </>)
                :
                (<>
                  <div className="list-item">
                    <div className="label">Shipment Date</div>
                    <div className="value">{formatDate(inventoryData.ishipmentdate)}</div>
                  </div>
                  <div className="list-item">
                    <div className="label">Packing Slip</div>
                    <div className="value downloadlink"><img src={downloadIcon} alt=""/> Download</div>
                  </div>
                  <div className="list-item">
                    <div className="label">Courier Company</div>
                    <div className="value">{inventoryData.icourier}</div>
                  </div>
                </>)
              }
              </div>
              {
                (inventoryData.ireturnmessage || inventoryData.ireturnrequestcomment) ?
            <div>
              <InventoryCommentsList comments={inventoryData} />
              </div>
               : null
              }
              {
                enableHospital 
                ? 
                <div>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputHospital
                      isHospitalRelation={true}
                      hospital={hospital}
                      error={hospitalError}
                      setHospital={(value) => {
                        setHospital(value);
                      }}
                      label="Hospital"
                      placeholder="e.g. FirstHealth"
                    />
                  </FormControl>
                </div>
                :
                null
              }

              <div className="poModel"> 
                <h3  className={classes.subformHeading}> Product Details </h3>

                <AddEditProduct
                  isCreate = {true}
                  productItemsList ={[]}
                  productItems = {productItems}
                  productError= {productError}
                  setProductError={setProductError}
                  setProductItems = {setProductItems}
                  hospital={hospital}
                  options={{
                    isEnableEditProduct: false,
                    isEnableDeleteProduct: false,
                    infoAlertText: "You can confirm product receipt with scanner or by clicking confirm.",
                    edit: {
                      isEnableCompliance: false,
                      hiddenParams: ['sepdistributor','sepproductwaste'],
                      disableParams: (isHospital ? ['sepreferencenumber', 'sepownership', 'sepproductmanufacturer'] : []),
                      isInventory: true,
                    },
                  }}
                />

                {
                  isQuantiy &&
                  <div className={classes.marginBM30}>
                    <TextField
                      id="input-with-icon-adornment123"
                      style={{ margin: 8 }}
                      label="Quantity"
                      placeholder=""
                      value={quantity}
                      className={classes.customFullWidth}
                      margin="normal"
                      // InputProps={{ 
                      //   inputProps: { min: 1, max: maxQuantity } 
                      // }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        const val = e.target.value;
                        if (val >= 1 && val <= maxQuantity) setQuantity(val);
                      }}
                      variant="outlined"
                      type="number"
                    />
                  </div>
                }

              </div>
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <div className="form-footer">
              <div className="right-buttons">
                <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={confirmInventory} >Confirm</Button> 
              </div>
            </div>
          </DialogActions>
        </div>

      </Dialog>

      <ErrorDialog
        openModal={errorModal}
        setOpenModal={setErrorModal}
      />

    </>
  );
}