import React from "react";
import classNames from "classnames";
import { Chip } from "@material-ui/core";
import Contracts from "../../components/Contracts/Contracts1";
import { Ownerships } from "../../models/Ownership";  
import { getTotalPrice , MyInventoryProductStatus} from "../../models/InventoryData";
import { contractFilter1 } from "../../services/daml-filters";
import { handleCheckedContract, handleClickedContract } from '../surgicalevent/surgicaleventlistcontracts1';
import { getInventoryDataStatus, getInventorySortKey, getInventoryReturnStatus } from './inventorylistcontractshospital';
import useStyles from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";
import withFilters from "../../hoc/withFilters";



/**
 * InventoryList Contracts in Vendor
 * Facility Inventory Contracts
 * @param {Object} searchParams 
 * @param {Boolean} isIncludeHistory 
 * @param {Array} allContracts 
 * @param {Array} historyContracts 
 * @param {Function} setArchived
 * @param {Function} setChecked
 * @param {Function} handleEditInventory
 * @returns 
 */
function InventoryListContractsVendor({
  searchParams = {},
  isIncludeHistory = false,
  allContracts = [],
  historyContracts = [],
  setArchived = null,
  setChecked = null,
  handleEditInventory = null,
}) {

  const classes = useStyles();

  allContracts = allContracts.concat(isIncludeHistory && historyContracts.length ?
    historyContracts.map(h => ({ ...h, payload: h.payload.inventory })) : []);
  allContracts = allContracts
    .map(c => {
      c.payload.inventorydata = c.payload.inventorydata.filter(invData => invData.iproductstatus !== "Opens");
      c.payload.price = getTotalPrice(c.payload.inventorydata);
      // new sortKey
      c.newsortkey = getInventorySortKey(c);
      // status
      c.payload.status = getInventoryDataStatus(c.payload.inventorydata);
      // return status
      c.payload.returnstatus = getInventoryReturnStatus(c.payload.inventorydata);

      return c;
    });
  let contracts = contractFilter1(allContracts, searchParams);
  

  const getColorCode = (value) => {
    switch (value) {
      case 'Received':
        return classes.backRed;
      case 'Recall':
        return classes.backOrange;
      case 'Edited':
        return classes.backOrange;
      case 'Expiring':
        return classes.backOrange;
      case 'Back-order':
        return classes.backOrange;
      default:
        return classes.backBlue;
    }
  };


  return (
    <>

      <Contracts
        className={classes.customRoot}
        contracts={contracts}
        columns={[
          ["Hospital", "payload.hospital", {
            search: {
              type: "AdaptiveSearch",
              name: "hospital",
              getValue: (c) => c.payload.hospital,
              contracts: allContracts,
            },
            options: {
              flex: 0.8,
              headerAlign: 'center',
              align: 'center',
              cellClassName: classNames(classes.staticCellelement, classes.primaryColor),
              headerClassName: classes.staticCellelement,
            },
          }],
          ["Quantity", "payload.inventoryproduct.itemcount", {
            type: "link",
            search: {
              type: "AdaptiveSearch",
              name: "inventoryproduct.itemcount",
              getValue: (c) => c.payload.inventoryproduct.itemcount,
              contracts: allContracts,
            },
            options: {
              cellClassName: classNames(classes.primaryColor, classes.centerCell),
              flex: 0.6,
            },
          }],
          ["Par level", "payload.inventoryproduct.parlevel", {
            search: {
              type: "AdaptiveSearch",
              name: "inventoryproduct.parlevel",
              getValue: (c) => c.payload.inventoryproduct.parlevel,
              contracts: allContracts,
            },
            options: {
              flex: 0.6,
            },
          }],
          ["Reference Number", "payload.inventoryproduct.ireferencenumber", {
            type: "link",
            search: {
              type: "AdaptiveSearch",
              name: "inventoryproduct.ireferencenumber",
              getValue: (c) => c.payload.inventoryproduct.ireferencenumber,
              contracts: allContracts,
            },
            options: {
              flex: 1,
              cellClassName: classNames(classes.primaryColor),
            },
          }],
          ["Manufacturer", "payload.inventorydata.0.iproduct.sepproductmanufacturer", {
            search: {
              type: "AdaptiveSearch",
              name: "inventorydata.iproduct.sepproductmanufacturer",
              getValue: (c) => c.payload.inventorydata[0]?.iproduct.sepproductmanufacturer,
              contracts: allContracts,
            },
            options: {
              flex: 0.9,
            },
          }],
          ["Product Description", "payload.inventoryproduct.iproductdescription", {
            options: {
              flex: 1.2,
              tooltip: true,
            },
          }],
          ["Ownership", "payload.inventoryproduct.iownership", {
            type: "render",
            render: (param) => (param.value in Ownerships ? Ownerships[param.value] : null),
            search: {
              type: "AdaptiveSearch",
              name: "inventoryproduct.iownership",
              getValue: (c) => c.payload.inventoryproduct.iownership,
              contracts: allContracts,
            },
            options: {
              flex: 1,
            },
          }],
          ["Price", "payload.inventoryproduct.iprice", {
            type: "render",
            render: (param) => (param.value ? `$${param.value}` : 'No Contract Price'),
            search: {
              type: "AdaptiveSearch",
              name: "inventoryproduct.iprice",
              getValue: (c) => c.payload.inventoryproduct.iprice,
              contracts: allContracts,
            },
            options: {
              flex: 0.9,
            },
          }],
          ["Status", "payload.status", {
            type: "render",
            render: (param) => {
              const status = param.row.contract.payload.status;
              return (status.length ?
                status.map((s, i) => (<Chip
                  label={s}
                  key={i}
                  size="small"
                  className={getColorCode(s)}
                  style={{ marginLeft: 5 }}
                />))
                : <></>);
            },
            search: {
              type: "SelectSearch",
              name: "status",
              options: Object.keys(MyInventoryProductStatus).map(value => ({ label: MyInventoryProductStatus[value], value: value })),
            },
            options: {
              flex: 1,
            },
          }],

        ]}
        options={{
          statusEmpty: (allContracts.length === 0),
          rowsPerPage: 100,
          sortKey: 'newsortkey',
          checkbox: true,
        }}

        handleArchived={setArchived}
        handleClicked={(e) => handleClickedContract(e, handleEditInventory)}
        handleChecked={(e) => handleCheckedContract(e, contracts, setChecked)}
      />

    </>
  );
}

const InventoryVendorListContractsWithLoading = withLoading(InventoryListContractsVendor);
export default withFilters(InventoryVendorListContractsWithLoading)([
  { name: "hospital", label: "Hospital" },
  { name: "inventoryproduct.itemcount", label: "Quantity" },
  { name: "inventoryproduct.parlevel", label: "Par level" },
  { name: "inventoryproduct.ireferencenumber", label: "Reference Number" },
  { name: "inventorydata.iproduct.sepproductmanufacturer", label: "Manufacturer" },
  { name: "inventoryproduct.iownership", label: "Ownership" },
  { name: "inventoryproduct.iprice", label: "Price" },
  { name: "status", label: "Status" },
]);

