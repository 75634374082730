import { INVENTORY_SET, INVENTORY_SET_ALL, INVENTORY_SET_RESTOCK, INVENTORY_SET_ACCOUNT } from "./constants";

export const setInventory = (payload) => ({
  type: INVENTORY_SET,
  payload
});

export const setInventoryAll = (payload) => ({
  type: INVENTORY_SET_ALL,
  payload
});

export const setRestockInventory = (payload) => ({
  type: INVENTORY_SET_RESTOCK,
  payload
});

export const setAccountInventory = (payload) => ({
  type: INVENTORY_SET_ACCOUNT,
  payload
});
