import React, { useState, useEffect } from "react";
import { getState } from "../../store";
import StreamQueries from "./StreamQueries";


/**
 * Component for DAML Data StreamQueries
 * @param {Object} template
 * @param {Object} searchParams
 * @param {String} indexKey
 * @param {Function} setValues
 * @returns 
 */
export default function DamlStream({
  template,
  searchParams={},
  indexKey,
  setValues=()=>{},
  isLoading=false,
}) {

  let states = null;
  if (typeof indexKey === 'string') {
    states = getState().queryDAML[indexKey];
  }

  const [assets, setAssets] = useState(null);
  // console.log("[DamlStream]", indexKey, searchParams, assets, states);


  useEffect(() => {
    if (!!states) {
      setAssets(states);
    }
  }, [states]);

  useEffect(() => {
    if (!!assets && typeof setValues === 'function') {
      setValues(assets);
    }
  }, [assets, setValues]);


  return (
    <>
      {
        (isLoading || !!states) ?
        <></>
        :
        <StreamQueries 
          template={template}
          searchParams={searchParams}
          indexKey={indexKey}
          setValues={setAssets}
        />
      }
    </>
  );
}
