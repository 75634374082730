import React, { useCallback } from "react";
import classNames from "classnames";
import { Table, TableBody, TableRow, TableCell, Grid, Typography } from "@material-ui/core";
import useInventory from "../../hooks/useInventory";
import { useHospitalUser } from "../../hooks/useProduct";
import { Ownerships } from "../../models/Ownership";
import { PoType } from "../../models/Po";
import { CourierCompany } from "../../models/Courier";
import { filterModel } from "../../models/handles";
import { useDamlState } from "../../context/DamlContext";
import { CustomSelect, DateInput, CustomTextField } from "../../components/Inputs";
import EditProduct, { InputDistributor } from "../surgicalevent/edit-product1";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "./styles";


const productPattern = [
  {
    label: 'Product Details', style: {paddingTop: 0},
  },
  {
    key: 'barcode', width: 288, colSpan: 3,
  },
  {
    key: 'sepudi', width: 288, colSpan: 3,
  },
  {
    key: 'sepreferencenumber', width: 288, colSpan: 3,
  },
  {
    key: 'seplotcode', width: 128, style: { width: '144px' },
    key1: 'sepproductexpiration', width1: 128,
  },
  {
    key: 'sepproductmanufacturer', width: 128,
    // key1: 'sepdistributor', width1: 128,
  },
  {
    key: 'sepproductname', width: 333, colSpan: 3,
  },
  {
    key: 'sepproductdescription', width: 333, colSpan: 3,
  },
  {
    key: 'sepproductprice', width: 128,
    key1: 'sepproducttype', width1: 128,
    key2: 'sepproductside', width2: 128,
  },
  {
    key: 'sepuom', width: 128, 
    key1: 'iquantityunit', width1: 128, type1: 'number', label1: 'Rec/QTY',
  },
];


/**
 * Add Inventory Form
 * @returns 
 */
export default function AddInventory () {
  
  const classes = useStyles();
  const classes1 = useStyles1();

  const { role, relHospitals: hospitals } = useDamlState();

  const { restockInventoryData, setRestockInventoryData, restockInventoryErrors } = useInventory();
  const { hospital, setHospital } = useHospitalUser();
  
  
  const handleChange = useCallback((value, key) => {
    setRestockInventoryData({
      [key]: value,
    });
  }, [setRestockInventoryData]);


  return (
    <Grid container>
      <Grid item>
        
        <Table className={classNames(classes.newSEForm, classes1.newPOForm)}>
          <TableBody>
            <TableRow>
              <TableCell colSpan={3} className="new-subtitle" style={{paddingTop: 0}}>PO Details</TableCell>
            </TableRow>
            <TableRow>
            {
              role === 'Vendor'
              ?
              <TableCell>
                <CustomSelect
                  label="Hospital"
                  value={hospital || ""}
                  onChange={(val) => setHospital(val)}
                  items={hospitals}
                  placeholder="Select One"
                  width={128}
                  options={{
                    // readOnly: true, 
                  }}
                />
              </TableCell>
              :
              <TableCell>
                <InputDistributor
                  label="Distributor"
                  distributor={restockInventoryData.povendorid}
                  setDistributor={(val) => handleChange(val, 'povendorid')}
                  placeholder="Select One"
                  warning={restockInventoryErrors.povendorid}
                  width={128}
                />
              </TableCell>
            }
              <TableCell>
                <CustomSelect
                  label="Ownership"
                  value={restockInventoryData.popurchaseordertype || ""}
                  onChange={(val) => handleChange(val, 'popurchaseordertype')}
                  items={filterModel(Ownerships, ['Unknown'])}
                  placeholder="Select One"
                  warning={restockInventoryErrors.popurchaseordertype}
                  width={128}
                  options={{
                    // readOnly: true, 
                  }}
                />
              </TableCell>
              <TableCell>
                {
                  !!restockInventoryData.popurchaseorderid ? 
                  <CustomTextField
                    label="PO Number"
                    value={restockInventoryData.popurchaseorderid || ""}
                    warning={restockInventoryErrors.popurchaseorderid}
                    width={128}
                    className={classes1.lightGreenText}
                    options={{
                      disabled: true, 
                      readOnly: true, 
                    }}
                  />
                  :
                  <Typography style={{width: 128}} ></Typography>
                }
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <CustomSelect
                  label="PO Type"
                  value={restockInventoryData.potype || ""}
                  items={PoType}
                  placeholder="Select One"
                  warning={restockInventoryErrors.potype}
                  width={128}
                  options={{
                    disabled: true, 
                    readOnly: true, 
                  }}
                />
              </TableCell>
              <TableCell>
                <DateInput
                  label="PO Date"
                  value={restockInventoryData.podate || ""}
                  warning={restockInventoryErrors.podate}
                  width={128}
                  options={{ 
                    disabled: true, 
                    readOnly: true, 
                  }}
                />
              </TableCell>
            </TableRow>
            {
              role === 'Vendor' && 
            <TableRow>
              <TableCell>
                <CustomSelect
                  label="Courier Company"
                  value={restockInventoryData.icourier || ""}
                  onChange={(val) => handleChange(val, 'icourier')}
                  items={CourierCompany}
                  placeholder="Select One"
                  warning={restockInventoryErrors.icourier}
                  width={128}
                  options={{
                    // readOnly: true, 
                  }}
                />
              </TableCell>
              <TableCell>
                <CustomTextField
                  label="Tracking Number"
                  value={restockInventoryData.itrackingstatus || ""}
                  onChange={(val) => handleChange(val, 'itrackingstatus')}
                  warning={restockInventoryErrors.itrackingstatus}
                  width={128}
                  options={{
                    // readOnly: true, 
                  }}
                />
              </TableCell>
            </TableRow>
            }
          </TableBody>
        </Table>

        <EditProduct
          options={{
            isEnableCompliance: false,
            isComplianceWarning: false,
            isContractUpdate: true,
          }}
          designPattern={productPattern}
        />
          
      </Grid>
    </Grid>
  );
}
