import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import VerticalContracts from "../../components/Contracts/VerticalContracts";
import VHContracts from "../../components/Contracts/VHContracts";
import { getState } from "../../store";


export default function ComplianceDetail() {

  const history = useHistory();

  const contract = [getState().compliance.detail];
  console.log("[ComplianceDetail]", contract);

  /**
   * For vertical contracts, this should be 'Vertical'.
   * For horizontal-vertical contracts, this should be 'VH'.
   */
  const verticalHorizontal = 'VH';


  return (
    <>
      {
        contract.length && typeof contract[0] === "object" && 'contractId' in contract[0]
          ?
          <>

            <Button
              variant="contained"
              color="primary"
              size="small"
              // component={Link}
              // to="/app/compliancelist"
              onClick={() => history.goBack()}
            >
              Back
            </Button>

            {
              verticalHorizontal === 'Vertical'
                ?
                <VerticalContracts
                  contracts={contract}
                  rows={[
                    // ["ContractId", "contractId"],
                    ["Date", "payload.date"],
                    ["User", "payload.user"],
                    ["Role", "payload.role"],
                    ["Rule Type Level", "payload.ruletypelevel"],
                    ["Rule Type", "payload.ruletype"],
                    ["Rule Message", "payload.rulemessage"],
                    ["Rule Outcome", "payload.ruleoutcome"],
                  ]}
                  more={[
                    ["Surgical Event", "payload.surgicalevent.surgicaleventdata", {
                      rows: [
                        ["SE ID", "seid"],
                        ["MRN", "mrn"],
                        ["Patient Name", ["patientfirstname", "patientlastname"]],
                        ["Gender", "patientgender"],
                        ["Patient Dob", "patientdob"],
                        ["Event Date", "eventdate"],
                        ["Surgical Procedure", "surgicalprocedure"],
                        ["Surgical Side", "surgicalside"],
                        ["Physician Name", ["physiciansalutation", "physicianfirstname", "physicianlastname"]],
                        ["Price", "price"],
                        ["Status", "status"],
                      ],
                    }],
                    ["Product Data", "payload.auditedproductdata", {
                      rows: [
                        ["Reference Number", "sepreferencenumber"],
                        ["Manufacturer", "sepproductmanufacturer"],
                        ["Product Name", "sepproductname"],
                        ["Description", "sepproductdescription"],
                        ["Expiration", "sepproductexpiration"],
                        ["Price", "sepproductprice"],
                        ["Lotcode", "seplotcode"],
                        ["Product Type", "sepproducttype"],
                        ["Ownership", "sepownership"],
                        ["Udi", "sepudi"],
                        ["Product Side", "sepproductside"],
                      ],
                    }]
                  ]}
                  options={{ collapseFixed: true }}
                />
                :
                (
                  verticalHorizontal === 'VH'
                    ?
                    <VHContracts
                      contracts={contract}
                      rows={[
                        // ["ContractId", "contractId"],
                        ["Date", "payload.date"],
                        ["User", "payload.user"],
                        ["Role", "payload.role"],
                        ["Rule Type Level", "payload.ruletypelevel"],
                        ["Rule Type", "payload.ruletype"],
                        ["Rule Message", "payload.rulemessage"],
                        ["Rule Outcome", "payload.ruleoutcome"],
                      ]}
                      more={[
                        ["Surgical Event", "payload.surgicalevent.surgicaleventdata", {
                          columns: [
                            ["SE ID", "seid"],
                            ["MRN", "mrn"],
                            ["Patient Name", ["patientfirstname", "patientlastname"]],
                            ["Gender", "patientgender"],
                            ["Patient Dob", "patientdob"],
                            ["Event Date", "eventdate"],
                            ["Surgical Procedure", "surgicalprocedure"],
                            ["Surgical Side", "surgicalside"],
                            ["Physician Name", ["physiciansalutation", "physicianfirstname", "physicianlastname"]],
                            ["Price", "price"],
                            ["Status", "status"],
                          ],
                        }],
                        ["Product Data", "payload.auditedproductdata", {
                          columns: [
                            ["Reference Number", "sepreferencenumber"],
                            ["Manufacturer", "sepproductmanufacturer"],
                            ["Product Name", "sepproductname"],
                            ["Description", "sepproductdescription"],
                            ["Expiration", "sepproductexpiration"],
                            ["Price", "sepproductprice"],
                            ["Lotcode", "seplotcode"],
                            ["Product Type", "sepproducttype"],
                            ["Ownership", "sepownership"],
                            ["Udi", "sepudi"],
                            ["Product Side", "sepproductside"],
                          ],
                        }],
                      ]}
                      options={{ collapseFixed: true }}
                    />
                    :
                    null
                )
            }

          </>
          :
          <Redirect to="/app/compliancelist" />
      }

    </>
  );
}
