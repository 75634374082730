import React, { useState, useEffect } from "react";
import { Button, Dialog, TextField, FormControl } from "@material-ui/core";
// import { onChangeNumber } from "../../components/Modals/handles";
import { HospitalTemplateModel, requiredHospitalTemplate } from "../../models/HospitalTemplate";
import { isEmptyObject } from "../../services/util";
import { InputHospital } from "../inventoryaccount/create-inventory-vendor";
import useStyles from "../surgicalevent/styles";


/**
 * Create Hospital Template Dialog in Vendor
 * @param {Boolean} isCreate 
 * @param {Boolean} open 
 * @param {Function} setOpen 
 * @param {Function} handleRequest 
 * @param {String} title 
 * @param {String} editTitle 
 * @param {Object} defaultValue 
 * @param {String} hospital 
 * @param {Function} setHospital 
 * @returns 
 */
export default function AddEditHospitalTemplate ({
  isCreate, 
  open,
  setOpen,
  handleRequest,
  title="",
  editTitle = "",
  defaultValue = {},
  hospital,
  setHospital,
}) {

  const classes = useStyles();
  const [hospitaltemplate, setHospitalTemplate] = useState({ ...HospitalTemplateModel });
  const [productError, setProductError] = useState({ ...HospitalTemplateModel });
  const [hospitalError, setHospitalError] = useState("");
  // disable validation once input each field
  // handleValueFill = () => {};

  const handleCreateUpdate = async () => {
    if (!hospital) {
      setHospitalError("Hospital is required.");
      return false;
    }
    let newErrors = requiredHospitalTemplate(hospitaltemplate);
    console.log("[AddEditHospitalTemplate] handleCreateUpdate", newErrors);
    if (!isEmptyObject(newErrors)) {
      setProductError({ ...newErrors });
      return false;
    }

    setProductError({ ...HospitalTemplateModel });

    await handleRequest(hospitaltemplate);
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      console.log("[AddEditHospitalTemplate] Open AddEditHospitalTemplate dialog.");
      setHospitalError("");
      setProductError({ ...HospitalTemplateModel });
      setHospitalTemplate({ ...HospitalTemplateModel, ...defaultValue });
    }
  }, [open, defaultValue]);

  // console.log("[AddEditHospitalTemplate]", hospitaltemplate);

  return (
    <Dialog
        className={classes.customRoot}
        open={open}
        onClose={()=>{setOpen(false)}}
        aria-labelledby="draggable-dialog-title"
      >
      <div className="formsteps">
        <h1 className={classes.formHeading}>
          {isCreate ? 
            title : 
            editTitle}
        </h1>
        
        <div className={classes.AddEditVendorForm}>
          <br/>

          <div className={classes.marginBM30}>
            <InputHospital
              isHospitalRelation={true}
              hospital={hospital}
              error={hospitalError}
              setHospital={(value) => {
                setHospital(value);
              }}
              label="Hospital"
              placeholder=""
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="API Index"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_index1._index || ""}
              error={!!productError.api_index1._index}
              helperText={productError.api_index1._index}
              onChange={(e) => setHospitalTemplate({
                ...hospitaltemplate, 
                api_index1: { ...hospitaltemplate.api_index1, _index : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Id"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_index1._id || ""}
              error={!!productError.api_index1._id}
              helperText={productError.api_index1._id}
              onChange={(e) => setHospitalTemplate({
                ...hospitaltemplate, 
                api_index1: { ...hospitaltemplate.api_index1, _id : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Invoice Number"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_invoice.invoiceNumber || ""}
              error={!!productError.api_invoice.invoiceNumber}
              helperText={productError.api_invoice.invoiceNumber}
              onChange={(e) => setHospitalTemplate({
                ...hospitaltemplate, 
                api_invoice: { ...hospitaltemplate.api_invoice, invoiceNumber : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Invoice Currency"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_invoice.invoiceCurrency || ""}
              error={!!productError.api_invoice.invoiceCurrency}
              helperText={productError.api_invoice.invoiceCurrency}
              onChange={(e) => setHospitalTemplate({
                ...hospitaltemplate, 
                api_invoice: { ...hospitaltemplate.api_invoice, invoiceCurrency : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Invoice Amount"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_invoice.invoiceAmount || ""}
              error={!!productError.api_invoice.invoiceAmount}
              helperText={productError.api_invoice.invoiceAmount}
              onChange={(e) => {
                setHospitalTemplate({
                  ...hospitaltemplate, 
                  api_invoice: { ...hospitaltemplate.api_invoice, invoiceAmount : e.target.value }
                });
              }}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
              type="number"
            />
          </div>
          <div className={classes.marginBM30}>
            <FormControl variant="outlined" className={classes.formControl}>     
              <TextField
                label="Invoice Date"
                type="date"
                className={classes.datehalfWidth}
                value={hospitaltemplate.api_invoice.invoiceDate || ""}
                error={!!productError.api_invoice.invoiceDate}
                helperText={productError.api_invoice.invoiceDate}
                onChange={(e) => setHospitalTemplate({
                  ...hospitaltemplate, 
                  api_invoice: { ...hospitaltemplate.api_invoice, invoiceDate : e.target.value }
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"  
              />
            </FormControl>
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Business Unit"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_invoice.businessUnit || ""}
              error={!!productError.api_invoice.businessUnit}
              helperText={productError.api_invoice.businessUnit}
              onChange={(e) => setHospitalTemplate({
                ...hospitaltemplate, 
                api_invoice: { ...hospitaltemplate.api_invoice, businessUnit : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Supplier"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_invoice.supplier || ""}
              error={!!productError.api_invoice.supplier}
              helperText={productError.api_invoice.supplier}
              onChange={(e) => setHospitalTemplate({
                ...hospitaltemplate, 
                api_invoice: { ...hospitaltemplate.api_invoice, supplier : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Supplier Site"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_invoice.supplierSite || ""}
              error={!!productError.api_invoice.supplierSite}
              helperText={productError.api_invoice.supplierSite}
              onChange={(e) => setHospitalTemplate({
                ...hospitaltemplate, 
                api_invoice: { ...hospitaltemplate.api_invoice, supplierSite : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Requester"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_invoice.requester || ""}
              error={!!productError.api_invoice.requester}
              helperText={productError.api_invoice.requester}
              onChange={(e) => setHospitalTemplate({
                ...hospitaltemplate, 
                api_invoice: { ...hospitaltemplate.api_invoice, requester : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Invoice Group"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_invoice.invoiceGroup || ""}
              error={!!productError.api_invoice.invoiceGroup}
              helperText={productError.api_invoice.invoiceGroup}
              onChange={(e) => setHospitalTemplate({
                ...hospitaltemplate, 
                api_invoice: { ...hospitaltemplate.api_invoice, invoiceGroup : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Description"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_invoice.description || ""}
              error={!!productError.api_invoice.description}
              helperText={productError.api_invoice.description}
              onChange={(e) => setHospitalTemplate({
                ...hospitaltemplate, 
                api_invoice: { ...hospitaltemplate.api_invoice, description : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="API Index"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_index2._index || ""}
              error={!!productError.api_index2._index}
              helperText={productError.api_index2._index}
              onChange={(e) => setHospitalTemplate({
                ...hospitaltemplate, 
                api_index2: { ...hospitaltemplate.api_index2, _index : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Id"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_index2._id || ""}
              error={!!productError.api_index2._id}
              helperText={productError.api_index2._id}
              onChange={(e) => setHospitalTemplate({
                ...hospitaltemplate, 
                api_index2: { ...hospitaltemplate.api_index2, _id : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Type"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_invoice_attachment[0]._type || ""}
              error={!!productError.api_invoice_attachment[0]._type}
              helperText={productError.api_invoice_attachment[0]._type}
              onChange={(e) => setHospitalTemplate({
                ...hospitaltemplate, 
                api_invoice_attachment: [{ ...hospitaltemplate.api_invoice_attachment[0], _type : e.target.value }]
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="FileName"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_invoice_attachment[0].fileName || ""}
              error={!!productError.api_invoice_attachment[0].fileName}
              helperText={productError.api_invoice_attachment[0].fileName}
              onChange={(e) => setHospitalTemplate({
                ...hospitaltemplate, 
                api_invoice_attachment: [{ ...hospitaltemplate.api_invoice_attachment[0], fileName : e.target.value }]
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Title"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_invoice_attachment[0].title || ""}
              error={!!productError.api_invoice_attachment[0].title}
              helperText={productError.api_invoice_attachment[0].title}
              onChange={(e) => setHospitalTemplate({
                ...hospitaltemplate, 
                api_invoice_attachment: [{ ...hospitaltemplate.api_invoice_attachment[0], title : e.target.value }]
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Description"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_invoice_attachment[0].description || ""}
              error={!!productError.api_invoice_attachment[0].description}
              helperText={productError.api_invoice_attachment[0].description}
              onChange={(e) => setHospitalTemplate({
                ...hospitaltemplate, 
                api_invoice_attachment: [{ ...hospitaltemplate.api_invoice_attachment[0], description : e.target.value }]
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Category"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_invoice_attachment[0].category || ""}
              error={!!productError.api_invoice_attachment[0].category}
              helperText={productError.api_invoice_attachment[0].category}
              onChange={(e) => setHospitalTemplate({
                ...hospitaltemplate, 
                api_invoice_attachment: [{ ...hospitaltemplate.api_invoice_attachment[0], category : e.target.value }]
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="File Url"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_invoice_attachment[0].fileUrl || ""}
              error={!!productError.api_invoice_attachment[0].fileUrl}
              helperText={productError.api_invoice_attachment[0].fileUrl}
              onChange={(e) => setHospitalTemplate({
                ...hospitaltemplate, 
                api_invoice_attachment: [{ ...hospitaltemplate.api_invoice_attachment[0], fileUrl : e.target.value }]
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="API Index"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_index3._index || ""}
              error={!!productError.api_index3._index}
              helperText={productError.api_index3._index}
              onChange={(e) => setHospitalTemplate({
                ...hospitaltemplate, 
                api_index3: { ...hospitaltemplate.api_index3, _index : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Id"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_index3._id || ""}
              error={!!productError.api_index3._id}
              helperText={productError.api_index3._id}
              onChange={(e) => setHospitalTemplate({
                ...hospitaltemplate, 
                api_index3: { ...hospitaltemplate.api_index3, _id : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Installment Number"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_invoice_installments[0].installmentNumber || ""}
              error={!!productError.api_invoice_installments[0].installmentNumber}
              helperText={productError.api_invoice_installments[0].installmentNumber}
              onChange={(e) => {
                setHospitalTemplate({
                  ...hospitaltemplate, 
                  api_invoice_installments: [{ ...hospitaltemplate.api_invoice_installments[0], installmentNumber : e.target.value }]
                });
              }}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
              type="number"
            />
          </div>
          <div className={classes.marginBM30}>
            <FormControl variant="outlined" className={classes.formControl}>     
              <TextField
                label="Due Date"
                type="date"
                className={classes.datehalfWidth}
                value={hospitaltemplate.api_invoice_installments[0].dueDate || ""}
                error={!!productError.api_invoice_installments[0].dueDate}
                helperText={productError.api_invoice_installments[0].dueDate}
                onChange={(e) => setHospitalTemplate({
                  ...hospitaltemplate, 
                  api_invoice_installments: [{ ...hospitaltemplate.api_invoice_installments[0], dueDate : e.target.value }]
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"  
              />
            </FormControl>
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Gross Amount"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_invoice_installments[0].grossAmount || ""}
              error={!!productError.api_invoice_installments[0].grossAmount}
              helperText={productError.api_invoice_installments[0].grossAmount}
              onChange={(e) => {
                setHospitalTemplate({
                  ...hospitaltemplate, 
                  api_invoice_installments: [{ ...hospitaltemplate.api_invoice_installments[0], grossAmount : e.target.value }]
                });
              }}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
              type="number"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Invoice Installment Dff"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_invoice_installments[0].invoiceInstallmentDff || ""}
              error={!!productError.api_invoice_installments[0].invoiceInstallmentDff}
              helperText={productError.api_invoice_installments[0].invoiceInstallmentDff}
              onChange={(e) => setHospitalTemplate({
                ...hospitaltemplate, 
                api_invoice_installments: [{ ...hospitaltemplate.api_invoice_installments[0], invoiceInstallmentDff : e.target.value }]
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Payment Mode"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_invoice_installments[0]._payment__mode || ""}
              error={!!productError.api_invoice_installments[0]._payment__mode}
              helperText={productError.api_invoice_installments[0]._payment__mode}
              onChange={(e) => setHospitalTemplate({
                ...hospitaltemplate, 
                api_invoice_installments: [{ ...hospitaltemplate.api_invoice_installments[0], _payment__mode : e.target.value }]
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div> 
          <div className={classes.marginBM30}>
            <TextField
              label="API Index"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_index4._index || ""}
              error={!!productError.api_index4._index}
              helperText={productError.api_index4._index}
              onChange={(e) => setHospitalTemplate({
                ...hospitaltemplate, 
                api_index4: { ...hospitaltemplate.api_index4, _index : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Id"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_index4._id || ""}
              error={!!productError.api_index4._id}
              helperText={productError.api_index4._id}
              onChange={(e) => setHospitalTemplate({
                ...hospitaltemplate, 
                api_index4: { ...hospitaltemplate.api_index4, _id : e.target.value }
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Line Number"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_invoice_lines[0].lineNumber || ""}
              error={!!productError.api_invoice_lines[0].lineNumber}
              helperText={productError.api_invoice_lines[0].lineNumber}
              onChange={(e) => {
                setHospitalTemplate({
                  ...hospitaltemplate, 
                  api_invoice_lines: [{ ...hospitaltemplate.api_invoice_lines[0], lineNumber : e.target.value }]
                });
              }}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
              type="number"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Line Amount"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_invoice_lines[0].lineAmount || ""}
              error={!!productError.api_invoice_lines[0].lineAmount}
              helperText={productError.api_invoice_lines[0].lineAmount}
              onChange={(e) => {
                setHospitalTemplate({
                  ...hospitaltemplate, 
                  api_invoice_lines: [{ ...hospitaltemplate.api_invoice_lines[0], lineAmount : e.target.value }]
                });
              }}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
              type="number"
            />
          </div>
          <div className={classes.marginBM30}>
            <TextField
              label="Line Description"
              placeholder=""
              className={classes.customFullWidth}
              value={hospitaltemplate.api_invoice_lines[0].lineDescription || ""}
              error={!!productError.api_invoice_lines[0].lineDescription}
              helperText={productError.api_invoice_lines[0].lineDescription}
              onChange={(e) => setHospitalTemplate({
                ...hospitaltemplate, 
                api_invoice_lines: [{ ...hospitaltemplate.api_invoice_lines[0], lineDescription : e.target.value }]
              })}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
          </div>
            
        </div>
      </div>
        <div className="form-footer">
        <div className="right-buttons">
          <Button autoFocus onClick={() => setOpen(false)} className="noBtn" variant="outlined"  color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreateUpdate} className="yesBtn" variant="contained" color="primary">
            {isCreate ? "Create" : "Update"}
          </Button>
        </div>
      </div>
    
    </Dialog>
  );
}
           