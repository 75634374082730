import React, { useState, useEffect } from "react";
import { useLedger, useParty, useStreamQueries } from "@daml/react";
import { Typography, Grid, Button } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { AccountInventory } from "../../services/daml-modules1";
import InventoryListContractsHospital from './inventorylistcontractshospital';
import CreateInventory from './create-inventory';
import { createAccountInventoryHospital, createRestockInventoryHospital, matchInventoryData } from './handle';
import ModalDialogBox from "../../components/Modals/GeneralModal/modalDialogBox";
import { dispatch, getState } from "../../store";
import { inventorySearch } from "../../store/actions/generalActions";
import { createPartyFromName } from "../../models/handles";
import { useDamlState } from "../../context/DamlContext";
import useStyles from "../surgicalevent/styles";
import { getTodayString } from "../../services/util";


export default function InventoryListHospital() {

  const classes = useStyles();

  const ledger = useLedger();
  const hospital = useParty();
  const { contracts } = useStreamQueries(AccountInventory, () => [{ hospital }]);
  const { roleCid, party, userParty } = useDamlState();

  // console.log("InventoryListHospital:", contracts, roleCid);

  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({});
  const [searchParamsInternal, setSearchParamsInternal] = useState({});
  const [inventoryDataList, setInventoryDataList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [delOpen, setDelOpen] = useState(false);
  const [poInvData, setPoInvData] = useState({});


  // handle when click "Add Inventory" button
  const openCreateInventoryModal = (e) => {
    setInventoryDataList([]);
    setOpenModal(true);
  };

  // handle to add new Inventory
  const addProductItem = async () => {
    console.log("[InventoryListHospital] addProductItem", inventoryDataList);

    // Set Edit Status as Add
    const tempInvDataList = inventoryDataList.map(inv => ({
      ...inv, 
      itempstatus: 'Add',
      // itempproduct: inv.iproduct, 
      itemprequestdate: getTodayString(), 
      itemprequestuser: userParty ? userParty : party,
      // itemprequestmessage: "",
    }));

    await handleAddNewInventory({
      inventoryDataList: tempInvDataList,
      ledger,
      roleCid,
      setOpenModal,
      match: false,
      openAlert: (totalres) => {
        setPoInvData(totalres);
        // disable to open confirm PO dialog now.
        // setDelOpen(true);
      },
      isRestockNotAccount: false,
    });

  };

  // handle to add more Product in Inventory
  const handleAddMoreProduct = async (product) => {
    // Nothing
  };

  // handle to match Inventory & PO
  const handleRequestDelOpen = async () => {
    console.log("[InventoryListHospital] handleRequestDelOpen", poInvData);

    if (!!poInvData && typeof poInvData === 'object' && 'oldInventorydata' in poInvData) {

      // Match Inventory & PO
      await matchInventoryData({
        ledger,
        roleCid,
        ContractId: poInvData.contractId,
        oldinventorydata: poInvData.oldInventorydata,
        newinventorydata: poInvData.newInventorydata,
      });

    }

    setDelOpen(false);
  };


  const [searchInventory, setSearchInventory] = useState({
    vendor: '',
    ireferencenumber: '',
    itemcount: '',
    parlevel: '',
    iprice: '',
    iownership: '',
    sepproductmanufacturer: '',
    status: '',
    ...getState().inventory,
  });
  const handleChangeSearchValue = (val, key) => {
    const getVal= (val==='No Contract Price' ? null : val) 
    setSearchInventory({ ...searchInventory, [key]: getVal });
    dispatch(inventorySearch({ key, value: getVal }));
  };


  useEffect(() => {
    setLoading(true);

    let temp = {}, tempInternal = {};

    temp.inventoryproduct = {};
    if (searchInventory.vendor !== "") temp.vendor = searchInventory.vendor;
    if (searchInventory.ireferencenumber !== "") temp.inventoryproduct.ireferencenumber = searchInventory.ireferencenumber;
    if (searchInventory.itemcount !== "") temp.inventoryproduct.itemcount = searchInventory.itemcount;
    if (searchInventory.parlevel !== "") temp.inventoryproduct.parlevel = searchInventory.parlevel;
    if (searchInventory.iprice !== "") temp.inventoryproduct.iprice = searchInventory.iprice;
    if (searchInventory.iownership !== "") temp.inventoryproduct.iownership = searchInventory.iownership;
    if (searchInventory.sepproductmanufacturer !== "") tempInternal.sepproductmanufacturer = searchInventory.sepproductmanufacturer;
    if (searchInventory.status !== "") tempInternal.status = searchInventory.status;
    temp.hospital = hospital;

    const delay = 0;
    if (delay > 0) setTimeout(() => {
      setSearchParams(temp);
      setSearchParamsInternal(tempInternal);
    }, delay);
    else {
      setSearchParams(temp);
      setSearchParamsInternal(tempInternal);
    }
  }, [searchInventory, hospital]);

  useEffect(() => {
    setLoading(false);
  }, [searchParams]);


  return (
    <>
      <Grid>
        <Typography variant="h4" className={classes.pageTitle}>Account Inventory</Typography>

        <Button
          size="small"
          className={classes.newSetupButton}
          variant="contained"
          onClick={openCreateInventoryModal}
        >
          <span className="desktop">Add Inventory</span>
          <span className="mobile"><AddIcon /></span>
        </Button>

        <InventoryListContractsHospital
          searchParams={searchParams}
          searchParamsInternal={searchParamsInternal}
          handleChangeSearchValue={handleChangeSearchValue}
          allContracts={contracts}
          searchInventory={searchInventory}
          hospital={hospital}
          ledger={ledger}
          loading={loading}
        />

        <ModalDialogBox
          open={delOpen}
          setOpen={setDelOpen}
          heading="Alert!"
          type="warning"
          text={`<p>There is a recent PO (${('popurchaseorderid' in poInvData ? poInvData.popurchaseorderid : '')}) 
            that this Product matches.  Would you like to Add this Product to that PO?.</p>`}
          handleRequest={handleRequestDelOpen}
          firstBtn={"Yes"}
          secondBtn={"No"}
          handleSecRequest={() => setDelOpen(false)}
        />

        <CreateInventory
          openModal={openModal}
          setOpenModal={setOpenModal}
          addProductItem={addProductItem}
          handleAddMoreProduct={handleAddMoreProduct}
          hospital={hospital}
          title={'Receive Inventory'}
          inventoryDataList={inventoryDataList}
          setInventoryDataList={setInventoryDataList}
          btnCreate="Add"
        />

      </Grid>
    </>
  );
}

/**
 * handle to add new Inventory in Hospital
 * @param {Array} inventoryDataList 
 * @param {Object} ledger 
 * @param {String} roleCid 
 * @param {Function} setOpenModal 
 * @param {Boolean} match 
 * @param {Function} openAlert 
 * @param {Boolean} isRestockNotAccount If make Restock Inventory, then true. If make Account Inventory, then false.
 * @param {String} userlistPayload
 */
export const handleAddNewInventory = async ({
  inventoryDataList, ledger, roleCid, setOpenModal, match, openAlert, isRestockNotAccount=true, userlistPayload, 
}) => {
  console.log("[InventoryListHospital] handleAddNewInventory", inventoryDataList);
  let totalres = [];

  if (inventoryDataList.length) {
    let inventorydata = [], vendor = null, invDataList = [];

    for (let i in inventoryDataList) {
      if (inventoryDataList[i].iproduct && inventoryDataList[i].iproduct.sepdistributor) {
        // create party name
        inventoryDataList[i].iproduct.sepdistributor = createPartyFromName(inventoryDataList[i].iproduct.sepdistributor);
        if (inventoryDataList[i].iproduct.sepproductmanufacturer) 
          inventoryDataList[i].iproduct.sepproductmanufacturer = createPartyFromName(inventoryDataList[i].iproduct.sepproductmanufacturer);

        if (vendor === inventoryDataList[i].iproduct.sepdistributor) {
          inventorydata.push(inventoryDataList[i]);
        }
        else {
          if (!!vendor) invDataList.push({ inventorydata, vendor });
          inventorydata = [inventoryDataList[i]];
          vendor = inventoryDataList[i].iproduct.sepdistributor;
        }
      }
    }
    if (!!vendor) invDataList.push({ inventorydata, vendor });
    console.log("[InventoryListHospital] handleAddNewInventory", invDataList);

    if (inventoryDataList.length) {
      for (let i in inventoryDataList) {

        const invdata = inventoryDataList[i];

        // create new inventory with match = true once restock inventory
        // create new inventory with match = false once account inventory
        // create inventory
        let res = null;
        if (isRestockNotAccount) {
          res = await createRestockInventoryHospital({
            ledger, 
            roleCid,
            inventorydata: invdata,
          });
        }
        else {
          res = await createAccountInventoryHospital({
            ledger, 
            roleCid,
            inventorydata: invdata,
            userlistPayload,
          });
        }
        console.log("[InventoryListHospital] handleAddNewInventory", res);

        if (!!res && typeof res === 'object') {
          // open Alert
          totalres.push(res);
        }
      }
    }

  }

  if (setOpenModal && typeof setOpenModal === 'function') {
    // close Modal
    setOpenModal(false);
  }

  if (totalres && typeof openAlert === 'function') {
    // open Alert
    openAlert(totalres);
  }
};