import React, { useState, useEffect } from "react";
import { useParty, useStreamQueries } from "@daml/react";
import { Grid, FormControlLabel, Checkbox, Typography } from "@material-ui/core";
import { PostProcedureVendor, /* PostProcedureVendorHistory */ } from "../../services/daml-modules1";
import DetailModal from "../../components/Modals/DetailModal";
import { dispatch, getState } from "../../store";
import { procedureSearch } from "../../store/actions/generalActions";
import PostProcedureListContractsVendor from "./postprocedurelistcontractsvendor";
import useStyles from "./styles";
import useStyle1 from "../surgicalevent/styles";
import classNames from "classnames";



/**
 * PPF page in Vendor
 * @returns 
 */
export default function PostProcedureListVendor() {

  const classes = useStyles();
  const classes1 = useStyle1();
  const vendor = useParty();
  const { contracts } = useStreamQueries(PostProcedureVendor, () => [{ vendor }]);
  // let { contracts: contractsHistory } = useStreamQueries(PostProcedureVendorHistory, () => [{ vendor }]);
  // contractsHistory = contractsHistory.map(h => ({ ...h, payload: h.payload.postprocedurevendor }));

  const [detailContract, setDetailContract] = useState({});
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useState({});
  const [searchHistoryParams, setSearchHistoryParams] = useState({});
  const [loading, setLoading] = useState(true);
  const [isHistory, setHistory] = useState(false);


  const [searchProcedure, setProcedureSurgical] = useState({
    physicianfirstname: '',
    physicianlastname: '',
    seid: '',
    eventdate: '',
    hospital: '',
    ...getState().procedure,
  });
  const handleChangeSearchValue = (val, key) => {
    setProcedureSurgical({ ...searchProcedure, [key]: val });
    dispatch(procedureSearch({ key, value: val }));
  };


  useEffect(() => {
    setLoading(true);

    let temp = {}, tempHistory = {};
    temp.vendor = vendor;
    temp.surgicaleventdata = {};
    if (searchProcedure.physicianfirstname !== "") temp.surgicaleventdata.physicianfirstname = searchProcedure.physicianfirstname;
    if (searchProcedure.physicianlastname !== "") temp.surgicaleventdata.physicianlastname = searchProcedure.physicianlastname;
    if (searchProcedure.seid !== "") temp.surgicaleventdata.seid = searchProcedure.seid;
    if (searchProcedure.eventdate !== "") temp.surgicaleventdata.eventdate = searchProcedure.eventdate;
    if (searchProcedure.hospital !== "") temp.hospital = searchProcedure.hospital;

    tempHistory.vendor = temp.vendor;
    tempHistory.postprocedurevendor = { ...temp };

    const delay = 0;
    if (delay > 0) setTimeout(() => {
      setSearchParams(temp);
      setSearchHistoryParams(tempHistory);
    }, delay);
    else {
      setSearchParams(temp);
      setSearchHistoryParams(tempHistory);
    }
  }, [searchProcedure, vendor]);

  useEffect(() => {
    setLoading(false);
  }, [searchParams]);



  return (
    <>

      <Grid>

        <Typography variant="h4" className={classNames(classes.pageTitle, classes1.newPageTitle)}>Post Procedure Form</Typography>

        <FormControlLabel
          className={classes.newCheckbox}
          control={
            <Checkbox
              color="primary"
              checked={isHistory}
              onChange={(e) => setHistory(e.target.checked)}
            />
          }
          label="Include Archive Contracts"
        />

        <PostProcedureListContractsVendor
          isDetailModalOrPage={false}
          setDetailModalOpen={setDetailModalOpen}
          setDetailContract={setDetailContract}
          searchParams={searchParams}
          searchHistoryParams={searchHistoryParams}
          isIncludeHistory={isHistory}
          handleChangeSearchValue={handleChangeSearchValue}
          searchProcedure={searchProcedure}
          allContracts={contracts}
          loading={loading}
        />

      </Grid>

      <DetailModal
        isButtonVisible={false}
        contract={detailContract}
        modalOpenController={detailModalOpen}
        cancelHandler={() => setDetailModalOpen(false)}
      />

    </>
  );
}
