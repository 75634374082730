import React from "react";
import { BASIC_SERVER_ENDPOINT } from "../../config";

export default function DashboardHospital() {




        return (
                <div>
                        <p> The following page will re-dircet you to Kibana Dashboard</p>
                        <p> you can use the following credential information</p>
                        <p> </p>
                        <p>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    User : bartcant </p>
                        <p>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    Password : Password1! </p>
                        <p></p>

                        <p></p>
                        <p><a href={BASIC_SERVER_ENDPOINT + "/_plugin/kibana/goto/1e6a243be67605737e43bedbd5fc6f59?security_tenant=private"} target="_blank" rel="noopener noreferrer">Click Here for Product Dashboard</a> </p>

                        <p><a href={BASIC_SERVER_ENDPOINT + "/_plugin/kibana/goto/221119f103081ad26b69716b8debc312?security_tenant=private"} target="_blank" rel="noopener noreferrer">Click Here for Product Pricing</a> </p>

                        <p><a href={BASIC_SERVER_ENDPOINT + "/_plugin/kibana/goto/96316e9ca9185f451068fcc140b77cd7?security_tenant=private"} target="_blank" rel="noopener noreferrer">Click Here for Average Based Costing  </a> </p>

                        <p><a href={BASIC_SERVER_ENDPOINT + "/_plugin/kibana/goto/4794dfeeda779856eecfa66a713cb0e9?security_tenant=private"} target="_blank" rel="noopener noreferrer">Click Here for Purchase Order History  </a> </p>

                </div >
        )
}
