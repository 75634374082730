import React from "react";
import { useParty, useStreamQueries } from "@daml/react";
import { Grid, Typography } from "@material-ui/core";
import { DiscoverPayment } from "../../services/daml-modules1";
import ReceivedPaymentsListContracts from "./receivedpaymentscontracts1";
import useStyles1 from "../surgicalevent/styles";
import classNames from "classnames";

function ReceivedPaymentsList() {

    const classes1 = useStyles1();
    const vendor = useParty();


    const { contracts, loading } =  useStreamQueries(DiscoverPayment, () => [{ vendor }]);

    return (
        <>

            <Grid>

                <Typography variant="h4" className={classNames(classes1.pageTitle, classes1.newPageTitle)}>Received Payments</Typography>

                <ReceivedPaymentsListContracts
                    className={classes1.customRoot}
                    allContracts={contracts}
                    loading={loading}
                />

            </Grid>

        </>
    )
}


export default ReceivedPaymentsList;