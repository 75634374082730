import React, { useState, useEffect } from "react";


/**
 * Callback Cell
 * @param {Function} callback 
 * @param {String|Object} initValue 
 * @returns 
 */
export default function CallbackCell({
  callback,
  initValue,
}) {
  // console.log("[CallbackCell]", initValue);
  
  const [value, setValue] = useState(null);


  useEffect(() => {
    if (typeof callback === 'function') {
      callback(initValue, setValue);
    }
  }, [callback, initValue]);


  return (
    <>
      {value}
    </>
  );

}
