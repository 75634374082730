import React, { useEffect } from "react";
import { Button } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { CloseOutlined, Chat as ChatIcon } from "@material-ui/icons";
// import classNames from "classnames";
import { useLayoutState, useLayoutDispatch, toggleChat } from "../../context/LayoutContext";
import { useDamlState } from '../../context/DamlContext';
import { DamlChat } from "../../chat/Chat/DamlChat";
import useStyles from "./styles";


function ChatBox() {
  var classes = useStyles();
  var theme = useTheme();
  const layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  const { role, party } = useDamlState();

  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && layoutState.isChatOpened) {
      //
    } else {
      //
    }
  }

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    role === null
    ?
    <></>
    :
    <div className={classes.root}>
        <div style={{display: (layoutState.isChatOpened ? 'inline-block' : 'none')}}>
          <div className={classes.header}>
            Chat
            <Button 
              className={classes.closeIcon}
              onClick={() => toggleChat(layoutDispatch)} 
              style={{color: '#fff'}}
            >
              <CloseOutlined />
            </Button>
          </div>
          {
            party && role &&
            <DamlChat name={party} role={role} />
          }
        </div>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => toggleChat(layoutDispatch)}
          className={classes.button}
          style={{display: (!layoutState.isChatOpened ? 'inline-flex' : 'none')}}
          endIcon={<ChatIcon />}
        >
          Chat
        </Button>
    </div>
  );

}

export default ChatBox;
