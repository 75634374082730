import React, { useState, useEffect, Fragment } from "react";
import { useStreamQueries, useParty, useLedger } from "@daml/react";
import {
    AppBar, Button, Box, Typography, Tabs, Tab, Accordion, AccordionDetails, AccordionSummary, Grid, Tooltip, IconButton,
    CircularProgress
} from "@material-ui/core";
import useStyles from "../showlist/styles";
import useStyles1 from "../surgicalevent/styles";
import useStyles2 from "../purchaseorder/styles";
import classNames from "classnames";
import TabPanel, { a11yProps } from "../../components/Modals/MultiTabsModals/TabPanel";
import { CustomTextField, CustomSelect } from "../../components/Inputs";
import ShipAddressPopUp from './shipAddressPopUp';
import AdminContact from "./AdminContact.js";
import AddIcon from '@material-ui/icons/Add';
import FileUploadDropzone from "../../components/FileUpload/FileUploadDropzone";
import { HospitalRole } from "../../services/daml-modules1";
import { useDamlState, useDamlDispatch } from '../../context/DamlContext';
import { getOperatorParty } from "../../services/axios/daml-json-api";
import { getCanadaStates, getUsStates } from "../../models/State";
import { HospitalDetailModel } from "../../models/HospitalDetail";
import { onChangeNumber } from "../../components/Modals/handles";
import { setByPath } from "../../components/Contracts/functions";
import { exerciseRoleTwoAccept } from "../showlist/handle";
import { DAML_CLEAR_ROLE } from "../../store/actions/constants";
import { CustomLoading } from "../../hoc/withLoading";
import SnackbarModal from "../../components/Modals/SnackbarModal";
import NoteEditSolid from "../../icons/clarity_note-edit-solid.svg";
import Vector from "../../icons/Vector.svg";
import BiTrashFill from "../../icons/bi_trash-fill.svg";
import PdfLogo from "../../icons/PdfLogo.svg";
import Pdf from "../../icons/pdf1.svg";
import { getW9Form } from "../../models/Contact";
import { NODE_SERVER_ENDPOINT } from '../../config';
import View from "../../icons/view.svg";
import ModalDialog from '../../components/Modals/NewGeneralModal';
import { useTranslation } from 'react-i18next';

export default function HospitalProfile() {

    const { role, rolePayload } = useDamlState();
    const party = useParty();
    const operator = getOperatorParty();
    const ledger = useLedger();
    const dispatch = useDamlDispatch();

    let searchParams = {};
    if (role === "Operator") searchParams = { operator: operator };
    else if (role === "Hospital") searchParams = { hospital: party };
    const assets = useStreamQueries(HospitalRole, () => [searchParams]);

    const [contractId, setContractId] = useState('');
    const [hospitaldetails, setHospitalDetails] = useState({ ...HospitalDetailModel });
    const [file, setFile] = useState(null);
    const [openShipAddressPopup, setOpenShipAddressPopup] = useState(false);
    const [isAddNew, setIsAddNew] = useState({ new: false, ship: false });
    const [value, setValue] = useState(0);
    const [showPreview, setShowPreview] = useState(true);
    const [states, setStates] = useState(getUsStates);

    // progress loading 
    const [isLoading, setIsLoading] = useState(false);

    //  styles
    const classes = useStyles();
    const classes1 = useStyles1();
    const classes2 = useStyles2();

    // Accordion
    const [expanded1, setExpanded1] = useState(0);
    const [expanded2, setExpanded2] = useState(0);
    const [isClick, setIsClick] = useState(false);
    const [openUploadedFile, setOpenUploadedFile] = useState(false);
    const [url, setUrl] = useState("");


    useEffect(() => {
        if (file === null) {
            setIsClick(false);
            setShowPreview(false);
        } else {
            setShowPreview(true);
        }
    }, [file])

    const handleChangeAccordion1 = (panel) => (event, isExpanded) => {
        setExpanded1(isExpanded ? panel : false);
        setExpanded2(false);
    };

    const handleChangeAccordion2 = (panel) => (event, isExpanded) => {
        setExpanded2(isExpanded ? panel : false);
        setExpanded1(false);
    };

    useEffect(() => {
        if (openShipAddressPopup) {
            setExpanded2(false);
            setExpanded1(false);
        }
    }, [openShipAddressPopup])

    // handle to update all fields
    const updateField = (key, isNumber = false) => (e) => {
        if (!isNumber || (isNumber && onChangeNumber(e))) setHospitalDetails(setByPath(e, key, hospitaldetails));
    };

    // handle when click "Update" button
    const handleRequest = async () => {
        if (file) {
            const fileResponse = await handleUploader();
            if (fileResponse.status === 200) {
                let newHospital = {};
                newHospital.w9Form = [{ filename: file.name, filetype: file.type }];
                newHospital.w9Form = getW9Form(newHospital.w9Form[0]);
                const temp = { ...hospitaldetails, w9Form: [{ filename: newHospital.w9Form.filename, filetype: newHospital.w9Form.filetype }] };
                let res = await exerciseRoleTwoAccept(temp, ledger, contractId);
                setIsDeleted(false);
                if (res) dispatch({
                    type: DAML_CLEAR_ROLE,
                });
            }
        } else {
            let res = await exerciseRoleTwoAccept(hospitaldetails, ledger, contractId);
            if (res) dispatch({
                type: DAML_CLEAR_ROLE,
            });
        }
        setFile(null);
        setToastContent("Saved successfully")
        setOpenShipAddressPopup(false);
        setIsAddNew({ new: false, ship: false });
        setOpenToast(true);
    };

    // handle when click "Cancel" button
    function handleCancel() {
        setOpenShipAddressPopup(false);
        setIsAddNew({ new: false, ship: false });
    };

    function handleDelete(key, ind) {
        if (key) {
            hospitaldetails[key].splice(ind, 1);
        }
        setIsAddNew({ new: false, ship: false });
    };

    function handleUpload(file, callback) {
        file && setIsLoading(true);
        setFile(file);
    };

    const handleUploader = async () => {
        let formData = new FormData()
        formData.append('file', file)
        const response = await fetch(NODE_SERVER_ENDPOINT + 'api/w9-form/upload', {
            method: 'POST',
            body: formData,
        })
        return response;
    };

    const viewUploadedFile = () => {
        setOpenUploadedFile(true);
    };

    useEffect(() => {
        if (!assets.loading && assets.contracts.length) {
            setContractId(assets.contracts[0].contractId);
            setHospitalDetails({ ...assets.contracts[0].payload.hospitaldetails });
            if (assets.contracts[0].payload.hospitaldetails.w9Form.length === 0) {
                setIsDeleted(true);
            } else {
                setIsDeleted(false);
            }
            setFile(null);
        }
    }, [assets]);

    const handleAwsDelete = async () => {
        const response = await fetch(
            `${NODE_SERVER_ENDPOINT}api/w9-form/delete/${assets.contracts[0].payload.hospitaldetails.w9Form[0].filename}`,
            {
                method: "DELETE",
            }
        );
        // const data  = await response.json();
        if (response.status === 200) {
            const temp = { ...hospitaldetails, w9Form: [] };
            let res = await exerciseRoleTwoAccept(temp, ledger, contractId);
            if (res) {
                setToastContent("File deleted successfully.")
                setOpenToast(true);
                setIsDeleted(true);
                dispatch({
                    type: DAML_CLEAR_ROLE,
                });
            }
        }
    }


    useEffect(() => {
        async function fetchData() {
            try {
                if (!assets.loading && assets.contracts.length) {
                    const w9Forms = assets.contracts[0].payload.hospitaldetails.w9Form[0];
                    if (w9Forms && w9Forms.filename) {
                        const response = await fetch(
                            `${NODE_SERVER_ENDPOINT}api/w9-form/download/${w9Forms.filename}`,
                            {
                                method: "GET",
                            }
                        );
                        const { data } = await response.json();
                        setUrl(data);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [assets]);

    console.log("[HospitalList]", party, assets);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function handleCountry(e, key) {
        updateField(key)(e);
        if (e === "US")
            setStates(getUsStates);
        if (e === "CA")
            setStates(getCanadaStates);
    }

    const GetCountryList = {
        US: 'US',
        CA: 'Canada'
    }

    console.log("[hospitaldetails],hospitaldetails", hospitaldetails);

    const GetStateList = states && states.map(s => {
        return { label: s[1], value: s[0] };
    });


    // new codes
    const [selectedContact, setSelectedContact] = useState({ index: -1, contact: null });
    const [selectedKey, setSelectedKey] = useState();
    const [openContactModal, setOpenContactModal] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    // Toast
    const [openToast, setOpenToast] = useState(false);
    const [toastContent, setToastContent] = useState('');
    // handle when click Edit button
    const handleEditDetails = (contact) => {
        let contactList = hospitaldetails[selectedKey];
        if (selectedContact?.index >= 0) {
            contactList.splice(selectedContact?.index, 1);
            contactList.splice(selectedContact?.index, 0, contact);
            hospitaldetails[selectedKey] = contactList
        } else {
            hospitaldetails[selectedKey] = (contactList ? contactList.concat(contact) : [contact])
        }
        setOpenContactModal(false);
    };

    const handleOpenAddModal = (event, key, shipping) => {
        setIsAddNew({ new: true, ship: shipping });
        setSelectedContact({ index: -1, contact: null })
        setSelectedKey(key);
        setOpenContactModal(true);
    };

    const handleBox = (name) => {
        let _name;
        let classname;
        if (!name && assets.contracts[0].payload.hospitaldetails?.w9Form.length) {
            _name = assets.contracts[0].payload.hospitaldetails?.w9Form[0]?.filename;
            classname = classes.imgNew;
        } else _name = name;
        return (
            <Box className={classNames(classname, "preview-item", classes.positionBottom)}>
                <Grid container>
                    <Grid item className={classes.divCenter} xs={8}>
                        <img src={Pdf} className={classes.image} alt="" />
                        <Typography className={classes.fileName}>{_name}</Typography>
                        
                    </Grid>
                    {
                        (file === null) && !isDeleted &&
                        <Grid item className={classes.imgAction} xs={4}>
                            
                            <IconButton
                                onClick={viewUploadedFile}
                                className={classes.iconSize}>
                                <img src={View} className={classes.imgAction1} alt="view-uploaded-file" />
                            </IconButton>
                            <IconButton
                                onClick={handleAwsDelete}
                                className={classes.iconSize}>
                                <img src={BiTrashFill} alt="delete-uploaded-file" />
                            </IconButton>
                        </Grid>
                    }
                </Grid>
            </Box>
        )
    }

    // preview icon of uploader
    const handlePreviewIcon = (fileObject, classes) => {
        const { type, name } = fileObject.file;
        console.log("[handlePreviewIcon]", type, name);
        setIsLoading(false);
        if (name) setIsClick(true);
        return (
            isLoading ?
                <CircularProgress size={22} className={classes.loginLoader} /> :
                <>
                    {handleBox(name)}

                </>
        );
    };

    const [tabMenus, setTabMenus] = useState([]);
    const standalone_payment = rolePayload?.hospitaldetails?.standalone_payment;
    const { t } = useTranslation();

    useEffect(() => {
        setTabMenus(standalone_payment === undefined ? [] : (standalone_payment ? ["Facility Information"] : ["Facility Information", "Admistrative Contacts", "ERP Integration"]));
    }, [standalone_payment])

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Typography className={classes.activeTabTitle}>{t('admin.settings.tabs.hospital')}</Typography>
                </Grid>
                {
                    assets.loading
                        ?
                        <CustomLoading />
                        :
                        <>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={10} className={classes.centerPanel}>
                                <AppBar position="static" className={classes.newTabPanelMenu}>
                                    <Tabs
                                        indicatorColor="primary"
                                        textColor="primary"
                                        value={value}
                                        onChange={handleChange} >
                                        {
                                            tabMenus.map((t, i) => <Tab key={t} label={t} className={classes1.subTitles} {...a11yProps(i)} />)
                                        }
                                    </Tabs>
                                </AppBar>
                                <TabPanel value={value} index={0} className={classNames(classes1.tabPanelbody1)}>
                                    <Grid container item sx={12} >
                                        <Grid item xs={4} style={{ height: '49vh' }}>
                                            <Grid container sx={12}
                                                direction="row"
                                                justifyContent="space-around"
                                                alignItems="center">

                                                <Grid item xs={11}>
                                                    <Typography className={classes.sectionDetailTitle}>
                                                        Facility Name
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3} className={classes.tabLabel}>
                                                    <label>Facility Name</label>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <CustomTextField
                                                        // options={{ disabled: true }}
                                                        width={'60%'}
                                                        placeholder='eg. FirstHealth'
                                                        value={hospitaldetails?.facilityname}
                                                        onChange={updateField('facilityname')}
                                                    />
                                                </Grid>
                                                <Grid item xs={11}> <Typography className={classes.sectionDetailTitle}>Physical Address</Typography></Grid>

                                                <Grid item xs={3} className={classes.tabLabel} ><label >Address</label></Grid>
                                                <Grid item xs={8}>
                                                    <CustomTextField
                                                        value={hospitaldetails?.physicaladdress?.address1 || ""}
                                                        onChange={updateField('physicaladdress.address1')}
                                                        width={'90%'}
                                                        placeholder='Type here...'
                                                    />
                                                </Grid>

                                                <Grid item xs={3} className={classes.tabLabel}><label>City</label></Grid>
                                                <Grid item xs={8}>
                                                    <CustomTextField
                                                        value={hospitaldetails?.physicaladdress?.city || ""}
                                                        onChange={updateField('physicaladdress.city')}
                                                        placeholder="Please select"
                                                        width={'90%'}
                                                    />
                                                </Grid>

                                                <Grid item xs={3} className={classes.tabLabel}><label>Zipcode</label></Grid>
                                                <Grid item xs={8}>
                                                    <CustomTextField
                                                        width={'90%'}
                                                        placeholder='Type here...'
                                                        variant="outlined"
                                                        value={hospitaldetails?.physicaladdress?.zipcode || ""}
                                                        onChange={updateField('physicaladdress.zipcode')}
                                                    />
                                                </Grid>

                                                <Grid item xs={3} className={classes.tabLabel}><label>Country</label></Grid>
                                                <Grid item xs={8}>
                                                    <CustomSelect
                                                        className={classes.customFullWidth}
                                                        onChange={(e) => handleCountry(e, 'physicaladdress.country')}
                                                        value={hospitaldetails?.physicaladdress?.country || ""}
                                                        items={GetCountryList}
                                                        placeholder='Select One'
                                                        width={'90%'}
                                                    />
                                                </Grid>
                                                <Grid item xs={3} className={classes.tabLabel}><label>State</label></Grid>
                                                <Grid item xs={8}>
                                                    <CustomSelect
                                                        className={classes.customFullWidth}
                                                        onChange={updateField('physicaladdress.state')}
                                                        value={hospitaldetails?.physicaladdress?.state || ""}
                                                        items={GetStateList}
                                                        placeholder='Select One'
                                                        width={'90%'}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={5} className={classes.detailedOverflow} style={{ height: '49vh' }}>
                                            <div className="item">
                                                <Grid container className={classes.detailedContainer}>
                                                    <Grid item xs={6}>
                                                        <Grid>
                                                            <label style={{ padding: '0px 45px 0px 0px' }} className={classNames(classes.tabLabel, classes.labels)}>Shipping Address</label>
                                                            <Button variant="contained" color="primary" className={classNames(classes.textFormation, classes.btnAddress)}
                                                                onClick={(event) => handleOpenAddModal(event, 'shippingaddress', true)}
                                                                size="small"
                                                                startIcon={<AddIcon />}
                                                            >
                                                            </Button>
                                                        </Grid>

                                                        {hospitaldetails?.shippingaddress?.map((obj, index) => (
                                                            <Grid className={classes.productAccordion} key={index}>
                                                                <Accordion
                                                                    key={`ship${index}`}
                                                                    expanded={expanded1 === (index + 1)}
                                                                    onChange={handleChangeAccordion1((index + 1))}
                                                                    className={classes.accordion_main1}
                                                                    style={{ padding: '1px 0px 1px 0px' }}
                                                                >
                                                                    <AccordionSummary
                                                                        expandIcon={<img src={Vector} alt='vector-arrow' />}
                                                                        aria-controls="panel1bh-content"
                                                                        id="panel1bh-header"
                                                                        className={classes.accordionSummeryDark}
                                                                    >
                                                                        <div style={{ display: 'flex' }}>
                                                                            <span className={classes.nicknameSize}>{obj.nickname}</span>
                                                                            <span className={classes.accordionIcon}
                                                                            >
                                                                                <Tooltip title="">
                                                                                    <IconButton
                                                                                        onClick={() => handleDelete('shippingaddress', index)}
                                                                                        className={classes.iconSize}>
                                                                                        <img src={BiTrashFill} alt="delete" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip title="">
                                                                                    <IconButton className={classes.iconSize}
                                                                                        onClick={() => {
                                                                                            setSelectedKey('shippingaddress');
                                                                                            setSelectedContact({ index: index, contact: obj });
                                                                                            setExpanded1(false);
                                                                                            setExpanded2(false);
                                                                                            setIsAddNew({ new: false, ship: true });
                                                                                            setOpenContactModal(true);
                                                                                        }}>
                                                                                        <img src={NoteEditSolid} alt="edit" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </span>
                                                                        </div>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails className={classes.accordionDetailsDark}>
                                                                        <Grid container spacing={2} className={classes.gridItem}
                                                                        >
                                                                            {
                                                                                [
                                                                                    {
                                                                                        title: 'Nickname', value: obj.nickname || ""
                                                                                    },
                                                                                    {
                                                                                        title: 'Address Type', value: obj.adddresstype || ""
                                                                                    },
                                                                                    {
                                                                                        title: 'Address', value: obj.address1 || ""
                                                                                    },
                                                                                    {
                                                                                        title: 'Location', value: obj.location || ""
                                                                                    },
                                                                                    {
                                                                                        title: 'City', value: obj.city || ""
                                                                                    },
                                                                                    {
                                                                                        title: 'State', value: obj.state || ""
                                                                                    },
                                                                                    {
                                                                                        title: 'Zipcode', value: obj.zipcode || ""
                                                                                    },
                                                                                    {
                                                                                        title: 'Country', value: obj.country || ""
                                                                                    },

                                                                                ].map((item, index) => {
                                                                                    return (<Fragment key={index}>
                                                                                        <Grid item xs={5} className={classes.gridSubItem}>
                                                                                            <label className={classes.acordionSubtitle}>{item.title}</label>
                                                                                        </Grid>
                                                                                        <Grid item xs={7} className={classes.gridSubItem}>
                                                                                            <Typography className={classes.acordionDetails}>{item.value}</Typography>
                                                                                        </Grid>
                                                                                    </Fragment>)
                                                                                })
                                                                            }
                                                                        </Grid>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </Grid>
                                                        )).reverse()}
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Grid>
                                                            <label style={{ padding: '0px 63px 0px 0px' }} className={classNames(classes.tabLabel, classes.labels)}>Billing Address</label>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                className={classNames(classes.textFormation, classes.btnAddress)}
                                                                size='small'
                                                                onClick={(event) => handleOpenAddModal(event, 'billingaddress', false)}
                                                                startIcon={<AddIcon />}
                                                            >
                                                            </Button>
                                                        </Grid>
                                                        {hospitaldetails?.billingaddress?.map((obj, index) => (
                                                            <Grid className={classes.productAccordion} key={index}>
                                                                <Accordion
                                                                    key={`bill${index}`}
                                                                    expanded={expanded2 === (index + 1)}
                                                                    onChange={handleChangeAccordion2((index + 1))}
                                                                    className={classes.accordion_main1}
                                                                    style={{ padding: '1px 0px 1px 0px' }}
                                                                >
                                                                    <AccordionSummary
                                                                        expandIcon={<img src={Vector} alt='vecot-arrow' />}
                                                                        aria-controls="panel2bh-content"
                                                                        id="panel2bh-header"
                                                                        className={classes.accordionSummeryDark}
                                                                    >
                                                                        <div style={{ display: 'flex' }}>
                                                                            <span className={classes.nicknameSize}>{obj.nickname}</span>
                                                                            <span className={classes.accordionIcon}>
                                                                                <Tooltip title="">
                                                                                    <IconButton
                                                                                        onClick={() => handleDelete('billingaddress', index)}
                                                                                        className={classes.iconSize}>
                                                                                        <img src={BiTrashFill} alt="delete" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip title="">
                                                                                    <IconButton className={classes.iconSize}
                                                                                        onClick={() => {
                                                                                            setSelectedKey('billingaddress');
                                                                                            setSelectedContact({ index: index, contact: obj });
                                                                                            setExpanded1(false);
                                                                                            setExpanded2(false);
                                                                                            setIsAddNew({ new: false, ship: false });
                                                                                            setOpenContactModal(true);
                                                                                        }}>
                                                                                        <img src={NoteEditSolid} alt="edit" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </span>
                                                                        </div>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails className={classes.accordionDetailsDark}>
                                                                        <Grid container spacing={2} className={classes.gridItem}
                                                                        >
                                                                            {
                                                                                [
                                                                                    {
                                                                                        title: 'Nickname', value: obj.nickname || ""
                                                                                    },
                                                                                    {
                                                                                        title: 'Address Type', value: obj.adddresstype || ""
                                                                                    },
                                                                                    {
                                                                                        title: 'Address', value: obj.address1 || ""
                                                                                    },
                                                                                    {
                                                                                        title: 'Location', value: obj.location || ""
                                                                                    },
                                                                                    {
                                                                                        title: 'City', value: obj.city || ""
                                                                                    },
                                                                                    {
                                                                                        title: 'State', value: obj.state || ""
                                                                                    },
                                                                                    {
                                                                                        title: 'Zipcode', value: obj.zipcode || ""
                                                                                    },
                                                                                    {
                                                                                        title: 'Country', value: obj.country || ""
                                                                                    },

                                                                                ].map((item, ind) => {
                                                                                    return (<Fragment key={ind}>
                                                                                        <Grid item xs={5} className={classes.gridSubItem}>
                                                                                            <label className={classes.acordionSubtitle}>{item.title}</label>
                                                                                        </Grid>
                                                                                        <Grid item xs={7} className={classes.gridSubItem}>
                                                                                            <Typography className={classes.acordionDetails}>{item.value}</Typography>
                                                                                        </Grid>
                                                                                    </Fragment>)
                                                                                })
                                                                            }
                                                                        </Grid>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </Grid>
                                                        )).reverse()}
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3} style={{ height: '49vh' }}>
                                            <Grid container sx={12} >
                                                <Grid item xs={1}> </Grid>
                                                <Grid item xs={11}> <Typography className={classes.sectionDetailTitle}>Tax ID</Typography></Grid>
                                                <Grid item xs={1}> </Grid>
                                                <Grid item xs={4} className={classes.tabLabel}><label>Tax ID</label></Grid>
                                                <Grid item xs={7}>
                                                    <CustomTextField
                                                        value={hospitaldetails.taxid}
                                                        onChange={updateField('taxid')}
                                                        width={'80%'}
                                                        placeholder='FirstHealth'
                                                        borderRadius={'10px'}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}> </Grid>
                                                <Grid item xs={10} style={{ padding: '22px 30px 0px 0px' }}>
                                                    <FileUploadDropzone
                                                        className={classNames(classes2.uploadFile, classes.uploadFile1, classes2.uploadFile1, classes2.uploadFile3)}
                                                        title='Upload W9 Form'
                                                        uploadLabel={(
                                                            <>
                                                                <Box className={classes.btnUploader}>
                                                                    <img src={PdfLogo} alt='' className={classes.pdfLogos} />
                                                                    Drop your PDF file here, or browse
                                                                </Box>
                                                                <p className={classes.sampleFilePreview}>Sample file.PDF</p>
                                                            </>
                                                        )}
                                                        changeFile={handleUpload}
                                                        isNonePadding={true}
                                                        isEnableUpload={false}
                                                        handlePreviewIcon={handlePreviewIcon}
                                                        showFile={showPreview}
                                                    />
                                                    {
                                                        !isClick && (assets.contracts[0].payload.hospitaldetails?.w9Form.length !== 0) && handleBox()
                                                    }
                                                </Grid>
                                                <Grid item xs={1}> </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid xs={12} item
                                            className={classes.actionBtn}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleCancel}
                                                className={classNames(classes1.newCompleteBtn, "secondary-color")}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleRequest}
                                                className={classNames(classes1.newCompleteBtn, "primary-color")}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                {
                                    standalone_payment ? null :
                                        <TabPanel value={value} index={1} className={classes1.tabPanelbody1}>
                                            <AdminContact
                                                hospitaldetails={hospitaldetails}
                                                setHospitalDetails={setHospitalDetails}
                                                handleSave={handleRequest}
                                                handleCancel={() => { }}
                                            />
                                        </TabPanel>
                                }

                            </Grid >
                            <Grid item xs={1}></Grid>

                            {openContactModal ? <ShipAddressPopUp
                                openModal={openContactModal}
                                handleSave={handleEditDetails}
                                setOpenToast={setOpenToast}
                                setToastContent={setToastContent}
                                handleCancel={() => {
                                    setOpenContactModal(false);
                                }}
                                isAddNew={isAddNew}
                                data={selectedContact.contact}
                                hospitaldetails={hospitaldetails}
                                setHospitalDetails={setHospitalDetails}
                            /> : null}
                            <SnackbarModal
                                open={openToast}
                                setOpen={setOpenToast}
                                title=""
                                content={toastContent}
                                width={400}
                                height={88}
                                className={classes1.toastMedium}
                            />
                        </>
                }
            </Grid >
            <ModalDialog
                open={openUploadedFile}
                setOpen={setOpenUploadedFile}
                className={classes.newModalViewer}
                height="600"
                content={(
                    <>
                        <iframe title="iframe-url" src={url} height="100%" width="100%" />
                    </>
                )}
            />
        </>
    )
}
