import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useLedger } from "@daml/react";
import { useDamlState } from "../../../context/DamlContext";
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Button, Box } from "@material-ui/core";
import exportIcon from "../../../icons/new-export-icon.svg";
import useStyles from "../styles";
import ModalDialog from "../../../components/Modals/NewGeneralModal";
import scanIcon from "../../../icons/scan_icon.svg";
import PrefcaseUsageCategories from "./caseUsageCategories";
import { updatePrefCardDetails } from "../handle";
import SnackbarModal from "../../../components/Modals/SnackbarModal";
import usePrefReducer from "../../../hooks/usePrefReducer";
import { PREF_LOAD_EVENT } from "../../../store/actions/constants";
import useEvent from "../../../hooks/useEvent";

/**
 * @returns
 * @param {Boolean} open
 * @param {Function} setOpen
 * @param {Object} detailData
 */

export default function PrefCaseUsage({
  open,
  setOpen,
  detailData,
}) {
  const { t } = useTranslation();
  const { state, dispatch } = usePrefReducer(detailData);
  const detailDataOriginal = JSON.stringify(detailData);
  const { roleCid } = useDamlState();
  const [snackbarModel, setSnackbarModel] = useState(false);
  const [changedWarning, setChangedWarning] = useState(false);
  const classes = useStyles();
  const [openCost, setOpenCost] = useState(0);
  const [pulledCost, setPulledCost] = useState(0);
  const [holdCost, setHoldCost] = useState(0);
  const ledger = useLedger();
  const [openToast, setOpenToast] = useState(false);
  const [toastContent, setToastContent] = useState("");

  const { onEventStart } = useEvent();
  useEffect(() => {
    const calculateCost = () => {
      const itemList = state?.items;

      return itemList?.reduce(
        (acc, currentValue) => {
          let openSum = 0;
          let holdSum = 0;
          let pulledSum = 0;
          currentValue.items.forEach((item) => {
            openSum = openSum + parseInt(item.open) * item?.pseudoItem.cost;
            holdSum = holdSum + parseInt(item.closed) * item?.pseudoItem.cost;
            pulledSum =
              pulledSum +
              (parseInt(item.closed) + parseInt(item.closed)) *
                item?.pseudoItem.cost;
          });
          return {
            openCost: acc.openCost + openSum.toFixed(2),
            holdCost: acc.holdCost + holdSum.toFixed(2),
            pulledCost: acc.pulledCost + pulledSum.toFixed(2),
          };
        },
        { openCost: 0, holdCost: 0, pulledCost: 0 }
      );
    };
    const costs = calculateCost();
    setOpenCost(costs?.openCost);
    setHoldCost(costs?.holdCost);
    setPulledCost(costs?.pulledCost);
  }, [state]);

  const handleNext = async () => {
    if (!state.isChecked) {
      setSnackbarModel(true);
    } else {
      setOpenToast(true);
      setToastContent("All items have been picked");
      await handleSave();
      onEventStart(PREF_LOAD_EVENT);
      setOpen(false);
    }
  };
  const handleSave = async () => {
    let totalproducts = state.items.reduce((accumulator, currentCategory) => {
      let products = currentCategory.items.reduce(
        (consumedproduct, currentproduct) => {
          if (currentproduct.isChecked) {
            if (
              currentproduct.pseudoItem.vendor &&
              currentproduct.pseudoItem.referenceNumber
            ) {
              consumedproduct.push({
                vendor: currentproduct.pseudoItem.vendor,
                reference: currentproduct.pseudoItem.referenceNumber,
                quantity: parseInt(currentproduct.used),
                price: parseFloat(currentproduct.pseudoItem.cost),
              });
            }
          }
          return consumedproduct;
        },
        []
      );
      return [...accumulator, ...products];
    }, []);
    let detailData = JSON.parse(JSON.stringify(state));
    delete detailData.contractid;
    await updatePrefCardDetails({
      ledger,
      roleCid,
      prefId: state.contractid,
      detailData,
      totalproducts,
    });
    setSnackbarModel(false);
    setOpen(false);
    setOpenToast(false);
  };
  // handle to close
  const handleClose = () => {
    if (detailDataOriginal !== JSON.stringify(state)) setChangedWarning(true);
    else {
      setSnackbarModel(false);
      setOpen(false);
    }
  };
  return (
    <>
      <ModalDialog
        open={open}
        setOpen={setOpen}
        handleCloseRequest={handleClose}
        title={
          <>
            <Grid container className={classes.newTitlePanel}>
              <Grid item xs={3}>
                <Typography
                  variant="inherit"
                  component="span"
                  className="label"
                >
                  Case Usage
                </Typography>
              </Grid>

              <Grid item xs={6} className={classes.dialogSubheading}>
                <Typography
                  variant="inherit"
                  component="span"
                  className="detailLabel"
                >
                  {state.name}{" "}
                  <span className="details">
                    {" "}
                    Open: ${parseFloat(openCost)} | Pulled: ${parseFloat(pulledCost)}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={3} className="rightContent">
                <Button
                  className={classNames(
                    classes.newCreateButton,
                    classes.newIconButton
                  )}
                  variant="contained"
                  // onClick={handleOpenPopover}
                  startIcon={<img alt="" src={scanIcon} />}
                />
                <Button
                  className={classNames(
                    classes.newCreateButton,
                    classes.newIconButton
                  )}
                  variant="contained"
                  // onClick={handleOpenPopover}
                  startIcon={<img alt="" src={exportIcon} />}
                />
              </Grid>
            </Grid>
          </>
        }
        content={
          <>
            <Box className={classes.newPopoverBox}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                className={classes.newTitlePanel}
              >
                <Grid
                  item
                  xs={12}
                  className={classNames(
                    classes.overviewGrid,
                    classes.prefCategoryTable
                  )}
                >
                  <PrefcaseUsageCategories
                    detailData={state}
                    dispatch={dispatch}
                  />
                  <Typography className={classes.newtableTitle}>
                    Total
                  </Typography>
                  <Typography>
                    Open: <span className={classes.boldCount}>${parseFloat(openCost)}</span>{" "}
                    Hold: <span className={classes.boldCount}>${parseFloat(holdCost)}</span>
                  </Typography>
                  <Typography>
                    Total Cost : 
                    <span className={classes.boldCount}>  
                      ${parseFloat(openCost) + parseFloat(holdCost)}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <SnackbarModal
              open={snackbarModel}
              setOpen={setSnackbarModel}
              type="warning"
              title={"Warning"}
              content={t('warnings.caseusage.message1')}
              vertical="center"
              horizontal="center"
              width={400}
              height={150}
              transition="fade"
              className={classNames(classes.toastLarge, classes.toastWarning)}
              options={{
                delay: 500,
                validateULE: true,
              }}
              actions={[
                {
                  label: "Yes",
                  handle: async () => {
                   await handleSave();
                    onEventStart(PREF_LOAD_EVENT);
                  },
                },
                {
                  label: "No",
                  handle: () => {
                    handleClose();
                  },
                },
              ]}
            />

            <SnackbarModal
              open={openToast}
              setOpen={setOpenToast}
              title=""
              content={toastContent}
              width={330}
              height={88}
              className={classes.toastMedium}
            />
            <SnackbarModal
              open={changedWarning}
              setOpen={setChangedWarning}
              type="warning"
              content={t('warnings.surgicalevent.removedproduct.message')}
              vertical="center"
              horizontal="center"
              width={400}
              height={150}
              transition="fade"
              className={classNames(classes.toastLarge, classes.toastWarning)}
              options={{
                delay: 500,
                validateULE: true,
              }}
              actions={[
                {
                  label: "Yes",
                  handle: async () => {
                    await handleSave();
                    onEventStart(PREF_LOAD_EVENT);
                    setChangedWarning(false);
                    setOpen(false);
                  },
                },
                {
                  label: "No",
                  handle: () => {
                    setChangedWarning(false);
                  },
                },
              ]}
            />
          </>
        }
        actions={
          <>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClose}
              className={classNames(classes.newCompleteBtn, "secondary-color")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              className={classes.newCompleteBtn}
            >
              Save
            </Button>
          </>
        }
        width={1203}
        height={689}
      />
    </>
  );
}
