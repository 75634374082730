import React from "react";
import { Grid, Typography, Card, CardContent } from "@material-ui/core";
import { getFormattedLastLoginDate } from "../../services/util";
import makeStyles from "./styles";
import { useTranslation } from 'react-i18next';

export default function WelcomeCard({
  userName,
  lastLogin,
  completedCases,
  closedPOs,
}) {
  const { t } = useTranslation();
  const classes = makeStyles();
  return (
    <div>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography
                gutterBottom
                variant="h1"
                component="div"
                className={classes.welcomeBack}
              >
                {t('dashboard.welcome.title')},{" "}
                <span className={classes.userName}>{userName}</span>
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Last login on{" "}
                {lastLogin && getFormattedLastLoginDate(new Date(lastLogin))}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
