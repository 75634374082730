import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Avatar, IconButton, TablePagination, Menu, MenuItem } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import CustomCheckbox from "../CheckBoxList/CustomCheckbox";
import paginationRightIcon from "../../icons/pagination-right.svg";
import { useStyles } from "./styles";

/**
 * Custom Pagination
 * @param {Int} count 
 * @param {Int} page 
 * @param {Function} setPage 
 * @param {Int} rowsPerPage 
 * @param {Function} setRowsPerPage 
 * @param {Object} options 
 * @returns 
 */
export function CustomPagination({
  count, page, setPage, rowsPerPage, setRowsPerPage, options={},
}) {
  
  const classes = useStyles();

  options = {
    isPaginationChangeable: true,
    ...options,
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TablePagination
        rowsPerPageOptions={(options.isPaginationChangeable ? [10, 25, 50, 100] : [])}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={classes.TablePagination}
      />
   </>
  );
}

/**
 * Custom Pagination
 * @param {Int} count 
 * @param {Int} page 
 * @param {Function} setPage 
 * @param {Int} rowsPerPage 
 * @param {Object} options 
 * @param {Function} handleCheckbox 
 * @param {Function} setRowsPerPage 
 * @returns 
 */
export function CustomPagination1({
  count, page, setPage, rowsPerPage, options={}, handleCheckbox=null, setRowsPerPage,
}) {
  
  const classes = useStyles();

  const [checked, setChecked] = useState(false);
  const [anchorElPgs, setAnchorElPgs] = useState(null);

  options = {
    maxShowPages: 5,
    ...options,
  };
  // console.log("[CustomPagination1]", count, page, rowsPerPage, options);

  const rowsPerPageOptions = [10, 50, 100, 500];

  const totalPage = Math.ceil(count / rowsPerPage);
  const currentPage = Math.floor((page < 0) ? 0 : ((page >= totalPage && totalPage > 0) ? (totalPage - 1) : page)) + 1;

  // pagination numbers
  let pages = [];
  // current page & around pages
  const halfPage = Math.floor((options.maxShowPages - 2) / 2);
  let startPage = currentPage - halfPage;
  let endPage = currentPage + halfPage;
  if (startPage < 1) {
    startPage = 1;
    endPage = Math.min(totalPage, startPage + 2 * halfPage);
  }
  if (endPage > totalPage) {
    endPage = totalPage;
    startPage = Math.max(1, endPage - 2 * halfPage);
  }
  // console.log("[CustomPagination1]", totalPage, currentPage, startPage, endPage);
  // first & last page
  if (startPage === 2) pages.push(1);
  else if (startPage === 3) pages = pages.concat([1, 2]);
  else if (startPage > 3) pages = pages.concat([1, '...']);
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }
  if (endPage === (totalPage - 1)) pages.push(totalPage);
  else if (endPage === (totalPage - 2)) pages = pages.concat([totalPage-1, totalPage]);
  else if (endPage < (totalPage - 2)) pages = pages.concat(['...', totalPage]);


  const handleChangePage = (newPage) => {
    // console.log("[CustomPagination1] handleChangePage", newPage);
    if (typeof setPage === 'function' && !isNaN(newPage) && newPage > 0 && newPage <= totalPage) setPage(newPage - 1);
  };

  const handleCustomCheckbox = (state) => {
    setChecked(state);
    if (typeof handleCheckbox === 'function') handleCheckbox(state);
  };

  const handlePageRightClick = (event) => {
    // console.log("[CustomPagination1] handlePageRightClick");
    setAnchorElPgs(event.currentTarget);
  };

  const handleClosePgs = () => {
    setAnchorElPgs(null);
  };

  const handleChangeRowsPerPage = (p) => {
    if (typeof setRowsPerPage === 'function') setRowsPerPage(p);
    setAnchorElPgs(null);
  };


  useEffect(() => {
    if (page !== (currentPage - 1)) setPage(currentPage - 1);
  }, [currentPage, page, setPage]);


  return (
    <>
      {
        handleCheckbox && 
        <CustomCheckbox
          checked={checked}
          setChecked={handleCustomCheckbox}
          title="Archived Contracts Included"
        />
      }
      <IconButton disabled={currentPage === 1} className={classes.pageBadge} 
        onClick={() => handleChangePage(currentPage - 1)} 
      >
        <Avatar>
          <KeyboardArrowLeft color={currentPage === 1 ? 'disabled' : 'inherit'} />
        </Avatar>
      </IconButton>
      {
        pages.map((p, i) => (
          <IconButton key={i}
            disabled={p === '...'} 
            className={p === currentPage ? classNames(classes.pageBadge, classes.pageBadgeSelected) : classes.pageBadge}
            onClick={() => handleChangePage(p)}
          >
            <Avatar>{p}</Avatar>
          </IconButton>
        ))
      }
      <IconButton disabled={currentPage >= totalPage} className={classes.pageBadge} 
        onClick={() => handleChangePage(currentPage + 1)} 
      >
        <Avatar>
          <KeyboardArrowRight color={currentPage >= totalPage ? 'disabled' : 'inherit'} />
        </Avatar>
      </IconButton>
      <IconButton className={classes.pageBadge} onClick={handlePageRightClick} > 
        <img  alt="" src={paginationRightIcon} /> 
      </IconButton>
      <Menu
        open={Boolean(anchorElPgs)}
        anchorEl={anchorElPgs}
        onClose={handleClosePgs}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        {
          rowsPerPageOptions.map((p, i) => (
            <MenuItem
              key={i}
              selected={p === rowsPerPage}
              onClick={() => handleChangeRowsPerPage(p)}
            >
              {p}
            </MenuItem>
          ))
        }
      </Menu>
   </>
  );
}
