import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import moment from "moment";
import {
  Grid, Card, CardContent, Button, Paper,
  Table, TableBody, TableRow, TableCell, DialogContentText, Drawer, Typography,
} from "@material-ui/core";
import TextField from '@mui/material/TextField';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Paytype } from "../../models/HospitalDetail";
import { useDamlState, useDamlHoliday } from "../../context/DamlContext";
import { CustomSelect } from "../../components/Inputs";
import SnackbarModal from "../../components/Modals/SnackbarModal";
import closeIcon from "../../icons/new-close-icon.svg";
import useStyles2 from "../surgicalevent/styles";
import useStyles from "./styles";


/**
 * Detail View Slide Drawer For Invoice
 * @param {Boolean} state
 * @param {Function} toggleDrawer
 * @param {Object} invoice
 * @param {Object} vendortemplate
 */
const PaymentPopup = ({
  state, toggleDrawer, invoice, vendortemplate = null, setInvoiceDetailViewer, handlePayment, handleSave
}) => {
  const classes2 = useStyles2();
  const classes = useStyles();
  
  const { rolePayload } = useDamlState();
  const { holidays, loaded, loadHoliday } = useDamlHoliday();

  const [toastDetails, setToastDetails] = useState({ isOpen: false, content: "" });

  const { hospital, invoicedata, invoicedetail, purchaseordercomment } = invoice;
  const { hospitaldetails } = (rolePayload ? rolePayload : {});
  const [dueDate, setDueDate] = useState()
  const [payType, setPayType] = useState()

  const tydeiDateCalculator = useCallback((paymentDueDate, offset, next) => {

    const isOffDay = (date) => {
      return (date.getDay() === 0 || date.getDay() === 6 || holidays.includes(moment(date).format('YYYY-MM-DD')))
    }

    let previousDate = new Date(paymentDueDate);
    previousDate.setDate(next ? paymentDueDate.getDate() + 1 : paymentDueDate.getDate() - 1);
    if (isOffDay(paymentDueDate)) {
      return tydeiDateCalculator((previousDate), offset, next)
    } else if (offset === 0) {
      return paymentDueDate
    } else {
      return tydeiDateCalculator((previousDate), --offset, next)
    }

  }, [holidays])

  function isNonWorkingDay(date) {
    return (date.getDay() === 0 || date.getDay() === 6 || holidays.includes(moment(date).format('YYYY-MM-DD')))
  }

  const addDays = (invdate, days) => {
    let date = new Date(invdate);
    return new Date(date.setDate(date.getDate() + days));
  }
  const calculateDueDate = useCallback(() => {

    if (invoice?.paymentdetaildata?.paymentdate) {
      return invoice?.paymentdetaildata?.paymentdate
    } else {
      let paymentDate = new Date();
      switch (invoice?.paymentdetaildata?.payment_term) {
        case 'INV NET 30':
          paymentDate = addDays(invoice?.paymentdetaildata?.paymentstatus === 'Cancelled' ? paymentDate : invoice?.created_at, 30);
          break;
        case 'INV NET 60':
          paymentDate = addDays(invoice?.paymentdetaildata?.paymentstatus === 'Cancelled' ? paymentDate : invoice?.created_at, 60);
          break;
        case 'INV NET 90':
          paymentDate = addDays(invoice?.paymentdetaildata?.paymentstatus === 'Cancelled' ? paymentDate : invoice?.created_at, 90);
          break;
        case 'Immediate':
        default:
          return tydeiDateCalculator(paymentDate, 1, true);
      }
      return tydeiDateCalculator(paymentDate, 2, true);
    }

  }, [invoice, tydeiDateCalculator])

  
  useEffect(() => {
    if (!loaded) loadHoliday();
  }, [loaded, loadHoliday]);

  useEffect(() => {
    setDueDate(moment(calculateDueDate()).format('YYYY-MM-DD'));
  }, [holidays, calculateDueDate])


  useEffect(() => {
    setPayType(invoice?.paymentdetaildata?.paymenttype);
  }, [invoice])





  const renderAddressRow = (label, value, key, labelCss = classes2.tabletitle, valueCss = classes2.userInputs) => {
    if (label) {
      return (<TableRow key={key}>
        <TableCell className={(classes2.cellWithoutBorderPadding, labelCss)}>{label}</TableCell>
        <TableCell className={(classes2.cellWithoutBorderPadding, valueCss)}>
          {value}
        </TableCell>
      </TableRow>)
    }
    else if (value) {
      return (<TableRow key={key}>
        <TableCell className={(classes2.cellWithoutBorderPadding, valueCss)}>
          {value}
        </TableCell>
      </TableRow>)
    }
  }

  const productDetails = () => {
    return (<Grid className="left-card">

      <Card>
        <CardContent className={classes.paddingZero}>
          <Grid container
            className={classes.gridContainer}
            spacing={0}>
            <Grid container className={classes.gridBottomBorder}>
              {
                ['Line', 'Item', /*"column not defined yet", */ 'Description', 'Manufacturer', 'Quantity', 'Price', 'EXT Cost', 'DEPT or INVEN', 'G/L Account']
                  .map((header, index) => {
                    if (header === 'Description') return (<Grid item key={index} md={4} sm={1} className={classes.gridHead}>{header}</Grid>)
                    if (header === 'Line') return (<Grid item key={index} md={1} sm={1} className={classNames(classes.gridHead, classes.gridSeriesWidth)}>{header}</Grid>)
                    if (header === 'Manufacturer') return (<Grid item key={index} md={1} sm={1} className={classNames(classes.gridHead, classes.gridSeriesWidth1)}>{header}</Grid>)
                    else return (<Grid item key={index} md={1} sm={1} className={classes.gridHead}>{header}</Grid>)
                  })
              }
            </Grid>
            <Grid container
              className={classes.gridColumn}
            >

              {/* <Table> */}
              <Grid className={classes.scrollit2} item md={12} sm={12}  >
                {

                  invoicedetail?.map((invDetail, index) => (
                    <Grid container style={{ height: "30px" }} key = {`invde ${index}`}>
                      {[
                        { 'css': classNames(classes.gridCellCenter, classes.gridSeriesWidth), value: index + 1 },
                        { 'css': classNames(classes.gridCell, classes.gridMinWidth), value: invDetail.invdproductitem },
                        { 'css': classNames(classes.gridCell, classes.gridWidth), value: invDetail.invddescription },
                        // { 'css': classes.gridCellCenter, value: '' },
                        { 'css': classes.gridCellCenter, value: invDetail.invdproduct.sepproductmanufacturer },
                        { 'css': classes.gridCellCenter, value: invDetail.invdproductquantity },
                        { 'css': classes.gridCellCenter, value: invDetail.invdproductprice },
                        { 'css': classes.gridCellCenter, value: invDetail.invdtotalcost },
                        { 'css': classes.gridCellCenter, value: 'NA' },
                        { 'css': (classes.gridCellCenter,classes.endStart), value: invDetail.invdtotalcost }
                      ].map(({ css, value }) => <Grid item md={1} sm={1} className={classNames(css)}>{value}</Grid>)
                      }
                    </Grid>
                  )
                  )
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.gridTopBorder}>
            <Grid item container direction="column" xs={12} sm={8} className={classes2.rightBorder}>
              <Grid item style={{ height: "30px" }} >
                <Typography
                  className={classes.gridFooterDetails1}
                >Comments :</Typography>
              </Grid>
              <Grid item >
                <Paper style={{ height: "59px" }}>
                  <Typography
                    className={classes.gridFooterComments}>
                    {purchaseordercomment ? purchaseordercomment[0]?.note : ''}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} >
              <Paper style={{ height: "89px", }}
                className={classes.gridFooterDetails}>
                <span>Total :</span>
                <span className={classes.amountInvoice}>${invoicedata?.invinvoicetotalamount}</span>
              </Paper>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>)
  }

  const shippingDetails = () => {
    return (<Grid className="left-card">

      <Card>
        <CardContent className={classes.paddingBottom}>
          <Grid container spacing={3}>

            <Grid item xs={2} sm={4} md={4} className={classes2.rightBorder}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={(classes2.cellWithoutBorderPadding, classes2.tabletitle)}>Ship To:</TableCell>
                  </TableRow>
                  {
                    [
                      { label: '', value: hospitaldetails?.shippingaddress[0]?.address1 },
                      { label: '', value: `${hospitaldetails?.shippingaddress[0]?.city} ${hospitaldetails?.shippingaddress[0]?.location}` },
                      { label: '', value: `${hospitaldetails?.shippingaddress[0]?.state} ${hospitaldetails?.shippingaddress[0]?.zipcode}` }
                    ].map(({ label, value }, index) => {
                      return renderAddressRow(label, value, index)
                    })
                  }

                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={2} sm={4} md={4} className={(classes2.rightBorder)}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={(classes2.cellWithoutBorderPadding, classes2.tabletitle)}>Invoice To:</TableCell>
                  </TableRow>
                  {
                    [
                      { label: '', value: vendortemplate?.api_payload?.buyer },
                      { label: '', value: invoice?.operator },
                      { label: '', value: hospital },
                      { label: '', value: `${hospitaldetails?.billinfocontact[0].fax}` },
                      { label: 'Contract ID:', value: `` },
                      { label: '', value: " " },
                    ].map(({ label, value }, index) => {
                      return renderAddressRow(label, value, index, classes2.tabletitle2)
                    })
                  }
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={2} sm={4} md={4}>
              <Table>
                <TableBody>

                  {
                    [
                      { label: 'Terms:', value: 'FOB Contact:', labelCss: classes2.tabletitle, valueCss: classes2.tabletitle },
                      { label: '', value: vendortemplate?.api_payload.paymentTerms, valueCss: '' },
                      { label: '', value: hospitaldetails?.billingaddress[0]?.location, valueCss: classes2.userInputs },
                      { label: '', value: invoice?.paymentdetaildata?.payment_term || 'NA', valueCss: classes2.cellwithpaddings }
                    ].map(({ label, value, labelCss, valueCss }, index) => {
                      return renderAddressRow(label, value, index, labelCss, valueCss)
                    })
                  }

                </TableBody>
              </Table>
            </Grid>

          </Grid>
        </CardContent>
      </Card>

    </Grid>)
  }

   //#region  
   const [paymentOffset, setPayemtOffset] = useState({ offset: 0, referalDate: new Date() })
   const setPaymentDueOffset = (paymentType) => {
     debugger
 
     const currentHour = new Date().getHours();
     const currentMinutes = new Date().getMinutes();
     let offset = 0;
     let dateOffset = new Date();
     if ((currentHour < 16 || (currentHour === 16 && currentMinutes < 30))) {
       let tomorrow = new Date()
       tomorrow.setDate(dateOffset?.getDate() + 1)
       dateOffset = tomorrow;
     }
     switch (paymentType) {
       case 'VCard':
         offset = 1
         break;
       default:
         offset = 2
         break;
     }
     setPayemtOffset({ offset, referalDate: dateOffset })
   }
   //#endregion

  return (
    <React.Fragment key={'right'} >
      <Drawer className={classNames(classes.invoicePopupWidth, classes2.customRoot, classes.customRootInvoice, classes.customRootInvoice2)}
        anchor={'right'} open={state} onClose={() => { toggleDrawer(false) }} >

        <DialogContentText component={'div'} className="modelStepDetai">
          <div className="view-heading3">
            <Button
              className={classes.closeButton}
              onClick={() => setInvoiceDetailViewer(false)}
            >
              <img src={closeIcon} alt="" />
            </Button>
          </div>
          <Grid container
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={1}
          >
            <Grid item md={12} sm={12} className="left-panel">

              <div className='view-heading4 view-heading5'>
                Invoice number: <span className={classes.skipUnderline}>{invoicedata && invoicedata.invinvoicenumber}</span>

                <CustomSelect
                  items={Paytype}
                  className={classes2.printButton2}
                  placeholder="Select One"
                  width={143}
                  value={payType}
                  onChange={(e) => {
                    setPayType(e)
                    setPaymentDueOffset(e)
                  }}
                />
                <div className={classNames(classes2.printButton2)}><span className={classes.ptypeLabel}>Payment Type</span></div>
              </div>

              <Grid className="left-card">

                <Card>
                  <CardContent className={classes.paddingBottom}>
                    <Grid container spacing={3}>

                      <Grid item xs={2} sm={4} md={4} className={(classes2.rightBorder)}>
                        <Table>
                          <TableBody>
                            {
                              [
                                { label: 'Facility:', value: hospital },
                                { label: '', value: hospitaldetails?.physicaladdress.address1 },
                                { label: '', value: `${hospitaldetails?.physicaladdress.city} ${hospitaldetails?.physicaladdress.state} ${hospitaldetails?.physicaladdress.zipcode}`},
                              ].map(({ label, value }, index) => {
                                return renderAddressRow(label, value, index)
                              })
                            }
                          </TableBody>
                        </Table>

                      </Grid>

                      <Grid item xs={2} sm={4} md={4} className={(classes2.rightBorder)}>
                        <Table>
                          <TableBody>

                            {
                              [
                                { label: 'Vendor:', value: invoice?.vendor },
                                { label: '', value: invoice?.vendoraddress?.address1 },
                                { label: '', value: `${invoice?.vendoraddress?.city} ${invoice?.vendoraddress?.state} ${invoice?.vendoraddress?.zipcode}` },
                              ].map(({ label, value }, index) => {
                                return renderAddressRow(label, value, index)
                              })
                            }
                          </TableBody>
                        </Table>
                      </Grid>

                      <Grid item xs={2} sm={4} md={4}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.tabletitle2)}>Date:</TableCell>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.userInputs)}>

                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DesktopDatePicker
                                    shouldDisableDate={isNonWorkingDay}
                                    inputFormat="MM/dd/yyyy"
                                    value={dueDate}
                                    minDate={tydeiDateCalculator(paymentOffset.referalDate, paymentOffset.offset, true)}
                                    onChange={(newDate) => { setDueDate(newDate) }}
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                </LocalizationProvider>
                              </TableCell>
                            </TableRow>
                            {
                              [
                                { label: 'Buyer:', value: vendortemplate?.api_payload?.buyer },
                                { label: 'Type:', value: '' },
                              ].map(({ label, value }, index) => {
                                return renderAddressRow(label, value, index, classes2.tabletitle2)
                              })
                            }
                          </TableBody>
                        </Table>
                      </Grid>

                    </Grid>
                  </CardContent>
                </Card>

              </Grid>

              {
                shippingDetails()
              }


              {
                productDetails()
              }


              <Grid className={classes.navigationBtn}>
                <Button variant="contained" className={classNames(classes.secondarybtn, classes.marginInBetween)} onClick={() => {
                  let newDate;

                  if (dueDate instanceof Date) {
                    newDate = dueDate.toISOString().slice(0, 10);
                  } else {
                    newDate = dueDate;
                  }

                  handleSave(newDate, payType);

                }}>Save</Button>
                <Button variant="contained" className={classNames(classes.primarybtn, classes.marginInBetween)}
                  onClick={() => {

                    let paymentinitiationdate = tydeiDateCalculator(new Date(dueDate), paymentOffset.offset, false);
                    if (paymentinitiationdate.getTime() <= new Date().getTime()) {
                      setToastDetails({ isOpen: true, content: 'Sorry, we cann\'t proceed with selected payment date.' })
                    } else if (payType === undefined || payType === null) {
                      setToastDetails({ isOpen: true, content: 'Sorry, we cann\'t proceed without Payment Type.' })
                    }
                    else {
                      handlePayment(payType, dueDate, paymentinitiationdate)
                    }

                  }}>
                  Confirm Payment
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <SnackbarModal
            open={toastDetails.isOpen}
            setOpen={(isOpen)=>setToastDetails({isOpen, content:''})}
            type="warning"
            title=""
            content={toastDetails.content}
            vertical="center"
            horizontal="center"
            width={500}
            height={117}
            transition="fade"
            className={classes.toastMedium}
            options={{
              delay: 500,
            }}
            actions={[
              {
                label: 'OK',
              }
            ]}
          />

        </DialogContentText>
      </Drawer>
    </React.Fragment >
  );
}

export default PaymentPopup;