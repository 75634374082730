import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  grid: {
    height: '90%',
  },
  comment: {
    marginTop: '10px',
  },
  invitePart: {
    minWidth: '350px',
    margin: '30px',
  },
}));
