import React, { useState } from "react";
import { Dialog, DialogTitle} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import logoImg from "../../icons/newlogo.svg";
import { useCookies } from 'react-cookie';
import SlickSlider from './slickSlider';
// import './slick.css';
import useStyles from "./styles";

/**
 *  Dialog for Welcome
 */
export default function WelcomeModal () {

  const [cookies, setCookie] = useCookies(['welcomeModal']);  
  // console.log("[WelcomeModal] cookies",cookies);
  const classes = useStyles();
  const status = (cookies.welcomeModal === "false") ? false: true;
  const[open,setOpen]=useState(status);
  const[slideNumber,setSlideNumber]=useState(1);
  const handleSuccessModalClose = () => {
    setOpen(false);
    setCookie('welcomeModal', "false", { path: '/' });
  }
  const handleSuccessModalCloseOnly = () => {
    setOpen(false);
    setCookie('welcomeModal', "false", { path: '/' });
  }


  return (
    <Dialog
      open={open}
      onClose={handleSuccessModalClose}
      className={classes.customRoot}
      style={{textAlign: 'center'}}
      aria-labelledby="draggable-dialog-title"
    >
      <div className="close-icon" onClick={handleSuccessModalCloseOnly}> <CloseIcon /></div>
      
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title1">
        <img src={logoImg} alt=""/>
      </DialogTitle>

      <SlickSlider handleSuccessModalClose={handleSuccessModalClose} slideNumber={slideNumber} setSlideNumber={setSlideNumber} classes={classes} />
  
    </Dialog>
  );
}

   