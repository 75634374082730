import React, { useState, useEffect } from "react";
import { useStreamQueries, useParty, useLedger } from "@daml/react";
import { HospitalRole } from "../../services/daml-modules1";
import { useDamlState, useDamlDispatch } from '../../context/DamlContext';
import { getOperatorParty } from "../../services/axios/daml-json-api";
import {
  Button, FormControl, FormControlLabel, InputLabel, List, ListItem, Select, TextField,
  Checkbox, Typography, Card, CardContent
} from "@material-ui/core";
import { getLevels } from "../../models/AdminDetail";
import { onChangeNumber } from "../../components/Modals/handles";
import { setByPath } from "../../components/Contracts/functions";
import editIcon from '../../icons/edit.svg';
import { exerciseHospitalAdminAccept } from "./handle";
import { DAML_CLEAR_ROLE } from "../../store/actions/constants";
import useStyles from "./styles";
import useTabStyle from "../surgicalevent/styles";
import { AppBar, Tabs, Tab } from '@material-ui/core';
import TabPanel, { a11yProps } from "../../components/Modals/MultiTabsModals/TabPanel";
import { CustomLoading } from "../../hoc/withLoading";


/**
 * Admin Hospital List page
 * @returns 
 */
export default function AdminList() {
  const classes = useStyles();
  const { role } = useDamlState();
  const party = useParty();
  const operator = getOperatorParty();
  let searchParams = {};
  if (role === "Operator") searchParams = { operator: operator };
  else if (role === "Hospital") searchParams = { hospital: party };
  const assets = useStreamQueries(HospitalRole, () => [searchParams]);
  return (
    <>
      <Typography variant="h4" className={classes.pageTitle}>Admin Hospital Data</Typography>

      {
        assets.loading
          ?
          <CustomLoading />
          :

          <Card className={classes.cardItem}>
            <CardContent>

              <AdminView assets={assets} />
              {/*  */}
            </CardContent>
          </Card>

      }
    </>
  );
}

const AdminView = ({ assets }) => {
  const classes = useTabStyle();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    // setAllContracts([]);
    setValue(newValue);
  };

  return <>
    <AppBar position="static" className={classes.tabPanelMenu}>
      <Tabs indicatorColor="primary" textColor="primary" value={value} onChange={handleChange} aria-label="simple tabs example">
        <Tab label="Purchase Order Settings" {...a11yProps(0)} />
        {/* <Tab label="Invoice Settings" {...a11yProps(1)} />
        <Tab label="Payment" {...a11yProps(2)} /> */}
      </Tabs>
    </AppBar>

    <TabPanel value={value} index={0} className={classes.tabPanelbody}>
      <PurchaseOrderSetting assets={assets} />
    </TabPanel>
    {/* <TabPanel value={value} index={1} className={classes.tabPanelbody}>
      <InvoiceSetting assets={assets} />
    </TabPanel>
    <TabPanel value={value} index={2} className={classes.tabPanelbody}>
      <PaymentSetting assets={assets} />
    </TabPanel> */}
  </>
}


const PurchaseOrderSetting = ({ assets }) => {
  const classes = useStyles();
  const ledger = useLedger();
  const dispatch = useDamlDispatch();
  const { userlistPayload } = useDamlState();
  const [contractId, setContractId] = useState('');
  const [admindetails, setAdminDetails] = useState({});
  const [purchaseorderdetails, setPurchaseOrderDetails] = useState({});
  const [editData, setEditData] = useState(0);


  const updateField = (key, isNumber = false) => (e) => {
    if (!isNumber || (isNumber && onChangeNumber(e))) setPurchaseOrderDetails(setByPath(e.target.value, key, purchaseorderdetails));
  };

  // handle when click "Update" button
  const handleRequest = async () => {
    let tempAdminDetails = { ...admindetails, purchase_order_setting: purchaseorderdetails }
    let res = await exerciseHospitalAdminAccept(tempAdminDetails, ledger, contractId, userlistPayload);
    if (res) dispatch({
      type: DAML_CLEAR_ROLE,
    });
    setAdminDetails(tempAdminDetails);
    setEditData(0);
  };

  // handle when click "Cancel" button
  const handleCancel = () => {
    setAdminDetails({ ...assets.contracts[0].payload.admindetails });
    setPurchaseOrderDetails({ ...assets.contracts[0].payload.admindetails.purchase_order_setting });
    setEditData(0);
  };

  useEffect(() => {
    console.dir(assets.contracts);
    if (!assets.loading && assets.contracts.length) {
      setContractId(assets.contracts[0].contractId);
      setAdminDetails({ ...assets.contracts[0].payload.admindetails });
      setPurchaseOrderDetails({ ...assets.contracts[0].payload.admindetails.purchase_order_setting });
    }
  }, [assets]);

  return <List className={classes.listItem}>
    <ListItem divider></ListItem>
    <ListItem >
      <div className="item">
        <Typography variant="h5" >
          Automated PO Settings   {editData !== 1 && <img src={editIcon} alt="edit" onClick={() => { setEditData(1) }} />}
        </Typography>

        <Typography component={"div"}>
          {editData === 1 &&
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={purchaseorderdetails.auto_po}
                  onChange={(e) => setPurchaseOrderDetails({ ...purchaseorderdetails, auto_po: e.target.checked })}
                />
              }
              label="Automate PO"
            />
          }

          {editData !== 1 && <>
            <span className="label">Automate PO</span>
            <span className={`value ${purchaseorderdetails.auto_po ? "greenbtn" : "redbtn"}`}>
              {purchaseorderdetails.auto_po ? "On" : "Off"}
            </span>
          </>}
        </Typography>

        <Typography component={"div"}>
          {editData === 1 &&
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={purchaseorderdetails.auto_po_vendor}
                  onChange={(e) => setPurchaseOrderDetails({ ...purchaseorderdetails, auto_po_vendor: e.target.checked })}
                />
              }
              label="Automate PO and Send to Vendor"
            />
          }

          {editData !== 1 && <>
            <span className="label">Automate PO and Send to Vendor</span>
            <span className={`value ${purchaseorderdetails.auto_po_vendor ? "greenbtn" : "redbtn"}`}>
              {purchaseorderdetails.auto_po_vendor ? "On" : "Off"}
            </span>
          </>}
        </Typography>
      </div>

      {editData === 1 &&
        <div className="form-footer">
          <div className="right-buttons">
            <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
            <Button variant="outlined" color="primary" onClick={handleRequest}>Update</Button>
          </div>
        </div>
      }
    </ListItem>

    <ListItem>
      <div className="item">
        <Typography variant="h5" >
          Approval Amount {editData !== 2 && <img src={editIcon} alt="edit" onClick={() => { setEditData(2) }} />}
        </Typography>

        <Typography component={"div"}>
          {editData === 2 &&
            <div className={classes.marginBM30}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
                  Approval
                </InputLabel>
                <Select
                  // native
                  displayEmpty
                  className={classes.halfWidth}
                  inputProps={{
                    name: 'age',
                    id: 'outlined-age-native-simple1-out',
                  }}
                  value={purchaseorderdetails.approval_level || ""}
                  onChange={updateField('approval_level')}
                >
                  {getLevels()}
                </Select>
              </FormControl>
            </div>
          }

          {editData !== 2 && <>
            <span className="label">Approval Level</span>
            <span className="value">{purchaseorderdetails.approval_level}</span>
          </>}
        </Typography>

        <Typography component={"div"}>
          {editData === 2 &&
            <div className={classes.marginBM30}>
              <TextField
                id="outlined-full-width-mrn-out"
                label="Approval Amount Level One"
                placeholder="eg: 2"
                className={classes.customFullWidth}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={purchaseorderdetails.approval_amount_levelone || ""}
                onChange={updateField('approval_amount_levelone', true)}
              />
            </div>
          }

          {editData !== 2 && <>
            <span className="label">Approval Amount Level One</span>
            <span className="value">{purchaseorderdetails.approval_amount_levelone}</span>
          </>}
        </Typography>

        <Typography component={"div"}>
          {editData === 2 &&
            <div className={classes.marginBM30}>
              <TextField
                id="outlined-full-width-mrn"
                label="Approval Amount Level Two"
                placeholder="eg: 3"
                className={classes.customFullWidth}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={purchaseorderdetails.approval_amount_leveltwo || ""}
                onChange={updateField('approval_amount_leveltwo', true)}
              />
            </div>
          }

          {editData !== 2 && <>
            <span className="label">Approval Amount Level Two</span>
            <span className="value">{purchaseorderdetails.approval_amount_leveltwo}</span>
          </>}
        </Typography>
      </div>

      {editData === 2 &&
        <div className="form-footer">
          <div className="right-buttons">
            <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
            <Button variant="outlined" color="primary" onClick={handleRequest}>Update</Button>
          </div>
        </div>
      }
    </ListItem>

  </List>
}

// const PaymentSetting = ({ assets }) => {
//   const classes = useStyles();
//   const ledger = useLedger();
//   const dispatch = useDamlDispatch();

//   const [contractId, setContractId] = useState('');
//   const [admindetails, setAdminDetails] = useState({});
//   const [paymentdetails, setPaymentDetails] = useState({ ...PaymentSettingModel });
//   const [editData, setEditData] = useState(0);

//   const updateField = (key, isNumber = false) => (e) => {
//     if (!isNumber || (isNumber && onChangeNumber(e)))
//       setPaymentDetails(setByPath(e.target.value, key, paymentdetails));
//   };

//   // handle when click "Update" button
//   const handleRequest = async () => {
//     let tempAdminDetails = { ...admindetails, payment_setting: paymentdetails }
//     let res = await exerciseHospitalAdminAccept(tempAdminDetails, ledger, contractId);
//     if (res) dispatch({
//       type: DAML_CLEAR_ROLE,
//     });
//     setAdminDetails(tempAdminDetails);
//     setEditData(0);
//   };

//   // handle when click "Cancel" button
//   const handleCancel = () => {
//     setAdminDetails({ ...assets.contracts[0].payload.admindetails });
//     setPaymentDetails({ ...assets.contracts[0].payload.admindetails.payment_setting });
//     setEditData(0);
//   };

//   useEffect(() => {
//     console.dir(assets.contracts);
//     if (!assets.loading && assets.contracts.length) {
//       setContractId(assets.contracts[0].contractId);
//       setAdminDetails({ ...assets.contracts[0].payload.admindetails });
//       setPaymentDetails(assets.contracts[0].payload.admindetails.payment_setting ? { ...assets.contracts[0].payload.admindetails.payment_setting } : { ...PaymentSettingModel });
//     }
//   }, [assets]);

//   return <List className={classes.listItem}>
//     <ListItem divider></ListItem>
//     <ListItem >
//       <div className="item">
//         <Typography variant="h5" >
//           Payment Settings {editData !== 1 && <img src={editIcon} alt="edit" onClick={() => { setEditData(1) }} />}
//         </Typography>

//         <Typography component={"div"}>
//           <FormControlLabel
//             control={
//               <Checkbox
//                 color="primary"
//                 checked={paymentdetails.discover_payment}
//                 onChange={(e) => setPaymentDetails({ ...paymentdetails, discover_payment: e.target.checked })}
//               />
//             }
//             label="Enable Payment through Discover"
//             labelPlacement="start"
//             disabled={editData !== 1}
//             style={{ marginLeft: 0 }}
//           />

//         </Typography>


//         <Typography component={"div"}>
//           <div className={classes.marginBM30}>
//             <TextField
//               id="outlined-full-width-mrn"
//               label="Discover PayerNetworkID"
//               placeholder="eg: 3"
//               className={classes.customFullWidth}
//               margin="normal"
//               InputLabelProps={{
//                 shrink: true,
//               }}
//               variant="outlined"
//               value={paymentdetails.discover_payernetwork_id || ""}
//               onChange={updateField('discover_payernetwork_id', false)}
//               disabled={editData !== 1}
//             />
//           </div>

//         </Typography>



//         <Typography component={"div"}>
//           <div className={classes.marginBM30}>
//             <TextField
//               id="outlined-full-width-mrn"
//               label="Discover PartnerNetworkID"
//               placeholder="eg: 3"
//               className={classes.customFullWidth}
//               margin="normal"
//               InputLabelProps={{
//                 shrink: true,
//               }}
//               variant="outlined"
//               value={paymentdetails.discover_partnernetwork_id || ""}
//               onChange={updateField('discover_partnernetwork_id', false)}
//               disabled={editData !== 1}
//             />
//           </div>
//         </Typography>

//         <Typography component={"div"}>
//           <div className={classes.marginBM30}>
//             <TextField
//               id="outlined-full-width-mrn"
//               label="Discover PartnerNameID"
//               placeholder="eg: 3"
//               className={classes.customFullWidth}
//               margin="normal"
//               InputLabelProps={{
//                 shrink: true,
//               }}
//               variant="outlined"
//               value={paymentdetails.discover_partnername_id || ""}
//               onChange={updateField('discover_partnername_id', false)}
//               disabled={editData !== 1}
//             />
//           </div>
//         </Typography>

//         {/* <Typography component={"div"}>
//           <FormControlLabel
//             control={
//               <Checkbox
//                 color="primary"
//                 checked={paymentdetails.straight_through_payment}
//                 onChange={(e) => setPaymentDetails({ ...paymentdetails, straight_through_payment: e.target.checked })}
//               />
//             }
//             label="Enable Straight through Payments"
//             labelPlacement="start"
//             disabled={editData !== 1}
//             style={{ marginLeft: 0 }}
//           />
//         </Typography> */}
//       </div>

//       {editData === 1 &&
//         <div className="form-footer">
//           <div className="right-buttons">
//             <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
//             <Button variant="outlined" color="primary" onClick={handleRequest}>Update</Button>
//           </div>
//         </div>
//       }
//     </ListItem>



//   </List>
// }

// const InvoiceSetting = ({ assets }) => {
//   const classes = useStyles();
//   const ledger = useLedger();
//   const dispatch = useDamlDispatch();

//   const { relVendors: distributors } = useDamlState();
//   const [contractId, setContractId] = useState('');
//   const [admindetails, setAdminDetails] = useState({});
//   const [invoicesettingdetail, setInvoiceSettingDetails] = useState([]);

//   const [invSettingData, setinvSettingData] = useState({ vendor: '', auto_payment_vendor: '' });
//   const [invSettingErrorData, setinvSettingErrorData] = useState({ vendor: false, auto_payment_vendor: false });


//   useEffect(() => {
//     if (!assets.loading && assets.contracts.length) {
//       setContractId(assets.contracts[0].contractId);
//       setAdminDetails({ ...assets.contracts[0].payload.admindetails });
//       setInvoiceSettingDetails(assets.contracts[0].payload.admindetails.invoice_setting || []);
//     }
//   }, [assets]);

//   const handleRequest = async (invoice_setting_detail) => {
//     console.log(invoice_setting_detail);
//     let tempAdminDetails = { ...admindetails, invoice_setting: invoice_setting_detail }
//     let res = await exerciseHospitalAdminAccept(tempAdminDetails, ledger, contractId);
//     console.log(tempAdminDetails);
//     if (res) {
//       dispatch({
//         type: DAML_CLEAR_ROLE,
//       });
//     }
//     setAdminDetails(tempAdminDetails);
//   };

//   return (<List className={classNames(classes.listItem, classes.padding10)}>
//     <ListItem divider></ListItem>


//     <ListItem>
//       <Typography className={classNames(classes.row, classes.margin10)}>
//         Invoice Terms
//       </Typography>
//       <div className={classNames(classes.row, classes.padding10, classes.margin10)} >
//         <FormControl variant="outlined" className={classNames(classes.padding10)}>
//           <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
//             Vendor
//           </InputLabel>
//           <Select
//             // native
//             displayEmpty
//             error={!!invSettingErrorData.vendor}
//             value={invSettingData.vendor}
//             inputProps={{
//               name: 'vendors',
//               id: 'outlined-age-native-simple1',
//             }}
//             onChange={(e) => {
//               console.log(e);
//               setinvSettingData({ ...invSettingData, vendor: e.target.value })
//             }}
//           >
//             <MenuItem key={'init'} value="" disabled>Select One</MenuItem>
//             {
//               distributors.map((vendor, index) => (<MenuItem value={vendor} key={index}>{vendor}</MenuItem>))
//             }

//           </Select>
//         </FormControl>


//         <FormControl variant="outlined" className={classNames(classes.padding10)}>
//           <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
//             Value
//           </InputLabel>
//           <Select
//             // native
//             displayEmpty
//             error={!!invSettingErrorData.auto_payment_vendor}
//             value={invSettingData.auto_payment_vendor}
//             inputProps={{
//               name: 'values',
//               id: 'outlined-age-native-simple1',
//             }}
//             onChange={(e) => {
//               setinvSettingData({ ...invSettingData, auto_payment_vendor: e.target.value })
//             }}
//           >
//             <MenuItem key={'init1'} value="" disabled>Select One</MenuItem>
//             {
//               ["Immediate", "INV NET 30", "INV NET 60", "INV NET 90"].map((vendor, index) => (<MenuItem value={vendor} key={index}>{vendor}</MenuItem>))
//             }
//           </Select>

//         </FormControl>
//         <img src={plusCircle} alt="plusCircle" onClick={async () => {
//           console.log("CLICK PLUS")
//           if (invSettingData.vendor && invSettingData.auto_payment_vendor) {

//             let filterVendor = invoicesettingdetail.filter((item, index) => {
//               return item.vendor == invSettingData.vendor
//             });

//             filterVendor.forEach((element) => {
//               let index = invoicesettingdetail.indexOf(element);
//               if (index != -1)
//                 invoicesettingdetail.splice(index, 1);
//             });


//             let temp = [...invoicesettingdetail, invSettingData];
//             setInvoiceSettingDetails(temp);
//             setinvSettingData({ vendor: '', auto_payment_vendor: '' });
//             await handleRequest(temp);

//           } else {
//             let error = { vendor: false, value: false };
//             error.vendor = invSettingData.vendor ? false : true;
//             error.auto_payment_vendor = invSettingData.auto_payment_vendor ? false : true;
//             setinvSettingErrorData(error);
//           }
//         }} />

//       </div>
//     </ListItem>
//     <ListItem>
//       {
//         invoicesettingdetail.flatMap((e, index) => {
//           console.log(e);
//           return (<div className={classNames(classes.row, classes.margin10)} key={index}>
//             <div className={classNames(classes.rowAuto, classes.outline, classes.padding5)}>
//               <span className={classNames(classes.padding10)} >{e.vendor}</span>
//               <span className={classNames(classes.padding10)}>{e.auto_payment_vendor}</span>

//               <img src={deleteIcon} alt="deleteIcon" onClick={async () => {
//                 invoicesettingdetail.splice(index, 1);
//                 setInvoiceSettingDetails([...invoicesettingdetail]);
//                 await handleRequest(invoicesettingdetail);
//               }} />
//             </div>
//           </div>)
//         })
//       }
//     </ListItem>
//   </List>)
// }