import React from "react";
import { Checkbox, Switch, FormControlLabel } from "@material-ui/core";
import { useStyles } from "./styles";


export default function CheckBoxList({ 
  index, 
  selected=[], 
  contracts=[], 
  setSelected=null, 
  isCheckNotSwitch=true,
  labelOn="",
  labelOff="",
  key='id', 
  ...props 
}) {

    const classes = useStyles();

    // console.log("[CheckBoxList]", selected);
    

    const isSelected = id => selected.indexOf(id) !== -1;

    const handleSelected = (id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }
    
        // console.log("[CheckBoxList]", newSelected, contracts);
        if (typeof setSelected === "function") {
          let newContracts = [];
          if (key === 'id') newContracts = newSelected.map(id => contracts[id]);
          else if (key === 'contractId') newContracts = contracts.filter(c => newSelected.indexOf(c.contractId) !== -1);

          setSelected(newSelected, newContracts);
        }
    };

    return (
      isCheckNotSwitch
      ?
        <Checkbox
          {...props}
          className={classes.checkbox}
          checked={isSelected(index)}
          onChange={() => handleSelected(index)}
        />
      :
        <FormControlLabel
          control={
            <Switch
              {...props}
              checked={isSelected(index)}
              onChange={() => handleSelected(index)}
            />
          }
          label={isSelected(index)?labelOn:labelOff}
        />
    );
}