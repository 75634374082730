import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import { useReactToPrint } from 'react-to-print';
import { useParty, useStreamQueries } from "@daml/react";
import { Grid, Typography, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ContractManagement, ContractManagementHistory, ContractTotals, ContractTotalsHistory, SurgicalEventProcedure } from "../../services/daml-modules1";
import { arrayToCsv, downloadCSV } from "../../services/download";
import useEvent from "../../hooks/useEvent";
import { useDamlProcedure } from '../../context/DamlContext';
import ContractManagementListContracts from "./contractmanagementlistcontracts";
import ContractManagementPrint from "./contractmanagement-prints";
import ContractManagementPDF, { styles as pdfstyles } from "./contractmanagement-pdf";
import CreateContractManagement from "./create-contractmanagement";
import exportIcon from "../../icons/new-export-icon.svg";
import printIcon from "../../icons/new-print-icon.svg";
import CustomPrints from "../surgicalevent/surgicalevent-prints";
import { handlePDFGenerator } from "../surgicalevent/surgicalevent-pdf";
import ExportPopover from "../surgicalevent/export-popover";
import useStyles from "../surgicalevent/styles";
// import useStyles1 from "./styles";


/**
 * Contract Management page in Hospital
 * @returns 
 */
export default function ContractManagementList() {

  const classes = useStyles();
  // const classes1 = useStyles1();

  const hospital = useParty();

  // Component used for React-to-Print
  const componentRef = useRef();

  let { contracts, loading } = useStreamQueries(ContractManagement, () => [{ hospital }]);
  let { contracts: contractsHistory, loading: loading2 } = useStreamQueries(ContractManagementHistory, () => [{ hospital }]);
  const { contracts: contracts1, loading: loading1 } = useStreamQueries(ContractTotals, () => [{ hospital }]);
  const { contracts: contractsHistory1, loading: loading3 } = useStreamQueries(ContractTotalsHistory, () => [{ hospital }]);
  const { contracts: contractsProcedure, loading: loadingProcedure } = useStreamQueries(SurgicalEventProcedure, () => [{ hospital }]);
  // merge ContractManagement & ContractTotals
  loading = loading && loading1;
  contracts = loading ? contracts : contracts.map(c => {
    const found = contracts1.find(c1 => c1.payload.detail.contractnumber === c.payload.detail.contractmanagementnumber);
    if (found) {
      c.payload.detail = { ...c.payload.detail, ...found.payload.detail };
      c.contractId1 = found.contractId;
    }
    return c;
  });
  contractsHistory = (loading2 && loading3) ? contractsHistory : contractsHistory.map(c => {
    const found = contractsHistory1.find(c1 => c1.payload.contracttotals.detail.contractnumber === c.payload.contracteetail.detail.contractmanagementnumber);
    if (found) {
      c.payload.contracteetail.detail = { ...c.payload.contracteetail.detail, ...found.payload.contracttotals.detail };
    }
    return c;
  });
  // console.log("[ContractManagementList]", contracts, contractsHistory);

  const { onEventClear } = useEvent();
  const { setProcedure: setProcedureContext } = useDamlProcedure();

  const [isHistory, setHistory] = useState(false);
  // CreateContractManagement
  const [contract, setContract] = useState({});
  const [openModal, setOpenModal] = useState(false);
  // checked Contracts
  const [checkedContract, setCheckedContract] = useState([]);
  // Popover when clicking Export button
  const [anchorEl, setAnchorEl] = useState(null);


  // Click "Add Contract Management" in Contract list page
  const handleCreateContract = () => {
    setContract({});
    setOpenModal(true);
    onEventClear();
  };

  const handleEditContract = (c) => {
    setContract(c);
    setOpenModal(true);
    onEventClear();
  };

  const handlePrintClick = () => {
    console.log("[ContractManagementList] handlePrintClick", checkedContract);
    if (!checkedContract || checkedContract.length === 0) return;
    handlePrint();
  };

  const handleOpenPopover = (event) => {
    if (!checkedContract || checkedContract.length === 0) return;
    setAnchorEl(event.currentTarget);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  useEffect(() => {
    if (!loadingProcedure) setProcedureContext(contractsProcedure);
    // eslint-disable-next-line
  }, [loadingProcedure]);


  return (
    <>

      <Grid>

        <Grid container justifyContent="space-between">
          <Grid item xs>
            <Typography variant="h4" className={classes.newPageTitle}>Contract Management</Typography>
          </Grid>
          <Grid item>
            <Button
              className={classNames(classes.newCreateButton, classes.newIconButton)}
              variant="contained"
              onClick={handlePrintClick}
              startIcon={<img alt="" src={printIcon} />}
            />
            <Button
              className={classNames(classes.newCreateButton, classes.newIconButton)}
              variant="contained"
              onClick={handleOpenPopover}
              startIcon={<img alt="" src={exportIcon} />}
            />
            <Button
              className={classes.newCreateButton}
              variant="contained"
              onClick={handleCreateContract}
              startIcon={<AddIcon />}
            >
              Add Contract
            </Button>

            <ExportPopover
              anchor={anchorEl}
              setAnchor={setAnchorEl}
              handleExportCSV={() => handleExportCSV(checkedContract)}
              handleExportPDF={() => handleExportPDF(checkedContract)}
            />
          </Grid>
        </Grid>

          <ContractManagementListContracts
            allContracts={contracts}
            historyContracts={contractsHistory}
            isIncludeHistory={isHistory}
            setArchived={setHistory}
            setChecked={setCheckedContract}
            handleEditContract={handleEditContract}
            loading={(loading)}
          />
        

      </Grid>

      <CreateContractManagement
        open={openModal}
        setOpen={setOpenModal}
        contract={contract}
      />

      <CustomPrints
        ref={componentRef}
        contracts={checkedContract}
        Component={ContractManagementPrint}
      />

    </>
  );
}

const getExportData = (checkedContract) => {
  if (!checkedContract || !checkedContract.length) return {};
  const filename = ``;
  const runningContracts = [...checkedContract];
  return { filename, runningContracts };
};

const handleExportCSV = async (checkedContract) => {
  const { filename, runningContracts } = getExportData(checkedContract);
  let header, data, csvData = '';
  runningContracts.forEach(({ hospital }) => {
    csvData = csvData + '\n' + arrayToCsv(header, data);
  });
  await downloadCSV(csvData, filename);
};

const handleExportPDF = async (checkedContract) => {
  const { filename, runningContracts } = getExportData(checkedContract);
  await handlePDFGenerator({ contracts: runningContracts, fileName: filename, Component: ContractManagementPDF, style: pdfstyles });
};
