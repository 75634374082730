import React, { useState } from "react";
import classNames from "classnames";
import { Chip } from "@material-ui/core";
import { SurgicalEventHistory } from "../../services/daml-modules1";
import AdaptiveSearch from "../../components/Search/adaptivesearch";
import DateSearch from "../../components/Search/datesearch";
import SelectSearch from "../../components/Search/selectsearch";
import TreeContracts from "../../components/Contracts/TreeContracts";
import { contractFilter } from "../../services/daml-filters";
import checkIcon from "../../icons/check1.svg";
import completeIcon from "../../icons/check6.svg";
import { checkEnableArchiveSE } from '../surgicalevent/surgicaleventlistcontracts';
import { /* getInventoryPOStatus, */ InventoryPOStatusHospital } from '../../models/Po';
import { InventoryProductStatus, isEnableConfirmHospital, getTotalPrice } from "../../models/InventoryData";
import DetailViewDrawer from "./detail-view-drawer";
import SEDetailViewDrawer from "./detail-view-drawer-se";
import ReceiptConfirmInventory from './receipt-confirm';
import { confirmRestockInventoryHospital } from "./handle";
import archiveIcon from "../../icons/folder-plus.svg";
import archiveDisableIcon from "../../icons/folder-plus-disable.svg";
import { archiveSurgicalEvent } from "../surgicalevent/handle";
import ModalDialogBox from "../../components/Modals/GeneralModal/modalDialogBox";
import { useDamlStream } from "../../hooks/useDAML";
import useStyles from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";


/**
 * Restock Inventory Contracts in SE for Hospital
 * @param {Object} searchParams 
 * @param {Object} searchParamsSurgical 
 * @param {Boolean} isIncludeHistory 
 * @param {Function} handleChangeSearchValue 
 * @param {Array} allContracts 
 * @param {Array} allContractsSurgical 
 * @param {Object} ledger 
 * @param {String} hospital 
 * @param {String} roleCid
 * @returns 
 */
function ScanInventoryHospitalContracts({
  searchParams = {},
  searchParamsSurgical = {},
  isIncludeHistory = false,
  handleChangeSearchValue = null,
  allContracts = [],
  allContractsSurgical = [],
  ledger,
  hospital,
  roleCid,
  userlistPayload,
}) {

  const classes = useStyles();

  // const assetsSE = useStreamQueries(SurgicalEvent, () => [searchParamsSurgical]);
  let contractsSE = contractFilter(allContractsSurgical, searchParamsSurgical);
  // const assets = useStreamQueries(RestockInventory, () => [searchParams]);
  // const assetsHistory = useStreamQueries(InventoryProductHistory, () => [searchParams]);

  // const [assetsSEHistory, setSEHisotry] = useState({});
  const assetsSEHistory = useDamlStream(SurgicalEventHistory, [{
    hospital: searchParamsSurgical.hospital,
    surgicalevent: {...searchParamsSurgical.surgicaleventdata},
  }], isIncludeHistory);
  // const [assetsHistory, setInvHisotry] = useState({});

  contractsSE = contractsSE.concat(isIncludeHistory && assetsSEHistory.contracts ?
    assetsSEHistory.contracts.map(h => ({
      ...h, 
      payload: h.payload.surgicalevent,
    })) : []);
  /* const contracts = allContracts
    .map(c => ({
      ...c,
      payload: {
        ...c.payload,
        inventorydata: compressInventoryDataList(c.payload.inventorydata).map(inv => ({
          ...inv,
          sortkey: `${c.payload.inventoryproduct.ireferencenumber}${c.payload.inventoryproduct.iownership}${inv.index}`,
        })),
      }
    })); */

  // hash keys for Inventory
  let hashKeys = [];
  contractsSE.forEach(c => {
    c.payload.purchaseorders = [];
    if (c.payload.surgicaleventdata) hashKeys[c.payload.surgicaleventdata.seid] = c;
  });
  allContracts.forEach(c => {
    // contractId, inventoryproduct of Inventory into inventorydatas
    /* if (c.payload.inventorydata && c.payload.inventorydata.length) {
      c.payload.inventorydata.forEach(inv => {
        if (!!inv.iseid && inv.iseid in hashKeys && !!inv.ipurchaseordernumber) {
          let newInvData = {
            ...inv,
            contractId: c.contractId,
            vendor: c.payload.vendor,
            // inventoryproduct: c.payload.inventoryproduct,
            inventoryproduct: c.payload.inventoryproduct,
          };
          let findPO = hashKeys[inv.iseid].payload.purchaseorders.find((po => po.popurchaseorderid === inv.ipurchaseordernumber));
          if (!findPO) {
            // create virtual PO
            let newPO = { ...PoModel };
            newPO.popurchaseorderid = inv.ipurchaseordernumber;
            newPO.popurchaseordertype = c.payload.inventoryproduct.iownership;
            newPO.poreferenceid = c.payload.inventoryproduct.ireferencenumber;
            newPO.povendorid = inv.iproduct.sepdistributor;
            newPO.poseid = inv.iseid;
            newPO.inventorydatas = [newInvData];
            newPO.podate = inv.ipurchaseorderdate;
            hashKeys[inv.iseid].payload.purchaseorders.push(newPO);
          }
          else {
            // push inventorydata to PO
            findPO.inventorydatas.push(newInvData);
          }
        }
      });
    } */
    if (c.payload.ipurchaseorderdata && c.payload.ipurchaseorderdata.poseid in hashKeys) {
      hashKeys[c.payload.ipurchaseorderdata.poseid].payload.purchaseorders.push({
        ...c.payload.ipurchaseorderdata,
        inventorydata: c.payload.inventorydata.map((inv, index) => ({
          ...inv, 
          index,
          contractId: c.contractId,
          vendor: c.payload.vendor,
        })),
      });
    }
  });
  // check InventoryPOStatus, etc
  const newContracts = contractsSE.filter(c => c.payload.purchaseorders.length)
    .map(c => {
      c.payload.surgicaleventdata.shippedreceivedproducts = 0;
      c.payload.purchaseorders = c.payload.purchaseorders.map(p => {
        p.pototalamount = getTotalPrice(p.inventorydata);
        p.receivedinventorydatalength = 0;
        // p.poinventorystatus = getInventoryPOStatus(p.inventorydata, p.popurchaseordertype);
        p.inventorydata.forEach(inv => {
          if (inv.iproductstatus === 'Received') p.receivedinventorydatalength += parseInt(inv.iquantity);
        });
        c.payload.surgicaleventdata.shippedreceivedproducts += p.inventorydata.length;
        return p;
      });
      return c;
    });

  console.log("[ScanInventoryHospitalContracts]", searchParamsSurgical, searchParams, allContracts, newContracts);

  
  // SEDetailViewDrawer
  const [seData, setSEData] = useState({});
  const [poList, setPoList] = useState([]);
  const [sestate, setSEState] = useState(false);
  // DetailViewDrawer
  const [poData, setPOData] = useState({});
  const [inventoryList, setInventoryList] = useState([]);
  const [detailViewer, setDetailViewer] = useState(false);
  // Confirm Inventory
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [confirmInventoryData, setConfirmInventoryData] = useState({});
  // SE Archive
  const [openWarning, setOpenWarning] = useState(false);
  const [confirmModalContract, setConfirmModalContract] = useState({});


  // Click "Confirm" in list
  const handleConfirm = (inventoryData) => {
    // console.log("[ScanInventoryHospitalContracts] handleConfirm", inventoryData);
    if (inventoryData.iproductstatus !== 'Received') {
      let newInventoryData = {
        ...inventoryData,
        iproduct: {...inventoryData.iproduct},
      };
     
      setConfirmInventoryData(newInventoryData);
      setOpenConfirmModal(true);
    }
  };

  // handler to Confirm a Inventory in Confirm Modal
  const handleConfirmItem = async (newInventoryData) => {
    console.log("[ScanInventoryHospitalContracts] handleConfirmItem", confirmInventoryData, newInventoryData);
    
    const result = await confirmRestockInventoryHospital({
      ledger,
      roleCid,
      userlistPayload,
      vendor: newInventoryData.vendor,
      inventoryCid: newInventoryData.contractId,
      inventorydata: newInventoryData, // old case : newInventoryData.datas,
      newiproductstatus: 'Received', // new case
    });

    // setOpenConfirmModal(false);
    return result;
  };


  // handler to Archive SEID
  const handleArchiveSE = (c) => {
    // console.log("[ScanInventoryHospitalContracts] handleArchiveSE", c);
    setOpenWarning(true);
    setConfirmModalContract(c);

  };

  // handle to click "Yes" in Archive SE Modal
  const handleModalYes = async () => {
    await archiveSurgicalEvent({
      contractId: confirmModalContract.contractId, 
      payload: confirmModalContract.payload, 
      ledger,
      roleCid,
    });
    setOpenWarning(false);
  };

  const handleSecRequest = () => {
    setOpenWarning(false);
  };


  return (
    <>
      <div key="formChildStep" className="formsteps">
        <TreeContracts
          className={classes.customRoot}
          contracts={newContracts}
          columns={[
            // ["ContractId", "contractId"],
            ["SE Id", "payload.surgicaleventdata.seid", {
              type: "link",
              options: {
                cellClassName: classNames(classes.primaryColor),
                width: 120
              },
              onClick: (contractId, contract) => {
                if (!!contract) {
                  // console.log("[ScanInventoryHospitalContracts] click", contract);
                  setSEData(contract.payload.surgicaleventdata);
                  setPoList(contract.payload.purchaseorders.map(p => ({
                    purchaseorderdata: p,
                    inventorydata: p.inventorydata,
                  })));
                  setSEState(true);
                }
              },
              search: {
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.surgicaleventdata.seid}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'seid')}
                    defaultValue={searchParamsSurgical.surgicaleventdata.seid}
                    label="Search SE ID"
                    contracts={allContractsSurgical}
                    isShrink={true}
                  />
              },
              isSearched: searchParamsSurgical.surgicaleventdata.seid,
              handleClear: () => handleChangeSearchValue('', 'seid'),
            }],
            ["MRN", "payload.surgicaleventdata.mrn", {
              search: {
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.surgicaleventdata.mrn}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'mrn')}
                    defaultValue={searchParamsSurgical.surgicaleventdata.mrn}
                    label="Search MRN"
                    contracts={allContractsSurgical}
                    isShrink={true}
                  />
              },
              isSearched: searchParamsSurgical.surgicaleventdata.mrn,
              handleClear: () => handleChangeSearchValue('', 'mrn'),
              options: {
                width: 120
              }
            }],
            ["Patient Name", ["payload.surgicaleventdata.patientfirstname", "payload.surgicaleventdata.patientlastname"], {
              search: [
                {
                  label: "First Name",
                  component:
                    <AdaptiveSearch
                      getValue={(c) => c.payload.surgicaleventdata.patientfirstname}
                      handleChangeSearch={(v) => handleChangeSearchValue(v, 'patientfirstname')}
                      defaultValue={searchParamsSurgical.surgicaleventdata.patientfirstname}
                      label="Search Patient Name"
                      contracts={allContractsSurgical}
                      isShrink={true}
                    />
                },
                {
                  label: "Last Name",
                  component:
                    <AdaptiveSearch
                      getValue={(c) => c.payload.surgicaleventdata.patientlastname}
                      handleChangeSearch={(v) => handleChangeSearchValue(v, 'patientlastname')}
                      defaultValue={searchParamsSurgical.surgicaleventdata.patientlastname}
                      label="Search Patient Name"
                      isShrink={true}
                      contracts={allContractsSurgical}
                    />
                }
              ],
              isSearched: (!!searchParamsSurgical.surgicaleventdata.patientfirstname || !!searchParamsSurgical.surgicaleventdata.patientlastname),
              handleClear: () => {
                if (!!searchParamsSurgical.surgicaleventdata.patientfirstname && !!searchParamsSurgical.surgicaleventdata.patientlastname) {
                  handleChangeSearchValue('', ['patientfirstname', 'patientlastname']);
                }
                else if (!!searchParamsSurgical.surgicaleventdata.patientfirstname) handleChangeSearchValue('', 'patientfirstname');
                else if (!!searchParamsSurgical.surgicaleventdata.patientlastname) handleChangeSearchValue('', 'patientlastname');
              },
              options: {
                width: 160
              }
            }],
            ["Event Date", "payload.surgicaleventdata.eventdate", {
              search: {
                component:
                  <DateSearch
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'eventdate')}
                    defaultValue={searchParamsSurgical.surgicaleventdata.eventdate}
                    label="Search Event Date :"
                    isStatic={true}
                  />
              },
              isSearched: searchParamsSurgical.surgicaleventdata.eventdate,
              handleClear: () => handleChangeSearchValue('', 'eventdate'),
              options: {
                width: 160
              }
            }],
            ["Procedure", "payload.surgicaleventdata.surgicalprocedure"],
            ["Products", "payload.surgicaleventdata.shippedreceivedproducts", {
              options: {
                type: 'number',
                headerClassName: classes.centerCell,
                cellClassName: classes.centerCell,
              },
            }],
            ["Inventory Details", "payload.purchaseorders", {
              type: "collapse",
              columns: [
                ["PO Number", "popurchaseorderid", {
                  type: 'link',
                  onClick: (contractId, contract) => {
                    if (!!contract) {
                      // console.log("[ScanInventoryHospitalContracts] click", contract);
                      setPOData({ ...contract });
                      setInventoryList(contract.inventorydata);
                      setDetailViewer(true);
                    }
                  },
                  search: {
                    component:
                      <AdaptiveSearch
                        getValue={(c) => c.popurchaseorderid}
                        handleChangeSearch={(v) => handleChangeSearchValue(v, 'popurchaseorderid')}
                        defaultValue={searchParams.popurchaseorderid}
                        label="Search PO Number"
                        contracts={allContracts}
                        isShrink={true}
                      />
                  },
                  isSearched: searchParams.popurchaseorderid,
                  handleClear: () => handleChangeSearchValue('', 'popurchaseorderid'),
                }],
                ["PO Date", "podate"],
                ["Received Status", "poinventorystatus", {
                  type: "render",
                  render: (param) => (InventoryPOStatusHospital[param.value] && <Chip
                    label={InventoryPOStatusHospital[param.value]}
                    size="small"
                    className={classNames(classes.smallChip,
                      (param.value === 'AllReceived' ? classes.backGreen : (
                        param.value === 'SomeReceived' ? classes.backYellow : (
                          param.value === 'AllShipped' ? classes.backBlue : (
                            param.value === 'SomeShipped' ? classes.backPink : classes.backRed
                          )
                        )
                      ))
                    )}
                  />),
                  search: {
                    component:
                      <SelectSearch
                        handleChangeSearch={(v) => handleChangeSearchValue(v, 'poinventorystatus')}
                        label="Search Status"
                        options={Object.keys(InventoryPOStatusHospital).map(value => ({ label: InventoryPOStatusHospital[value], value: value }))}
                        defaultValue={searchParams.poinventorystatus}
                        isStatic={true}
                      />
                  },
                  isSearched: searchParams.poinventorystatus,
                  handleClear: () => handleChangeSearchValue('', 'poinventorystatus'),
                }],
                ["Manufacturer", "inventorydata.0.iproduct.sepproductmanufacturer"], // TODO - check if manufacturer equals to vendor
                ["Distributor", "povendorid", { // TODO - check if distributor equals to vendor
                  search: {
                    component:
                      <AdaptiveSearch
                        getValue={(c) => c.povendorid}
                        handleChangeSearch={(v) => handleChangeSearchValue(v, 'povendorid')}
                        defaultValue={searchParams.povendorid}
                        label="Search Manufacturer"
                        contracts={allContracts}
                        isShrink={true}
                      />
                  },
                  isSearched: searchParams.povendorid,
                  handleClear: () => handleChangeSearchValue('', 'povendorid'),
                }],
                ["Ownership", "popurchaseordertype"],
                ["Received Products", "receivedinventorydatalength", {
                  type: "object",
                  options: {
                    headerClassName: classes.centerCell,
                    cellClassName: classes.centerCell,
                  },
                }],
                ["Total Amount", "pototalamount", {
                  options: {
                    beforeText: '$'
                  }
                }],
                ["Inventory Details", "inventorydata", {
                  type: "collapse",
                  columns: [
                    ["Reference Number", "iproduct.sepreferencenumber"],
                    ["Product Description", "iproduct.sepproductdescription", {
                      options: {
                        tooltip: true
                      }
                    }],
                    ["Expiration", "iproduct.sepproductexpiration"],
                    ["Price", "iproduct.sepproductprice", {
                      options: {
                        beforeText: '$'
                      }
                    }],
                    ["Lot Code", "iproduct.seplotcode"],
                    ["Product Type", "iproduct.sepproducttype"],
                    ["Quantity", "iquantity"],
                    ["Receipt Status", "iproductstatus", {
                      type: "render",
                      render: (param) => {
                        const receiptStatus = (param.value === 'ShippedVendor' ? 'Received' : param.value);
                        return (InventoryProductStatus[receiptStatus] && <Chip
                          label={InventoryProductStatus[receiptStatus]}
                          size="small"
                          className={classNames(classes.smallChip, (
                            receiptStatus === 'Received' ? classes.backGreen : classes.backYellow
                          ))}
                        />);
                      },
                    }],
                  ],
                  actions: [
                    [
                      "Confirmation",
                      [
                        [["Confirm", [<img alt="" src={checkIcon} />, <img alt="" src={completeIcon} />],
                          (c) => isEnableConfirmHospital(c.iproductstatus)],
                          handleConfirm],
                      ],
                    ],
                  ],
                  options: {
                    // sortKey: 'sortkey',
                  },
                }],
              ],
              actions: [
              ],
              options: {
              },
            }],
          ]}

          actions={[
            [
              "Action",
              [
                [["Archive", [<img alt="" src={archiveIcon} />, <img alt="" src={archiveDisableIcon} />],
                  checkEnableArchiveSE],
                  handleArchiveSE,
                ],
              ],
            ],
          ]}
          options={{
            statusEmpty: (allContracts.length === 0)
          }}
        />


        <SEDetailViewDrawer
          state={sestate}
          toggleDrawer={(open) => setSEState(open)}
          selectedEventData={seData}
          poList={poList}
          isPPH={true}
        />

        <DetailViewDrawer
          state={detailViewer}
          toggleDrawer={(open) => setDetailViewer(open)}
          selectedPOData={poData}
          inventoryList={inventoryList}
        />

        <ReceiptConfirmInventory
          openModal={openConfirmModal}
          setOpenModal={setOpenConfirmModal}
          addProductItem={handleConfirmItem}
          inventoryData={confirmInventoryData}
          setConfirmInventoryData={setConfirmInventoryData}
          clearProductParams={[]} // show seplotcode, sepproductexpiration in Confirm Modal
        />

        <ModalDialogBox
          open={openWarning}
          setOpen={setOpenWarning}
          heading="Archive the Surgical Event?"
          type="warning"
          // text={'<p> You will not be able to Recover it.</p>'}
          text={'<p>Archiving  the Surgical Event on this page will impact Surgical Events, Purchase Order and Restock Inventory Records.  Please be aware that you will not be able to recover from this.</p><p>In the future,  to display Archived Contract Information, please check the “Include Archived Contracts” checkmark.</p>'}
          handleRequest={handleModalYes}
          secondBtn={'No'}
          handleSecRequest={handleSecRequest}
        />

      </div>
    </>
  );
}

export default withLoading(ScanInventoryHospitalContracts);
