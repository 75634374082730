import { requiredFields } from "./handles";

export const API_PayloadModel = {
  referenceId: '',
  procurementBU: '',
  requisitioningBU: '',
  buyer: '',
  supplier: '',
  currency: '',
  supplierSite: '',
  billToLocation: '',
  defaultShipToLocation: '',
  requiredAcknowledgment: '',
  description: '',
  supplierCommunicationMethod: '',
  supplierEmailAddress: '',
  buyerManagedTransportFlag: false,
  payOnReceiptFlag: false,
  paymentTerms: '',
  freightTerms: '',
  fOB: '',
  noteToReceiver: '',
  noteToSupplier: '',
  carrier: '',
  serviceLevel: '',
  modeOfTransportCode: '',
  defaultTaxationCountry: '',
  firstPartyRegistrationNumber: '',
  thirdPartyRegistrationId: '',
  documentFiscalClassification: '',
  totalAmount: '',
  taxAmount: '',
};
export const validateAPI_Payload = (api_payload) => {
  return (api_payload.referenceId !== ""
    || api_payload.procurementBU !== ""
    || api_payload.requisitioningBU !== ""
    || api_payload.buyer !== ""
    || api_payload.supplier !== ""
    || api_payload.currency !== ""
    || api_payload.supplierSite !== ""
    || api_payload.billToLocation !== ""
    || api_payload.defaultShipToLocation !== ""
    || api_payload.requiredAcknowledgment !== ""
    || api_payload.description !== ""
    || api_payload.supplierCommunicationMethod !== ""
    || api_payload.supplierEmailAddress !== ""
    || api_payload.paymentTerms !== ""
    || api_payload.freightTerms !== ""
    || api_payload.fOB !== ""
    || api_payload.noteToReceiver !== ""
    || api_payload.noteToSupplier !== ""
    || api_payload.carrier !== ""
    || api_payload.serviceLevel !== ""
    || api_payload.modeOfTransportCode !== ""
    || api_payload.defaultTaxationCountry !== ""
    || api_payload.firstPartyRegistrationNumber !== ""
    || api_payload.thirdPartyRegistrationId !== ""
    || api_payload.documentFiscalClassification !== ""
    || api_payload.totalAmount !== ""
    || api_payload.taxAmount !== "");
};
export const getAPI_Payload = (api_payload) => {
  let newAPI_Payload = {};
  newAPI_Payload.referenceId = api_payload.referenceId || "";
  if (typeof api_payload.procurementBU === "string" && api_payload.procurementBU !== "") newAPI_Payload.procurementBU = api_payload.procurementBU;
  if (typeof api_payload.requisitioningBU === "string" && api_payload.requisitioningBU !== "") newAPI_Payload.requisitioningBU = api_payload.requisitioningBU;
  if (typeof api_payload.buyer === "string" && api_payload.buyer !== "") newAPI_Payload.buyer = api_payload.buyer;
  if (typeof api_payload.supplier === "string" && api_payload.supplier !== "") newAPI_Payload.supplier = api_payload.supplier;
  if (typeof api_payload.currency === "string" && api_payload.currency !== "") newAPI_Payload.currency = api_payload.currency;
  if (typeof api_payload.supplierSite === "string" && api_payload.supplierSite !== "") newAPI_Payload.supplierSite = api_payload.supplierSite;
  if (typeof api_payload.billToLocation === "string" && api_payload.billToLocation !== "") newAPI_Payload.billToLocation = api_payload.billToLocation;
  if (typeof api_payload.defaultShipToLocation === "string" && api_payload.defaultShipToLocation !== "") newAPI_Payload.defaultShipToLocation = api_payload.defaultShipToLocation;
  if (typeof api_payload.requiredAcknowledgment === "string" && api_payload.requiredAcknowledgment !== "") newAPI_Payload.requiredAcknowledgment = api_payload.requiredAcknowledgment;
  if (typeof api_payload.description === "string" && api_payload.description !== "") newAPI_Payload.description = api_payload.description;
  if (typeof api_payload.supplierCommunicationMethod === "string" && api_payload.supplierCommunicationMethod !== "") newAPI_Payload.supplierCommunicationMethod = api_payload.supplierCommunicationMethod;
  if (typeof api_payload.supplierEmailAddress === "string" && api_payload.supplierEmailAddress !== "") newAPI_Payload.supplierEmailAddress = api_payload.supplierEmailAddress;
  if (typeof api_payload.buyerManagedTransportFlag === "boolean") newAPI_Payload.buyerManagedTransportFlag = api_payload.buyerManagedTransportFlag;
  if (typeof api_payload.payOnReceiptFlag === "boolean") newAPI_Payload.payOnReceiptFlag = api_payload.payOnReceiptFlag;
  if (typeof api_payload.paymentTerms === "string" && api_payload.paymentTerms !== "") newAPI_Payload.paymentTerms = api_payload.paymentTerms;
  if (typeof api_payload.freightTerms === "string" && api_payload.freightTerms !== "") newAPI_Payload.freightTerms = api_payload.freightTerms;
  if (typeof api_payload.fOB === "string" && api_payload.fOB !== "") newAPI_Payload.fOB = api_payload.fOB;
  if (typeof api_payload.noteToReceiver === "string" && api_payload.noteToReceiver !== "") newAPI_Payload.noteToReceiver = api_payload.noteToReceiver;
  if (typeof api_payload.noteToSupplier === "string" && api_payload.noteToSupplier !== "") newAPI_Payload.noteToSupplier = api_payload.noteToSupplier;
  if (typeof api_payload.carrier === "string" && api_payload.carrier !== "") newAPI_Payload.carrier = api_payload.carrier;
  if (typeof api_payload.serviceLevel === "string" && api_payload.serviceLevel !== "") newAPI_Payload.serviceLevel = api_payload.serviceLevel;
  if (typeof api_payload.modeOfTransportCode === "string" && api_payload.modeOfTransportCode !== "") newAPI_Payload.modeOfTransportCode = api_payload.modeOfTransportCode;
  if (typeof api_payload.defaultTaxationCountry === "string" && api_payload.defaultTaxationCountry !== "") newAPI_Payload.defaultTaxationCountry = api_payload.defaultTaxationCountry;
  if (typeof api_payload.firstPartyRegistrationNumber === "string" && api_payload.firstPartyRegistrationNumber !== "") newAPI_Payload.firstPartyRegistrationNumber = api_payload.firstPartyRegistrationNumber;
  if (typeof api_payload.thirdPartyRegistrationId === "string" && api_payload.thirdPartyRegistrationId !== "") newAPI_Payload.thirdPartyRegistrationId = api_payload.thirdPartyRegistrationId;
  if (typeof api_payload.documentFiscalClassification === "string" && api_payload.documentFiscalClassification !== "") newAPI_Payload.documentFiscalClassification = api_payload.documentFiscalClassification;
  if (typeof api_payload.totalAmount === "string" && api_payload.totalAmount !== "") newAPI_Payload.totalAmount = api_payload.totalAmount;
  if (typeof api_payload.taxAmount === "string" && api_payload.taxAmount !== "") newAPI_Payload.taxAmount = api_payload.taxAmount;
  return newAPI_Payload;
};
export const requiredAPI_Payload = (api_payload) => requiredFields(api_payload, {
  'referenceId': 'Reference Id',
});

export const API_Payload_LinesModel = {
  lineNumber: '',
  referenceId: '',
  lineType: '',
  category: '',
  description: '',
  quantity: '',
  price: '',
  uOM: '',
};
export const validateAPI_Payload_Lines = (api_payloadline) => {
  return (api_payloadline.lineNumber !== ""
    || api_payloadline.referenceId !== ""
    || api_payloadline.lineType !== ""
    || api_payloadline.category !== ""
    || api_payloadline.description !== ""
    || api_payloadline.quantity !== ""
    || api_payloadline.price !== ""
    || api_payloadline.uOM !== "");
};
export const getAPI_Payload_Lines = (api_payloadline) => {
  let newAPI_Payload_Line = {};
  newAPI_Payload_Line.lineNumber = api_payloadline.lineNumber || "0";
  if (typeof api_payloadline.referenceId === "string" && api_payloadline.referenceId !== "") newAPI_Payload_Line.referenceId = api_payloadline.referenceId;
  if (typeof api_payloadline.lineType === "string" && api_payloadline.lineType !== "") newAPI_Payload_Line.lineType = api_payloadline.lineType;
  if (typeof api_payloadline.category === "string" && api_payloadline.category !== "") newAPI_Payload_Line.category = api_payloadline.category;
  if (typeof api_payloadline.description === "string" && api_payloadline.description !== "") newAPI_Payload_Line.description = api_payloadline.description;
  if (typeof api_payloadline.quantity === "string" && api_payloadline.quantity !== "") newAPI_Payload_Line.quantity = api_payloadline.quantity;
  if (typeof api_payloadline.price === "string" && api_payloadline.price !== "") newAPI_Payload_Line.price = api_payloadline.price;
  if (typeof api_payloadline.uOM === "string" && api_payloadline.uOM !== "") newAPI_Payload_Line.uOM = api_payloadline.uOM;
  return newAPI_Payload_Line;
};
export const requiredAPI_Payload_Lines = (api_payloadline) => requiredFields(api_payloadline, {
  'lineNumber': 'Line Number',
});
