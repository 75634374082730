import React from "react";
// import { HospitalTemplate } from "../../services/daml-modules1";
import AdaptiveSearch from "../../components/Search/adaptivesearch";
import { contractFilter } from "../../services/daml-filters";
import editIcon from "../../icons/edit.svg";
import Contracts from "../../components/Contracts/Contracts";
import useStyles from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";


/**
 * Hospital Template Contracts in Vendor
 * @param {Object} searchParams 
 * @param {Function} handleChangeSearchValue 
 * @param {Array} allContracts 
 * @param {Object} searchHospitalTemplate 
 * @param {Function} handleRequest 
 * @returns 
 */
function HospitalTemplateContracts({
  searchParams = {},
  handleChangeSearchValue = null,
  allContracts = [],
  searchHospitalTemplate = {},
  handleRequest,
}) {

  const classes = useStyles();
  // const { contracts } = useStreamQueries(HospitalTemplate, () => [searchParams]);
  let contracts = contractFilter(allContracts, searchParams);

  console.log("[HospitalTemplateContracts]", searchParams, contracts);


  return (
    <Contracts
      className={classes.customRoot}
      contracts={contracts}
      columns={[
        // ["ContractId", "contractId"],
        ["Hospital Name", "payload.hospital", {
          options: {
            flex: 1,
          },
          search: {
            component:
              <AdaptiveSearch
                getValue={(c) => c.payload.hospital}
                handleChangeSearch={(v) => handleChangeSearchValue(v, 'hospital')}
                defaultValue={searchHospitalTemplate.hospital}
                label="Search By Hospital Name"
                contracts={allContracts}
                isShrink={true}
              />
          },
          isSearched: searchHospitalTemplate.hospital,
          handleClear: () => handleChangeSearchValue('', 'hospital'),
        }],
        ["Id", "payload.api_index1._id", {
          options: {
            flex: 1,
          }
        }],
        ["API Index", "payload.api_index1._index", {
          options: {
            flex: 1,
          }
        }],
        ["Supplier", "payload.api_invoice.supplier", {
          options: {
            flex: 1,
          }
        }],
        ["Invoice Number", "payload.api_invoice.invoiceNumber", {
          options: {
            flex: 1,
          }
        }],
      ]}

      actions={[
        [
          "Action",
          [
            [["Edit", [<img alt="" src={editIcon} />]],
              handleRequest],
          ],
        ]
      ]}

      options={{
        statusEmpty: (allContracts.length === 0)
      }}
    />
  );
};

export default withLoading(HospitalTemplateContracts);
