import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { CssBaseline } from "@material-ui/core";
import { LicenseInfo } from '@mui/x-license-pro';
import { Auth0Provider } from '@auth0/auth0-react';
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import { store } from './store';
import { auth0, MUI_LICENSE_KEY } from "./config";


// MUI Pro License Key
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

ReactDOM.render(
  <Auth0Provider
    domain={auth0.domain}
    clientId={auth0.clientId}
    redirectUri={window.location.origin}
    audience={auth0.audience}
    /**
     * Store cache data in `localstorage`.
     */
    cacheLocation="localstorage"
    /**
     * Not use refresh token.
     */
    useRefreshTokens={true}
    /**
     * After 30 minutes, needs to login again.
     */
    maxAge={1800}
    /**
     * Use cache to generate refresh token
     */
    cache={false}
    /**
     * scope to add details in access_token
     */
    scope={auth0.scope}
  >
    <Provider store={store}>
      <LayoutProvider>
        <UserProvider>
          <CssBaseline />
          <App />
        </UserProvider>
      </LayoutProvider>
    </Provider>
  </Auth0Provider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
