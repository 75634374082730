import { validateDate, validateDateTime, validateTime } from "./util";

/**
 * check if the object is matched to pattern object
 * @param {Object} obj 
 * @param {Object} pattern 
 * @returns {Boolean}
 */
const checkObjMatch = (obj = {}, pattern = {}) => {
    // console.log("[checkObjMatch]", obj, pattern);
    let result = true;
    for (let key in pattern) {
        // console.log("[checkObjMatch]", key);
        if (!(key in obj)) return false;
        let val = pattern[key], data = obj[key];
        if (typeof val === 'undefined') continue;
        if (val && typeof val === 'object') {
            result = result && checkObjMatch(data, val);
        }
        else {
            result = result && (val === data);
        }
        // console.log("[checkObjMatch]", result);
        if (!result) return false;
    }
    // console.log("[checkObjMatch]", result);
    return result;
};

/**
 * check if the object is matched to pattern object
 * New Version with Array Object instead of An Object
 * @param {Object|Array} obj 
 * @param {Object} pattern 
 * @param {Object} type list | date | time
 * @returns {Boolean}
 */
const checkObjMatchArr = (obj = {}, pattern = {}, type='list') => {
    // console.log("[checkObjMatchArr]", obj, pattern, type);
    let result = true;
    if (typeof pattern !== 'object') return false;
    // If obj is Array,
    if (obj && typeof obj === 'object' && obj.length >= 0) {
        return obj.some(newobj => checkObjMatchArr(newobj, pattern, type));
    }
    if (pattern.length === undefined) {
        // object pattern
        for (let key in pattern) {
            if (!(key in obj)) return false;
            let val = pattern[key], data = obj[key];
            if (typeof val === 'undefined') continue;
            if (val && typeof val === 'object') {
                result = result && checkObjMatchArr(data, val, type);
            }
            else {
                result = result && (val === data);
            }
            if (!result) return false;
        }
    }
    else {
        // array pattern
        if (pattern.length === 0) return true;
        // list type
        if (type === 'list') return pattern.some(val => val === obj);
        // from date|time, to date|time
        let [from, to] = pattern;
        if (type === 'date') {
            const val = (validateDate(obj) || validateDateTime(obj)) ? new Date(obj) : null;
            result = result && val;
            // console.log("[checkObjMatchArr]", val, from, to);
            if (from && result) {
                from = new Date(from);
                result = result && from && (val >= from);
                // console.log("[checkObjMatchArr]", from, (val >= from), result);
            }
            if (to && result) {
                to = new Date(to);
                result = result && to && (val <= to);
                // console.log("[checkObjMatchArr]", to, (val <= to), result);
            }
        }
        else if (type === 'time') {
            const val = validateTime(obj) ? obj.substring(0, 5) : null;
            result = result && val;
            // console.log("[checkObjMatchArr]", "<"+obj+">", val, from, to);
            if (from && result) {
                result = result && (val >= from);
                // console.log("[checkObjMatchArr]", from, (val >= from), result);
            }
            if (to && result) {
                result = result && (val <= to);
                // console.log("[checkObjMatchArr]", to, (val <= to), result);
            }
        }
    }
    return result;
};

/**
 * filter a object to match to pattern object
 * @param {Object|Array} obj 
 * @param {Object} pattern 
 * @returns {Object}
 */
const filterObjMatchArr = (obj = {}, pattern = {}) => {
    // console.log("[filterObjMatchArr]", obj, pattern);
    let result = true;
    if (typeof pattern !== 'object') return undefined;
    // If obj is Array,
    if (obj && typeof obj === 'object' && obj.length >= 0) {
        // filter object
        return obj.reduce((s, newobj) => {
            const newsubobj = filterObjMatchArr(newobj, pattern);
            if (newsubobj !== undefined) s.push(newsubobj);
            return s;
        }, []);
    }
    if (pattern.length === undefined) {
        // object pattern
        const newresult = {...obj};
        for (let key in pattern) {
            if (!(key in obj)) return undefined;
            let val = pattern[key], data = obj[key];
            if (typeof val === 'undefined') continue;
            if (val && typeof val === 'object') {
                const newsubobj = filterObjMatchArr(data, val);
                // console.log("[filterObjMatchArr]", newresult, key, newsubobj);
                if (newsubobj !== undefined) {
                    newresult[key] = newsubobj;
                }
                else return undefined;
            }
            else {
                result = result && (val === data);
            }
            if (!result) return undefined;
        }
        return newresult;
    }
    else {
        // console.log("[filterObjMatchArr]", obj, pattern);
        // array pattern
        if (pattern.length === 0) return obj;
        result = pattern.some(val => val === obj);
    }
    // console.log("[filterObjMatchArr]", obj, pattern, result);
    return result ? obj : undefined;
};

/**
 * Make Object by the String Path
 * @param {String} path Split by "."
 * @param {Object} value
 * @param {Object} old Old Object
 * @returns {Object}
 */
const pathToObj = (path, value, old={}) => {
    // console.log("[pathToObj]", path, value, JSON.stringify(old));
    const splits = typeof path === "string" && path !== "" ? path.split(".") : path;
    if (splits.length === 1) return {...old, [splits[0]]: value};
    if (typeof old[splits[0]] !== 'object') old[splits[0]] = {};
    // console.log("[pathToObj]", splits[0], JSON.stringify(old),  JSON.stringify(old[splits[0]]));
    return {...old, [splits[0]]: pathToObj(splits.slice(1), value, old[splits[0]])};
};

/**
 * filter DAML contracts by search parameters
 * @param {Array} contracts 
 * @param {Object} searchParams 
 * @returns {Array}
 */
export const contractFilter = (contracts = [], searchParams = {}) => {
    // console.log("[contractFilter]", contracts, searchParams);
    if (!contracts || typeof contracts !== 'object' || contracts.length === 0 
        || !('payload' in contracts[0]) || !searchParams || typeof searchParams !== 'object') return contracts;
    return contracts.filter(c => checkObjMatch(c.payload, searchParams));
};

/**
 * filter DAML contracts by search parameters
 * New Version with multiple Arrays
 * @param {Array} contracts 
 * @param {Object} searchParams 
 * @returns {Array}
 */
export const contractFilter1 = (contracts = [], searchParams = {}) => {
    // console.log("[contractFilter1]", contracts, searchParams);
    if (!contracts || typeof contracts !== 'object' || contracts.length === 0 
        || !('payload' in contracts[0]) || !searchParams || typeof searchParams !== 'object') return contracts;
    // New version -> all searchParams is string with "."
    let newSearchParams = {};
    for (let key in searchParams) {
        if (searchParams[key] === undefined || (typeof searchParams[key] === 'object' && searchParams[key].length === 0)) continue;
        newSearchParams = pathToObj(key, searchParams[key], newSearchParams);
    }
    // Three type (list | date | time | filter) => 4 search params
    const {list: listSearchParams, date: dateSearchParams, time: timeSearchParams, filter: filterSearchParams} = newSearchParams;
    // console.log("[contractFilter1]", listSearchParams, filterSearchParams);
    return contracts.filter(c => checkObjMatchArr(c.payload, listSearchParams))
                    .filter(c => checkObjMatchArr(c.payload, filterSearchParams))
                    .filter(c => checkObjMatchArr(c.payload, dateSearchParams, 'date'))
                    .filter(c => checkObjMatchArr(c.payload, timeSearchParams, 'time'))
                    .reduce((s, c) => {
                        const payload = filterObjMatchArr(c.payload, filterSearchParams);
                        if (payload) return s.concat([{...c, payload: payload}]);
                        else return s;
                    }, []);
};

/**
 * filter DAML assets by search parameters
 * @param {Object} assets 
 * @param {Object} searchParams 
 * @returns {Object}
 */
export const assetsFilter = (assets = {}, searchParams = {}) => {
    // console.log("[assetsFilter]", assets, searchParams);
    if (!('contracts' in assets)) return assets;
    return {
        ...assets,
        contracts: contractFilter([...assets.contracts], searchParams),
    };
};
