import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@material-ui/core";
import TextField from '@mui/material/TextField';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import classNames from "classnames";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { setByPath } from "../../components/Contracts/functions";
import FileUploadDropzone, { handlePreviewIcon } from "../../components/FileUpload/FileUploadDropzone";
import { CustomSelect, CustomTextField } from "../../components/Inputs";
import { useDamlState } from "../../context/DamlContext";
import folderIcon from "../../icons/mdi_folder-upload.svg";
import closeIcon from "../../icons/new-close-icon.svg";
import closeIconDM from "../../icons/new-close-icon-dm.svg";
import infoIcon from "../../icons/info_icon.svg";
import { getSubModel } from "../../models/handles";
import { Paytype } from "../../models/HospitalDetail";
import useStyles from "./styles";
import { usePaymentDateCalculator } from './utills';
import { NODE_SERVER_ENDPOINT } from "../../config";
import viewFile from "../../icons/view_file.svg";
import downloadFile from "../../icons/download.svg";
import deleteFile from "../../icons/delete.svg";
import ModalDialog from '../../components/Modals/NewGeneralModal';
import viewFileDM from "../../icons/view_file-dm.svg";
import downloadFileDM from "../../icons/download-dm.svg";
import { InputDistributor } from "../surgicalevent/edit-product1";

export default function EditPayment({
  openModal, setOpenModal,
  paymentDetail, type, handleUpdate, options,
}) {

  const classes = useStyles();
  const [payload, setPayload] = useState();
  const [invoiceId, setInvoiceId] = useState();
  const [amount, setAmount] = useState();
  const [confirmAmount, setConfirmAmount] = useState("");
  const [confirmInvoiceId, setConfirmInvoiceId] = useState("");

  const dateCalculator = usePaymentDateCalculator();

  const { rolePayload } = useDamlState();

  const [isInvoiceIDValid, setIsInvoiceIDValid] = useState('');
  const [isAmountValid, setIsAmountValid] = useState('');
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [remainingFiles, setRemainingFiles] = useState([]);
  const handleCancel = () => {
    setOpenModal(false);
    setConfirmInvoiceId('');
    setConfirmAmount('');
    setIsInvoiceIDValid(null);
    setIsAmountValid(null);
    setDeletedFiles([]);
    setUploadedFiles([]);
    setRemainingFiles([]);
  };


  useEffect(() => {
    if (openModal) {
      // setErrorModal(false);
      setAmount(paymentDetail.paymentamount)
      setInvoiceId(paymentDetail?.invoicedata?.invinvoicenumber);
      setPayload({ ...paymentDetail, payemnttype: type });
      setConfirmInvoiceId('');
      setConfirmAmount('');
      setIsInvoiceIDValid(null);
      setIsAmountValid(null);
      setPaymentDueOffset(paymentDetail?.paymenttype);
      setRemainingFiles(paymentDetail?.fileName);
    }
  }, [openModal, paymentDetail, type]);



  const styleCSS = (success) => {
    if (success === true) {
      return (classes.custSuccessSelection);
    } else if (success === false) {
      return (classes.custFailSelection);
    }
    return null;
  }
  const [openDoc, setOpenDoc] = useState(false);
  const [fileLink, setFileLink] = useState("");
  const handleFile = async (file) => {
    const response = (await ((await fetch(NODE_SERVER_ENDPOINT + `api/sap-getsignedurls/${file}`, {
      method: 'GET',
    })).json())).data
    if (file.includes(".pdf") || file.includes(".jpg") || file.includes(".png")) {
      setFileLink(response)
      setOpenDoc(true);
    }
    else
      window.open(response);
  }
  const handleDeleteFile = (file) => {
    setDeletedFiles([...deletedFiles, file]);
    setRemainingFiles(remainingFiles.filter(f => f !== file))
  }
  const getFileUploadZone = (issystemgen) => {

    return issystemgen ? null : (<>
      <Grid item xs={6}>
        <div className={options.layoutState.darkMode ? classes.viewFileEditDM : classes.viewFileEdit}>
          {
            remainingFiles.map(f => <div key={f} className={classes.singleFile}>
              <div>
                {f.slice(30).length < 24 ? (f.slice(30)) : (f.slice(30, 48) + "...")}
              </div>
              <div >
                {
                  (f.includes(".pdf") || f.includes(".jpg") || f.includes(".png")) ?
                    <img style={{ cursor: "pointer" }} src={options.layoutState.darkMode ? viewFileDM : viewFile} onClick={() => handleFile(f)} height={15} width={15} alt="view file" /> :
                    <img style={{ cursor: "pointer" }} src={options.layoutState.darkMode ? downloadFileDM : downloadFile} onClick={() => handleFile(f)} height={15} width={15} alt="download file" />
                }
                <img style={{ cursor: "pointer" }} src={deleteFile} onClick={() => handleDeleteFile(f)} height={15} width={15} alt="download file" />
              </div>
            </div>)
          }
        </div>
      </Grid>
      <Grid item xs={6}>
        <FileUploadDropzone
          className={options.layoutState.darkMode ? classes.uploadFileStyleDM : classes.uploadFileStyle}
          title=""
          uploadLabel={(
            <Box className="drag-drop-btn">
              Drag and Drop file to upload
              <img src={folderIcon} alt="" />
            </Box>
          )}
          changeFile={() => { }}
          isNonePadding={true}
          isEnableUpload={false}
          handlePreviewIcon={handlePreviewIcon}
          filesLimit={3}
          setUploadedFiles={setUploadedFiles}
        />
      </Grid>
    </>)
  }

  //#region  
  const [paymentOffset, setPayemtOffset] = useState({ offset: 0, referalDate: new Date() })
  const setPaymentDueOffset = (paymentType) => {
    const currentHour = new Date().getHours();
    const currentMinutes = new Date().getMinutes();
    let offset = 0;
    let dateOffset = new Date();
    if (!(currentHour < 16 || (currentHour === 16 && currentMinutes < 30))) {
      let tomorrow = new Date()
      tomorrow.setDate(dateOffset?.getDate() + 1)
      dateOffset = tomorrow;
    }
    switch (paymentType) {
      case 'VCard':
        offset = 1
        break;
      default:
        offset = 2
        break;
    }
    setPayemtOffset({ offset, referalDate: dateOffset })
  }
  //#endregion

  return (
    <>
      <Dialog open={openModal} className={options.layoutState.darkMode ? classes.customRootDM : classes.customRoot} disableEnforceFocus aria-labelledby="draggable-dialog-title">
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <Button className={classes.closeButton} onClick={handleCancel}>
            <img src={options.layoutState.darkMode ? closeIconDM : closeIcon} alt="" />
          </Button>
        </Grid>
        <DialogTitle className='MuiDialogTitleRoot' style={{ borderBottom: options.layoutState.darkMode ? "1px solid #FFFFFF" : "" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            className={classes.newDIATitlePanel}
          >

            <Typography variant="inherit" component="span" className={options.layoutState.darkMode ? classes.popUpTitleDM : classes.popUpTitle}>
              Edit Payment
            </Typography>
          </Grid>

        </DialogTitle>
        <DialogContent
          dividers={true}
        >
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={4}>
              <label className={options.layoutState.darkMode ? classes.popUpLabelDM : classes.popUpLabel}>Distributor</label>
            </Grid>
            <Grid item xs={8}>
              {/* <CustomSelect
                items={vendors}
                value={payload?.vendor}
                placeholder="Select One"
                width={'83%'}
                onChange={(e) => {
                  setPayload(setByPath(e, 'vendor', payload))
                }}
              /> */}
              <InputDistributor
                distributor={payload?.vendor}
                setDistributor={(val) => {
                  setPayload(setByPath(val, 'vendor', payload))
                }}
                placeholder="Select One"
                // width={'83%'}
              />
            </Grid>
            <Grid item xs={4}>
              <label className={options.layoutState.darkMode ? classes.popUpLabelDM : classes.popUpLabel}>Type</label>
            </Grid>
            <Grid item xs={8}>
              <CustomSelect
                items={getSubModel(Paytype, rolePayload?.hospitaldetails?.ach_info?.paymethod)}
                value={payload?.paymenttype}
                placeholder="Select One"
                width={'83%'}
                onChange={(e) => {
                  setPayload(setByPath(e, 'paymenttype', payload))
                  setPaymentDueOffset(e);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <label className={options.layoutState.darkMode ? classes.popUpLabelDM : classes.popUpLabel}>Invoice ID</label>
            </Grid>
            <Grid item xs={8}>
              <CustomTextField
                warning={invoiceId === "" ? " " : ""}
                value={invoiceId}
                onChange={(e) => {
                  setInvoiceId(e);
                  if (e !== "")
                    setIsInvoiceIDValid(confirmInvoiceId === e);
                }}
                options={{
                  disabled: false,
                  disableHelper: true,
                }}
                width={'83%'}
              />
            </Grid>
            <Grid item xs={1}>
            </Grid>
            <Grid item xs={3}>
              <label className={options.layoutState.darkMode ? classes.confirmLabelDM : classes.confirmLabel}>Confirm Invoice</label>
            </Grid>
            <Grid item xs={8}>
              <CustomTextField
                value={confirmInvoiceId}
                onChange={(e) => {
                  setConfirmInvoiceId(e);
                  setIsInvoiceIDValid(invoiceId === e);
                }}
                options={{ disabled: false }}
                width={'83%'}
                className={styleCSS(isInvoiceIDValid)}
              />
            </Grid>

            <Grid item xs={4}>
              <label className={options.layoutState.darkMode ? classes.popUpLabelDM : classes.popUpLabel}>Amount</label>
            </Grid>
            <Grid item xs={8}>
              <CustomTextField
                type={"number"}
                warning={payload?.paymentamount === "" ? " " : ""}
                value={payload?.paymentamount}
                width={'83%'}
                onChange={(e) => {
                  setPayload(setByPath(e, 'paymentamount', payload))
                  if (e !== "")
                    setIsAmountValid(confirmAmount === e)
                }}
                options={{
                  disableHelper: true,
                }}
              />
            </Grid>
            <Grid item xs={1}>
            </Grid>
            <Grid item xs={3}>
              <label className={classNames(options.layoutState.darkMode ? classes.confirmLabelDM : classes.confirmLabel, classes.newFont)}>Confirm Amount</label>
            </Grid>
            <Grid item xs={8}>
              <CustomTextField
                type={"number"}
                width={'83%'}
                value={confirmAmount}
                onChange={(e) => {
                  setConfirmAmount(e);
                  setIsAmountValid(payload?.paymentamount === e);
                }}
                className={styleCSS(isAmountValid)}
              />
            </Grid>
            <Grid item xs={4}>
              <label className={options.layoutState.darkMode ? classes.popUpLabelDM : classes.popUpLabel}>Payment Due</label>
            </Grid>
            <Grid item xs={6}
              className={classes.positionRelative}>
              <LocalizationProvider dateAdapter={AdapterDateFns} >
                <DesktopDatePicker
                  PopperProps={{
                    sx: options.layoutState.darkMode ? {
                      "& .MuiCalendarPicker-root": {
                        backgroundColor: "#1c3c74 !important",
                        color: "#F7CD37",
                      },
                      "& .MuiPickersDay-root": {
                        color: "#FFFFFF",
                        backgroundColor: "#1c3c74",
                        "&.Mui-selected": {
                          backgroundColor: "#F7CD37 !important",
                          color: "#1c3c74 !important"
                        },
                      },
                      "& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel": {
                        color: "#F7CD37 !important"
                      }
                    } : {}
                  }}
                  className={options.layoutState.darkMode ? classes.buttongrpDM : classes.buttongrp}
                  showToolbar={false}
                  shouldDisableDate={dateCalculator?.isOffDay}
                  inputFormat="MM/dd/yyyy"
                  value={moment(payload?.paymentdate).utc()}
                  minDate={dateCalculator?.tydeiDateCalculator(paymentOffset.referalDate, paymentOffset.offset, true)}
                  onChange={(newDate) => {
                    let _payload = setByPath(moment(newDate).format('yyyy-MM-DD'), 'paymentdate', payload);
                    _payload = setByPath(moment(dateCalculator?.tydeiDateCalculator(new Date(newDate), paymentOffset.offset, false)).format('yyyy-MM-DD'), 'paymentinitiationdate', _payload);
                    setPayload(_payload);
                  }}
                  renderInput={(params) => <TextField disabled className={classes.customDateField} size="small" {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={2}>
              <label className={classNames(options.layoutState.darkMode ? classes.noteTitleDM : classes.noteTitle)}>Note</label>
            </Grid>
            <Grid item xs={10}>
              <TextField
                placeholder="Leave note here."
                multiline
                inputProps={{ style: { color: options.layoutState.darkMode ? "white" : "" } }}
                style={{ width: '87%' }}
                rows={2}
                maxRows={4}
                className={options.layoutState.darkMode ? classes.noteFieldDM : ""}
                value={payload?.note}
                onChange={(e) => {
                  setPayload(setByPath(e.target.value, 'note', payload))
                }}
              />
              <Grid>
                <span >
                  <img src={infoIcon} alt="" />
                  <Typography className={classes.infoMsg}>Your note will save automatically. </Typography>
                </span>
              </Grid>
            </Grid>

            {
              getFileUploadZone(payload?.issystemgen)
            }
            <Grid item xs={12}>
              {
                fileLink !== "" &&
                <ModalDialog
                  open={openDoc}
                  setOpen={setOpenDoc}
                  content={
                    <object data={fileLink} style={{ width: "68.5vw", height: "91vh" }} >Alternative Text</object>
                  }
                />
              }
            </Grid>
          </Grid>

          <DialogActions className={classes.actionbtns}>
            <Button variant="contained" className={classes.secondarybtn} onClick={handleCancel}>Cancel</Button>
            <Button variant="contained" className={options.layoutState.darkMode ? classes.primarybtnDM : classes.primarybtn}
              onClick={() => {
                const minDate=moment(dateCalculator?.tydeiDateCalculator(paymentOffset.referalDate, paymentOffset.offset, true)).format('yyyy-MM-DD');
                const selectedDate=payload.paymentdate;               
                if (payload.vendor === paymentDetail.vendor &&
                  payload.paymenttype === paymentDetail.paymenttype &&
                  payload.invoicedata.invinvoicenumber === invoiceId &&
                  payload.paymentamount === amount &&
                  payload.paymentdate === paymentDetail.paymentdate &&
                  payload.note === paymentDetail.note &&
                  confirmInvoiceId.length === 0 &&
                  confirmAmount.length === 0 &&
                  deletedFiles.length === 0 &&
                  uploadedFiles.length === 0 &&
                  remainingFiles.length === paymentDetail.fileName.length
                ) {
                  setOpenModal(false);
                }
                else if (invoiceId === "");
                else if (payload.invoicedata.invinvoicenumber !== invoiceId && !!options.paymentContracts.find(c => c.payload.invoicedata.invinvoicenumber === invoiceId)) {
                  options.setmsgType("error");
                  options.setOpenToastNotification(true);
                  options.setToastContent("Invoice id already exist");
                }
                else if (invoiceId !== payload.invoicedata.invinvoicenumber && invoiceId !== confirmInvoiceId)
                  setIsInvoiceIDValid(false)
                else if (confirmInvoiceId.length > 0 && invoiceId !== confirmInvoiceId)
                  setIsInvoiceIDValid(false)
                else if (payload.paymentamount === "")
                  setPayload({ ...payload, paymentamount: "" })
                else if (payload.paymentamount !== amount && payload.paymentamount !== confirmAmount)
                  setIsAmountValid(false)
                else if (confirmAmount.length > 0 && payload.paymentamount !== confirmAmount)
                  setIsAmountValid(false)
                else if(selectedDate<minDate){
                  options.setmsgType("error");
                  options.setOpenToastNotification(true);
                  options.setToastContent("Please select valid payment due date");
                }
                else {
                  handleUpdate(payload, invoiceId, payload?.note, deletedFiles, uploadedFiles, remainingFiles);
                  setDeletedFiles([]);
                  setUploadedFiles([]);
                  setRemainingFiles([]);
                }
              }}>
              Save
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

    </>
  );
}
