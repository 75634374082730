import React, { useState } from "react";
import { Button, FormControl, InputLabel, MenuItem, Popover, Select, Typography } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import CreateIcon from '@material-ui/icons/Create';
import useStyles from "../surgicalevent/styles";


export default function HospitalFilter({
  title = ''
}) {

  const[userItems,setUserItems] = useState({});
  const classes = useStyles();

  const[vendorName] = useState('First Health');



  const [anchorEl, setAnchorEl] = useState(null);
  const[openSearch,setOpenSearch]= useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenSearch(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenSearch(false)
  }


  return (
    <>  
     <Typography variant="h4" className={classes.pageTitle}>{title}
     <Button
       size="small"
       className={classes.filterOperatorBtn}
       variant="contained"
       onClick={handleClick}
        > <span>{vendorName} </span> <CreateIcon /></Button>
      <Popover
        className={classes.searchFilterPoperOp}
        id='simple-popover'
        open={openSearch}
        anchorEl={anchorEl}
        onClose={handleClose}
        
      >
        <Button className={classes.searchbtn}  onClick={()=>{setOpenSearch(false)}}><SearchIcon /></Button>
        <div className={classes.searchFilterOperator}>
        <div className={classes.marginTP30}>
              <FormControl variant="outlined" className={classes.filterFields}>
                <InputLabel className={classes.customLabel} shrink={true}>
                Select Hospital
                </InputLabel>
                <Select
                        // native
                        placeholder={"Circulating Nurse"}
                        value={"Circulating Nurse"}
                        className={classes.customFullWidth}
                        onChange={(e) => {
                          setUserItems({
                            ...userItems,
                            access_level: e.target.value,
                            });
                          
                        }} 
                    >
                  <MenuItem key={1} value="FirstHealth">First Health</MenuItem>
                  </Select>
              </FormControl> 
            </div>
           
        </div>
        
        </Popover>
        </Typography>
    
    </>
  );
}
