import React, { useState } from "react";
import classNames from "classnames";
import { useLedger } from "@daml/react";
import { Chip } from "@material-ui/core";
import { UOM } from "../../models/Product";
import { Ownerships } from "../../models/Ownership";
import { MyInventoryProductStatus } from "../../models/InventoryData";
import Contracts from "../../components/Contracts/Contracts1";
import { contractFilter1 } from "../../services/daml-filters";
import { useDamlState } from "../../context/DamlContext";
import { handleCheckedContract, handleClickedContract } from '../surgicalevent/surgicaleventlistcontracts1';
import ConfirmShipment from "../inventoryrestock/confirm-shipment";
import { getReturnStatus } from "./returninventorycontracts";
import { confirmReturnInventoryHospital } from "./handle";
import useStyles from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";
import withFilters from "../../hoc/withFilters";
import { useTranslation } from 'react-i18next';

/**
 * Return Inventory Contracts in Hospital Side
 * @param {Object} searchParams 
 * @param {Boolean} isIncludeHistory 
 * @param {Array} allContracts 
 * @param {Array} historyContracts 
 * @param {Function} setArchived
 * @param {Function} setChecked
 * @param {Function} handleEditInventory
 * @returns 
 */
function ReturnInventoryContracts({
  searchParams = {},
  isIncludeHistory = false,
  allContracts = [],
  historyContracts = [],
  setArchived = null,
  setChecked = null,
  handleEditInventory = null,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const ledger = useLedger();
  const { roleCid } = useDamlState();
  
  allContracts = allContracts.concat(isIncludeHistory && historyContracts.length ?
    historyContracts.map(h => ({ ...h, payload: h.payload.inventory })) : [])
    .map(c => {
      c.payload.itemcount = "1";
      c.payload.iproductreturnstatus = getReturnStatus(c.payload.inventorydata);
      return c;
    });
  let contracts = contractFilter1(allContracts, searchParams);
  console.log("[ReturnInventoryContracts]", contracts);

  // Confirm Inventory
  const [open, setOpen] = useState(false);
  const [confirmInventoryData, setConfirmInventoryData] = useState({});


  // handle to Shipped
  const handleShipped = async (inventoryData) => {
   
    await confirmReturnInventoryHospital({
      ledger,
      roleCid,
      vendor: confirmInventoryData.vendor,
      inventoryCid: confirmInventoryData.ContractId,
      inventorydata: inventoryData,
      newiproductstatus: 'ShippedVendor',
      updateddate: inventoryData.ishipmentdate,
    });
    //console.log("[ReturnInventoryContracts] handleShipped", res);

    setOpen(false);
  };


  return (
    <>
      <Contracts
        className={classes.customRoot}
        contracts={contracts}
        columns={[
          ["Reference Number", "payload.inventorydata.iproduct.sepreferencenumber", {
            type: "link",
            search: {
              type: "AdaptiveSearch",
              name: "inventorydata.iproduct.sepreferencenumber",
              getValue: (c) => c.payload.inventorydata.iproduct.sepreferencenumber,
              contracts: allContracts,
            },
            options: {
              flex: 1,
              headerAlign: 'center',
              align: 'center',
              cellClassName: classNames(classes.staticCellelement, classes.primaryColor),
              headerClassName: classes.staticCellelement,
            },
          }],
          ["Quantity", "payload.itemcount", {
            search: {
              type: "AdaptiveSearch",
              name: "itemcount",
              getValue: (c) => c.payload.itemcount,
              contracts: allContracts,
            },
            options: {
              flex: 0.9,
              cellClassName: classNames(classes.primaryColor, classes.centerCell),
            },
          }],
          ["Product Description", "payload.inventorydata.iproduct.sepproductdescription", {
            search: {
              type: "AdaptiveSearch",
              name: "inventorydata.iproduct.sepproductdescription",
              getValue: (c) => c.payload.inventorydata.iproduct.sepproductdescription,
              contracts: allContracts,
            },
            options: {
              flex: 2,
              tooltip: true,
            },
          }],
          ["Distributor", "payload.inventorydata.iproduct.sepdistributor", {
            search: {
              type: "AdaptiveSearch",
              name: "inventorydata.iproduct.sepdistributor",
              getValue: (c) => c.payload.inventorydata.iproduct.sepdistributor,
              contracts: allContracts,
            },
            options: {
              flex: 1,
            },
          }],
          ["Manufacturer", "payload.inventorydata.iproduct.sepproductmanufacturer", {
            search: {
              type: "AdaptiveSearch",
              name: "inventorydata.iproduct.sepproductmanufacturer",
              getValue: (c) => c.payload.inventorydata.iproduct.sepproductmanufacturer,
              contracts: allContracts,
            },
            options: {
              flex: 1,
            },
          }],
          ["Ownership", "payload.inventorydata.iproduct.sepownership", {
            search: {
              type: "SelectSearch",
              name: "inventorydata.iproduct.sepownership",
              options: Object.keys(Ownerships).map(value => ({ label: Ownerships[value], value: value })),
            },
            options: {
              flex: 0.9,
            },
          }],
          ["Price", "payload.inventorydata.iproduct.sepproductprice", {
            type: "render",
            render: (param) => (param.value ? `$${param.value}` : 'No Contract Price'),
            search: {
              type: "AdaptiveSearch",
              name: "inventorydata.iproduct.sepproductprice",
              getValue: (c) => c.payload.inventorydata.iproduct.sepproductprice,
              contracts: allContracts,
            },
            options: {
              flex: 0.8,
            },
          }],
          [`${t('main.uom.lable')}`, "payload.inventorydata.iproduct.sepuom", {
            search: {
              type: "SelectSearch",
              name: "inventorydata.iproduct.sepuom",
              options: Object.keys(UOM).map(value => ({ label: UOM[value], value: value })),
            },
            options: {
              flex: 0.7,
            },
          }],
          ["Product Status", "payload.iproductreturnstatus", {
            type: "render",
            render: (param) => (<Chip
              label={param.value}
              size="small"
              className={classNames(classes.smallChip, (
                param.value === 'Shipped' ? classes.backBlue : classes.backGreen
              ))}
            />),
            search: {
              type: "SelectSearch",
              name: "iproductreturnstatus",
              options: Object.keys(MyInventoryProductStatus).map(value => ({ label: MyInventoryProductStatus[value], value: value })),
            },
            options: {
              flex: 1,
              headerAlign: 'center',
              align: 'center',
            },
          }],
        ]}

        options={{
          statusEmpty: (allContracts.length === 0),
          checkbox: true,  
        }}
        
        handleArchived={setArchived}
        handleClicked={(e) => handleClickedContract(e, handleEditInventory)}
        handleChecked={(e) => handleCheckedContract(e, contracts, setChecked)}
      />

      <ConfirmShipment
        open={open}
        setOpen={setOpen}
        handleRequest={handleShipped}
        inventoryData={confirmInventoryData}
        setInventoryData={setConfirmInventoryData}
        clearProductParams={[]}
        isHospital={true}
      />

    </>
  );
}

const ReturnInventoryContractsWithLoading = withLoading(ReturnInventoryContracts);
export default withFilters(ReturnInventoryContractsWithLoading)([
  { name: "inventorydata.iproduct.sepreferencenumber", label: "Reference Number" },
  { name: "itemcount", label: "Quantity" },
  { name: "inventorydata.iproduct.sepproductdescription", label: "Product Description" },
  { name: "inventorydata.iproduct.sepdistributor", label: "Distributor" },
  { name: "inventorydata.iproduct.sepproductmanufacturer", label: "Manufacturer" },
  { name: "inventorydata.iproduct.sepownership", label: "Ownership" },
  { name: "inventorydata.iproduct.sepproductprice", label: "Price" },
  { name: "inventorydata.iproduct.sepuom", label: "UoM" },
  { name: "iproductreturnstatus", label: "Product Status" },
]);
