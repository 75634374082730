import React, { useEffect, useState, useCallback } from "react";
import classNames from "classnames";
import BarcodeReader from "react-barcode-reader";
import { useLedger } from "@daml/react";
import { Table, TableBody, TableRow, TableCell, Button, IconButton, Grid, Typography, Box, Badge, FormControlLabel, 
  Checkbox } from "@material-ui/core";
import { ProductTypes, Sides, WasteTypes, requiredProduct, getProduct, isEmptyProduct, UOM, getListOfUOM } from "../../models/Product";
import { Ownerships } from "../../models/Ownership";
import { checkComplianceStatusExpiration, checkComplianceStatusSide } from "../../models/SurgicalEventCompliance";
import SnackbarModal from "../../components/Modals/SnackbarModal";
import { isUseGUDID, IS_CONTRACT_NOT_ES } from "../../config";
import { CustomTextField, CustomSelect, DateInput } from "../../components/Inputs";
import { retrieveGUDID, retrieveES } from "../../services/axios/retrieves";
import { damlFetchContractPrice, damlFetchInventory, updateESContractPrice } from "../../services/axios/daml-json-api";
import { getDataFromCode, checkUdi, parseCptCode, /* isInputZero, */ isEmptyObject, getQuantityOnOrder } from "../../services/util";
import { useDamlState } from "../../context/DamlContext";
import { useUserState } from "../../context/UserContext";
import refreshIcon from "../../icons/new-barcode-refresh-icon.svg";
import refreshRunningIcon from "../../icons/new-barcode-refresh-running-icon.svg";
import retrieveIcon from "../../icons/new-retrieve-icon.svg";
import retrieveRunningIcon from "../../icons/new-retrieve-running-icon.svg";
import complianceIcon from "../../icons/compliance-warning-icon.svg";
import { PRODUCT_ADD_EVENT, PRODUCT_UPDATE_EVENT, EVENT_REQUIRED } from "../../store/actions/constants";
import useProduct, { useSurgicalEvent, useHospitalUser } from "../../hooks/useProduct";
import useEvent from "../../hooks/useEvent";
import { updateProductsContractPrice } from "./handle";
import useStyles from "./styles";
import CustomTooltip from "../../components/Tooltip/CustomTooltip";
import AdaptiveSearch from "../../components/Search/adaptivesearch1";
import { useTranslation } from 'react-i18next';

/**
 * Compliance Warning Panel
 * @param {String} type expiration | side
 * @param {String} content 
 * @param {String} size sm | md | lg
 * @param {Object} className 
 * @returns 
 */
export const ComplianceWarning = ({
  type, content = "", size = "md", className = null,
}) => {

  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      className={(size === 'lg' ? classNames(classes.compliance, "compliance-lg", className) :
        (size === 'sm' ? classNames(classes.compliance, "compliance-sm", className) :
          classNames(classes.compliance, className)))}
    >
      <Grid item>
        {
          (type === 'expiration')
            ?
            <Typography>The item has an EXPIRATION DATE before the surgical event. </Typography>
            :
            (
              (type === 'side')
                ?
                <Typography>The item’s laterality does not match the patient’s indicated laterality “{content}”</Typography>
                :
                null
            )
        }
      </Grid>
      <img src={complianceIcon} alt="" />
    </Grid>
  );
};

export const defaultPattern = [
  {
    label: 'Product Code', style: { paddingTop: 0 },
  },
  {
    key: 'barcode', width: 306, colSpan: 3,
  },
  {
    key: 'sepudi', width: 306, colSpan: 3,
  },
  {
    key: 'sepreferencenumber', width: 306, colSpan: 3,
  },
  {
    key: 'seplotcode', width: 306, colSpan: 3,
  },
  {
    label: 'Product Details',
  },
  {
    key: 'sepproductmanufacturer', width: 152, style: { width: '167px' },
    key1: 'sepdistributor', width1: 152,
  },
  {
    key: 'sepproductexpiration', width: 152,
    key1: 'sepownership', width1: 152,
    key2: 'expiration-compliance',
  },
  {
    key: 'sepproductname', width: 330, colSpan: 3,
  },
  {
    key: 'sepproductdescription', width: 330, colSpan: 3,
  },
  {
    key: 'sepproductprice', width: 152,
    key1: 'sepproducttype', width1: 152,
    key2: 'side-compliance',
  },
  {
    key: 'sepproductwaste', width: 152,
    key1: 'sepproductside', width1: 152,
    key2: 'btns',
  },
];

// For Add Prdouct in SE
export const defaultPatternMain = [
  {
    label: 'Product Code', style: { paddingTop: 0 },
  },
  {
    key: 'barcode', width: 306, colSpan: 3,
  },
  {
    key: 'sepudi', width: 306, colSpan: 3,
  },
  {
    key: 'sepreferencenumber', width: 306, colSpan: 3,
  },
  {
    key: 'seplotcode', width: 222, colSpan: 3,
  },
  {
    label: 'Product Details',
  },
  {
    key: 'sepproductmanufacturer', width: 152, style: { width: '167px' },
    key1: 'sepdistributor', width1: 152,
  },
  {
    key: 'sepproductexpiration', width: 152,
    key2: 'expiration-compliance',
  },
  {
    key: 'sepproductname', width: 306, colSpan: 3,
  },
  {
    key: 'sepproductdescription', width: 306, colSpan: 3,
  },
  {
    key: 'sepproductprice', width: 152,
    key1: 'sepproducttype', width1: 152,
    key2: 'side-compliance',
  },
  {
    key: 'sepproductwaste', width: 152,
    key1: 'sepproductside', width1: 152,
    key2: 'btns',
  },
];

/**
 * check to different between sepproductprice & sepproductesprice
 */
export const checkChangedPrice = (p) => (!!p.sepproductesprice && p.sepproductprice !== p.sepproductesprice);

/**
 * get Edit Product Component
 * @param {String} key 
 * @param {Number} width 
 * @param {String} type "number" | "number{max}" | "select" | "text" | "date" | "checkbox"
 * @param {String} label 
 * @param {Array} items 
 * @param {Boolean} disabled
 * @param {Boolean} required  
 * @returns {Component}
 */
const getEditProductComp = ({
  key, width, type, label, items, disabled = false, required = false, readOnly = false, labelLeft, labelWidth, infoBadge,
  productItems, productError, handleChange, vendors, surgicalEventData, options, classes, placeholder, 
  handleScanError, barcodeStatus, hospital, token, role, party, ledger, roleCid,
  retrieveStatus, setProductItems, setRetrieveStatus,
  openExpirationErrorModal, setOpenExpirationErrorModal, setIsChangedPrice = function() {},
  openSideErrorModal, setOpenSideErrorModal, setOpenToastConfirm, setConfirmTitle,
  handleClearClick, handleAddClick, setOpenToastNonWarning, designPattern = defaultPattern , t
}) => {

  if (!key) return null;
  if (key === 'barcode') return (
    <>
      <BarcodeReader
        onError={handleScanError}
        onScan={(data) => handleScanSuccess({
          data, options, productItems, setProductItems, setRetrieveStatus,
          retrieveStatus, hospital, token, role, party, vendors, ledger, roleCid, setOpenToastNonWarning, designPattern,
        })}
      />
      <CustomTextField
        label={label || "Scanned Barcode"}
        value={productItems.barcode || ""}
        onChange={(val) => handleChange(val, 'barcode')}
        warning={productError.barcode}
        width={width}
        options={{ disabled }}
      />
      <IconButton className="retrieve-btn" onClick={() => {
        handleBarcode({
          barcode: productItems.barcode, options, productItems, setProductItems, setRetrieveStatus,
          retrieveStatus, hospital, token, role, party, vendors, ledger, roleCid, setOpenToastNonWarning, designPattern,
        });
      }}>
        <img src={(barcodeStatus ? refreshRunningIcon : refreshIcon)} alt="" />
      </IconButton>
    </>
  );
  if (key === 'sepudi') return (
    <>
      <CustomTextField
        label={label || "UDI"}
        value={productItems.sepudi || ""}
        onChange={(val) => {
          if (!!val && (retrieveStatus !== 'enable')) setRetrieveStatus('enable');
          else if (!val && (retrieveStatus !== 'disable')) setRetrieveStatus('disable');
          handleChange(val, 'sepudi');
        }}
        warning={productError.sepudi}
        width={width}
        options={{ disabled }}
      />
      {
        !disabled &&
        <IconButton className="retrieve-btn" onClick={() => {
          if ((retrieveStatus === 'enable')) handleRetrieve({
            udi: productItems.sepudi,
            options, retrieveStatus, setRetrieveStatus, hospital, productItems, setProductItems,
            token, role, party, vendors, ledger, roleCid, setOpenToastNonWarning, designPattern, handleChange
          });
        }}>
          <img src={((retrieveStatus === 'loading') ? retrieveRunningIcon : retrieveIcon)} alt="" />
        </IconButton>
      }
    </>
  );
  if (key === 'sepreferencenumber') return (
    <>
      <CustomTextField
        label={label || "Reference Number"}
        required={(disabled && readOnly) ? false : true}
        value={productItems.sepreferencenumber || ""}
        onChange={(val) => {
          if (!!val && (retrieveStatus !== 'enable')) setRetrieveStatus('enable');
          else if (!val && (retrieveStatus !== 'disable')) setRetrieveStatus('disable');
          handleChange(val, 'sepreferencenumber');
        }}
        warning={productError.sepreferencenumber}
        width={width}
        options={{ disabled, readOnly }}
      />
      {
        !disabled &&
        <IconButton className="retrieve-btn" onClick={() => {
          if ((retrieveStatus === 'enable')) handleRetrieve({
            ref: productItems.sepreferencenumber,
            options, retrieveStatus, setRetrieveStatus, hospital, productItems, setProductItems,
            token, role, party, vendors, ledger, roleCid, setOpenToastNonWarning, designPattern, handleChange
          });
        }}>
          <img src={((retrieveStatus === 'loading') ? retrieveRunningIcon : retrieveIcon)} alt="" />
        </IconButton>
      }
    </>
  );
  if (key === 'seplotcode') return (
    <CustomTextField
      label={label || "Lot/SN"}
      value={productItems.seplotcode || ""}
      onChange={(val) => handleChange(val, 'seplotcode')}
      warning={productError.seplotcode}
      width={width}
      options={{ disabled }}
    />
  );
  if (key === 'sepproductmanufacturer') return (
    <InputDistributor
      type={type}  
      label={label || (width >= 152 ? "Product Manufacturer" : "Manufacturer")}
      distributor={productItems.sepproductmanufacturer}
      setDistributor={(val) => handleChange(val, 'sepproductmanufacturer')}
      placeholder={(disabled && readOnly) ? "" : "Select One"}
      warning={productError.sepproductmanufacturer}
      width={width}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
  if (key === 'sepdistributor') return (
    <InputDistributor
      type={type}  
      label={label || "Product Distributor"}
      required={(disabled && readOnly) ? false : true}
      distributor={productItems.sepdistributor}
      setDistributor={(val) => {
        if (!!val && (retrieveStatus !== 'enable')) setRetrieveStatus('enable');
        handleChange(val, 'sepdistributor');
      }}
      placeholder="Select One"
      warning={productError.sepdistributor}
      width={width}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
  if (key === 'sepproductexpiration') return (
    <DateInput
      label={label || "Expiration Date"}
      value={productItems.sepproductexpiration || ""}
      onChange={(val) => handleChange(val, 'sepproductexpiration')}
      warning={productError.sepproductexpiration}
      width={width}
      options={{ disabled }}
    />
  );
  if (key === 'expiration-compliance') return (
    <>
      {
        checkComplianceStatusExpiration(surgicalEventData, productItems) &&
        <>
          {
            options.isEnableCompliance &&
            <ComplianceWarning
              type="expiration"
              size={options.isLargeCompliance ? "lg" : "md"}
              className={classes.newCompliance}
            />
          }
          {
            options.isComplianceWarning &&
            <SnackbarModal
              open={openExpirationErrorModal}
              setOpen={setOpenExpirationErrorModal}
              type="error"
              title=""
              content={t('warnings.surgicalevent.expirationdate')}
              vertical="center"
              horizontal="center"
              width={350}
              height={150}
              transition="fade"
              className={classes.toastLarge}
              options={{
                isClose: false,
                delay: 0,
                validateULE: true,
              }}
              actions={[
                {
                  label: 'Acknowledge',
                  handle: () => {
                    handleChange('Acknowledged', 'acknowledge');
                  },
                },
                {
                  label: 'Decline',
                  handle: () => {
                    handleChange('Declined', 'acknowledge');
                    // open confirm Toast
                    setOpenToastConfirm(true);
                    setConfirmTitle('acknowledge');
                  },
                }
              ]}
            />
          }
        </>
      }
    </>
  );
  if (key === 'sepproductname') return (
    <CustomTextField
      label={label || "Product Name"}
      value={productItems.sepproductname || ""}
      onChange={(val) => handleChange(val, 'sepproductname')}
      warning={productError.sepproductname}
      width={width}
      options={{ disabled, readOnly }}
    />
  );
  if (key === 'sepproductdescription') return (
    <CustomTextField
      label={label || "Product Description"}
      value={productItems.sepproductdescription || ""}
      onChange={(val) => handleChange(val, 'sepproductdescription')}
      warning={productError.sepproductdescription}
      width={width}
      options={{ disabled, readOnly }}
    />
  );
  if (key === 'sepproductprice') return (
    <CustomTextField
      type="number"
      label={label || "Price"}
      required={(disabled && readOnly) ? false : true}
      value={productItems.sepproductprice ?? ""}
      onChange={(val) => {
        if (val >= 0) {
          if(productItems.sepproductprice !== productItems.sepproductesprice){
            setIsChangedPrice(true);
          }
          handleChange(val, 'sepproductprice')
        }
      }}
      warning={productError.sepproductprice}
      width={width}
      startAdornment={"$"}
      options={{ disabled, readOnly }}
    />
  );
  if (key === 'sepproducttype') return (
    <CustomSelect
      label={label || "Product Type"}
      required={(disabled && readOnly) ? false : true}
      value={productItems.sepproducttype || ""}
      onChange={(val) => handleChange(val, 'sepproducttype')}
      items={ProductTypes}
      placeholder="Select One"
      warning={productError.sepproducttype}
      width={width}
      options={{ disabled, readOnly }}
    />
  );
  if (key === 'sepproductwaste') return (
    <CustomSelect
      label={label || "Waste"}
      value={productItems.sepproductwaste || ""}
      onChange={(val) => handleChange(val, 'sepproductwaste')}
      items={{
        ...WasteTypes,
        NotApplicable: 'Not Applicable',
      }}
      placeholder="Select One"
      warning={productError.sepproductwaste}
      width={width}
      options={{ disabled }}
    />
  );
  if (key === 'sepownership') return (
    <CustomSelect
      label={label || "Ownership"}
      required={(disabled && readOnly) ? false : true}
      value={productItems.sepownership || ""}
      onChange={(val) => handleChange(val, 'sepownership')}
      items={Ownerships}
      placeholder="Select One"
      warning={productError.sepownership}
      width={width}
      options={{ disabled, readOnly }}
    />
  );
  if (key === 'sepproductside') return (
    <CustomSelect
      label={label || "Product Side"}
      value={productItems.sepproductside || ""}
      onChange={(val) => handleChange(val, 'sepproductside')}
      items={Sides}
      placeholder="Select One"
      warning={productError.sepproductside}
      width={width}
      options={{ disabled, readOnly }}
    />
  );
  if (key === 'side-compliance') return (
    <>
      {
        checkComplianceStatusSide(surgicalEventData, productItems) &&
        <>
          {
            options.isEnableCompliance &&
            <ComplianceWarning
              type="side"
              content={surgicalEventData.surgicalside}
              size={options.isLargeCompliance ? "lg" : "md"}
              className={classes.newCompliance}
            />
          }
          {
            options.isComplianceWarning &&
            <SnackbarModal
              open={openSideErrorModal}
              setOpen={setOpenSideErrorModal}
              type="error"
              title=""
              content={`${t('warnings.surgicalevent.laterality-does-not')} ${surgicalEventData.surgicalside}`}
              vertical="center"
              horizontal="center"
              width={345}
              height={150}
              transition="fade"
              className={classes.toastLarge}
              options={{
                isClose: false,
                delay: 0,
                validateULE: true,
              }}
              actions={[
                {
                  label: 'Acknowledge',
                  handle: () => {
                    handleChange('Acknowledged', 'acknowledgeProduct');
                  },
                },
                {
                  label: 'Decline',
                  handle: () => {
                    handleChange('Declined', 'acknowledgeProduct');
                    // open confirm Toast
                    setOpenToastConfirm(true);
                    setConfirmTitle('acknowledgeProduct');
                  },
                }
              ]}
            />
          }
        </>
      }
    </>
  );
  if (key === 'btns') return (
    <>
      {
        options.isEditable && options.isEnableAdd &&
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClearClick}
            className={classNames(classes.newCompleteBtn, classes.newInsideAddBtn, "secondary-color")}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddClick}
            className={classNames(classes.newCompleteBtn, classes.newInsideAddBtn)}
          >
            Add
          </Button>
        </>
      }
    </>
  );
  if (key === 'sepuom') return (
    <CustomSelect
      label={label || t('main.uom.lable')}
      value={productItems.sepuom || ""}
      onChange={(val) => handleChange(val, 'sepuom')}
      //items={UOM}
      items={productItems.packagestring ? getListOfUOM(productItems.packagestring) : UOM}
      placeholder={(disabled && readOnly) ? "" : "Select One"}
      warning={productError.sepuom}
      width={width}
      options={{ disabled, readOnly }}
    />
  );
  if (type === 'number') return (
    <CustomTextField
      type="number"
      label={label}
      required={required}
      value={productItems[key] ?? ""}
      onChange={(val) => {
        if (val > 0) {
          handleChange(val, key);
        }
      }}
      warning={productError[key]}
      width={width}
      options={{ disabled, readOnly }}
    />
  );
  if (/number{[a-zA-Z]+}|number{[\d]+}$/.test(type)) {
    let maxVal, isLimit = true;
    if (/number{[\d]+}$/.test(type)) {
      maxVal = parseInt(/number{([\d]+)}$/.exec(type)[1]);
      // set Maximum Value per Key in productItems
      productItems[`${key}-max`] = maxVal;
    }
    else {
      const maxKey = /number{([a-zA-Z]+)}$/.exec(type)[1];
      maxVal = isNaN(productItems[maxKey]) ? 1 : parseInt(productItems[maxKey]);
      isLimit = false;
    }
    return (
      <>
        <CustomTextField
          type="number"
          label={label}
          required={required}
          value={productItems[key] ?? ""}
          onChange={(val) => {
            if ((isLimit && val >= 0 && val <= maxVal) || (!isLimit && val > 0)) {
              handleChange(val, key);
            }
          }}
          warning={productError[key]}
          width={width}
          options={{ disabled }}
          endAdornment={<Box style={{
            whiteSpace: 'nowrap',
            padding: '0px 5px 0px',
          }}>/ {maxVal}</Box>}
        />
        {
          !!infoBadge && 
          <CustomTooltip
            title={(
              <Box>
                <Typography className={classes.information}>{infoBadge}</Typography>
              </Box>
            )}
            arrow
            open={true}
            classes={{ tooltip: classes.productTooltip }}>
           <Badge className={ `${ (key === 'iquantityunit') ? classNames(classes.badgeInfo1, classes.badgeInfo) : classes.badgeInfo}`} badgeContent='i' color="primary" overlap="circular" showZero={false} >
            </Badge>
          </CustomTooltip>
        }
      </>
    );
  }
  if (type === 'select') return (
    <CustomSelect
      label={label}
      required={required}
      value={productItems[key] || ""}
      onChange={(val) => handleChange(val, key)}
      items={items}
      placeholder="Select One"
      warning={productError[key]}
      width={width}
      options={{ disabled, labelLeft, labelWidth }}
    />
  );
  if (type === 'text') return (
    <CustomTextField
      label={label}
      required={required}
      value={productItems[key] || ""}
      placeholder={placeholder ?? undefined}
      onChange={(val) => handleChange(val, key)}
      warning={productError[key]}
      width={width}
      options={{ disabled, readOnly, labelLeft, labelWidth }}
    />
  );
  if (type === 'date') return (
    <DateInput
      label={label}
      required={required}
      value={productItems[key] || ""}
      onChange={(val) => handleChange(val, key)}
      warning={productError[key]}
      width={width}
      options={{ disabled }}
    />
  );
  if (type === 'checkbox') return (
    <FormControlLabel
      className={classes.newCheckbox}
      control={
        <Checkbox
          color="primary"
          checked={!!productItems[key]}
          onChange={(e) => handleChange(e.target.checked, key)}
        />
      }
      label={label}
      // labelPlacement="end"
    />
  );
  return null;
};

/**
 * quantity by using unit
 * It's using getQuantityOnOrder of util.
 * @param {Object} productItems 
 * @param {String} key 
 * @param {any} value 
 * @returns {Object}
 */
export const checkQuantityUnit = (productItems, key, value) => {
  // console.log('[checkQuantityUnit] productItems', productItems, 'key', key, 'value', value);
  const units = productItems?.units || [];
  const result = {[key]: value};
  if (units && units.length) {
    // calculate iquantity, recqoo by using units
    const sepuom = key === 'sepuom' ? value : productItems?.sepuom; //?? "Each";
    const unit = getQuantityOnOrder({iproduct: {...productItems, sepuom}}, {isobject: true});
    let quantiyPerCaseBoxEach = unit ? unit?.quantity : 1;
    // console.log('[checkQuantityUnit] quantiyPerCaseBoxEach', quantiyPerCaseBoxEach);
    if (key === "iquantityunit" || key === "iquantityremain") { //set caclulated recqoo based on unit and iquantity
      let iquantityByUnit = 0, iquantityByRemain = 0;
      if (key === "iquantityunit") {
        iquantityByUnit = value * quantiyPerCaseBoxEach;
        iquantityByRemain = productItems?.iquantityremain ?? 0;
      }
      else if (key === "iquantityremain") {
        iquantityByUnit = (productItems?.iquantityunit ?? 0) * quantiyPerCaseBoxEach;
        iquantityByRemain = value;
        // checkout iquantityremain-max
        if ("iquantityremain-max" in productItems && iquantityByRemain >= productItems['iquantityremain-max']) {
          iquantityByRemain = productItems?.iquantityremain;
          result['iquantityremain'] = productItems?.iquantityremain;
        }
      }
      result['iquantity'] = parseInt(iquantityByUnit ?? 0) + parseInt(iquantityByRemain ?? 0);
      // checkout iquantity-max
      if ("iquantity-max" in productItems && result['iquantity'] > productItems['iquantity-max']) {
        result['iquantity'] = productItems?.iquantity;
        if (key === "iquantityunit") {
          result['iquantityunit'] = productItems?.iquantityunit;
        }
        else if (key === "iquantityremain") {
          result['iquantityremain'] = productItems?.iquantityremain;
        }
      }
    }
    else if (key === "iquantity") { //set caclulated iquantity based on unit and iquantity
      result['iquantityunit'] = Math.floor(value / quantiyPerCaseBoxEach);
    }
    else if (key === 'sepuom') { //set product price based on UOM pricing for Create PO page only
      // console.log('[checkQuantityUnit] unit', unit);
      result['sepproductprice'] = unit?.price;
      result['iquantity'] = quantiyPerCaseBoxEach;
      result['iquantityunit'] = result['podquantity'] = 1;
    }
    else if (key === "podquantity") { //set caclulated iquantity as similar to iquantityunit
      result['iquantity'] = value * quantiyPerCaseBoxEach;
    }
  } else if (key === "iquantity") { //set caclulated recqoo based on unit and iquantity
    result['iquantityunit'] = value;
  } else if (key === "iquantityunit") { //set caclulated iquantity based on unit and iquantity
    result['iquantity'] = value;
  }
  return result;
};

/**
 * handler to click barcode
 * @param {String} barcode 
 * @param {Boolean} onlyBarcode 
 * @param {Object} options 
 * @param {Object} productItems 
 * @param {Function} setProductItems 
 * @param {Function} setRetrieveStatus 
 * @param {String} retrieveStatus 
 * @param {String} hospital 
 * @param {String} token 
 * @param {String} role 
 * @param {String} party 
 * @param {Array} vendors 
 * @param {Object} ledger 
 * @param {String} roleCid 
 * @param {Function} setOpenToastNonWarning 
 * @param {Array} designPattern 
 * @returns {Boolean}
 */
export const handleBarcode = async ({
  barcode, onlyBarcode = false,
  options = {}, productItems = {}, setProductItems = () => { }, setRetrieveStatus = () => { }, retrieveStatus, hospital,
  token, role, party, vendors, ledger, roleCid, setOpenToastNonWarning = () => { }, designPattern = defaultPattern,
}) => {
  //console.log("[EditProduct] handleBarcode", barcode, setOpenToastNonWarning, setProductItems);
  if (!options.isEditable) return false;
  if (!barcode) return false;
  if (options.disableParams.indexOf('barcode') !== -1) return false;

  // get UDI from Barcode
  const { udi, expiration, lotcode } = getDataFromCode(barcode);

  if (udi !== '' || expiration !== '' || lotcode !== '') {
    const oldProductItems = { ...productItems };
    // old udi
    // const oldUdi = oldProductItems.sepudi;

    // set params to productItems
    oldProductItems.barcode = barcode;
    if (options.disableParams.indexOf('sepudi') === -1) oldProductItems.sepudi = udi;
    if (options.disableParams.indexOf('sepproductexpiration') === -1) oldProductItems.sepproductexpiration = expiration;
    if (options.disableParams.indexOf('seplotcode') === -1) oldProductItems.seplotcode = lotcode;

    // console.log("[EditProduct] handleBarcode", oldProductItems);
    await setProductItems(oldProductItems);
    await setRetrieveStatus('enable');

    // Now, enable to retrieve Automatically from Barcode
    if (options.isEnableAutoScan) {
      // retrieve on change of barcode automatically
      // && udi !== oldUdi - Removig to enable for every scan without changing the input text
      if (options.disableParams.indexOf('sepudi') === -1) {
        handleRetrieve({
          udi, isForce: true, product: oldProductItems,
          options, retrieveStatus, setRetrieveStatus, hospital, productItems, setProductItems,
          token, role, party, vendors, ledger, roleCid, setOpenToastNonWarning, designPattern,
        });
      }
    }

    return true;
  }
  else if (onlyBarcode) {
    await setProductItems({
      ...productItems,
      barcode,
    });
  }

  return false;
};

/**
 * Barcode Scan Success handler
 * when there is no selected TextField and scan barcode
 * set barcode of productItems
 * @param {String} data 
 * @param {Object} options 
 * @param {Object} productItems 
 * @param {Function} setProductItems 
 * @param {String} retrieveStatus 
 * @param {Function} setRetrieveStatus 
 * @param {String} hospital 
 * @param {String} token 
 * @param {String} role 
 * @param {String} party 
 * @param {Array} vendors 
 * @param {Object} ledger 
 * @param {String} roleCid 
 * @param {Array} designPattern 
 */
export const handleScanSuccess = async ({
  data,
  options = {}, productItems = {}, setProductItems = () => { }, retrieveStatus, setRetrieveStatus = () => { },
  hospital, token, role, party, vendors, ledger, roleCid, designPattern = defaultPattern,
}) => {
  console.log("[EditProduct] Barcode", data);
  if (options.disableParams.indexOf('barcode') === -1) {
    let result = true, newdata = data;
    // check old barcode of productItems
    if (!!data && data !== productItems.barcode) {
      // console.log("[EditProduct] productItems.barcode", productItems.barcode);
      result = await handleBarcode({
        barcode: newdata, options, productItems, setProductItems, setRetrieveStatus,
        retrieveStatus, hospital, token, role, party, vendors, ledger, roleCid, designPattern,
      });
      console.log("[EditProduct] result", result);
      if (!result) {
        if (!!productItems.barcode) {
          const temp = `${productItems.barcode}${data}`;
          if (temp.length < 45) { // limit of barcode
            newdata = temp;
            result = await handleBarcode({
              barcode: newdata, options, productItems, setProductItems, setRetrieveStatus,
              retrieveStatus, hospital, token, role, party, vendors, ledger, roleCid, designPattern,
            });
            console.log("[EditProduct] result", result);
          }
        }
        if (!result) {
          console.log("[EditProduct] set barcode only");
          await handleBarcode({
            barcode: newdata, onlyBarcode: true, options, productItems, setProductItems, setRetrieveStatus,
            retrieveStatus, hospital, token, role, party, vendors, ledger, roleCid, designPattern,
          });
        }
      }
    }
  }
};

/**
 * handle to retrieve GUDID or ES
 * @param {String} udi 
 * @param {String} ref 
 * @param {Boolean} isForce 
 * @param {Object} product 
 * @param {Object} options 
 * @param {String} retrieveStatus 
 * @param {Function} setRetrieveStatus 
 * @param {String} hospital 
 * @param {Object} productItems 
 * @param {Function} setProductItems 
 * @param {String} token 
 * @param {String} role 
 * @param {String} party 
 * @param {Array} vendors 
 * @param {Object} ledger 
 * @param {String} roleCid 
 * @param {Function} setOpenToastNonWarning 
 * @param {Array} designPattern 
 * @returns 
 */
export const handleRetrieve = ({
  udi, ref, isForce = false, product = {},
  options = {}, retrieveStatus, setRetrieveStatus = () => { }, hospital, productItems = {}, setProductItems = () => { },
  token, role, party, vendors = [], ledger, roleCid, setOpenToastNonWarning = () => { }, designPattern = defaultPattern, handleChange = () => {}
}) => {

  // check if it's enable to retrieve
  console.log("[EditProduct] handleRetrieve", udi, ref, retrieveStatus, isForce, options);
  if (!options.isEditable) return;
  if ((!!udi || !!ref) && ((isForce && retrieveStatus !== 'loading') || retrieveStatus === 'enable')) {
    setRetrieveStatus('loading');
    let searchParams = {}, di;
    if (!!udi) {
      const checkedUdi = checkUdi(udi);
      udi = checkedUdi.udi;
      di = checkedUdi.di;
      // searchParams = { result: udi };
      // searchParams = { query: { Udi: udi, Hospital: hospital } }; 
      //New Case	
      searchParams = { query: { sepudi: udi, hospital: hospital, sepdistributor : productItems.sepdistributor } };
    }
    else {
      // searchParams = { result: ref, field: 'ReferenceNumber' };
      // searchParams = { query: { ReferenceNumber: ref, Hospital: hospital } };
      //New case	
      searchParams = { query: { sepreferencenumber: ref, hospital: hospital, sepdistributor : productItems.sepdistributor } };
    }
    // console.log("[EditProduct] isUseGUDID: ", isUseGUDID, searchParams, "product", product);

    var tempData = {
      ...productItems,
      ...product,
    };
    let isDoneGUDID = false, isDoneES = false;
    let updateContractPrice = {};

    const callRetrieveES = (searchParams, opt) => {
      // console.log("[EditProduct] callRetrieveES", searchParams, opt);
      // finish retrieve function
      const finishRetrieve = () => {
        /**
         * update all fields with the retrieved data
         * check out all disabled fields to keep old one & skip retrieve (using designPattern)
         */
        const disabledFields = designPattern.reduce((fields, { key, disabled, key1, disabled1, disabled2, key2 }) => {
          if (key === 'sepreferencenumber') return fields;
          if (disabled) fields.push(key);
          if (disabled1) fields.push(key1);
          if (disabled2) fields.push(key2);
          return fields;
        }, (options.defaultDisabledFields ?? []));

        const finalTempData = Object.keys(tempData).reduce((data, key) => {
          if (disabledFields.indexOf(key) === -1) data[key] = tempData[key];
          return data;
        }, {});

        /* if(finalTempData?.packagestring) {
          const baseUoM = finalTempData.sepuom || "EA";	
          const basePrice = finalTempData.sepproductprice;
          const sepBaseQty = finalTempData.sepqtyuom;
          	
          const unitsData = finalTempData?.packagestring?.filter((packageuom)=> { return packageuom === baseUoM});	
          console.log('unitsData', unitsData);
          if(disabledFields.indexOf('sepproductprice') === -1) {
            const tempUnitPrice = !isInputZero(basePrice) ? basePrice  : unitsData[0]?.price;
            finalTempData.sepquantity = Number(unitsData[0]?.multiplier) * Number(sepBaseQty);
            if (!isNaN(tempUnitPrice)) finalTempData.sepproductprice = tempUnitPrice;
          }

          if(disabledFields.indexOf('sepuom') === -1) {
            finalTempData.sepuom = finalTempData?.sepuom ? finalTempData?.sepuom : (unitsData[0]?.packageuom ?? "EA");
            //to update the sepom
            handleChange(finalTempData?.sepuom,'sepuom');
          }

          if(disabledFields.indexOf('iqoh') === -1) {
            const sepuom =  finalTempData.sepuom;
            const tempIQuantity = getQuantityOnOrder({iproduct: {...finalTempData, sepuom}}, {isobject: true});
            console.log('iquntityunit []', tempIQuantity)
            finalTempData.iqoh = tempIQuantity.quantity;
          }

        } */
        // Set iquantity with sepqtyuom
        if(disabledFields.indexOf('iquantity') === -1 && finalTempData?.sepqtyuom) {
          finalTempData.iquantity = finalTempData.sepqtyuom;
        }
        /**
         * TYD-2032
         * Populate the sepudi after Ref retrieve
         * If sepudi is empty/null/undefined then assign ref retrieve value else keep the existing one.
         */
        if(!isDoneGUDID && disabledFields.indexOf('sepudi') === -1 &&
          (typeof productItems.sepudi === 'undefined' ||
            productItems.sepudi === null)
          && !!tempData?.sepesudi) {
          finalTempData.sepudi = tempData?.sepesudi;
        }

        setProductItems({
          ...finalTempData
        });


        console.log("[EditProduct] finishRetrieve", disabledFields, finalTempData, updateContractPrice);
        setRetrieveStatus('finished');

        /**
         * Update Contract Price.
         * Only Hospital is available.
         */
        if (options.isContractUpdate
          && role === 'Hospital'
          && (isDoneGUDID || isDoneES)
          && Object.keys(updateContractPrice).length) {
          // get product to update
          let tempGetProduct = {
            sepreferencenumber: tempData.sepreferencenumber,
            sepdistributor: tempData.sepdistributor,
            sepproducttype: tempData.sepproducttype,
            ...updateContractPrice,
          }
          /**
           * To add name, description, udi from GUDI scan only on SE/PO create/Edit
           */
          if(options.isCreateEditPoSE) {
            tempGetProduct.sepproductname = tempData.sepproductname;
            tempGetProduct.sepproductdescription = tempData.sepproductdescription;
            tempGetProduct.sepudi =  tempData.sepudi;
          }
          if (IS_CONTRACT_NOT_ES) {
            // Update ContractPrice contract
            updateContractPrice = getProduct(tempGetProduct);
            console.log("[EditProduct] Update Contract Price", updateContractPrice, isDoneGUDID, isDoneES, 'tempGetProduct', tempGetProduct);
            updateProductsContractPrice({
              ledger, products: [updateContractPrice], user: party, roleCid,
            });
          }
          else {
            // Update Elastic Search
            console.log("[EditProduct] Update Contract Price", tempGetProduct);
            updateESContractPrice({
              query: {
                sepreferencenumber: tempGetProduct.sepreferencenumber, 
                hospital: hospital, 
                sepdistributor : tempGetProduct.sepdistributor, 
              },
              source: {
                sepproductname: tempGetProduct.sepproductname,
                sepproductdescription: tempGetProduct.sepproductdescription,
              },
            });
          }
        }

      setRetrieveStatus('enable');
      };
      // next function
      const next = (data, isES = true) => {
        if (data) {
          tempData = {
            ...tempData,
            ...data,
          };
          if (isES) {
            // flag to retrieve ES is TRUE
            isDoneES = true;
            if(data?.error) {
              setOpenToastNonWarning(data?.error); 
            } else if(isEmptyObject(data))  {
              setOpenToastNonWarning("No Contract Price information found."); 
            }
          }
        }
        /**
         * To get Ownership, get the oldest one,
         * check out fetching Account Inventory
         */
        if (options.isFetchInventory) {
          damlFetchInventory({
            token: token,
            hospital,
            referencenumber: tempData.sepreferencenumber,
            lotcode: tempData.seplotcode,
            ownership: tempData.sepownership,
            sepproductexpiration: tempData.sepproductexpiration,
            distributor: tempData.sepdistributor,
          }).then(res => {
            let invproduct = {}, expOldProducts = [], newownership;
            console.log("[AddEditProduct] retrieveES: damlFetchInventory", res);
            if (res) {
              invproduct = res[0];
              expOldProducts = res[1];
            }
            if (invproduct && 'sepownership' in invproduct) {
              newownership = invproduct.sepownership;
            }
            else {
              if (!tempData.seplotcode) {
                newownership = "Loaned";
              }
              else {
                newownership = "Unknown";
              }
            }
            if (newownership) {
              tempData = {
                ...tempData,
                sepownership: newownership,
              };
              /**
               * Old case: update lot code if account inventory data has non-empty lotcode
               * New case: TYD-2444 No lot code should be populated except Manual entry and UDI scan
               * In order to implement new case, we should always set isFetchInventory as False
               */
              if (invproduct?.seplotcode && !tempData.seplotcode) tempData.seplotcode = invproduct?.seplotcode;
              finishRetrieve();
            }
            if (typeof options.handleWarningExp === 'function' && expOldProducts && expOldProducts.length > 0) {
              options.handleWarningExp(expOldProducts);
            }
          });
        }
        else {
          finishRetrieve();
        }
      };
      // fetch from Contract Price in DAML
      if (IS_CONTRACT_NOT_ES) {
        if (searchParams.query) {
          //console.log('damlFetchContractPrice', searchParams.query, 'isDoneGUDID', isDoneGUDID)
          damlFetchContractPrice({
            token,
            hospital,
            referencenumber: searchParams.query.ReferenceNumber,
            udi: productItems.sepudi,
            distributor: productItems.sepdistributor,
          })
            .then(({ result: res, error, data, index }) => {
              if (res === null || error !== null) {
                // The case of non-exist
                // retrieveES(searchParams, next, null, opt); // Old case - Retrieve from ElasticSearch
                // New case - Open toast notification - with error
                setOpenToastNonWarning(error ?? "No Contract Price information found.");
                // Set res with 0 price
                res = {
                  sepproductprice: 0,
                };
              }
              // Overwrite the product name, desc from GUDID
              if (isDoneGUDID) {
                // tempDate - from GUDID, res - from ContractPrice
                if ((tempData.sepproductname && tempData.sepproductname !== res.sepproductname)
                  || (tempData.sepproductdescription && tempData.sepproductdescription !== res.sepproductdescription)) {
                  delete res.sepproductname;
                  delete res.sepproductdescription;
                  updateContractPrice = { ...res };
                  updateContractPrice.sepproductname = tempData.sepproductname;
                  updateContractPrice.sepproductdescription = tempData.sepproductdescription;

                  // IF it's possible, it may be necessary to check out & update gudid_desc of ContractPriceData
                }
              }
              // Overwrite sepesudi of the product
              if (error === null && res.sepesudi && index >= 0
                && res.sepesudi !== data[index].sepesudi && res.sepesudi !== data[index].sepudi) {
                if (Object.keys(updateContractPrice).length === 0) updateContractPrice = { ...res };
                updateContractPrice.sepesudi = res.sepesudi;
              }
              next(res, false);
            });
        }
      }
      else {
        // next with updating ContractPrice
        const newNext = (res, isES) => {
          // Overwrite the product name, desc from GUDID
          if (isDoneGUDID
            && res
            && !res?.error
            && !isEmptyObject(res)) {
            // tempDate - from GUDID, res - from ContractPrice
            if ((tempData.sepproductname && tempData.sepproductname !== res.sepproductname)
              || (tempData.sepproductdescription && tempData.sepproductdescription !== res.sepproductdescription)) {
              delete res.sepproductname;
              delete res.sepproductdescription;
              updateContractPrice = { ...res };
              updateContractPrice.sepproductname = tempData.sepproductname;
              updateContractPrice.sepproductdescription = tempData.sepproductdescription;
            }
          }
          next(res, isES);
        };
        // Retrieve from ElasticSearch
        // console.log("[EditProduct] callRetrieveES", searchParams, opt);
        retrieveES(searchParams, newNext, null, opt);
      }
    };

    const callRetrieveGUDID = (searchParams, opt, nextSearchParams = null) => {
      // console.log("[EditProduct] callRetrieveGUDID: ", searchParams, nextSearchParams);
      // Retrieve from accessgudid
      retrieveGUDID(searchParams, (data) => {
        console.log("[EditProduct] retrieveGUDID: ", data);
        if (data && !data?.error) {
          // if response is okay, then
          tempData = {
            ...tempData,
            ...data,
          };
          // flag to retrieve GUDID is TRUE
          isDoneGUDID = true;
          // update searchParams from GUDID response
          if ('sepreferencenumber' in data) {
            searchParams = { query: { sepreferencenumber: data.sepreferencenumber, hospital: hospital, sepdistributor : productItems.sepdistributor  } };
          }
        } else {
          // if response is error or none, then
          if (nextSearchParams !== null) {
            // console.log("[EditProduct] retrieveGUDID: ", nextSearchParams);
            callRetrieveGUDID(nextSearchParams, opt);
          }
          else if(data?.error) { 
            setOpenToastNonWarning(data.error);
          }
        }

        if (data || nextSearchParams === null) {
          callRetrieveES(searchParams, opt);
        }
      }, opt);
    };
    if (isUseGUDID && !!udi) {
      const opt = {
        // isForce: true,
        user: { role, party },
        vendors,
      };
      if (options.disableParams) opt.disableParams = options.disableParams;

      callRetrieveGUDID({ udi: udi }, opt, { di: di });
    }
    else {
      const opt = {
        isExpLot: true,
        user: { role, party },
        vendors,
      };
      if (options.disableParams) opt.disableParams = options.disableParams;

      callRetrieveES(searchParams, opt);
    }
  }
  
};

/**
 * default product
 */
export const defaultProduct = {
  'sepproductwaste': 'NotApplicable',
  'sepproductside': 'NotApplicable',
};


/**
 * Edit Product
 * @param {Object} options 
 * @param {Array} designPattern  
 * @returns 
 */
export default function EditProduct({
  options = {}, designPattern = defaultPatternMain,  // designPattern for Add Prdouct in SE
}) {

  options = {
    isEditable: true,             // Edit, Update every field
    // isEnableScan: true,
    isEnableAutoScan: true,       // Auto scan(retrieve), old value is false
    // isEnableRetrieve: true,
    isEnableCompliance: true,     // Compliance Warning Alert
    isComplianceWarning: true,    // Compliance Warning notification Dialog
    // isInventory: false,
    // isSurgicalEvent: false,
    // isPurchaseOrder: false,
    // hiddenParams: [],          // e.g. 'sepproductprice'
    // enableParams: [],          // e.g. 'sepproductesprice'
    disableParams: [],            // e.g. 'sepreferencenumber'
    // extraParams: [],           // e.g. {label: '', value: '', setValue: () => {}, placeholder: '', type: 'number', key: ''}
    isLargeCompliance: false,     // Compliance warning size is Large or Medium
    isSecondary: false,           // If add a product, then false, If edit a product, then true
    isEnableAdd: false,           // Able to show Add, Clear buttons
    isEnableWarnings: true,       // Show warnings once required event
    isWarningExp: false,          // If it's true, then show warnings if it's not oldest
    handleWarningExp: null,       // handler to show warning when sepproductexpiration is not oldest
    isFetchInventory: false,      // If true, then it fetches Account Inventory with Received and change fields as same
    isContractUpdate: false,      // If true, then it updates ContractPrice
    className: null,              // Optional extra class name of table
    defaultDisabledFields: [
      'seplocation',              // Do not change seplocation and unclassified on scanning
    ],                            // default disabled fields, it's used in callRetrieveES/finishRetrieve
    setIsChangedPrice: () => {},
    isCreateEditPoSE: false,
    extraReqs : {},
    ...options,
  };
  // console.log("[EditProduct] options", options);

  // filter via undefined
  designPattern = designPattern.filter(p => !!p);

  const classes = useStyles();
  const { t } = useTranslation();
  const { product: productItems, setProduct: setProductItems, errors: productError,
    setErrors: setProductError, clearProduct } = useProduct(options.isSecondary);
  // console.log("[EditProduct] product", productItems);
  const { surgicalevent: surgicalEventData } = useSurgicalEvent();
  const { hospital } = useHospitalUser();
  const { event, status, onEventStart, onEventEnd, onEventVerify, onEventReject } = useEvent();

  const ledger = useLedger();
  const { role, party, roleCid, relVendors: vendors } = useDamlState();
  const { token } = useUserState();

  const [barcodeStatus, setBarcodeStatus] = useState(false);
  const [retrieveStatus, setRetrieveStatus] = useState('enable');
  const [openExpirationErrorModal, setOpenExpirationErrorModal] = useState(true);
  const [openSideErrorModal, setOpenSideErrorModal] = useState(true);
  const [openToastConfirm, setOpenToastConfirm] = useState(false);
  const [openToastOldWarning, setOpenToastOldWarning] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState(null);


  if (options.isWarningExp) {
    options.handleWarningExp = (products) => {
      console.log("[EditProduct] handleWarningExp", products);
      setOpenToastOldWarning(true);
    };
  }


  const handleChange = useCallback((value, key) => {
    // console.log('[EditProduct] handleChange: value', value, "key", key);
    if (!options.isEditable) return;
    if (typeof setProductItems === 'function') {
      // if(key === 'sepuom') productItems.sepuom = value;
      const temp = checkQuantityUnit(productItems, key, value);
      setProductItems(temp);
    }
  }, [options.isEditable, setProductItems, productItems]);

  // Barcode Scan Error handler
  const handleScanError = (err) => {
    console.log("[EditProduct] Barcode Error", err);
  };

  // handle to click Clear button
  const handleClearClick = () => {
    // default Waste, default Side
    clearProduct(defaultProduct);
  };

  // handle to click Add button
  const handleAddClick = () => {
    onEventStart(PRODUCT_ADD_EVENT);
  };

  // handle to click "No" button in Confirm Modal
  const handleReOpenError = () => {
    if (confirmTitle === 'acknowledge') {
      setOpenExpirationErrorModal(true);
    }
    else if (confirmTitle === 'acknowledgeProduct') {
      setOpenSideErrorModal(true);
    }
  };

  // set toast message and open
  const setOpenToastNonWarning = (msg) => {
    //console.log('setOpenToastNonWarning []', msg)
    if (!msg) {
      setToastMessage("");
      setOpenToast(false);
    }
    else {
      setToastMessage(msg);
      setOpenToast(true);
    }
  };

  useEffect(() => {
    setOpenExpirationErrorModal(true);
  }, [productItems.sepproductexpiration]);

  useEffect(() => {
    setOpenSideErrorModal(true);
  }, [productItems.sepproductside]);

  useEffect(() => {
    // get UDI from Barcode
    const { udi, expiration, lotcode } = getDataFromCode(productItems.barcode);
    let status = false;
    if (udi !== '' || expiration !== '' || lotcode !== '') {
      if (productItems.sepudi === udi
        || productItems.sepproductexpiration === expiration
        || productItems.seplotcode === lotcode) status = true;
    }
    setBarcodeStatus(status);
  }, [productItems.barcode, productItems.sepudi, productItems.sepproductexpiration, productItems.seplotcode]);

  useEffect(() => {
    if (((event === PRODUCT_ADD_EVENT && !options.isSecondary) || (event === PRODUCT_UPDATE_EVENT && options.isSecondary))
      && status === EVENT_REQUIRED) {
      if (isEmptyProduct({ ...productItems, sepproductside: "", sepproductwaste: "" })) {
        onEventEnd();
        return;
      }
      if(surgicalEventData?.surgicalprocedure) {
        productItems.cptcode = [parseCptCode(surgicalEventData.surgicalprocedure)];
      }
      const newErrors = requiredProduct(productItems, {introReqs: ['sepproductprice', 'sepownership'], extraReqs: options.extraReqs });
      if ((Object.keys(newErrors).length > 0)) {
        if (options.isEnableWarnings) setProductError({ ...newErrors });
        onEventReject();
      }
      else {
        onEventVerify();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, status, productItems, onEventEnd, onEventReject, onEventVerify, setProductError,
    options.isEnableWarnings, options.isSecondary]);


  return (
    <>
      <Table className={classNames(classes.newSEForm, classes.newProductForm, options.className, classes.tableSize)}>
        <TableBody>
          {
            designPattern.map(({
              key, width, label, type, items, disabled = false, required = false, colSpan, style, onChange, readOnly = false, labelLeft, labelWidth, infoBadge, placeholder = "", startAdornment = null, endAdornment = null, WrapperComp = null,
              key1, width1, label1, type1, items1, disabled1 = false, required1 = false, colSpan1, style1, onChange1, readOnly1 = false, labelLeft1, labelWidth1, infoBadge1, placeholder1 = "", startAdornment1 = null, endAdornment1 = null, WrapperComp1 = null,
              key2, width2, label2, type2, items2, disabled2 = false, required2 = false, colSpan2, style2, onChange2, readOnly2 = false, labelLeft2, labelWidth2, infoBadge2, placeholder2 = "", startAdornment2 = null, endAdornment2 = null, WrapperComp2 = null,
            }, index) => {
              let cell, cell1, cell2;
              // the first cell
              if (label && !key) cell = (<TableCell colSpan={3} className="new-subtitle" style={style}>{label}</TableCell>);
              else {
                const editcomponent = getEditProductComp({
                  key, width, type, label, items, disabled, readOnly, labelLeft, labelWidth, infoBadge,
                  handleChange: onChange ?? handleChange, 
                  productItems, productError, vendors, surgicalEventData, options, classes,
                  handleScanError, barcodeStatus, hospital, token, role, party, ledger, roleCid,
                  retrieveStatus, setProductItems, setRetrieveStatus,
                  openExpirationErrorModal, setOpenExpirationErrorModal,
                  openSideErrorModal, setOpenSideErrorModal, setOpenToastConfirm, setConfirmTitle,
                  setIsChangedPrice: options.setIsChangedPrice,placeholder,
                  handleClearClick, handleAddClick, required, setOpenToastNonWarning, designPattern , t
                });
                if (WrapperComp) cell = (
                  <TableCell colSpan={colSpan} style={style}>
                    <WrapperComp>
                      {startAdornment}
                      {editcomponent}
                      {endAdornment}
                    </WrapperComp>
                  </TableCell>
                );
                else cell = (
                  <TableCell colSpan={colSpan} style={style}>
                    {startAdornment}
                    {editcomponent}
                    {endAdornment}
                  </TableCell>
                );
              }
              // the second cell
              if (!label1 && !key1) cell1 = null;
              else if (label1 && !key1) cell1 = (<TableCell colSpan={3} className="new-subtitle" style={style1}>{label1}</TableCell>);
              else {
                const editcomponent = getEditProductComp({
                  key: key1, width: width1, type: type1, label: label1, items: items1, labelWidth: labelWidth1,
                  labelLeft: labelLeft1, infoBadge: infoBadge1, 
                  disabled: disabled1, readOnly: readOnly1, handleChange: onChange1 ?? handleChange,
                  productItems, productError, vendors, surgicalEventData, options, classes,
                  handleScanError, barcodeStatus, hospital, token, role, party, ledger, roleCid,
                  retrieveStatus, setProductItems, setRetrieveStatus,
                  openExpirationErrorModal, setOpenExpirationErrorModal,
                  openSideErrorModal, setOpenSideErrorModal, setOpenToastConfirm, setConfirmTitle,
                  setIsChangedPrice: options.setIsChangedPrice, placeholder :placeholder1 ,
                  handleClearClick, handleAddClick, required: required1, setOpenToastNonWarning, designPattern, t
                });
                if (WrapperComp1) cell1 = (
                  <TableCell colSpan={colSpan1} style={style1}>
                    <WrapperComp1>
                      {startAdornment1}
                      {editcomponent}
                      {endAdornment1}
                    </WrapperComp1>
                  </TableCell>
                );
                else cell1 = (
                  <TableCell colSpan={colSpan1} style={style1}>
                    {startAdornment1}
                    {editcomponent}
                    {endAdornment1}
                  </TableCell>
                );
              }
              // the third cell
              if (!label2 && !key2) cell2 = null;
              else if (label2 && !key2) cell2 = (<TableCell colSpan={3} className="new-subtitle" style={style2}>{label2}</TableCell>);
              else {
                const editcomponent = getEditProductComp({
                  key: key2, width: width2, type: type2, label: label2, items: items2,
                  disabled: disabled2, readOnly: readOnly2, handleChange: onChange2 ?? handleChange,
                  labelWidth: labelWidth2, labelLeft: labelLeft2, infoBadge: infoBadge2, 
                  productItems, productError, vendors, surgicalEventData, options, classes,
                  handleScanError, barcodeStatus, hospital, token, role, party, ledger, roleCid,
                  retrieveStatus, setProductItems, setRetrieveStatus,
                  openExpirationErrorModal, setOpenExpirationErrorModal,
                  openSideErrorModal, setOpenSideErrorModal, setOpenToastConfirm, setConfirmTitle,
                  setIsChangedPrice: options.setIsChangedPrice, placeholder : placeholder2, t ,
                  handleClearClick, handleAddClick, required: required2, setOpenToastNonWarning, designPattern
                });
                if (WrapperComp2) cell2 = (
                  <TableCell colSpan={colSpan2} style={style2}>
                    <WrapperComp2>
                      {startAdornment2}
                      {editcomponent}
                      {endAdornment2}
                    </WrapperComp2>
                  </TableCell>
                );
                else cell2 = (
                  <TableCell colSpan={colSpan2} style={style2}>
                    {startAdornment2}
                    {editcomponent}
                    {endAdornment2}
                  </TableCell>
                );
              }
              return (
                <TableRow key={index}>
                  {cell}
                  {cell1}
                  {cell2}
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>

      <SnackbarModal
        open={openToastConfirm}
        setOpen={setOpenToastConfirm}
        type="warning"  
        content= {t('warnings.surgicalevent.removedproduct.message')}
        note={t('warnings.surgicalevent.removedproduct.note')}
        vertical="center"
        horizontal="center"
        width={470}
        height={190}
        transition="fade"
        options={{
          isClose: false,
          delay: 0,
          validateULE: true,
        }}
        actions={[
          {
            label: 'Acknowledge',
            handle: handleAddClick, // handle to click "Yes" button in Confirm Modal
          },
          {
            label: 'Decline',
            handle: handleReOpenError,
          }
        ]}
      />
      <SnackbarModal
        open={openToastOldWarning}
        setOpen={setOpenToastOldWarning}
        type="warning"
        title=""
        content={t('warnings.surgicalevent.editproductse')}
        vertical="center"
        horizontal="center"
        width={345}
        height={150}
        transition="fade"
        options={{
          isClose: false,
          delay: 0,
          validateULE: true,
           }}
        actions={[
          {
            label: 'Ok',
          },
        ]}
      />
      <SnackbarModal
        open={openToast}
        setOpen={setOpenToast}
        type="error"
        title=""
        content={toastMessage}
        className={classes.errorMessage}
      />
    </>
  );
}

/**
 * Input Distributor Component
 * @param {String} type select | any
 * @param {String} label 
 * @param {Boolean} disabled 
 * @param {Boolean} readOnly 
 * @param {Boolean} required 
 * @param {String} placeholder 
 * @param {String} distributor 
 * @param {Function} setDistributor 
 * @param {String} warning 
 * @param {Number} width 
 * @param {Object} className 
 * @param {Object} options 
 * @returns 
 */
export function InputDistributor ({
  type, label, disabled, readOnly, required, placeholder, distributor, setDistributor, warning, width, 
  className, options, 
}) {

  const classes = useStyles();
  const { relVendors: vendors } = useDamlState();
  
  return (
    type === 'select'
    ?
    <CustomSelect
      label={label}
      required={required}
      value={distributor || ""}
      onChange={(val) => {
        if (typeof setDistributor === 'function') setDistributor(val);
      }}
      items={vendors}
      placeholder={placeholder}
      warning={warning}
      width={width}
      options={{ disabled, readOnly, ...options }}
      className={className}
    />
    :
    <AdaptiveSearch
      label={label}
      required={required}
      contracts={vendors}
      params={distributor ? [distributor] : []}
      setParams={(vals) => {
        if (!!vals && typeof vals === 'object' && vals.length >= 0 && typeof setDistributor === 'function') {
          if (vals.length > 0) setDistributor(vals[vals.length-1]);
          else setDistributor(undefined);
        }
      }}
      classes={{ 
        container: className,
        wrapper: classes.adaptiveSearchWrapper, 
        label: classes.adaptiveSearchLabel, 
      }}
      isChangable={!disabled}
      placeholder={placeholder}
      warning={warning}
      isReadOnly={readOnly}
      width={width}
      defaultValue={distributor}
      options={options}
    />
  );
}
