import React, { useState, useRef } from "react";
import classNames from "classnames";
import { useReactToPrint } from 'react-to-print';
import { useParty, useStreamQueries } from "@daml/react";
import { Grid, Typography, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { RestockInventory, RestockInventoryHistory } from "../../services/daml-modules1";
import useEvent from "../../hooks/useEvent";
import ScanInventoryVendorContracts from "./inventoryscanvendorcontracts1";
import IncomingInventory from "./incoming-inventory";
import ConfirmInventory from "./confirm-inventory";
import InventoryScanPrint from "./inventoryscan-prints";
import exportIcon from "../../icons/new-export-icon.svg";
import printIcon from "../../icons/new-print-icon.svg";
import CustomPrints from "../surgicalevent/surgicalevent-prints";
import ExportPopover from "../surgicalevent/export-popover";
import { handleExportCSV, handleExportPDF } from "./inventoryscanhospital1";
import useStyles from "../surgicalevent/styles";
import { useTranslation } from 'react-i18next';


/**
 * Outgoing(Restock) Inventory Page for Vendor
 * @returns 
 */
export default function ScanInventoryVendor() {

  const classes = useStyles();
  const { t } = useTranslation();

  const vendor = useParty();

  const { contracts, loading } = useStreamQueries(RestockInventory, () => [{ vendor }]);
  const { contracts: contractsHistory } = useStreamQueries(RestockInventoryHistory, () => [{ vendor }]);

  const { onEventClear } = useEvent();
  
  // Component used for React-to-Print
  const componentRef = useRef();

  const [isHistory, setHistory] = useState(false);
  // Outgoing Inventory - Shipment Confirmation
  const [contract, setContract] = useState({});
  const [openModal, setOpenModal] = useState(false);
  // checked Inventory
  const [checkedInv, setCheckedInv] = useState([]);
  // Popover when clicking Export button
  const [anchorEl, setAnchorEl] = useState(null);
  // Confirm Inventory (Auto Match)
  const [openConfirmModal, setOpenConfirmModal] = useState(false);


  // Click "Auto Match" in Inventory list page
  const handleCreateInventory = () => {
    setOpenConfirmModal(true);
    onEventClear();
  };

  const handleEditInventory = (c) => {
    setContract(c);
    setOpenModal(true);
    onEventClear();
  };

  const handlePrintClick = () => {
    if (!checkedInv || checkedInv.length === 0) return;
    handlePrint();
  };

  const handleOpenPopover = (event) => {
    if (!checkedInv || checkedInv.length === 0) return;
    setAnchorEl(event.currentTarget);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  return (
    <>

      <Grid>

        <Grid container justifyContent="space-between">
          <Grid item xs>
            <Typography variant="h4" className={classes.newPageTitle}>Outgoing Inventory</Typography>
          </Grid>
          <Grid item>
            <Button
              className={classNames(classes.newCreateButton, classes.newIconButton)}
              variant="contained"
              onClick={handlePrintClick}
              startIcon={<img  alt="" src={printIcon} />}
            />
            <Button
              className={classNames(classes.newCreateButton, classes.newIconButton)}
              variant="contained"
              onClick={handleOpenPopover}
              startIcon={<img  alt="" src={exportIcon} />}
            />
            <Button
              className={classes.newCreateButton}
              variant="contained"
              onClick={handleCreateInventory}
              startIcon={<AddIcon />}
            >
              Auto Match
            </Button>

            <ExportPopover
              anchor={anchorEl}
              setAnchor={setAnchorEl}
              handleExportCSV={() => handleExportCSV(checkedInv)}
              handleExportPDF={() => handleExportPDF(checkedInv)}
            />
          </Grid>
        </Grid>

        <ScanInventoryVendorContracts
          allContracts={contracts}
          historyContracts={contractsHistory}
          isIncludeHistory={isHistory}
          setArchived={setHistory}
          setChecked={setCheckedInv}
          handleEditInventory={handleEditInventory}
          loading={loading}
        />

        <IncomingInventory
          open={openModal}
          setOpen={setOpenModal}
          contract={contract}
          contracts={contracts}
          title={t('inventory.restock.incoming.title')}
          options={{
            // isNotesDisabled: true,
            // btnTitle: "Confirm",
          }}
        />

        <ConfirmInventory
          open={openConfirmModal}
          setOpen={setOpenConfirmModal}
          contracts={contracts}
        />
        
        <CustomPrints
          ref={componentRef}
          contracts={checkedInv}
          Component={InventoryScanPrint}
        />

      </Grid>

    </>
  );
}