import React, { useState } from "react";
import { useParty, useStreamQueries } from "@daml/react";
import { Typography, Grid } from "@material-ui/core";
import { ReturnInventory, ReturnInventoryHistory } from "../../services/daml-modules1";
import ReceivedInventoryContracts from './receivedinventorycontracts1';
import useStyles from "../surgicalevent/styles";


/**
 * Received Inventory page in Vendor Side
 * @returns 
 */
export default function ReceivedInventory() {

  const classes = useStyles();

  const vendor = useParty();
  const { contracts, loading } = useStreamQueries(ReturnInventory, () => [{ vendor }]);
  const { contracts: contractsHistory } = useStreamQueries(ReturnInventoryHistory, () => [{ vendor }]);

  const [isHistory, setHistory] = useState(false);


  const handleEditInventory = (c) => {
  };


  return (
    <>
      <Grid>

        <Grid container justifyContent="space-between">
          <Grid item xs>
            <Typography variant="h4" className={classes.newPageTitle}>Incoming Inventory</Typography>
          </Grid>
          <Grid item>
            {/* <Button
              className={classNames(classes.newCreateButton, classes.newIconButton)}
              variant="contained"
              onClick={handlePrintClick}
              startIcon={<img  alt="" src={printIcon} />}
            /> */}
          </Grid>
        </Grid>

        <ReceivedInventoryContracts
          allContracts={contracts}
          historyContracts={contractsHistory}
          isIncludeHistory={isHistory}
          setArchived={setHistory}
          handleEditInventory={handleEditInventory}
          loading={loading}
        />

      </Grid>
      
    </>
  );
}
