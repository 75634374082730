import React, { useEffect } from "react";
import { Button, Dialog, DialogContentText, FormControl, FormControlLabel, Paper, Radio, RadioGroup, 
  TableContainer, TextField, Typography } from "@material-ui/core";
import { useLayoutDispatch, setLoading, unsetLoading } from "../../context/LayoutContext";
import { ReturnType } from "../../models/InventoryData";
import ProductDetailViewTable from "../surgicalevent/product-detail-view-table";
import downloadIcon from "../../icons/download.svg";
import { formatDate } from "../../services/util";
import { useDamlState } from '../../context/DamlContext';
import useStyles from "../surgicalevent/styles";


/**
 * Confirm Request Dialog
 * Account Inventory Contracts In House of Vendor
 * Hospital Adjusted Inventory Contracts in Vendor
 * @param {Boolean} open 
 * @param {Function} setOpen 
 * @param {Function} handleRequest 
 * @param {Object} request 
 * @param {Function} setRequest 
 * @param {String} requestType 'Return', 'Change'
 * @returns 
 */
export default function ConfirmDialog ({
  open, setOpen, handleRequest,
  request, setRequest, requestType='Return',
}) {
    
  const classes = useStyles();
  const { party, userParty } = useDamlState();

  const layoutDispatch = useLayoutDispatch();


  const {inventorydata} = {inventorydata:{}, ...request};
  // console.log("[ConfirmDialog]", request, inventorydata);

  // compare itempproduct & iproduct - disabled now
  let redParams = [];
  /* if (requestType === 'Change' && inventorydata.itempstatus === 'Edit') {
    if (inventorydata.itempproduct.sepproductmanufacturer !== inventorydata.iproduct.sepproductmanufacturer) redParams.push('sepproductmanufacturer');
    if (inventorydata.itempproduct.sepdistributor !== inventorydata.iproduct.sepdistributor) redParams.push('sepdistributor');
    if (inventorydata.itempproduct.sepreferencenumber !== inventorydata.iproduct.sepreferencenumber) redParams.push('sepreferencenumber');
    if (inventorydata.itempproduct.seplotcode !== inventorydata.iproduct.seplotcode) redParams.push('seplotcode');
    if (inventorydata.itempproduct.sepproductexpiration !== inventorydata.iproduct.sepproductexpiration) redParams.push('sepproductexpiration');
    if (inventorydata.itempproduct.sepownership !== inventorydata.iproduct.sepownership) redParams.push('sepownership');
    if (inventorydata.itempproduct.sepproductprice !== inventorydata.iproduct.sepproductprice) redParams.push('sepproductprice');
  } */


  const handleConfirm = async () => {
    // spinner
    setLoading(layoutDispatch);
    await handleRequest();
    unsetLoading(layoutDispatch);
  };

  const handleCancel = () => {
    setOpen(false);
    unsetLoading(layoutDispatch);
  };


  useEffect(() => {
    if (open) {
      console.log("[ConfirmDialog] Open ConfirmDialog dialog.");
    }
  }, [open]);

 
  return (
    <Dialog
      className={classes.customRoot}
      open={open}
      onClose={()=>{setOpen(false)}}
      aria-labelledby="draggable-dialog-title"
    >
      <h2 className={classes.poformSubHeading}>
        {(requestType === 'Change' ? 'Accept or Decline Change Request' : 'Accept or Decline Return Request')}
      </h2>

      <div className={classes.mainFormSteps}>
        {
          requestType === 'Return' && 
          <>
            <h2 className={classes.formSubHeading}>Return Request Overview</h2>
            <div className="grid-list grid-list-3">
              <div className="list-item">
                <div className="label">Request Type</div>
                <div className="value">{ReturnType[inventorydata.ireturntype]}</div>
              </div>
              <div className="list-item">
                <div className="label">Hospital</div>
                <div className="value">{request.hospital}</div>
              </div>
              <div className="list-item">
                <div className="label">Status</div>
                <div className="value">{request.ireturnstatus}</div>
              </div>
            </div>
            <div className="grid-list">
              <div className="list-item">
                <div className="label">Request Description</div>
                <div className="value">{inventorydata.ireturnmessage}</div>
              </div>
              <div className="list-item">
                <div className="label">Attachment</div>
                <div className="value downloadlink"><img src={downloadIcon} alt=""/> Download</div>
              </div>
            </div>
          </>
        }
    
        <DialogContentText component={'div'} className="modelStepDetai">

          {
            inventorydata.iproduct && 
            <div className="viewDetailsSe">
      
              <h2 className={classes.formSubHeading}>
                {(requestType === 'Change' ? 'Adjusted Inventory Details' : 'Product Details')}
              </h2>

              <div>
                <div className={classes.productAccordion}>
                
                  <Typography component={'div'}>
                    <TableContainer  className={classes.productTableContainer} component={Paper}>
                      {
                        requestType === 'Change' ?
                        <ProductDetailViewTable 
                          // obj={inventorydata.itempproduct ? inventorydata.itempproduct : inventorydata.iproduct}
                          obj={inventorydata.iproduct}
                          options={{
                            hiddenParams: [
                              'sepudi',
                              'sepproductdescription',
                              'sepproducttype',
                              'sepproductwaste',
                              'sepproductside',
                            ],
                            enableParams: ['sepreferencenumber'],
                            extraParams: [
                              {label: 'Date', value: formatDate(inventorydata.itemprequestdate), position: 'top'},
                              {label: 'Hospital', value: inventorydata.hospital, position: 'top'},
                              {label: 'User', value: inventorydata.itemprequestuser, position: 'top'},
                              {label: 'Hospital Comment', value: inventorydata.itemprequestmessage},
                            ],
                            redParams: redParams,
                          }}
                        />
                        :
                        <ProductDetailViewTable 
                          obj={inventorydata.iproduct}
                          options={{
                            hiddenParams: [
                              'sepdistributor', 
                              'sepproductwaste',
                            ],
                          }}
                        />
                      }
                    </TableContainer>
                  </Typography>
                      
      
                </div> 
              </div>

            </div>
          }

          <div className={classes.itemData}>
            <FormControl 
              variant="outlined" 
              className="radio-btn" >   
              <div className={classes.customCheckbox}>
                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                    <FormControlLabel value="Accepted" 
                      control={<Radio color="primary" checked={request.decision === true} />} 
                      className={classes.customRadio} 
                      label="I Accept" 
                      onChange={(e) => {
                        setRequest({
                          ...request,
                          decision: (e.target.value === "Accepted"),
                          user: (userParty ? userParty : party),
                        });
                      }} 
                    />
                    <FormControlLabel value="Rejected" 
                      control={<Radio color="primary" checked={request.decision === false} />} 
                      className={classes.customRadio} 
                      label="I Decline"
                      onChange={(e) => {
                        setRequest({
                          ...request,
                          decision: (e.target.value !== "Rejected"),
                          user: (userParty ? userParty : party),
                        });
                      }} 
                    />
                </RadioGroup>
              </div>
            </FormControl> 
          </div>
          <div className={classes.marginBM30}>
            <TextField
              id="input-with-icon-adornment7"
              label="User Name"
              placeholder="e.g. BioMed"
              className={classes.customFullWidth}
              margin="normal"
              value={request.user || ""}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />   
          </div>
          <div className={classes.marginBM30}>
            <TextField
              id="input-with-icon-adornment8"
              label="Comment"
              placeholder="e.g. I accept the Request"
              className={classes.customFullWidth}
              margin="normal"
              value={request.message || ""}
              onChange={(e) => {
                setRequest({
                  ...request,
                  message: e.target.value,
                });
              }}
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />   
          </div>

        </DialogContentText>
      </div>
    
      <div className="form-footer">
        <div className="right-buttons">
          <Button autoFocus onClick={handleCancel} className="noBtn" variant="outlined"  color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} className="yesBtn" variant="contained" color="primary">
            Submit
          </Button>
        </div>
      </div>

    </Dialog>
  );
}