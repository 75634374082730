import { VendorRole } from "../../services/daml-modules1";
import { getTemplateListFromResponse } from "../../services/util";


/**
 * create new Relationship
 * it means to Accept old RelationshipOffer
 * exercise VRelationOfferAccept choice of VendorRole
 * @param {Object} ledger 
 * @param {String} contractId
 * @param {String} roleCid 
 * @returns {Boolean}
 */
export const createRelationship = async ({
    ledger, contractId, roleCid,
}) => {
    // console.log("[createRelationship]", contractId);

    if (!contractId) return false;

    try {
        console.log("start Ledger Exercise");
    
        let res = await ledger.exercise(VendorRole.VRelationOfferAccept, roleCid, {
            relationcid: contractId,
        });

        console.log("[createRelationship]", res);

        // get contractId of the created Relationship
        let templateRelationship = getTemplateListFromResponse(res, "Relationship");

        return (templateRelationship.length > 0);
    } catch (e) {
        console.error("[createRelationship]", e);
    }

    return false;
};

/**
 * decline RelationshipOffer
 * exercise VRelationOfferDecline choice of VendorRole
 * @param {Object} ledger 
 * @param {String} contractId
 * @param {String} roleCid 
 * @returns {Boolean}
 */
export const declineRelationship = async ({
    ledger, contractId, roleCid,
}) => {
    // console.log("[declineRelationship]", contractId);

    if (!contractId) return false;

    try {
        console.log("start Ledger Exercise");
    
        let res = await ledger.exercise(VendorRole.VRelationOfferDecline, roleCid, {
            relationcid: contractId,
        });

        console.log("[declineRelationship]", res);

        // get contractId of the updated RelationshipOffer
        let templateRelationship = getTemplateListFromResponse(res, "RelationshipOffer");

        return (templateRelationship.length > 0);
    } catch (e) {
        console.error("[declineRelationship]", e);
    }

    return false;
};
