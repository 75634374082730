import { useState, useEffect, useCallback } from "react";
import { auth0, ledgerApiIdentify } from "../config";
import { useAuth0 } from "@auth0/auth0-react";


/**
 * Hook for Auth0 Token
 * @returns 
 */
export default function useAuthToken() {

  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    user,
  } = useAuth0();

  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({});


  const getToken = useCallback(async () => {
    try {
      if (auth0.isOnlyAuthLogin) {

        setLoading(true);
        if (isAuthenticated) {
          const val = await getAccessTokenSilently();
          // console.log("[useAuthToken] getAccessTokenSilently", val);
          setToken(val);
        }
      }
    }
    catch(e) {
      //
    }

    setLoading(false);
  }, [getAccessTokenSilently, isAuthenticated]);


  useEffect(() => {
    getToken();
  }, [getToken]);


  useEffect(() => {
    if (!isLoading && isAuthenticated && user) {

      const newUserInfo = getUserFromAuthToken(user);
      
      setUserInfo(newUserInfo);
    }
  }, [isAuthenticated, isLoading, user]);


  return { token, loading, ...userInfo };
}


/**
 * get user infos from auth0 token
 * @param {String} token 
 * @return {Object}
 */
export function getUserFromAuthToken (token) {
  if (token && typeof token === 'object') {
    // console.log("[getUserFromAuthToken]", token);

    const operatorName = token[ledgerApiIdentify].operatorName;
    const role = token[ledgerApiIdentify].roles[0];
    const metadata = token[ledgerApiIdentify].metadata;
    const index = token.sub;
    const last_password_reset = token[ledgerApiIdentify].last_password_reset;

    // partyName is same as UserList Party
    // const partyName = token.name; // old version
    let partyName = token.name;
    if (metadata && metadata.party) partyName = metadata.party;

    // party means current_organization or organization or partyName
    let party = null;
    if (metadata && metadata.current_organization) party = metadata.current_organization;
    else if (metadata && metadata.organization) {
      if (typeof metadata.organization === 'object') party = metadata.organization[0];
      else party = metadata.organization;
    }
    else if(partyName) party = partyName;

    // organizations
    let organizations = typeof metadata.organization === 'object' ? metadata.organization : [metadata.organization];
    if (metadata && metadata.invited_organization) organizations = organizations.concat(metadata.invited_organization);
    organizations = organizations.reduce((orgs, org) => (
      (org && orgs.indexOf(org) === -1) ? orgs.concat(org) : orgs), []);

    // organization
    let organization = null;
    if (metadata && metadata.organization) organization = metadata.organization;

    let current_organization = null;
    if (metadata && metadata.current_organization) { current_organization = metadata.current_organization; } 
    else { current_organization =  metadata.organization}
    // console.log("[getUserFromAuthToken]", partyName, operatorName, role, metadata, party);
    return { partyName, operatorName, role, metadata, index, last_password_reset, party, organizations, organization, current_organization };
  }
  return {};
}
