import React from "react";
import { WellKnownPartiesProvider } from "@daml/hub-react";
import OnboardDAContents from "./contents";

export default function OnboardDA() {

  return (
    <WellKnownPartiesProvider>
      <OnboardDAContents />
    </WellKnownPartiesProvider>
  );
}

