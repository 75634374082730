import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(theme => ({
  tooltip: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: 'rgba(76, 175, 80, 0.38);',
      border: '1px solid #4CAF50',
      color: '#000000',
      fontWeight: 400,
      fontSize: 10,
      lineHeight: '11px',
    },
  },
  tooltipPlacementTop: {
    margin: 0
  },
}));
