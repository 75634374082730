import React, { useState, useEffect } from "react";
import { useStreamQueries, useParty, useLedger } from "@daml/react";
import { VendorRole } from "../../services/daml-modules1";
import {useDamlState, useDamlDispatch } from '../../context/DamlContext';
import { Button, FormControlLabel, List, ListItem, 
  Checkbox, Typography, Card, CardContent } from "@material-ui/core";
  import {
     FormControl,  InputLabel,   Select, MenuItem
  } from "@material-ui/core";
import { AdminVendorDetailModel } from "../../models/AdminVendorDetail";
import editIcon from '../../icons/edit.svg';
import { exerciseVendorAdminAccept } from "./handle";
import { DAML_CLEAR_ROLE } from "../../store/actions/constants";
import useStyles from "./styles";
import { AppBar, Tabs, Tab } from '@material-ui/core';
import TabPanel, { a11yProps } from "../../components/Modals/MultiTabsModals/TabPanel";
import classNames from "classnames";import useTabStyle from "../surgicalevent/styles";
import plusCircle from '../../icons/plus-circle.svg';
import deleteIcon from '../../icons/delete.svg';
import { CustomLoading } from "../../hoc/withLoading";


/**
 * Admin Vendor List page
 * @returns 
 */
export default function AdminVendorList() {

  const classes = useStyles();
  const vendor = useParty();

  const assets = useStreamQueries(VendorRole, () => [{vendor}]);

  return (
    <>
      <Typography variant="h4" className={classes.pageTitle}>Admin Vendor Data</Typography>

      {
        assets.loading
          ?
          <CustomLoading />
          :

          <Card className={classes.cardItem}>
            <CardContent>
              <AdminView assets={assets}/>
            </CardContent>
          </Card>

      }
    </>
  );
}


const AdminView = ({ assets }) => {
  const classes = useTabStyle();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    // setAllContracts([]);
    setValue(newValue);
  };

  return <>
    <AppBar position="static" className={classes.tabPanelMenu}>
      <Tabs indicatorColor="primary" textColor="primary" value={value} onChange={handleChange} aria-label="simple tabs example">
        <Tab label="Automated Invoice Settings" {...a11yProps(0)} />
        <Tab label="Invoice Settings" {...a11yProps(1)} />
      </Tabs>
    </AppBar>

    <TabPanel value={value} index={0} className={classes.tabPanelbody}>
      <InvoiceSetting assets={assets} />
    </TabPanel>
    <TabPanel value={value} index={1} className={classes.tabPanelbody}>
      <HospitalInvoiceSetting assets={assets} />
    </TabPanel>
 
  </>
}


const InvoiceSetting = ({ assets }) => {
  const classes = useStyles();
  const ledger = useLedger();
  const dispatch = useDamlDispatch();

  const [contractId, setContractId] = useState('');
  const [admindetails, setAdminDetails] = useState({ ...AdminVendorDetailModel });
  const [adminAutoInvoiceDetails, setAdminAutoInvoiceDetails] = useState({ ...AdminVendorDetailModel });
  const { userlistPayload } = useDamlState();
  const [editData, setEditData] = useState(0);

  // handle when click "Update" button
  const handleRequest = async () => {

    let tempAdminDetails = {
      ...admindetails, invoice_setting:adminAutoInvoiceDetails}
  
    let res = await exerciseVendorAdminAccept(tempAdminDetails, ledger, contractId,userlistPayload);
    if (res) dispatch({
      type: DAML_CLEAR_ROLE,
    });
    setAdminDetails(tempAdminDetails);
    setEditData(0);
  };

  // handle when click "Cancel" button
  const handleCancel = () => {
    setAdminDetails({ ...assets.contracts[0].payload.admindetails });
    setEditData(0);
  };

  useEffect(() => {
    if (!assets.loading && assets.contracts.length) {
      setContractId(assets.contracts[0].contractId);
      setAdminDetails({ ...assets.contracts[0].payload.admindetails });
      setAdminAutoInvoiceDetails({ ...assets.contracts[0].payload.admindetails.invoice_setting });
    }
  }, [assets]);

  console.log("[AdminVendorList]",  assets.contracts[0].payload);
  return <List className={classes.listItem}>
    <ListItem divider></ListItem>
    <ListItem >
      <div className="item">
        <Typography variant="h5" >
        Automated Invoice Settings   {editData !== 1 && <img src={editIcon} alt="edit" onClick={() => { setEditData(1) }} />}
        </Typography>

        <Typography component={"div"}>
                      {editData === 1 &&
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={adminAutoInvoiceDetails.auto_invoice}
                              onChange={(e) => setAdminAutoInvoiceDetails({ ...adminAutoInvoiceDetails, auto_invoice: e.target.checked })}
                            />
                          }
                          label="Automate Invoice"
                        />
                      }

                      {editData !== 1 && <>
                        <span className="label">Automate Invoice</span>
                        <span className={`value ${adminAutoInvoiceDetails.auto_invoice ? "greenbtn" : "redbtn"}`}>
                          {adminAutoInvoiceDetails.auto_invoice ? "On" : "Off"}
                        </span>
                      </>}
                    </Typography>

                    <Typography component={"div"}>
                      {editData === 1 &&
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={adminAutoInvoiceDetails.auto_invoice_hospital}
                              onChange={(e) => setAdminAutoInvoiceDetails({ ...adminAutoInvoiceDetails, auto_invoice_hospital: e.target.checked })}
                            />
                          }
                          label="Automate Invoice and Send to Hospital"
                        />
                      }

                      {editData !== 1 && <>
                        <span className="label">Automate Invoice and Send to Hospital</span>
                        <span className={`value ${adminAutoInvoiceDetails.auto_invoice_hospital ? "greenbtn" : "redbtn"}`}>
                          {adminAutoInvoiceDetails.auto_invoice_hospital ? "On" : "Off"}
                        </span>
                      </>}
                    </Typography>
                  </div>

                  {editData === 1 &&
                    <div className="form-footer">
                      <div className="right-buttons">
                        <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
                        <Button variant="outlined" color="primary" onClick={handleRequest}>Update</Button>
                      </div>
                    </div>
                  }
    </ListItem>

  </List>
}



const HospitalInvoiceSetting = ({ assets }) => {
  const classes = useStyles();
  const ledger = useLedger();
  const dispatch = useDamlDispatch();

  const {relHospitals: hospitals} = useDamlState();
  const [contractId, setContractId] = useState('');
  const [admindetails, setAdminDetails] = useState({});
  const [invoicesettingdetail, setInvoiceSettingDetails] = useState([]);

  const [invSettingData, setinvSettingData] = useState({ hospital: '', auto_payment_hospital: '' });
  const [invSettingErrorData, setinvSettingErrorData] = useState({ hospital: false, auto_payment_hospital: false });

  useEffect(() => {
    if (!assets.loading && assets.contracts.length) {
      setContractId(assets.contracts[0].contractId);
      setAdminDetails({ ...assets.contracts[0].payload.admindetails });
      setInvoiceSettingDetails(assets.contracts[0].payload.admindetails.invoice_term_setting || []);
    }
  }, [assets]);

  const handleRequest = async (invoice_setting_detail) => {
    console.log(invoice_setting_detail);
    let tempAdminDetails = { ...admindetails, invoice_term_setting: invoice_setting_detail }
    let res = await exerciseVendorAdminAccept(tempAdminDetails, ledger, contractId);
    console.log(tempAdminDetails);
    if (res) {
      dispatch({
        type: DAML_CLEAR_ROLE,
      });
    }
    setAdminDetails(tempAdminDetails);
  };

  return (<List className={classNames(classes.listItem, classes.padding10)}>
    <ListItem divider></ListItem>


    <ListItem>
      <Typography className={classNames(classes.row, classes.margin10)}>
        Invoice Terms
      </Typography>
      <div className={classNames(classes.row, classes.padding10, classes.margin10)} >
        <FormControl variant="outlined" className={classNames(classes.padding10)}>
          <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
            Hospital
          </InputLabel>
          <Select
            // native
            displayEmpty
            error={!!invSettingErrorData.hospital}
            value={invSettingData.hospital}
            inputProps={{
              name: 'hospitals',
              id: 'outlined-age-native-simple1',
            }}
            onChange={(e) => {
              console.log(e);
              setinvSettingData({ ...invSettingData, hospital: e.target.value })
            }}
          >
            <MenuItem key={'init'} value="" disabled>Select One</MenuItem>
            {
              hospitals.map((hospital, index) => (<MenuItem value={hospital} key={index}>{hospital}</MenuItem>))
            }

          </Select>
        </FormControl>


        <FormControl variant="outlined" className={classNames(classes.padding10)}>
          <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
            Value
          </InputLabel>
          <Select
            // native
            displayEmpty
            error={!!invSettingErrorData.auto_payment_hospital}
            value={invSettingData.auto_payment_hospital}
            inputProps={{
              name: 'values',
              id: 'outlined-age-native-simple1',
            }}
            onChange={(e) => {
              setinvSettingData({ ...invSettingData, auto_payment_hospital: e.target.value })
            }}
          >
            <MenuItem key={'init1'} value="" disabled>Select One</MenuItem>
            {
              ["Immediate", "INV NET 30", "INV NET 60", "INV NET 90"].map((hospital, index) => (<MenuItem value={hospital} key={index}>{hospital}</MenuItem>))
            }
          </Select>

        </FormControl>
        <img src={plusCircle} alt="plusCircle" onClick={async () => {
          if (invSettingData.hospital && invSettingData.auto_payment_hospital) {

            let filterHospital = invoicesettingdetail.filter((item, index) => {
              return item.hospital === invSettingData.hospital
            });

            filterHospital.forEach((element) => {
              let index = invoicesettingdetail.indexOf(element);
              if (index !== -1)
                invoicesettingdetail.splice(index, 1);
            });


            let temp = [...invoicesettingdetail, invSettingData];
            setInvoiceSettingDetails(temp);
            setinvSettingData({ hospital: '', auto_payment_hospital: '' });
            await handleRequest(temp);

          } else {
            let error = { };
            error.hospital = invSettingData.hospital ? false : true;
            error.auto_payment_hospital = invSettingData.auto_payment_hospital ? false : true;
            setinvSettingErrorData(error);
          }
        }} />

      </div>
    </ListItem>
    <ListItem>
      {
        invoicesettingdetail ? invoicesettingdetail.flatMap((e, index) => {
          console.log(e);
          return (<div className={classNames(classes.row, classes.margin10)} key={index}>
            <div className={classNames(classes.rowAuto, classes.outline, classes.padding5)}>
              <span className={classNames(classes.padding10)} >{e.hospital}</span>
              <span className={classNames(classes.padding10)}>{e.auto_payment_hospital}</span>

              <img src={deleteIcon} alt="deleteIcon" onClick={async () => {
                invoicesettingdetail.splice(index, 1);
                setInvoiceSettingDetails([...invoicesettingdetail]);
                await handleRequest(invoicesettingdetail);
              }} />
            </div>
          </div>)
        })
        :
        null
      }
      
    </ListItem>
  </List>)
}
