import React, { useState } from "react";
import {
  TableRow, TableCell, Checkbox
} from "@material-ui/core";
import useStyles from "../styles";
import { CustomTextField } from "../../../components/Inputs";

export default function CaseUsageElement({ rowElement, dispatch }) {
  const [used, setUsed] = useState(rowElement.used ? rowElement.used : (rowElement?.isChecked ? rowElement?.open : rowElement.used));
  const [unUsed, setUnUsed] = useState(rowElement.unUsed ? rowElement.unUsed : (rowElement?.isChecked ? rowElement?.closed : rowElement.unUsed));
  const [isChecked, setIsChecked] = useState(rowElement?.isChecked);

  const classes = useStyles();

  return (
    <>
      <TableRow>
        <TableCell className={classes.cellHeaderBold}>{rowElement?.pseudoItem.name}</TableCell>
        <TableCell className={classes.cellHeaderBold}>{rowElement?.note}</TableCell>
        <TableCell>{rowElement?.pseudoItem.referenceNumber}</TableCell>
        <TableCell>{rowElement?.pseudoItem.cost}</TableCell>
        <TableCell>
          <CustomTextField
            type="number"
            value={used ?? ""}
            onChange={(val) => {
              if (val >= 0) {
                rowElement.used = val;
                const unused = parseInt(rowElement?.open) + parseInt(rowElement?.closed) - parseInt(val);
                rowElement.unUsed = unused >= 0 ? unused : 0;
                setUnUsed(unused >= 0 ? unused : 0);
                setUsed(val);
              }
            }}
            width={51} />
        </TableCell>

        <TableCell className={classes.cellHeaderBold}>{unUsed}</TableCell>
        <TableCell className={classes.cellHeaderBold}>{rowElement?.open}</TableCell>
        <TableCell className={classes.cellHeaderBold}>{rowElement?.closed}</TableCell>

        <TableCell>
          {!isChecked &&
            <Checkbox checked={!isChecked}
              onChange={(val) => {
                rowElement.checked = true;
                setIsChecked(true);
                dispatch({
                  type: "change_product",
                  payload: {
                    productId: rowElement.pseudoItem.id,
                    categoryId: rowElement.pseudoItem.categoryId,
                    isChecked: true,
                  },
                });
              }}
              className={classes.newCheckboxPrimary} />}
          {isChecked &&
            <Checkbox onChange={(val) => {
              rowElement.checked = false;
              dispatch({
                type: "change_product",
                payload: {
                  productId: rowElement.pseudoItem.id,
                  categoryId: rowElement.pseudoItem.categoryId,
                  isChecked: false,
                  used:0,
                },
              });
              setIsChecked(false);
            }}
              checked={isChecked} className={classes.checkboxSelected} />}
        </TableCell>
      </TableRow>
    </>
  )
};