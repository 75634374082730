import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Link } from "react-router-dom";
import classnames from "classnames";
import arrowMenu from '../../../../icons/arrow-menu.svg';
import arrowMenuDis from '../../../../icons/arrow-menu-dis.svg';
import useStyles from "./styles";

export default function SidebarLink({
  path,
  icon,
  label,
  location,
  isSidebarOpened,
  activeIcon=null,
  hasSubMenu=false,
  setMenuToggle={},
  menuIndex=0
}) {
  const classes = useStyles();
  const isLinkActive = path && (location.pathname === path || location.pathname.indexOf(path) !== -1);
  const isSidebarText = !path;

  const ListItemContent = (
    <>
    
      {icon && (<ListItemIcon
        className={classnames(classes.linkIcon, {
          [classes.linkIconActive]: isLinkActive,
        })}
      >
       {isLinkActive === true ? activeIcon : icon }
      </ListItemIcon>)}
      <ListItemText
        classes={{
          primary: classnames(classes.linkText, {
            [classes.linkTextActive]: isLinkActive,
            [classes.linkTextHidden]: !isSidebarOpened,
            [classes.linkTextTitle]: isSidebarText,
          }),
        }}
        primary={label}
      />
       { (hasSubMenu === true) &&
      <> { (menuIndex !== 0) ?  
         <img src={arrowMenuDis} alt=""/>
       :
         <img src={arrowMenu} alt=""/>
      }
         </>
     }
    </>
  );

  return (
    isSidebarText ?
    (<ListItem
      className={classes.linkhead}
      classes={{
        root: classnames(classes.linkRoot, {
          [classes.linkActive]: isLinkActive,
        }),
      }}
    >
    {ListItemContent}
    </ListItem>)
    :
    (<>{hasSubMenu === true ? <ListItem
      button
      onClick={(e)=>{setMenuToggle(e,menuIndex)}}
      component={path && Link}
      to={path}
      className={classes.link}
      classes={{
        root: classnames(classes.linkRoot, {
          [classes.linkActive]: isLinkActive,
        }),
      }}
      disableRipple
    >
    <div className={isLinkActive === true ? 'active-item menuitem':'menuitem'}> {ListItemContent}</div>
    </ListItem>
    :
    <ListItem
    button
    component={path && Link}
    to={path}
    className={classes.link}
    classes={{
      root: classnames(classes.linkRoot, {
        [classes.linkActive]: isLinkActive,
      }),
    }}
    disableRipple
  >
  <div className={isLinkActive === true ? 'active-item menuitem':'menuitem'}> {ListItemContent}</div>
  </ListItem>
    }
    
    </>)
  );

  // ###########################################################
}
