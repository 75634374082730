import React, { useState } from "react";
import classNames from "classnames";
import { IconButton, Popover, Box, AppBar, Tabs, Tab, Grid } from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CloseIcon from '@material-ui/icons/Close';
import TabPanel, { a11yProps } from "../Modals/MultiTabsModals/TabPanel";
import DateSearch from "../Search/datesearch";
import SelectSearch from "../Search/selectsearch";
import { useStyles } from "./styles";


/**
 * Header Cell in Contracts, TreeContracts
 * @param {String} title 
 * @param {Object} search 
 * @param {Boolean} isSearched 
 * @param {Function} handleClear 
 * @returns 
 */
const HeaderCell = ({ 
  title, search, isSearched, handleClear,
}) => {
  // console.log("[HeaderCell]", search);
  
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {title}
      {(!!search.component && search.component.type === DateSearch) && 
        <IconButton 
          className={classes.searchIcon}
          onClick={handleClick}
        >
          <CalendarTodayIcon className={classes.calendarIcon}/>
        </IconButton>
      }
      <IconButton 
        className={classes.searchIcon}
        onClick={handleClick}
      >
        <ArrowDropDownIcon /> 
      </IconButton>
      {!!isSearched && 
        <IconButton 
          className={classes.searchIcon}
          onClick={typeof handleClear === 'function' ? handleClear : () => {}}
        >
          <CloseIcon />
        </IconButton>
      }
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <Box 
          className={(!!search.component && search.component.type === SelectSearch) ? 
            classNames(classes.popoverBox, classes.noPadding) : classes.popoverBox}
        >
          {
            Array.isArray(search)
            ?
              <>
                <AppBar position="static" color="default">
                  <Tabs 
                    value={value} 
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    {search.map((s, i) => <Tab label={s.label} key={i} {...a11yProps(i)} />)}
                  </Tabs>
                </AppBar>
                {search.map((s, i) => <TabPanel value={value} key={i} index={i}>{s.component}</TabPanel>)}
              </>
            :
            (
              !!search && !!search.component
              ?
              <Grid style={{ padding: 20 }}>
                {search.component}
              </Grid>
              :
              null
            )
          }
        </Box>
      </Popover>
    </>
  );

};

/**
 * render Header Cell
 * @param {Object} option 
 * @returns 
 */
export const renderHeaderCell = (option) => {
  if (option) {
    if (typeof option.search !== 'undefined') {
      return (param) => (
        <HeaderCell 
          title={param.field} 
          search={option.search} 
          isSearched={option.isSearched}
          handleClear={option.handleClear}
        />
      );
    }
  }
  return (param) => (param.field);
};