import React from 'react'
import classNames from "classnames";
import { Grid, Typography, Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import ModalDialog from '../../components/Modals/NewGeneralModal';
import useStyles from "../surgicalevent/styles";
import useStyles1 from "../purchaseorder/styles";
import AuditDetails from './auditDetails';
import moment from 'moment-timezone';
const AuditDetailsAccInv = ({
    open, setOpen, contract = {},
}) => {
    const classes = useStyles();
    const classes1 = useStyles1();
    const invData = contract.payload;
    const time = moment(invData?.created_at).tz(moment.tz.guess()).format('LT z');
    return (
        <>

            <ModalDialog
                open={open}
                setOpen={setOpen}
                height={450}
                width={800}
                title={(
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        className={classNames(classes.newSETitlePanel, classes1.newPOTitlePanel)}
                    >
                        <Typography variant="inherit" component="span" className="label" >
                            Audit Log Details -
                        </Typography>
                        <Typography variant="inherit" component="span" className="label" >
                            Account Inventory
                        </Typography>
                    </Grid>

                )}
                content={(
                    <Grid container
                        direction="row"
                        className={classes1.createPanel}
                    >
                        <Grid item xs={4}>
                            <AuditDetails
                                poData={invData}
                                time={time}
                            />
                        </Grid>
                        <Grid item xs={8} style={{paddingTop:"2rem"}}>
                            <Table className={classNames(classes.newSEForm, classes.overview)}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={2} className="new-subtitle">
                                            Inventory Details
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="label">
                                        Reference Number
                                        </TableCell>
                                        <TableCell className="value">
                                            {invData?.logEvents?.value?.accInventoryData?.inventoryproduct?.ireferencenumber}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="label">
                                        Ownership
                                        </TableCell>
                                        <TableCell className="value">
                                            {invData?.logEvents?.value?.accInventoryData?.inventoryproduct?.iownership}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="label">
                                        Distributor
                                        </TableCell>
                                        <TableCell className="value">
                                            {invData?.logEvents?.value?.accInventoryData?.vendor}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="label">
                                        Description
                                        </TableCell>
                                        <TableCell style={{ whiteSpace: 'normal',wordWrap: 'break-word'}} className="value">
                                            {invData?.logEvents?.value?.accInventoryData?.inventoryproduct?.iproductdescription}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="label">
                                        Unclassified
                                        </TableCell>
                                        <TableCell className="value">
                                            {String(Boolean(invData?.logEvents?.value?.accInventoryData?.inventoryproduct?.unclassified))}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>

                )}
            />

        </>
    )
}

export default AuditDetailsAccInv