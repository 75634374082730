import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useParty, useStreamQueries, useLedger } from "@daml/react";
import { Grid, Typography, Button, IconButton, Badge, 
  Table, TableBody, TableHead, TableRow, TableCell } from "@material-ui/core";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { SurgicalEventProcedureThresholdAmount, SurgicalEventProcedureVendorAmount,
  SurgicalEventProcedure } from "../../services/daml-modules1";
import { useDamlState, useDamlProcedure } from "../../context/DamlContext";
import { useLayoutDispatch, setLoading, unsetLoading } from "../../context/LayoutContext";
import CustomTooltip from "../../components/Tooltip/CustomTooltip";
import ModalDialog from '../../components/Modals/NewGeneralModal';
import { CustomTextField } from "../../components/Inputs";
import AdaptiveSearch from "../../components/Search/adaptivesearch1";
import { validateSurgicalEventProcedurePrice, compareSurgicalEventProcedurePrice,
  validateSurgicalEventProcedureDisPrice, compareSurgicalEventProcedureDisPrice } from "../../models/SurgicalEventProcedure";
import { CustomLoading } from "../../hoc/withLoading";
import deleteIcon from "../../icons/new-delete3.svg";
import editIcon from "../../icons/edit.svg";
import { InputDistributor } from "../surgicalevent/edit-product1";
import useStyles from "../surgicalevent/styles";
import { createSurgicalEventProcedureThresholdAmount, updateSurgicalEventProcedureThresholdAmount, 
  removeSurgicalEventProcedureThresholdAmount, createSurgicalEventProcedureVendorAmount, 
  updateSurgicalEventProcedureVendorAmount, removeSurgicalEventProcedureVendorAmount } from "../surgicalevent/handle";
import useStyles1 from "./styles";


const AddProcedureModal = ({
  open, setOpen, isVendor=false, isEdit=false, handleAdd, procedure={}, 
}) => {

  const classes = useStyles();
  const classes1 = useStyles1();

  const { procedures } = useDamlProcedure();

  const [filterProcedures, setFilterProcedures] = useState([]);
  const [distributor, setDistributor] = useState('');
  const [price, setPrice] = useState('');
  const [defaultValue, setDefaultValue] = useState(null);


  // handle to click "Add"
  const handleAddClick = () => {
    let temp = {
      distributor,
      price,
    };
    if (isEdit) temp.cptname = procedure.cptname;
    else temp.cptname = (filterProcedures.length ? filterProcedures[0] : "");
    let validate = false;
    if (isVendor) validate = validateSurgicalEventProcedureDisPrice(temp);
    else validate = validateSurgicalEventProcedurePrice(temp);
    if (validate) {
      handleAdd(temp);
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      if (isEdit) {
        setFilterProcedures([]);
        setDistributor(procedure.distributor);
        setPrice(procedure.price);
        setDefaultValue(procedure.cptname);
      }
      else {
        setFilterProcedures([]);
        setDistributor('');
        setPrice('');
        setDefaultValue(null);
      }
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <ModalDialog
      open={open}
      setOpen={setOpen}
      title={'Add Procedure'}
      content={(
        <Grid 
          container
          direction="column" 
          justifyContent="space-between" 
          alignItems="stretch"
          className={classes1.addProcedureModalBody}
        >
          <Grid item>
            <AdaptiveSearch
              contracts={procedures}
              params={filterProcedures}
              setParams={setFilterProcedures}
              classes={{ wrapper: classes1.newAutoComplete }}
              defaultValue={defaultValue}
              isChangable={!isEdit}
              label='Choose Procedure'
            />
          </Grid>
          {
            isVendor &&
            <Grid item>
              <InputDistributor
                type='select'
                label="Distributor"
                distributor={distributor}
                setDistributor={(val) => setDistributor(val)}
                placeholder="Select One"
                width={167}
              />
            </Grid>
          }
          <Grid item>
            <CustomTextField
              type="number"
              label="Set Price"
              placeholder="Enter price.."
              value={price}
              onChange={(val) => setPrice(val)}
              width={290}
            />
          </Grid>
        </Grid>
      )}
      actions={(
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleAddClick} 
          className={classes.newCompleteBtn} 
        >
          {isEdit ? 'Update' : 'Add'}
        </Button>
      )}
      width={634}
      height={453}
    />
  );
};

/**
 * Procedure Settings Panel
 * @returns 
 */
export default function ProcedurePanel() {
  
  const classes = useStyles();
  const classes1 = useStyles1();

  const hospital = useParty();
  const ledger = useLedger();
  const { contracts, loading } = useStreamQueries(SurgicalEventProcedure, () => [{ hospital }]);
  const { contracts: contracts1, loading: loading1 } = useStreamQueries(SurgicalEventProcedureThresholdAmount, () => [{ hospital }]);
  const { contracts: contracts2, loading: loading2 } = useStreamQueries(SurgicalEventProcedureVendorAmount, () => [{ hospital }]);
  const thresholdContracts = contracts1.map(c => ({ ...c.payload.procedure, contractId: c.contractId }));
  const vendorContracts = contracts2.map(c => ({ ...c.payload.procedure, contractId: c.contractId }));
  
  const { roleCid } = useDamlState();
  const { procedures, setProcedure: setProcedureContext } = useDamlProcedure();
  const layoutDispatch = useLayoutDispatch();

  const [thresholdAmounts, setThresholdAmounts] = useState([]);
  const [vendorAmounts, setVendorAmounts] = useState([]);
  // Add Procedure Modal
  const [open, setOpen] = useState(false);
  const [isVendor, setIsVendor] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [procedure, setProcedure] = useState({});

  // console.log("[ProcedurePanel]", thresholdContracts);


  // handle to click "Add" in Modal
  const handleAddProcedure = (c) => {
    // console.log("[ProcedurePanel] handleAddProcedure", c);
    let temp = [];
    if (isVendor) {
      temp = [...vendorAmounts];
      const found = temp.find(({cptname, distributor}) => (cptname === c.cptname && distributor === c.distributor));
      if (found) {
        found.price = c.price;
      }
      else {
        temp.push({
          cptname: c.cptname,
          distributor: c.distributor,
          price: c.price,
        });
      }
      setVendorAmounts(temp);
    }
    else {
      temp = [...thresholdAmounts];
      const found = temp.find(({cptname}) => cptname === c.cptname);
      if (found) {
        found.price = c.price;
      }
      else {
        temp.push({
          cptname: c.cptname,
          price: c.price,
        });
      }
      setThresholdAmounts(temp);
    }
  };

  // handle to click "Add" Threshold
  const handleAddThreshold = () => {
    setIsVendor(false);
    setIsEdit(false);
    setOpen(true);
  };

  // handle to click "Edit" Threshold
  const handleEditThreshold = (index) => {
    const temp = [...thresholdAmounts];
    setIsVendor(false);
    setIsEdit(true);
    setProcedure(temp[index]);
    setOpen(true);
  };

  // handle to click "Delete" Threshold
  const handleDeleteThreshold = (index) => {
    const temp = [...thresholdAmounts];
    temp.splice(index, 1);
    setThresholdAmounts(temp);
  };

  // handle to click "Add" Vendor
  const handleAddVendor = () => {
    setIsVendor(true);
    setIsEdit(false);
    setOpen(true);
  };

  // handle to click "Edit" Vendor
  const handleEditVendor = (index) => {
    const temp = [...vendorAmounts];
    setIsVendor(true);
    setIsEdit(true);
    setProcedure(temp[index]);
    setOpen(true);
  };

  // handle to click "Delete" Vendor
  const handleDeleteVendor = (index) => {
    const temp = [...vendorAmounts];
    temp.splice(index, 1);
    setVendorAmounts(temp);
  };

  // handle to click "Save"
  const handleSave = async () => {
    // spinner
    setLoading(layoutDispatch);

    // SurgicalEventProcedureThresholdAmount
    let allprocedures = {};
    if (thresholdAmounts.length) {
      thresholdAmounts.forEach(tmp => {
        tmp.status = 'new';
        allprocedures[tmp.cptname] = tmp;
      });
    }
    if (thresholdContracts.length) {
      thresholdContracts.forEach(tmp => {
        if (tmp.cptname in allprocedures) {
          if (compareSurgicalEventProcedurePrice(tmp, allprocedures[tmp.cptname])) {
            allprocedures[tmp.cptname].status = 'none';
          }
          else {
            allprocedures[tmp.cptname].status = 'update';
            allprocedures[tmp.cptname].contractId = tmp.contractId;
          }
        }
        else {
          tmp.status = 'remove';
          allprocedures[tmp.cptname] = tmp;
        }
      });
    }
    for (let cptname in allprocedures) {
      const tmp = allprocedures[cptname];
      if (tmp.status === 'new') {
        await createSurgicalEventProcedureThresholdAmount({
          ledger,
          procedure: tmp,
          roleCid,
        });
      }
      else if (tmp.status === 'update') {
        await updateSurgicalEventProcedureThresholdAmount({
          ledger,
          newprice: tmp.price,
          roleCid,
          sepcid: tmp.contractId,
        });
      }
      else if (tmp.status === 'remove') {
        await removeSurgicalEventProcedureThresholdAmount({
          ledger,
          roleCid,
          sepcid: tmp.contractId,
        });
      }
    }

    // SurgicalEventProcedureVendorAmount
    allprocedures = {};
    if (vendorAmounts.length) {
      vendorAmounts.forEach(tmp => {
        tmp.status = 'new';
        allprocedures[`${tmp.cptname}:${tmp.distributor}`] = tmp;
      });
    }
    if (vendorContracts.length) {
      vendorContracts.forEach(tmp => {
        const key = `${tmp.cptname}:${tmp.distributor}`;
        if (key in allprocedures) {
          if (compareSurgicalEventProcedureDisPrice(tmp, allprocedures[key])) {
            allprocedures[key].status = 'none';
          }
          else {
            allprocedures[key].status = 'update';
            allprocedures[key].contractId = tmp.contractId;
          }
        }
        else {
          tmp.status = 'remove';
          allprocedures[key] = tmp;
        }
      });
    }
    for (let key in allprocedures) {
      const tmp = allprocedures[key];
      if (tmp.status === 'new') {
        await createSurgicalEventProcedureVendorAmount({
          ledger,
          procedure: tmp,
          roleCid,
        });
      }
      else if (tmp.status === 'update') {
        await updateSurgicalEventProcedureVendorAmount({
          ledger,
          newprice: tmp.price,
          roleCid,
          sepcid: tmp.contractId,
        });
      }
      else if (tmp.status === 'remove') {
        await removeSurgicalEventProcedureVendorAmount({
          ledger,
          roleCid,
          sepcid: tmp.contractId,
        });
      }
    }

    unsetLoading(layoutDispatch);
  };

  // handle to click "Cancel"
  const handleCancel = () => {
    setThresholdAmounts(thresholdContracts);
    setVendorAmounts(vendorContracts);
  };


  useEffect(() => {
    if (!loading) {
      const strContext = JSON.stringify(procedures);
      const strStream = JSON.stringify(contracts.map(c => c.payload.procedure.cptname));
      if (strContext !== strStream) {
        setProcedureContext(contracts);
      }
    }
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    if (!loading1) {
      setThresholdAmounts(thresholdContracts);
    }
    // eslint-disable-next-line
  }, [loading1]);

  useEffect(() => {
    if (!loading2) {
      setVendorAmounts(vendorContracts);
    }
    // eslint-disable-next-line
  }, [loading2]);
  

  return (
    <Grid 
      container 
      className={classes1.locationTab} 
      direction="column" 
      justifyContent="space-between" 
      alignItems="flex-start"
      spacing={2}
    >
      {
        loading
        ?
        <CustomLoading />
        :
        <>
          <Grid item className="newheader">
            <Typography component="span">Compliance Rule for Surgical Events amount Thresholds</Typography>
            <Button
              className={classes1.addSmallButton}
              variant="contained"
              onClick={handleAddThreshold}
              endIcon={<AddCircleIcon />}
            >
              Add Procedure
            </Button>
          </Grid>
          <Grid item className="procedure-panel">
            {
              loading1
              ?
              <CustomLoading />
              :
              <Table className={classes1.procedureTable}>
                <TableHead>
                  <TableRow>
                    <TableCell>Procedure</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    thresholdAmounts.map(({cptname, price}, index) => (
                      <TableRow key={index}>
                        <TableCell>{cptname}</TableCell>
                        <TableCell>${price}</TableCell>
                        <TableCell>
                          <IconButton className="add-icon" onClick={() => handleEditThreshold(index)}>
                            <img alt="" src={editIcon} />
                          </IconButton>
                          <IconButton className="add-icon" onClick={() => handleDeleteThreshold(index)}>
                            <img alt="" src={deleteIcon} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            }
          </Grid>
          <Grid item className="newheader">
            <CustomTooltip 
              title={`These settings will not allow a PO to be created for a vendor above the set amount`}
              arrow
              classes={{tooltip: classes.productTooltip}}
            >
              <Badge badgeContent='i' color="primary" overlap="circular" >
                <Typography variant="inherit" component="span" >
                  Capitated Procedural Settings
                </Typography>
              </Badge>
            </CustomTooltip>
            <Button
              className={classes1.addSmallButton}
              variant="contained"
              onClick={handleAddVendor}
              endIcon={<AddCircleIcon />}
            >
              Add Capitated Procedural Setting
            </Button>
          </Grid>
          <Grid item className="procedure-panel">
            {
              loading2
              ?
              <CustomLoading />
              :
              <Table className={classes1.procedureTable}>
                <TableHead>
                  <TableRow>
                    <TableCell>Procedure</TableCell>
                    <TableCell>Distributor</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    vendorAmounts.map(({cptname, distributor, price}, index) => (
                      <TableRow key={index}>
                        <TableCell>{cptname}</TableCell>
                        <TableCell>{distributor}</TableCell>
                        <TableCell>${price}</TableCell>
                        <TableCell>
                          <IconButton className="add-icon" onClick={() => handleEditVendor(index)}>
                            <img alt="" src={editIcon} />
                          </IconButton>
                          <IconButton className="add-icon" onClick={() => handleDeleteVendor(index)}>
                            <img alt="" src={deleteIcon} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            }
          </Grid>
          <Grid item className="actions">
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCancel}
              className={classNames(classes.newCompleteBtn, "secondary-color")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSave}
              className={classes.newCompleteBtn}
            >
              Save
            </Button>
          </Grid>
        </>
      }

      <AddProcedureModal
        open={open}
        setOpen={setOpen}
        isVendor={isVendor}
        isEdit={isEdit}
        handleAdd={handleAddProcedure}
        procedure={procedure}
      />
    </Grid>
  );
}
