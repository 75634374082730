import { getArray, validateText, validateDecimal } from "./handles";

export const PrefProduct = {
  vendor: '',
  reference: '',
  quantity: '',
  price: '',
  invproductkey: '',
  invids: [],
};

export const validatePrefProduct = (pref) => {
  return (validateText(pref.vendor)
    && validateText(pref.reference)
    && validateDecimal(pref.quantity)
  );
};

export const getPrefProduct = (pref) => {
  let newPref = {};
  newPref.vendor = pref.vendor || "";
  newPref.reference = pref.reference || "";
  newPref.quantity = validateDecimal(pref.quantity) ? pref.quantity : 0;
  if (validateDecimal(pref.price)) newPref.price = pref.price;
  if (validateText(pref.invproductkey)) newPref.invproductkey = pref.invproductkey;
  newPref.invids = getArray(pref.invids, validateText);
  return newPref;
};


export const PrefData = {
  prefcardid: '',
  surgonprefid: '',
  prefcarddetails: '',
  cptcodes: [],
  consumedproduct: [],
};

export const validatePrefData = (pref) => {
  return (validateText(pref.prefcardid)
    && validateText(pref.surgonprefid)
    && validateText(pref.prefcarddetails)
  );
};

export const getPrefData = (pref) => {
  let newPref = {};
  newPref.prefcardid = pref.prefcardid || "";
  newPref.surgonprefid = pref.surgonprefid || "";
  newPref.prefcarddetails = pref.prefcarddetails || "";
  newPref.cptcodes = getArray(pref.cptcodes, validateText);
  newPref.consumedproduct = getArray(pref.consumedproduct, validatePrefProduct, getPrefProduct);
  return newPref;
};

/**
 * convert PrefData List
 * TODO
 * Need to get invproductkey, invids in consumedproduct from SECard contracts
 * @param {Array} data 
 * @returns 
 */
export const convertprefData = (data=[]) => {
  return data.map((prefitem) => {
      let totalproducts = prefitem.items.reduce((accumulator, currentCategory) => {
          let products = currentCategory.items.reduce((consumedproduct, currentproduct) => {
              if (currentproduct.isChecked) {
                  if (currentproduct.pseudoItem.vendor && currentproduct.pseudoItem.referenceNumber) {
                      consumedproduct.push(getPrefProduct({
                          vendor: currentproduct.pseudoItem.vendor,
                          reference: currentproduct.pseudoItem.referenceNumber,
                          quantity: parseInt(currentproduct.open) + parseInt(currentproduct.closed),
                          price: parseFloat(currentproduct.pseudoItem.cost),
                          invproductkey: currentproduct.invproductkey,          // TODO Need to confirm invproductkey in consumedproduct
                          invids: currentproduct.invids,                        // TODO Need to confirm invids in consumedproduct
                      }))
                  }
              }
              return consumedproduct;
          }, []);
          return [...accumulator, ...products];
      }, []);
      console.log("[convertprefData] prefitem", prefitem, "totalproducts", totalproducts);

      return {
          prefcardid: prefitem.id,
          surgonprefid: prefitem.surgeonId,
          prefcarddetails: JSON.stringify(prefitem),
          cptcodes: prefitem.cptCodes,
          consumedproduct: totalproducts
      }
  });
}
