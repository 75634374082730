import React, { useEffect, useState } from "react";
import { Chat } from "../../services/daml-modules1";
import { Avatar, Grid, ListItem, ListItemText } from "@material-ui/core";
import ScrollToBottom from 'react-scroll-to-bottom';
import DamlStream from "../../components/Daml/DamlStream";
import { getPartyLabel } from "../../models/handles";
import useStyles from "./styles";


export default function MessageList({
    room,
    lastMessage='',
    setLastMessage,
}) {

    const classes = useStyles();
    const [searchMsg, setSearchMsg] = useState([]);
    const [msgList, setMsgList] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
       console.log("isloading", isLoading);
        if (!isLoading) {
            setSearchMsg([{ room }]);
            setLoading(true);
        }
    }, [room, isLoading, lastMessage]);

    //console.log("[MessageList]", room, msgList,lastMessage);

    return (
        <>
            <ScrollToBottom className={classes.messages}>
                {
                    msgList && msgList.length
                        ?
                        msgList.map((c, i) => (
                            <div key={i}>
                                <ListItem key={i} >
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div className={classes.chatList}>
                                                <Avatar>{getPartyLabel(c.payload.sender)}</Avatar> 
                                            </div> 
                                            <ListItemText  primary={c.payload.sender}  secondary={c.payload.created_at}></ListItemText>
                                            <ListItemText>{c.payload.message}</ListItemText>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                
                            </div>
                        ))
                        :
                        null
                }
            </ScrollToBottom>
            
            <DamlStream
                template={Chat}
                searchParams={searchMsg}
                lastMessage={lastMessage}
                setLastMessage={setLastMessage}
                setData={(data) => {
                    setMsgList(data);
                    setLoading(false);
                }}
                isLoading={isLoading}
            />

        </>
    );

}
