import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  halfGrid: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  topGrid: {
    marginTop: '10px',
    marginBottom: '20px',
  },
  middleGrid: {
    marginTop: 12,
    marginBottom: 8,
  },
  bottomGrid: {
    marginTop: 5,
    marginBottom: 40,
  },
  formControl: {
    minWidth: 200,
    marginLeft: 10,
    marginRight: 10,
  },
  buttonRetrieve: {
    marginTop: 20,
    marginRight: 20,
  },
  buttonLeft: {
    marginRight: 15,
  },
  buttonRight: {
    marginLeft: 15,
  },
  setupButton: {
    marginBottom: '20px',
  },
  customRoot:{

  },
  comment: {
    marginTop: '7px',
    display: 'block',
  },
  dialogField: {
    width: '200px',
  },
  dateField: {
    display:'contents',
  },
  divider: {
    height: 20,
    marginLeft: 10,
  },
  pageTitle: {
    display: 'inline-block',
    marginBottom: '15px',
  },
  smallChip: {
    fontSize: '0.65rem',
  },
  centerCell: {
    justifyContent: 'center !important'
  },
  newSetupButton: {
    padding:'6px 15px',
    color: '#ffffff',
    backgroundColor: '#183C73',
    float: 'right',
    '&:hover': {
      backgroundColor: '#183c73d9',
    },
    '&.MuiButton-root': {
      textTransform: 'capitalize',
      fontWeight: '500',
    },
    '@media (max-width:1024px)':{
      fontSize:'0px',
      minWidth:'36px',
      padding:'6px',
    },
    '& .desktop':{
      
      '@media (max-width:1024px)':{
        display:"none",
      },
    },
    '& .mobile':{
      display:"none",
      '@media (max-width:1024px)':{
        display:"block",
      },
    },
   
  },
  newCheckbox: {
    float: 'right',
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '16px',
    '& .MuiCheckbox-root': {
      padding: '4px 9px',
    }
  },
  poCommentHeading:{
    fontSize:'16px',
    marginBottom:'25px'
  },
  poCommentsGridTable:{
    marginBottom:'40px',

    background: '#ffffff',
   
    '& .MuiDataGrid-root.MuiDataGrid-autoHeight':{
      background:'#F4F6F8',
      boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    },
    '& .MuiGrid-spacing-xs-4 > .MuiGrid-item':{
      padding:'0px 16px'
    },
   '& .MuiChip-sizeSmall': {
    background: 'rgba(76, 175, 80, 0.38)',
    border: '1px solid #4CAF50'
   },
   '& [data-value="Rejected"]  .MuiChip-sizeSmall':{
       background: 'rgba(244, 67, 54, 0.38)',
       border: '1px solid #F44336'
   }
  },
  customRootComment:{
    background: '#F4F6F8',
    boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    '& .MuiAvatar-colorDefault':{
      background: '#0EC4D5'
    },
    '& .rightComment':{
      justifyContent:'flex-end',
      '& .MuiListItemAvatar-root':{
        minWidth:'40px',
        marginLeft:'16px',
      },
      '& .me':{
        background: '#DEF3FB',
        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        padding:'10px 16px',
        
      }
    },
    '& .leftComment':{
      '& .MuiListItemAvatar-root':{
        minWidth:'40px',
        marginRight:'16px',
      },
      '& .you':{
        background: '#ffffff',
        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        padding:'10px 16px',
        
      }
    },
    '& .MuiListItemText-primary':{
      width:'auto',
      float:'left',
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight:'500',
    },
    '& .MuiListItemText-secondary':{
      width:'auto',
      float:'right',
      color: 'rgba(0, 0, 0, 0.38)',
      marginLeft: '16px',
    },
    '& .msg':{
      width:'100%',
      display:'inline-block',
      color: 'rgba(0, 0, 0, 0.87)',
    }
  },
  // New redesign
  incomingPanel: {
    // padding: '0px 35px',
  },
  details: {
    '& .title': {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '22px',
      color: '#000000',
      marginLeft: '-20px',
      marginBottom: '10px',
    },
    '& .header-label': {
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '18px',
      color: '#5C5C5C',
    },
    '& .header-value': {
      fontWeight: 700,
      fontSize: '15px',
      lineHeight: '17px',
      color: '#000000',
    },
  },
  headerPanel: {
    padding: '6px 320px',
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        paddingTop: '2px',
        paddingBottom: '2px',
        '&:first-child': {
          paddingLeft: '2px',
          minWidth: 'inherit',
        },
      }
    },
  },
  middlePanel: {
    padding: '0px 15px',
    '& .header-value:nth-child(even)': {
      paddingLeft: '10px',
      paddingRight: '70px',
    },
    '& .MuiIconButton-root': {
      padding: '1px',
      marginLeft: '20px',
    },
  },
  contractsPanel: {
    '& .MuiDataGrid-columnHeaders': {
      '& .MuiDataGrid-columnHeader': {
        '&:last-child': {
          borderRight: '1px solid rgba(224, 224, 224, 1)',
        },
      },
    },
    '& .MuiDataGrid-row': {
      background: '#F5F5F5',
    },
    '& .MuiDataGrid-detailPanels': {
      '& .MuiDataGrid-detailPanel': {
        padding: '10px 0px 10px',
        background: '#F5F5F5',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        '& .accordion-table': {
          // tableLayout: 'fixed',
          '& .MuiTableCell-root': {
            padding: '0px 5px 0px 0px',
            '&.details': {
              fontSize: '15px',
              fontWeight: 400,
              lineHeight: '17px',
              paddingLeft: '10px',
              width: '150px',
            },
            '&.value': {
              overflowWrap: 'anywhere',
              whiteSpace: 'pre-wrap',
              width: '200px',
              '&.pattern-red': {
                color: '#f00',
              },
            }
          },
        },
      },
    },
  },
  bottomPanel: {
    paddingLeft: '15px',
  },
  statusCell: {
    "& .text": {
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '18px',
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  redText: {
    '& .MuiSelect-selectMenu.MuiInput-input': {
      color: '#F44336 !important',
    },
    '&.MuiTypography-root': {
      color: '#F44336',
    },
  },
  blueText: {
    '& .MuiSelect-selectMenu.MuiInput-input': {
      color: '#2196F3 !important',
    },
    '&.MuiTypography-root': {
      color: '#2196F3',
    },
  },
  greyText: {
    '& .MuiSelect-selectMenu.MuiInput-input': {
      color: '#5C5C5C !important',
    },
    '&.MuiTypography-root': {
      color: '#5C5C5C',
    },
  },
  greenText: {
    '& .MuiSelect-selectMenu.MuiInput-input': {
      color: '#4CAF50 !important',
    },
    '&.MuiTypography-root': {
      color: '#4CAF50',
    },
  },
  yellowText: {
    '& .MuiSelect-selectMenu.MuiInput-input': {
      color: '#F7CD37 !important',
    },
    '&.MuiTypography-root': {
      color: '#F7CD37',
    },
  },
  lightGreenText: {
    '& .MuiFormLabel-root': {
      color: '#0F7913 !important',
    },
    '& .MuiInputBase-root .MuiInputBase-input.Mui-disabled': {
      color: '#0F7913 !important',
    },
  },
  // statusPicker: {
  //   color: ({ status }) => (
  //     status === 'Received' ? '#4CAF50' : (
  //       status === 'Opens' ? '#2196F3' : (
  //         status === 'BackOrder' ? '#F44336' : '#2196F3'
  //       )
  //     )
  //   ),
  // },
  // poDetailTable: {
  //   '& .MuiTableCell-root.label': {
  //     fontWeight: 400,
  //     fontSize: '15px',
  //     lineHeight: '18px',
  //     color: '#CCCCCC',
  //   },
  //   '& .MuiTableCell-root.value': {
  //     fontWeight: 400,
  //     fontSize: '15px',
  //     lineHeight: '17px',
  //     color: '#000000',
  //   },
  // },
  newInvTitlePanel: {
    '& .MuiBadge-root': {
      '& .MuiBadge-colorPrimary': {
        backgroundColor: '#5C5C5C',
        fontSize: '10px',
        width: '15px',
        height: '15px',
        minWidth: 'inherit',
        right: '-8px',
        top: '5px',
      },
    },
    '& .MuiFormControl-root': {
      marginLeft: '600px',
    },
  },
  completeBtn: {
    marginTop: '-8px',
  },
  addBtn: {
    marginRight: '226px',
  },
  addBtn1: {
    marginRight: '168px',
    marginTop: '8px',
  },
  newPOForm: {
    width: '432px',
    '& .MuiTableCell-root': {
      paddingTop: '2px',
      paddingBottom: '2px',
      '&.label': {
        padding: '1px 0px 1px 0px',
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '18px',
        color: '#CCCCCC',
      },
      '&.value': {
        padding: '1px 0px 1px 0px',
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '17px',
        color: '#000000',
      },
    },
  },
  newAddedProductPanel: {
    width: '570px',
    '& .MuiCollapse-root .MuiAccordionDetails-root .accordion-table .MuiTableCell-body': {
      '&.details': {
        // width: '113px',
      },
      '&.value': {
        minWidth: '122px !important',
      },
    },
  },
  receiveBtn: {
    padding: '5px 9px 4px 9px',
    fontSize: '13px'
  },
  newBodyPanel: {
    paddingTop: '0px',
  },
  newProductForm: {
    '& .MuiTableCell-root': {
      paddingTop: '0px',
      paddingBottom: '0px',
      '& .MuiFormControlLabel-root': {
        marginRight: '25px',
      },
    },
  },
  newUploadTitle: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '18px',
    marginTop: '20px',
    marginBottom: '15px',
  },
  newUploadFile: {
    margin: '0px',
  },
  newCheckbox1: {
    fontWeight: 700,
    color: '#000000',
    fontSize: '15px',
    lineHeight: '17px',
  },
  warningAutomatch: {
    '& .MuiAlert-message .MuiButton-root:nth-child(3)': {
      marginLeft: '14px',
      marginRight: '0px',
    },
  },
  warningAutomatchBadge: {
    display: 'block',
    textTransform: 'none',
    fontWeight: 300,
    fontSize: '10px',
    lineHeight: '11px',
    color: '#000000',
    '& .MuiBadge-badge.MuiBadge-colorPrimary': {
      backgroundColor: '#5C5C5C',
      left: '-13px',
      width: '11px',
      height: '11px',
      minWidth: 'inherit',
      fontSize: '11px',
    },
    '& p.MuiTypography-root.MuiTypography-h6': {
      marginBottom: '0px !important',
      textAlign: 'left',
    },
  },
}), { index: 1 });
