import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useStreamQueries } from "@daml/react";
import {
  Grid, Typography, Card, CardContent, Button, Chip,
  Table, TableBody, TableRow, TableCell, Paper, Badge, DialogContentText, Drawer,
} from "@material-ui/core";
import { SurgicalEvent, PurchaseOrder } from "../../services/daml-modules1";
import { formatDate } from "../../services/util";
import { damlFetchRestockInventory } from "../../services/axios/daml-json-api";
import printerIcon from '../../icons/printer.svg';
import { useDamlState } from "../../context/DamlContext";
import { useUserState } from "../../context/UserContext";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "../invoice/styles";
import closeIcon from "../../icons/new-close-icon.svg";
import PoContract1 from "../purchaseorder/poContract1";
import { useTranslation } from 'react-i18next';



/**
 * Detail View Slide Drawer For Invoice
 * @param {Boolean} state
 * @param {Function} toggleDrawer
 * @param {Object} invoice
 * @param {Object} vendortemplate
 */
export default function DetailViewDrawer({
  state, toggleDrawer, invoice = {}, vendortemplate = null, isHospital = false,
}) {
  const { t } = useTranslation();
  const [seData, setSeData] = useState(null);
  const [poData, setPoData] = useState(null);
  const [restockData, setRestockData] = useState(null);


  // TODO
  // It's better to use daml-json-api functions instead of useStreamQueries
  const { contracts } = useStreamQueries(SurgicalEvent, () => [{ seid: invoice?.invoicedata?.invseid }]);
  const { contracts: poContractDetails } = useStreamQueries(PurchaseOrder, () => [{ hospital }]);

  const classes = useStyles();
  const classes1 = useStyles1();

  const { rolePayload } = useDamlState();
  const { token } = useUserState();

  const { hospital, vendor, invoicedata, invoicedetail } = invoice;
  const { hospitaldetails, vendordetails } = (rolePayload ? rolePayload : {});


  // handle to download detail of invoice
  const handleDownload = async () => {
  };


  useEffect(() => {
    setSeData(null);
    contracts.map((instance) => {
      if (invoice?.invoicedata?.invseid === instance.payload.surgicaleventdata.seid) {
        return setSeData(instance.payload.surgicaleventdata);
      }
      return null
    })
  }, [invoice, contracts])

  useEffect(() => {
    setPoData(null);
    poContractDetails.map((instance) => {
      console.log("[instance]", instance);
      if (invoice?.invoicedata?.invpurchaseorderid === instance?.payload?.purchaseorderdata?.popurchaseorderid) {
        return setPoData(instance.payload.purchaseorderdata);
      }
      return null
    })
  }, [invoice, poContractDetails])


  const getPOAmount = () => {
    if (invoicedetail?.length > 0) {
      return invoicedetail?.reduce((total, product) => total + parseInt(product?.invdtotalcost), 0)
    }
    return 0;
  }

  useEffect(() => {
    const searchRestock = async () => {
      const restock = await damlFetchRestockInventory({
        token, hospital, vendor, popurchaseorderid: invoicedata?.invpurchaseorderid,
      });
      setRestockData(restock);
    };
    searchRestock();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice]);


  return (
    <React.Fragment key={'right'} >
      <Drawer className={classNames(classes.customRoot, classes1.customRootInvoice)}
        anchor={'right'} open={state} onClose={() => { toggleDrawer(false) }}>

        <DialogContentText component={'div'} className="modelStepDetai">

          <Grid container
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={0}
          >
            <Grid item md={8} className="new-panel">
              <Grid item md={12} className="header-section">
                <div className="view-heading">
                  <span>Invoice number:</span> <span className='detailTitle'>{invoicedata && invoicedata.invinvoicenumber}</span>

                  <Button
                    className={classes.printButton}
                    onClick={handleDownload}
                  >
                    <img src={printerIcon} alt="" />
                  </Button>
                </div>
              </Grid>

              <Grid className="left-card">

                <Card>
                  <CardContent>
                    <Grid container spacing={3}>

                      <Grid item xs={2} sm={4} md={4} className={classes.rightBorder}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell className={classNames(classes.cellWithoutBorderPadding, classes.tabletitle)}>Facility:</TableCell>
                              <TableCell className={classes.cellWithoutBorderPadding}>
                                {hospital}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.cellWithoutBorderPadding}>
                                {hospitaldetails ? hospitaldetails.physicaladdress.address1 : null}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.cellWithoutBorderPadding}>
                                {hospitaldetails ? `${hospitaldetails.physicaladdress.city}, ${hospitaldetails.physicaladdress.state} ${hospitaldetails.physicaladdress.zipcode}` : null}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>

                      <Grid item xs={2} sm={4} md={4} className={classes.rightBorder}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell className={classNames(classes.cellWithoutBorderPadding, classes.tabletitle)}>Vendor:</TableCell>
                              <TableCell className={classes.cellWithoutBorderPadding}>
                                {vendor}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.cellWithoutBorderPadding}>
                                {invoice ? invoice?.vendoraddress?.address1 : null}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.cellWithoutBorderPadding}>
                                {invoice && `${invoice?.vendoraddress?.city} ${invoice?.vendoraddress?.state} ${invoice?.vendoraddress?.zipcode}`}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.cellWithoutBorderPadding}>
                                {vendordetails ? `${vendordetails.vendoraddress.city}, ${vendordetails.vendoraddress.state} ${vendordetails.vendoraddress.zipcode}` : null}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>

                      <Grid item xs={2} sm={4} md={4}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell className={classNames(classes.cellWithoutBorderPadding, classes.tabletitle)}>Date:</TableCell>
                              <TableCell className={classes.cellWithoutBorderPadding}>
                                {formatDate(invoicedata && invoicedata.invinvoicedate)}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classNames(classes.cellWithoutBorderPadding, classes.tabletitle)}>Status:</TableCell>
                              <TableCell className={classes.cellWithoutBorderPadding}>
                                <div className="value">
                                  {invoice.status && (
                                    <Chip
                                      label={invoice.status}
                                      size="small"
                                      className={classNames(classes.smallChip, (
                                        invoice.status === 'Ready for payment' ? classes.backBlue : (
                                          invoice.status === 'Sent' ? classes.backGreen : (
                                            invoice.status === 'Complete' ? classes.backGreen : (
                                              invoice.status === 'Available for payment' ? classes.backBlue : (
                                                invoice.status === 'Cancelled' ? classes.backOrange : classes.backYellow)
                                            )
                                          )
                                        )))}
                                    />)}
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classNames(classes.cellWithoutBorderPadding, classes.tabletitle)}>Buyer:</TableCell>
                              <TableCell className={classes.cellWithoutBorderPadding}>
                                {vendortemplate && vendortemplate.api_payload.buyer}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classNames(classes.cellWithoutBorderPadding, classes.tabletitle)}>Type:</TableCell>
                              <TableCell className={classes.cellWithoutBorderPadding}>
                                Standard
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>

                    </Grid>
                  </CardContent>
                </Card>

              </Grid>

              <Grid className="left-card">

                <Card>
                  <CardContent>
                    <Grid container spacing={3}>

                      <Grid item xs={2} sm={4} md={4} className={classes.rightBorder}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell className={classNames(classes.cellWithoutBorderPadding, classes.tabletitle)}>Ship To:</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.cellWithoutBorderPadding}>
                                {hospitaldetails && hospitaldetails.shippingaddress.length ? `${hospitaldetails.shippingaddress[0].address1}` : null}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.cellWithoutBorderPadding}>
                                {hospitaldetails && hospitaldetails.shippingaddress.length ? `${hospitaldetails.shippingaddress[0].city} ${hospitaldetails.shippingaddress[0].location}` : null}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.cellWithoutBorderPadding}>
                                {hospitaldetails && hospitaldetails.shippingaddress.length ? `${hospitaldetails.shippingaddress[0].country} ${hospitaldetails.shippingaddress[0].zipcode}` : null}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>

                      <Grid item xs={2} sm={4} md={4} className={classes.rightBorder}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell className={classNames(classes.cellWithoutBorderPadding, classes.tabletitle)}>Invoice To:</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={(classes.cellWithoutBorderPadding, classes.userInputs)}>{invoice.operator}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.cellWithoutBorderPadding}>
                                {hospital}
                              </TableCell>
                            </TableRow>
                            {/* <TableRow>
                              <TableCell className={classes.cellWithoutBorderPadding}>
                                {hospitaldetails && hospitaldetails.billinfocontact.length ? `${hospitaldetails.billinfocontact[0].fax}` : null}
                              </TableCell>
                            </TableRow> */}
                            <TableRow>
                              <TableCell className={classNames(classes.cellWithoutBorderPadding, classes.tabletitle)}>Contract ID:</TableCell>
                              <TableCell className={classes.cellWithoutBorderPadding}>
                                {/* Now, blank */}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>

                      <Grid item xs={2} sm={4} md={4}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell className={classNames(classes.cellWithoutBorderPadding, classes.tabletitle)}>Terms:</TableCell>
                              <TableCell className={classNames(classes.cellWithoutBorderPadding, classes.tabletitle)}>FOB Contact:</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.cellWithoutBorderPadding}>
                                {vendortemplate && vendortemplate.api_payload.paymentTerms}
                              </TableCell>
                              <TableCell className={classes.cellWithoutBorderPadding}>
                                {vendortemplate && vendortemplate.api_payload.fOB}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.cellwithpaddings} colSpan={2}>
                                MA EXEMPT PURCH CERT #: {/* Now, blank */}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>

                    </Grid>
                  </CardContent>
                </Card>

              </Grid>

              <Grid className="left-card">

                <Card>
                  <CardContent className={classes1.paddingZero}>
                    <Grid container
                      className={classes1.gridContainer}
                      spacing={0}>
                      <Grid container className={classes1.gridBottomBorder}>
                        <Grid item md={1} sm={1} style={{minWidth: "6%"}} className={classNames(classes1.gridHead, classes1.gridSeriesWidth2)}>Line</Grid>
                        <Grid item md={1} sm={1} style={{minWidth: "17%"}} className={classes1.gridHead}>Item</Grid>
                        {/* <Grid item md={1} sm={1} className={classes1.gridHead}>Vend's Catlg</Grid> */}
                        <Grid item md={4} sm={1} style={{maxWidth: "25%"}} className={classes1.gridHead}>Description</Grid>
                        <Grid item md={1} sm={1} style={{minWidth: "10%"}} className={classNames(classes1.gridHead, classes1.mleft5)}>Manufacturer</Grid>
                        <Grid item md={1} sm={1} className={classes1.gridHead}>Qty/{t('main.uom.lable')}</Grid>
                        <Grid item md={1} sm={1} className={classes1.gridHead}>Price</Grid>
                        <Grid item md={1} sm={1} className={classes1.gridHead}>EXT Cost</Grid>
                        <Grid item md={1} sm={1} className={classes1.gridHead}>DEPT or INVEN</Grid>
                        <Grid item md={1} sm={1} className={classNames(classes1.gridHead, classes1.gridCellAlign)}>G/L Account</Grid>
                      </Grid>
                    </Grid>
                    <Grid container
                      className={classes1.gridColumn}
                    >
                      <Grid className={classes1.scrollit1} item md={12}  >
                        {
                          (invoicedetail && invoicedetail.length > 0)
                            ?
                            invoicedetail.map((invDetail, index) => (
                              <Grid container key={index} style={{ height: "50px" }}>
                                <Grid item md={1} sm={1} style={{minWidth: "6%"}} className={classNames(classes1.gridCellCenter, classes1.gridSeriesWidth1, classes1.gridSeriesWidth2)}>
                                  {index + 1}
                                </Grid>
                                <Grid item md={2} sm={1} style={{minWidth: "17%"}} className={classNames(classes1.gridCell, classes1.gridMinWidth1, classes1.hideOverflow, classes1.gridCellCenter)}>
                                  {invDetail.invdproductitem}
                                </Grid>
                                {/* <Grid item md={1} sm={1} className={classes1.gridCellCenter}>Now, blank</Grid> */}
                                <Grid item md={3} sm={1} className={classNames(classes1.gridCell, classes1.gridWidth, classes1.hideOverflow, classes1.gridCellCenter)}>
                                  {invDetail.invddescription}</Grid>
                                <Grid item md={1} sm={1} style={{minWidth: "10%"}} className={classes1.gridCellCenter}>
                                  {invDetail.invdproduct.sepproductmanufacturer}</Grid>
                                <Grid item md={1} sm={1} className={classes1.gridCellCenter}>
                                  {invDetail.invdproductquantity}</Grid>
                                <Grid item md={1} sm={1} className={classes1.gridCellCenter}>
                                  ${invDetail.invdproductprice}</Grid>
                                <Grid item md={1} sm={1} className={classes1.gridCellCenter}>
                                  ${invDetail.invdtotalcost}</Grid>
                                <Grid item md={1} sm={1} className={classes1.gridCellCenter}>
                                  NA</Grid>
                                <Grid item md={1} sm={1} className={classes1.gridCellCenter}>
                                  ${invDetail.invdtotalcost}</Grid>
                              </Grid>
                            ))
                            :
                            null
                        }
                      </Grid>
                    </Grid>
                    <Grid container className={classes1.gridTopBorder}>
                      <Grid item xs={12} sm={8} className={classes1.rightBorder}>
                        <Grid item style={{ height: "30px" }} >
                          <Typography
                            className={classes1.gridFooterDetails1}
                          >Comments :</Typography>
                        </Grid>
                        <Grid item >
                          <Paper style={{ height: "100px" }}>
                            <Typography
                              className={classes1.gridFooterComments}>
                            </Typography>
                          </Paper>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Paper className={classes1.gridFooterDetails} style={{ height: "130px", }}>
                          <span>Total :</span>
                          <span className={classes1.amountInvoice}>${invoicedata && invoicedata.invinvoicetotalamount}</span>
                        </Paper>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

            </Grid>

            <Grid item md={4} className="right-panel">
              <Button
                className={classes.closeButton}
                onClick={() => toggleDrawer(false)}
              >
                <img src={closeIcon} alt="" />
              </Button>
              <Grid item md={12} className="header-section">
                <div className="view-heading">
                  <span>Purchase Order:</span> <span className='detailTitle'>{invoicedata && invoicedata.invpurchaseorderid}</span>
                </div>
              </Grid>
              {
                isHospital
                &&
                <>
                  <div className={`grid-list grid-list-3 newgrid-list-3`}>
                    <Grid container className="list-item">
                      <Grid item xs={6}><h2 className={classes.formSubHeading}>PO Details</h2></Grid>
                      <Grid item xs={6}><h2 className={classes.formSubHeading}>Patient Details</h2></Grid>

                      <Grid container>
                        <Grid item xs={3}><div className="label">PO Type :</div></Grid>
                        <Grid item xs={3}><div className="label">Distributor :</div></Grid>
                        <Grid item xs={3} className="label">MRN :</Grid>
                        <Grid item xs={3} className="label">SE ID :</Grid>

                        <Grid item xs={3} className={classes.valuesCss}>{poData ? poData.potype : 'NA'}</Grid>
                        <Grid item xs={3} className={classes.valuesCss}>{vendor}</Grid>
                        <Grid item xs={3} className={classes.valuesCss}>{seData && seData.mrn}</Grid>
                        <Grid item xs={3} className={classes.valuesCss}>{invoicedata && invoicedata.invseid}</Grid>

                        <Grid container>
                          <Grid item xs={3} className="label">PO Date :</Grid>
                          <Grid item xs={3} className="label">OwnerShip :</Grid>
                          <Grid item xs={3} className="label">Last Name :</Grid>
                          <Grid item xs={3} className="label">First Name :</Grid>

                          <Grid item xs={3} className={classes.valuesCss}>{poData?.podate}</Grid>
                          <Grid item xs={3} className={classes.valuesCss}>{poData ? poData?.popurchaseordertype : "NA"}</Grid>
                          <Grid item xs={3} className={classes.valuesCss}> {seData?.patientlastname ? seData?.patientlastname : 'NA'} </Grid>
                          <Grid item xs={3} className={classes.valuesCss}>{seData?.patientfirstname ? seData?.patientfirstname : 'NA'}</Grid>

                          <Grid item xs={6}></Grid>
                          <Grid item xs={3} className="label">Gender :</Grid>
                          <Grid item xs={3} className="label">DOB :</Grid>

                          <Grid item xs={6}></Grid>
                          <Grid item xs={3} className={classes.valuesCss}> {seData?.patientgender ? seData?.patientgender : 'NA'}</Grid>
                          <Grid item xs={3} className={classes.valuesCss}> {seData?.patientdob ? seData?.patientdob : 'NA'}</Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </>
              }

              {(invoicedetail && invoicedetail.length > 0)
                ?
                <div> <div className={classNames(classes.productDetails, classes.displayflex)}>
                  <span className="price-label">Total Price of Products:</span> <span>${getPOAmount()}</span>
                  <h2 className={classNames(classes.formSubHeading, classes.leftPad)}>Total Added Products
                    <Badge badgeContent={invoicedata.totalitemscount} color="primary" overlap="rectangular"></Badge>
                  </h2>
                </div>
                  <PoContract1
                    allcontracts={invoicedetail}
                    invoicedata={invoicedata}
                    contractData={restockData}
                  />
                </div>
                :
                null
              }
            </Grid>
          </Grid>

        </DialogContentText>
      </Drawer>
    </React.Fragment>
  );
}
