import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  infoBar: {
    padding: '2px',
    borderBottom: '1px solid #183C73',
  },
  leftInnerContainer: {
    display: 'flex',
    padding: '10px',
  },
  onlineIcon: {
    margin: '2px 10px',
    color: '#183C73'
  },
  infoIcon:{
    color:'#183C73'
  }
}));
