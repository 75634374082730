import React, { useState } from 'react';
import classNames from "classnames";
import { Chip } from '@material-ui/core';
// import { Inventory } from "../../services/daml-modules1";
import Contracts from '../../components/Contracts/Contracts';
import requestIconAccept from '../../icons/toggle-left-accepted.svg';
import requestIconDeclined from '../../icons/toggle-left-declined.svg';
import requestIconReceived from '../../icons/toggle-left-received.svg';
import requestIconDisable from '../../icons/toggle-left-disable.svg';
import ConfirmDialog from "./confirm-dialog";
import { createReturnResponse } from "./handle";
import { getInventoryDataStatus } from "./inventorylistcontractshospital";
import { getReturnStatus } from "./accountinventoryactivecontractshospital";
import AdaptiveSearch from '../../components/Search/adaptivesearch';
import DateSearch from '../../components/Search/datesearch';
import DetailViewDrawer from "./detail-view-drawer-account";
import { useDamlState } from "../../context/DamlContext";
import useStyles from "../surgicalevent/styles";



/**
 * Account Inventory in House Contracts in Vendor
 * @param {Object} searchInv 
 * @param {Object} searchParams 
 * @param {Function} handleChangeSearchValue 
 * @param {Object} ledger 
 * @param {Array} allContracts 
 * @returns 
 */
export default function AccountInventoryActiveContractsVendor({
  searchInv = {},
  searchParams = {},
  handleChangeSearchValue = () => { },
  ledger,
  allContracts = [],
}) {

  const classes = useStyles();
  // const vendor = useParty();

  // return request
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState({});

  // Detail Viewer
  const [state, setState] = useState(false);
  const [selectedInventory, setSelectedInventory] = useState({});


  let contracts = [], searchPO = [];

  // const { contracts: contractsInv } = useStreamQueries(Inventory, () => [searchInv]);
  const contractsInv = allContracts;
  const { roleCid } = useDamlState();


  // handle Request
  const handleRequest = (inventorydata) => {
    // console.log("[AccountInventoryActiveContractsVendor] handleReturnRequest", inventorydata);
    setRequest({
      ContractId: inventorydata.ContractId,
      inventorydata,
      user: "",
      decision: 0,
      ireturnstatus: getReturnStatus(inventorydata),
      hospital: inventorydata.hospital,
    });
    setOpen(true);
  };

  // click Yes in ConfirmDialog modal
  const handleModalYes = async () => {
    // console.log("[AccountInventoryActiveContractsVendor] handleModalYes", request);

    // use inventorydata instead of purchaseordernumber
    let res = await createReturnResponse({
      ledger, 
      ContractId: request.ContractId,
      inventorydata: request.inventorydata,
      newireturnrequestdecision: request.decision,
      newireturnrequestcomment: request.message,
      newireturnrequestdecisionuser: request.user,
      roleCid,
    });
    console.log("[AccountInventoryActiveContractsVendor] handleModalYes", res);

    setOpen(false);
  };

  const toggleDrawer = (open) => {
    setState(open);
  };


  if (contractsInv) {
    contractsInv.forEach(c => {
      for (let i in c.payload.inventorydata) {
        let invData = c.payload.inventorydata[i];

        if (invData.iproductstatus === "Opens") continue;

        // search
        if ('ishipmentdate' in searchParams && !!searchParams.ishipmentdate) {
          if (invData.ishipmentdate !== searchParams.ishipmentdate) continue;
        }

        contracts.push({
          ...invData,
          index: i,
          ContractId: c.contractId,
          hospital: c.payload.hospital,
          iproductreturnstatus: getReturnStatus(invData),
        });

        // search PO
        if (!!invData.ipurchaseordernumber) {
          searchPO.push({
            hospital: c.payload.hospital,
            vendor: c.payload.vendor,
            purchaseorderdata: {
              popurchaseorderid: invData.ipurchaseordernumber,
            }
          });
        }
      }
    });
  }

  // console.log("[AccountInventoryActiveContractsVendor]", contracts);


  return (
    <>
      <Contracts
        className={classes.customRoot}
        contracts={contracts}
        columns={[
          // ["SE Id", "iseid"],
          ["Lot Code", "iproduct.seplotcode", {
            options: {
              width: 180,
            },
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload?.iproduct?.seplotcode}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'seplotcode')}
                  defaultValue={searchParams.seplotcode}
                  label="Search Lot Code"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchParams.seplotcode,
            handleClear: () => handleChangeSearchValue('', 'seplotcode'),
            type: "link",
            onClick: (contractId, data) => {
              // detail page
              console.log("[AccountInventoryActiveContractsVendor]", contractId, data);
              if (!!data && 'contract' in data) {
                setSelectedInventory(data.contract);
                toggleDrawer(true);
              }
            },
          }],
          ["PO Number", "ipurchaseordernumber", {
            options:{
              width:230,
              tooltip: true,
            },
          }],
          ["PO Date", "ipurchaseorderdate", {
            options: {
              cellClassName: classes.centerCell,
              width: 130
            },
          }],
          /* ["Manufacturer", "iproduct.sepproductmanufacturer",{
            options:{
              width:180
            }
          }], */
          /* ["Ownership", "iproduct.sepownership",{
            options:{
              width:150
            }
          }], */
          ["Price", "iproduct.sepproductprice",{
            options:{
              beforeText:'$'
            }
          }],
          /* ["Status", "iproductstatus", {
            type: "render",
            render: (param) => (<Chip 
              label={
                InventoryProductStatus[param.value]
              } 
              size="small" 
              className={classNames(classes.smallChip, (
                param.value === 'Received' ? classes.backGreen : (
                  param.value === 'Shipped' ? classes.backBlue : classes.backYellow
                )
              ))} 
            />),
            options: {
              cellClassName: classes.centerCell,
              width: 120,
            },
          }], */
          ["Product Status", "iproductstatus", {
            type: "render",
            render: (param) => {
              // console.log("[AccountInventoryActiveContractsHospital] iproductstatus", param.row);
              const status = getInventoryDataStatus([param.row.contract]);
              return (status.length ?
                status.map((s, i) => (<Chip
                  label={s}
                  key={i}
                  size="small"
                  className={classNames(classes.smallChip, (s === 'Received' ? classes.backGreen : (
                    s === 'Recall' ? classes.backPink : (
                      s === 'Expiring' ? classes.backYellow : (
                        s === 'Edited' ? classes.backLightGreen : (
                        (s === 'Back-order' || s === 'Expired') ? classes.backRed : classes.backBlue
                        )
                      )
                    )
                  )))}
                  style={{ marginLeft: 5 }}
                />))
                : <></>);
            },
            options: {
              cellClassName: classes.centerCell,
              width: 140,
            },
          }],
          ["Shipment Date", "ishipmentdate", {
            search: {
              component:
                <DateSearch
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'ishipmentdate')}
                  defaultValue={searchParams.ishipmentdate}
                  label="Shipment Date :"
                  isStatic={true}
                />
            },
            isSearched: searchParams.ishipmentdate,
            handleClear: () => handleChangeSearchValue('', 'ishipmentdate'),
            options: {
              width: 180,
              cellClassName: classes.centerCell,
            },
          }],
          /* ["Reference Number", "iproduct.sepreferencenumber",{
            options:{
              width:180
            }
          }],*/
          ["Expiration", "iproduct.sepproductexpiration", {
            search: {
              component:
                <DateSearch
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'sepproductexpiration')}
                  defaultValue={searchParams.sepproductexpiration}
                  label="Expiration Date :"
                  isStatic={true}
                />
            },
            isSearched: searchParams.sepproductexpiration,
            handleClear: () => handleChangeSearchValue('', 'sepproductexpiration'),
            options: {
              width: 180,
              cellClassName: classes.centerCell,
            },
          }],
          ["Return Status", "iproductreturnstatus", {
            type: "render",
            render: (param) => (param.value &&
              <Chip
                label={param.value}
                size="small"
                className={classNames(classes.smallChip, (param.value === 'Accepted' ? classes.backGreen : (
                  param.value === 'Declined' ? classes.backRed : classes.backYellow
                )))}
              />),
            options: {
              width: 150,
              cellClassName: classes.centerCell,
            },
          }],
          /* ["Reason", "ireturntype",{
            options:{
              width:120
            }
          }],
          ["Comment", "ireturnmessage",{
            options:{
              width:120,
              tooltip: true,
            },
          }], */
        ]}
        actions={[
          [
            "Return Request",
            [
              [["Accept/Decline", [<img alt="" src={requestIconReceived} className="toggleTree" />,
              <img alt="" src={requestIconDeclined} className="toggleTree" />,
              <img alt="" src={requestIconAccept} className="toggleTree" />,
              <img alt="" src={requestIconDisable} className="toggleTree" />],
                enableReturnRequest],
                handleRequest,
              ],
            ],
            {
              width: 140,
            },
          ],
        ]}
        options={{
          statusEmpty: (allContracts.length === 0),
          sortKey: 'id',
        }}
      />

      <ConfirmDialog
        requestType='Return'
        open={open}
        setOpen={setOpen}
        handleRequest={handleModalYes}
        request={request}
        setRequest={setRequest}
      />

      <DetailViewDrawer
        state={state}
        toggleDrawer={toggleDrawer}
        selectedInventory={selectedInventory}
      />

    </>
  );
}

/**
 * enable to return request
 * 
 * @param {Object} inventorydata 
 * @returns old version: 0, 1, 2, 3, new version: true, false
 */
export const enableReturnRequest = (inventorydata) => {
  const status = getReturnStatus(inventorydata);
  return (status === "Request Sent") ? 0 : (
    (status === "Declined") ? 1 : (
      (status === "Accepted") ? 2 : 3
    )
  );
};
