import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  filterWrapper: {
    background: '#FFFFFF',
    padding: '0px 15px 0px 15px',
  },
  filterWrapperDM: {
    background: '#1c3c74',
  },
  tabPostion:{
   zIndex: 0,
  },
}));
