import React, { useState, useCallback } from "react";
import classNames from "classnames";
import { ButtonGroup, Button } from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Contracts from "../../components/Contracts/Contracts1";
import { getValueByCol } from "../../components/Contracts/functions";
import SnackbarModal from "../../components/Modals/SnackbarModal";
import CustomTooltip from "../../components/Tooltip/CustomTooltip";
import { UOM } from "../../models/Product";
import { ReturnStatus } from "../../models/InventoryData";
import { contractFilter1 } from "../../services/daml-filters";
import { INVENTORY_DELETE_EVENT } from "../../store/actions/constants";
import { useItemDetails } from "../../hooks/useProduct";
import useEvent from "../../hooks/useEvent";
import { useDamlState } from "../../context/DamlContext";
import withLoading from "../../hoc/withLoading";
import withFilters from "../../hoc/withFilters";
import { handleCheckedContract } from '../surgicalevent/surgicaleventlistcontracts1';
import { ProductDetailTable } from "../surgicalevent/add-edit-product1";
import { getReturnStatus } from "./accountinventoryactivecontractshospital";
import ConfirmEdit from "./confirm-edit";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "../purchaseorder/styles";
import useStyles2 from "./styles";
import { useTranslation } from 'react-i18next';



/**
 * Custom Header Cell with Checkbox
 * @param {Function} handleView 
 * @param {Function} handleShip 
 * @param {Function} handleDelete 
 * @param {Function} handleAccept 
 * @param {Function} handleDecline 
 * @param {Boolean} isReturn 
 * @returns 
 */
const CustomCheckboxHeaderCell = ({
  handleView, handleShip, handleDelete, handleAccept, handleDecline, isReturn = false,
}) => {

  const classes = useStyles2();
  const { role } = useDamlState();
  const { details } = useItemDetails();

  return (
    <>
      {
        (details.length === 0)
          ?
          <Button
            className={classes.newActionCell}
            variant="contained"
            startIcon={<ArrowDropDownIcon />}
          />
          :
          <CustomTooltip
            title={(
              <ButtonGroup orientation="vertical" >
                {
                  (role === 'Hospital' && isReturn) &&
                  <Button
                    onClick={handleShip}
                  >
                    Ship
                  </Button>
                }
                {
                  role === 'Vendor' &&
                  <Button
                    onClick={handleAccept}
                  >
                    Accept
                  </Button>
                }
                {
                  (role === 'Vendor' && isReturn) &&
                  <Button
                    onClick={handleDecline}
                  >
                    Decline
                  </Button>
                }
                {/*
                ((role === 'Hospital' && !isReturn) || role === 'Vendor') &&
                <Button 
                  onClick={handleView} 
                >
                  View
                </Button>
                */}
                {
                  (role !== 'Vendor' && !isReturn) &&
                  <Button
                    onClick={handleDelete}
                  // disabled
                  >
                    Delete
                  </Button>
                }
              </ButtonGroup>
            )}
            classes={{ arrow: classes.newTooltipArroww, tooltip: classes.newAddedTooltip }}
            arrow={true}
          >
            <Button
              className={classes.newActionCell}
              variant="contained"
              startIcon={<ArrowDropDownIcon />}
            />
          </CustomTooltip>
      }
    </>
  );
};

const checkRedByTemp = (contract, tempkey, value) => {
  const tempValue = getValueByCol(contract, tempkey, { isString: true });
  // console.log("[checkRedByTemp]", contract, tempkey, value, tempValue);
  return (tempValue === undefined || value === tempValue);
};
const listPattern = (contract, obj, t) => {
  let patterns = [
    {
      key: 'sepudi', details: 'Product Details', key1: 'sepproductprice',
      tempkey: 'payload.itempproduct.iproduct.sepudi', tempkey1: 'payload.itempproduct.iproduct.sepproductprice',
    },
    {
      key: 'sepreferencenumber', key1: 'sepuom',
      tempkey: 'payload.itempproduct.iproduct.sepreferencenumber', tempkey1: 'payload.itempproduct.iproduct.sepuom',
    },
    {
      key: 'sepproductmanufacturer', key1: 'sepproducttype',
      tempkey: 'payload.itempproduct.iproduct.sepproductmanufacturer', tempkey1: 'payload.itempproduct.iproduct.sepproducttype',
    },
    {
      key: 'sepdistributor', key1: 'sepownership',
      tempkey: 'payload.itempproduct.iproduct.sepdistributor', tempkey1: 'payload.itempproduct.iproduct.sepownership',
    },
    {
      key: 'sepproductname', key1: 'sepproductside',
      tempkey: 'payload.itempproduct.iproduct.sepproductname', tempkey1: 'payload.itempproduct.iproduct.sepproductside',
    },
    {
      key: 'sepproductdescription', key1: 'iquantity', label1: `${t('main.quantityonhand.lable')}`,
      tempkey: 'payload.itempproduct.iproduct.sepproductdescription', tempkey1: 'payload.itempproduct.iquantity',
    },
    {
      key: 'sepproductexpiration', key1: 'seplocation', label1: 'Location: ',
      tempkey: 'payload.itempproduct.iproduct.sepproductexpiration', tempkey1: 'payload.itempproduct.iproduct.seplocation',
    },
    {
      key: 'ireceiveddate', label: 'Received: ', key1: 'idoh', label1: 'DOH: ',
      tempkey: 'payload.itempproduct.ireceiveddate',
    },
  ];
  patterns.forEach(p => {
    p.className = checkRedByTemp(contract, p.tempkey, obj[p.key]) ? "" : "pattern-red";
    p.className1 = checkRedByTemp(contract, p.tempkey1, obj[p.key1]) ? "" : "pattern-red";
  });
  // console.log("[EditReturnInventoryContracts] listPattern", patterns);
  return patterns;
};

/**
 * Detail Panel
 */
const getDetailPanelContent = ({ row }, t) => {
  // console.clear();
  // console.log("row t " , row , t);
  const obj = {
    ...row?.contract?.payload,
    ...row?.contract?.payload?.iproduct,
  };
  const pattern = listPattern(row?.contract, obj, t);
  return (
    <ProductDetailTable
      product={obj}
      listPattern={pattern}
      isEnableCompliance={false}
    />
  );
};

export const CellwithRedColor = ({ param, tempkey }) => {
  const classes = useStyles();
  const value = param.value;
  if (checkRedByTemp(param.row.contract, tempkey, value)) return (<>{value}</>);
  return (<span className={classes.tableCellRed}>{value}</span>)
};

/**
 * Edits / Returns Inventory Contracts
 * @param {Object} searchParams 
 * @param {Array} allContracts 
 * @param {Object} options 
 * @returns 
 */
function EditReturnInventoryContracts({
  searchParams = {},
  allContracts = [],
  options = {},
}) {

  options = {
    isCheck: true,
    isReturn: false,
    ...options,
  };

  const classes = useStyles();
  const classes1 = useStyles1();

  const { t } = useTranslation();
  allContracts = allContracts.map((c, i) => ({
    id: i,
    payload: {
      ...(options.isReturn ? c : c._1),
      itempproduct: c._2,
      index: i,
      iproductreturnstatus: options.isReturn ? getReturnStatus(c) : c._1.itempstatus,  // return status or edit status
    },
  }));
  let contracts = contractFilter1(allContracts, searchParams);

  // checked Inventory
  const { details, setDetails } = useItemDetails();
  const { onEventStart } = useEvent();

  console.log("[EditReturnInventoryContracts]", searchParams, contracts);


  // Confirm Edits Modal
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  // Delete Product Toast
  const [openToast, setOpenToast] = useState(false);


  // handle to check rows
  const handleChecked = (e) => {
    handleCheckedContract(e, contracts, setDetails, 'id');
  };

  // handle to click "View"
  const handleView = useCallback(() => {
    if (details.length === 0) return;
  }, [details]);

  // handle to click "Ship"
  const handleShip = useCallback(() => {
    if (details.length === 0) return;
  }, [details]);

  // handle to click "Delete"
  const handleDeleteClick = useCallback(() => {
    if (details.length === 0) return;
    setOpenToast(true);
  }, [details]);

  // handle to delete items
  const handleDelete = useCallback(async () => {
    if (details.length === 0) return;
    // console.log("[EditReturnInventoryContracts] handleDelete", details);
    onEventStart(INVENTORY_DELETE_EVENT);
  }, [details, onEventStart]);

  // handle to click "Accept"
  const handleAccept = useCallback(() => {
    if (details.length === 0) return;
    setOpenConfirmModal(true);
  }, [details]);

  // handle to click "Decline"
  const handleDecline = useCallback(() => {
    if (details.length === 0) return;
    // disable to open confirm modal
    // setOpenConfirmModal(true);
    // Instead, use Inventory Delete event
    onEventStart(INVENTORY_DELETE_EVENT);
  }, [details, onEventStart]);


  return (
    <>
      <Contracts
        contracts={contracts}
        columns={[
          [`${t('main.lotcode.label')}`, "payload.iproduct.seplotcode", {
            type: (options.isReturn ? undefined : "render"),
            render: (param) => <CellwithRedColor param={param} tempkey='payload.itempproduct.iproduct.seplotcode' />,
            search: {
              type: "AdaptiveSearch",
              name: "iproduct.seplotcode",
              getValue: (c) => c.payload.iproduct.seplotcode,
              contracts: allContracts,
            },
            options: {
              flex: 0.9,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            }
          }],
          ["Expiration Date", "payload.iproduct.sepproductexpiration", {
            type: (options.isReturn ? undefined : "render"),
            render: (param) => <CellwithRedColor param={param} tempkey='payload.itempproduct.iproduct.sepproductexpiration' />,
            search: {
              type: "DateSearch",
              name: "iproduct.sepproductexpiration",
            },
            options: {
              flex: 1.1,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            },
          }],
          ["PO Number", "payload.ipurchaseordernumber", {
            type: (options.isReturn ? undefined : "render"),
            render: (param) => <CellwithRedColor param={param} tempkey='payload.itempproduct.ipurchaseordernumber' />,
            search: {
              type: "AdaptiveSearch",
              name: "ipurchaseordernumber",
              getValue: (c) => c.payload.ipurchaseordernumber,
              contracts: allContracts,
            },
            options: {
              flex: 1,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            },
          }],
          ["Price", "payload.iproduct.sepproductprice", {
            type: (options.isReturn ? undefined : "render"),
            render: (param) => <CellwithRedColor param={param} tempkey='payload.itempproduct.iproduct.sepproductprice' />,
            search: {
              type: "AdaptiveSearch",
              name: "iproduct.sepproductprice",
              getValue: (c) => c.payload.iproduct.sepproductprice,
              contracts: allContracts,
            },
            options: {
              beforeText: '$',
              flex: 0.7,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            }
          }],
          [`${t('main.uom.lable')}`, "payload.iproduct.sepuom", {
            type: (options.isReturn ? undefined : "render"),
            render: (param) => <CellwithRedColor param={param} tempkey='payload.itempproduct.iproduct.sepuom' />,
            search: {
              type: "SelectSearch",
              name: "iproduct.sepuom",
              options: Object.keys(UOM).map(value => ({ label: UOM[value], value: value })),
            },
            options: {
              flex: 0.6,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            }
          }],
          ["PO Date", "payload.ipurchaseorderdate", {
            type: (options.isReturn ? undefined : "render"),
            render: (param) => <CellwithRedColor param={param} tempkey='payload.itempproduct.ipurchaseorderdate' />,
            search: {
              type: "DateSearch",
              name: "ipurchaseorderdate",
            },
            options: {
              flex: 1.1,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            },
          }],
          ["Manufacturer", "payload.iproduct.sepproductmanufacturer", {
            type: (options.isReturn ? undefined : "render"),
            render: (param) => <CellwithRedColor param={param} tempkey='payload.itempproduct.iproduct.sepproductmanufacturer' />,
            search: {
              type: "AdaptiveSearch",
              name: "iproduct.sepproductmanufacturer",
              getValue: (c) => c.payload.iproduct.sepproductmanufacturer,
              contracts: allContracts,
            },
            options: {
              flex: 0.9,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            },
          }],
          ["Received Date", "payload.ireceiveddate", {
            type: (options.isReturn ? undefined : "render"),
            render: (param) => <CellwithRedColor param={param} tempkey='payload.itempproduct.ireceiveddate' />,
            search: {
              type: "DateSearch",
              name: "ireceiveddate",
            },
            options: {
              flex: 1.1,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            },
          }],
          ["Status", "payload.iproductreturnstatus", {
            search: {
              type: "SelectSearch",
              name: "iproductreturnstatus",
              options: ReturnStatus,
            },
            options: {
              flex: 1.3,
              headerAlign: 'center',
              align: 'center',
              headerClassName: classes1.customText,
            },
          }],
        ]}

        options={{
          sortKey: 'id',
          checkbox: options.isCheck,
          height: '450px',
          headerHeight: 38,
          rowHeight: 38,
          renderCheckboxHeaderCell: () => (
            <CustomCheckboxHeaderCell
              handleView={handleView}
              handleShip={handleShip}
              handleDelete={handleDeleteClick}
              handleAccept={handleAccept}
              handleDecline={handleDecline}
              isReturn={options.isReturn}
            />
          ),
          checkboxCellWidth: 78,
          getDetailPanelContent: ({ row }) => getDetailPanelContent({ row }, t),
        }}

        handleChecked={handleChecked}
      />

      <ConfirmEdit
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
        isReturn={options.isReturn}
      />

      <SnackbarModal
        open={openToast}
        setOpen={setOpenToast}
        type="warning"
        title=""
        content={t('warnings.inventory.returninventory.message')}
        vertical="center"
        horizontal="center"
        width={345}
        height={150}
        transition="fade"
        className={classes.toastLarge}
        options={{
          validateULE: true,
        }}
        actions={[
          {
            label: 'Yes',
            handle: handleDelete,
          },
          {
            label: 'No',
          }
        ]}
      />
    </>
  );
}

const EditReturnInventoryContractsWithLoading = withLoading(EditReturnInventoryContracts);
export default withFilters(EditReturnInventoryContractsWithLoading)([
  { name: "iproduct.seplotcode", label: "Lot/SN" },
  { name: "iproduct.sepproductexpiration", label: "Expiration Date" },
  { name: "ipurchaseordernumber", label: "PO Number" },
  { name: "iproduct.sepproductprice", label: "Price" },
  { name: "iproduct.sepuom", label: "UoM" },
  { name: "ireceiveddate", label: "Received Date" },
  { name: "ipurchaseorderdate", label: "PO Date" },
  { name: "iproduct.sepproductmanufacturer", label: "Manufacturer" },
  { name: "iproductreturnstatus", label: "Status" },
], true);