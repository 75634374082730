import React from "react";
// import { Relationship } from "../../services/daml-modules1";
import Contracts from "../../components/Contracts/Contracts";
import AdaptiveSearch from "../../components/Search/adaptivesearch";
import withLoading from "../../hoc/withLoading";
import { contractFilter } from "../../services/daml-filters";
import useStyles from "../surgicalevent/styles";


function HospitalAcceptContracts({
  searchParams = {},
  handleChangeSearchValue = null,
  allContracts = [],
  searchHospitalAccept = {},
}) {


  const classes = useStyles();
  // const { contracts } = useStreamQueries(Relationship, () => [searchParams]);
  let contracts = contractFilter(allContracts, searchParams);

  console.log("[HospitalAcceptContracts]", searchParams, contracts);


  return (
    <Contracts
      className={classes.customRoot}
      contracts={contracts}
      columns={[
        // ["ContractId", "contractId"],
        ["Hospital Name", "payload.hospital", {
          options: {
            width: 180,
          },
          search: {
            component:
              <AdaptiveSearch
                getValue={(c) => c.payload.vendor}
                handleChangeSearch={(v) => handleChangeSearchValue(v, 'hospital')}
                defaultValue={searchHospitalAccept.hospital}
                label="Search By Hospital Name"
                contracts={allContracts}
                isShrink={true}
              />
          },
          isSearched: searchHospitalAccept.hospital,
          handleClear: () => handleChangeSearchValue('', 'hospital'),
        }],
        ["Offered at", "payload.offered_at"],
        ["Accepted at", "payload.created_at"],

      ]}

      options={{
        statusEmpty: (allContracts.length === 0)
      }}
    />
  );
}

export default withLoading(HospitalAcceptContracts);
