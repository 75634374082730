import { validateRole } from "./Role";

export const OnboardNetworkModel = {
  name: '',
  email: '',
  company: '',
  companytype: '',
  approved: false,
};

export const validateOnboardNetwork = (network) => {
  return ((network.name !== ""
  || network.email !== ""
  || network.company !== "")
  && validateRole(network.companytype));
};

export const getOnboardNetwork = (network) => {
  let newNetwork = {};
  newNetwork.name = network.name || "";
  newNetwork.email = network.email || "";
  newNetwork.company = network.company || "";
  newNetwork.companytype = network.companytype;
  if (typeof network.approved === "boolean") newNetwork.approved = network.approved;
  return newNetwork;
};