import React, { useState, useEffect } from "react";
import { useLedger, useParty, useStreamQueries } from "@daml/react";
import { Grid, Typography, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { PurchaseOrder } from "../../services/daml-modules1";
import NewPurchaseOrderListContracts from "./newpurchaseorderlistcontracts";
import { dispatch, getState } from "../../store";
import { purchaseOrderSearch } from "../../store/actions/generalActions";
import CreatePurchaseOrder from "./create-purchaseorder";
import { SurgicalEventModel } from "../../models/SurgicalEvent";
import { PoModel } from "../../models/Po";
import { createUpdatePO } from './purchaseorderlist';
import AddIcon from '@material-ui/icons/Add';
import { useDamlState } from "../../context/DamlContext";
import { removePODetailData, updatePODetailData } from './handle';
import useStyles from "./styles";


/**
 * New Purchase Orders page in Hospital
 * If accesslevel is HInventoryManager, then NO PO Creation manually.
 * @returns 
 */
export default function NewPurchaseOrderList() {

  const classes = useStyles();

  const ledger = useLedger();
  const hospital = useParty();
  const { contracts } = useStreamQueries(PurchaseOrder, () => [{ hospital, purchaseorderdata: { poseid: null } }]);
  
  const { role, party, userParty, roleCid, userlistPayload } = useDamlState();

  const [isHistory, setHistory] = useState(false);
  const [ContractId, setContractId] = useState('');
  const [payload, setPayload] = useState({});
  const [searchParams, setSearchParams] = useState({});
  const [loading, setLoading] = useState(true);
  // CreatePurchaseOrder
  const [isCreate, setCreate] = useState(true);
  const [purchaseOrderData, setPurchaseOrderData] = useState({});
  const [purchaseOrderDetailData, setPurchaseOrderDetailData] = useState([]);
  const [surgicalEventData, setSurgicalEventData] = useState({});
  const [openModal, setOpenModal] = useState(false);


  // handler to Open Create Purchase Order
  const handleOpenCreatePO = ({
    poData = {}, poDetail = [], seData = {}, isCreate = true, contractId = '', payload = {},
  }) => {
    setPurchaseOrderData({ ...PoModel, ...poData });
    setPurchaseOrderDetailData(poDetail);
    setSurgicalEventData({ ...SurgicalEventModel, ...seData });
    setCreate(isCreate);
    setContractId(contractId);
    setPayload(payload);
    // open create PO modal
    setOpenModal(true);
  };

  // Click "Create Purchase Order" in PO list page
  const handleCreatePO = () => {
    handleOpenCreatePO({});
  };

  // Click "Create" or "Save Update" in PO modal
  const addProductItem = async (isEnable=true) => {
    await createUpdatePO({
      ledger, ContractId, contracts, hospital, roleCid, isCreate, userlistPayload,
      purchaseOrderData, purchaseOrderDetailData, surgicalEventData, setOpenModal, isEnable,
      user: (userParty ? userParty : party), role, payload,
    });
  };

  // Click "Add More Product" in PO modal
  const handleAddMoreProduct = async (detail) => {
    // add PurchaseOrderDetaildata into PO
    console.log("[NewPurchaseOrderList] handleAddMoreProduct", detail, isCreate, ContractId);
    // DO Nothing
  };

  // Click Delete Icon in PO modal to remove PO Detail
  const handleDeleteProduct = async (podetail, podetailindex) => {
    return await removePODetailData({
      ledger, 
      contractId: ContractId, 
      setContractId,
      podetail,
      roleCid,
      podetailindex,
      user: (userParty ? userParty : party), 
      role, 
      payload,
    });
  };

  // Click "Update" in PO modal to update PO Detail
  const handleUpdateProduct = async (podetail, podetailindex) => {
    return await updatePODetailData({
      ledger, 
      contractId: ContractId, 
      setContractId,
      podetail,
      roleCid,
      podetailindex,
      user: (userParty ? userParty : party), 
      role, 
      payload,
    });
  };

  const createOptions = {
    handleOpenCreatePO,
    addProductItem,
    handleAddMoreProduct,
    roleCid: roleCid,
  };


  const [searchPO, setSearchPO] = useState({
    popurchaseorderid: '',
    podate: '',
    postatus: '',
    povendorid: '',
    ...getState().purchase,
  });
  const handleChangeSearchValue = (val, key) => {
    setSearchPO({ ...searchPO, [key]: val });
    dispatch(purchaseOrderSearch({ key, value: val }));
  };


  useEffect(() => {
    setLoading(true);

    let temp = {};

    temp.hospital = hospital;
    temp.purchaseorderdata = { poseid: null }; // only created without poseid
    if (searchPO.popurchaseorderid !== "") temp.purchaseorderdata = {
      ...temp.purchaseorderdata, popurchaseorderid: searchPO.popurchaseorderid
    };
    if (searchPO.podate !== "") temp.purchaseorderdata = {
      ...temp.purchaseorderdata, podate: searchPO.podate
    };
    if (searchPO.postatus !== "") temp.purchaseorderdata = {
      ...temp.purchaseorderdata, postatus: searchPO.postatus
    };
    if (searchPO.povendorid !== "") temp.purchaseorderdata = {
      ...temp.purchaseorderdata, povendorid: searchPO.povendorid
    };

    const delay = 0;
    if (delay > 0) setTimeout(() => {
      setSearchParams(temp);
    }, delay);
    else {
      setSearchParams(temp);
    }
  }, [searchPO, hospital]);

  useEffect(() => {
    setLoading(false);
  }, [searchParams]);


  return (
    <>

      <Grid>

        <Typography variant="h4" className={classes.pageTitle}>New Purchase Orders</Typography>
        
        <Button
          size="small"
          className={classes.newSetupButton}
          variant="contained"
          onClick={handleCreatePO}
        >
          <span className="desktop">Create PO</span>
          <span className="mobile"><AddIcon /></span>
        </Button>
        <FormControlLabel
          className={classes.newCheckbox}
          control={
            <Checkbox
              color="primary"
              checked={isHistory}
              onChange={(e) => setHistory(e.target.checked)}
            />
          }
          label="Include Archive Contracts"
        />

        <NewPurchaseOrderListContracts
          searchParams={searchParams}
          handleChangeSearchValue={handleChangeSearchValue}
          searchPO={searchPO}
          allContracts={contracts}
          createOptions={createOptions}
          isIncludeHistory={isHistory}
          loading={loading}
        />

      </Grid>


      <CreatePurchaseOrder
        isCreate={isCreate}
        openModal={openModal}
        setOpenModal={setOpenModal}
        addProductItem={addProductItem}
        handleAddMoreProduct={handleAddMoreProduct}
        surgicalEventData={surgicalEventData}
        purchaseOrderData={purchaseOrderData}
        setPurchaseOrderData={setPurchaseOrderData}
        purchaseOrderDetailData={purchaseOrderDetailData}
        setPurchaseOrderDetailData={setPurchaseOrderDetailData}
        handleDeleteProduct={handleDeleteProduct}
        handleUpdateProduct={handleUpdateProduct}
      />

    </>
  );
}
