import React from "react";
// import { VendorTemplate } from "../../services/daml-modules1";
import Contracts from "../../components/Contracts/Contracts";
import AdaptiveSearch from "../../components/Search/adaptivesearch";
import { contractFilter } from "../../services/daml-filters";
import editIcon from "../../icons/edit.svg";
import useStyles from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";


/**
 * Vendor Template Contracts in Hospital
 * @param {Object} searchParams 
 * @param {Function} handleChangeSearchValue 
 * @param {Array} allContracts 
 * @param {Object} searchVendorTemplate 
 * @param {Function} handleRequest 
 * @returns 
 */
function VendorTemplateContracts({
  searchParams = {},
  handleChangeSearchValue = null,
  allContracts = [],
  searchVendorTemplate = {},
  handleRequest,
}) {

  const classes = useStyles();
  // const { contracts } = useStreamQueries(VendorTemplate, () => [searchParams]);
  let contracts = contractFilter(allContracts, searchParams);

  console.log("[VendorTemplateContracts]", searchParams, contracts);


  return (
    <Contracts
      className={classes.customRoot}
      contracts={contracts}
      columns={[
        // ["ContractId", "contractId"],
        ["Vendor Name", "payload.vendor", {
          options: {
            flex: 1,
          },
          search: {
            component:
              <AdaptiveSearch
                getValue={(c) => c.payload.vendor}
                handleChangeSearch={(v) => handleChangeSearchValue(v, 'vendor')}
                defaultValue={searchVendorTemplate.vendor}
                label="Search By Vendor Name"
                contracts={allContracts}
                isShrink={true}
              />
          },
          isSearched: searchVendorTemplate.vendor,
          handleClear: () => handleChangeSearchValue('', 'vendor'),
        }],
        ["Id", "payload.api_index1._id", {
          options: {
            flex: 1,
          }
        }],
        ["API Index", "payload.api_index1._index", {
          options: {
            flex: 1,
          }
        }],
        ["Supplier", "payload.api_payload.supplier", {
          options: {
            flex: 1,
          }
        }],
        ["Supplier Id", "payload.api_payload.referenceId", {
          options: {
            flex: 1,
          }
        }],
      ]}

      actions={[
        [
          "Actions",
          [
            [["Edit", [<img alt="" src={editIcon} />]],
              handleRequest],
          ],
        ]
      ]}

      options={{
        statusEmpty: (allContracts.length === 0)
      }}
    />
  );
}

export default withLoading(VendorTemplateContracts);
