import React, { useState, useEffect } from "react";
import { Button, Dialog, TextField } from "@material-ui/core";
import useStyles from "../surgicalevent/styles";

export default function AddEditVendorInvite ({
  isCreate, 
  open,
  setOpen,
  handleRequest,
  title="",
  editTitle = "",
}) {

  const classes = useStyles();
  const [vendor, setVendor] = useState("");

  useEffect(() => {
    if (open) {
      console.log("[AddEditVendorInvite] Open AddEditVendorInvite dialog.");
      setVendor("");
    }
  }, [open]);


  return (
    <Dialog
        className={classes.customRoot}
        open={open}
        onClose={()=>{setOpen(false)}}
        aria-labelledby="draggable-dialog-title"
      >
      <div className="formsteps">
        <h1  className={classes.formHeading}>
          {isCreate ? 
            title : 
            editTitle}
        </h1>
        
        <div className={classes.AddEditVendorForm}>
          <br/>

          <div className={classes.marginBM30}>
            <TextField
              id="input-with-icon-adornment1"
              label="Vendor Invitation"
              placeholder=""
              className={classes.customFullWidth}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              value={vendor}
              onChange={(e) => {
                setVendor(e.target.value);
              }}
              variant="outlined"
            />
          </div>
        </div>  
      </div> 
      <div className="form-footer">
        <div className="right-buttons">
          <Button autoFocus onClick={() => setOpen(false)} className="noBtn" variant="outlined"  color="primary">
            Cancel
          </Button>
          <Button onClick={async () => {
            await handleRequest(vendor);
            setOpen(false);
          }} className="yesBtn" variant="contained" color="primary">
            {isCreate ? "Create" : "Update"}
          </Button>
        </div>
      </div>  
    </Dialog>
  );
}
           