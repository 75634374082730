import React, { useState, useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { useLedger, useParty, useStreamQueries } from "@daml/react";
import { Grid, Button, Typography } from '@material-ui/core';
import { HowToReg } from '@material-ui/icons';
import { OnboardNetwork } from "../../services/daml-modules1";
import { getOperatorParty } from "../../services/axios/daml-json-api";
import { DefaultOperatorParty } from "../../config";
import { getTodayString } from "../../services/util";
import { OnboardNetworkModel, validateOnboardNetwork } from "../../models/OnboardNetwork";
import OnboardNetworkContents from "../../components/Modals/OnboardNetworkModal/contents";
import useStyles from "./styles";


export default function InviteParty() {

  const party = useParty();
  const ledger = useLedger();
  const assets = useStreamQueries(OnboardNetwork, () => [{ party }]);
  console.log("[InviteParty]", assets, party);

  var classes = useStyles();
  const history = useHistory();

  const [operator, setOperator] = useState(null);
  const [isOperator, setIsOperator] = useState(false);
  const [redirectPath, setRedirect] = useState(null);
  const [networkdetail, setNetworkDetail] = useState({ ...OnboardNetworkModel, name: party });


  const nextPath = (role) => {
    return (role === "Operator") ? "/app/onboardnetwork" :
      ((role === "Vendor" || role === "Hospital") ? "/app/onboarding" : null);
  };

  const approveOnboardNetwork = async () => {
    console.log("[approveOnboardNetwork]", networkdetail, operator, party);

    if (validateOnboardNetwork(networkdetail)) {
      let res = await ledger.create(OnboardNetwork, {
        party,
        operator,
        name: networkdetail.name,
        email: networkdetail.email,
        company: networkdetail.company,
        companytype: networkdetail.companytype,
        created_at: getTodayString(true),
      });

      console.log("[approveOnboardNetwork]", res);

      if (res.contractId) {
        // localStorage.setItem(damlRoleKey, networkdetail.companytype);
        if (nextPath(networkdetail.companytype)) {
          history.push(nextPath(networkdetail.companytype));
          window.location.reload();
        }
      }
    }
  };


  useEffect(() => {
    setOperator(getOperatorParty());

    if (assets.contracts && assets.contracts.length > 0) {
      const role = assets.contracts[0].payload.companytype;
      if (nextPath(role)) {
        setRedirect(nextPath(role));
        history.push(nextPath(role));
        window.location.reload();
      }
    }
    else {
      if (party === DefaultOperatorParty) {
        setIsOperator(true);
        setNetworkDetail({ ...OnboardNetworkModel, companytype: 'Operator' });
      }
    }
  }, [assets, history, party]);


  return (
    <>
      {
        assets.contracts && assets.contracts.length > 0
          ?
          (
            redirectPath
              ?
              <Redirect
                to={{
                  pathname: redirectPath,
                }}
              />
              :
              <Typography variant="h3">The same party already exists.</Typography>
          )
          :
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.grid}
          >
            <Grid item className={classes.invitePart}>
              <OnboardNetworkContents
                networkdetail={networkdetail}
                setConductForm={setNetworkDetail}
                isTypeEditable={!isOperator}
                isOperatorEnable={isOperator}
              />
            </Grid>
            <Button
              color="primary"
              variant="contained"
              edge="end"
              endIcon={<HowToReg />}
              onClick={approveOnboardNetwork}
            >
              Approve
            </Button>
          </Grid>
      }
    </>
  );
};
