import React, { useState, Fragment } from "react";
import classNames from "classnames";
import {
  Grid, Accordion, AccordionDetails, AccordionSummary, Typography, ListItemSecondaryAction,
  Table, TableBody, TableRow, TableCell, List, ListItem, ListItemText
} from '@material-ui/core';
import CustomTooltip from "../../components/Tooltip/CustomTooltip";
import { ProductTypes, Sides, WasteTypes } from "../../models/Product";
import { Ownerships } from "../../models/Ownership";
import patientIcon from "../../icons/new-se-patient-icon.svg";
import detailIcon from "../../icons/new-se-detail-icon.svg";
import physicianIcon from "../../icons/new-se-physician-icon.svg";
import { formatDate } from "../../services/util";
import { useSurgicalEvent } from "../../hooks/useProduct";
import useStyles from "../surgicalevent/styles";


/**
 * Overview Surgical Event
 * @param {String} type normal|narrow|twocolumns
 * @param {Object} className 
 * @param {Object} seData 
 * @returns 
 */
export const OverviewSurgicalEvent = ({
  type = "normal", className, seData,
}) => {

  const classes = useStyles();
  const classNameList = [classes.overview];
  if (className) classNameList.push(className);

  const { surgicalevent: surgicalEventData } = useSurgicalEvent();


  return (
    <Grid
      className={classNames(classNameList)}
    >
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="details" colSpan={6}>
              {type !== 'twocolumns' && <img src={patientIcon} alt="" />}
              <Typography variant="inherit">Patient Details</Typography>
            </TableCell>
            {
              type === 'normal' && <TableCell style={{ width: '200px' }}></TableCell>
            }
          </TableRow>
          {
            type === 'narrow'
              ?
              <>
                <TableRow>
                  <TableCell className="label">MRN :</TableCell>
                  <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.mrn}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">Last Name :</TableCell>
                  <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.patientlastname}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">First Name :</TableCell>
                  <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.patientfirstname}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">Gender :</TableCell>
                  <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.patientgender}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">DOB :</TableCell>
                  <TableCell className="value">
                    {surgicalEventData.patientdob && formatDate(seData?.value?.seData?.surgicaleventdata.patientdob)}
                  </TableCell>
                </TableRow>
              </>
              :
              (
                type === 'twocolumns'
                  ?
                  <>
                    <TableRow>
                      <TableCell className="label">MRN :</TableCell>
                      <TableCell className="label">SE ID :</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.mrn}</TableCell>
                      <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.seid}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="label">Last Name :</TableCell>
                      <TableCell className="label">First Name :</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.patientlastname}</TableCell>
                      <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.patientfirstname}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="label">Gender :</TableCell>
                      <TableCell className="label">DOB :</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.patientgender}</TableCell>
                      <TableCell className="value">
                        {surgicalEventData.patientdob && formatDate(seData?.value?.seData?.surgicaleventdata.patientdob)}
                      </TableCell>
                    </TableRow>
                  </>
                  :
                  <>
                    <TableRow>
                      <TableCell className="label">MRN :</TableCell>
                      <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.mrn}</TableCell>
                      <TableCell className="label">SE ID :</TableCell>
                      <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.seid}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="label">Last Name :</TableCell>
                      <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.patientlastname}</TableCell>
                      <TableCell className="label">First Name :</TableCell>
                      <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.patientfirstname}</TableCell>
                    </TableRow>
                    <TableRow className="border-row">
                      <TableCell className="label">Gender :</TableCell>
                      <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.patientgender}</TableCell>
                      <TableCell className="label">DOB :</TableCell>
                      <TableCell className="value">
                        {seData?.value?.seData?.surgicaleventdata.patientdob && formatDate(surgicalEventData.patientdob)}
                      </TableCell>
                    </TableRow>
                  </>
              )
          }

          <TableRow>
            <TableCell className="details" colSpan={6}>
              {type !== 'twocolumns' && <img src={detailIcon} alt="" />}
              <Typography variant="inherit">Event Details</Typography>
            </TableCell>
          </TableRow>
          {
            type === 'narrow'
              ?
              <>
                <TableRow>
                  <TableCell className="label">Event Date :</TableCell>
                  <TableCell className="value">
                    {seData?.value?.seData?.surgicaleventdata.eventdate && formatDate(seData?.value?.seData?.surgicaleventdata.eventdate)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">Procedure :</TableCell>
                  <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.surgicalprocedure}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">Side :</TableCell>
                  <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.surgicalside}</TableCell>
                </TableRow>
              </>
              :
              (
                type === 'twocolumns'
                  ?
                  <>
                    <TableRow>
                      <TableCell className="label">Event Date :</TableCell>
                      <TableCell className="label">Side :</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="value">
                        {seData?.value?.seData?.surgicaleventdata.eventdate && formatDate(surgicalEventData.eventdate)}
                      </TableCell>
                      <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.surgicalside}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="label">Procedure :</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.surgicalprocedure}</TableCell>
                    </TableRow>
                  </>
                  :
                  <>
                    <TableRow className="border-row">
                      <TableCell className="label">Event Date :</TableCell>
                      <TableCell className="value">
                        {seData?.value?.seData?.surgicaleventdata.eventdate && formatDate(seData?.value?.seData?.surgicaleventdata.eventdate)}
                      </TableCell>
                      <TableCell className="label">Procedure :</TableCell>
                      <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.surgicalprocedure}</TableCell>
                      <TableCell className="label">Side :</TableCell>
                      <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.surgicalside}</TableCell>
                    </TableRow>
                  </>
              )
          }

          <TableRow>
            <TableCell className="details" colSpan={6}>
              {type !== 'twocolumns' && <img src={physicianIcon} alt="" />}
              <Typography variant="inherit">Physician Details</Typography>
            </TableCell>
          </TableRow>
          {
            type === 'narrow'
              ?
              <>
                <TableRow>
                  <TableCell className="label">Salutation :</TableCell>
                  <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.physiciansalutation}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">Last Name :</TableCell>
                  <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.physicianlastname}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">First Name :</TableCell>
                  <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.physicianfirstname}</TableCell>
                </TableRow>
              </>
              :
              (
                type === 'twocolumns'
                  ?
                  <>
                    <TableRow>
                      <TableCell className="label">Salutation :</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.physiciansalutation}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="label">Last Name :</TableCell>
                      <TableCell className="label">First Name :</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.physicianlastname}</TableCell>
                      <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.physicianfirstname}</TableCell>
                    </TableRow>
                  </>
                  :
                  <>
                    <TableRow>
                      <TableCell className="label">Salutation :</TableCell>
                      <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.physiciansalutation}</TableCell>
                      <TableCell className="label">Last Name :</TableCell>
                      <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.physicianlastname}</TableCell>
                      <TableCell className="label">First Name :</TableCell>
                      <TableCell className="value">{seData?.value?.seData?.surgicaleventdata.physicianfirstname}</TableCell>
                    </TableRow>
                  </>
              )
          }
        </TableBody>
      </Table>
    </Grid>
  );
};

/**
 * Added Product List Header Component
 * @param {Array} productItemsList 
 * @returns 
 */
const AddedProductHeader = ({
  productItemsList = [],
}) => {

  const classes = useStyles();
  const getTotalPrice1 = (products = []) => {
    return products.reduce((total, obj) => parseFloat(obj.sepproductprice) + total, 0);
  };

  // count each Product Type
  const productTypes = productItemsList.reduce((list, p) => {
    if (p.sepproducttype in list) list[p.sepproducttype]++;
    else list[p.sepproducttype] = 1;
    return list;
  }, {});

  return (
    <>
      <Typography className="product-title">
        Added Products
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="product-subtitle"
      >
        <Grid item>
          <Typography variant="inherit">Product total:{' '}</Typography>
          <CustomTooltip
            title={(
              <List>
                {
                  Object.entries(productTypes).map(([k, v], i) => (
                    <ListItem key={i} >
                      <ListItemText primary={ProductTypes[k]} />
                      <ListItemSecondaryAction>{v}</ListItemSecondaryAction>
                    </ListItem>
                  ))
                }
              </List>
            )}
            arrow
            // open={true}
            classes={{ tooltip: classes.productTooltip }}
          >
            <Typography variant="inherit">{productItemsList.length}</Typography>
          </CustomTooltip>
        </Grid>
        <Grid item>
          <Typography variant="inherit">Total Price of Products:{' '}</Typography>
          <Typography variant="inherit">${getTotalPrice1(productItemsList)}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export const defaultListPattern = [
  {
    key: 'sepudi', key1: 'sepproductwaste', details: 'Product Details',
  },
  {
    key: 'sepreferencenumber', key1: 'sepownership',
  },
  {
    key: 'sepproductmanufacturer', key1: 'sepproductside',
  },
  {
    key: 'sepdistributor', key1: 'sepproductprice',
  },
  {
    key: 'sepproductname', key1: 'sepuom',
  },
  {
    key: 'sepproductdescription', key1: 'podquantity', label1: 'Quantity :',
  },
  {
    key: 'seplotcode',
  },
  {
    key: 'sepproductexpiration',
  },
  {
    key: 'sepproducttype'
  },
 
];

// check to different between sepproductprice & sepproductesprice
const checkChangedPrice = (p) => (!!p.sepproductesprice && p.sepproductprice !== p.sepproductesprice);

const getShowProductComp = ({
  key, label, value, valueClassName = "value", label1, value1, valueClassName1 = "value", details, detailIsCol = true,
}) => {
  if (!key) return null;
  if (detailIsCol) return (
    <Fragment key={key}>
       <TableRow>
        <TableCell className="details left" colSpan={4}>{details}</TableCell>
      </TableRow>
    <TableRow >
      <TableCell className="label">{label}</TableCell>
      <TableCell className={valueClassName}>
        {value}
      </TableCell>
      {
        !!label1 &&
        <TableCell className="label">{label1}
        </TableCell>
      }
      {
        !!value1 &&
        <TableCell className={valueClassName1}>
          {value1}
        </TableCell>
      }
    </TableRow>
    </Fragment>
  );
  else return (
    <Fragment key={key}>
      <TableRow>
        <TableCell className="details left" colSpan={4}>{details}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="label">{label}</TableCell>
        <TableCell className={valueClassName}>
          {value}
        </TableCell>
        {
          !!label1 &&
          <TableCell className="label">{label1}
          </TableCell>
        }
        {
          !!value1 &&
          <TableCell className={valueClassName1}>
            {value1}
          </TableCell>
        }
      </TableRow>
    </Fragment>
  );
};

const matchListProduct = ({
  key, obj, label = "", details = "", key1, label1, detailIsCol = true,
}) => {
  if (!key) return null;
  const matching = (key, label = "") => {
    let value = "", valueClassName = "value";
    switch (key) {
      case 'sepudi':
        value = obj[key];
        label = "UDI :";
        break;
      case 'sepreferencenumber':
        value = obj[key];
        label = "Ref :";
        break;
      case 'sepproductmanufacturer':
        value = obj[key];
        label = "Manufacturer :";
        break;
      case 'sepdistributor':
        value = obj[key];
        label = "Distributor :";
        break;
      case 'sepproductname':
        value = obj[key];
        label = "Name :";
        break;
      case 'sepproductdescription':
        value = obj[key];
        label = "Description :";
        break;
      case 'sepproductexpiration':
        if (key in obj) value = formatDate(obj[key]);
        label = "Expiration :";
        break;
      case 'seplotcode':
        value = obj[key];
        label = "Lot code :";
        break;
      case 'sepproductprice':
        value = (typeof obj[key] === 'string' || typeof obj[key] === 'number') ? `$${obj[key]}` : '';
        label = "Price :";
        if (checkChangedPrice(obj)) {
          valueClassName = "value red";
          // if (obj.classes && typeof obj.classes === 'object') obj.classes.summary = "red";
          // else obj.classes = { summary: "red" };
        }
        break;
      case 'sepproducttype':
        if (key in obj) value = ProductTypes[obj[key]];
        label = "Product Type :";
        break;
      case 'sepproductwaste':
        if (key in obj) value = WasteTypes[obj[key]];
        label = "Waste :";
        break;
      case 'sepownership':
        if (key in obj) value = Ownerships[obj[key]];
        label = "Ownership :";
        break;
      case 'sepproductside':
        if (key in obj) value = Sides[obj[key]];
        label = "Product Side :";
        break;
      case 'sepuom':
        value = obj[key];
        label = "UoM :";
        break;
        case 'podquantity':
          value = obj[key];
          break;
        
      default:
        if (!!label) {
          value = obj[key];
        }
    }
    return { label, value, valueClassName };
  }
  const { label: tempLabel, value, valueClassName } = matching(key, label);
  if (!!tempLabel) label = tempLabel;
  if (!key1) return getShowProductComp({ key, value, label, details, valueClassName, detailIsCol });
  // label1, value1
  const { label: tempLabel1, value: value1, valueClassName: valueClassName1 } = matching(key1, label1);
  if (!!tempLabel1) label1 = tempLabel1;
  return getShowProductComp({ key, value, label, details, valueClassName, label1, value1, valueClassName1, detailIsCol });
};



/**
 * Surgical Event added products detail
 * @param {Object} className 
 * @param {Component} HeaderComponent 
 * @param {Array} listPattern 
 * @param {Object} seData 
 * @param {Object} options
 * @returns 
 */
export const AddedProductList = ({
  className, seData, HeaderComponent = AddedProductHeader, listPattern = defaultListPattern, options = {},
}) => {

  options = {
    isEnableCollapse: true,
    isEnableEditProduct: true,
    isEnableDeleteProduct: true,
    keyProduct: 'product',              // Key of Detail for Product, e.g. product, iproduct, invdproduct, podproduct, etc.
    isEnableCompliance: true,
    ...options,
    edit: {
      ...options.edit,
    },
  };

  const classes = useStyles();
  const classNameList = [classes.overview];
  const productDetailsList = seData;
  const productItemsList = productDetailsList;
  const [expanded, setExpanded] = useState(-1);
  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    if (options.isEnableCollapse) {
      if (event.target.tagName.toLowerCase() === 'img' || event.target.tagName.toLowerCase() === 'button') return;
      setExpanded(isExpanded ? panel : false);
      const parentElm = event.target.closest(`div.MuiAccordion-root`);
      if (parentElm && parentElm.children && parentElm.children.length > 1) {
        const collapseElm = parentElm.children[1];
        const transitionDuration = collapseElm.style['transition-duration'];
        setTimeout(() => {
          collapseElm.scrollIntoViewIfNeeded();
        }, (transitionDuration ? parseInt(transitionDuration) + 30 : 390));
      }
    }
  };
  if (className) classNameList.push(className);
  return (
    <Grid className={(className ? classNames(classes.addedProductPanel, className) : classes.addedProductPanel)}>
      <HeaderComponent productItemsList={seData} />
      {
        productItemsList.map((obj, index) => (
          <Accordion
            key={index}
            expanded={expanded === index}
            onChange={handleChangeAccordion(index)}
            className="main-accordion"
          >
            <AccordionSummary>
              <Typography className="accordion-header-index">{index + 1}</Typography>
              <Typography className="accordion-header-label">Ref:</Typography>
              <Typography className="accordion-header-text" style={{ width: '75px' }}>{obj.sepreferencenumber}</Typography>
              <Typography className="accordion-header-label">Lot/SN :</Typography>
              <Typography className="accordion-header-text" style={{ width: '66px' }}>{obj.seplotcode}</Typography>
              <Typography className="accordion-header-label">Description:</Typography>
              <Typography className="accordion-header-text" style={{ width: '123px' }}>{obj.sepproductdescription}</Typography>
              <Typography className="accordion-header-label">Price:</Typography>
              <Typography className="accordion-header-text" style={{ width: '65px' }}>{`$${obj.sepproductprice}`}</Typography>

            </AccordionSummary>
            <AccordionDetails>

              <Table className="accordion-table" >
                <TableBody >

                {
                  listPattern.map(({key, key1, label, label1, details }) => matchListProduct({key, key1, obj, label, label1,details }))
                }

                {
                  options.isEnableCompliance &&
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                      >

                      </Grid>
                     </TableCell>
                   </TableRow>
                }
              </TableBody>
              </Table>



            </AccordionDetails>
          </Accordion>
        ))
      }

    </Grid>
  );
};

/**
 * Surgical Event Complete Panel Component
 * @param {Object} options 
 * @param {Object} seData 
 * @returns 
 */
export default function SurgicalEventDetails({
  options = {}, seData,

}) {

  options = {
    isEditable: true,
    isEnableEditProduct: true,
    isEnableDeleteProduct: true,
    ...options,
  };

  const createProductListFromSE = (seData) => {
    return seData.map((obj) => ({ 'sepreferencenumber': obj.product.sepreferencenumber, 'seplotcode': obj.product.seplotcode, 'sepproductdescription': obj.product.sepproductdescription, 'sepproductprice': obj.product.sepproductprice, 'sepudi': obj.product.sepudi, 'sepproductmanufacturer': obj.product.sepproductmanufacturer, 'sepdistributor': obj.product.sepdistributor, 'sepproductname': obj.product.sepproductname, 'sepproductexpiration': obj.product.sepproductexpiration, 'sepproducttype': obj.product.sepproducttype, 'sepproductwaste': obj.product.sepproductwaste, 'sepuom': obj.product.sepuom , 'sepownership': obj.product.sepownership, 'sepproductside': obj.product.sepproductside }))
  }


  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Grid item xs={5}>
        <OverviewSurgicalEvent
          type="narrow"
          className={classes.overviewcompletePanel}
          seData={seData}
        />
      </Grid >
      <Grid item  xs={7}>
        <AddedProductList
          className={classes.completePanel}
          seData={createProductListFromSE(seData?.value?.seData?.surgicaleventproductdata)}
          options={{
            isEnableCollapse: true,
            isEnableEditProduct: false,
            isEnableDeleteProduct: false,
          }}
        />
      </Grid>
    </Grid>
  );
}
