import { formatDate } from "../../services/util";

const CSVFormat = ({data,index}) => {
  return `${data.payload.surgicaleventdata.seid},${formatDate(data.payload.surgicaleventcompliancedata.date).replace(",","")},${data.payload.surgicaleventcompliancedata.user},${data.payload.surgicaleventcompliancedata.role},${formatDate(data.payload.surgicaleventdata.eventdate).replace(",","")},${data.payload.surgicaleventdata.mrn},${data.payload.surgicaleventcompliancedata.ruletypelevel},${data.payload.surgicaleventcompliancedata.ruletype},${data.payload.surgicaleventcompliancedata.ruleoutcome}\n`;
}

export default CSVFormat;

// return `${index+1}.compliance details
// Rule Type Level,Rule Type,Rule Outcome,User,Event Date 
// ${data.payload.surgicaleventcompliancedata.ruletypelevel},${data.payload.surgicaleventcompliancedata.ruletype},${data.payload.surgicaleventcompliancedata.ruleoutcome},${data.payload.surgicaleventcompliancedata.user},${formatDate(data.payload.surgicaleventcompliancedata.date).replace(",","")}
// SE ID:,${data.payload.surgicaleventdata.seid} 
// Patient Details 
// MRN,First Name,Last Name,Gender,DOB 
// ${data.payload.surgicaleventdata.mrn},${data.payload.surgicaleventdata.patientfirstname},${data.payload.surgicaleventdata.patientlastname},${data.payload.surgicaleventdata.patientgender},${formatDate(data.payload.surgicaleventdata.patientdob).replace(",","")}
// Event Details 
// Date,Procedure,Side 
// ${formatDate(data.payload.surgicaleventdata.eventdate).replace(",","")},${data.payload.surgicaleventdata.surgicalprocedure},${data.payload.surgicaleventdata.surgicalside} 
// Physician Details 
// Salutation,First Name,Last Name 
// ${data.payload.surgicaleventdata.physiciansalutation},${data.payload.surgicaleventdata.physicianfirstname},${data.payload.surgicaleventdata.physicianlastname} 
// Product Details
// Reference Number:,${data.payload.auditedproductdata.sepreferencenumber},Lot Code:,${data.payload.auditedproductdata.seplotcode} 
// UDI,${data.payload.auditedproductdata.sepudi},Product Description,${data.payload.auditedproductdata.sepproductdescription}
// Product Manufacturer,${data.payload.auditedproductdata.sepproductmanufacturer},Expiration,${formatDate(data.payload.auditedproductdata.sepproductexpiration).replace(",","")}
// Product Distributor,${data.payload.auditedproductdata.sepdistributor},Price,${data.payload.auditedproductdata.sepproductprice}
// Product Name,${data.payload.auditedproductdata.sepproductname},Product Type,${data.payload.auditedproductdata.sepproducttype}
// ,,Waste,${data.payload.auditedproductdata.sepproductwaste}
// ,,Ownership,${data.payload.auditedproductdata.sepownership}
// ,,Product Side,${data.payload.auditedproductdata.sepproductside}\n\n
// `;