import React, { useState } from "react";
import { useLedger, useStreamQueries } from "@daml/react";
import EditSurgeon from "./editSurgeon";
import { useDamlState } from "../../context/DamlContext";
import useStyles from "../surgicalevent/styles";
import Contracts from "../../components/Contracts/Contracts1";
import { Surgeons } from "../../services/daml-modules1";
import { modifySurgeonDetail } from "./handle";
import SnackbarModal from "../../components/Modals/SnackbarModal";


/**
 * @returns
 * @param hospital
 */
export default function OperatorSurgeonList({hospital}) {
    const classes = useStyles();


    const { role, roleCid } = useDamlState();


    const ledger = useLedger();
    const { contracts } = useStreamQueries(Surgeons, () => [
        {hospital},
    ]);
    console.log("contracts", contracts)


    const [open, setOpen] = useState(false);
    const [contract, setContract] = useState(null);
    const [surgeonData, setSurgeonData] = useState({});
    const [openResultToast, setOpenResultToast] = useState({ isVisible: false, msg: '' });


    const handleRequest = async (c) => {
        setContract(c.contractId);
        setSurgeonData(c.payload);
        setOpen(true);
    }


    return (
        <>
            <div key="formChildStep" className="formsteps">
                <Contracts
                    contracts={contracts}
                    columns={[
                        ["Salutation", "payload.salutation", {
                            options: {
                                flex: 1,
                            },
                        }],
                        ["First Name", "payload.firstname", {
                            options: {
                                flex: 1,
                            },
                            search: {
                                type: "AdaptiveSearch",
                                name: "name"
                            },
                        }],
                        ["Last Name", "payload.lastname", {
                            type: "render",
                            options: {
                                flex: 1,
                            },
                        }],
                        ["Pref Card ID", "payload.pref_card_id", {
                            type: "render",
                            options: {
                                flex: 1,
                            },
                        }],


                    ]}


                    actions={[
                        [
                            "Actions",
                            [
                                [["Edit",
                                ],
                                (c) => handleRequest(c)],
                            ],
                            {
                                width: 150,
                                flex: 1,
                                headerAlign: 'center',
                                // checkVisible: checkEditableSE,
                            }
                        ]
                    ]}


                    options={{
                        height: 400
                    }}
                />


                {open ? <EditSurgeon
                    open={open}
                    setOpen={setOpen}
                    className={classes.pageTitle}
                    title={"Edit"}
                    handleUpdate={async (update) => {
                        setOpen(false);
                        let res = await modifySurgeonDetail({
                            ledger,
                            cid: contract,
                            newsalutation: update?.salutation,
                            newfirstname: update?.firstname,
                            newlastname: update?.lastname,
                            newpref_card_id: update?.pref_card_id,
                            roleCid,
                        })
                        setOpenResultToast({ isVisible: true, msg: res ? 'Surgeon data has been updated successfully.' : 'We are not able to edit surgeon now please try after some time.' })
                    }}
                    surgeonData={surgeonData}
                    role={role}
                /> : null}






                {openResultToast.isVisible ? <SnackbarModal
                    open={openResultToast.isVisible}
                    setOpen={(isVisible) => { setOpenResultToast({ isVisible }) }}
                    title=""
                    content={openResultToast.msg}
                    width={340}
                    height={88}
                    className={classes.toastMedium}
                /> : null}


            </div>
        </>
    );
}