import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  tooltipComp: {
    display: 'inline-block',
  },
  tooltip: {
    padding: 0,
    marginTop: 0,
  },
  tooltipArroww: {
  },
  tooltipPopper: {
    pointerEvents: 'all',
  },
}));
