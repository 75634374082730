import React, { useState } from "react";
import classNames from "classnames";
import { useLedger, useParty } from "@daml/react";
import { /* PostProcedureVendor, */ PostProcedureVendorHistory } from "../../services/daml-modules1";
import AdaptiveSearch from "../../components/Search/adaptivesearch";
import DateSearch from "../../components/Search/datesearch";
import Contracts from "../../components/Contracts/Contracts";
import { contractFilter } from "../../services/daml-filters";
import { checkNotPOHistory } from "../../models/Po";
import { archivePPV } from "./handle";
import archiveIcon from "../../icons/folder-plus.svg";
import archiveDisableIcon from "../../icons/folder-plus-disable.svg";
import DetailViewDrawer from "./detail-view-drawer";
import ModalDialogBox from "../../components/Modals/GeneralModal/modalDialogBox";
import { useDamlStream } from "../../hooks/useDAML";
import { useDamlState } from "../../context/DamlContext";
import useStyles from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";


/**
 * PPF List Contracts in Vendor
 * @param {Object} searchParams 
 * @param {Object} searchHistoryParams 
 * @param {Boolean} isIncludeHistory 
 * @param {Function} handleChangeSearchValue 
 * @param {Object} searchProcedure 
 * @param {Array} allContracts 
 * @returns 
 */
function PostProcedureListContractsVendor({
  searchParams = {},
  searchHistoryParams = {},
  isIncludeHistory = false,
  handleChangeSearchValue = null,
  searchProcedure = {},
  allContracts = [],
}) {

  const classes = useStyles();
  const [confirmModalStatus, setConfirmModalStatus] = useState('');
  const [openWarning, setOpenWarning] = useState(false);
  const [confirmModalContract, setConfirmModalContract] = useState({});


  // const history = useHistory();
  // const [ContractId, setContractId] = React.useState('');
  const ledger = useLedger();
  const vendor = useParty();


  // const assets = useStreamQueries(PostProcedureVendor, () => [searchParams]);
  const { roleCid } = useDamlState();

  // const [assetsHistory, setHisotry] = useState({});
  const assetsHistory = useDamlStream(PostProcedureVendorHistory, [{ vendor }], isIncludeHistory);

  allContracts = allContracts.concat(isIncludeHistory && assetsHistory.contracts ?
    assetsHistory.contracts.map(h => ({ ...h, payload: h.payload.postprocedurevendor })) : []);
  let contracts = contractFilter(allContracts, searchParams);

  console.log("[PostProcedureListContractsVendor] assets:", contracts, "searchParams:", searchParams);
  // console.log("[PostProcedureListContractsVendor] assetsHistory:", assetsHistory.contracts, searchHistoryParams);


  const [ppvData, setPPVData] = useState({});
  const [productList, setProductList] = useState([]);
  const [detailViewer, setDetailViewer] = useState(false);


  const handleArchivePPV = (c) => {
    // console.log("[handleArchivePPV]", c);
    setConfirmModalStatus('Archive');
    setOpenWarning(true);
    setConfirmModalContract(c);
  }

  const handleModalYes = () => {
    if (confirmModalStatus === 'Archive') {
      archivePPV({
        contractId: confirmModalContract.contractId, 
        payload: confirmModalContract.payload, 
        ledger,
        roleCid,
      });
    }
    setOpenWarning(false);
  }
  const handleSecRequest = () => {
    setOpenWarning(false);
  }

  return (
    <>

      <Contracts
        contracts={contracts}
        columns={[
          ["SE Id", "payload.surgicaleventdata.seid", {
            type: "link",
            onClick: (contractId) => {
              const data = contracts.find(c => c.contractId === contractId);
              // console.log("[postproceduredetail] data", data);
              setPPVData({ 
                ...data.payload.surgicaleventdata, 
                created_at: data.payload.created_at,
                hospital: data.payload.hospital, 
              });
              setProductList(data.payload.surgicaleventproductdata);
              setDetailViewer(true);
            },
            options: {
              width: 150,
              cellClassName: classNames(classes.primaryColor),
            },
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.surgicaleventdata.seid}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'seid')}
                  defaultValue={searchProcedure.seid}
                  label="Search SE ID"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchProcedure.seid,
            handleClear: () => handleChangeSearchValue('', 'seid'),
          }],
          ["Event Date", "payload.surgicaleventdata.eventdate", {
            search: {
              component:
                <DateSearch
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'eventdate')}
                  defaultValue={searchProcedure.eventdate}
                  label="Search Event Date :"
                  isStatic={true}
                />
            },
            isSearched: searchProcedure.eventdate,
            handleClear: () => handleChangeSearchValue('', 'eventdate'),
            options: {
              width: 200,
            },
          }],
          ["Hospital", "payload.hospital", {
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.hospital}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'hospital')}
                  defaultValue={searchProcedure.hospital}
                  label="Hospital"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchProcedure.hospital,
            handleClear: () => handleChangeSearchValue('', 'hospital'),
            options: {
              width: 180,
            },
          }],
          ["Physician Name", ["payload.surgicaleventdata.physiciansalutation", "payload.surgicaleventdata.physicianfirstname", "payload.surgicaleventdata.physicianlastname"], {
            search: [
              {
                label: "First Name",
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.surgicaleventdata.physicianfirstname}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'physicianfirstname')}
                    defaultValue={searchProcedure.physicianfirstname}
                    placeholder="First Name of Physician"
                    contracts={allContracts}
                  />
              },
              {
                label: "Last Name",
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.surgicaleventdata.physicianlastname}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'physicianlastname')}
                    defaultValue={searchProcedure.physicianlastname}
                    placeholder="Last Name of Physician"
                    contracts={allContracts}
                  />
              }
            ],
            isSearched: (!!searchProcedure.physicianfirstname || !!searchProcedure.physicianlastname),
            handleClear: () => {
              if (!!searchProcedure.physicianfirstname && !!searchProcedure.physicianlastname) {
                handleChangeSearchValue('', ['physicianfirstname', 'physicianlastname']);
              }
              else if (!!searchProcedure.physicianfirstname) handleChangeSearchValue('', 'physicianfirstname');
              else if (!!searchProcedure.physicianlastname) handleChangeSearchValue('', 'physicianlastname');
            },
            options: {
              flex: 1,
            },
          }],
          ["Procedure", "payload.surgicaleventdata.surgicalprocedure", {
            options: {
              flex: 1,
            },
          }],
          // ["Price", "payload.surgicaleventdata.price", {
          //   options: {
          //     type: 'number',
          //   },
          // }],
          ["Products", "payload.surgicaleventproductdata", {
            type: "object",
            options: {
              cellClassName: classes.centerCell,
              width: 80,
            },
          }],
          ["Received Date", "payload.created_at", {
            options: {
              width: 200,
            },
          }],
        ]}

        actions={[
          [
            "Actions",
            [
              [["Archive", [<img alt="" src={archiveIcon} />, <img alt="" src={archiveDisableIcon} />],
                (c) => checkNotPOHistory(c)],
                handleArchivePPV],
            ]
          ]

        ]}
        options={{
          statusEmpty: (allContracts.length === 0)
        }}
      />

      <DetailViewDrawer
        state={detailViewer}
        toggleDrawer={(open) => setDetailViewer(open)}
        selectedEventData={ppvData}
        productItemsList={productList}
      />

      <ModalDialogBox
        open={openWarning}
        setOpen={setOpenWarning}
        heading={"Warning"}
        type="warning"
        text={'<p> You will not be able to Recover it.</p>'}
        handleRequest={handleModalYes}
        secondBtn={'No'}
        handleSecRequest={handleSecRequest}
      />

    </>
  );

}

export default withLoading(PostProcedureListContractsVendor);
