import React from "react";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import { useStyles } from "./styles";


export const ToolTipCell = ({cellData}) => {
  const classes = useStyles();

  return (
    <Tooltip 
      className={classes.tooltip} 
      title={cellData || ""} 
      aria-label=""
      classes={{ popper: classes.tooltipPopper, arrow: classes.tooltipArroww }}
      arrow={true}
    >
      <Typography component="div" className={classes.innerTootltip}>
        {cellData}
      </Typography>
    </Tooltip>
  );
};

/**
 * Action Cell
 * @param {Array} action 
 * @param {Object} contract 
 * @returns 
 */
export const ActionCell = ({ action, contract }) => {
  
  const classes = useStyles();

  const [label, icons, checkAction] = action[0];
  const handleAction = action[1];

  const iconIndex = (typeof checkAction === 'function') ? checkAction(contract) : 0;

  // console.log("[ActionCell]", action, contract, label, checkAction, handleAction);
  // console.log("[ActionCell]", icons, Array.isArray(icons), iconIndex);

  return (
    <Tooltip 
      title={label}
      classes={{ arrow: classes.tooltipArroww, tooltip: classes.tooltip, popper: classes.tooltipPopper }} 
      arrow={true}
    >
      <IconButton 
        onClick={() => {
          if ((iconIndex === 0 || (typeof iconIndex !== 'number' && !!iconIndex)) 
            && typeof handleAction === 'function') handleAction(contract);
        }} 
        className={!!iconIndex ? classes.actionCell : classes.actionCellDisabled}
      >
        {
          Array.isArray(icons)
          ?
          (
            icons.length > 2 && typeof iconIndex === 'number' && iconIndex < icons.length
            ?
            icons[iconIndex]
            :
            (
              icons.length > 1
              ?
              (iconIndex ? icons[0] : icons[1])
              :
              icons[0]
            )
          )
          :
          icons
        }
      </IconButton> 
    </Tooltip>
  );

};

/**
 * Render Action Cell
 * @param {Array} actions 
 * @returns 
 */
export const renderActionCells = (actions) => {
  // console.log("[renderActionCell] actions", actions);

  return (param) => {
    const contract = param.value;

    return (
      !!actions && typeof actions === 'object' && actions.length > 0
      ?
      actions.map((action, index) => <ActionCell action={action} contract={contract} key={index} />)
      :
      <></>
    );
  }
};
