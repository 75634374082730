import React from "react";
import {
  Typography,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import ModalDialog from "../../components/Modals/NewGeneralModal";
import patientIcon from "../../icons/new-se-patient-icon.svg";
import useStyles from "./styles";

/**
 * Surgical Event Title Component
 * @param {Object} classes
 * @param {Number} tab
 * @param {Object} seData
 *
 * @returns
 */
const SETitle = ({ classes, seData }) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.newSETitlePanel}
    >
      <img src={patientIcon} alt="" />
      <Typography variant="inherit" component="span" className="name">
        Surgical Event
      </Typography>
      <Typography variant="inherit" component="span" className="value">
        {seData?.seid}
      </Typography>
    </Grid>
  );
};

/**
 *
 * @param {Object} seData
 * @returns
 */
const EventDetails = ({ seData }) => {
  return (
    <Grid item xs={4}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="details" colSpan={4}>
              <Typography variant="inherit">Event Details</Typography>
            </TableCell>
          </TableRow>
          <TableRow className="details-row">
            <TableCell className="label">Event Date :</TableCell>
            <TableCell className="label">Side :</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="value">{seData?.eventdate}</TableCell>
            <TableCell className="value">{seData?.surgicalside}</TableCell>
          </TableRow>
          <TableRow className="details-row">
            <TableCell className="label" colSpan={2}>Procedure :</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="value" colSpan={2}>{seData?.surgicalprocedure}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
};

const PatientDetails = ({ seData }) => {
  return (
    <Grid item xs={4}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="details" colSpan={4}>
              <Typography variant="inherit">Patient Details</Typography>
            </TableCell>
          </TableRow>
          <TableRow className="details-row">
            <TableCell className="label">MRN :</TableCell>
            <TableCell className="label">SE ID :</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="value">{seData?.mrn}</TableCell>
            <TableCell className="value">{seData?.seid}</TableCell>
          </TableRow>
          <TableRow className="details-row">
            <TableCell className="label">Last Name :</TableCell>
            <TableCell className="label">First Name :</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="value">{seData?.patientlastname}</TableCell>
            <TableCell className="value">{seData?.patientfirstname}</TableCell>
          </TableRow>
          <TableRow className="details-row">
            <TableCell className="label">Gender :</TableCell>
            <TableCell className="label">DOB :</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="value">{seData?.patientgender}</TableCell>
            <TableCell className="value">{seData?.patientdob}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
};

const PhysicianDetails = ({ seData }) => {
  return (
    <Grid item xs={4}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="details" colSpan={4}>
              <Typography variant="inherit">Physician Details</Typography>
            </TableCell>
          </TableRow>
          <TableRow className="details-row">
            <TableCell className="label">Salutation :</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="value">
              {seData?.physiciansalutation}
            </TableCell>
          </TableRow>
          <TableRow className="details-row">
            <TableCell className="label">Last Name :</TableCell>
            <TableCell className="label">First Name :</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="value">{seData?.physicianlastname}</TableCell>
            <TableCell className="value">
              {seData?.physicianfirstname}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
};

/**
 *
 * @param {Object} seData
 * @param {Object} classes
 * @returns
 */
const ComplianceContent = ({ classes, detailData }) => {
  return (
    
    <Table>
      <TableBody  className={classes.rowWithBorder}>
        <TableRow>
          <TableCell className={classes.cellWithBorder}>Reference Number</TableCell>
          <TableCell className={classes.cellWithBorder}>Lot/SN</TableCell>
          <TableCell className={classes.cellWithBorder}>Description</TableCell>
          <TableCell className={classes.cellWithBorder}>Ownership</TableCell>
          <TableCell className={classes.cellWithBorder}>Distributor</TableCell>
          <TableCell className={classes.cellWithBorder}>Price</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.cellHeaderBold}>
            {detailData.sepreferencenumber}
          </TableCell>
          <TableCell className={classes.cellHeaderBold}>
            {detailData.seplotcode}
          </TableCell>
          <TableCell className={classes.cellHeaderBold}>
            {detailData.sepproductdescription}
          </TableCell>
          <TableCell className={classes.cellHeaderBold}>
            {detailData.sepownership}
          </TableCell>
          <TableCell className={classes.cellHeaderBold}>
            {detailData.sepdistributor}
          </TableCell>
          <TableCell className={classes.cellHeaderBold}>
            {detailData.sepproductprice}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
/**
 * SurgicalEvent Dialog
 * @param {Boolean} open
 * @param {Function} setOpen
 * @param {Object} contract
 * @param {Boolean} isEdit
 */
export default function ComplianceDeatilpopup({
  open,
  setOpen,
  contract = {},
}) {
  const classes = useStyles();

  const seData = contract?.payload?.surgicaleventdata;
  const detailData = contract?.payload?.auditedproductdata;
  return (
    <>
      <ModalDialog
        open={open}
        setOpen={setOpen}
        width={850}
        height={450}
        title={<SETitle classes={classes} seData={seData} />}
        content={
          <Grid>
            <Grid container className={classes.newOverview}>
              <PatientDetails seData={seData} />
              <EventDetails seData={seData} />
              <PhysicianDetails seData={seData} />
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={classes.productSubtitle}
            >
              <Grid item>
                <Typography variant="inherit">Total Products: </Typography>

                <Typography variant="inherit">
                  {contract?.payload?.auditedproductdata.length || 1}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="inherit">Total Price: </Typography>
                <Typography variant="inherit"> {seData?.price} </Typography>
              </Grid>
            </Grid>

            <Grid container className={classes.paddingLeftRight}>
              <ComplianceContent classes={classes} detailData={detailData} />
            </Grid>
          </Grid>
        }
      />
    </>
  );
}
