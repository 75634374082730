import React from "react";
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { formatDate } from "../../services/util";
import { useTranslation } from 'react-i18next';


// Create styles
export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    // paddingTop: 35,
    // paddingBottom: 65,
  },
  header: {
    borderBottom: '1px solid #CCCCCC',
  },
  footer: {
    borderTop: '1px solid #CCCCCC',
  },
  section: {
    padding: '15px 27px',
    color: '#000000',
    flexGrow: 1,
  },
  table: { 
    borderColor: '#CCCCCC',
    marginBottom: '10px',
  }, 
  tableRow: { 
    flexDirection: 'row',
  }, 
  tableCell: { 
    padding: '1px',
    fontWeight: 400,
    fontSize: '8px',
  },
  tableCellHead: {
    backgroundColor: 'rgba(196, 196, 196, 0.38)',
    fontWeight: 600,
    fontSize: '10px',
  },
  borderCell: {
    border: '1px solid #000000',
  },
  col2: {
    width: '50%',
  },
  col10: {
    width: '10%',
  },
  col14: {
    width: '14%',
  },
  col8: {
    width: '8%',
  },
  col20: {
    width: '20%',
  },
  detailsForm: {
    margin: '70px 100px 60px',
  },
  detailsCell: { 
    fontWeight: 700,
    fontSize: '14px',
  },
});

/**
 * PurchaseOrder PDF
 * @param {Object} purchaseorderdata 
 * @param {Array} purchaseorderdetaildata 
 * @param {Array} purchaseordercomment 
 * @param {String} hospital 
 * @returns 
 */

const PurchaseOrderPDF = ({
  purchaseorderdata={}, purchaseorderdetaildata=[], purchaseordercomment=[], hospital, 
}) => {
  const { t } = useTranslation();
  
  return (
    <View style={styles.section} wrap={true}>
      {/* <View style={styles.header} fixed></View> */}

      <View style={[styles.table, styles.detailsForm]}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCell, styles.detailsCell, styles.col2]}>
            <Text>PO number: {purchaseorderdata.popurchaseorderid}</Text>
          </View>
          <View style={[styles.tableCell, styles.detailsCell, styles.col2]}>
            <Text>PO Type: {purchaseorderdata.potype}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCell, styles.detailsCell, styles.col2]}>
            <Text>PO Date: {formatDate(purchaseorderdata.podate)}</Text>
          </View>
          <View style={[styles.tableCell, styles.detailsCell, styles.col2]}>
            <Text>Hospital: {hospital}</Text>
          </View>
        </View>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCell, styles.tableCellHead, styles.borderCell, styles.col10]}>
            <Text>Ref No</Text>
          </View>
          <View style={[styles.tableCell, styles.tableCellHead, styles.borderCell, styles.col10]}>
            <Text>Lot/SN</Text>
          </View>
          <View style={[styles.tableCell, styles.tableCellHead, styles.borderCell, styles.col14]}>
            <Text>Name</Text>
          </View>
          <View style={[styles.tableCell, styles.tableCellHead, styles.borderCell, styles.col20]}>
            <Text>Description</Text>
          </View>
          <View style={[styles.tableCell, styles.tableCellHead, styles.borderCell, styles.col10]}>
            <Text>Distributor</Text>
          </View>
          <View style={[styles.tableCell, styles.tableCellHead, styles.borderCell, styles.col10]}>
            <Text>Type</Text>
          </View>
          <View style={[styles.tableCell, styles.tableCellHead, styles.borderCell, styles.col10]}>
            <Text>Price</Text>
          </View>
          <View style={[styles.tableCell, styles.tableCellHead, styles.borderCell, styles.col8]}>
            <Text>{t('main.uom.lable')}</Text>
          </View>
          <View style={[styles.tableCell, styles.tableCellHead, styles.borderCell, styles.col8]}>
            <Text>Quantity</Text>
          </View>
        </View>
        {
          purchaseorderdetaildata.map((detail, i) => (
            <View style={styles.tableRow} key={i}>
              <View style={[styles.tableCell, styles.borderCell, styles.col10]}>
                <Text>{detail.podproduct.sepreferencenumber}</Text>
              </View>
              <View style={[styles.tableCell, styles.borderCell, styles.col10]}>
                <Text>{detail.podproduct.seplotcode}</Text>
              </View>
              <View style={[styles.tableCell, styles.borderCell, styles.col14]}>
                <Text>{detail.podproduct.sepproductname}</Text>
              </View>
              <View style={[styles.tableCell, styles.borderCell, styles.col20]}>
                <Text>{detail.podproduct.sepproductdescription}</Text>
              </View>
              <View style={[styles.tableCell, styles.borderCell, styles.col10]}>
                <Text>{detail.podproduct.sepdistributor}</Text>
              </View>
              <View style={[styles.tableCell, styles.borderCell, styles.col10]}>
                <Text>{detail.podproduct.sepproducttype}</Text>
              </View>
              <View style={[styles.tableCell, styles.borderCell, styles.col10]}>
                <Text>${detail.podproduct.sepproductprice}</Text>
              </View>
              <View style={[styles.tableCell, styles.borderCell, styles.col8]}>
                <Text>{detail.podproduct.sepuom}</Text>
              </View>
              <View style={[styles.tableCell, styles.borderCell, styles.col8]}>
                <Text>{detail.podquantity}</Text>
              </View>
            </View>
          ))
        }
      </View>

      {/* <View style={styles.footer} fixed>
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} />
      </View> */}
    </View>
  );
};

export default PurchaseOrderPDF;
