import React, { Component } from "react";
import * as styles from "./FileUpload.module.css";
import { UploadFileExt } from '../../config';
import { readCSV, uploadFile, validateFile } from "./handle";


export default class FileUploadButton extends Component {
    constructor () {
        super();
        this.state = {
            fileToUpload: undefined,
            uploadSuccess: undefined,
            error: undefined,
        };
        this.handleFileUpload = this.handleFileUpload.bind(this);
    }

    async handleFileUpload (e) {
        const file = e.target.files[0];
        if (!file) return;
        if (!validateFile(file.name)) return ;
        await this.setState({
            fileToUpload: file
        });
        if ((file.name).includes(UploadFileExt)) {
            readCSV(file, this.props.setFilePathData);
        }
        // upload
        if (typeof this.props.uploadFile === 'function') {
            this.props.uploadFile(this.state.fileToUpload, (d) => this.setState(d));
        }
        else {
            uploadFile(this.state.fileToUpload, (d) => this.setState(d));
        }
        this.state = {
        fileToUpload: undefined,}
    }


    render() {
        return (
            <>
                <label className={styles.fileLabelBtn} style={{marginTop: 0}}>
                    <input
                        type="file"
                        className="form-control-file"
                        id="fileUpload"
                        onChange={this.handleFileUpload}
                    />
                    <span>Browse Files</span>
                </label>
            </>
        );
    }
}
