import React, { useState, useEffect, useRef } from "react";
import classNames from 'classnames';
import { Snackbar, Slide, Grow, Fade, Button, Typography, Grid } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { CheckCircleOutlineOutlined } from '@material-ui/icons';
import warningIcon from '../../icons/new-warning-icon.svg';
import errorIcon from '../../icons/new-error-icon.svg';
import { delay, isAsync } from "../../services/util";
import useStyles from "./styles";
import { useLayoutState } from "../../context/LayoutContext";
/**
 * Custom Snackbar
 * @param {String} type success | warning | error
 * @param {String} title
 * @param {String} content
 * @param {String} vertical bottom | top | center | semi-bottom
 * @param {String} horizontal left | right | center
 * @param {Number|null} duration
 * @param {Function} handleClose
 * @param {String} transition slide | grow | fade
 * @param {Array} actions {label: string, handle: void}
 * @param {Number|null} width
 * @param {Number|null} height
 * @param {Object} className
 * @param {Object} options
 * @returns 
 */
export default function CustomSnackbar({
  type = "success", note = "", title = "Success", content = "", vertical, horizontal = "left", duration, handleClose,
  transition = "slide", actions = [], width = null, height = null, className, options = {},
}) {

  options = {
    isClose: true,
    duration: 3000,
    delay: 1000,      // sleep 1 second
    validateULE: false,

    ...options,
  };

  const [open, setOpen] = useState(false);
  const [closing, setClosing] = useState(false);

  const classes = useStyles();
  const layoutState = useLayoutState();
  const mounted = useRef(false);

  // default vertical position
  vertical = (vertical ? vertical : (horizontal === "left" ? "semi-bottom" : "bottom"));
  const hideDuration = ((typeof duration === 'number' || duration === null) ? duration : (
    (actions.length > 0) ? null : options.duration));
  const icon = (type === "warning" ? <img src={warningIcon} alt="" />
    : (type === "error" ? <img src={errorIcon} alt="" />
      : <CheckCircleOutlineOutlined fontSize="inherit" />));
  // console.log("[CustomSnackbar]", content);

  const onCloseHandler = async () => {
    setClosing(true);
    await setOpen(false);
    await delay(options.delay);  // sleep delay second
    if (typeof handleClose === 'function') await handleClose();
    if (mounted.current) setClosing(false);
  };

  const onClickAction = async (handle) => {
    if (typeof handle === 'function') {
      if (isAsync(handle)) await handle();
      else handle();
    }
    onCloseHandler();
  };


  // when content is not empty, open it.
  useEffect(() => {
    // console.log("[CustomSnackbar]", content, open, closing);
    if (!!content && !open && !closing) setOpen(true);
  }, [content, open, closing]);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);


  return (
    <Snackbar
      open={open}
      className={classNames(layoutState.darkMode ? classes.customSnackbarDM : classes.customSnackbar,
        (vertical === "center" ? classes.verticalCenter : (
          vertical === "semi-bottom" ? classes.verticalSemiBottom : null
        )),
        (className ? className : null))}
      anchorOrigin={{
        vertical: (vertical === "top" ? "top" : "bottom"),
        horizontal,
      }}
      autoHideDuration={hideDuration}
      onClose={(options.isClose ? onCloseHandler : null)}
      // message={content}
      TransitionComponent={(
        transition === 'fade' ? Fade : (
          transition === 'grow' ? GrowTransition : SlideTransition)
      )}
      TransitionProps={{
        appear: !options.validateULE,
      }}
    >
      {
        options.validateULE ?
          <>
            <Grid container className={classes.newCustomSnackbar} sx={{ width: '100%' }}
              style={{ width, height }}>

              <Grid item>
                <Typography  className={classes.newSnackbarcontent} >{content}</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.newSnackbarnote}>{note}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent={actions.length===1?"center":"flex-end"} className={classes.newSnackbarbtn}>
                  <Typography>
                    {
                      actions.map(({ label, handle }, i) => (
                        <Button className={i === 0 ? classes.ULEButton : classes.ULEButton1} key={label} onClick={() => onClickAction(handle)}>{label}</Button>
                      ))
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
          :
          <Alert
            variant="outlined"
            severity={type}
            // iconMapping={{
            //   success: <CheckCircleOutlineOutlined fontSize="inherit" />,
            // }}
            icon={icon}
            // onClose={onCloseHandler} 
            sx={{ width: '100%' }}
            style={{ width, height }}
          >
            <AlertTitle className="title">{title}</AlertTitle>
            <Typography
              className="content"
              component={typeof content === 'string' ? 'p' : 'div'}
            >
              {content}
            </Typography>
            <Typography variant="body1" className="actions">
              {
                actions.map(({ label, handle }, index) => (
                  <Button key={index} style={{ color: layoutState.darkMode ? "#F7CD37" : "" }} onClick={() => onClickAction(handle)}>{label}</Button>
                ))
              }
            </Typography>
          </Alert>
      }
    </Snackbar>
  );
}

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

function GrowTransition(props) {
  return <Grow {...props} />;
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

