import React from "react";
import classNames from "classnames";
import { IconButton, ButtonGroup, Button } from "@material-ui/core";
import CustomTooltip from "../Tooltip/CustomTooltip";
import actionButtonIcon from "../../icons/action-button.svg";
import actionButtonIconDM from "../../icons/action-button-dm.svg";
import { useStyles } from "./styles";
import { useLayoutState } from "../../context/LayoutContext";

/**
 * New Action Cell
 * @param {Array} actions 
 * @param {Object} contract 
 * @param {Object} classes 
 * @returns 
 */
export const ActionCell = ({ actions, contract, classes }) => {
  
  const classesInner = useStyles();
  const layoutState = useLayoutState();
  return (
    <CustomTooltip 
      title={(
        <ButtonGroup orientation="vertical" >
          {
            actions.map(([[label, checkAction], handleAction], i) => (
              <Button 
                key={i} 
                onClick={() => {
                  if (typeof handleAction === 'function') handleAction(contract);
                }} 
                disabled={((typeof checkAction === 'function') ? !checkAction(contract) : false)}
              >
                {label}
              </Button>
            ))
          }
        </ButtonGroup>
      )}
      classes={{ 
        arrow: classNames(layoutState.darkMode?classesInner.newTooltipArrowwDM:classesInner.newTooltipArroww, (classes ? classes.arrow : null)), 
        tooltip: classNames(layoutState.darkMode?classesInner.newAddedTooltipDM:classesInner.newAddedTooltip, (classes ? classes.tooltip : null)), 
      }} 
      arrow={true}
    >
      <IconButton className={layoutState.darkMode?classesInner.newActionCellDM:classesInner.newActionCell} >
        <img src={layoutState.darkMode?actionButtonIconDM:actionButtonIcon} alt="Action" />
      </IconButton> 
    </CustomTooltip>
  );

};

/**
 * New Render Action Cell
 * @param {Array} actions 
 * @param {Object} options 
 * @returns 
 */
export const renderActionCells1 = (actions, { checkVisible, classes }) => {
  // console.log("[renderActionCell] actions", actions);
  if (!checkVisible) checkVisible = () => (true);
  const isVisible = (!!actions && typeof actions === 'object' && actions.length > 0);

  return (param) => {
    const contract = param.value;

    if (!isVisible || !checkVisible(contract)) return (<></>);
    return (<ActionCell actions={actions} contract={contract} classes={classes} />);
  }
};
