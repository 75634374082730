import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, MenuItem, Radio, FormControlLabel, RadioGroup } from "@material-ui/core";
import { useStyles } from "../styles";
import { getPartyLabel } from "../../../models/handles";

export default function SwitchOrganizationModal({ 
  dialogborder,
  modaltitle, 
  buttontitle,
  organizationList,
  onChangeHandler,
  cancelButtontitle="Cancel", 
  isButtonVisible=true,
  isDefaultVisible=false,
  cancelHandler=null, user }) {

  const classes = useStyles();

  const [modalOpenState, setModalOpenState] = React.useState(false);
  
  React.useEffect(() => {
    if (isDefaultVisible) setModalOpenState(true);
  }, [isDefaultVisible]);

  const handleConductModalClose = () => {
    setModalOpenState(false);
  };

  return (
    <>
      <Dialog
        open={modalOpenState}
        onClose={handleConductModalClose}
        classes={{ paper: classes.dialogSwitchOrgPaper }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
            paddingTop: '0px',
            border: '1px solid #dedede',
           
        }}
      >
        <DialogTitle id="alert-dialog-title" className={ classes.switchOrgDialogTitle } >
            {modaltitle}
            <button
                aria-label="close"
                onClick={handleConductModalClose}
                style={{
                    position: 'absolute',
                    right: 4,
                    top:5,
                    textDecoration:'none'
                }}
                className={classes.switchOrgCloseIcon}
            >
                <span style={{ borderLeft: '1px'}}>X</span>
            </button>
        </DialogTitle>
        
        <DialogContent className={ classes.dialogContent } style={{
            paddingTop: 0
        }}>
                      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
      >
        {organizationList && organizationList.map((list, index)=>{
            return (
              list === user?.current_organization
              ?
              <FormControlLabel 
                label={<>
                  <span className={classes.avtarSwitchOrg} key={index}>
                    {list !== null ? getPartyLabel(list) : ''} 
                  </span>{list}
                  </>}  
                control={<Radio checked onChange={()=>{onChangeHandler(list)}}  />} 
                value={list} 
                key={index}
              >
                {list}
              </FormControlLabel>
              :
              <FormControlLabel 
                label={<>
                  <span className={classes.avtarSwitchOrg} key={index}>
                    {list !== null ? getPartyLabel(list) : ''} 
                  </span>{list}
                  </>}  
                control={<Radio  onChange={()=>{onChangeHandler(list)}}  />} 
                value={list} 
                key={index}
              >
                {list}
              </FormControlLabel>
            )
          })
        }

      </RadioGroup>
        </DialogContent>
        <DialogActions className={classes.dialogborder}>
          <Button 
          className={classes.primarybtn }
          onClick={() => {
              handleConductModalClose();
              if (typeof cancelHandler === "function" && cancelHandler) cancelHandler();
            }}>
            {cancelButtontitle}
          </Button>
        </DialogActions>
      </Dialog>

      {isButtonVisible && (
        <MenuItem className={classes.seeMoreText} color={"primary"} onClick={() => setModalOpenState(true)}>
            {buttontitle}
        </MenuItem>
      )}
    </>
  );
}