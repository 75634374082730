import React, { useState } from "react";
// import classNames from "classnames";
import { useLedger } from "@daml/react";
import { Grid, /* Chip */ } from "@material-ui/core";
import { contractFilter1 } from "../../services/daml-filters";
import { getTemplate } from "../../services/util";
import { ArchiveInvoiceHospital, PaymentInvoiceHospital, SaveInvoiceHospitalPaymentDetails } from "./handle";
import ModalDialogBox from '../../components/Modals/GeneralModal/modalDialogBox';
import Contracts from "../../components/Contracts/Contracts1";
import DetailViewDrawer from "./detailviewdrawer";
import DetailViewDrawerPO from "./detailviewdrawerpo";
import { useDamlState } from "../../context/DamlContext";
import useStyles from "./styles";
import withLoading from "../../hoc/withLoading";
import withFilters from "../../hoc/withFilters";
import SnackbarModal from "../../components/Modals/SnackbarModal";
import PaymentPopup from "./payment-popup";


/**
 * Received InvoiceList Contracts New
 * @param {Object} searchParams 
 * @param {Boolean} isIncludeHistory 
 * @param {Array} allContracts 
 * @param {Array} historyContracts 
 * @param {Function} setArchived
 * @param {Function} setChecked
 * @param {Array} vendortemplates
 * @returns 
 */
function ReceivedInvoiceListContracts({
  searchParams = {},
  isIncludeHistory = false,
  allContracts = [],
  historyContracts = [],
  setArchived = null,
  setChecked = null,
  vendortemplates = [],
}) {

  allContracts = allContracts.concat(isIncludeHistory && historyContracts.length ?
    historyContracts.map(h => ({ ...h, payload: h.payload.invoicehospital })) : []);
  let contracts = contractFilter1(allContracts, searchParams);
  console.log('[ReceivedInvoiceListContracts] allContracts', allContracts);
  
  const classes = useStyles();
  const ledger = useLedger();
  const { roleCid, rolePayload } = useDamlState();
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [archiveModalContract, setArchiveModalContract] = useState({});

  // DetailViewDrawer with PO
  const [invoiceData, setInvoiceData] = useState({});
  const [invoiceList, setInvoiceList] = useState([]);
  const [detailViewer, setDetailViewer] = useState(false);
  const [openToast, setOpenToast] = useState(false);

  // DetailViewDrawer for Invoice detail
  const [invoice, setInvoice] = useState({});
  const [detailViewerInvoice, setDetailViewerInvoice] = useState(false);
  const [vendortemplate, setVendorTemplate] = useState(null);
  const [paymentDetailView, setPaymentDetailView] = useState(false);
  const [toastDetails, setToastDetails] = useState({ isOpen: false, content: "" });
  

  const isDiscoverPayment = (rolePayload?.hospitaldetails?.ach_info?.discover_payment);

  
  const checkNotInvHistory = (c) => (getTemplate(c) !== "InvoiceHospitalHistory");
  const checkInvPaymentInitiated = (c) => {
    return c.payload.paymentdetaildata.paymentstatus === "Available for payment" || c.payload.paymentdetaildata.paymentstatus === "Cancelled"
  };

  const openArchiveInv = (c) => {
    setArchiveModalContract(c);
    console.log(archiveModalContract);
    setArchiveModalOpen(true);

  };

  const openPaymentInv = (c) => {
    setArchiveModalContract(c);

    if (rolePayload?.hospitaldetails?.ach_info?.accountnumber === "" || rolePayload?.hospitaldetails?.ach_info?.partnernetwork_id === "" || rolePayload?.hospitaldetails?.ach_info?.payernetwork_id === "" || rolePayload?.hospitaldetails?.ach_info?.routingnumber === "" || rolePayload?.hospitaldetails?.physicaladdress === "") {
      setOpenToast(true);
    }
    else {
      setInvoice(c.payload);
      setPaymentDetailView(true)
    }

  };

  const handleArchiveInv = async () => {
    await ArchiveInvoiceHospital({
      ledger,
      cid: archiveModalContract.contractId,
      payload: archiveModalContract.payload,
      roleCid,
    });
    setArchiveModalOpen(false);
  };


  const getActions = () => {
    return [
      [
        "Archive",
        [
          [["Payment",
            checkInvPaymentInitiated],
            openPaymentInv],
          [["Archive",
            (c) => (checkNotInvHistory(c) && c.payload.paymentdetaildata.paymentstatus === "Available for payment")],
            openArchiveInv]

        ]
      ],
    ]
  }


  const getStatus = (status) => {
    let statusCode = ''
    switch (status) {
      case 'Error Received':
        statusCode = 'rgba(244, 67, 54);'
        break;
      case 'Payment Complete':
        statusCode = 'rgba(76, 175, 80);'
        break;
      case 'Available for payment':
        statusCode ='rgba(76, 175, 80)';
        break;
      case 'Ready for payment':
        statusCode = 'rgba(33, 150, 243)';
        break;
      case 'Cancelled':
        statusCode = 'rgba(255, 152, 0);'
        break;
      case 'Processing by Bank':
        statusCode = 'rgba(33, 150, 243)';
        break;
      case 'Sent to Bank':
      case 'Confirmed by Bank':
        statusCode = 'rgba(12, 165, 165)';
        break;
      default:
        statusCode = 'rgba(33, 150, 243)';
        break;
    }

    return statusCode;
  }


  const getInvoiceStatus = (status) => {
    let invstatus = ''
    switch (status) {
      case 'Updated Invoice':
      case 'Created Invoice':
        invstatus = '#2196F3';
        break;
      case 'Declined':
        invstatus = '#F44336';
        break;
      case 'Sent':
      case 'Invoice Received':
      case 'Accepted':
        invstatus = '#4CAF50';
        break;
      default:
        invstatus = 'rgba(247, 110, 17)';
        break;
    }
    return invstatus;
  }




  return (
    <>

      <Contracts
        contracts={contracts}
        columns={[
          // ["ContractId", "contractId"],
          ["Invoice Number", "payload.invoicedata.invinvoicenumber", {
            type: "link",
            onClick: (contractId, contract) => {
              const clickedContractId = (!!contractId) ? contractId : contract.contractId;
              const data = contracts.find(c => c.contractId === clickedContractId);

              setInvoice({
                ...data.payload,
                is_sent_hospital: true,
              });
              const foundVendorTemplate = vendortemplates.find(temp => temp.vendor === data.payload.vendor);
              if (foundVendorTemplate) setVendorTemplate(foundVendorTemplate.payload);
              else setVendorTemplate(null);
              setDetailViewerInvoice(true);
            },
            options: {
              width: 180,
            },
            search: {
              type: "AdaptiveSearch",
              name: "invoicedata.invinvoicenumber",
              getValue: (c) => c.payload.invoicedata.invinvoicenumber,
              contracts: allContracts,
            },
          }],
          ["Purchase Order", "payload.invoicedata.invpurchaseorderid", {
            type: "link",
            onClick: (contractId, contract) => {
              const clickedContractId = (!!contractId) ? contractId : contract.contractId;
              const data = contracts.find(c => c.contractId === clickedContractId);
              console.log("[invoicedetail] data", data);
              setInvoiceData({
                ...data.payload.invoicedata,
                is_sent_hospital: true,
                hospital: data.payload.hospital,
              });
              setInvoiceList(data.payload.invoicedetail);
              setDetailViewer(true);
            },
            search: {
              type: "AdaptiveSearch",
              name: "invoicedata.invpurchaseorderid",
              getValue: (c) => c.payload.invoicedata.invpurchaseorderid,
              contracts: allContracts,
            },
            options: {
              width: 150,
            },
          }],
          ["Distributor", "payload.vendor", {
            options: {
              width: 160,
            },
            search: {
              type: "AdaptiveSearch",
              name: "vendor",
              getValue: (c) => c.payload.vendor,
              contracts: allContracts,
            }
          }],
          ["Invoice Date", "payload.invoicedata.invinvoicedate", {
            options: {
              width: 160,
            },
            search: {
              type: "DateSearch",
              name: "invoicedata.invinvoicedate",
            },
          }],
          ["Invoice Amount", "payload.invoicedata.invinvoicetotalamount", {
            options: {
              width: 150,
              beforeText: '$'
            },
          }],
          ["Invoice Items", "payload.invoicedata.totalitemscount", {
            options: {
              width: 150,
              headerClassName: classes.centerCell,
              cellClassName: classes.centerCell,
            },
          }],
          ["Invoice Status", "payload.status", {
            type: "render",
            render: (param) => 
            <Grid container alignItems="center">
              <Grid item xs={2} style={{ marginTop: "2px" }}>
                <svg height="10" width="10">
                  <circle cx="5" cy="5" r="5"
                    fill={getInvoiceStatus(param.value)} />
                </svg>
              </Grid>
              <Grid item xs={10}>
                {param.value}
              </Grid>
            </Grid>,
            options: {
              flex: 1,
              alignItems: 'center',
            },
          }],
          ...(isDiscoverPayment  ? [
          ["Payment Status", "payload.paymentdetaildata.paymentstatus", {
            type: "render",
            render: (param) => 
            <Grid container alignItems="center">
              <Grid item xs={2} style={{ marginTop: "2px" }}>
                <svg height="10" width="10">
                  <circle cx="5" cy="5" r="5"
                    fill={getStatus(param.value)} />
                </svg>
              </Grid>
              <Grid item xs={10}>
                {param.value}
              </Grid>
            </Grid>,
            options: {
              flex: 1,
              alignItems: 'center',
            },
          }],
          ["Payment Date", "payload.paymentdetaildata.paymentinitiationdate", {
            options: {
              width: 160,
              headerClassName: classes.centerCell,
              cellClassName: classes.centerCell,
            },
            search: {
              type: "DateSearch",
              name: "paymentdetaildata.paymentinitiationdate",
            },
          }]] : [] )
        ]}

        actions={getActions()}
        options={{
          statusEmpty: (allContracts.length === 0),
          checkbox: true,
        }}

        handleArchived={setArchived}
        handleChecked={setChecked}
      />

      <DetailViewDrawerPO
        state={detailViewer}
        toggleDrawer={(open) => setDetailViewer(open)}
        selectedInvoiceData={invoiceData}
        productItemsList={invoiceList}
      />

      <DetailViewDrawer
        state={detailViewerInvoice}
        toggleDrawer={(open) => setDetailViewerInvoice(open)}
        invoice={invoice}
        vendortemplate={vendortemplate}
        isHospital={true}
      />


      <ModalDialogBox
        open={archiveModalOpen}
        setOpen={setArchiveModalOpen}
        heading="Confirm"
        type="warning"
        text={'<p>Do you want to archive Invoice now ?</p>'}
        handleRequest={handleArchiveInv}
        secondBtn={'No'}
        handleSecRequest={() => setArchiveModalOpen(false)}
      />



      <SnackbarModal
        open={openToast}
        setOpen={setOpenToast}
        type="warning"
        title=""
        content="We don\'t have your Bank details or Conact details Please connect with  your Operator"
        vertical="center"
        horizontal="center"
        width={500}
        height={117}
        transition="fade"
        className={classes.toastMedium}
        options={{
          delay: 500,
        }}
        actions={[
          {
            label: 'OK',
          }
        ]}
      />

      <SnackbarModal
        open={toastDetails.isOpen}
        setOpen={(isOpen) => setToastDetails({ isOpen, content: '' })}
        title=""
        content={toastDetails.content}
        width={330}
        height={88}
        className={classes.toastMedium}
      />

      <PaymentPopup
        state={paymentDetailView}
        setInvoiceDetailViewer={setPaymentDetailView}
        toggleDrawer={(open) => {
          setInvoice(null);
          setPaymentDetailView(open);
        }}
        invoice={invoice}
        handlePayment={async (payType, dueDate, paymentinitiationdate) => {
          let shortDate = new Date(dueDate).toISOString().slice(0, 10);
          let shortInitDate = paymentinitiationdate.toISOString().slice(0, 10);
          await PaymentInvoiceHospital({
            ledger,
            vendor: archiveModalContract.payload.vendor,
            cId: archiveModalContract.contractId,
            roleCid,
            paymentdate: shortDate,
            paymentInitDate: shortInitDate,
            paytype: payType
          });
          setPaymentDetailView(false);
          setToastDetails({ isOpen: true, content: 'Your payment is Ready for Payment.' })
        }}
        handleSave={async (paymentdate, paymenttype) => {
          setPaymentDetailView(false);
          await SaveInvoiceHospitalPaymentDetails({
            ledger,
            cid: archiveModalContract.contractId,
            roleCid,
            paymentdate, paymenttype
          })
          setToastDetails({ isOpen: true, content: 'The Payment Updates have been saved Successfully.' })
        }}
      />
    </>
  );
}

const ReceivedInvoiceListContractsWithLoading = withLoading(ReceivedInvoiceListContracts);
export default withFilters(ReceivedInvoiceListContractsWithLoading)([
  { name: "invoicedata.invpurchaseorderid", label: "Purchase Order" },
  { name: "invoicedata.invinvoicenumber", label: "Invoice Number" },
  { name: "invoicedata.invinvoicedate", label: "Invoice Date", type: "date" },
  { name: "surgicaleventdata.surgicalprocedure", label: "Payment Date", type: "date" },
  { name: "vendor", label: "Distributor" },
]);