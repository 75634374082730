import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  messageContainerStart: {
    display: 'flex',
    padding: '0 5%',
    marginTop: '3px',
    justifyContent: 'flex-start',
  },
  messageContainerEnd: {
    display: 'flex',
    padding: '0 5%',
    marginTop: '3px',
    justifyContent: 'flex-end',
  },
  messageBoxBlue: {
    background: '#2979FF',
    borderRadius: '20px',
    padding: '5px 20px',
    color: '#fff',
    display: 'inline-block',
    maxWidth: '80%',
    marginLeft: '10px',
  },
  messageBoxLight: {
    background: '#F3F3F3',
    borderRadius: '20px',
    padding: '5px 20px',
    color: '#fff',
    display: 'inline-block',
    maxWidth: '80%',
    marginRight: '10px',
  },
  sentText: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Helvetica',
    color: '#828282',
    letterSpacing: '0.3px',
  },
  messageTextWhite: {
    width: '100%',
    letterSpacing: 0,
    float: 'left',
    fontSize: '1.1em',
    wordwrap: 'break-word',
    color: '#fff',
  },
  messageTextDark: {
    width: '100%',
    letterSpacing: 0,
    float: 'left',
    fontSize: '1.1em',
    wordwrap: 'break-word',
    color: '#353535',
  },
}));
