import React, { useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import { useLedger, useParty } from "@daml/react";
import { Button, Grid, Typography, Badge } from "@material-ui/core";
import { useDamlState } from "../../context/DamlContext";
import { useLayoutDispatch, setLoading, unsetLoading } from "../../context/LayoutContext";
import { getTodayString, getTemplateListFromResponse, getQuantityOnOrder } from "../../services/util";
import { contractFilter1 } from "../../services/daml-filters";
import { SearchTextSmField } from "../../components/Inputs";
import CustomTooltip from "../../components/Tooltip/CustomTooltip";
import ModalDialog from '../../components/Modals/NewGeneralModal';
import SnackbarModal from "../../components/Modals/SnackbarModal";
import { requiredPurData } from "../../models/Po";
import { PRODUCT_ADD_EVENT, EVENT_REQUIRED, EVENT_VERIFIED, INVENTORY_CONFIRM_EVENT } from "../../store/actions/constants";
import useProduct, { useItemDetails, useHospitalUser } from "../../hooks/useProduct";
import useInventory from "../../hooks/useInventory";
import useEvent from "../../hooks/useEvent";
import patientIcon  from "../../icons/new-se-patient-icon.svg";
import useStyles from "../surgicalevent/styles";
import { defaultProduct } from "../surgicalevent/edit-product1";
import { createAccountInventoryHospital, createRestockInventoryHospital, 
  createRestockInventoryVendor } from "../inventoryaccount/handle";
import { findoutRestockInventory } from "./inventoryscanhospital";
import AddInventory from "./add-inventory";
import MatchedProducts from "./matched-products";
import useStyles1 from "../purchaseorder/styles";
import useStyles2 from "./styles";


/**
 * Confirm Inventory Title Component
 * @param {Object} classes 
 * @param {Object} classes1  
 * @param {Object} classes2  
 * @param {Function} handleSearch  
 * @returns 
 */
const InvTitle = ({
  classes, classes1, classes2, handleSearch=()=>{}, 
}) => {

  const [searchValue, setSearchValue] = useState('');


  // handle to change search PO
  const handleChangeSearch = (val) => {
    setSearchValue(val);
  };


  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classNames(classes.newSETitlePanel, classes1.newPOTitlePanel, classes2.newInvTitlePanel)}
    >
      <img src={patientIcon} alt="" />
      <CustomTooltip 
        title={`Auto Match will allow you to scan 
                inventory in and match it to an open PO.`}
        arrow
        classes={{tooltip: classes.productTooltip}}
      >
        <Badge badgeContent='i' color="primary" overlap="circular" >
          <Typography variant="inherit" component="span" className="label" >
            Auto Match
          </Typography>
        </Badge>
      </CustomTooltip>

      <SearchTextSmField
        value={searchValue}
        onChange={handleChangeSearch}
        onSubmit={() => handleSearch(searchValue)}
        placeholder="Search PO Number..."
      />
    </Grid>
  );
};

/**
 * Confirm Inventory Body Panel Component
 * @param {Object} classes 
 * @param {Boolean} isEditable 
 * @returns 
 */
const InvPanel = ({
  classes, isEditable=false, 
}) => {

  return (
    <>
      <Grid 
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        className={classes.createPanel}
      >
        <Grid item>
          <AddInventory />
        </Grid>

        <Grid item>
          <MatchedProducts 
            isEditable={isEditable}
          />
        </Grid>
      </Grid>
    </>
  );
};

/**
 * Confirm Inventory Action Buttons Component
 * @param {Object} classes 
 * @param {Object} classes2
 * @param {String} vendor
 * @returns 
 */
const InvActionButtons = ({
  classes, classes2, vendor, setOpen,
}) => {

  const { role } = useDamlState();

  const { product, clearProduct } = useProduct();
  const { restockInventoryData, setRestockInventoryData, setRestockInventoryErrors } = useInventory();
  const { onEventStart, onEventClear, onEventReject } = useEvent();


  // handle to click "Clear" button
  const handleClearClick = () => {
    // default Product
    clearProduct({
      ...defaultProduct,
      sepownership: restockInventoryData.popurchaseordertype,
      sepdistributor: restockInventoryData.povendorid,
    });
    onEventClear();
  };

  // handle to click "Add" button
  const handleAddClick = () => {
    // check requirements of PO data
    let newErrors = requiredPurData(restockInventoryData);
    // console.log("[handleAddClick]", restockInventoryData, newErrors);
    if (Object.keys(newErrors).length > 0) {
      setRestockInventoryErrors({ ...newErrors });
      onEventReject();
      return;
    }

    clearProduct({
      ...product,
      sepownership: restockInventoryData.popurchaseordertype,
      sepdistributor: restockInventoryData.povendorid,
    });
    onEventStart(PRODUCT_ADD_EVENT);
  };

  // handle to click "Cancel" button
  const handleCancelClick = () => {
    setOpen(false);
  };

  // handle to click "Receive" button
  const handleReceiveClick = () => {
    setRestockInventoryData({
      iproductstatus: 'Received',
    });
    onEventStart(INVENTORY_CONFIRM_EVENT);
  };

  // handle to click "Shipped" button
  const handleShipClick = () => {
    setRestockInventoryData({
      iproductstatus: 'Shipped',
    });
    onEventStart(INVENTORY_CONFIRM_EVENT);
  };

  // handle to click "Stocked for Facility" button
  const handleFacilityClick = () => {
    setRestockInventoryData({
      iproductstatus: 'Received',
    });
    onEventStart(INVENTORY_CONFIRM_EVENT);
  };


  return (
    <>
      {
        role === 'Vendor'
        ?
        <>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleClearClick} 
            className={classNames(classes.newCompleteBtn, classes.newInsideAddBtn, "secondary-color")} 
          >
            Clear
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleAddClick} 
            className={classNames(classes.newCompleteBtn, classes.newInsideAddBtn, classes2.addBtn1)} 
          >
            Add
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleFacilityClick} 
            className={classNames(classes.newCompleteBtn)} 
          >
            Stocked for Facility
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleShipClick} 
            className={classes.newCompleteBtn} 
          >
            Shipped
          </Button>
        </>
        :
        <>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleClearClick} 
            className={classNames(classes.newCompleteBtn, classes.newInsideAddBtn, "secondary-color", classes2.completeBtn)} 
          >
            Clear
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleAddClick} 
            className={classNames(classes.newCompleteBtn, classes.newInsideAddBtn, classes2.completeBtn, classes2.addBtn)} 
          >
            Add
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleCancelClick} 
            className={classNames(classes.newCompleteBtn, "secondary-color")} 
          >
            Cancel
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleReceiveClick} 
            className={classes.newCompleteBtn} 
          >
            Receive
          </Button>
        </>
      }
    </>
  );
};

/**
 * Dialog when click "Auto Match" to Confirm Inventory
 * @param {Boolean} open
 * @param {Function} setOpen
 * @param {Array} contracts
 */
export default function ConfirmInventory ({
  open, setOpen, contracts=[], 
}) {
  // console.log("[ConfirmInventory]", contracts);

  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();

  const { role, roleCid, userlistPayload } = useDamlState();

  const layoutDispatch = useLayoutDispatch();

  const ledger = useLedger();
  const party = useParty();
  // Hospital
  const hospital = (role === 'Hospital' ? party : null);
  // Vendor
  const vendor = (role === 'Vendor' ? party : null);

  const { setAll: setDefault, product: productItems, isChanged, clearProduct } = useProduct();
  const { details, setDetails } = useItemDetails();
  const { setInventoryData: setDefaultInventory, restockInventoryData } = useInventory();
  const { hospital: selectedHospital, setHospital } = useHospitalUser();
  const { event, status, onEventRun, onEventEnd } = useEvent();
  // Editable
  const isEditable = false;

  // Toast
  const [openToastWarning, setOpenToastWarning] = useState(false);
  const [warningTitle, setWarningTitle] = useState('');
  const [warningContent, setWarningContent] = useState('');
  const [openToastWarningAccount, setOpenToastWarningAccount] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [toastContent, setToastContent] = useState('');

  const [matchedInvd, setMatchedInvd] = useState(null);
  const [matchedInvds, setMatchedInvds] = useState([]);

  const [snackbarModel, setSnackbarModel] = useState(false);
  
  // handle to submit search Restock Inventory
  const handleSearch = (ponumber) => {
    const found = searchRestock({ 
      ponumber, contracts 
    });
    console.log("[ConfirmInventory] handleSearch", ponumber, found);
    if (found) {
      setDefaultInventory({
        restockInventoryData: found.payload.ipurchaseorderdata,
      });
      if (!hospital) setHospital(found.payload.hospital);
    }
    else {
      setDefaultInventory({
        restockInventoryData: {},
      });
      if (!hospital) setHospital(null);
    }
  };

  const handleCloseRequest = () => {
    if (isChanged) {
      setSnackbarModel(true);
    }
    else {
      handleClose();
    }
  };

  // handle to close
  const handleClose = () => {
    // reset isvirtualmatched, iquantity in contracts
    contracts.forEach(c => {
      c.payload.inventorydata = c.payload.inventorydata.map(invd => ({
        ...invd, 
        isvirtualmatched: false,
        iquantity: getQuantityOnOrder({...invd, iquantityunit: invd.itempquantity ?? invd.iquantity}),
        iquantityunit: invd.itempquantity ?? invd.iquantityunit,
      }));
    });
    setSnackbarModel(false);
    setOpen(false);
  };
  
  // handle to click "OK" in confirm dialog
  const handleConfirmClick = useCallback(() => {
    setOpenToastWarning(false);

    if (!matchedInvd) return;

    const foundTagPONumber = matchedInvd.ipurchaseordernumber;
    if (!foundTagPONumber) return;

    // search in old details
    let newdetails = [...details];
    const detailResult = newdetails.find(({ipurchaseorderdata}) => ipurchaseorderdata.popurchaseorderid === foundTagPONumber);
    if (detailResult) {
      detailResult.inventorydata.push({...matchedInvd});
    }
    else {
      const inventoryResult = contracts.find(c => c.payload.ipurchaseorderdata.popurchaseorderid === foundTagPONumber);
      // console.log("[ConfirmInventory] handleConfirmClick", inventoryResult);
      newdetails.push({
        ipurchaseorderdata: inventoryResult.payload.ipurchaseorderdata,
        inventorydata: [{...matchedInvd}],
      });
    }

    // add into details
    setDetails(newdetails);
    // add into matchedInvds
    setMatchedInvds(m => [...m, {...matchedInvd, ponumber: foundTagPONumber}]);

  }, [matchedInvd, details, setDetails, contracts]);

  // handle to click "Receive" or "Shipped" or "Stock for Facility" button in confirm dialog
  const handleConfirmAction = useCallback(async () => {
    console.log("[ConfirmInventory] handleConfirmAction", matchedInvds);

    if (!matchedInvds || !matchedInvds.length) {
      onEventEnd();
      return;
    }

    // spinner
    setLoading(layoutDispatch);
    onEventRun();

    for (let i in matchedInvds) {
      const inventorydata = matchedInvds[i];
      let resSuccess = false;
      if (role === 'Hospital') {
        // add new Restock Inventory
        const res = await createRestockInventoryHospital({
          ledger, 
          roleCid,
          inventorydata,
        });

        resSuccess = (res.length > 0);
      }
      else if (role === 'Vendor') {
        // create new inventory and confirm
        const res = await createRestockInventoryVendor({
          ledger,
          roleCid,
          hospital: selectedHospital,
          inventorydata,
          newiproductstatus: restockInventoryData.iproductstatus,
        });
  
        const templateInv = getTemplateListFromResponse(res, "RestockInventory");
        resSuccess = (templateInv.length > 0);
      }

      if (resSuccess) {
        setToastContent(`Inventroy Confirmed as Stocked to ${inventorydata.ponumber} at ${selectedHospital} Hospital`);
      }
      else {
        setToastContent(`Failed to receive to ${inventorydata.ponumber}`);
      }
      setOpenToast(true);
    }
  
    unsetLoading(layoutDispatch);
    onEventEnd();

    setOpen(false);

  }, [layoutDispatch, onEventRun, onEventEnd, ledger, roleCid, matchedInvds, setOpen, role, selectedHospital, 
    restockInventoryData]);

  // handle to click "Yes" button in "Match Unsuccessful" dialog
  const handleCreateAccount = useCallback(async () => {
    // console.log("[ConfirmInventory] handleCreateAccount");

    // spinner
    setLoading(layoutDispatch);
    onEventRun();

    const invdata = {
      iproduct: {
        ...productItems,
        sepownership: restockInventoryData.popurchaseordertype,
        sepdistributor: restockInventoryData.povendorid,
      },
      iproductstatus: 'Received',
      ireceiveddate: getTodayString(),
      iquantityunit: productItems.iquantityunit,
    };

    if (role === 'Hospital') {
      const res = await createAccountInventoryHospital({
        ledger, 
        roleCid,
        inventorydata: invdata,
        userlistPayload,
      });
      if (res) {
        setToastContent(`Successfully Added in Inventory`);
        setOpenToast(true);
      }
    }
  
    unsetLoading(layoutDispatch);
    onEventEnd();

    clearProduct(defaultProduct);

  }, [layoutDispatch, onEventRun, onEventEnd, ledger, roleCid, productItems, userlistPayload, role, restockInventoryData,
    clearProduct]);

  // Click "Add" button
  const addConfirmInventory = useCallback(() => {

    let foundTagPONumber = null;
    let msgTitle, msgContent, matched=[];
    if (role === 'Hospital') {
      const inventoryDataList = [{
        iproduct: {
          ...productItems,
          sepownership: restockInventoryData.popurchaseordertype,
          sepdistributor: restockInventoryData.povendorid,
        },
        iproductstatus: 'Received',
        ireceiveddate: getTodayString(),
        ipurchaseordernumber: restockInventoryData.popurchaseorderid,
        iquantityunit: productItems.iquantityunit ?? 1,
        iquantity: productItems.iquantity ?? 1,
      }];
      matched = findoutRestockInventory({inventoryDataList, contracts});
    }
    else if (role === 'Vendor') {
      const inventoryDataList = [{
        iproduct: {
          ...productItems,
          sepownership: restockInventoryData.popurchaseordertype,
          sepdistributor: vendor,
        },
        ipurchaseordernumber: restockInventoryData.popurchaseorderid,
        iquantityunit: productItems.iquantityunit ?? 1,
        iquantity: productItems.iquantity ?? 1,
        icourier: restockInventoryData.icourier,
        itrackingstatus: restockInventoryData.itrackingstatus,
      }];
      matched = findoutRestockInventory({inventoryDataList, contracts, matchedStatus: ['BackOrder','Opens']});
    }

    if (matched && matched.length) {
      foundTagPONumber = matched[0].ipurchaseordernumber;
      setMatchedInvd(matched[0]);
    }
    else {
      setMatchedInvd(null);
    }
    if (!!foundTagPONumber) {
      msgTitle = '';
      msgContent = `Success your Product was matched correctly to ${foundTagPONumber}`;
      // toast
      setWarningTitle(msgTitle);
      setWarningContent(msgContent);
      setOpenToastWarning(true);

      clearProduct(defaultProduct);
    }
    else {
      // toast
      setOpenToastWarningAccount(true);
    }

    onEventEnd();
    
  }, [contracts, productItems, restockInventoryData, role, vendor, onEventEnd, clearProduct]);
  
  
  useEffect(() => {
    console.log("[ConfirmInventory] set default data.");
    if (!open) return;
    setDefault({
      details: [],
      hospital,
      product: defaultProduct,
    });
    setDefaultInventory({
      restockInventoryData: {
        povendorid: vendor,
      },
    });
    setMatchedInvds([]);
    onEventEnd();
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (!open) return;
    if (event) {
      if (status === EVENT_REQUIRED || status === EVENT_VERIFIED) {
        console.log("[ConfirmInventory] On Start Event", event);
        if (event === PRODUCT_ADD_EVENT && status === EVENT_VERIFIED) {
          addConfirmInventory();
        }
        else if (event === INVENTORY_CONFIRM_EVENT && status === EVENT_REQUIRED) {
          // confirm handler
          handleConfirmAction();
        }
      }
    }
  }, [open, event, status, addConfirmInventory, handleConfirmAction]);


  return (
    <>
      <ModalDialog
        open={open}
        setOpen={setOpen}
        handleCloseRequest={handleCloseRequest}
        title={(
          <InvTitle 
            classes={classes}
            classes1={classes1}
            classes2={classes2}
            handleSearch={handleSearch}
          />
        )}
        content={(
          <InvPanel
            classes={classes1}
            isEditable={isEditable}
          />
        )}
        actions={(
          <InvActionButtons
            classes={classes}
            classes2={classes2}
            vendor={vendor}
            setOpen={setOpen}
          />
        )}
      />

      <SnackbarModal
        open={openToastWarning}
        setOpen={setOpenToastWarning}
        type="warning"
        title={warningTitle}
        content={warningContent}
        vertical="center"
        horizontal="center"
        width={500}
        height={130}
        transition="fade"
        options={{
          isClose: false,
          delay: 0,
        }}
        actions={[
          {
            label: 'Ok',
            handle: handleConfirmClick,
          },
          {
            label: 'Cancel',
            handle: () => { onEventEnd(); },
          }
        ]}
      />

      <SnackbarModal
        open={openToastWarningAccount}
        setOpen={setOpenToastWarningAccount}
        type="warning"
        title="Match Unsuccessful"
        content="Would you like to add it  to your inventory anyway?"
        vertical="center"
        horizontal="center"
        width={500}
        height={117}
        transition="fade"
        className={classes2.warningAutomatch}
        options={{
          delay: 0,
        }}
        actions={[
          {
            label: 'Yes',
            handle: handleCreateAccount,
          },
          {
            label: 'No',
            handle: () => { onEventEnd(); },
          },
          {
            label: (<Badge badgeContent='i' color="primary" overlap="circular" 
              anchorOrigin={{horizontal: 'left', vertical: 'top'}} className={classes2.warningAutomatchBadge}>
              <Typography variant="h6" component="p">
                When using Automatch make sure the Distributor and Ownership are
              </Typography>
              <Typography variant="h6" component="p">
                selected correctly.
              </Typography>
            </Badge>)
          }
        ]}
      />

      <SnackbarModal
        open={openToast}
        setOpen={setOpenToast}
        title=""
        content={toastContent}
        width={330}
        height={88}
        className={classes.toastMedium}
      />
      
      <SnackbarModal
        open={snackbarModel}
        setOpen={setSnackbarModel}
        type="warning"
        title={'Warning'}
        content={'Do you want to exit without saving?'}
        vertical="center"
        horizontal="center"
        width={500}
        height={130}
        transition="fade"
        className={classNames(classes.toastLarge, classes.toastWarning)}
        options={{
          delay: 500,
        }}
        actions={[
          {
            label: 'Yes',
            handle: () => {
              handleClose();
            },
          },
          {
            label: 'No',
            handle: () => {
              setSnackbarModel(false);
            },
          }
        ]}
      />
    </>
  );
}


/**
 * Search Restock Inventory with PO Number
 * @param {String} ponumber 
 * @param {Array} contracts 
 * @returns {Object}
 */
export const searchRestock = ({ ponumber, contracts=[] }) => {
  if (contracts && contracts.length) {
    const found = contractFilter1(contracts, {
      'list.ipurchaseorderdata.popurchaseorderid': [ponumber], 
    });
    if (found && found.length) return found[0];
  }
  return null;
};
