import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  dialog: {
    // [theme.breakpoints.down("lg")]: {},
    '& .MuiDialog-paper': {
      border: '1px solid rgba(0, 0, 0, 0.29)',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '10px',
      marginTop: 0,
      marginBottom: 0,
      '& .MuiDialogTitle-root': {
        width: ({ width }) => `${width}px`,
        height: '51px',
        position: 'relative',
        paddingTop: '10px',
        paddingBottom: '10px',
        '& > .MuiTypography-root': {
          height: '100%',
        },
        '& .MuiGrid-root': {
          height: '100%',
          fontWeight: 700,
          fontSize: '25px',
          lineHeight: '30px',
          color: '#000000',
        },
        '& .MuiButtonBase-root.MuiIconButton-root': {
          position: 'absolute',
          top: '3px',
          right: '9px',
          padding: '10px',
          '& img': {
            filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
          },
        },
      },
      '& .MuiDialogContent-root': {
        width: ({ width }) => `${width}px`,
        height: ({ height }) => `${height}px`,
        position: 'relative',
        padding: '0px',
      },
      '& .MuiDialogContent-dividers': {
        borderColor: '#CCCCCC',
      },
      '& .MuiDialogActions-root': {
        position: 'absolute',
        bottom: '18px',
        right: '26px',
        padding: 0,
        '& .MuiButtonBase-root.MuiIconButton-root': {
          padding: '1px',
        },
      },
    },
  },
  dialogDM: {
    // [theme.breakpoints.down("lg")]: {},
    '& .MuiDialog-paper': {
      border: '1px solid rgba(0, 0, 0, 0.29)',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '10px',
      marginTop: 0,
      marginBottom: 0,
      backgroundColor:"#1c3c74",
      '& .MuiDialogTitle-root': {
        width: ({ width }) => `${width}px`,
        height: '51px',
        position: 'relative',
        paddingTop: '10px',
        paddingBottom: '10px',
        '& > .MuiTypography-root': {
          height: '100%',
        },
        '& .MuiGrid-root': {
          height: '100%',
          fontWeight: 700,
          fontSize: '25px',
          lineHeight: '30px',
          color: '#000000',
        },
        '& .MuiButtonBase-root.MuiIconButton-root': {
          position: 'absolute',
          top: '3px',
          right: '9px',
          padding: '10px',
          '& img': {
            filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
          },
        },
      },
      '& .MuiDialogContent-root': {
        width: ({ width }) => `${width}px`,
        height: ({ height }) => `${height}px`,
        position: 'relative',
        padding: '0px',
      },
      '& .MuiDialogContent-dividers': {
        borderColor: '#CCCCCC',
      },
      '& .MuiDialogActions-root': {
        position: 'absolute',
        bottom: '18px',
        right: '26px',
        padding: 0,
        '& .MuiButtonBase-root.MuiIconButton-root': {
          padding: '1px',
        },
      },
    },
  },
}));
