import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { Close } from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';
import { getTemplateListFromResponse } from "../../services/util";
import successIcons from "../../icons/check-circle.svg";
import useStyles from "./styles";


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/**
 * Dialog when Complete Surgical Event
 * @param {String} open 
 * If open is "POV", created PO and sent PO to vendor
 * Else if open is "PO", created PO and should send PO
 * Else if open is "POF", created PO and should send PO, but couldn't send PO because of Ownership
 * Else if open is "PPV", created only Post Procedure Form
 * Else if open is "PPH", created Post Procedure Form and should create PO
 * Else, not should show dialog
 * @param {Function} setOpen 
 * @param {String} text 
 * @param {String} heading 
 * @param {String} customButton 
 * @param {Function} handleRequest 
 */
export default function SECompletedDialog ({
  open, setOpen, text="", heading="Successfully Completed", customButton="Surgical Event",
  handleRequest,
}) {

  // console.log("[SECompletedDialog]", open, !!open);

  const handleSuccessModalClose = () => {
    setOpen(false);
  }

  /**
   * go to PurchaseOrder page
   * click "Surgical Event" button
   */
  const handleCompletePurchaseOrder = () => {
    setOpen(false);
    if (typeof handleRequest === 'function') {
      handleRequest();
    }
    else {
      // history.push("/app/purchaseorder-list");
      history.push("/app/surgicalevent");
    }
  }

  const history = useHistory();
  const classes = useStyles();


  return (
    <Dialog
      open={!!open}
      onClose={handleSuccessModalClose}
      className={classes.customRoot}
      style={{textAlign: 'center'}}
      aria-labelledby="draggable-dialog-title"
    >
        
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title1">
        <div className="customClose" onClick={handleSuccessModalClose}><Close /></div>
        <div className="alert-icon"><img alt="" src={successIcons} /></div>
        <span className="warningHeading">{heading}</span> 
      </DialogTitle>

      <DialogContent>
        <DialogContentText component={'div'}>
          {text !== "" && <p>{text}</p>}

          {(open !== 'PPH' && text === "") && 'You have successfully completed the Surgical Event. A Post Procedure Form has been created and sent to the Manufacturer.'}
          {open === 'PO' && <Alert severity="info">The Po Was Automatically Created and Sent to the Purchasing Department.</Alert>}
          {open === 'POV' && <Alert severity="info">The Po Was Automatically Created and Sent to the Purchasing Department and the Manufacturer.</Alert>}
          {open === 'POF' && <Alert severity="warning">The Po Was Automatically Created but Some Product Ownership Was Not Defined and Could Not Be Sent Automatically to the Manufacturer.</Alert>}
          {(open !== "" && open !== true) &&
            <div className="btnPopGroup">
              {(open === 'PPH' || open === 'PPV') && <p>Next step, Create Purchase Order for Surgical Event on the Purchase Order Screen.</p>}
              {open === 'PO' && <p>Next step, Edit and Send PO to the Manufacturer on the Purchase Order Screen.</p>}
              {(open === 'POV' || open === 'POF') && <p>Next step, Check Status of the PO on the Purchase Order Screen.</p>}
            </div>
          }

          <Button className="yesBtn" variant="contained" color="primary" onClick={handleCompletePurchaseOrder}>
            {customButton}
          </Button>
        
        </DialogContentText>
      </DialogContent>
      
    </Dialog>
  );
}

/**
 * get Complete Result from response
 * @param {Object} res 
 * @param {Boolean} isAutoVendor 
 * @returns {String} POV, PO, POF, PPV, PPH
 */
export function getCompleteResult(res, isAutoVendor) {
  let createdTemplates = "";
  const createdPOV = getTemplateListFromResponse(res, "PurchaseOrderVendor").length;
  const createdPO = getTemplateListFromResponse(res, "PurchaseOrder").length;
  if (createdPOV && createdPOV === createdPO) createdTemplates = "POV";
  else if (createdPO) createdTemplates = !isAutoVendor ? "PO" : "POF";
  else if (getTemplateListFromResponse(res, "PostProcedureVendor").length) createdTemplates = "PPV";
  else if (getTemplateListFromResponse(res, "PostProcedureHospital").length) createdTemplates = "PPH";

  console.log("[getCompleteResult]", res, createdTemplates);
  
  return createdTemplates;
}

/**
 * get Complete Message from response
 * @param {Object} res 
 * @param {Boolean} isAutoVendor 
 * @returns {Object}
 */
export function getCompleteMessage(res, isAutoVendor) {

  const msg = {};

  msg.content = 'You have completed the surgical event successfully';
  return msg;

  /* 
  // For Old design
  const result = getCompleteResult(res, isAutoVendor);

  if (result === 'PPH' || result === 'PPV') 
    msg.content = <>You have successfully Completed <br/>
      the Surgical Event. A post procedure form has <br/>
      been created and sent to the Manufacturer</>;
  else if (result === 'PO') 
    msg.content = <>You have successfully Completed the Surgical Event. <br/>
      The PO was automatically created and sent to the <br/>
      Purchasing Department</>;
  else if (result === 'POV') 
    msg.content = <>You have successfully Completed the Surgical Event. <br/>
      The PO was automatically created and sent to the <br/>
      Purchasing Departmentand the Manufacturer.</>;
  else if (result === 'POF') 
    msg.content = <>You have successfully Completed the Surgical Event. <br/>
      The PO was created however some product ownership <br/>
      was not defined and will need to be adjusted <br/>
      by Purchasing.</>;

  if (result === 'POF') msg.color = 'yellow';

  return msg;
  */
}