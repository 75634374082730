import React, { useState, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { useStyles } from "../styles";


export default function AlertModal({
  modaltitle,
  modalText = "Are you sure to delete this?",
  buttontitle = null,
  buttonicon = <Delete />,
  okButtontitle = "Ok",
  cancelButtontitle = "Cancel",
  setDetailValue,
  contract = {},
  modalOpenController = false,
  isButtonVisible = true,
  isDefaultVisible = false,
  cancelHandler = null }) {

  const classes = useStyles();

  const [modalOpenState, setModalOpenState] = useState(false);

  useEffect(() => {
    if (isDefaultVisible) setModalOpenState(true);
  }, [isDefaultVisible]);

  const handleConductModalAccept = () => {
    setDetailValue(contract);
    setModalOpenState(false);
  };

  const handleConductModalClose = () => {
    setModalOpenState(false);
  };
  

  return (
    <>
      <Dialog
        open={modalOpenState || modalOpenController}
        onClose={handleConductModalClose}
        classes={{ paper: modalText.length > 50 ? classes.dialogPaperMd : classes.dialogPaperSm }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle} >{modaltitle}</DialogTitle> 
      
        <DialogContent className={classes.dialogContent} >

          {modalText}

        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            handleConductModalClose();
            if (typeof cancelHandler === "function" && cancelHandler) cancelHandler();
          }} color="primary">
            {cancelButtontitle}
          </Button>
          <Button onClick={handleConductModalAccept} color="primary" autoFocus>
            {okButtontitle}
          </Button>
        </DialogActions>
      </Dialog>

      {isButtonVisible && (
        buttontitle
        ?
        <Button
          color="primary"
          size="small"
          className={classes.button}
          variant="contained"
          onClick={() => { setModalOpenState(true); }}
        > 
          {buttontitle}
        </Button>
        :
        <IconButton
          color="primary"
          size="small"
          className={classes.button}
          variant="contained"
          onClick={() => { setModalOpenState(true); }}
        > 
         {buttonicon}
        </IconButton>
      )}
    </>
  );
}