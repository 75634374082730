import { makeStyles } from "@material-ui/styles";
export default makeStyles(theme => ({

  readOnlyFont: {
    fontFamily: 'Helvetica',
    fontWeight: '700',
    fontSize: '15px',
    lineHeight: '100%',
    color: '#000000',
    padding: '10px'
  },
  readOnlyFontDM: {
    fontFamily: 'Helvetica',
    fontWeight: '700',
    fontSize: '15px',
    lineHeight: '100%',
    color: '#FFFFFF',
  },
  labelPaddings: {
    '& .MuiGrid-item': {
      paddingTop: '12px',
      paddingBottom: '12px',
    }
  },
  divider: {
    height: 20,
    marginLeft: 10,
  },
  gridPad: {
    paddingBottom: '2vh !important'
  },
  secondaryTitle: {
    fontStyle: 'semiBold',
    fontSize: '20px',
    lineHeight: '24px',
    paddingLeft: '2vw'
  },
  secondaryTitleDM: {
    fontStyle: 'semiBold',
    fontSize: '20px',
    lineHeight: '24px',
    paddingLeft: '2vw',
    color:"#FFFFFF"
  },
  chipTitle: {
    paddingLeft: '2vw'
  },
  setupButton1: {
    backgroundColor: '#1C3C74',
    color: "white",
    padding: '9px 16px',
    textTransform: 'capitalize',
    float: 'right',
    '&:hover': {
      backgroundColor: '#183C73',
    },
    '& .desktop': {

      '@media (max-width:1024px)': {
        display: "none",
      },
    },
    '& .mobile': {
      display: "none",
      '@media (max-width:1024px)': {
        display: "block",
      },
    },
  },


  newTooltipArroww: {
    color: '#5C5C5C',
    borderColor: '#CCCCCC',
  },
  newTooltip: {
    background: '#E1DDDD',
    border: '1px solid #CCCCCC',
    boxSizing: 'border-box',
    borderRadius: '4px',
    padding: 0,
    marginTop: 0,
    '& .MuiButtonGroup-root': {
      paddingTop: '3px',
      paddingBottom: '2px',
      '& .MuiButton-root': {
        borderWidth: 0,
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: '16px',
        textAlign: 'start',
        paddingTop: '3px',
        paddingBottom: '3px',
        textTransform: 'capitalize',
        color: '#000000',
        '&:hover': {
          background: '#F7CD37',
          color: '#5C5C5C',
        },
      },
    },
  },
  newDIATitlePanel: {
    '& .name': {
      fontWeight: 700,
      fontSize: '20px',
      minWidth: '170px',
      maxWidth: '600px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      color: '#1C3C74',
    },
    '& .labels': {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
      color: '#000000',
      '&:not(:first-child)': {
        marginLeft: '55px',
      },
    },
    '& .value': {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '24px',
      marginLeft: '13px',
    },
  },

  MuiDialogTitleRoot: {
    paddingTop: '3px',
    paddingBottom: '2px',
  },
  label: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#000000',
    alignSelf: 'center',
  },
  secondarybtn: {
    background: "#5C5C5C",
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    color: "#fff",
    borderRadius: '5px',
  },
  fontCaseTransform: {
    textTransform: 'capitalize'
  },
  primarybtn: {
    background: "#1C3C74",
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    color: "#fff",
    borderRadius: '5px',
  },
  primarybtnDM: {
    background: "#F7CD37",
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    color: "#1C3C74",
    borderRadius: '5px',
  },
  cancelbtnDM:{
    background: "#F7CD37",
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    color: "#1C3C74",
    borderRadius: '5px',
  },
  actionbtns: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end !important',
  },
  centerCell: {
    justifyContent: 'center !important',
    '& .MuiDataGrid-columnHeaderDraggableContainer > .MuiDataGrid-columnHeaderTitleContainer': {
      justifyContent: 'center !important',
    },
  },

  // create payment popups and edit payment (newDIATitlePanel)
  popUpTitle: {
    fontStyle: 'normal;',
    fontWeight: 700,
    fontSize: '25px;',
    lineHeight: '30px;',
    color: '#1C3C74'
  },
  popUpTitleDM: {
    fontStyle: 'normal;',
    fontWeight: 700,
    fontSize: '25px;',
    lineHeight: '30px;',
    color: '#F7CD37'
  },
  popUpLabel: {
    fontWeight: 500,
    fontSize: '15px;',
    lineHeight: "18px;",
    color: '#5C5C5C;',
    paddingLeft: '13px'
  },
  popUpLabelDM: {
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: "18px",
    color: '#FFFFFF',
    paddingLeft: '13px'
  },
  confirmLabel: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px;',
    color: '#5C5C5C;'
  },
  confirmLabelDM: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px;',
    color: '#FFFFFF'
  },
  newFont: {
    fontSize: '13.7px'
  },
  closeButton: {
    position: 'absolute;',
    top: '10px;'
  },
  noteTitle: {
    fontWeight: 500,
    fontSize: '14px;',
    lineHeight: '16px;',
    color: '#000000',
    paddingLeft: '13px',
  },
  noteTitleDM: {
    fontWeight: 500,
    fontSize: '14px;',
    lineHeight: '16px;',
    color: '#FFFFFF',
    paddingLeft: '13px',
  },
  noteFieldDM:{
    width: '87%',
    border:"1px solid #FFFFFF !important", 
    input: {
      color: "white"
    }
  },
  customDateField: {
    position: 'absolute !important',
    top: '0px',
    left: '0px',
    width: '63px',
    height: '30px',
    margin: '0px',
  },
  buttongrpDM: {
    width: '112%;',
    '& .MuiInputBase-input': {
      height: '13px',
      color:"#FFFFFF"
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '0px',
      border:"1px solid #FFFFFF",
      '& Button': {
        width: '176.1%;',
        color:"#FFFFFF"
      },

    },
  },
  buttongrp: {
    width: '112%;',
    '& .MuiInputBase-input': {
      height: '13px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '0px',
      '& Button': {
        width: '176.1%;',
      },

    },
  },
  customSelection: {
    position: 'absolute',
    right: '5.1%',
    zIndex: 3,
    '& .MuiSelect-root': {
      '& img': {
        right: '33px',
      }
    }
  },
  custSuccessSelection: {
    '& .MuiInputBase-root': {
      border: '1px solid green !important'
    }
  },
  custFailSelection: {
    '& .MuiInputBase-root': {
      border: '1px solid red !important'
    }
  },
  positionRelative: {
    position: 'relative'
  },
  noteWidth: {
    width: '87%'
  },
  customRoot: {
    '& .MuiDialog-paperWidthSm': {
      width: '485px'
    },
  },
  customRootDM: {
    '& .MuiDialog-paperWidthSm': {
      width: '485px',
      backgroundColor:"#1c3c74"
    },
  },
  uploadFileStyle: {
    margin: '0px 0px 0px auto',
    width: '235px',
    height: 'auto',
    '& .MuiDropzoneArea-root': {
      marginBottom: '0px',
      borderWidth: '0px',
      minHeight: 'inherit',
      '& .MuiDropzoneArea-textContainer': {
        '& > p.MuiTypography-root': {
          margin: '0px !important',
        },
        '& > label': {
          marginTop: '10px !important',
          marginBottom: '10px',
          padding: '0px',
          width: '100%',
          height: '29px',
          background: '#FFFFFF',
          border: '2px solid #CCCCCC',
          borderRadius: '5px',
          '& .drag-drop-btn': {
            fontWeight: 400,
            fontSize: '10px',
            lineHeight: '11px',
            color: '#5C5C5C',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '100%',
            paddingLeft: '10px',
            paddingRight: '9px',
          },
        },
      },
      '& .MuiDropzonePreviewList-root': {
        width: '100%',
        margin: '0px',
        '& .MuiDropzonePreviewList-imageContainer': {
          padding: '0px',
          maxWidth: 'inherit',
          flexBasis: '100%',
          '& .preview-item': {
            height: '25px',
            background: '#FFFFFF',
            border: '1px solid #CCCCCC',
            borderRadius: '4px',
            fontWeight: 400,
            fontSize: '10px',
            lineHeight: '12px',
            color: '#5C5C5C',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingLeft: '10px',
            paddingRight: '9px',
            '& .MuiDropzonePreviewList-image': {
              height: '15px',
              opacity: 1,
              marginRight: '10px',
            },
          },
          '& .MuiDropzonePreviewList-removeButton': {
            opacity: '1 !important',
            backgroundColor: 'inherit',
            boxShadow: 'none',
            '& .MuiSvgIcon-root': {
              width: '0.7em',
              height: '0.7em',
              color: '#F24E1E',
            },
          },
        },
      },
    },
  },
  uploadFileStyleDM: {
    margin: '0px 0px 0px auto',
    width: '235px',
    height: 'auto',
    '& .MuiDropzoneArea-root': {
      marginBottom: '0px',
      borderWidth: '0px',
      minHeight: 'inherit',
      '& .MuiDropzoneArea-textContainer': {
        backgroundColor:"#1c3c74",
        '& > p.MuiTypography-root': {
          margin: '0px !important',
        },
        '& > label': {
          marginTop: '10px !important',
          marginBottom: '10px',
          padding: '0px',
          width: '100%',
          height: '29px',
          background: '#1c3c74',
          border: '2px solid #FFFFFF',
          borderRadius: '5px',
          '& .drag-drop-btn': {
            fontWeight: 400,
            fontSize: '10px',
            lineHeight: '11px',
            color:"#FFFFFF",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '100%',
            paddingLeft: '10px',
            paddingRight: '9px',
          },
        },
      },
      '& .MuiDropzonePreviewList-root': {
        width: '100%',
        margin: '0px',
        '& .MuiDropzonePreviewList-imageContainer': {
          padding: '0px',
          maxWidth: 'inherit',
          flexBasis: '100%',
          '& .preview-item': {
            height: '25px',
            background: '#1c3c74',
            border: '1px solid #CCCCCC',
            borderRadius: '4px',
            fontWeight: 400,
            fontSize: '10px',
            lineHeight: '12px',
            color: '#FFFFFF',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingLeft: '10px',
            paddingRight: '9px',
            '& .MuiDropzonePreviewList-image': {
              height: '15px',
              opacity: 1,
              marginRight: '10px',
            },
          },
          '& .MuiDropzonePreviewList-removeButton': {
            opacity: '1 !important',
            backgroundColor: 'inherit',
            boxShadow: 'none',
            '& .MuiSvgIcon-root': {
              width: '0.7em',
              height: '0.7em',
              color: '#F24E1E',
            },
          },
        },
      },
    },
  },
  cancelNotification: {
    '& .MuiAlert-message .MuiTypography-root.content': {
      fontSize: '18px !important',
      lineHeight: '16px !important',
      color: '#000000 ',
    },
  },
  cancelNotificationDM: {
    '& .MuiAlert-message .MuiTypography-root.content': {
      fontSize: '18px !important',
      lineHeight: '16px !important',
      color: '#FFFFFF ',
    },
  },
  infoMsg: {
    display: 'inline-block',
    color: '#B7B3B3',
    fontWeight: 400,
    fontSize: '10px;',
    paddingLeft: '5px',

  },
  dashboardTable:{
    "& .lightModeTable":{
        "& .MuiTableCell-root":{
            padding:5,
            border:"none",
            color:"#1C3C74",
        }
    },
    "& .darkModeTable":{
        "& .MuiTableCell-root":{
            padding:5,
            border:"none",
            color:"#FFFFFF",
        },
    },
    "& .tableHeaderLight":{
        "& .MuiTableCell-root":{
            fontWeight:700,
        }
    },
    "& .tableHeaderDark":{
      "& .MuiTableCell-root":{
        fontWeight:700,
          backgroundColor:"#1C3C74"
      }
  }
},
  viewFile:{
    height: 95, 
    width: 260, 
    overflowY: "scroll",
  },
  viewFileEdit:{
    marginTop:20,
    height: 95, 
    width: 200, 
    overflowY: "scroll",
  },
  viewFileDM:{
    color:"#FFFFFF",
    height: 95, 
    width: 260, 
    overflowY: "scroll",
  },
  viewFileEditDM:{
    color:"#FFFFFF",
    marginTop:20,
    height: 95, 
    width: 200, 
    overflowY: "scroll",
  },
  singleFile:{
    display: "flex",
    justifyContent: "space-between",
    border:"1px solid #CCCCCC",
    padding:2,margin:5
  }
}));
