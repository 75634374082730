import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  sampleFilePreview: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '18px',
    color: '#CCCCCC',
    margin: '0px 0px 0px 0px',
    '&.black': {
      color: '#000000',
    },
  },
  newCompleteBtnResetPassword: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '22px',
    letterSpacing: '0em',
    backgroundColor: '#1C3C74',
    textTransform: 'capitalize',
    padding: '11px 27px 11px 27px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    width: "275px",
    '&:hover': {
      backgroundColor: '#1C3C74d9',
    },
    '&.secondary-color': {
      backgroundColor: '#5C5C5C',
      '&:hover': {
        backgroundColor: '#5C5C5Cd9',
      },
    },
  },
  detailedContainer: {
    paddingTop: '12px',
    width: '456px',
    margin: '0px 0px 0px 0px'
  },
  pdfLogos: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    // margin: '0px 0px 2px 38%'
  },
  actionBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 1vw 2vh 0',
  },
  imgNew: {
    width: '16vw',
    position: 'relative',
    bottom: '34px',
    left: '0px',
    padding: '3px',
    color: '#5C5C5C',
    border: '1px solid #CCCCCC',
    height: '25px',
    display: 'flex',
    fontSize: '10px',
    background: '#FFFFFF',
    alignItems: 'center',
    fontWeight: '400',
    lineHeight: '12px',
    paddingLeft: '10px !important',
    borderRadius: '4px',
    paddingRight: '9px',
    justifyContent: 'flex-start',
  },
  gridSubItem: {
    padding: '1px 0px 2px 10px !important'
  },
  detailedOverflow: {
    overflowX: 'scroll',
    height: '400px'
  },
  detailedOverflow1: {
    overflowX: 'scroll',
    height: '324px'
  },
  accordion_main: {
    width: '289px',
  },
  accordion_main1: {
    width: '221px',
  },
  addActionsBtn: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  popUpTitle1: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '22px',
    lineHeight: '26px',
    color: '#1C3C74',
  },
  uploadFile1: {
    margin: '0px 0px 0px auto',
    width: '16vw !important',
    height: '170px !important',
    border: "dashed #CCCCCC",
    borderWidth: '0px 2px 2px 2px',
    '& .MuiDropzoneArea-root': {
      '& .MuiDropzoneArea-text': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#1C3C74',
        color: '#FFFFFF',
        height: '35px',
        fontWeight: '800',
        fontSize: '15px',
        lineHeight: '18px',
        fontFamily: 'Lato',
        fontStyle: 'normal',
      },
      '& .MuiDropzoneArea-textContainer': {
        '& > label': {
          marginTop: '10px !important',
          marginBottom: '10px',
          padding: '0px',
          width: '100%',
          height: '29px',
          background: '#FFFFFF',
          border: '0px !important',
          boxShadow: 'none',
          '& .drag-drop-btn': {
            fontWeight: 400,
            fontSize: '10px',
            lineHeight: '11px',
            color: '#5C5C5C',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '100%',
            paddingLeft: '10px',
            paddingRight: '9px',
          },
        },
      },
      '& .MuiDropzonePreviewList-root': {
        '& .MuiDropzonePreviewList-imageContainer': {
          '& .preview-item': {
            margin: '23px 0px 0px 0px',
            height: '25px',
          },
          '& .MuiDropzonePreviewList-removeButton': {
            margin: '23px 0px 0px 0px',
          },
        },
      },
    },
  },
  dropZone: {
    background: 'red',
    '& > .MuiDropzoneArea-root': {
      width: '83%',
      border: 'dashed',
      minHeight: '205px',
    }
  },
  comment: {
    marginTop: '7px',
    display: 'block',
  },
  textFormation: {
    textTransform: "capitalize"
  },
  btnAddress: {
    background: '#1C3C74',
    borderRadius: '5px',
    margin: '4px',
    width: '15%',
    height: '20%',
    minWidth: '35px',
    minHeight: '26px',
    justifyContent: 'normal'
  },
  cardItem: {
    width: '700px',
    maxWidth: '100%',
    margin: '0 auto',
    '& .MuiCardContent-root': {
      padding: 0
    },
    '& .MuiListItem-gutters': {
      padding: 0,
    },
    '& .MuiListItem-root': {
      display: 'inherit',
      borderBottom: 'solid 1px #cccccc',
      padding: '0px',
      margin: '0px',
    },
    '& .MuiListItem-root:last-child': {
      borderBottom: 'none',
      paddingBottom: '0px',
      marginBottom: '0px',
    },
    '& .MuiTypography-h5': {
      width: '100%',
      marginBottom: '16px',
      color: 'rgba(0, 0, 0, 0.87)',
      '& img': {
        float: 'right',
      }
    },
    '& .MuiOutlinedInput-input': {
      padding: '0 16px',
      height: '40px',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#183C73 !important;'
    },
    '& .MuiFormLabel-root.Mui-focused.Mui-error': {
      color: '#F44336 !important;'
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#183C73 !important;'
    },
    '& .Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#F44336 !important;'
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: '#ffffff !important;'
    },
    '& .MuiButton-label': {
      textTransform: 'capitalize',
    },
    '& .label-width': {
      width: '180px',
    },
    '& .label': {
      display: 'inline-block',
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '16px',
      marginRight: '32px',
      marginBottom: '8px',
      '@media (max-width:1024px)': {
        width: '200px',
      },
      minWidth: '250px',
    },
    '& .value': {
      display: 'inline-block',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: '16px',
    },
    '& .MuiButton-text.MuiButton-textPrimary': {
      color: '#183C73 !important;'
    },
    '& .redbtn': {
      color: '#F44336'
    },
    '& .greenbtn': {
      color: '#4CAF50'
    },
    '& .form-footer': {
      borderTop: '1px solid #cccccc',
      minHeight: '40px',
      height: '56px',
      padding: '10px 30px',
    },
    '& .right-buttons': {
      float: 'right;'
    },
    '& .right-buttons .MuiButton-root': {
      margin: '0 5px;'
    },
    '& .right-buttons .MuiButton-root:last-child': {
      marginRight: '0px;'
    },
    '& .MuiButton-outlinedPrimary': {
      backgroundColor: '#183C73 !important;',
      color: '#fff'
    },
    '& .MuiButton-containedPrimary': {
      color: '#183C73',
      border: '1px solid #183C73',
      backgroundColor: 'transparent',
      marginBottom: '5px'
    },
    '& .MuiFormControl-root': {
      minWidth: '50%',
      '@media (max-width:1024px)': {
        minWidth: '100%',
      },
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      lineHeight: '44px'
    }
  },
  generalMainAccordion: {
    boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    borderColor: '#d4d4d4',
    border: '0px',
    borderStyle: 'solid',
    background: '#F4F6F8',
    borderRadius: 4,
    marginBottom: '16px',
    '& .MuiAccordionSummary-root': {
      borderBottom: 'solid 1px #cccccc'
    }
  },
  accordionHeading: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'flex',
    alignItems: 'center',
  },
  accordionParentHeading: {
    borderColor: '#d4d4d4',
    border: '1px',
    borderLeft: 0,
    borderRight: 0,
    borderTop: 0,
    borderStyle: 'solid',
  },
  productTableContainer: {
    background: 'none',
    boxShadow: 'none',
  },
  marginBM30: {
    marginBottom: 10,
  },
  padding10: {
    padding: 10
  },
  padding5: {
    padding: 5
  },
  pageTitle: {
    width: '700px',
    margin: '0 auto',
    marginBottom: '20px'
  },
  listItem: {
    paddingTop: '0px',
    paddingBottom: '0px',
    '& .item': {
      padding: '30px',
      '& h5': {
        fontSize: '16px',
        fontWeight: '500',
      }
    }
  },
  folderItem: {
    textAlign: 'center',
    '& img': {
      display: 'block',
      margin: '0px auto 10px'
    }
  },
  customFullWidth: {
    width: '100%'
  },
  shippingItem: {
    float: 'right',
    '& .bg-info-light': {
      background: 'rgba(33, 150, 243, 0.38)',
      border: '1px solid #2196F3'
    },
    '& span': {
      display: 'inline-block',
      verticalAlign: 'middle',
      marginLeft: '4px',
      color: '#183C73'
    }
  },
  customLabel: {
    background: '#fff',
    padding: '0 2px'
  },
  margin10: {
    margin: '10px'
  },

  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  rowAuto: {
    flex: '0 0 auto',
    justifyItems: 'center'
  },
  outline: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '5px'
  },
  newSETitlePanel: {
    '& .name': {
      fontWeight: 700,
      fontSize: '25px',
      lineHeight: '30px',
      paddingLeft: '12px',
      minWidth: '170px',
    },
    '& .label': {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '22px',
      color: '#1C3C74',
      '&:not(:first-child)': {
        marginLeft: '55px',
      },
    },
    '& .value': {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '24px',
      marginLeft: '13px',
    },
  },
  completePanel: {
    // height: '441px',
    '& .product-title': {
      // display: 'none',
    },
    '& .product-subtitle': {
      fontSize: '18px',
      lineHeight: '21px',
      '& .MuiBadge-root': {
        '& .MuiBadge-colorPrimary': {
          fontSize: '20px',
          lineHeight: '23px',
        },
      },
      '& .MuiChip-root': {
        height: '37px',
        borderRadius: '15px',
        '& .MuiChip-label': {
          fontSize: '20px',
          lineHeight: '23px',
        },
      },
    },
  },
  overviewcompletePanel: {
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        padding: '5px',
        maxWidth: '110px',
        '&:first-child': {
          paddingLeft: '69px',
          minWidth: '183px',
        },
        '&.details': {
          padding: '22px 0px 7px 22px',
        },
      },
    },
  },

  // Auditlog css file

  auditLogForm: {
    // marginLeft: '16px',
    '& .MuiTableHead-root': {
      display: 'none',
    },
    '& .MuiTableCell-root': {
      padding: '3px 0px 3px 0px',
      borderWidth: '0px',
      verticalAlign: 'top',
    },
    '& .new-subtitle': {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '22px',
      color: '#5C5C5C',
      paddingTop: '12px',
    },
  },
  auditLogTop: {
    marginTop: '15%',
  },
  overviewAuditLog: {
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        borderWidth: '0px',
        padding: '9px',
        overflow: 'hidden',
        // textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '&:first-child': {
          paddingLeft: '73px',
          minWidth: '100px',
        },
        '&.details': {
          fontWeight: 700,
          fontSize: '20px',
          lineHeight: '23px',
          color: '#000000',
          padding: '40px 28px 10px',
          '& img': {
            marginRight: '11px',
          },
          '& span.MuiTypography-root': {
            verticalAlign: 'super',
          },
        },
        '&.label': {
          fontWeight: 300,
          fontSize: '14px',
          lineHeight: '16px',
          color: '#5C5C5C',
          width: '105px',
        },
        '&.value': {
          fontWeight: 700,
          fontSize: '17px',
          lineHeight: '20px',
        },
      },
      '&.border-row': {
        borderBottom: '1px solid #CCCCCC',
        '& .MuiTableCell-root': {
          paddingBottom: '50px',
        },
      },
    },
  },
  textStyle: {
    whiteSpace: "normal !important",
    wordWrap: "break-word !important",
    fontWeight: 700,
    fontSize: '17px',
    lineHeight: '20px',

  },
  auditLogPanel: {
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        padding: '4px',
        maxWidth: '120px !important',
        '&:first-child': {
          paddingLeft: '0px',
        },
        '&.details': {
          padding: '20px 0px 0px 0px',
          fontSize: '18px',
          lineHeight: '22px',
          color: '#5C5C5C',
        },
        '&.label': {
          fontWeight: 400,
          fontSize: '15px',
          lineHeight: '18px',
        },
      },
    },
  },

  overviewAuditLogPO: {
    '&.MuiGrid-grid-xs-6': {
      flexGrow: '0',
      maxWidth: '40% !important',
      flexBasis: '50%',
    },
  },
  auditLogDetails: {
    backgroundColor: '#F5F5F5',
    border: '3px solid #FFFFFF',
    color: '#000000',
    boxShadow: 'none',
    height: "279px",
    width: '610px',
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        '&:first-child': {
          paddingLeft: '15px !important',
          paddingRight: '10px !important',
        },
      },
    },
  },
  auditLogDetails1: {
    marginTop: '-27%',
  },
  auditLogPayDetails: {
    backgroundColor: '#FFFFFF',
    marginTop: '0%',
  },
  auditLogTextField: {
    '& input': { fontWeight: '600 !important', },
  },
  customTextArea: {
    resize: "none",
    fontWeight: 600,
    fontSize: "18px",
  },
  newTabPanelbody: {
    height: '730px',
    backgroundColor: '#fff',
  },
  newAddCntBtn: {
    fontFamily: 'Helvetica',
    fontStyle: 'Bold',
    fontSize: '12px',
    padding: '2px 13px 2px 13px',
    margin: '6px 6px 0px 0px',
  },
  newTabPanelMenu1: { // for navigation to admin setting from logo
    zIndex: 0
  },
  newTabPanelbody1: {
    height: '414px',
    width: '1000px',
    border: '1px solid #CCCCCC',
    backgroundColor: '#fff',
  },
  newTabPanelbody2: {
    minHeight: '564px', // 543px
    backgroundColor: '#fff',
  },
  newTabPanelbody3: {
    height: '517px',
    backgroundColor: '#fff',
  },
  newTabPanelbody4: {
    backgroundColor: "#FFFFFF",
    '& .MuiBox-root': {
      padding: 0,
    }
  },
  locationTab: {
    '& .header-title': {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '21px',
    },
    '& .body': {
      height: '270px',
      marginTop: '35px',
    },
    '& .actions': {
      marginLeft: 'auto',
    },
    '& .location-text': {
      marginLeft: '50px',
    },
    '& .add-icon': {
      padding: '6px 2px',
      '& img': {
        height: '18px',
      },
    },
    '& .locations-panel': {
      marginLeft: '248px',
      width: '400px',
      maxHeight: '200px',
      overflowY: 'scroll',
    },
    '& .location-item': {
      border: '1px solid #CCCCCC',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .MuiTypography-root': {
        fontWeight: 700,
        fontSize: '13px',
        lineHeight: '15px',
        padding: '5px 3px',
      },
      '& .MuiIconButton-root': {
        padding: '5px 3px',
        height: '100%',
        borderLeft: '1px solid #CCCCCC',
        borderRadius: 'inherit',
        // '&::before': {
        //   position: 'absolute',
        //   top: '-7px',
        //   left: '-5px',
        //   width: '1px',
        //   height: '27px',
        //   backgroundColor: '#CCCCCC',
        //   content: '""',
        // },
        '& img': {
          height: '12px',
        },
      },
    },
    '& .newheader': {
      '& .MuiBadge-root': {
        '& .MuiBadge-colorPrimary': {
          backgroundColor: '#5C5C5C',
          fontSize: '8px',
          width: '11px',
          height: '11px',
          minWidth: 'inherit',
          right: '-5px',
          top: '1px',
        },
      },
      '& .MuiTypography-root': {
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '22px',
        color: '#000000',
      },
    },
    '& .procedure-panel': {
      maxHeight: '182px',
      overflowY: 'scroll',
    },
  },
  facilityPanel: {
    marginTop: '8px', // 74px
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  newTabPanelMenu: {
    backgroundColor: '#5C5C5C',
    '& .MuiButtonBase-root.MuiTab-root': {
      textTransform: 'capitalize',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#F7CD37',
    },
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-around',
    },
    '& .MuiTab-textColorPrimary': {
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '18px',
      color: '#FFFFFF',
      '&.Mui-selected': {
        fontWeight: 800,
        color: '#F7CD37',
      },
    },
  },
  btnGroupTop: {
    // position: 'absolute',
    // top: '15px',
    // right: '10px',
    borderRadius: '10px',
    margin: '5px',
    '& .MuiButton-root': {
      background: 'rgba(255, 255, 255, 0.38)',
      border: '1px solid #CCCCCC',
      '&:first-child': {
        borderRadius: '10px 0px 0px 10px',
      },
      '&:last-child': {
        borderRadius: '0px 10px 10px 0px',
      },
      '&.active': {
        background: 'rgba(76, 175, 80, 0.38)',
        border: '1px solid #4CAF50',
      },
      '& .MuiButton-label': {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '14px',
        color: '#000000',
        textTransform: 'capitalize',
      },
    },
  },
  newTabTitle: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#000000',
  },
  newTabTitle1: {
    padding: '0px 0px 1px 31px'
  },
  paddingBtm: {
    padding: '10px',
  },
  // hospital profile
  tabLabel: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '18px',
    /* Grey Tydei official */
    color: '#5C5C5C',
    margin: 'auto',
  },
  accordionLabel: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '14px',
    /* identical to box height */
    color: ' #FFFFFF',
    // background: '#1C3C74',
  },
  acordionSubtitle: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#000000',
    padding: ' 0 !important',
    height: '17px',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  acordionDetails: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#000000',
    padding: ' 0 !important',
  },
  centerPanel: {
    direction: "row",
    justifyContent: "space-around",
    alignItems: "center",
    border: '1px solid #CCCCCC',
  },
  titleMain: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '29px',
    color: '#1C3C74',
    padding: '0px 0px 7px 0px',
  },
  tabTitles: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '15px',
    lineHeight: '18px',
    color: '#000000',
    '& active': {
      fontWeight: '800 !important ',
      color: '#1C3C74',
    }
  },
  completeTabPanel: {
    padding: '3px',
  },
  subtabsPanel: {
    bgcolor: '#5C5C5C',
    display: "flex",
    justifyContent: "center",
    height: '50px',
    /* Grey Tydei official */
    background: '#5C5C5C',
  },
  mainBox: {
    background: '#FFFFFF',
    height: '73vh',
    padding: '2px',
  },
  subtabsPanel1: {
    background: '#FFFFFF',
    border: '1px solid #CCCCCC',
    display: "flex",
    justifyContent: "center",
    height: '45vh',
  },
  subTitles: {
    textTransform: 'capitalize',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '18px;',
    /* TYDEi Yellow */
    color: '#FFFFFF;',
    '&:focus': {
      /* TYDEi Yellow */
      color: '#F7CD37;',
      fontWeight: 800,
    },
  },
  sectionDetailTitle: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '17px',
    lineHeight: '22px',
    color: '#1C3C74',
    padding: '18px 0px 16px 0',
  },
  sectionDetailTitle1: {
    padding: '0px 0px 5px 0',
  },
  activeTabTitle: {
    // fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '22px',
    padding: '0px 0px 1px 31px',
    color: '#000000',
  },
  labels: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#1C3C74'
  },
  popperSize: {
    left: '51px',
    transition: 'opacity 284ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 189ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    top: '0px',
    width: '319px',
    height: '240px',
  },
  root: {
    padding: '0px',
    height: '32px',
  },
  profilemenu: {
    position: 'relative',
    width: '258px',
    left: '1px',
    top: '5px',
    padding: '7px 41px 8px 18px'
  },
  profilemenu1: {
    position: 'relative',
    width: '258px',
    left: '100px',
    top: '5px',
    padding: '7px 41px 8px 18px'
  },
  iconSize: {
    width: '0px',
    height: '0px',

  },
  // for accordion at hospital profile screen
  fileName: {
    overflowX: "hidden",
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: "15px",
    lineHeight: "18px",
    color: "#FFFFFF",
    padding: "0px 0px 0px 4px"
  },
  accordionIcon: {
    width: "49px",
    position: 'relative',
    left: '10px',
  },
  accordionIcon1: {
    width: "49px",
    position: 'relative',
    left: '18px',
  },
  nicknameSize: {
    width: '118px',
    fonSstyle: 'normal',
    fontWeight: '700',
    fontSize: '15px',
    lineHeight: '18px',
    color: '#000000',
  },
  contactaddtitle: {
    width: '170px',
    fonSstyle: 'normal',
    fontWeight: '700',
    fontSize: '15px',
    lineHeight: '18px',
    color: '#000000',
  },
  popOverItems: {
    fontFamily: 'Lato',
    fontStyle: 'Regular',
    fontSize: '14px',
    lineHeight: '17px',
    '&:hover': {
      backgroundColor: '#5C5C5C',
      color: '#FFFFFF'
    },
  },
  accordionSummeryDark: {
    background: '#EAEAEA',
    borderRadius: '5px',
    minHeight: '10px',
    height: '35px',
    '&.MuiAccordionSummary-root': {
      display: 'flex',
      justifyContent: 'space-between',
      "&.Mui-expanded": {
        minHeight: '35px'
      }
    }
  },
  accordionDetailsDark: {
    '&.MuiAccordionDetails-root': {
      marginTop: '3px',
      display: 'flex',
      padding: '8px 10px 4px',
      background: '#EAEAEA',
    }
  },
  productAccordion: {

  },
  addSmallButton: {
    padding: '3px 7px',
    background: '#F5F5F5',
    border: '1px solid #CCCCCC',
    borderRadius: '5px',
    marginLeft: '21px',
    '& .MuiButton-label': {
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '16px',
      color: '#000000',
      textTransform: 'capitalize',
    },
  },
  newAutoComplete: {
    position: 'inherit',
    width: '359px',
    left: 0,
  },
  adaptiveSearchAutoComplete: {
    position: 'inherit',
    width: '330px',
    left: 0,
    '& .MuiInputBase-root': {
      borderRadius: '0px',
      backgroundColor: 'rgba(196, 196, 196, 0.18) !important'
    },
    '& .MuiAutocomplete-endAdornment': {
      right: '5px !important'
    },
    "& .MuiOutlinedInput-adornedEnd": {
      border: "none"
    },
  },
  addProcedureModalBody: {
    padding: '15px 23px',
    '& .MuiGrid-item': {
      marginBottom: '30px',
    },
  },
  procedureTable: {
    '& .MuiTableCell-root': {
      border: '1px solid #CCCCCC',
      padding: '4px 18px',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '18px',
      color: '#000000',
      '&.MuiTableCell-head': {
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '22px',
        color: '#5C5C5C',
      },
    },
  },
  // user Profile styles
  profileTabTitle: {
    fontFamily: "Lato",
    fontSize: "15px",
    lineHeight: '18px',
    padding: "4px 23px 0px 21px",
    color: "#667085",
    minWidth: "max-content",
    '&.Mui-selected .MuiPrivateTabIndicator-colorSecondary': {
      backgroundColor: 'red',
    },
    "&:focus": {
      color: "#1C3C74",
      fontWeight: 800,
    }
  },
  profileTab: {
    background: '#FFFFFF'
  },
  profileAvatar: {
    width: '100px',
    height: '100px',
    margin: "22px 10px 10px 0px"
  },
  activeProfileHeadline: {
    fontFamily: "Lato",
    fontStyle: "bold",
    fontSize: "30px",
    lineHeight: "36px",
    color: "#1C3C74",
    padding: "0px 0px 11px 0px"
  },
  actionBtn1: {
    padding: "0px 30px 0px 0px"
  },
  formLabel: {
    fontFamily: 'Lato',
    fontSize: '15px',
    lineHeight: '18px',
    alignSelf: "center"
  },
  dividerCss: {
    border: "snow",
    height: "1px",
    width: "100%",
    margin: "0px 0px 10px 0px",
    // '&.MuiDivider-root': {
    //   // backgroundColor: theme.palette.grey[500], // Change the color to match your theme
    //   height: '1px', // Change the height to your desired value
    //   margin: theme.spacing(2, 0), // Change the margin to your desired value
    // },
  },
  dividerCss1: {
    margin: "10px 0px 10px 0px"
  },
  dividerCss2: {
    margin: "0px 0px 0px 0px"
  },
  dividerCss3: {
    width: "99%",
    margin: "10px 18px 10px 8px"
  },
  longDivider: {
    width: "80vw"
  },
  primaryText: {
    display: 'block',
    fontFamily: 'Lato',
    fontSize: '18px',
    lineHeight: '22px',
    color: "#000000"
  },
  secondaryText: {
    fontFamily: 'Lato',
    fontStyle: 'Regular',
    fontSize: '14px',
    lineHeight: '17px',
    color: "#475467"
  },
  newSwitch: {
    '& .MuiSwitch-track': {
      // color : "#FFFFFF",
      // backgroundColor: "#6DE25A",
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><text x="0" y="15" fill="red">ON</text></svg>')`,
        left: 13,
        top: 20,
      },
      '&:after': {
        '&:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><text x="0" y="15" fill="red">OFF</text></svg>')`,
          right: 13,
          top: 20,
        },
      },
    },
  },
  switchLeft: {
    padding: "0px 0px 0px 200px",
    display: "flex",
    flexDirection: "row-reverse"
  },
  switchLeft1: {
    display: "flex",
    flexDirection: "row-reverse",
    padding: "0px 0px 0px 12px",
    justifyContent: "flex-end"
  },
  positionBottom: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '35px',
    background: '#1C3C74 !important',
    color: '#FFFFFF',
    fontSize: "15px",
    lineHeight: "18px",
    fontWeight: 600
  },
  divCenter: {
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    maxWidth: "156px",
    width: "114px",
    margin: "0px 0px 0px 0px",
  },
  imgAction: {
    width: "63px"
  },
  imgAction1: {
    padding: "0px 6px 0px 6px",
    width: "25px"
  },
  newModalViewer: {
    '& .MuiDialog-paper': {
      '& .MuiDialogTitle-root': {
        display: "none"
      }
    }
  },
  // userPassword Input css
  newInput: {
    "& .MuiOutlinedInput-input": {
      padding: "6.5px 14px !important"
    }
  },
  // styles for profile info
  paddingAll: {
    padding: "15px 32px 20px 55px"
  },
  paddingAll2: {
    padding: "20px 31px 0px 55px"
  },
  positionRelative: {
    width: "280px", position: "relative"
  },
  resetPasswordPosition: {
   paddingTop: "25px",
  },
  // Created Invoice
  auditCreateInvoice: {
    "& .MuiTableCell-root": {
      border: "none",
      backgroundColor: "white",
      padding: "0 0.5rem 0 0",
      textAlign: "right"
    },
    "& .table-head": {
      border: "none",
      fontWeight: 700,
      color: 'black',
      lineHeight: "3rem",
      borderBottom: "1px solid #CCCCCC"
    },
  },
  gridHead: {
    color: 'rgba(0, 0, 0, 0.87);',
    fontWeight: '500;',
    fontSize: '14px;',
    lineHeight: '16px;',
    padding: '0px !important;',
    display: 'flex;',
    justifyContent: 'center;',
    alignItems: 'center;',
    height: '30px;',
  },
  gridColumn: {
    height: '100%;',
    overflowY: 'auto;',
  },
  gridCellAlign: {
    textAlign: 'center',
  },
  gridCellCenter1: {
    color: 'rgba(0, 0, 0, 0.87) !important',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '14px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: "34px",
  },
  gridCell1: {
    color: 'rgba(0, 0, 0, 0.87) !important',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: "34px",
  },
  gridSeriesWidth: {
    maxWidth: '9%',
  },
  gridMinWidth1: {
    minWidth: '12%',
    overflow: 'hidden',
  },
  hideOverflow1: {
    overflow: 'hidden'
  },
  gridWidth1: {
    justifyContent: 'center',
    minWidth: '25%',
    overflow: 'hidden',
  },
  gridColumn1: {
    height: '100%;',
    overflowY: 'auto;',
  },
  gridComment: {
    border: "1px solid #CCCCCC",
  },
  commentstyle: {
    padding: "0.5rem",
  },
  commentstyle1: {
    padding: "1rem",
  },
  gridInvoiceTo: {
    padding: "1rem",
    borderLeft: "1px solid #CCCCCC",
    borderRight: "1px solid #CCCCCC",
  },
  girdShipTo: {
    padding: " 0 1rem 1rem 1rem",
  },
  girdShipTo1: {
    border: "1px solid #CCCCCC",
    borderRadius: "0.5rem"
  },
  gridInvoiceNo: {
    borderBottom: "1px solid #CCCCCC",
  },

}), { index: 1 });
