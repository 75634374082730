import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchContracts from "./searchcontracts";
import useStyles from "./styles";


export default function AdaptiveSearch({
  template = null,
  search = (p) => p,
  getValue = (c) => c,
  handleChangeSearch = (v) => v,
  label = "",
  placeholder = "",
  contracts = null,
  defaultValue = "",
  isShrink = false,
  isStatic = false,
}) {

  const classes = useStyles();


  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(!!defaultValue ? defaultValue : null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  // console.log("[AdaptiveSearch]", loading, options, value);


  const handleChange = (val) => {
    // console.log("[AdaptiveSearch] handleChange", val);
    setInputValue(val);
  };


  const handleSearch = (val) => {
    // console.log("[AdaptiveSearch] handleSearch", val);
    setValue(val);
    handleChangeSearch(val || "");
  };


  useEffect(() => {
    if (contracts !== null && typeof contracts === 'object' && contracts.length > 0) {
      // console.log("[AdaptiveSearch]", contracts);
      setLoading(false);
      setOptions(contracts.map(getValue)
        .filter((itm, idx, arr) => !!itm && arr.indexOf(itm) === idx)
        .sort());
    }
  }, [contracts, getValue]);


  return (
    <>

      <Autocomplete
        className={classes.searchInput}
        classes={isStatic ? { paper: classes.searchPaper } : undefined}
        open={isStatic || open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        value={value}
        onChange={(_, newValue) => handleSearch(newValue)}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => handleChange(newInputValue)}
        // defaultValue={!!defaultValue ? defaultValue : undefined}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField 
            {...params} 
            label={label}
            placeholder={placeholder}
            margin="normal" 
            variant="outlined" 
            InputLabelProps={!!isShrink ? {
              shrink: isShrink,
            } : undefined}
          />
        )}
      />
      {
        contracts === null
        &&
        <SearchContracts
          template={template}
          searchParams={search(value)}
          getValue={getValue}
          setLoading={setLoading}
          setOptions={setOptions}
        />
      }

    </>
  );
}
