import { SurgicalEventProductModel, validateProduct, getProduct } from "./Product";

export const SurgicalEventDetailModel = {
  id: '',
  product: {...SurgicalEventProductModel},
  invproductkey: '',
  invid: '',
  podetailid: '',
};
export const validateSurgicalEventDetail = (sedetail) => {
  return (
    validateProduct(sedetail.product)
  );
};

export const getSurgicalEventDetail = (sedetail) => {
  let newSEDetail = {};
  if (typeof sedetail.id === "string" && sedetail.id !== "") newSEDetail.id = sedetail.id;
  newSEDetail.product = getProduct(sedetail.product);
  if (typeof sedetail.invproductkey === "string" && sedetail.invproductkey !== "") newSEDetail.invproductkey = sedetail.invproductkey;
  if (typeof sedetail.invid === "string" && sedetail.invid !== "") newSEDetail.invid = sedetail.invid;
  if (typeof sedetail.podetailid === "string" && sedetail.podetailid !== "") newSEDetail.podetailid = sedetail.podetailid;
  return newSEDetail;
};

export const convertSEDetailToCSV = (sedetails=[]) => {
  const header = [
    { label: "Reference Number", key: "sepreferencenumber" },
    { label: "Lot/SN", key: "seplotcode" },
    { label: "UDI", key: "sepudi" },
    { label: "Product Manufacturer", key: "sepproductmanufacturer" },
    { label: "Product Distributor", key: "sepdistributor" },
    { label: "Product Name", key: "sepproductname" },
    { label: "Product Description", key: "sepproductdescription" },
    { label: "Expiration", key: "sepproductexpiration" },
    { label: "Price", key: "sepproductprice" },
    { label: "Product Type", key: "sepproducttype" },
    { label: "Waste", key: "sepproductwaste" },
    { label: "Ownership", key: "sepownership" },
    { label: "Product Side", key: "sepproductside" },
  ];
  const data = sedetails.map(sedetail => {
    return header.reduce((d, h) => {
      d[h.key] = (h.key in sedetail) ? (sedetail[h.key] ? sedetail[h.key] : '') 
        : ((h.key in sedetail.product && sedetail.product[h.key]) ? sedetail.product[h.key] : '');
      return d;
    }, {});
  });
  return {header, data};
};
