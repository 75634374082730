import { useReducer } from "react";

function reducer(state, action) {
  switch (action.type) {
    case "change_quantity": {
      let { categoryId, productId, open, hold } = action.payload;
      state.items.forEach((element) => {
        if (element?.category?.id === categoryId) {
          element.items.forEach((product) => {
            if (product.pseudoItem.id === productId) {
              product.open = open;
              product.closed = hold;
            }
          });
        }
      });
      return { ...state };
    }
    case "select_category": {
      let { categoryId, isChecked } = action.payload;
      let isAllCheckedProd = true;
      state.items.forEach((element) => {
        if (element?.category?.id === categoryId) {
          element.category.isChecked = isChecked;
          element.items.forEach((product) => {
            product.isChecked = isChecked;
          });
        }
        if (!element.category.isChecked) isAllCheckedProd = false;
      });
      if (state.isChecked && !isChecked) state.isChecked = isChecked;
      else state.isChecked = isAllCheckedProd;
      return { ...state };
    }
    case "select_page": {
      let { isChecked } = action.payload;
      state.isChecked = isChecked;
      state.items.forEach((element) => {
        element.category.isChecked = isChecked;
        element.items.forEach((product) => {
          product.isChecked = isChecked;
          product.used = !isChecked ? 0 :product.open;
          product.unUsed = product.closed
        });
      });
      return { ...state };
    }
    case "change_product": {
      let { categoryId, isChecked, productId,used } = action.payload;
      let isAllCheckedProd = true;
      state.items.forEach((element) => {
        let isAllCheckedCat = true;
        if (element?.category?.id === categoryId) {
          element.items.forEach((product) => {
            if (product.pseudoItem.id === productId) {
              product.isChecked = isChecked;
              product.used = !isChecked ? used :product.open;
              product.unUsed = product.closed
            }
            if (!product.isChecked) {
              isAllCheckedCat = false;
              isAllCheckedProd = false;
            }
          });
          element.category.isChecked = isAllCheckedCat;
          if (!element.category.isChecked && isAllCheckedProd)
            isAllCheckedProd = false;
        }
        state.isChecked = isAllCheckedProd;
      });

      return { ...state };
    }
    default: {
      return { ...state };
    }
  }
}

const usePrefReducer = (prefdetail) => {
  const [state, dispatch] = useReducer(reducer, prefdetail);
  return { state, dispatch };
};

export default usePrefReducer;
