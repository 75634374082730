import React, { useState, useEffect } from "react";
import { useParty, useStreamQueries } from "@daml/react";
import { Typography } from "@material-ui/core";
import { dispatch, getState } from "../../store";
import { inventorySearch } from "../../store/actions/generalActions";
import { RecallInventory as RecallInventoryTemplate } from "../../services/daml-modules1";
import { useDamlState } from '../../context/DamlContext';
import RecallInventoryContracts from "./recallinventorycontracts";
import useStyles from "../surgicalevent/styles";


/**
 * Recall Inventory page in Hospital & Vendor Side
 * @returns 
 */
export default function RecallInventory() {

  const classes = useStyles();
  const { role } = useDamlState();
  
  const party = useParty();
  const assets = useStreamQueries(RecallInventoryTemplate, () => [role === "Hospital" ? {hospital: party} : {vendor: party}]);

  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({});

  const [searchInventory, setSearchInventory] = useState({
    ireferencenumber: '',
    ipurchaseordernumber: '',
    sepproductmanufacturer: '',
    ilotcode: '',
    iexpirationdate: '',
    ishipmentdate: '',
    hospital: '',
    ...getState().inventory,
  });
  const handleChangeSearchValue = (val, key) => {
    if ([
      'ipurchaseordernumber', 'sepproductmanufacturer', 'ishipmentdate',
    ].includes(key)) return;
    setSearchInventory({ ...searchInventory, [key]: val });
    dispatch(inventorySearch({ key, value: val }));
  };


  useEffect(() => {
    setLoading(true);

    let temp = {};

    temp.inventoryproduct = {};
    if (searchInventory.ireferencenumber !== "") temp.inventoryproduct.ireferencenumber = searchInventory.ireferencenumber;
    if (searchInventory.ipurchaseordernumber !== "") temp.inventorydata.ipurchaseordernumber = searchInventory.ipurchaseordernumber;
    if (searchInventory.sepproductmanufacturer !== "") temp.inventorydata.iproduct = { ...temp.inventorydata.iproduct, sepproductmanufacturer: searchInventory.sepproductmanufacturer };
    // if (searchInventory.ilotcode !== "") temp.inventoryproduct.ilotcode = searchInventory.ilotcode;
    // if (searchInventory.iexpirationdate !== "") temp.inventoryproduct.iexpirationdate = searchInventory.iexpirationdate;
    if (searchInventory.ishipmentdate !== "") temp.inventorydata.ishipmentdate = searchInventory.ishipmentdate;
    if (role === "Hospital") temp.hospital = party;
    else temp.vendor = party;

    const delay = 0;
    if (delay > 0) setTimeout(() => {
      setSearchParams(temp);
    }, delay);
    else {
      setSearchParams(temp);
    }
  }, [searchInventory, role, party]);

  useEffect(() => {
    setLoading(false);
  }, [searchParams]);


  return (
    <>
      <div key="formChildStep" className="formsteps">
        <Typography variant="h4" className={classes.pageTitle}>
          Recalled Products
        </Typography>

        <RecallInventoryContracts
          searchParams={searchParams}
          handleChangeSearchValue={handleChangeSearchValue}
          allContracts={assets.contracts}
          searchInventory={searchInventory}
          loading={loading}
        />

      </div>
    </>
  );
}
