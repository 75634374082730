import React, { useState, useEffect } from "react";
import { Typography, Accordion, AccordionDetails, AccordionSummary,
  TableContainer, Paper, Badge, Chip, DialogContentText, Drawer } from "@material-ui/core";
import { ExpandMore } from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';
import downloadIcon from "../../icons/download.svg";
import { formatDate } from "../../services/util";
import { arrayToCsv, downloadCSV } from "../../services/download";
import { checkComplianceStatus, checkComplianceStatusExpiration, 
  checkComplianceStatusSide } from "../../models/SurgicalEventCompliance";
import { PoType, convertPurDataToCSV } from "../../models/Po";
import { convertPODetailToCSV } from "../../models/PODetail";
import { POStatus } from "../../models/POStatus";
import POCommentsList from './poslistcomment';
import ProductDetailViewTable from "../surgicalevent/product-detail-view-table";
import useStyles from "../surgicalevent/styles";


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/**
 * Detail View Slide Drawer 
 * @param {Boolean} state
 * @param {Function} toggleDrawer
 * @param {Object} selectedPOData
 * @param {Array} productItemsList
 * @param {Array} commentsList
 * @param {Boolean} isDownload
 * @param {Object} selectedSEData
 */
export default function DetailViewDrawer({
  state, toggleDrawer, selectedPOData = {}, productItemsList = [], commentsList = [], isDownload=false,
  selectedSEData = {},
}) {
  
  if (!state) {
    productItemsList = [];
  }

  const classes = useStyles();

  const [expanded, setExpanded] = useState(0);

  const handleChangeAccordion = (panel) => (_event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }

  const handleDownloadClick = async (_e) => {
    let {header, data} = convertPurDataToCSV(selectedPOData);
    let csvData = arrayToCsv(header, data);
    console.log("[DetailViewDrawer] handleDownloadClick", selectedPOData, header, data, csvData);
    ({header, data} = convertPODetailToCSV(productItemsList));
    csvData = csvData + '\n' + arrayToCsv(header, data);
    console.log("[DetailViewDrawer] handleDownloadClick", productItemsList, header, data, csvData);
    const filename = selectedPOData ? selectedPOData.popurchaseorderid : 'PO';
    downloadCSV(csvData, filename);
  };


  useEffect(() => {
    if (state) {
      console.log("[DetailViewDrawer] is opened.");
      setExpanded(0);
    }
  }, [state]);

console.log("selectedPOData",selectedPOData)
  return (
    <React.Fragment key={'right'} >
      <Drawer className={classes.customRoot} anchor={'right'} open={state} onClose={() => { toggleDrawer(false) }}>
        <div className="view-heading">
          <span>Purchase Order:</span> {selectedPOData && selectedPOData.popurchaseorderid}
          {selectedPOData && selectedPOData.poseid && (<>
            <span style={{marginLeft: 10}}>SE Id:</span> {selectedPOData.poseid}
          </>)}

          {isDownload && 
          <button className={classes.printButton} 
            onClick={handleDownloadClick}
          >
            <img src={downloadIcon} alt="Download" />
          </button>}
        </div>


        <DialogContentText component={'div'} className="modelStepDetai">
          <div className="viewDetailsSe">

            <h2 className={classes.formSubHeading}>PO Details</h2>
            <div className={`grid-list grid-list-4`}>
              <div className="list-item">
                <div className="label">PO Date</div>
                <div className="value">{selectedPOData && formatDate(selectedPOData.podate)}</div>
              </div>
              <div className="list-item">
                <div className="label">PO Distributor</div>
                <div className="value">{selectedPOData && selectedPOData.povendorid}</div>
              </div>
              {
                //(commentsList.length === 0) &&
                <div className="list-item">
                  <div className="label">PO Type</div>
                  <div className="value">{selectedPOData && PoType[selectedPOData.potype]}</div>
                </div>
              }
              <div className="list-item">
                <div className="label">Status</div>
                <div className="value">{selectedPOData && POStatus[selectedPOData.postatus]}</div>
              </div>
            </div>

            {commentsList.length > 0 &&
              <POCommentsList comments={commentsList} />
            }

            {productItemsList.length !== 0 &&
              <div> <div className={classes.productDetails}>
                <h2 className={classes.formSubHeading}>Total Added Product
                  <Badge badgeContent={selectedPOData.purchaseorderdetaildataproducts} color="primary" overlap="rectangular"></Badge>
                  <span className={classes.productTableHeading}><span className="price-label">Total Price of Products:</span> ${selectedPOData.pototalamount}</span>
                </h2>
              </div>

                <div className={classes.productAccordion}>
                  {productItemsList.map(({ podproduct: obj, podnotes, podquantity }, index) => (
                    <Accordion 
                      key={index} 
                      expanded={expanded === (index + 1)} 
                      onChange={handleChangeAccordion((index + 1))} 
                      className={classes.productMainAccordion}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className="product-detail-accordion-head"
                      >
                        <Typography className={classes.accordionHeading}>
                          <span className="text-inactive">Reference Number :</span> <span className="text-active">{obj.sepreferencenumber}</span> 
                        </Typography>
                        <Typography className={classes.accordionHeading}>
                          <span className="text-inactive">Lot Code :</span> <span className="text-active">{obj.seplotcode}</span> 
                        </Typography>
                        <Typography className={classes.accordionHeading}>
                          <span className="text-inactive">Qty: </span> <span className="text-active">{podquantity}</span> 
                        </Typography>
                        {
                          productItemsList[index].pricechange === true
                          && <Chip label="Price Changed" className="bg-info-price-change" />
                        }
                        {
                          checkComplianceStatus({ surgicalevent: selectedSEData, auditedproductdata: obj }) !== ''
                          && <Chip label="Compliance" className="bg-info-light" />
                        }
                      </AccordionSummary>

                      <AccordionDetails>
                        <Typography component={'div'}>
                          <TableContainer className={classes.productTableContainer} component={Paper}>
                            {
                              productItemsList[index].pricechange === true &&
                              <div className="price-change"> 
                                <Alert severity="info" > The Price has been changed from the contract pricing please check before 
                                Accepting the PO</Alert> 
                              </div>
                            }
                            {
                              checkComplianceStatusSide(selectedSEData, obj) &&
                              <Alert severity="info"><strong>Compliance</strong>: Product is the incorrect Side based on the Surgical Procedure</Alert>
                            }
                            {
                              checkComplianceStatusExpiration(selectedSEData, obj) &&
                              <Alert severity="info"><strong>Compliance</strong>: Product is past expiration date</Alert>
                            }

                            <ProductDetailViewTable 
                              obj={obj}
                              options={{
                                hiddenParams: ['sepdistributor', 'sepproductwaste'],
                                extraParams: [
                                  {label: 'Product Note', value: podnotes, className: classes.tableRowBorderTop}
                                ],
                                redParams: (productItemsList[index].pricechange ? 
                                  ['sepproductprice'] : []),
                              }}
                            />
                          </TableContainer>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}

                </div>
              </div>
            }
          </div>
        </DialogContentText>
      </Drawer>
    </React.Fragment>
  );
}
