import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableRow, TableCell, TableHead, Grid, Button, Box, Typography, IconButton } from "@material-ui/core";
import { TimeLine, requiredServiceTermData, requiredPaymentRebateData, requiredSpendRebateData, 
  requiredVolumeRebateData } from "../../models/ContractManagement";
import ModalDialog from '../../components/Modals/NewGeneralModal';
import SnackbarModal from "../../components/Modals/SnackbarModal";
import { CustomSelect, CustomTextField } from "../../components/Inputs";
import { useDamlProcedure } from '../../context/DamlContext';
import useContract from "../../hooks/useContract";
import editIcon from "../../icons/new-edit-icon1.svg";
import deleteIcon from "../../icons/new-delete5.svg";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "./styles";


const ContractDetailModal = ({
  open, setOpen, title, handleAdd, ModalContent, requiredData, defaultValue={}, isCreate, 
}) => {

  const classes = useStyles();
  const classes1 = useStyles1();

  const [value, setValue] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (val, key) => {
    setValue({
      ...value,
      [key]: val,
    });
  };

  const handleCancelClick = () => {
    setOpen(false);
  };

  const handleSaveClick = () => {
    let newErrors = {};
    if (typeof requiredData === 'function') newErrors = requiredData(value);
    if (Object.keys(newErrors).length > 0) {
      setErrors({ ...newErrors });
    }
    else {
      if (typeof handleAdd === 'function') handleAdd(value);
      setOpen(false);
    }
  };

  useEffect(() => {
    if (!open) return;
    setValue(defaultValue);
    setErrors({});
  }, [open, defaultValue]);

  return (
    <ModalDialog
      open={open}
      setOpen={setOpen}
      title={title}
      content={(
        <Box className={classes1.contentPanel}>
          {ModalContent && <ModalContent value={value} handleChange={handleChange} errors={errors} />}
        </Box>
      )}
      actions={(
        <>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleCancelClick} 
            className={classNames(classes.newCompleteBtn, "secondary-color", classes1.newBtn)} 
          >
            Cancel
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleSaveClick} 
            className={classNames(classes.newCompleteBtn, classes1.newBtn)} 
          >
            {
              isCreate ? 'Add' : 'Save'
            }
          </Button>
        </>
      )}
      width={754}
      height={344}
    />
  );
};

const ServiceTermForm = ({
  value, handleChange, errors={}, 
}) => {
  
  const classes = useStyles();
  const classes1 = useStyles1();

  return (
    <Table className={classNames(classes.newSEForm, classes1.detailForm)}>
      <TableBody>
        <TableRow>
          <TableCell>
            <CustomTextField
              type="number"
              label="Shipping fee"
              placeholder="Enter amount here.."
              value={value.servicetermshippingfee || ""}
              onChange={(val) => handleChange(val, 'servicetermshippingfee')}
              warning={errors.servicetermshippingfee}
              width={186}
              options={{
                labelLeft: true,
                labelWidth: 261,
              }}
              className={classNames(classes1.formField, "lg")}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <CustomTextField
              type="number"
              label="Service Fee(Freight/Handling)"
              placeholder="Enter amount here.."
              value={value.servicetermfreightfee || ""}
              onChange={(val) => handleChange(val, 'servicetermfreightfee')}
              warning={errors.servicetermfreightfee}
              width={186}
              options={{
                labelLeft: true,
                labelWidth: 261,
              }}
              className={classNames(classes1.formField, "lg")}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <CustomSelect
              label="Time Line"
              placeholder="Select"
              value={value.servicetermtimeline || ""}
              onChange={(val) => handleChange(val, 'servicetermtimeline')}
              items={TimeLine}
              warning={errors.servicetermtimeline}
              width={143}
              options={{
                labelLeft: true,
                labelWidth: 261,
              }}
              className={classNames(classes1.formField, "lg")}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const PaymentRebateForm = ({
  value, handleChange, errors={}, 
}) => {
  
  const classes = useStyles();
  const classes1 = useStyles1();

  return (
    <Table className={classNames(classes.newSEForm, classes1.detailForm)}>
      <TableBody>
        <TableRow>
          <TableCell>
            <CustomSelect
              label="PO sent in"
              placeholder="Select"
              value={value.pmtrebateposent || ""}
              onChange={(val) => handleChange(val, 'pmtrebateposent')}
              items={TimeLine}
              warning={errors.pmtrebateposent}
              width={143}
              options={{
                labelLeft: true,
                labelWidth: 215,
              }}
              className={classNames(classes1.formField, "lg")}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <CustomTextField
              type="number"
              label="Rebate % of Spend"
              placeholder="Enter amount here.."
              value={value.pmtrebatepopct || ""}
              onChange={(val) => handleChange(val, 'pmtrebatepopct')}
              warning={errors.pmtrebatepopct}
              width={169}
              options={{
                labelLeft: true,
                labelWidth: 215,
              }}
              className={classNames(classes1.formField, "lg")}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <CustomSelect
              label="Payment sent in"
              placeholder="Select"
              value={value.pmtrebatepaymentsent || ""}
              onChange={(val) => handleChange(val, 'pmtrebatepaymentsent')}
              items={TimeLine}
              warning={errors.pmtrebatepaymentsent}
              width={143}
              options={{
                labelLeft: true,
                labelWidth: 215,
              }}
              className={classNames(classes1.formField, "lg")}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <CustomTextField
              type="number"
              label="Rebate % of Spend"
              placeholder="Enter amount here.."
              value={value.pmtrebatepaymentpct || ""}
              onChange={(val) => handleChange(val, 'pmtrebatepaymentpct')}
              warning={errors.pmtrebatepaymentpct}
              width={169}
              options={{
                labelLeft: true,
                labelWidth: 215,
              }}
              className={classNames(classes1.formField, "lg")}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const SpendRebateForm = ({
  value, handleChange, errors={}, 
}) => {
  
  const classes = useStyles();
  const classes1 = useStyles1();

  return (
    <Table className={classNames(classes.newSEForm, classes1.detailForm)}>
      <TableBody>
        <TableRow>
          <TableCell>
            <CustomTextField
              type="number"
              label="Current Dollar spend (USD)"
              placeholder="Enter amount here.."
              value={value.spendtotal || ""}
              onChange={(val) => handleChange(val, 'spendtotal')}
              warning={errors.spendtotal}
              width={169}
              options={{
                labelLeft: true,
                labelWidth: 310,
              }}
              className={classNames(classes1.formField, "lg")}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <CustomTextField
              type="number"
              label="Spend needed for Rebate  (USD)"
              placeholder="Enter amount here.."
              value={value.spendrebatespendneeded || ""}
              onChange={(val) => handleChange(val, 'spendrebatespendneeded')}
              warning={errors.spendrebatespendneeded}
              width={169}
              options={{
                labelLeft: true,
                labelWidth: 310,
              }}
              className={classNames(classes1.formField, "lg")}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <CustomTextField
              type="number"
              label="Percent of total spend Rebated"
              placeholder="Type percentage here.."
              value={value.spendrebatepct || ""}
              onChange={(val) => handleChange(val, 'spendrebatepct')}
              warning={errors.spendrebatepct}
              width={169}
              options={{
                labelLeft: true,
                labelWidth: 310,
              }}
              className={classNames(classes1.formField, "lg")}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const VolumeRebateForm = ({
  value, handleChange, errors={}, 
}) => {
  
  const classes = useStyles();
  const classes1 = useStyles1();

  const { procedures } = useDamlProcedure();


  return (
    <Table className={classNames(classes.newSEForm, classes1.detailForm)}>
      <TableBody>
        <TableRow>
          <TableCell>
            <CustomSelect
              label="CPT Code"
              placeholder="Select"
              value={value.cptcode || ""}
              onChange={(val) => handleChange(val, 'cptcode')}
              items={procedures}
              warning={errors.cptcode}
              width={267}
              options={{
                labelLeft: true,
                labelWidth: 224,
              }}
              className={classNames(classes1.formField, "lg")}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <CustomTextField
              type="number"
              label="Current Volume Total"
              placeholder="Enter amount here.."
              value={value.currentvolume || ""}
              onChange={(val) => handleChange(val, 'currentvolume')}
              warning={errors.currentvolume}
              width={169}
              options={{
                labelLeft: true,
                labelWidth: 224,
              }}
              className={classNames(classes1.formField, "lg")}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <CustomTextField
              type="number"
              label="Volume needed for Rebate"
              placeholder="Type percentage here.."
              value={value.volumeneeded || ""}
              onChange={(val) => handleChange(val, 'volumeneeded')}
              warning={errors.volumeneeded}
              width={169}
              options={{
                labelLeft: true,
                labelWidth: 224,
              }}
              className={classNames(classes1.formField, "lg")}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <CustomTextField
              type="number"
              label="% Rebated"
              placeholder="Type percentage here.."
              value={value.volumepctrebate || ""}
              onChange={(val) => handleChange(val, 'volumepctrebate')}
              warning={errors.volumepctrebate}
              width={169}
              options={{
                labelLeft: true,
                labelWidth: 224,
              }}
              className={classNames(classes1.formField, "lg")}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

/**
 * Contract Detail
 * @param {Object} options 
 * @returns 
 */
export default function ContractDetail({
  options = {},
}) {

  options = {
    isCreate: false,
    ...options,
  };

  const classes = useStyles();
  const classes1 = useStyles1();
  
  const { t } = useTranslation();

  const { serviceterm, setServiceTerm, pmtrebate, setPaymentRebate,
    spendrebate, setSpendRebate, volumerebate, setVolumeRebate } = useContract();
  // console.log("[ContractDetail]", serviceterm, pmtrebate, spendrebate, volumerebate);

  // add or edit
  const [openServiceTerms, setOpenServiceTerms] = useState(false);
  const [openPaymentRebates, setOpenPaymentRebates] = useState(false);
  const [openSpendRebates, setOpenSpendRebates] = useState(false);
  const [openVolumeRebates, setOpenVolumeRebates] = useState(false);
  const [editDefaultValue, setEditDefaultValue] = useState({});
  // delete toast
  const [openToast, setOpenToast] = useState(false);
  const [deleteKey, setDeleteKey] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);


  const handleOpenAdd = (key) => {
    setEditDefaultValue({});
    setDeleteIndex(null);
    if (key === 'serviceterm') setOpenServiceTerms(true);
    else if (key === 'pmtrebate') setOpenPaymentRebates(true);
    else if (key === 'spendrebate') setOpenSpendRebates(true);
    else if (key === 'volumerebate') setOpenVolumeRebates(true);
  };

  const handleOpenEdit = (key, index) => {
    setDeleteIndex(index);
    if (key === 'serviceterm') {
      setEditDefaultValue({...serviceterm[index]});
      setOpenServiceTerms(true);
    }
    else if (key === 'pmtrebate') {
      setEditDefaultValue({...pmtrebate[index]});
      setOpenPaymentRebates(true);
    }
    else if (key === 'spendrebate') {
      setEditDefaultValue({...spendrebate[index]});
      setOpenSpendRebates(true);
    }
    else if (key === 'volumerebate') {
      setEditDefaultValue({...volumerebate[index]});
      setOpenVolumeRebates(true);
    }
  };

  const handleAddEdit = (key, value) => {
    if (key === 'serviceterm') {
      if (deleteIndex === null) {
        setServiceTerm([
          ...serviceterm,
          value,
        ]);
      }
      else {
        const temp = [...serviceterm];
        temp[deleteIndex] = value;
        setServiceTerm(temp);
      }
    }
    else if (key === 'pmtrebate') {
      if (deleteIndex === null) {
        setPaymentRebate([
          ...pmtrebate,
          value,
        ]);
      }
      else {
        const temp = [...pmtrebate];
        temp[deleteIndex] = value;
        setPaymentRebate(temp);
      }
    }
    else if (key === 'spendrebate') {
      if (deleteIndex === null) {
        setSpendRebate([
          ...spendrebate,
          value,
        ]);
      }
      else {
        const temp = [...spendrebate];
        temp[deleteIndex] = value;
        setSpendRebate(temp);
      }
    }
    else if (key === 'volumerebate') {
      if (deleteIndex === null) {
        setVolumeRebate([
          ...volumerebate,
          value,
        ]);
      }
      else {
        const temp = [...volumerebate];
        temp[deleteIndex] = value;
        setVolumeRebate(temp);
      }
    }
  };
  
  const handleOpenDelete = (key, index) => {
    setDeleteKey(key);
    setDeleteIndex(index);
    setOpenToast(true);
  };

  const handleDelete = () => {
    // console.log("[ContractDetail] handleDelete", deleteKey, deleteIndex);
    if (deleteKey === 'serviceterm') {
      const temp = [...serviceterm];
      temp.splice(deleteIndex, 1);
      setServiceTerm(temp);
    }
    else if (deleteKey === 'pmtrebate') {
      const temp = [...pmtrebate];
      temp.splice(deleteIndex, 1);
      setPaymentRebate(temp);
    }
    else if (deleteKey === 'spendrebate') {
      const temp = [...spendrebate];
      temp.splice(deleteIndex, 1);
      setSpendRebate(temp);
    }
    else if (deleteKey === 'volumerebate') {
      const temp = [...volumerebate];
      temp.splice(deleteIndex, 1);
      setVolumeRebate(temp);
    }
  };


  return (
    <>
      <Box className={classes1.contractDetail} >
        <Box className="button-panel">
          <Button
            variant="contained" 
            color="primary"
            className={classes.newCompleteBtn}
            onClick={() => handleOpenAdd('volumerebate')}
          >
            Add Volume Rebate
          </Button>
          <Button
            variant="contained" 
            color="primary"
            className={classes.newCompleteBtn}
            onClick={() => handleOpenAdd('spendrebate')}
          >
            Add Spend Rebate
          </Button>
          <Button
            variant="contained" 
            color="primary"
            className={classes.newCompleteBtn}
            onClick={() => handleOpenAdd('pmtrebate')}
          >
            Add Payment Rebate
          </Button>
          <Button
            variant="contained" 
            color="primary"
            className={classes.newCompleteBtn}
            onClick={() => handleOpenAdd('serviceterm')}
          >
            Add Shipping Term
          </Button>
        </Box>
        <Grid 
          container 
          className="contract-grid" 
          direction="row" 
          justifyContent="space-between" 
          alignItems="flex-start"
        >
          <Grid item className="contract-panel">
            <Typography className="contract-header">
              Service Term
            </Typography>
            <Table className='contract-table'>
              <TableHead>
                <TableRow>
                  <TableCell>Shipping Charge per Invoice</TableCell>
                  <TableCell>Service Fee(Freight/Handling)</TableCell>
                  <TableCell>Over Nighted</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  serviceterm.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>${item.servicetermshippingfee}</TableCell>
                      <TableCell>${item.servicetermfreightfee}</TableCell>
                      <TableCell>
                        {TimeLine[item.servicetermtimeline]}
                        <IconButton onClick={() => handleOpenDelete('serviceterm', index)}>
                          <img src={deleteIcon} alt="" />
                        </IconButton>
                        <IconButton onClick={() => handleOpenEdit('serviceterm', index)}>
                          <img src={editIcon} alt="" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </Grid>
          <Grid item className="contract-panel">
            <Typography className="contract-header">
              Payment Rebate
            </Typography>
            <Table className='contract-table'>
              <TableHead>
                <TableRow>
                  <TableCell>PO Sent in</TableCell>
                  <TableCell>Rebate Percentage Value</TableCell>
                  <TableCell>Payment Sent in</TableCell>
                  <TableCell>Rebate Percentage Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  pmtrebate.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{TimeLine[item.pmtrebateposent]}</TableCell>
                      <TableCell>{item.pmtrebatepopct}%</TableCell>
                      <TableCell>{TimeLine[item.pmtrebatepaymentsent]}</TableCell>
                      <TableCell>
                        {item.pmtrebatepaymentpct}%
                        <IconButton onClick={() => handleOpenDelete('pmtrebate', index)}>
                          <img src={deleteIcon} alt="" />
                        </IconButton>
                        <IconButton onClick={() => handleOpenEdit('pmtrebate', index)}>
                          <img src={editIcon} alt="" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </Grid>
          <Grid item className="contract-panel">
            <Typography className="contract-header">
              Spend Rebate
            </Typography>
            <Table className='contract-table'>
              <TableHead>
                <TableRow>
                  <TableCell>Dollar Spend (In US Dollar Amount)</TableCell>
                  <TableCell>Amount needed for Rebate (In US Dollar Amount)</TableCell>
                  <TableCell>Percent of spend Rebated</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  spendrebate.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>${item.spendtotal}</TableCell>
                      <TableCell>${item.spendrebatespendneeded}</TableCell>
                      <TableCell>
                        {item.spendrebatepct}%
                        <IconButton onClick={() => handleOpenDelete('spendrebate', index)}>
                          <img src={deleteIcon} alt="" />
                        </IconButton>
                        <IconButton onClick={() => handleOpenEdit('spendrebate', index)}>
                          <img src={editIcon} alt="" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </Grid>
          <Grid item className="contract-panel">
            <Typography className="contract-header">
              Volume Rebate
            </Typography>
            <Table className='contract-table'>
              <TableHead>
                <TableRow>
                  <TableCell>CPT Codes</TableCell>
                  <TableCell>Current Volume Total</TableCell>
                  <TableCell>Volume Needed for Rebate</TableCell>
                  <TableCell>% Rebated</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  volumerebate.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.cptcode}</TableCell>
                      <TableCell>${item.currentvolume}</TableCell>
                      <TableCell>${item.volumeneeded}</TableCell>
                      <TableCell>
                        {item.volumepctrebate}%
                        <IconButton onClick={() => handleOpenDelete('volumerebate', index)}>
                          <img src={deleteIcon} alt="" />
                        </IconButton>
                        <IconButton onClick={() => handleOpenEdit('volumerebate', index)}>
                          <img src={editIcon} alt="" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Box>

      <ContractDetailModal
        open={openServiceTerms}
        setOpen={setOpenServiceTerms}
        title='Service Term'
        handleAdd={(val) => handleAddEdit('serviceterm', val)}
        ModalContent={ServiceTermForm}
        requiredData={requiredServiceTermData}
        defaultValue={editDefaultValue}
        isCreate={options.isCreate}
      />

      <ContractDetailModal
        open={openPaymentRebates}
        setOpen={setOpenPaymentRebates}
        title='Payment Rebate'
        handleAdd={(val) => handleAddEdit('pmtrebate', val)}
        ModalContent={PaymentRebateForm}
        requiredData={requiredPaymentRebateData}
        defaultValue={editDefaultValue}
        isCreate={options.isCreate}
      />

      <ContractDetailModal
        open={openSpendRebates}
        setOpen={setOpenSpendRebates}
        title='Spend Rebate'
        handleAdd={(val) => handleAddEdit('spendrebate', val)}
        ModalContent={SpendRebateForm}
        requiredData={requiredSpendRebateData}
        defaultValue={editDefaultValue}
        isCreate={options.isCreate}
      />

      <ContractDetailModal
        open={openVolumeRebates}
        setOpen={setOpenVolumeRebates}
        title='Volume Rebate'
        handleAdd={(val) => handleAddEdit('volumerebate', val)}
        ModalContent={VolumeRebateForm}
        requiredData={requiredVolumeRebateData}
        defaultValue={editDefaultValue}
        isCreate={options.isCreate}
      />

      <SnackbarModal
        open={openToast}
        setOpen={setOpenToast}
        type="warning"
        title=""
        content={t('contractmanagement.details.delete')}
        vertical="center"
        horizontal="center"
        width={500}
        height={117}
        transition="fade"
        className={classes.toastLarge}
        actions={[
          {
            label: 'Yes',
            handle: handleDelete,
          },
          {
            label: 'No',
          }
        ]}
      />
    </>
  );
}
