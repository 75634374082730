import { validateOwnership } from './Ownership';
import { validateUnitPriceQuantity, getUnitPriceQuantity } from './Product';
import { getArray } from "./handles";

export const validateParlevel = (parlevel) => {
  return (parlevel._2 !== "");
};
export const getParlevel = (parlevel) => {
  let newParlevel = {};
  if (typeof parlevel._1 === "string" && parlevel._1 !== "") newParlevel._1 = parlevel._1;
  newParlevel._2 = parlevel._2 || "0";
  return newParlevel;
};

export const InventoryProductModel = {
  id: '',
  iitemnumber: '',
  ireferencenumber: '',
  // ilotcode: '',
  // iexpirationdate: '',
  iownership: '',
  // iudi: '',
  parlevel: '',
  iparlevels: [],
  itemcount: 0,
  iproductname: '',
  iproductdescription: '',
  iprice: '',
  iunits: [],
  ilastexpirydate: '',
  iuom: '',
  unclassified: false,
};
export const validateInventoryProduct = (inventory) => {
  return (inventory.iitemnumber !== ""
    || inventory.ireferencenumber !== ""
    || validateOwnership(inventory.iownership)
    || inventory.parlevel !== ""
  );
};
export const getInventoryProduct = (inventory) => {
  let newInventory = {};
  if (typeof inventory.id === "string" && inventory.id !== "") newInventory.id = inventory.id;
  if (typeof inventory.iitemnumber === "string" && inventory.iitemnumber !== "") newInventory.iitemnumber = inventory.iitemnumber;
  if (typeof inventory.ireferencenumber === "string" && inventory.ireferencenumber !== "") newInventory.ireferencenumber = inventory.ireferencenumber;
  if (validateOwnership(inventory.iownership)) newInventory.iownership = inventory.iownership;
  // if (typeof inventory.iudi === "string" && inventory.iudi !== "") newInventory.iudi = inventory.iudi;
  if (typeof inventory.parlevel === "string" && inventory.parlevel !== "") newInventory.parlevel = inventory.parlevel;
  newInventory.iparlevels = getArray(inventory.iparlevels, validateParlevel, getParlevel);
  newInventory.itemcount = inventory.itemcount || "0";
  if (typeof inventory.iproductname === "string" && inventory.iproductname !== "") newInventory.iproductname = inventory.iproductname;
  if (typeof inventory.iproductdescription === "string" && inventory.iproductdescription !== "") newInventory.iproductdescription = inventory.iproductdescription;
  if ((typeof inventory.iprice === "string" && inventory.iprice !== "") || typeof inventory.iprice === "number") newInventory.iprice = inventory.iprice;
  newInventory.iunits = getArray(inventory.iunits, validateUnitPriceQuantity, getUnitPriceQuantity);
  if (typeof inventory.ilastexpirydate === "string" && inventory.ilastexpirydate !== "") newInventory.ilastexpirydate = inventory.ilastexpirydate;
  if (typeof inventory.iuom === "string" && inventory.iuom !== "") newInventory.iuom = inventory.iuom;
  if (typeof inventory.unclassified === "boolean") newInventory.unclassified = inventory.unclassified;
  return newInventory;
};
export const getInvenKey = (inventory) => (
  `(25)${inventory.ireferencenumber}(35)${inventory.iownership}`
);
