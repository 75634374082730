export const InvoiceModel = {
  id: '',
  invinvoicenumber: '',
  invpurchaseorderid: '',
  invinvoicedate: '',
  invoicesentdate: '',
  invinvoicetotalamount: '',
  invseid: '',
  // is_sent_hospital: false,
  // is_sent_erp: false,
};

export const validateInvoice = (invoice) => {
  return (invoice.invinvoicenumber !== ""
    || invoice.invpurchaseorderid !== ""
    || invoice.invinvoicedate !== "" 
    || invoice.invinvoicetotalamount !== "" 
    || invoice.invseid !== "" 
  );
};

export const getInvoice = (invoice) => {
  let newInvoice = {};
  if (typeof invoice.id === "string" && invoice.id !== "") newInvoice.id = invoice.id;
  newInvoice.invinvoicenumber = invoice.invinvoicenumber || "";
  if (typeof invoice.invpurchaseorderid === "string" && invoice.invpurchaseorderid !== "") newInvoice.invpurchaseorderid = invoice.invpurchaseorderid;
  newInvoice.invinvoicedate = invoice.invinvoicedate || "";
  if (typeof invoice.invoicesentdate === "string" && invoice.invoicesentdate !== "") newInvoice.invoicesentdate = invoice.invoicesentdate;
  newInvoice.invinvoicetotalamount = invoice.invinvoicetotalamount || "0";
  if (typeof invoice.invseid === "string" && invoice.invseid !== "") newInvoice.invseid = invoice.invseid;
  // if (typeof invoice.is_sent_hospital === "boolean") newInvoice.is_sent_hospital = invoice.is_sent_hospital;
  // if (typeof invoice.is_sent_erp === "boolean") newInvoice.is_sent_erp = invoice.is_sent_erp;
  return newInvoice;
};