import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import React, { Fragment } from "react";
import { formatDate } from '../../services/util';
const styles = StyleSheet.create({
    page: {
        fontSize: "9.5px",
    },
    pageHeader: {
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "5px",
        color: "gray",
        fontSize: "10px",
    },
    pageHeaderTitle: {
        margin: "0 20px 0 20px",
        padding: "0 0 5px 0",
        borderBottom: "1px solid gray",
        fontSize: "12px",
    },
    pageBody: {
        padding: "0 20px 0 20px"
    },
    table: {
        padding: "10px 0 0 0"
    },
    tableTitle: {
        fontSize: "11px",
    },
    tableBody: {
        padding: "2px 0 0 0",
        flexDirection: "row",
    },
    tableHeading: {
        fontSize: "8.5px",
        color:"gray",
        fontWeight:100,
    },
    tableData: {
        fontSize:"9px",
    },
    tableCell1: {
        width: "110px",
        fontSize: "9.5px",
        padding: "1px 0 0 0"
    },
    tableCell2: {
        width: "183.33px",
    },
    productDetailsCol1:{
        width: "110px",
        color:"gray"
    },
    productDetailsCol2:{
        width: "165px",
    },
    productDetailsCol3:{
        width: "100px",
        color:"gray"
    },
    productDetailsCol4:{
        width: "175px",
    },
});
const CompliancePDF = ({ data = [] }) => {
    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }
    const currentDate = new Date();
    const date = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}, ${formatAMPM(currentDate)}`;
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* Page Header */}
                <View fixed>
                    <View style={styles.pageHeader}>
                        <Text>{date}</Text>
                        <Text>Tydei Health</Text>
                    </View>
                    <Text style={styles.pageHeaderTitle}>Compliance</Text>
                </View>
                {/* Page Body */}
                {
                    data.map((d, i) =>
                        <Fragment key={d.contractId}>
                            {
                                (i !== 0 && i % 2 === 0) && <Text break></Text>
                            }
                            <View style={styles.pageBody}>
                                <View style={styles.table}>
                                    <Text style={styles.tableTitle}>Compliance Details</Text>
                                    <View style={styles.tableBody}>
                                        <View style={styles.tableCell1}>
                                            <Text style={styles.tableHeading}>Rule Type Level</Text>
                                            <Text style={styles.tableData}>{d.payload.surgicaleventcompliancedata.ruletypelevel}</Text>
                                        </View>
                                        <View style={styles.tableCell1}>
                                            <Text style={styles.tableHeading}>Rule Type</Text>
                                            <Text style={styles.tableData}>{d.payload.surgicaleventcompliancedata.ruletype}</Text>
                                        </View>
                                        <View style={styles.tableCell1}>
                                            <Text style={styles.tableHeading}>Rule Outcome</Text>
                                            <Text style={styles.tableData}>{d.payload.surgicaleventcompliancedata.ruleoutcome}</Text>
                                        </View>
                                        <View style={styles.tableCell1}>
                                            <Text style={styles.tableHeading}>User</Text>
                                            <Text style={styles.tableData}>{d.payload.surgicaleventcompliancedata.user}</Text>
                                        </View>
                                        <View style={styles.tableCell1}>
                                            <Text style={styles.tableHeading}>Event Date</Text>
                                            <Text style={styles.tableData}>{formatDate(d.payload.surgicaleventcompliancedata.date)}</Text>
                                        </View>
                                    </View>
                                </View>
                                <Text style={{ ...styles.tableTitle, padding: "10px 0 0 0" }}>SE ID: {d.payload.surgicaleventdata.seid}</Text>
                                <View style={styles.table}>
                                    <Text style={styles.tableTitle}>Patient Details</Text>
                                    <View style={styles.tableBody}>
                                        <View style={styles.tableCell1}>
                                            <Text style={styles.tableHeading}>MRN</Text>
                                            <Text style={styles.tableData}>{d.payload.surgicaleventdata.mrn}</Text>
                                        </View>
                                        <View style={styles.tableCell1}>
                                            <Text style={styles.tableHeading}>First Name</Text>
                                            <Text style={styles.tableData}>{d.payload.surgicaleventdata.patientfirstname}</Text>
                                        </View>
                                        <View style={styles.tableCell1}>
                                            <Text style={styles.tableHeading}>Last Name</Text>
                                            <Text style={styles.tableData}>{d.payload.surgicaleventdata.patientlastname}</Text>
                                        </View>
                                        <View style={styles.tableCell1}>
                                            <Text style={styles.tableHeading}>Gender</Text>
                                            <Text style={styles.tableData}>{d.payload.surgicaleventdata.patientgender}</Text>
                                        </View>
                                        <View style={styles.tableCell1}>
                                            <Text style={styles.tableHeading}>DOB</Text>
                                            <Text style={styles.tableData}>{formatDate(d.payload.surgicaleventdata.patientdob)}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.table}>
                                    <Text style={styles.tableTitle}>Event Details</Text>
                                    <View style={styles.tableBody}>
                                        <View style={styles.tableCell2}>
                                            <Text style={styles.tableHeading}>Date</Text>
                                            <Text style={styles.tableData}>{formatDate(d.payload.surgicaleventdata.eventdate)}</Text>
                                        </View>
                                        <View style={styles.tableCell2}>
                                            <Text style={styles.tableHeading}>Procedure</Text>
                                            <Text style={styles.tableData}>{d.payload.surgicaleventdata.surgicalprocedure}</Text>
                                        </View>
                                        <View style={styles.tableCell2}>
                                            <Text style={styles.tableHeading}>Side</Text>
                                            <Text style={styles.tableData}>{d.payload.surgicaleventdata.surgicalside}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.table}>
                                    <Text style={styles.tableTitle}>Physician Details</Text>
                                    <View style={styles.tableBody}>
                                        <View style={styles.tableCell2}>
                                            <Text style={styles.tableHeading}>Salutation</Text>
                                            <Text style={styles.tableData}>{d.payload.surgicaleventdata.physiciansalutation}</Text>
                                        </View>
                                        <View style={styles.tableCell2}>
                                            <Text style={styles.tableHeading}>First Name</Text>
                                            <Text style={styles.tableData}>{d.payload.surgicaleventdata.physicianfirstname}</Text>
                                        </View>
                                        <View style={styles.tableCell2}>
                                            <Text style={styles.tableHeading}>Last Name</Text>
                                            <Text style={styles.tableData}>{d.payload.surgicaleventdata.physicianlastname}</Text>
                                        </View>
                                    </View>
                                </View>
                                <Text style={{ ...styles.tableTitle, borderBottom: "0.5px solid gray", padding: "10px 0 5px 0" }}>Product Details</Text>
                                <View style={{ flexDirection: "row", padding: "5px 0 0 0" }}>
                                    <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
                                        <Text style={{color:"gray"}}>Reference Number: </Text>
                                        <Text style={styles.tableData}>{d.payload.auditedproductdata.sepreferencenumber}</Text>
                                    </View>
                                    <Text> </Text>
                                    <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
                                        <Text style={{color:"gray"}}>Lot Code: </Text>
                                        <Text style={styles.tableData}>{d.payload.auditedproductdata.seplotcode}</Text>
                                    </View>
                                </View>
                                <View style={{ flexDirection: "row",justifyContent:"space-between", padding: "10px 0 0 0",lineHeight:"1.3px" }}>
                                    <View style={{ width: "270px" }}>
                                        <View style={{ flexDirection: "row" }}>
                                            <Text style={styles.productDetailsCol1}>UDI</Text>
                                            <Text style={styles.productDetailsCol2}>{d.payload.auditedproductdata.sepudi}</Text>
                                        </View>
                                        <View style={{ flexDirection: "row" }}>
                                            <Text style={styles.productDetailsCol1}>Product Manufacturer</Text>
                                            <Text style={styles.productDetailsCol2}>{d.payload.auditedproductdata.sepproductmanufacturer}</Text>
                                        </View>
                                        <View style={{ flexDirection: "row" }}>
                                            <Text style={styles.productDetailsCol1}>Product Distributor</Text>
                                            <Text style={styles.productDetailsCol2}>{d.payload.auditedproductdata.sepdistributor}</Text>
                                        </View>
                                        <View style={{ flexDirection: "row" }}>
                                            <Text style={styles.productDetailsCol1}>Product Name</Text>
                                            <Text style={styles.productDetailsCol2}>{d.payload.auditedproductdata.sepproductname}</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: "270px" }}>
                                        <View style={{ flexDirection: "row" }}>
                                            <Text style={styles.productDetailsCol3}>Product Description</Text>
                                            <Text style={styles.productDetailsCol4}>{d.payload.auditedproductdata.sepproductdescription}</Text>
                                        </View>
                                        <View style={{ flexDirection: "row" }}>
                                            <Text style={styles.productDetailsCol3}>Expiration</Text>
                                            <Text style={styles.productDetailsCol4}>{formatDate(d.payload.auditedproductdata.sepproductexpiration)}</Text>
                                        </View>
                                        <View style={{ flexDirection: "row" }}>
                                            <Text style={styles.productDetailsCol3}>Price</Text>
                                            <Text style={styles.productDetailsCol4}>{d.payload.auditedproductdata.sepproductprice}</Text>
                                        </View>
                                        <View style={{ flexDirection: "row" }}>
                                            <Text style={styles.productDetailsCol3}>Product Type</Text>
                                            <Text style={styles.productDetailsCol4}>{d.payload.auditedproductdata.sepproducttype}</Text>
                                        </View>
                                        <View style={{ flexDirection: "row" }}>
                                            <Text style={styles.productDetailsCol3}>Waste</Text>
                                            <Text style={styles.productDetailsCol4}>{d.payload.auditedproductdata.sepproductwaste}</Text>
                                        </View>
                                        <View style={{ flexDirection: "row" }}>
                                            <Text style={styles.productDetailsCol3}>Ownership</Text>
                                            <Text style={styles.productDetailsCol4}>{d.payload.auditedproductdata.sepownership}</Text>
                                        </View>
                                        <View style={{ flexDirection: "row" }}>
                                            <Text style={styles.productDetailsCol3}>Product Side</Text>
                                            <Text style={styles.productDetailsCol4}>{d.payload.auditedproductdata.sepproductside}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <Text style={{ borderBottom: "1px dashed gray",margin:"10px 20px 0 20px"}}></Text>
                        </Fragment>)
                }

            </Page>
        </Document>
    )
}
export default CompliancePDF;