export const AdminVendorDetailModel = {
  auto_invoice: false,
  auto_invoice_hospital: false,
};
export const validateAdminVendorDetail = (admin) => {
  return (typeof admin.auto_invoice === 'boolean'
    || typeof admin.auto_invoice_hospital === 'boolean'
  );
};
export const getAdminVendorDetail = (admin) => {
  let newAdmin = {};
  newAdmin.auto_invoice = admin.auto_invoice || false;
  newAdmin.auto_invoice_hospital = admin.auto_invoice_hospital || false;
  return newAdmin;
};
