import { VendorRole, HospitalRole, OperatorRole } from "../../services/daml-modules1";
import { validateVendorDetail, getVendorDetail } from '../../models/VendorDetail';
import { validateAdminDetail, getAdminDetail } from '../../models/AdminDetail';
import { validateInventoryLocation, getInventoryLocation } from '../../models/InventoryData';
import { getTemplateListFromResponse } from "../../services/util";

/**
 * mask function for Tax ID
 * @param {String} d 
 * @returns {String}
 */
export const maskForTax = (d) => {
  // pattern xxxxxxx99
  return d.replace(/(.{0,7})(\d{2}).*/, ($0, $1, $2) => {
    // console.log("[mask]", $0, $1, $2);
    return 'x'.repeat($1.length).concat($2);
  });
};

/**
 * accept RoleOne
 * exercise UpdateRegistration choice of VendorRole
 * @param {Object} onedetails 
 * @param {Object} ledger 
 * @param {String} contractId 
 * @returns {Boolean}
 */
export const exerciseRoleOneAccept = async (onedetails, ledger, contractId) => {

  console.log("cid: " + contractId);
  console.log("onedetails: ", onedetails);

  if (!validateVendorDetail(onedetails)) {
    alert("You must input all fields.");
    return false;
  }

  try {

    const newonedetails = getVendorDetail(onedetails);

    let res = await ledger.exercise(VendorRole.UpdateRegistration, contractId, {
      newvendordetails: newonedetails
    });

    // get contractId of the updated VendorRole
    let templateHR = getTemplateListFromResponse(res, "VendorRole");
    return (templateHR.length > 0);

  }
  catch (e) { }

  return false;

};

/**
 * accept RoleOne
 * exercise SetAdminVendorDetails choice of VendorRole
 * @param {Object} admindetails 
 * @param {Object} ledger 
 * @param {String} contractId 
 * @param {String} userlistPayload
 * @returns {Boolean}
 */
export const exerciseVendorAdminAccept = async (admindetails, ledger, contractId, userlistPayload) => {

  const email = userlistPayload?.email;
  const username = userlistPayload?.name;

  // console.log("cid: " + contractId);
  // console.log("admindetails: ", admindetails);

  // if (!validateAdminVendorDetail(admindetails)) {
  //   alert("You must input all fields.");
  //   return false;
  // }

  try {

    // const newadmindetails = getAdminVendorDetail(admindetails);

    let res = await ledger.exercise(VendorRole.SetAdminVendorDetails, contractId, {
      newadmindetails: admindetails,
      useremail: email,
      username: username,
    });

    // get contractId of the updated VendorRole
    let templateHR = getTemplateListFromResponse(res, "VendorRole");
    return (templateHR.length > 0);

  }
  catch (e) { }

  return false;

};

/**
 * accept RoleTwo
 * exercise UpdateRegistration2 choice of HospitalRole
 * @param {Object} twodetails 
 * @param {Object} ledger 
 * @param {String} contractId 
 * @returns {Boolean}
 */


export const exerciseRoleTwoAccept = async (twodetails, ledger, contractId) => {
  console.log("cid: " + contractId);
  console.log("twodetails: ", twodetails);

  // Temporary Comment 
  // if (!validateHospitalDetail(twodetails)) {
  //   alert("You must input all fields.");
  //   return false;
  // }

  try {
    let res = await ledger.exercise(HospitalRole.UpdateRegistration2, contractId, {
      newhospitaldetails: twodetails
    });

    // get contractId of the updated HospitalRole
    let templateHR = getTemplateListFromResponse(res, "HospitalRole");
    return (templateHR.length > 0);

  }
  catch (e) { }

  return false;

};

/**
 * accept Hospital AdminDetail
 * exercise SetAdminDetails choice of HospitalRole
 * @param {Object} admindetails 
 * @param {Object} ledger 
 * @param {String} contractId 
 * @param {String} userlistPayload 
 * @returns {Boolean}
 */
export const exerciseHospitalAdminAccept = async (admindetails, ledger, contractId, userlistPayload) => {

  const email = userlistPayload?.email;
  const username = userlistPayload?.name;

  if (!validateAdminDetail(admindetails)) {
    alert("You must input all fields.");
    return false;
  }

  try {

    const adminParams = getAdminDetail(admindetails);
    console.log("admindetails: ", admindetails, JSON.stringify(adminParams));

    let res = await ledger.exercise(HospitalRole.SetAdminDetails, contractId, {
      newadmindetails: adminParams,
      useremail: email,
      username: username,
    });

    // get contractId of the updated HospitalRole
    let templateHR = getTemplateListFromResponse(res, "HospitalRole");
    return (templateHR.length > 0);

  }
  catch (e) { }

  return false;

};

/**
 * create a InventoryLocation
 * exercise HCreateInventoryLocation choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} contractId 
 * @param {Object} inventorylocation 
 * @returns {Boolean}
 */
export const createInventoryLocation = async ({
  ledger, contractId, inventorylocation
}) => {

  if (!validateInventoryLocation(inventorylocation)) return false;

  try {

    const newinventorylocation = getInventoryLocation(inventorylocation);

    let res = await ledger.exercise(HospitalRole.HCreateInventoryLocation, contractId, {
      ...newinventorylocation,
    });

    // get contractId of the updated InventoryLocation
    let templateIL = getTemplateListFromResponse(res, "InventoryLocation");
    return (templateIL.length > 0);

  }
  catch (e) {
    console.log("[createInventoryLocation]", e);
  }

  return false;

};

/**
 * remove a InventoryLocation
 * exercise HArchiveInventoryLocation choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} contractId 
 * @param {String} invlcid 
 * @returns 
 */
export const removeInventoryLocation = async ({
  ledger, contractId, invlcid
}) => {

  try {

    let res = await ledger.exercise(HospitalRole.HArchiveInventoryLocation, contractId, {
      invlcid,
    });

    console.log("[removeInventoryLocation]", res);

  }
  catch (e) {
    console.log("[removeInventoryLocation]", e);
  }

};

/**
 * create new surgeon in Hospital
 * exercise AddUser choice of HospitalRole
 * @param {Object} ledger 
 * @param {Object} newuser
 * @param {String} role
 * @returns {Boolean|String}
 */
export const createSurgeon = async ({
  ledger, newuser, roleCid
}) => {
  try {
      // create userlist in ledger
      let res = await ledger.exercise(HospitalRole.AddSurgeon, roleCid, {
          salutation: newuser.salutation,
          firstname: newuser.fname,
          lastname: newuser.lname,
          status: "Disconnected",
          pref_card_id : "",
      });

      console.log("[createUserList]", res);

  } catch (e) {
      console.error(e);
  }

  return false;
};


export const removeSurgeon = async ({
  ledger, roleCid, surgeonId
}) => {
  try {
      let res = await ledger.exercise(HospitalRole.RemoveSurgeon, roleCid, {
        surgeonid: surgeonId
      });

      console.log("[Remove surgeon]", res);
      return true;
  } catch (e) {
      console.error(e);
  }
  return false;
};

export const modifySurgeonDetail = async ({
  ledger, roleCid, newfirstname,newlastname,newsalutation,newpref_card_id,cid
}) => {
  console.log("handledata",newsalutation,newfirstname,newlastname,newpref_card_id)

  try {
      let res = await ledger.exercise(OperatorRole.UpdateSurgeonData, roleCid, {
        cid: cid,
        newsalutation : newsalutation,
          newfirstname : newfirstname,  
          newlastname : newlastname,  
          newstatus : newpref_card_id ? "Connected" : "Disconnected",
          newpref_card_id : newpref_card_id,
      });

      console.log("[Update surgeon]", res);
      return true;
  } catch (e) {
      console.error(e);
  }
  return false;
};
