import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';
import ConfirmListModal from "../ConfirmListModal";


export default function ConfirmSimpleModal({
  modaltitle,
  contractList = [],
  setContractList = null,
  textFunction = null,
  mapFunction = null,
  confirmModalOpen = false,
  setConfirmModalOpen = null,
  confirmDialogOpen = false,
  setConfirmDialogOpen = null,
}) {


  if (typeof textFunction !== "function") textFunction = (d) => `Total: ${d}`;
  if (typeof mapFunction !== "function") mapFunction = (c) => `${c}`;
  if (typeof setConfirmModalOpen !== "function") setConfirmModalOpen = () => {};
  if (typeof setConfirmDialogOpen !== "function") setConfirmDialogOpen = () => {};


  return (
    <>
      
      <ConfirmListModal
        isButtonVisible={false}
        isCheckBoxVisible={false}
        isHeaderVisible={true}
        modalOpenController={confirmModalOpen}
        modaltitle={modaltitle}
        contractList={contractList}
        setDetailValue={(data) => setConfirmModalOpen(false)}
        cancelHandler={() => setConfirmModalOpen(false)}
      />

      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {modaltitle}
        </DialogTitle>
        <DialogContent>

          <p>
            {textFunction(contractList.length)}
          </p>
          {
            contractList.map((c, i) => (
              <p key={i}>
                {i + 1}. {mapFunction(c)} 
              </p>
            ))
          }

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
}
