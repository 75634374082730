import { useCallback } from "react";
import { useSelector } from "react-redux";
import { dispatch } from "../store";
import { setContract, setContractAll, setContractData } from "../store/actions/contractAction";


/**
 * Contract Management Hook
 * @returns 
 */
export default function useContract () {
  const detail = useSelector(state => state.contract.detail);
  const detailErrors = useSelector(state => state.contract.detailErrors);
  const serviceterm = useSelector(state => state.contract.serviceterm);
  const pmtrebate = useSelector(state => state.contract.pmtrebate);
  const spendrebate = useSelector(state => state.contract.spendrebate);
  const volumerebate = useSelector(state => state.contract.volumerebate);
  const cptcodetotal = useSelector(state => state.contract.cptcodetotal);

  const setAll = useCallback((data) => dispatch(setContractAll(data)), []);
  const setDetail = useCallback((data) => dispatch(setContractData(data)), []);
  const setDetailErrors = useCallback((data) => dispatch(setContract({detailErrors: data})), []);
  const setServiceTerm = useCallback((data) => dispatch(setContract({serviceterm: data})), []);
  const setPaymentRebate = useCallback((data) => dispatch(setContract({pmtrebate: data})), []);
  const setSpendRebate = useCallback((data) => dispatch(setContract({spendrebate: data})), []);
  const setVolumeRebate = useCallback((data) => dispatch(setContract({volumerebate: data})), []);
  const setCptCodeTotal = useCallback((data) => dispatch(setContract({cptcodetotal: data})), []);

  return { detail, detailErrors, setDetail, setDetailErrors, setAll, serviceterm, setServiceTerm, pmtrebate, setPaymentRebate,
    spendrebate, setSpendRebate, volumerebate, setVolumeRebate, cptcodetotal, setCptCodeTotal };
}
