import React, { Fragment, useEffect, useState } from "react";
import {
    Button, Dialog, DialogContent, DialogTitle, DialogActions, Typography, Grid
} from "@material-ui/core";
import ErrorDialog from '../../components/Modals/GeneralModal/error-dialog';
import useStyles from "../payments/styles";
import closeIcon from "../../icons/new-close-icon.svg";
import { CustomSelect, CustomTextField } from "../../components/Inputs";
import classNames from "classnames";

function ContactPopup({
    openModal, handleCancel, handleSave, data, setOpenToast, setToastContent
}) {
    const [errorModal, setErrorModal] = useState(false);

    const classes = useStyles();
    const [contact, setContact] = useState({
        firstname: '', lastname: '', gender: '', phone: '', mobile: '', email: '', fax: '', jobtitle: '', department: '',
    });

    useEffect(() => {
        if (data)
            setContact(data);

        return () => {
            setContact({
                firstname: '', lastname: '', gender: '', phone: '', mobile: '', email: '', fax: '', jobtitle: '', department: ''
            })
        }
    }, [openModal, data])


    const handleChange = (value, key) => {
        let _contact = { ...contact };
        _contact[key] = value;
        setContact(_contact);
    }


    const getInput = (item) => {
        let view = null;

        switch (item.type) {
            case 'dropdown':
                view = (<CustomSelect
                    placeholder="Select One"
                    width={item.width}
                    value={item.value}
                    items={item.items}
                    onChange={item.onChange}
                />)
                break;

            default:
                view = (<CustomTextField
                    placeholder="Type here..."
                    value={item.value}
                    options={{ disabled: false }}
                    width={item.width}
                    onChange={item.onChange}
                />)
                break;
        }
        return view;
    }


    return (
        <>
            <Dialog
                open={openModal}
                className={classes.customRoot}
                disableEnforceFocus
                aria-labelledby="draggable-dialog-title"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >

                    <Button
                        className={classes.closeButton}
                        onClick={handleCancel}
                    >
                        <img src={closeIcon} alt="" />
                    </Button>
                </Grid>
                <DialogTitle className='MuiDialogTitleRoot'>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Typography variant="inherit" component="span" className={classes.popUpTitle1}>
                            {
                                !data ? 'Add Contact' : 'Edit Contact'
                            }
                        </Typography>
                    </Grid>

                </DialogTitle>
                <DialogContent
                    dividers={true}
                >
                    <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        justifyContent="center"
                    >
                        {
                            [
                                {
                                    label: 'First Name',
                                    value: contact?.firstname,
                                    onChange: (e) => handleChange(e, 'firstname'),
                                    width: '100%'
                                },
                                {
                                    label: 'Last Name',
                                    value: contact?.lastname,
                                    onChange: (e) => handleChange(e, 'lastname'),
                                    width: '100%'
                                },

                                {
                                    label: 'Gender',
                                    value: contact?.gender,
                                    type: 'dropdown',
                                    items: ['Male', 'Female'],
                                    onChange: (e) => handleChange(e, 'gender'),
                                    width: '50%'
                                },

                                {
                                    label: 'Phone',
                                    value: contact?.phone,
                                    onChange: (e) => handleChange(e, 'phone'),
                                    width: '100%'
                                },
                                {
                                    label: 'Mobile Number',
                                    value: contact?.mobile,
                                    onChange: (e) => handleChange(e, 'mobile'),
                                    width: '100%'
                                },
                                {
                                    label: 'Email',
                                    value: contact?.email,
                                    onChange: (e) => handleChange(e, 'email'),
                                    width: '100%'
                                },
                                {
                                    label: 'Fax',
                                    value: contact?.fax,
                                    onChange: (e) => handleChange(e, 'fax'),
                                    width: '100%'
                                },
                                {
                                    label: 'Job Title',
                                    value: contact?.jobtitle,
                                    onChange: (e) => handleChange(e, 'jobtitle'),
                                    width: '100%'
                                },
                                {
                                    label: 'Department',
                                    value: contact?.department,
                                    onChange: (e) => handleChange(e, 'department'),
                                    width: '100%'
                                },
                            ].map((item, index) => (<Fragment key={index}>
                                <Grid item xs={4}>
                                    <label className={classes.popUpLabel1}>{item.label}</label>
                                </Grid>
                                <Grid item xs={8}>
                                    {getInput(item)}
                                </Grid>
                            </Fragment >))
                        }

                    </Grid>
                    <DialogActions className={classes.actionbtns}>
                        <Button variant="contained" className={classNames(classes.secondarybtn,classes.fontCaseTransform)} onClick={handleCancel}>Cancel</Button>

                        <Button variant="contained" className={classNames(classes.primarybtn,classes.fontCaseTransform)}
                            onClick={() => {
                                if ((contact.firstname !== '' || contact.lastname !== '' ||
                                    contact.fax !== '' ||
                                    contact.jobtitle !== '' || contact.department !== '' ||
                                    contact.phone !== '' || contact.mobile !== '' ||
                                    contact.email !== '') && contact.gender !== '') {
                                    handleSave(contact);
                                    setToastContent("Contact successfully edited");
                                    if (!data) setToastContent("Contact successfully added");
                                    setOpenToast(true);
                                } else {
                                    handleCancel(false);
                                }
                            }
                            }
                        >
                            Add
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <ErrorDialog
                openModal={errorModal}
                setOpenModal={setErrorModal}
            />
        </>
    )
}

export default ContactPopup;