import { EVENT_SET_ALL, EVENT_SET_STATUS, EVENT_CLEAR_ALL, EVENT_REJECTED, EVENT_FINISHED } from "../actions/constants";

const default_state = {
  name: null,
  status: null,
  data: null
};

export default function eventReducer (state=default_state, action) {
  const { type, payload } = action;
  if (type === EVENT_SET_ALL) { 
    // e.g. {name: PRODUCT_CREATE_EVENT, status: EVENT_REQUIRED}
    if (payload && typeof payload === 'object') {
      if (!state.name || !state.status || state.status === EVENT_REJECTED || state.status === EVENT_FINISHED) {
        return { ...state, name: payload.name, status: payload.status, data:payload.data };
      }
    }
  }
  if (type === EVENT_SET_STATUS) { 
    // e.g. EVENT_VERIFIED
    if (payload && typeof payload === 'string') {
      return { ...state, status: payload };
    }
  }
  else if (type === EVENT_CLEAR_ALL) {
    return { ...default_state };
  }
  return state;
}