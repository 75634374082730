import { validateOwnership } from "./Ownership";
import { validateSurgicalEventProcedure, getSurgicalEventProcedure } from "./SurgicalEventProcedure";
import { getQuantityOnOrder } from "../services/util";
import { requiredFields, getModelMenu, validateModelItem, createPartyFromName, getArray } from "./handles";

export const SurgicalEventProductModel = {
  id: '',
  sepreferencenumber: '',
  sepdistributor: '',
  sepproductmanufacturer: '',
  sepproductname: '',
  sepproductdescription: '',
  sepproductexpiration: '',
  sepproductprice: '',
  sepproductesprice: '',
  seplotcode: '',
  sepproducttype: '',
  sepproductwaste: '',
  sepownership: 'Unknown', // TODO why is it necessary to default as Unknown?
  sepudi: '',
  sepesudi: '',
  sepproductside: '',
  sepuom: '',
  units: [],  // Array of UnitPriceQuantity
  seplocation: '', // for Inventory Location
  // unclassified: false,
  cptcode: [], // Array of CPTCode
  sepcontractnumber: '', // It's coming from Elastic Search Product
};

export const validateProduct = (product) => {
  // console.log('validateProduct', product);
  return (product &&
    (product.sepreferencenumber !== ""
      || product.sepdistributor !== ""
      || product.sepproductmanufacturer !== ""
      || product.sepproductname !== ""
      || product.sepproductdescription !== ""
      || product.sepproductexpiration !== ""
      || product.sepproductprice !== ""
      || product.seplotcode !== ""
      || product.sepudi !== ""
      || validateWasteType(product.sepproductwaste))
    && validateProductType(product.sepproducttype)
    && validateOwnership(product.sepownership)
    && validateSide(product.sepproductside)
  );
};

/**
 * get SurgicalEventProductModel Object
 * @param {SurgicalEventProductModel} product 
 * @param {Object} options 
 * @returns 
 */
export const getProduct = (product, options = {}) => {
  options = {
    vendor: null,                     // set sepdistributor by using vendor
    ownership: null,                  // set sepownership by using ownership
    isCreatePartyFromName: false,     // create a new party from name
    // unclassified: true,               // set unclassified
    sepproductesprice: false,         // set sepproductesprice
    ...options,
  };
  let newProduct = {};
  if (!product) return newProduct;
  if (typeof product.id === "string" && product.id !== "") newProduct.id = product.id;
  newProduct.sepreferencenumber = product.sepreferencenumber || "";
  if (!!options.vendor) newProduct.sepdistributor = options.vendor;
  else if (!!product.sepdistributor) newProduct.sepdistributor = product.sepdistributor;
  else newProduct.sepdistributor = "";
  if (options.isCreatePartyFromName) newProduct.sepdistributor = createPartyFromName(newProduct.sepdistributor);
  if (typeof product.sepproductmanufacturer === "string" && product.sepproductmanufacturer !== "") {
    newProduct.sepproductmanufacturer = product.sepproductmanufacturer;
    if (options.isCreatePartyFromName) newProduct.sepproductmanufacturer = createPartyFromName(newProduct.sepproductmanufacturer);
  }
  if (typeof product.sepproductname === "string" && product.sepproductname !== "") newProduct.sepproductname = product.sepproductname;
  if (typeof product.sepproductdescription === "string" && product.sepproductdescription !== "") newProduct.sepproductdescription = product.sepproductdescription;
  // newProduct.sepproductexpiration = product.sepproductexpiration || getTodayString(); // TODO : check again default value
  if (typeof product.sepproductexpiration === "string" && product.sepproductexpiration !== "") newProduct.sepproductexpiration = product.sepproductexpiration;
  newProduct.sepproductprice = product.sepproductprice || "0";
  if (typeof product.seplotcode === "string" && product.seplotcode !== "") newProduct.seplotcode = product.seplotcode;
  if (validateProductType(product.sepproducttype)) newProduct.sepproducttype = product.sepproducttype;
  if (validateOwnership(options.ownership)) newProduct.sepownership = options.ownership;
  else if (validateOwnership(product.sepownership)) newProduct.sepownership = product.sepownership;
  else newProduct.sepownership = "Unknown";
  if (validateWasteType(product.sepproductwaste)) newProduct.sepproductwaste = product.sepproductwaste;
  if (typeof product.sepudi === "string" && product.sepudi !== "") newProduct.sepudi = product.sepudi;
  if (typeof product.sepesudi === "string" && product.sepesudi !== "") newProduct.sepesudi = product.sepesudi;
  newProduct.sepproductside = validateSide(product.sepproductside) ? product.sepproductside : "NotApplicable";
  if (validateUOM(product.sepuom)) newProduct.sepuom = product.sepuom;
  else newProduct.sepuom = "EA";
  newProduct.units = getArray(product.units, validatePackageString, getPackageString);
  if (options.sepproductesprice && newProduct.units.length) {
    const tempuom = newProduct.sepuom ?? "EA";
    const tempunit = getQuantityOnOrder({ iproduct: { ...newProduct, sepuom: tempuom } }, { isobject: true });
    if (tempunit) newProduct.sepproductesprice = tempunit.price;
  }
  else if ((typeof product.sepproductesprice === "string" && product.sepproductesprice !== "")
    || (typeof product.sepproductesprice === "number")) newProduct.sepproductesprice = product.sepproductesprice;
  if (typeof product.seplocation === "string" && product.seplocation !== "") newProduct.seplocation = product.seplocation;
  newProduct.cptcode = getArray(product.cptcode, validateSurgicalEventProcedure, getSurgicalEventProcedure);

  // if ((typeof product.iqoh === "number" ||  typeof product.iqoh === "string") && product.iqoh !== "") newProduct.iqoh = product.iqoh || 1;
  if (typeof product.ilocation === "string" && product.ilocation !== "") newProduct.ilocation = product.ilocation;
  if (typeof product.ibin === "string" && product.ibin !== "") newProduct.ibin = product.ibin;
  if ((typeof product.idistributornumber === "number" || typeof product.idistributornumber === "string") && product.idistributornumber !== "") newProduct.idistributornumber = product.idistributornumber;

  if (typeof product.sepcontractnumber === "string" && product.sepcontractnumber !== "") newProduct.sepcontractnumber = product.sepcontractnumber;

  return newProduct;
};


export const isEmptyProduct = (product) => {
  return (JSON.stringify(getProduct(product)) === JSON.stringify(getProduct({ ...SurgicalEventProductModel })));
};

export const compareProduct = (product1, product2, isAll = false) => {
  if (!product1 && !product2) return true;
  if (!product1) return false;
  if (!product2) return false;
  return ((!isAll || product1.id === product2.id)
    && product1.sepreferencenumber === product2.sepreferencenumber
    && product1.sepdistributor === product2.sepdistributor
    && product1.sepproductmanufacturer === product2.sepproductmanufacturer
    && (!isAll || product1.sepproductname === product2.sepproductname)
    && (!isAll || product1.sepproductdescription === product2.sepproductdescription)
    && ((!product1.sepproductexpiration && !product2.sepproductexpiration)
      || product1.sepproductexpiration === product2.sepproductexpiration)
    && (product1.sepproductprice === product2.sepproductprice
      || parseFloat(product1.sepproductprice) === parseFloat(product2.sepproductprice))
    && (!isAll || product1.sepproductesprice === product2.sepproductesprice)
    && ((!product1.seplotcode && !product2.seplotcode)
      || product1.seplotcode === product2.seplotcode)
    && product1.sepproducttype === product2.sepproducttype
    && product1.sepproductwaste === product2.sepproductwaste
    && (!isAll || product1.sepownership === product2.sepownership)
    && product1.sepudi === product2.sepudi
    && (!isAll || product1.sepesudi === product2.sepesudi)
    && product1.sepproductside === product2.sepproductside
    && (!isAll || product1.sepuom === product2.sepuom)
    && (!isAll || product1.seplocation === product2.seplocation)
    // && (!isAll || product1.unclassified === product2.unclassified)
    && (!isAll || product1.sepcontractnumber === product2.sepcontractnumber)
  );
};

/**
 * compare two products and get difference value(delta)
 * @param {Object} product1 
 * @param {Object} product2 
 * @returns {Number}
 */
export const comapreProductsDelta = (product1, product2) => {
  let delta = 0;
  const deltaref = product1.sepreferencenumber === product2.sepreferencenumber ? 0 : 1000;
  const deltadis = product1.sepdistributor === product2.sepdistributor ? 0 : 500;
  const deltaown = product1.sepownership === product2.sepownership ? 0 : 200;
  const deltalot = product1.seplotcode === product2.seplotcode ? 0 : 50;
  const deltaexp = product1.sepproductexpiration === product2.sepproductexpiration ? 0 : 10;
  delta = (deltaref + deltaown + deltadis + deltalot + deltaexp);
  // console.log("[comapreProductsDelta]", product1, product2, delta);
  return delta;
};

/**
 * require Product
 * @param {Object} product 
 * @param {Object} options extraReqs, introReqs 
 * @returns 
 */
export const requiredProduct = (product, options = {}) => {
  options = {
    extraReqs: {},  // Add extra requirement params
    introReqs: [],  // Remove intro requirement params
    ...options,
  };
  const defaultReq = {
    'sepdistributor': 'Distributor',
    // 'sepproductmanufacturer': 'Product manufacturer', 
    // 'sepproductexpiration':'Product expiration',
    'sepproducttype': 'Product Type',
    'sepownership': 'Product Ownership',
    'sepproductside': 'Product Side',
    // 'seplotcode': 'Lot Code', 
    'sepreferencenumber': 'Reference Number',
    // 'sepproductname': '', 
    // 'sepproductdescription': '', 
    // 'sepudi': '',
    'sepproductprice': 'Product price',
  };
  const reqFields = options.introReqs.reduce((s, t) => {
    if (t in s) delete s[t];
    return s;
  }, defaultReq);
  return requiredFields(product, { ...reqFields, ...options.extraReqs });
}

export const combineProduct = (product1, product2) => {
  if (!product2) return getProduct({ ...product1 });
  let product = {};
  Object.keys({ ...SurgicalEventProductModel }).forEach(key => {
    if (key in product1) product[key] = product1[key];
    if (key in product2 && product2[key] !== undefined && product2[key] !== null) product[key] = product2[key];
  });
  return getProduct(product);
};

export const getTotalPrice = (products = []) => {
  return products.reduce((total, obj) => parseFloat(obj.sepproductprice) + total, 0);
};
export const getInvenKey = (product) => (
  `(25)${product.sepreferencenumber}(35)${product.sepownership}`
);


export const ProductTypes = {
  Implant: 'Implant',
  Disposable: 'Disposable',
  TissueBiologic: 'Tissue/Biologic',
};
export const validateProductType = validateModelItem(ProductTypes);
export const getProductTypes = getModelMenu(ProductTypes);

// ProductTypes for Inventory
const ProductTypesInventory = { ...ProductTypes };
export const getProductTypesInventory = getModelMenu(ProductTypesInventory);

// ProductTypes for SurgicalEvent (disable now)
/* const ProductTypesSurgicalEvent = {...ProductTypes};
ProductTypesSurgicalEvent['Implant'] = 'Implanted';
export const getProductTypesSurgicalEvent = getModelMenu(ProductTypesSurgicalEvent); */


export const Sides = {
  Left: 'Left',
  Right: 'Right',
  NotApplicable: 'Not Applicable',
};
export const validateSide = validateModelItem(Sides);
export const getSides = getModelMenu(Sides);

export const WasteTypes = {
  Waste: "Wasted",
  ImplantRemoved: "Implanted and Removed",
};
export const validateWasteType = validateModelItem(WasteTypes);
export const getWasteTypes = getModelMenu(WasteTypes);


export const UOM = {
  CS: 'Case',
  BX: 'Box',
  EA: 'Each',
  PK: 'Package',
  BG: 'Bag',
  KT: 'Kit',
  CN: 'Can',
  SL: 'Spool',
  RL: 'Reel',
  SP: 'Strip',
  GL: 'Gallon',
  TB: 'TB',
  ST: 'ST',
  VI: 'Vial',
  TH: 'TH',
  TR: 'TR',
  PR: 'Pair',
  DZ: 'Dozen'
};

export const validateUOM = validateModelItem(UOM);

export const UnitPriceQuantity = {
  uom: '',
  price: '',
  quantity: '',
};
export const validateUnitPriceQuantity = (unit) => {
  return (unit &&
    (unit.uom !== "" && validateUOM(unit.uom))
  );
};
export const getUnitPriceQuantity = (unit) => {
  let newUnit = {};
  newUnit.uom = unit.uom || "Each";
  if ((typeof unit.price === "string" && unit.price !== "") || (typeof unit.price === "number")) newUnit.price = unit.price;
  if ((typeof unit.quantity === "string" && unit.quantity !== "") || (typeof unit.quantity === "number")) newUnit.quantity = unit.quantity;
  return newUnit;
};

export const getListOfUOM = (packageString) => {
  // console.log('packageString', packageString, UOM);
  if (packageString) {
    const baseUoms = {};
    for (const pkg of packageString) {
      baseUoms[pkg.baseuom] = UOM[pkg.baseuom]; //.toUpperCase()
      if (pkg.packageuom in UOM) {
        baseUoms[pkg.packageuom] = UOM[pkg.packageuom]; //.toUpperCase()
      }
    }
    // console.log('baseUoms', baseUoms);
    return baseUoms;
  }
}

export const PackageString = {
  packageuom: '',
  price: '',
  quantity: '',
  baseuom: '',
  multiplier: '',
};
export const validatePackageString = (unit) => {
  return (unit &&
    (unit.packageuom !== "" && validateUOM(unit.packageuom))
  );
};
export const getPackageString = (unit) => {
  let newUnit = {};
  newUnit.packageuom = unit.packageuom || "EA";
  if ((typeof unit.price === "string" && unit.price !== "") || (typeof unit.price === "number")) newUnit.price = unit.price;
  if ((typeof unit.quantity === "string" && unit.quantity !== "") || (typeof unit.quantity === "number")) newUnit.quantity = unit.quantity;
  if (typeof unit.baseuom === "string" && unit.baseuom !== "") newUnit.baseuom = unit.baseuom;
  if ((typeof unit.multiplier === "string" && unit.multiplier !== "") || (typeof unit.multiplier === "number")) newUnit.multiplier = unit.multiplier;
  return newUnit;
};
