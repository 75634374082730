import React, { useState } from "react";
import { Typography, Grid } from '@material-ui/core';
import ModalDialog from '../../components/Modals/NewGeneralModal';
import patientIcon from "../../icons/new-se-patient-icon.svg";
import SurgicalEventDetails from "./surgicalevent-details";
import useStyles from "./styles";


/**
 * Surgical Event Title Component
 * @param {Object} classes 
 * @param {Number} tab 
 * @param {Object} seData
 * @returns 
 */
const SETitle = ({
    classes, seData
}) => {


    return (
    <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.newSETitlePanel}
    >
        <img src={patientIcon} alt="" />
        <Typography variant="inherit" component="span" className="name" >
            {seData.value.seData?.surgicaleventdata.patientfirstname} {seData.value.seData?.surgicaleventdata.patientlastname}
        </Typography>
        <Typography variant="inherit" component="span" className="label" >
            SE ID:
        </Typography>
        <Typography variant="inherit" component="span" className="value" >
            {seData.value.seData?.surgicaleventdata.seid}
        </Typography>
    </Grid>
    );
};


/**
 * SurgicalEvent Dialog
 * @param {Boolean} open
 * @param {Function} setOpen
 * @param {Object} contract
 * @param {Boolean} isEdit
 */
export default function AuditDetailSE({
    open, setOpen, contract = {},
}) {
    const classes = useStyles();



    const [tab] = useState(0);
    const seData = contract.payload?.logEvents;
    return (
        <>
            <ModalDialog
                open={open}
                setOpen={setOpen}
                title={(
                    <SETitle
                        classes={classes}
                        tab={tab}
                        seData={seData}
                    />
                )}
                content={(
                    <SurgicalEventDetails
                        seData={seData}
                    />
                )}
            />
        </>
    );
}
