import * as React from "react";
import { Grid } from "@mui/material";

import DashboardDataCards from "../../pages/dashboard/dashboardDataCards";

const xsItem = 12;
const smItem = 7.5;
const mdItem = 3.75;
const xlItem = 3;

export const DashboardCardsAccess = (props) => {
  const contracts = props?.contracts;
  const role = props?.role;
  return (
    <>
      {" "}
      <Grid
        container
        mt={4}
        spacing={2}
        columns={15}
        padding={"1vh"}
        sx={{ marginTop: "0px" }}
      >
        {!props.isVendor ? (<>
        {role !== "HInventoryManager" && role !== "HFinanceAssociate" && (
          <Grid item xs={xsItem} sm={smItem} md={mdItem} xl={xlItem}>
            <DashboardDataCards
              vendorNotAccepted={contracts?.vendor_not_accepted}
              unsentPo={contracts?.unsent_purchase_order}
              unsentPOAmount={contracts?.unsent_purchase_order_amount}
              posDeclined={contracts?.pos_declined}
              role={role}
              title="Purchase Orders"
              newPurchaseOrder={contracts?.new_purchase_order}
              newPurchaseOrderAmount={contracts?.new_purchase_order_amount}
            />
          </Grid>
        )}
        {role !== "HInventoryManager" && role !== "HFinanceAssociate" && (
          <Grid item xs={xsItem} sm={smItem} md={mdItem} xl={xlItem}>
            <DashboardDataCards
              title="Inventory Status"
              pendingShipmentArrival={contracts?.pending_shipment_arrival}
              belowParLevel={contracts?.below_par_level}
              outOfStock={contracts?.out_of_stock}
              backOrdered={contracts?.back_ordered}
              role={role}
            />
          </Grid>
        )}
        {role !== "HInventoryManager" && (
          <>
            {" "}
            <Grid item xs={xsItem} sm={smItem} md={mdItem} xl={xlItem}>
              <DashboardDataCards
                title="Inventory Value"
                totalInventory={contracts?.total_inventory}
                consigned={contracts?.consigned}
                owned={contracts?.owned}
                stock={contracts?.stock}
                role={role}
              />
            </Grid>
            <Grid item xs={xsItem} sm={smItem} md={mdItem} xl={xlItem}>
              <DashboardDataCards
                title="Product Utilization"
                productsUsed1day={contracts?.products_used_in_1_day}
                productsUsed7day={contracts?.product_used_in_7_days}
                productsUsed30day={contracts?.product_used_in_30_days}
                productsUsedLastYear={contracts?.products_used_in_last_year}
                productsUsed1DayAmount={
                  contracts?.products_used_in_1_day_amount
                }
                productsUsed7DaysAmount={
                  contracts?.products_used_in_7_days_amount
                }
                productsUsed30DaysAmount={
                  contracts?.products_used_in_30_days_amount
                }
                productsUsed180DaysAmount={
                  contracts?.products_used_in_180_days_amount
                }
                productsUsedlastYearAmount={
                  contracts?.products_used_in_last_year_amount
                }
                role={role}
              />
            </Grid>
          </>
        )}
        {role !== "HInventoryManager" && role !== "HFinanceAssociate" && (
          <Grid item xs={xsItem} sm={smItem} md={mdItem} xl={xlItem}>
            <DashboardDataCards
              title="Invoices not Received"
              sevenDays={contracts?.invoices_7_days}
              thirtyDays={contracts?.invoices_30_days}
              oneEightyDays={contracts?.invoices_180_days}
              year={contracts?.invoices_1_year}
              lastSentInvoice={contracts?.last_sent_invoice}
              role={role}
            />
          </Grid>
        )}
        {role !== "HInventoryManager" && (
          <Grid item xs={xsItem} sm={smItem} md={mdItem} xl={xlItem}>
            <DashboardDataCards
              title="Expiring and Expired Products"
              expired={contracts?.expired_products}
              expiring={contracts?.expiring_products}
              expiredAmount={contracts?.expired_products_amount}
              expiringAmount={contracts?.expiring_products_amount}
              role={role}
            />
          </Grid>
        )}
        {role !== "HInventoryManager" && role !== "HFinanceAssociate" && (
          <Grid item xs={xsItem} sm={smItem} md={mdItem} xl={xlItem}>
            <DashboardDataCards
              title="Recalls and Returns"
              recall={contracts?.in_recall}
              returnsAwaiting={contracts?.returns_awaiting_approval}
              role={role}
            />
          </Grid>
        )}
        {role !== "HInventoryManager" && (
          <Grid item xs={xsItem} sm={smItem} md={mdItem} xl={xlItem}>
            <DashboardDataCards
              title="Products used off Contracts"
              usedOffsevenDays={contracts?.products_used_off_contracts_7_days}
              usedOffthirtyDays={contracts?.products_used_off_contracts_30_days}
              usedOffoneEightyDays={
                contracts?.products_used_off_contracts_180_days
              }
              usedOffyear={contracts?.products_used_off_contracts_last_year}
              usedOffsevenDaysAmount={
                contracts?.products_used_off_contracts_7_days_amount
              }
              usedOffthirtyDaysAmount={
                contracts?.products_used_off_contracts_30_days_amount
              }
              usedOffoneEightyDaysAmount={
                contracts?.products_used_off_contracts_180_days_amount
              }
              usedOffyearAmount={
                contracts?.products_used_off_contracts_last_year_amount
              }
              role={role}
            />
          </Grid>
        )}
        <Grid item xs={xsItem} sm={smItem} md={mdItem} xl={xlItem}>
          <DashboardDataCards
            title="Compliance"
            productsOutOfCompliance={contracts?.products_out_of_compliance}
            productsOutOfComplianceAmount={
              contracts?.products_out_of_compliance_amount
            }
            role={role}
          />
        </Grid>
        {role === "HInventoryManager" && (
          <Grid item xs={xsItem} sm={smItem} md={mdItem} xl={xlItem}>
            <DashboardDataCards
              title="Surgical Events"
              scheduledToday={contracts?.se_scheduled_today}
              completed={contracts?.se_completed}
              role={role}
            />
          </Grid>
        )}
        {/*  **************Do Not Remove below code [TYD-1668]***********************
        This card is not currently in a scope. It will be in 2.0.0 release when Contract Management Screen is implemented.
        {role === "HFinanceAssociate" && (
          <Grid item xs={xsItem} sm={smItem} md={mdItem} xl={xlItem}>
            <DashboardDataCards
              title="Expiring and Expired Contracts"
              expired={contracts?.expired_contracts}
              expiring={contracts?.expiring_contracts}
              expiredAmount={contracts?.expired_contracts_amount}
              expiringAmount={contracts?.expiring_contracts_amount}
            />
          </Grid>
        )} */}
        </>) : (<>
          <Grid item xs={xsItem} sm={smItem} md={mdItem} xl={xlItem}>
            <DashboardDataCards
              vendorNotAccepted={contracts?.vendor_not_accepted}
              unsentPo={contracts?.unsent_purchase_order}
              unsentPOAmount={contracts?.unsent_purchase_order_amount}
              posDeclined={contracts?.pos_declined}
              role={role}
              title="Purchase Orders"
              newPurchaseOrder={contracts?.new_purchase_order}
              newPurchaseOrderAmount={contracts?.new_purchase_order_amount}
            />
          </Grid>
          <Grid item xs={xsItem} sm={smItem} md={mdItem} xl={xlItem}>
            <DashboardDataCards
              title="Inventory Adjustments"
              hospitalAdjustedCount={contracts?.hospital_adjusted}
              returnRequringApprovalCount={contracts?.product_returns_requiring_approval}
              hospitalAdjustedCountAmount={contracts?.hospital_adjusted_amount}
              returnRequringApprovalAmount={contracts?.product_returns_requiring_approval_amount}
              role={role}
            />
          </Grid>
          <Grid item xs={xsItem} sm={smItem} md={mdItem} xl={xlItem}>
            <DashboardDataCards
              title="Expiring and Expired Products"
              expired={contracts?.expired_products}
              expiring={contracts?.expiring_products}
              expiredAmount={contracts?.expired_products_amount}
              expiringAmount={contracts?.expiring_products_amount}
              role={role}
            />
          </Grid>
          <Grid item xs={xsItem} sm={smItem} md={mdItem} xl={xlItem}>
            <DashboardDataCards
              title="Shipping"
              readyForShipmentCount={contracts?.product_ready_for_shipment}
              backOrderCount={contracts?.back_ordered}
              readyForShipmentAmount={contracts?.product_ready_for_shipment_amount}
              backOrderAmount={contracts?.back_ordered_amount}
              role={role}
            />
          </Grid>
        </>)}
      </Grid>
    </>
  );
};
