import { VendorRole, HospitalRole } from "../../services/daml-modules1";
import { dispatch, getState } from "../../store";
import { purchaseOrderAdd } from "../../store/actions/generalActions";
import { getTodayString, getTemplateListFromResponse } from "../../services/util";
import { createHashID } from "../../services/crypto-util";
import { loggerPush, LOGGER_INFO, LOGGER_ERROR } from '../../services/logger';
import { getPOComment, validatePOComment, validatePOStatus } from "../../models/POStatus";
import { getPurData, validatePurData } from "../../models/Po";
import { getPODetail, validatePODetail, comparePODetail } from "../../models/PODetail";
import { getArray } from "../../models/handles";
import { validateOwnership } from "../../models/Ownership";


export const getLastPurchaseOrderID = (isAdd = false) => {
  const last_id = getState().purchase.last_id;
  if (isAdd) {
    dispatch(purchaseOrderAdd());
    // console.log("[getLastPurchaseOrderID] last id is " + (last_id + 1));
    return "" + (last_id + 1);
  }
  else {
    // console.log("[getLastPurchaseOrderID] last id is " + last_id);
  }
  return "" + last_id;
}

export const getPurchaseOrderName = (vendor) => {
  const today_id = getState().purchase.today_id;
  // console.log("[getPurchaseOrderName] today index is " + today_id);
  return "PO-" + vendor + "-" + getTodayString() + "-" + today_id;
};

/**
 * create invoice
 * exercise VPOVSetInvoice choice of VendorRole
 * @param {String} contractId 
 * @param {Object} payload 
 * @param {Object} ledger 
 * @param {Function} setInvoiceNumber 
 * @param {Function} setHospital 
 * @param {Function} setConfirmModalOpen 
 * @param {String} roleCid
 * @param {String} userlistPayload
 * @returns {Boolean}
 */
export const createInvoice = async ({
  contractId, payload, ledger, setInvoiceNumber, setHospital, setConfirmModalOpen, roleCid, userlistPayload
}) => {
  const email = userlistPayload?.email;
  const username = userlistPayload?.name;
  try {

    let res = await ledger.exercise(VendorRole.VPOVSetInvoice, roleCid, {
      povcid: contractId,
      hospital:payload.hospital,
      useremail: email,
      username: username,
    });

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'PO: VPOVSetInvoice', response: res });

    console.log("[createInvoice]", res);

    // get contractId of the created Invoice
    let templateInv = getTemplateListFromResponse(res, "Invoice");
    let createdInvoiceList = getTemplateListFromResponse(res, "Invoice");
    if (templateInv.length) {
      if (typeof setInvoiceNumber === 'function') setInvoiceNumber(createdInvoiceList[0].invoicedata.invinvoicenumber);
      if (typeof setHospital === 'function') setHospital(createdInvoiceList[0].hospital);
      if (typeof setConfirmModalOpen === 'function') setConfirmModalOpen(true);
      return true;
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'PO: VPOVSetInvoice', payload: e, params: payload });
    console.log("[createInvoice]", e);
  }
  return false;

};

/**
 * send PO to Hospital with comment
 * exercise VPOVSend choice of VendorRole
 * @param {Object} data 
 * @param {Object} ledger 
 * @param {String} ContractId 
 * @param {String} roleCid
 * @returns {Boolean}
 */
export const handlePOStatus = async ({
  ledger, ContractId, pocomment, postatus, poHospital,roleCid,email,username
}) => {
  console.log("[handlePOStatus]", ContractId);

  try {
    let res = await ledger.exercise(VendorRole.VPOVSendWithLog, roleCid, {
      povcid: ContractId,
      newpostatus: postatus,
      newpocomment: getPOComment(pocomment),
      hospital: poHospital,
      useremail: email,
      username: username,
    });
    console.log("[handlePOStatus]", res);

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'PO: VPOVSend', response: res });

    let templatePO = getTemplateListFromResponse(res, "PurchaseOrderVendor");
    return (templatePO.length > 0 ? templatePO[0] : null);
  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'PO: VPOVSend', payload: e, params: {povcid: ContractId, postatus, pocomment} });
    console.warn("[handlePOStatus]", e);
  }

  return false;
};

/**
 * send PO to Vendor with comment
 * exercise HPOSend choice of HospitalRole
 * @param {Object} pocomment 
 * @param {Object} ledger 
 * @param {String} ContractId 
 * @param {String} roleCid
 * @param {String} email
 * @param {String} username
 * @returns {Boolean}
 */
export const SendPov = async ({
  pocomment, ledger, ContractId, roleCid, email, username
}) => {
  console.log("[SendPov]", ContractId);

  console.log("start Send PO to Vendor");

  try {

    if (roleCid && validatePOComment(pocomment)) {
      // use HPOSendWithLog choice in the next version
      const choice = HospitalRole.HPOSendWithLog; // Old case : HospitalRole.HPOSend
      let res = await ledger.exercise(choice, roleCid, {
        pocid: ContractId,
        newpocomment: getPOComment(pocomment),
        useremail: email,
        username: username,
      });
      console.log("[SendPov]", res);

      // log the response to loggly
      loggerPush({ type: LOGGER_INFO, action: 'PO: HPOSend', response: res });

      let templatePO = getTemplateListFromResponse(res, "PurchaseOrder");
      return (templatePO.length > 0 ? templatePO[0] : null);
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'PO: HPOSend', payload: e, params: pocomment });
    console.warn("[SendPov]", e);
  }

  return false;
};

/**
 * create purchase order
 * exercise CreatePurchaseOrder choice of HospitalRole
 * When creating PO, SE & AccountInventory should be updated
 * @param {Object} ledger
 * @param {String} hospital
 * @param {Object} purdata
 * @param {Function} setPurchaseOrderdata
 * @param {Array} purdetail
 * @param {Function} setPurchaseOrderDetailData
 * @param {Function} setContractId
 * @param {String} roleCid
 * @param {String} user 
 * @param {String} role 
 * @returns {Boolean}
 */
export const createpurchaseorder = async ({
  ledger, hospital, purdata, setPurchaseOrderdata, purdetail, setPurchaseOrderDetailData, setContractId, roleCid,
  user, role, email,username,
}) => {

  //console.log("returned contract id from usequery" + contract);
  console.log("[createpurchaseorder] hospital: ", hospital, "purchaseorder data:", purdata);

  try {

    if (!roleCid) {
      console.log("query Result has no contracts.");
      return;
    }

    const purchaseorderdetaildata = getArray(purdetail, validatePODetail, (d) => getPODetail(d, {sepproductesprice: true}));

    const newpurchaseorderParams = getPurData(purdata);
    if (!validatePOStatus(purdata.postatus)) newpurchaseorderParams.postatus = "Initiated";
    const vendor = newpurchaseorderParams.povendorid;

    console.log("[createpurchaseorder] vendor: ", vendor, "ContractId: ", roleCid,
      "purchaseorder:", JSON.stringify(newpurchaseorderParams),
      "purchaseorderdetail:", JSON.stringify(purchaseorderdetaildata));

    if (!validatePurData(purdata)) return;

    if (typeof setPurchaseOrderdata === 'function') setPurchaseOrderdata(purdata);

    console.log("start Ledger Create");

    // use CreatePurchaseOrderWithLog choice in the next version
    const choice = HospitalRole.CreatePurchaseOrderWithLog; // Old case : HospitalRole.CreatePurchaseOrder
    let res = await ledger.exercise(choice, roleCid, {
      vendor,
      purchaseorderdata: newpurchaseorderParams,
      purchaseorderdetaildata,
      useremail: email,
      username: username,
    });

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'PO: CreatePurchaseOrder', response: res });

    // get contractId of the created PurchaseOrder
    let templatePO = getTemplateListFromResponse(res, "PurchaseOrder");
    // Tried to create a Stock PO
    if (templatePO.length === 0
      && newpurchaseorderParams.popurchaseordertype === "Stock"
      && !!newpurchaseorderParams.poseid
      && purchaseorderdetaildata.length > 0) {
      // create virtual ids of details
      templatePO = [{
        purchaseorderdata: newpurchaseorderParams,
        purchaseorderdetaildata: purchaseorderdetaildata.map((d, i) => ({ ...d, id: createHashID({ index: i }) }))
      }];
      console.log("[createpurchaseorder] templatePO: ", templatePO);
    }
    if (templatePO.length) {
      if (typeof setContractId === 'function') setContractId(templatePO[0].contractId);

      await comparePOsAndUpdateSE({
        ledger,
        newpos: templatePO,
        oldpo: null,
        user,
        role,
        roleCid,
      });

      return templatePO[0];
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'PO: CreatePurchaseOrder', payload: e, params: purdata });
    console.warn("[createpurchaseorder]", e);
  }

  return false;
};

/**
 * Archive POV
 * exercise VPOVArchive choice of VendorRole
 * @param {String} cid 
 * @param {Object} payload 
 * @param {Object} ledger 
 * @param {String} roleCid
 */
export const ArchivePoV = async ({
  cid = ' ', payload = ' ', ledger, roleCid,
}) => {

  console.log("start Ledger Archive");

  try {

    let res = await ledger.exercise(VendorRole.VPOVArchive, roleCid, {
      povcid: cid,
    });

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'PO: VPOVArchive', response: res });

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'PO: VPOVArchive', payload: e, params: cid });
    console.warn("[ArchivePoV]", e);
  }

};


/**
 * add PurchaseOrderDetaildata list into PurchaseOrder
 * exercise HPOAddDetails choice of HospitalRole
 * When adding PO detail, SE & AccountInventory should be updated
 * @param {Object} ledger 
 * @param {String} contractId 
 * @param {Function} setContractId 
 * @param {Array} newpodetaildatas 
 * @param {String} roleCid
 * @param {String} user 
 * @param {String} role 
 * @param {Object} payload 
 * @returns {Object}
 */
export const addPODetailDatas = async ({
  ledger, contractId, setContractId, newpodetaildatas, roleCid, user, role, payload,
}) => {
  console.log("[addPODetailDatas]", contractId, roleCid);

  try {
    const podetaildatas = getArray(newpodetaildatas, validatePODetail, (d) => getPODetail(d, {sepproductesprice: true}));
    console.log("[addPODetailDatas]", podetaildatas);

    if (roleCid && podetaildatas.length) {
      let res = await ledger.exercise(HospitalRole.HPOAddDetails, roleCid, {
        pocid: contractId,
        newpodetaildatas: podetaildatas,
      });

      // log the response to loggly
      loggerPush({ type: LOGGER_INFO, action: 'PO: HPOAddDetails', response: res });

      // get contractId of the created PurchaseOrder
      let templatePO = getTemplateListFromResponse(res, "PurchaseOrder");
      if (templatePO.length) {
        if (typeof setContractId === 'function') setContractId(templatePO[0].contractId);

        await comparePOsAndUpdateSE({
          ledger,
          newpos: templatePO,
          oldpo: payload,
          user,
          role,
          roleCid,
        });

        return templatePO[0];
      }
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'PO: HPOAddDetails', payload: e, params: newpodetaildatas });
    console.warn("[addPODetailDatas]", e);
  }

  return contractId;
};

/**
 * Archive PO
 * exercise HPOArchive choice of HospitalRole
 * When removing PO, SE & AccountInventory should be updated
 * @param {String} cid 
 * @param {Object} payload 
 * @param {Object} ledger 
 * @param {String} roleCid
 * @param {String} user 
 * @param {String} role 
 * @returns {Boolean}
 */
export const ArchivePo = async ({
  cid = ' ', payload = ' ', ledger, roleCid, user, role,
}) => {

  try {

    console.log("start Ledger Archive");

    let res = await ledger.exercise(HospitalRole.HPOArchive, roleCid, {
      pocid: cid,
    });

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'PO: HPOArchive', response: res });

    await comparePOsAndUpdateSE({
      ledger,
      newpos: null,
      oldpo: payload,
      user,
      role,
      roleCid,
      options: {
        isRemove: true,
      }
    });

    return true;
  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'PO: HPOArchive', payload: e, params: cid });
    console.warn("[ArchivePo]", e);
  }

  return false;
};

/**
 * update PurchaseOrderdata
 * exercise HPOUpdate choice of HospitalRole
 * When updating PO, SE & AccountInventory should be updated
 * Not used now
 * @param {Object} ledger 
 * @param {String} contractId 
 * @param {Function} setContractId 
 * @param {Object} podata 
 * @param {String} roleCid
 * @param {String} user 
 * @param {String} role 
 * @param {Object} payload 
 * @returns {Boolean}
 */
export const updatePOData = async ({
  ledger, contractId, setContractId, podata, roleCid, user, role, payload,
}) => {
  console.log("[updatePOData]", contractId, podata);

  try {

    if (roleCid && validatePurData(podata)) {
      let res = await ledger.exercise(HospitalRole.HPOUpdate, roleCid, {
        pocid: contractId,
        updatepodata: getPurData(podata),
      });

      // log the response to loggly
      loggerPush({ type: LOGGER_INFO, action: 'PO: HPOUpdate', response: res });

      // get contractId of the created PurchaseOrder
      let templatePO = getTemplateListFromResponse(res, "PurchaseOrder");
      if (templatePO.length) {
        if (typeof setContractId === 'function') setContractId(templatePO[0].contractId);

        await comparePOsAndUpdateSE({
          ledger,
          newpos: templatePO,
          oldpo: payload,
          user,
          role,
          roleCid,
        });

        return templatePO[0];
      }

      return undefined;
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'PO: HPOUpdate', payload: e, params: podata });
    console.log("[updatePOData]", e);
  }

  return undefined;
};

/**
 * remove PO Detail Data in PO
 * exercise HPORemoveDetail choice of HospitalRole
 * When removing PO detail, SE & AccountInventory should be updated
 * @param {Object} ledger 
 * @param {String} contractId 
 * @param {Function} setContractId 
 * @param {Object} podetail 
 * @param {Number} podetailindex
 * @param {String} roleCid
 * @param {String} user 
 * @param {String} role 
 * @param {Object} payload 
 * @returns {Boolean}
 */
export const removePODetailData = async ({
  ledger, contractId, setContractId, podetail, podetailindex, roleCid, user, role, payload,
}) => {

  try {

    if (roleCid) {
      let res = await ledger.exercise(HospitalRole.HPORemoveDetail, roleCid, {
        pocid: contractId,
        removepodetaildata: getPODetail(podetail),
        removepodetailindex: podetailindex,
      });

      // log the response to loggly
      loggerPush({ type: LOGGER_INFO, action: 'PO: HPORemoveDetail', response: res });

      // get contractId of the created PurchaseOrder
      let templatePO = getTemplateListFromResponse(res, "PurchaseOrder");
      if (templatePO.length) {
        if (typeof setContractId === 'function') setContractId(templatePO[0].contractId);

        await comparePOsAndUpdateSE({
          ledger,
          newpos: templatePO,
          oldpo: payload,
          user,
          role,
          roleCid,
          options: {
            isRemove: true,
          }
        });

        return templatePO[0];
      }
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'PO: HPORemoveDetail', payload: e, params: podetail });
    console.warn("[removePODetailData]", e);
  }

  return false;
};

/**
 * update PO Detail Data in PO
 * exercise HPOUpdateDetail choice of HospitalRole
 * When updating PO detail, SE & AccountInventory should be updated
 * @param {Object} ledger 
 * @param {String} contractId 
 * @param {Function} setContractId 
 * @param {Object} podetail 
 * @param {Number} podetailindex
 * @param {String} roleCid
 * @param {String} user 
 * @param {String} role 
 * @param {Object} payload 
 * @returns {Boolean}
 */
export const updatePODetailData = async ({
  ledger, contractId, setContractId, podetail, podetailindex, roleCid, user, role, payload,
}) => {

  try {

    const updatepodetaildata = getPODetail(podetail, {sepproductesprice: true});

    let res = await ledger.exercise(HospitalRole.HPOUpdateDetail, roleCid, {
      pocid: contractId,
      updatepodetaildata,
      updatepodetailindex: podetailindex,
    });

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'PO: HPOUpdateDetail', response: res });

    // get contractId of the created PurchaseOrder
    let templatePO = getTemplateListFromResponse(res, "PurchaseOrder");
    if (templatePO.length) {
      if (typeof setContractId === 'function') setContractId(templatePO[0].contractId);

      await comparePOsAndUpdateSE({
        ledger,
        newpos: templatePO,
        oldpo: payload,
        user,
        role,
        roleCid,
      });

      return templatePO[0];
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'PO: HPOUpdateDetail', payload: e, params: podetail });
    console.warn("[updatePODetailData]", e);
  }

  return false;
};

/**
 * change Ownership of PO
 * exercise HPOChangeOwnership choice of HospitalRole
 * When changing Ownership of PO, SE & AccountInventory should be updated
 * @param {String} cid 
 * @param {Object} payload 
 * @param {Object} ledger 
 * @param {String} newownership
 * @param {String} targetponumber Target Purchase Order Number
 * @param {String} roleCid
 * @param {String} user 
 * @param {String} role 
 * @returns {Boolean}
 */
export const changeOwnershipPO = async ({
  cid = ' ', payload = ' ', ledger, newownership, targetponumber, roleCid, user, role,
}) => {

  try {

    console.log("start Ledger Archive");

    if (roleCid && validateOwnership(newownership)) {

      let res = await ledger.exercise(HospitalRole.HPOChangeOwnership, roleCid, {
        pocid: cid,
        newownership,
        targetponumber, // old : targetpoid,
      });

      // log the response to loggly
      loggerPush({ type: LOGGER_INFO, action: 'PO: HPOChangeOwnership', response: res });

      let templatePO = getTemplateListFromResponse(res, "PurchaseOrder");
      if (templatePO.length) {
        await comparePOsAndUpdateSE({
          ledger,
          newpos: templatePO,
          oldpo: payload,
          user,
          role,
          roleCid,
          options: {
            isCreate: false,
          }
        });

        return templatePO[0];
      }

    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'PO: HPOChangeOwnership', payload: e, params: {newownership, cid, targetponumber} });
    console.warn("[changeOwnershipPO]", e);
  }

  return false;
};

/**
 * Archive PO
 * exercise HPOChangeOwnershipDetail choice of HospitalRole
 * When changing Ownership of PO detail, SE & AccountInventory should be updated
 * @param {String} cid 
 * @param {Object} payload 
 * @param {Object} ledger 
 * @param {String} newownership
 * @param {String} targetponumber Target Purchase Order Number
 * @param {Object} oldpodetaildata
 * @param {Number} podetailindex
 * @param {String} roleCid
 * @param {String} poseid SE Id of the PO
 * @param {String} user 
 * @param {String} role 
 * @returns {Boolean}
 */
export const changeOwnershipDetail = async ({
  cid = ' ', payload = ' ', ledger, newownership, targetponumber, oldpodetaildata, podetailindex, roleCid,
  poseid, user, role,
}) => {

  try {

    console.log("start Ledger Archive");

    if (roleCid && validateOwnership(newownership) && validatePODetail(oldpodetaildata)) {
      let res = await ledger.exercise(HospitalRole.HPOChangeOwnershipDetail, roleCid, {
        pocid: cid,
        newownership,
        targetponumber, // old : targetpoid,
        oldpodetaildata: getPODetail(oldpodetaildata),
        oldpodetailindex: podetailindex,
        // poseid,
      });

      // log the response to loggly
      loggerPush({ type: LOGGER_INFO, action: 'PO: HPOChangeOwnershipDetail', response: res });

      let templatePO = getTemplateListFromResponse(res, "PurchaseOrder");
      if (templatePO.length) {
        await comparePOsAndUpdateSE({
          ledger,
          newpos: templatePO,
          oldpo: payload,
          user,
          role,
          roleCid,
          options: {
            isCreate: false,
          }
        });

        return templatePO[0];
      }
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'PO: HPOChangeOwnershipDetail', payload: e, params: {oldpodetaildata, newownership} });
    console.warn("[changeOwnershipDetail]", e);
  }

  return false;
};

/**
 * update SurgicalEvent by PurchaseOrder
 * exercise UpdateSEByPO choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} seid 
 * @param {Object} newpodetail 
 * @param {Object} oldpodetail 
 * @param {Number} oldpodetailindex 
 * @param {String} user 
 * @param {String} role 
 * @param {String} roleCid
 */
export const updateSEByPO = async ({
  ledger, seid, newpodetail, oldpodetail, oldpodetailindex, user, role, roleCid,
}) => {

  try {

    console.log("[updateSEByPO]", seid, newpodetail, oldpodetail, oldpodetailindex);

    let oldpodetaildata = null, newpodetaildata = null;
    if (oldpodetail && validatePODetail(oldpodetail)) oldpodetaildata = getPODetail(oldpodetail);
    if (newpodetail && validatePODetail(newpodetail)) newpodetaildata = getPODetail(newpodetail);
    // console.log("[updateSEByPO]", seid, newpodetail, oldpodetail, oldpodetailindex, user, role, roleCid);
    if (!oldpodetaildata && !newpodetaildata) return;

    if (roleCid && seid) {
      let res = await ledger.exercise(HospitalRole.UpdateSEByPO, roleCid, {
        seid,
        newpodetail: newpodetaildata,
        oldpodetail: oldpodetaildata,
        oldpodetailindex,
        user,
        role,
      });

      // log the response to loggly
      loggerPush({ type: LOGGER_INFO, action: 'PO: UpdateSEByPO', response: res });

      return true;
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'PO: UpdateSEByPO', payload: e, params: {newpodetail, oldpodetail, seid} });
    console.warn("[updateSEByPO]", e);
  }

  return false;
};

/**
 * compare two POs and find out the difference between them,
 * call updateSEByPO to update SE by PO
 * @param {Object} ledger 
 * @param {Array} newpos
 * @param {Object} oldpo
 * @param {String} user 
 * @param {String} role 
 * @param {String} roleCid
 * @param {Object} options
 */
export const comparePOsAndUpdateSE = async ({
  ledger, newpos, oldpo, user, role, roleCid, options = {},
}) => {

  options = {
    isRemove: false,
    isCreate: true,
    ...options,
  };

  try {
    console.log("[comparePOsAndUpdateSE]", newpos, oldpo);

    let newpodetails = [], oldpodetails = [], seid = null;

    const arraySet = (arr) => arr.reduce((s, d) => {
      if (!d.id) return s;
      const found = s.findIndex(dd => dd.id === d.id);
      if (found === -1) return [...s, d];
      s[found] = d;
      return s;
    }, []);

    const getQuantity = (quantity) => {
      if (isNaN(quantity)) return 1;
      quantity = parseInt(quantity);
      if (quantity < 1) return 1;
      return quantity;
    };

    if (oldpo && typeof oldpo === 'object') {
      if (oldpo.purchaseorderdata && typeof oldpo.purchaseorderdata === 'object') {
        const oldpodata = oldpo.purchaseorderdata;
        if (!!oldpodata.poseid) {
          seid = oldpodata.poseid;
          if (oldpo.purchaseorderdetaildata && typeof oldpo.purchaseorderdetaildata === 'object'
            && oldpo.purchaseorderdetaildata.length > 0) oldpodetails = oldpo.purchaseorderdetaildata;
        }
      }
    }
    oldpodetails = arraySet(oldpodetails);
    console.log("[comparePOsAndUpdateSE] oldpodetails: ", oldpodetails);
    if (newpos && typeof newpos === 'object' && newpos.length > 0) {
      newpos.forEach(newpo => {
        if (newpo.purchaseorderdata && typeof newpo.purchaseorderdata === 'object') {
          const newpodata = newpo.purchaseorderdata;
          if (!!newpodata.poseid) {
            if (!seid || seid === newpodata.poseid) {
              seid = newpodata.poseid;
              if (newpo.purchaseorderdetaildata && typeof newpo.purchaseorderdetaildata === 'object'
                && newpo.purchaseorderdetaildata.length > 0)
                newpodetails = newpodetails.concat(newpo.purchaseorderdetaildata);
            }
          }
        }
      });
    }
    newpodetails = arraySet(newpodetails);
    console.log("[comparePOsAndUpdateSE] newpodetails: ", newpodetails);

    if (!seid) {
      console.log("[comparePOsAndUpdateSE] Old & New POs have no SE Id.");
      return false;
    }
    console.log("[comparePOsAndUpdateSE] seid: ", seid);

    let podetailpair = [];

    for (let i = 0; i < newpodetails.length; i++) {
      const newpodetail = newpodetails[i];
      if (!newpodetail.id) continue;
      const found = oldpodetails.find(d => d.id === newpodetail.id);
      if (!found) {
        // to Add
        if (options.isCreate) {
          const quantity = getQuantity(newpodetail.podquantity);
          podetailpair = podetailpair.concat(Array(quantity).fill({
            newpodetail,
            oldpodetail: null,
            oldpodetailindex: 0,
          }));
        }
      }
      else {
        found.isChecked = true;
        const cmp = comparePODetail(newpodetail, found);
        if (!cmp) {
          // to Update
          const quantity = getQuantity(found.podquantity);
          podetailpair = podetailpair.concat(Array(quantity).fill().map((v, i) => ({
            newpodetail,
            oldpodetail: found,
            oldpodetailindex: i,
          })));
        }
      }
    }
    for (let i = 0; i < oldpodetails.length; i++) {
      const oldpodetail = oldpodetails[i];
      if (!oldpodetail.id || oldpodetail.isChecked) continue;
      // to Remove
      const quantity = getQuantity(oldpodetail.podquantity);
      if (!options.isRemove) {
        /**
         * If No Remove PO Detail, then it should change Stock Ownership.
         */
        const newvirtualpodetail = {
          ...oldpodetail,
          podproduct: {
            ...oldpodetail.podproduct,
            sepownership: "Stock"
          }
        };
        // If change to Stock, then index of podetail should be sequence
        podetailpair = podetailpair.concat(Array(quantity).fill().map((v, i) => ({
          newpodetail: newvirtualpodetail,
          oldpodetail,
          oldpodetailindex: i,
        })));
      }
      else {
        // If remove, then index of podetail should be always 0
        podetailpair = podetailpair.concat(Array(quantity).fill({
          newpodetail: null,
          oldpodetail,
          oldpodetailindex: 0,
        }));
      }
    }

    if (podetailpair.length === 0) {
      console.log("[comparePOsAndUpdateSE] Nothing to update SE by POs.");
      return false;
    }
    console.log("[comparePOsAndUpdateSE] podetailpair: ", podetailpair);

    for (let i = 0; i < podetailpair.length; i++) {
      const { newpodetail, oldpodetail, oldpodetailindex } = podetailpair[i];
      await updateSEByPO({
        ledger,
        seid,
        newpodetail,
        oldpodetail,
        oldpodetailindex,
        user,
        role,
        roleCid,
      });
    }

    return true;
  }
  catch (e) {
    console.warn("[comparePOsAndUpdateSE]", e);
  }

  return false;
};

/**
 * Add a PurchaseOrder Comment by Hospital
 * exercise HPOAddComment choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} roleCid
 * @param {String} pocid 
 * @param {Object} newpocomment 
 */
export const addPOCommentHospital = async ({
  ledger, roleCid, pocid, newpocomment, 
}) => {

  try {

    if (roleCid && pocid) {
      let res = await ledger.exercise(HospitalRole.HPOAddComment, roleCid, {
        pocid,
        newpocomment: getPOComment(newpocomment),
      });

      // log the response to loggly
      loggerPush({ type: LOGGER_INFO, action: 'PO: HPOAddComment', response: res });

      return true;
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'PO: HPOAddComment', payload: e, params: {newpocomment, pocid} });
    console.warn("[addPOCommentHospital]", e);
  }

  return false;
};

/**
 * Add a PurchaseOrder Comment by Vendor
 * exercise VPOVAddComment choice of VendorRole
 * @param {Object} ledger 
 * @param {String} roleCid
 * @param {String} povcid 
 * @param {Object} newpocomment 
 */
export const addPOCommentVendor = async ({
  ledger, roleCid, povcid, newpocomment, 
}) => {

  try {

    if (roleCid && povcid) {
      let res = await ledger.exercise(VendorRole.VPOVAddComment, roleCid, {
        povcid,
        newpocomment: getPOComment(newpocomment),
      });

      // log the response to loggly
      loggerPush({ type: LOGGER_INFO, action: 'PO: VPOVAddComment', response: res });

      return true;
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'PO: VPOVAddComment', payload: e, params: {newpocomment, povcid} });
    console.warn("[addPOCommentVendor]", e);
  }

  return false;
};

/**
 * Update PurchaseOrder Notes by Hospital
 * exercise HPOUpdatePONotes choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} roleCid
 * @param {String} pocid 
 * @param {String} newponotes 
 */
export const updatePONotesHospital = async ({
  ledger, roleCid, pocid, newponotes, 
}) => {

  try {

    if (roleCid && pocid) {
      let res = await ledger.exercise(HospitalRole.HPOUpdatePONotes, roleCid, {
        pocid,
        newponotes,
      });

      // log the response to loggly
      loggerPush({ type: LOGGER_INFO, action: 'PO: HPOUpdatePONotes', response: res });

      return true;
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'PO: HPOUpdatePONotes', payload: e, params: {newponotes, pocid} });
    console.warn("[updatePONotesHospital]", e);
  }

  return false;
};

/**
 * Update PurchaseOrder Notes by Vendor
 * exercise VPOVUpdatePONotes choice of VendorRole
 * @param {Object} ledger 
 * @param {String} roleCid
 * @param {String} povcid 
 * @param {String} newponotes 
 */
export const updatePONotesVendor = async ({
  ledger, roleCid, povcid, newponotes, 
}) => {

  try {

    if (roleCid && povcid) {
      let res = await ledger.exercise(VendorRole.VPOVUpdatePONotes, roleCid, {
        povcid,
        newponotes,
      });

      // log the response to loggly
      loggerPush({ type: LOGGER_INFO, action: 'PO: VPOVUpdatePONotes', response: res });

      return true;
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'PO: VPOVUpdatePONotes', payload: e, params: {newponotes, povcid} });
    console.warn("[updatePONotesVendor]", e);
  }

  return false;
};

