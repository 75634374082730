import React from "react";
import classNames from "classnames";
import { Chip } from "@material-ui/core";
import Contracts from "../../components/Contracts/Contracts1";
import { contractFilter1 } from "../../services/daml-filters";
import editIcon from "../../icons/edit.svg";
import deleteIcon from "../../icons/delete.svg";
import { UserAccessLevel, UserJobTitle, UserStatus } from "../../models/UserAccessLevel";
import useStyles from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";
import withFilters from "../../hoc/withFilters";



/**
 * User Management List Contracts in Vendor
 * @param {Object} searchParams 
 * @param {Function} handleChangeSearchValue 
 * @param {Array} allContracts 
 * @param {Object} searchUser 
 * @param {Function} handleRequest 
 * @param {Function} handleDeleteRequest 
 * @returns 
 */
function VendorUserContracts({
  searchParams = {},
  allContracts = [],
  searchUser = {},
  handleRequest,
  handleDeleteRequest,
}) {

  const classes = useStyles();
  let contracts = contractFilter1(allContracts, searchParams);

  const getColorCode = (value) =>{
    switch (value) {
      case 'Active':
        return classes.backGreen;
      case 'Cancelled':
        return classes.backRed;
      default:
        return classes.backBlue;
    }
  }

  return (
    <Contracts
      className={classes.customRoot}
      contracts={contracts}
      columns={[
        ["Name", "payload.name", {
          options: {
            width: 180,
          },
          search: {
            type: "AdaptiveSearch",
            name: "name",
            getValue: (c) => c.payload.name,
            contracts: allContracts,
          },
        }],
        ["Job Title", "payload.jobtitle", {
          type: "render",
          render: (param) => UserJobTitle[param.value],
          options: {
            flex: 1,
          },
        }],
        ["Gender", "payload.gender"],
        ["Office Phone", "payload.officephone", {
          options: {
            width: 180
          }
        }],
        ["Mobile Phone", "payload.phone", {
          options: {
            width: 180
          }
        }],
        ["Email", "payload.email", {
          options: {
            width: 180
          }
        }],
        ["Access Level", "payload.accesslevel", {
          type: "render",
          render: (param) => UserAccessLevel[param.value],
          options: {
            width: 150,
          },
        }],
        ["User Status", "payload.userstatus", {
          type: "render",
          render: (param) => (UserStatus[param.value] && <Chip
            label={
              UserStatus[param.value]
            }
            size="small"
            className={classNames(classes.smallChip,getColorCode(param.value))}
          />),

          options: {
            cellClassName: classes.centerCell,
            headerClassName: classes.centerCell,
            width: 140,
          },
        }],
      ]}

      actions={[
        [
          "Action",
          [
            [["Edit", [<img alt="" src={editIcon} />]],
              handleRequest],
            [["Delete", [<img alt="" src={deleteIcon} />]],
              handleDeleteRequest],
          ],
        ]
      ]}

      options={{
        statusEmpty: (allContracts.length === 0),
        checkbox : true,
      }}
    />
  );
}

const VendorUserContractsWithLoading = withLoading(VendorUserContracts);
export default withFilters(VendorUserContractsWithLoading)([
    { name: "name", label: "Name" },  
]);