import { requiredFields } from "./handles";

export const API_InvoiceModel = {
  invoiceNumber: '',
  invoiceCurrency: '',
  invoiceAmount: '',
  invoiceDate: '',
  businessUnit: '',
  supplier: '',
  supplierSite: '',
  requester: '',
  invoiceGroup: '',
  description: '',
};
export const validateAPI_Invoice = (api_invoice) => {
  return (api_invoice.invoiceNumber !== ""
    || api_invoice.invoiceCurrency !== ""
    || api_invoice.invoiceAmount !== ""
    || api_invoice.invoiceDate !== ""
    || api_invoice.businessUnit !== ""
    || api_invoice.supplier !== ""
    || api_invoice.supplierSite !== ""
    || api_invoice.requester !== ""
    || api_invoice.invoiceGroup !== ""
    || api_invoice.description !== "");

};
export const getAPI_Invoice = (api_invoice) => {
  let newAPI_Invoice = {};
  newAPI_Invoice.invoiceNumber = api_invoice.invoiceNumber || "";
  if (typeof api_invoice.invoiceCurrency === "string" && api_invoice.invoiceCurrency !== "") newAPI_Invoice.invoiceCurrency = api_invoice.invoiceCurrency;
  newAPI_Invoice.invoiceAmount = api_invoice.invoiceAmount || "0";
  newAPI_Invoice.invoiceDate = api_invoice.invoiceDate || "";
  if (typeof api_invoice.businessUnit === "string" && api_invoice.businessUnit !== "") newAPI_Invoice.businessUnit = api_invoice.businessUnit;
  if (typeof api_invoice.supplier === "string" && api_invoice.supplier !== "") newAPI_Invoice.supplier = api_invoice.supplier;
  if (typeof api_invoice.supplierSite === "string" && api_invoice.supplierSite !== "") newAPI_Invoice.supplierSite = api_invoice.supplierSite;
  if (typeof api_invoice.requester === "string" && api_invoice.requester !== "") newAPI_Invoice.requester = api_invoice.requester;
  if (typeof api_invoice.invoiceGroup === "string" && api_invoice.invoiceGroup !== "") newAPI_Invoice.invoiceGroup = api_invoice.invoiceGroup;
  if (typeof api_invoice.description === "string" && api_invoice.description !== "") newAPI_Invoice.description = api_invoice.description;
  return newAPI_Invoice;
};
export const requiredAPI_Invoice = (api_invoice) => requiredFields(api_invoice, {
  'invoiceNumber': 'Invoice Number',
  // 'invoiceCurrency': 'Invoice Currency',
  'invoiceAmount': 'Invoice Amount',
  'invoiceDate': 'Invoice Date',
  // 'businessUnit': 'Business Unit',
  // 'supplier': 'Supplier',
  // 'supplierSite': 'Supplier Site',
  // 'requester': 'Requester',
  // 'invoiceGroup': 'Invoice Group',
  // 'description': 'Description',
});

export const API_Invoice_AttachmentModel = {
  _type: '',
  fileName: '',
  title: '',
  description: '',
  category: '',
  fileUrl: '',
};
export const validateAPI_Invoice_Attachment = (api_invoice_attachment) => {
  return (api_invoice_attachment._type !== ""
    || api_invoice_attachment.fileName !== ""
    || api_invoice_attachment.title !== ""
    || api_invoice_attachment.description !== ""
    || api_invoice_attachment.category !== ""
    || api_invoice_attachment.fileUrl !== "");
};
export const getAPI_Invoice_Attachment = (api_invoice_attachment) => {
  let newAPI_Invoice_Attachment = {};
  newAPI_Invoice_Attachment._type = api_invoice_attachment._type || "";
  if (typeof api_invoice_attachment.fileName === "string" && api_invoice_attachment.fileName !== "") newAPI_Invoice_Attachment.fileName = api_invoice_attachment.fileName;
  newAPI_Invoice_Attachment.title = api_invoice_attachment.title || "";
  if (typeof api_invoice_attachment.description === "string" && api_invoice_attachment.description !== "") newAPI_Invoice_Attachment.description = api_invoice_attachment.description;
  if (typeof api_invoice_attachment.category === "string" && api_invoice_attachment.category !== "") newAPI_Invoice_Attachment.category = api_invoice_attachment.category;
  if (typeof api_invoice_attachment.fileUrl === "string" && api_invoice_attachment.fileUrl !== "") newAPI_Invoice_Attachment.fileUrl = api_invoice_attachment.fileUrl;
  return newAPI_Invoice_Attachment;
};
export const requiredAPI_Invoice_Attachment = (api_invoice_attachment) => requiredFields(api_invoice_attachment, {
  '_type': 'Type',
  // 'fileName': 'File Name',
  'title': 'Title',
  // 'description': 'Description',
  // 'category': 'Category',
  // 'fileUrl': 'File Url',
});

export const API_Invoice_InstallmentModel = {
  installmentNumber: '',
  dueDate: '',
  grossAmount: '',
  invoiceInstallmentDff: '',
  _payment__mode: '',
};
export const validateAPI_Invoice_Installment = (api_invoice_installments) => {
  return (api_invoice_installments._installmentNumber !== ""
    || api_invoice_installments.dueDate !== ""
    || api_invoice_installments.grossAmount !== ""
    || api_invoice_installments.invoiceInstallmentDff !== ""
    || api_invoice_installments._payment__mode !== "");
};
export const getAPI_Invoice_Installment = (api_invoice_installments) => {
  let newAPI_Invoice_Installment = {};
  newAPI_Invoice_Installment.installmentNumber = api_invoice_installments.installmentNumber || "0";
  newAPI_Invoice_Installment.dueDate = api_invoice_installments.dueDate || "";
  newAPI_Invoice_Installment.grossAmount = api_invoice_installments.grossAmount || "0";
  if (typeof api_invoice_installments.invoiceInstallmentDff === "string" && api_invoice_installments.invoiceInstallmentDff !== "") newAPI_Invoice_Installment.invoiceInstallmentDff = api_invoice_installments.invoiceInstallmentDff;
  if (typeof api_invoice_installments._payment__mode === "string" && api_invoice_installments._payment__mode !== "") newAPI_Invoice_Installment._payment__mode = api_invoice_installments._payment__mode;
  return newAPI_Invoice_Installment;
};
export const requiredAPI_Invoice_Installment = (api_invoice_installments) => requiredFields(api_invoice_installments, {
  'installmentNumber': 'Installment Number',
  'dueDate': 'Due Date',
  'grossAmount': 'Gross Amount',
  // 'invoiceInstallmentDff': 'Invoice Installment Dff',
  // '_payment__mode': 'Payment Mode',
});

export const API_Invoice_LinesModel = {
  lineNumber: '',
  lineAmount: '',
  lineDescription: '',
};
export const validateAPI_Invoice_Lines = (api_invoice_lines) => {
  return (api_invoice_lines._lineNumber !== ""
    || api_invoice_lines.lineAmount !== ""
    || api_invoice_lines.lineDescription !== ""
    );
};
export const getAPI_Invoice_Lines = (api_invoice_lines) => {
  let newAPI_Invoice_Lines = {};
  newAPI_Invoice_Lines.lineNumber = api_invoice_lines.lineNumber || "0";
  newAPI_Invoice_Lines.lineAmount = api_invoice_lines.lineAmount || "0";
  if (typeof api_invoice_lines.lineDescription === "string" && api_invoice_lines.lineDescription !== "") newAPI_Invoice_Lines.lineDescription = api_invoice_lines.lineDescription;
  return newAPI_Invoice_Lines;
};
export const requiredAPI_Invoice_Lines = (api_invoice_lines) => requiredFields(api_invoice_lines, {
  'lineNumber': 'Line Number',
  'lineAmount': 'Line Amount',
  // 'lineDescription': 'Line Description',
});
