import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { useLedger } from "@daml/react";
import { Chip } from "@material-ui/core";
import { /* PurchaseOrderVendor, */ PurchaseOrderVendorHistory } from "../../services/daml-modules1";
import AdaptiveSearch from "../../components/Search/adaptivesearch";
import DateSearch from "../../components/Search/datesearch";
import TreeContracts from "../../components/Contracts/TreeContracts";
import { contractFilter } from "../../services/daml-filters";
import archiveIcon from "../../icons/archive.svg";
import archiveDisableIcon from "../../icons/archive-disable.svg";
import toggleAcceptedIcon from "../../icons/toggle-right-accepted.svg";
import toggleDeclinedIcon from "../../icons/toggle-left-declined.svg";
import toggleReceivedIcon from "../../icons/toggle-left-received.svg";
import { checkNotPOHistory } from '../../models/Po';
import { isEnableAcceptDecline, isEnableCreateInvoice, isEnableArchive, POCommentModel,
  POStatus } from '../../models/POStatus';
import { getTodayString } from "../../services/util";
import { ArchivePoV, handlePOStatus, createInvoice } from "./handle";
import DetailViewDrawer from "./detail-view-drawer";
import SendPO from './sendpo';
import createInvoiceIcon from '../../icons/file-plus.svg';
import createInvoiceDisIcon from '../../icons/file-plus-disable.svg';
import ModalDialogBox from "../../components/Modals/GeneralModal/modalDialogBox";
import { useDamlStream } from "../../hooks/useDAML";
import { useDamlState } from "../../context/DamlContext";
import useStyles from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";


/**
 * ReceivedPoList Contracts in Vendor
 * remove all parameters & searches & contracts for Surgical(PPV)
 * @param {Object} searchParams
 * @param {Object} searchHistoryParams
 * @param {Boolean} isIncludeHistory
 * @param {Function} handleChangeSearchValue
 * @param {Object} searchPO
 * @param {Array} allContracts
 * @returns 
 */
function ReceivedPoListContracts({
  searchParams = {},
  searchHistoryParams = {},
  isIncludeHistory = false,
  handleChangeSearchValue = null,
  searchPO = {},
  allContracts = [],
}) {


  const classes = useStyles();
  const history = useHistory();
  const ledger = useLedger();
  // const assets = useStreamQueries(PurchaseOrderVendor, () => [searchParams]);
  const { roleCid, userlistPayload, /* rolePayload */ } = useDamlState();

  // const [assetsHistory, setHisotry] = useState({});
  const assetsHistory = useDamlStream(PurchaseOrderVendorHistory, [searchHistoryParams], isIncludeHistory);

  allContracts = allContracts.concat(isIncludeHistory && assetsHistory.contracts ?
    assetsHistory.contracts.map(h => ({ ...h, payload: h.payload.purchaseordervendor })) : []);

  let contracts = contractFilter(allContracts, searchParams);


  // const assets = useStreamQueries(VendorRole, () => [{ vendor }]);

  // const contractsSE = assetsSE.contracts.concat(assetsHistorySE.contracts.map(h => ({...h, payload: h.payload.postprocedurevendor})));
  contracts = contracts
    .map(c => {
      c.payload.purchaseorderdata.purchaseorderdetaildataproducts = c.payload.purchaseorderdetaildata.reduce((s, d) => (s + parseInt(d.podquantity)), 0);
      return c;
    });
  /* contracts = contracts.map(c => {
    let newc = c;
    newc.payload.purchaseorderdetaildata = c.payload.purchaseorderdetaildata.map(d => ({
      ...d, 
      purchaseorderdata: c.payload.purchaseorderdata,
    }));
    return newc;
  }); */

  // hash keys for PO
  /* let hashKeys = [];
  contractsSE.forEach(c => {
    c.payload.purchaseorders = [];
    if (c.payload.surgicaleventdata) hashKeys[c.payload.surgicaleventdata.seid] = c;
  });
  contracts.forEach(c => {
    if (c.payload.purchaseorderdata && c.payload.purchaseorderdata.poseid in hashKeys) {
      hashKeys[c.payload.purchaseorderdata.poseid].payload.purchaseorders.push(c);
    }
  }); */

  console.log("[ReceivedPoListContracts]", searchParams, contracts);

  const [ContractId, setContractId] = useState('');
  // DetailViewDrawer
  const [poData, setPOData] = useState({});
  const [productList, setProductList] = useState([]);
  const [commentList, setCommentList] = useState([]);
  const [detailViewer, setDetailViewer] = useState(false);
  // send PO
  const [sendPOOpen, setSendPOOpen] = useState(false);
  const [sendPoSuccess, setSendPoSuccess] = useState(false);
  const [sendSelectedPOData, setSendSelectedPOData] = useState({});
  const [sendPoproductItemsList, setSendPoproductItemsList] = useState([]);
  const [poComment, setPoComment] = useState({});
  const [poHospital, setPoHospital] = useState({});
  // Archive PO
  const [openWarning, setOpenWarning] = useState(false);
  const [, setConfirmModalStatus] = useState('');
  // const [invoiceNumber, setInvoiceNumber] = useState('');
  const [openIC, setOpenIc] = useState(false);
  // Accept/Decline
  const [openWarningDecline, setOpenWarningDecline] = useState(false);


  // Click "Send PO" button in PO list
  const setSendPOData = (data) => {
    console.log("data", data);
    setSendSelectedPOData(data.payload.purchaseorderdata);
    setSendPoproductItemsList(data.payload.purchaseorderdetaildata);
    setContractId(data.contractId);
    setPoComment({
      ...POCommentModel,
      date: getTodayString(),
    });
    setPoHospital(data.payload.hospital);
    setSendPOOpen(true);
  };

  // Click "Send" button in Send PO modal
  const handleSendPO = async (callback) => {
    console.log("[ReceivedPoListContracts] handleSendPO", ContractId, sendSelectedPOData, sendPoproductItemsList, poComment);

    if (poComment.status === 'Accepted' || poComment.status === 'Rejected') {

      // send PO to Hospital
      const result = await handlePOStatus({
        pocomment: poComment,
        postatus: poComment.status,
        poHospital,
        ledger,
        ContractId,
        roleCid,
      });

      // if (result && poComment.status === 'Accepted') {
      //   console.log(rolePayload.admindetails.auto_invoice);
      //   let auto_invoice = rolePayload.admindetails.auto_invoice;
      //   if (auto_invoice && result[0]) {
      //     await handleCreateInvoice(result[0], false)
      //   }
      // }

      if (typeof callback === 'function') callback(() => {
        if (typeof setSendPoSuccess === 'function' && !!result) setSendPoSuccess(true);
        if (typeof setSendPOOpen === 'function') setSendPOOpen(false);
        return result;
      });
      else {
        if (typeof setSendPoSuccess === 'function' && !!result) setSendPoSuccess(true);
        if (typeof setSendPOOpen === 'function') setSendPOOpen(false);
      }

    }
    else {
      if (typeof callback === 'function') callback(() => {
        setOpenWarningDecline(true);
        return true;
      });
    }
  };

  // Click "Archive POV" in list
  const handleArchivePOV = (pov) => {
    console.log('[ReceivedPoListContracts] handleArchivePOV', pov);
    setConfirmModalStatus('ArchivePO');
    setContractId(pov.contractId);
    setOpenWarning(true);
  };

  // Click "Yes" in "Archive Purchase Order" modal
  const handleModalYes = async () => {
    console.log("[ReceivedPoListContracts] handleModalYes to archive Purchase Order", ContractId);
    // archive PO
    await ArchivePoV({
      ledger, cid: ContractId, roleCid,
    });
    setOpenWarning(false);
  };

  // Click "Create Invoice" in list
  const handleCreateInvoice = async (c, showInvoiceConfirmModal = true) => {
    console.log(" ", c);
    // create invoice and open confirm modal
    await createInvoice({
      contractId: c.contractId,
      payload: c.payload,
      ledger,
      setConfirmModalOpen: showInvoiceConfirmModal ? setOpenIc : undefined,
      roleCid,
      userlistPayload,
    });
  };

  // Click "Yes" in "Created Invoice" modal
  const handleModalInvoice = () => {
    setOpenIc(false);
    history.push("/app/invoice");
  };
  const handleSecRequestWarning = () => {
    setOpenWarning(false);
  };
  const handleCompletePurchaseOrder = () => {
    // console.log(productItemsList);
    setSendPoSuccess(false);
    history.push("/app/receivedpo");
  };

  // check to changed Price
  const checkChangedPrice = (c) => {
    // console.log("[PurchaseOrderListContracts] checkChangedPrice", c);
    return !!c.pricechange;
  };


  return (
    <>
      <div key="formChildStep" className="formsteps">
        <TreeContracts
          className={classes.customRoot}
          contracts={contracts}
          columns={[
            // ["ContractId", "contractId"],
            ["SE Id", "payload.purchaseorderdata.poseid", {
              search: {
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.purchaseorderdata.poseid}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'poseid')}
                    defaultValue={searchPO.poseid}
                    label="Search SE ID"
                    contracts={allContracts}
                    isShrink={true}
                  />
              },
              isSearched: searchPO.poseid,
              handleClear: () => handleChangeSearchValue('', 'poseid'),
              options: {
                width: 130
              }
            }],
            ["PO Number", "payload.purchaseorderdata.popurchaseorderid", {
              type: "link",
              onClick: (contractId, contract) => {
                const clickedContractId = (!!contractId) ? contractId : contract.contractId;
                // const data = (!!contract) ? contract : contractsSE.find(c => c.contractId === clickedContractId);
                const data = contracts.find(c => c.contractId === clickedContractId);
                setPOData({...data.payload.purchaseorderdata, hospital: data.payload.hospital});
                setProductList(data.payload.purchaseorderdetaildata);
                setCommentList(data.payload.purchaseordercomment);
                setDetailViewer(true);
              },
              options: {
                width: 160,
                cellClassName: classNames(classes.primaryColor)
              },
              search: {
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.purchaseorderdata.popurchaseorderid}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'popurchaseorderid')}
                    defaultValue={searchPO.popurchaseorderid}
                    label="Search PO Number"
                    contracts={allContracts}
                    isShrink={true}
                  />
              },
              isSearched: searchPO.popurchaseorderid,
              handleClear: () => handleChangeSearchValue('', 'popurchaseorderid'),
            }],
            ["PO Date", "payload.purchaseorderdata.podate", {
              search: {
                component:
                  <DateSearch
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'podate')}
                    defaultValue={searchPO.podate}
                    label="Search Event Date :"
                    isStatic={true}
                  />
              },
              isSearched: searchPO.podate,
              handleClear: () => handleChangeSearchValue('', 'podate'),
              options: {
                width: 160,
              }
            }],
            ["Status", "payload.purchaseorderdata.postatus", {
              type: "render",
              render: (param) => (POStatus[param.value] && <Chip
                label={POStatus[param.value]}
                size="small"
                className={classNames(classes.smallChip, (param.value === 'InvoiceCreated' ? classes.backBlue
                  : (param.value === 'Accepted' ? classes.backGreen : (
                    param.value === 'Rejected' ? classes.backRed : classes.backLightYellow
                  ))))}
              />),
              options: {
                cellClassName: classes.centerCell,
                headerClassName: classes.centerCell,
              },
            }],
            ["Hospital", "payload.hospital", {
              search: {
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.hospital}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'hospital')}
                    defaultValue={searchPO.hospital}
                    label="Search SE ID"
                    contracts={allContracts}
                    isShrink={true}
                  />
              },
              isSearched: searchPO.hospital,
              handleClear: () => handleChangeSearchValue('', 'hospital'),
              options: {
                width: 160,
              }
            }],
            ["Total Amount", "payload.purchaseorderdata.pototalamount", {
              options: {
                beforeText: '$',
                width: 160,
              }
            }],
            ["Ownership", "payload.purchaseorderdata.popurchaseordertype"],
            ["Products", "payload.purchaseorderdata.purchaseorderdetaildataproducts", {
              type: "object",
              options: {
                type: 'number',
                cellClassName: classes.centerCell,
              },
            }],
            ["Product Details", "payload.purchaseorderdetaildata", {
              type: "collapse",
              columns: [
                ["Reference Number", "podproduct.sepreferencenumber", {
                  options: {
                    width: 160,
                  }
                }],
                ["Product Name", "podproduct.sepproductname", {
                  options: {
                    width: 170,
                    tooltip: true,
                  },
                }],
                ["Manufacturer", "podproduct.sepproductmanufacturer", {
                  options: {
                    width: 150,
                  },
                }],
                ["Product Description", "podproduct.sepproductdescription", {
                  options: {
                    width: 180,
                    tooltip: true,
                  },
                }],
                ["Quantity", "podquantity"],
                ["Price", "podunitprice", {
                  type: "render",
                  render: (param) => (
                    <>
                      <span className={checkChangedPrice(param.row) ? classes.changePrice : null}>
                        {param.value !== "" && <>$</>}
                        {param.value}
                      </span>
                    </>
                  ),
                }],
                ["Lot Code", "podproduct.seplotcode"],
                ["Product Type", "podproduct.sepproducttype"],
                // ["Waste", "podproduct.sepproductwaste"],
                ["Ownership", "podproduct.sepownership"],
              ],
            }],
          ]}

          actions={[
            [
              "Actions",
              [
                [["Accept/Decline", [
                  <img alt="" src={toggleReceivedIcon} />,
                  <img alt="" src={toggleDeclinedIcon} />,
                  <img alt="" src={toggleAcceptedIcon} />,
                ],
                  (c) => ((isEnableAcceptDecline(c.payload.purchaseorderdata.postatus) && checkNotPOHistory(c)) ? 0 :
                    ((c.payload.purchaseorderdata.postatus === "Rejected") ? 1 : 2))
                ],
                  setSendPOData],
                [["Create Invoice ", [<img alt="" src={createInvoiceIcon} />, <img alt="" src={createInvoiceDisIcon} />],
                  (c) => isEnableCreateInvoice(c.payload.purchaseorderdata.postatus) && checkNotPOHistory(c)],
                  handleCreateInvoice],
                [["Archive PO", [<img alt="" src={archiveIcon} />, <img alt="" src={archiveDisableIcon} />],
                  (c) => isEnableArchive(c.payload.purchaseorderdata.postatus) && checkNotPOHistory(c)],
                  handleArchivePOV],
              ],
            ]
          ]}
          options={{
            statusEmpty: (allContracts.length === 0)
          }}
        />


        <DetailViewDrawer
          state={detailViewer}
          toggleDrawer={(open) => setDetailViewer(open)}
          selectedPOData={poData}
          productItemsList={productList}
          commentsList={commentList}
          isDownload={true}
        />

        <ModalDialogBox
          open={openIC}
          setOpen={setOpenIc}
          heading={"Successfully Created Invoice"}
          text={`<p>You have Successfully Created the Invoice. The Hospital has been Alerted.</p>
            <div className="btnPopGroup"><p>Next step, Send the Invoice of the Purchase Order on the Invoice screen.</p></div>`}
          handleRequest={handleModalInvoice}
          firstBtn="Invoices"
        />

        <ModalDialogBox
          open={openWarning}
          setOpen={setOpenWarning}
          heading={"Warning"}
          type="warning"
          text={'<p> You will not be able to Recover it.</p>'}
          handleRequest={handleModalYes}
          secondBtn={'No'}
          handleSecRequest={handleSecRequestWarning}
        />

        <SendPO
          isSendVendor={false}
          openModal={sendPOOpen}
          setSendPOOpen={setSendPOOpen}
          handleSendPO={handleSendPO}
          pohospital={poHospital}
          selectedPOData={sendSelectedPOData}
          productItemsList={sendPoproductItemsList}
          pocommentdata={poComment}
          setConductFormComment={setPoComment}
          isDownload={true}
        />

        <ModalDialogBox
          open={sendPoSuccess}
          setOpen={setSendPoSuccess}
          heading={poComment.status === "Accepted" ? "Successfully Accepted" : "Purchase Order Declined "}
          type={poComment.status === "Accepted" ? "success" : "warning"}
          text={poComment.status === "Accepted" ?
            '<p> You have successfully Accepted the Purchase Order. The Hospital has been Alerted.</p>'
            : '<p>You have successfully Declined the Purchase Order. The Hospital has been Alerted.</p>'}
          handleRequest={handleCompletePurchaseOrder}
          firstBtn={'Ok'}
        />

        <ModalDialogBox
          open={openWarningDecline}
          setOpen={setOpenWarningDecline}
          heading={"Warning"}
          type="warning"
          text={'<p>You have not accepted/declined the PO.</p><p>Please make your selection in order to proceed.</p>'}
          handleRequest={() => setOpenWarningDecline(false)}
          firstBtn="Ok"
        />

      </div>

    </>
  );
}

export default withLoading(ReceivedPoListContracts);
