import React from "react";
import { Grid, CircularProgress } from "@material-ui/core";

export const CustomLoading = ({ className }) => (
  <Grid
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
    className={className ? className : null}
  >
    <CircularProgress />
  </Grid>
);

export default function withLoading (WrappedComponent) {
  return ({ loading, loadingClass, ...props }) => {
    if (typeof loading !== 'undefined' && loading) return (<CustomLoading className={loadingClass} />);
    return (<WrappedComponent {...props} />);
  }
}