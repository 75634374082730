import { PRODUCT_SET, PRODUCT_SET_ALL, PRODUCT_SET_DETAIL, PRODUCT_SET_SECONDARY, PRODUCT_SET_EVENT_DETAIL, 
  PRODUCT_CLEAR, PREF_SET_EVENT_DETAIL,} from "./constants";

export const setProduct = (payload) => ({
  type: PRODUCT_SET,
  payload
});

export const setProductAll = (payload) => ({
  type: PRODUCT_SET_ALL,
  payload
});

export const setProductDetail = (payload) => ({
  type: PRODUCT_SET_DETAIL,
  payload
});

export const setProductSecondary = (payload) => ({
  type: PRODUCT_SET_SECONDARY,
  payload
});

export const setProductSEDetail = (payload) => ({
  type: PRODUCT_SET_EVENT_DETAIL,
  payload
});

export const clearProduct = () => ({
  type: PRODUCT_CLEAR,
});

export const setPrefSEDetail = (payload) => ({
  type: PREF_SET_EVENT_DETAIL,
  payload
});