import React, { useState } from "react";
import classNames from "classnames";
import { useLedger, useParty } from "@daml/react";
import { Button, Chip, Select, FormControl } from "@material-ui/core";
import { SurgicalEventHistory, PurchaseOrderHistory } from "../../services/daml-modules1";
import { useDamlState } from '../../context/DamlContext';
import AdaptiveSearch from "../../components/Search/adaptivesearch";
import DateSearch from "../../components/Search/datesearch";
import SelectSearch from "../../components/Search/selectsearch";
import TreeContracts from "../../components/Contracts/TreeContracts";
import { contractFilter } from "../../services/daml-filters";
import filePlusIcon from "../../icons/file-plus.svg";
import filePlusDisableIcon from "../../icons/file-plus-disable.svg";
import sendIcon from "../../icons/send.svg";
import sendDisableIcon from "../../icons/send-disable.svg";
import editIcon from "../../icons/edit.svg";
import editDisableIcon from "../../icons/edit-disable.svg";
import archiveIcon from "../../icons/archive.svg";
import archiveDisableIcon from "../../icons/archive-disable.svg";
import deleteIcon from "../../icons/delete.svg";
import deleteDisableIcon from "../../icons/delete-disable.svg";
import slashIcon from "../../icons/slash.svg";
import { checkEnableArchiveSE } from "../surgicalevent/surgicaleventlistcontracts";
import { getOwnerships } from "../../models/Ownership";
import { isEnableSendVendor, isEnableEdit, isEnableDeleteSubProduct, isEnableChangeOwnership, 
  isEnableArchive, POHStatus, POCommentModel, /* isEnableArchivePO */ } from '../../models/POStatus';
import { InventoryPOStatusHospital, /* InventoryPOStatus, */ checkNotPOHistory } from "../../models/Po";
import { getTodayString } from "../../services/util";
import { SendPov, ArchivePo, removePODetailData, updatePODetailData, changeOwnershipDetail } from "./handle";
import DetailViewDrawer from "./detail-view-drawer";
import SEDetailViewDrawer from "./detail-view-drawer-se";
import SendPO from './sendpo';
import { completeSurgicalEvent, archiveSurgicalEvent } from '../surgicalevent/handle';
import { createpo } from '../postprocedureform/handle';
import ModalDialogBox from "../../components/Modals/GeneralModal/modalDialogBox";
import EditPrice from './EditPrice';
import AddIcon from '@material-ui/icons/Add';
import { setToggleStage } from "../../store/actions/generalActions";
import { dispatch } from "../../store";
import { useDamlStream } from "../../hooks/useDAML";
import useStyles from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";


/**
 * SE Purchase Order Contracts in Hospital
 * @param {Object} searchParams 
 * @param {Object} searchHistoryParams 
 * @param {Object} searchParamsSurgical 
 * @param {Object} searchHistoryParamsSurgical 
 * @param {Boolean} isIncludeHistory 
 * @param {Function} handleChangeSearchValue 
 * @param {Object} searchPO 
 * @param {Array} allContracts 
 * @param {Array} allContractsSurgical 
 * @param {Object} createOptions 
 * @param {Object} assetsPPH 
 * @returns 
 */
function PurchaseOrderListContracts({
  searchParams = {},
  searchHistoryParams = {},
  searchParamsSurgical = {},
  searchHistoryParamsSurgical = {},
  isIncludeHistory = false,
  handleChangeSearchValue = null,
  searchPO = {},
  allContracts = [],
  allContractsSurgical = [],
  createOptions = {},
  assetsPPH = {},
}) {

  const classes = useStyles();
  const ledger = useLedger();
  const hospital = useParty();
  
  const { role, party, userParty, roleCid } = useDamlState();

  // const assetsSE = useStreamQueries(SurgicalEvent, () => [searchParamsSurgical]);
  // const assets = useStreamQueries(PurchaseOrder, () => [searchParams]);

  const [poHospital, setPoHospital] = useState('');
  // const [assetsHistorySE, setSEHisotry] = useState({});
  const assetsHistorySE = useDamlStream(SurgicalEventHistory, [{ hospital }], isIncludeHistory);
  // const [assetsHistory, setHisotry] = useState({});
  const assetsHistory = useDamlStream(PurchaseOrderHistory, [{ hospital }], isIncludeHistory);

  allContractsSurgical = allContractsSurgical.concat(isIncludeHistory && assetsHistorySE.contracts ?
    assetsHistorySE.contracts.map(h => ({ ...h, payload: h.payload.surgicalevent })) : []);
  allContracts = allContracts.concat(isIncludeHistory && assetsHistory.contracts ?
    assetsHistory.contracts.map(h => ({ ...h, payload: h.payload.purchaseorder })) : []);

  let contractsSE = contractFilter(allContractsSurgical, searchParamsSurgical);
  let contracts = contractFilter(allContracts, searchParams);

  // hash keys for PO
  let hashKeys = [];
  contractsSE.forEach(c => {
    c.payload.purchaseorders = [];
    c.payload.sumofpoproducts = 0;
    if (c.payload.surgicaleventdata) hashKeys[c.payload.surgicaleventdata.seid] = c;
  });
  contracts.forEach(c => {
    // contractId, purchaseorderdata of PO into purchaseorderdetaildata
    if (c.payload.purchaseorderdetaildata && c.payload.purchaseorderdetaildata.length) {
      c.payload.purchaseorderdetaildata = c.payload.purchaseorderdetaildata.map((d, index) => ({
        ...d,
        contractId: c.contractId,
        payload: c.payload,
        purchaseorderdata: c.payload.purchaseorderdata,
        purchaseorderdetaildatalength: c.payload.purchaseorderdetaildata.length,
        index,
      }));
    }
    // hash
    if (c.payload.purchaseorderdata && c.payload.purchaseorderdata.poseid in hashKeys) {
      c.payload.purchaseorderdata.purchaseorderdetaildataproducts = c.payload.purchaseorderdetaildata.reduce((s, d) => (s + parseInt(d.podquantity)), 0);
      hashKeys[c.payload.purchaseorderdata.poseid].payload.purchaseorders.push(c);
      hashKeys[c.payload.purchaseorderdata.poseid].payload.sumofpoproducts += c.payload.purchaseorderdata.purchaseorderdetaildataproducts;
    }
  });
  assetsPPH.contracts.forEach(c => {
    // hash
    if (c.payload.surgicaleventdata && c.payload.surgicaleventdata.seid in hashKeys) {
      hashKeys[c.payload.surgicaleventdata.seid].payload.postprocedurehospital = c;
    }
  });

  // check InventoryPOStatus, etc
  const newContracts = contractsSE
    // .filter(c => c.payload.purchaseorders.length) // old filter of SEs
    .filter(c => c.payload.surgicaleventdata.status === 'Complete') // new filter of SEs
    .map(c => {
      c.payload.surgicaleventdata.shippedreceivedproducts = 0;
      c.payload.purchaseorders = c.payload.purchaseorders.map(p => {
        p.pototalamount = 0;
        p.receivedinventorydatalength = 0;
        p.surgicaleventdata = {...c.payload.surgicaleventdata};
        // TODO
        // Now inventorydatas are empty, since it doesn't use streamQueries(Inventory.Inventory, () => [])
        // p.poinventorystatus = getInventoryPOStatus(p.inventorydatas);
        /*const orderData = {};
        p.payload.purchaseorderdetaildata.forEach(inv => {
           orderData[inv.id] = inv
        });*/
        // p.payload.purchaseorderdetaildata = sortingJson(orderData);
        /*c.payload.surgicaleventdata.shippedreceivedproducts += p.inventorydatas.length; */
        return p;
      });
      return c;
    });

  console.log("[PurchaseOrderListContracts]", searchParamsSurgical, searchParams, newContracts);


  const [ContractId, setContractId] = useState('');
  const [payload, setPayload] = useState({});
  // SEDetailViewDrawer
  const [seData, setSEData] = useState({});
  const [poList, setPoList] = useState([]);
  const [sestate, setSEState] = useState(false);
  // Archive PO
  const [openWarning, setOpenWarning] = useState(false);
  // send PO
  const [sendPOOpen, setSendPOOpen] = useState(false);
  const [sendPoSuccess, setSendPoSuccess] = useState(false);
  const [sendSelectedPOData, setSendSelectedPOData] = useState({});
  const [sendPoproductItemsList, setSendPoproductItemsList] = useState([]);
  const [poComment, setPoComment] = useState({});
  // DetailViewDrawer
  const [poData, setPOData] = useState({});
  const [productList, setProductList] = useState([]);
  const [commentList, setCommentList] = useState([]);
  const [detailViewer, setDetailViewer] = useState(false);
  // Delete Product
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteProductData, setDeleteProductData] = useState({});
  // Archive SE
  const [openWarningSE, setOpenWarningSE] = useState(false);
  const [confirmModalContract, setConfirmModalContract] = useState({});
  const {
    handleOpenCreatePO,
  } = createOptions;
  

  // Click "Add More PO" button
  const handleManageProductModal = (c) => {
    //console.log("[PurchaseOrderListContracts] handleManageProductModal", c);

    // open "Create PO" modal
    handleOpenCreatePO({ seData: c.payload.surgicaleventdata });
  };

  // Click "Create PO" button
  const handleCreatePOProduct = (c) => {
   // console.log("[PurchaseOrderListContracts] handleCreatePOProduct", c);
    if (!c.payload.surgicaleventproductdata || !c.payload.surgicaleventproductdata.length) {
      // Check out if SE is already completed
      if (c.payload.surgicaleventdata?.status === "Complete") {
        // Then, it opens modal to create a new PO
        handleManageProductModal(c);
      }
      return;
    }

    if (!roleCid) {
      return;
    }

    // create PO with PPH or SE
    if (c.payload.postprocedurehospital && c.payload.postprocedurehospital.contractId) {
      // create POs using PPH
      createpo({
        contractId: c.payload.postprocedurehospital.contractId, 
        payload: c.payload, 
        ledger, 
        roleCid,
      });
    }
    else {
      // create POs using SE
      completeSurgicalEvent({
        contractId: c.contractId, 
        payload: c.payload, 
        ledger, 
        isCertificate: true,
        isAutoPO: true, 
        roleCid,
        name: userParty ? userParty : party,
        role: role,
      });
    }
  };

  // Click "Edit PO" in list
  const handleEditPO = (po) => {
    //console.log("[PurchaseOrderListContracts] handleEditPO", po);
    // open CreatePurchaseOrder with po info
    handleOpenCreatePO({
      poData: po.payload.purchaseorderdata, poDetail: po.payload.purchaseorderdetaildata,
      isCreate: false, contractId: po.contractId, payload: po.payload,
    });
  };

  // Click "Send PO" button in PO list
  const setSendPOData = (data) => {
    // console.log('[PurchaseOrderListContracts] setSendPOData', data);
    setSendSelectedPOData(data.payload.purchaseorderdata);
    setSendPoproductItemsList(data.payload.purchaseorderdetaildata);
    setContractId(data.contractId);
    setPayload(data.payload);
    setPoComment({
      ...POCommentModel,
      date: getTodayString(),
      user: userParty ? userParty : party,
    });
    setSendPOOpen(true);
    setPoHospital(data.payload.hospital);
  };

  // Click "Send" button in Send PO modal
  const handleSendPO = async (callback) => {
    await handleToSendPO({
      ledger, ContractId, poComment, setSendPoSuccess, setSendPOOpen, callback, roleCid
    });
  };

  // Click "Archive PO" in list
  const handleArchivePO = (po) => {
    //console.log('[PurchaseOrderListContracts] handleArchivePO', po);
    setContractId(po.contractId);
    setPayload(po.payload);
    setOpenWarning(true);
  };

  // Click "Yes" in "Archive Purchase Order" modal
  const handleModalYes = async () => {
    await handleToArchivePO({
      ledger, ContractId, setOpenWarning, roleCid,
      payload, 
      user: userParty ? userParty : party, 
      role,
    });
  };

  // handle to change Ownership in Product of PO
  const handleChangeOwnership = async (ownership, product) => {

    dispatch(setToggleStage(product.id));
    console.log("[PurchaseOrderListContracts] handleChangeOwnership id of product", product.id);

    await handleToChangeOwnership({
      ledger, hospital, setContractId, 
      roleCid,
      contracts: contracts,
      ownership, product,
      podetailindex: product.index,
      payload: product.payload, 
      user: userParty ? userParty : party, 
      role,
    });
  };

  // handle to change Price in Product of PO
  const handleChangePrice = async (price, product) => {
    await handleToChangePrice({
      ledger, price, product, 
      roleCid,
      podetailindex: product.index,
      payload: product.payload, 
      user: userParty ? userParty : party, 
      role,
    });
  };

  // Click "Delete Product" in list
  const handleDeleteSubProduct = (product) => {
    // console.log("[PurchaseOrderListContracts] handleDeleteSubProduct", product);
    setDeleteProductData(product);
    setContractId(product.contractId);
    setPayload(product.payload);
    setOpenDeleteModal(true);
  };

  // handler to delete product of a PO in Delete Product Modal
  const handleDeleteProductItem = async () => {
    await handleToDeleteProductItem({
      ledger, ContractId, deleteProductData, setOpenDeleteModal, 
      roleCid,
      podetailindex: deleteProductData.index,
      payload, 
      user: userParty ? userParty : party, 
      role,
    });
  };

  const handleSecRequest = () => {
    setOpenDeleteModal(false);
  };
  const setSendPoSuccessRequest = () => {
    setSendPoSuccess(false);
  };
  const handleSecRequestWarning = () => {
    setOpenWarning(false);
  };


  // handler to Archive SEID
  const handleArchiveSE = (c) => {
    setOpenWarningSE(true);
    setConfirmModalContract(c);
  };

  // handle to click "Yes" in Archive SE Modal
  const handleModalYesSE = async () => {
    await archiveSurgicalEvent({
      contractId: confirmModalContract.contractId, 
      payload: confirmModalContract.payload, 
      ledger, roleCid,
    });
    setOpenWarningSE(false);
  };

  // check to changed Price
  const checkChangedPrice = (c) => {
    // console.log("[PurchaseOrderListContracts] checkChangedPrice", c);
    return !!c.pricechange;
  };

  // check if a SE has one or more rejected PO.
  const checkRejected = (c) => {
    // console.log("[PurchaseOrderListContracts] checkRejected", c.payload.purchaseorders);
    let isRejected = false;
    if (c.payload.purchaseorders.length > 0) {
      for (let i = 0; i < c.payload.purchaseorders.length; i++) {
        if (c.payload.purchaseorders[i].payload.purchaseorderdata.postatus === 'Rejected') {
          isRejected = true;
          break;
        }
      }
    }
    return isRejected;
  };
  
  const getColorCss = (value)=>{
      switch (value) {
        case 'Accepted':
          return classes.backGreen;
        case 'Rejected':
          return classes.backRed;
        case 'MissingInfo':
          return classes.backYellow;
        case 'InvoiceCreated':
          return classes.backPurple;
        case 'SentVendor':
        case 'SentUpdate':
          return classes.backPink;
        default:
          return classes.backBlue;
      }
  }


  const getPoInventryStatusColorCss = (value)=>{
    switch (value) {
      case 'AllReceived':
        return classes.backGreen;
      case 'SomeReceived':
        return classes.backYellow;
      case 'AllShipped':
        return classes.backBlue;
      case 'SomeShipped':
        return classes.backPink;
      default:
        return classes.backRed;
    }
}

  return (
    <>
      <div key="formChildStep" className="formsteps" >
        <TreeContracts
          className={classes.customRoot}
          contracts={newContracts}
          columns={[
            // ["ContractId", "contractId"],
            ["SE Id", "payload.surgicaleventdata.seid", {
              type: "link",
              onClick: (contractId, contract) => {
                const clickedContractId = (!!contractId) ? contractId : contract.contractId;
                // const data = (!!contract) ? contract : contractsSE.find(c => c.contractId === clickedContractId);
                const data = contractsSE.find(c => c.contractId === clickedContractId);
               // console.log("[PurchaseOrderListContracts] click", data);
                setSEData(data.payload.surgicaleventdata);
                setPoList(data.payload.purchaseorders);
                setSEState(true);
              },
              search: {
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.surgicaleventdata.seid}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'seid')}
                    defaultValue={searchPO.seid}
                    label="Search SE ID"
                    contracts={allContractsSurgical}
                    isShrink={true}
                  />
              },
              isSearched: searchPO.seid,
              handleClear: () => handleChangeSearchValue('', 'seid'),
              options: {
                cellClassName: classNames(classes.primaryColor, classes.staticCell),
                headerClassName: classes.staticCell,
                width: 120
              }
            }],
            ["MRN", "payload.surgicaleventdata.mrn", {
              search: {
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.surgicaleventdata.mrn}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'mrn')}
                    defaultValue={searchPO.mrn}
                    label="Search MRN"
                    contracts={allContractsSurgical}
                    isShrink={true}
                  />
              },
              isSearched: searchPO.mrn,
              handleClear: () => handleChangeSearchValue('', 'mrn'),
              options: {
                width: 120
              }
            }],
            ["Event Date", "payload.surgicaleventdata.eventdate", {
              search: {
                component:
                  <DateSearch
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'eventdate')}
                    defaultValue={searchPO.eventdate}
                    label="Search Event Date :"
                    isStatic={true}
                  />
              },
              isSearched: searchPO.eventdate,
              handleClear: () => handleChangeSearchValue('', 'eventdate'),
              options: {
                width: 120
              }
            }],
            ["Procedure", "payload.surgicaleventdata.surgicalprocedure"],
            /* ["Products", "payload.surgicaleventproductdata", {
              type: "object",
              options: {
                type: 'number',
                headerClassName: classes.centerCell,
                cellClassName: classes.centerCell,
              },
            }], */
            ["Products", "payload.sumofpoproducts", {
              options: {
                headerClassName: classes.centerCell,
                cellClassName: classes.centerCell,
              },
            }],
            ["Status", "payload.surgicaleventdata.status", {
              type: "render",
              render: (param) => (<Chip
                label={getSEPOStatus(param.row).label}
                size="small"
                className={classNames(classes.smallChip, getSEPOStatus(param.row, classes).color)}
              />)
            }],
            ["PO Details", "payload.purchaseorders", {
              type: "collapse",
              columns: [
                ["PO Number", "payload.purchaseorderdata.popurchaseorderid", {
                  type: 'link',
                  onClick: (contractId, contract) => {
                    const data = (!!contract) ? contract : contracts.find(c => c.contractId === contractId);
                    // console.log("[PurchaseOrderListContracts] click", data);
                    setPOData(data.payload.purchaseorderdata);
                    setSEData(data.surgicaleventdata);
                    setProductList(data.payload.purchaseorderdetaildata);
                    setCommentList((data.payload.purchaseordercomment));
                    setDetailViewer(true);
                  },
                  search: {
                    component:
                      <AdaptiveSearch
                        getValue={(c) => c.payload.purchaseorderdata.popurchaseorderid}
                        handleChangeSearch={(v) => handleChangeSearchValue(v, 'popurchaseorderid')}
                        defaultValue={searchPO.popurchaseorderid}
                        label="Search PO Number"
                        contracts={allContracts}
                        isShrink={true}
                      />
                  },
                  isSearched: searchPO.popurchaseorderid,
                  handleClear: () => handleChangeSearchValue('', 'popurchaseorderid'),
                  options: {
                    width: 120,
                    cellClassName: classes.nobreak
                  }
                }],
                ["PO Date", "payload.purchaseorderdata.podate", {
                  search: {
                    component:
                      <DateSearch
                        handleChangeSearch={(v) => handleChangeSearchValue(v, 'podate')}
                        defaultValue={searchPO.podate}
                        label="Search PO Date :"
                        isStatic={true}
                      />
                  },
                  isSearched: searchPO.podate,
                  handleClear: () => handleChangeSearchValue('', 'podate'),
                  options: {
                    width: 120,
                  }
                }],
                ["PO Status", "payload.purchaseorderdata.postatus", {
                  type: "render",
                  render: (param) => (POHStatus[param.value] && <Chip
                    label={POHStatus[param.value]}
                    size="small"
                    className={classNames(classes.smallChip, getColorCss(param.value))}
                  />),
                  search: {
                    component:
                      <SelectSearch
                        handleChangeSearch={(v) => handleChangeSearchValue(v, 'postatus')}
                        label="Search Status"
                        options={Object.keys(POHStatus).map(value => ({ label: POHStatus[value], value: value }))}
                        defaultValue={searchPO.postatus}
                        isStatic={true}
                      />
                  },
                  isSearched: searchPO.postatus,
                  handleClear: () => handleChangeSearchValue('', 'postatus'),
                  options: {
                    width: 130
                  }
                }],
                ["Product Status", "payload.purchaseorderdata.poinventorystatus", {
                  type: "render",
                  render: (param) => (InventoryPOStatusHospital[param.value] && <Chip
                    label={InventoryPOStatusHospital[param.value]}
                    size="small"
                    className={classNames(classes.smallChip,
                      getPoInventryStatusColorCss(param.value)
                    )}
                  />),
                  search: {
                    component:
                      <SelectSearch
                        handleChangeSearch={(v) => handleChangeSearchValue(v, 'poinventorystatus')}
                        label="Search Status"
                        options={Object.keys(InventoryPOStatusHospital).map(value => ({ label: InventoryPOStatusHospital[value], value: value }))}
                        defaultValue={searchParams.poinventorystatus}
                        isStatic={true}
                      />
                  },
                  isSearched: searchParams.poinventorystatus,
                  handleClear: () => handleChangeSearchValue('', 'poinventorystatus'),
                  options: {
                    width: 160
                  }
                }],
                
                ["Manufacturer", "payload.purchaseorderdetaildata.0.podproduct.sepproductmanufacturer", {
                  options: {
                    width: 175,
                  }
                }],
                ["Distributor", "payload.purchaseorderdata.povendorid", {
                  search: {
                    component:
                      <AdaptiveSearch
                        getValue={(c) => c.payload.purchaseorderdata.povendorid}
                        handleChangeSearch={(v) => handleChangeSearchValue(v, 'povendorid')}
                        defaultValue={searchPO.povendorid}
                        label="Search Distributor"
                        contracts={allContracts}
                        isShrink={true}
                      />
                  },
                  isSearched: searchPO.povendorid,
                  handleClear: () => handleChangeSearchValue('', 'povendorid'),
                  options: {
                    width: 175,
                  }
                }],
                ["Ownership", "payload.purchaseorderdata.popurchaseordertype"],
                ["Products", "payload.purchaseorderdata.purchaseorderdetaildataproducts", {
                  options: {
                    headerClassName: classes.centerCell,
                    cellClassName: classes.centerCell,
                  },
                }],
                ["Total Amount", "payload.purchaseorderdata.pototalamount", {
                  options: {
                    beforeText: '$',
                    width: 175,
                  }
                }],
                ["PO Lines", "payload.purchaseorderdetaildata", {
                  type: "collapse",
                  columns: [
                    ["Reference Number", "podproduct.sepreferencenumber", {
                      options: {
                        width: 150,
                      }
                    }],
                    ["Product Name", "podproduct.sepproductname", {
                      options: {
                        width: 150,
                        tooltip: true
                      }
                    }],
                    ["Product Description", "podproduct.sepproductdescription", {
                      options: {
                        width: 175,
                        tooltip: true
                      }
                    }],
                    ["Quantity", "podquantity", {
                      // type: "render",
                      // render: (param) => (
                      //   <>
                      //     {parseInt(param.value)}
                      //   </>
                      // ),
                      options: {
                        headerClassName: classes.centerCell,
                        cellClassName: classes.centerCell,
                      },
                    }],
                    ["Price", "podunitprice", {
                      type: "render",
                      render: (param) => (
                        <>
                          <span className={checkChangedPrice(param.row) ? classes.changePrice : null}>
                            {param.value !== "" && <>$</>}
                            {param.value}
                          </span>
                          {
                            isEnableChangeOwnership(param.row.purchaseorderdata.postatus)
                              && param.row.purchaseorderdata.potype !== "NoCharge"
                              ?
                              <EditPrice
                                priceValue={param.value}
                                contract={param.row}
                                setPriceValue={handleChangePrice}
                              />
                              : null
                          }
                        </>
                      ),
                      options: {
                        width: 100,
                        cellClassName: classes.editPriceItem //Price change color based on condition
                      }
                    }],
                    ["Lot Code", "podproduct.seplotcode", {
                      options: {
                        headerClassName: classes.minWdt130
                      }
                    }],
                    ["Product Type", "podproduct.sepproducttype", {
                      options: {
                        headerClassName: classes.minWdt130
                      }
                    }],
                    /* ["Product Waste", "podproduct.sepproductwaste", {
                      options: {
                        headerClassName: classes.minWdt130
                      }
                    }], */
                    ["Ownership", "podproduct.sepownership", {
                      type: "render",
                      render: (param) => (
                        <div>
                          {
                            isEnableChangeOwnership(param.row.purchaseorderdata.postatus)
                              ?
                              <FormControl variant="outlined">
                                <Select
                                  value={param.value}
                                  onChange={(e) => handleChangeOwnership(e.target.value, param.row)}>
                                  {getOwnerships(null, ['BillOnly'])}
                                </Select>
                              </FormControl>
                              :
                              param.value
                          }
                        </div>),
                    }],
                  ],
                  actions: [
                    [
                      "Action",
                      [
                        [["Delete", [<img alt="" src={deleteIcon} />, <img alt="" src={deleteDisableIcon} />],
                          (product) => isEnableDeleteSubProduct(product.purchaseorderdata.postatus)],
                          handleDeleteSubProduct],
                      ],
                    ],
                  ],
                }],
              ],
              actions: [
                [
                  "Actions",
                  [
                    [["Send PO", [<img alt="" src={sendIcon} />, <img alt="" src={sendDisableIcon} />],
                      (c) => isEnableSendVendor(c.payload.purchaseorderdata.postatus) && checkNotPOHistory(c)
                        && c.payload.purchaseorderdetaildata.length > 0],
                      setSendPOData],
                    [["Edit PO", [<img alt="" src={editIcon} />, <img alt="" src={editDisableIcon} />],
                      (c) => isEnableEdit(c.payload.purchaseorderdata.postatus) && checkNotPOHistory(c)],
                      handleEditPO],
                    [["Archive PO", [<img alt="" src={deleteIcon} />, <img alt="" src={deleteDisableIcon} />],
                      (c) => isEnableArchivePurchaseOrder(c)],
                      handleArchivePO],
                  ],
                ]
              ],
              options: {
                renderHeading: (
                  <Button
                    className={classes.newSetupButton}
                    variant="contained"
                    color="primary"
                    onClick={(e, c) => {
                      // get selected contract(parentContract of TreeContracts)
                      // console.log("[PurchaseOrderListContracts] clicked", c);
                      handleManageProductModal(c);
                    }}
                  >
                    <span className="desktop"> Add PO</span>
                    <span className="mobile"><AddIcon /></span>
                  </Button>
                ),
                sortKey: 'payload.created_at',
              },
            }],
          ]}

          actions={[
            [
              "Actions",
              [
                [["Create PO", [<img alt="" src={filePlusIcon} className="toggleTree" />,
                <img alt="" src={filePlusDisableIcon} className="toggleTree" />],
                  (c) => (c.payload.purchaseorders.length === 0)],
                  handleCreatePOProduct,
                ],
                [["Archive", [<img alt="" src={archiveIcon} />, <img alt="" src={archiveDisableIcon} />],
                  checkEnableArchiveSE],
                  handleArchiveSE,
                ],
              ]
            ]
          ]}
          options={{
            statusEmpty: (allContracts.length === 0),
            extraIcon: slashIcon,
            checkExtraIcon: checkRejected,
          }}
        />

        <SendPO
          openModal={sendPOOpen}
          setSendPOOpen={setSendPOOpen}
          handleSendPO={handleSendPO}
          pohospital={poHospital}
          selectedPOData={sendSelectedPOData}
          productItemsList={sendPoproductItemsList}
          pocommentdata={poComment}
          setConductFormComment={setPoComment}
        />

        <ModalDialogBox
          open={sendPoSuccess}
          setOpen={setSendPoSuccess}
          heading={"Successfully Sent PO"}
          type="success"
          text={'<p>You have successfully Sent the Purchase Order to Distributor, You can Track your Purchase Order Status on this page.</p>'}
          handleRequest={setSendPoSuccessRequest}
          firstBtn={"Ok"}
        />

        <ModalDialogBox
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          heading={"Delete Product?"} 
          type="warning"
          text={`<p>This product will be deleted from under the PO
            (${deleteProductData.purchaseorderdata && deleteProductData.purchaseorderdata.popurchaseorderid}). 
            You will not be able to Recover it.</p>`}
          handleRequest={handleDeleteProductItem}
          secondBtn={'No'}
          handleSecRequest={handleSecRequest}
        />

        <SEDetailViewDrawer
          state={sestate}
          toggleDrawer={(open) => setSEState(open)}
          selectedEventData={seData}
          poList={poList}
          isPPH={true}
        />

        <DetailViewDrawer
          state={detailViewer}
          toggleDrawer={(open) => setDetailViewer(open)}
          selectedPOData={poData}
          selectedSEData={seData}
          productItemsList={productList}
          commentsList={commentList}
        />

        <ModalDialogBox
          open={openWarning}
          setOpen={setOpenWarning}
          heading={"Archive Purchase Order?"}
          type="warning"
          text={'<p>You Will Not Be Able to Perform Any Action on an Archived Po.</p>'}
          handleRequest={handleModalYes}
          secondBtn={'No'}
          handleSecRequest={handleSecRequestWarning}
        />

        <ModalDialogBox
          open={openWarningSE}
          setOpen={setOpenWarningSE}
          heading="Archive the Surgical Event?"
          type="warning"
          // text={'<p> You will not be able to Recover it.</p>'}
          text={'<p>Archiving  the Surgical Event on this page will impact Surgical Events, Purchase Order and Restock Inventory Records.  Please be aware that you will not be able to recover from this.</p><p>In the future,  to display Archived Contract Information, please check the “Include Archived Contracts” checkmark.</p>'}
          handleRequest={handleModalYesSE}
          secondBtn={'No'}
          handleSecRequest={() => setOpenWarningSE(false)}
        />

      </div>
    </>
  );
}

export default withLoading(PurchaseOrderListContracts);

/**
 * "Send" in Send PO
 * @param {Object} ledger
 * @param {String} ContractId
 * @param {String} poComment
 * @param {Function} setSendPoSuccess
 * @param {Function} setSendPOOpen
 * @param {Function} callback
 * @returns {Boolean}
 * @param {String} userlistPayload
 */
export const handleToSendPO = async ({
  ledger, ContractId, poComment, setSendPoSuccess, setSendPOOpen, callback, roleCid,userlistPayload
}) => {
   const email = userlistPayload?.email;
   const username = userlistPayload?.name;
  
  // send PO to Vendor
  const result = await SendPov({
    pocomment: poComment,
    ledger, 
    ContractId, roleCid,email,username
  });

  if (typeof callback === 'function') callback(() => {
    if (typeof setSendPoSuccess === 'function' && !!result) setSendPoSuccess(true);
    if (typeof setSendPOOpen === 'function') setSendPOOpen(false);
    return result;
  });
  else {
    if (typeof setSendPoSuccess === 'function' && !!result) setSendPoSuccess(true);
    if (typeof setSendPOOpen === 'function') setSendPOOpen(false);
  }

  return result;
};

/**
 * "Yes" in Archive PO
 */
export const handleToArchivePO = async ({
  ledger, ContractId, setOpenWarning, roleCid, payload, user, role,
}) => {
 // console.log("[handleToArchivePO]", ContractId);
  // archive PO
  await ArchivePo({
    ledger, cid: ContractId, roleCid, payload, user, role,
  });
  setOpenWarning(false);
};

/**
 * find out target PO with ownership
 */
export const findTargetPOWithOwnership = (newpodata, contracts) => {
  // check if there is same PO
  // Question1)
  // PurchaseOrder & PurchaseOrderHistory with same seid, vendor, ownership
  // Question2)
  // same PurchaseOrder is not able to update (e.g. sentVendor)
  // Question3)
  // Which status can archive PO?
  // same PO should be enable to update
  // Old version - seid shouldn't be empty
  /* const samePO = !!newpodata.poseid ? contracts.find(c => (
    c.payload.purchaseorderdata.poseid === newpodata.poseid
    && c.payload.purchaseorderdata.povendorid === newpodata.povendorid
    && c.payload.purchaseorderdata.popurchaseordertype === newpodata.popurchaseordertype
    && isEnableEdit(c.payload.purchaseorderdata.postatus)
  )) : null; */
  // New version - seid can be null
  // For new version, DAML choice(ChangeOwnership) must be updated
  const samePO = contracts.find(c => (
    c.payload.purchaseorderdata.poseid === newpodata.poseid
    && c.payload.purchaseorderdata.povendorid === newpodata.povendorid
    && c.payload.purchaseorderdata.popurchaseordertype === newpodata.popurchaseordertype
    && isEnableEdit(c.payload.purchaseorderdata.postatus)
  ));
  return samePO;
};

/**
 * change Ownership in Product of PO
 */
export const handleToChangeOwnership = async ({
  ledger, contracts, hospital, setContractId,
  ownership, product, roleCid, podetailindex, payload, user, role,
}) => {
  // console.log("[handleToChangeOwnership]", ownership, product);

  // check if enable to edit PO
  if (!isEnableChangeOwnership(product.purchaseorderdata.postatus)) return;

  const oldContractId = product.contractId;
  if (typeof setContractId === 'function') setContractId(oldContractId);

  // change ownership of product
  if (oldContractId) {
    // find target PO
    const samePO = findTargetPOWithOwnership({
      ...product.purchaseorderdata,
      popurchaseordertype: ownership,
    }, contracts);
    //console.log("[handleToChangeOwnership]", samePO);

    // check remain purchaseorderdetaildatal count
    const podetailcount = product.purchaseorderdetaildatalength;
    console.log("[handleToChangeOwnership]", ownership, samePO, podetailcount);

    // const targetpoid = (!!samePO) ? samePO.contractId : null;
    const targetponumber = (!!samePO) ? samePO.payload.purchaseorderdata.popurchaseorderid : null;

    // add product in Same PO
    const res = await changeOwnershipDetail({
      ledger, 
      cid: oldContractId,
      newownership: ownership,
      // targetpoid: targetpoid,
      targetponumber: targetponumber,
      oldpodetaildata: product,
      roleCid,
      podetailindex,
      payload, 
      user, 
      role,
    });
    
    return res;

  }
};

/**
 * change Price in Product of PO
 */
export const handleToChangePrice = async ({
  ledger, price, product, roleCid, podetailindex, payload, user, role,
}) => {
  //console.log("[handleToChangePrice]", price, product);

  // check if enable to edit PO
  const status = product.purchaseorderdata.postatus;
  if (!isEnableChangeOwnership(status)) return;

  // change price of product
  if ('contractId' in product) {
    product.podunitprice = price;
    product.podproduct.sepproductprice = price;
    if (status === "Rejected" || status === "Updated") product.pricechange = true;

    await updatePODetailData({
      ledger,
      contractId: product.contractId,
      podetail: product, 
      roleCid,
      podetailindex,
      payload, 
      user, 
      role,
    });
  }
};

/**
 * delete product of a PO
 */
export const handleToDeleteProductItem = async ({
  ledger, ContractId, deleteProductData, setOpenDeleteModal, roleCid, podetailindex,
  payload, user, role,
}) => {
  // console.log("[handleToDeleteProductItem]", ContractId, deleteProductData);

  // const podetailcount = deleteProductData.purchaseorderdetaildatalength;
  
  // delete product of a PO
  await removePODetailData({
    ledger, 
    contractId: ContractId,
    podetail: deleteProductData, 
    roleCid,
    podetailindex,
    payload, 
    user, 
    role,
  });
    
  setOpenDeleteModal(false);
};

/**
 * check to archive PurchaseOrder
 * @param {Object} c 
 * @returns {Boolean}
 */
export const isEnableArchivePurchaseOrder = (c) => {
  // if (typeof c.payload === "undefined") return false;
  return (
      (
        isEnableArchive(c.payload.purchaseorderdata.postatus) 
        //&& isEnableArchivePO(c.payload.purchaseorderdata.poinventorystatus)
      ) 
      || c.payload.purchaseorderdetaildata.length === 0
    )
    && checkNotPOHistory(c);
  
  /* let enabled = (c.payload.surgicaleventdata.status === "Complete" || c.payload.surgicaleventdata.status === "Canceled");
  if (enabled && c.payload.purchaseorders && c.payload.purchaseorders.length) {
    for (let i in c.payload.purchaseorders) {
      let p = c.payload.purchaseorders[i];
      if (!isEnableArchive(p.payload.purchaseorderdata.postatus)) {
        enabled = false;
        break;
      }
    }
  }
  return enabled; */
};

/**
 * get PO status
 * @param {Object} c 
 * @param {Object} classes 
 * @returns {Object}
 */
export const getSEPOStatus = (c, classes={}) => {
  if (c.payload.purchaseorders.length === 0) return {label: "Pending PO", color: classes.backYellow};
  if (checkEnableArchiveSE(c)) return {label: "PO's Closed", color: classes.backPink};
  if (c.payload.purchaseorders.length === 1) {
    for (let i in c.payload.purchaseorders) {
      let p = c.payload.purchaseorders[i];
      if(p.payload.purchaseorderdata.postatus==="Accepted" && p.payload.purchaseorderdata.popurchaseordertype==="Loaned"){
        return {label: "PO's Closed", color: classes.backPink};
      }
    }
  }
  for (let i in c.payload.purchaseorders) {
    let p = c.payload.purchaseorders[i];
    if (!isEnableEdit(p.payload.purchaseorderdata.postatus)) {
      return {label: "Active PO", color: classes.backRed};
    }
  }
  return {label: "Created PO", color: classes.backGreen};
};
