import React, { useState, useEffect, useCallback } from "react";
import { useStreamQueries, useParty, useLedger } from "@daml/react";
import { Typography, CircularProgress } from '@material-ui/core';
import { Edit } from "@material-ui/icons";
import { VendorInvitation, /* VendorRole */ } from "../../services/daml-modules1";
import { useDamlState } from "../../context/DamlContext";
import Contracts from "../../components/Contracts/Contracts";
import { useHistory } from "react-router-dom";
import { VendorDetailModel, validateVendorDetail, getVendorDetail } from '../../models/VendorDetail';
import { AdminVendorDetailModel, validateAdminVendorDetail, getAdminVendorDetail } from '../../models/AdminVendorDetail';
import AlertModal from "../../components/Modals/AlertModal";
import MultiTabsModals from "../../components/Modals/MultiTabsModals";


/**
 * Vendor Invite Page
 * TODO
 * create new modal to make AdminVendorDetail
 * @returns 
 */
export default function VendorInvite() {

  const history = useHistory();

  const vendor = useParty();
  const ledger = useLedger();
  const assets = useStreamQueries(VendorInvitation, () => [{ party: vendor }]);
  // const assetsVendorRole = useStreamQueries(VendorRole, () => [{ vendor }]);
  const { roleCid } = useDamlState();

  console.log("[VendorInvite]", vendor, assets);


  const [conductModalOpen, setConductModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [curContractId, setContractId] = useState('');
  const [curContract, setContract] = useState({});
  const [detailChange, setDetailChange] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [onedetails, setConductForm] = useState({ ...VendorDetailModel });
  const [admindetails, setAdminConductForm] = useState({ ...AdminVendorDetailModel });
  // const [alias] = useState({
  //   alias: ''
  // });

  const checkOneDetailCompleted = useCallback(() => {
    return validateVendorDetail(onedetails) && validateAdminVendorDetail(admindetails);
  }, [onedetails, admindetails]);


  const handleConductModalClose = () => {
    setConductModalOpen(false);
  };


  const handleConductChange = (idx, data) => {
    setDetailChange(true);
    if (idx === "vendordetails") {
      setConductForm({
        ...onedetails,
        ...data
      });
    }
    else if (idx === "admindetails") {
      setAdminConductForm({
        ...admindetails,
        ...data
      });
    }
  };

  const exerciseVendorAccept = useCallback(() => {
    setConductModalOpen(false);

    console.log("vendor : " + vendor);
    console.log("cid: " + curContractId);

    const onedetailParams = getVendorDetail(onedetails);
    console.log("onedetails: ", onedetails, JSON.stringify(onedetailParams));

    const admindetailParams = getAdminVendorDetail(admindetails);
    console.log("admindetails: ", admindetails, JSON.stringify(admindetailParams));

    if (!checkOneDetailCompleted()) {
      alert("You must input all fields.");
      return;
    }
    // console.log("alias" + JSON.stringify(alias));

    const successResponseHandler = (res) => {
      console.log(res);
      // dispatch(conductclick({
      //   vendor,
      //   contractId: res[0]

      // }));
      setConfirmModalOpen(true);
    };

    ledger.exercise(VendorInvitation.AcceptVendorInvitation, curContractId, {
      // vendor, 
      vendordetails: onedetailParams,
      admindetails: admindetailParams,
    })
      .then((res) => {
        successResponseHandler(res);
      })
      .catch(events => {
        if (events.status === 200) {
          successResponseHandler(events);
          return;
        }

        let message = '';

        console.log(events);
        if (typeof events.errors === "object" && events.errors.length > 0)
          events.errors.forEach(msg => {
            console.log(msg)
            message += msg;
            message += '\n';
          });
        else
          message = JSON.stringify(events);
        alert("Something went wrong!\n\nMessage: " + message);

        return false;
      });

  }, [curContractId, /* dispatch, */ ledger, onedetails, admindetails, vendor, checkOneDetailCompleted]);


  const handleConfirmModal = () => {
    setConfirmModalOpen(false);
    history.push("/app/welcome");
  };

  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };


  useEffect(() => {
    if (detailChange === true) {
      exerciseVendorAccept();
    }
    setDetailChange(false);
  }, [onedetails, detailChange, exerciseVendorAccept]);


  return (
    !assets.contracts || !assets.contracts.length
      ?
      <div>
        <CircularProgress />
        <Typography variant="h4">Please wait until accept your AccountRequested.</Typography>
      </div>
      :
      (
        roleCid
          ?
          <div>
            <Contracts
              contracts={assets.contracts}

              columns={[
                ["ContractId", "contractId"],
                ["Operator", "payload.operator"],
                ["Party", "payload.party"],
                ["Actions", "actions-created", {
                  type: "link",
                  onClick: () => history.push('/app/viewvendordata'),
                  defaultValue: "Created",
                }],
              ]}
              options={{
                // statusEmpty: (allContracts.length === 0)
              }}
            />
          </div>
          :
          <div>
            <Contracts
              contracts={assets.contracts}

              columns={[
                ["ContractId", "contractId"],
                ["Operator", "payload.operator"],
                ["Party", "payload.party"],
              ]}

              actions={[
                [
                  "Actions",
                  [
                    [["Edit", <Edit />], (c) => {
                      setOpenModal(true);
                      setContract(c);
                      setContractId(c.contractId);
                    }],
                  ]
                ]
              ]}
              options={{
                // statusEmpty: (allContracts.length === 0)
              }}
            />

            <div>

              <MultiTabsModals
                modaltitle={"Edit Vendor Detail"}
                modals={[
                  ["customdialog", "Vendor Name", "payload.vendordetails.vendorname", "vendorname"],
                  ["customdialog", "Division Name", "payload.vendordetails.vendordivision", "vendordivision"],
                  ["addressdialog", "Vendor Address", "payload.vendordetails.vendoraddress", "vendoraddress"],
                  ["contactdialog", "Vendor Contact", "payload.vendordetails.vendorcontact", "vendorcontact", "list"],
                  ["addressdialog", "Vendor Contact Address", "payload.vendordetails.vendorcontactaddress", "vendorcontactaddress"],
                  ["customdialog", "TAX ID", "payload.vendordetails.taxid", "taxid", "number"],
                  ["contactdialog", "Remit Contact", "payload.vendordetails.remittocontact", "remittocontact", "list"],
                  ["addressdialog", "Remit Contact Address", "payload.vendordetails.remittoaddress", "remittoaddress"],
                  ["contactdialog", "Customer Service Contact", "payload.vendordetails.customerservicecontact", "customerservicecontact", "list", "customerservice"],
                  ["addressdialog", "Customer Service Contact Address", "payload.vendordetails.customerservicedaddress", "customerservicedaddress"],
                  ["achinfodialog", "ACH Info", "payload.vendordetails.ach_info", "ach_info"],
                ]}
                setDetailValue={(data) => { setOpenModal(false); handleConductChange("vendordetails", data); }}
                isButtonVisible={false}
                isDefaultVisible={openModal}
                defaultDetails={curContract}
                cancelHandler={() => setOpenModal(false)}
              />

              <AlertModal
                modaltitle="Vendor Registration"
                modalText="Do you want to register this vendor?"
                setDetailValue={exerciseVendorAccept}
                modalOpenController={conductModalOpen}
                cancelHandler={handleConductModalClose}
                isButtonVisible={false}
                okButtontitle="Register"
              />

              <AlertModal
                modaltitle="Success to store Vendor"
                modalText="Vendor information is correctly stored on the Ledger."
                setDetailValue={handleConfirmModal}
                modalOpenController={confirmModalOpen}
                cancelHandler={closeConfirmModal}
                isButtonVisible={false}
              />

            </div>
          </div>
      )
  )
}
