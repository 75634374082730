import React from "react";
import { DialogContentText, Dialog, DialogContent, Button, DialogTitle, DialogActions } from "@material-ui/core";
import { Close } from '@material-ui/icons';
import { useLayoutDispatch, setLoading, unsetLoading } from "../../../context/LayoutContext";
import { isAsync } from "../../../services/util";
import successIcon from "../../../icons/check-circle.svg";
import alertIcon from "../../../icons/alert-triangle.svg";
import alertRedIcon from "../../../icons/alert-triangle-red.svg";
import useStyles from "../../../pages/surgicalevent/styles";

/**
 * General Modal Dialog
 * @param {Boolean} open 
 * @param {Function} setOpen 
 * @param {String} type 
 * @param {String} heading Warning text in title of dialog
 * @param {String} text HTML content in body of dialog content
 * @param {String} firstBtn 
 * @param {String} secondBtn 
 * @param {Function} handleRequest 
 * @param {Function} handleSecRequest 
 * @param {Boolean} isRedIcon
 * @returns 
 */
export default function ModalDialogBox ({
  open, setOpen, type="success", heading="Warning", text="", firstBtn = 'Yes', secondBtn='',
  handleRequest=()=>{}, handleSecRequest=()=>{}, isRedIcon=false,
}) {
  
  const classes = useStyles();

  const layoutDispatch = useLayoutDispatch();


  // handlers to click first button
  const handleFirstBtnClicked = async (event) => {
    if (typeof handleSecRequest === 'function') {
      if (isAsync(handleRequest)) {
        // spinner
        setLoading(layoutDispatch);
        await handleRequest(event);
        unsetLoading(layoutDispatch);
      }
      else {
        handleRequest(event);
        unsetLoading(layoutDispatch);
      }
    }
  };

  // handlers to click second button
  const handleSecondBtnClicked = async (event) => {
    if (typeof handleSecRequest === 'function') {
      if (isAsync(handleSecRequest)) {
        // spinner
        setLoading(layoutDispatch);
        await handleSecRequest(event);
        unsetLoading(layoutDispatch);
      }
      else {
        handleSecRequest(event);
        unsetLoading(layoutDispatch);
      }
    }
  };


  return (
    <Dialog
      open={open}
      className={classes.customRoot}
      style={{textAlign: 'center'}}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle className={classes.dialogTitle} style={{ cursor: 'move' }} id="draggable-dialog-title1">
        <div className="customClose" onClick={()=>{setOpen(false)}}><Close /></div>
        <div className="alert-icon">
          <img alt="" src={ type === "success" ? successIcon : (isRedIcon ? alertRedIcon : alertIcon) } />
        </div>
        <span className="warningHeading">{heading}</span> 
      </DialogTitle>

      <DialogContent>
        <DialogContentText component={'div'}>

          <div dangerouslySetInnerHTML={{__html: text}} />

          <DialogActions> 
            {secondBtn !== "" &&  
              <Button autoFocus className="noBtn" variant="outlined" onClick={handleSecondBtnClicked} color="primary">
                {secondBtn}
              </Button>  
            }
            {firstBtn !== "" &&  
              <Button className="yesBtn" variant="contained" color="primary" onClick={handleFirstBtnClicked}>
                {firstBtn}
              </Button>
            }
          </DialogActions>
        </DialogContentText>
      </DialogContent>
      
    </Dialog>
  );
}
  