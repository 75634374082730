import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useParty, useStreamQueries } from "@daml/react";
import { SurgicalEventHistory } from "../../services/daml-modules1";
import { dispatch, getState } from "../../store";
import { surgicalEventHistorySearch } from "../../store/actions/generalActions";
import SurgicalEventHistoryListContracts from "./surgicaleventhistorylistcontracts";
import { searchQueryTimestamp } from "../../services/util";
import useStyles from "./styles";


export default function SurgicalEventHistoryList() {


  const classes = useStyles();
  const hospital = useParty();


  const { contracts } = useStreamQueries(SurgicalEventHistory, () => [{ hospital }]);

  console.log("[SurgicalEventHistoryList]", hospital, contracts.contracts);

  const [searchParams, setSearchParams] = useState({});
  const [loading, setLoading] = useState(true);


  const [searchSurgicalHistory, setSearchSurgicalHistory] = useState({
    seid: '',
    patientfirstname: '',
    patientlastname: '',
    physicianfirstname: '',
    physicianlastname: '',
    patientgender: '',
    surgicalprocedure: '',
    surgicalside: '',
    price: '',
    status: '',
    created_at: '',
    ...getState().surgicalhistory,
  });
  const handleChangeSearchValue = (val, key) => {
    setSearchSurgicalHistory({ ...searchSurgicalHistory, [key]: val });
    dispatch(surgicalEventHistorySearch({ key, value: val }));
  };


  useEffect(() => {
    setLoading(true);

    let temp = {};

    temp.hospital = hospital;
    temp.surgicalevent = { surgicaleventdata: {} };
    if (searchSurgicalHistory.seid !== "") temp.surgicalevent.surgicaleventdata.seid = searchSurgicalHistory.seid;
    if (searchSurgicalHistory.patientfirstname !== "") temp.surgicalevent.surgicaleventdata.patientfirstname = searchSurgicalHistory.patientfirstname;
    if (searchSurgicalHistory.patientlastname !== "") temp.surgicalevent.surgicaleventdata.patientlastname = searchSurgicalHistory.patientlastname;
    if (searchSurgicalHistory.physicianfirstname !== "") temp.surgicalevent.surgicaleventdata.physicianfirstname = searchSurgicalHistory.physicianfirstname;
    if (searchSurgicalHistory.physicianlastname !== "") temp.surgicalevent.surgicaleventdata.physicianlastname = searchSurgicalHistory.physicianlastname;
    if (searchSurgicalHistory.patientgender !== "") temp.surgicalevent.surgicaleventdata.patientgender = searchSurgicalHistory.patientgender;
    if (searchSurgicalHistory.surgicalprocedure !== "") temp.surgicalevent.surgicaleventdata.surgicalprocedure = searchSurgicalHistory.surgicalprocedure;
    if (searchSurgicalHistory.surgicalside !== "") temp.surgicalevent.surgicaleventdata.surgicalside = searchSurgicalHistory.surgicalside;
    if (searchSurgicalHistory.price !== "") temp.surgicalevent.surgicaleventdata.price = searchSurgicalHistory.price;
    if (searchSurgicalHistory.status !== "") temp.surgicalevent.surgicaleventdata.status = searchSurgicalHistory.status;
    if (searchSurgicalHistory.created_at !== "") temp.created_at = searchQueryTimestamp(searchSurgicalHistory.created_at);



    const delay = 0;
    if (delay > 0) setTimeout(() => {
      setSearchParams(temp);
    }, delay);
    else {
      setSearchParams(temp);
    }
  }, [searchSurgicalHistory, hospital]);

  useEffect(() => {
    setLoading(false);
  }, [searchParams]);




  return (
    <>

      <Grid>

        <Typography variant="h4" className={classes.pageTitle}>Surgical Event History</Typography>

        <SurgicalEventHistoryListContracts
          searchParams={searchParams}
          handleChangeSearchValue={handleChangeSearchValue}
          searchSurgicalHistory={searchSurgicalHistory}
          allContracts={contracts}
          loading={loading}
        />

      </Grid>

    </>
  );
}
