import { useSelector } from "react-redux";
import { getState, dispatch } from "../store";
import { searchFilters, clearFilters } from "../store/actions/generalActions";

/**
 * get search filters in redux
 * @param {String|null} key 
 * @returns {Object}
 */
export const getSearchFilters = (key=null) => {
  const values = getState().searchFilters;
  return (key === null ? values : (values ? values[key] : null));
};

/**
 * set search filters in redux
 * @param {String} key 
 * @param {any} value 
 */
export const setSearchFilters = (key, value) => {
  dispatch(searchFilters({ key, value }));
};

/**
 * clear search filters in redux
 */
export const clearSearchFilters = () => {
  dispatch(clearFilters());
};

/**
 * redux hook for Search Filters
 * @param {String|null} key 
 * @returns 
 */
const useSearchFilters = (key=null) => {
  return useSelector(state => (
    (key === null) 
    ? 
    state.searchFilters
    :
    (
      (state.searchFilters && typeof state.searchFilters === 'object') ? state.searchFilters[key] : null
    )));
};
export default useSearchFilters;
