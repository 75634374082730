import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, TextField, 
  FormControl, InputLabel, Select } from "@material-ui/core";
import { useLayoutDispatch, setLoading, unsetLoading } from "../../context/LayoutContext";
import  AddEditProduct  from "../surgicalevent/add-edit-product";
import { getCourierCompany } from "../../models/Courier";
import { requiredProduct, isEmptyProduct } from "../../models/Product";
import { createInventoryData, requiredInventoryData } from "../../models/InventoryData";
import { getTodayString } from "../../services/util";
import ImportFile from "../import_file/Importfile";
import { useDamlState } from "../../context/DamlContext";
import { getModelMenu } from "../../models/handles";
// import { onChangeNumber } from "../../components/Modals/handles";
import useStyles from "../surgicalevent/styles";


/**
 * Create Inventory Dialog in Vendor
 * Restock Inventory Page in Vendor
 * @param {Boolean} openModal
 * @param {Function} setOpenModal
 * @param {Boolean} isDisableMultiple flag to disable multiple inventory data
 * @param {Function} addProductItem
 * @param {Function} handleAddMoreProduct
 * @param {String} title
 * @param {Array} inventoryDataList
 * @param {Function} setInventoryDataList
 * @param {String} btnCreate
 * @param {Boolean} isCreateShipment
 * @param {Function} setReferenceNumber
 * @param {Function} setLotCode
 * @param {String} hospital
 * @param {Function} setHospital
 * @param {String} vendor
 */
export default function CreateInventoryVendor ({
  openModal, setOpenModal, isDisableMultiple=true,
  addProductItem, handleAddMoreProduct, title="Add Extra Inventory",
  inventoryDataList=[], setInventoryDataList, btnCreate="Create",
  isCreateShipment=false, setReferenceNumber=()=>{}, setLotCode=()=>{}, 
  hospital="", setHospital=()=>{}, vendor="",
}) {
    
  const classes = useStyles();
  
  const layoutDispatch = useLayoutDispatch();

  const [, setFilePathData] = useState({});
  const [inventoryData, setInventoryData] = useState({});
  const [inventoryError, setInventoryError] = useState({});
  const [productItems, setProductItems] = useState({});
  const [productError, setProductError] = useState({});
  const [productItemsList, setProductItemsList] = useState([]); 
  const [hospitalError, setHospitalError] = useState(""); 
  const [quantity, setQuantity] = useState("1"); 

  const defaultInventoryData = {
    iproductstatus: 'Shipped', 
    // ireceiveddate: getTodayString(),
    ishipmentdate: getTodayString(),
  };


  // Click "Create" or "Save Update"
  const addInventory = async (event) => {
    // check required fields in InventoryData
    if(isCreateShipment === true){
      inventoryData.sepdistributor = productItems.sepdistributor;
      inventoryData.iownership = productItems.sepownership;
    }
    
    let newErrors = requiredInventoryData(inventoryData);
    // to skip 'sepdistributor' in productItems
    delete newErrors.sepdistributor;
   
    console.log("[CreateInventoryVendor] addInventory", inventoryData, newErrors);
    if ((Object.keys(newErrors).length > 0)) {
      setInventoryError({ ...newErrors });
      setProductError({});
      return false;
    }

    // check hospital
    if (!hospital) {
      setHospitalError("Hospital is requied.");
      return false;
    }
    
    // check product
    if (!isEmptyProduct(productItems)) {
      // check required fields in productItems
      newErrors = requiredProduct(productItems);
      // to skip 'sepdistributor' in productItems
      delete newErrors.sepdistributor;
      console.log("[CreateInventoryVendor] addInventory", productItems, newErrors);
      if ((Object.keys(newErrors).length > 0)) {
        setProductError({ ...newErrors });
        setInventoryError({});
        return false;
      }
      
      productItems.sepdistributor = vendor;

      productItemsList.push(productItems);
      if (isCreateShipment === true) {
        setLotCode(productItems.seplotcode);
        setReferenceNumber(productItems.sepreferencenumber);
      }
      
      const newinvdata = createInventoryData({...defaultInventoryData, ...inventoryData, iproduct: productItems});
      if (isCreateShipment === true && (parseInt(quantity) > 1)) {
        inventoryDataList.push(...Array(parseInt(quantity)).fill({...newinvdata}));
      }
      else {
        inventoryDataList.push(newinvdata);
      }
    }

    setProductItems({});
    setInventoryError({});
    setProductError({});
    setHospitalError("");
    setQuantity("1");

    // spinner
    setLoading(layoutDispatch);

    await addProductItem(inventoryDataList);

    unsetLoading(layoutDispatch);
  };

  const handleCancel = () => {
    setOpenModal(false);
    unsetLoading(layoutDispatch);
  };


  useEffect(() => {
    if (openModal) {
      console.log("[CreateInventoryVendor] Open CreateInventoryVendor dialog.");
      setInventoryData({});
      setInventoryError({});
      setProductItems({});
      setProductError({});
      setProductItemsList(inventoryDataList.map(p => p.iproduct));
      setHospitalError("");
      setQuantity("1");
    }
  }, [openModal, inventoryDataList]);
 

  return (
    <>

      <Dialog
        open={openModal}
        className={classes.customRoot}
        aria-labelledby="draggable-dialog-title"
        classes={{paper: classes.overflowHidden}}
      >
        <div className={classes.purchaseContentModal}> 
          <DialogTitle style={{ cursor: 'move', borderBottom: '1px solid #cccccc' }} id="draggable-dialog-title">
            <div className="view-heading">{title}</div> 
          </DialogTitle>

          <DialogContent className={classes.poContentModal}>
            <DialogContentText  component={'div'}>
            <div className={classes.marginBM20}></div> 
              <div className="poModel"> 
              
                {
                  isCreateShipment &&
                  <>
                    <div className={classes.marginBM30}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputHospital
                          isHospitalRelation={true}
                          hospital={hospital}
                          error={hospitalError}
                          setHospital={(value) => {
                            setHospital(value);
                          }}
                          label="Hospital"
                          placeholder="e.g. FirstHealth"
                        />
                      </FormControl> 
                    </div>
                  </>
                }
              
                <AddEditProduct
                  isCreate = {true}
                  productItemsList ={productItemsList}
                  productItems = {productItems}
                  productError= {productError}
                  setProductError={setProductError}
                  setProductItems = {setProductItems}
                  hospital={hospital}
                  options={{
                    isEnableEditProduct: false,
                    isEnableDeleteProduct: false,
                    edit: {
                      isEnableCompliance: false,
                      isInventory: true,
                      hiddenParams: ['sepdistributor', 'sepproductwaste']
                    },
                  }}
                />

                {
                  isCreateShipment && !isDisableMultiple &&
                  <>
                    <div className={classes.marginBM30}>
                      <TextField
                        id="input-with-icon-adornment123"
                        style={{ margin: 8 }}
                        label="Quantity"
                        placeholder=""
                        value={quantity}
                        className={classes.customFullWidth}
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => {
                          // if (onChangeNumber(e)) setQuantity(onChangeNumber(e));
                          setQuantity(e.target.value);
                        }}
                        variant="outlined"
                        type="number"
                      />
                    </div>
                  </>
                }
                <div className={classes.marginBM30}>
                  <FormControl variant="outlined" className={classes.formControl}>     
                    <TextField
                      id="shipmentdate"
                      label="Shipment Date"
                      type="date"
                      className={classes.datehalfWidth}
                      value={inventoryData.ishipmentdate || ""}
                      error={!!inventoryError.ishipmentdate}
                      helperText={inventoryError.ishipmentdate}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        setInventoryData({
                          ...inventoryData,
                          ishipmentdate: e.target.value,
                        });
                      }} 
                      variant="outlined"  
                    />
                  </FormControl> 
                </div>
                <div className={classes.marginBM30}>
                  <ImportFile 
                    setFilePathData={setFilePathData} 
                    title='Drag & Drop Packing Slip to Upload' 
                    addtionalBtn={false} 
                    subtitle={'You can upload only valid PDF here.'}
                  />
                </div>
                <div className={classes.marginBM30}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel className={classes.customLabel} shrink={true}>
                      Courier Company
                    </InputLabel>
                    <Select
                      // native
                      displayEmpty
                      className={classes.customFullWidth}
                      value={inventoryData.icourier || ""}
                      error={!!inventoryError.icourier}
                      onChange={(e) => {
                        setInventoryData({
                          ...inventoryData,
                          icourier: e.target.value,
                        });
                      }} 
                    >
                      {getCourierCompany("Select One", ['Unknown'])}
                    </Select>
                  </FormControl> 
                </div>
                <div className={classes.marginBM30}>
                  <TextField
                    id="input-with-icon-adornment12"
                    style={{ margin: 8 }}
                    label="Tracking Number"
                    placeholder="e.g. 12345"
                    value={inventoryData.itrackingstatus || ""}
                    error={!!inventoryError.itrackingstatus}
                    helperText={inventoryError.itrackingstatus}
                    className={classes.customFullWidth}
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => {
                      setInventoryData({
                        ...inventoryData,
                        itrackingstatus: e.target.value,
                      });
                    }}
                    variant="outlined"
                  />
                </div>

              </div>
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <div className="form-footer">
              <div className="right-buttons">
                <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={addInventory} >{btnCreate}</Button> 
              </div>
            </div>
          </DialogActions>
        </div>
        
      </Dialog>

    </>
  );
}

/**
 * Input Hospital Component
 * It uses DamlStream
 * @param {Boolean} isHospitalRelation 
 * @param {String} hospital 
 * @param {Function} setHospital 
 * @param {String} error 
 * @param {String} label 
 * @param {String} placeholder 
 * @returns 
 */
export function InputHospital ({
  isHospitalRelation=true, hospital="", setHospital=()=>{}, error="", 
  label="", placeholder="",
}) {
  
  const classes = useStyles();
  const { relHospitals: hospitals } = useDamlState();

  // console.log("[InputHospital] hospitals", hospitals);

  const getHospitals = getModelMenu(hospitals.reduce((a, v) => ({ ...a, [v]: v}), {}) );


  return (
    <>

      {
        isHospitalRelation
        ?
        <>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel className={classes.customLabel} shrink={true}>
              {label}
            </InputLabel>
            <Select
              // native
              displayEmpty
              className={classes.customFullWidth}
              value={hospital || ""}
              error={!!error}
              onChange={(e) => setHospital(e.target.value)} 
            >
              {
                getHospitals("Select One")
              }
            </Select>
          </FormControl> 
        </>
        :
        <TextField
          label={label}
          placeholder={placeholder}
          value={hospital || ""}
          error={!!error}
          helperText={error}
          className={classes.customFullWidth}
          margin="normal"
          InputLabelProps={{
              shrink: true,
          }}
          onChange={(e) => {
            setHospital(e.target.value);
          }}
          variant="outlined" 
        />
      }

    </>
  );
}
