// import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserDispatch, logoutAuth } from "../context/UserContext";


/**
 * Hook for Auth0 Logout
 * @param {Object} history 
 * @returns 
 */
export default function useAuthLogout({
  history,
}) {
  // global
  var userDispatch = useUserDispatch();

  const {
    isAuthenticated,
    logout,
  } = useAuth0();

  
  const handleLogout = async (event) => {
    console.log("[useAuthLogout] handleLogout", isAuthenticated);

    try {
      // Always logout of auth0
      if (true || isAuthenticated) {
        await logoutAuth({
          event,
          logout,
          userDispatch,
          history,
        });
      }
    }
    catch (e) {
      console.log("[useAuthLogout] handleLogout", e);
    }
  };


  return handleLogout;
}
