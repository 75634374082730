import React, { useState } from "react";
import classNames from "classnames";
import { Grid, Typography, Button } from "@material-ui/core";
import AuditLogListContarcts from "./auditloglistcontracts";
import AuditDetailSE from "./auditdetailSE";
import useStyles from "../surgicalevent/styles";
import { AuditLogs } from "../../services/daml-modules1";
import { useParty, useStreamQueries } from "@daml/react";
import exportIcon from "../../icons/new-export-icon.svg";
import printIcon from "../../icons/new-print-icon.svg";
import AuditDetailPO from "./auditDatailsPo";
import AuditDetailInventory from "./auditDatailsInv";
import AuditDetailPayment from "./auditDatailsPayment";
import AuditDetailReturn from "./auditDetailsReturn";
import AuditDetailsAccInv from "./auditDetailsAccInv";
import AuditDetailInvoice from "./auditlogDetailsInvoice";



export default function AuditReport() {
    const user = useParty();
    const { contracts, loading } = useStreamQueries(AuditLogs, () => [{ user: user }, { sec_user: user }]);
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openPO, setOpenPO] = useState(false);
    const [openInv, setOpenInv] = useState(false);
    const [openAuditDetailPayment, setOpenAuditDetailPayment] = useState(false);
    const [openReturn, setOpenReturn] = useState(false);
    const [openAccountInventory, setOpenAccountInventory] = useState(false);
    const [openInvoice, setOpenInvoice] = useState(false);
    const [contract, setContract] = useState({});


    const handleDetail = (c) => {
        setContract(c);
        switch (c.payload?.logEvents.tag) {
            case 'SELogEntry':

                setOpen(true);
                break;
            case 'POLogEntry':
                setOpenPO(true);
                break;
            case 'InventoryLogEntry':
                setOpenInv(true);
                break;
            case 'PaymentLogEntry':
                setOpenAuditDetailPayment(true);
                break;
                case 'InvoiceLogEntry':
                setOpenInvoice(true);
                break;
            case 'AccInventoryLogEntry':
                setOpenReturn(true);
                break;
            case "AccClsInventoryLogEntry":
                setOpenAccountInventory(true)
                break;
            default:
                break;
        }

    };

    return (
        <>
            <Grid>
                <Grid container className={classes.newContainer} justifyContent="space-between" >
                    <Grid item xs>
                        <Typography variant="h4" className={classes.newPageTitle}>Audit Log</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            className={classNames(classes.newCreateButton, classes.newIconButton)}
                            variant="contained"
                            startIcon={<img alt="" src={printIcon} />}
                        />
                        <Button
                            className={classNames(classes.newCreateButton, classes.newIconButton)}
                            variant="contained"
                            startIcon={<img alt="" src={exportIcon} />}
                        />


                    </Grid>
                </Grid>

                <AuditLogListContarcts
                    allContracts={contracts}
                    loading={loading}
                    handleDetail={handleDetail}
                />

                <AuditDetailPO
                    open={openPO}
                    setOpen={setOpenPO}
                    contract={contract}
                />

                <AuditDetailSE
                    open={open}
                    setOpen={setOpen}
                    contract={contract}
                />

                <AuditDetailInventory
                    open={openInv}
                    setOpen={setOpenInv}
                    contract={contract}
                />
                <AuditDetailPayment
                    open={openAuditDetailPayment}
                    setOpen={setOpenAuditDetailPayment}
                    contract={contract}
                />
                <AuditDetailInvoice
                    open={openInvoice}
                    setOpen={setOpenInvoice}
                    contract={contract}
                />
                <AuditDetailReturn
                    open={openReturn}
                    setOpen={setOpenReturn}
                    contract={contract}
                    />

                <AuditDetailsAccInv
                    open={openAccountInventory}
                    setOpen={setOpenAccountInventory}
                    contract={contract}
                />
            </Grid>
        </>
    );
}
