import React from "react";
import { Link, Redirect } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import Contracts from "../../components/Contracts/Contracts";
import VerticalContracts from "../../components/Contracts/VerticalContracts";
import VHContracts from "../../components/Contracts/VHContracts";
import { getState } from "../../store";
import useStyles from "./styles";


export default function PostProcedureDetailVendor({
  allContracts = [],
}) {

  const classes = useStyles();

  const contract = [getState().procedure.detail];
  console.log("[PostProcedureDetailVendor]", contract);


  /**
   * For vertical contracts, this should be 'Vertical'.
   * For horizontal contracts, this should be 'Horizontal'.
   * For horizontal-vertical contracts, this should be 'VH'.
   */
  const verticalHorizontal = 'VH';


  return (
    <>
      {
        contract.length && typeof contract[0] === "object" && 'contractId' in contract[0]
          ?
          <>

            <Button
              variant="contained"
              color="primary"
              size="small"
              component={Link}
              to="/app/vendorpostprocedurelist"
            >
              Back
            </Button>

            {
              verticalHorizontal === 'Vertical'
                ?
                <VerticalContracts
                  contracts={contract}
                  rows={[
                    // ["ContractId", "contractId"],
                    // ["MRN", "payload.mrn"],
                    ["Vendor", "payload.vendor"],
                    ["Hospital", "payload.hospital"],
                    ["Event Date", "payload.eventdate"],
                    ["Surgical Procedure", "payload.surgicalprocedure"],
                    ["Physician Salutation", "payload.physiciansalutation"],
                    ["Physician First Name", "payload.physicianfirstname"],
                    ["Physician Last Name", "payload.physicianlastname"],
                    ["surgicalside", "payload.surgicalside"],
                    ["Product Item", "payload.surgicaleventproduct"],
                    ["Price", "payload.price"],
                    ["Status", "payload.status"],
                  ]}
                  more={[
                    ["Product Item", "payload.surgicaleventproduct", {
                      rows: [
                        ["Reference Number", "sepreferencenumber"],
                        ["Product Manufacturer", "sepproductmanufacturer"],
                        ["Product Name", "sepproductname"],
                        ["Product Description", "sepproductdescription"],
                        ["Product Expiration", "sepproductexpiration"],
                        ["Product Price", "sepproductprice"],
                        ["lot Code", "seplotcode"],
                        ["Product Type", "sepproducttype"],
                        ["Ownership", "sepownership"],
                        ["UDI", "sepudi"],
                        ["Product Side", "sepproductside"],
                      ],
                    }]
                  ]}
                  options={{ collapseFixed: true }}
                />
                :
                (
                  verticalHorizontal === 'VH'
                    ?
                    <VHContracts
                      contracts={contract}
                      rows={[
                        // ["ContractId", "contractId"],
                       // ["MRN", "payload.mrn"],
                        ["Vendor", "payload.vendor"],
                        ["Hospital", "payload.hospital"],
                        ["Event Date", "payload.eventdate"],
                        ["Surgical Procedure", "payload.surgicalprocedure"],
                        ["Physician Salutation", "payload.physiciansalutation"],
                        ["Physician First Name", "payload.physicianfirstname"],
                        ["Physician Last Name", "payload.physicianlastname"],
                        ["surgicalside", "payload.surgicalside"],
                        ["Product Item", "payload.surgicaleventproduct"],
                        ["Price", "payload.price"],
                        ["Status", "payload.status"],
                      ]}
                      more={[
                        ["Product Item", "payload.surgicaleventproduct", {
                          columns: [
                            ["Reference Number", "sepreferencenumber"],
                            ["Product Manufacturer", "sepproductmanufacturer"],
                            ["Product Name", "sepproductname"],
                            ["Product Description", "sepproductdescription"],
                            ["Product Expiration", "sepproductexpiration"],
                            ["Product Price", "sepproductprice"],
                            ["lot Code", "seplotcode"],
                            ["Product Type", "sepproducttype"],
                            ["Ownership", "sepownership"],
                            ["UDI", "sepudi"],
                            ["Product Side", "sepproductside"],
                          ],
                        }]
                      ]}
                      options={{ collapseFixed: true }}
                    />
                    :
                    <Contracts
                      contracts={contract}
                      columns={[
                        // ["ContractId", "contractId"],
                      //  ["MRN", "payload.mrn"],
                        ["Vendor", "payload.vendor"],
                        ["Hospital", "payload.hospital"],
                        ["Event Date", "payload.eventdate"],
                        ["Surgical Procedure", "payload.surgicalprocedure"],
                        ["Physician Salutation", "payload.physiciansalutation"],
                        ["Physician First Name", "payload.physicianfirstname"],
                        ["Physician Last Name", "payload.physicianlastname"],
                        ["surgicalside", "payload.surgicalside"],
                        ["Product Item", "payload.surgicaleventproduct", {
                          type: "object",
                          columns: [
                            ["Reference Number", "sepreferencenumber"],
                            ["Product Manufacturer", "sepproductmanufacturer"],
                            ["Product Name", "sepproductname"],
                            ["Product Description", "sepproductdescription"],
                            ["Product Expiration", "sepproductexpiration"],
                            ["Product Price", "sepproductprice"],
                            ["lot Code", "seplotcode"],
                            ["Product Type", "sepproducttype"],
                            ["Ownership", "sepownership"],
                            ["UDI", "sepudi"],
                            ["Product Side", "sepproductside"],
                          ],
                        }],
                        ["Price", "payload.price"],
                        ["Status", "payload.status"],
                      ]}
                      options={{ collapseFixed: true , statusEmpty: (allContracts.length === 0)}}
                    />
                )
            }

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              className={classes.bottomGrid}
            >
            </Grid>

          </>
          :
          <Redirect to="/app/vendorpostprocedurelist" />
      }

    </>
  );
}
