import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  customSnackbar: {
    boxShadow: '0px 4px 4px 0px #00000040',
    '& .MuiAlert-root': {
      padding: '0px !important',
      backgroundColor: '#fff',
      border: '1px solid #CCCCCC',
      '&.MuiAlert-outlinedSuccess': {
        '& .MuiAlert-icon': {
          backgroundColor: '#4CAF5061',
        },
      },
      '&.MuiAlert-outlinedError': {
        '& .MuiAlert-icon': {
          background: 'rgba(244, 67, 54, 0.38)',
        },
      },
      '&.MuiAlert-outlinedWarning': {
        '& .MuiAlert-icon': {
          background: 'rgba(247, 205, 55, 0.38)',
        },
      },
    },
    '& .MuiAlert-icon': {
      // backgroundColor: '#4CAF5061',
      paddingLeft: 14,
      paddingRight: 14,
      alignItems: 'center',
      color: '#3D7C3F',
    },
    '& .MuiAlert-message': {
      // color: ((theme.version && theme.version.new) ? '#000000' : '#4CAF50'),
      color: '#000000',
      fontSize: '14px',
      fontStyle: 'normal',
      // fontWeight: ((theme.version && theme.version.new) ? 400 : 700),
      fontWeight: 400,
      lineHeight: '16px',
      letterSpacing: '0em',
      paddingRight: 6,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& .MuiTypography-root': {
        fontSize: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 'inherit',
        '&:not(:last-child)': {
          marginBottom: '10px',
        },
      },
      '& .MuiAlertTitle-root': {
        color: '#000000',
        fontSize: '20px',
        lineHeight: '23px',
        fontWeight: 700,
      },
      '& .MuiButton-root': {
        padding: 0,
        fontWeight: 700,
        fontSize: '15px',
        lineHeight: '18px',
        color: '#1C3C74',
        marginRight: '20px',
        textTransform: 'capitalize',
        minWidth: 'inherit',
      },
    },
  },
  customSnackbarDM: {
    boxShadow: '0px 4px 4px 0px #00000040',
    '& .MuiAlert-root': {
      padding: '0px !important',
      backgroundColor: '#1c3c74',
      border: '1px solid #CCCCCC',
      '&.MuiAlert-outlinedSuccess': {
        '& .MuiAlert-icon': {
          backgroundColor: '#4CAF50',
        },
      },
      '&.MuiAlert-outlinedError': {
        '& .MuiAlert-icon': {
          background: 'rgba(244, 67, 54, 0.60)',
        },
      },
      '&.MuiAlert-outlinedWarning': {
        '& .MuiAlert-icon': {
          background: 'rgba(247, 205, 55, 0.38)',
        },
      },
    },
    '& .MuiAlert-icon': {
      // backgroundColor: '#4CAF5061',
      paddingLeft: 14,
      paddingRight: 14,
      alignItems: 'center',
      color: '#3D7C3F',
    },
    '& .MuiAlert-message': {
      // color: ((theme.version && theme.version.new) ? '#000000' : '#4CAF50'),
      color: '#FFFFFF',
      fontSize: '14px',
      fontStyle: 'normal',
      // fontWeight: ((theme.version && theme.version.new) ? 400 : 700),
      fontWeight: 400,
      lineHeight: '16px',
      letterSpacing: '0em',
      paddingRight: 6,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& .MuiTypography-root': {
        fontSize: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 'inherit',
        '&:not(:last-child)': {
          marginBottom: '10px',
        },
      },
      '& .MuiAlertTitle-root': {
        color: '#000000',
        fontSize: '20px',
        lineHeight: '23px',
        fontWeight: 700,
      },
      '& .MuiButton-root': {
        padding: 0,
        fontWeight: 700,
        fontSize: '15px',
        lineHeight: '18px',
        color: '#1C3C74',
        marginRight: '20px',
        textTransform: 'capitalize',
        minWidth: 'inherit',
      },
    },
  },
  yellow: {
    '& .MuiAlert-icon': {
      backgroundColor: '#FF980061',
      color: '#FF9800',
    },
    '& .MuiAlert-message': {
      color: '#FF9800',
    },
  },
  verticalCenter: {
    top: '50%',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
  },
  verticalSemiBottom: {
    bottom: '70px',
  },
  ULEButton: {
    float: "right",
    backgroundColor: "#1C3C74",
    color: "white",
    fontWeight: 700,
    borderRadius: 0,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1C3C74",
      color: "white",
    }
  },
  ULEButton1: {
    float: "right",
    color: "#1C3C74",
    fontWeight: 700,
    borderRadius: 0,
    textTransform: "none",

  },
  newCustomSnackbar: {
    backgroundColor: " white",
    padding: "15px",
    justifyContent: "center",
    border: "solid 0.5px gray",
    borderRadius: "3px",
  },
  newSnackbarcontent: {
    fontSize: "18px",
    fontWeight: 700,
    color: "black"
  },
  newSnackbarnote: {
    fontSize: "14px",
    fontWeight: 400,
    color: "black"
  },
  newSnackbarbtn:{
    paddingTop: "20px"
  }
}
));
