import React from "react";
import classNames from "classnames";
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { formatDate } from "../../services/util";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "../purchaseorder/styles";
import { useTranslation } from 'react-i18next';

/**
 * InventoryScan Print
 * @param {Object} ipurchaseorderdata 
 * @param {Array} inventorydata
 * @param {String} hospital 
 * @returns 
 */
const InventoryScanPrint = ({
  ipurchaseorderdata={}, inventorydata=[], hospital, 
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const classes1 = useStyles1();


  return (
    <Grid container direction="column" className={classNames(classes.newPrint, classes1.newPrintPO)}>
      <Table className="details-form">
        <TableBody>
          <TableRow>
            <TableCell>PO number: {ipurchaseorderdata.popurchaseorderid}</TableCell>
            <TableCell>Ownership: {ipurchaseorderdata.popurchaseordertype}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Distributor: {ipurchaseorderdata.povendorid}</TableCell>
            <TableCell>PO Date: {formatDate(ipurchaseorderdata.podate)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>SEID: {ipurchaseorderdata.poseid}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Table className="product-form">
        <TableHead>
          <TableRow>
            <TableCell>Reference Number</TableCell>
            <TableCell>Lot/SN</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>{t('main.uom.lable')}</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            inventorydata.map((detail, i) => (
            <TableRow key={i}>
              <TableCell>{detail.iproduct.sepreferencenumber}</TableCell>
              <TableCell>{detail.iproduct.seplotcode}</TableCell>
              <TableCell>{detail.iproduct.sepproductdescription}</TableCell>
              <TableCell>${detail.iproduct.sepproductprice}</TableCell>
              <TableCell>{detail.iproduct.sepuom}</TableCell>
              <TableCell>{detail.iquantity}</TableCell>
              <TableCell>{detail.iproductstatus}</TableCell>
            </TableRow>
            ))
          }
        </TableBody>
      </Table>
      
    </Grid>
  );
};

export default InventoryScanPrint;
