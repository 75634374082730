import { useState, useEffect } from 'react';
import { useWellKnownParties, /* PublicLedger */ } from '@daml/hub-react';


export const useOperator = () => {
    const { parties } = useDablParties();
    return parties.userAdminParty;
}

export const useDablParties = () => {
    const { parties, loading, error } = useWellKnownParties();
    const [ result, setResult ] = useState({ parties: devParties, loading: true, error: null });

    useEffect(() => {
        // if (true) {
            if (error && !loading) {
                console.error(`Error fetching DABL parties: ${error}`);
            }

            // parties && setResult({ parties, loading, error });
            if (parties) setResult({ parties, loading, error });
        // } else {
        //     setResult({ parties: devParties, loading: false, error: null });
        // }
    }, [parties, loading, error]);

    return result;
}

const devParties = {
    userAdminParty: "Operator",
    publicParty: "Public"
}
