import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: '1px',
    right: '1px',
    borderColor:'#183C73'
  },
  header: {
    backgroundColor: '#183C73',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 0px 0px 10px',
    color: '#fff',
    fontSize: '1.5em',
    textTransform: 'uppercase',
    '& .MuiIconButton-label':{
      color:'#F7CD37'
    }
  },
  button: {
    backgroundColor: '#183C73',
    minHeight:45,
    minWidth:140,
    '&:hover':{
      backgroundColor:'#F7CD37'
    }
  }
}));
