import React, { useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import { useLedger, useParty } from "@daml/react";
import { IconButton, Box, Grid, Typography, Menu, MenuItem, Collapse } from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { AccountInventory } from "../../services/daml-modules1";
import { damlFetch } from "../../services/axios/daml-json-api";
import { contractFilter1 } from "../../services/daml-filters";
import { getTodayString, sameUdi, /* formatDate*/ getDaysByRecievedDate ,getQuantityOnOrder } from "../../services/util";
import { UOM } from "../../models/Product";
import { InventoryProductStatus, InventoryProductStatusHospital, InventoryProductStatusVendor, 
  isEnableConfirmStatus } from "../../models/InventoryData";
import { CourierCompany } from "../../models/Courier";
import { PRODUCT_UPDATE_EVENT, EVENT_VERIFIED } from "../../store/actions/constants";
import useProduct, { useItemDetails, useHospitalUser } from "../../hooks/useProduct";
import useEvent from "../../hooks/useEvent";
import { useDamlLocation, useDamlState } from "../../context/DamlContext";
import { useLayoutDispatch, setLoading, unsetLoading } from "../../context/LayoutContext";
import { useUserState } from "../../context/UserContext";
import withLoading from "../../hoc/withLoading";
import withFilters from "../../hoc/withFilters";
import Contracts from "../../components/Contracts/Contracts1";
import FileUploadDropzone from "../../components/FileUpload/FileUploadDropzone";
import { ProductDetailTable, EditProductModal } from "../surgicalevent/add-edit-product1";
import folderIcon from "../../icons/mdi_folder-upload.svg";
import { handlePreviewIcon } from "../purchaseorder/create-purchaseorder1";
import { confirmRestockInventoryHospital, changeInventoryShipment } from "./handle";
import useStyles from "../purchaseorder/styles";
import useStyles1 from "./styles";
import { useTranslation } from 'react-i18next';



const listPattern = [
  {
    key: 'sepudi', details: 'Product Details', key1: 'sepproductprice',
  },
  {
    key: 'sepreferencenumber', key1: 'sepuom',
  },
  {
    key: 'sepproductmanufacturer', key1: 'sepproducttype',
  },
  {
    key: 'sepdistributor', key1: 'sepownership',
  },
  {
    key: 'sepproductname', key1: 'sepproductside',
  },
  {
    key: 'sepproductdescription', key1: 'iquantityunit', label1: 'Quantity:',
  },
  {
    key: 'sepproductexpiration', key1: 'seplocation', label1: 'Location',
  },
];

/**
 * Detail Panel
 */
const getDetailPanelContent = ({ row }) => (
  <ProductDetailTable
    product={{
      ...row?.contract?.payload?.iproduct,
      iquantityunit: row?.contract?.payload?.iquantityunit,
    }}
    listPattern={listPattern}
    isEnableCompliance={false}
  />
);

const CollapseIconButton = () => {
  const { product, setProduct } = useProduct(true);
  return (
    <IconButton onClick={() => setProduct({ ...product, isCollapsed: !product.isCollapsed })} style={{padding: '5px'}} >
      {
        product.isCollapsed
        ?
        <RemoveCircleIcon />
        :
        <AddCircleIcon />
      }
    </IconButton>
  );
};

const CollapseCellWrapper = ({ children }) => {
  const { product } = useProduct(true);
  return (
    <Collapse in={product.isCollapsed} timeout="auto" unmountOnExit>
      {children}
    </Collapse>
  );
};

const BackOrderUploader = ({
  handleUpload, 
}) => {
  const classes = useStyles();
  const classes1 = useStyles1();
  return (
    <>
      <Typography className={classes1.details} variant="h6" style={{ color: "black" }}>Notes: <b>Please check the items</b></Typography>
      <FileUploadDropzone
        className={classNames(classes.uploadFile, classes1.newUploadFile)}
        title=""
        uploadLabel={(
          <Box className="drag-drop-btn">
            Drag and Drop file to upload
            <img src={folderIcon} alt="" />
          </Box>
        )}
        changeFile={handleUpload}
        isNonePadding={true}
        isEnableUpload={false}
        handlePreviewIcon={handlePreviewIcon}
      />
    </>
  );
};

const getProductPatternReceive = (contract, role, locations) => ([
  {
    label: 'Shipping Details', style: { paddingTop: 10 },
  },
  {
    key: 'ishipmentdate', width: 152, label: 'Shipment Date', disabled: true, readOnly: true, labelLeft: true, type: 'text', style: { width: '167px' },labelWidth: 140
  },
  {
    key: 'ireceiveddate', width: 152, label: 'Received Date', disabled: true, type: 'text', readOnly: true, labelLeft: true,labelWidth: 140
  },
  {
    key: 'icourier', width: 130, label: 'Courier Company', type: 'select', items: CourierCompany, disabled: false, readOnly: true, labelLeft: true,labelWidth: 140
  },
  {
    key: 'itrackingstatus',  label: 'Tracking Number', type: 'text',labelLeft: true ,labelWidth: 140 ,width: 210 , colSpan: 2
  },
  {
    label: 'Product Details', style: { paddingTop: 10 },
  },
  {
    key: 'sepreferencenumber', width: 306, colSpan: 2, disabled: true, readOnly: true,
  },
  {
    key: 'sepproductmanufacturer', width: 152, disabled: true, style: { width: '167px' },readOnly: true,
    key1: 'sepdistributor', width1: 152, disabled1: true,readOnly1: true,style1: { position: 'relative', right: '96px' },
    key2: 'sepownership', width2: 152, disabled2: true,readOnly2: true,style2: { position: 'relative', right: '96px' },
  },
  {
    key: 'sepproductname', width: 333, colSpan: 2, disabled: true, readOnly: true,
  },
  {
    key: 'sepproductdescription', width: 333, colSpan: 2, disabled: true, readOnly: true,
  },
  {
    key: 'sepproductprice', width: 152, disabled: true, readOnly: true,
    key1: 'sepproductside', width1: 152, disabled1: true, readOnly1: true, style1: { position: 'relative', right: '139px' },
  },
  {
    key: 'sepuom', width: 152, disabled: true, readOnly: true,
    key1: 'sepproducttype', width1: 152, disabled1: true, readOnly1: true, 
    style1: { position: 'relative', right: '139px' },
    key2: 'seplocation', width2: 152, label2: 'Location', items2: locations, type2: (role === 'Hospital' ? "select" : "text"), disabled2 : (role === 'Hospital' ? false : true) ,readOnly2 : (role === 'Hospital' ? false : true),placeholder2 : (role === 'Hospital' ? "" : "Not Assigned"),
    style2: { position: 'relative', right: '139px' },
  },
  {
    key: 'barcode', width: 360, colSpan: 2,
  },
  {
    key: 'sepudi', width: 306, colSpan: 2,
  },
  {
    key: 'seplotcode', width: 152,
    key1: 'sepproductexpiration', width1: 152, style1: { position: 'relative', right: '115px' },
  },
  {
    key: 'iquantity', width: 152, label: 'QoO', type: `number{${contract.payload.iquantity}}`,
    disabled: true, readOnly: true, style: { paddingTop: "10px" }, infoBadge: 'Actual item(s) received/Quantity on Order', 
    key1: 'iquantityunit', width1: 152, label1: 'Qty', type1: `number{${contract.payload.iquantityunit}}`,
    style1: { paddingTop: "10px",position: 'relative', right: '115px' }, 
    infoBadge1: 'Actual quantity received/Quantity Ordered',
    type2:"checkbox",key2:"iunclassified",label2: "Unclassified", 
    style2: { position: 'relative', right: '89px', top: '25px', display: 'flex' },
    startAdornment2: (<CollapseIconButton />),
  },
  {
    key: 'iquantityremain', width: 152, label: 'Rec/QCO/Partial', type: `number{${contract.payload.unit}}`, 
    style: { paddingTop: "10px" }, WrapperComp: CollapseCellWrapper, 
    key1: 'iquantitypartial', width1: 152, label1: 'Rec/Qty/Partial', type1: `number`,
    disabled1: true, readOnly1: true, style1: { paddingTop: "10px",position: 'relative', right: '115px' }, 
    WrapperComp1: CollapseCellWrapper, 
  },
]);

const getProductPatternShip = (contract, role, locations) => ([
  {
    label: 'PO Details', style: { paddingTop: 0 },
  },
  {
    key: 'ipurchaseordernumber', width: 152, label: 'PO Number', disabled: true, type: 'text', style: { width: '167px' }, readOnly: true,
    key1: 'hospital', width1: 152, label1: 'Hospital', disabled1: true, type1: 'text', readOnly1: true,
  },
  {
    key: 'ishipmentdate', width: 152, label: 'Shipmente Date', type: 'date',
    key1: 'icourier', width1: 152, label1: 'Courier Company', type1: 'select', items1: CourierCompany,
  },
  {
    key: 'itrackingstatus', width: 152, label: 'Tracking Number', type: 'text',
  },
  {
    label: 'Product Details',
  },
  {
    key: 'sepreferencenumber', width: 152, disabled: true, style: { width: '167px' }, readOnly: true,
  },
  {
    key: 'sepproductmanufacturer', width: 152, disabled: true, readOnly: true,
    key1: 'sepdistributor', width1: 152, disabled1: true, readOnly1: true,
    key2: 'sepownership', width2: 152, disabled2: true, readOnly2: true, style2: { position: 'relative', right: '61px' }
  },
  {
    key: 'sepproductname', label: 'Name', width: 333, colSpan: 2, disabled: true, readOnly: true,
  },
  {
    key: 'sepproductdescription', width: 333, colSpan: 2, disabled: true, readOnly: true,
  },
  {
    key: 'sepproductprice', width: 152, disabled: true, readOnly: true,
    key1: 'sepproductside', width1: 152, disabled1: true, readOnly1: true,
    key2: 'seplocation', width2: 152, label2: 'Location', items2: locations, type2: (role === 'Hospital' ? "select" : "text"), disabled2 : (role === 'Hospital' ? false : true) ,readOnly2 : (role === 'Hospital' ? false : true),placeholder2 : (role === 'Hospital' ? "" : "Not Assigned"),
    style2: { position: 'relative', right: '61px' }
  },
  {
    key: 'sepuom', width: 152, disabled: true, readOnly: true,
    key1: 'sepproducttype', width1: 152, disabled1: true, readOnly1: true,
  },
  {
    key: 'barcode', width: 306, colSpan: 2,
  },
  {
    key: 'sepudi', width: 306, colSpan: 2,
  },
  {
    key: 'seplotcode', width: 152,
    key1: 'sepproductexpiration', width1: 152,
  },
  {
    key: 'iquantity', width: 152, label: 'QoO', type: `number{${contract.payload.iquantity}}`,
    disabled: true, readOnly: true, style: { paddingTop: "10px" }, infoBadge: 'Actual item(s) received/Quantity on Order', 
    key1: 'iquantityunit', width1: 152, label1: 'Qty', type1: `number{${contract.payload.iquantityunit}}`,
    style1: { paddingTop: "10px" }, 
    infoBadge1: 'Actual quantity received/Quantity Ordered',
  },
  {
    key: 'iquantityremain', width: 152, label: 'Rec/QCO/Partial', type: `number{${contract.payload.unit}}`, style: { paddingTop: "10px" }, 
    key1: 'iquantitypartial', width1: 152, label1: 'Rec/Qty/Partial', type1: `number`,
    disabled1: true, readOnly1: true, style1: { paddingTop: "10px" }, 
  },
]);

const getProductPatternStockedForFacility = (contract, role, locations, t) => ([
  {
    label: 'Shipping Details', style: { paddingTop: 0 },
  },
  {
    key: 'ishipmentdate', width: 152, label: 'Shipment Date', disabled: true, style: { width: '167px' },
    type: 'text', readOnly: true, labelLeft: true, labelWidth: 140
  },
  {
    key: 'ireceiveddate', width: 152, label: 'Received Date', readOnly: true, disabled: true,
    type: 'text', labelLeft: true, labelWidth: 140
  },
  {
    key: 'icourier', width: 152, label: 'Courier Company', type: 'select', items: CourierCompany, labelLeft: true, labelWidth: 140, style: { width: '167px' }
  },
  {
    key: 'itrackingstatus', width: 152, label: 'Tracking Number', type: 'text', labelLeft: true, labelWidth: 140
  },
  {
    label: 'Product Details',
  },
  {
    key: 'sepreferencenumber', width: 152, disabled: true, style: { width: '167px' }, type: 'text', readOnly: true
  },
  {
    key: 'sepproductmanufacturer', width: 152, disabled: true, type: 'text', readOnly: true,
    key1: 'sepdistributor', width1: 152, disabled1: true, type1: 'text', readOnly1: true, style1: { position: 'relative', right: '96px' },
    key2: 'sepownership', width2: 152, disabled2: true, type2: 'text', readOnly2: true, style2: { position: 'relative', right: '96px' },
  },
  {
    key: 'sepproductname', width: 333, colSpan: 2, disabled: true, type: 'text', readOnly: true
  },
  {
    key: 'sepproductdescription', width: 333, colSpan: 2, disabled: true, type: 'text', readOnly: true
  },
  {
    key: 'sepproductprice', width: 152, disabled: true, type: 'text', readOnly: true,
    key1: 'sepproductside', width1: 152, disabled1: true, type1: 'text', readOnly1: true,
    style1: { position: 'relative', right: '96px' }
  },
  {
    key: 'sepuom', width: 152, disabled: true, type: 'text', readOnly: true,
    key1: 'sepproducttype', width1: 152, disabled1: true, type1: 'text', readOnly1: true,
    style1: { position: 'relative', right: '96px' },
    key2: 'seplocation', width2: 152, type2: (role === 'Hospital' ? "select" : "text"), disabled2 : (role === 'Hospital' ? false : true) ,readOnly2 : (role === 'Hospital' ? false : true),placeholder2 : (role === 'Hospital' ? "" : "Not Assigned"),
    style2: { position: 'relative', right: '96px' }, items2: locations, label2: 'Location'
  },
  {
    key: 'barcode', width: 360, colSpan: 2, style: { paddingBottom: '7px' }
  },
  {
    key: 'sepudi', width: 360, colSpan: 2, style: { paddingBottom: '7px' }
  },
  {
    key: 'seplotcode', width: 152, style: { paddingBottom: '7px' },
    key1: 'sepproductexpiration', width1: 152, style1: { position: 'relative', right: '96px' },
  },
  {
    key: 'iquantity', width: 152, label: `Rec/${t('main.quantityonorder.label')}`, type: `number{${contract.payload.iquantity}}`,
    style: { paddingTop: "10px" },  infoBadge: 'Actual item(s) received/Quantity on Order',
    key1: 'iquantityunit', width1: 152, label1: 'Rec/Qty', type1: `number{${contract.payload.iquantityunit}}`,
    style1: { position: 'relative', right: '96px', paddingTop: "10px" },
    infoBadge1: 'Actual quantity received/Quantity Ordered',
    type2:"checkbox",key2:"iunclassified",label2: "Unclassified", 
    style2: { position: 'relative', right: '60px', verticalAlign: 'bottom' },
  }
]);

const getProductPatternBackOrder = (role, locations, handleUpload) => ([
  {
    label: 'PO Details', style: { padding: '10px 0px 10px 0px' },
  },
  {
    key: 'ipurchaseordernumber', width: 90, label: 'PO Number', type: 'text', style: { width: '100px', padding: '10px 0px 10px 0px' },
    disabled: true, readOnly: true,
    key1: 'hospital', width1: 100, label1: 'Hospital', type1: 'text', style1: { width: '140px', padding: '10px 0px 10px 0px' },
    disabled1: true, readOnly1: true,
  },
  {
    key: 'sepownership', width: 90, disabled: true, readOnly: true, style: { padding: '3px 0px 0px 0px' },
    key1: 'ishipmentdate', width1: 120, label1: 'Expected Shipment Date', type1: 'date', style1: { padding: '3px 0px 0px 0px' }
  },
  {
    label: 'Product Details', style: { padding: '10px 0px 10px 0px' },
  },
  {
    key: 'sepreferencenumber', width: 350, colSpan: 3, disabled: true, readOnly: true,
    style: { padding: '3px 0px 0px 0px' }
  },
  {
    key: 'sepproductmanufacturer', width: 90, disabled: true, readOnly: true,
    style: { padding: '3px 0px 0px 0px' },
    key1: 'sepdistributor', width1: 100, disabled1: true, readOnly1: true,
    style1: { padding: '3px 0px 0px 0px' },
    key2: 'sepownership', width2: 100, disabled2: true, readOnly2: true,
    style2: { padding: '3px 0px 0px 0px' },
  },
  {
    key: 'sepproductname', width: 350, colSpan: 3, disabled: true, readOnly: true,
    style: { padding: '3px 0px 0px 0px' }
  },
  {
    key: 'sepproductdescription', width: 350, colSpan: 3, disabled: true, readOnly: true,
    style: { padding: '3px 0px 0px 0px' }
  },
  {
    key: 'sepproductprice', width: 90, disabled: true, readOnly: true,
    style: { padding: '3px 0px 0px 0px' },
    key1: 'sepproductside', width1: 100, disabled1: true, readOnly1: true,
    style1: { padding: '3px 0px 0px 0px' }
  },
  {
    key: 'sepuom', width: 90, disabled: true, readOnly: true, style: { padding: '3px 0px 0px 0px' },
    key1: 'sepproducttype', width1: 100, disabled1: true, readOnly1: true, style1: { padding: '3px 0px 0px 0px' },
    key2: 'seplocation', width2: 140, label2: "Location", type2: (role === 'Hospital' ? "select" : "text"), disabled2 : (role === 'Hospital' ? false : true) ,readOnly2 : (role === 'Hospital' ? false : true),placeholder2 : (role === 'Hospital' ? "" : "Not Assigned"),
    style2: { padding: '3px 0px 0px 0px' }, items2: locations
  },
  {
    label: (<BackOrderUploader handleUpload={handleUpload} />), 
    style: { width: "270px", padding: '25px 0px 0px 0px' }
  },
]);

const getProductPatternPartial = (contract, role, locations) => ([
  {
    label: 'Shipping Details', style: { paddingTop: 10 },
  },
  {
    key: 'ishipmentdate', width: 152, label: 'Shipment Date', disabled: true, readOnly: true, labelLeft: true, type: 'text', style: { width: '167px' },labelWidth: 140
  },
  {
    key: 'ireceiveddate', width: 152, label: 'Received Date', disabled: true, type: 'text', readOnly: true, labelLeft: true,labelWidth: 140
  },
  {
    key: 'icourier', width: 130, label: 'Courier Company', type: 'select', items: CourierCompany, disabled: false, readOnly: true, labelLeft: true,labelWidth: 140
  },
  {
    key: 'itrackingstatus',  label: 'Tracking Number', type: 'text',labelLeft: true ,labelWidth: 140 ,width: 210 , colSpan: 2
  },
  {
    label: 'Product Details', style: { paddingTop: 10 },
  },
  {
    key: 'sepreferencenumber', width: 306, colSpan: 2, disabled: true, readOnly: true,
  },
  {
    key: 'sepproductmanufacturer', width: 152, disabled: true, style: { width: '167px' },readOnly: true,
    key1: 'sepdistributor', width1: 152, disabled1: true,readOnly1: true,style1: { position: 'relative', right: '96px' },
    key2: 'sepownership', width2: 152, disabled2: true,readOnly2: true,style2: { position: 'relative', right: '96px' },
  },
  {
    key: 'sepproductname', width: 333, colSpan: 2, disabled: true, readOnly: true,
  },
  {
    key: 'sepproductdescription', width: 333, colSpan: 2, disabled: true, readOnly: true,
  },
  {
    key: 'sepproductprice', width: 152, disabled: true, readOnly: true,
    key1: 'sepproductside', width1: 152, disabled1: true, readOnly1: true, style1: { position: 'relative', right: '139px' },
  },
  {
    key: 'sepuom', width: 152, disabled: true, readOnly: true,
    key1: 'sepproducttype', width1: 152, disabled1: true, readOnly1: true, 
    style1: { position: 'relative', right: '139px' },
    key2: 'seplocation', width2: 152, label2: 'Location', items2: locations, type2: (role === 'Hospital' ? "select" : "text"), 
    disabled2 : (role === 'Hospital' ? false : true) ,readOnly2 : (role === 'Hospital' ? false : true),
    placeholder2 : (role === 'Hospital' ? "" : "Not Assigned"),
    style2: { position: 'relative', right: '139px' }
  },
  {
    key: 'iquantityreceived', width: 113, label: 'Rec/QoO/Partial', type: `number{${contract.payload.unit}}`,
    disabled: true, readOnly: true, 
    key1: 'iquantityunit', width1: 113, label1: 'Qty/Partial', type1: `number`,
    disabled1: true, readOnly1: true, style1: { position: 'relative', right: '139px' },
    key2: 'iquantityreceivednew', width2: 113, label2: 'New', 
    type2: `number{${contract.payload.unit-contract.payload.iquantityreceived}}`,
    style2: { position: 'relative', right: '139px' },
  },
]);


const IncomingStatusCell = ({ 
  contract={}, status='', role, handleChange, 
}) => {
  // console.log("[IncomingStatusCell]", contract, status);
  
  const classes1 = useStyles1();
  const [anchorEl, setAnchorEl] = useState(null);

  const items = (
      role === 'Hospital'
    ?
      [ 
        {value: 'Open', disabled: (status === 'Opens')},
        {value: 'Back-Order', disabled: (status === 'BackOrder' || status === 'PartialReceived' || status === 'PartialShipped')},
        {value: 'Received', disabled: (status === 'Received' || status === 'PartialReceived' || status === 'PartialShipped')},
        {value: 'Partial filled', disabled: !(status === 'PartialReceived' || status === 'PartialShipped')},
      ]
    :
    (
      role === 'Vendor'
      ?
      [ 
        {value: 'Open', disabled: (status === 'Opens')},
        {value: 'Shipped', disabled: (status === 'Shipped')},
        {value: 'Back-Order', disabled: (status === 'BackOrder' || status === 'PartialReceived' || status === 'PartialShipped')},
        {value: 'Stock for Facility', disabled: (status === 'Received' || status === 'StockedForFacility')},
      ]
      :
      []
    )
  );

  const getColorCss = (value) => {
    switch (value) {
      case 'Received':
      case 'StockedForFacility':
        return classes1.greenText;
      case 'Opens':
        return classes1.greyText;
      case 'BackOrder':
        return classes1.redText;
      case 'PartialReceived':
      case 'PartialShipped':
        return classes1.yellowText;
      default:
        return classes1.blueText;
    }
  };
  
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event, val) => {
    // const oldstatus = contract?.payload?.iproductstatus;
    const oldstatus = status;
    if (!isEnableConfirmStatus(oldstatus, val, role)) {
      // event.stopPropagation();
      console.log("[IncomingStatusCell] isEnableConfirmStatus is false");
    }
    if (typeof handleChange === 'function') {
      handleChange(val, event, contract);
    }
    handleClose();
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes1.statusCell}
    >
      <Grid item>
        <Typography className={classNames(getColorCss(status), "text")}>
          {InventoryProductStatus[status]}
        </Typography>
      </Grid>
      <Grid item>
        <IconButton 
          onClick={handleOpen}
          disabled={role === 'Vendor' && status === 'Received'}
        >
          <ArrowDropDownIcon /> 
        </IconButton>
      </Grid>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {
          items.map(({value, disabled}) => (
            <MenuItem 
              key={value}
              onClick={(event) => handleClick(event, value)} 
              disabled={disabled}
            >
              {value}
            </MenuItem>
          ))
        }
      </Menu>
    </Grid>
  );
};

const getNewInventoryData = (product, itemIndex) => {
  // console.log("[getNewInventoryData]", product, itemIndex);
  const invd = {
    ...product,
    index: itemIndex,
    iproduct: {
      ...product,
      id: product?.iproduct?.id,
    },
  };
  // check out if it was PartialReceived or PartialShipped
  if ((invd.itemsreceived && invd.itemsreceived.length) || (invd.itemsshipped && invd.itemsshipped.length)
    || (invd.itemsopens && invd.itemsopens.length)) {
    const newiproductstatus = invd.iproductstatus;
    let newiquantity = parseInt(invd.iquantity);
    // console.log("[getNewInventoryData] newiquantity", newiquantity);
    if (isNaN(newiquantity) || newiquantity <= 0) return null;
    let items = [], sum = 0, result = [];
    if (newiproductstatus === 'Received') {
      items = (invd.itemsshipped ?? []).concat((invd.itemsopens ?? []));
      if (invd.iquantityreceivednew !== undefined) newiquantity = parseInt(invd.iquantityreceivednew);
      else if (invd.iquantityreceived !== undefined) newiquantity = newiquantity - parseInt(invd.iquantityreceived);
    }
    else if (newiproductstatus === 'Shipped') {
      items = (invd.itemsreceived ?? []).concat((invd.itemsopens ?? []));
      if (invd.iquantityshipped !== undefined) newiquantity = newiquantity - parseInt(invd.iquantityshipped);
    }
    else if (newiproductstatus === 'Opens') {
      items = (invd.itemsreceived ?? []).concat((invd.itemsshipped ?? []));
      if (invd.iquantityopens !== undefined) newiquantity = newiquantity - parseInt(invd.iquantityopens);
    }
    // console.log("[getNewInventoryData] newiquantity", newiquantity);
    if (isNaN(newiquantity) || newiquantity <= 0) return null;
    for (let i in items) {
      const delta = newiquantity - sum;
      if (delta <= 0) break;
      const item = items[i];
      if (delta < item.iquantity) item.iquantity = delta;
      if (item.iquantity > 0) {
        result.push({
          ...invd,
          id: item.id,
          index: item.index,
          iquantity: item.iquantity,
        });
        sum += item.iquantity;
      }
    }
    return (result.length ? {...invd, items: result} : null);
  }
  return invd;
};

/**
 * Incoming Inventory Contracts
 * @param {Object} searchParams 
 * @param {Array} allContracts 
 * @param {Object} options 
 * @returns 
 */
function IncomingInventoryContracts({
  searchParams = {},
  allContracts = [],
  options = {},
  setToastContent = ()=>{},
  setOpenToast = ()=>{}
}) {

  const { t } = useTranslation();
  const classes = useStyles();
  const classes1 = useStyles1();
   /**
   * TYD-1924
   *  Add qoo key for the table listing, by using units key from iproduct
   */
  allContracts = allContracts.map((c) => {
    const order = getQuantityOnOrder(c, {isfloor: false});
    let qoo = "";
    if (c.iquantityvirtual) qoo = `${!isNaN(c.iquantityvirtual) ? c.iquantityvirtual : ''}/${!isNaN(order) ? order : ''}`;
    else if (c.iquantity && c.iquantity !== "0") qoo = `${!isNaN(c.iquantity) ? c.iquantity : ''}/${!isNaN(order) ? order : ''}`;
    return {
      payload: {
        ...c , 
        qoo: qoo,
        iquantityunit: !isNaN(c.iquantityunit) ? c.iquantityunit : 'N/A',
      }
    };
  });
 

  let contracts = contractFilter1(allContracts, searchParams);
  console.log("[IncomingInventoryContracts]", searchParams, contracts);

  const layoutDispatch = useLayoutDispatch();
  const { role, roleCid, userlistPayload } = useDamlState();
  const { event, status, onEventRun, onEventEnd, onEventStart, onEventVerify } = useEvent();
  
  // InventoryProductStatus Model, Items, Options for each role
  const InventoryProductStatusModel = role === 'Hospital' ? InventoryProductStatusHospital : InventoryProductStatusVendor;
  const statusOptions = Object.keys(InventoryProductStatusModel).map(value => ({
    label: InventoryProductStatusModel[value], value: value
  }));

  const ledger = useLedger();
  const party = useParty();
  const { token } = useUserState();
  // const [accInv, setAccInv] = useState();

  const { locations } = useDamlLocation();
  const [openToastUdi, setOpenToastUdi] = useState(false);
  const [toastUdiContent, setToastUdiContent] = useState('');

  const { product, clearProduct: setProductItems } = useProduct(true);
  const { itemIndex, setItemIndex } = useItemDetails();
  const { setHospital } = useHospitalUser();

  // Confirm to Receive Modal
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editModalLabel, setEditModalLabel] = useState("");
  const [btnConfirm, setBtnConfirm] = useState("Receive"); // Button on Confirm
  const [editModalHeight, setEditModalHeight] = useState(800);
  /**
   * Product Pattern in Edit Modal
   * 1. Received
   * 2. Shipped 
   * 3. Stock for Facility 
   * 4. Back-Order 
   * 5. Open 
   * 6. Partial filled 
   */
  const [productPattern, setProductPattern] = useState([]);


  const newHeaderPattern = (
    role === 'Hospital' ?
      [{
        key: 'ipurchaseordernumber', label: btnConfirm === "Confirm" ? "Confirm Back-Order" : 'PO Number: ', className: 'name ref', value: btnConfirm === "Confirm" ? "" : contracts[0]?.payload?.ipurchaseordernumber,
      },
      {
        key: btnConfirm === "Confirm" ? "" : 'sepreferencenumber', label: btnConfirm === "Confirm" ? "" : 'Ref: ', className: 'refName ref',
      }]
      :
      [{
        key: 'headerlabelvendor', label: editModalLabel, className: 'name ref',
      }]
  );


  // confirm back-order by uploading csv
  const handleUpload = (file, callback) => {
  };

  // useEffect(() => {
  //   setProductItems({...product, iunclassified: accInv?.payload?.inventoryproduct?.unclassified});
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [accInv]);

  // handle to change to received in contracts
  const handleChange = async (val, event, contract) => {

    // irec QOO
    // const irecQOO = contract?.payload?.iquantityunit > 0 ? 1 : 0;
    // get Rec/QOO value 
    // const totalQOO = irecQOO === 0 ? 1 : getQuantityOnOrder({...contract.payload, iquantityunit: irecQOO});
    const todaysDate = getTodayString();

    if (val === 'Received') {
      const res = await damlFetch({
        token,
        template: AccountInventory,
        searchParams: {
            hospital: party,
            inventoryproduct: {
                ireferencenumber: contract.payload.iproduct.sepreferencenumber,
            },
        },
      });
      const [temp] = res;
      // setAccInv(temp);
      setProductItems({
        ...contract.payload.iproduct,
        ...contract.payload,
        ireceiveddate: todaysDate,
        iproductstatus: 'Received',
        // iquantity: totalQOO,
        // iquantityunit: irecQOO,
        idoh: getDaysByRecievedDate(todaysDate),
        iunclassified: Boolean(temp?.payload?.inventoryproduct?.unclassified),
        iquantityremain: 0,
        iquantitypartial: 1,
      });
      setItemIndex(contract.payload.index);
      setHospital(contract.payload.hospital);
      // product pattern in Hospital
      setProductPattern(getProductPatternReceive(contract, role, locations));
      setBtnConfirm("Receive");
      setEditModalHeight(850);
      setOpenEditModal(true);
    }
    else if (val === 'Shipped') {
      setProductItems({
        ...contract.payload.iproduct,
        ...contract.payload,
        ishipmentdate: todaysDate,
        iproductstatus: 'Shipped',
        // iquantity: totalQOO,
        // iquantityunit: irecQOO,
        idoh: getDaysByRecievedDate(contract.payload?.ireceiveddate ? contract.payload?.ireceiveddate : todaysDate),
        iquantityremain: 0,
        iquantitypartial: 1,
      });
      setItemIndex(contract.payload.index);
      setHospital(contract.payload.hospital);
      setEditModalLabel("Confirm Shipment");
      //product pattern to Ship in Vendor
      setProductPattern(getProductPatternShip(contract, role, locations));
      setBtnConfirm("Confirm");
      setEditModalHeight(800);
      setOpenEditModal(true);
    }
    else if (val === 'Stock for Facility') {
      const res = await damlFetch({
        token,
        template: AccountInventory,
        searchParams: {
            vendor: party,
            inventoryproduct: {
                ireferencenumber: contract.payload.iproduct.sepreferencenumber,
            },
        },
      });
      const [temp] = res;
      // setAccInv(temp);
      setProductItems({
        ...contract.payload.iproduct,
        ...contract.payload,
        ireceiveddate: todaysDate,
        iproductstatus: 'Received',
        // iquantity: totalQOO,
        // iquantityunit: irecQOO,
        idoh: getDaysByRecievedDate(contract.payload?.ireceiveddate ? contract.payload?.ireceiveddate : todaysDate),
        iunclassified: Boolean(temp?.payload?.inventoryproduct?.unclassified),
      });
      setItemIndex(contract.payload.index);
      setHospital(contract.payload.hospital);
      setEditModalLabel("Stock for Facility");
      // product pattern to StockedForFacility in Vendor
      setProductPattern(getProductPatternStockedForFacility(contract, role, locations, t));
      setBtnConfirm("Stock for Facility");
      setEditModalHeight(800);
      setOpenEditModal(true);
    }
    else if (val === 'Back-Order') {
      setProductItems({
        ...contract.payload.iproduct,
        ...contract.payload,
        iproductstatus: 'BackOrder',
        // iquantity: 1,
        idoh: getDaysByRecievedDate(contract.payload?.ireceiveddate ? contract.payload?.ireceiveddate : todaysDate),
      });
      setItemIndex(contract.payload.index);
      setHospital(contract.payload.hospital);
      setEditModalLabel("Confirm Back-Order");
      // product pattern to BackOrder in Vendor
      setProductPattern(getProductPatternBackOrder(role, locations, handleUpload));
      setBtnConfirm("Confirm");
      setEditModalHeight(800);
      setOpenEditModal(true);
    }
    else if (val === 'Open') {
      setProductItems({
        ...contract.payload.iproduct,
        ...contract.payload,
        iproductstatus: 'Opens',
        idoh: getDaysByRecievedDate(contract.payload?.ireceiveddate ? contract.payload?.ireceiveddate : todaysDate),
      });
      setItemIndex(contract.payload.index);
      setHospital(contract.payload.hospital);
      // change status directly
      onEventStart(PRODUCT_UPDATE_EVENT);
      onEventVerify();
    }
    else if (val === 'Partial filled') {
      setProductItems({
        ...contract.payload.iproduct,
        ...contract.payload,
        ireceiveddate: todaysDate,
        iproductstatus: 'Received',
        //  iquantity: totalQOO,
        //  iquantityunit: irecQOO,
        idoh: getDaysByRecievedDate(todaysDate),
        iquantityreceivednew: 1,
      });
      setProductPattern(getProductPatternPartial(contract, role, locations));
      setBtnConfirm("Receive");
      setEditModalHeight(730);
      setOpenEditModal(true);
    }

    // event.stopPropagation();

  };


  // Click "Receive" in Confirm Modal
  const addConfirmInventory = useCallback(async () => {
    // console.log("[IncomingInventoryContracts] addConfirmInventory", product, itemIndex);

    // spinner
    setLoading(layoutDispatch);
    onEventRun();

    const newInventoryData = getNewInventoryData(product, itemIndex);
    
    if (newInventoryData) {
      let result;
      const items = newInventoryData.items ?? [newInventoryData];

      if (role === 'Hospital') {
        // change iproductstatus in Hospital
        for (let i in items) {
          
          let inventorydata = items[i];
          
          let newiproductstatus = newInventoryData.iproductstatus;

          if(newiproductstatus === 'Opens'){
            inventorydata = { ...inventorydata, iproduct: { ...inventorydata.iproduct, sepudi: "", seplotcode: "", sepproductexpiration: "" }, }
          }
          result = await confirmRestockInventoryHospital({
            ledger,
            roleCid,
            userlistPayload,
            vendor: newInventoryData.vendor,
            inventoryCid: newInventoryData.contractId,
            inventorydata,
            newiproductstatus,
          });
        }
  
        if (result) {
          // toast
          setToastContent(`Confirmed the product is ${newInventoryData.iproductstatus}.`);
          setOpenToast(true);
        }
      }
      else if (role === 'Vendor') {
        // change iproductstatus in Vendor
        for (let i in items) {
          const invd = items[i];
          result = await changeInventoryShipment({
            ledger,
            roleCid,
            userlistPayload,
            hospital: newInventoryData.hospital,
            inventoryCid: newInventoryData.contractId,
            inventorydata: invd,
            newiproductstatus: newInventoryData.iproductstatus,
          });
        }
  
        if (result) {
          // toast
          // setToastContent(`Confirmed to ${newInventoryData.iproductstatus}.`);
          // setOpenToast(true);
          if (newInventoryData.iproductstatus === 'BackOrder') {
            setToastContent(`You have confirmed REF ${newInventoryData.iproduct.sepreferencenumber} as Back-Order for ${newInventoryData.hospital}.`);
            setOpenToast(true);
          }
          if (newInventoryData.iproductstatus === 'Shipped') {
            setToastContent(`You have Shipped REF ${newInventoryData.iproduct.sepreferencenumber} Lot ${newInventoryData.iproduct.seplotcode?newInventoryData.iproduct.seplotcode:"NA"} for ${newInventoryData.hospital}.`);
            setOpenToast(true);
          }
          if (newInventoryData.iproductstatus === 'Received') {
            setToastContent(`You have Stocked REF ${newInventoryData.iproduct.sepreferencenumber} Lot ${newInventoryData.iproduct.seplotcode?newInventoryData.iproduct.seplotcode: "NA"} for ${newInventoryData.hospital}.`);
            setOpenToast(true);
          }
        }
      }
    }

    unsetLoading(layoutDispatch);
    onEventEnd();
    setOpenEditModal(false);

  }, [layoutDispatch, onEventRun, onEventEnd, ledger, role, roleCid, userlistPayload, itemIndex, product ,setToastContent , setOpenToast]);

  // check out if Udi & Ref is same as inventory data
  const checkSameUdiRef = useCallback(() => {
    // console.log("[IncomingInventoryContracts] checkSameUdiRef", product);
    let checked = sameUdi(product, product);
    if (!checked) {
      onEventEnd();
      // toast
      setToastUdiContent(t('inventory.restock.udi.toast'));
      setOpenToastUdi(true);
    }
    else {
      // check out ref
      checked = (product?.sepreferencenumber === product.iproduct?.sepreferencenumber);
      if (!checked) {
        onEventEnd();
        // toast
        setToastUdiContent(t('inventory.restock.ref.toast'));
        setOpenToastUdi(true);
      }
    }
    return checked;
  }, [product, onEventEnd, t]);

  // check out iunclassified
  const checkUnclassified = useCallback(() => {
    let checked = true;
    if (product?.iproductstatus === "Received") {
      if (product.iunclassified) checked = true;
      else {
        if (product.sepudi === "" ||
          product.sepproductexpiration === null ||
          product.seplotcode === null || product.seplotcode.trim() === "") {
            // toast
            setToastUdiContent(t('inventory.restock.lot.toast'));
            setOpenToastUdi(true);
            checked = false;
            onEventEnd();
        }
        else {
          checked = true;
        }
      }
    }
    return checked;
  }, [product, onEventEnd, t]);


  useEffect(() => {
    if (event) {
      console.log("[IncomingInventoryContracts] On Start Event", event);
      if (event === PRODUCT_UPDATE_EVENT && status === EVENT_VERIFIED) {
        // check out if udi is correct
        if (checkSameUdiRef() && checkUnclassified()) addConfirmInventory();
      }
    }
  }, [event, status, addConfirmInventory, checkSameUdiRef, checkUnclassified]);


  return (
    <>
      <Contracts
        contracts={contracts}
        columns={[
          ["Reference Number", "payload.iproduct.sepreferencenumber", {
            search: {
              type: "AdaptiveSearch",
              name: "iproduct.sepreferencenumber",
              getValue: (c) => c?.payload?.iproduct?.sepreferencenumber,
              contracts: allContracts,
            },
            options: {
              flex: 1,
              cellClassName: classNames(classes.customText),
              headerClassName: classes.customText,
            },
          }],
          [`${t('main.lotcode.label')}`, "payload.iproduct.seplotcode", {
            search: {
              type: "AdaptiveSearch",
              name: "iproduct.seplotcode",
              getValue: (c) => c?.payload?.iproduct?.seplotcode,
              contracts: allContracts,
            },
            options: {
              cellClassName: classNames(classes.customText),
              headerClassName: classes.customText,
            }
          }],
          ["Description", "payload.iproduct.sepproductdescription", {
            search: {
              type: "AdaptiveSearch",
              name: "iproduct.sepproductdescription",
              getValue: (c) => c?.payload?.iproduct?.sepproductdescription,
              contracts: allContracts,
            },
            options: {
              flex: 1,
              cellClassName: classNames(classes.customText),
              headerClassName: classes.customText,
            },
          }],
          ["Price", "payload.iproduct.sepproductprice", {
            search: {
              type: "AdaptiveSearch",
              name: "iproduct.sepproductprice",
              getValue: (c) => c?.payload?.iproduct?.sepproductprice,
              contracts: allContracts,
            },
            options: {
              beforeText: '$',
              cellClassName: classNames(classes.customText),
              headerClassName: classes.customText,
            }
          }],
          [`${t('main.quantityonorder.label')}`, "payload.qoo", {
            search: {
              type: "AdaptiveSearch",
              name: "qoo",
              getValue: (c) => c.payload.qoo,
              contracts: allContracts,
            },
            options: {
              cellClassName: classNames(classes.customText),
              headerClassName: classes.customText,
            }
          }],
          ["QTY", "payload.iquantityunit", {
            // search: {
            //   type: "AdaptiveSearch",
            //   name: "iquantityunit",
            //   getValue: (c) => c.payload.iquantityunit,
            //   contracts: allContracts,
            // },
            options: {
              cellClassName: classNames(classes.customText),
              headerClassName: classes.customText,
            }
          }],
          [`${t('main.uom.lable')}`, "payload.iproduct.sepuom", {
            search: {
              type: "SelectSearch",
              name: "iproduct.sepuom",
              options: Object.keys(UOM).map(value => ({ label: UOM[value], value: value })),
            },
            options: {
              cellClassName: classNames(classes.customText),
              headerClassName: classes.customText,
            }
          }],
          ["Status", "payload.iproductstatus", {
            type: "render",
            render: (param) => (
              <IncomingStatusCell 
                contract={param.row.contract}
                status={param.value}
                handleChange={handleChange}
                role={role}
              />
            ),
            search: {
              type: "SelectSearch",
              name: "iproductstatus",
              options: statusOptions,
            },
            options: {
              width: 200,
              headerAlign: 'center',
              align: 'center',
              headerClassName: classes.customText,
            },
          }],
        ]}

        options={{
          sortKey: 'id',
          checkbox: false,
          height: '450px',
          headerHeight: 38,
          rowHeight: 38,
          getDetailPanelContent: getDetailPanelContent,
        }}
      />

      <EditProductModal
        open={openEditModal}
        setOpen={setOpenEditModal}
        options={{
          isSecondary: true,
          isEnableCompliance: false,
          button: btnConfirm,
          designPattern: productPattern,
          headerPattern: newHeaderPattern,
          isExtraSnackbar: true,
          extraSnackbarContent: toastUdiContent,
          extraSnackbarActions: [{ label: 'Ok' }],
          extraSnackbarOpen: openToastUdi,
          extraSnackbarSetOpen: setOpenToastUdi,
          extraSnackbarOptions: { validateULE: true },
          bodyHeight: editModalHeight,
          isRequireChange: false,
          defaultDisabledFields: ['sepreferencenumber'], // Lock REF when retrieving
          isContractUpdate: true,
        }}
        classnames={{
          body: classes1.newBodyPanel,
          table: classes1.newProductForm,
        }}
      />
    </>
  );
}

const IncomingInventoryContractsWithLoading = withLoading(IncomingInventoryContracts);
export default withFilters(IncomingInventoryContractsWithLoading)([
  { name: "iproduct.sepreferencenumber", label: "Reference Number" },
  { name: "iproduct.seplotcode", label: "Lot/SN" },
  { name: "iproduct.sepproductdescription", label: "Description" },
  { name: "iproduct.sepproductprice", label: "Price" },
  { name: "iproduct.sepuom", label: "UoM" },
  { name: "iquantityunit", label: "Quantity" },
  { name: "iproductstatus", label: "Status" },
]);