import React, { useEffect, useState, useCallback } from "react";
import classNames from "classnames";
import { useLedger } from "@daml/react";
import { Button, AppBar, Tabs, Tab } from "@material-ui/core";
import { useDamlState } from "../../context/DamlContext";
import { useLayoutDispatch, setLoading, unsetLoading } from "../../context/LayoutContext";
import TabPanel, { a11yProps } from "../../components/Modals/MultiTabsModals/TabPanel";
import ModalDialog from '../../components/Modals/NewGeneralModal';
import { EVENT_FINISHED, INVENTORY_UPDATE_EVENT, EVENT_REQUIRED } from "../../store/actions/constants";
import useEvent from "../../hooks/useEvent";
import { useHospitalUser, useItemDetails } from "../../hooks/useProduct";
import { useAccountInventory } from "../../hooks/useInventory";
import { useVendorUser } from "../../hooks/usePO";
import HouseInventoryDetail, { getHouseInventoryDetailInventoryProductHistory } from "./house-inventory-detail";
import { updateInventoryProduct } from "./handle";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "./styles";


/**
 * Dialog of In House / Prior Use Inventory
 * @param {Boolean} open
 * @param {Function} setOpen
 * @param {Object} contract
 * @param {Object} options 
 */
export default function HouseInventory({
  open, setOpen, contract={}, options={}, 
}) {

  options = {
    btnTitle: "Save",         // Button Title
    ...options,
  };

  const classes = useStyles();
  const classes1 = useStyles1();

  const ledger = useLedger();

  const { roleCid,userlistPayload } = useDamlState();

  const layoutDispatch = useLayoutDispatch();

  const { event, status, onEventStart, onEventRun, onEventEnd } = useEvent();
  const { accountInventoryData, setAccountInventoryData } = useAccountInventory();
  const { setHospital } = useHospitalUser();
  const { setVendor } = useVendorUser();
  const { setDetails } = useItemDetails();

  const [value, setValue] = useState(0);
  const [searchInv, setSearchInv] = useState(null);

  const [mainModal, setMainModal] = useState(false);

  // HouseInventoryDetail component with searchInv of InventoryProductHistory
  const HouseInventoryDetailStreamQueries = getHouseInventoryDetailInventoryProductHistory(searchInv);

  // console.log("[HouseInventory]", contract, searchInv);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // handle to click "Save" button
  const handleSaveClick = () => {
    setMainModal(true);
    console.log("[HouseInventory] handleSaveClick");
    onEventStart(INVENTORY_UPDATE_EVENT);
  };

  const handleClose = useCallback(() => {
    setValue(0);
    setOpen(false);
    setMainModal(false);
  }, [setOpen]);

  const handleUpdateAccountInventory = useCallback(async () => {
    console.log("[HouseInventory] handleUpdateAccountInventory", accountInventoryData);

    // Update iparlevels

    // spinner
    setLoading(layoutDispatch);
    onEventRun();

    await updateInventoryProduct({
      ledger,
      ContractId: accountInventoryData.contractId,
      newinventoryproduct: accountInventoryData,
      roleCid,
      userlistPayload
    });
    
    unsetLoading(layoutDispatch);
    onEventEnd();

    // handleClose();


  }, [ledger, roleCid, layoutDispatch, onEventRun, onEventEnd, accountInventoryData,userlistPayload]);


  useEffect(() => {
    if (!open) return;
    console.log("[HouseInventory] set default data.");
    if (contract.payload) {
      setAccountInventoryData({
        ...contract.payload?.inventoryproduct,
        vendor: contract.payload?.vendor,
        iproduct_sepudi: contract.payload?.iproduct_sepudi,
        contractId: contract.contractId,
        inventorydata: contract.payload?.inventorydata,
      });
      setSearchInv({
        operator: contract.payload.operator,
        hospital: contract.payload.hospital,
        vendor: contract.payload.vendor,
        inventoryproduct: {
          ireferencenumber: contract.payload.inventoryproduct?.ireferencenumber,
          iownership: contract.payload.inventoryproduct?.iownership,
        }
      });
      setHospital(contract.payload?.hospital);
      setVendor(contract.payload?.vendor);
      setDetails([]);
    }
    else {
      setAccountInventoryData({});
      setSearchInv(null);
      setHospital(null);
      setVendor(null);
      setDetails([]);
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (!open) return;
    if (event) {
      console.log("[HouseInventory] On Start Event", event);
      if (event === INVENTORY_UPDATE_EVENT && status === EVENT_REQUIRED) {
        handleUpdateAccountInventory();
      }
      else if ((mainModal && event === INVENTORY_UPDATE_EVENT) && (status === EVENT_FINISHED || !!status)) {
        handleClose();
      }
    }
  }, [open, event, status, mainModal,handleClose, handleUpdateAccountInventory]);


  return (
    <>

      <ModalDialog
        open={open}
        setOpen={setOpen}
        title={(
          <AppBar position="static" className={classNames(classes.tabPanelMenu, classes1.detailTabPanelMenu)}>
            <Tabs indicatorColor="primary" textColor="primary" value={value} onChange={handleChange} 
              aria-label="simple tabs example" centered
            >
              <Tab label="In House" {...a11yProps(0)} />
              <Tab label="Prior Use" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
        )}
        content={(
          <>
            <TabPanel value={value} index={0} className={classes.tabPanelbody}>
              <HouseInventoryDetail
                options={options}
              />
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tabPanelbody}>
              <HouseInventoryDetailStreamQueries
                options={{
                  isCheck: true,
                  isPrior: true,
                  ...options,
                }}
              />
            </TabPanel>
          </>
        )}
        actions={(
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleSaveClick} 
            className={classNames(classes.newCompleteBtn)} 
          >
            {options.btnTitle}
          </Button>
        )}
        handleCloseRequest={handleClose}
      />

    </>
  );
}
