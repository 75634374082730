import React from "react";
import classNames from "classnames";
import { Table,TableBody,TableCell,TableHead, TableRow, DialogContentText, Drawer } from "@material-ui/core";
import { formatDate } from "../../services/util";
import ProductDetailViewTable from "../surgicalevent/product-detail-view-table";
import useStyles from "../surgicalevent/styles";


/**
 * Detail View Slide Drawer 
 * Account Inventory Contracts in Hospital & Vendor
 * @param {Boolean} state
 * @param {Function} toggleDrawer
 * @param {Object} selectedInventory
 */
export default function DetailViewDrawer ({
  state, toggleDrawer, selectedInventory,
}) {

  const classes = useStyles();

  // console.log("[DetailViewDrawer]", selectedInventory);


  return (
    <React.Fragment  key={'right'} >
      <Drawer className={classes.customRoot} anchor={'right'} open={state} onClose={()=>{toggleDrawer(false)}}>           
        <div className="view-heading">
          <span>Reference Number:</span> {selectedInventory && selectedInventory.inventoryproduct && selectedInventory.inventoryproduct.ireferencenumber}
        </div>
        
       <DialogContentText component={'div'} className="modelStepDetai">
 
        {selectedInventory && selectedInventory.inventorydata 
          && selectedInventory.inventorydata.length > 0 && 
          <div className="viewDetailsSe">

            <div>
              <Table className="product-detail-data" aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell  className={classNames(classes.tableCellHead, classes.tableCellFixWidth)}>Quantity</TableCell>
                    <TableCell  className={classes.tableCell}>{selectedInventory.inventoryproduct.itemcount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell  className={classNames(classes.tableCellHead, classes.tableCellFixWidth)}>Par Level</TableCell>
                    <TableCell  className={classes.tableCell}>{selectedInventory.inventoryproduct.parlevel}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>

          </div>
        }

        {selectedInventory && selectedInventory.inventorydata 
          && selectedInventory.inventorydata.length > 0 && selectedInventory.inventorydata[0].iproduct &&
          <div className="viewDetailsSe">

            <h2 className={classes.formSubHeading}>Product Details</h2>
            <div>
              <ProductDetailViewTable 
                obj={selectedInventory.inventorydata[0].iproduct}
                options={{
                  hiddenParams: ['sepreferencenumber', 'seplotcode', 'sepproductwaste'],
                }}
              />
            </div>
          
          </div>
        }

        {selectedInventory && selectedInventory.inventorydata 
          && selectedInventory.inventorydata.length > 0 && 
          <div className="viewDetailsSe">

            <div>
              <Table className="product-detail-data" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell  className={classNames(classes.formSubHeading, 'border-none')}>Lot Code</TableCell>
                    <TableCell  className={classNames(classes.formSubHeading, 'border-none')}>Expiration Date</TableCell>
                    <TableCell  className={classNames(classes.formSubHeading, 'border-none')}>Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    selectedInventory.inventorydata.map((invData, index) => (
                      invData.iproductstatus === 'Received' &&
                      <TableRow key={index}>
                        <TableCell  className={classes.tableCell}>{invData.iproduct.seplotcode}</TableCell>
                        <TableCell  className={classes.tableCell}>{formatDate(invData.iproduct.sepproductexpiration)}</TableCell>
                        <TableCell  className={classes.tableCell}>1</TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </div>
          
          </div>
        }

        </DialogContentText>
      </Drawer>
    </React.Fragment>
  );
}
