import { getModelMenu, validateModelItem } from "./handles";

export const CourierCompany = {
    Courier: 'Courier',
    FedEx: 'FedEx',
    UPS: 'UPS',
    DHL: 'DHL',
    UnknownCourier: 'Unknown',
}

export default Object.keys(CourierCompany).map(value => ({
    value,
    label: CourierCompany[value]
}));

export const validateCourierCompany = validateModelItem(CourierCompany);

export const getCourierCompany = getModelMenu(CourierCompany);
