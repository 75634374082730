import React from "react";
import { Checkbox, Button } from "@material-ui/core";
import {
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import useStyles from "../styles";
import CaseUsageElement from "./caseUsageElement";
import { createHashID } from "../../../services/crypto-util";

export default function PrefcaseUsageCategories({ detailData,dispatch }) {
  const classes = useStyles();


 
  return (
    <>
      <Button variant="contained" color="primary" className={classes.useAllBtn}>
        <Checkbox
          checked={detailData.isChecked}
          onChange={(event) => {
            const newSelected =!! event.target.checked;
            dispatch({
              type: "select_page",
              payload: {
                isChecked: newSelected,
              },
            });
          }}
          className={classes.checkboxSelected}
        />
        Use All
      </Button>

      {detailData?.items?.map((element, index) => {
        return (
          <div key={createHashID({ index })}>
            <Typography className={classes.newtableTitle}>
              {element.category.name}
            </Typography>
            <Table className={classes.rowWithBorder}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cellWithBorder} width={150}>
                    Name
                  </TableCell>
                  <TableCell className={classes.cellWithBorder} width={175}>
                    Item Note
                  </TableCell>
                  <TableCell className={classes.cellWithBorder} width={150}>
                    Reference
                  </TableCell>
                  <TableCell className={classes.cellWithBorder} width={75}>
                    Cost
                  </TableCell>
                  <TableCell className={classes.cellWithBorder} width={75}>
                    Used
                  </TableCell>
                  <TableCell className={classes.cellWithBorder} width={75}>
                    Unused
                  </TableCell>
                  <TableCell className={classes.cellWithBorder} width={75}>
                    Open
                  </TableCell>
                  <TableCell className={classes.cellWithBorder} width={75}>
                    Hold
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    className={classes.cellWithBorder}
                    width={75}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {element?.items?.map((rowElement, index) => (
                  <CaseUsageElement
                    dispatch={dispatch}
                    key={createHashID({ index })}
                    rowElement={rowElement}
                  ></CaseUsageElement>
                ))}
              </TableBody>
            </Table>
          </div>
        );
      })}
     
    </>
  );
}
