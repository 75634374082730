import React, { useState } from "react";
import classNames from "classnames";
import { Grid, Typography, Button } from "@material-ui/core";
import { useUserState } from "../../context/UserContext";
import { validateSurgicalStatus } from "../../models/SurgicalEvent";
import ModalDialog from '../../components/Modals/NewGeneralModal';
import { SearchTextSmField } from "../../components/Inputs";
import { contractFilter1 } from "../../services/daml-filters";
import { damlFetchSurgicalEvent, damlFetchPostProcedureVendor } from "../../services/axios/daml-json-api";
import { useSurgicalEvent, useHospitalUser } from "../../hooks/useProduct";
import usePO from "../../hooks/usePO";
import { OverviewSurgicalEvent } from '../surgicalevent/complete-surgicalevent1';
import detailIcon  from "../../icons/new-se-detail-icon1.svg";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "./styles";


/**
 * SE Details Modal
 * @param {Boolean} open 
 * @param {Function} setOpen 
 * @returns 
 */
export default function SEDetailsModal({
  open, setOpen, 
}) {

  const classes = useStyles();
  const classes1 = useStyles1();

  const { surgicalevent: surgicalEventData } = useSurgicalEvent();
  const { purchaseOrderData, setPurchaseOrder } = usePO();


  const handleSaveSE = () => {
    if (surgicalEventData.seid && !purchaseOrderData.poseid) {
      setPurchaseOrder({
        poseid: surgicalEventData.seid,
      });
    }
    setOpen(false);
  };


  return (
    <ModalDialog
      size="md"
      open={open}
      setOpen={setOpen}
      width={517}
      height={575}
      title={(
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.newSETitlePanel}
        >
          <img src={detailIcon} alt="" />
          <Typography variant="inherit" component="span" className="name ref" >
            SE ID: {surgicalEventData.seid}
          </Typography>
        </Grid>
      )}
      content={(
        <OverviewSurgicalEvent
          type="threecolumns"
          className={classes1.overviewNewSEPanel}
        />
      )}
      actions={((surgicalEventData.seid && !purchaseOrderData.poseid && !purchaseOrderData.id) && 
        <>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => setOpen(false)} 
            className={classNames(classes.newCompleteBtn, classes.newInsideAddBtn, "secondary-color")} 
          >
            Cancel
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleSaveSE} 
            className={classNames(classes.newCompleteBtn, classes.newInsideAddBtn)} 
          >
            Save
          </Button>
        </>
      )}
    />
  );
}

/**
 * Hook for Search SE Text Field with SE Details Modal
 * @param {Boolean} isAddableSE 
 * @returns 
 */
export const useSearchSEWithModal = (isAddableSE=true) => {

  const { token } = useUserState();

  const { setSurgicalEvent: surgicalEventData } = useSurgicalEvent();
  const { hospital } = useHospitalUser();

  const [searchValue, setSearchValue] = useState('');
  // Add PO to SE
  const [openSEModal, setOpenSEModal] = useState(false);

  const handleChangeSearch = (val) => {
    val = val.match(/[a-zA-Z0-9]+/g);
    val = val ? val.join('') : '';
    val = val.toUpperCase();
    setSearchValue(val);
  };

  const handleOpenAddSEModal = async () => {
    if (!isAddableSE) return;
    // handleSearchSE
    const surgicaleventcontract = await searchSE({
      seid: searchValue,
      token,
      hospital,
      status: 'Complete', // Only Complete SE
    });
    console.log("[AddedProductHeader] handleOpenAddSEModal", surgicaleventcontract);
    if (surgicaleventcontract) {
      surgicalEventData(surgicaleventcontract.payload.surgicaleventdata);
    }
    else {
      surgicalEventData({});
    }
    setOpenSEModal(true);
  };

  return {
    searchValue,
    handleChangeSearch,
    handleOpenAddSEModal,
    openSEModal,
    setOpenSEModal,
  };
};

/**
 * Search SE Text Field with SE Details Modal
 * @param {String} searchValue 
 * @param {Function} handleChangeSearch 
 * @param {Function} handleOpenAddSEModal 
 * @param {Boolean} openSEModal 
 * @param {Function} setOpenSEModal 
 * @param {Object} style 
 * @param {Number} width 
 * @returns 
 */
export const SearchSEWithModal = ({
  searchValue, handleChangeSearch, handleOpenAddSEModal, openSEModal, setOpenSEModal, style={}, width, 
}) => {

  return (
    <>
      <SearchTextSmField
        value={searchValue}
        onChange={handleChangeSearch}
        onSubmit={handleOpenAddSEModal}
        style={style}
        width={width}
      />
      <SEDetailsModal
        open={openSEModal}
        setOpen={setOpenSEModal}
      />
    </>
  );
};

/**
 * Search Surgical Event with SEId
 * @param {String} seid 
 * @param {Array} contracts 
 * @param {String} token 
 * @param {String} hospital 
 * @param {String|null} vendor 
 * @param {String|null} status 
 * @returns {Object}
 */
export const searchSE = async ({ 
  seid, contracts=[], token, hospital, vendor=null, status=null, 
}) => {
  if (contracts && contracts.length) {
    const query = { 'list.surgicaleventdata.seid': [seid] };
    if (status && validateSurgicalStatus(status)) query['list.surgicaleventdata.status'] = [status];
    const found = contractFilter1(contracts, query);
    if (found && found.length) return found[0];
  }
  else {
    const query = { seid, token };
    if (status && validateSurgicalStatus(status)) query.status = status;
    if (vendor) {
      query.vendor = vendor;
      return await damlFetchPostProcedureVendor(query);
    }
    else {
      query.hospital = hospital;
      return await damlFetchSurgicalEvent(query);
    }
  }
};
