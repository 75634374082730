import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Table, TableBody, TableRow, TableCell, Grid } from "@material-ui/core";
import { useUserState } from "../../context/UserContext";
import { useDamlState } from "../../context/DamlContext";
import { Ownerships } from "../../models/Ownership";
import { PoType } from "../../models/Po";
import { Salutations } from "../../models/Contact";
import { CustomSelect, CustomTextField, DateInput } from "../../components/Inputs";
import usePO from "../../hooks/usePO";
import { useSurgicalEvent, useHospitalUser } from "../../hooks/useProduct";
import { OverviewSurgicalEvent } from "../surgicalevent/complete-surgicalevent1";
import { InputDistributor } from "../surgicalevent/edit-product1";
import useStyles from "../surgicalevent/styles";
import { useAddEditPO } from "./add-po";
import { searchSE } from "./se-details-modal";
import useStyles1 from "./styles";
import SnackbarModal from "../../components/Modals/SnackbarModal";
import { useTranslation } from 'react-i18next';

/**
 * Overview Purchase Order
 * @param {Object} options 
 * @returns 
 */
export default function EditPO({
  options = {},
}) {

  options = {
    isEditable: false,
    isPONumber: false,
    showSE: true,
    ...options,
  };

  const classes = useStyles();
  const classes1 = useStyles1();
  const { t } = useTranslation();
  const { token } = useUserState();

  const { role, party } = useDamlState();

  const { purchaseOrderData, purchaseOrderErrors: purchaseOrderError } = usePO();
  const { surgicalevent, setSurgicalEvent } = useSurgicalEvent();
  const { hospital } = useHospitalUser();
  const { handleChange, handleUpdateAction } = useAddEditPO();
  // Vendor
  const vendor = (role === 'Vendor' ? party : null);

  const [stockConfirm, setStockConfirm] = useState(false);
  const [ownershipChanged, setOwnershipChanged] = useState(false);
  const [clicked, setClicked] = useState('');

  const handleChangeOwnership = (val) => {
    console.clear();
    console.log("val",val);
    if (val === "Stock" && purchaseOrderData.poseid) setStockConfirm(true);
    else if ((val === "Loaned" || "Owned" || "Consignment") && purchaseOrderData.poseid) {
      setClicked(val);
      setOwnershipChanged(true);
      handleChange(val, 'popurchaseordertype')
    }
    else handleChange(val, 'popurchaseordertype');
  };
  const handleChangeSE = (value, key) => {
    if (typeof setSurgicalEvent === 'function') {
      setSurgicalEvent({
        [key]: value,
      });
    }
  };
  useEffect(() => {
    handleUpdateAction();
  }, [handleUpdateAction]);

  useEffect(() => {
    const loadSE = async () => {
      const surgicaleventcontract = await searchSE({
        seid: purchaseOrderData.poseid,
        token,
        hospital,
        vendor,
      });
      if (surgicaleventcontract) setSurgicalEvent(surgicaleventcontract.payload.surgicaleventdata);
    };
    if (purchaseOrderData.poseid && purchaseOrderData.poseid !== surgicalevent.seid) loadSE();
  }, [token, hospital, vendor, purchaseOrderData.poseid, surgicalevent.seid, setSurgicalEvent]);


  return (
    <Grid container>
      <Grid item className={classNames(classes.overview, classes1.overviewSEPanel)}>
        <SnackbarModal
          open={stockConfirm}
          setOpen={setStockConfirm}
          type="warning"
          title={'You are declaring this product as Hospital Stock it will be removed from the PO and will not be able to be undone'}
          content={' '}
          vertical="center"
          horizontal="center"
          width={500}
          height={130}
          transition="fade"
          className={classNames(classes.toastLarge, classes.toastWarning)}
          options={{
            delay: 500,
          }}
          actions={[
            {
              label: 'Acknowledge',
              handle: () => {
                handleChange("Stock", 'popurchaseordertype');
              },
            },
            {
              label: 'Decline',
            }
          ]}
        />
        <SnackbarModal
          open={ownershipChanged}
          setOpen={setOwnershipChanged}
          type="warning"
          title={' '}
          content={`${t('error.po.edit-po.first-message-change-ownership')} ${purchaseOrderData.popurchaseorderid} ${t('error.po.edit-po.second-message-change-ownership')}`}
          vertical="center"
          horizontal="center"
          width={400}
          height={160}
          transition="fade"
          className={classNames(classes.toastLarge, classes.toastWarning)}
          options={{
            delay: 500,
            validateULE: true,
          }}
          actions={[
            {
              label: 'Acknowledge',
              handle: () => {
                handleChange( clicked , 'popurchaseordertype');
              },
            },
            {
              label: 'Decline',
            }
          ]}
        />
        <Table className={classes.newSEForm}>
          <TableBody>
            <TableRow>
              <TableCell colSpan={2} className="new-subtitle" style={{ paddingTop: 0 }}>PO Details</TableCell>
            </TableRow>
            {
              options.isEditable
                ?
                <>
                  <TableRow>
                    <TableCell style={{ paddingRight: '10px' }}>
                      <CustomSelect
                        label="PO Type"
                        value={purchaseOrderData.potype || ""}
                        onChange={(val) => handleChange(val, 'potype')}
                        items={PoType}
                        placeholder="Select One"
                        warning={purchaseOrderError.potype}
                        width={143}
                        options={{
                          readOnly: true,
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <InputDistributor
                        label="Distributor"
                        distributor={purchaseOrderData.povendorid}
                        setDistributor={(val) => handleChange(val, 'povendorid')}
                        placeholder="Select One"
                        warning={purchaseOrderError.povendorid}
                        width={143}
                        readOnly={true}
                        disabled={true}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <DateInput
                        label="PO Date"
                        value={purchaseOrderData.podate || ""}
                        onChange={(val) => handleChange(val, 'podate')}
                        warning={purchaseOrderError.podate}
                        width={143}
                        options={{
                          readOnly: true,
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <CustomSelect
                        label="Ownership"
                        value={purchaseOrderData.popurchaseordertype || ""}
                        onChange={(val) => handleChangeOwnership(val)}
                        items={Object.entries(Ownerships)
                          .map(([key, val]) => ({ label: val, value: key, disabled: (key === 'Unknown') }))}
                        placeholder="Select One"
                        warning={purchaseOrderError.popurchaseordertype}
                        width={143}
                        options={{
                          readOnly: true,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </>
                :
                <>
                  {
                    options.isPONumber && 
                    <>
                    <TableRow>
                      <TableCell className="label">PO Number</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="value">{purchaseOrderData.popurchaseorderid}</TableCell>
                    </TableRow>
                    </>
                  }
                  <TableRow>
                    <TableCell className="label">PO Type</TableCell>
                    <TableCell className="label">Distributor</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="value">{purchaseOrderData.potype}</TableCell>
                    <TableCell className="value">{purchaseOrderData.povendorid}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="label">PO Date</TableCell>
                    <TableCell className="label">Ownership</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="value">{purchaseOrderData.podate}</TableCell>
                    <TableCell className="value">{purchaseOrderData.popurchaseordertype}</TableCell>
                  </TableRow>
                </>
            }
          </TableBody>
        </Table>

        {
          options.showSE && purchaseOrderData.poseid &&
          <>
            <OverviewSurgicalEvent
              type="twocolumns"
              className={classes1.overviewSEPanel}
              options={{
                isPatient: (vendor === null),
                //hiding read only physician details because we are providing edit functionality
                hidePhysicianDetails:(vendor?false:
                  (purchaseOrderData.postatus === "Initiated" ||
                    purchaseOrderData.postatus === "Rejected" ||
                    purchaseOrderData.postatus === "MissingInfo" ||
                    purchaseOrderData.postatus === "Updated"))
              }}
            />
            {
              // here checking if user is vendor or not and based on PO status we are providing Edit functionality 
              (!vendor && (purchaseOrderData.postatus === "Initiated" ||
                purchaseOrderData.postatus === "Rejected" ||
                purchaseOrderData.postatus === "MissingInfo" ||
                purchaseOrderData.postatus === "Updated"))
              &&
              <Table className={classes.newSEForm}>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={2} className="new-subtitle">
                      Physician Details
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <CustomSelect
                        value={surgicalevent.physiciansalutation}
                        label="Salutation"
                        items={Salutations}
                        width={152}
                        onChange={(val) => handleChangeSE(val, 'physiciansalutation')}
                        options={{
                          readOnly:true
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <CustomTextField
                        value={surgicalevent.physicianlastname}
                        warning={surgicalevent.physicianlastname===""?"Last Name is required":""}
                        label="Last Name" width={152}
                        onChange={(val) => handleChangeSE(val, 'physicianlastname')}
                        options={{
                          readOnly:true
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <CustomTextField
                        value={surgicalevent.physicianfirstname}
                        warning={surgicalevent.physicianfirstname===""?"First Name is required":""}
                        label="First Name"
                        width={152}
                        onChange={(val) => handleChangeSE(val, 'physicianfirstname')}
                        options={{
                          readOnly:true
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            }
          </>

        }

      </Grid>
    </Grid>
  );
}
