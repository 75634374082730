import React, { useEffect } from "react";
import { useStreamQueries } from "@daml/react";


export default function SearchContracts({
  template = null,
  searchParams = {},
  getValue = (c) => c,
  setLoading = (load) => load,
  setOptions = (opts) => opts,
}) {

  const {loading, contracts} = useStreamQueries(template, () => [searchParams]);

  console.log("[SearchContracts]", searchParams, loading, contracts);


  useEffect(() => {
    setLoading(loading);
    setOptions(contracts.map(getValue));
  }, [loading, contracts, getValue, setLoading, setOptions]);



  return (
    <></>
  );
}
