import React, { useState } from "react";
import classNames from "classnames";
import { useParty } from "@daml/react";
import { useLedger } from "@daml/react";
// import { Chip } from "@material-ui/core";
import { /* PostProcedureHospital, */ PostProcedureHospitalHistory } from "../../services/daml-modules1";
import AdaptiveSearch from "../../components/Search/adaptivesearch";
import DateSearch from "../../components/Search/datesearch";
import Contracts from "../../components/Contracts/Contracts";
import { contractFilter } from "../../services/daml-filters";
import { getTemplate } from "../../services/util";
import ConfirmSimpleModal from "../../components/Modals/ConfirmSimpleModal";
import { archivePPH } from "./handle";
import archiveIcon from "../../icons/folder-plus.svg";
import archiveDisableIcon from "../../icons/folder-plus-disable.svg";
import DetailViewDrawer from "./detail-view-drawer";
import ModalDialogBox from '../../components/Modals/GeneralModal/modalDialogBox';
import { useDamlStream } from "../../hooks/useDAML";
import { useDamlState } from "../../context/DamlContext";
// import useStyles from "./styles";
import useStyles from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";


/**
 * PPF List Contracts in Hospital
 * @param {Boolean} isDetailModalOrPage 
 * @param {Function} setDetailModalOpen 
 * @param {Function} setDetailContract 
 * @param {Object} searchParams 
 * @param {Object} searchHistoryParams 
 * @param {Boolean} isIncludeHistory 
 * @param {Boolean} isAutoVendor 
 * @param {Function} handleChangeSearchValue 
 * @param {Object} searchProcedure 
 * @param {Array} allContracts 
 * @returns 
 */
function PostProcedureListContracts({
  isDetailModalOrPage = false,
  setDetailModalOpen = null,
  setDetailContract = null,
  searchParams = {},
  searchHistoryParams = {},
  isIncludeHistory = false,
  isAutoVendor = false,
  handleChangeSearchValue = null,
  searchProcedure = {},
  allContracts = [],
}) {

  const classes = useStyles();


  // const [ContractId, setContractId] = useState('');
  const ledger = useLedger();
  const hospital = useParty();


  // const assets = useStreamQueries(PostProcedureHospital, () => [searchParams]);
  const { roleCid } = useDamlState();

  const assetsHistory = useDamlStream(PostProcedureHospitalHistory, [{ hospital }], isIncludeHistory);

  allContracts = allContracts.concat(isIncludeHistory && assetsHistory.contracts ? 
    assetsHistory.contracts.map(h => ({ ...h, payload: h.payload.postprocedurehospital })) : []);
  let contracts = contractFilter(allContracts, searchParams);

  console.log("[PostProcedureListContracts] assets:", contracts, "searchParams:", searchParams);
  // console.log("[PostProcedureListContracts] assetsHistory:", assetsHistory, isIncludeHistory);


  const [poDatalist, setPODatalist] = useState([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmRVDialogOpen, setConfirmRVDialogOpen] = useState(false);
  const [rvDatalist, setRvDatalist] = useState([]);
  const [pphData, setPPHData] = useState({});
  const [productList, setProductList] = useState([]);
  const [detailViewer, setDetailViewer] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [archiveModalContract, setArchiveModalContract] = useState({});

  const checkNotPPHHistory = (c) => (getTemplate(c) !== "PostProcedureHospitalHistory");


  /**
   * If Release to PostProcedureVendor manually, it should be true
   * If it's impossible to Release to PostProcedureVendor manually, it should be false
   */
  // const isReleasePPVManually = false;


  // Click "Archive PostProcedure" in list
  const openArchivePPH = (c) => {
    console.log('[PPHContracts] handleArchivePPH', c);
    setArchiveModalContract(c);
    setArchiveModalOpen(true);
  };

  const handleArchivePPH = async () => {
    await archivePPH({
      ledger,
      contractId: archiveModalContract.contractId,
      payload: archiveModalContract.payload,
      roleCid,
    });
    setArchiveModalOpen(false);
  };


  return (
    <>
      <Contracts
        contracts={contracts}
        columns={[
          // ["ContractId", "contractId"],
          ["SE Id", "payload.surgicaleventdata.seid", {
            type: "link",
            onClick: (contractId) => {
              const data = contracts.find(c => c.contractId === contractId);
              // console.log("[postproceduredetail] data", data);
              setPPHData({ ...data.payload.surgicaleventdata, created_at: data.payload.created_at });
              setProductList(data.payload.surgicaleventproductdata.map(detail => detail.product));
              setDetailViewer(true);
            },
            options: {
              width: 120,
              cellClassName: classNames(classes.primaryColor),
            },
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.surgicaleventdata.seid}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'seid')}
                  defaultValue={searchProcedure.seid}
                  label="Search SE ID"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchProcedure.seid,
            handleClear: () => handleChangeSearchValue('', 'seid'),
          }],
          ["MRN", "payload.surgicaleventdata.mrn", {
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.surgicaleventdata.mrn}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'mrn')}
                  defaultValue={searchProcedure.mrn}
                  label="Search MRN"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchProcedure.mrn,
            handleClear: () => handleChangeSearchValue('', 'mrn'),
            options: {
              width: 150,
            },
          }],
          ["Event Date", "payload.surgicaleventdata.eventdate", {
            search: {
              component:
                <DateSearch
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'eventdate')}
                  defaultValue={searchProcedure.eventdate}
                  label="Search Event Date :"
                  isStatic={true}
                />
            },
            isSearched: searchProcedure.eventdate,
            handleClear: () => handleChangeSearchValue('', 'eventdate'),
            options: {
              width: 160,
            },
          }],
          ["Patient Name", ["payload.surgicaleventdata.patientfirstname", "payload.surgicaleventdata.patientlastname"], {
            options: {
              flex: 1,
              tooltip: true,
            },
          }],
          ["Physician Name", ["payload.surgicaleventdata.physiciansalutation", "payload.surgicaleventdata.physicianfirstname", "payload.surgicaleventdata.physicianlastname"], {
            search: [
              {
                label: "First Name",
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.surgicaleventdata.physicianfirstname}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'physicianfirstname')}
                    defaultValue={searchProcedure.physicianfirstname}
                    placeholder="First Name of Physician"
                    contracts={allContracts}
                  />
              },
              {
                label: "Last Name",
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.surgicaleventdata.physicianlastname}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'physicianlastname')}
                    defaultValue={searchProcedure.physicianlastname}
                    placeholder="Last Name of Physician"
                    contracts={allContracts}
                  />
              }
            ],
            isSearched: (!!searchProcedure.physicianfirstname || !!searchProcedure.physicianlastname),
            handleClear: () => {
              if (!!searchProcedure.physicianfirstname && !!searchProcedure.physicianlastname) {
                handleChangeSearchValue('', ['physicianfirstname', 'physicianlastname']);
              }
              else if (!!searchProcedure.physicianfirstname) handleChangeSearchValue('', 'physicianfirstname');
              else if (!!searchProcedure.physicianlastname) handleChangeSearchValue('', 'physicianlastname');
            },
            options: {
              flex: 1,
            },
          }],
          ["Procedure", "payload.surgicaleventdata.surgicalprocedure", {
            options: {
              flex: 1,
            },
          }],
          // ["Price", "payload.price", {
          //   options: {
          //     type: 'number',
          //   },
          // }],
          ["Products", "payload.surgicaleventproductdata", {
            type: "object",
            options: {
              cellClassName: classes.centerCell,
              width: 80,
            },
          }],
          // ["Status", "payload.is_create_po", {
          //   type: "render",
          //   render: (param) => (
          //     <Chip 
          //       label={param.value==='True'?"PO Created":"Sent to Vendor"} 
          //       size="small" 
          //       className={classNames(classes.smallChip, (param.value === 'True' ? classes.backGreen : classes.backYellow))} 
          //     />),
          //   options: {
          //     width: 110,
          //     cellClassName: classes.centerCell,
          //   },
          // }],
          ["Sent Date", "payload.created_at", {
            options: {
              width: 150,
            },
          }],
        ]}

        actions={[
          [
            "Archive",
            [
              [["Archive", [<img alt="" src={archiveIcon} />, <img alt="" src={archiveDisableIcon} />],
                (c) => checkNotPPHHistory(c)],
                openArchivePPH],
            ],
            {
              width: 100,
            }
          ],
        ]}

        options={{
          statusEmpty: (allContracts.length === 0)
        }}


      />


      <ConfirmSimpleModal
        modaltitle="Purchase Order Products are created"
        contractList={poDatalist}
        setContractList={setPODatalist}
        textFunction={(d) => `You have created ${d} new Purchase Order(s).`}
        mapFunction={(c) => `${c["PO Number"]} for Vendor ${c["Vendor"]}`}
        confirmModalOpen={confirmModalOpen}
        setConfirmModalOpen={setConfirmModalOpen}
        confirmDialogOpen={confirmDialogOpen}
        setConfirmDialogOpen={setConfirmDialogOpen}
      />

      <ConfirmSimpleModal
        modaltitle="PostSurgeryForms are created"
        contractList={rvDatalist}
        setContractList={setRvDatalist}
        textFunction={(d) => `You have created ${d} new PostSurgeryForm(s).`}
        mapFunction={(c) => `${c["surgicaleventproduct"].length} products for MRN ${c["mrn"]}`}
        confirmDialogOpen={confirmRVDialogOpen}
        setConfirmDialogOpen={setConfirmRVDialogOpen}
      />

      <DetailViewDrawer
        state={detailViewer}
        toggleDrawer={(open) => setDetailViewer(open)}
        selectedEventData={pphData}
        productItemsList={productList}
        isPPH={true}
      />

      <ModalDialogBox
        open={archiveModalOpen}
        setOpen={setArchiveModalOpen}
        heading="Confirm"
        type="warning"
        text={'<p>Do you want to archive Post Procedure Form now ?</p>'}
        handleRequest={handleArchivePPH}
        secondBtn={'No'}
        handleSecRequest={() => setArchiveModalOpen(false)}
      />

    </>
  );
}

export default withLoading(PostProcedureListContracts);