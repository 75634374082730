import React, { useState, useEffect } from "react";
import {
  Grid, Button, Dialog, DialogContent, DialogTitle, Typography
} from "@material-ui/core";
import { UserModel, requiredUserInvite } from "../../models/User";
import { validateEmail } from "../../services/util";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "./styles";
import closeIcon from "../../icons/new-close-icon.svg";
import { CustomTextField } from "../../components/Inputs";
import { setByPath } from "../../components/Contracts/functions";
import { OnboardNetwork } from "../../services/daml-modules1";
import { useStreamQueries } from "@daml/react";
import { InputDistributor } from "../surgicalevent/edit-product1";

/**
 * Create User Dialog
 * @param {Boolean} openInvite 
 * @param {Function} setOpenInvite 
//  * @param {Function} handleInviteRequest 
 * @param {String} title 
 * @param {String} editTitle 
 * @param {Object} defaultValue 
 * @param {String} role
 * @returns 
 */
export default function InviteVendorUser({
  openInvite,
  setOpenInvite,
//   handleInviteRequest,
  defaultValue = {},
  role,
  authUsersList,
  inviteOrgazationHandler
}) {

  const classes = useStyles();
  const classes1 = useStyles1();
  const [userItems, setUserItems] = useState({});
  const [userError, setUserError] = useState({});
//   const [inviteUser, setInviteUser] = useState(false);

  const { contracts } = useStreamQueries(OnboardNetwork, () => [
    {},
  ]);
  const handleChange = (value, key) => {

    setUserItems({
      ...userItems,
      [key]: value,
    });
  };

  // handle when click "Submit" or "Update" button
  const handleSave = async () => {
    let newErrors = requiredUserInvite(userItems);
    console.log("[AddEditUser] handleSave", newErrors);

    if ((Object.keys(newErrors).length > 0)) {
      setUserError({ ...newErrors });
      return false;
    }

   
    if (!validateEmail(userItems.email)) {
      setUserError({ ...UserModel, email: 'Please enter a valid email address' });
      return false;
    }

    let vendor = contracts.filter(item => item.payload.name === userItems.distributor);
    await inviteOrgazationHandler({name: userItems.name,email: userItems.email,vendor: userItems.distributor, vendorEmail: vendor[0].payload.email })
  };


  useEffect(() => {
    if (openInvite) {
      console.log("[invitevendoruser] Open invitevendoruser dialog.");
      setUserItems({ ...UserModel, ...defaultValue });
      setUserError({ ...UserModel });
      // setPassword("");
    //   setInviteUser(false);
    }
  }, [openInvite, defaultValue]);

  return (
    <Dialog
      open={openInvite}
      onClose={() => { setOpenInvite(false) }}
      className={classes.customRoot}
      disableEnforceFocus
      aria-labelledby="draggable-dialog-title"
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >

        <Button
          className={classes.closeButton}
          onClick={() => { setOpenInvite(false) }}
        >
          <img src={closeIcon} alt="" />
        </Button>
      </Grid>
      <DialogTitle className='MuiDialogTitleRoot'>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes1.newDIATitlePanel}
        >
          <Typography variant="inherit" component="span" className={classes1.popUpTitle}>
            Invite Vendor User
          </Typography>
        </Grid>

      </DialogTitle>
      <DialogContent
        dividers={true}
      >
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={2}>
            <label className={classes.popUpLabel}>Distributor</label>
          </Grid>
          <Grid item xs={9}>
            {/* <CustomSelect
              items={vendors}
              value={userItems.distributor}
              warning={userError.distributor}
              placeholder="Select One"
              width={'84%'}
              onChange={(e) => {
                setUserItems(setByPath(e, 'distributor', userItems))
              }}
            /> */}
            <InputDistributor
              distributor={userItems.distributor}
              setDistributor={(val) => {
                setUserItems(setByPath(val, 'distributor', userItems))
              }}
              placeholder="Select One"
              warning={userError.distributor}
              width={'84%'}
            />
          </Grid>
          <Grid item xs={2}>
            <label className={classes.noteTitle}>Name</label>
          </Grid>
          <Grid item xs={9}>
            <CustomTextField
              value={userItems?.name || ""}
              placeholder="Type here..."
              warning={userError.name}
              width={'100%'}
              onChange={(e) => handleChange(e, 'name')}
            />
          </Grid>
          <Grid item xs={2}>
            <label className={classes.noteTitle}>Email</label>
          </Grid>
          <Grid item xs={9}>
            <CustomTextField
              id="input-with-icon-adornment5"
              placeholder="Type here..."
              warning={userError.email}
              helperText={userError.email}
              className={classes.customFullWidth}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              value={userItems.email || ""}
              onChange={(e) => handleChange(e, 'email')}
            //   onChange={(e) => onChangeEmailHandler(e)}
              variant="outlined"
            />
          </Grid>

        </Grid>
        <br></br>
        <div className="right-buttons">
          <Button autoFocus onClick={() => { setOpenInvite(false) }} className="noBtn" variant="outlined" color="primary">
            Cancel
          </Button>

          <Button  onClick={handleSave} className="yesBtn" variant="contained" color="primary">
              Send Invite to Administrator
            </Button> 
        </div>
      </DialogContent>
    </Dialog>
  );
}
