import axios from "axios";
import { ledgerId, isProjectDabl, IS_PROJECTDABL_SA, DefaultOperatorParty,
  bearerTokenKey, damlTokenDateKey, damlOperatorKey, isSextant, UPDATE_ES_ENDPOINT } from "../../config";
import { AccountInventory, RestockInventory, ContractPrice, SurgicalEvent, PostProcedureVendor, PurchaseOrder, 
  PurchaseOrderVendor, PrefCards } from "../daml-modules1";
import { findMatchedInventoryData } from "../../models/InventoryData";
import { createElasticsearchQuery } from "./retrieves";


// get public token using ledgerId [projectdabl]
export const getTokenProjectDabl = async () => {

    try {
        let requestOptions = {
            method: 'POST',
        };
        let response = await fetch(`https://api.projectdabl.com/api/ledger/${ledgerId}/public/token`, requestOptions);
        let jsonResp = await response.json();
        console.log("[getTokenProjectDabl]", jsonResp);

        if (jsonResp && jsonResp.access_token) {
            // localStorage.setItem(damlTokenKey, jsonResp.access_token);
            return jsonResp.access_token;
        }
    }
    catch (e) {
        console.warn("[getTokenProjectDabl]", e);
    }

    return null;

};

const storeToken = async (token=null) => {
    // console.log("[storeToken]", token);
    // token
    if (!token) {
        // Local access token
        token = (window.location.hostname === 'localhost') ? 
            process.env.REACT_APP_TOKEN_LOCAL : 
            process.env.REACT_APP_TOKEN_PROJECTDABL;

        if (isSextant) {
            token = process.env.REACT_APP_TOKEN_SEXTANT;
        }
        else if (isProjectDabl) {
            token = await getTokenProjectDabl();
        }
    }
    if (!!token) {
        // it's disable to store token in local storage & window global variable now
        // localStorage.setItem(damlTokenKey, token);
        // window.globalToken = token;
    }
    return token;
};
    
// get token
export const getToken = () => {
    // console.log("[getToken]");
    // const token = localStorage.getItem(damlTokenKey);
    const token = localStorage.getItem(bearerTokenKey);
    if (!token) {
        storeToken();
        if (!!window.globalToken) return window.globalToken;
    }
    return token;
};

// get well known parties [projectdabl]
export const getWellKnownParties = async () => {
    
    try {
        let requestOptions = {
            method: 'GET',
        };
        let response = await fetch(`https://${ledgerId}.projectdabl.com/.well-known/dabl.json`, requestOptions);
        let jsonResp = await response.json();
        console.log("[getWellKnownParties]", jsonResp);

        // userAdminParty
        /* if (jsonResp && jsonResp.userAdminParty) {
            return jsonResp.userAdminParty;
        } */

        // publicParty
        if (jsonResp && jsonResp.publicParty) {
            return jsonResp.publicParty;
        }
    }
    catch (e) {
        console.warn("[getWellKnownParties]", e);
    }

    return null;

};

// get all parties and save Operator Party [localhost, projectdabl]
/**
 * Default false
 * @param {*} recieveAll | To get all parties or else only first partiy will get return 
 * @returns 
 */
export const getParties = async (recieveAll=false) => {
  
    try {

        const partiesResponse = await get('/v1/parties');

        const partiesResponseJson = await partiesResponse.json();
        if (partiesResponseJson.status === 200) {
            // console.log("[getParties] response", partiesResponseJson);

            if (typeof partiesResponseJson.result === "object" && partiesResponseJson.result.length > 0) {
                const parties = partiesResponseJson.result;
                const operatorParty = partiesResponseJson.result[0].identifier;
                // localStorage.setItem(damlOperatorKey, operatorParty);
                console.log("[getParties] operatorParty", operatorParty, parties);
                return recieveAll ? parties : operatorParty;
            }
        }
        else {
            console.log("[getParties] response status is not 200", partiesResponse);
        }
    }
    catch (e) {
        console.warn("[getParties]", e);
    }

    return null;

};

const storeOperator = async (operator=null) => {
    // operator party
    if (!operator) {
        operator = DefaultOperatorParty;

        if (isProjectDabl) {
            operator = await getWellKnownParties();
            // operator = await getParties();
        }
    }
    if (!!operator) localStorage.setItem(damlOperatorKey, operator);
    return operator;
};

// get Operator Party
export const getOperatorParty = () => {
    const operator = localStorage.getItem(damlOperatorKey);
    if (!operator) {
        storeOperator();
        return null;
    }
    return operator;
};

const siteSubDomain = (path = '/data/') => {
    if (isSextant) {
        return process.env.REACT_APP_SEXTANT_DOMAIN;
    }

    if (window.location.hostname === 'localhost') {
        return window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    }

    let host = window.location.host.split('.')
    const ledgerId = host[0];
    let apiUrl = host.slice(1)
    apiUrl.unshift('api')

    return apiUrl.join('.') + (window.location.port ? ':' + window.location.port : '') + path + ledgerId;
};

const headers = (token=null) => ({
    "Authorization": `Bearer ${(token ? token : getToken())}`,
    'Content-Type': 'application/json'
});

export const get = (url, options = {}) => {
    // console.log("[get] options", options);
    Object.assign(options, { method: 'GET', headers: headers(options.token) });
    return fetch('//' + siteSubDomain() + url, options);
}

export const post = (url, options = {}) => {
    // console.log("[post] options", options);
    Object.assign(options, { method: 'POST', headers: headers(options.token) });
    return fetch('//' + siteSubDomain() + url, options);
}

/* const fetchPublicToken = async () => {
    const response = await fetch('//' + siteSubDomain('/api/ledger/') + '/public/token', { method: 'POST' });
    const jsonResp = await response.json();
    const accessToken = jsonResp['access_token'];
    return accessToken;
} */

// get token by service account cred [projectdabl]
export const getTokenByCred = async () => {
    
    try {

        if (!IS_PROJECTDABL_SA) return process.env.REACT_APP_TOKEN_PROJECTDABL;

        let myHeaders = new Headers();
        myHeaders.append("Authorization", `Basic ${btoa(process.env.REACT_APP_CRED_PROJECTDABLE)}`);
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };

        let response = await fetch("https://login.projectdabl.com/sa/login", requestOptions);
        let jsonResp = await response.json();
        console.log("[getTokenByCred]", jsonResp);
    }
    catch (e) {
        console.warn("[getTokenByCred]", e);
    }

    return null;

};

// store token & opertor to localStorage
export const storeTokenOperator = async (params = {}) => {
    // console.log("[storeTokenOperator]");

    try {
        const today_now = Date.now();
        let {token, operator, force} = params;

        // check store datetime within 24 hours
        let token_date = localStorage.getItem(damlTokenDateKey);
        if (!force && !!token_date && parseInt(token_date) > (today_now - 24 * 3600 * 1000)) {
            let tempToken = getToken();
            let tempOperator = getOperatorParty();
            if (((!!token && token.toString() === tempToken) || (!token && !!tempToken))
                && ((!!operator && operator.toString() === tempOperator) || (!operator && !!tempOperator))) return;
            token = token || tempToken;
            operator = operator || tempOperator;
        }

        token = await storeToken(token);

        operator = await storeOperator(operator);    

        // store
        if (!!token && !!operator) {
            localStorage.setItem(damlTokenDateKey, today_now);
            console.log("[storeTokenOperator] token:" + token + ", operator:" + operator + ", token_date:" + token_date);
        }
    }
    catch (e) {
        console.warn("[storeTokenOperator]", e);
    }

};

/**
 * Allocate a New Party
 * @param {String} party 
 * @returns {Boolean}
 */
export const allocateParty = async (party, rToken) => {

    try {
        let response = await post("/v1/parties/allocate", {
            body: JSON.stringify({
                "identifierHint": party,
                "displayName": party
            }),
            token: rToken
        });
        console.log("[allocateParty]", response);
        return true;
    }
    catch (e) {
        console.warn("[allocateParty]", e);
    }

    return false;
};

/**
 * DAML Fetch
 * @param {String} token 
 * @param {Object} template 
 * @param {Object} searchParams 
 * @returns {Array} 
 */
export const damlFetch = async ({
    token, template, searchParams={}, 
}) => {
    if (!token) return [];
    if (!template || !template.templateId) return [];
    console.log("[damlFetch] template", template.templateId, "searchParams", searchParams);

    try {
        const res = await post('/v1/query', {
            body: JSON.stringify({
                "templateIds": [template.templateId],
                "query": searchParams,
            }),
            token,
        });
        const json = await res.json();
        console.log("[damlFetch] response", json);
        return json.result;
    }
    catch (e) {
        console.log("[damlFetch]", e);
    }
    return [];
};

/**
 * DAML Fetch in Account Inventory
 * find out the best matched Account Inventory Received
 * @param {String} token 
 * @param {String} hospital 
 * @param {String} referencenumber 
 * @param {String} lotcode 
 * @param {String} ownership 
 * @param {String} sepproductexpiration 
 * @param {String|undefined} distributor 
 * @returns {Array|null} 
 */
export const damlFetchInventory = async ({
    token, hospital, referencenumber, lotcode, ownership, sepproductexpiration, distributor, 
}) => {
    if (!token || !hospital || !referencenumber) return {};
    const contracts = await damlFetch({
        token,
        template: AccountInventory,
        searchParams: {
            hospital,
            inventoryproduct: {
                ireferencenumber: referencenumber,
            },
        },
    });
    // console.log("[damlFetchInventory] contracts", contracts);
    if (contracts.length > 0) {
        // find out the matched Inventory Data
        const allInventoryData = contracts.reduce((total, c) => total.concat(c.payload.inventorydata), []);
        const [targetInvd, expOldProducts] = findMatchedInventoryData(allInventoryData, 
            {
                iproductstatus: 'Received',
                iproduct: {
                    sepreferencenumber: referencenumber,
                    sepownership: ownership,
                    seplotcode: lotcode,
                    sepproductexpiration: sepproductexpiration,
                    sepdistributor: distributor,
                },
            }, 
            {
                limitDelta: 100, // Old case, it's 1000. But it should be in shelf for now.
                isArray: false,
                isReturnExpOld: true,
            });
        // console.log("[damlFetchInventory] found inventory data", targetInvd);
        if (targetInvd && 'iproduct' in targetInvd) {
            return [targetInvd.iproduct, expOldProducts];
        }
    }
    return null;
};

/**
 * DAML Fetch in Contract Price
 * Match UDI / Distributor if multiple items with the same REF
 * @param {String} token 
 * @param {String} hospital 
 * @param {String} referencenumber 
 * @param {String|undefined} udi 
 * @param {String|undefined} distributor 
 * @returns {Object} 
 */
export const damlFetchContractPrice = async ({
    token, hospital, referencenumber, udi, distributor, 
}) => {
    if (!token || !referencenumber) return {};
    if (!hospital) return { error: "Should select hospital." };
    console.log("[damlFetchContractPrice] params", hospital, referencenumber, udi, distributor);
    let result = null, error = null, data = [], index = -1;
    const contracts = await damlFetch({
        token,
        template: ContractPrice,
        searchParams: {
            hospital,
            cdata: {
                sepreferencenumber: referencenumber,
            },
        },
    });
    // console.log("[damlFetchContractPrice] contracts", contracts);
    const len = contracts.length;
    if (len > 0) {
        data = contracts.map(c => c.payload.cdata);
        let product = {}, cmp = -1;
        // find out to match udi, distributor
        for (let i = 0; i < len; i++) {
            const c = data[i];
            // check out sepudi, sepesudi of product
            const tempudi = !!c.sepudi ? c.sepudi : (!!c.sepesudi ? c.sepesudi : null);
            let tempcmp = 0;
            if (!udi) tempcmp += 10;
            else if (udi === tempudi) tempcmp += 30;
            else if (tempudi === null) tempcmp += 20;        // Optional case: udi of ContractPrice is null
            if (!distributor) tempcmp += 1;
            else if (distributor === c.sepdistributor) tempcmp += 2;
            // console.log("[damlFetchContractPrice] compare", cmp, tempcmp, c);
            if (cmp < tempcmp) {
                product = {...c};
                // Update sepesudi
                if (udi && !c.sepesudi) product.sepesudi = udi;                 // Non-empty UDI & empty sepesudi
                else if (c.sepudi && !c.sepesudi) product.sepesudi = c.sepudi;  // Non-empty sepudi & empty sepesudi
                else if (tempcmp <= 2) product.sepesudi = udi;                  // different between UDI & sepesudi
                cmp = tempcmp;
                index = i;
            }
        }
        // console.log("[damlFetchContractPrice] compare", udi, distributor, cmp);
        // check out to match udi, distributor
        if (len > 1 && (cmp === 11 || cmp === 10)) {
            error = `The Reference number matches ${len} different distributors. 
                    Please choose the Distributor from the drop down and click the REF retrieve button again.`;
        }
        else if (cmp === 2 || cmp === 0) {
            error = `The UDI does not match the item being scanned please try again.`;
        }
        // set sepcontractnumber as same as id of ContractPrice
        product.sepcontractnumber = product.id;
        // delete some fields because of overwrite
        delete product.id;
        delete product.sepudi;
        delete product.sepproductexpiration;
        delete product.seplotcode;
        // set sepproductesprice as same as sepproductprice
        product.sepproductesprice = product.sepproductprice;
        result = product;
    }
    else {
        error = "No Contract Price information found.";
    }
    console.log("[damlFetchContractPrice] results", result, error, data, index);
    return { result, error, data, index };
};

/**
 * DAML Fetch in Surgical Event
 * @param {String} token 
 * @param {String} hospital 
 * @param {String} seid 
 * @param {String|null} status 
 * @returns {Object} 
 */
export const damlFetchSurgicalEvent = async ({
    token, hospital, seid, status=null,
}) => {
    if (!token || !hospital || !seid) return null;
    const searchParams = {
        hospital,
        surgicaleventdata: {
            seid,
        },
    };
    if (status) searchParams.surgicaleventdata.status = status;
    const contracts = await damlFetch({
        token,
        template: SurgicalEvent,
        searchParams: searchParams,
    });
    if (contracts.length > 0) {
        return contracts[0];
    }
    return null;
};

/**
 * DAML Fetch in PostProcedureVendor
 * @param {String} token 
 * @param {String} vendor 
 * @param {String} seid 
 * @param {String|null} status 
 * @returns {Object} 
 */
export const damlFetchPostProcedureVendor = async ({
    token, vendor, seid, status=null,
}) => {
    if (!token || !vendor || !seid) return null;
    const searchParams = {
        vendor,
        surgicaleventdata: {
            seid,
        },
    };
    if (status) searchParams.surgicaleventdata.status = status;
    const contracts = await damlFetch({
        token,
        template: PostProcedureVendor,
        searchParams: searchParams,
    });
    if (contracts.length > 0) {
        return contracts[0];
    }
    return null;
};

/**
 * DAML Fetch in RestockInventory
 * @param {String} token 
 * @param {String} hospital 
 * @param {String} vendor 
 * @param {String} popurchaseorderid 
 * @returns {Object} 
 */
export const damlFetchRestockInventory = async ({
    token, hospital, vendor, popurchaseorderid, 
}) => {
    if (!token || (!hospital && !vendor)) return {};
    const searchParams = {};
    if (hospital !== undefined) searchParams.hospital = hospital;
    if (vendor !== undefined) searchParams.vendor = vendor;
    if (popurchaseorderid !== undefined) searchParams.ipurchaseorderdata = { popurchaseorderid };
    const contracts = await damlFetch({
        token,
        template: RestockInventory,
        searchParams,
    });
    // console.log("[damlFetchRestockInventory] contracts", contracts);
    if (contracts.length > 0) {
        return contracts[0];
    }
    return null;
};

/**
 * DAML Fetch in Purchase Order
 * @param {String} token 
 * @param {String} hospital 
 * @param {String} popurchaseorderid 
 * @returns {Object} 
 */
export const damlFetchPO = async ({
  token, hospital, popurchaseorderid, 
}) => {
  if (!token || !hospital || !popurchaseorderid) return null;
  const searchParams = {
    hospital,
    purchaseorderdata: {
      popurchaseorderid,
    },
  };
  const contracts = await damlFetch({
    token,
    template: PurchaseOrder,
    searchParams: searchParams,
  });
  if (contracts.length > 0) {
    return contracts[0];
  }
  return null;
};

/**
 * DAML Fetch in Purchase Order Vendor
 * @param {String} token 
 * @param {String} hospital 
 * @param {String} popurchaseorderid
 * @returns {Object} 
 */
export const damlFetchPOV = async ({
  token, vendor, popurchaseorderid, 
}) => {
  if (!token || !vendor || !popurchaseorderid) return null;
  const searchParams = {
    vendor,
    purchaseorderdata: {
      popurchaseorderid,
    },
  };
  const contracts = await damlFetch({
    token,
    template: PurchaseOrderVendor,
    searchParams: searchParams,
  });
  if (contracts.length > 0) {
    return contracts[0];
  }
  return null;
};

/**
 * DAML Fetch in PrefCard SECard
 * @param {String} token 
 * @param {String} hospital 
 * @param {String} seid 
 * @returns {Object} 
 */
export const damlFetchPrefCards = async ({
  token, hospital, seid, 
}) => {
  if (!token || !hospital || !seid) return [];
  const searchParams = {
    hospital,
    seid,
  };
  const contracts = await damlFetch({
    token,
    template: PrefCards,
    searchParams: searchParams,
  });
  return contracts;
};

/**
 * Update Elastic Search Contract Price
 * @param {Object} query
 * @param {Object} source
 */
export const updateESContractPrice = async ({ query: searchQuery, source }) => {
  const url = UPDATE_ES_ENDPOINT;
  let query = {};
  if (
    !!searchQuery &&
    typeof searchQuery === "object" &&
    Object.keys(searchQuery).length
  ) {
    query = createElasticsearchQuery(searchQuery);
  }
  if (
    !!source &&
    typeof source === "object" &&
    Object.keys(source).length
  ) {
    query.source = source;
  }
  console.log("[updateES] request: ", query);

  await axios
    .post(url, query)
    .then((res) => {
      console.log("[updateES] response: ", res);
      return res;
    })
    .catch((err) => {
      console.warn("[updateES] error: ", err);
      return null;
    });
};
