import React, { useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import { useLedger, useParty } from "@daml/react";
import { Button, Typography, Grid, Box, Table, TableBody, TableRow, TableCell, IconButton } from "@material-ui/core";
import { getTotalPrice } from "../../models/Product";
import { ReturnType } from "../../models/InventoryData";
import { CourierCompany } from "../../models/Courier";
import { useDamlState } from "../../context/DamlContext";
import { useLayoutDispatch, setLoading, unsetLoading } from "../../context/LayoutContext";
import { CustomSelect, DateInput, CustomTextField } from "../../components/Inputs";
import ModalDialog from '../../components/Modals/NewGeneralModal';
import FileUploadDropzone from "../../components/FileUpload/FileUploadDropzone";
import { EVENT_REQUIRED, EVENT_VERIFIED, INVENTORY_REQUEST_EVENT } from '../../store/actions/constants';
import { useItemDetails } from "../../hooks/useProduct";
import { useAccountInventory } from "../../hooks/useInventory";
import { useComment } from "../../hooks/usePO";
import useEvent from "../../hooks/useEvent";
import folderIcon from "../../icons/mdi_folder-upload.svg";
import commentsIcon from '../../icons/new-comments-icon.svg';
import { AddedProductList } from "../surgicalevent/add-edit-product1";
import { handlePreviewIcon } from "../purchaseorder/create-purchaseorder1";
import POCommentPopover from "../purchaseorder/pocomment-popover";
import { requiredRequests } from './accountinventoryactivecontractshospital';
import { createReturnRequest } from "./handle";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "../purchaseorder/styles";
import useStyles2 from "./styles";


const InvTitle = () => {

  const classes = useStyles();
  const classes2 = useStyles2();

  const { accountInventoryData } = useAccountInventory();

  // Popover when clicking Comment button
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenCommentPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classNames(classes.newSETitlePanel, classes2.newTitlePanel)}
    >
      <Typography variant="inherit" component="span" className="label" >
        REF Number:
      </Typography>
      <Typography variant="inherit" component="span" className="value" >
        {accountInventoryData.ireferencenumber}
      </Typography>
      <Typography variant="inherit" component="span" className="label" >
        Ownership:
      </Typography>
      <Typography variant="inherit" component="span" className="value" >
        {accountInventoryData.iownership}
      </Typography>
      <Typography variant="inherit" component="span" className="label" >
        Distributor:
      </Typography>
      <Typography variant="inherit" component="span" className="value" >
        {accountInventoryData.vendor}
      </Typography>

      <IconButton onClick={handleOpenCommentPopover}>
        <img alt="" src={commentsIcon} />
      </IconButton>

      <POCommentPopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        isSendable={false}
      />
    </Grid>
  );
};

export const AddedProductHeader = ({
  productItemsList = [],
}) => (
  <Grid
    container
    direction="row"
    justifyContent="flex-start"
    alignItems="center"
  >
    <Grid item>
      <Typography variant="inherit" className="header-label">Quantity:{' '}</Typography>
      <Typography variant="inherit" className="header-value">{productItemsList.length}</Typography>
    </Grid>
    <Grid item>
      <Typography variant="inherit" className="header-label">Total Price:{' '}</Typography>
      <Typography variant="inherit" className="header-value">${getTotalPrice(productItemsList)}</Typography>
    </Grid>
  </Grid>
);

const InvPanel = ({
  data, setData,
}) => {

  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();

  const { event, status, onEventReject, onEventVerify } = useEvent();

  const [errors, setErrors] = useState({});

  const handleChange = (value, key) => {
    if (errors[key]) setErrors({
      ...errors,
      [key]: undefined,
    });
    setData({
      ...data,
      [key]: value,
    });
  };

  // handle to upload
  const handleUpload = (file, callback) => {
  };

  useEffect(() => {
    if (event === INVENTORY_REQUEST_EVENT && status === EVENT_REQUIRED) {
      const newErrors = requiredRequests(data);
      if ((Object.keys(newErrors).length > 0)) {
        setErrors({ ...newErrors });
        onEventReject();
      }
      else {
        setErrors({});
        onEventVerify();
      }
    }
  }, [event, status, onEventReject, onEventVerify, data]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      className={classNames(classes.newOverview, classes2.requestPanel)}
    >
      <Grid item>
        <Grid container spacing={1}>
          <Grid item>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className="details">Return Request</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="value">
                    <CustomSelect
                      label="Request Type"
                      value={data.newireturntype || ""}
                      onChange={(val) => handleChange(val, 'newireturntype')}
                      warning={errors.newireturntype}
                      items={ReturnType}
                      placeholder="Select One"
                      width={115}
                      options={{
                        labelLeft: true,
                        labelWidth: 143,
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="value">
                    <CustomTextField
                      label="Request Description"
                      value={data.newireturnmessage || ""}
                      onChange={(val) => handleChange(val, 'newireturnmessage')}
                      warning={errors.newireturnmessage}
                      width={200}
                      options={{
                        labelLeft: true,
                        labelWidth: 143,
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>

          <Grid item>
            {
              ('ishipmentdate' in data && 'icourier' in data && 'itrackingstatus' in data) &&
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className="details">Shipping Details</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="value">
                      <DateInput
                        label="Shipping Date"
                        value={data.ishipmentdate || ""}
                        onChange={(val) => handleChange(val, 'ishipmentdate')}
                        width={115}
                        options={{
                          labelLeft: true,
                          labelWidth: 130,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="value">
                      <CustomSelect
                        label="Courier Company"
                        value={data.icourier || ""}
                        onChange={(val) => handleChange(val, 'icourier')}
                        items={CourierCompany}
                        placeholder="Select One"
                        width={115}
                        options={{
                          labelLeft: true,
                          labelWidth: 130,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="value">
                      <CustomTextField
                        label="Tracking Number"
                        value={data.itrackingstatus || ""}
                        onChange={(val) => handleChange(val, 'itrackingstatus')}
                        width={115}
                        options={{
                          labelLeft: true,
                          labelWidth: 130,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            }
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <AddedProductList
          options={{
            keyProduct: 'iproduct',
            isEnableEditProduct: false,
            isEnableDeleteProduct: false,
          }}
          HeaderComponent={AddedProductHeader}
          className={classes2.addedProductNewPanel}
        />
      </Grid>

      <Grid item>
        <FileUploadDropzone
          className={classNames(classes1.uploadFile)}
          title=""
          uploadLabel={(
            <Box className="drag-drop-btn">
              Drag and Drop file to upload
              <img src={folderIcon} alt="" />
            </Box>
          )}
          changeFile={handleUpload}
          isNonePadding={true}
          isEnableUpload={false}
          handlePreviewIcon={handlePreviewIcon}
        />
      </Grid>
    </Grid>
  );
};

/**
 * Send Return Request Dialog New
 * in Hospital
 * @param {Boolean} open 
 * @param {Function} setOpen 
 * @returns 
 */
export default function NewRequest({
  open, setOpen,
}) {

  const classes = useStyles();

  const ledger = useLedger();
  const hospital = useParty();

  const { roleCid, userlistPayload } = useDamlState();

  const layoutDispatch = useLayoutDispatch();

  const { accountInventoryData, setAccountInventoryData } = useAccountInventory();
  const { details, common } = useItemDetails();
  const { setComments } = useComment();
  const { event, status, onEventStart, onEventRun, onEventEnd } = useEvent();

  const [data, setData] = useState({});

  // handle to click "Send"
  const handleSendClick = () => {
    onEventStart(INVENTORY_REQUEST_EVENT);
  };

  // handle to return request
  const handleSend = useCallback(async () => {
    console.log("[NewRequest] handleSend", data);
    // spinner
    setLoading(layoutDispatch);
    onEventRun();
    if (details.length) {
      let ContractId = accountInventoryData.contractId, res;
      for (let i in details) {
        const inventorydata = {
          ...details[i], icourier: data.icourier, ishipmentdate: data.ishipmentdate,
          itrackingstatus: data.itrackingstatus, newireturntype: data.newireturntype,
          newireturnmessage: data.newireturnmessage
        };
        
        res = await createReturnRequest({
          ...data,
          inventorydata,
          inventoryindex: inventorydata.index,
          ledger,
          ContractId,
          roleCid,
          userlistPayload,
        });
        if (res) {
          ContractId = res.contractId;
        }
      }
      setAccountInventoryData({
        ...accountInventoryData,
        ...res?.inventoryproduct,
        contractId: ContractId,
        inventorydata: res?.inventorydata,
      });
    }
    unsetLoading(layoutDispatch);
    onEventEnd();
    setOpen(false);
  }, [data, ledger, roleCid, userlistPayload, layoutDispatch, onEventRun, onEventEnd, details, setOpen,
    accountInventoryData, setAccountInventoryData]);
    
  useEffect(() => {
    if (event) {
      console.log("[NewRequest] On Start Event", event);
      if (event === INVENTORY_REQUEST_EVENT && status === EVENT_VERIFIED) {
        handleSend();
      }
    }
  }, [event, status, handleSend]);

  useEffect(() => {
    if (open) {
      console.log("[NewRequest] Open NewRequest dialog.", common);
      if (details.length) {
        setData({ ...common, 'newireturntype': '', 'newireturnmessage': '', 'newireturnuser': hospital, });
        setComments(common.ireturncomments ?? []);
      }
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <>
      <ModalDialog
        open={open}
        setOpen={setOpen}
        title={(
          <InvTitle />
        )}
        content={(
          <InvPanel
            data={data}
            setData={setData}
          />
        )}
        actions={(
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSendClick}
              className={classNames(classes.newCompleteBtn)}
            >
              Send Request
            </Button>
          </>
        )}
        width={824}
        height={634}
      />
    </>
  );
}

