import React, { useState } from "react";
import classNames from "classnames";
import { Chip } from "@material-ui/core";
// import { Inventory } from "../../services/daml-modules1";
import Contracts from "../../components/Contracts/Contracts";
import AdaptiveSearch from "../../components/Search/adaptivesearch";
import DateSearch from "../../components/Search/datesearch";
import { contractFilter } from "../../services/daml-filters";
import truckIcon from "../../icons/truck.svg";
import truckDisIcon from "../../icons/truck-dis.svg";
import ConfirmShipment from "../inventoryrestock/confirm-shipment";
import { confirmReturnInventoryHospital } from "./handle";
import DetailViewDrawer from "./detail-view-drawer";
import useStyles from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";


/**
 * Return Inventory Contracts in Hospital Side
 * @param {Object} searchParams 
 * @param {Function} handleChangeSearchValue 
 * @param {Array} allContracts 
 * @param {Object} searchInventory 
 * @param {String} hospital 
 * @param {Object} ledger 
 * @param {String} roleCid 
 * @returns 
 */
function ReturnInventoryContracts({
  searchParams = {},
  handleChangeSearchValue = null,
  allContracts = [],
  searchInventory = {},
  hospital,
  ledger,
  roleCid,
}) {

  const classes = useStyles();
  // const assets = useStreamQueries(Inventory, () => [searchParams]);
  let assetsContracts = contractFilter(allContracts, searchParams);
  // Confirm Inventory
  const [open, setOpen] = useState(false);
  const [confirmInventoryData, setConfirmInventoryData] = useState({});
  // Detail Viewer
  const [state, setState] = useState(false);
  const [selectedInventory, setSelectedInventory] = useState({});


  // open ConfirmModal
  const openConfirmModal = (c) => {
    console.log("[ReturnInventoryContracts] openConfirmModal", c);
    setConfirmInventoryData({
      ...c.payload.inventorydata,
      ContractId: c.contractId,
    });
    setOpen(true);
  };

  // handle to Shipped
  const handleShipped = async (inventoryData) => {
   
    await confirmReturnInventoryHospital({
      ledger,
      roleCid,
      vendor: confirmInventoryData.vendor,
      inventoryCid: confirmInventoryData.ContractId,
      inventorydata: inventoryData,
      newiproductstatus: 'ShippedVendor',
      updateddate: inventoryData.ishipmentdate,
    });
    //console.log("[ReturnInventoryContracts] handleShipped", res);

    setOpen(false);
  };

  
  /* let contracts = [];
  if (assetsContracts.length) {
    assetsContracts.forEach(c => {
      if (c.payload.inventorydata.length) {
        c.payload.inventorydata.forEach((invData, index) => {
          if (invData.ireturnstatus === true && invData.ireturnrequestdecision === true) {
            contracts.push({
              ...invData,
              index,
              ContractId: c.contractId,
              inventoryproduct: c.payload.inventoryproduct,
              iproductreturnstatus: getReturnStatus(invData),
              vendor: c.payload.vendor,
            });
          }
        });
      }
    });
  } */
  assetsContracts = assetsContracts.map(c => ({
    ...c, 
    payload: {
      ...c.payload, 
      iproductreturnstatus: getReturnStatus(c.payload.inventorydata)
    }
  }));
  console.log("[ReturnInventoryContracts]", searchParams, allContracts, assetsContracts);

  const toggleDrawer = (open) => {
    setState(open);
  };


  return (
    <>
      <Contracts
        className={classes.customRoot}
        contracts={assetsContracts}
        columns={[
          ["PO Number", "payload.inventorydata.ipurchaseordernumber", {
            options: {
              width: 180,
              tooltip:true
            },
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.inventorydata.ipurchaseordernumber}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'ipurchaseordernumber')}
                  defaultValue={searchInventory.ipurchaseordernumber}
                  label="SearchPO Number"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchInventory.ipurchaseordernumber,
            handleClear: () => handleChangeSearchValue('', 'ipurchaseordernumber'),
          }],
          ["Reference Number", "payload.inventorydata.iproduct.sepreferencenumber", {
            type: "link",
            onClick: (contractId, data) => {
              // detail page
              // console.log("[ReturnInventoryContracts]", data);
              if (!!data && 'contract' in data && 'payload' in data.contract) {
                setSelectedInventory({
                  // ...data.contract,
                  // inventorydata: [data.contract.payload.inventorydata],
                  ...data.contract.payload.inventorydata,
                });
                toggleDrawer(true);
              }
            },
            options: {
              width: 200,
              cellClassName: classNames(classes.primaryColor),
            },
          }],
          ["Manufacturer", "payload.inventorydata.iproduct.sepproductmanufacturer", {
            options: {
              width: 180,
            },
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.inventorydata.iproduct.sepproductmanufacturer}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'sepproductmanufacturer')}
                  defaultValue={searchInventory.sepproductmanufacturer}
                  label="Search Manufacturer"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchInventory.sepproductmanufacturer,
            handleClear: () => handleChangeSearchValue('', 'sepproductmanufacturer'),
          }],
          ["Distributor", "payload.inventorydata.iproduct.sepdistributor", {
            options: {
              width: 180,
            },
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.vendor}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'sepdistributor')}
                  defaultValue={searchInventory.sepdistributor}
                  label="Search Distributor"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchInventory.sepdistributor,
            handleClear: () => handleChangeSearchValue('', 'sepdistributor'),
          }],
          ["Lot Code", "payload.inventorydata.iproduct.seplotcode", {
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.inventorydata.iproduct.seplotcode}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'ilotcode')}
                  defaultValue={searchInventory.ilotcode}
                  label="Search Lot Code"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchInventory.ilotcode,
            handleClear: () => handleChangeSearchValue('', 'ilotcode'),
            options: {
              width: 150,
            },
          }],
          ["Product Name", "payload.inventorydata.iproduct.sepproductname", {
            options: {
              tooltip:true,
              flex: 1,
            } 
          }],
          ["Expiration", "payload.inventorydata.iproduct.sepproductexpiration", {
            search: {
              component:
                <DateSearch
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'iexpirationdate')}
                  defaultValue={searchInventory.iexpirationdate}
                  label="Search Event Date :"
                  isStatic={true}
                />
            },
            isSearched: searchInventory.iexpirationdate,
            handleClear: () => handleChangeSearchValue('', 'iexpirationdate'),
            options: {
              width: 150,
            },
          }],
          /*["Shipment Date", "ishipmentdate", {
            options: {
              width: 180,
            },
          }],*/
          ["Status", "payload.iproductreturnstatus", {
            type: "render",
            render: (param) => (<Chip
              label={param.value}
              size="small"
              className={classNames(classes.smallChip, (
                param.value === 'Shipped' ? classes.backBlue : classes.backGreen
              ))}
            />),
             options: {
              width: 150,
            },
 
          }],

        ]}

        actions={[
          [
            "Action",
            [
              [["Shipped", [<img alt="" src={truckIcon} />, <img alt="" src={truckDisIcon} />],
                (c) => (c.payload.iproductreturnstatus === 'Accepted')],
                openConfirmModal],
              /* [["Archive", [<img alt="" src={archiveIcon} />, <img alt="" src={archiveDisableIcon} />],
               (c) => isEnableArchive(c.iproductstatus) && checkNotPOHistory(c)],
                  handleArchivePo], */
            ],
           
          ]
        ]}

        options={{
          statusEmpty: (allContracts.length === 0),  
        }}
      />

      <ConfirmShipment
        open={open}
        setOpen={setOpen}
        handleRequest={handleShipped}
        inventoryData={confirmInventoryData}
        setInventoryData={setConfirmInventoryData}
        clearProductParams={[]}
        isHospital={true}
      />

      <DetailViewDrawer
        state={state}
        toggleDrawer={toggleDrawer}
        selectedInventory={selectedInventory}
      />

    </>
  );
}

export default withLoading(ReturnInventoryContracts);

// get return status by inventorydata
export const getReturnStatus = (inventorydata) => {
  return (
    inventorydata.iproductstatus === 'ShippedVendor' ? "Shipped" : (
      inventorydata.iproductstatus === 'ReceivedVendor' ? "Received" : "Accepted")
  );
};