import React, { useState, useEffect } from "react";
import {
  Button, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, FormControl} from "@material-ui/core";
import ErrorDialog from '../../components/Modals/GeneralModal/error-dialog';
import useStyles from "../surgicalevent/styles";
import TextField from '@mui/material/TextField';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import moment from 'moment';
import { setByPath } from "../../components/Contracts/functions";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export default function UpdateInvoice({
  openModal, setOpenModal,
  invoice, handleUpdate
}) {

  const classes = useStyles();
  const [payload, setPayload] = useState(invoice);

  // error modal
  const [errorModal, setErrorModal] = useState(false);

  const handleCancel = () => {
    setOpenModal(false);
  };


  useEffect(() => {
    if (openModal) {
      setErrorModal(false);
      setPayload(invoice);
    }
  }, [openModal,invoice]);


  return (
    <>

      <Dialog
        open={openModal}
        className={classes.customRoot}
        style={{ textAlign: 'left', }}
        aria-labelledby="draggable-dialog-title"
        classes={{ paper: classes.overflowHidden }}
        disableEnforceFocus
      >
        <div className={classes.purchaseContentModal}>

          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            <div className="view-heading">
              Edit Invoice
            </div>
          </DialogTitle>

          <DialogContent className={classes.poContentModal}>
            <DialogContentText component={'div'}>
              <div className="poModel">
                <>
                  <div className={classes.marginBM30}>
                    <FormControl variant="outlined"
                      className={classes.formControl}>
                      <div className={classes.form}>
                        <TextField
                          id="input-with-icon-adornment1"
                          disabled
                          label="PO Number"
                          value={payload?.invoicedata?.invpurchaseorderid}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.form}>

                        <TextField
                          disabled
                          id="input-with-icon-adornment1"
                          label="Invoice Number"
                          value={payload?.invoicedata?.invinvoicenumber}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.form}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                        >
                          <DesktopDatePicker
                            label="Invoice Date"
                            inputFormat="MM/dd/yyyy"
                            value={payload?.invoicedata?.invinvoicedate}
                            onChange={(newDate) => {
                              setPayload(setByPath(moment(newDate).format('yyyy-MM-DD'), 'invoicedata.invinvoicedate', payload))
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </div>

                      <div className={classes.form}>

                        <TextField
                          disabled
                          id="input-with-icon-adornment1"
                          label="Hospital"
                          value={payload?.hospital}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.form}>

                        <TextField
                          id="input-with-icon-adornment1"
                          label="Invoice Amount"
                          value={payload?.invoicedata?.invinvoicetotalamount}
                          margin="normal"
                          onChange={(e) =>
                            setPayload(setByPath(e.target.value, 'invoicedata.invinvoicetotalamount', payload))
                          }
                          variant="outlined"
                        />
                      </div>

                    </FormControl>
                  </div>
                </>
              </div>

            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <div className="form-footer">
              <div className="right-buttons">
                <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={() => handleUpdate(payload)} >
                  Save Update
                </Button>
              </div>
            </div>
          </DialogActions>

        </div>

      </Dialog>

      <ErrorDialog
        openModal={errorModal}
        setOpenModal={setErrorModal}
      />

    </>
  );
}
