import React, { useState } from "react";
import classNames from "classnames";
import { Chip, FormControl, Select } from "@material-ui/core";
import { RestockInventoryHistory } from "../../services/daml-modules1";
import AdaptiveSearch from "../../components/Search/adaptivesearch";
import DateSearch from "../../components/Search/datesearch";
import SelectSearch from "../../components/Search/selectsearch";
import TreeContracts from "../../components/Contracts/TreeContracts";
import { contractFilter } from "../../services/daml-filters";
import { getInventoryProductStatus, InventoryProductStatus, isShippedVendor, getTotalPrice } from '../../models/InventoryData';
import { InventoryPOStatus, /* getInventoryPOStatus */ } from '../../models/Po';
import ModalDialogBox from "../../components/Modals/GeneralModal/modalDialogBox";
import ConfirmShipment from "./confirm-shipment";
import ConfirmBackOrder from "./confirm-backorder";
import DetailViewDrawer from "./detail-view-drawer";
import { changeInventoryShipment } from "./handle";
import { setToggleStage } from "../../store/actions/generalActions";
import { dispatch } from "../../store";
import archiveIcon from "../../icons/archive.svg";
import archiveDisableIcon from "../../icons/archive-disable.svg";
import { getTemplate, getTodayString } from "../../services/util";
// import { ArchivePoV } from '../purchaseorder/handle';
import ErrorDialog from '../../components/Modals/GeneralModal/error-dialog';
// import { InventoryLocationModel } from "../../models/InventoryData";
import { useDamlStream } from "../../hooks/useDAML";
import useStyles from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";


/**
 * Restock Inventory Contracts for Vendor
 * @param {Object} searchParams 
 * @param {Object} searchParamsPO 
 * @param {Boolean} isIncludeHistory 
 * @param {Function} handleChangeSearchValue 
 * @param {Array} allContracts 
 * @param {Object} ledger 
 * @param {String} vendor 
 * @param {String} roleCid 
 * @param {String} userlistPayload
 * @returns 
 */
function ScanInventoryVendorContracts({
  searchParams = {},
  searchParamsPO = {},
  searchHistoryParamsPO = {},
  isIncludeHistory = false,
  handleChangeSearchValue = null,
  allContracts = [],
  ledger,
  vendor,
  roleCid,
  userlistPayload,
}) {

  const classes = useStyles();


  // const assetsPOV = useStreamQueries(PurchaseOrderVendor, () => [searchParamsPO]);
  let contractsPOV = contractFilter(allContracts, searchParamsPO);
  // const assets = useStreamQueries(RestockInventory, () => [searchParams]);
  // const assetsHistory = useStreamQueries(InventoryProductHistory, () => [searchParams]);

  // const [assetsPOVHistory, setPOVHisotry] = useState({});
  const assetsPOVHistory = useDamlStream(RestockInventoryHistory, [searchHistoryParamsPO], isIncludeHistory);
  // const [assetsHistory, setInvHisotry] = useState({});

  contractsPOV = contractsPOV.concat(isIncludeHistory && assetsPOVHistory.contracts ?
    assetsPOVHistory.contracts.map(h => ({ ...h, payload: h.payload.purchaseordervendor })) : []);
  let contracts = [];

  // hash keys for Inventory
  /* let hashKeys = [];
  contractsPOV.forEach(c => {
    c.payload.inventorydatas = [];
    // c.payload.inventorylocations = [];
    if (c.payload.purchaseorderdata) hashKeys[c.payload.purchaseorderdata.popurchaseorderid] = c;
  });
  if (assets && assets.contracts) {
    assets.contracts
      .map(c => ({
        ...c,
        payload: {
          ...c.payload,
          inventorydata: compressInventoryDataList(c.payload.inventorydata).map(inv => ({
            ...inv,
            sortkey: `${c.payload.inventoryproduct.ireferencenumber}${c.payload.inventoryproduct.iownership}${inv.index}`,
          })),
        }
      }))
      .forEach(c => {
        if (c.payload.inventorydata && c.payload.inventorydata.length) {
          c.payload.inventorydata.forEach((invData, index) => {
            if (invData.ipurchaseordernumber in hashKeys) {
              hashKeys[invData.ipurchaseordernumber].payload.inventorydatas.push({
                ...invData,
                contractId: c.contractId,
                inventoryproduct: c.payload.inventoryproduct,
                hospital: c.payload.hospital,
              });
              // hashKeys[invData.ipurchaseordernumber].payload.inventorylocations.push((
              //   (c.payload.inventorylocation && index in c.payload.inventorylocation) ? 
              //     c.payload.inventorylocation[index] : {...InventoryLocationModel}));
            }
          });
        }
      });
  } */
  // check InventoryPOStatus, shippedproducts, etc
  contracts = contractsPOV.filter(c => c.payload.inventorydata.length)
    .map(c => {
      // c.payload.purchaseorderdata.poinventorystatus = getInventoryPOStatus(c.payload.inventorydatas);
      c.payload.ipurchaseorderdata.shippedproducts = 0;
      c.payload.inventorydata.forEach(inv => {
        if (isShippedVendor(inv.iproductstatus)) c.payload.ipurchaseorderdata.shippedproducts += parseInt(inv.iquantity);
        if (inv.iproductstatus === 'Opens') {
          // remove seplotcode, sepproductexpiration in List - disable now
          // inv.iproduct.seplotcode = "";
          // inv.iproduct.sepproductexpiration = "";
        }
        if (inv.iproductstatus === 'Received' && inv.ireceivedstockforfacility) {
          inv.iproductstatus = 'StockedForFacility';
        }
      });
      c.payload.pototalamount = getTotalPrice(c.payload.inventorydata);
      c.payload.inventorydata = c.payload.inventorydata.map((inv, index) => ({
        ...inv,
        index,
        contractId: c.contractId,
        hospital: c.payload.hospital,
      }));
      return c;
    });

  /* let contracts = contracts.map(c => {
    const parent_id = c.payload.inventorydatas[0].id;
    c.payload.inventorydatas.forEach((inv,index) => {
      inv.parent_id = parent_id;
      c.payload.inventorydatas[index] = inv
    }); 
    return c;
  }); */
  console.log("[ScanInventoryVendorContracts]", searchParams, contractsPOV, contracts);


  // DetailViewDrawer
  const [poData, setPOData] = useState({});
  const [inventoryList, setInventoryList] = useState([]);
  const [detailViewer, setDetailViewer] = useState(false);
  // Confirm Inventory
  const [open, setOpen] = useState(false);
  const [confirmInventoryData, setConfirmInventoryData] = useState({});
  const [confirmStatus, setConfirmStatus] = useState({});
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  // Confirm Back Order
  const [openBackOrder, setOpenBackOrder] = useState(false);
  const [backorderInventoryData, setBackOrderInventoryData] = useState({});
  const [openBackOrderModal, setOpenBackOrderModal] = useState(false);
  const [shipmentdate, setShipmentdate] = useState("");
  // Archive SE
  const [openWarningPOV, setOpenWarningPOV] = useState(false);
  const [confirmModalContract, setConfirmModalContract] = useState({});
  // const [openWarning, setOpenWarning] = useState(false); 
  // error modal
  const [errorModal, setErrorModal] = useState(false);


  const [isIreceivedStockForFacility, setIsIreceivedStockForFacility] = useState(true);
  const [ireceivedStockForFacility, setIreceivedStockForFacility] = useState(true);


  // change shipment in list
  const handleChangeShipment = async (type, product) => {
    if (product.parent_id) dispatch(setToggleStage(product.parent_id));
    //console.log("[ScanInventoryVendorContracts] handleChangeShipment", product.parent_id);

    let newInventoryData = {
      ...product,
      iproduct: { ...product.iproduct },
    };
    if ((product.iproductstatus === 'Opens' || product.iproductstatus === 'BackOrder') && (type === 'Shipped')) {
      // open modal to change status
      setConfirmStatus(type);
      setConfirmInventoryData(newInventoryData);
      setOpen(true);
    }
    if ((product.iproductstatus === 'Opens') && (type === 'StockedForFacility')) { // Removed (type === 'Received' ||) case
      if (type === 'StockedForFacility') {
        setIsIreceivedStockForFacility(true);
        setIreceivedStockForFacility(true);
      }
      // open modal to change status
      setConfirmStatus('Received');
      setConfirmInventoryData(newInventoryData);
      setOpen(true);
    }
    else if ((product.iproductstatus === 'Opens' && type === 'BackOrder')) {
      setConfirmStatus(type);

      setBackOrderInventoryData(newInventoryData);
      setOpenBackOrder(true);
    }
    else if ((product.iproductstatus === 'BackOrder' && type === 'Opens')) {
      // change status directly

      await changeInventoryShipment({
        ledger,
        roleCid,
        userlistPayload,
        hospital: product.hospital,
        inventoryCid: product.contractId,
        inventorydata: newInventoryData, // old case : product.datas
        newiproductstatus: type,
      });
      // console.log("[ScanInventoryVendorContracts] handleChangeShipment", res);
    }
  };

  // handler to Confirm a Inventory in Confirm Modal
  const handleRequest = async (product) => {

    //console.log("[ScanInventoryVendorContracts] handleRequest", confirmStatus, confirmInventoryData);

    setConfirmInventoryData(product);

    setOpen(false);

    // if (res) {
    setOpenConfirmModal(true);
    // }
  };

  // handler to Confrim a Inventory in ModalDialogBox
  const handleConfirmRequest = async () => {

    let result = await changeInventoryShipment({
      ledger,
      roleCid,
      userlistPayload,
      hospital: confirmInventoryData.hospital,
      inventoryCid: confirmInventoryData.contractId,
      inventorydata: confirmInventoryData, // old case : confirmInventoryData.datas,
      newiproductstatus: confirmStatus,
      ireceivedstockforfacility: confirmInventoryData.ireceivedstockforfacility ,
      updateddate: confirmInventoryData.ishipmentdate,
    });
   
    setOpenConfirmModal(false);

    if (!result) setErrorModal(true);
  };

  // handle to confirm back-order
  const handleBackOrder = async (product) => {
   
    let res = await changeInventoryShipment({
      ledger,
      roleCid,
      userlistPayload,
      hospital: product.hospital,
      inventoryCid: product.contractId,
      inventorydata: product, // old case : product.datas,
      newiproductstatus: confirmStatus,
      updateddate: product.ishipmentdate,
    });
    console.log("[ScanInventoryVendorContracts] handleBackOrder", res);

    setOpenBackOrder(false);
    setShipmentdate(product.ishipmentdate ? product.ishipmentdate : getTodayString());
    setOpenBackOrderModal(true);

  };

  const checkNotPOVHistory = (c) => (getTemplate(c) !== "PurchaseOrderVendorHistory");

  // check to archive SE
  const checkEnableArchivePOV = (c) => {
    // console.log("[ScanInventoryVendorContracts] checkEnableArchivePOV", c);
    return (checkNotPOVHistory(c) && c.payload.ipurchaseorderdata.poinventorystatus === 'AllReceived');
  };
  const handleArchivePOV = (c) => {
    // console.log("[ScanInventoryVendorContracts] handleArchivePOV", c);
    setOpenWarningPOV(true);
    setConfirmModalContract(c);

  };
  const handleModalYesPOV = async () => {
    console.log("[ScanInventoryVendorContracts] handleModalYesPOV", confirmModalContract);
    // await archiveSurgicalEvent(confirmModalContract.contractId, confirmModalContract.payload, ledger);
    // Now, Restock Inventory & Purchase Order are different. So it can't remove POV here.
    /* await ArchivePoV({
      ledger, 
      cid: confirmModalContract.contractId,
      payload: confirmModalContract.payload,
      roleCid,
    }); */
    setOpenWarningPOV(false);
  };

  return (
    <>
      <div key="formChildStep" className="formsteps">
        <TreeContracts
          className={classes.customRoot}
          contracts={contracts}
          columns={[
            // ["ContractId", "contractId"],
            ["SE Id", "payload.ipurchaseorderdata.poseid", {
              type: "link",
              options: {
                cellClassName: classNames(classes.primaryColor),
                width: 116
              },
              onClick: (contractId, contract) => {
                if (!!contract) {
                  // console.log("[ScanInventoryVendorContracts] click", contract);
                  setPOData({ ...contract.payload.ipurchaseorderdata });
                  setInventoryList(contract.payload.inventorydata);
                  setDetailViewer(true);
                }
              },
              search: {
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.ipurchaseorderdata.poseid}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'poseid')}
                    defaultValue={searchParamsPO.ipurchaseorderdata.poseid}
                    label="Search SE ID"
                    contracts={allContracts}
                    isShrink={true}
                  />
              },
              isSearched: searchParamsPO.ipurchaseorderdata.poseid,
              handleClear: () => handleChangeSearchValue('', 'poseid'),
            }],
            ["PO Number", "payload.ipurchaseorderdata.popurchaseorderid", {
              search: {
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.ipurchaseorderdata.popurchaseorderid}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'popurchaseorderid')}
                    defaultValue={searchParamsPO.ipurchaseorderdata.popurchaseorderid}
                    label="Search PO Number"
                    contracts={allContracts}
                    isShrink={true}
                  />
              },
              isSearched: searchParamsPO.ipurchaseorderdata.popurchaseorderid,
              handleClear: () => handleChangeSearchValue('', 'popurchaseorderid'),
              options: {
                width: 160
              }
            }],
            ["PO Date", "payload.ipurchaseorderdata.podate", {
              search: {
                component:
                  <DateSearch
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'podate')}
                    defaultValue={searchParamsPO.ipurchaseorderdata.podate}
                    label="Search PO Date :"
                    isStatic={true}
                  />
              },
              isSearched: searchParamsPO.ipurchaseorderdata.podate,
              handleClear: () => handleChangeSearchValue('', 'podate'),
              options: {
                width: 145
              }
            }],
            ["Status", "payload.ipurchaseorderdata.poinventorystatus", {
              type: "render",
              render: (param) => (InventoryPOStatus[param.value] && <Chip
                label={InventoryPOStatus[param.value]}
                size="small"
                className={classNames(classes.smallChip, (
                  param.value === 'Open' ? classes.backGrey : (
                    param.value === 'AllShipped' ? classes.backGreen : (
                      param.value === 'AllReceived' ? classes.backLightGreen : (
                        classes.backYellow)
                    ))
                ))}
              />),
              search: {
                component:
                  <SelectSearch
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'poinventorystatus')}
                    label="Search Status :"
                    options={Object.keys(InventoryPOStatus).map(value => ({ label: InventoryPOStatus[value], value: value }))}
                    defaultValue={searchParamsPO.ipurchaseorderdata.poinventorystatus}
                    isStatic={true}
                  />
              },
              isSearched: searchParamsPO.ipurchaseorderdata.poinventorystatus,
              handleClear: () => handleChangeSearchValue('', 'poinventorystatus'),
            }],
            ["Hospital", "payload.hospital", {
              search: {
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.hospital}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'hospital')}
                    defaultValue={searchParams.hospital}
                    label="Search Hospital"
                    contracts={allContracts}
                    isShrink={true}
                  />
              },
              isSearched: searchParams.hospital,
              handleClear: () => handleChangeSearchValue('', 'hospital'),
              options: {
                width: 138
              }
            }],
            ["Ownership", "payload.ipurchaseorderdata.popurchaseordertype"],
            ["Total Amount", "payload.pototalamount", {
              options: {
                beforeText: '$',
                width: 160
              }
            }],
            ["Shipped Products", "payload.ipurchaseorderdata.shippedproducts", {
              options: {
                type: 'number',
                headerClassName: classes.centerCell,
                cellClassName: classes.centerCell,
                width: 147
              },
            }],
            ["Product Details", "payload.inventorydata", {
              type: "collapse",
              columns: [
                ["Reference Number", "iproduct.sepreferencenumber"],
                ["Product Name", "iproduct.sepproductdescription", {
                  options: {
                    tooltip: true
                  }
                }],
                ["Manufacturer", "iproduct.sepproductmanufacturer", {
                  options: {
                    tooltip: true
                  }
                }],
                ["Quantity", "iquantity"],
                ["Price", "iproduct.sepproductprice", {
                  options: {
                    beforeText: '$'
                  }
                }],
                ["Lot Code", "iproduct.seplotcode"],
                ["Product Type", "iproduct.sepproducttype"],
                ["Ship/Expected Ship Date", "ishipmentdate"],
                ["Shipment Status", "iproductstatus", {
                  type: "render",
                  render: (param) => (
                    (param.value === 'Opens' || param.value === 'BackOrder')
                      ?
                      <div>
                        <FormControl variant="outlined">
                          <Select
                            value={param.value}
                            onChange={(e) => handleChangeShipment(e.target.value, param.row)}>
                            {getInventoryProductStatus(null, ['Received', 'ShippedVendor', 'ReceivedVendor', !ireceivedStockForFacility ? 'StockedForFacility' : ""])}
                          </Select>
                        </FormControl>
                      </div>
                      :
                      <Chip
                        label={InventoryProductStatus[(param.value === 'Received' ? 'Shipped' : param.value)]}
                        size="small"
                        className={classNames(classes.smallChip, (
                          param.value === 'BackOrder' ? classes.backYellow : classes.backGreen
                        ))}
                      />),
                }],
              ],
              actions: [],
              options: {
                // sortKey: 'sortkey',
              },
            }],
          ]}

          actions={[
            [
              "Actions",
              [
                [["Archive", [<img alt="" src={archiveIcon} />, <img alt="" src={archiveDisableIcon} />],
                  checkEnableArchivePOV],
                  handleArchivePOV,
                ]
              ],
            ],
          ]}
          options={{
            statusEmpty: (allContracts.length === 0)
          }}
        />


        <DetailViewDrawer
          state={detailViewer}
          toggleDrawer={(open) => setDetailViewer(open)}
          selectedPOData={poData}
          inventoryList={inventoryList}
        />

        <ConfirmShipment
          open={open}
          setOpen={setOpen}
          handleRequest={handleRequest}
          inventoryData={confirmInventoryData}
          setInventoryData={setConfirmInventoryData}
          confirmStatus={confirmStatus}
          clearProductParams={[]} // remove seplotcode, sepproductexpiration in Confirm Modal - disable now
          setIsIreceivedStockForFacility={setIsIreceivedStockForFacility}
          isIreceivedStockForFacility={isIreceivedStockForFacility}
        />

        <ConfirmBackOrder
          openModal={openBackOrder}
          setOpenModal={setOpenBackOrder}
          inventoryData={backorderInventoryData}
          setInventoryData={setBackOrderInventoryData}
          addProductItem={handleBackOrder}
        />

        <ModalDialogBox
          open={openConfirmModal}
          setOpen={setOpenConfirmModal}
          heading={"Alert!"}
          type="warning"
          text={`<p>This Product (${confirmInventoryData.iproduct && confirmInventoryData.iproduct.sepreferencenumber}) 
            (${confirmInventoryData.iproduct && confirmInventoryData.iproduct.seplotcode}) is now Confirmed Shipped.</p>`}
          handleRequest={handleConfirmRequest}
          secondBtn={'No'}
          handleSecRequest={() => setOpenConfirmModal(false)}
        />

        <ModalDialogBox
          open={openBackOrderModal}
          setOpen={setOpenBackOrderModal}
          heading={"Alert!"}
          type="warning"
          text={`<p>This Product is backordered with an expected shipping date of (${shipmentdate}).</p>`}
          handleRequest={() => setOpenBackOrderModal(false)}
        />

        <ModalDialogBox
          open={openWarningPOV}
          setOpen={setOpenWarningPOV}
          heading="Archive the Surgical Event?"
          type="warning"
          // text={'<p> You will not be able to Recover it.</p>'}
          text={`<p>Archiving the Surgical Event on this page will impact Surgical Events, 
            Purchase Order and Restock Inventory Records.  Please be aware that you will not be able to recover from this.</p>
            <p>In the future,  to display Archived Contract Information, please check the “Include Archived Contracts” checkmark.</p>`}
          handleRequest={handleModalYesPOV}
          secondBtn={'No'}
          handleSecRequest={() => setOpenWarningPOV(false)}
        />

        <ErrorDialog
          openModal={errorModal}
          setOpenModal={setErrorModal}
        />

      </div>
    </>
  );
}

export default withLoading(ScanInventoryVendorContracts);
