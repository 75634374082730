import React from "react";
import PaymentsListContracts from './paymentlistcomponent';


function ReceivedPaymentsListContracts({
    searchParams = {},
    allContracts = [],
    loading
}) {

    return <PaymentsListContracts isDistributor allContracts={allContracts} searchParams={searchParams}  loading={loading}/>
}

export default ReceivedPaymentsListContracts;


