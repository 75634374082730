import { useCallback } from "react";
import { useSelector } from "react-redux";
import { dispatch } from "../store";
import { EVENT_REQUIRED, EVENT_VERIFIED, EVENT_REJECTED, EVENT_RUNNING, EVENT_FINISHED } from "../store/actions/constants";
import { setAllEvent, setStatusEvent, clearAllEvent } from "../store/actions/eventAction";


/**
 * Event Hook for Product, SE, etc
 * @returns 
 */
export default function useEvent() {
  const event = useSelector(state => state.event.name);
  const status = useSelector(state => state.event.status);
  const data = useSelector(state => state.event.data);
  const onEventStart = useCallback((eventname, data = null) => dispatch(setAllEvent({ name: eventname, status: EVENT_REQUIRED, data })), []);
  const onEventVerify = useCallback(() => dispatch(setStatusEvent(EVENT_VERIFIED)), []);
  const onEventReject = useCallback(() => dispatch(setStatusEvent(EVENT_REJECTED)), []);
  const onEventRun = useCallback(() => dispatch(setStatusEvent(EVENT_RUNNING)), []);
  const onEventEnd = useCallback(() => dispatch(setStatusEvent(EVENT_FINISHED)), []);
  const onEventClear = useCallback(() => dispatch(clearAllEvent()), []);

  return { event, status, data, onEventStart, onEventVerify, onEventReject, onEventRun, onEventEnd, onEventClear };
}
