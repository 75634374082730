import React, { forwardRef } from "react";
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, Box } from "@material-ui/core";
import { formatDate } from "../../services/util";
import useStyles from "./styles";


/**
 * Print Product List Component
 * @param {Array} products 
 * @returns 
 */
export const ProductsPrint = ({ products=[] }) => {

  const classes = useStyles();

  let _columnsize = 2
  let _rows = parseInt(products.length / 2) + 1
  let _productRow = []
  let _pageRowCount = 3
  for (let row = _rows - 1; row >= 0; row --) {
    let _rowproduct = []
    for (let col = 0; col < _columnsize; col++) {
      let index = (row * _columnsize) + col;
      if (index < products.length) {
        let product = products[index];
        _rowproduct.push(<Grid item xs={6} key={index} style={{ marginTop: '25px' }}>

          <Box className="product-box">
            <Typography className="head">
              <Typography variant="inherit" className="label">Reference Number:</Typography>
              <Typography variant="inherit" className="text">{product.sepreferencenumber}</Typography>
              <Typography variant="inherit" className="label">Lot/SN:</Typography>
              <Typography variant="inherit" className="text">{product.seplotcode}</Typography>
            </Typography>
            <Table className="box-table">
              <TableBody>
                <TableRow>
                  <TableCell className="label">UDI</TableCell>
                  <TableCell className="text">{product.sepudi}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">Product Manufacturer</TableCell>
                  <TableCell className="text">{product.sepproductmanufacturer}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">Product Distributor</TableCell>
                  <TableCell className="text">{product.sepdistributor}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">Product Name</TableCell>
                  <TableCell className="text">{product.sepproductname}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">Product Description</TableCell>
                  <TableCell className="text">{product.sepproductdescription}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">Expiration</TableCell>
                  <TableCell className="text">{formatDate(product.sepproductexpiration)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">Price</TableCell>
                  <TableCell className="text">${product.sepproductprice}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">Lot/SN</TableCell>
                  <TableCell className="text">{product.seplotcode}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">Product Type</TableCell>
                  <TableCell className="text">{product.sepproducttype}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">Waste</TableCell>
                  <TableCell className="text">{product.sepproductwaste}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">Ownership</TableCell>
                  <TableCell className="text">{product.sepownership}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">Product Side</TableCell>
                  <TableCell className="text">{product.sepproductside}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Grid>)
      }
    }
    let _classname = (row !== 0 && (row % _pageRowCount) === 0) ? classes.avoidBreakInside : '';
    _productRow.push(
    <Grid className={_classname} key={row}>
      <Grid container direction="row" spacing={1}>
        {
          _rowproduct.flatMap(element => element)
        }
      </Grid>
    </Grid>)
  }
  return _productRow;
};

/**
 * SurgicalEvent Print
 * @param {Object} surgicaleventdata 
 * @param {Array} surgicaleventproductdata 
 * @returns 
 */
const SurgicalEventPrint = ({
  surgicaleventdata = {}, surgicaleventproductdata = [],
}) => {

  const classes = useStyles();

  const products = surgicaleventproductdata.map(p => p.product);

  return (
    <Grid container direction="column" className={classes.newPrint}>
      <Typography className="title">
        <Typography variant="inherit" className="label">SE ID: </Typography>{surgicaleventdata.seid}
      </Typography>
      <Typography className="sub-heading">Patient Details</Typography>
      <Table className="details-form col-5">
        <TableHead>
          <TableRow>
            <TableCell>MRN</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Gender</TableCell>
            <TableCell>DOB</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{surgicaleventdata.mrn}</TableCell>
            <TableCell>{surgicaleventdata.patientfirstname}</TableCell>
            <TableCell>{surgicaleventdata.patientlastname}</TableCell>
            <TableCell>{surgicaleventdata.patientgender}</TableCell>
            <TableCell>{formatDate(surgicaleventdata.patientdob)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Typography className="sub-heading">Event Details</Typography>
      <Table className="details-form col-3">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Procedure</TableCell>
            <TableCell>Side</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{formatDate(surgicaleventdata.eventdate)}</TableCell>
            <TableCell>{surgicaleventdata.surgicalprocedure}</TableCell>
            <TableCell>{surgicaleventdata.surgicalside}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Typography className="sub-heading">Physician Details</Typography>
      <Table className="details-form col-3">
        <TableHead>
          <TableRow>
            <TableCell>Salutation</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{surgicaleventdata.physiciansalutation}</TableCell>
            <TableCell>{surgicaleventdata.physicianfirstname}</TableCell>
            <TableCell>{surgicaleventdata.physicianlastname}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Grid container spacing={1}>
        <Grid item xs={6} >
          <Typography className="sub-title">
            <Typography variant="inherit" className="label">Total Added Products</Typography>
            {surgicaleventproductdata.length}
          </Typography>
        </Grid>
        <Grid item xs={6} >
          <Typography className="sub-title">
            <Typography variant="inherit" className="label">Total Price of Products</Typography>
            ${surgicaleventdata.price}
          </Typography>
        </Grid>

      </Grid>

      <ProductsPrint products={products} />

    </Grid>
  );
};

/**
 * Custom Print Component
 * @param {Array} contracts 
 * @param {Component} Component 
 * @returns 
 */
const CustomPrints = forwardRef(({
  contracts = [], Component = SurgicalEventPrint,
}, ref) => (
  <Grid ref={ref}>
    {
      contracts.map((contract, index) => (
        <Component
          key={index}
          {...contract}
        />
      ))
    }
  </Grid>
));

export default CustomPrints;
