
export const AuditLogsModel = {
  CreateSE: 'Completed Surgical Event',
  CreatePO: 'Created PO',
  SendPO: 'Purchase Order Sent',
  CreatedInvoice: 'Invoice Created', 
  SendInvoice: 'Invoice Sent',
  EditInvoice: 'Invoice Edited',
  Receive: 'Received Inventory',
  InvShipped: 'Product Shipped',
  Shipped: 'Product Shipped',
  StockedForFacility: 'Stocked for Facility',
  BackOrder: 'Product Back-Ordered',
  PoAccept: 'Product Order Accepted',
  PoDecline: 'Product Order Declined',
  ReturnInv: 'Return Request',
  InventoryEdit: 'Inventory Edited',
  InventoryDelete: 'Deleted Inventory',
  AddInventory: 'New Inventory',
  CreatePayment: 'New Payment Created',
  EditPayment: 'Payment Edited',
  HSettingChange: 'Hospital Settings Changed',
  VSettingChange: 'Vendor Settings Changed'
};
