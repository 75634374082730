import { Button, Chip, Dialog, FormControl, InputLabel, Select, TextField, Typography, FormControlLabel, Checkbox, MenuItem } from "@material-ui/core";
import { useLedger } from "@daml/react";
import React, { useState, useEffect } from "react";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useStyles from "../surgicalevent/styles";
import { getCanadaStates, getUsStates } from "../../models/State";
import { getGenders } from "../../models/Contact";
import { onChangeNumber } from "../../components/Modals/handles";
import { setByPath } from "../../components/Contracts/functions";
import { exerciseRoleTwoAccept } from "./handle";
import TabPanel, { a11yProps } from "./TabPanel";
import OperatorSurgeonList from "../showlist/operator-surgeon-list"


export default function HospitalProfileUpdate({
    open,
    setOpen,
    hospitalProfile
}) {
    // console.log(hospitalProfile)
    const classes = useStyles();
    const ledger = useLedger();
    const [value, setValue] = useState(0);
    const [selected, setSelected] = useState(0);
    const [count, setCount] = useState(1);
    const [hospitaldetails, setHospitalDetails] = useState({ ...hospitalProfile?.payload.hospitaldetails });
    const [states, setStates] = useState(getUsStates);

    const handleCountry = (e) => {
        console.clear(e.target.value);
        updateField('physicaladdress.country')(e);
        updateField(`shippingaddress.${selected}.country`)(e);
        updateField('billingaddress.country')(e);

        if (e.target.value === "US")
            setStates(getUsStates);
        if (e.target.value === "CA")
            setStates(getCanadaStates);
        console.log(e.target.value, getUsStates, getCanadaStates);
    }

    const handleAddMore = (key, defaultValue) => {
        setHospitalDetails(setByPath({ ...defaultValue }, `${key}.${count}`, hospitaldetails));
        setSelected(count);
        setCount(count + 1);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleRequest = async () => {
        await exerciseRoleTwoAccept(hospitaldetails, ledger, hospitalProfile.contractId);
        setOpen(false);
    };

    const updateField = (key, isNumber = false) => (e) => {
        if (!isNumber || (isNumber && onChangeNumber(e))) setHospitalDetails(setByPath(e.target.value, key, hospitaldetails));
    };


    useEffect(() => {
        // console.log(hospitalProfile?.payload.hospitaldetails)
        setHospitalDetails({ ...hospitalProfile?.payload.hospitaldetails });
    }, [hospitalProfile]);

    const isChecked = (paytype) => {
        let _payTypes = hospitaldetails?.ach_info?.paymethod;
        if (_payTypes && Array.isArray(_payTypes)) {
            return _payTypes.includes(paytype);
        }
        return false;
    }

    const getPaymethodCheckbox = (input) => {
        return input.map(({ classes, type, label }) => <Typography key={type} component={"div"} className={classes}>
            <FormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        checked={isChecked(type)}
                        onChange={(e) => {
                            let _payTypes = hospitaldetails?.ach_info?.paymethod;
                            if (_payTypes) {
                                if (_payTypes.includes(type)) {
                                    _payTypes.splice(_payTypes.indexOf(type), 1)
                                } else {
                                    _payTypes.push(type)
                                }
                                setHospitalDetails(setByPath(_payTypes, 'ach_info.paymethod', hospitaldetails));
                            } else {
                                setHospitalDetails(setByPath([type], 'ach_info.paymethod', hospitaldetails));
                            }
                        }
                        }
                    />
                }
                label={label}
                labelPlacement="end"
                style={{ marginLeft: 0 }}
            />

        </Typography>);
    }

    return (
        <Dialog
            className={classes.customRoot}
            open={open}
            onClose={() => { setOpen(false) }}
            aria-labelledby="draggable-dialog-title"
        >
            <div className="formsteps">
                <AppBar position="static" color="default" className={classes.tabHeader}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab label="Name of Facility" {...a11yProps(0)} />
                        <Tab label="Physical Address" {...a11yProps(1)} />
                        <Tab label="Shipping Address" {...a11yProps(2)} />
                        <Tab label="Billing Address" {...a11yProps(3)} />
                        <Tab label="Hospital Contact Address" {...a11yProps(4)} />
                        <Tab label="Tax ID" {...a11yProps(5)} />
                        <Tab label="Payment Setting" {...a11yProps(6)} />
                        <Tab label="Bank Information" {...a11yProps(7)} />
                        <Tab label="Virtual Credit Card" {...a11yProps(8)} />
                        <Tab label="Surgeon Onboarding" {...a11yProps(9)} />
                    </Tabs>
                </AppBar>

                <div className={classes.mainFormStepsPanel}>

                    <TabPanel value={value} index={0}>
                        <div className={classes.marginBM30}>
                            <TextField
                                id="input-with-icon-adornment2"
                                label="Name"
                                placeholder=""
                                className={classes.customFullWidth}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={hospitaldetails.facilityname}
                                onChange={updateField('facilityname')}
                                variant="outlined"
                            />
                        </div>
                        <div className={classes.marginBM30}>
                            <TextField
                                id="input-with-icon-adornment2"
                                label="Pref Identity"
                                placeholder=""
                                className={classes.customFullWidth}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={hospitaldetails.pref_facilityid}
                                onChange={updateField('pref_facilityid')}
                                variant="outlined"
                            />
                        </div>
                        <div className={classes.marginBM30}>
                        <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={hospitaldetails?.standalone_payment ? hospitaldetails.standalone_payment : false}
                                        onChange={(e) => setHospitalDetails(setByPath(e.target.checked, 'standalone_payment', hospitaldetails))}
                                    />
                                }
                                label="Enable Standalone Payment"
                                labelPlacement="end"
                                style={{ marginLeft: 0 }}
                            />
                        </div>

                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <>
                            <div className={classes.marginBM30}>
                                <TextField
                                    id="outlined-full-width-mrn"
                                    label="Address 1"
                                    placeholder=""
                                    className={classes.customFullWidth}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={hospitaldetails?.physicaladdress?.address1 || ""}
                                    onChange={updateField('physicaladdress.address1')}
                                />
                            </div>
                            <div className={classes.marginBM30}>
                                <TextField
                                    id="outlined-full-width-mrn"
                                    label="Address 2"

                                    placeholder=""
                                    className={classes.customFullWidth}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                    variant="outlined"
                                    value={hospitaldetails?.physicaladdress?.address2 || ""}
                                    onChange={updateField('physicaladdress.address2')}
                                />
                            </div>
                            <div className={classes.marginBM30}>
                                <TextField
                                    id="outlined-full-width-mrn"
                                    label="City"

                                    placeholder=""
                                    className={classes.customFullWidth}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                    variant="outlined"
                                    value={hospitaldetails?.physicaladdress?.city || ""}
                                    onChange={updateField('physicaladdress.city')}
                                />
                            </div>
                            <div className={classes.marginBM30}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
                                        Country
                                    </InputLabel>
                                    <Select
                                        className={classes.customFullWidth}
                                        onChange={handleCountry}
                                        value={hospitaldetails?.physicaladdress?.country || ""}
                                    >
                                        <MenuItem value={"US"}>US</MenuItem>
                                        <MenuItem value={"CA"}>Canada</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={classes.marginBM30}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
                                        State
                                    </InputLabel>
                                    <Select
                                        // native
                                        className={classes.customFullWidth}
                                        onChange={updateField('physicaladdress.state')}
                                    >
                                        {
                                            states.map(s => <MenuItem key={s[0]} value={s[0]}>{s[1]}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={classes.marginBM30}>
                                <TextField
                                    id="outlined-full-width-mrn"
                                    label="Zip Code"

                                    placeholder=""
                                    className={classes.halfWidth}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                    variant="outlined"
                                    value={hospitaldetails?.physicaladdress?.zipcode || ""}
                                    onChange={updateField('physicaladdress.zipcode')}
                                />
                            </div>
                            <div className={classes.marginBM30}>
                                <TextField
                                    id="outlined-full-width-mrn"
                                    label="Location"

                                    placeholder=""
                                    className={classes.customFullWidth}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                    variant="outlined"
                                    value={hospitaldetails?.physicaladdress?.location || ""}
                                    onChange={updateField('physicaladdress.location')}
                                />
                            </div>

                        </>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <>
                            <Button variant="contained" color="primary" className={classes.textFormation}
                                onClick={() => handleAddMore('shippingaddress')}
                            >
                                Add More Address
                            </Button>
                            <div className={classes.shippingItem}>
                                <Chip label={`${selected + 1}/${count}`} className="bg-info-light" />
                            </div>
                            {
                                hospitaldetails?.shippingaddress?.reverse().map((shippingaddress, selected) => (
                                    <div key={`ship${selected}`}>
                                        <div className={classes.marginBM30}>
                                            <TextField
                                                id="outlined1"
                                                label="Address 1"
                                                placeholder="47 Anyville Rd Nw #2"
                                                className={classes.customFullWidth}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                value={hospitaldetails?.shippingaddress[selected]?.address1 || ""}
                                                onChange={updateField(`shippingaddress.${selected}.address1`)}
                                            />
                                        </div>
                                        <div className={classes.marginBM30}>
                                            <TextField
                                                id="outlined-2"
                                                label="Address 2"
                                                placeholder=""
                                                className={classes.customFullWidth}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                value={hospitaldetails.shippingaddress[selected].address2 || ""}
                                                onChange={updateField(`shippingaddress.${selected}.address2`)}
                                            />
                                        </div>
                                        <div className={classes.marginBM30}>
                                            <TextField
                                                id="outlined-f3"
                                                label="City"
                                                placeholder="Phoenix"
                                                className={classes.customFullWidth}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                value={hospitaldetails.shippingaddress[selected].city || ""}
                                                onChange={updateField(`shippingaddress.${selected}.city`)}
                                            />
                                        </div>
                                        <div className={classes.marginBM30}>
                                            <FormControl variant="outlined" className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
                                                    Country
                                                </InputLabel>
                                                <Select
                                                    className={classes.customFullWidth}
                                                    onChange={handleCountry}
                                                    value={hospitaldetails.shippingaddress[selected].country || ""}
                                                >
                                                    <MenuItem value={"US"}>US</MenuItem>
                                                    <MenuItem value={"CA"}>Canada</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className={classes.marginBM30}>
                                            <FormControl variant="outlined" className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
                                                    State
                                                </InputLabel>
                                                <Select
                                                    // native
                                                    className={classes.customFullWidth}
                                                    onChange={updateField(`shippingaddress.${selected}.state`)}
                                                    value={hospitaldetails.shippingaddress[selected].state || ""}
                                                >
                                                    {
                                                        states.map(s => <MenuItem key={s[0]} value={s[0]}>{s[1]}</MenuItem>)
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className={classes.marginBM30}>
                                            <TextField
                                                id="outlined-4n"
                                                label="Zip Code"
                                                placeholder="123456"
                                                className={classes.customFullWidth}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                value={hospitaldetails.shippingaddress[selected].zipcode || ""}
                                                onChange={updateField(`shippingaddress.${selected}.zipcode`)}
                                            />
                                        </div>
                                        <div className={classes.marginBM30}>
                                            <TextField
                                                id="outlined-6"
                                                label="Location"
                                                placeholder=""
                                                className={classes.customFullWidth}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                value={hospitaldetails.shippingaddress[selected].location || ""}
                                                onChange={updateField(`shippingaddress.${selected}.location`)}
                                            />
                                        </div>
                                        <hr />
                                    </div>
                                ))
                            }
                        </>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <>
                            <div className={classes.marginBM30}>
                                <TextField
                                    id="outlined1"
                                    label="Address1"
                                    placeholder="47 Anyville Rd Nw #2"
                                    className={classes.customFullWidth}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={hospitaldetails?.billingaddress?.address1 || ""}
                                    onChange={updateField('billingaddress.address1')}
                                />
                            </div>
                            <div className={classes.marginBM30}>
                                <TextField
                                    id="outlined-2"
                                    label="Address2"
                                    placeholder=""
                                    className={classes.customFullWidth}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={hospitaldetails?.billingaddress?.address2 || ""}
                                    onChange={updateField('billingaddress.address2')}
                                />
                            </div>
                            <div className={classes.marginBM30}>
                                <TextField
                                    id="outlined-f3"
                                    label="City"
                                    placeholder="Phoenix"
                                    className={classes.customFullWidth}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={hospitaldetails?.billingaddress?.city || ""}
                                    onChange={updateField('billingaddress.city')}
                                />
                            </div>
                            <div className={classes.marginBM30}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
                                        Country
                                    </InputLabel>
                                    <Select
                                        className={classes.customFullWidth}
                                        onChange={handleCountry}
                                        value={hospitaldetails?.billingaddress?.country || ""}
                                    >
                                        <MenuItem value={"US"}>US</MenuItem>
                                        <MenuItem value={"CA"}>Canada</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={classes.marginBM30}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
                                        State
                                    </InputLabel>
                                    <Select
                                        // native
                                        className={classes.customFullWidth}
                                        value={hospitaldetails?.billingaddress?.state || ""}
                                        onChange={(e)=>updateField('billingaddress.state')(e)}
                                    >
                                        {
                                            states.map(s => {return <MenuItem key={s[0]} value={s[0]}>{s[1]}</MenuItem>})
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={classes.marginBM30}>
                                <TextField
                                    id="outlined-4n"
                                    label="Zip Code"
                                    placeholder="123456"
                                    className={classes.customFullWidth}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={hospitaldetails?.billingaddress?.zipcode || ""}
                                    onChange={updateField('billingaddress.zipcode')}
                                />
                            </div>
                            <div className={classes.marginBM30}>
                                <TextField
                                    id="outlined-6"
                                    label="Location"
                                    placeholder=""
                                    className={classes.customFullWidth}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={hospitaldetails?.billingaddress?.location || ""}
                                    onChange={updateField('billingaddress.location')}
                                />
                            </div>
                        </>
                    </TabPanel>
                    <TabPanel value={value} index={4}>

                        <>
                            <Button variant="contained" color="primary" className={classes.textFormation}
                                onClick={() => handleAddMore('point_contact')}
                            >
                                Add More User
                            </Button>
                            <div className={classes.shippingItem}>
                                <Chip label={`${selected + 1}/${count}`} className="bg-info-light" />
                            </div>
                            {
                                hospitaldetails?.point_contact?.reverse().map((pointofcontact, selected) => (
                                    <div key={`ship${selected}`}>
                                        <div className={classes.marginBM30}>
                                            <TextField
                                                id="outlined-full-width-mrn"
                                                label="Name"

                                                placeholder="eg: name"
                                                className={classes.customFullWidth}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={hospitaldetails.point_contact[selected].firstname || ""}
                                                onChange={updateField(`point_contact.${selected}.firstname`)}
                                                variant="outlined"
                                            />
                                        </div>
                                        <div className={classes.marginBM30}>
                                            <TextField
                                                id="outlined-full-width88-mrn"
                                                label="Last Name"
                                                placeholder="eg: Smith"
                                                className={classes.customFullWidth}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                value={hospitaldetails.point_contact[selected].lastname || ""}
                                                onChange={updateField(`point_contact.${selected}.lastname`)}
                                            />
                                        </div>
                                        <div className={classes.marginBM30}>
                                            <FormControl variant="outlined" className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
                                                    Gender
                                                </InputLabel>
                                                <Select
                                                    // native
                                                    displayEmpty
                                                    className={classes.halfWidth}
                                                    inputProps={{
                                                        name: 'age',
                                                        id: 'outlined-age-native-simple1',
                                                    }}
                                                    value={hospitaldetails.point_contact[selected].gender || ""}
                                                    onChange={updateField(`point_contact.${selected}.gender`)}
                                                >
                                                    {getGenders()}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className={classes.marginBM30}>
                                            <TextField
                                                id="outlined-full-width-mrn"
                                                label="Phone"

                                                placeholder="eg: 0123456789"
                                                className={classes.customFullWidth}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}

                                                variant="outlined"
                                                value={hospitaldetails.point_contact[selected].phone || ""}
                                                onChange={updateField(`point_contact.${selected}.phone`)}
                                            />
                                        </div>
                                        <div className={classes.marginBM30}>
                                            <TextField
                                                id="outlined-full-width-mrn"
                                                label="Email"

                                                placeholder="eg: info@email.com"
                                                className={classes.customFullWidth}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}

                                                variant="outlined"
                                                value={hospitaldetails.point_contact[selected].email || ""}
                                                onChange={updateField(`point_contact.${selected}.email`)}
                                            />
                                        </div>
                                        <div className={classes.marginBM30}>
                                            <TextField
                                                id="outlined-full-width-mrn"
                                                label="Fax"

                                                placeholder="eg: 1234567"
                                                className={classes.customFullWidth}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}

                                                variant="outlined"
                                                value={hospitaldetails.point_contact[selected].fax || ""}
                                                onChange={updateField(`point_contact.${selected}.fax`)}
                                            />
                                        </div>
                                        <div className={classes.marginBM30}>
                                            <TextField
                                                id="outlined-full-width-mrn"
                                                label="Job"
                                                placeholder="eg: job title"
                                                className={classes.customFullWidth}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}

                                                variant="outlined"
                                                value={hospitaldetails.point_contact[selected].jobtitle || ""}
                                                onChange={updateField(`point_contact.${selected}.jobtitle`)}
                                            />
                                        </div>
                                        <hr />
                                    </div>
                                ))
                            }
                        </>

                    </TabPanel>
                    <TabPanel value={value} index={5}>

                        <div className={classes.marginBM30}>
                            <TextField
                                id="outlined-full-width-mrn"
                                label="Tax ID"
                                placeholder=""
                                className={classes.customFullWidth}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}

                                variant="outlined"
                                value={hospitaldetails.taxid}
                                onChange={updateField('taxid')}
                            />
                        </div>
                    </TabPanel>

                    <TabPanel value={value} index={6}>

                        <Typography component={"div"} >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={hospitaldetails?.ach_info?.discover_payment ? hospitaldetails.ach_info.discover_payment : false}
                                        onChange={(e) => setHospitalDetails(setByPath(e.target.checked, 'ach_info.discover_payment', hospitaldetails))}
                                    />
                                }
                                label="Enable Payment through Discover"
                                labelPlacement="start"
                                style={{ marginLeft: 0 }}
                            />

                        </Typography>

                        {/* <Typography component={"div"} >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={isChecked('Check')}
                                        onChange={(e) => {
                                            let _payTypes = hospitaldetails?.ach_info?.paymethod;
                                            if (_payTypes) {
                                                if (_payTypes.includes('Check')) {
                                                    _payTypes.splice(_payTypes.indexOf('Check'), 1)
                                                } else {
                                                    _payTypes.push('Check')
                                                }
                                                setHospitalDetails(setByPath(_payTypes, 'ach_info.paymethod', hospitaldetails));
                                            } else {
                                                setHospitalDetails(setByPath(['Check'], 'ach_info.paymethod', hospitaldetails));
                                            }
                                        }
                                        }
                                    />
                                }
                                label="Check"
                                labelPlacement="end"
                                style={{ marginLeft: 0 }}
                            />

                        </Typography>

                        <Typography component={"div"} >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={isChecked('ACH')}
                                        onChange={(e) => {
                                            let _payTypes = hospitaldetails?.ach_info?.paymethod;
                                            if (_payTypes) {
                                                if (_payTypes.includes('ACH')) {
                                                    _payTypes.splice(_payTypes.indexOf('ACH'), 1)
                                                } else {
                                                    _payTypes.push('ACH')
                                                }
                                                setHospitalDetails(setByPath(_payTypes, 'ach_info.paymethod', hospitaldetails));
                                            } else {
                                                setHospitalDetails(setByPath(['ACH'], 'ach_info.paymethod', hospitaldetails));
                                            }
                                        }
                                        }
                                    />
                                }
                                label="ACH"
                                labelPlacement="end"
                                style={{ marginLeft: 0 }}
                            />

                        </Typography>

                        <Typography component={"div"} className={classes.marginBM30}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={isChecked('VCard')}
                                        onChange={(e) => {
                                            let _payTypes = hospitaldetails?.ach_info?.paymethod;
                                            if (_payTypes) {
                                                if (_payTypes.includes('VCard')) {
                                                    _payTypes.splice(_payTypes.indexOf('VCard'), 1)
                                                } else {
                                                    _payTypes.push('VCard')
                                                }
                                                setHospitalDetails(setByPath(_payTypes, 'ach_info.paymethod', hospitaldetails));
                                            } else {
                                                setHospitalDetails(setByPath(['VCard'], 'ach_info.paymethod', hospitaldetails));
                                            }
                                        }
                                        }
                                    />
                                }
                                label="Prefunded Virtual Debit Card"
                                labelPlacement="end"
                                style={{ marginLeft: 0 }}
                            />

                        </Typography> */}
                        {
                            getPaymethodCheckbox([
                                { classes: "", type: "Check", label: "Check" },
                                { classes: "", type: "ACH", label: "ACH" },
                                { classes: classes.marginBM30, type: "VCard", label: "Prefunded Virtual Debit Card" },
                            ])
                        }


                        <div className={classes.marginBM30}>
                            <TextField
                                id="outlined-full-width-mrn"
                                label="PayerNetworkID"
                                placeholder=""
                                className={classes.customFullWidth}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                value={hospitaldetails?.ach_info?.payernetwork_id}
                                onChange={updateField('ach_info.payernetwork_id')}
                            />
                        </div>

                        <div className={classes.marginBM30}>
                            <TextField
                                id="outlined-full-width-mrn"
                                label="PartnerNetworkID"
                                placeholder=""
                                className={classes.customFullWidth}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                value={hospitaldetails?.ach_info?.partnernetwork_id}
                                onChange={updateField('ach_info.partnernetwork_id')}
                            />
                        </div>

                        <div className={classes.marginBM30}>
                            <TextField
                                id="outlined-full-width-mrn"
                                label="SenderID"
                                placeholder=""
                                className={classes.customFullWidth}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                value={hospitaldetails?.ach_info?.sender_id}
                                onChange={updateField('ach_info.sender_id')}
                            />
                        </div>

                        <div className={classes.marginBM30}>
                            <TextField
                                id="outlined-full-width-mrn"
                                label="PartnerNameID"
                                placeholder=""
                                className={classes.customFullWidth}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                value={hospitaldetails?.ach_info?.partnername_id}
                                onChange={updateField('ach_info.partnername_id')}
                            />
                        </div>

                        <div className={classes.marginBM30}>
                            <TextField
                                id="outlined-full-width-mrn"
                                label="User Agent"
                                placeholder=""
                                className={classes.customFullWidth}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                value={hospitaldetails?.ach_info?.user_agent}
                                onChange={updateField('ach_info.user_agent')}
                            />
                        </div>

                        <Typography component={"div"}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={hospitaldetails?.ach_info?.straight_through_payment ? hospitaldetails.ach_info.straight_through_payment : false}
                                        onChange={(e) => setHospitalDetails(setByPath(e.target.checked, 'ach_info.straight_through_payment', hospitaldetails))}
                                    />
                                }
                                label="Enable straight through Payments"
                                labelPlacement="start"
                                style={{ marginLeft: 0 }}
                            />

                        </Typography>
                    </TabPanel>

                    <TabPanel value={value} index={7}>

                        <div className={classes.marginBM30}>
                            <TextField
                                id="outlined-full-width-mrn"
                                label="Bank ABA Number"
                                placeholder=""
                                className={classes.customFullWidth}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                value={hospitaldetails?.ach_info?.routingnumber}
                                onChange={updateField('ach_info.routingnumber')}
                            />
                        </div>
                        <div className={classes.marginBM30}>
                            <TextField
                                id="outlined-full-width-mrn"
                                label="Bank Account Number"
                                placeholder=""
                                className={classes.customFullWidth}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                value={hospitaldetails?.ach_info?.accountnumber}
                                onChange={updateField('ach_info.accountnumber')}
                            />
                        </div>
                    </TabPanel>

                    <TabPanel value={value} index={8}>

                        <div className={classes.marginBM30}>
                            <TextField
                                id="outlined-full-width-mrn"
                                label="Card Number"
                                placeholder=""
                                className={classes.customFullWidth}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                value={hospitaldetails?.ach_info?.cardnumber}
                                onChange={updateField('ach_info.cardnumber')}
                            />
                        </div>
                        <div className={classes.marginBM30}>
                            <TextField
                                id="outlined-full-width-mrn"
                                label="Expiration"
                                type="date"
                                placeholder=""
                                className={classes.customFullWidth}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                value={hospitaldetails?.ach_info?.cardexpiry}
                                onChange={updateField('ach_info.cardexpiry')}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={9}>
                        <OperatorSurgeonList
                            hospital={hospitalProfile?.payload?.hospital}
                        />
                    </TabPanel>
                </div>
            </div>
            <div className="form-footer">
                <div className="right-buttons">
                    <Button autoFocus onClick={() => { setOpen(false) }} className="noBtn" variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleRequest} className="yesBtn" variant="contained" color="primary">
                        Update
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}
