import React, { useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import { useLedger, useParty } from "@daml/react";
import { Button, AppBar, Tabs, Tab } from "@material-ui/core";
import { requiredContractDetailData } from "../../models/ContractManagement";
import { useDamlState } from "../../context/DamlContext";
import { useLayoutDispatch, setLoading, unsetLoading } from "../../context/LayoutContext";
import TabPanel, { a11yProps } from "../../components/Modals/MultiTabsModals/TabPanel";
import ModalDialog from '../../components/Modals/NewGeneralModal';
import SnackbarModal from "../../components/Modals/SnackbarModal";
import { CONTRACT_CREATE_EVENT, CONTRACT_UPDATE_EVENT, EVENT_REQUIRED, EVENT_VERIFIED } from "../../store/actions/constants";
import useContract from "../../hooks/useContract";
import useEvent from "../../hooks/useEvent";
import useStyles from "../surgicalevent/styles";
import ContractOverview from './contract-overview';
import ContractDetail from './contract-detail';
import { createContractManagement, updateContractManagement, createContractTotals, updateContractTotals } from "./handle";
import useStyles1 from "./styles";


/**
 * Contract Action Buttons Component
 * @param {Object} classes 
 * @param {Object} classes1 
 * @param {Function} setOpen 
 * @param {Boolean} isCreate 
 * @returns 
 */
const ContractActionButtons = ({
  classes, classes1, setOpen, isCreate=false, 
}) => {

  const { onEventStart } = useEvent();

  // handle to click Save button
  const handleSaveClick = async () => {
    // setOpen(false);
    if (isCreate) onEventStart(CONTRACT_CREATE_EVENT);
    else onEventStart(CONTRACT_UPDATE_EVENT);
  };

  // handle to click Cancel button
  const handleCancelClick = () => {
    setOpen(false);
  };


  return (
    <>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleCancelClick} 
        className={classNames(classes.newCompleteBtn, "secondary-color", classes1.newBtn)} 
      >
        Cancel
      </Button>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleSaveClick} 
        className={classNames(classes.newCompleteBtn, classes1.newBtn)} 
      >
        Save
      </Button>
    </>
  );
};

/**
 * Dialog when Create Contract Management
 * @param {Boolean} open
 * @param {Function} setOpen
 * @param {Object} contract
 */
export default function CreateContractManagement ({
  open, setOpen, contract={}, 
}) {
  // console.log("[CreateContractManagement]", contract);

  const classes = useStyles();
  const classes1 = useStyles1();

  const { role, roleCid } = useDamlState();
  const hospital = useParty();

  const layoutDispatch = useLayoutDispatch();

  const ledger = useLedger();

  const { detail, setAll: setDefault, setDetailErrors, serviceterm, pmtrebate, spendrebate, volumerebate } = useContract();
  const { event, status, onEventRun, onEventEnd, onEventVerify, onEventReject } = useEvent();

  const { contractId, contractId1, payload } = contract;

  // Create|Edit
  const isCreate = !contractId;
  // Editable
  const isEditable = (role === 'Hospital');

  // ContractId is contractId of Contract Management
  const [ContractId, setContractId] = useState(null);
  const [ContractId1, setContractId1] = useState(null);

  const [value, setValue] = useState(0);

  // Toast
  const [openToast, setOpenToast] = useState(false);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Click "Create" or "Save Update"
  const addContractManagement = useCallback(async (isAdd=true) => {
    if (!isEditable) return;
    
    // spinner
    setLoading(layoutDispatch);
    onEventRun();

    let res = false;
    // check out file
    if (detail.file && !isAdd) {
      //
    }
    else {
      if (isAdd) {
        // Create
        res = await createContractManagement({
          ledger, 
          roleCid, 
          vendor: detail.vendor, 
          detail, 
          serviceterm, 
          pmtrebate, 
          spendrebate, 
          volumerebate,
        });
        await createContractTotals({
          ledger, 
          roleCid, 
          vendor: detail.vendor, 
          detail: {
            ...detail,
            contractnumber: detail.contractmanagementnumber,
          }, 
          cptcodetotal: [],
        });
      }
      else {
        // Update
        res = await updateContractManagement({
          ledger, 
          roleCid, 
          contractCid: ContractId, 
          detail, 
          serviceterm, 
          pmtrebate, 
          spendrebate, 
          volumerebate,
        });
        if (ContractId1) await updateContractTotals({
          ledger, 
          roleCid, 
          contractCid: ContractId1, 
          detail: {
            ...detail,
            contractnumber: detail.contractmanagementnumber,
          }, 
          cptcodetotal: [],
        });
        else await createContractTotals({
          ledger, 
          roleCid, 
          vendor: detail.vendor, 
          detail: {
            ...detail,
            contractnumber: detail.contractmanagementnumber,
          }, 
          cptcodetotal: [],
        });
      }
    }
    console.log("[CreateContractManagement] addContractManagement", res);
    
    unsetLoading(layoutDispatch);
    onEventEnd();

    setOpen(false);

  }, [isEditable, layoutDispatch, onEventRun, onEventEnd, ContractId, ContractId1, detail, setOpen, ledger, roleCid, 
    serviceterm, pmtrebate, spendrebate, volumerebate]);


  useEffect(() => {
    console.log("[CreateContractManagement] set default data.");
    if (!open) return;
    if (payload) {
      setDefault({
        detail: {
          ...payload.detail,
          vendor: payload.vendor,
        },
        serviceterm: payload.serviceterm ?? [],
        pmtrebate: payload.pmtrebate ?? [],
        spendrebate: payload.spendrebate ?? [],
        volumerebate: payload.volumerebate ?? [],
        cptcodetotal: payload.cptcodetotal ?? [],
      });
      setContractId(contractId);
      setContractId1(contractId1);
    }
    else {
      setDefault({
        detail: {},
        serviceterm: [],
        pmtrebate: [],
        spendrebate: [],
        volumerebate: [],
        cptcodetotal: [],
      });
      setContractId(null);
      setContractId1(null);
    }
    setValue(0);
  }, [payload, setDefault, hospital, open, contractId, contractId1]);

  useEffect(() => {
    if (!open) return;
    console.log("[CreateContractManagement] On Start Event", event, status);
    if (event === CONTRACT_CREATE_EVENT && status === EVENT_VERIFIED && isEditable) {
      addContractManagement(true);
    }
    else if (event === CONTRACT_UPDATE_EVENT && status === EVENT_VERIFIED && isEditable) {
      addContractManagement(false);
    }
  }, [open, event, status, isEditable, addContractManagement]);

  
  useEffect(() => {
    if ((event === CONTRACT_CREATE_EVENT || event === CONTRACT_UPDATE_EVENT) && status === EVENT_REQUIRED) {
      // check out PO data
      let newErrors = requiredContractDetailData(detail);
      if (Object.keys(newErrors).length > 0) {
        setDetailErrors({ ...newErrors });
        setValue(0);
        onEventReject();
      }
      else {
        onEventVerify();
      }
    }
  }, [event, status, detail, onEventVerify, onEventReject, setDetailErrors]);


  return (
    <>
      <ModalDialog
        open={open}
        setOpen={setOpen}
        title={(
          <AppBar position="static" className={classNames(classes.tabPanelMenu, classes1.detailTabPanelMenu)}>
            <Tabs indicatorColor="primary" textColor="primary" value={value} onChange={handleChange} 
              aria-label="simple tabs example" centered
            >
              <Tab label={isCreate ? 'Add Contract' : 'Contract Overview'} {...a11yProps(0)} />
              <Tab label="Contract Details" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
        )}
        content={(
          <>
            <TabPanel value={value} index={0} className={classes.tabPanelbody}>
              <ContractOverview
                options={{
                  isCreate,
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tabPanelbody}>
              <ContractDetail
                options={{
                  isCreate,
                }}
              />
            </TabPanel>
          </>
        )}
        actions={(
          <ContractActionButtons
            classes={classes}
            classes1={classes1}
            setOpen={setOpen}
            isCreate={isCreate}
          />
        )}
        height={634}
      />

      <SnackbarModal
        type={"success"}
        open={openToast}
        setOpen={setOpenToast}
        title=""
        content={"..."}
        width={330}
        height={88}
        className={classes.toastMedium}
      />
    </>
  );
}
