import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  messages: {
    padding: '5% 0',
    overflow: 'auto',
    flex: 'auto',
    height: '210px',
  },
}));
