import * as React from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import GridContent from "./GridContent";
import { useHistory } from "react-router-dom";
import { useDamlState } from "../../context/DamlContext";
const roundToTwoDecimalNumber = (data) => {
  if (data) {
    return Number(parseFloat(data).toFixed(0)).toLocaleString("en");
  }
  return 0;
};

const colorA6A0A0 = "#A6A0A0";
export const PurchaseOrderManager = (props) => {
  const history = useHistory();
  return (
    <>
      <Grid
        container
        sx={{
          direction: "flex",
          alignContent: "center",
          justifyItems: "center",
          marginTop: "0px",
        }}
        onClick={(e) => {
          history.push("/app/purchaseorder-list");
        }}
      >
        <Grid
          item
          p={2}
          xs={6}
          borderRight={6}
          borderBottom={6}
          borderColor="#CCCCCC"
        >
          <GridContent
            currency={false}
            color={"#4CAF50"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"47px"}
            value={roundToTwoDecimalNumber(props.vendorNotAccepted)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            Vendor not Accepted
          </Typography>
        </Grid>
        <Grid item p={2} xs={6} borderBottom={6} borderColor="#CCCCCC">
          <GridContent
            currency={false}
            color={"#F44336"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"47px"}
            value={roundToTwoDecimalNumber(props.unsentPO)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            Unsent Purchase Orders
          </Typography>
        </Grid>
        <Grid item p={2} xs={9} paddingLeft={11} borderColor="#CCCCCC">
          <GridContent
            currency={false}
            color={"#F44336"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"47px"}
            value={roundToTwoDecimalNumber(props.posDeclined)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            POs Declined
          </Typography>
        </Grid>
        `
      </Grid>
    </>
  );
};

export const PurchaseOrderAssociate = (props) => {
  const history = useHistory();
  const { userlistPayload } = useDamlState();
  const accessLevel = userlistPayload?.accesslevel;
  return (
    <>
      <Grid
        container
        onClick={(e) => {
          history.push(`${ accessLevel !=='VManager' ? '/app/purchaseorder-list' : '/app/receivedpo' }`);
        }}
      >
        <Grid item p={2} xs={6} borderRight={6} borderColor="#CCCCCC">
          {/*change value to actual data*/}
          <GridContent
            color={"#4CAF50"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"45px"}
            value={roundToTwoDecimalNumber(props.newPurchaseOrder)}
          />

          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            New Purchase Order Added
          </Typography>
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            {"($"}
            {roundToTwoDecimalNumber(props.newPurchaseOrderAmount)}
            {")"}
          </Typography>
        </Grid>

        <Grid item p={2} xs={6}>
          <GridContent
            color={"#F44336"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"45px"}
            value={roundToTwoDecimalNumber(props.unsentPO)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            Unsent Purchase Orders
          </Typography>
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            {"($"}
            {roundToTwoDecimalNumber(props.unsentPOAmount)}
            {")"}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export const SurgicalEvents = (props) => {
  const history = useHistory();
  return (
    <>
      <Grid
        container
        onClick={(e) => {
          history.push("/app/surgicalevent");
        }}
      >
        <Grid item p={2} xs={6} borderRight={6} borderColor="#CCCCCC">
          <GridContent
            color={"#F7CD37"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"45px"}
            //add here
            value={roundToTwoDecimalNumber(props.scheduledToday)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            Scheduled Today
          </Typography>
        </Grid>

        <Grid item p={2} xs={6}>
          <GridContent
            color={"#4CAF50"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"45px"}
            //add here
            value={roundToTwoDecimalNumber(props.completed)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            Completed
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export const InventoryStatus = (props) => {
  const history = useHistory();

  return (
    <>
      <Grid
        container
        onClick={(e) => {
          history.push("/app/inventorylist");
        }}
      >
        <Grid
          item
          p={2}
          xs={6}
          borderRight={6}
          borderBottom={6}
          borderColor="#CCCCCC"
        >
          <GridContent
            color={"#4CAF50"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"47px"}
            value={roundToTwoDecimalNumber(props.pendingShip)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            Open Items
          </Typography>
        </Grid>
        <Grid item p={2} xs={6} borderBottom={6} borderColor="#CCCCCC">
          <GridContent
            color={"#F7CD37"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"47px"}
            value={roundToTwoDecimalNumber(props.belowParLevel)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            Below par level
          </Typography>
        </Grid>
        <Grid item p={2} xs={6} borderRight={6} borderColor="#CCCCCC">
          <GridContent
            color={"#F44336"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"47px"}
            value={roundToTwoDecimalNumber(props.outOfStock)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            Out of Stock
          </Typography>
        </Grid>
        <Grid item p={2} xs={6}>
          <GridContent
            color={"#F7CD37"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"47px"}
            value={roundToTwoDecimalNumber(props.backOrdered)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            Back-Ordered
          </Typography>
        </Grid>
        `
      </Grid>
    </>
  );
};

export const InventoryValue = (props) => {
  const history = useHistory();
  return (
    <>
      <Grid
        container
        onClick={(e) => {
          history.push("/app/inventorylist");
        }}
      >
        <Grid
          item
          p={2}
          xs={6}
          borderRight={6}
          borderBottom={6}
          borderColor="#CCCCCC"
        >
          <GridContent
            currency={true}
            color={"#4CAF50"}
            fontWeight={550}
            fontSize={"20px"}
            lineHeight={"23px"}
            value={roundToTwoDecimalNumber(props.totalInventory)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            Total Inventory
          </Typography>
        </Grid>

        <Grid item p={2} xs={6} borderBottom={6} borderColor="#CCCCCC">
          <GridContent
            currency={true}
            color={"#4CAF50"}
            fontWeight={550}
            fontSize={"20px"}
            lineHeight={"23px"}
            value={roundToTwoDecimalNumber(props.consigned)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            Consigned
          </Typography>
        </Grid>
        <Grid item p={2} xs={6} borderRight={6} borderColor="#CCCCCC">
          <GridContent
            currency={true}
            color={"#4CAF50"}
            fontWeight={550}
            fontSize={"20px"}
            lineHeight={"23px"}
            value={roundToTwoDecimalNumber(props.owned)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            Owned
          </Typography>
        </Grid>

        <Grid item p={2} xs={6}>
          <GridContent
            currency={true}
            color={"#4CAF50"}
            fontWeight={550}
            fontSize={"20px"}
            lineHeight={"23px"}
            value={roundToTwoDecimalNumber(props.stock)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            Stock
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export const ProductUtilization = (props) => {
  const history = useHistory();
  return (
    <>
      <Grid
        container
        onClick={(e) => {
          history.push("/app/purchaseorder-list");
        }}
      >
        <Grid
          item
          p={2}
          xs={6}
          borderRight={6}
          borderBottom={6}
          borderColor="#CCCCCC"
        >
          <GridContent
            currency={true}
            color={"#4CAF50"}
            fontWeight={550}
            fontSize={"20px"}
            lineHeight={"23px"}
            value={roundToTwoDecimalNumber(props.productsUsed1DayAmount)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
              //width: '10vh'
            }}
          >
            Product used in 1 day
          </Typography>
        </Grid>

        <Grid item p={2} xs={6} borderBottom={6} borderColor="#CCCCCC">
          <GridContent
            currency={true}
            color={"#4CAF50"}
            fontWeight={550}
            fontSize={"20px"}
            lineHeight={"23px"}
            value={roundToTwoDecimalNumber(props.productsUsed7DaysAmount)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
              //width: '10vh'
            }}
          >
            Product used in 7 days
          </Typography>
        </Grid>
        <Grid item p={2} xs={9} paddingLeft={11} borderColor="#CCCCCC">
          <GridContent
            currency={true}
            color={"#4CAF50"}
            fontWeight={550}
            fontSize={"20px"}
            lineHeight={"23px"}
            value={roundToTwoDecimalNumber(props.productsUsed30DaysAmount)}
            //swidth={"18vh"}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
              //width: '20vh'
            }}
          >
            Product used in 30 days
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export const Invoices = (props) => {
  const history = useHistory();
  return (
    <>
      <Grid
        container
        onClick={(e) => {
          history.push("/app/receivedinvoice");
        }}
      >
        <Grid
          item
          p={2}
          xs={6}
          borderRight={6}
          borderBottom={6}
          borderColor="#CCCCCC"
        >
          <GridContent
            color={"#4CAF50"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"47px"}
            value={roundToTwoDecimalNumber(props.sevenDays)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            7 days
          </Typography>
        </Grid>
        <Grid item p={2} xs={6} borderBottom={6} borderColor="#CCCCCC">
          <GridContent
            color={"#F7CD37"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"47px"}
            value={roundToTwoDecimalNumber(props.thirtyDays)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            30 days
          </Typography>
        </Grid>
        <Grid item p={2} xs={6} borderRight={6} borderColor="#CCCCCC">
          <GridContent
            color={"#F44336"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"47px"}
            value={roundToTwoDecimalNumber(props.oneEightyDays)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            180 days
          </Typography>
        </Grid>
        <Grid item p={2} xs={6}>
          <GridContent
            color={"#F44336"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"47px"}
            value={roundToTwoDecimalNumber(props.year)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            1 year
          </Typography>
        </Grid>
        `
      </Grid>
    </>
  );
};

export const Expired = (props) => {
  const history = useHistory();
  const { userlistPayload } = useDamlState();
  const accessLevel = userlistPayload?.accesslevel;
  return (
    <>
      <Grid
        container
        onClick={(e) => {
          history.push(`${ accessLevel !== 'VManager' ? '/app/inventorylist' : '/app/vendorpostprocedurelist'}`);
        }}
      >
        <Grid item p={2} xs={6} borderRight={6} borderColor="#CCCCCC">
          {/*change value to actual data*/}
          <GridContent
            color={"#F7CD37"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"45px"}
            value={roundToTwoDecimalNumber(props.expiring)}
          />

          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            Expiring Products
          </Typography>
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            {"($"}
            {roundToTwoDecimalNumber(props.expiringAmount)}
            {")"}
          </Typography>
        </Grid>

        <Grid item p={2} xs={6}>
          <GridContent
            color={"#F44336"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"45px"}
            value={roundToTwoDecimalNumber(props.expired)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            Expired Products
          </Typography>
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            {"($"}
            {roundToTwoDecimalNumber(props.expiredAmount)}
            {")"}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export const ExpiredContracts = (props) => {
  const history = useHistory();
  return (
    <>
      <Grid container>
        <Grid item p={2} xs={6} borderRight={6} borderColor="#CCCCCC">
          {/*change value to actual data*/}
          <GridContent
            color={"#F7CD37"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"45px"}
            value={roundToTwoDecimalNumber(props.expiring)}
            onClick={(e) => {
              history.push("/app/inventorylist");
            }}
          />

          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            Expiring Contracts
          </Typography>
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            {"($"}
            {roundToTwoDecimalNumber(props.expiringAmount)}
            {")"}
          </Typography>
        </Grid>

        <Grid item p={2} xs={6}>
          <GridContent
            color={"#F44336"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"45px"}
            value={roundToTwoDecimalNumber(props.expired)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            Expired Contracts
          </Typography>
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            {"($"}
            {roundToTwoDecimalNumber(props.expiredAmount)}
            {")"}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export const Recall = (props) => {
  const history = useHistory();
  return (
    <>
      <Grid
        container
        onClick={(e) => {
          history.push("/app/recall-lookup-list");
        }}
      >
        <Grid item p={2} xs={6} borderRight={6} borderColor="#CCCCCC">
          <GridContent
            color={"#F7CD37"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"45px"}
            value={roundToTwoDecimalNumber(props.recall)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            In Recall
          </Typography>
        </Grid>

        <Grid item p={2} xs={6}>
          <GridContent
            color={"#4CAF50"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"45px"}
            value={roundToTwoDecimalNumber(props.returnsAwaiting)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            Returns awaiting Approval
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export const ProductsUsedOff = (props) => {
  const history = useHistory();

  return (
    <>
      <Grid
        container
        onClick={(e) => {
          history.push("/app/compliancelist");
        }}
      >
        <Grid
          item
          p={2}
          xs={6}
          borderRight={6}
          borderBottom={6}
          borderColor="#CCCCCC"
        >
          <GridContent
            color={"#4CAF50"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"47px"}
            value={roundToTwoDecimalNumber(props.usedOffsevenDays)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            7 Days
          </Typography>
        </Grid>
        <Grid item p={2} xs={6} borderBottom={6} borderColor="#CCCCCC">
          <GridContent
            color={"#F7CD37"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"47px"}
            value={roundToTwoDecimalNumber(props.usedOffthirtyDays)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            30 Days
          </Typography>
        </Grid>
        <Grid item p={2} xs={6} borderRight={6} borderColor="#CCCCCC">
          <GridContent
            color={"#F44336"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"47px"}
            value={roundToTwoDecimalNumber(props.usedOffoneEightyDays)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            180 Days
          </Typography>
        </Grid>
        <Grid item p={2} xs={6}>
          <GridContent
            color={"#F7CD37"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"47px"}
            value={roundToTwoDecimalNumber(props.usedOffyear)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            Last Year
          </Typography>
        </Grid>
        `
      </Grid>
    </>
  );
};

export const Compliance = (props) => {
  const history = useHistory();
  return (
    <>
      <Grid
        container
        onClick={(e) => {
          history.push("/app/compliancelist");
        }}
      >
        <Grid item p={2} xs={11}>
          <GridContent
            color={"#F44336"}
            fontWeight={800}
            fontSize={"46px"}
            lineHeight={"112px"}
            value={roundToTwoDecimalNumber(props.productsOutOfCompliance)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
              //width: '30vh'
            }}
          >
            Products out of Compliance
          </Typography>
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            {"($"}
            {roundToTwoDecimalNumber(props.productsOutOfComplianceAmount)}
            {")"}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

//Vendors Cards
export const InventoryAdjustments = (props) => {
  const history = useHistory();
  return (
    <>
      <Grid
        container
        onClick={(e) => {
          history.push("/app/inventorylist-facility");
        }}
      >
        <Grid item p={2} xs={6} borderRight={6} borderColor="#CCCCCC">
          {/*change value to actual data*/}
          <GridContent
            color={"#4CAF50"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"45px"}
            value={roundToTwoDecimalNumber(props.hospitalAdjustedCount)}
          />

          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            Hospital Adjusted
          </Typography>
        </Grid>

        <Grid item p={2} xs={6}>
          <GridContent
            color={"#F44336"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"45px"}
            value={roundToTwoDecimalNumber(props.returnRequringApprovalCount)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            Returns requiring Approval
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};


export const Shipping = (props) => {
  const history = useHistory();
  return (
    <>
      <Grid
        container
        onClick={(e) => {
          history.push("/app/inventoryscan");
        }}
      >
        <Grid item p={2} xs={6} borderRight={6} borderColor="#CCCCCC">
          {/*change value to actual data*/}
          <GridContent
            color={"#F7CD37"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"45px"}
            value={roundToTwoDecimalNumber(props.readyForShipmentCount)}
          />

          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            Ready for Shipment
          </Typography>
        </Grid>

        <Grid item p={2} xs={6}>
          <GridContent
            color={"#F44336"}
            fontWeight={800}
            fontSize={"40px"}
            lineHeight={"45px"}
            value={roundToTwoDecimalNumber(props.backOrderCount)}
          />
          <Typography
            sx={{
              color: colorA6A0A0,

              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "center",
              paddingTop: 2,
            }}
          >
            Back-Order
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};