import React, { useState } from "react";
import BarcodeReader from "react-barcode-reader";
import { FormControlLabel, TextField, Select, InputLabel,
  FormControl, Radio, RadioGroup, Button, Grid } from "@material-ui/core";
import ReplayIcon from '@material-ui/icons/Replay';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { getSides, getProductTypes, getProductTypesInventory, /* getProductTypesSurgicalEvent, */
  getWasteTypes } from "../../models/Product";
import { getOwnerships } from "../../models/Ownership";
import { SurgicalEventModel } from "../../models/SurgicalEvent";
import { checkComplianceStatusExpiration, checkComplianceStatusSide } from "../../models/SurgicalEventCompliance";
import { getModelMenu } from "../../models/handles";
import { useDamlState } from "../../context/DamlContext";
import { useUserState } from "../../context/UserContext";
// import { onChangeNumber } from "../../components/Modals/handles";
import ErrorDialog from "../../components/Modals/GeneralModal/error-dialog";
import reloadIcon  from "../../icons/reload.svg";
import loadingIcon  from "../../icons/loading.svg";
import checkoutIcon  from "../../icons/white-checkout.svg";
import { handleBarcode, handleScanSuccess, handleRetrieve } from "./edit-product1";
import useStyles from "./styles";


/**
 * Edit Product
 * @param {Object} productItems 
 * @param {Function} setProductItems 
 * @param {Object} productError 
 * @param {Object} surgicalEventData
 * @param {String} retrieveStatus 
 * @param {Function} setRetrieveStatus 
 * @param {Function} handleValueFill 
 * @param {Object} options 
 * @param {Object} sameUdi
 * @param {String} hospital 
 * @returns 
 */
export default function EditProduct ({
  productItems, setProductItems, productError={},
  surgicalEventData={...SurgicalEventModel},
  retrieveStatus, setRetrieveStatus=()=>{}, handleValueFill=(key,type)=>{},
  options={}, sameUdi, hospital, 
}) {

  options = {
    isEditable: true,
    isEnableScan: true,
    isEnableAutoScan: true,       // Auto scan(retrieve), old value is false
    isEnableRetrieve: true,
    isEnableCompliance: true,
    isValidateOwnership: false,
    isInventory: false,
    isSurgicalEvent: false,
    hiddenParams: [],   // e.g. 'sepproductprice'
    enableParams: [],   // e.g. 'sepproductesprice'
    disableParams: [],  // e.g. 'sepreferencenumber'
    extraParams: [],    // e.g. {label: '', value: '', setValue: () => {}, placeholder: '', type: 'number', key: ''}
    isDistributorRelation: true,  // Distributors use Relation of Hospital & Vendor
    isManufacturerRelation: true, // Manufacturers use Relation of Hospital & Vendor
    ...options,
  };
  // console.log("[EditProduct] options", options, "productItems", productItems);
  
  const classes = useStyles();
  const { role, party, relVendors: vendors } = useDamlState();
  const { token } = useUserState();

  const [openExpirationErrorModal, setOpenExpirationErrorModal] = useState(true);
  const [openSideErrorModal, setOpenSideErrorModal] = useState(true);


  // const handleBarcode = async (barcode, onlyBarcode=false) 
  // const handleScanSuccess = async (data)
  
  // Barcode Scan Error handler
  const handleScanError = (err) => {
    console.log("[EditProduct] Barcode Error", err);
  };


  return (
    <>
    
        {
          options.isEnableScan && (options.hiddenParams.indexOf('barcode') === -1) && 
          <div className={classes.marginBM30}>

            <BarcodeReader
              onError={handleScanError}
              onScan={(data) => handleScanSuccess({
                data, options, productItems, setProductItems, setRetrieveStatus, 
                retrieveStatus, hospital, token, role, party, vendors, 
              })}
            />

            <TextField
              id="input-with-icon-adornment0"
              label="Scanned Barcode"
              value={productItems.barcode || ""}
              className={classes.customFullWidth}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              onChange={(e) => {
                if (options.disableParams.indexOf('barcode') === -1)
                  setProductItems({
                    ...productItems,
                    barcode: e.target.value,
                  });
              }}
              InputProps={{
                endAdornment: 
                <ReplayIcon 
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    if (options.disableParams.indexOf('barcode') === -1)
                      handleBarcode({
                        barcode: productItems.barcode, options, productItems, setProductItems, setRetrieveStatus, 
                        retrieveStatus, hospital, token, role, party, vendors, 
                      });
                  }}
                />
              }}
              variant="outlined"
              disabled={(options.disableParams.indexOf('barcode') !== -1)}
            />  
          </div>
          }
          {
            sameUdi === 0 ?
            <div className={classes.marginBM30}>
            <p className={classes.redColor}>
                 The UDI Scanned does not match what is scanned for shipping.
                    </p> </div>
                    : ""
        }
        {
          (options.hiddenParams.indexOf('sepudi') === -1) && 
          <div className={classes.marginBM30}>
            <Grid 
              container 
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              spacing={3}
            >
              <Grid item xs>
                <TextField
                  id="input-with-icon-adornment1"
                  label="UDI"
                  value={productItems.sepudi || ""}
                  error={!!productError.sepudi}
                  helperText={productError.sepudi}
                  placeholder=""
                  className={classes.customFullWidth}
                  margin="normal"
                  InputLabelProps={{
                      shrink: true,
                  }}
                  onChange={(e) => {
                    const udi = e.target.value;
                    if (!!udi && (retrieveStatus !== 'enable')) setRetrieveStatus('enable');
                    else if (!udi && (retrieveStatus !== 'disable')) setRetrieveStatus('disable');
                    if (options.disableParams.indexOf('sepudi') === -1) 
                      setProductItems({
                        ...productItems,
                        sepudi: udi,
                      });
                  }}
                  variant="outlined"
                  disabled={(options.disableParams.indexOf('sepudi') !== -1)}
                />
              </Grid>
              {
                options.isEnableRetrieve && 
                <Grid item className={classes.retrieveWrapper}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={`btn-retrieve-${retrieveStatus}`}
                    onClick={() => {
                      if ((retrieveStatus === 'enable')) handleRetrieve({ 
                        udi: productItems.sepudi, 
                        options, retrieveStatus, setRetrieveStatus, hospital, productItems, setProductItems, 
                        token, role, party, vendors, 
                      });
                    }} 
                    startIcon={((retrieveStatus === 'disable') ? <img src={reloadIcon} alt=""/> : (
                      (retrieveStatus === 'loading') ? <img src={loadingIcon} alt=""/> : (
                        (retrieveStatus === 'finished') ? <img src={checkoutIcon} alt=""/> : <img src={reloadIcon} alt=""/> 
                      )
                    ))}
                  >
                    {((retrieveStatus === 'disable') ? "Retrieve" : (
                      (retrieveStatus === 'loading') ? "Retrieving" : (
                        (retrieveStatus === 'finished') ? "Retrieved" : "Retrieve" 
                      )
                    ))}
                  </Button>
                </Grid>
              }
            </Grid>
          </div>
        }
        {
          (options.hiddenParams.indexOf('sepreferencenumber') === -1) && 
          <div className={classes.marginBM30}>
          <Grid 
            container 
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item xs>
              <TextField
                id="input-with-icon-adornmentReferenceNumbe"
                label="Reference Number"
                value={productItems.sepreferencenumber || ""}
                error={!!productError.sepreferencenumber}
                helperText={productError.sepreferencenumber}
                placeholder=""
                className={classes.customFullWidth}
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(e) => {
                  const sepnumbr = e.target.value;
                  if (!!sepnumbr && (retrieveStatus !== 'enable')) setRetrieveStatus('enable');
                  else if (!sepnumbr && (retrieveStatus !== 'disable')) setRetrieveStatus('disable');
                  if (options.disableParams.indexOf('sepreferencenumber') === -1) 
                    setProductItems({
                      ...productItems,
                      sepreferencenumber: sepnumbr,
                    });
                }}
                variant="outlined"
                disabled={(options.disableParams.indexOf('sepreferencenumber') !== -1)}
              />
            </Grid>
            {
              options.isEnableRetrieve && 
              <Grid item className={classes.retrieveWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  className={`btn-retrieve-${retrieveStatus}`}
                  onClick={() => {
                    if ((retrieveStatus === 'enable')) handleRetrieve({ 
                      ref: productItems.sepreferencenumber, 
                      options, retrieveStatus, setRetrieveStatus, hospital, productItems, setProductItems, 
                      token, role, party, vendors, 
                    });
                  }} 
                  startIcon={((retrieveStatus === 'disable') ? <img src={reloadIcon} alt=""/> : (
                    (retrieveStatus === 'loading') ? <img src={loadingIcon} alt=""/> : (
                      (retrieveStatus === 'finished') ? <img src={checkoutIcon} alt=""/> : <img src={reloadIcon} alt=""/> 
                    )
                  ))}
                >
                  {((retrieveStatus === 'disable') ? "Retrieve" : (
                    (retrieveStatus === 'loading') ? "Retrieving" : (
                      (retrieveStatus === 'finished') ? "Retrieved" : "Retrieve" 
                    )
                  ))}
                </Button>
              </Grid>
            }
          </Grid>
          </div>
        }
        {
          (options.hiddenParams.indexOf('seplotcode') === -1) && 
          <div className={classes.marginBM30}>
            <TextField
              id="input-with-icon-adornment7"
              label="Lot Code"
              placeholder="e.g. 123456"
              className={classes.customFullWidth}
              margin="normal"
              value={productItems.seplotcode || ""}
              error={!!productError.seplotcode}
              helperText={productError.seplotcode} 
              InputLabelProps={{
                  shrink: true,
              }}
              onChange={(e) => {
                if (options.disableParams.indexOf('seplotcode') === -1) 
                  setProductItems({
                    ...productItems,
                    seplotcode: e.target.value,
                  });
              }} 
              variant="outlined"
              disabled={(options.disableParams.indexOf('seplotcode') !== -1)}
            />
          </div>
        }
        {
          (options.hiddenParams.indexOf('sepproductmanufacturer') === -1) && 
          <div className={classes.marginBM30}>
            <InputDistributor 
              isDistributorRelation={options.isManufacturerRelation}
              distributor={productItems.sepproductmanufacturer}
              error={productError.sepproductmanufacturer}
              setDistributor={(value) => {
                if (options.disableParams.indexOf('sepproductmanufacturer') === -1) 
                  setProductItems({
                    ...productItems,
                    sepproductmanufacturer: value,
                  });
                handleValueFill('sepproductmanufacturer','PO')
              }}
              label="Product Manufacturer"
              placeholder="e.g. Manufacturer X"
              isDisable={(options.disableParams.indexOf('sepproductmanufacturer') !== -1)}
            />
          </div>
        }
        {
          (options.hiddenParams.indexOf('sepdistributor') === -1) && 
          <div className={classes.marginBM30}>
            <InputDistributor 
              isDistributorRelation={options.isDistributorRelation}
              distributor={productItems.sepdistributor}
              error={productError.sepdistributor}
              setDistributor={(value) => {
                if (options.disableParams.indexOf('sepdistributor') === -1) 
                  setProductItems({
                    ...productItems,
                    sepdistributor: value,
                  });
                handleValueFill('sepdistributor','PO')
              }}
              isDisable={(options.disableParams.indexOf('sepdistributor') !== -1)}
            />
          </div>
        }
        {
          (options.hiddenParams.indexOf('sepproductname') === -1) && 
          <div className={classes.marginBM30}>
            <TextField
              id="input-with-icon-adornment4"
              value={productItems.sepproductname || ""}
              error={!!productError.sepproductname}
              helperText={productError.sepproductname}
              label="Product Name"
              placeholder="e.g. Biolox delta taper liner"
              className={classes.customFullWidth}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              onChange={(e) => {
                if (options.disableParams.indexOf('sepproductname') === -1) 
                  setProductItems({
                    ...productItems,
                    sepproductname: e.target.value,
                  });
              }}
              variant="outlined"
              disabled={(options.disableParams.indexOf('sepproductname') !== -1)}
            /> 
          </div>
        }
        {
          (options.hiddenParams.indexOf('sepproductdescription') === -1) && 
          <div className={classes.marginBM30}>
            <TextField
              id="input-with-icon-adornment5"
              label="Product Description"
              placeholder="e.g. Biolox delta ceramic femoral head"
              error={!!productError.sepproductdescription}
              helperText={productError.sepproductdescription}
              className={classes.customFullWidth}
              margin="normal"
              value={productItems.sepproductdescription || ""}
              InputLabelProps={{
                  shrink: true,
              }}
              onChange={(e) => {
                if (options.disableParams.indexOf('sepproductdescription') === -1) 
                  setProductItems({
                    ...productItems,
                    sepproductdescription: e.target.value,
                  });
              }}
              variant="outlined"
              disabled={(options.disableParams.indexOf('sepproductdescription') !== -1)}
            /> 
          </div>
        }
        {
          (options.hiddenParams.indexOf('sepproductexpiration') === -1) && 
          <div className={classes.marginBM30}>
            <FormControl variant="outlined" className={classes.formControl}>     
              <TextField
                id="eventdate"
                label="Expiration"
                type="date"
                className={classes.datehalfWidth}
                value={productItems.sepproductexpiration || ""}
                error={!!productError.sepproductexpiration}
                helperText={productError.sepproductexpiration}
                InputLabelProps={{
                shrink: true,
                }}
                onChange={(e) => {
                  // console.log("[EditProduct] sepproductexpiration", e.target.value);
                  if (options.disableParams.indexOf('sepproductexpiration') === -1) {
                    setProductItems({
                      ...productItems,
                      sepproductexpiration: e.target.value,
                    });
                    setOpenExpirationErrorModal(true);
                  }
                  handleValueFill('sepproductexpiration','PO');
                }} 
                variant="outlined"  
                disabled={(options.disableParams.indexOf('sepproductexpiration') !== -1)}
              />
            </FormControl> 
          </div>
        }
        {
          checkComplianceStatusExpiration(surgicalEventData, productItems,
            (options.isEnableCompliance && (options.hiddenParams.indexOf('sepproductexpiration') === -1))) &&
            <>
              <FormControl variant="outlined" className="msg-alert">   
                <div className={classes.customCheckbox}>
                  <p className={classes.redColor}>
                  The item has an EXPIRATION DATE before the Surgical Event date. 
                  If you want to continue with Expired Product, 
                  please Click “I Acknowledge”, If you don't want to proceed, please Click “I Decline”.
                  </p>
                  <RadioGroup row aria-label="position" name="position" defaultValue="top">
                      <FormControlLabel value="Acknowledged" 
                        control={<Radio color="primary" checked={productItems.acknowledge === 'Acknowledged'} />} 
                        label="I Acknowledge" className={classes.customRadio} 
                        onChange={(e) => {
                          setProductItems({
                            ...productItems,
                            acknowledge: e.target.value,
                          });
                        }} 
                      />
                      <FormControlLabel value="Declined" 
                        control={<Radio color="primary" checked={productItems.acknowledge === 'Declined'} />} 
                        className={classes.customRadio} label="I Decline"
                        onChange={(e) => {
                          setProductItems({
                            ...productItems,
                            acknowledge: e.target.value,
                          });
                        }} 
                      />
                  </RadioGroup>
                </div>
                {
                  checkComplianceStatusExpiration(surgicalEventData, productItems,
                    (options.isEnableCompliance && (options.hiddenParams.indexOf('sepproductexpiration') === -1)))
                      && !productItems.acknowledge && 
                    <p className={classes.alertRed}>Please select 1 option</p> 
                }
              </FormControl>
              <ErrorDialog
                openModal={openExpirationErrorModal} 
                setOpenModal={setOpenExpirationErrorModal} 
                isLogout={false}
                text={`<p style="color: red;">The item has an EXPIRATION DATE before the<br/> Surgical Event date.</p>`}
                firstBtn='OK'
                isRedIcon={true}
              />
            </>
        }
        {
          (options.hiddenParams.indexOf('sepproductprice') === -1) && 
          <div className={classes.marginBM30}>
            <TextField
              id="input-with-icon-adornment6"
              label="Price"
              placeholder="e.g.1000"
              error={!!productError.sepproductprice}
              helperText={productError.sepproductprice}
              className={classes.customFullWidth}
              margin="normal"
              value={productItems.sepproductprice || ""}
              InputLabelProps={{
                  shrink: true,
              }}
              onChange={(e) => {
                // if (onChangeNumber(e)) setProductItems({ ...productItems, sepproductprice: onChangeNumber(e) });
                if (options.disableParams.indexOf('sepproductprice') === -1) 
                  setProductItems({ ...productItems, sepproductprice: e.target.value });
                handleValueFill('sepproductprice','PO')
              }} 
              variant="outlined"
              type="number"
              disabled={(options.disableParams.indexOf('sepproductprice') !== -1)}
            /> 
          </div>
        }
        {
          (options.enableParams.indexOf('sepproductesprice') !== -1) && 
          <div className={classes.marginBM30}>
            <TextField
              id="input-with-icon-adornment66"
              label="Price"
              placeholder="e.g.1000"
              error={!!productError.sepproductesprice}
              helperText={productError.sepproductesprice}
              className={classes.customFullWidth}
              margin="normal"
              value={productItems.sepproductesprice || ""}
              InputLabelProps={{
                  shrink: true,
              }}
              onChange={(e) => {
                // if (onChangeNumber(e)) setProductItems({ ...productItems, sepproductesprice: onChangeNumber(e) });
                if (options.disableParams.indexOf('sepproductesprice') === -1) 
                  setProductItems({ ...productItems, sepproductesprice: e.target.value });
              }} 
              variant="outlined"
              type="number"
              disabled={(options.disableParams.indexOf('sepproductesprice') !== -1)}
            /> 
          </div>
        }
        {
          (options.hiddenParams.indexOf('sepproducttype') === -1) && 
          <div className={classes.marginBM30}>
            <FormControl variant="outlined" className={classes.formControl}
              disabled={(options.disableParams.indexOf('sepproducttype') !== -1)}
            >
              <InputLabel className={classes.customLabel} shrink={true}>
                Product Type
              </InputLabel>
              <Select
                // native
                displayEmpty
                className={classes.customFullWidth}
                value={productItems.sepproducttype || ""}
                error={!!productError.sepproducttype}
                onChange={(e) => {
                  if (options.disableParams.indexOf('sepproducttype') === -1) 
                    setProductItems({
                      ...productItems,
                      sepproducttype: e.target.value,
                    });
                  handleValueFill('sepproducttype','PO')
                }} 
              >
                {
                  options.isInventory ? 
                    getProductTypesInventory("Select One")
                  : 
                    // (
                    //   options.isSurgicalEvent ? 
                    //     getProductTypesSurgicalEvent("Select One")
                    //   : 
                        getProductTypes("Select One")
                    // )
                }
              </Select>
            </FormControl> 
          </div>
        }
        {
          (options.hiddenParams.indexOf('sepproductwaste') === -1) && 
          <div className={classes.marginBM30}>
            <FormControl variant="outlined" className={classes.formControl}
              disabled={(options.disableParams.indexOf('sepproductwaste') !== -1)}
            >
              <InputLabel className={classes.customLabel} shrink={true}>
                Waste
              </InputLabel>
              <Select
                // native
                displayEmpty
                className={classes.customFullWidth}
                value={productItems.sepproductwaste || ""}
                error={!!productError.sepproductwaste}
                onChange={(e) => {
                  if (options.disableParams.indexOf('sepproductwaste') === -1) 
                    setProductItems({
                      ...productItems,
                      sepproductwaste: e.target.value,
                    });
                  handleValueFill('sepproductwaste','PO')
                }} 
              >
                {
                  getWasteTypes("Select One")
                }
              </Select>
              {productItems.sepproductwaste !== "" && 
                <RotateLeftIcon 
                  className={classes.iconReset}
                  onClick={() => {
                    if (options.disableParams.indexOf('sepproductwaste') === -1) 
                      setProductItems({
                        ...productItems,
                        sepproductwaste:'',
                      });
                    handleValueFill('sepproductwaste','PO')
                  }}
                />
              }
            </FormControl> 
          </div>
        }
        {
          (options.hiddenParams.indexOf('sepownership') === -1) && 
          <div className={classes.marginBM30}>
            <FormControl variant="outlined" className={classes.formControl}
              disabled={(options.disableParams.indexOf('sepownership') !== -1)}
            >
              <InputLabel className={classes.customLabel} shrink={true}>
                Ownership
              </InputLabel>
              <Select
                // native
                displayEmpty
                className={classes.customFullWidth}
                value={productItems.sepownership || ""}
                error={!!productError.sepownership}
                onChange={(e) => {
                  if (options.disableParams.indexOf('sepownership') === -1) 
                    setProductItems({
                      ...productItems,
                      sepownership: e.target.value,
                    });
                  handleValueFill('sepownership','PO') 
                }} 
              >
                {
                  options.isInventory ? 
                    getOwnerships("Select One", ['Unknown', 'BillOnly']) 
                  : (
                    options.isSurgicalEvent ? 
                      getOwnerships("Select One", ['BillOnly']) 
                    : (
                      options.isValidateOwnership ? 
                        getOwnerships("Select One", ['Unknown']) 
                      : 
                        getOwnerships("Select One")
                      )
                    )
                }
              </Select>
            </FormControl>   
          </div>
        }
        {
          (options.hiddenParams.indexOf('sepproductside') === -1) && 
          <div className={classes.marginBM30}>
            <FormControl variant="outlined" className={classes.formControl}
              disabled={(options.disableParams.indexOf('sepproductside') !== -1)}
            >
              <InputLabel className={classes.customLabel} shrink={true}>
                Product Side
              </InputLabel>
              <Select
                // native
                displayEmpty
                className={classes.customFullWidth}
                value={productItems.sepproductside || ""}
                error={!!productError.sepproductside}
                onChange={(e) => {
                  if (options.disableParams.indexOf('sepproductside') === -1) {
                    setProductItems({
                      ...productItems,
                      sepproductside: e.target.value,
                    });
                    setOpenSideErrorModal(true);
                  }
                }} 
              >
                {getSides("Select One")}
              </Select>
            </FormControl>   
          </div>
        }
        {
          checkComplianceStatusSide(surgicalEventData, productItems,
            (options.isEnableCompliance && (options.hiddenParams.indexOf('sepproductside') === -1))) &&
            <>  
              <FormControl variant="outlined" className="msg-alert">   
                <div className={classes.customCheckbox}>
                  <p className={classes.redColor}>
                    This item's laterality does not match the patient's indicated laterality of 
                    "{surgicalEventData.surgicalside}". 
                    If you want to continue  with "{productItems.sepproductside}" laterality, 
                    please Click “I Acknowledge”, 
                    If you don't want to proceed, please Click “I Decline”.
                  </p>
                  <RadioGroup row aria-label="ProcedureSideRadio" name="ProcedureSideRadio" defaultValue="top">
                      <FormControlLabel value="Acknowledged" 
                        control={<Radio color="primary" checked={productItems.acknowledgeProduct === 'Acknowledged'} />} 
                        label="I Acknowledge" className={classes.customRadio} 
                        onChange={(e) => {
                          setProductItems({
                            ...productItems,
                            acknowledgeProduct: e.target.value,
                          });
                        }} 
                      />
                      <FormControlLabel value="Declined" 
                        control={<Radio color="primary" checked={productItems.acknowledgeProduct === 'Declined'} />} 
                        className={classes.customRadio} label="I Decline" 
                        onChange={(e) => {
                          setProductItems({
                            ...productItems,
                            acknowledgeProduct: e.target.value,
                          });
                        }} 
                      />
                  </RadioGroup>
                </div>
                {
                  checkComplianceStatusSide(surgicalEventData, productItems,
                    (options.isEnableCompliance && (options.hiddenParams.indexOf('sepproductside') === -1))) 
                      && !productItems.acknowledgeProduct && 
                    <p className={classes.alertRed}>Please select 1 option</p> 
                }
              </FormControl>
              <ErrorDialog
                openModal={openSideErrorModal} 
                setOpenModal={setOpenSideErrorModal} 
                isLogout={false}
                text={`<p style="color: red;">The item’s Laterality does not match the patient’s<br/> indicated laterality.</p>`}
                firstBtn='OK'
                isRedIcon={true}
              />
            </>
        }
        {
          options.extraParams.map((param, index) => (
            <div className={classes.marginBM30} key={index}>
              <TextField
                label={param.label || ""}
                placeholder={param.placeholder || ""}
                className={classes.customFullWidth}
                margin="normal"
                value={(param.value ? param.value : 
                  ((param.key && param.key in productItems) ? productItems[param.key] : ""))}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(e) => {
                  if (typeof param.setValue === 'function') param.setValue(e.target.value);
                  else if (param.key) {
                    setProductItems({
                      ...productItems,
                      [param.key]: e.target.value,
                    });
                  }
                }} 
                variant="outlined"
                type={param.type || "text"}
              /> 
            </div>
          ))
        }
        
    </>
  );
}

/**
 * Input Distributor Component
 * It uses DamlStream
 * @param {Boolean} isDistributorRelation 
 * @param {String} distributor 
 * @param {Function} setDistributor 
 * @param {String} error 
 * @param {String} label 
 * @param {String} placeholder 
 * @param {Boolean} isDisable 
 * @returns 
 */
export function InputDistributor ({
  isDistributorRelation=true, distributor="", setDistributor=()=>{}, error="", 
  label="Product Distributor", placeholder="e.g. Distributor X", isDisable=false,
}) {
  
  const classes = useStyles();

  const { relVendors: distributors } = useDamlState();

  // console.log("[InputDistributor] distributors", distributors);


  const getDistributors = getModelMenu(distributors.reduce((a, v) => ({ ...a, [v]: v}), {}) );


  return (
    <>

      {
        isDistributorRelation
        ?
        <>
          <FormControl variant="outlined" className={classes.formControl}
            disabled={isDisable}
          >
            <InputLabel className={classes.customLabel} shrink={true}>
              {label}
            </InputLabel>
            <Select
              // native
              displayEmpty
              className={classes.customFullWidth}
              value={distributor || ""}
              error={!!error}
              onChange={(e) => setDistributor(e.target.value)} 
            >
              {
                getDistributors("Select One")
              }
            </Select>
          </FormControl> 
        </>
        :
        <TextField
          label={label}
          placeholder={placeholder}
          value={distributor || ""}
          error={!!error}
          helperText={error}
          className={classes.customFullWidth}
          margin="normal"
          InputLabelProps={{
              shrink: true,
          }}
          onChange={(e) => setDistributor(e.target.value)}
          variant="outlined"
          disabled={isDisable}
        /> 
      }

    </>
  );
}
