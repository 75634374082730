import React from "react";
import "./Import_file.css";
import FileUploadDropzone from "../../components/FileUpload/FileUploadDropzone";


/**
 * Drag & Drop to Upload File
 * @param {Function} setFilePathData 
 * @param {String} title 
 * @param {Boolean} addtionalBtn 
 * @param {String} subtitle 
 * @returns 
 */
export default function ImportFile({
    setFilePathData,
    title='Drag & Drop files to Upload',
    addtionalBtn=true,
    subtitle='You can upload only valid csv here.',
}) {
    return (
        <div className="App">
            <FileUploadDropzone 
                title={title}
                subtitle={subtitle}
                setFilePathData={setFilePathData}
                addtionalBtn={addtionalBtn}
            />
        </div>
    );
}

