import { makeStyles } from "@material-ui/styles";
// import { WrapText } from "@material-ui/icons";

export const useStyles = makeStyles(theme => ({
  tableCell: {
    verticalAlign: 'top',
    paddingTop: 6,
    paddingBottom: 6,
    // fontSize: ((theme.version && theme.version.new) ? '16px' : "0.75rem"),
    fontSize: '16px',
    maxWidth: 30,
    whiteSpace: 'unset',
    wordWrap: 'break-all',
  },
  tableCellCenter: {
    textAlign: 'center',
  },
  tableCellFullWidth: {
    maxWidth: 'inherit',
    minWidth: 120,
    whiteSpace: 'nowrap',
    // border: '1px solid #e0e0e0',
  },
  tableCellFixed: {
    position: 'sticky',
    left: 0,
    top: 0,
    zIndex: 2,
    backgroundColor: '#fafafa',
  },
  headerCell: {
    padding: '0 8px !important',
    '& .MuiDataGrid-colCellTitleContainer': {
      justifyContent: 'normal !important',
    },
  },
  actionCellWrap: {
    justifyContent: 'center !important',
  },
  centerCell: {
    justifyContent: 'center !important',
    '& .MuiDataGrid-columnHeaderDraggableContainer > .MuiDataGrid-columnHeaderTitleContainer': {
      justifyContent: 'center !important',
    },
  },
  actionCell: {
    color: '#111111',
  },
  actionCellDisabled: {
    color: '#828282',
  },
  newActionCell: {
    width: '38px',
    height: '38px',
    '&:hover, &:active': {
      background: '#FFFFFF',
      border: '1px solid #CCCCCC',
      boxSizing: 'border-box',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  },
  newActionCellDM: {
    width: '38px',
    height: '38px',
    '&:hover, &:active': {
      background: '#1c3c74',
      boxSizing: 'border-box',
    },
  },
  textField: {
    fontSize: "0.75rem"
  },
  grid: {
    overflowX: 'auto',
    marginTop: 10,
  },
  datagrid: {
    backgroundColor: '#fff',
    // borderWidth: ((theme.version && theme.version.new) ? 0: 1), // ! New Design
    borderWidth: 0,
    '&.MuiDataGrid-root': {
      borderWidth: 0,
    },
    '& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer': {
      padding: 0
    },
    '& .MuiTablePagination-caption': {
      '@media (max-width:1024px)': {
        display: 'none'
      },
    },
    '& .MuiDataGrid-columnHeaders': {
      borderTop: '1px solid rgba(224, 224, 224, 1)',
      '& .MuiDataGrid-columnHeader': {
        fontWeight: 500,
        '&:not(.MuiDataGrid-columnHeaderCheckbox)': {
          borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 500,
      },
      '& .MuiDataGrid-columnHeaderWrapper': {
        // borderTop: ((theme.version && theme.version.new) ? '1px solid rgba(224, 224, 224, 1)': 'none'), // ! New Design
        borderTop: '1px solid rgba(224, 224, 224, 1)',
      },
    },
    // '& .MuiDataGrid-virtualScroller': {
    //   height: 'calc(100vh - 350px) !important',
    //   overflowY: 'scroll',
    // },
    '& .MuiDataGrid-footerContainer': {
      // justifyContent: ((theme.version && theme.version.new) ? 'end': 'space-between'), // ! New Design
      justifyContent: 'end',
    },
    // '& .MuiChip-root.MuiChip-sizeSmall': ((theme.version && theme.version.new) ? {
    '& .MuiChip-root.MuiChip-sizeSmall': {
      minWidth: '95px',
      height: '20px',
      textAlign: 'center',
      '& .MuiChip-label': {
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '20px',
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
    // } : {}),
    '& .MuiDataGrid-row.custom-clicked': {
      backgroundColor: '#CCCCCC61',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      // outline: ((theme.version && theme.version.new) ? 'none': 'inherit'), // ! New Design
      outline: 'none',
    },
    '& svg.MuiSvgIcon-root.MuiDataGrid-iconSeparator': {
      opacity: 0,
    },
  },
  datagridDM: {
    backgroundColor: '#1c3c74',
    // borderWidth: ((theme.version && theme.version.new) ? 0: 1), // ! New Design
    borderWidth: 0,
    '&.MuiDataGrid-root': {
      borderWidth: 0,
    },
    "& .MuiButtonBase-root":{
      color:"#FFFFFF",
    },
    "& .MuiDataGrid-row":{
      color:"#ffffff"
    },
    '& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer': {
      padding: 0
    },
    '& .MuiTablePagination-caption': {
      '@media (max-width:1024px)': {
        display: 'none'
      },
    },
    '& .MuiDataGrid-columnHeaders': {
      borderTop: '1px solid rgba(224, 224, 224, 1)',
      color:"#F7CD37",
      '& .MuiDataGrid-columnHeader': {
        fontWeight: 500,
        '&:not(.MuiDataGrid-columnHeaderCheckbox)': {
          borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 500,
      },
      '& .MuiDataGrid-columnHeaderWrapper': {
        // borderTop: ((theme.version && theme.version.new) ? '1px solid rgba(224, 224, 224, 1)': 'none'), // ! New Design
        borderTop: '1px solid rgba(224, 224, 224, 1)',
      },
    },
    // '& .MuiDataGrid-virtualScroller': {
    //   height: 'calc(100vh - 350px) !important',
    //   overflowY: 'scroll',
    // },
    '& .MuiDataGrid-footerContainer': {
      // justifyContent: ((theme.version && theme.version.new) ? 'end': 'space-between'), // ! New Design
      justifyContent: 'end',
    },
    // '& .MuiChip-root.MuiChip-sizeSmall': ((theme.version && theme.version.new) ? {
    '& .MuiChip-root.MuiChip-sizeSmall': {
      minWidth: '95px',
      height: '20px',
      textAlign: 'center',
      '& .MuiChip-label': {
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '20px',
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
    // } : {}),
    '& .MuiDataGrid-row.custom-clicked': {
      backgroundColor: '#CCCCCC61',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      // outline: ((theme.version && theme.version.new) ? 'none': 'inherit'), // ! New Design
      outline: 'none',
    },
    '& svg.MuiSvgIcon-root.MuiDataGrid-iconSeparator': {
      opacity: 0,
    },
  },
  searchIcon: {
    width: 20,
    height: 20,
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  dateSearchIcon: {
    width: 48,
    height: 48,
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 6
  },
  calendarIcon: {
    fontSize: '1.1rem'
  },
  popoverBox: {
    // minHeight: 300,
    '& .MuiTab-textColorPrimary': {
      color: '#666666',
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: '#000000',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#000000',
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
    '&  .MuiTab-textColorPrimary': {
      textTransform: 'none'
    }
  },
  newPopoverBox: {
    width: 221,
    height: 130,
    backgroundColor: '#5C5C5C',
    border: '1px solid #CCCCCC',
    boxSizing: 'border-box',
    borderRadius: 4,
  },
  closeIcon: {
    position: 'absolute',
    width: 11,
    height: 11,
    right: 3,
    top: 3,
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  },
  clearLink: {
    position: 'absolute',
    left: 5,
    bottom: 5,
    color: '#2196F3',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
  },
  applyLink: {
    left: 'inherit',
    right: 5,
  },
  innerPopper: {
    position: 'absolute',
    left: 15,
    top: 16,
    width: 190,
    height: 84,
    overflow: 'auto',
    '& .one-column': {
      marginLeft: '2px',
    }
  },
  noPadding: {
    '& .MuiGrid-root': {
      padding: '0 !important',
    },
    '& svg.MuiSvgIcon-root.MuiDataGrid-iconSeparator': {
      opacity: 0,
    }

  },
  gridView: {
    '& svg.MuiSvgIcon-root.MuiDataGrid-iconSeparator': {
      opacity: 0,
    },
  },
  customTree: {
    marginTop: '12px',
    '&  table table table .MuiTableCell-head': {
      padding: '16px;'
    },
    '& .inner-tree-child': {
      background: '#F4F6F8',
      margin: '20px',
      boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)'
    },
    '& .toggle-div': {
      transition: 'max-height 0.8s;'
    },
    '&  .MuiDataGrid-overlay': {
      display: 'none'
    },
    '& .MuiDataGrid-colCell ': {
      minWidth: '14% !important'
    },
    '&  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12': {
      padding: '16px 16px 0 16px'
    },
    '& .MuiDataGrid-columnsContainer': {
      border: '0px !important'
    },
    '& .toggleTree': {
      cursor: 'pointer'
    },
    '& .child-tree-grid': {
      padding: '20px',
    },
    '& .MuiChip-root.Complete.MuiChip-sizeSmall': {
      background: 'rgba(76, 175, 80, 0.38)'
    },
    '& .MuiChip-root.Scheduled.MuiChip-sizeSmall': {
      background: 'rgba(255, 152, 0, 0.38)'
    },
    '&  .MuiChip-root.Art.MuiChip-sizeSmall': {
      background: 'rgba(255, 152, 0, 0.38)'
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      height: '10px',
      padding: '0px 32px 0px 0px',
      fontSize: '.85em'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px'
    },
    '& .MuiButton-containedPrimary': {
      backgroundColor: '#183C73',
      color: '#fff',
      borderRadius: '4px'
    },
    '& .customSearch': {
      opacity: 1,
      transform: 'none',
      transition: 'opacity 226ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 151ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      top: '0px',
      left: '0px',
      transformOrigin: '170px 39px',
      boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)'
    },
    '& .MuiBox-root-168': {
      margin: '8px 0px;'
    },
    '& .tree-heading': {
      display: 'flow-root',
    },
    '& .MuiTableCell-head': {
      padding: '4px 16px;',
      height: 55,
      fontWeight: 500,
    },
    '& .MuiTypography-colorPrimary': {
      // color: '#183c73;'
    },
    '& .makeStyles-backGreen-99': {
      border: 'solid 1px  #4CAF50;'
    },
    '& .makeStyles-backRed-100': {
      border: 'solid 1px  #F44336;'
    },
    '& .footerpagination': {
      padding: '0px;'
    },
    '& .MuiPickersDay-daySelected': {
      color: '#183c73;',
    },
    '& .MuiPickersDay-current': {
      color: '#183c73;',
    },
  },
  childInner: {
    '& .tablePaddingSm': {
      paddingTop: '0px',
      paddingBottom: '0px',
      paddingLeft: '8px',
      paddingRight: '8px',
      height: '50px',
    },
    '& .MuiChip-root': {
      margin: '0 16px 0 0'
    },

    '& .makeStyles-collapseIconWrap-160': {
      padding: '0px'
    }

  },
  collapseIconWrap: {
    padding: '4px',
    width: 81,
  },
  collapseIconWrapLg: {
    padding: '4px',
    width: 105,
  },
  tableRow: {
    height: "auto",
    paddingTop: '15px'
  },
  poArchive: {
    float: 'right',
  },
  poHeading: {
    display: 'inline',
    fontSize: '18px'
  }, customChildGrid: {
    '& .MuiDataGrid-colCell ': {
      minWidth: '12% !important'
    }
  },
  toggleSlide: {
    float: 'right',
    // paddingRight:'5px'
  },
  TablePagination: {
    background: '#ffffff',
    // paddingRight: ((theme.version && theme.version.new) ? 'inherit': '100px !important'), // ! New Design
    paddingRight: 'inherit',
    /* '& .MuiTablePagination-spacer + p':{
       display:'none'
     },
     '& .MuiTablePagination-selectRoot':{
       display:'none'
     }*/
  },
  pageBadge: {
    padding: 7,
    '& .MuiAvatar-root': {
      fontSize: '10px',
      lineHeight: '12px',
      fontWeight: 400,
      width: 17,
      height: 17,
      color: '#000000',
      backgroundColor: '#ffffff',
    },
  },
  pageBadgeSelected: {
    '& .MuiAvatar-root': {
      color: '#ffffff',
      backgroundColor: '#1C3C74',
    },
  },
  tooltip: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    // maxWidth: '200px',
    fontSize: 'inherit',
  },
  arrow: {
    color: theme.palette.common.black,
  },
  tooltipPopper: {
    '& .MuiTooltip-tooltip': {
      background: '#183C73',
      color: '#fff',
    },
    '& .MuiListItem-root:hover': {
      background: '#DEF3FB'
    },
    '& .MuiTooltip-tooltipPlacementBottom': {
      margin: 0
    },
  },
  tooltipArroww: {
    // border: '6px solid transparent',
    // borderTop: 0,
    // borderBottom: '6px solid #183C73',
    color: '#183C73',
    // content: '',
    // position: 'absolute',
    // display: 'block',
    // width: 0,
    // left: '50%',
    // top: 6,
    // transform: 'translate(-50%, calc(-100% - 5px))',
  },
  newTooltip: {
    background: '#5C5C5C',
    border: '1px solid #CCCCCC',
    boxSizing: 'border-box',
    borderRadius: '4px',
    padding: 0,
    marginTop: 0,
    '& .MuiButtonGroup-root': {
      paddingTop: '3px',
      paddingBottom: '2px',
      '& .MuiButton-root': {
        borderWidth: 0,
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '12px',
        paddingTop: '3px',
        paddingBottom: '3px',
        textTransform: 'capitalize',
        color: '#FFFFFF',
        /* '&:nth-child(odd)': {
          color: '#FFFFFF',
        },
        '&:nth-child(even)': {
          background: '#F7CD37',
          color: '#5C5C5C',
        }, */
        '&:hover': {
          background: '#F7CD37',
          color: '#5C5C5C',
        },
      },
    },
  },
  newAddedTooltip: {
    background: '#E1DDDD',
    border: '1px solid #CCCCCC',
    boxSizing: 'border-box',
    borderRadius: '4px',
    padding: 0,
    marginTop: 0,
    '& .MuiButtonGroup-root': {
      paddingTop: '3px',
      paddingBottom: '2px',
      '& .MuiButton-root': {
        borderWidth: 0,
        fontWeight: 500,
        fontSize: '15px',
        lineHeight: '18px',
        // fontWeight: 400,
        // fontSize: '10px',
        // lineHeight: '12px',
        paddingTop: '3px',
        paddingBottom: '3px',
        textTransform: 'capitalize',
        color: '#000000',
        /* '&:nth-child(odd)': {
          color: '#FFFFFF',
        },
        '&:nth-child(even)': {
          background: '#F7CD37',
          color: '#5C5C5C',
        }, */
        '&:hover': {
          background: '#5C5C5C',
          color: '#FFFFFF',
        },
      },
    },
  },
  newAddedTooltipDM: {
    background: '#1c3c74',
    color:"#FFFFFF",
    border: '1px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '4px',
    padding: 0,
    marginTop: 0,
    '& .MuiButtonGroup-root': {
      paddingTop: '3px',
      paddingBottom: '2px',
      '& .MuiButton-root': {
        borderWidth: 0,
        fontWeight: 500,
        fontSize: '15px',
        lineHeight: '18px',
        // fontWeight: 400,
        // fontSize: '10px',
        // lineHeight: '12px',
        paddingTop: '3px',
        paddingBottom: '3px',
        textTransform: 'capitalize',
        color: '#FFFFFF',
        /* '&:nth-child(odd)': {
          color: '#FFFFFF',
        },
        '&:nth-child(even)': {
          background: '#F7CD37',
          color: '#5C5C5C',
        }, */
        '&:hover': {
          background: '#F7CD37',
          color: '#FFFFFF',
        },
      },
    },
  },
  newTooltipArroww: {
    color: '#5C5C5C',
    borderColor: '#CCCCCC',
  },
  newTooltipArrowwDM: {
    color: '#FFFFFF',
    borderColor: '#CCCCCC',
  },
  noRecords: {
    '& img': {
      width: '100%'
    }
  },
  dataLoading: {
    position: 'absolute',
    zIndex: 99,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%)',
    '& .MuiCircularProgress-colorPrimary': {
      color: '#183C73'
    }
  },
  tableData: {
    position: 'relative',
    '& .MuiDataGrid-root[aria-rowcount="0"] + div': {
      display: 'none !important'
    },
    '& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer': {
      padding: 0
    }
  },
}));
