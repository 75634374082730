import React, { useEffect } from "react";
import * as jwt from 'jsonwebtoken';
import { dablLoginUrl, DefaultOperatorParty, damlPartyKey, damlRoleKey, ledgerApiIdentify, damlLastPath,
  damlFlagAuth, damlTokenDateKey, damlReloadTime, bearerTokenKey, damlTokenKey } from "../config";
import { cognitoLogIn, asyncChangePassword } from "./CognitoContext";
import { post, getToken } from "../services/axios/daml-json-api";
import { LOGIN_SUCCESS, LOGIN_FAILURE, SIGN_OUT_SUCCESS, SET_FHIR, CHANGE_LASTPATH, REFRESH_BEARER_TOKEN } from "../store/actions/constants";
import { dispatch as rootDispatch } from "../store";
import useAuthToken, { getUserFromAuthToken } from "../hooks/useAuthToken";
var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();


function userReducer(state, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, isAuthenticated: true, token: action.token, refreshToken: '', party: action.party, 
        role: action.role, tempRole: action.tempRole, userParty: action.userParty, errorMessage: "",
        isAuth0: !!action.isAuth0, operator: action.operator, last_password_reset: action.last_password_reset,
        organizations: action.organizations, organization: action.organization, current_organization: action.current_organization };
    case LOGIN_FAILURE:
      return { ...state, isAuthenticated: false, tempRole: null, userParty: null,
        errorMessage: typeof action.payload.error === "string" ? action.payload.error : "",
        isAuth0: false };
    case SIGN_OUT_SUCCESS:
      return { ...state, isAuthenticated: false, tempRole: null, userParty: null, errorMessage: "",
        isAuth0: false };
    case SET_FHIR:
      return { ...state, FHIR: action.payload };
    case CHANGE_LASTPATH:
      return { ...state, lastPath: action.payload };
    case REFRESH_BEARER_TOKEN:
    return { ...state, refreshToken: action.payload };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {

  const {
    token: authToken, 
    loading: authLoading, 
    partyName, 
    role: authRole, 
    // metadata, 
    operatorName,
    last_password_reset,
    party: authPaty,
    organizations,
    organization,
    current_organization
  } = useAuthToken();


  const party = localStorage.getItem(damlPartyKey);
  // set token as null, not use getToken
  // const token = getToken();
  const token = null;
  const role = localStorage.getItem(damlRoleKey);
  // const lastPath = localStorage.getItem(damlLastPath);


  var [state, dispatch] = React.useReducer(userReducer, {
    // isAuthenticated: !!token && !!role,
    isAuthenticated: !!role,
    token,
    party,              // organization or current_organization
    role,
    errorMessage: "",
    tempRole: null,
    FHIR: false,
    userParty: null,    // For UserList
    isAuth0: false,     // use Auth0 or not
    lastPath: null,     // last path before refresh
    operator: null,     // Operator from Auth0 token
    last_password_reset: null,     // Last Password Reset in Auth0
    organizations: [],  // Organizations to switch for hospital : organization ++ invited_organization
    organization: null, // Original organization : organization
    current_organization: null,
  });
  

  useEffect(() => {
    if (!authLoading) {
      const newToken = (authToken ? authToken : token);
      const newParty = authPaty || party;
      const newRole = (authRole ? authRole : role);

      console.log("[UserProvider]", newToken, newParty);

      if (newToken && newParty) {
        dispatch({ 
          type: LOGIN_SUCCESS, 
          token: newToken, 
          party: newParty, 
          role: newRole, 
          userParty: partyName, // old version : (metadata && metadata.party)
          isAuth0: true,
          operator: operatorName,
          last_password_reset,
          organizations,
          organization,
          current_organization,
        });

        /* if (lastPath) {
          dispatch({ 
            type: CHANGE_LASTPATH, 
            payload: lastPath,
          });
        } */
      } else {
        signOut(null, dispatch, null);
      }

    }
  }, [authLoading, authToken, token, party, role, authRole, partyName, operatorName, 
    last_password_reset, authPaty, organizations, organization, current_organization]);


  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}


/**
 * login user to userDispatch and redirect of history
 * @param {Function} dispatch 
 * @param {String} party 
 * @param {String} password password or userToken
 * @param {String} oldPassword 
 * @param {Object} history 
 * @param {Function} setLoading 
 * @param {Function} setError 
 * @param {String} userParty 
 * @param {Boolean} isAuth0 
 * @param {String} token default setting token
 * @param {String} operatorName
 * @param {String} last_password_reset
 * @param {Array} organizations
 * @param {String} organization
 * @returns 
 */
async function loginUser({
  dispatch, party, password="", oldPassword="", history, setLoading=()=>{}, setError=()=>{}, 
  userParty=null, isAuth0=false, token=null, operatorName, last_password_reset, organizations=[], organization=null, current_organization=null,
  switchOrganization=false,
}) {
  setError(false);
  setLoading(true);


  let role = 'Undefined';
  if (!!party) {
    // NEW CODE HERE TO RETRIEVE ROLE FROM RoleOne (Role data element)

    // let failedStatus = false;
    const fetchUpdate = async () => {
      console.log("insidefetch");
      try {

        const contractResponse = await post('/v1/query', {
          body: JSON.stringify({
            "templateIds": ["Auth.Networks:OnboardNetwork"],
            "query": { "party": party }
          }),
          token,
        });

        const contractResponseJson = await contractResponse.json();
        if (contractResponseJson.status === 200) {
          role = contractResponseJson.result[0].payload.companytype;
          console.log("[fetchUpdate] role", role);
        }
        else {
          role = "Undefined";
        }
      }
      catch (err) {
        if (party === DefaultOperatorParty) alert("You're Manager. You must create Operator at first.");
        else alert("Something went wrong with roletype or should submit InviteParty.");
        role = "Undefined";
        // dispatch({ type: LOGIN_FAILURE });
        // setError(true);
        // setLoading(false);
        // failedStatus = true;
      }
    };

    console.log("[loginUser] username: " + party + ", password: " + password + ", temporary pass: " + oldPassword);

    let cognitoToken;
    let errorMsg = "";
    let tempRole = null;

    if (oldPassword !== "") {
      // cognito change password
      cognitoToken = await asyncChangePassword(party, oldPassword, password);
      errorMsg = "Password is not long enough.";
      tempRole = cognitoToken;
    }
    else {
      // cognito log in
      cognitoToken = await cognitoLogIn(party, password);
      errorMsg = "Something is wrong with your login or password :(";
    }

    if (!cognitoToken) {

      console.log("[loginUser] cognito LogIn is failed.");
      setError(true);
      setLoading(false);

      dispatch({ type: LOGIN_FAILURE, payload: { error: errorMsg } });
      return;
    }

    console.log("[loginUser] before fetchUpdate", cognitoToken);

    await fetchUpdate();
    
    // await getParties();

    // When party is Operator, but role should be still "Undefined"
    // if (party === DefaultOperatorParty && role === "Undefined") role = "Manager";

    // if (failedStatus) return;
    // const token = password || createToken(party)
    // localStorage.setItem(damlPartyKey, party);
    // localStorage.setItem(damlTokenKey, token);

    // Role is retrieved from party Name
    // const role = localStorage.getItem(damlPartyKey,party)
    // localStorage.setItem(damlRoleKey, role);


    setError(false);
    setLoading(false);

    dispatch({ 
      type: LOGIN_SUCCESS, 
      token: (token ? token : getToken()), 
      party, 
      role, 
      tempRole, 
      userParty, 
      isAuth0, 
      operator: operatorName,
      last_password_reset,
      organizations,
      organization,
      current_organization
    });

    console.log("[loginUser] after login:", party, role, tempRole, userParty);

    //reload is req. to update the current_organization of the user which is coming from auth0 API, Need to think new way to handle it
    if(switchOrganization) {
      window.location.href = "/";
    }
    else {
      history.push("/app");
    }
  } else {

    setError(true);
    setLoading(false);

    dispatch({ type: LOGIN_FAILURE });
  }
}

const loginDablUser = () => {
  window.location.assign(`https://${dablLoginUrl}`);
}

/**
 * Log out
 * @param {Object} event 
 * @param {Function} dispatch 
 * @param {Object} history 
 */
function signOut(event, dispatch, history) {
  if(event) event.preventDefault();
  // remove party
  localStorage.removeItem(damlPartyKey);
  // remove token
  localStorage.removeItem(damlTokenKey);
  // remove role
  localStorage.removeItem(damlRoleKey);
  // remove operator party
  // localStorage.removeItem(damlOperatorKey);
  // remove flag auth0
  localStorage.removeItem(damlFlagAuth);
  // remove token date
  localStorage.removeItem(damlTokenDateKey);
  // remove last path
  localStorage.removeItem(damlLastPath);
  // remove reload timestamp
  localStorage.removeItem(damlReloadTime);

  //remove bearerToken
  localStorage.removeItem(bearerTokenKey);
  // for user reducer
  dispatch({ type: SIGN_OUT_SUCCESS });

  // clear all redux stores
  rootDispatch({ type: SIGN_OUT_SUCCESS });

  if(history) history.push("/login");

}


// storeTokenOperator();


/**
 * login using auth0
 * @param {Function} loginWithRedirect 
 * @param {Function} loginWithPopup 
 * @param {Function} getAccessTokenSilently 
 * @param {Function} getIdTokenClaims 
 * @param {Function} userDispatch 
 * @param {Object} history 
 */
async function loginAuth({
  loginWithRedirect,
  loginWithPopup,
  getAccessTokenSilently,
  getIdTokenClaims,
  userDispatch,
  history,
  switchOrganization=false,
}) {

  // This part substitutes the party and access token 
  // parameter input by acquiring the access token from Auth0
  // and extracting the party id from the access key.
  // disable now
  // await loginWithPopup();
  // login page to redirect
  await loginWithRedirect();

  // AccessToken
  let token = null;
  try {
    token = await getAccessTokenSilently();
  }
  catch (e) {
    console.warn("[loginAuth] getAccessTokenSilently", e);
    return;
  }

  const decoded = jwt.decode(token);
  console.log("[loginAuth] accessToken", token, decoded);
  if (typeof decoded !== "object" || !decoded){
    throw new Error("Decoded token is not object");
  }

  // get party
  const party = decoded[ledgerApiIdentify].actAs[0];
  console.log("[loginAuth] party:", party);

  // IdToken
  const idToken = await getIdTokenClaims();
  console.log("[loginAuth] idToken", idToken);

  const { partyName, operatorName, last_password_reset, party: authPaty, 
    organizations, organization } = getUserFromAuthToken(idToken);
  // console.log("[loginAuth] name:", partyName, "operator:", operatorName, "role:", role, "metadata:", metadata);

  // This part is bascally the same as the body
  // of the loginUser function.

  if (!!party) {
    // This line is deleted, it originally served 
    // the purpose of creating an access token locally
    // when no access token was inputted on the UI
    // const token = password || createToken(party)
    // disable now
    // localStorage.setItem(damlPartyKey, party);
    // localStorage.setItem(damlTokenKey, token);
    localStorage.setItem(damlFlagAuth, "true");

    // disable now
    // userDispatch({ type: LOGIN_SUCCESS, token, party });
    // props.history.push("/app");

    // store token, operator
    // disable now
    /* await storeTokenOperator({
      token, 
      operator: operatorName, 
      force: true
    }); */

    // wait for 1 second
    // disable now
    // setTimeout(async () => {
      // log in in daml ledger
      await loginUser({
        dispatch: userDispatch, 
        party: authPaty || party, 
        history, 
        // setLoading: (param) => console.log("[loginAuth] setLoading", param), 
        // setError: (param) => console.log("[loginAuth] setError", param), 
        userParty: ((!!partyName) ? partyName : party), 
        isAuth0: true,
        token,
        operatorName,
        last_password_reset,
        organizations,
        organization,
        switchOrganization: switchOrganization
      });
    // }, 1000);

  } else {
    // disable now
    // userDispatch({ type: LOGIN_FAILURE });
  }

}

/**
 * check if login using auth0
 * @returns {Boolean}
 */
function isLoginAuth() {
  const flag = localStorage.getItem(damlFlagAuth);
  const role = localStorage.getItem(damlRoleKey);
  return (flag === "true" && !!role);
}

/**
 * logout using auth0
 * @param {Object} event 
 * @param {Function} logout
 * @param {Function} userDispatch 
 * @param {Object} history 
 */
async function logoutAuth({
  event,
  logout,
  userDispatch,
  history,
}) {

  // Sign out
  signOut(event, userDispatch, history);

  // logout of Auth0
  logout({ returnTo: window.location.origin });

}


export { UserProvider, useUserState, useUserDispatch, loginUser, loginDablUser, signOut, 
  loginAuth, logoutAuth, isLoginAuth };
