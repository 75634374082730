import React, { useState } from "react";
import classNames from "classnames";
import useStyles from "../styles";
import { TableCell, Box, List, ListItem, ListItemText } from "@material-ui/core";
import { useSurgicalEvent } from "../../../hooks/useProduct";
import prefCardLogo from "../../../icons/Pref_Card_Logo.svg";
import useEvent from "../../../hooks/useEvent";
import trash_icon from "../../../icons/trash_icon.svg";
import { PREF_DELETE_EVENT } from "../../../store/actions/constants";
import SnackbarModal from "../../../components/Modals/SnackbarModal";


const PrefListView = ({ onPrefView,top=0, }) => {
    const { prefdata, setPrefData } = useSurgicalEvent();
    const classes = useStyles();
    const { onEventStart } = useEvent();
    const [openToast, setToast] = useState({ isVisible: false, msg: '', selectedItem: null});

    return (<>
        <TableCell colSpan={4}>
            {prefdata.length !== 0 && (
                <Box className={classes.overflowBoxPref} style={{top:`${top}px`}}>
                    <List disablePadding={true}>
                        {prefdata?.map((dataPrefElement, index) => (
                            <ListItem
                                key={dataPrefElement.id}
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    borderBottom: '1px solid rgba(204, 204, 204, 1)',
                                }}
                            >
                                <ListItemText>
                                    {dataPrefElement.cptCodes},
                                    {dataPrefElement.name}
                                </ListItemText>
                                <img
                                    src={prefCardLogo}
                                    alt=""
                                    onClick={() =>
                                        onPrefView(dataPrefElement)
                                    }
                                />
                                <img
                                    src={trash_icon}
                                    alt=""
                                    onClick={() => {
                                        // debugger
                                        setToast({ isVisible: true, msg: 'Are you sure you want to Delete this Preference Card?', selectedItem: { index, id: dataPrefElement.contractid } })
                                    }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            )}
        </TableCell>
        {
            (openToast.isVisible ) ?
                (
                    <SnackbarModal
                        open={openToast.isVisible}
                        setOpen={(isVisible) => {setToast({isVisible})}}
                        type="warning"
                        title=""
                        content={openToast.msg}
                        vertical="center"
                        horizontal="center"
                        width={350}
                        height={140}
                        transition="fade"
                        className={classNames(classes.toastLarge, classes.toastWarning)}
                        options={{
                            validateULE: true,
                          }}
                        actions={
                            [
                                {
                                    label: "Yes",
                                    handle: async () => {
                                        if (openToast.selectedItem.id) {
                                            onEventStart(PREF_DELETE_EVENT, openToast.selectedItem.id)
                                        }
                                        prefdata.splice(openToast.selectedItem.index, 1)
                                        setPrefData([...prefdata])
                                        setToast({ isVisible: false, msg: 'Preference Card has been deleted.', selectedItem: null})

                                    },
                                },
                                {
                                    label: "No",
                                    handle: async () => {
                                        setToast({ isVisible: false, msg: '', selectedItem: null });
                                    },
                                },
                            ]}
                    />
                )
                :
                null
        }
    </>

    )

}

export default PrefListView;