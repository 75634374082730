import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import {
  CardActionArea,
  Grid,
  CardHeader,
} from "@mui/material";
import {
  Compliance,
  Invoices,
  Expired,
  ExpiredContracts,
  InventoryStatus,
  InventoryValue,
  ProductUtilization,
  PurchaseOrderAssociate,
  PurchaseOrderManager,
  Recall,
  ProductsUsedOff,
  SurgicalEvents,
  InventoryAdjustments,
  Shipping
} from "../../components/Card/CardVariations";
//'VManager', 'VAssociate',
const DashboardDataCards = (props) => {
  const staticData = {
    title: props.title,
  };
  return (
    <>
      <main>
        <Card sx={{ minHeight: "325px" }}>
          <CardHeader
            sx={{
              bgcolor: "#1C3C74",
              color: "white",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "21px",
            }}
            titleTypographyProps={{ variant: "h7" }}
            title={staticData.title}
          />

          <CardActionArea>
            <Grid
              container
              sx={{
                direction: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "255px",
              }}
            >
              <Grid item>
                <CardContent>
                  {staticData.title === "Purchase Orders"  && (props.role !== "HInventoryAssociate" && props.role !== "VAssociate" &&  props.role !== "VManager") ? (
                    <PurchaseOrderManager
                      staticData={staticData}
                      vendorNotAccepted={props.vendorNotAccepted}
                      unsentPO={props.unsentPo}
                      posDeclined={props.posDeclined}
                    />
                  ) : null}


                  {staticData.title === "Purchase Orders" && (props.role === "HInventoryAssociate" ||  props.role === "VAssociate" ||  props.role === "VManager" )? (
                    <PurchaseOrderAssociate
                      staticData={staticData}
                      newPurchaseOrder={props.newPurchaseOrder}
                      unsentPO={props.unsentPo}
                      unsentPOAmount={props.unsentPOAmount}
                      newPurchaseOrderAmount={props.newPurchaseOrderAmount}
                    />
                  ) : null}

                  {staticData.title === "Surgical Events" ? (
                    <SurgicalEvents
                      
                      scheduledToday={props.scheduledToday}
                      completed={props.completed}
                      staticData={staticData}
                    />
                  ) : null}

                  {staticData.title === "Inventory Status" ? (
                    <InventoryStatus
                      pendingShip={props.pendingShipmentArrival}
                      belowParLevel={props.belowParLevel}
                      outOfStock={props.outOfStock}
                      backOrdered={props.backOrdered}
                      staticData={staticData}
                    />
                  ) : null}

                  {staticData.title === "Inventory Value" ? (
                    <InventoryValue
                      staticData={staticData}
                      totalInventory={props.totalInventory}
                      consigned={props.consigned}
                      owned={props.owned}
                      stock={props.stock}
                    />
                  ) : null}

                  {staticData.title === "Product Utilization" ? (
                    <ProductUtilization
                      staticData={staticData}
                      productsUsed1day={props.productsUsed1day}
                      productsUsed7day={props.productsUsed7day}
                      productsUsed30day={props.productsUsed30day}
                      productsUsed1DayAmount={props.productsUsed1DayAmount}
                      productsUsed7DaysAmount={props.productsUsed7DaysAmount}
                      productsUsed30DaysAmount={props.productsUsed30DaysAmount}
                      productsUsed180DaysAmount={props.productsUsed180DaysAmount}
                      productsUsedlastYearAmount={props.productsUsedlastYearAmount}
                    />
                  ) : null}

                  {staticData.title === "Invoices not Received" ? (
                    <Invoices
                      staticData={staticData}
                      sevenDays={props.sevenDays}
                      thirtyDays={props.thirtyDays}
                      oneEightyDays={props.oneEightyDays}
                      year={props.year}
                    />
                  ) : null}

                  {staticData.title === "Expiring and Expired Products" ? (
                    <Expired
                      staticData={staticData}
                      expired={props.expired}
                      expiredAmount={props.expiredAmount}
                      expiring={props.expiring}
                      expiringAmount={props.expiringAmount}
                    />
                  ) : null}

                  {staticData.title === "Expiring and Expired Contracts" ? (
                    <ExpiredContracts
                      staticData={staticData}
                      expired={props.expired}
                      expiredAmount={props.expiredAmount}
                      expiring={props.expiring}
                      expiringAmount={props.expiringAmount}
                    />
                  ) : null}

                  {staticData.title === "Recalls and Returns" ? (
                    <Recall
                      staticData={staticData}
                      recall={props.recall}
                      returnsAwaiting={props.returnsAwaiting}
                    />
                  ) : null}

                  {staticData.title === "Products used off Contracts" ? (
                    <ProductsUsedOff
                      staticData={staticData}
                      usedOffsevenDays={props.usedOffsevenDays}
                      usedOffthirtyDays={props.usedOffthirtyDays}
                      usedOffoneEightyDays={props.usedOffoneEightyDays}
                      usedOffyear={props.usedOffyear}
                    />
                  ) : null}

                  {staticData.title === "Compliance" ? (
                    <Compliance
                      staticData={staticData}
                      productsOutOfCompliance={props.productsOutOfCompliance}
                      productsOutOfComplianceAmount={props.productsOutOfComplianceAmount}
                    />
                  ) : null}

                  {staticData.title === "Inventory Adjustments" ? (
                    <InventoryAdjustments
                      staticData={staticData}
                      hospitalAdjustedCount={props.hospitalAdjustedCount}
                      returnRequringApprovalCount={props.returnRequringApprovalCount}
                    />
                  ) : null}

                  {staticData.title === "Shipping" ? (
                    <Shipping
                      staticData={staticData}
                      readyForShipmentCount={props.readyForShipmentCount}
                      backOrderCount={props.backOrderCount}
                    />
                  ) : null}
                </CardContent>
              </Grid>
            </Grid>
          </CardActionArea>
        </Card>
      </main>
    </>
  );
};

export default DashboardDataCards;
