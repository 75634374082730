import React from "react";
import { MenuItem } from "@material-ui/core";

export const validateArray = (data, validate) => (
  typeof data === "object"
  && (data.length === 0
    || (data.length > 0
      && !!!(data.find(d => !!!validate(d)) !== undefined)))
);

export const getArray = (data, validate, getData=(d) => (d)) => (
  (
    typeof data === "object"
    && data.length > 0
  )
  ?
    data.filter(d => !!validate(d)).map(d => getData(d))
  :
    []
);

export const requiredFields = (data, fields) => {
  let errors = {};
  if (!!fields && typeof fields === "object") {
    if (!data || typeof data !== "object") data = {};
    for (let key in fields) {
      let val = fields[key];
      if (!data[key]) errors[key] = `${val} is required.`;
    }
  }
  return errors;
};

/**
 * create Party from Name
 * Old case: remove all spaces
 * New case: remove only start & end spaces
 * @param {String} name 
 * @returns {String}
 */
export const createPartyFromName = (name) => {
  const inputName = name?.trim(); // Old: name?.replace(/[\W]/g, "")
  return inputName?.charAt(0)?.toUpperCase() + inputName?.slice(1);
};

/**
 * get Label of Party
 * @param {String} party 
 * @param {Number} length 
 * @returns {String}
 */
export const getPartyLabel = (party, length=2) => {
  if (length === 2) {
    const onlyUppers = party.match(/[A-Z]/g);
    if (onlyUppers && onlyUppers.length >= 2) return onlyUppers[0] + onlyUppers[1];
    return party.slice(0, 2);
  }
  return party.charAt(0);
};

/**
 * validate Model
 * @param {Object} Model 
 * @returns 
 */
export const validateModelItem = (Model) => (value) => {
  for (let i in Model) {
      if(i === value) return true;
  }
  return false;
};

/**
 * get Menu by Model
 * @param {Object} Model 
 * @returns 
 */
export const getModelMenu = (Model) => (init=null, hidden=[]) => {
  const menus = Object.keys(Model).filter(value => !hidden.includes(value))
    .map(value => {
      return <MenuItem key={value} value={value}>{Model[value]}</MenuItem>
    });
  if(typeof init === 'string') return [<MenuItem key={init} value="" disabled>{init}</MenuItem>].concat(menus);
  return menus;
};

/**
 * get Sub Model
 * @param {Object} Model 
 * @param {Array} filters 
 * @returns 
 */
export const getSubModel = (Model, filters=[]) => filters.reduce((obj, key) => {
    if (key in Model) obj[key] = Model[key];
    return obj;
  }, {});

/**
 * filter Model
 * @param {Object} Model 
 * @param {Array} filters
 * @returns {Object}
 */
export const filterModel = (Model, filters=[]) => {
  let results = {...Model};
  filters.forEach(key => {
    if (key in results) delete results[key];
  });
  return results;
};

/**
 * sort json
 * @param {Array} jsonArray 
 * @returns 
 */
export const sortingJson = (jsonArray) => {
  jsonArray =  Object.keys(jsonArray).sort().reduce(function (result, key) {
    result[key] = jsonArray[key];
    return result;
  }, {});
  var jsonObject = {}
  Object.keys(jsonArray).forEach((inv,index) => {
    jsonObject[index] = jsonArray[inv];
  });
  return jsonObject;
};

/**
 * validate text
 * @param {String|null} data 
 * @returns 
 */
export const validateText = (data) => (
  typeof data === 'string' && data !== ""
);

export const validateDecimal = (data) => (
  typeof data === 'number' || (typeof data === 'string' && !isNaN(data))
);
