import React, { useState, useRef } from "react";
import classNames from "classnames";
import { useReactToPrint } from 'react-to-print';
import { useParty, useStreamQueries } from "@daml/react";
import { Grid, Typography, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { RestockInventory, RestockInventoryHistory } from "../../services/daml-modules1";
import { arrayToCsv, downloadCSV } from "../../services/download";
import useEvent from "../../hooks/useEvent";
import { convertPurDataToCSV } from "../../models/Po";
import { convertInventoryDataToCSV } from "../../models/InventoryData";
import ScanInventoryHospitalContracts from "./inventoryscanhospitalcontracts1";
import IncomingInventory from "./incoming-inventory";
import ConfirmInventory from "./confirm-inventory";
import InventoryScanPrint from "./inventoryscan-prints";
import InventoryScanPDF, { styles as pdfstyles } from "./inventoryscan-pdf";
import exportIcon from "../../icons/new-export-icon.svg";
import printIcon from "../../icons/new-print-icon.svg";
import CustomPrints from "../surgicalevent/surgicalevent-prints";
import { handlePDFGenerator } from "../surgicalevent/surgicalevent-pdf";
import ExportPopover from "../surgicalevent/export-popover";
import useStyles from "../surgicalevent/styles";


/**
 * Incoming(Restock) Inventory Page for Hospital
 * Re-design
 * @returns 
 */
export default function ScanInventoryHospital() {

  const classes = useStyles();

  const hospital = useParty();

  const { contracts, loading } = useStreamQueries(RestockInventory, () => [{ hospital }]);
  const { contracts: contractsHistory } = useStreamQueries(RestockInventoryHistory, () => [{ hospital }]);

  const { onEventClear } = useEvent();

  // Component used for React-to-Print
  const componentRef = useRef();

  const [isHistory, setHistory] = useState(false);
  // Incoming Inventory
  const [contract, setContract] = useState({});
  const [openModal, setOpenModal] = useState(false);
  // checked Inventory
  const [checkedInv, setCheckedInv] = useState([]);
  // Popover when clicking Export button
  const [anchorEl, setAnchorEl] = useState(null);
  // Confirm Inventory (Auto Match)
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  // Click "Auto Match" in Inventory list page
  const handleCreateInventory = () => {
    setOpenConfirmModal(true);
    onEventClear();
  };

  const handleEditInventory = (c) => {
    setContract(c);
    setOpenModal(true);
    onEventClear();
  };

  const handlePrintClick = () => {
    if (!checkedInv || checkedInv.length === 0) return;
    handlePrint();
  };

  const handleOpenPopover = (event) => {
    if (!checkedInv || checkedInv.length === 0) return;
    setAnchorEl(event.currentTarget);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  return (
    <>

      <Grid>

      <Grid container justifyContent="space-between">
        <Grid item xs>
          <Typography variant="h4" className={classes.newPageTitle}>Incoming Inventory</Typography>
        </Grid>
        <Grid item>
          <Button
            className={classNames(classes.newCreateButton, classes.newIconButton)}
            variant="contained"
            onClick={handlePrintClick}
            startIcon={<img  alt="" src={printIcon} />}
          />
          <Button
            className={classNames(classes.newCreateButton, classes.newIconButton)}
            variant="contained"
            onClick={handleOpenPopover}
            startIcon={<img  alt="" src={exportIcon} />}
          />
          <Button
            className={classes.newCreateButton}
            variant="contained"
            onClick={handleCreateInventory}
            startIcon={<AddIcon />}
          >
            Auto Match
          </Button>

          <ExportPopover
            anchor={anchorEl}
            setAnchor={setAnchorEl}
            handleExportCSV={() => handleExportCSV(checkedInv)}
            handleExportPDF={() => handleExportPDF(checkedInv)}
          />
        </Grid>
      </Grid>

      <ScanInventoryHospitalContracts
        allContracts={contracts}
        historyContracts={contractsHistory}
        isIncludeHistory={isHistory}
        setArchived={setHistory}
        setChecked={setCheckedInv}
        handleEditInventory={handleEditInventory}
        loading={loading}
      />

      </Grid>

      <IncomingInventory
        open={openModal}
        setOpen={setOpenModal}
        contract={contract}
        contracts={contracts}
      />

      <ConfirmInventory
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
        contracts={contracts}
      />

      <CustomPrints
        ref={componentRef}
        contracts={checkedInv}
        Component={InventoryScanPrint}
      />

    </>
  );
}

const getExportData = (checkedInv) => {
  if (!checkedInv || !checkedInv.length) return {};
  const filename = `${checkedInv[0].ipurchaseorderdata.popurchaseorderid}${(checkedInv.length > 1) ? `(+${checkedInv.length-1})` : ''}`;
  const runningInvs = [...checkedInv];
  return {filename, runningInvs};
};

export const handleExportCSV = async (checkedInv) => {
  // console.log("[ScanInventoryHospital] handleExportCSV", checkedInv);
  const {filename, runningInvs} = getExportData(checkedInv);
  let header, data, csvData = '';
  runningInvs.forEach(({ipurchaseorderdata, inventorydata, hospital}) => {
    const csvHeader = [
      { label: "PO number: ", key: "popurchaseorderid" },
      { label: "Ownership:", key: "popurchaseordertype" },
      { label: "Distributor:", key: "povendorid" },
      { label: "PO Date: ", key: "podate" },
      { label: "SEID: ", key: "poseid" },
    ];
    ({header, data} = convertPurDataToCSV({...ipurchaseorderdata, hospital}, csvHeader));
    // customize as redesign
    csvData = csvData + `\n\n\n\n,,${header[0].label} ${data[0][header[0].key]},${header[1].label} ${data[0][header[1].key]}\n`;
    csvData = csvData + `,,${header[2].label} ${data[0][header[2].key]},${header[3].label} ${data[0][header[3].key]}\n`;
    csvData = csvData + `,,${header[4].label} ${data[0][header[4].key]}\n\n`;
    ({header, data} = convertInventoryDataToCSV(inventorydata));
    csvData = csvData + '\n' + arrayToCsv(header, data);
  });
  await downloadCSV(csvData, filename);
};

export const handleExportPDF = async (checkedInv) => {
  // console.log("[ScanInventoryHospital] handleExportPDF", checkedInv);
  const {filename, runningInvs} = getExportData(checkedInv);
  await handlePDFGenerator({ contracts: runningInvs, fileName: filename, Component: InventoryScanPDF, style: pdfstyles });
};