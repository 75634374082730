import React, { useCallback, useEffect } from "react";
import { useLedger } from "@daml/react";
import { Typography } from "@material-ui/core";
import ModalDialog from '../../components/Modals/NewGeneralModal';
import { useLayoutDispatch, setLoading, unsetLoading } from "../../context/LayoutContext";
import { useDamlState } from "../../context/DamlContext";
import { INVENTORY_DELETE_EVENT, EVENT_REQUIRED } from "../../store/actions/constants";
import { useHospitalUser, useItemDetails } from "../../hooks/useProduct";
import { useAccountInventory } from "../../hooks/useInventory";
import { useVendorUser, useComment } from "../../hooks/usePO";
import useEvent from "../../hooks/useEvent";
import { EditInventoryDetail, ReturnInventoryDetail } from "./edit-return-inventory-detail";
import { createReturnResponse, acceptDeclineInventoryData, removeInventoryRequestData } from "./handle";
import useStyles from "./styles";


/**
 * Dialog of In Edits / Returns of My Inventory
 * @param {Boolean} open
 * @param {Function} setOpen
 * @param {Object} contract
 * @param {Object} options 
 */
export default function EditReturnInventory({
  open, setOpen, contract={}, options={}, 
}) {

  options = {
    title: "Edits",         // Modal Title
    isReturn: false,        // if it's true, then Edit. if it's false, then Return
    ...options,
  };

  const classes = useStyles();

  const layoutDispatch = useLayoutDispatch();

  const ledger = useLedger();
  const { role, roleCid, party, userParty } = useDamlState();
  const { accountInventoryData, setAccountInventoryData } = useAccountInventory();
  const { setHospital } = useHospitalUser();
  const { setVendor } = useVendorUser();
  const { setComments } = useComment();
  const { details, setDetails } = useItemDetails();
  const { event, status, onEventRun, onEventEnd } = useEvent();


  // console.log("[EditReturnInventory]", contract);


  const handleDelete = useCallback(async () => {
    if (details.length === 0) return;
    console.log("[EditReturnInventory] handleDelete", details, options.isReturn, role);

    // spinner
    setLoading(layoutDispatch);
    onEventRun();

    let ContractId = accountInventoryData.contractId, res;

    if (options.isReturn) {
      // return
      const defaultUser = userParty ? userParty : party;

      for (let i in details) {
        const inventorydata = details[i];

        if (role === 'Vendor') {
          // accept / decline return request
          await createReturnResponse({
            ledger, 
            ContractId: ContractId,
            inventorydata: inventorydata,
            newireturnrequestdecision: false,
            newireturnrequestcomment: "",
            newireturnrequestdecisionuser: defaultUser,
            roleCid,
          });
        }
      }
    }
    else {
      // edit
      for (let i in details) {
        const inventorydata = details[i];

        if (role === 'Vendor') {
          // accept edit request
          res = await acceptDeclineInventoryData({
            ledger, 
            ContractId: ContractId, 
            inventorydata: inventorydata,  
            roleCid,
          });
        }
        else if (role === 'Hospital') {
          // remove edit request
          res = await removeInventoryRequestData({
            ledger, 
            ContractId: ContractId, 
            inventorydata: inventorydata,  
            roleCid,
          });
        }
  
        if (res) {
          ContractId = res.contractId;
        }
      }

      setAccountInventoryData({
        ...accountInventoryData,
        ...res?.inventoryproduct,
        contractId: ContractId,
        inventorydata: res?.inventorydata,
      });
    }

    unsetLoading(layoutDispatch);
    onEventEnd();

    setOpen(false);

  }, [details, options.isReturn, role, accountInventoryData, setAccountInventoryData, ledger, party, roleCid, userParty,
    layoutDispatch, onEventEnd, onEventRun, setOpen]);


  useEffect(() => {
    if (!open) return;
    console.log("[EditReturnInventory] set default data.", contract);
    if (contract.payload) {
      if (options.isReturn) {
        // Return
        setAccountInventoryData({
          iunits: contract.payload?.inventorydata?.iproduct?.units,
          ...contract.payload?.inventoryproduct,
          hospital: contract.payload?.hospital,
          vendor: contract.payload?.vendor,
          iproduct_sepudi: contract.payload?.iproduct_sepudi,
          contractId: contract.contractId,
          inventorydata: [contract.payload?.inventorydata],
        });
        setComments(contract.payload?.inventorydata?.ireturncomments);
      }
      else {
        // Edit
        setAccountInventoryData({
          ...contract.payload?.inventoryproduct,
          vendor: contract.payload?.vendor,
          iproduct_sepudi: contract.payload?.iproduct_sepudi,
          contractId: contract.contractId,
          inventorydata: contract.payload?.inventorydata,
        });
        setComments([]);
      }
      setHospital(contract.payload?.hospital);
      setVendor(contract.payload?.vendor);
      setDetails([]);
    }
    else {
      setAccountInventoryData({});
      setComments([]);
      setHospital(null);
      setVendor(null);
      setDetails([]);
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (event) {
      console.log("[EditReturnInventory] On Start Event", event, status);
      if (event === INVENTORY_DELETE_EVENT && status === EVENT_REQUIRED) {
        handleDelete();
      }
    }
  }, [event, status, handleDelete]);


  return (
    <>

      <ModalDialog
        open={open}
        setOpen={setOpen}
        title={(
          <Typography className={classes.editReturnTitle}>{options.title}</Typography>
        )}
        content={(
          options.isReturn
          ?
          <ReturnInventoryDetail
            options={options}
          />
          :
          <EditInventoryDetail
            options={options}
          />
        )}
        className={classes.dialogNoBorder}
        height={651}
      />

    </>
  );
}
