
import { Button, Dialog, DialogContent, DialogContentText, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import React, { useState } from "react";
import "../import_file/Import_file.css";
import FileUploadDropzone from "../../components/FileUpload/FileUploadDropzone";
import CloseIcon from '@material-ui/icons/Close';
import useStyles from "../surgicalevent/styles";


export default function RecallUpload({
  title = "Drag & Drop files to Upload",
  addtionalBtn= true,
}) {

  const[open,setOpen] = useState(false);
  const[filesData,setFileData] = useState([]);
  const setFilePathData = (filedata)=>{
      const lines = filedata.split(/\r\n|\n/);
      let fileDataArray = [];
      for(var i = 0; i <lines.length; i++){
        fileDataArray.push((lines[i]).split(","));
      }
      setFileData(fileDataArray)
      setOpen(true)
  }
  const handleRequest= ()=>{
      setOpen(false)
  }
  
  return (
    <> 
      <div className="App">
        <FileUploadDropzone 
          title={title} 
          subtitle={'You can upload only valid csv here.'}
          setFilePathData={setFilePathData} 
          addtionalBtn={addtionalBtn} 
          heading={"Upload Recall Products"} 
        />
      </div>
      <PreviewDialog 
        open = {open}
        setOpen = {setOpen}
        filesData={filesData}
        handleRequest={handleRequest}
      />
    </>
  );
}

export function PreviewDialog ({
  open, setOpen, handleRequest,filesData
}) {
  
  const classes = useStyles();

  return (
    <Dialog
      className={classes.customRoot}
      open={open}
      onClose={()=>{setOpen(false)}}
      aria-labelledby="draggable-dialog-title"
    >
      <h2 className={classes.poformSubHeading}>
        Confirm Recall Products  
        <CloseIcon onClick={()=>{setOpen(false)}} className={classes.closeModal} /> 
      </h2>

      <DialogContent className={classes.mainFormSteps}>
        <DialogContentText  component={'div'}>
          <Table className="product-detail-data" aria-label="simple table">
            <TableBody>
              {filesData && filesData.map((obj,key)=>(
                  <TableRow key={key}>
                    <TableCell  className={key === 0 ? classes.tableHeadCell : classes.tableCell}>{obj[0]}</TableCell>
                    <TableCell  className={key === 0 ? classes.tableHeadCell : classes.tableCell}>{obj[1]}</TableCell>
                    <TableCell  className={key === 0 ? classes.tableHeadCell : classes.tableCell}>{obj[2]}</TableCell>
                    <TableCell  className={key === 0 ? classes.tableHeadCell : classes.tableCell}>{obj[3]}</TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContentText>
      </DialogContent>
    
      <div className="form-footer">
        <div className="right-buttons">
          <Button variant="text" color="primary" onClick={(e)=>(setOpen(false))}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleRequest} >Confirm</Button> 
        </div>
      </div>
    
    </Dialog>
  );
}
