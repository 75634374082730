import React , { useState } from "react";
import classNames from "classnames";
import { Table, TableBody, TableRow, TableCell, Grid, Typography, /*Button*/} from "@material-ui/core";
import { CustomTextArea } from "../../components/Inputs";
import useInventory from "../../hooks/useInventory";
import { formatDate } from "../../services/util";
// import commentsIcon from '../../icons/new-comments-icon.svg';
import IncomingInventoryContracts from "./incoming-inventory-contracts";
import useStyles from "../surgicalevent/styles";
// import POCommentPopover from "../purchaseorder/pocomment-popover";
import useStyles1 from "./styles";
import SnackbarModal from "../../components/Modals/SnackbarModal";


/**
 * Incoming Inventory Detail
 * @param {Array} inventorydata 
 * @param {Object} options 
 * @returns 
 */
export default function IncomingInventoryDetail({
  inventorydata = [], options = {},
}) {

  const classes = useStyles();
  const classes1 = useStyles1();
  const [openToast , setOpenToast] = useState(false);
  const [toastContent, setToastContent] = useState('');

  const { restockInventoryData: podata, setRestockInventoryData } = useInventory();

  // console.log("[IncomingInventoryDetail]", inventorydata, podata);

  // Popover when clicking Comment button
  // const [anchorEl, setAnchorEl] = useState(null);


  // handle to open Comment popover
  // const handleOpenCommentPopover = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // handle to click "All Receive" Icon Button to receive all
  // const handleAllReceive = async () => {
  //   //
  // };

  const getPOAmount = () => {
    if (inventorydata?.length > 0) {
      return inventorydata?.reduce((total, product) => total + parseInt(product?.iquantity), 0)
    }
    return 0;
  }

  return (
    <Grid container
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      className={classNames(classes.overview, classes1.incomingPanel)}
    >
      <Grid item className={classNames(classes1.details, classes1.headerPanel)}>
        <Typography variant="h2" className="title">PO Details</Typography>
        <Table>
          <TableBody>
            {/* <TableRow>
              <TableCell className="header-label">PO Number</TableCell>
              <TableCell className="header-value">{podata.popurchaseorderid}</TableCell>
              <TableCell className="header-label">Ownership</TableCell>
              <TableCell className="header-value">{podata.popurchaseordertype}</TableCell>
              <TableCell className="header-label">SE ID</TableCell>
              <TableCell className="header-value"> {podata.poseid}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="header-label">Distributor</TableCell>
              <TableCell className="header-value">{podata.povendorid}</TableCell>
              <TableCell className="header-label">PO Date</TableCell>
              <TableCell className="header-value">{podata.podate}</TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell className="header-label">PO Number</TableCell>
              <TableCell className="header-label">Distributor</TableCell>
              <TableCell className="header-label">Ownership</TableCell>
              <TableCell className="header-label">PO Date</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="header-value">{podata.popurchaseorderid}</TableCell>
              <TableCell className="header-value">{podata.povendorid}</TableCell>
              <TableCell className="header-value">{podata.popurchaseordertype}</TableCell>
              <TableCell className="header-value">{formatDate(podata.podate)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>

      <Grid item className={classNames(classes1.details, classes1.middlePanel)}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="inherit" className="header-value">Total Products: </Typography>
            <Typography variant="inherit" className="header-value">{getPOAmount()}</Typography>
            <Typography variant="inherit" className="header-value">Total Price:</Typography>
            <Typography variant="inherit" className="header-value">${podata.pototalamount}</Typography>
          </Grid>
          <Grid item>
            {/* <IconButton onClick={handleOpenCommentPopover}>
              <img alt="" src={commentsIcon} />
            </IconButton> */}

            {/* <Button
              variant="contained"
              color="primary"
              onClick={handleAllReceive}
              className={classNames(classes.newCompleteBtn,classes1.receiveBtn)}
            >
            Receive All
            </Button> */}

            {/* <POCommentPopover
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              // isSendable={false}
            /> */}
          </Grid>
        </Grid>
      </Grid>

      <Grid item className={classes1.contractsPanel}>
        <IncomingInventoryContracts
          allContracts={inventorydata}
          options={options}
          setOpenToast={setOpenToast}
          setToastContent={setToastContent}
        />
      </Grid>

      <Grid item className={classes1.bottomPanel}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {
            options.isNotes &&
            <Grid item>
              <CustomTextArea
                label="Note:"
                placeholder="Type here..."
                value={podata.ponotes || ""}
                onChange={(val) => setRestockInventoryData({
                  ponotes: val,
                })}
                minRows={3}
                width={304}
                options={{
                  labelLeft: true,
                  labelWidth: 40,
                  maxHeight: 150,
                  disabled: options.isNotesDisabled,
                }}
              />
            </Grid>
          }
        </Grid>
        <SnackbarModal
          open={openToast}
          setOpen={setOpenToast}
          title=""
          content={toastContent}
          width={330}
          height={88}
          className={classes.toastMedium}
        />
      </Grid>
    </Grid>
  );
}
