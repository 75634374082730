import React, { useState, useEffect, useRef } from "react";
import CustomSnackbar from "../../Toast/CustomSnackbar1";


/**
 * Modal by using Custom Snackbar
 * @param {Boolean} open
 * @param {Function} setOpen
 * @param {String} content
 * @returns 
 */
export default function SnackbarModal ({
  open, setOpen, content, ...props
}) {

  const [snackbarContent, setSnackbarContent] = useState("");

  const mounted = useRef(false);

  const handleClose = () => {
    if (typeof setOpen === 'function' && mounted.current) setOpen(false);
  };

  
  useEffect(() => {
    if (open) setSnackbarContent(content);
    else setSnackbarContent("");
  }, [open, content]);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);


  return (
    <CustomSnackbar
      {...props}
      content={snackbarContent}
      handleClose={handleClose}
    />
  );
}