import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Tooltip } from "@material-ui/core";
import useStyles from "./styles";


/**
 * Custom Tooltip
 * @param {Component} children 
 * @param {Object} props 
 * @returns 
 */
export default function CustomTooltip({
  children, ...props 
}) {

  const {title, classes, arrow} = props;

  // constant delay time to keep opened tooltip
  const TOOLTIP_DELAY = 50;

  const classesInner = useStyles();

  const [open, setOpen] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const [popper, setPopper] = useState(false);
  const tooltipRef = useRef(false);
  const popperRef = useRef(false);


  const handleTooltipOpen = () => {
    // console.log("[CustomTooltip] handleTooltipOpen");
    setOpen(true);
    setTooltip(true);
  };

  const handleTooltipLeave = () => {
    // console.log("[CustomTooltip] handleTooltipLeave");
    setTooltip(false);
    setTimeout(checkMouseOver, TOOLTIP_DELAY);
  };

  const handlePopperEnter = () => {
    // console.log("[CustomTooltip] handlePopperEnter");
    setPopper(true);
  };

  const handlePopperLeave = () => {
    // console.log("[CustomTooltip] handlePopperLeave");
    setPopper(false);
    setTimeout(checkMouseOver, TOOLTIP_DELAY);
  };

  const checkMouseOver = () => {
    if (open && !tooltipRef.current && !popperRef.current) setOpen(false);
  };

  useEffect(() => {
    tooltipRef.current = tooltip;
  }, [tooltip]);

  useEffect(() => {
    popperRef.current = popper;
  }, [popper]);


  return (
    <Tooltip 
      title={(
        <div onMouseLeave={handlePopperLeave} onMouseEnter={handlePopperEnter} >
          {title}
        </div>
      )}
      classes={{ 
        arrow: classNames(classesInner.tooltipArroww, (classes ? classes.arrow : null)), 
        tooltip: classNames(classesInner.tooltip, (classes ? classes.tooltip : null)), 
        popper: classNames(classesInner.tooltipPopper, (classes ? classes.popper : null)), 
      }} 
      arrow={arrow}
      open={open}
      onOpen={handleTooltipOpen}
      // leaveDelay={1000}
    >
      <div className={classesInner.tooltipComp} onMouseEnter={handleTooltipOpen} onMouseLeave={handleTooltipLeave} >
        {children}
      </div>
    </Tooltip>
  );
}