import React from "react";
import {
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import useStyles from "../styles";

import PickSetupElement from "./pickSetupElement";
import PrefPickSetupSelectAll from "./pickSetupSelectAll";
import { createHashID } from "../../../services/crypto-util";

export default function PickSetupSubCategories({
  element,
  dispatch,
}) {
  

  const classes = useStyles();

/*   const updateItemStatus = (value) => {
    element.checked = value;
    setIsChecked(value);
    setParentSelected(value);
  }; */

  return (
    <>
      <Typography className={classes.newtableTitle}>
        {element?.category?.name}
      </Typography>
      <Table className={classes.rowWithBorder}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cellWithBorder} width={150}>
              Name
            </TableCell>
            <TableCell className={classes.cellWithBorder} width={100}>
              Reference
            </TableCell>
            <TableCell className={classes.cellWithBorder} width={140}>
              Item Note
            </TableCell>
            <TableCell className={classes.cellWithBorder} width={75}>
              Cost
            </TableCell>
            <TableCell className={classes.cellWithBorder} width={75}>
              Open
            </TableCell>
            <TableCell
              colSpan={2}
              className={classes.cellWithBorder}
              width={75}
            >
              Hold
              <PrefPickSetupSelectAll
                dispatch={dispatch}
                element={element}
                
    
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {element?.items?.map((rowElement, index) => {
            return (
              <PickSetupElement
                dispatch={dispatch}
                key={createHashID({ index })}
                rowElement={rowElement}
                
              />
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}
