import React,{useState} from "react";
import {Typography } from "@material-ui/core";
import editIcon from "../../icons/edit.svg";
import editDisableIcon from "../../icons/edit-disable.svg";
import {CustomizedProgressBars} from './Progress';
import HospitalProfileUpdate from "./HospitalEdit";
import TreeContracts from "../../components/Contracts/TreeContracts";
import useStyles from "../surgicalevent/styles";
import { HospitalRole } from "../../services/daml-modules1";
import { useStreamQueries } from "@daml/react";

export default function OperatorHospitalList() {

  const assets = useStreamQueries(HospitalRole, () => [{}]);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const[open,setOpen] = useState(false);
  const classes = useStyles();
  const handleRequest = (c) =>{
    // console.log("c", c)
    setSelectedProfile(c);
    setOpen(true)
  } 
  //<BorderLinearProgress variant="determinate" value={50} />
  return (
    <>  
     <div key="formChildStep" className="formsteps">
     <Typography variant="h4" className={classes.pageTitle}>Hospitals </Typography>      
      <TreeContracts
        className={classes.customRoot}
        contracts={assets.contracts}
        columns={[
          // ["ContractId", "contractId"],
          ["Name of Facility", "payload.hospital", {
            options: {
              width: 200,
            },
               
          }],
          ["Address", "payload.hospital"],
          ["No. of Contacts", "payload.hospitaldetails.billinfocontact.length",{
            options:{
                width:180,
                cellClassName:classes.centerCell,
                headerClassName:classes.centerCell
              }
          }],
          ["Profile Completion", "",{
            type: "render",
            render: (param) => (
                <CustomizedProgressBars  completePercent={50}/>
            ),  
            options:{
              width:180
            }
          }]
        ]}

        actions={[
          [
            "Actions",
            [
              [["Edit", [<img  alt="" src={editIcon} />, <img  alt="" src={editDisableIcon} />], 
                (c) =>true], 
              handleRequest],              
            ],
            {
              width: 110,
              cellClassName: classes.centerCell,
              headerClassName: classes.centerCell,
            }
          ]
        ]}
        options={{
          // statusEmpty: (allContracts.length === 0)
        }}
      />
       <HospitalProfileUpdate 
        isCreate={true}
        open={open}
        setOpen={setOpen}
        handleRequest ={()=>{}}
        hospitalProfile ={selectedProfile}
      />
     
      </div>
    </>
  );
}