import axios from 'axios';
import { HospitalRole, VendorRole } from "../../services/daml-modules1";
import { HospitalTemplateModel } from "../../models/HospitalTemplate";
import { validateInvoice, getInvoice } from "../../models/Invoice";
import { jsonToERP } from "../../services/util";
import { loggerPush, LOGGER_INFO, LOGGER_ERROR } from '../../services/logger';
import { IS_USE_ERP, NODE_SERVER_ENDPOINT, NODE_SERVER_AUTH_NAME,
  NODE_SERVER_AUTH_PASS, INDEX_ERP } from '../../config';



/**
 * send Invoice to ERP
 * exercise VInvSentERP choice of VendorRole
 * @param {Object} ledger 
 * @param {String} cid 
 * @param {Object} payload
 * @param {String} roleCid 
 */
export const setERPStatus = async ({
  ledger, cid = ' ', payload = ' ', roleCid,
}) => {

  let res = await ledger.exercise(VendorRole.VInvSentERP, roleCid, {
    invcid: cid,
    new_sent_erp: true,
  });
  console.log("[setERPStatus]", res);


  // let response = await ledger.exercise(VendorRole.VInvUpdateDetail, roleCid, {
  //   newinvoicedate: Date ,
  //   newinvoiceamount: Decimal
  // });
  // console.log("[setERPUpdatedate and payment]", response);

};


export const setUpdateInv = async ({
  ledger, cid = ' ', roleCid, newinvoicedate, newinvoiceamount,userlistPayload,hospital
}) => {

  const email = userlistPayload?.email;
  const username = userlistPayload?.name;
  
  try {
    console.log("running ");
    let res = await ledger.exercise(VendorRole.VInvUpdateDetail, roleCid, {
      invcid : cid,
      hospital,
      newinvoicedate,
      newinvoiceamount,
      useremail: email,
      username: username,
    });
    console.log("[setUpdateInv and payment]", res);

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'Invoi: VInvUpdateDetail', response: res });

  }
  catch (e) {
    console.log("[UpdateInvoice]", e);
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Invoi: VInvUpdateDetail', payload: e });
  }

  // return undefined ;
};





const getDataInv = (axiospayload, payload) => {
  return {
    "InvoiceNumber": axiospayload.invoicedata.invoiceNumber,
    "InvoiceDate": axiospayload.invoicedata.invoiceDate,
    "TotalAmount": axiospayload.invoicedata.invoiceAmount,
    "TaxAmount": "0.0",
    "Status": "Initiated"
  };
};

const getDataInvDetail = (axiospayload, payload, i) => {
  const invdproduct = { ...payload.invoicedetail[i].invdproduct };
  return {
    "ItemNumber": (i + 1),
    "InvoiceNumber": axiospayload.invoicedata.invoiceNumber,
    "ReferenceNumber": payload.invoicedetail[i].invdorderitem,
    "Manufacturer": axiospayload.invoicedata.supplier,
    "Description": axiospayload.invoicelines[i].lineDescription,
    "Expiration": axiospayload.invoicedata.invoiceDate,
    "Quantity": payload.invoicedetail[i].invdproductquantity,
    "Price": payload.invoicedetail[i].invdproductprice,
    "LotCode": invdproduct.seplotcode,
    "ProductType": invdproduct.sepproducttype,
    "Udi": invdproduct.sepudi,
    "Ownership": invdproduct.sepownership,
    "ProductSide": invdproduct.sepproductside
  };
};

/**
 * 
 * @param {*} cid 
 * @param {*} payload 
 * @param {*} queryHospitalTemplate 
 * @param {*} vendor 
 * @param {*} invoicedata 
 * @param {*} setInvDatalist 
 * @param {*} setConfirmDialogOpen 
 */
export const SendtoERP = ({
  cid = ' ', payload = ' ', queryHospitalTemplate, vendor, invoicedata, setInvDatalist, setConfirmDialogOpen,
}) => {

  const curContractId = cid;
  console.log("curContract id = " + curContractId);
  console.log("payload from record: " + JSON.stringify(payload));


  let hospitalTemplate = { ...HospitalTemplateModel };
  if (queryHospitalTemplate.contracts && queryHospitalTemplate.contracts.length) {
    hospitalTemplate = queryHospitalTemplate.contracts[0].payload;
  }


  const axiosindex1 = {
    index:
    {
      ...hospitalTemplate.api_index1,
      _index: INDEX_ERP.Inv_H,
      _id: curContractId
    }
  }

  const axiosinvoice = {
    ...hospitalTemplate.api_invoice,
    invoiceNumber: payload.invoicedata.invinvoicenumber,
    invoiceAmount: payload.invoicedata.invinvoicetotalamount,
    invoiceDate: payload.invoicedata.invinvoicedate,
    supplier: vendor,
  }

  const axiosindex2 =
  {
    index:
    {
      ...hospitalTemplate.api_index2,
      _index: INDEX_ERP.InvAttach_H,
      _id: curContractId
    }
  }

  const axiosinvoiceattachment = {
    ...hospitalTemplate.api_invoice_attachment[0],
  };

  const axiosindex3 =
  {
    index:
    {
      ...hospitalTemplate.api_index3,
      _index: INDEX_ERP.InvInstall_H,
      _id: curContractId
    }
  }

  const axiosinvoiceinstallment = {
    ...hospitalTemplate.api_invoice_installments[0],
  };

  const axiosindex4 =
  {
    index:
    {
      ...hospitalTemplate.api_index4,
      _index: INDEX_ERP.InvLine_H,
      _id: curContractId
    }
  }

  const axiosinvoicelines = payload.invoicedetail.map(invd => {
    return {
      ...hospitalTemplate.api_invoice_lines[0],
      lineAmount: invd.invdtotalcost,
      lineDescription: invd.invddescription
    };
  });



  // const axiospayload = JSON.stringify(axiosindex1) + "\n" + JSON.stringify(axiospayload1) + "\n" + JSON.stringify(axiosindex2) + "\n" + JSON.stringify(axiospayloadline) + "\n";
  const axiospayload = {
    index1: axiosindex1,
    invoice: axiosinvoice,
    index2: axiosindex2,
    attachment: axiosinvoiceattachment,
    index3: axiosindex3,
    installment: axiosinvoiceinstallment,
    index4: axiosindex4,
    invoicelines: axiosinvoicelines,
  };

  console.log("axiospayload  : ", JSON.stringify(axiospayload));

  let uploadUrl = null, uploadData = null;

  // check if upload to elastic search or node server
  if (IS_USE_ERP) {
    uploadUrl = NODE_SERVER_ENDPOINT;
    uploadData = jsonToERP(getDataInv(axiospayload, payload), INDEX_ERP.Iv_H, `${axiospayload.invoicedata.invoiceNumber}`)
      + axiospayload.invoicelines.map((p, i) => jsonToERP(getDataInvDetail(axiospayload, payload, i), INDEX_ERP.IvD_H, `${axiospayload.invoicedata.invoiceNumber}${(i + 1)}`)).join('');
  }
  else {
    const jsonFileName = invoicedata.invordernumber;
    uploadUrl = NODE_SERVER_ENDPOINT + 'api/message/invoice/' + ((jsonFileName !== "") ? jsonFileName + ".json" : "");
    uploadData = axiospayload;
  }

  console.log("uploadData  : ", uploadData);

  // upload
  axios.post(
    uploadUrl, uploadData, {
    headers: {
      'Content-type': 'application/json'
    },
    auth: { username: NODE_SERVER_AUTH_NAME, password: NODE_SERVER_AUTH_PASS }
  })
    .then((result) => {
      console.log('result _bulk', result);
      if (result.status === 200) {
        console.log("success", result.data);

        // It also needs ledger, roleCid
        setERPStatus({ cid, payload });

        setInvDatalist(result.data.items);
        setConfirmDialogOpen(true);
      }
    })
    .catch((err) => {
      console.log('err', err);
    });
};


/**
 * send Invoice to Hospital
 * exercise VInvSetHospital choice of VendorRole
 * @param {Object} ledger 
 * @param {String} cid 
 * @param {Object} payload
 * @param {String} roleCid 
 */
export const sendInvoice = async ({
  ledger, cid = ' ', payload = ' ', roleCid, userlistPayload
}) => {
  const email = userlistPayload?.email;
  const username = userlistPayload?.name;
  try{
    console.log("start Ledger SetInvoiceHospital");

    let res = await ledger.exercise(VendorRole.VInvSetHospital, roleCid, {
      invcid: cid,
      hospital: payload?.hospital,
      useremail: email,
      username: username,
    });

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'Invoi: VInvSetHospital', response: res });

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Invoi: VInvSetHospital', payload: e });
    console.log(e); 
  }
};

/**
 * archive Invoice
 * exercise VInvArchive choice of VendorRole
 * @param {Object} ledger 
 * @param {String} cid 
 * @param {Object} payload
 * @param {String} roleCid 
 */
export const ArchiveInvoice = async ({
  ledger, cid = ' ', payload = ' ', roleCid,
}) => {
  try{
    console.log("start Ledger Archive");

    let res = await ledger.exercise(VendorRole.VInvArchive, roleCid, {
      invcid: cid,
    });

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'Invoi: VInvArchive', response: res });

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Invoi: VInvArchive', payload: e });
    console.log(e); 
  }
};

/**
 * create Invoice
 * exercise CreateInvoice choice of VendorRole
 * @param {Object} ledger 
 * @param {String} ContractId 
 * @param {Object} invdata
 * @param {String} hospital 
 * @param {Function} setInvoicedata 
 * @param {String} roleCid 
 * @returns 
 */
export const createinvoice = async function ({
  invdata, ledger, ContractId, hospital, setInvoicedata, roleCid,
}) {
  try{
    // console.log("hospital : " + hospital);

    //console.log("returned contract id from usequery" + contract);

    console.log("invoice data : ", invdata);

    if (!validateInvoice(invdata)) return;

    setInvoicedata(invdata);

    const invoice = getInvoice(invdata);
    const invoicedetail = [];


    // const ContractId = contract;
    console.log("ContractId :" + ContractId);

    console.log("start Ledger Create");


    /**
     * if you want to use ledger.exercise, this should be true.
     * if you want to use ledger.create, this should be false.
     */
    if (roleCid) {

      let res = await ledger.exercise(VendorRole.CreateInvoice, roleCid, {
        hospital,
        invoice,
        invoicedetail,
      });
      console.log("[createinvoice]", res);

      // log the response to loggly
      loggerPush({ type: LOGGER_INFO, action: 'Invoi: CreateInvoice', response: res });
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Invoi: CreateInvoice', payload: e });
    console.log(e); 
  }
};

/**
 * archive InvoiceHospital
 * exercise HInvHArchive choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} cid 
 * @param {Object} payload
 * @param {String} roleCid
 */
export const ArchiveInvoiceHospital = async ({
  ledger, cid = ' ', payload = ' ', roleCid,
}) => {
  try{
    console.log("start Ledger Archive");

    let res = await ledger.exercise(HospitalRole.HInvHArchive, roleCid, {
      invhcid: cid,
    });

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'Invoi: HInvHArchive', response: res });

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Invoi: HInvHArchive', payload: e });
    console.log(e); 
  }
};


/**
 * Payment InvoiceHospital
 * exercise SendPayment choice of HospitalRole
 * @param {Object} ledger 
 * @param {Object} payload
 * @param {String} roleCid
 */
export const PaymentInvoiceHospital = async ({
  ledger, cid, vendor, roleCid, cId, paymentdate, paymentInitDate, paytype
}) => {
  try{
    let res = await ledger.exercise(HospitalRole.SendPayment, roleCid, {
      vendor,
      paymentdate: paymentdate,
      paymentinitiationdate: paymentInitDate,
      invhcid: cId,
      paytype
    });

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'Invoi: SendPayment', response: res });

    return res;
  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Invoi: SendPayment', payload: e });
    console.log(e); 
  }
};


/**
 * Payment InvoiceHospital Status
 * exercise UpdateStatusPaymentInvoiceHospital choice of HospitalRole
 * @param {Object} ledger 
 * @param {Object} cid
 * @param {String} roleCid
 */
export const UpdateInvoiceHospitalPaymentStatus = async ({
  ledger, cid, roleCid,
}) => {
  try {
    let res = await ledger.exercise(HospitalRole.UpdateStatusPaymentInvoiceHospital, roleCid, {
      invh: cid
    });
    
    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'Invoi: UpdateStatusPaymentInvoiceHospital', response: res });

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Invoi: UpdateStatusPaymentInvoiceHospital', payload: e });
    console.log(e); 
  }
};



/**
 * Payment InvoiceHospital PayemntDetails
 * exercise UpdateStatusPaymentInvoiceHospital choice of HospitalRole
 * @param {Object} ledger 
 * @param {Object} cid
 * @param {String} roleCid
 */
 export const SaveInvoiceHospitalPaymentDetails = async ({
  ledger, cid, roleCid, paymentdate, paymenttype
}) => {
  try {
    let res = await ledger.exercise(HospitalRole.SavePaymentDetails, roleCid, {
      ihcid: cid,
      paymenttype,
      paymentdate
    });
    
    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'Invoi: SavePaymentDetailsInvoiceHospital', response: res });

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Invoi: SavePaymentDetailsInvoiceHospital', payload: e });
    console.log(e); 
  }
};
