import React, { useState, useEffect } from "react";
import { useLedger, useParty, useStreamQueries } from "@daml/react";
import { Grid, Typography, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { RestockInventory, SurgicalEvent } from "../../services/daml-modules1";
import ScanInventoryHospitalContracts from "./inventoryscanhospitalcontracts";
import { dispatch, getState } from "../../store";
import { inventorySearch } from "../../store/actions/generalActions";
import CreateInventory from "../inventoryaccount/create-inventory";
import { handleAddNewInventory } from "../inventoryaccount/inventorylisthospital";
import { findMatchedInventoryData } from "../../models/InventoryData";
import { useDamlState } from "../../context/DamlContext";
import useStyles from "./styles";


/**
 * Restock Inventory Page in SE for Hospital
 * @returns 
 */
export default function ScanInventoryHospital() {

  const classes = useStyles();

  const ledger = useLedger();
  const hospital = useParty();

  const { contracts } = useStreamQueries(RestockInventory, () => [{ hospital }]);
  const { contracts: contractsSurgical } = useStreamQueries(SurgicalEvent, () => [{ hospital }]);
  const { roleCid ,userlistPayload} = useDamlState();

  const [searchParams, setSearchParams] = useState({});
  const [searchParamsSurgical, setSearchParamsSurgical] = useState({});
  const [loading, setLoading] = useState(true);
  // Confirm Inventory
  const [openModal, setOpenModal] = useState(false);
  const [inventoryDataList, setInventoryDataList] = useState([]);


  // click Confirm button
  const openConfirmInventory = (e) => {
    setInventoryDataList([]);
    setOpenModal(true);
  };

  // handle to add more Product in Inventory
  const handleAddMoreProduct = async (product) => {
    // Nothing
  };

  // handle to add new Inventory
  const addProductItem = async (openAlert=null, isAccount=false) => {
    if (isAccount) {
      // 1. To add new Account Inventory

      // add new Account Inventory
      await handleAddNewInventory({
        inventoryDataList: inventoryDataList.map(invd => ({...invd, iproductstatus: 'Received'})), 
        ledger, 
        roleCid, 
        setOpenModal, 
        openAlert,
        isRestockNotAccount: false,
      });
    }
    else {
      // 2. To add new Restock Inventory
      const matchedInvds = findoutRestockInventory({inventoryDataList, contracts});

      // add new Restock Inventory
      await handleAddNewInventory({
        inventoryDataList: matchedInvds, 
        ledger, 
        roleCid, 
        setOpenModal, 
        openAlert,
      });

    }
  };


  /**
   * 
   * constant to search PO
   * if isSearchablePO is true, it can search PO
   * if isSearchablePO is false, it can't search PO
   */
  const isSearchablePO = false;
  const [isHistory, setHistory] = useState(false);
  const [searchInventory, setSearchInventory] = useState({
    seid: '',
    mrn: '',
    eventdate: '',
    patientfirstname: '',
    patientlastname: '',
    popurchaseorderid: '',
    poinventorystatus: '',
    povendorid: '',
    ...getState().inventory,
  });
  const handleChangeSearchValue = (val, key) => {
    if (!isSearchablePO && [
      'popurchaseorderid', 'poinventorystatus', 'povendorid',
    ].includes(key)) return;
    setSearchInventory({ ...searchInventory, [key]: val });
    dispatch(inventorySearch({ key, value: val }));
  };


  useEffect(() => {
    setLoading(true);

    let temp = {}, tempSE = {};

    temp.hospital = hospital;
    tempSE.surgicaleventdata = {};
    if (searchInventory.seid !== "") tempSE.surgicaleventdata.seid = searchInventory.seid;
    if (searchInventory.mrn !== "") tempSE.surgicaleventdata.mrn = searchInventory.mrn;
    if (searchInventory.eventdate !== "") tempSE.surgicaleventdata.eventdate = searchInventory.eventdate;
    if (searchInventory.patientfirstname !== "") tempSE.surgicaleventdata.patientfirstname = searchInventory.patientfirstname;
    if (searchInventory.patientlastname !== "") tempSE.surgicaleventdata.patientlastname = searchInventory.patientlastname;

    if (searchInventory.popurchaseorderid !== "") temp.purchaseorderdata = {
      ...temp.purchaseorderdata, popurchaseorderid: searchInventory.popurchaseorderid
    };
    if (searchInventory.poinventorystatus !== "") temp.purchaseorderdata = {
      ...temp.purchaseorderdata, poinventorystatus: searchInventory.poinventorystatus
    };
    if (searchInventory.povendorid !== "") temp.purchaseorderdata = {
      ...temp.purchaseorderdata, povendorid: searchInventory.povendorid
    };

    tempSE.hospital = temp.hospital;

    const delay = 0;
    if (delay > 0) setTimeout(() => {
      setSearchParams(temp);
      setSearchParamsSurgical(tempSE);
    }, delay);
    else {
      setSearchParams(temp);
      setSearchParamsSurgical(tempSE);
    }
  }, [searchInventory, hospital]);

  useEffect(() => {
    setLoading(false);
  }, [searchParams]);


  return (
    <>

      <Grid>

        <Typography variant="h4" className={classes.pageTitle}>Restock Inventory by SEID</Typography>

        <Button
          size="small"
          className={classes.newSetupButton}
          variant="contained"
          onClick={openConfirmInventory}
        >
          <span className="desktop">Confirm Inventory</span>
          <span className="mobile"><SaveIcon /></span>

        </Button>
        <FormControlLabel
          className={classes.newCheckbox}
          control={
            <Checkbox
              color="primary"
              checked={isHistory}
              onChange={(e) => setHistory(e.target.checked)}
            />
          }
          label="Include Archive Contracts"
        />

        <ScanInventoryHospitalContracts
          searchParams={searchParams}
          searchParamsSurgical={searchParamsSurgical}
          handleChangeSearchValue={handleChangeSearchValue}
          allContracts={contracts}
          isIncludeHistory={isHistory}
          allContractsSurgical={contractsSurgical}
          ledger={ledger}
          hospital={hospital}
          roleCid={roleCid}
          userlistPayload={userlistPayload}
          loading={loading}
        />

        <CreateInventory
          openModal={openModal}
          setOpenModal={setOpenModal}
          addProductItem={addProductItem}
          handleAddMoreProduct={handleAddMoreProduct}
          hospital={hospital}
          title={'Confirm Inventory'}
          inventoryDataList={inventoryDataList}
          setInventoryDataList={setInventoryDataList}
          btnCreate="Confirm"
        />

      </Grid>

    </>
  );
}

/**
 * Find out the matched Restock Inventory
 * in Hospital Side
 * by the order Shipped -> Opens -> BackOrder
 * @param {Array} inventoryDataList 
 * @param {Array} contracts 
 * @param {Boolean} setVirtualMatch 
 * @param {Array} matchedStatus e.g. 'Shipped', 'Opens', 'BackOrder'
 * @returns 
 */
export const findoutRestockInventory = ({
  inventoryDataList, contracts, setVirtualMatch=true, matchedStatus=['Shipped', 'Opens', 'BackOrder'],
}) => {
  // find matched Restock Inventory to get PO Number
  if (!matchedStatus.length) return [];

  const allInventoryData = contracts.reduce((total, c) => total.concat(c.payload.inventorydata), []);
  
  let targetInventoryDataList = [], foundInventoryDataList = [];
  for (let i in matchedStatus) {
    const matchedProductStatus = matchedStatus[i];
    targetInventoryDataList[i] = findMatchedInventoryData(allInventoryData, 
      inventoryDataList.map((invd, index) => (!foundInventoryDataList[index]) ? {
        ...invd, iproductstatus: matchedProductStatus
      } : null), 
      {isPONumber: true, setVirtualMatch});
    targetInventoryDataList[i].forEach((invd, index) => {
      foundInventoryDataList[index] = !!foundInventoryDataList[index] && !!invd;
    });
  }
  
  const matchedInvds = inventoryDataList.reduce((matched, invd, index) => {
    let found = false;
    for (let i in targetInventoryDataList) {
      const targetInvds = targetInventoryDataList[i];
      if (targetInvds[index] && !found) {
        matched.push({
          ...invd,
          id: targetInvds[index].id,
          ipurchaseordernumber: targetInvds[index].ipurchaseordernumber,
          index: targetInvds[index].index,
          iproductstatus: targetInvds[index].iproductstatus,
          iproduct: {
            ...invd.iproduct,
            id: targetInvds[index].iproduct.id,
            sepproductprice: targetInvds[index].iproduct.sepproductprice,
          },
        });
        found = true;
        break;
      }
    }
    if (!found) {
      console.log("[findoutRestockInventory] Not found the matched Restock Inventory", invd);
    }
    return matched;
  }, []);

  return matchedInvds;
};
