import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  fullPaper: {
    width: '100%'
  },
  actionsContainer: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(5),
    textAlign: 'right'
  },
  resetContainer: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}));
