import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import FilterBox from "./FilterBox";
import useStyles from "./styles";


/**
 * Filters Panel
 * @param {Array} keys 
 * @param {Function} setFilters 
 * @returns 
 */
const FiltersPanel = ({
  keys=[], setFilters=()=>{},
}) => {

  const classes = useStyles();

  const [filters, setFilterParams] = useState({});
  // console.log("[FiltersPanel] filters", filters);


  const updateFilters = (key, val, type) => {
    // console.log("[FiltersPanel] updateFilters", key, val);
    if (typeof setFilters === 'function') {
      const newKey = `${type}.${key}`;
      if (JSON.stringify(filters[newKey]) !== JSON.stringify(val)) {
        const temp = {...filters, [newKey]: val};
        /* let newtemp = {};
        Object.keys(temp).forEach(k => {
          const v = temp[k];
          if (typeof v === 'object' && v.length === 0) {}
          else newtemp[k] = v;
        }); */
        setFilters(temp);
        setFilterParams(temp);
      }
    }
  };


  return (
    <Grid container className={classes.panel}>
    {
      (keys && keys.length > 0) && 
      keys.filter(key => !!key.name)
      .map((key, i) => (
        <Grid item key={i} style={(key.disabled ? {display: 'none'} : {})}>
          <FilterBox 
            {...key} 
            setFilter={updateFilters}
          />
        </Grid>
      ))
    }
    </Grid>
  );
};

export default FiltersPanel;