import React from "react";
import classNames from "classnames";
import { Table, TableBody, TableRow, TableCell, Grid, Typography, TextareaAutosize } from "@material-ui/core";
import useStyles from "../showlist/styles";
import AuditDetails from "./auditDetails";
import moment from 'moment-timezone';
import { CustomTextField } from "../../components/Inputs";
import { useTranslation } from 'react-i18next';


/**
 * @param {Object} searchParams 
 * @param {Object} options 
 * @param {Object} invData 
 * @returns 
 */
export default function AuditDetailsReturn({
    invData,
}) {

    const { t } = useTranslation();
    const returnData = invData.logEvents.value.accInventoryData.inventorydata[0];

    const classes = useStyles();
    const time = moment(invData.created_at).tz(moment.tz.guess()).format('LT z');
    return (
        <Grid>
            <Grid container direction="row" className={classNames(classes.overviewAuditLog, classes.auditLogPanel)}>
                <Grid item sm={5} className={classes.overviewAuditLogPO} >
                    {(invData?.event === 'ReturnInv') ?
                        <Table className={classes.auditLogForm}>

                            <TableBody>

                                <TableRow>
                                    <TableCell className="new-subtitle" style={{ paddingTop: 0 }}>Return Request</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="label">Return Type</TableCell>
                                    <TableCell className="value">
                                        <CustomTextField
                                            className={classes.auditLogTextField}
                                            value={returnData.ireturncomments[returnData.ireturncomments.length - 1].returntype}
                                            width={110}
                                            disabled={true}
                                            height={500}

                                        />

                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="label">Return Description</TableCell>
                                    <TableCell className="value">
                                        <TextareaAutosize
                                            minRows={3}
                                            value={returnData.ireturncomments[returnData.ireturncomments.length - 1].note}
                                            className={classes.customTextArea}
                                            disabled={true}
                                        />
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className="new-subtitle" style={{ paddingTop: 0 }}>Shipping Details</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="label">Shipping Date</TableCell>
                                    <TableCell className="value">
                                        <CustomTextField
                                            className={classes.auditLogTextField}
                                            value={returnData.ishipmentdate}
                                            width={110}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="label">Courier Company</TableCell>
                                    <TableCell className="value">
                                        <CustomTextField
                                            className={classes.auditLogTextField}
                                            value={returnData.icourier}
                                            width={110}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="label">Tracked Number</TableCell>
                                    <TableCell className="value">
                                        <CustomTextField
                                            className={classes.auditLogTextField}
                                            value={returnData.itrackingstatus}
                                            width={110}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                        :

                        <Table className={classes.auditLogForm}>

                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={2} className="new-subtitle" style={{ paddingTop: 0 }}>PO Details</TableCell>
                                </TableRow>
                                <>
                                    {(invData?.event === "InventoryEdit") ?
                                        <>
                                            <TableRow>
                                                <TableCell className="label">PO Number</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className="value">{returnData?.ipurchaseordernumber}</TableCell>
                                            </TableRow>
                                        </>
                                        :
                                        <>
                                            <TableRow>
                                                <TableCell className="label">PO Number</TableCell>
                                                <TableCell className="label">PO Type</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className="value">{returnData?.ipurchaseordernumber}</TableCell>
                                                <TableCell className="value"></TableCell>
                                            </TableRow>
                                        </>
                                    }
                                    <TableRow>
                                        <TableCell className="label">Ownership</TableCell>
                                        <TableCell className="label">Distributor</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="value">{returnData.iproduct.sepownership}</TableCell>
                                        <TableCell className="value">{returnData.iproduct.sepdistributor}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="label">PO Date</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="value">{returnData.ipurchaseorderdate}</TableCell>
                                    </TableRow>
                                </>
                            </TableBody>
                        </Table>

                    }

                    <AuditDetails
                        poData={invData}
                        time={time}
                    />

                </Grid>
                <Grid item sm={7}>

                    <Grid item sm={6} >

                        <Typography>Product Details</Typography>
                        <Table className={classes.auditLogDetails}>
                            <TableBody>
                                <TableRow>
                                    <TableCell className="label">UDI :</TableCell>
                                    <TableCell className="value">{returnData?.iproduct.sepudi}</TableCell>
                                    <TableCell className="label">Waste:</TableCell>
                                    <TableCell className="value">{returnData?.iproduct.sepproductwaste}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="label">Ref :</TableCell>
                                    <TableCell className="value">{returnData?.iproduct.sepreferencenumber}</TableCell>
                                    <TableCell className="label">Ownership :</TableCell>
                                    <TableCell className="value">{returnData?.iproduct.sepownership}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="label">Manufacture :</TableCell>
                                    <TableCell className="value">{returnData?.iproduct.sepproductmanufacturer}</TableCell>
                                    <TableCell className="label">Product Side :</TableCell>
                                    <TableCell className="value">{returnData?.iproduct.sepproductside}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="label">Distributor :</TableCell>
                                    <TableCell className="value">{returnData?.iproduct.sepdistributor}</TableCell>
                                    <TableCell className="label">Price :</TableCell>
                                    <TableCell className="value">{returnData?.iproduct.sepproductprice}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="label">Name :</TableCell>
                                    <TableCell className={classes.textStyle}>{returnData?.iproduct.sepproductname}</TableCell>
                                    <TableCell className="label">UoM :</TableCell>
                                    <TableCell className="value">{returnData?.iproduct.sepuom}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="label">Description :</TableCell>
                                    <TableCell className={classes.textStyle}>{returnData?.iproduct.sepproductdescription}</TableCell>
                                    <TableCell className="label">Quantity :</TableCell>
                                    <TableCell className="value">{returnData?.iquantity}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="label">Lot/SN :</TableCell>
                                    <TableCell className="value">{returnData?.iproduct.seplotcode}</TableCell>
                                    <TableCell className="label">{t('main.quantityonhand.lable')}:</TableCell>
                                    <TableCell className="value">{returnData?.iquantity}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="label">Expiration :</TableCell>
                                    <TableCell className="value">{returnData?.iproduct.sepproductexpiration}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="label">Product Type :</TableCell>
                                    <TableCell className="value">{returnData?.iproduct.sepproducttype}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>

                </Grid>
            </Grid>

        </Grid >

    );
}
