import React, { useState } from "react";
import { useParty, useStreamQueries } from "@daml/react";
import { Grid, FormControlLabel, Checkbox, Typography } from "@material-ui/core";
import { PostProcedureHospital } from "../../services/daml-modules1";
import DetailModal from "../../components/Modals/DetailModal";
import { useDamlState } from "../../context/DamlContext";
import PostProcedureListContracts from "./postprocedurelistcontracts1";
import useStyles from "./styles";
import classNames from "classnames";
import useStyle from "../surgicalevent/styles";


/**
 * PPF page in Hospital
 * @returns 
 */
export default function PostProcedureList() {

  const classes = useStyles();
  const classes1 = useStyle();
  const hospital = useParty();
  const { rolePayload } = useDamlState();

  const { contracts, loading } = useStreamQueries(PostProcedureHospital, () => [{ hospital }]);

  const [detailContract, setDetailContract] = useState({});
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [isHistory, setHistory] = useState(false);


  return (
    <>
      <Grid>

        <Typography variant="h4" className={classNames(classes.pageTitle, classes1.newPageTitle)}>Post Procedure Form</Typography>

        <FormControlLabel
          className={classes.newCheckbox}
          control={
            <Checkbox
              color="primary"
              checked={isHistory}
              onChange={(e) => setHistory(e.target.checked)}
            />
          }
          label="Include Archive Contracts"
        />

        <PostProcedureListContracts
          isDetailModalOrPage={false}
          setDetailModalOpen={setDetailModalOpen}
          setDetailContract={setDetailContract}
          isIncludeHistory={isHistory}
          isAutoVendor={rolePayload && rolePayload.admindetails ? rolePayload.admindetails.auto_po_vendor : false}
          allContracts={contracts}
          loading={loading}
        />
      </Grid>

      <DetailModal
        isButtonVisible={false}
        contract={detailContract}
        modalOpenController={detailModalOpen}
        cancelHandler={() => setDetailModalOpen(false)}
      />

    </>
  );
}