import React, { useCallback } from "react";
import classNames from "classnames";
import { Table, TableBody, TableRow, TableCell, Grid, Box, Typography, IconButton } from "@material-ui/core";
import { CustomTextField, DateInput } from "../../components/Inputs";
import FileUploadDropzone from "../../components/FileUpload/FileUploadDropzone";
// import { uploadFileCreatePO } from "../../services/axios/api-node_server";
import useContract from "../../hooks/useContract";
import PdfLogo from "../../icons/PdfLogo.svg";
import pdfSmallIcon from "../../icons/pdf-small.svg";
import exportIcon from "../../icons/new-export-icon.svg";
import deleteIcon from "../../icons/new-delete4.svg";
import useStyles from "../surgicalevent/styles";
import { InputDistributor } from "../surgicalevent/edit-product1";
import useStyles2 from "../showlist/styles";
import useStyles1 from "./styles";



// preview icon of uploader
const handlePreviewIcon = (fileObject, classes) => {
  const { type, name } = fileObject.file;

  const handleExport = () => {
    console.log("[handlePreviewIcon] handleExport", type, name);
  };

  const handleDelete = () => {
    console.log("[handlePreviewIcon] handleDelete", type, name);
  };

  return (
    <Box className="preview-item" onClick={(e) => e.stopPropagation()}>
      <img src={pdfSmallIcon} alt="" />
      <Typography>{name}</Typography>
      <IconButton onClick={handleExport}><img alt="" src={exportIcon} /></IconButton>
      <IconButton onClick={handleDelete}><img alt="" src={deleteIcon} /></IconButton>
    </Box>
  );
};

/**
 * Contract Overview
 * @param {Object} options 
 * @param {Boolean} required 
 * @returns 
 */
export default function ContractOverview ({
  options={}, 
}) {

  options = {
    isCreate: false,
    ...options,
  };
  
  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();

  const { detail, detailErrors, setDetail } = useContract();


  const handleChange = useCallback((value, key) => {
    setDetail({
      [key]: value,
    });
  }, [setDetail]);

  // create Contract by uploading csv
  const handleUpload = (file, callback) => {
    // setContract({
    //   file,
    // });
  };


  return (
    <Grid 
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      <Grid item className={classes1.overviewForm}>
        <Table className={classes.newSEForm}>
          <TableBody>
            <TableRow>
              <TableCell style={{width: '660px'}}>
                <InputDistributor
                  label="Distributor:"
                  distributor={detail.vendor}
                  setDistributor={(val) => handleChange(val, 'vendor')}
                  placeholder="Select"
                  warning={detailErrors.vendor}
                  width={194}
                  disabled={!options.isCreate}
                  options={{
                    labelLeft: true,
                    labelWidth: 320,
                  }}
                  className={classes1.formField}
                />
              </TableCell>
              <TableCell>
                <Typography className="label">Contact:</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <DateInput
                  label="Contract Date Executed:"
                  value={detail.contractdateexecuted || ""}
                  onChange={(val) => handleChange(val, 'contractdateexecuted')}
                  warning={detailErrors.contractdateexecuted}
                  width={157}
                  options={{
                    labelLeft: true,
                    labelWidth: 320,
                  }}
                  className={classes1.formField}
                />
              </TableCell>
              <TableCell>
                <CustomTextField
                  label="Name"
                  value={detail.contactname || ""}
                  onChange={(val) => handleChange(val, 'contactname')}
                  warning={detailErrors.contactname}
                  width={235}
                  options={{
                    labelLeft: true,
                    labelWidth: 60,
                  }}
                  className={classes1.formField}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <DateInput
                  label="Contract Expiration Date:"
                  value={detail.contractexpirationdate || ""}
                  onChange={(val) => handleChange(val, 'contractexpirationdate')}
                  warning={detailErrors.contractexpirationdate}
                  width={157}
                  options={{
                    labelLeft: true,
                    labelWidth: 320,
                  }}
                  className={classes1.formField}
                />
              </TableCell>
              <TableCell>
                <CustomTextField
                  label="Email"
                  value={detail.contactemail || ""}
                  onChange={(val) => handleChange(val, 'contactemail')}
                  warning={detailErrors.contactemail}
                  width={235}
                  options={{
                    labelLeft: true,
                    labelWidth: 60,
                  }}
                  className={classes1.formField}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <CustomTextField
                  type="number"
                  label="Current total dollar spend on that contract:"
                  value={detail.contracttotal || ""}
                  onChange={(val) => handleChange(val, 'contracttotal')}
                  warning={detailErrors.contracttotal}
                  width={235}
                  options={{
                    labelLeft: true,
                    labelWidth: 320,
                    disabled: !options.isCreate,
                  }}
                  className={classes1.formField}
                />
              </TableCell>
              <TableCell>
                <CustomTextField
                  label="Title"
                  value={detail.contacttitle || ""}
                  onChange={(val) => handleChange(val, 'contacttitle')}
                  warning={detailErrors.contacttitle}
                  width={235}
                  options={{
                    labelLeft: true,
                    labelWidth: 60,
                  }}
                  className={classes1.formField}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <CustomTextField
                  type="number"
                  label="Dollars spent in the last 90 days of that Contract:"
                  value={detail.contract90days || ""}
                  onChange={(val) => handleChange(val, 'contract90days')}
                  warning={detailErrors.contract90days}
                  width={235}
                  options={{
                    labelLeft: true,
                    labelWidth: 320,
                    disabled: !options.isCreate,
                  }}
                  className={classes1.formField}
                />
              </TableCell>
              <TableCell>
                <CustomTextField
                  label="Phone"
                  value={detail.contactphone || ""}
                  onChange={(val) => handleChange(val, 'contactphone')}
                  warning={detailErrors.contactphone}
                  width={235}
                  options={{
                    labelLeft: true,
                    labelWidth: 60,
                  }}
                  className={classes1.formField}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <CustomTextField
                  label="Description:"
                  value={detail.description || ""}
                  onChange={(val) => handleChange(val, 'description')}
                  warning={detailErrors.description}
                  width={276}
                  options={{
                    labelLeft: true,
                    labelWidth: 320,
                    disabled: !options.isCreate,
                  }}
                  className={classes1.formField}
                />
              </TableCell>
              <TableCell>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <CustomTextField
                  label="Contract Number:"
                  value={detail.contractmanagementnumber || ""}
                  onChange={(val) => handleChange(val, 'contractmanagementnumber')}
                  warning={detailErrors.contractmanagementnumber}
                  width={276}
                  options={{
                    labelLeft: true,
                    labelWidth: 320,
                    disabled: !options.isCreate,
                  }}
                  className={classes1.formField}
                />
              </TableCell>
              <TableCell>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>

      <Grid item>
        <FileUploadDropzone 
          className={classes1.newUploadFile}
          title="Upload Contract"
          uploadIcon={(
            <>
              <img src={PdfLogo} alt='' className={classNames(classes2.pdfLogos, "newPdfLogos")} />
              <Typography className={classNames(classes2.sampleFilePreview, "black")}>
                Drop your PDF file here, or browse
              </Typography>
              <Typography className={classes2.sampleFilePreview}>Sample file.PDF</Typography>
            </>
          )}
          changeFile={handleUpload}
          isNonePadding={true}
          isEnableUpload={false}
          handlePreviewIcon={handlePreviewIcon}
        />
      </Grid>
    </Grid>
  );
}
