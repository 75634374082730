import React, { useState, useEffect } from "react";
import { useLedger, useParty, useStreamQueries } from "@daml/react";
import { Grid, FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import { PurchaseOrder, SurgicalEvent, PostProcedureHospital } from "../../services/daml-modules1";
import PurchaseOrderListContracts, { findTargetPOWithOwnership } from "./purchaseorderlistcontracts";
import { dispatch, getState } from "../../store";
import { purchaseOrderSearch } from "../../store/actions/generalActions";
import CreatePurchaseOrder from "./create-purchaseorder";
import { SurgicalEventModel } from "../../models/SurgicalEvent";
import { PoModel, createPurData } from "../../models/Po";
import { createPODetail } from "../../models/PODetail";
import { createpurchaseorder, addPODetailDatas, changeOwnershipPO, 
  removePODetailData, updatePODetailData, updatePOData } from "./handle";
import { useDamlState } from "../../context/DamlContext";
import useStyles from "./styles";


/**
 * SE Purchase Orders page in Hospital
 * @returns 
 */
export default function PurchaseOrderList() {

  const classes = useStyles();

  const ledger = useLedger();
  const hospital = useParty();
  
  const { contracts } = useStreamQueries(PurchaseOrder, () => [{ hospital }]);
  const { contracts: contractsSurgical } = useStreamQueries(SurgicalEvent, () => [{ hospital }]);
  const assetsPPH = useStreamQueries(PostProcedureHospital, () => [{hospital}]);

  const { role, party, userParty, roleCid, userlistPayload } = useDamlState();

  const [ContractId, setContractId] = useState('');
  const [payload, setPayload] = useState({});
  const [searchParams, setSearchParams] = useState({});
  const [searchHistoryParams, setSearchHistoryParams] = useState({});
  const [searchParamsSurgical, setSearchParamsSurgical] = useState({});
  const [searchHistoryParamsSurgical, setSearchHistoryParamsSurgical] = useState({});
  const [loading, setLoading] = useState(true);
  const [isHistory, setHistory] = useState(false);
  // CreatePurchaseOrder
  const [isCreate, setCreate] = useState(true);
  const [purchaseOrderData, setPurchaseOrderData] = useState({});
  const [purchaseOrderDetailData, setPurchaseOrderDetailData] = useState([]);
  const [surgicalEventData, setSurgicalEventData] = useState({});
  const [openModal, setOpenModal] = useState(false);


  // handler to Open Create Purchase Order
  const handleOpenCreatePO = ({
    poData = {}, poDetail = [], seData = {}, isCreate = true, contractId = '', payload = {},
  }) => {
    setPurchaseOrderData({ ...PoModel, ...poData });
    setPurchaseOrderDetailData(poDetail);
    setSurgicalEventData({ ...SurgicalEventModel, ...seData });
    setCreate(isCreate);
    setContractId(contractId);
    setPayload(payload);
    // open create PO modal
    setOpenModal(true);
  };


  // Click "Create" or "Save Update" in PO modal
  const addProductItem = async () => {
    
    await createUpdatePO({
      ledger, ContractId, contracts, hospital, roleCid, userlistPayload, isCreate,
      purchaseOrderData, purchaseOrderDetailData, surgicalEventData, setOpenModal,
      user: (userParty ? userParty : party), role, payload,
    });
  };

  // Click "Add More Product" in PO modal
  const handleAddMoreProduct = async (detail) => {
    // add PurchaseOrderDetaildata into PO
    console.log("[PurchaseOrderList] handleAddMoreProduct", detail, isCreate, ContractId);
    // DO Nothing
  };

  // Click Delete Icon in PO modal to remove PO Detail
  const handleDeleteProduct = async (podetail, podetailindex) => {
    return await removePODetailData({
      ledger, 
      contractId: ContractId, 
      setContractId,
      podetail,
      podetailindex,
      roleCid,
      user: (userParty ? userParty : party), 
      role, 
      payload,
    });
  };

  // Click "Update" in PO modal to update PO Detail
  const handleUpdateProduct = async (podetail, podetailindex) => {
    return await updatePODetailData({
      ledger, 
      contractId: ContractId, 
      setContractId,
      podetail,
      podetailindex,
      roleCid,
      user: (userParty ? userParty : party), 
      role, 
      payload,
    });
  };


  const createOptions = {
    handleOpenCreatePO,
    addProductItem,
    handleAddMoreProduct,
    roleCid: roleCid,
  };


  /**
   * constant to search PO
   * if isSearchablePO is true, it can search PO
   * if isSearchablePO is false, it can't search PO
   */
  const isSearchablePO = false;

  const [searchPO, setSearchPO] = useState({
    seid: '',
    mrn: '',
    eventdate: '',
    status: 'Complete', // default status of surgicaleventdata
    popurchaseorderid: '',
    podate: '',
    postatus: '',
    povendorid: '',
    ...getState().purchase,
  });
  const handleChangeSearchValue = (val, key) => {
    if (!isSearchablePO && [
      'popurchaseorderid', 'podate', 'postatus', 'povendorid',
    ].includes(key)) return;
    setSearchPO({ ...searchPO, [key]: val });
    dispatch(purchaseOrderSearch({ key, value: val }));
  };


  useEffect(() => {
    setLoading(true);

    let temp = {}, tempHistory = {}, tempSE = {}, tempHistorySE = {};

    temp.hospital = hospital;
    tempSE.surgicaleventdata = {};
    if (searchPO.seid !== "") tempSE.surgicaleventdata.seid = searchPO.seid;
    if (searchPO.mrn !== "") tempSE.surgicaleventdata.mrn = searchPO.mrn;
    if (searchPO.eventdate !== "") tempSE.surgicaleventdata.eventdate = searchPO.eventdate;
    if (searchPO.status !== "") tempSE.surgicaleventdata.status = searchPO.status;

    if (searchPO.popurchaseorderid !== "") temp.purchaseorderdata = {
      ...temp.purchaseorderdata, popurchaseorderid: searchPO.popurchaseorderid
    };
    if (searchPO.podate !== "") temp.purchaseorderdata = {
      ...temp.purchaseorderdata, podate: searchPO.podate
    };
    if (searchPO.postatus !== "") temp.purchaseorderdata = {
      ...temp.purchaseorderdata, postatus: searchPO.postatus
    };
    if (searchPO.povendorid !== "") temp.purchaseorderdata = {
      ...temp.purchaseorderdata, povendorid: searchPO.povendorid
    };

    tempHistory.hospital = temp.hospital;
    tempSE.hospital = temp.hospital;
    tempHistorySE.hospital = temp.hospital;

    tempHistory.purchaseorder = { purchaseorderdata: temp.purchaseorderdata };
    tempHistorySE.surgicalevent = { ...tempSE };

    const delay = 0;
    if (delay > 0) setTimeout(() => {
      setSearchParams(temp);
      setSearchHistoryParams(tempHistory);
      setSearchParamsSurgical(tempSE);
      setSearchHistoryParamsSurgical(tempHistorySE);
    }, delay);
    else {
      setSearchParams(temp);
      setSearchHistoryParams(tempHistory);
      setSearchParamsSurgical(tempSE);
      setSearchHistoryParamsSurgical(tempHistorySE);
    }
  }, [searchPO, hospital]);

  useEffect(() => {
    setLoading(false);
  }, [searchParams]);


  return (
    <>

      <Grid>

        <Typography variant="h4" className={classes.pageTitle}>SE Purchase Order</Typography>

        {/* <Button
          size="small"
          className={classes.newSetupButton}
          variant="contained"
          onClick={handleCreatePO}
        >
          <span className="desktop">Create PO</span> 
          <span className="mobile"><AddIcon /></span>
        </Button> */}
        

        <FormControlLabel
          className={classes.newCheckbox}
          control={
            <Checkbox
              color="primary"
              checked={isHistory}
              onChange={(e) => setHistory(e.target.checked)}
            />
          }
          label="Include Archive Contracts"
        />

        <PurchaseOrderListContracts
          searchParams={searchParams}
          searchHistoryParams={searchHistoryParams}
          searchParamsSurgical={searchParamsSurgical}
          searchHistoryParamsSurgical={searchHistoryParamsSurgical}
          isIncludeHistory={isHistory}
          handleChangeSearchValue={handleChangeSearchValue}
          searchPO={searchPO}
          allContracts={contracts}
          allContractsSurgical={contractsSurgical}
          createOptions={createOptions}
          assetsPPH={assetsPPH}
          loading={loading}
        />

      </Grid>

      <CreatePurchaseOrder
        isCreate={isCreate}
        openModal={openModal}
        setOpenModal={setOpenModal}
        addProductItem={addProductItem}
        handleAddMoreProduct={handleAddMoreProduct}
        surgicalEventData={surgicalEventData}
        purchaseOrderData={purchaseOrderData}
        setPurchaseOrderData={setPurchaseOrderData}
        purchaseOrderDetailData={purchaseOrderDetailData}
        setPurchaseOrderDetailData={setPurchaseOrderDetailData}
        handleDeleteProduct={handleDeleteProduct}
        handleUpdateProduct={handleUpdateProduct}
      />

    </>
  );
}

/**
 * "Create" or "Save Update" in PO
 */
export const createUpdatePO = async ({
  ledger, ContractId, contracts, hospital, roleCid, isCreate,
  purchaseOrderData, purchaseOrderDetailData, surgicalEventData, setOpenModal, isEnable=true,
  user, role, payload, userlistPayload, isAddInsteadCreate=true, 
}) => {
  const email  =  userlistPayload?.email;
  const username  =  userlistPayload?.name;
  console.log("[createUpdatePO]", purchaseOrderData, purchaseOrderDetailData, isCreate, ContractId, isEnable, payload);

  if (isEnable === true) {

    // purchase order data & detail data
    const seid = (!!surgicalEventData.seid) ? surgicalEventData.seid : "";
    const newpodata = createPurData(purchaseOrderData, seid);
    const newpodetails = purchaseOrderDetailData
      .map(p => createPODetail({
        podetail: p,
        vendor: purchaseOrderData.povendorid,
        ownership: purchaseOrderData.popurchaseordertype,
        seid,
      }));

    // find target PO
    const samePO = findTargetPOWithOwnership(newpodata, contracts);
    // find out old contract
    const oldPO = contracts.find(c => c.contractId === ContractId);
    console.log("[createUpdatePO]", samePO, seid, newpodata, newpodetails, oldPO);

    const targetpoid = (!!samePO) ? samePO.contractId : null;
    const targetponumber = (!!samePO) ? samePO.payload.purchaseorderdata.popurchaseorderid : null;
    
    // create or update PO
    if (isCreate) {
      // create
      if (isAddInsteadCreate && targetpoid) {
        payload = samePO.payload;
        // add PODetails
        await addPODetailDatas({
          ledger, contractId: targetpoid,
          newpodetaildatas: newpodetails,
          roleCid,
          user, role, payload,
        });
      }
      else {
        // create PO
        await createpurchaseorder({
          ledger, hospital, roleCid,
          purdata: newpodata,
          purdetail: newpodetails,
          email: email,
          username: username,
          user, role,
        });
      }
    }
    else {
      // update

      // add only new PODetails
      const newaddeddetails = newpodetails.filter(d => !d.id);
      let newContractId = ContractId;
      if (newaddeddetails.length > 0) {
        const res = await addPODetailDatas({
          ledger, 
          contractId: ContractId,
          newpodetaildatas: newaddeddetails,
          roleCid,
          user, role, payload,
        });
        newContractId = res.contractId;
        payload = res;
      }

      // check change PoType
      let isChangePoType = false;
      if (oldPO) {
        const newpodata_potype = newpodata.potype ? newpodata.potype : null;
        const oldPO_potype = oldPO.payload.purchaseorderdata.potype ? oldPO.payload.purchaseorderdata.potype : null;
        isChangePoType = (newpodata_potype !== oldPO_potype);
        if (isChangePoType) console.log("[createUpdatePO] PO is Changed PO Type", oldPO.payload.purchaseorderdata.potype, 
          newpodata.potype);
      }
      else {
        isChangePoType = (targetpoid !== ContractId);
      }
      // check other fields of PO e.g. PO Note, PO Date
      let isChangePO = false;
      if (oldPO) {
        const newpodata_ponotes = newpodata.ponotes ? newpodata.ponotes : null;
        const oldPO_ponotes = oldPO.payload.purchaseorderdata.ponotes ? oldPO.payload.purchaseorderdata.ponotes : null;
        const newpodata_podate = newpodata.podate ? newpodata.podate : null;
        const oldPO_podate = oldPO.payload.purchaseorderdata.podate ? oldPO.payload.purchaseorderdata.podate : null;
        isChangePO = (newpodata_ponotes !== oldPO_ponotes || newpodata_podate !== oldPO_podate);
        if (isChangePO) console.log("[createUpdatePO] PO is Changed PO notes or date", oldPO.payload.purchaseorderdata.ponotes, 
          newpodata.ponotes, oldPO.payload.purchaseorderdata.podate, newpodata.podate);
      }
      // console.log("[createUpdatePO] check PoType", oldPO, newpodata.potype, targetpoid, ContractId, isChangePoType);
      if (isChangePoType || isChangePO) {
        // change PoType
        if (!oldPO) console.log("[createUpdatePO] oldPO has no payload", oldPO);
        let tempnewpodata = oldPO ? oldPO.payload.purchaseorderdata : {}; // error happen
        tempnewpodata.potype = newpodata.potype;
        tempnewpodata.ponotes = newpodata.ponotes;
        tempnewpodata.podate = newpodata.podate;
        let res = await updatePOData({
          ledger, 
          contractId: newContractId,
          // targetpoid: targetpoid,
          targetponumber: targetponumber,
          podata: tempnewpodata,
          roleCid,
          user, role, payload,
        });
        // console.log("[createUpdatePO] updatePOData result", res);
        if(res) {
          newContractId = res.contractId;
          payload = res;
        }
      } 

      // check change Ownership
      let isChangeOwnership = false;
      if (oldPO) {
        isChangeOwnership = (newpodata.popurchaseordertype !== oldPO.payload.purchaseorderdata.popurchaseordertype);
      }
      else {
        isChangeOwnership = (targetpoid !== ContractId);
      }
      if (isChangeOwnership) {
        // change ownership
        await changeOwnershipPO({
          ledger, 
          cid: newContractId,
          // targetpoid: targetpoid,
          targetponumber: targetponumber,
          newownership: newpodata.popurchaseordertype,
          roleCid,
          user, role, payload,
        });
      }
    }

  }

  if (typeof setOpenModal === 'function') setOpenModal(false);
};