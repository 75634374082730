import React, { Component } from "react";
import Slider from "react-slick";
import { Button, DialogContentText, DialogContent } from "@material-ui/core";
import slideImg from "../../icons/group1.svg";
import slideImg1 from "../../icons/slide.svg";
import slideImg2 from "../../icons/slide2.svg";
import slideImg3 from "../../icons/slide3.svg";
import slideImg4 from "../../icons/slide4.svg";

/**
 * Slick Slider
 */
export default class SlickSlider extends Component {
  
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    
  }
  next() {
    //console.log(this.slider);
    this.props.setSlideNumber(this.props.slideNumber+1);
    if(this.props.slideNumber >= 5){ this.props.handleSuccessModalClose(); return false; }
    console.log(this.props.slideNumber);
    this.slider.slickNext();
    
  }
  previous() {
    this.props.setSlideNumber(this.props.slideNumber-1);
    console.log(this.props.slideNumber);
    this.slider.slickPrev();
   
  }
 
  render() {
    
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
     };
     
    return (
      <div>
         <DialogContent className={this.props.classes.slickslider}>
           <DialogContentText component={'div'}>
           <h2>Welcome to TYDEi Health</h2>
        <Slider ref={c => (this.slider = c)} {...settings}>
                <div>
                 <img src={slideImg1} alt=""/>
                 <p>  TYDEi Health is expected to demonstrate the following business values from this project.</p>
              </div>
               <div>
                 <img src={slideImg} alt=""/>
                 <p>   Confirmation of surgical implants (Owned, Loaned or Consigned) used during surgical procedures to avoid expiration, or incorrect usage.</p>
              </div>
              <div>
               <img src={slideImg2} alt=""/>
              <p> Allocation of payments based on contractual agreements defined and agreed within the Smart Contract Solution.</p>
              </div>
              <div>
             
              <img src={slideImg3} alt=""/>
              <p>Smart Contract Processing of Purchase orders, Invoices and Payments.</p>
              </div>
              <div>
              
              <img src={slideImg4} alt=""/>
              <p>Smart Contract re-supply information of surgical implants that are used, with information related to shipping back to the originating facility or back to vendor shared supply inventory.</p>
              </div>
            </Slider>  
            </DialogContentText>  
            </DialogContent>   
        <div className="form-footer welcome-footer">
          <Button variant="outlined" color="primary" onClick={this.props.handleSuccessModalClose}>Skip</Button>
            <div className="right-buttons">
            <Button variant="text" color="primary" onClick={this.previous}>Back</Button>
            <Button variant="contained" color="primary" onClick={this.next} >{this.props.slideNumber > 4 ? 'Finish' : 'Next' }</Button> 
            </div>
        </div>
       
      </div>
    );
  }
}