import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@material-ui/core";
import { useStyles } from "../styles";


export default function IdleTimeoutModal({ 

  okButtontitle="Stay Logged In", 
  cancelButtontitle="Logout", 
  cancelHandler,
  openState,
  remaining,
  logoutHandle = null,
  }) {

  const classes = useStyles();

  const [modalOpenState, setModalOpenState] = React.useState(openState);
 
  const handleConductModalAccept = () => {
    setModalOpenState(false);
    if (typeof logoutHandle === "function" && logoutHandle)  logoutHandle();
  };

  const handleConductModalClose = () => {
    setModalOpenState(false);
  };

  console.log('Ideal Modal', remaining, openState);
  
  return (
    <>
      <Dialog
        open={modalOpenState}
        onClose={handleConductModalClose}
        classes={{ paper: classes.idleTimeoutDialogPaper }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={ classes.dialogTitle } >Session Timeout!</DialogTitle>
        <DialogContent className={ classes.dialogContent } >
        Your session is about to expire in {remaining} seconds due to inactivity. You will be redirected to the login page.
    
        {/* <p>Logging out in {remaining} seconds</p> */}

        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
              handleConductModalClose();
              if (typeof cancelHandler === "function" && cancelHandler) cancelHandler();
            }} color="primary">
            {okButtontitle}
          </Button>
          <Button onClick={handleConductModalAccept} color="primary" autoFocus>
            {cancelButtontitle}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}