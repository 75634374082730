import React, { useState } from "react";
import { useLedger } from "@daml/react";
import AdaptiveSearch from "../../components/Search/adaptivesearch";
import DateSearch from "../../components/Search/datesearch";
import { contractFilter } from "../../services/daml-filters";
import archiveIcon from "../../icons/archive.svg";
import archiveDisableIcon from "../../icons/archive-disable.svg";
import icon9 from '../../icons/payment.svg';
import activeIcon9 from '../../icons/active9.svg';
import { getTemplate } from "../../services/util";
import { ArchiveInvoiceHospital, PaymentInvoiceHospital, SaveInvoiceHospitalPaymentDetails } from "./handle";
import ModalDialogBox from '../../components/Modals/GeneralModal/modalDialogBox';
import TreeContracts from "../../components/Contracts/TreeContracts";
import DetailViewDrawer from "./detailviewdrawer";
import DetailViewDrawerPO from "./detailviewdrawerpo";
import { useDamlState } from "../../context/DamlContext";
import useStyles from "./styles";
import { Chip } from "@material-ui/core";
import classNames from "classnames";
import withLoading from "../../hoc/withLoading";
import SnackbarModal from "../../components/Modals/SnackbarModal";
import PaymentPopup from "./payment-popup";
/**
 * Received InvoiceList Contracts
 * @param {Object} searchParams 
 * @param {Object} searchHistoryParams 
 * @param {Boolean} isIncludeHistory 
 * @param {Object} searchInv 
 * @param {Function} handleChangeSearchValue 
 * @param {Array} allContracts 
 * @param {Array} vendortemplates
 * @returns 
 */
function ReceivedInvoiceListContracts({
  searchParams = {},
  searchInv = {},
  handleChangeSearchValue,
  allContracts = [],
  vendortemplates = [],
  isSecondary= false
}) {
  const classes = useStyles();
  const ledger = useLedger();
  const { roleCid, rolePayload } = useDamlState();
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [archiveModalContract, setArchiveModalContract] = useState({});

  // DetailViewDrawer with PO
  const [invoiceData, setInvoiceData] = useState({});
  const [invoiceList, setInvoiceList] = useState([]);
  const [detailViewer, setDetailViewer] = useState(false);
  const [openToast, setOpenToast] = useState(false);

  // DetailViewDrawer for Invoice detail
  const [invoice, setInvoice] = useState({});
  const [detailViewerInvoice, setDetailViewerInvoice] = useState(false);
  const [vendortemplate, setVendorTemplate] = useState(null);
  const [paymentDetailView, setPaymentDetailView] = useState(false);
  const [toastDetails, setToastDetails] = useState({ isOpen: false, content: "" });
  let contracts = contractFilter(allContracts, searchParams);

  const checkNotInvHistory = (c) => (getTemplate(c) !== "InvoiceHospitalHistory");
  const checkInvPaymentInitiated = (c) => {
    return c.payload.paymentdetaildata.paymentstatus === "Available for payment" || c.payload.paymentdetaildata.paymentstatus === "Cancelled"
  };

  const openArchiveInv = (c) => {
    setArchiveModalContract(c);
    console.log(archiveModalContract);
    setArchiveModalOpen(true);

  };

  const openPaymentInv = (c) => {
    setArchiveModalContract(c);

    if (rolePayload?.hospitaldetails?.ach_info?.accountnumber === "" || rolePayload?.hospitaldetails?.ach_info?.partnernetwork_id === "" || rolePayload?.hospitaldetails?.ach_info?.payernetwork_id === "" || rolePayload?.hospitaldetails?.ach_info?.routingnumber === "" || rolePayload?.hospitaldetails?.physicaladdress === "") {
      setOpenToast(true);
    }
    else {
      setInvoice(c.payload);
      setPaymentDetailView(true)
    }

  };

  const handleArchiveInv = async () => {
    await ArchiveInvoiceHospital({
      ledger,
      cid: archiveModalContract.contractId,
      payload: archiveModalContract.payload,
      roleCid,
    });
    setArchiveModalOpen(false);
  };


  const getActions = () => {
    return [
      [
        "Archive",
        [
          [["Payment", [<img alt="" src={activeIcon9} />, <img alt="" src={icon9} />],
            checkInvPaymentInitiated],
            openPaymentInv],
          [["Archive", [<img alt="" src={archiveIcon} />, <img alt="" src={archiveDisableIcon} />],
            (c) => (checkNotInvHistory(c) && c.payload.paymentdetaildata.paymentstatus === "Available for payment")],
            openArchiveInv]

        ]
      ],
    ]
  }


  const getStatus = (status) => {
    let statusCode = ''
    switch (status) {
      case 'Error Received':
        statusCode = classes.backRed
        break;
      case 'Payment Complete':
        statusCode = classes.backGreen
        break;
      case 'Available for payment':
        statusCode = classes.backBlue
        break;
      case 'Ready for payment':
        statusCode = classes.backBlue
        break;
      case 'Cancelled':
        statusCode = classes.backYellow
        break;
      case 'Processing by Bank':
        statusCode = classes.backBlue
        break;
      case 'Sent to Bank':
      case 'Confirmed by Bank':
        statusCode = classes.backTeal
        break;
      default:
        statusCode = classes.backBlue
        break;
    }

    return statusCode;
  }


  const getInvoiceStatus = (status) => {
    let invstatus = ''
    switch (status) {
      case 'Updated Invoice':
      case 'Created Invoice':
        invstatus = classes.backBlue;
        break;
      case 'Declined':
        invstatus = classes.backLightOrange;
        break;
      case 'Sent':
      case 'Accepted':
        invstatus = classes.backGreen;
        break;
      default:
        invstatus = classes.backOrange;
        break;
    }
    return invstatus;
  }

  console.table(allContracts);

  return (
    <>

      <TreeContracts
        contracts={contracts}
        columns={[
          // ["ContractId", "contractId"],
          ["Purchase Order", "payload.invoicedata.invpurchaseorderid", {
            type: "link",
            onClick: (contractId, contract) => {
              const clickedContractId = (!!contractId) ? contractId : contract.contractId;
              const data = contracts.find(c => c.contractId === clickedContractId);
              console.log("[invoicedetail] data", data);
              setInvoiceData({
                ...data.payload.invoicedata,
                is_sent_hospital: true,
                hospital: data.payload.hospital,
              });
              setInvoiceList(data.payload.invoicedetail);
              setDetailViewer(true);
            },
            options: {
              width: 150,
            },
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.invoicedata.invpurchaseorderid}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'invpurchaseorderid')}
                  defaultValue={searchInv.invpurchaseorderid}
                  label="Search PO Number"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchInv.invpurchaseorderid,
            handleClear: () => handleChangeSearchValue('', 'invpurchaseorderid'),
          }],
          ["Invoice Number", "payload.invoicedata.invinvoicenumber", {
            type: "link",
            onClick: (contractId, contract) => {
              const clickedContractId = (!!contractId) ? contractId : contract.contractId;
              const data = contracts.find(c => c.contractId === clickedContractId);

              setInvoice({
                ...data.payload,
                is_sent_hospital: true,
              });
              const foundVendorTemplate = vendortemplates.find(temp => temp.vendor === data.payload.vendor);
              if (foundVendorTemplate) setVendorTemplate(foundVendorTemplate.payload);
              else setVendorTemplate(null);
              setDetailViewerInvoice(true);
            },
            options: {
              width: 180,
            },
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.invoicedata.invinvoicenumber}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'invinvoicenumber')}
                  defaultValue={searchInv.invinvoicenumber}
                  label="Search SE ID"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchInv.invinvoicenumber,
            handleClear: () => handleChangeSearchValue('', 'invinvoicenumber'),
          }],
          ["Invoice Date", "payload.invoicedata.invinvoicedate", {
            options: {
              width: 160,
            },
            search: {
              component:
                <DateSearch
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'invinvoicedate')}
                  defaultValue={searchInv.invinvoicedate}
                  label="Search Invoice Date :"
                  isStatic={true}
                />
            },
            isSearched: searchInv.invinvoicedate,
            handleClear: () => handleChangeSearchValue('', 'invinvoicedate'),
          }],
          ["Invoice Amount", "payload.invoicedata.invinvoicetotalamount", {
            options: {
              width: 130,
              beforeText: '$'
            },
          }],
          ["Invoice Items", "payload.invoicedata.totalitemscount", {
            options: {
              width: 100,
              headerClassName: classes.centerCell,
              cellClassName: classes.centerCell,
            },
          }],
          ["Invoice Status", "payload.status", {
            type: "render",
            render: (param) => (<Chip
              label={param.value}
              size="small"
              className={classNames(classes.smallChip, getInvoiceStatus())}
            />),

            options: {
              flex: 1.5,
              alignItems: 'center',
            },
          }],
          ["Payment Status", "payload.paymentdetaildata.paymentstatus", {
            type: "render",
            render: (param) => (<Chip
              label={param.value}
              size="small"
              className={classNames(classes.smallChip, getStatus(param.value))}
            />),

            options: {
              flex: 1.5,
              alignItems: 'center',
            },
          }],
          ["Payment Date", "payload.paymentdetaildata.paymentinitiationdate", {
            options: {
              width: 130,
              headerClassName: classes.centerCell,
              cellClassName: classes.centerCell,
            },
          }]
        ]}

        actions={getActions()}
        options={{
          statusEmpty: (allContracts.length === 0)
        }}
      />


      <DetailViewDrawerPO
        state={detailViewer}
        toggleDrawer={(open) => setDetailViewer(open)}
        selectedInvoiceData={invoiceData}
        productItemsList={invoiceList}
      />

      <DetailViewDrawer
        state={detailViewerInvoice}
        toggleDrawer={(open) => setDetailViewerInvoice(open)}
        invoice={invoice}
        vendortemplate={vendortemplate}
        options = {{
          // isSecondary : true,
          isHospital: true
        }}
      />


      <ModalDialogBox
        open={archiveModalOpen}
        setOpen={setArchiveModalOpen}
        heading="Confirm"
        type="warning"
        text={'<p>Do you want to archive Invoice now ?</p>'}
        handleRequest={handleArchiveInv}
        secondBtn={'No'}
        handleSecRequest={() => setArchiveModalOpen(false)}
      />



      <SnackbarModal
        open={openToast}
        setOpen={setOpenToast}
        type="warning"
        title=""
        content="We don\'t have your Bank details or Conact details Please connect with  your Operator"
        vertical="center"
        horizontal="center"
        width={500}
        height={117}
        transition="fade"
        className={classes.toastMedium}
        options={{
          delay: 500,
        }}
        actions={[
          {
            label: 'OK',
          }
        ]}
      />

      <SnackbarModal
        open={toastDetails.isOpen}
        setOpen={(isOpen) => setToastDetails({ isOpen, content: '' })}
        title=""
        content={toastDetails.content}
        width={330}
        height={88}
        className={classes.toastMedium}
      />

      <PaymentPopup
        state={paymentDetailView}
        setInvoiceDetailViewer={setPaymentDetailView}
        toggleDrawer={(open) => {
          setInvoice(null);
          setPaymentDetailView(open);
        }}
        invoice={invoice}
        handlePayment={async (payType, dueDate, paymentinitiationdate) => {
          let shortDate = new Date(dueDate).toISOString().slice(0, 10);
          let shortInitDate = paymentinitiationdate.toISOString().slice(0, 10);
          await PaymentInvoiceHospital({
            ledger,
            vendor: archiveModalContract.payload.vendor,
            cId: archiveModalContract.contractId,
            roleCid,
            paymentdate: shortDate,
            paymentInitDate: shortInitDate,
            paytype: payType
          });
          setPaymentDetailView(false);
          setToastDetails({ isOpen: true, content: 'Your payment is Ready for Payment.' })
        }}
        handleSave={async (paymentdate, paymenttype) => {
          setPaymentDetailView(false);
          await SaveInvoiceHospitalPaymentDetails({
            ledger,
            cid: archiveModalContract.contractId,
            roleCid,
            paymentdate, paymenttype
          })
          setToastDetails({ isOpen: true, content: 'The Payment Updates have been saved Successfully.' })
        }}
      />
    </>
  );
}

export default withLoading(ReceivedInvoiceListContracts);