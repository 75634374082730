import React from "react";
import {TextField, Select, InputLabel,FormControl} from "@material-ui/core";
import { getSides} from "../../models/Product";
import { getGenders, getSalutations } from "../../models/Contact";
import useStyles from "./styles";


/**
 * Add, Edit SurgicalEvent Form
 * @param {Boolean} isCreate 
 * @param {Object} surgicalError 
 * @param {Object} surgicalEventData 
 * @param {Function} SetSurgicalEventData 
 * @param {Function} handleValueFill 
 * @returns 
 */
export default function AddEditEvent ({
  isCreate, surgicalError, surgicalEventData, SetSurgicalEventData,
  handleValueFill=()=>{},
}) {

  const classes = useStyles();

  // disable validation once input each field
  // handleValueFill = () => {};

  return (

    <div className="formsteps">
      <h1  className={classes.formHeading}>
        {isCreate ? 
          'Add a Surgical Event' : 
          'Update Surgical Event'}
      </h1>
      
      <div className={classes.mainFormSteps}>
        <h2 className={classes.formSubHeading}>Patient Details</h2>

        <div className={classes.marginBM30}>
        <TextField
            id="outlined-full-width-mrn"
            label="MRN"
            error={!!surgicalError.mrn}
            helperText={surgicalError.mrn}
            value={surgicalEventData.mrn}
            style={{ margin: 8 }}
            placeholder="eg: MR-111-1111"
            className={classes.customFullWidth}
            margin="normal"
            InputLabelProps={{
                shrink: true,
            }}
            onChange={(e) => {
              SetSurgicalEventData({
                ...surgicalEventData,
                mrn: e.target.value,
              });
              handleValueFill('mrn')
            }}
            variant="outlined"
            />
        </div>
        <div className={classes.marginBM30}>
        <TextField
            id="outlined-full-width-first-name"
            label="First Name"
            error={!!surgicalError.patientfirstname}
            helperText={surgicalError.patientfirstname}
            style={{ margin: 8 }}
            value={surgicalEventData.patientfirstname}
            placeholder="eg: John"
            className={classes.customFullWidth}
            margin="normal"
            InputLabelProps={{
                shrink: true,
            }}
            onChange={(e) => {
              
              SetSurgicalEventData({
                ...surgicalEventData,
                patientfirstname: e.target.value,
              });
              handleValueFill('patientfirstname')
            }}
            variant="outlined"
            />
        </div>
        <div className={classes.marginBM30}>
        <TextField
            id="outlined-full-width-last-name"
            label="Last Name"
            style={{ margin: 8 }}
            error={!!surgicalError.patientlastname}
            helperText={surgicalError.patientlastname}
            value={surgicalEventData.patientlastname}
            placeholder="eg: Smith"
            className={classes.customFullWidth}
            margin="normal"
            InputLabelProps={{
                shrink: true,
            }}
            onChange={(e) => {
              SetSurgicalEventData({
                ...surgicalEventData,
                patientlastname: e.target.value,
              });
              handleValueFill('patientlastname')
            }}
            onKeyDown = {handleValueFill}
            variant="outlined"
            />
        </div>
        <div className={classes.marginBM30}>
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
              Gender
            </InputLabel>
            <Select
                    // native
                    displayEmpty
                    error={!!surgicalError.patientgender}
                    value={surgicalEventData.patientgender}
                    className={classes.halfWidth}
                    inputProps={{
                        name: 'age',
                        id: 'outlined-age-native-simple1',
                    }}
                    onChange={(e) => {
                      SetSurgicalEventData({
                        ...surgicalEventData,
                        patientgender: e.target.value,
                      });
                      handleValueFill('patientgender')
                    }}
            >
                {getGenders("Select One")}
            </Select>
        </FormControl> 
        </div>
        <div className={classes.marginBM30}>
        <FormControl variant="outlined" className={classes.formControl}>            
            <TextField
                id="date"
                label="DOB"
                type="date"
                error={!!surgicalError.patientdob}
                helperText={surgicalError.patientdob}
                value={surgicalEventData.patientdob}
                className={classes.datehalfWidth}
                onChange={(e) => {
                  SetSurgicalEventData({
                    ...surgicalEventData,
                    patientdob: e.target.value,
                  });
                  handleValueFill('patientdob')
                }}
                InputLabelProps={{
                shrink: true,
                }}
                variant="outlined" 
            /> 
        </FormControl> 
        </div>

            <h2 className={classes.formSubHeading}>Event Details</h2> 
        
        <div className={classes.marginBM30}>
        <FormControl variant="outlined" className={classes.formControl}>     
        <TextField
                id="eventdate"
                label="Date"
                type="date"
                error={!!surgicalError.eventdate}
                helperText={surgicalError.eventdate}
                value={surgicalEventData.eventdate}
                className={classes.datehalfWidth}
                onChange={(e) => {
                  SetSurgicalEventData({
                    ...surgicalEventData,
                    eventdate: e.target.value,
                  });
                  handleValueFill('eventdate')
                }}
                InputLabelProps={{
                shrink: true,
                }}
                variant="outlined"  
            />
        </FormControl>
        </div>
        <div className={classes.marginBM30}>
        <TextField
            id="outlined-full-width-surgicalprocedure"
            label="Procedure"
            style={{ margin: 8 }}
            error={!!surgicalError.surgicalprocedure}
            helperText={surgicalError.surgicalprocedure}
            value={surgicalEventData.surgicalprocedure}
            placeholder="e.g. Arthroscopy"
            onChange={(e) => {
              SetSurgicalEventData({
                ...surgicalEventData,
                surgicalprocedure: e.target.value,
              });
              handleValueFill('surgicalprocedure')
            }}
            className={classes.customFullWidth}
            margin="normal"
            InputLabelProps={{
                shrink: true,
            }}
            variant="outlined"
            />
        </div>
        <div className={classes.marginBM30}>
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple2" className={classes.customLabel} shrink={true}>
              Side
            </InputLabel>
            <Select
                    // native
                    displayEmpty
                    className={classes.halfWidth}
                    error={!!surgicalError.surgicalside}
                    value={surgicalEventData.surgicalside}
                  inputProps={{
                        name: 'surgical',
                        id: 'outlined-age-native-simple2',
                    }}
                    onChange={(e) => {
                      SetSurgicalEventData({
                        ...surgicalEventData,
                        surgicalside: e.target.value,
                      });
                      handleValueFill('surgicalside')
                    }}
                    
                >
                  {getSides("Select One")}
              </Select>
        </FormControl>
        </div>
        <h2 className={classes.formSubHeading}>Physician Details</h2>
        <div className={classes.marginBM30}>
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple3" className={classes.customLabel} shrink={true}>
              Physician Salutation
            </InputLabel>
            <Select
                    // native
                    displayEmpty
                    className={classes.halfWidth}
                    error={!!surgicalError.physiciansalutation}
                    value={surgicalEventData.physiciansalutation}
                    inputProps={{
                        name: 'age',
                        id: 'outlined-age-native-simple3',
                    }}
                    onChange={(e) => {
                      SetSurgicalEventData({
                        ...surgicalEventData,
                        physiciansalutation: e.target.value,
                      });
                      handleValueFill('physiciansalutation')
                    }}
                >
                  {getSalutations("Select One")}
              </Select>
        </FormControl>     
        </div>
        <div className={classes.marginBM30}>
        <TextField
            id="outlined-full-width-ptname"
            label="Physician First Name"
            style={{ margin: 8 }}
            placeholder=" "
            className={classes.customFullWidth}
            error={!!surgicalError.physicianfirstname}
            helperText={surgicalError.physicianfirstname}
            value={surgicalEventData.physicianfirstname}
            margin="normal"
            onChange={(e) => {
              SetSurgicalEventData({
                ...surgicalEventData,
                physicianfirstname: e.target.value,
              });
              handleValueFill('physicianfirstname')
            }}
            InputLabelProps={{
                shrink: true,
            }}
            variant="outlined"
            />
        </div>
        <div className={classes.marginBM30}>
        <TextField
            id="outlined-full-width-ptlastname"
            label="Physician Last Name"
            style={{ margin: 8 }}
            placeholder="eg: Johnson"
            className={classes.customFullWidth}
            error={!!surgicalError.physicianlastname}
            helperText={surgicalError.physicianlastname}
            value={surgicalEventData.physicianlastname}
            margin="normal"
            InputLabelProps={{
                shrink: true,
            }}
            variant="outlined"
            onChange={(e) => {
              SetSurgicalEventData({
                ...surgicalEventData,
                physicianlastname: e.target.value,
              });
              handleValueFill('physicianlastname')
            }}
            />    
        </div>

      </div>
    </div>
  );
}
