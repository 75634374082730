import React from "react";
import classNames from "classnames";
import { Link } from "@material-ui/core";
import Contracts from "../../components/Contracts/Contracts1";
import { contractFilter1 } from "../../services/daml-filters";
import { POOwnerships } from "../../models/Ownership";
import { isShippedVendor } from '../../models/InventoryData';
import { InventoryPOStatus } from '../../models/Po';
import { handleCheckedContract, handleClickedContract } from '../surgicalevent/surgicaleventlistcontracts1';
import { ProductStatusComp } from '../purchaseorder/purchaseorderlistcontracts1';
import withLoading from "../../hoc/withLoading";
import withFilters from "../../hoc/withFilters";
import { checkNotRestockInventoryHistory } from "./inventoryscanhospitalcontracts1";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "../purchaseorder/styles";


/**
 * Outgoing(Restock) Inventory Contracts for Vendor
 * @param {Object} searchParams 
 * @param {Boolean} isIncludeHistory 
 * @param {Array} allContracts 
 * @param {Array} historyContracts 
 * @param {Function} setArchived
 * @param {Function} setChecked
 * @param {Function} handleEditInventory
 * @returns 
 */
function ScanInventoryVendorContracts({
  searchParams = {},
  isIncludeHistory = false,
  allContracts = [],
  historyContracts = [],
  setArchived = null,
  setChecked = null,
  handleEditInventory = null,
}) {

  const classes = useStyles();
  const classes1 = useStyles1();


  allContracts = allContracts.concat(isIncludeHistory && historyContracts.contracts ?
    historyContracts.contracts.map(h => ({ ...h, payload: h.payload.inventory })) : [])
    .map(c => {
      c.payload.ipurchaseorderdata.shippedproducts = 0;
      c.payload.inventorydata.forEach(inv => {
        if (isShippedVendor(inv.iproductstatus)) c.payload.ipurchaseorderdata.shippedproducts += parseInt(inv.iquantity);
        // if (inv.iproductstatus === 'Received' && inv.ireceivedstockforfacility) {
        //   inv.iproductstatus = 'StockedForFacility';
        // }
      });
      // c.payload.pototalamount = getTotalPrice(c.payload.inventorydata);
      c.payload.inventorydata = c.payload.inventorydata.map((inv, index) => ({
        ...inv,
        index,
        contractId: c.contractId,
        vendor: c.payload.vendor,
        hospital: c.payload.hospital,
      }));
      return c;
    });

  let contracts = contractFilter1(allContracts, searchParams);
  // console.log("[ScanInventoryVendorContracts]", searchParams, contracts);


  // check to enable all ship
  const checkEnableAllShip = (c) => {
    // console.log("[ScanInventoryVendorContracts] checkEnableAllShip", c);
    return (checkNotRestockInventoryHistory(c) && c.payload.ipurchaseorderdata.poinventorystatus !== 'AllReceived');
  };

  // handle to click "All Shipped"
  const handleAllShip = (c) => {
    console.log("[ScanInventoryVendorContracts] handleAllShip", c);
  };
  

  return (
    <>
      <Contracts
        className={classes.customRoot}
        contracts={contracts}
        columns={[
          ["SEID", "payload.ipurchaseorderdata.poseid", {
            type: 'render',
            render: (param) => (
              param.value
              ?
              <Link component="button" variant="body2">{param.value}</Link>
              :
              "N/A"
            ),
            search: {
              type: "AdaptiveSearch",
              name: "ipurchaseorderdata.poseid",
              getValue: (c) => c.payload.ipurchaseorderdata.poseid,
              contracts: allContracts,
            },
            options: {
              cellClassName: classNames(classes.primaryColor),
              flex: 1.1,
            },
          }],
          ["PO Number", "payload.ipurchaseorderdata.popurchaseorderid", {
            type: 'link',
            search: {
              type: "AdaptiveSearch",
              name: "ipurchaseorderdata.popurchaseorderid",
              getValue: (c) => c.payload.ipurchaseorderdata.popurchaseorderid,
              contracts: allContracts,
            },
            options: {
              flex: 1,
            }
          }],
          ["PO Date", "payload.ipurchaseorderdata.podate", {
            search: {
              type: "DateSearch",
              name: "ipurchaseorderdata.podate",
            },
            options: {
              flex: 1,
            }
          }],
          ["Hospital", "payload.hospital", {
            search: {
              type: "AdaptiveSearch",
              name: "hospital",
              getValue: (c) => c.payload.hospital,
              contracts: allContracts,
            },
            options: {
              flex: 1,
            }
          }],
          ["Ownership", "payload.ipurchaseorderdata.popurchaseordertype", {
            search: {
              type: "SelectSearch",
              name: "ipurchaseorderdata.popurchaseordertype",
              options: Object.keys(POOwnerships).map(value => ({ label: POOwnerships[value], value: value })),
            },
            options: {
              flex: 1,
            }
          }],
          ["Total Amount", "payload.ipurchaseorderdata.pototalamount", {
            options: {
              beforeText: '$',
              flex: 1,
            }
          }],
          ["Shipped Products", "payload.ipurchaseorderdata.shippedproducts", {
            options: {
              cellClassName: classes.centerCell,
              flex: 1.2,
            },
          }],
          ["Product Status", "payload.ipurchaseorderdata.poinventorystatus", {
            type: "render",
            render: (param) => (<ProductStatusComp value={param.value} classes={classes1} />),
            search: {
              type: "SelectSearch",
              name: "ipurchaseorderdata.poinventorystatus",
              options: Object.keys(InventoryPOStatus).map(value => ({ label: InventoryPOStatus[value], value: value })),
              maxColCnt: 5,
              classes: {
                popoverBox: classes1.newPopoverBox,
                popoverInner: classes1.newInnerPopper,
              },
            },
            options: {
              flex: 1.1,
            }
          }],
        ]}

        actions={[
          [
            "Actions",
            [
              [["All Shipped", 
                checkEnableAllShip],
                handleAllShip,
              ]
            ],
          ],
        ]}
        options={{
          statusEmpty: (allContracts.length === 0),
          checkbox: true,
        }}
        
        handleArchived={setArchived}
        handleClicked={(e) => handleClickedContract(e, handleEditInventory)}
        handleChecked={(e) => handleCheckedContract(e, contracts, setChecked)}
      />
    </>
  );
}

const ScanInventoryVendorContractsWithLoading = withLoading(ScanInventoryVendorContracts);
export default withFilters(ScanInventoryVendorContractsWithLoading)([
  { name: "ipurchaseorderdata.poseid", label: "SE ID" },
  { name: "ipurchaseorderdata.popurchaseorderid", label: "PO Number" },
  { name: "ipurchaseorderdata.podate", label: "PO Date", type: "date" },
  { name: "hospital", label: "Hospital" },
  { name: "ipurchaseorderdata.popurchaseordertype", label: "Ownership" },
  { name: "ipurchaseorderdata.poinventorystatus", label: "Product Status" },
]);
