import React from "react";
import { Grid, Typography, Card, CardContent } from "@material-ui/core";

export default function NoDataCard() {
  return (
    <div>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={8} style={{
              marginLeft: '12vh'
            }}>
              <Typography
                gutterBottom
                variant="h2"
                component="div"
                style={{
                  color: "#1C3C74",
                }}
                
                align="center"
                
              >
                No Data Available
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
