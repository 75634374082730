import React from "react";
// import { RelationshipOffer } from "../../services/daml-modules1";
import Contracts from "../../components/Contracts/Contracts";
import { contractFilter } from "../../services/daml-filters";
import editIcon from "../../icons/edit.svg";
import AdaptiveSearch from "../../components/Search/adaptivesearch";
import useStyles from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";


function VendorReceivedInviteContracts({
  searchParams = {},
  handleChangeSearchValue = null,
  allContracts = [],
  searchVendorReceivedInvite = {},
  handleRequest,
}) {

  const classes = useStyles();
  // const { contracts } = useStreamQueries(RelationshipOffer, () => [searchParams]);
  let contracts = contractFilter(allContracts, searchParams);

  console.log("[VendorReceivedInviteContracts]", searchParams, contracts);


  return (
    <Contracts
      className={classes.customRoot}
      contracts={contracts}
      columns={[
        // ["ContractId", "contractId"],
        ["Hospital Name", "payload.hospital", {
          options: {
            width: 180,
          },
          search: {
            component:
              <AdaptiveSearch
                getValue={(c) => c.payload.vendor}
                handleChangeSearch={(v) => handleChangeSearchValue(v, 'hospital')}
                defaultValue={searchVendorReceivedInvite.hospital}
                label="Search By Hospital Name"
                contracts={allContracts}
                isShrink={true}
              />
          },
          isSearched: searchVendorReceivedInvite.hospital,
          handleClear: () => handleChangeSearchValue('', 'hospital'),
        }],
        ["Created at", "payload.created_at"],

      ]}

      actions={[
        [
          "Actions",
          [
            [["Accept", [<img alt="" src={editIcon} />]],
              handleRequest],
          ],
        ]
      ]}

      options={{
        statusEmpty: (allContracts.length === 0)
      }}
    />
  );
}

export default withLoading(VendorReceivedInviteContracts);
