import { Grid } from "@material-ui/core";
// import classNames from "classnames";
import React, { useState } from "react";
import Contracts from "../../components/Contracts/Contracts1";
import withFilters from "../../hoc/withFilters";
import withLoading from "../../hoc/withLoading";
import { contractFilter1 } from "../../services/daml-filters";
import useStyles1 from "../surgicalevent/styles";
import useStyles from "./styles";
import InvoicePopUp from "./invoice-view-popup";
import SnackbarModal from "../../components/Modals/SnackbarModal";
import { Paytype } from "../../models/HospitalDetail";
import { handleClickedContract } from '../surgicalevent/surgicaleventlistcontracts1';



function PaymentsListContracts({
    searchParams = {},
    allContracts = [],
    contractactions = null,
    handleRowClicked = null,
    handleExtraRowClicks = null,
    open = false,
    isDistributor = false,
}) {

    const classes1 = useStyles1();
    const classes = useStyles();

    let contracts = contractFilter1(allContracts, searchParams);
    const [isInvoiceDetailViewerOpen, setIsInvoiceDetailViewerOpen] = useState(false);
    const [invoiceDetail, setInvoiceDetail] = useState({});
    const [openToast, setOpenToast] = useState({ isVisible: false, msg: '' });

    // const getColorCode = (value) => {
    //     switch (value) {
    //         case 'Sent':
    //             return classes1.backGreen;
    //         case 'Ready for payment':
    //             return classes1.backBlue;
    //         case 'Complete':
    //             return classes1.backGreen;
    //         case 'Available for payment':
    //             return classes1.backBlue;
    //         case 'Cancelled':
    //             return classes1.backOrange;
    //         default:
    //             return classes1.backYellow;
    //     }
    // }
    const getColorCode = (value) => {
        switch (value) {
            case 'Sent':
                return "#4CAF50";
            case 'Ready for payment':
                return "#2196F3";
            case 'Complete':
                return "#4CAF50";
            case 'Available for payment':
                return "#2196F3";
            case 'Cancelled':
                return "#F95A05";
            default:
                return "#FF9800";
        }
    }

    const getType = (value) => {
        if (value === 'Prefunded Virtual Debit Card') return 'VCP'
        else return value
    }
    const getPayType = (value) => {
        if (value === "Sent to Bank" || value === "Processing by Bank" || value === "Received by Bank") return 'Payment Processing'
        else return value
    }
    const distributorType = () => {
        return (!isDistributor ?
            ["Distributor", "payload.vendor", {
                options: {
                    width: 90,
                },
                search: {
                    type: "AdaptiveSearch",
                    name: "vendor",
                    getValue: (c) => c.payload.vendor,
                    contracts: allContracts,
                },
            }] :
            ["Facility", "payload.hospital", {
                options: {
                    width: 90,
                },
                search: {
                    type: "AdaptiveSearch",
                    name: "hospital",
                    getValue: (c) => c.payload.hospital,
                    contracts: allContracts,
                },
            }])
    }

    return (
        <>
            <Contracts
                className={classes1.customRoot}
                contracts={contracts}
                columns={
                    [
                        ["Payment No.", "payload.paymentnumber", {
                            options: {
                                width: 150,
                            },
                            search: {
                                type: "AdaptiveSearch",
                                name: "paymentnumber",
                                getValue: (c) => c.payload.paymentnumber,
                                contracts: allContracts,
                            },
                        }],
                        ["Type", "payload.paymenttype", {
                            options: {
                                width: 77,
                            },
                            search: {
                                type: "SelectSearch",
                                name: "paymenttype",
                                options: Object.keys(Paytype).map(value => ({ label: getType(Paytype[value]), value: value }))
                            },
                        }],
                        ["Invoice ID", "payload.invoicedata.invinvoicenumber", {
                            type: 'link',
                            onClick: (_contractId, _contract) => {
                                open = true;
                                const data = _contract;
                                setInvoiceDetail(data.contract.payload);
                                if (data.contract.payload.purchaseorderdata) {
                                    handleExtraRowClicks(false);
                                    setIsInvoiceDetailViewerOpen(data.contract.payload.purchaseorderdata);
                                } else {
                                    handleExtraRowClicks(false);
                                    setOpenToast({ isVisible: true, msg: "Sorry, we don't have Invoice data." })
                                }
                            },
                            search: {
                                type: "AdaptiveSearch",
                                name: "invoicedata.invinvoicenumber",
                                getValue: (c) => c.payload.invoicedata.invinvoicenumber,
                                contracts: allContracts,
                            },
                            options: {
                                width: 120,
                            },
                        }],
                        ["Invoice Date", "payload.invoicedata.invinvoicedate", {
                            options: {
                                width: 130,
                            },
                            search: {
                                type: "DateSearch",
                                name: "invoicedata.invinvoicedate",
                            },
                        }],
                        ["Purchase Order ID", "payload.purchaseorderdata.popurchaseorderid", {
                            options: {
                                width: 140,
                            },
                        }],
                        ["PO Date", "payload.purchaseorderdata.podate", {
                            options: {
                                width: 130,
                            },
                        }],
                        ["Amount", "payload.paymentamount", {
                            options: {
                                width: 100,
                                beforeText: '$'
                            },
                        }],
                        
                            distributorType()
                        ,
                        ["Payment Sent", "payload.paymentinitiationdate", {
                            options: {
                                width: 140,
                            },
                            search: {
                                type: "DateSearch",
                                name: "paymentinitiationdate",
                            },
                        }],
                        ["Payment Due", "payload.paymentdate", {
                            options: {
                                width: 140,
                            },
                            search: {
                                type: "DateSearch",
                                name: "paymentdate",
                            },
                        }],
                        ["Status", "payload.status", {
                            type: "render",
                            render: (param) => 
                                <Grid container alignItems="center">
                                    <Grid item xs={2} style={{ marginTop: "2px" }}>
                                        <svg height="10" width="10">
                                            <circle cx="5" cy="5" r="5" fill={getColorCode((param.value === "Sent to Bank" || param.value === "Processing by Bank" || param.value === "Received by Bank ") ? "Payment Processing" : param.value)} />
                                        </svg>
                                    </Grid>
                                    <Grid item xs={10}>
                                        {(param.value === "Sent to Bank" || param.value === "Processing by Bank" || param.value === "Received by Bank ") ? "Payment Processing" : param.value}
                                    </Grid>
                                </Grid>,
                            search: {
                                type: "SelectSearch",
                                name: "status",
                                maxColCnt: 8,
                                options: ["Payment Processing", "Ready for payment", "Error Recieved", "Payment Complete", "Cancelled"].map(value => ({ label: getPayType([value]), value: value }))
                            },
                            options: {
                                width: 150,
                                headerClassName: classes.centerCell,
                                cellClassName: classes.centerCell,
                            },
                        }],

                    ]
                }
                actions={contractactions}

                options={{
                    statusEmpty: (allContracts.length === 0),
                    checkbox: true,
                }}
                handleClicked={(e) => !open && handleClickedContract(e, handleRowClicked)}
            />
            <InvoicePopUp
                state={isInvoiceDetailViewerOpen}
                setInvoiceDetailViewer={setIsInvoiceDetailViewerOpen}
                toggleDrawer={(open) => setIsInvoiceDetailViewerOpen(open)}
                invoice={invoiceDetail}
            />

            <SnackbarModal
                open={openToast.isVisible}
                setOpen={(isVisible) => setOpenToast({ isVisible })}
                type="warning"
                title=""
                content={openToast.msg}
                vertical="center"
                horizontal="center"
                width={500}
                height={117}
                transition="fade"
                className={classes.toastSmall}
                actions={[
                    {
                        label: 'OK',
                        handle: () => {
                            setOpenToast(false);
                        },
                    },

                ]}
            />
        </>
    )
}

const PaymentsListContractsLoading = withLoading(PaymentsListContracts);
export default withFilters(PaymentsListContractsLoading)([
    { name: "paymentnumber", label: "Payment No." },
    { name: "paymenttype", label: "Type" },
    { name: "invoicedata.invinvoicenumber", label: "Invoice ID" },
    { name: "invoicedata.invinvoicedate", label: "Invoice Date", type: "date" },
    { name: "purchaseorderdata.popurchaseorderid", label: "Purchase Order ID" },
    { name: "purchaseorderdata.podate", label: "PO Date", type: "date" },
    { name: "vendor", label: "Distributor" },
    { name: "paymentinitiationdate", label: "Date Range", type: "date" },
    { name: "paymentdate", label: "Date Range", type: "date" },
    { name: "status", label: "Status" }
]);