import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton } from "@material-ui/core";
import { PlaylistAddCheck } from "@material-ui/icons";
import ConfirmListContents from "./contents";
import { useStyles } from "../styles";


export default function ConfirmListModal({
  modaltitle,
  buttontitle = "Confirm",
  buttonicon = <PlaylistAddCheck />,
  okButtontitle = "Ok",
  cancelButtontitle = "Cancel",
  setDetailValue,
  contractList = [],
  contractKeys = [],
  contractKeyLabels = [],
  isButtonVisible = true,
  isDefaultVisible = false,
  cancelHandler = null,
  modalOpenController = false,
  isCheckBoxVisible = true,
  isCheckBoxLeft = true,
  isCheckNotSwitch = true,
  isHeaderVisible = false,
  switchLabels = [],
  extraContents = null }) {

  const classes = useStyles();

  const [modalOpenState, setModalOpenState] = React.useState(false);

  var [selectedList, setSelectedList] = useState([]);
  var [selected, setSelected] = useState([]);

  contractKeys = (contractKeys.length === 0 && contractList.length > 0) ? Object.keys(contractList[0]) : contractKeys;

  React.useEffect(() => {
    if (isDefaultVisible) setModalOpenState(true);
  }, [isDefaultVisible]);

  const handleConductModalAccept = () => {
    if (isCheckBoxVisible) {
      // console.log("[ConfirmListModal]", selectedList);
      setDetailValue(selectedList);
    }
    else {
      setDetailValue(contractList);
    }
    setSelected([]);
    setModalOpenState(false);
  };

  const handleConductModalClose = () => {
    setModalOpenState(false);
  };
  

  return (
    <>
      <Dialog
        open={modalOpenState || modalOpenController}
        onClose={() => {
          handleConductModalClose();
          if (typeof cancelHandler === "function" && cancelHandler) cancelHandler();
        }}
        classes={{ paper: classes.dialogPaperLg }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle} >{modaltitle}</DialogTitle>
        <DialogContent className={classes.dialogContent} >

          <ConfirmListContents
            contractList={contractList}
            contractKeys={contractKeys}
            contractKeyLabels={contractKeyLabels}
            isCheckBoxVisible={isCheckBoxVisible}
            isCheckBoxLeft={isCheckBoxLeft}
            isCheckNotSwitch={isCheckNotSwitch}
            isHeaderVisible={isHeaderVisible}
            switchLabels={switchLabels}
            setSelectedList={setSelectedList}
            selected={selected}
            setSelected={setSelected}
          />

          {extraContents}

        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            handleConductModalClose();
            if (typeof cancelHandler === "function" && cancelHandler) cancelHandler();
          }} color="primary">
            {cancelButtontitle}
          </Button>
          <Button onClick={handleConductModalAccept} color="primary" autoFocus>
            {okButtontitle}
          </Button>
        </DialogActions>
      </Dialog>

      {isButtonVisible && (
        buttontitle
        ?
        <Button
          color="secondary"
          size="small"
          className={classes.button}
          variant="contained"
          onClick={() => { setModalOpenState(true); }}
        >
          {buttontitle}
        </Button>
        :
        <IconButton
          color="secondary"
          size="small"
          className={classes.button}
          variant="contained"
          onClick={() => { setModalOpenState(true); }}
        >
          {buttonicon}
        </IconButton>
      )}
    </>
  );
}