import React from "react";
import classNames from "classnames";
import { UOM } from "../../models/Product";
import { contractFilter1 } from "../../services/daml-filters";
import withLoading from "../../hoc/withLoading";
import withFilters from "../../hoc/withFilters";
import Contracts from "../../components/Contracts/Contracts1";
import useStyles1 from "../purchaseorder/styles";
import { useTranslation } from 'react-i18next';

/**
 * Prior Inventory Contracts
 * @param {Object} searchParams 
 * @param {Array} allContracts 
 * @param {Object} options 
 * @returns 
 */
function PriorInventoryContracts({
  searchParams = {},
  allContracts=[], 
  options={}, 
}) {

  options = {
    isCheck: false,
    ...options,
  };
  const { t } = useTranslation();
  const classes1 = useStyles1();

  allContracts = allContracts.map((c, i) => ({
    id: i, 
    payload: {
      ...c, 
      index: i, 
    } 
  }));
  let contracts = contractFilter1(allContracts, searchParams);

  // console.log("[PriorInventoryContracts]", searchParams, contracts);


  return (
    <>
      <Contracts
        contracts={contracts}
        columns={[
          [`${t('main.lotcode.label')}`, "payload.iproduct.seplotcode", {
            search: {
              type: "AdaptiveSearch",
              name: "iproduct.seplotcode",
              getValue: (c) => c.payload.iproduct.seplotcode,
              contracts: allContracts,
            },
            options: {
              flex: 0.9,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            }
          }],
          ["PO Number", "payload.ipurchaseordernumber", {
            search: {
              type: "AdaptiveSearch",
              name: "ipurchaseordernumber",
              getValue: (c) => c.payload.ipurchaseordernumber,
              contracts: allContracts,
            },
            options: {
              flex: 1,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            },
          }],
          ["SEId", "payload.ipphseid", {
            search: {
              type: "AdaptiveSearch",
              name: "ipphseid",
              getValue: (c) => c.payload.ipphseid,
              contracts: allContracts,
            },
            options: {
              flex: 1,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            },
          }],
          ["Price", "payload.iproduct.sepproductprice", {
            search: {
              type: "AdaptiveSearch",
              name: "iproduct.sepproductprice",
              getValue: (c) => c.payload.iproduct.sepproductprice,
              contracts: allContracts,
            },
            options: {
              beforeText: '$',
              flex: 0.7,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            }
          }],
          [`${t('main.uom.lable')}`, "payload.iproduct.sepuom", {
            type: 'render',
            search: {
              type: "SelectSearch",
              name: "iproduct.sepuom",
              options: Object.keys(UOM).map(value => ({ label: UOM[value], value: value })),
            },
            options: {
              flex: 0.6,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            }
          }],
          ["Received Date", "payload.ireceiveddate", {
            search: {
              type: "DateSearch",
              name: "ireceiveddate",
            },
            options: {
              flex: 1.1,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            },
          }],
          ["Manufacturer", "payload.iproduct.sepproductmanufacturer", {
            search: {
              type: "AdaptiveSearch",
              name: "iproduct.sepproductmanufacturer",
              getValue: (c) => c.payload.iproduct.sepproductmanufacturer,
              contracts: allContracts,
            },
            options: {
              flex: 0.9,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            },
          }],
          ["DOH", "payload.idoh", {
            search: {
              type: "AdaptiveSearch",
              name: "idoh",
              getValue: (c) => c.payload.idoh,
              contracts: allContracts,
            },
            options: {
              flex: 0.7,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            },
          }],
          ["Location", "payload.iproduct.seplocation", {
            search: {
              type: "AdaptiveSearch",
              name: "iproduct.seplocation",
              getValue: (c) => c.payload.iproduct.seplocation,
              contracts: allContracts,
            },
            options: {
              flex: 0.8,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            },
          }],
          [`${t('inventory.account.bin')}`, "payload.ibin", {
            search: {
              type: "AdaptiveSearch",
              name: "ibin",
              getValue: (c) => c.payload.ibin,
              contracts: allContracts,
            },
            options: {
              flex: 0.5,
              cellClassName: classNames(classes1.customText),
              headerClassName: classes1.customText,
            },
          }],
        ]}

        options={{
          sortKey: 'id',
          checkbox: options.isCheck,
          height: '450px', 
          headerHeight: 38,
          rowHeight: 38,
        }}
      />
    </>
  );
}

const PriorInventoryContractsWithLoading = withLoading(PriorInventoryContracts);


export default withFilters(PriorInventoryContractsWithLoading)(
  [
  { name: "iproduct.seplotcode", label: "Lot/SN" },
  { name: "ipurchaseordernumber", label: "PO Number" },
  { name: "ipphseid", label: "SEId" },
  { name: "iproduct.sepproductprice", label: "Price" },
  { name: "iproduct.sepuom", label: "UoM" },
  { name: "ireceiveddate", label: "Received Date" },
  { name: "iproduct.sepproductmanufacturer", label: "Manufacturer" },
  { name: "idoh", label: "DOH" },
  { name: "iproduct.seplocation", label: "Location" },
  { name: "ibin", label: "Bin" },
]);