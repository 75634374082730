import { PRODUCT_SET, PRODUCT_SET_ALL, PRODUCT_SET_DETAIL, PRODUCT_SET_SECONDARY, PRODUCT_CLEAR, 
  PRODUCT_SET_EVENT_DETAIL, PREF_SET_EVENT_DETAIL } from "../actions/constants";

const default_state = {
  product: {},
  errors: {},
  secondaryProduct: {},
  secondaryErrors: {},
  surgicalevent: {},
  surgicaleventErrors: {},
  prefdata: [],
  details: [],
  itemIndex: null,
  hospital: null,
  user: null,
  isChanged: false,             // change to True when any field is changeed
  isChangedSE: false,           // if SE field is changed, then true
};

export default function productReducer (state=default_state, action) {
  const { type, payload } = action;
  if (type === PRODUCT_SET) { 
    // e.g. {product: {}, surgicalevent: {}}
    if (payload && typeof payload === 'object') return { ...state, isChanged: false, isChangedSE: false, ...payload };
  }
  else if (type === PRODUCT_SET_ALL) { 
    if (payload && typeof payload === 'object') return { ...default_state, ...payload };
  }
  else if (type === PRODUCT_SET_DETAIL) { 
    // e.g. {sepreferencenumber: ''}
    // e.g. {sepreferencenumber: '', seplotcode: ''}
    // e.g. [{sepreferencenumber: ''}, {seplotcode: ''}]
    if (payload && typeof payload === 'object') {
      const product = (payload.length === undefined) ? payload : payload.reduce((obj, p) => ({...obj, ...p}));
      for (let key in product) {
        if (product[key] && state.errors[key]) delete state.errors[key];
      }
      return { ...state, product: { ...state.product, ...product }, isChanged: true };
    }
  }
  else if (type === PRODUCT_SET_SECONDARY) { 
    if (payload && typeof payload === 'object') {
      const product = (payload.length === undefined) ? payload : payload.reduce((obj, p) => ({...obj, ...p}));
      for (let key in product) {
        if (product[key] && state.secondaryErrors[key]) delete state.secondaryErrors[key];
      }
      return { ...state, secondaryProduct: { ...state.secondaryProduct, ...product }, isChanged: true };
    }
  }
  else if (type === PRODUCT_SET_EVENT_DETAIL) { 
    if (payload && typeof payload === 'object') {
      for (let key in payload) {
        if (payload[key] && state.surgicaleventErrors[key]) delete state.surgicaleventErrors[key];
      }
      return { ...state, surgicalevent: { ...state.surgicalevent, ...payload }, isChangedSE: true };
    }
  }
  else if (type === PREF_SET_EVENT_DETAIL) { 
    if (payload && typeof payload === 'object') {
      const data = (typeof payload.data === 'object' && payload.data.length > 0) ? payload.data : [];
      return { ...state, prefdata: data, isChangedSE: (state.isChangedSE || !payload.isLoad) };
    }
  }
  else if (type === PRODUCT_CLEAR) {
    return { ...default_state };
  }
  return state;
}