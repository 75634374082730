import React, { useEffect, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import classnames from "classnames";
import DamlLedger from "@daml/react";
import { wsBaseUrl, httpBaseUrl, damlLastPath, damlReloadTime } from "../../config";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import ChatBox from "../ChatBox";
import CustomSnackbar from "../Toast/CustomSnackbar";
import { post, /* getToken */ } from "../../services/axios/daml-json-api";
import { useLayoutState, useLayoutDispatch, toastOpen } from "../../context/LayoutContext";
import { useUserState } from "../../context/UserContext";
import { DamlProvider } from "../../context/DamlContext";
import ErrorBoundary from "../ErrorBoundary";
import Routes from "../Routes/Routes";
import { CustomLoading } from "../../hoc/withLoading";
import useStyles from "./styles";


/**
 * main Layout
 * TODO
 * ChatBox is disabled in bottom right now, it should be fixed in the future.
 * @returns 
 */
function Layout() {

  const classes = useStyles();
  const user = useUserState();
  const { isSidebarOpened, toast, isLoading } = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const location = useLocation();

  const [isHeaderShow, setHeaderShow] = useState(true);
  const [isSidebarShow, setSidebarShow] = useState(true);

  // toast
  const handleCloseToast = async () => {
    toastOpen(layoutDispatch, {});
  };


  // Show or Hide Header & Sidebar
  useEffect(() => {
    console.log("[route changed]", location.pathname);
      
    // hide sidebar on some paths
    if (location.pathname === '/app/surgicalevent/add-new'
      || location.pathname === '/app/surgicalevent/edit') {
      if (isHeaderShow) setHeaderShow(false);
      if (isSidebarShow) setSidebarShow(false);
    }
    else {
      if (!isHeaderShow) setHeaderShow(true);
      if (!isSidebarShow) setSidebarShow(true);
    }

    // update last path
    if (location.pathname !== '/app/welcome') {
      sessionStorage.setItem(damlLastPath, location.pathname);
    }

  }, [location, isHeaderShow, isSidebarShow]);


  // To check websocket connection, post request to get Party
  // If status of response is not 200, then reload window.
  useEffect(() => {
    let timestamp = 0, now = (new Date()).getTime();
    try {
      timestamp = parseInt(localStorage.getItem(damlReloadTime));
    }
    catch (e) {
    }
    if (isNaN(timestamp)) timestamp = 0;
    // console.log("[Layout]", timestamp, now);

    const postParty = async () => {
      let res = await post('/v1/parties', {
        body: JSON.stringify(
          [user.party]
        ),
        token: user.token
      });
      // console.log("[Layout]", res.status);
      localStorage.setItem(damlReloadTime, now);
      if (res.status !== 200) {
        window.location.reload();
      }
    };
    if ((now - timestamp) > 60 * 1000) postParty(); // 1min after last try
  }, [user]);


  return (
    <ErrorBoundary>
      <DamlLedger party={user.party} token={user.token} httpBaseUrl={httpBaseUrl} wsBaseUrl={wsBaseUrl}>
        <DamlProvider>

          <div className={classes.root}>
              
            {isHeaderShow && <Header />}
            
            {isSidebarShow && <Sidebar />}

            <div
              className={classnames(classes.content, {
                [classes.contentShift]: isSidebarOpened,
              })}
            >
              {isHeaderShow && <div className={classes.fakeToolbar} />}

              <Routes />

              <CustomSnackbar 
                handleClose={handleCloseToast}
                {...toast}
                // title={toast.title}
                // content={toast.content}
                // color={toast.color}
              />

            </div>

            {false && user.isAuthenticated ? <ChatBox /> : null}

            {
              isLoading &&
              <CustomLoading className={classes.loadingSpinner} />
            }

          </div>
          
        </DamlProvider>
      </DamlLedger>
    </ErrorBoundary>
  );
}

export default withRouter(Layout);
