import React, { useState } from 'react';
import {
    Card, List, ListItem, ListItemText, Button, Accordion, AccordionDetails, AccordionSummary, Grid, Tooltip, IconButton, Popover, Typography
} from "@material-ui/core";
import useStyles from "../showlist/styles";
import useStyles1 from "../surgicalevent/styles";
import useStyles2 from "../../components/Header/styles";
import classNames from "classnames";
import ContactDetails from './contactDetails';
import ContactPopup from './contactPopup';
import SnackbarModal from "../../components/Modals/SnackbarModal";
// icons import
import NoteEditSolid from "../../icons/clarity_note-edit-solid.svg";
import Vector from "../../icons/Vector.svg";
import BiTrashFill from "../../icons/bi_trash-fill.svg";
import AddIcon from '@material-ui/icons/Add';



function AdminContact({ hospitaldetails, setHospitalDetails, handleSave, handleCancel }) {
    // styles
    const classes = useStyles();
    const classes1 = useStyles1();
    const classes2 = useStyles2();

    const [selectedContact, setSelectedContact] = useState({ index: -1, contact: null });

    const [selectedKey, setSelectedKey] = useState();
    const [openContactModal, setOpenContactModal] = useState(false);
    // Popper
    const [anchorElAdd, setAnchorElAdd] = useState(null);
    const [expanded, setExpanded] = React.useState(false);

    const handleOpenAddPopover = (event) => {
        setAnchorElAdd(event.currentTarget);
    };
    const handleOpenAddModal = (event, key) => {
        setSelectedContact({ index: -1, contact: null })
        setSelectedKey(key);
        setOpenContactModal(true);
        setAnchorElAdd(false);
    };

    // handle when click Edit button
    const handleEditDetail = (contact) => {
        debugger
        let contactList = hospitaldetails[selectedKey];
        if (selectedContact?.index >= 0) {
            contactList.splice(selectedContact?.index, 1);
            contactList.splice(selectedContact?.index, 0, contact);
            hospitaldetails[selectedKey] = contactList
        } else {
            hospitaldetails[selectedKey] = (contactList ? contactList.concat(contact) : [contact])
        }
        setOpenContactModal(false);
    };

    function handleDelete(key, ind) {
        let contactList = hospitaldetails[key];
        contactList.splice(ind, 1);
    };
    const handleExpand = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    }

    // Toast
    const [openToast, setOpenToast] = useState(false);
    const [toastContent, setToastContent] = useState('');

    return (
        <>
            <Grid container>
                <Grid container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                >
                    <Button
                        // className={classes.newCreateButton}
                        variant="contained"
                        color="primary"
                        className={classNames(classes1.newCompleteBtn, classes.newAddCntBtn, "primary-color")}
                        onClick={handleOpenAddPopover}
                        startIcon={<AddIcon />}
                    >
                        Add Contact
                    </Button>
                    <Popover
                        open={Boolean(anchorElAdd)}
                        anchorEl={anchorElAdd}
                        onClose={() => setAnchorElAdd(null)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Card className={classes2.profilemenu}>
                            <List disablePadding={true}>
                                <ListItem
                                    className={classNames(classes2.profilemenu, classes2.menuText)}
                                    onClick={(event) => handleOpenAddModal(event, 'inventorycontact')}
                                >
                                    <ListItemText className={classNames(classes.popOverItems, classes2.menuText)} >
                                        Inventory Contacts
                                    </ListItemText>
                                </ListItem>
                                <ListItem
                                    className={classNames(classes2.profilemenu, classes2.menuText)}
                                    onClick={(event) => handleOpenAddModal(event, 'purchinfocontact')}
                                >
                                    <ListItemText className={classNames(classes.popOverItems, classes2.menuText)} >
                                        Purchasing Contacts
                                    </ListItemText>
                                </ListItem>
                                <ListItem
                                    className={classNames(classes2.profilemenu, classes2.menuText)}
                                    onClick={(event) => handleOpenAddModal(event, 'surgicalsuitecontact')}
                                >
                                    <ListItemText className={classNames(classes.popOverItems, classes2.menuText)} >
                                        Surgical Suite Contacts
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Card>
                    </Popover>
                </Grid>
                <Grid container item style={{ padding: '0px 67px 0px 67px' }}
                    className={classes.productAccordion}
                >
                    {
                        [{ label: "Inventory Contacts", value: hospitaldetails?.inventorycontact, key: 'inventorycontact' },
                        { label: "Surgical Suite Contacts", value: hospitaldetails?.surgicalsuitecontact, key: 'surgicalsuitecontact' },
                        { label: "Purchasing Contacts", value: hospitaldetails?.purchinfocontact, key: 'purchinfocontact' }]
                            .map((items, index) => (<Grid xs={4} item
                                key={index}
                                style={{ height: '320px', padding: '0px 0px 0px 48px' }}
                            >
                                <Typography className={classNames(classes.sectionDetailTitle, classes.sectionDetailTitle1)}>{items.label}</Typography>
                                {
                                    items.value.map((contact, index) => (
                                        <Grid className={classes.productAccordion}>
                                            <Accordion
                                                key={`${items.label}${index}`}
                                                className={classes.accordion_main}
                                                expanded={expanded === `${items.label}${index}`}
                                                onChange={handleExpand(`${items.label}${index}`)}
                                                style={{padding: '1px 0px 1px 0px'}}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<img src={Vector} alt='arrow' />}
                                                    aria-controls="panel2bh-content"
                                                    id="panel2bh-header"
                                                    className={classes.accordionSummeryDark}
                                                >
                                                    <div style={{ display: 'flex' }}>
                                                        <span className={classes.contactaddtitle}>{`${contact?.firstname} ${contact?.lastname}`}</span>
                                                        <span className={classNames(classes.accordionIcon, classes.accordionIcon1)}>
                                                            <Tooltip title="">
                                                                <IconButton
                                                                    onClick={() => handleDelete(items.key, index)}
                                                                    className={classes.iconSize}>
                                                                    <img src={BiTrashFill} alt="delete" />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="">
                                                                <IconButton className={classes.iconSize}
                                                                    onClick={() => {
                                                                        setSelectedKey(items.key);
                                                                        setSelectedContact({ index: index, contact });
                                                                        setOpenContactModal(true);
                                                                    }}>
                                                                    <img src={NoteEditSolid} alt="edit" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </span>
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails className={classes.accordionDetailsDark}>
                                                    <Grid container spacing={2} className={classes.gridItem}>
                                                        <ContactDetails
                                                            obj={contact}
                                                        />
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )).reverse()
                                }
                            </Grid>))
                    }
                </Grid>
                <Grid container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    style={{ height: '57px', padding: '0px 6px 6px 0px' }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCancel}
                        className={classNames(classes1.newCompleteBtn, "secondary-color")}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        className={classNames(classes1.newCompleteBtn, "primary-color")}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
            {openContactModal ? <ContactPopup
                openModal={openContactModal}
                handleSave={handleEditDetail}
                setOpenToast={setOpenToast}
                setToastContent={setToastContent}
                data={selectedContact.contact}
                handleCancel={() => {
                    setOpenContactModal(false);
                }}
            /> : null}
            <SnackbarModal
                open={openToast}
                setOpen={setOpenToast}
                title=""
                content={toastContent}
                width={330}
                height={88}
                className={classes1.toastMedium}
            />
        </>
    )
}

export default AdminContact;