import React, { useEffect } from "react";
import { useStreamQueries } from "@daml/react";
import { dispatch } from "../../store";
import { setDAMLSearch } from "../../store/actions/generalActions";


/**
 * Component using useStreamQueries
 * @param {Object} template
 * @param {Object} searchParams
 * @param {String} indexKey
 * @param {Function} setValues
 * @returns 
 */
export default function StreamQueries({
  template,
  searchParams=[],
  indexKey,
  setValues=()=>{},
}) {

  const assets = useStreamQueries(template, () => searchParams);
  console.log("[StreamQueries]", template.templateId, "searchParams: ", searchParams, "indexKey: ", indexKey, assets);

  useEffect(() => {
    if (assets.loading === false) {
      setValues(assets);
      if (typeof indexKey === 'string') {
        dispatch(setDAMLSearch({ key: indexKey, value: assets }));
      }
    }
  }, [assets, indexKey, setValues]);

  return (<></>);
}
