import React, { useState, useEffect, useCallback } from "react";
import { useLedger, useStreamQueries } from "@daml/react";
import { Button, Typography, Grid, ButtonGroup } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { UserList as UserListTemplate } from "../../services/daml-modules1";
import { useDamlState } from "../../context/DamlContext";
import AddEditUser from "./addedituser";
import InviteVendorUser from "./invitevendoruser";
import VendorUserContracts from "./vendorusercontracts1";
import HospitalUserContracts from "./hospitalusercontracts1";
import { dispatch, getState } from "../../store";
import { userSearch } from "../../store/actions/generalActions";
import { createUserList, updateUser, getUserList, deleteUser, inviteUserOrg, inviteVendorUserOrg } from "./handle";
import ModalDialogBox from "../../components/Modals/GeneralModal/modalDialogBox";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "../showlist/styles";
import SnackbarModal from "../../components/Modals/SnackbarModal";
import { useUserState} from "../../context/UserContext";

/**
 * User Management Page in Hospital, Vendor, Operator
 * @returns
 */
export default function UserList() {
  const classes = useStyles();
  const classes1 = useStyles1();
  const ledger = useLedger();
  const userState = useUserState();
  const { role, roleCid, rolePayload, organization } = useDamlState();

  const { contracts, loading } = useStreamQueries(UserListTemplate, () => [
    { },
  ]);

  console.log('userlist', contracts);

  //auth0 contract user
  const [auth0ContractUserList, setAuth0ContractUserList] = useState([]);
  const [ContractId, setContractId] = useState("");

  // AddEditUser
  const [open, setOpen] = useState(false);
  const [isCreate, setCreate] = useState(true);
  const [selectedUser, selectUser] = useState({});

  //invite vendor user
  const [openInvite, setOpenInvite] = useState(false);

  const [authUsers, setAuthUsers] = useState([]);

  // alert
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  // delete alert
  const [isDelete, setDelete] = useState(false);
  const [openDeleteAlert, setDeleteAlert] = useState(false);
  const deleteMsg = "Are you sure to delete this user?";

  // onclickHandle
  const [newRole, setNewRole] = useState("Hospital");


  // snackbar settings
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarType, setOpenSnackBarType] = useState("warning");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  //invite organization
  const inviteOrgazationHandler = async (userInfoPayload) => {
    let res;
    res = await inviteUserOrg({
      ledger,
      userInfoPayload,
      roleCid,
      rolePayload,
      oldUser: userInfoPayload
    });
    if (res !== true) {
      // when error occurs
      if (typeof res === "string") setAlertMsg(res);
      else
        setAlertMsg(
          `An Error occurred ! Please contact <a href="mailto:info@tydei.io">info@tydei.io</a> for further assistance`
        );

      setOpenAlert(true);
      setOpenSnackBar(true);
      setOpenSnackBarType("warning");
    } else {
      setOpenSnackBar(true);
      setOpenSnackBarType("success");
      setSnackBarMessage(
        "User has been successfully invited to the organization"
      );
      getuserslist();
    }
  };

  const inviteVendorOrgazationHandler = async (userInfoPayload) => {

    setOpenInvite(false);
    let res;

    res = await inviteVendorUserOrg({
      ledger,
      userInfoPayload,
      roleCid,
      rolePayload,
      oldUser: userInfoPayload
    });
    if (res !== true) {
      // when error occurs
      if (typeof res === "string") setAlertMsg(res);
      else
        setAlertMsg(
          `An Error occurred ! Please contact <a href="mailto:info@tydei.io">info@tydei.io</a> for further assistance`
        );

      setOpenAlert(true);
      setOpenSnackBar(true);
      setOpenSnackBarType("warning");
    } else {
      setOpenSnackBar(true);
      setOpenSnackBarType("success");
      setSnackBarMessage(
        "User has been successfully invited to the organization"
      );
      getuserslist();
    }
  };

  // handle when click "Create" or "Update" button in Create Vendor Template modal
  const handleRequest = async (newuser, password) => {
    let res;
    // email is required
    if (!newuser.email) return false;

    // close modal
    setOpen(false);

    let authUserId = null;
    let authUserList = authUsers;
    let found = null;

    // update authUsers everytime
    authUserList = await getUserList();
    setAuthUsers(authUserList);

    // find a user in auth user list
    if (authUserList.length > 0) {
      found = authUserList.find(
        (a) => a.email.toLocaleLowerCase() === newuser.email.toLocaleLowerCase()
      );
      if (found) authUserId = found.user_id;
    }
    if (isCreate) {
      if (authUserId) {
        // This email already exists in auth0
        res = `<p>User already exists. User Creation was Unsuccessful.</p><p>${newuser.email} already exists in Auth0.</p>`;

        if (role === "Hospital") {
          res = await inviteUserOrg({
            ledger,
            newuser,
            roleCid,
            rolePayload,
            oldUser: found,
          });
          if (res) {
            setOpenSnackBar(true);
            setOpenSnackBarType("success");
            setSnackBarMessage(
              "User has been successfully invited to the organization"
            );
          }
        }
      } else if (
        role === "Hospital" ||
        role === "Vendor" ||
        role === "Operator"
      ) {
        res = await createUserList({
          ledger,
          newuser,
          password,
          roleCid,
          rolePayload,
          role: role,
          token: userState.token,
        });
      }
    } else if (
      (role === "Hospital" || role === "Vendor" || role === "Operator") &&
      !isDelete
    ) {
      res = await updateUser({
        ledger,
        newuser,
        ContractId,
        authUserId,
        roleCid,
        rolePayload,
        role: role,
        oldUser: found,
      });
    } else if (isDelete) {
      setDeleteAlert(false);
      res = await deleteUser({
        ledger,
        ContractId,
        authUserId,
        roleCid,
        rolePayload,
        role: role,
      });
    }

    if (res !== true) {
      // when error occurs
      if (typeof res === "string") setAlertMsg(res);
      else
        setAlertMsg(
          `An Error occurred ! Please contact <a href="mailto:info@tydei.io">info@tydei.io</a> for further assistance`
        );
      setOpenAlert(true);
    }
    getuserslist();
  };


  const [searchUser, setSearchUser] = useState({
    name: "",
    ...getState().user,
  });
  const handleChangeSearchValue = (val, key) => {
    setSearchUser({ ...searchUser, [key]: val });
    dispatch(userSearch({ key, value: val }));
  };


  const getuserslist = async () => {
    const users = await getUserList();
    setAuthUsers(users);

  };

  // get user list from auth0 and get allparties
  useEffect(() => {
    getuserslist();
  }, []);


  /** 
   * filter auth0 userlist based on current organization/invited_organization
   * @param {*} userData
   * @returns
   */
  const filterUserByOrganizationH = useCallback((userData) => {
    const filterUserData = userData.filter((value) => {
      return (
        value?.app_metadata?.daml_ledger_api?.organization === organization ||
        value.app_metadata?.daml_ledger_api?.invited_organization?.includes(
          organization
        )
      );
    });
    const buildUserData = [];
    filterUserData.forEach((_filterValue) => {
      let payloadData = {};
      payloadData = {
        payload: {
          ..._filterValue,
          ..._filterValue.app_metadata?.daml_ledger_api,
          contractId: getContractId(_filterValue.email, contracts),
        },
      }
      delete payloadData.payload?.app_metadata;
      buildUserData.push(payloadData);
    });
    return buildUserData;
  }, [organization, contracts]);

  const filterUserByOrganizationV = (userData) => {
    const filterUserData = userData.filter((value) => {
      return (
        value?.app_metadata?.daml_ledger_api.role === "Vendor"
      );
    });
    const buildUserData = [];
    filterUserData.forEach((_filterValue) => {
      let payloadData = {};
      payloadData = {
        payload: {
          ..._filterValue,
          ..._filterValue.app_metadata?.daml_ledger_api,
          contractId: getContractId(_filterValue.email, contracts),
        },
      }
      delete payloadData.payload?.app_metadata;
      buildUserData.push(payloadData);
    });
    return buildUserData;
  };

  //get contract Id from DAML contracts
  const getContractId = (userId, contractObj) => {
    const found = contractObj.filter((value) => { return value.payload.email.toLowerCase() === userId.toLowerCase() });
    return found[0]?.contractId;
  }

  const selectedRole = (c) => {
    setNewRole(c);
    if (c === "Hospital") {
      if (authUsers && contracts) {
        setAuth0ContractUserList(filterUserByOrganizationH(authUsers));
      }
    }
    if (c === "Vendor") {
      if (authUsers && contracts) {
        setAuth0ContractUserList(filterUserByOrganizationV(authUsers));
      }
    }
  }
  useEffect(() => {
    if (newRole === "Hospital") {
      if (authUsers && contracts && newRole) {
        setAuth0ContractUserList(filterUserByOrganizationH(authUsers));
      }
    }

  }, [authUsers, contracts, newRole, filterUserByOrganizationH]);

  const onSnackbarHandler = (inputStatus) => {
    setOpenSnackBar(!inputStatus);
    setOpen(false);
    setSnackBarMessage("");
  };

  return (
    <>
      <div key="formChildStep" className="formsteps">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          className={classes1.paddingBtm}
        >
          <Grid item>
            <Typography variant="h5" className={classes1.newTabTitle}>
              User Management
            </Typography>
          </Grid>
          <Grid item>
            {
              role === "Hospital" &&
              <ButtonGroup
              className={classes1.btnGroupTop}
              color="primary"
              variant="contained"
              size="small"
            >
              <Button value="Facility" onClick={() => selectedRole('Hospital')} className={(newRole === 'Hospital') ? "active" : ""}> Facility </Button>
              <Button value="Vendor" onClick={() => selectedRole('Vendor')} className={(newRole === 'Vendor') ? "active" : ""}> Vendor</Button>
            </ButtonGroup>
            }
            {
              newRole === "Hospital" &&
              <Button
                size="small"
                className={classes.newSetupButton}
                onClick={() => {
                  selectUser({});
                  setCreate(true);
                  setOpen(true);
                }}
                startIcon={<AddIcon />}
                variant="contained"
              >
                <span className="desktop">Create new User</span>
              </Button>
            }
            {

              newRole === "Vendor" &&

              <Button
                size="small"
                className={classes.newSetupButton}
                onClick={() => {
                  selectUser({});
                  setCreate(true);
                  setOpenInvite(true);
                }}
                startIcon={<AddIcon />}
                variant="contained"

              >
                <span className="desktop">Invite User Vendor</span>
              </Button>
            }
          </Grid>
        </Grid>
        {
        role === "Hospital" ? (
          <HospitalUserContracts
            loading={loading}
            handleChangeSearchValue={handleChangeSearchValue}
            allContracts={auth0ContractUserList}
            searchUser={searchUser}
            newRole={newRole}
            handleRequest={(data) => {
              setContractId(data.payload.contractId);
              selectUser(data.payload);
              setCreate(false);
              setOpen(true);
              setDelete(false);
              setDeleteAlert(false);
            }}
            handleDeleteRequest={(data) => {
              setAlertMsg(deleteMsg);
              setContractId(data.payload.contractId);
              selectUser(data.payload);
              setCreate(false);
              setDelete(true);
              setDeleteAlert(true);
            }}
          />
        ) : (role === "Vendor" ? (
          <VendorUserContracts
            loading={loading}
            handleChangeSearchValue={handleChangeSearchValue}
            allContracts={auth0ContractUserList}
            searchUser={searchUser}
            handleRequest={(data) => {
              setContractId(data.payload.contractId);
              selectUser(data.payload);
              setCreate(false);
              setOpen(true);
              setDelete(false);
              setDeleteAlert(false);
            }}
            handleDeleteRequest={(data) => {
              setAlertMsg(deleteMsg);
              setContractId(data.payload.contractId);
              selectUser(data.payload);
              setCreate(false);
              setDelete(true);
              setDeleteAlert(true);
            }}
          />
        ) : <></>)}
      </div>

      <AddEditUser
        isCreate={isCreate}
        open={open}
        setOpen={setOpen}
        className={classes.pageTitle}
        handleRequest={handleRequest}
        title={"Create User"}
        editTitle={"Edit user"}
        defaultValue={selectedUser}
        role={role}
        authUsersList={authUsers}
        inviteOrgazationHandler={inviteOrgazationHandler}
      />
      <InviteVendorUser
        openInvite={openInvite}
        setOpenInvite={setOpenInvite}
        className={classes.pageTitle}
        title={"Invite Vendor User"}
        defaultValue={selectedUser}
        role={role}
        authUsersList={authUsers}
        inviteOrgazationHandler={inviteVendorOrgazationHandler}
      />

      <ModalDialogBox
        open={openAlert}
        setOpen={setOpenAlert}
        heading="Error!"
        type="warning"
        text={alertMsg}
        firstBtn={"Yes"}
        handleRequest={() => {
          setOpenAlert(false);
          setAlertMsg("");
        }}
      />

      <ModalDialogBox
        isDelete={isDelete}
        open={openDeleteAlert}
        setOpen={setDeleteAlert}
        type="warning"
        text={alertMsg}
        firstBtn={"Yes"}
        secondBtn={"No"}
        handleRequest={() => handleRequest(selectedUser)}
        handleSecRequest={() => {
          setDeleteAlert(false);
          setAlertMsg("");
          setContractId("");
          selectUser({});
        }}
      />
      <SnackbarModal
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        type={openSnackBarType}
        title=""
        content={snackBarMessage}
        vertical="center"
        horizontal="center"
        width={500}
        height={117}
        className={classes.toastSmall}
        transition="fade"
        actions={[
          {
            label: "Ok",
            handle: () => onSnackbarHandler(openSnackBar),
          },
        ]}
      />
    </>
  );
}