import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useParty, useStreamQueries, useLedger } from "@daml/react";
import { Grid, Typography, Button, IconButton } from "@material-ui/core";
import { InventoryLocation } from "../../services/daml-modules1";
import { useDamlState, useDamlLocation } from "../../context/DamlContext";
import { useLayoutDispatch, setLoading, unsetLoading } from "../../context/LayoutContext";
import { CustomTextField } from "../../components/Inputs";
import { getInventoryLocation } from '../../models/InventoryData';
import { CustomLoading } from "../../hoc/withLoading";
import addIcon from '../../icons/plus-circle-new-icon.svg';
import deleteIcon from "../../icons/new-delete.svg";
import { createInventoryLocation, removeInventoryLocation } from "./handle";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "./styles";


/**
 * Inventory Locations Settings Panel
 * @returns 
 */
export default function LocationsPanel() {
  
  const classes = useStyles();
  const classes1 = useStyles1();

  const hospital = useParty();
  const ledger = useLedger();
  const { contracts, loading } = useStreamQueries(InventoryLocation, () => [{ hospital }]);
  const contractsPayload = contracts.map(c => c.payload);

  const { roleCid } = useDamlState();
  const { locations: locationsContext, setLocation: setLocationContext } = useDamlLocation();
  const layoutDispatch = useLayoutDispatch();

  const [location, setLocation] = useState('');
  const [locationWarning, setLocationWarning] = useState('');
  const [locations, setLocations] = useState([]);

  // console.log("[LocationsPanel]", contracts);


  // handle to click "+"
  const handleAddLocation = () => {
    if (!location) return ;
    const locs = [...locations];
    // check out if the same title is already exist in contracts & locations
    const found = locs.findIndex((loc) => (loc.iinventorylocation === location));
    if (found >= 0) {
      setLocationWarning('The same location exists');
    }
    else {
      locs.push(getInventoryLocation({ iinventorylocation: location }));
      setLocations(locs);
      setLocation('');
    }
  };

  // handle to click delete icon
  const handleDelete = (index) => {
    const locs = [...locations];
    locs.splice(index, 1);
    setLocations(locs);
  };

  // handle to click "Save"
  const handleSave = async () => {
    if (locations.length) {
      // spinner
      setLoading(layoutDispatch);

      let newlocs = [], oldlocs = [];
      // search
      for (let i in locations) {
        const loc = locations[i];
        const found = contractsPayload.findIndex(({iinventorylocation}) => (iinventorylocation === loc));
        if (found === -1 && !loc.created_at) newlocs.push(loc);
      }
      for (let i in contractsPayload) {
        const loc = contractsPayload[i];
        const found = locations.findIndex(({iinventorylocation}) => (iinventorylocation === loc.iinventorylocation));
        if (found === -1) oldlocs.push(contracts[i]);
      }
      console.log("[LocationsPanel] handleSave", newlocs, oldlocs);

      // add
      for (let i in newlocs) {
        const loc = newlocs[i];
        await createInventoryLocation({
          ledger, contractId: roleCid, inventorylocation: loc,
        });
      }
      // remove
      for (let i in oldlocs) {
        const loc = oldlocs[i];
        await removeInventoryLocation({
          ledger, contractId: roleCid, invlcid: loc.contractId,
        });
      }
    }

    // spinner
    unsetLoading(layoutDispatch);
  };

  // handle to click "Cancel"
  const handleCancel = () => {
    setLocations(contractsPayload);
  };


  useEffect(() => {
    if (!loading) {
      const strContext = JSON.stringify(locationsContext);
      const strStream = JSON.stringify(contractsPayload.map(loc => loc.iinventorylocation));
      if (strContext !== strStream) {
        console.log("[LocationsPanel]", strContext, strStream);
        setLocationContext(contracts);
      }
    }
  }, [loading, contractsPayload, locationsContext, contracts, setLocationContext]);
  
  useEffect(() => {
    if (!loading) {
      setLocations(contractsPayload);
    }
  // eslint-disable-next-line
  }, [loading]);


  return (
    <Grid 
      container 
      className={classes1.locationTab} 
      direction="column" 
      justifyContent="space-between" 
      alignItems="stretch"
    >
      {
        loading
        ?
        <CustomLoading />
        :
        <>
          <Grid item>
            <Typography className="header-title">Location</Typography>
          </Grid>
          <Grid item className="body">
            <CustomTextField
              label="Add Location"
              placeholder="Type here.."
              value={location}
              onChange={(val) => {
                setLocation(val);
                setLocationWarning('');
              }}
              warning={locationWarning}
              className="location-text"
              width={221}
              options={{
                labelLeft: true,
                labelWidth: 200,
              }}
            />
            <IconButton className="add-icon" onClick={handleAddLocation}>
              <img alt="" src={addIcon} />
            </IconButton>
            <Grid container className="locations-panel" columns={{ xs: 4, sm: 8, md: 12 }}>
              {
                locations
                .map(({iinventorylocation}, index) => (
                  <Grid item className="location-item" xs={2} sm={4} md={4} key={index}>
                    <Typography>{iinventorylocation}</Typography>
                    <IconButton onClick={() => handleDelete(index)}>
                      <img src={deleteIcon} alt="" />
                    </IconButton>
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
          <Grid item className="actions">
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCancel}
              className={classNames(classes.newCompleteBtn, "secondary-color")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSave}
              className={classes.newCompleteBtn}
            >
              Save
            </Button>
          </Grid>
        </>
      }
    </Grid>
  );
}
