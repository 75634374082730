import React from "react";
import { Checkbox } from "@material-ui/core";
import useStyles from "../styles";

export default function PrefPickSetupSelectAll({ element, dispatch }) {
  const classes = useStyles();

  return (
    <>
      <Checkbox
        checked={ element.category.isChecked}
        onChange={(event) => {
          const newSelected = event.target.checked;
          dispatch({
            type: "select_category",
            payload: {
              categoryId: element.category.id,
              isChecked: newSelected,
            },
          });
        }}
        style={{ marginLeft: "28px" }}
        className={classes.checkboxSelected}
      />
    </>
  );
}
