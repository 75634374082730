import axios from 'axios';
import { auth0, DefaultPassword } from "../../config";
import { hashPassword } from "../util";
import { getToken as getLocalToken } from "./api-node_server";


/**
 * TODO
 * 
 * Some Requirements need here.
 * Task: to implement invited_organization, current_organization
 * 
 * createUser, updateUser
 */

/**
 * get access token in Auth0
 * CORS occurs
 * @returns {Object}
 */
export const getToken = async () => {
    const url = `${auth0.host}oauth/token`;
    const body = {
        "client_id": auth0.client_id,
        "client_secret": auth0.client_secret,
        "audience": `${auth0.host}api/v2/`,
        "grant_type": "client_credentials"
    };
    
    const response = await axios.post(url, body, {
        headers: { 'content-type': 'application/json' }
    });

    console.log("[getToken]", response);

    return response.data ? response.data : {};
};

/**
 * get User List in Auth0
 * @returns {Array}
 */
export const getUsers = async () => {
    const url = `${auth0.host}api/v2/users`;

    const token = await getLocalToken();
    
    const response = await axios.get(url, { headers: { "authorization": `Bearer ${token}` } });

    console.log("[getUsers]", response);

    return response.data ? response.data : [];
};

/**
 * search User List in Auth0 by Email List
 * @param {Array} emails 
 * @returns {Array}
 */
export const searchUsers = async ({
    emails=[],
}) => {
    if (emails.length === 0) return [];

    const url = `${auth0.host}api/v2/users`;

    const token = await getLocalToken();

    const query = emails.map(email => `email: "${email}"`).join(" or ");
    
    const response = await axios.get(url, {
        headers: { "authorization": `Bearer ${token}` },
        params: { q: query },
    });

    console.log("[searchUsers]", response);

    return response.data ? response.data : [];
};

/**
 * get a User in Auth0
 * @param {String} id 
 * @returns {Object}
 */
export const getUser = async ({
    id,
}) => {
    const url = `${auth0.host}api/v2/users/${id}`;

    const token = await getLocalToken();
    
    const response = await axios.get(url, { headers: { "authorization": `Bearer ${token}` } });

    console.log("[getUser]", response);

    return response.data ? response.data : {};
};

/**
 * create a User in Auth0
 * @param {String} email 
 * @param {String} name 
 * @param {String} party 
 * @param {String} password 
 * @param {String} role 
 * @param {String} organization 
 * @param {String} userParty 
 * @param {String} userid 
 * @param {String} gender 
 * @param {String} officephone 
 * @param {String} phone 
 * @param {String} accesslevel 
 * @param {String} jobtitle 
 * @param {String} userstatus 
 * @param {Array} invited_organization 
 * @param {String} current_organization 
 * @returns {Object}
 */
export const createUser = async ({
    email, name, party = "Operator", password = DefaultPassword, role, organization, userParty,
    userid, gender, officephone, phone, accesslevel, jobtitle, userstatus, invited_organization, current_organization
}) => {
    const url = `${auth0.host}api/v2/users`;
    const body = {
        "email": email,
        "email_verified": false,
        "verify_email": false,      // to force password reset
        "connection": "Username-Password-Authentication",
        "name": name,
        "nickname": name,
        "password": password,
        "app_metadata": {
            "daml_ledger_api": {
                "partyIdentifier": party,
                "role": role,
                "organization" : organization,
                "party" : userParty,
                "userid" : userid,
                "gender" : gender,
                "officephone" : officephone,
                "phone" : phone,
                "accesslevel" : accesslevel,
                "jobtitle" : jobtitle,
                "userstatus" : userstatus,
                "passwordHash" : hashPassword(password),
                "invited_organization": invited_organization,
                "current_organization": current_organization
            }
        }
    };

    const token = await getLocalToken();
    
    const response = await axios.post(url, body, {
        headers: { "authorization": `Bearer ${token}` }
    });

    console.log("[createUser]", response);

    return response.data ? response.data : {};
};

/**
 * update a User in Auth0
 * @param {String} id 
 * @param {String} email 
 * @param {String} name 
 * @param {String} party 
 * @param {String} role 
 * @param {String} organization 
 * @param {String} userParty 
 * @param {String} userid 
 * @param {String} gender 
 * @param {String} officephone 
 * @param {String} phone 
 * @param {String} accesslevel 
 * @param {String} jobtitle 
 * @param {String} userstatus 
 * @param {Number} passwordHash 
 * @param {Array} invited_organization 
 * @param {String} current_organization 
 * @returns {Object}
 */
export const updateUser = async ({
    id, email, name, party = "Operator", role, organization, userParty,
    userid, gender, officephone, phone, accesslevel, jobtitle, userstatus, passwordHash, 
    invited_organization, current_organization,
}) => {
    const url = `${auth0.host}api/v2/users/${id}`;
    const body = {
        "email": email,
        "name": name,
        "nickname": name,
        "app_metadata": {
            "daml_ledger_api": {
                "partyIdentifier": party,
                "role": role,
                "organization" : organization,
                "party" : userParty,
                "userid" : userid,
                "gender" : gender,
                "officephone" : officephone,
                "phone" : phone,
                "accesslevel" : accesslevel,
                "jobtitle" : jobtitle,
                "userstatus" : userstatus,
                "passwordHash" : passwordHash,
                "invited_organization": invited_organization,
                "current_organization": current_organization,
            }
        }
    };

    const token = await getLocalToken();
    
    const response = await axios.patch(url, body, {
        headers: { "authorization": `Bearer ${token}` }
    });

    console.log("[updateUser]", response);

    return response.data ? response.data : {};
};

/**
 * Send an email address verification email in Auth0
 * @param {String} id 
 * @returns {Object}
 */
export const sendVerificationEmail = async ({
    id,
}) => {
    const url = `${auth0.host}api/v2/jobs/verification-email`;
    const body = {
        "user_id": id,
        "client_id": auth0.client_id,
    };

    const token = await getLocalToken();
    
    const response = await axios.post(url, body, {
        headers: { "authorization": `Bearer ${token}` }
    });

    console.log("[sendVerificationEmail]", response);

    return response.data ? response.data : {};
};

/**
 * change password of a User in Auth0
 * @param {String} id 
 * @param {String} newpassword
 * @returns {Object}
 */
export const changePassword = async ({
    id, newpassword,
}) => {
    const url = `${auth0.host}api/v2/users/${id}`;
    const body = {
        "password": newpassword,
        "connection":'Username-Password-Authentication'
    };
    console.log("[changePassword]", id, newpassword, body);

    const token = await getLocalToken();
    
    const response = await axios.patch(url, body, {
        headers: { "authorization": `Bearer ${token}` }
    });

    console.log("[changePassword]", response);

    return response.data ? response.data : null;
};

/**
 * delete a User in Auth0
 * @param {String} id 
 * @returns {String}
 */
export const deleteUser = async ({
    id,
}) => {
    const url = `${auth0.host}api/v2/users/${id}`;

    const token = await getLocalToken();

    const response = await axios.delete(url, {
        headers: { "authorization": `Bearer ${token}` }
    });

    console.log("[deleteUser]", response);

    return response.status ? response.status : null;
};

/**
 * Create a password change ticket
 * @param {String} id 
 * @returns {Object}
 */
export const passwordChangeTicket = async ({
    id,
}) => {
    const url = `${auth0.host}api/v2/tickets/password-change`;
    const body = {
        "user_id": id,
        "client_id": auth0.client_id,
        "mark_email_as_verified": true,
    };

    const token = await getLocalToken();
    
    const response = await axios.post(url, body, {
        headers: { "authorization": `Bearer ${token}` }
    });

    console.log("[passwordChangeTicket]", response);

    return response.data ? response.data : {};
};

/**
 * Send an email to change password
 * @param {String} email 
 * @returns {Object}
 */
export const changePasswordEmailSend = async ({
    email,
}) => {
    const url = `${auth0.host}dbconnections/change_password`;
    const body = {
        "client_id": auth0.client_id,
        "email": email,
        "connection": 'Username-Password-Authentication',
    };

    const token = await getLocalToken();
    
    const response = await axios.post(url, body, {
        headers: { "authorization": `Bearer ${token}` }
    });

    console.log("[changePasswordEmailSend]", response);

    return response.data ? response.data : null;
};


/**
 * get a User Last login date and app metadata
 * @param {String} id 
 * @returns {Object}
 */
 export const getUsersLastLoginDate = async (
    id,
) => {
    const url = `${auth0.host}api/v2/users?fields=last_login,app_metadata,name&q=${id}`;

    const token = await getLocalToken();
    
    const response = await axios.get(url, { headers: { "authorization": `Bearer ${token}` } });

    return response.data ? response.data : {};
};