import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  setupButton: {
    marginBottom: '20px',
  },
  topGrid: {
    marginTop: '10px',
    marginBottom: '20px',
  },
  listTitle: {
    margin: '20px',
  },
  divider: {
    height: 20,
    marginLeft: 10,
  },
  bottomGrid: {
    marginTop: 25,
    marginBottom: 30,
  },
  scanButton: {
    marginLeft: 15,
  },
  dialogField: {
    width: '200px',
  },
  pageTitle: {
    display: 'inline-block',
    marginBottom: '15px',
  },
  smallChip: {
    fontSize: '0.65rem',
  },
  centerCell: {
    justifyContent: 'center !important'
  },
  newCheckbox: {
    float: 'right',
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '16px',
    '& .MuiCheckbox-root': {
      padding: '4px 9px',
    },
    '& .MuiCheckbox-colorPrimary.Mui-checked':{
      color:'#183C73'
    }
  },

  paymentLogTitle:{
    fontSize: '20px',
    lineHeight: '29px',
    color: '#1C3C74',
    fontWeight: 'bolder'
  },
  paymentLogHeader: {
    fontWeight:700,
    fontSize: '20px'
  },
  paymentLogBody: {
    padding:'15px'
  },
  backYellow: {
    backgroundColor: 'rgba(255, 152, 0, 0.38)',
  },
  backGreen: {
    backgroundColor: 'rgba(76, 175, 80, 0.38)',
  },
}));