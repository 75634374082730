import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Typography, Accordion, AccordionDetails, AccordionSummary, TableContainer, Paper, Badge, Chip, Button, 
  DialogContentText, Dialog, TextField, FormControl, FormControlLabel, RadioGroup, Radio } from "@material-ui/core";
import { useLayoutDispatch, setLoading, unsetLoading } from "../../context/LayoutContext";
import { ExpandMore } from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';
import downloadIcon from "../../icons/download.svg";
import { useDamlState } from '../../context/DamlContext';
import { formatDate } from "../../services/util";
import { arrayToCsv, downloadCSV } from "../../services/download";
import { checkComplianceStatus, checkComplianceStatusExpiration, 
  checkComplianceStatusSide } from "../../models/SurgicalEventCompliance";
import ProductDetailViewTable from "../surgicalevent/product-detail-view-table";
import ErrorDialog from '../../components/Modals/GeneralModal/error-dialog';
import { PoType, convertPurDataToCSV } from "../../models/Po";
import { convertPODetailToCSV } from "../../models/PODetail";
import { POStatus } from "../../models/POStatus";
import useStyles from "../surgicalevent/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/**
 * SendPO
 * Send PO to Vendor or Send PO to Hospital(Accept or Decline PO)
 * 1. SE PO Contracts in Hospital
 * 2. New PO Contracts in Hospital
 * 3. Received PO Contracts in Vendor
 * @param {Boolean} isSendVendor
 * @param {String} pohospital
 * @param {Boolean} openModal
 * @param {Function} setSendPOOpen
 * @param {Function} handleSendPO
 * @param {Object} selectedPOData
 * @param {Array} productItemsList
 * @param {Object} pocommentdata
 * @param {Function} setConductFormComment
 * @param {Boolean} isDownload
 * @returns 
 */
export default function SendPO ({
  isSendVendor=true, pohospital,
  openModal, setSendPOOpen, handleSendPO, selectedPOData, productItemsList=[],
  pocommentdata={}, setConductFormComment, isDownload=false,
}) {
 
  const classes = useStyles();

  const { party, userParty } = useDamlState();
  const layoutDispatch = useLayoutDispatch();

  // console.log("[SendPO] user", user, pocommentdata);

  const [expanded, setExpanded] = useState(0);
  // error modal
  const [errorModal, setErrorModal] = useState(false);


  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // handle to send PO
  const sendPO = async () => {
    // spinner
    setLoading(layoutDispatch);

    await handleSendPO((cb) => {
      unsetLoading(layoutDispatch);
      if (typeof cb === 'function') {
        const result = cb();
        if (!result) setErrorModal(true);
      }
    });
  };

  const handleDownloadClick = async (e) => {
    const tempPOdata = {...selectedPOData, hospital: pohospital};
    let {header, data} = convertPurDataToCSV(tempPOdata);
    let csvData = arrayToCsv(header, data);
    console.log("[SendPO] handleDownloadClick", tempPOdata, header, data, csvData);
    ({header, data} = convertPODetailToCSV(productItemsList));
    csvData = csvData + '\n' + arrayToCsv(header, data);
    console.log("[SendPO] handleDownloadClick", productItemsList, header, data, csvData);
    const filename = tempPOdata ? tempPOdata.popurchaseorderid : 'PO';
    downloadCSV(csvData, filename);
  };

  const handleCancel = () => {
    setSendPOOpen(false);
    unsetLoading(layoutDispatch);
  };


  useEffect(() => {
    if (openModal) {
      console.log("[SendPO] Open SendPO dialog.");
      setExpanded(0);
      setErrorModal(false);
    }
  }, [openModal]);


  return (
    <>

      <Dialog
        className={classes.customRoot}
        open={openModal}
        // onClose={()=>{setSendPOOpen(false)}}
        aria-labelledby="draggable-dialog-title"
      >
        <h2 className={classes.poformSubHeading}>
          {
            isSendVendor ? 'Send Purchase Order' : 'Accept or Decline Purchase Order'
          }
        </h2>

        <div className="formsteps"> 
          <div className={classes.mainFormSteps}>
        
          <DialogContentText component={'div'} className="modelStepDetai">
            <div className="viewDetailsSe">

            <h2 className={classes.formSubHeading}>PO Overview</h2>
            <div className="grid-list grid-list-flex">
              <div className="list-item">
                <div className="label">PO Date</div>
                <div className="value">{formatDate(selectedPOData.podate)}</div>
              </div>
              {isSendVendor === false ? 
              <>
                <div className="list-item">
                    <div className="label">Hospital</div>
                    <div className="value">{pohospital}</div>
                </div>
                <div className="list-item">
                  <div className="label">Ownership</div>
                  <div className="value">{selectedPOData.popurchaseordertype}</div>
                </div>
              </>
              :
              <div className="list-item">
                <div className="label">PO Distributor</div>
                <div className="value">{selectedPOData.povendorid}</div>
              </div>
              }
              <div className="list-item">
                <div className="label">Status</div>
                <div className="value">{selectedPOData.postatus && POStatus[selectedPOData.postatus]}</div>
              </div>
              <div className="list-item">
                <div className="label">PO Type</div>
                <div className="value">{selectedPOData && PoType[selectedPOData.potype]}</div>
              </div>
            </div>
            <div className="grid-list grid-list-flex">
              {isDownload && 
              <div className="list-item">
                <div className="label">Attachment</div>
                <div className="value">
                  <button className={classNames(classes.printButton, classes.leftButton)} 
                    onClick={handleDownloadClick}
                  >
                    <img src={downloadIcon} alt="Download" />Download
                  </button>
                </div>
              </div>}
            </div>


            {productItemsList.length !== 0 && 
              <div> 
                <div className={classes.productDetails}>
                  <h2 className={classes.formSubHeading}>Total Added Product 
                  <Badge badgeContent={selectedPOData.purchaseorderdetaildataproducts} color="primary" overlap="rectangular"></Badge>
                  <span className={classes.productTableHeading}><span className="price-label">Total Price of Products:</span> ${selectedPOData.pototalamount}</span>
                  </h2>
                </div>
                
                <div className={classes.productAccordion}>
                  {productItemsList.map(({ podproduct: obj, podnotes, podquantity },index)=>(
                    <Accordion 
                      key={index} 
                      expanded={expanded === (index+1)} 
                      onChange={handleChangeAccordion((index+1))} 
                      className={classes.productMainAccordion}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                          aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className="product-detail-accordion-head"
                      >
                      
                        <Typography className={classes.accordionHeading}>
                          <span className="text-inactive">Reference Number :</span> <span className="text-active">{obj.sepreferencenumber}</span> 
                        </Typography>
                        <Typography className={classes.accordionHeading}>
                          <span className="text-inactive">Lot/SN :</span> <span className="text-active">{obj.seplotcode}</span> 
                        </Typography>
                        <Typography className={classes.accordionHeading}>
                          <span className="text-inactive">Qty :</span> <span className="text-active">{podquantity}</span> 
                        </Typography>
                        {
                          productItemsList[index].pricechange === true
                          && <Chip label="Price Changed" className="bg-info-price-change" />
                        }
                        {
                          checkComplianceStatus({surgicalevent: selectedPOData, auditedproductdata: obj}) !== ''
                          && <Chip label="Compliance" className="bg-info-light" />
                        }
                      
                      </AccordionSummary>

                      <AccordionDetails>
                        <Typography component={'div'}>
                          <TableContainer  className={classes.productTableContainer} component={Paper}>
                            {
                              productItemsList[index].pricechange === true &&
                              <div className="price-change"> 
                                <Alert severity="info" > The Price has been changed from the contract pricing please check before 
                                Accepting the PO</Alert> 
                              </div>
                            }
                            {
                              checkComplianceStatusSide(selectedPOData, obj) &&  
                              <Alert severity="info"><strong>Compliance</strong>: Product is the incorrect Side based on the Surgical Procedure</Alert> 
                            }
                            {
                              checkComplianceStatusExpiration(selectedPOData, obj) &&  
                              <Alert severity="info"><strong>Compliance</strong>: Product is past expiration date</Alert> 
                            }

                            <ProductDetailViewTable 
                              obj={obj}
                              options={{
                                hiddenParams: ['sepdistributor'],
                                extraParams: [
                                  {label: 'Product Note', value: podnotes, className: classes.tableRowBorderTop}
                                ],
                                redParams: (productItemsList[index].pricechange ? 
                                  ['sepproductprice'] : []),
                              }}
                            />
                          </TableContainer>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}

                </div> 
              </div>
            }

            {!isSendVendor && 
              <div>
                <FormControl 
                  variant="outlined" 
                  className="radio-btn" >   
                  <div className={classes.customCheckbox}>
                    <RadioGroup row aria-label="position" name="position" defaultValue="top">
                      <FormControlLabel value="Accepted" 
                        control={<Radio color="primary" checked={pocommentdata.status === 'Accepted'} />} 
                        className={classes.customRadio} 
                        label="I Accept" 
                        onChange={(e) => {
                          setConductFormComment({
                            ...pocommentdata,
                            status: e.target.value,
                            user: userParty ? userParty : party, // set username as user
                          });
                        }} 
                      />
                      <FormControlLabel value="Rejected" 
                        control={<Radio color="primary" checked={pocommentdata.status === 'Rejected'} />} 
                        className={classes.customRadio} 
                        label="I Decline"
                        onChange={(e) => {
                          setConductFormComment({
                            ...pocommentdata,
                            status: e.target.value,
                            user: userParty ? userParty : party, // set username as user
                          });
                        }} 
                      />
                    </RadioGroup>
                  </div>
                </FormControl> 
              </div>
            }

            {!isSendVendor && 
              <div className={classes.marginBM20}>
                <TextField
                  id="outlined-full-width"
                  label="User Name"
                  style={{ margin: 8 }}
                  className={classes.customFullWidth}
                  margin="normal"
                  value={pocommentdata.user}
                  // onChange={(e) => setConductFormComment({ ...pocommentdata, user: e.target.value })}
                  InputLabelProps={{
                      shrink: true,
                  }}
                  variant="outlined"
                />
              </div> 
            }

            <div className={classes.marginBM30}>
              <TextField
                id="outlined-full-width-1"
                label="Comment"
                style={{ margin: 8 }}
                className={classes.customFullWidth}
                margin="normal"
                value={pocommentdata.note}
                onChange={(e) => setConductFormComment({ ...pocommentdata, note: e.target.value })}
                InputLabelProps={{
                    shrink: true,
                }}
                variant="outlined"
              />
            </div> 
            
            </div>
          </DialogContentText>

          </div>
        </div>
        
        <div className="form-footer">
          <div className="right-buttons">
            <Button autoFocus onClick={handleCancel} className="noBtn" variant="outlined"  color="primary">
              Cancel
            </Button>
            <Button onClick={sendPO} className="yesBtn" variant="contained" color="primary">
              Send
            </Button>
          </div>
        </div>
      
      </Dialog>

      <ErrorDialog
        openModal={errorModal}
        setOpenModal={setErrorModal}
      />

    </>
  );
}