import React, { /* useCallback, */ useState } from "react";
import classNames from "classnames";
import { useLedger } from "@daml/react";
import { contractFilter1 } from "../../services/daml-filters";
import Contracts from "../../components/Contracts/Contracts1";
import { SurgicalStatus, checkEditableSE } from "../../models/SurgicalEvent";
import { cancelSurgicalEvent } from "./handle";
import { useDamlState } from "../../context/DamlContext";
import useStyles from "./styles";
import withLoading from "../../hoc/withLoading";
import withFilters from "../../hoc/withFilters";
import SnackbarModal from "../../components/Modals/SnackbarModal";
import { Grid } from "@material-ui/core";
import { useTranslation } from 'react-i18next';



/**
 * SurgicalEvent List Contracts
 * @param {Object} searchParams 
 * @param {Boolean} isIncludeHistory 
 * @param {Array} allContracts 
 * @param {Array} historyContracts 
 * @param {Function} setArchived
 * @param {Function} handleEditSE
 * @param {Function} setChecked
 * @param {Function} handleRollOver
 * @returns 
 */
function SurgicalEventListContracts({
  searchParams = {},
  isIncludeHistory = false,
  allContracts = [],
  historyContracts = [],
  setArchived = null,
  handleEditSE = null,
  setChecked = null,
  handleRollOver = null,
}) {

  const classes = useStyles();

  const ledger = useLedger();
  const { roleCid } = useDamlState();


  allContracts = allContracts.concat(isIncludeHistory && historyContracts.length ?
    historyContracts.map(h => ({ ...h, payload: h.payload.surgicalevent })) : []);
  let contracts = contractFilter1(allContracts, searchParams);


  contracts = contracts
    .map(c => {
      // add visual_status into every contract
      c.payload.surgicaleventdata.visual_status = SurgicalStatus[c.payload.surgicaleventdata.status];
      /* if (currentStatus && 'edit' in currentStatus && c.contractId in currentStatus.edit) {
        c.payload.surgicaleventdata.visual_status = 'Pending';
      } */
      if (c.payload.surgicaleventdata.status === 'Scheduled'
        && c.payload.surgicaleventproductdata.length
        // && !!c.payload.surgicaleventdata.checked
      ) {
        c.payload.surgicaleventdata.visual_status = 'Pending';
      }
      // add Physician Name, Patient Name
      c.payload.surgicaleventdata.patientname = `${c.payload.surgicaleventdata.patientfirstname} ${c.payload.surgicaleventdata.patientlastname}`;
      c.payload.surgicaleventdata.physicianname = `${c.payload.surgicaleventdata.physicianfirstname} ${c.payload.surgicaleventdata.physicianlastname}, ${c.payload.surgicaleventdata.physiciansalutation || ""}`;
      return c;
    });

  console.log("[SurgicalEventListContracts]", searchParams, contracts);
  // console.log("[SurgicalEventListContracts] assetsHistory:", assetsHistory.contracts);


  const [openWarning, setOpenWarning] = useState(false);
  const [confirmModalContract, setConfirmModalContract] = useState({});


  const handleCancelSE = (c) => {
    console.log("[handleCancelSE]", c);
    setConfirmModalContract(c);
    setOpenWarning(true);
  };

  const handleModalYes = () => {
    cancelSurgicalEvent({
      contractId: confirmModalContract.contractId,
      payload: confirmModalContract.payload,
      ledger,
      roleCid,
    });
    setOpenWarning(false);
  }
  const { t } = useTranslation();

  return (
    <>

      <Contracts
        className={classes.customRoot}
        contracts={contracts}
        columns={[
          // ["ContractId", "contractId"],
          ["SE Id", "payload.surgicaleventdata.seid", {
            type: "link",
            search: {
              type: "AdaptiveSearch",
              name: "surgicaleventdata.seid",
              getValue: (c) => c.payload.surgicaleventdata.seid,
              contracts: allContracts,
            },
            options: {
              // width: 150,
              flex: 1,
              headerAlign: 'center',
              align: 'center',
              cellClassName: classNames(classes.staticCellelement, classes.primaryColor),
              headerClassName: classes.staticCellelement,
            },
          }],
          ["MRN", "payload.surgicaleventdata.mrn", {
            search: {
              type: "AdaptiveSearch",
              name: "surgicaleventdata.mrn",
              getValue: (c) => c.payload.surgicaleventdata.mrn,
              contracts: allContracts,
            },
            options: {
              // width: 180,
              flex: 1,
            },
          }],
          ["Event Date", "payload.surgicaleventdata.eventdate", {
            search: {
              type: "DateSearch",
              name: "surgicaleventdata.eventdate",
            },
            options: {
              // width: 190,
              flex: 1.3,
            },
          }],
          ["Patient Name", "payload.surgicaleventdata.patientname", {
            search: {
              type: "AdaptiveSearch",
              name: "surgicaleventdata.patientname",
              getValue: (c) => c.payload.surgicaleventdata.patientname,
              contracts: allContracts,
            },
            options: {
              flex: 1.8,
            },
          }],
          ["Physician Name", "payload.surgicaleventdata.physicianname", {
            search: {
              type: "AdaptiveSearch",
              name: "surgicaleventdata.physicianname",
              getValue: (c) => c.payload.surgicaleventdata.physicianname,
              contracts: allContracts,
            },
            options: {
              flex: 1.8,
            },
          }],
          ["Procedure", "payload.surgicaleventdata.surgicalprocedure", {
            search: {
              type: "AdaptiveSearch",
              name: "surgicaleventdata.surgicalprocedure",
              getValue: (c) => c.payload.surgicaleventdata.surgicalprocedure,
              contracts: allContracts,
            },
            options: {
              flex: 1.8,
            },
          }],
          ["Products", "payload.surgicaleventproductdata", {
            type: "object",
            options: {
              // width: 140,
              flex: 1,
              headerAlign: 'center',
              align: 'center',
            },
          }],
          ["Status", "payload.surgicaleventdata.visual_status", {
            type: "render",
            render: (param) =>
              <Grid container alignItems="center">
                <Grid item xs={2} style={{ marginTop: "2px" }}>
                  <svg height="10" width="10">
                    <circle cx="5" cy="5" r="5"
                      fill={(param.value === 'Completed')
                        ? 'rgba(76, 175, 80)'
                        : (param.value === 'Cancelled' ? '#F44336'
                          : (param.value === 'Pending' ? '#FEB139' : 'rgba(33, 150, 243)'))} />
                  </svg>
                </Grid>
                <Grid item xs={10}>
                  {param.value}
                </Grid>
              </Grid>,
            search: {
              type: "SelectSearch",
              name: "surgicaleventdata.status",
              options: Object.keys(SurgicalStatus).map(value => ({ label: SurgicalStatus[value], value: value })),
            },
            options: {
              // width: 150,
              flex: 1,
              headerAlign: 'center',
              align: 'center',
            },
          }],
        ]}

        actions={[
          [
            "Actions",
            [
              [["Cancel",
                checkEditableSE],
                handleCancelSE],
            ],
            {
              width: 150,
              flex: 1,
              headerAlign: 'center',
              checkVisible: checkEditableSE,
            }
          ]
        ]}

        options={{
          statusEmpty: (allContracts.length === 0),
          checkbox: true,
        }}

        handleArchived={setArchived}
        handleClicked={(e) => handleClickedContract(e, handleEditSE)}
        handleChecked={(e) => handleCheckedContract(e, contracts, setChecked)}
      // handleOver={(e) => handleOverContract(e, handleRollOver)}
      />

      <SnackbarModal
        open={openWarning}
        setOpen={setOpenWarning}
        type="warning"
        title=""
        content={t('warnings.surgicalevent.surgicaleventcancle')}
        vertical="center"
        horizontal="center"
        width={345}
        height={150}
        transition="fade"
        options={{
          validateULE: true,
        }}
        actions={[
          {
            label: 'Yes',
            handle: handleModalYes,
          },
          {
            label: 'No',
          }
        ]}
      />

    </>
  );
}

const SEListContractsWithLoading = withLoading(SurgicalEventListContracts);
export default withFilters(SEListContractsWithLoading)([
  { name: "surgicaleventdata.seid", label: "SE ID" },
  { name: "surgicaleventdata.mrn", label: "MRN" },
  { name: "surgicaleventdata.eventdate", label: "Date Range", type: "date" },
  { name: "surgicaleventdata.patientname", label: "Patient Name" },
  { name: "surgicaleventdata.physicianname", label: "Physician Name" },
  { name: "surgicaleventdata.surgicalprocedure", label: "Procedure" },
  { name: "surgicaleventdata.status", label: "Status" },
  { name: "surgicaleventproductdata.product.sepproductdescription", label: "Description" },
  { name: "surgicaleventproductdata.product.seplotcode", label: "Lot code" },
  { name: "surgicaleventproductdata.product.sepreferencenumber", label: "Ref number" },
]);

export const handleCheckedContract = (e, contracts, setChecked, key = 'contractId') => {
  // console.log("[handleCheckedContract]", e, contracts);
  if (typeof setChecked === 'function') {
    const ids = e;
    const selected = contracts.filter(c => ids.indexOf(c[key]) !== -1)
      .map(c => c.payload);
    setChecked(selected);
  }
};

export const handleClickedContract = (e, func) => {
  const contract = ('row' in e && 'contract' in e.row) ? e.row.contract : {};
  // console.log("[handleClickedContract]", contract);
  if (typeof func === 'function' && 'contractId' in contract) {
    func(contract);
  }
};

export const handleOverContract = (e, func) => {
  const contract = (e && 'row' in e && 'contract' in e.row) ? e.row.contract : {};
  if (typeof func === 'function') {
    if ('contractId' in contract) {
      func(contract);
    }
    else {
      func(null);
    }
  }
};