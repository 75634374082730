import React, { useState } from "react";
import classNames from "classnames";
import { useLedger } from "@daml/react";
import { Link, Grid } from "@material-ui/core";
import Contracts from "../../components/Contracts/Contracts1";
import SnackbarModal from "../../components/Modals/SnackbarModal";
import { checkNotPOHistory, InventoryPOStatus } from '../../models/Po';
import { isEnableAcceptDecline, isEnableCreateInvoice, POVStatus } from '../../models/POStatus';
import { POOwnerships } from "../../models/Ownership";
import { contractFilter1 } from "../../services/daml-filters";
import { getTodayString } from "../../services/util";
import { useDamlState } from "../../context/DamlContext";
import withLoading from "../../hoc/withLoading";
import withFilters from "../../hoc/withFilters";
import { handleCheckedContract, handleClickedContract } from '../surgicalevent/surgicaleventlistcontracts1';
import { ProductStatusComp } from "./purchaseorderlistcontracts1";
import { createInvoice, handlePOStatus } from "./handle";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "./styles";
import { getColorCode } from '../../models/POStatus';
import { useTranslation } from 'react-i18next';

/**
 * ReceivedPoList Contracts in Vendor
 * remove all parameters & searches & contracts for Surgical(PPV)
 * @param {Object} searchParams 
 * @param {Boolean} isIncludeHistory 
 * @param {Array} allContracts 
 * @param {Array} historyContracts 
 * @param {Function} setArchived
 * @param {Function} setChecked
 * @param {Function} handleEditPO
 * @returns 
 */
function ReceivedPoListContracts({
  searchParams = {},
  isIncludeHistory = false,
  allContracts = [],
  historyContracts = [],
  setArchived = null,
  setChecked = null,
  handleEditPO = null,
}) {

  const classes = useStyles();
  const classes1 = useStyles1();

  const ledger = useLedger();

  const { roleCid, party, userParty, userlistPayload } = useDamlState();

  const { t } = useTranslation();
  allContracts = allContracts.concat(isIncludeHistory && historyContracts.length ?
    historyContracts.map(h => ({ ...h, payload: h.payload.purchaseordervendor })) : []);
  let contracts = contractFilter1(allContracts, searchParams);
  console.log("[ReceivedPoListContracts]", searchParams, contracts);

  // Toast for Accepted
  const [openToast, setOpenToast] = useState(false);
  const [openToast1, setOpenToast1] = useState(false);
  const [toastContent, setToastContent] = useState('');
  const [toastContent1, setToastContent1] = useState('');
  // Toast for Rejected
  const [openToastWarning, setOpenToastWarning] = useState(false);
  const [warningContent, setWarningContent] = useState('');
  const [snackbarModel, setSnackbarModel] = useState(false);
  const [confirmToast, setconfirmToast] = useState(false);
  const [contract, setcontract] = useState();

  // handle to accept warning
  const handleAcceptRequest = (c) => {
    setSnackbarModel(true);
    setcontract(c);
  };


  // Click "Accept" button in PO list
  const handleAccept = async (c) => {
        // send PO to Hospital
        await handlePOStatus({
          pocomment: {
            date: getTodayString(),
            user: (userParty ? userParty : party),
          },
          postatus: 'Accepted',
          poHospital: c.payload.hospital,
          ledger,
          ContractId: c.contractId,
          roleCid,
          email: userlistPayload?.email,
          username: userlistPayload?.name,
        });
      // toast
      setToastContent1(`${t('success.purchaseorder.receivedpo.accept-po')}`);
      setOpenToast1(true);
  };


  // handle to decline warning
  const handleDeclineRequest = (c) => {
    setconfirmToast(true);
    setcontract(c);
  };

  // Click "Decline" button in PO list
  const handleDecline = async (c) => {
    // send PO to Hospital
    await handlePOStatus({
      pocomment: {
        date: getTodayString(),
        user: (userParty ? userParty : party),
      },
      postatus: 'Rejected',
      poHospital: c.payload.hospital,
      ledger,
      ContractId: c.contractId,
      roleCid,
      email: userlistPayload?.email,
      username: userlistPayload?.name,
    });
    // toast
    setWarningContent(`Purchase Order Declined.`);
    setOpenToastWarning(true);

  };

  // Click "Create Invoice" in list
  const handleCreateInvoice = async (c) => {
    console.log("[ReceivedPoListContracts] handleCreateInvoice", c);
    // create invoice
    await createInvoice({
      contractId: c.contractId,
      payload: c.payload,
      ledger,
      roleCid,
      userlistPayload,
    });
    // toast
    setToastContent(["Invoice successfully created.", "The facility has been alerted."]);
    setOpenToast(true);
  };


  return (
    <>
      <Contracts
        className={classes.customRoot}
        contracts={contracts}
        columns={[
          ["PO Number", "payload.purchaseorderdata.popurchaseorderid", {
            type: 'link',
            search: {
              type: "AdaptiveSearch",
              name: "purchaseorderdata.popurchaseorderid",
              getValue: (c) => c.payload.purchaseorderdata.popurchaseorderid,
              contracts: allContracts,
            },
            options: {
              flex: 0.8,
              cellClassName: classes.nobreak
            }
          }],
          ["SE Id", "payload.purchaseorderdata.poseid", {
            type: 'render',
            render: (param) => (
              param.value
                ?
                <Link component="button" variant="body2">{param.value}</Link>
                :
                "N/A"
            ),
            search: {
              type: "AdaptiveSearch",
              name: "purchaseorderdata.poseid",
              getValue: (c) => c.payload.purchaseorderdata.poseid,
              contracts: allContracts,
            },
            options: {
              flex: 1.1,
              cellClassName: classes.nobreak
            }
          }],
          ["PO Date", "payload.purchaseorderdata.podate", {
            search: {
              type: "DateSearch",
              name: "purchaseorderdata.podate",
            },
            options: {
              flex: 1,
            }
          }],
          ["Ownership", "payload.purchaseorderdata.popurchaseordertype", {
            search: {
              type: "SelectSearch",
              name: "purchaseorderdata.popurchaseordertype",
              options: Object.keys(POOwnerships).map(value => ({ label: POOwnerships[value], value: value })),
            },
            options: {
              flex: 1,
            }
          }],
          ["Facility", "payload.hospital", {
            search: {
              type: "AdaptiveSearch",
              name: "hospital",
              getValue: (c) => c.payload.hospital,
              contracts: allContracts,
            },
            options: {
              flex: 1,
            },
          }],
          ["PO Status", "payload.purchaseorderdata.postatus", {
            type: "render",
            render: (param) =>
              <Grid container alignItems="center">
                <Grid item xs={2} style={{ marginTop: "2px" }}>
                  <svg height="10" width="10">
                    <circle cx="5" cy="5" r="5"
                      fill={getColorCode(param.value)} />
                  </svg>
                </Grid>
                <Grid item xs={10}>
                  {POVStatus[param.value]}
                </Grid>
              </Grid>,
            search: {
              type: "SelectSearch",
              name: "purchaseorderdata.postatus",
              options: Object.keys(POVStatus).map(value => ({ label: POVStatus[value], value: value })),
              maxColCnt: 5,
              classes: {
                popoverInner: classes1.newInnerPopper1,
              },
            },
            options: {
              flex: 1,
            }
          }],
          ["Product Status", "payload.purchaseorderdata.poinventorystatus", {
            type: "render",
            render: (param) => (<ProductStatusComp value={param.value} classes={classes1} />),
            search: {
              type: "SelectSearch",
              name: "purchaseorderdata.poinventorystatus",
              options: Object.keys(InventoryPOStatus).map(value => ({ label: InventoryPOStatus[value], value: value })),
              maxColCnt: 5,
              classes: {
                popoverBox: classes1.newPopoverBox,
                popoverInner: classes1.newInnerPopper,
              },
            },
            options: {
              flex: 1,
            }
          }],
          ["Total", "payload.purchaseorderdata.pototalamount", {
            options: {
              beforeText: '$',
              flex: 0.8,
            }
          }],
        ]}

        actions={[
          [
            "Actions",
            [
              [["Accept",
                (c) => (isEnableAcceptDecline(c.payload.purchaseorderdata.postatus) && checkNotPOHistory(c))],
                handleAcceptRequest],
              [["Decline",
                (c) => (isEnableAcceptDecline(c.payload.purchaseorderdata.postatus) && checkNotPOHistory(c))],
                handleDeclineRequest],
              [["Create Invoice",
                (c) => (isEnableCreateInvoice(c.payload.purchaseorderdata.postatus) && checkNotPOHistory(c))],
                handleCreateInvoice],
              // [["Archive PO", 
              //   (c) => (isEnableArchive(c.payload.purchaseorderdata.postatus) && checkNotPOHistory(c))],
              //   handleArchivePOV],
            ],
            {
              headerAlign: 'center',
              checkVisible: (c) => ((isEnableAcceptDecline(c.payload.purchaseorderdata.postatus)
                || isEnableCreateInvoice(c.payload.purchaseorderdata.postatus))
                && checkNotPOHistory(c)),
              classes: {
                tooltip: classes.newAddedTooltip1
              }
            }
          ]
        ]}
        options={{
          statusEmpty: (allContracts.length === 0),
          checkbox: true,
        }}

        handleArchived={setArchived}
        handleClicked={(e) => handleClickedContract(e, handleEditPO)}
        handleChecked={(e) => handleCheckedContract(e, contracts, setChecked)}
      />

      <SnackbarModal
        open={openToast1}
        setOpen={setOpenToast1}
        title={<h1 className={classes1.successTitle}>Successfully Accepted</h1>}
        content={toastContent1}
        width={450}
        height={100}
        className={classes1.toastContents}
      />

      <SnackbarModal
        open={openToast}
        setOpen={setOpenToast}
        title=""
        content={<><h2>{toastContent[0]}</h2><h2>{toastContent[1]}</h2></>}
        width={400}
        height={100}
        className={classes1.toastContent1}
      />

      <SnackbarModal
        open={openToastWarning}
        setOpen={setOpenToastWarning}
        type="error"
        title=""
        content={warningContent}
        className={classes.toastMedium}
        width={450}
        height={100}

      />
      <SnackbarModal
        open={snackbarModel}
        setOpen={setSnackbarModel}
        type="warning"
        title={'Warning'}
        content={t('warnings.purchaseorder.receivedpo.acceptpo')}
        vertical="center"
        horizontal="center"
        width={345}
        height={150}
        transition="fade"
        contract={contract}
        className={classNames(classes.toastLarge, classes.toastWarning)}
        options={{
          delay: 500,
          validateULE: true,
        }}
        actions={[
          {
            label: 'Yes',
            handle: () => {
              handleAccept(contract);
            },
          },
          {
            label: 'No',
            handle: () => {
              setSnackbarModel(false);
            },
          }
        ]}
      />
      <SnackbarModal
        open={confirmToast}
        setOpen={setconfirmToast}
        type="warning"
        title={'Warning'}
        content={t('warnings.purchaseorder.receivedpo.declinepo')}
        vertical="center"
        horizontal="center"
        width={345}
        height={150}
        transition="fade"
        contract={contract}
        className={classNames(classes.toastLarge, classes.toastWarning)}
        options={{
          delay: 500,
          validateULE: true,
        }}
        actions={[
          {
            label: 'Yes',
            handle: () => {
              handleDecline(contract);
            },
          },
          {
            label: 'No',
            handle: () => {
              setconfirmToast(false);
            },
          }
        ]}
      />
    </>
  );
}

const ReceivedPoListContractsWithLoading = withLoading(ReceivedPoListContracts);
export default withFilters(ReceivedPoListContractsWithLoading)([
  { name: "purchaseorderdata.poseid", label: "SE ID" },
  { name: "purchaseorderdata.popurchaseorderid", label: "PO Number" },
  { name: "purchaseorderdata.podate", label: "PO Date", type: "date" },
  { name: "purchaseorderdata.popurchaseordertype", label: "Ownership" },
  { name: "hospital", label: "Facility" },
  { name: "purchaseorderdata.postatus", label: "PO Status" },
  { name: "purchaseorderdata.poinventorystatus", label: "Product Status" },
]);
