import React, { useState } from "react";
import { useParty, useStreamQueries } from "@daml/react";
import { Grid, Typography, Button } from "@material-ui/core";
import { InvoiceHospital, InvoiceHospitalHistory, VendorTemplate } from "../../services/daml-modules1";
import ReceivedInvoiceListContracts from "./receivedinvoicelistcontracts1";
import useStyles from "./styles";
import { pdf } from "@react-pdf/renderer";
import InvoicePDF from "./invoice-pdf";
import ExportPopover from "../surgicalevent/export-popover";
import classNames from "classnames";
import useStyles1 from "../surgicalevent/styles";
import exportIcon from "../../icons/new-export-icon.svg";
import { blobToDownload } from "../../services/download";

/**
 * Received Invoice List page in Hospital
 * @returns 
 */
export default function ReceivedInvoiceList() {

  const classes = useStyles();
  const classes1 = useStyles1();
  const hospital = useParty();

  const { contracts, loading } = useStreamQueries(InvoiceHospital, () => [{ hospital }]);
  const { contracts: contractsHistory } = useStreamQueries(InvoiceHospitalHistory, () => [{ hospital }]);
  const { contracts: vendortemplate } = useStreamQueries(VendorTemplate, () => [{ hospital }]);

  const [isHistory, setHistory] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState([]);
  
  const handleOpenPopover = (event) => {
    if(selectedInvoice.length>0)
    setAnchorEl(event.currentTarget);
  }
  const [anchorEl, setAnchorEl] = useState(null);

  const handleExportPDF=async()=>{
    const selectedInvoiceWithDetails=contracts.filter(c=>selectedInvoice.includes(c.contractId));
    const blob1 = await pdf((
      <InvoicePDF data={selectedInvoiceWithDetails} or="landscape"/>
    )).toBlob();
    blobToDownload(blob1, `${selectedInvoiceWithDetails[0].payload.invoicedata.invinvoicenumber} ${selectedInvoiceWithDetails.length > 1 ? "(+" + (selectedInvoiceWithDetails.length - 1) + ")" : ""}_landscape.pdf`);

    const blob2 = await pdf((
      <InvoicePDF data={selectedInvoiceWithDetails}  or="potrait"/>
    )).toBlob();

    blobToDownload(blob2, `${selectedInvoiceWithDetails[0].payload.invoicedata.invinvoicenumber} ${selectedInvoiceWithDetails.length > 1 ? "(+" + (selectedInvoiceWithDetails.length - 1) + ")" : ""}_potrait.pdf`);
  }


  return (
    <>

      <Grid container>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h4" className={classNames(classes.pageTitle, classes1.newPageTitle)}>Received Invoices</Typography>
            </Grid>
            <Grid item>
              <Button
                className={classNames(classes1.newCreateButton, classes1.newIconButton)}
                variant="contained"
                onClick={handleOpenPopover}
                startIcon={<img alt="" src={exportIcon} />}
              />
              <ExportPopover
                anchor={anchorEl}
                setAnchor={setAnchorEl}
                handleExportPDF={handleExportPDF}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ReceivedInvoiceListContracts
            allContracts={contracts}
            historyContracts={contractsHistory}
            isIncludeHistory={isHistory}
            setArchived={setHistory}
            setChecked={setSelectedInvoice}
            vendortemplates={vendortemplate}
            loading={loading}
          />
        </Grid>
      </Grid>
    </>
  );
}
