import { CONTRACT_SET, CONTRACT_SET_ALL, CONTRACT_SET_DATA } from "./constants";

export const setContract = (payload) => ({
  type: CONTRACT_SET,
  payload
});

export const setContractAll = (payload) => ({
  type: CONTRACT_SET_ALL,
  payload
});

export const setContractData = (payload) => ({
  type: CONTRACT_SET_DATA,
  payload
});
