import React, { useState } from "react";
import classNames from "classnames";
import {
    Grid, Button, Dialog, DialogContent, DialogTitle, Typography
} from "@material-ui/core";
import useStyles from "../surgicalevent/styles";
import closeIcon from "../../icons/new-close-icon.svg";
import { CustomSelect, CustomTextField } from "../../components/Inputs";
import { Salutations } from "../../models/Contact";
import SnackbarModal from "../../components/Modals/SnackbarModal";
import { useTranslation } from 'react-i18next';


/**
 * Create User Dialog
 * @param {Boolean} open 
 * @param {Function} setOpen
 * @param {String} title 
 * @param {String} role
 * @returns 
 */
export default function AddSurgeon({
    open,
    setOpen,
    handleRequest,
    defaultValue = {},
    role,
}) {

    const classes = useStyles();
    const [snackbarModel, setSnackbarModel] = useState(false);
    const [dataItems, setDataItems] = useState({});
    const [isChange, setIsChange] = useState(false);
    const { t } = useTranslation();
    const handleChange = (value, key) => {
        setIsChange(true)
        setDataItems({
            ...dataItems,
            [key]: value,
        });
    };

    // handle when click "Submit" or "Update" button
    const handleSave = async () => {
        await handleRequest(dataItems);
    };

    const handleCloseRequest = () => {
        if ( isChange) {
          setSnackbarModel(true);
        }
        else {
            setOpen(false);
        }
      }

    return (
        <>
        <Dialog
            open={open}
            onClose={() => { setOpen(false) }}
            className={classes.customRoot}
            disableEnforceFocus
            aria-labelledby="draggable-dialog-title"
            maxWidth='xs'
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >

                <Button
                    className={classes.closeButton}
                    onClick= { handleCloseRequest }
                >
                    <img src={closeIcon} alt="" />
                </Button>
            </Grid>
            <DialogTitle className='MuiDialogTitleRoot'>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                // className={classes1.newDIATitlePanel}
                >
                    <Typography variant="inherit" component="span" >
                        Add Surgeon
                    </Typography>
                </Grid>

            </DialogTitle>
            <DialogContent
                dividers={true}
            >
                <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={4}>
                        <label className={classes.popUpLabel}>Salutation</label>
                    </Grid>
                    <Grid item xs={8}>
                        <CustomSelect
                            required={true}
                            value={dataItems.salutation || ""}
                            onChange={(val) => handleChange(val, 'salutation')}
                            items={Salutations}
                            placeholder="Select One"
                        //   warning={surgicalError.physiciansalutation}
                        //   width={160}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <label className={classes.noteTitle}>First Name</label>
                    </Grid>
                    <Grid item xs={8}>
                        <CustomTextField
                            value={dataItems?.fname || ""}
                            placeholder="Type here..."
                            onChange={(e) => handleChange(e, 'fname')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <label className={classes.noteTitle}>Last Name</label>
                    </Grid>
                    <Grid item xs={8}>
                        <CustomTextField
                            id="input-with-icon-adornment5"
                            value={dataItems?.lname || ""}
                            placeholder="Type here..."
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => handleChange(e, 'lname')}
                            variant="outlined"
                        />
                    </Grid>

                </Grid>
                <br></br>
                <div className="right-buttons">
                    <Button autoFocus onClick={() => { setOpen(false) }} className="noBtn" variant="outlined" color="primary">
                        Cancel
                    </Button>

                    <Button onClick={handleSave} className="yesBtn" variant="contained" color="primary">
                        Add
                    </Button>
                </div>
            </DialogContent>
        </Dialog>

<SnackbarModal
open={snackbarModel}
setOpen={setSnackbarModel}
type="warning"
title={'Warning'}
content={t('warnings.surgicalevent.exitmessage')}
vertical="center"
horizontal="center"
width={345}
height={130}
transition="fade"
className={classNames(classes.toastLarge, classes.toastWarning)}
options={{
  delay: 500,
  validateULE: true,
}}
actions={[
  {
    label: 'Yes',
    handle: () => {
      setOpen(false);
    },
  },
  {
    label: 'No',
    handle: () => {
      setSnackbarModel(false);
    },
  }
]}
/>
</>
    );
}