import React, { useState, useEffect } from "react";
import classNames from 'classnames';
import { Snackbar, Slide } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { CheckCircleOutlineOutlined } from '@material-ui/icons';
import { delay } from "../../services/util";
import useStyles from "./styles";

/**
* Custom Snackbar
* @param {String} title
* @param {String} content
* @param {String} vertical
* @param {String} horizontal
* @param {Number} duration
* @param {Function} handleClose
* @param {String} color green | yellow
* @returns 
*/
export default function CustomSnackbar({
  title="Success", content="", vertical="bottom", horizontal="left", duration=3000, handleClose, 
  color='green',
}) {

const [open, setOpen] = useState(false);
const [closing, setClosing] = useState(false);

const classes = useStyles();


const onCloseHandler = async () => {
  setClosing(true);
  await setOpen(false);
  await delay(1000);  // sleep 1 second
  if (typeof handleClose === 'function') await handleClose();
  setClosing(false);
};


// when content is not empty, open it.
useEffect(() => {
  if (!!content && !open && !closing) setOpen(true);
}, [content, open, closing]);


return (
  <>
    <Snackbar
      open={open}
      className={classNames(classes.customSnackbar, (color === 'yellow' ? classes.yellow : classes.green))}
      anchorOrigin={{ vertical, horizontal }}
      autoHideDuration={duration}
      onClose={onCloseHandler}
      // message={content}
      TransitionComponent={SlideTransition}
    >
      <Alert
        variant="outlined"
        severity="success" 
        iconMapping={{
          success: <CheckCircleOutlineOutlined fontSize="inherit" />,
        }}
        // onClose={onCloseHandler} 
        sx={{ width: '100%' }}
      >
        <AlertTitle>{title}</AlertTitle>
        {content}
      </Alert>
    </Snackbar>
  </>
);
}

function SlideTransition(props) {
return <Slide {...props} direction="up" />;
}

const Alert = React.forwardRef(function Alert(props, ref) {
return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

