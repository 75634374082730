import { getModelMenu, validateModelItem } from "./handles";

export const AdminDetailModel = {
  auto_po: false,
  auto_po_vendor: false,
  approval_level: '',
  approval_amount_levelone: '',
  approval_amount_leveltwo: '',
  purchaseorderid_prefix: '',
  purchaseorderid_start: '',
};

export const PaymentSettingModel = {
  discover_payment: false,
  straight_through_payment: false,
  discover_payernetwork_id: '',
  discover_partnernetwork_id: '',
  discover_partnername_id: ''
};
export const validateAdminDetail = (admin) => {
  let {purchase_order_setting} = admin;
  return ((purchase_order_setting.approval_level !== ""
      && validateLevel(purchase_order_setting.approval_level))
    || purchase_order_setting.approval_amount_levelone !== ""
    || purchase_order_setting.approval_amount_leveltwo !== ""
  );
};
export const getAdminDetail = (admin) => {
  let {purchase_order_setting,invoice_setting,payment_setting} = admin;
  let newAdmin = {};
  newAdmin.auto_po = purchase_order_setting.auto_po || false;
  newAdmin.auto_po_vendor = purchase_order_setting.auto_po_vendor || false;
  newAdmin.auto_replenish = purchase_order_setting.auto_replenish || false;
  newAdmin.approval_level = purchase_order_setting.approval_level  || "";
  if ((typeof purchase_order_setting.approval_amount_levelone === "string" && purchase_order_setting.approval_amount_levelone !== "")
    || (typeof purchase_order_setting.approval_amount_levelone === "number")) newAdmin.approval_amount_levelone = purchase_order_setting.approval_amount_levelone;
  if ((typeof purchase_order_setting.approval_amount_leveltwo === "string" && purchase_order_setting.approval_amount_leveltwo !== "")
    || (typeof purchase_order_setting.approval_amount_leveltwo === "number")) newAdmin.approval_amount_leveltwo = purchase_order_setting.approval_amount_leveltwo;
  if (typeof purchase_order_setting.purchaseorderid_prefix === "string" && purchase_order_setting.purchaseorderid_prefix !== "") newAdmin.purchaseorderid_prefix = purchase_order_setting.purchaseorderid_prefix;
  if ((typeof purchase_order_setting.purchaseorderid_start === "string" && purchase_order_setting.purchaseorderid_start !== "")
    || (typeof purchase_order_setting.purchaseorderid_start === "number")) newAdmin.purchaseorderid_start = purchase_order_setting.purchaseorderid_start;
  return {purchase_order_setting:newAdmin,invoice_setting,payment_setting};
};

export const Levels = {
  One: 'One',
  Two: 'Two',
  None: 'None',
};
export const validateLevel = validateModelItem(Levels);
export const getLevels = getModelMenu(Levels);