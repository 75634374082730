import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, TextField,
  FormControl, InputLabel, Select } from "@material-ui/core";
import { useLayoutDispatch, setLoading, unsetLoading } from "../../context/LayoutContext";
import AddEditProduct from "../surgicalevent/add-edit-product";
import { getOwnerships } from "../../models/Ownership";
import { requiredProduct, isEmptyProduct } from "../../models/Product";
import { createInventoryData, requiredInventoryData } from "../../models/InventoryData";
import { InputDistributor } from "../surgicalevent/edit-product";
import { getTodayString, getTemplateListFromResponse } from "../../services/util";
// import ErrorDialog from '../purchaseorder/error-dialog';
import useStyles from "../surgicalevent/styles";
import ModalDialogBox from "../../components/Modals/GeneralModal/modalDialogBox";

/**
 * Create Inventory Dialog in Hospital
 * 1. Account Inventory List Page in Hospital
 * 2. Restock Inventory SE Page in Hospital
 * 3. Restock Inventory PO Page in Hospital
 * 4. In House Tab of Account Inventory Page in Hospital
 * @param {Boolean} openModal
 * @param {Function} setOpenModal
 * @param {Boolean} isDisableMultiple flag to disable multiple inventory data
 * @param {String} hospital 
 * @param {Function} addProductItem
 * @param {Function} handleAddMoreProduct
 * @param {String} title
 * @param {Array} inventoryDataList
 * @param {Function} setInventoryDataList
 * @param {String} btnCreate
 * @param {Object} staticValues
 */
export default function CreateInventory({
  openModal, setOpenModal, isDisableMultiple = true, hospital,
  addProductItem, handleAddMoreProduct, title = "Add Extra Inventory",
  inventoryDataList = [], setInventoryDataList, btnCreate = "Create", staticValues = {},
}) {

  const classes = useStyles();

  const layoutDispatch = useLayoutDispatch();

  const [inventoryData, setInventoryData] = useState({});
  const [inventoryError, setInventoryError] = useState({});
  const [productItems, setProductItems] = useState({});
  const [productError, setProductError] = useState({});
  const [productItemsList, setProductItemsList] = useState([]);
  const [quantity, setQuantity] = useState("1");
  // error modal
  // const [errorModal, setErrorModal] = useState(false);
  // confirm Modal
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState('');
  const [confirmText, setConfirmText] = useState('');
  const [confirmFirstBtn, setConfirmFirstBtn] = useState('Ok');
  const [confirmSecondBtn, setConfirmSecondBtn] = useState('');
  // clear
  const [clear, setClear] = useState(true);

  const defaultInventoryData = {
    iproductstatus: 'Received',
    ireceiveddate: getTodayString(),
  };


  // get inventorydata by using static values
  const updateInventoryStatic = () => {
    let invd = inventoryData;
    if (staticValues.sepdistributor || staticValues.iownership) {
      if (staticValues.sepdistributor) invd.sepdistributor = staticValues.sepdistributor;
      if (staticValues.iownership) invd.iownership = staticValues.iownership;
      setInventoryData(invd);
    }
  }
  // get product by using static values
  const updateProductStatic = () => {
    let prod = productItems;
    if (staticValues.ireferencenumber) {
      prod.sepreferencenumber = staticValues.ireferencenumber;
      setProductItems(prod);
    }
  }

  // Click "Add more Product"
  const addMoreProduct = async (event) => {
    updateInventoryStatic();

    // check required fields in InventoryData
    let newErrors = requiredInventoryData(inventoryData);
    console.log("[CreateInventory] addMoreProduct", inventoryData, newErrors);
    if ((Object.keys(newErrors).length > 0)) {
      setInventoryError({ ...newErrors });
      setProductError({});
      return false;
    }

    updateProductStatic();
    // check required fields in productItems
    newErrors = requiredProduct(productItems);
    // to skip 'sepdistributor', 'sepownership' in productItems
    delete newErrors.sepdistributor;
    delete newErrors.sepownership;
    console.log("[CreateInventory] addMoreProduct", productItems, newErrors);
    if ((Object.keys(newErrors).length > 0)) {
      setProductError({ ...newErrors });
      setInventoryError({});
      return false;
    }

    productItemsList.push(productItems);
    const newinvdata = createInventoryData({
      ...defaultInventoryData,
      ...inventoryData,
      iproduct: productItems
    });
    if (parseInt(quantity) > 1) {
      inventoryDataList.push(...Array(parseInt(quantity)).fill({ ...newinvdata }));
    }
    else {
      inventoryDataList.push(newinvdata);
    }

    setProductItems({});
    setInventoryError({});
    setProductError({});
    setQuantity("1");
    // setProductItemsList(productItemsList);

    await handleAddMoreProduct(productItems);
  };

  // Click btnCreate "Confirm" | "Add"
  const addInventory = async (event) => {
    // if(productItems.sepreferencenumber)
    setConfirmOpen(false);
    updateInventoryStatic();

    // check required fields in InventoryData
    let newErrors = requiredInventoryData(inventoryData);
    console.log("[CreateInventory] addInventory", inventoryData, newErrors);
    if ((Object.keys(newErrors).length > 0)) {
      setInventoryError({ ...newErrors });
      setProductError({});
      return false;
    }

    if (!isEmptyProduct(productItems)) {
      updateProductStatic();

      // check required fields in productItems
      newErrors = requiredProduct(productItems);
      // to skip 'sepdistributor', 'sepownership' in productItems
      delete newErrors.sepdistributor;
      delete newErrors.sepownership;
      console.log("[CreateInventory] addInventory", productItems, newErrors);
      if ((Object.keys(newErrors).length > 0)) {
        setProductError({ ...newErrors });
        setInventoryError({});
        return false;
      }

      productItemsList.push(productItems);
      const newinvdata = createInventoryData({
        ...defaultInventoryData,
        ...inventoryData,
        iproduct: productItems
      });
      if (parseInt(quantity) > 1) {
        inventoryDataList.push(...Array(parseInt(quantity)).fill({ ...newinvdata }));
      }
      else {
        inventoryDataList.push(newinvdata);
      }
    }

    // setProductItems({});
    // setInventoryError({});
    // setProductError({});
    // setQuantity("1");
    setClear(true);

    console.log("[CreateInventory] addInventory", inventoryDataList);

    // spinner
    setLoading(layoutDispatch);

    await addProductItem(handleConfirmRequest);

    unsetLoading(layoutDispatch);
  };

  const handleCancel = () => {
    setClear(true);
    setOpenModal(false);
    unsetLoading(layoutDispatch);
  };

  // Close Confirm Modal and Create new Account Inventory
  const handleConfirmToAccount = async () => {
    // spinner
    setLoading(layoutDispatch);

    await addProductItem(null, true);

    unsetLoading(layoutDispatch);
    setConfirmOpen(false);
  };

  const handleConfirmBack = () => {
    // console.log("[CreateInventory] handleConfirmBack", inventoryDataList);
    inventoryDataList.pop();
    // console.log("[CreateInventory] handleConfirmBack", inventoryDataList);
    setClear(false);
    setConfirmOpen(false);
    setOpenModal(true);
  };

  const handleCloseConfirmModal = () => {
    setConfirmOpen(false);
  };

  const incorrectPONumMsgTitle = 'A incorrect Purchase order Number was entered';
  // After confirmed, detect the result to show confirm Modal
  const handleConfirmRequest = async (totalres) => {
    console.log("[CreateInventory] handleConfirmRequest", totalres);

    let templatePO = [];
    if (totalres && totalres.length) templatePO = getTemplateListFromResponse(totalres, "RestockInventory");
    console.log("[CreateInventory] handleConfirmRequest", templatePO);

    if (inventoryData.ipurchaseordernumber) {
      if (templatePO.length) {
        setConfirmTitle(`Item successfully received to ${inventoryData.ipurchaseordernumber}`);
        setConfirmText(`<p></p>`);
        setConfirmFirstBtn('');
        setConfirmSecondBtn('Ok');
      }
      else {
        setConfirmTitle(incorrectPONumMsgTitle);
        setConfirmText(`<p>please check your entry.</p>`);
        setConfirmFirstBtn('Ok');
        setConfirmSecondBtn('');
      }
    }
    else {
      if (templatePO.length) {
        let foundTagPONumber = templatePO[0].ipurchaseorderdata.popurchaseorderid;
        setConfirmTitle(`Success your Product was matched correctly to ${foundTagPONumber}`);
        setConfirmText(`<p></p>`);
        setConfirmFirstBtn('');
        setConfirmSecondBtn('Ok');
      }
      else {
        setConfirmTitle('The product that was entered does not match any Purchase order in the system');
        setConfirmText(`<p>Would you like to add it to your inventory?</p>`);
        setConfirmFirstBtn('Yes');
        setConfirmSecondBtn('No');
      }
    }

    setConfirmOpen(true);
  };


  useEffect(() => {
    if (openModal) {
      console.log("[CreateInventory] Open CreateInventory dialog.", inventoryDataList);
      if (clear) {
        setInventoryData({});
        setInventoryError({});
        setProductItems({});
        setProductError({});
        setQuantity("1");
      }
      setProductItemsList(inventoryDataList.map(p => p.iproduct));
      setConfirmOpen(false);
    }
  }, [openModal, inventoryDataList, clear]);


  return (
    <>

      <Dialog
        open={openModal}
        className={classes.customRoot}
        aria-labelledby="draggable-dialog-title"
        classes={{ paper: classes.overflowHidden }}
      >
        <div className={classes.purchaseContentModal}>
          <DialogTitle style={{ cursor: 'move', borderBottom: '1px solid #cccccc' }} id="draggable-dialog-title">
            <div className="view-heading">{title}</div>
          </DialogTitle>

          <DialogContent className={classes.poContentModal}>
            <DialogContentText component={'div'}>
              <h3 className="poDetailsHeading">Inventory Details</h3>
              <div className={classes.marginBM30}>
                <TextField
                  id="input-with-icon-adornment11"
                  style={{ margin: 8 }}
                  label="Purchase Order"
                  placeholder=""
                  value={inventoryData.ipurchaseordernumber || ""}
                  error={!!inventoryError.ipurchaseordernumber}
                  helperText={inventoryError.ipurchaseordernumber}
                  className={classes.customFullWidth}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setInventoryData({
                      ...inventoryData,
                      ipurchaseordernumber: e.target.value,
                    });
                  }}
                  variant="outlined"
                />
              </div>
              <div className={classes.marginBM30}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="shipmentdate"
                    label="Shipment Date"
                    type="date"
                    className={classes.datehalfWidth}
                    value={inventoryData.ishipmentdate || ""}
                    error={!!inventoryError.ishipmentdate}
                    helperText={inventoryError.ishipmentdate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setInventoryData({
                        ...inventoryData,
                        ishipmentdate: e.target.value,
                      });
                    }}
                    variant="outlined"
                  />
                </FormControl>
              </div>
              <div className={classes.marginBM30}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="receiveddate"
                    label="Received Date"
                    type="date"
                    className={classes.datehalfWidth}
                    value={inventoryData.ireceiveddate || getTodayString()}
                    error={!!inventoryError.ireceiveddate}
                    helperText={inventoryError.ireceiveddate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setInventoryData({
                        ...inventoryData,
                        ireceiveddate: e.target.value,
                      });
                    }}
                    variant="outlined"
                  />
                </FormControl>
              </div>
              <div className={classes.marginBM30}>
                <InputDistributor
                  distributor={staticValues.sepdistributor ? staticValues.sepdistributor : inventoryData.sepdistributor}
                  error={inventoryError.sepdistributor}
                  setDistributor={(value) => {
                    setInventoryData({
                      ...inventoryData,
                      sepdistributor: value,
                    });
                  }}
                />
              </div>
              <div className={classes.marginBM30}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel className={classes.customLabel} shrink={true}>
                    Ownership
                  </InputLabel>
                  <Select
                    // native
                    displayEmpty
                    className={classes.customFullWidth}
                    value={staticValues.iownership ? staticValues.iownership : (inventoryData.iownership || "")}
                    error={!!inventoryError.iownership}
                    onChange={(e) => {
                      setInventoryData({
                        ...inventoryData,
                        iownership: e.target.value,
                      });
                    }}
                  >
                    {getOwnerships("Select One", ['Unknown', 'BillOnly'])}
                  </Select>
                </FormControl>
              </div>
              <div className="poModel">
                <h3 className={classes.subformHeading}> Product Details </h3>

                <AddEditProduct
                  isCreate={true}
                  productItemsList={productItemsList}
                  productItems={{
                    ...productItems,
                    sepreferencenumber: (staticValues.ireferencenumber ? staticValues.ireferencenumber
                      : productItems.sepreferencenumber),
                  }}
                  productError={productError}
                  setProductError={setProductError}
                  setProductItems={setProductItems}
                  hospital={hospital}
                  options={{
                    isEnableEditProduct: false,
                    isEnableDeleteProduct: false,
                    edit: {
                      isEnableCompliance: false,
                      isInventory: true,
                      hiddenParams: [
                        'sepdistributor',
                        'sepownership',
                        'sepproductwaste',
                      ],
                    },
                  }}
                />

                {
                  !isDisableMultiple &&
                  <div className={classes.marginBM30}>
                    <TextField
                      id="input-with-icon-adornment123"
                      style={{ margin: 8 }}
                      label="Quantity"
                      placeholder=""
                      value={quantity}
                      className={classes.customFullWidth}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        setQuantity(e.target.value);
                      }}
                      variant="outlined"
                      type="number"
                    />
                  </div>
                }

              </div>
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <div className="form-footer">
              <Button variant="outlined" color="primary" onClick={addMoreProduct}>Add more</Button>
              <div className="right-buttons">
                <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={addInventory} >{btnCreate}</Button>
              </div>
            </div>
          </DialogActions>
        </div>

      </Dialog>

      <ModalDialogBox
        open={confirmOpen}
        setOpen={setConfirmOpen}
        heading={confirmTitle}
        type="warning"
        text={confirmText}
        handleRequest={(confirmTitle === incorrectPONumMsgTitle ? handleConfirmBack : handleConfirmToAccount)}
        firstBtn={confirmFirstBtn}
        secondBtn={confirmSecondBtn}
        handleSecRequest={handleCloseConfirmModal}
      />

      {/* <ErrorDialog
        openModal={errorModal}
        setOpenModal={setErrorModal}
      /> */}

    </>
  );
}