import React, { useState } from "react";
import { useStreamQueries, useLedger } from "@daml/react";
import { Chip, Typography } from "@material-ui/core";
import { OnboardNetwork as OnboardNetworkTemplate } from "../../services/daml-modules1";
import Contracts from "../../components/Contracts/Contracts";
import AdaptiveSearch from "../../components/Search/adaptivesearch";
import icon4 from '../../icons/Frame 16.svg';
import activeIcon4 from '../../icons/active4.svg';
import classNames from "classnames";
import ModalDialogBox from "../../components/Modals/GeneralModal/modalDialogBox";
import useStyles from "../surgicalevent/styles";

export default function OnboardNetwork({
  allContracts = [],
  searchSurgical = {},
  handleChangeSearchValue = {},
}) {

  const classes = useStyles();

  const ledger = useLedger();
  const assets = useStreamQueries(OnboardNetworkTemplate);

  const [open, setOpen] = useState(false);
  const [inviteParty, setInviteParty] = useState('');
  const [invitePartyRole, setInvitePartyRole] = useState('');

  console.log("[OnboardNetwork]", assets.contracts);


  const exerciseInviteParty = async (contractId, payload) => {
    console.log("[OnboardNetwork] exerciseInviteParty", contractId, payload);

    let party = payload.party;
    let roletype = payload.companytype;
    let choice = null;
    console.log("party: " + party + ", role " + roletype);

    if (roletype === "Operator") choice = OnboardNetworkTemplate.InviteManager;
    else if (roletype === "Vendor") choice = OnboardNetworkTemplate.InviteVendor;
    else if (roletype === "Hospital") choice = OnboardNetworkTemplate.InviteHospital;

    if (choice) {
      // exerice choice
      await ledger.exercise(choice, contractId, {})
        .then(() => {
          console.log("[exerciseInviteParty] success!");
        })
        .catch((error) => {
          console.log("[exerciseInviteParty] error: " + error);
        });

      // open modal
      setInviteParty(party);
      setInvitePartyRole(roletype);
      setOpen(true);
    }

    console.log("Party Registered: " + party + "with the following  roletype :" + roletype);
  };


  return (
    <>
      <div key="formChildStep" className="formsteps">
        <Typography variant="h4" className={classes.pageTitle}>Network


        </Typography>

        <Contracts
          className={classes.customRoot}
          contracts={assets.contracts}
          columns={[
            // ["ContractId", "contractId"],
            ["Contract Id", "contractId", {
              options: {
                width: 120,
              },
              search: {
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.contractId}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'contractId')}
                    defaultValue={searchSurgical.contractId}
                    label="Search By Contract Id"
                    contracts={[]}
                    isShrink={true}
                  />
              },
              isSearched: searchSurgical.contractId,
              handleClear: () => handleChangeSearchValue('', 'contractId'),
            }],
            ["Party", "payload.party"],
            ["Name", "payload.name"],
            ["Email", "payload.email", {
              options: {
                width: 180
              }
            }],
            ["Company", "payload.company", {
              options: {
                width: 180
              }
            }],

            ["Type", "payload.companytype"],

            ["Status", "payload.approved", {
              type: "render",
              render: (param) => (<Chip
                label={
                  param.value ? 'Approved' : 'Created'
                }
                size="small"
                className={classNames(classes.smallChip, (
                  param.value ? classes.backGreen : classes.backBlue
                ))}
              />),

              options: {
                cellClassName: classes.centerCell,
                headerClassName: classes.centerCell,
                width: 90,
              },
            }],
            ["Created", "payload.created_at", {
              options: {
                width: 150,
              },
            }],
          ]}

          actions={[

            [
              "Actions",
              [
                [["Edit", [<img alt="" src={activeIcon4} />, <img alt="" src={icon4} />],
                  (c) => (!c.payload.approved)],
                (c) => exerciseInviteParty(c.contractId, c.payload)],

              ],
              {
                width: 110,
                cellClassName: classes.centerCell,
                headerClassName: classes.centerCell,
              }
            ]
          ]}
          options={{
            statusEmpty: (allContracts.length === 0)
          }}
        />

        <ModalDialogBox
          open={open}
          setOpen={setOpen}
          heading="Successfully Approved"
          type="success"
          text={`<p>You have successfully approved to ${inviteParty} ${invitePartyRole}.</p> 
            <p>Next step, Update the additional information on the ${invitePartyRole}s screen.</p>`}
          handleRequest={() => setOpen(false)}
          firstBtn={`${invitePartyRole}s`}
        />

      </div>
    </>
  );
};
