import { combineReducers, legacy_createStore as createStore } from "redux";
import { SIGN_OUT_SUCCESS } from './actions/constants';
import {
  surgicalReducer,
  purchaseReducer,
  invoiceReducer,
  receivedinvReducer,
  invdReducer,
  inventoryReducer,
  receivedpoReducer,
  complianceReducer,
  procedureReducer,
  surgicalhistoryReducer,
  templateReducer,
  inviteReducer,
  acceptReducer,
  receivedinviteReducer,
  accepthospitalReducer,
  userReducer,
  toggleStageReducer,
  retrieveESReducer,
  queryDAMLReducer,
  queryCallbackKeyReducer,
  searchFiltersReducer,
} from './reducers/generalReducers';
import eventReducer from './reducers/eventReducer';
import productReducer from './reducers/productReducer';
import poReducer from "./reducers/poReducer";
import newInventoryReducer from "./reducers/inventoryReducer";
import contractReducer from "./reducers/contractReducer";


const appReducer = combineReducers({
  surgical: surgicalReducer,
  purchase: purchaseReducer,
  invoice: invoiceReducer,
  receivedinv: receivedinvReducer,
  invd: invdReducer,
  inventory: inventoryReducer,
  receivedpo: receivedpoReducer,
  compliance: complianceReducer,
  procedure: procedureReducer,
  surgicalhistory: surgicalhistoryReducer,
  template: templateReducer,
  invite: inviteReducer,
  vendoraccept: acceptReducer,
  receivedinvite : receivedinviteReducer,
  hospitalaccept: accepthospitalReducer,
  user: userReducer,
  toggleStage: toggleStageReducer,
  retrieveES: retrieveESReducer,
  queryDAML: queryDAMLReducer,
  callbackKey: queryCallbackKeyReducer,
  searchFilters: searchFiltersReducer,
  event: eventReducer,
  product: productReducer,
  po: poReducer,
  newInventory: newInventoryReducer,
  contract: contractReducer,
});

const rootReducer = (state, action) => {
  if (action.type === SIGN_OUT_SUCCESS) {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

// Store
const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

// getState
const getState = store.getState;

// dispatch
const dispatch = store.dispatch;

// Root Reducers for OneInvite.js
export { store, getState, dispatch };
