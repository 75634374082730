import React, { useState, useEffect } from "react";
import useStyles from "../styles";
import { useParty } from "@daml/react";
import { showPrefCardList, showPrefCardDataById } from '../../../services/axios/api-node_server';
import { CustomSelect } from "../../../components/Inputs";
import plusCircle from '../../../icons/plus-circle.svg';
import { useDamlState } from "../../../context/DamlContext";
import { Grid, TableCell } from '@material-ui/core';
import { useLayoutDispatch, setLoading, unsetLoading } from "../../../context/LayoutContext";
import SnackbarModal from "../../../components/Modals/SnackbarModal";
import useEvent from "../../../hooks/useEvent";
import { PREF_ADD_EVENT } from "../../../store/actions/constants";
import { useSurgicalEvent } from "../../../hooks/useProduct";

const PrefDropDownView = ({ surgonid, onAddClick, width = 170, dropDownlabel }) => {
    const hospital = useParty();
    const { onEventStart } = useEvent();
    const { rolePayload } = useDamlState();
    const { hospitaldetails } = (rolePayload ? rolePayload : {});
    const [resultDataList, setresultDataList] = useState([]);
    const [selected, setSelected] = useState();
    const layoutDispatch = useLayoutDispatch();
    const [openToast, setOpenToast] = useState({ visible: false, msg: null });
    const { prefdata } = useSurgicalEvent();

    const classes = useStyles();

    useEffect(() => {
        const getCardList = async () => {
            if (surgonid && hospitaldetails.pref_facilityid) {
                let res = await showPrefCardList({
                    surgeonIds: [surgonid],
                    facilityId: hospitaldetails.pref_facilityid,
                });
                if (res) {
                    const prefcardList = res?.map((prefData, c) => ({ label: `${prefData.cptCodes}, ${prefData.name}`, value: prefData.id }));
                    setresultDataList(prefcardList)
                }
            }
        }
        if (hospitaldetails.pref_facilityid) {
            getCardList().catch((error) => console.log(error))
        }

    }, [surgonid, hospitaldetails.pref_facilityid])

    const onaddClick = async () => {
        if (selected) {
            let filterData = prefdata?.filter(item => item.id === selected);
            if (filterData && filterData.length > 0) {
                setOpenToast({ visible: true, msg: "Pref Card already added into list." })
            } else {
                setLoading(layoutDispatch);
                let res = await showPrefCardDataById({
                    cardId: selected,
                    hospital
                });
                if (res) {
                    onEventStart(PREF_ADD_EVENT, res);
                    unsetLoading(layoutDispatch);
                    setSelected(null)
                    if (onAddClick && typeof onAddClick === "function")
                        onAddClick()
                }
            }
        }
    };

    return (<>
        <TableCell colSpan={2}>
            <Grid container>
                <Grid item xs={9}>
                    <CustomSelect
                        label={dropDownlabel}
                        value={selected}
                        onChange={(val) =>
                            setSelected(val)
                        }
                        items={resultDataList}
                        placeholder="Select One"
                        width={width}
                    />
                </Grid>
                <Grid item xs={3}>
                    <img
                        src={plusCircle}
                        className={(dropDownlabel ? classes.iconblock2 : classes.iconblock1)}
                        alt=""
                        onClick={onaddClick}
                    />
                </Grid>
            </Grid>
        </TableCell>

        {
            openToast.visible
                ?
                <SnackbarModal
                    open={openToast.visible}
                    setOpen={(visible) => setOpenToast({ ...openToast, visible })}
                    title=""
                    content={openToast.msg}
                    width={330}
                    height={88}
                    className={classes.toastMedium}
                />
                :
                null
        }
    </>
    )

}

export default PrefDropDownView;