import React from "react";
import { Page, Document, View, Image, Text, StyleSheet, pdf, PDFViewer } from '@react-pdf/renderer';
import { COMPANY_NAME } from "../../config";
import { formatDate } from "../../services/util";
import { blobToDownload } from "../../services/download";
import logoIcon from "../../icons/TYDEi-Logo.png";
import prefCardLogo from "../../icons/prefCardLogoNew.png";
import qrcode from "../../icons/sampleQRCOde.png";

// Create styles
const styles = StyleSheet.create({
  section: {
    padding: '27px',
    color: '#000000',
    flexGrow: 1,
  },
  prefSection: {
    padding: '20px 15px 10px 20px',
    color: '#000000',
    flexGrow: 1,
  },
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  table: {
    // flexDirection: 'row',
    // flexWrap: 'wrap',
    borderColor: '#CCCCCC',
    marginBottom: '10px',
  },
  tableRow: {
    flexDirection: 'row',
    // borderBottomWidth: 1,
  },
  tableCell: {
    // borderRightWidth: 1,
    padding: '2px 0px 2px',
    fontWeight: 500,
    fontSize: '12px',
  },
  tableCellHead: {
    fontWeight: 300,
    fontSize: '9px',
  },
  col6: {
    width: '10%',
  },
  col5: {
    width: '20%',
  },
  col3: {
    width: '33%',
  },
  col2: {
    width: '50%',
  },
  col1: {
    width: '40%',
  },
  title: {
    fontWeight: 700,
    fontSize: '14px',
    borderBottom: '1px solid #CCCCCC',
    padding: '6px',
  },
  titleLabel: {
    fontWeight: 500,
    fontSize: '12px',
  },
  subHeading: {
    fontWeight: 500,
    fontSize: '10px',
    marginTop: '1px',
  },
  subTitle: {
    fontWeight: 500,
    fontSize: '12px',
  },
  subTitleLabel: {
    fontSize: '10px',
  },
  productBox: {
    border: '1px solid #CCCCCC',
    borderRadius: '4px',
    marginTop: '10px',
  },
  boxHead: {
    flexDirection: 'row',
    padding: '13px 5px 10px',
    borderBottom: '1px solid #CCCCCC',
  },
  boxHeadLabel: {
    fontWeight: 300,
    fontSize: '9px',
    marginRight: '3px',
  },
  boxHeadText: {
    fontWeight: 500,
    fontSize: '10px',
    marginRight: '8px',
  },
  boxTable: {
    marginTop: '8px',
  },
  boxTableCell: {
    padding: '1px 5px 1px',
    fontSize: '8px',
  },
  boxTableLabel: {
    fontWeight: 300,
    width: '80px',
  },
  boxTableText: {
    fontWeight: 500,
    width: '170px',
  },

  // for prefcards
  profileName: {
    width: '180px',
    height: '23px',
    // left: '29px',
    top: '3px',
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '20px',
    color: '#1C3C74'
  },
  patientDetails: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '14px',
  },
  prefPage: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: '10px 5px 10px 5px',
  },
  newTable: {
    borderColor: '#CCCCCC',
    marginBottom: '10px',
    display: 'inline-block',
    pageBreakInside: 'avoid'
  },
  newTable1: {
    display: 'inline-block',
    pageBreakInside: 'avoid'
  },
  image: {
    width: '80px',
    height: '30x',
  },
  prefCardImage: {
    width: "100px",
    height: "80px",
    top: '-20px',
    left: "40px",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  prefCardImage1: {
    width: "100%",
    height: "100px",
  },
  qrCodeImg: {
    width: "50px",
    height: "50px",
    bottom: '20px',
    left: "90px",
  },
  procedureName: {
    color: '#000000',
    fontWeight: 400,
    fontSize: '10px',
    width: '160px',
    height: '20px',
    bottom: '20px',
    left: "90px",
    textAlign : 'right'
  },
  tableCol: {
    flexDirection: 'column',
  },
  subtitles: {
    fontweight: 700,
    fontSize: '13px',
    color: '#000000',
    padding: '5px 0px 5px 0px',
  },
  impNoteTitle: {
    fontWeight: 400,
    fontSize: '15px',
  },
  subtitles1: {
    fontweight: 700,
    fontSize: '13px',
    color: '#000000',
    padding: '5px 0px',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    height: 'auto',
    minWidth: '160px',
    wordBreak: 'break-word'
  },
  colHeight: {
    height: '100px'
  },
  prefInfoPosition: {
    right: '30px'
  },
  prefInfoPosition1 : {
    right: '25px'
  },
  prefCardSubHeading: {
    fontWeight: 700,
    fontSize: '14px',
    padding: '2px 0px 5px 0px',
    color: '#1C3C74'
    // lineHeight: '17px',
  },
  imgTitleColor: {
    color: '#000000',
    fontWeight: 700
  },
  imgNoteStyle: {
    fontWeight: 400,
    fontSize: '12px',
    // lineheight: '14px',
    color: '#000000',
  },
  sideImgCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  prefCardSection: {
    color: '#1C3C74',
    width: '400px',
    height: 'auto',
  },
  prefCardSection1: {
    color: '#1C3C74',
    width: '30%',
    height: 'auto',
  },
  prefCardSubtitles: {
    fontSize: '13px',
    // lineHeight: '16px',
  },
  prefCardSubtitlesOverFlow: {
    fontWeight: 700,
    fontSize: '13px',
    whiteSpace: 'nowrap',
    overflow: 'auto',
    textOverflow: 'ellipsis',
  },
  prefCardSubtitlesOverFlow1: {
    fontWeight: 300,
    fontSize: '13px',
    color: '#000000',
    lineHeight: 1.5
  },
  prefValueBgColor: {
    background: '#F5F5F5',
    display: 'inline-block',
    pageBreakInside: 'avoid'
  },
  imgPreviewer: {
    width: '98%',
    height: 'auto',
  },
  mainPrefListSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  headerRow: {
    borderBottom: '1px solid #000000',
    pageBreakInside: 'avoid',
  },
  headerSubRow: {
    borderBottom: '1px solid #CCCCCC',
    display: 'inline-block',
    pageBreakInside: 'avoid'
  },
});

/**
 * SurgicalEvent PDF
 * @param {Object} surgicaleventdata 
 * @param {Array} surgicaleventproductdata 
 * @returns 
 */
const SurgicalEventPDF = ({
  surgicaleventdata = {}, surgicaleventproductdata = [],
}) => {

  return (
    <View style={styles.section} wrap={true}>
      <Text style={styles.title}>
        <Text style={styles.titleLabel}>SE ID: </Text>{surgicaleventdata.seid}
      </Text>
      <Text style={styles.subHeading}>Patient Details</Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCell, styles.tableCellHead, styles.col5]}>
            <Text>MRN</Text>
          </View>
          <View style={[styles.tableCell, styles.tableCellHead, styles.col5]}>
            <Text>First Name</Text>
          </View>
          <View style={[styles.tableCell, styles.tableCellHead, styles.col5]}>
            <Text>Last Name</Text>
          </View>
          <View style={[styles.tableCell, styles.tableCellHead, styles.col5]}>
            <Text>Gender</Text>
          </View>
          <View style={[styles.tableCell, styles.tableCellHead, styles.col5]}>
            <Text>DOB</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCell, styles.col5]}>
            <Text>{surgicaleventdata.mrn}</Text>
          </View>
          <View style={[styles.tableCell, styles.col5]}>
            <Text>{surgicaleventdata.patientfirstname}</Text>
          </View>
          <View style={[styles.tableCell, styles.col5]}>
            <Text>{surgicaleventdata.patientlastname}</Text>
          </View>
          <View style={[styles.tableCell, styles.col5]}>
            <Text>{surgicaleventdata.patientgender}</Text>
          </View>
          <View style={[styles.tableCell, styles.col5]}>
            <Text>{formatDate(surgicaleventdata.patientdob)}</Text>
          </View>
        </View>
      </View>
      <Text style={styles.subHeading}>Event Details</Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCell, styles.tableCellHead, styles.col3]}>
            <Text>Date</Text>
          </View>
          <View style={[styles.tableCell, styles.tableCellHead, styles.col3]}>
            <Text>Procedure</Text>
          </View>
          <View style={[styles.tableCell, styles.tableCellHead, styles.col3]}>
            <Text>Side</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCell, styles.col3]}>
            <Text>{formatDate(surgicaleventdata.eventdate)}</Text>
          </View>
          <View style={[styles.tableCell, styles.col3]}>
            <Text>{surgicaleventdata.surgicalprocedure}</Text>
          </View>
          <View style={[styles.tableCell, styles.col3]}>
            <Text>{surgicaleventdata.surgicalside}</Text>
          </View>
        </View>
      </View>
      <Text style={styles.subHeading}>Physician Details</Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCell, styles.tableCellHead, styles.col3]}>
            <Text>Salutation</Text>
          </View>
          <View style={[styles.tableCell, styles.tableCellHead, styles.col3]}>
            <Text>First Name</Text>
          </View>
          <View style={[styles.tableCell, styles.tableCellHead, styles.col3]}>
            <Text>Last Name</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCell, styles.col3]}>
            <Text>{surgicaleventdata.physiciansalutation}</Text>
          </View>
          <View style={[styles.tableCell, styles.col3]}>
            <Text>{surgicaleventdata.physicianfirstname}</Text>
          </View>
          <View style={[styles.tableCell, styles.col3]}>
            <Text>{surgicaleventdata.physicianlastname}</Text>
          </View>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={styles.col2}>
          <Text style={styles.subTitle}>
            <Text style={styles.subTitleLabel}>Total Added Products&nbsp;&nbsp;</Text>
            {surgicaleventproductdata.length}
          </Text>
        </View>
        <View style={styles.col2}>
          <Text style={styles.subTitle}>
            <Text style={styles.subTitleLabel}>Total Price of Products&nbsp;&nbsp;</Text>
            ${surgicaleventdata.price}
          </Text>
        </View>
      </View>

      {
        surgicaleventproductdata.reduce((products, product, index) => {
          if (index % 2 === 0) products.push([product]);
          else products[products.length - 1].push(product);
          return products;
        }, []).map((pair, index) => (
          <View style={styles.tableRow} key={index} wrap={false}>
            {
              pair.map(({ product }, index) => (
                <View style={[styles.col2, (index === 0 ? { marginRight: '5px' } : { marginLeft: '5px' })]} key={index}>
                  <View style={styles.productBox}>
                    <View style={styles.boxHead}>
                      <Text style={styles.boxHeadLabel}>Reference Number:</Text>
                      <Text style={styles.boxHeadText}>{product.sepreferencenumber}</Text>
                      <Text style={styles.boxHeadLabel}>Lot/SN:</Text>
                      <Text style={styles.boxHeadText}>{product.seplotcode}</Text>
                    </View>
                    <View style={styles.boxTable}>
                      <View style={styles.table}>
                        <View style={styles.tableRow}>
                          <View style={[styles.tableCell, styles.boxTableCell]}>
                            <Text style={styles.boxTableLabel}>UDI</Text>
                          </View>
                          <View style={[styles.tableCell, styles.boxTableCell]}>
                            <Text style={styles.boxTableText}>{product.sepudi}</Text>
                          </View>
                        </View>
                        <View style={styles.tableRow}>
                          <View style={[styles.tableCell, styles.boxTableCell]}>
                            <Text style={styles.boxTableLabel}>Product Manufacturer</Text>
                          </View>
                          <View style={[styles.tableCell, styles.boxTableCell]}>
                            <Text style={styles.boxTableText}>{product.sepproductmanufacturer}</Text>
                          </View>
                        </View>
                        <View style={styles.tableRow}>
                          <View style={[styles.tableCell, styles.boxTableCell]}>
                            <Text style={styles.boxTableLabel}>Product Distributor</Text>
                          </View>
                          <View style={[styles.tableCell, styles.boxTableCell]}>
                            <Text style={styles.boxTableText}>{product.sepdistributor}</Text>
                          </View>
                        </View>
                        <View style={styles.tableRow}>
                          <View style={[styles.tableCell, styles.boxTableCell]}>
                            <Text style={styles.boxTableLabel}>Product Name</Text>
                          </View>
                          <View style={[styles.tableCell, styles.boxTableCell]}>
                            <Text style={styles.boxTableText}>{product.sepproductname}</Text>
                          </View>
                        </View>
                        <View style={styles.tableRow}>
                          <View style={[styles.tableCell, styles.boxTableCell]}>
                            <Text style={styles.boxTableLabel}>Product Description</Text>
                          </View>
                          <View style={[styles.tableCell, styles.boxTableCell]}>
                            <Text style={styles.boxTableText}>{product.sepproductdescription}</Text>
                          </View>
                        </View>
                        <View style={styles.tableRow}>
                          <View style={[styles.tableCell, styles.boxTableCell]}>
                            <Text style={styles.boxTableLabel}>Expiration</Text>
                          </View>
                          <View style={[styles.tableCell, styles.boxTableCell]}>
                            <Text style={styles.boxTableText}>{formatDate(product.sepproductexpiration)}</Text>
                          </View>
                        </View>
                        <View style={styles.tableRow}>
                          <View style={[styles.tableCell, styles.boxTableCell]}>
                            <Text style={styles.boxTableLabel}>Price</Text>
                          </View>
                          <View style={[styles.tableCell, styles.boxTableCell]}>
                            <Text style={styles.boxTableText}>${product.sepproductprice}</Text>
                          </View>
                        </View>
                        <View style={styles.tableRow}>
                          <View style={[styles.tableCell, styles.boxTableCell]}>
                            <Text style={styles.boxTableLabel}>Lot/SN</Text>
                          </View>
                          <View style={[styles.tableCell, styles.boxTableCell]}>
                            <Text style={styles.boxTableText}>{product.seplotcode}</Text>
                          </View>
                        </View>
                        <View style={styles.tableRow}>
                          <View style={[styles.tableCell, styles.boxTableCell]}>
                            <Text style={styles.boxTableLabel}>Product Type</Text>
                          </View>
                          <View style={[styles.tableCell, styles.boxTableCell]}>
                            <Text style={styles.boxTableText}>{product.sepproducttype}</Text>
                          </View>
                        </View>
                        <View style={styles.tableRow}>
                          <View style={[styles.tableCell, styles.boxTableCell]}>
                            <Text style={styles.boxTableLabel}>Waste</Text>
                          </View>
                          <View style={[styles.tableCell, styles.boxTableCell]}>
                            <Text style={styles.boxTableText}>{product.sepproductwaste}</Text>
                          </View>
                        </View>
                        <View style={styles.tableRow}>
                          <View style={[styles.tableCell, styles.boxTableCell]}>
                            <Text style={styles.boxTableLabel}>Ownership</Text>
                          </View>
                          <View style={[styles.tableCell, styles.boxTableCell]}>
                            <Text style={styles.boxTableText}>{product.sepownership}</Text>
                          </View>
                        </View>
                        <View style={styles.tableRow}>
                          <View style={[styles.tableCell, styles.boxTableCell]}>
                            <Text style={styles.boxTableLabel}>Product Side</Text>
                          </View>
                          <View style={[styles.tableCell, styles.boxTableCell]}>
                            <Text style={styles.boxTableText}>{product.sepproductside}</Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              ))
            }
          </View>
        ))
      }
    </View>
  );
};


const PrefCardPDF = ({
  facility, items, patientDetails
}) => {
  const validatePatientName = (patientDetails) => {
    const { firstname, lastname, dob } = patientDetails || {};
    const validatedName = (firstname && lastname)
      ? `${firstname}, ${lastname}`
      : (firstname || lastname);
    return { validatedDetails: validatedName, dob };
  };

  const patient = validatePatientName(patientDetails);

  return (
    items.map((procedure, ind1) => {
      return (
        <Page size="A4" wrap={true} key={ind1} style={styles.prefPage}>
          <View style={styles.prefSection} wrap={true}>
            <View wrap={true}>
              <Image
                style={styles.image}
                src={logoIcon}
              />
            </View>
            <View style={styles.table} wrap={true}>
              <View style={styles.tableRow} wrap={true}>
                <View style={styles.profileNameContainer}>
                  <Text style={styles.profileName}>{patient.validatedDetails}</Text>
                  <Text style={styles.patientDetails}>{patient.dob}</Text>
                  <Text style={styles.patientDetails}>{procedure.name}</Text>
                </View>
                <View wrap={true} style={styles.tableRow}>
                  <View wrap={true} style={[styles.tableCol, styles.colHeight]}>
                    <Image
                      style={styles.prefCardImage}
                      src={prefCardLogo}
                    />
                  </View>
                  <View wrap={true} style={[styles.tableCol, styles.colHeight, styles.prefInfoPosition]}>
                    <Text style={styles.procedureName}>{procedure.name}</Text>
                    <Text style={styles.procedureName}>Or</Text>
                    <Text style={styles.procedureName}>Open: $2,014.18 | Pulled: $3,444.61</Text>
                  </View>
                  <View style={styles.prefInfoPosition1}>
                    <Image
                      style={styles.qrCodeImg}
                      src={qrcode}
                    />
                  </View>
                </View>
              </View>
              <View wrap={true} style={[styles.tableCol]}>
                <Text style={[styles.subtitles, styles.impNoteTitle]}>Important Note:</Text>
                <Text style={[styles.subtitles]}>Surgeon Hints:</Text>
                <Text style={[styles.subtitles]}>*Dr. Jones - 9 Blue / 9 Esteem 3XL Gown*Debbie - 7.5 Indicator/7 Protegrity Large Gown
                  *If Mako Knee - No loban, Saw Blade or Pins. Uses Gelpis (In set), 4-0 Monocryl PS-2, Mastisol &
                  Stresis for Tib & Femur Incisions. Will need 1 batch of Depuy Cement</Text>
              </View>
            </View >
            <View style={[styles.mainPrefListSection]} wrap={true}>
              <View style={styles.prefCardSection} wrap={true}>
                {
                  procedure.items && procedure.items.map((info, index1) => {
                    return (
                      <View wrap={true} key={index1}
                        style={styles.newTable1}>
                        <View minPresenceAhead={100}>
                          <Text style={styles.prefCardSubHeading}>{info.category.name}</Text>
                        </View>
                        <View style={styles.newTable} minPresenceAhead={80}>
                          <View style={[styles.tableRow, styles.headerRow]}>
                            <View style={[styles.tableCell, styles.tableCellHead, styles.col3]}>
                              <Text style={[styles.subtitles1, styles.prefCardSubtitles]}>Name</Text>
                              <Text style={[styles.subtitles1, styles.prefCardSubtitles]}>Ref#</Text>
                            </View>
                            <View style={[styles.tableCell, styles.tableCellHead, styles.col6]}>
                              <Text style={[styles.subtitles1, styles.prefCardSubtitles]}>Open</Text>
                            </View>
                            <View style={[styles.tableCell, styles.tableCellHead, styles.col6]}>
                              <Text style={[styles.subtitles1, styles.prefCardSubtitles]}>Hold</Text>
                            </View>
                            <View style={[styles.tableCell, styles.tableCellHead, styles.col1]}>
                              <Text style={[styles.subtitles1, styles.prefCardSubtitlesOverFlow]}>Item Note</Text>
                            </View>
                            <View style={[styles.tableCell, styles.tableCellHead, styles.col6]}>
                              <Text style={[styles.subtitles1, styles.prefCardSubtitles]}>Cost</Text>
                            </View>
                          </View>
                          {
                            info.items && info.items.map(({ open: openItem, note: noteItem, closed, pseudoItem }, index2) => {
                              return (
                                <View style={styles.headerSubRow} key={index2} minPresenceAhead={80}>
                                  <View style={[styles.tableRow, styles.prefValueBgColor]}>
                                    <View style={[styles.tableCell, styles.col3]}>
                                      <Text style={[styles.subtitles1, styles.prefCardSubtitles, styles.prefCardSubtitlesOverFlow]}>
                                        {pseudoItem && pseudoItem.name}
                                      </Text>
                                      <Text style={[styles.subtitles1, styles.prefCardSubtitles, styles.prefCardSubtitlesOverFlow1]}>
                                        {pseudoItem?.referenceNumber}
                                      </Text>
                                    </View>
                                    <View style={[styles.tableCell, styles.col6]}>
                                      <Text style={[styles.subtitles1, styles.prefCardSubtitles, styles.prefCardSubtitlesOverFlow1]}>
                                        {openItem}
                                      </Text>
                                    </View>
                                    <View style={[styles.tableCell, styles.col6]}>
                                      <Text style={[styles.subtitles1, styles.prefCardSubtitles, styles.prefCardSubtitlesOverFlow1]}>
                                        {closed}
                                      </Text>
                                    </View>
                                    <View style={[styles.tableCell, styles.col1]} wrap={true}>
                                      <Text style={[styles.subtitles1, styles.prefCardSubtitles, styles.prefCardSubtitlesOverFlow1]}>
                                        {noteItem}
                                      </Text>
                                    </View>
                                    <View style={[styles.tableCell, styles.col6]}>
                                      <Text style={[styles.prefCardSubtitlesOverFlow1, styles.subtitles1, styles.prefCardSubtitles]}>
                                        ${pseudoItem?.cost}
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                              )
                            })
                          }
                        </View>
                      </View>
                    )
                  })
                }
              </View>
              <View style={styles.prefCardSection1} wrap={true}>
                {
                  procedure.notes && procedure.notes.map(((newNote, index) => {
                    return (
                      <View style={[styles.sideImgCol]} key={index} minPresenceAhead={100}>
                        {newNote.title ? (
                          <Text style={[styles.prefCardSubHeading, styles.imgTitleColor]}>
                            {newNote.title}
                          </Text>
                        ) : null}
                        {
                          newNote?.image &&
                          <View>
                            <Image
                              style={[styles.prefCardImage1]}
                              src={newNote?.imageUrl}
                              alt={newNote?.title}
                            />
                          </View>
                        }
                        {newNote.note ? (
                          <Text style={[styles.prefCardSubHeading, styles.imgNoteStyle]}>
                            {newNote.note}
                          </Text>
                        ) : null}
                      </View>
                    )
                  }))
                }
              </View>
            </View>
          </View>
        </Page>
      )
    })
  );
};


/**
 * PDF Document
 * @param {Array} contracts
      * @param {String} title
      * @param {Component} Component
      * @param {any} style
      * @returns
      */
export const PDFDocument = ({
  contracts = [], title = "", Component = SurgicalEventPDF, style = styles,
}) => (
  <Document title={title} author={COMPANY_NAME}>
    <Page size="A4" wrap={true} style={style.page}>
      {
        contracts.map((contract, index) => (
          <Component
            key={index}
            {...contract}
          />
        ))
      }
    </Page>
  </Document>
);

export default PDFDocument;


export const PrefPDFDocument = ({
  contracts = [], title = "", Component = PrefCardPDF, style = styles, patientDetails = {}, isPrefPdfGenerator = false
}) => {
  let tempArray = [];
  if (isPrefPdfGenerator) {
    tempArray = contracts.map(prefData =>
      prefData.map(prefDetail => JSON.parse(prefDetail.payload.prefcarddetails))
    );
  }

  return (
    <Document title={title}
      author={COMPANY_NAME}
    >
      {
        tempArray.map((contract, index) => {
          return (
            <PrefCardPDF
              key={index}
              patientDetails={patientDetails[index]}
              items={contract}
            />
          )
        })
      }
    </Document>
  )
};


/**
 * handle to generate PDF
 * @param {Array} contracts
      * @param {String} fileName
      * @param {Component} Component
      * @param {any} style
      */
export const handlePDFGenerator = async ({ contracts = [], fileName = "se", Component = SurgicalEventPDF, style = styles, isPrefPdfGenerator = false,
  patientDetails }) => {
  const blob = await pdf((
    isPrefPdfGenerator ?
      <PrefPDFDocument contracts={contracts} title={fileName} Component={PrefCardPDF} style={style} patientDetails={patientDetails} isPrefPdfGenerator={isPrefPdfGenerator} /> :
      <PDFDocument contracts={contracts} title={fileName} Component={Component} style={style} />
  )).toBlob();
  blobToDownload(blob, `${fileName}.pdf`);
};

/**
 * SE PDF Viewer
 * @param {String} width
      * @param {String} height
      * @param {Component} Component
      * @param {any} style
      * @param {Object} ...
      * @returns
      */
export const SEPDFViewer = ({ width = "900", height = "1200", Component = SurgicalEventPDF, style = styles, ...props }) => (
  <PDFViewer width={width} height={height}>
    <PDFDocument Component={Component} style={style} {...props} />
  </PDFViewer>
);
