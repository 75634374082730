import Network from "../../pages/network/Network";
import OnboardNetwork from "../../pages/network/OnboardNetwork";
import InviteParty from "../../pages/invite/InviteParty";
import VendorInvite from "../../pages/invite/VendorInvite";
import HospitalInvite from "../../pages/invite/HospitalInvite";
import OperatorInvite from "../../pages/invite/OperatorInvite";
import Welcome from "../../pages/welcome/welcome";
import UploadProductData from "../../pages/import_file/uploadproductdata";
import VendorList from "../../pages/showlist/VendorList";
import HospitalList from "../../pages/showlist/HospitalList";
import AdminList from "../../pages/showlist/AdminList";
import AdminVendorList from "../../pages/showlist/AdminVendorList";
import AuditReport from "../../pages/showlist/auditReport";
import AuditReport1 from "../../pages/showlist/auditReport1";
import UserProfile from "../../pages/showlist/userProfile";
import Dashboard from "../../pages/dashboard/dashboard";
import DashboardHospital from "../../pages/dashboard/dashboardhospital";
import { CreateSurgicalEvent, EditSurgicalEvent } from "../../pages/surgicalevent/create_edit_surgicalevent";
import SurgicalEventList1 from "../../pages/surgicalevent/surgicaleventlist1";
import SurgicalEventHistoryList from "../../pages/surgicalevent/surgicaleventhistorylist";
import PurchaseOrderList from "../../pages/purchaseorder/purchaseorderlist";
import PurchaseOrderList1 from "../../pages/purchaseorder/purchaseorderlist1";
import PurchaseOrderDetail from "../../pages/purchaseorder/purchaseorderdetail";
import ReceivedPOList from "../../pages/purchaseorder/receivedpolist";
import ReceivedPOList1 from "../../pages/purchaseorder/receivedpolist1";
import NewPurchaseOrderList from "../../pages/purchaseorder/newpurchaseorderlist";
import VendorTemplateList from "../../pages/template/vendortemplate";
import HospitalTemplateList from "../../pages/template/hospitaltemplate";
import InvoiceList from "../../pages/invoice/invoicelist";
import InvoiceList1 from "../../pages/invoice/invoicelist1";
import InvoiceDetail from "../../pages/invoice/invoicedetail";
import ReceivedInvoiceList from "../../pages/invoice/receivedinvoicelist";
import ReceivedInvoiceList1 from "../../pages/invoice/receivedinvoicelist1";
import ReceivedPaymentsList from "../../pages/payments/receivedpaymentslist";
import ReceivedPaymentsList1 from "../../pages/payments/receivedpaymentslist1";
import SentPaymentsList from "../../pages/payments/sentpaymentslist1";
import ScanInventoryOperator from "../../pages/inventoryrestock/inventoryscanoperator";
import ScanInventoryVendor from "../../pages/inventoryrestock/inventoryscanvendor";
import ScanInventoryVendor1 from "../../pages/inventoryrestock/inventoryscanvendor1";
import ScanInventoryHospital from "../../pages/inventoryrestock/inventoryscanhospital";
import ScanInventoryHospitalPO from "../../pages/inventoryrestock/inventoryscanhospital-po";
import ScanInventoryHospital1 from "../../pages/inventoryrestock/inventoryscanhospital1";
import InventoryListOperator from "../../pages/inventoryaccount/inventorylistoperator";
import InventoryListVendor from "../../pages/inventoryaccount/inventorylistvendor";
import InventoryListVendor1 from "../../pages/inventoryaccount/inventorylistvendor1";
import InventoryListHospital from "../../pages/inventoryaccount/inventorylisthospital";
import InventoryListHospital1 from "../../pages/inventoryaccount/inventorylisthospital1";
import AccountInventory from '../../pages/inventoryaccount/accountinventory';
import RecallInventory from "../../pages/inventoryrecall/recallinventory";
import RecallUpload from "../../pages/inventoryrecall/recallUpload";
import ReturnInventory from "../../pages/inventoryreturn/returninventory";
import ReceivedInventory from '../../pages/inventoryreturn/receivedinventory';
import ReceivedInventory1 from '../../pages/inventoryreturn/receivedinventory1';
import PostProcedureList from "../../pages/postprocedureform/postprocedurelist";
import PostProcedureList1 from "../../pages/postprocedureform/postprocedurelist1";
import PostProcedureListVendor from "../../pages/postprocedureform/postprocedurelistvendor";
import PostProcedureDetail from "../../pages/postprocedureform/postproceduredetail";
import PostProcedureDetailVendor from "../../pages/postprocedureform/postproceduredetailvendor";
import ComplianceList from "../../pages/compliance/compliancelist";
import ComplianceList1 from "../../pages/compliance/compliancelist1";
import ComplianceDetail from "../../pages/compliance/compliancedetail";
import Manager from "../../pages/manager/manager";
import OnboardDA from "../../pages/onboard/OnboardDA";
import MessageInbox from '../../chat/Messenger/inbox';
import OperatorVendorList from '../../pages/operator/OperatorList';
import OperatorHospitalList from '../../pages/operator/HospitalList';
import VendorInviteRelationship from "../../pages/vendorinvite/vendorinvite";
import VendorInviteReceivedRelationship from "../../pages/vendoraccept/vendorreceivedinvite";
import UserList from "../../pages/user/userlist";
import UserList1 from "../../pages/user/userlist1";
import SettingPassword from "../../pages/user/setting-password";
import HolidayCalendar from "../../pages/discoverpayment/HolidayCalendar";
import PaymentLogs from "../../pages/discoverpayment/PaymentLogs";
import { AdminSettingsHospitalProfile, AdminSettingsFacilitySettings, AdminSettingsVendorManagement, 
  AdminSettingsVendorIntegration, AdminSettingsUserManagement, AdminSettingsVendorProfile, AdminSettingsVendorSettings, 
  AdminSettingsHospitalIntegration, AdminSettingsVendorUserManagement } from "../../pages/showlist/admin-settings";
import ContractManagementList from "../../pages/contractmanagement/contractmanagementlist";
import { isNewDesign } from "../../config";


/**
 * Routes Table Array
 */
export const RoutesTable = [
  {
    path: '/app/network', 
    component: Network
  },
  {
    path: '/app/purchaseorderdetail', 
    component: PurchaseOrderDetail
  },
  {
    path: '/app/importfile', 
    component: UploadProductData
  },
  {
    path: '/app/invoicedetail', 
    component: InvoiceDetail
  },
  {
    path: '/app/onboardda', 
    component: OnboardDA
  },
  {
    path: '/app/audit-report', 
    component: isNewDesign ? AuditReport1 : AuditReport,
    roles: ['Hospital', 'Vendor'],
    accesslevels: ['HManager','HFinanceAssociate','VManager','VAssociate']
  },
  {
    path: '/app/user-profile', 
    component: UserProfile,
    roles: ['Hospital'],
    accesslevels: ['HManager','HFinanceAssociate', 'HInventoryManager', 'HInventoryAssociate' ,'VManager','VAssociate' ,] 
  },
  {
    path: '/app/message/inbox', 
    component: MessageInbox
  },
  {
    path: '/app/users-list', 
    component: isNewDesign ? UserList1 : UserList,
    roles: ['Operator', 'Hospital', 'Vendor'],
    accesslevels: ['HManager','HInventoryAssociate','VManager']
  },
  {
    path: '/app/setting-password', 
    component: SettingPassword
  },
  {
    path: '/app/welcome', 
    component: InviteParty,
    roles: ['Undefined']
  },
  {
    path: '/app/welcome', 
    component: Manager,
    roles: ['Manager']
  },
  {
    path: '/app/welcome', 
    component: Welcome,
    roles: ['Operator', 'Hospital', 'Vendor'] // ! Undefined && ! Manager
  },
  // Operator role
  {
    path: '/app/dashboard', 
    component: Dashboard,
    roles: ['Operator']
  },
  {
    path: '/app/inventorylist', 
    component: InventoryListOperator,
    roles: ['Operator']
  },
  {
    path: '/app/inventoryscan', 
    component: ScanInventoryOperator,
    roles: ['Operator']
  },
  {
    path: '/app/onboardnetwork', 
    component: OnboardNetwork,
    roles: ['Operator']
  },
  {
    path: '/app/onboarding', 
    component: OperatorInvite,
    roles: ['Operator']
  },
  {
    path: '/app/viewhospitaldata', 
    component: OperatorHospitalList,
    roles: ['Operator']
  },
  {
    path: '/app/viewvendordata', 
    component: OperatorVendorList,
    roles: ['Operator']
  },
  {
    path: '/app/viewpaymentcalendar', 
    component: HolidayCalendar,
    roles: ['Operator']
  },
  {
    path: '/app/viewpaymentlogs', 
    component: PaymentLogs,
    roles: ['Operator']
  },
  // Vendor role
  {
    path: '/app/onboarding', 
    component: VendorInvite,
    roles: ['Vendor']
  },
  {
    path: '/app/viewvendordata', 
    component: VendorList,
    roles: ['Vendor']
  },
  {
    path: '/app/adminvendorlist', 
    component: AdminVendorList,
    roles: ['Vendor']
  },
  {
    path: '/app/invoice', 
    component: isNewDesign ? InvoiceList1 : InvoiceList,
    roles: ['Vendor'],
    accesslevels: ['VManager']
  },
  {
    path: '/app/receivedpayments', 
    component: isNewDesign ? ReceivedPaymentsList1 : ReceivedPaymentsList,
    roles: ['Vendor'],
    accesslevels: ['VManager']
  },
  {
    path: '/app/receivedpo', 
    component: isNewDesign ? ReceivedPOList1 : ReceivedPOList,
    roles: ['Vendor']
  },
  {
    path: '/app/vendorpostprocedurelist', 
    component: PostProcedureListVendor,
    roles: ['Vendor']
  },
  {
    path: '/app/vendorpostproceduredetail', 
    component: PostProcedureDetailVendor,
    roles: ['Vendor']
  },
  {
    path: '/app/hospitaltemplate', 
    component: HospitalTemplateList,
    roles: ['Vendor']
  },
  {
    path: '/app/inventoryscan', 
    component: isNewDesign ? ScanInventoryVendor1 : ScanInventoryVendor,
    roles: ['Vendor']
  },
  {
    path: '/app/inventorylist-facility', 
    component: isNewDesign ? InventoryListVendor1 : InventoryListVendor,
    roles: ['Vendor']
  },
  {
    path: '/app/inventorylist-pos', 
    component: AccountInventory,
    roles: ['Vendor']
  },
  {
    path: '/app/received-inventory', 
    component: isNewDesign ? ReceivedInventory1 : ReceivedInventory,
    roles: ['Vendor']
  },
  {
    path: '/app/receivedinvitation', 
    component: VendorInviteReceivedRelationship,
    roles: ['Vendor']
  },
  {
    path: '/app/recall-lookup', 
    component: RecallInventory,
    roles: ['Vendor']
  },
  {
    path: '/app/admin-settings-vendor-profile', 
    component: AdminSettingsVendorProfile,
    roles: ['Vendor']
  },
  {
    path: '/app/admin-settings-vendor-settings', 
    component: AdminSettingsVendorSettings,
    roles: ['Vendor']
  },
  {
    path: '/app/admin-settings-hospital-integration', 
    component: AdminSettingsHospitalIntegration,
    roles: ['Vendor']
  },
  {
    path: '/app/admin-settings-vendor-user-management', 
    component: AdminSettingsVendorUserManagement,
    roles: ['Vendor']
  },
  // Hospital role
  {
    path: '/app/viewhospitaldata', 
    component: HospitalList,
    roles: ['Hospital'],
    accesslevels: ['HManager', 'HInventoryManager']
  },
  {
    path: '/app/adminhospitallist', 
    component: AdminList,
    roles: ['Operator', 'Hospital'],
    accesslevels: ['HManager','HFinanceAssociate']
  },
  {
    path: '/app/postprocedurelist', 
    component:isNewDesign ? PostProcedureList1 : PostProcedureList,
    roles: ['Hospital'],
    accesslevels: ['HManager', 'HFinanceAssociate', 'HInventoryManager','HInventoryAssociate']
  },
  {
    path: '/app/postproceduredetail', 
    component: PostProcedureDetail,
    roles: ['Hospital']
  },
  {
    path: '/app/compliancelist', 
    component: isNewDesign ? ComplianceList1 : ComplianceList,
    roles: ['Hospital'],
    accesslevels: ['HManager', 'HInventoryManager','HFinanceAssociate']
  },
  {
    path: '/app/compliancedetail', 
    component: ComplianceDetail,
    roles: ['Hospital']
  },
  {
    path: '/app/receivedinvoice', 
    component: isNewDesign ? ReceivedInvoiceList1 : ReceivedInvoiceList,
    roles: ['Hospital'],
    accesslevels: ['HManager','HFinanceAssociate']
  },
  {
    path: '/app/sentpayments', 
    component: SentPaymentsList,
    roles: ['Hospital'],
    accesslevels: ['HManager','HFinanceAssociate']
  },
  {
    path: '/app/purchaseorder-list', 
    component: isNewDesign ? PurchaseOrderList1 : PurchaseOrderList,
    roles: ['Hospital'],
    accesslevels: ['HManager', 'HFinanceAssociate', 'HInventoryAssociate']
  },
  {
    path: '/app/purchaseorder-new', 
    component: NewPurchaseOrderList,
    roles: ['Hospital'],
    accesslevels: ['HManager', 'HFinanceAssociate', 'HInventoryManager', 'HInventoryAssociate']
  },
  {
    path: '/app/surgicalevent/edit', 
    component: EditSurgicalEvent,
    roles: ['Hospital']
  },
  {
    path: '/app/surgicalevent/add-new', 
    component: CreateSurgicalEvent,
    roles: ['Hospital']
  },
  {
    path: '/app/surgicalevent', 
    component: SurgicalEventList1, // use redesign SE page
    roles: ['Hospital'],
    accesslevels: ['HManager','HFinanceAssociate','HInventoryAssociate','HInventoryManager']
  },
  {
    path: '/app/sehistory', 
    component: SurgicalEventHistoryList,
    roles: ['Hospital']
  },
  {
    path: '/app/vendortemplate', 
    component: VendorTemplateList,
    roles: ['Hospital'],
    accesslevels: ['HManager', 'HFinanceAssociate', 'HInventoryManager', 'HInventoryAssociate']
  },
  {
    path: '/app/inventoryscan-seid', 
    component: ScanInventoryHospital,
    roles: ['Hospital'],
    accesslevels: ['HManager', 'HFinanceAssociate', 'HInventoryManager', 'HInventoryAssociate']
  },
  {
    path: '/app/inventoryscan-po', 
    component: isNewDesign ? ScanInventoryHospital1 : ScanInventoryHospitalPO,
    roles: ['Hospital'],
    accesslevels: ['HManager', 'HFinanceAssociate', 'HInventoryManager', 'HInventoryAssociate']
  },
  {
    path: '/app/inventorylist', 
    component: isNewDesign ? InventoryListHospital1 : InventoryListHospital,
    roles: ['Hospital'],
    accesslevels: ['HManager', 'HInventoryManager', 'HInventoryAssociate','HFinanceAssociate']
  },
  {
    path: '/app/inventorylist-pos', 
    component: AccountInventory,
    roles: ['Hospital']
  },
  {
    path: '/app/recall-lookup-list', 
    component: RecallInventory,
    roles: ['Hospital']
  },
  {
    path: '/app/recall-lookup-upload', 
    component: RecallUpload,
    roles: ['Hospital']
  },
  {
    path: '/app/onboarding', 
    component: HospitalInvite,
    roles: ['Hospital'],
    accesslevels: ['HManager', 'HFinanceAssociate', 'HInventoryManager', 'HInventoryAssociate']
  },
  {
    path: '/app/dashboardhospital', 
    component: DashboardHospital,
    roles: ['Hospital']
  },
  {
    path: '/app/return-inventory', 
    component: ReturnInventory,
    roles: ['Hospital'],
    accesslevels: ['HManager', 'HFinanceAssociate']
  },
  {
    path: '/app/vendorinvitation', 
    component: VendorInviteRelationship,
    roles: ['Hospital']
  },
  {
    path: '/app/admin-settings-hospital-profile', 
    component: AdminSettingsHospitalProfile,
    roles: ['Hospital']
  },
  {
    path: '/app/admin-settings-facility-settings', 
    component: AdminSettingsFacilitySettings,
    roles: ['Hospital']
  },
  {
    path: '/app/admin-settings-vendor-management', 
    component: AdminSettingsVendorManagement,
    roles: ['Hospital']
  },
  {
    path: '/app/admin-settings-vendor-integration', 
    component: AdminSettingsVendorIntegration,
    roles: ['Hospital']
  },
  {
    path: '/app/admin-settings-user-management', 
    component: AdminSettingsUserManagement,
    roles: ['Hospital']
  },
  {
    path: '/app/contract-management', 
    component: ContractManagementList,
    roles: ['Hospital']
  },
];



/**
 * check route is enabled
 * @param {Object} route 
 * @param {String} role 
 * @param {String} accesslevel 
 * @param {Array} uidetaills 
 * @returns {Boolean}
 */
export const checkRoute = (route, role, accesslevel, uidetaills=[]) => {
  // console.log("[checkRoute]", route, uidetaills);
  return ((route.roles === undefined || route.roles.includes(role))
    && (route.accesslevels === undefined || route.accesslevels.includes(accesslevel))
    && (uidetaills.every(({path, visible}) => visible || path !== route.path)));
};

/**
 * check route path is enabled or disabled
 * @param {String} path 
 * @param {String} role 
 * @param {String} accesslevel 
 * @param {Array} uidetaills 
 * @returns {Boolean}
 */
export const checkEnableRoute = (path, role, accesslevel, uidetaills=[]) => {
  const routes = RoutesTable.filter(r => r.path === path);
  return routes.length && routes.some(route => checkRoute(route, role, accesslevel, uidetaills));
};
