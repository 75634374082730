import React, { useState } from "react";
import classNames from "classnames";
import { useLedger, useParty } from "@daml/react";
import { Chip } from "@material-ui/core";
import { /* PurchaseOrder, */ PurchaseOrderHistory } from "../../services/daml-modules1";
import { useDamlState } from '../../context/DamlContext';
import AdaptiveSearch from "../../components/Search/adaptivesearch";
import DateSearch from "../../components/Search/datesearch";
import SelectSearch from "../../components/Search/selectsearch";
import TreeContracts from "../../components/Contracts/TreeContracts";
import { contractFilter } from "../../services/daml-filters";
import sendIcon from "../../icons/send.svg";
import sendDisableIcon from "../../icons/send-disable.svg";
import editIcon from "../../icons/edit.svg";
import editDisableIcon from "../../icons/edit-disable.svg";
import deleteIcon from "../../icons/delete.svg";
import deleteDisableIcon from "../../icons/delete-disable.svg";
import archiveIcon from "../../icons/archive.svg";
import archiveDisableIcon from "../../icons/archive-disable.svg";
import { isEnableSendVendor, isEnableChangeStatus, isEnableEdit, isEnableDeleteSubProduct,
  isEnableChangeOwnership, POHStatus, POCommentModel } from '../../models/POStatus';
import { getTodayString } from "../../services/util";
import { ArchivePo } from "./handle";
import DetailViewDrawer from "./detail-view-drawer";
import SendPO from './sendpo';
import { isEnableArchivePurchaseOrder } from './purchaseorderlistcontracts';
import ModalDialogBox from "../../components/Modals/GeneralModal/modalDialogBox";
import EditPrice from './EditPrice';
import { handleToSendPO, handleToChangePrice, handleToDeleteProductItem } from './purchaseorderlistcontracts';
import { useDamlStream } from "../../hooks/useDAML";
import useStyles from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";


/**
 * New Purchase Order Contracts in Hospital
 * @param {Object} searchParams 
 * @param {Boolean} isIncludeHistory 
 * @param {Function} handleChangeSearchValue 
 * @param {Object} searchPO 
 * @param {Array} allContracts 
 * @param {Object} createOptions
 * @returns 
 */
function NewPurchaseOrderListContracts({
  searchParams = {},
  isIncludeHistory = false,
  handleChangeSearchValue = null,
  searchPO = {},
  allContracts = [],
  createOptions = {},
}) {

  const classes = useStyles();
  const ledger = useLedger();
  const hospital = useParty();

  const { role, party, userParty, roleCid } = useDamlState();

  // const assets = useStreamQueries(PurchaseOrder, () => [searchParams]);

  // const [assetsHistory, setHisotry] = useState({});
  const assetsHistory = useDamlStream(PurchaseOrderHistory, [{ 
    hospital, purchaseorder: { purchaseorderdata: { poseid: null } } 
  }], isIncludeHistory);

  allContracts = allContracts.concat(isIncludeHistory && assetsHistory.contracts ? 
    assetsHistory.contracts.map(h => ({ ...h, payload: h.payload.purchaseorder })) : []);
  let contracts = contractFilter(allContracts, searchParams);

  contracts = contracts.map(c => {
        c.payload.purchaseorderdata.purchaseorderdetaildataproducts = c.payload.purchaseorderdetaildata.reduce((s, d) => (s + parseInt(d.podquantity)), 0);
        if (c.payload.purchaseorderdetaildata && c.payload.purchaseorderdetaildata.length) {
          c.payload.purchaseorderdetaildata = c.payload.purchaseorderdetaildata
            .map((d, index) => ({
              ...d,
              contractId: c.contractId,
              payload: c.payload,
              purchaseorderdata: c.payload.purchaseorderdata,
              purchaseorderdetaildatalength: c.payload.purchaseorderdetaildata.length,
              index,
            }));
        }
        return c;
      });

  // console.log("[NewPurchaseOrderListContracts]", searchParams, contracts);


  const [ContractId, setContractId] = useState('');
  const [payload, setPayload] = useState({});
  // send PO
  const [sendPOOpen, setSendPOOpen] = useState(false);
  const [sendPoSuccess, setSendPoSuccess] = useState(false);
  const [sendSelectedPOData, setSendSelectedPOData] = useState({});
  const [sendPoproductItemsList, setSendPoproductItemsList] = useState([]);
  const [poComment, setPoComment] = useState({});
  // DetailViewDrawer
  const [poData, setPOData] = useState({});
  const [productList, setProductList] = useState([]);
  const [commentList, setCommentList] = useState([]);
  const [detailViewer, setDetailViewer] = useState(false);
  // Delete Product
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteProductData, setDeleteProductData] = useState({});
  // Archive PO
  const [openWarning, setOpenWarning] = useState(false);
  const [, setConfirmModalStatus] = useState('');

  const {
    handleOpenCreatePO,
  } = createOptions;
  console.log(createOptions);


  // Click "Edit PO" in list
  const handleEditPO = (po) => {
    //console.log("[NewPurchaseOrderListContracts] handleEditPO", po);
    // open CreatePurchaseOrder with po info
    handleOpenCreatePO({
      poData: po.payload.purchaseorderdata, poDetail: po.payload.purchaseorderdetaildata,
      isCreate: false, contractId: po.contractId, roleCid, payload: po.payload,
    });
  };

  // Click "Send PO" button in PO list
  const setSendPOData = (data) => {
    setSendSelectedPOData(data.payload.purchaseorderdata);
    setSendPoproductItemsList(data.payload.purchaseorderdetaildata);
    setContractId(data.contractId);
    setPayload(data.payload);
    setPoComment({
      ...POCommentModel,
      date: getTodayString(),
      user: userParty ? userParty : party,
    });
    setSendPOOpen(true);
  };

  // Click "Send" button in Send PO modal
  const handleSendPO = async (callback) => {
    await handleToSendPO({
      ledger, ContractId, poComment, setSendPoSuccess, setSendPOOpen, callback, roleCid,
    });
  };

  // handle to change Price in Product of PO
  const handleChangePrice = async (price, product) => {
    await handleToChangePrice({
      ledger, price, product, roleCid, podetailindex: product.index,
      payload, user: (userParty ? userParty : party), role,
    });
  };

  // Click "Delete Product" in list
  const handleDeleteSubProduct = (product) => {
   // console.log("[NewPurchaseOrderListContracts] handleDeleteSubProduct", product);
    setDeleteProductData(product);
    setContractId(product.contractId);
    setPayload(product.payload);
    setOpenDeleteModal(true);
  };

  // handler to delete product of a PO in Delete Product Modal
  const handleDeleteProductItem = async () => {
    await handleToDeleteProductItem({
      ledger,
      ContractId, 
      deleteProductData, 
      setOpenDeleteModal, 
      roleCid, 
      podetailindex: deleteProductData.index,
      payload, 
      user: (userParty ? userParty : party), 
      role,
    });
  };

  const handleSecRequest = () => {
    setOpenDeleteModal(false);
  };
  const setSendPoSuccessRequest = () => {
    setSendPoSuccess(false);
  };
  const handleSecRequestWarning = () => {
    setOpenWarning(false);
  };


  // check to changed Price
  const checkChangedPrice = (c) => {
    //console.log("[NewPurchaseOrderListContracts] checkChangedPrice", c);
    return !!c.pricechange;
  };

  // Click "Archive PO" in list
  const handleArchivePO = (po) => {
    console.log('[NewPoListContracts] handleArchivePO', po);
    setConfirmModalStatus('ArchivePO');
    setContractId(po.contractId);
    setPayload(po.payload);
    setOpenWarning(true);
  };

  // Click "Yes" in "Archive Purchase Order" modal
  const handleModalYes = async () => {
    console.log("[NewPoListContracts] handleModalYes to archive Purchase Order", ContractId);
    // archive PO
    await ArchivePo({
      ledger, cid: ContractId, roleCid,
      payload, user: (userParty ? userParty : party), role,
    });
    setOpenWarning(false);
  };


  return (
    <>
      <div key="formChildStep" className="formsteps" >
        <TreeContracts
          className={classes.customRoot}
          contracts={contracts}
          columns={[

            ["PO Number", "payload.purchaseorderdata.popurchaseorderid", {
              type: 'link',
              onClick: (contractId, contract) => {
                const data = (!!contract) ? contract : contracts.find(c => c.contractId === contractId);
                // console.log("[NewPurchaseOrderListContracts] click", data);
                setPOData(data.payload.purchaseorderdata);
                setProductList(data.payload.purchaseorderdetaildata);
                setCommentList((data.payload.purchaseordercomment));
                setDetailViewer(true);
              },
              search: {
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.purchaseorderdata.popurchaseorderid}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'popurchaseorderid')}
                    defaultValue={searchPO.popurchaseorderid}
                    label="Search PO Number"
                    contracts={allContracts}
                    isShrink={true}
                  />
              },
              isSearched: searchPO.popurchaseorderid,
              handleClear: () => handleChangeSearchValue('', 'popurchaseorderid'),
              options: {
                width: 170,
                cellClassName: classes.nobreak
              }
            }],
            ["PO Date", "payload.purchaseorderdata.podate", {
              search: {
                component:
                  <DateSearch
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'podate')}
                    defaultValue={searchPO.podate}
                    label="Search PO Date :"
                    isStatic={true}
                  />
              },
              isSearched: searchPO.podate,
              handleClear: () => handleChangeSearchValue('', 'podate'),
              options: {
                width: 150,
              }
            }],
            ["Status", "payload.purchaseorderdata.postatus", {
              type: "render",
              render: (param) => (POHStatus[param.value] && <Chip
                label={POHStatus[param.value]}
                size="small"
                className={classNames(classes.smallChip, (param.value === 'InvoiceCreated' ? classes.backPurple : (
                  isEnableChangeStatus(param.value) ? classes.backPink : (
                    param.value === 'Accepted' ? classes.backGreen : (
                      param.value === 'Rejected' ? classes.backRed : (
                        param.value === 'MissingInfo' ? classes.backYellow : classes.backBlue
                      )
                    )
                  )
                )))}
              />),
              search: {
                component:
                  <SelectSearch
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'postatus')}
                    label="Search Status"
                    options={Object.keys(POHStatus).map(value => ({ label: POHStatus[value], value: value }))}
                    defaultValue={searchPO.postatus}
                    isStatic={true}
                  />
              },
              isSearched: searchPO.postatus,
              handleClear: () => handleChangeSearchValue('', 'postatus'),
              options: {
                width: 122
              }
            }],
            ["Distributor", "payload.purchaseorderdata.povendorid", {
              search: {
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.purchaseorderdata.povendorid}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'povendorid')}
                    defaultValue={searchPO.povendorid}
                    label="Search Distributor"
                    contracts={allContracts}
                    isShrink={true}
                  />
              },
              isSearched: searchPO.povendorid,
              handleClear: () => handleChangeSearchValue('', 'povendorid'),
              options: {
                width: 175,
              }
            }],
            ["Ownership", "payload.purchaseorderdata.popurchaseordertype"],
            ["Products", "payload.purchaseorderdata.purchaseorderdetaildataproducts", {
              options: {
                headerClassName: classes.centerCell,
                cellClassName: classes.centerCell,
              },
            }],
            ["Total Amount", "payload.purchaseorderdata.pototalamount", {
              options: {
                beforeText: '$',
                width: 175,
              }
            }],
            ["PO Details", "payload.purchaseorderdetaildata", {
              type: "collapse",
              columns: [
                ["Reference Number", "podproduct.sepreferencenumber", {
                  options: {
                    width: 150,
                  }
                }],
                ["Product Name", "podproduct.sepproductname", {
                  options: {
                    width: 170,
                    tooltip: true,
                  }
                }],
                ["Manufacturer", "podproduct.sepproductmanufacturer", {
                  search: {
                    component:
                      <AdaptiveSearch
                        getValue={(c) => c.payload.purchaseorderdata.sepproductmanufacturer}
                        handleChangeSearch={(v) => handleChangeSearchValue(v, 'sepproductmanufacturer')}
                        defaultValue={searchPO.sepproductmanufacturer}
                        label="Search Manufacturer"
                        contracts={allContracts}
                        isShrink={true}
                      />
                  },
                  isSearched: searchPO.sepproductmanufacturer,
                  handleClear: () => handleChangeSearchValue('', 'sepproductmanufacturer'),
                  options: {
                    width: 175,
                  }
                }],
                ["Product Description", "podproduct.sepproductdescription", {
                  options: {
                    width: 200,
                    tooltip: true,
                  }
                }],
                ["Quantity", "podquantity", {
                  type: "render",
                  render: (param) => (
                    <>
                      {parseInt(param.value)}
                    </>
                  ),
                  options: {
                    headerClassName: classes.centerCell,
                    cellClassName: classes.centerCell,
                  },
                }],
                ["Price", "podunitprice", {
                  type: "render",
                  render: (param) => (
                    <>
                      <span className={checkChangedPrice(param.row) ? classes.changePrice : null}>
                        {param.value !== "" && <>$</>}{param.value}
                      </span>
                      {
                        isEnableChangeOwnership(param.row.purchaseorderdata.postatus)
                          ?
                          <EditPrice
                            priceValue={param.value}
                            contract={param.row}
                            setPriceValue={handleChangePrice}
                          />
                          : null
                      }
                    </>
                  ),
                  options: {
                    width: 150,
                    cellClassName: classes.editPriceItem // Price change color based on condition
                  }
                }],
                ["Lot Code", "podproduct.seplotcode", {
                  options: {
                    headerClassName: classes.minWdt130
                  }
                }],
                ["Product Type", "podproduct.sepproducttype", {
                  options: {
                    headerClassName: classes.minWdt130
                  }
                }],
              ],
              actions: [
                [
                  "Action",
                  [
                    [["Delete", [<img alt="" src={deleteIcon} />, <img alt="" src={deleteDisableIcon} />],
                      (product) => isEnableDeleteSubProduct(product.purchaseorderdata.postatus)],
                      handleDeleteSubProduct],
                  ],
                ],
              ],
            }],

          ]}

          actions={[
            [
              "Actions",
              [
                [["Send PO", [<img alt="" src={sendIcon} />, <img alt="" src={sendDisableIcon} />],
                  (c) => isEnableSendVendor(c.payload.purchaseorderdata.postatus)],
                  setSendPOData],
                [["Edit PO", [<img alt="" src={editIcon} />, <img alt="" src={editDisableIcon} />],
                  (c) => isEnableEdit(c.payload.purchaseorderdata.postatus)],
                  handleEditPO],
                [["Archive PO", [<img alt="" src={archiveIcon} />, <img alt="" src={archiveDisableIcon} />],
                  (c) => isEnableArchivePurchaseOrder(c)],
                  handleArchivePO],
              ],
            ]
          ]}
          options={{
            statusEmpty: (allContracts.length === 0)
          }}
        />

        <SendPO
          openModal={sendPOOpen}
          setSendPOOpen={setSendPOOpen}
          handleSendPO={handleSendPO}
          selectedPOData={sendSelectedPOData}
          productItemsList={sendPoproductItemsList}
          pocommentdata={poComment}
          setConductFormComment={setPoComment}
        />

        <ModalDialogBox
          open={sendPoSuccess}
          setOpen={setSendPoSuccess}
          heading={"Successfully Sent PO"}
          type="success"
          text={'<p>You have successfully Sent the Purchase Order to Distributor, You can Track your Purchase Order Status on this page.</p>'}
          handleRequest={setSendPoSuccessRequest}
          firstBtn={"Ok"}
        />

        <ModalDialogBox
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          heading={"Delete  Product?"}
          type="warning"
          text={`<p>This product will be deleted from under the PO 
            (${deleteProductData.purchaseorderdata && deleteProductData.purchaseorderdata.popurchaseorderid}). 
            You will not be able to Recover it.</p>`}
          handleRequest={handleDeleteProductItem}
          secondBtn={'No'}
          handleSecRequest={handleSecRequest}
        />

        <ModalDialogBox
          open={openWarning}
          setOpen={setOpenWarning}
          heading={"Warning"}
          type="warning"
          text={'<p> You will not be able to Recover it.</p>'}
          handleRequest={handleModalYes}
          secondBtn={'No'}
          handleSecRequest={handleSecRequestWarning}
        />

        <DetailViewDrawer
          state={detailViewer}
          toggleDrawer={(open) => setDetailViewer(open)}
          selectedPOData={poData}
          productItemsList={productList}
          commentsList={commentList}
        />

      </div>
    </>
  );
}

export default withLoading(NewPurchaseOrderListContracts);
