import React, { useState, useEffect, Fragment } from "react";
import classNames from "classnames";
import {
  Grid, Accordion, AccordionDetails, AccordionSummary, Typography, Button, IconButton, ListItemSecondaryAction,
  Table, TableBody, TableRow, TableCell, List, ListItem, TableHead , ListItemText, Badge
} from "@material-ui/core";
import ModalDialog from '../../components/Modals/NewGeneralModal';
import SnackbarModal from "../../components/Modals/SnackbarModal";
import CustomTooltip from "../../components/Tooltip/CustomTooltip";
import { ProductTypes, Sides, WasteTypes, getTotalPrice } from "../../models/Product";
import { Ownerships } from "../../models/Ownership";  
import { checkComplianceStatusExpiration, checkComplianceStatusSide } from "../../models/SurgicalEventCompliance";
import EditProduct, { ComplianceWarning, defaultPattern, checkChangedPrice } from './edit-product1';
import editIcon from "../../icons/new-edit-icon.svg";
import deleteIcon from "../../icons/new-delete.svg";
import { formatDate, /* getQuantityOnOrder */ } from "../../services/util";
import { PRODUCT_ADD_EVENT, PRODUCT_UPDATE_EVENT, PRODUCT_DELETE_EVENT, EVENT_FINISHED, PREF_DELETE_EVENT } from "../../store/actions/constants";
import useProduct, { useSurgicalEvent, useItemDetails } from "../../hooks/useProduct";
import useEvent from "../../hooks/useEvent";
import { damlFetchPrefCards } from '../../services/axios/daml-json-api';
import useStyles from "./styles";
import prefCardLogo from "../../icons/Pref_Card_Logo.svg";
import trash_icon from "../../icons/trash_icon.svg";
import { useTranslation } from 'react-i18next';
import PrefCaseUsage from './caseusage/caseUsage';
import { useParty } from "@daml/react";
import { useUserState } from "../../context/UserContext";
import { useDamlState } from "../../context/DamlContext";


const defaultHeaderPattern = [
  {
    key: 'sepreferencenumber', label: 'Ref: ', className: 'name ref',
  },
  {
    key: 'seplotcode', label: 'Lot/SN:', className: 'name lotcode',
  },
];

/**
 * Edit Product Modal (small size)
 * @param {Boolean} open 
 * @param {Function} setOpen 
 * @param {Object} options 
 * @param {Object} classnames { title: null, body: null, table: null }
 * @returns 
 */
export const EditProductModal = ({
  open, setOpen, options = {}, classnames = {},
}) => {

  options = {
    isLargeCompliance: true,
    isSecondary: true,
    designPattern: defaultPattern,              // For Edit Modal SE
    headerPattern: defaultHeaderPattern,
    button: 'Save',
    isCancel: true,
    isStyle: false,                             // To show Cancel button
    isExtraSnackbar: false,                     // If it needs to show extra SnackbarModal
    extraSnackbarContent: '',
    extraSnackbarActions: [],
    extraSnackbarOpen: false,
    extraSnackbarSetOpen: () => { },
    extraSnackbarOptions: {},
    bodyHeight: 640,                            // Optional height of body content
    isRequireChange: true,                      // Require to change any field
    isRequireConfirm: false,                    // Require to show Confirm Warning
    width: 680,
    isChangedPrice: false,
    setIsChangedPrice: () => { },
    ...options,
  };

  const classes = useStyles();

  const { product: productItems, isChanged } = useProduct(options.isSecondary);


  const { onEventStart } = useEvent();
  const [openToast, setOpenToast] = useState(false);
  const [openToastContent, setOpenToastContent] = useState("");

  // handle to close
  const handleClose = () => {
    setOpenToast(false);
    setOpenToastContent("");
    if (options.isExtraSnackbar) options.extraSnackbarSetOpen(false);
    setOpen(false);
  };

  /**
   * If some fields are changed, then open toast.
   * Else, then close.
   */
  const handleOpenToast = () => {
    if (isChanged) {
      // toast
      options.isChangedPrice ? setOpenToastContent("Do you confirm the price change?") : setOpenToastContent("Would you like to save the changes?");
      setOpenToast(true);
    }
    else {
      handleClose();
    }
  };
  
  // handle to click complete button e.g. "Save"
  const handleUpdateClick = () => {
    // If any field is changed or require flag is false
    if (!options.isRequireChange || isChanged) {
      if (options.isRequireConfirm) {
        // Show confirm warning
        handleOpenToast();
      }
      else {
        onEventStart(options.isSecondary ? PRODUCT_UPDATE_EVENT : PRODUCT_ADD_EVENT);
      }
    }
    else {
      handleClose();
    }
  };

  // handle to click cancel button
  const handleCancelClick = () => {
    handleClose();
  };

  useEffect(() => {
    if (!open) {
      if (typeof options.setIsChangedPrice === 'function') options.setIsChangedPrice(false);
    }
  }, [open, options]);


  return (
    <ModalDialog
      size="md"
      open={open}
      setOpen={setOpen}
      title={(
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classNames(classes.newSETitlePanel, classnames.title)}
        >
          {
            options.headerPattern.map(({ key, label, className, value }) => (
              <Typography key={key} variant="inherit" component="span" className={className} >
                {label}{(value ?? productItems[key])}
              </Typography>
            )
            )
          }
        </Grid>
      )}
      content={(
        <Grid className={classNames(classes.newSEBodyPanel, classes.newSEBodyPanel1, classnames.body)} >
          <EditProduct
            options={{
              isLargeCompliance: true,
              isSecondary: true,
              isContractUpdate: true,
              className: classnames.table,
              setIsChangedPrice: options.setIsChangedPrice,
              isCreateEditPoSE: true,
              ...options,
            }}
            designPattern={options.designPattern}
          />
          <SnackbarModal
            open={openToast}
            setOpen={setOpenToast}
            type="warning"
            title=""
            content={openToastContent}
            vertical="center"
            horizontal="center"
            width={345}
            height={150}
            transition="fade"
            className={classes.toastLarge}
            options={{
              delay: 500,
              validateULE: true,
            }}
            actions={[
              {
                label: 'Yes',
                handle: () => {
                  onEventStart(options.isSecondary ? PRODUCT_UPDATE_EVENT : PRODUCT_ADD_EVENT);
                },
              },
              {
                label: 'No',
                handle: () => {
                  handleClose();
                },
              }
            ]}
          />
          {
            options.isExtraSnackbar &&
            <SnackbarModal
              open={options.extraSnackbarOpen}
              setOpen={options.extraSnackbarSetOpen}
              type="warning"
              title=""
              content={options.extraSnackbarContent}
              vertical="center"
              horizontal="center"
              width={380}
              height={150}
              transition="fade"
              className={classes.toastLarge}
              options={{
                delay: 500,
                ...options.extraSnackbarOptions,
                validateULE: true,
              }}
              actions={options.extraSnackbarActions}
            />
          }
        </Grid>
      )}
      actions={(
        <>
          {
            options.isCancel &&
            <Button
              variant="contained"
              color="primary"
              onClick={handleCancelClick}
              className={classNames(classes.newCompleteBtn, "secondary-color")}
            >
              Cancel
            </Button>
          }
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateClick}
            className={classes.newCompleteBtn}
          >
            {options.button}
          </Button>
        </>
      )}

      handleCloseRequest={handleOpenToast}
      height={options.bodyHeight}
      width={options.width}
    />
  );
};

export const DefaultProductHeader = ({
  productItemsList = [], isShowToolTip = true
}) => {

  const classes = useStyles();

  // count each Product Type
  const productTypes = productItemsList.reduce((list, p) => {
    if (p.sepproducttype in list) list[p.sepproducttype]++;
    else list[p.sepproducttype] = 1;
    return list;
  }, {});
  console.log('productItemsList', productItemsList);
  return (
    <Grid item>
      <Typography variant="inherit" className={classes.productCount}>Product total:{' '}</Typography>
      {
        productItemsList.length === 0
          ?
          <Typography variant="inherit">0</Typography>
          :
          (
          isShowToolTip ? 
          <CustomTooltip
            title={(
              <List>
                {
                  Object.entries(productTypes).map(([k, v], i) => (
                    <ListItem key={i} >
                      <ListItemText primary={ProductTypes[k]} />
                      <ListItemSecondaryAction>{v}</ListItemSecondaryAction>
                    </ListItem>
                  ))
                }
              </List>
            )}
            arrow
            // open={true}
            classes={{ tooltip: classes.productTooltip }}
          >
            <Badge badgeContent='i' color="primary" overlap="circular" showZero={false} >
              <Typography variant="inherit" >{productItemsList.length}</Typography>
              {/* <Typography variant="inherit">{produtTotalCount === 0 ? productItemsList.length : produtTotalCount}</Typography> */}
            </Badge>
          </CustomTooltip>
          :
          <Typography variant="inherit" className={classes.productCount}>{productItemsList.length}</Typography>
          )

      }
    </Grid>
  );
};

/**
 * Added Product List Header Component
 * @param {Array} productItemsList 
 * @param {Object} options 
 * @returns 
 */
const AddedProductHeader = ({
  productItemsList = [], options = {},
}) => {
  options = {
    isShow : true,
    ...options
  }

  return (
    <>
      <Typography className="product-title">
        Added Products
      </Typography>
      {
        options.isShow &&
        < Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="product-subtitle"
        >
          <DefaultProductHeader productItemsList={productItemsList}/>
          <Grid item>
            <Typography variant="inherit">Total Price of Products:{' '}</Typography>
            <Typography variant="inherit">${getTotalPrice(productItemsList)}</Typography>
          </Grid>
        </Grid >
      }
    </>
  );
};

/**
 * Added Product List Header Component
 * @param {Array} productItemsList 
 * @param {Object} options 
 * @returns 
 */
const AddedProductHeader1 = ({
  productItemsList = [], options = {},
}) => {

  options = {
    isShowToolTip : false
  }

  const classes = useStyles();

  return (
    <>
      < Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="flex-end"
        className="product-subtitle"
      >
        <DefaultProductHeader 
        productItemsList={productItemsList} 
        isShowToolTip ={options.isShowToolTip}
        />
        <Grid item className={classes.footerPrice}>
          <Typography variant="inherit">Total Price:{' '}</Typography>
          <Typography variant="inherit">${getTotalPrice(productItemsList)}</Typography>
        </Grid>
      </Grid >
    </>
  );
};

const getShowProductComp = ({
  key, label, value, valueClassName = "value", label1, value1, valueClassName1 = "value", details, detailIsCol = true,
}) => {
  if (!key) return null;
  if (detailIsCol) return (
    <TableRow key={key}>
      <TableCell className="details">{details}</TableCell>
      <TableCell className="label">{label}</TableCell>
      <TableCell className={valueClassName}>
        {value}
      </TableCell>
      {
        !!label1 &&
        <TableCell className="label">{label1}</TableCell>
      }
      {
        !!value1 &&
        <TableCell className={valueClassName1}>{value1}</TableCell>
      }
    </TableRow>
  );
  else return (
    <Fragment key={key}>
      <TableRow>
        <TableCell className="details left" colSpan={4}>{details}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="label">{label}</TableCell>
        <TableCell className={valueClassName}>{value}</TableCell>
        {
          !!label1 &&
          <TableCell className="label">{label1}</TableCell>
        }
        {
          !!value1 &&
          <TableCell className={valueClassName1}>{value1}</TableCell>
        }
      </TableRow>
    </Fragment>
  );
};

const matchListProduct = ({
  key, obj, label = "", details = "", className = "", key1, label1, className1 = "", detailIsCol = true, 
}) => {
  if (!key) return null;
  const matching = (key, label = "", className = "") => {
    let value = "", valueClassName = `value ${className}`;
    switch (key) {
      case 'sepudi':
        value = obj[key];
        label = "UDI :";
        break;
      case 'sepreferencenumber':
        value = obj[key];
        label = "Ref :";
        break;
      case 'sepproductmanufacturer':
        value = obj[key];
        label = "Manufacturer :";
        break;
      case 'sepdistributor':
        value = obj[key];
        label = "Distributor :";
        break;
      case 'sepproductname':
        value = obj[key];
        label = "Name :";
        break;
      case 'sepproductdescription':
        value = obj[key];
        label = "Description :";
        break;
      case 'sepproductexpiration':
        if (key in obj) value = formatDate(obj[key]);
        label = "Expiration :";
        break;
      case 'seplotcode':
        value = obj[key];
        label = "Lot/SN :";
        break;
      case 'sepproductprice':
        value = (typeof obj[key] === 'string' || typeof obj[key] === 'number') ? `$${obj[key]}` : '';
        label = "Price :";
        if (checkChangedPrice(obj)) {
          valueClassName = `${valueClassName} red`;
          // if (obj.classes && typeof obj.classes === 'object') obj.classes.summary = "red";
          // else obj.classes = { summary: "red" };
        }
        break;
      case 'sepproducttype':
        if (key in obj) value = ProductTypes[obj[key]];
        label = "Product Type :";
        break;
      case 'sepproductwaste':
        if (key in obj) value = WasteTypes[obj[key]];
        label = "Waste :";
        break;
      case 'sepownership':
        if (key in obj) value = Ownerships[obj[key]];
        label = "Ownership :";
        break;
      case 'sepproductside':
        if (key in obj) value = Sides[obj[key]];
        label = "Product Side :";
        break;
      case 'sepuom':
        value = obj[key];
        label = "UoM :";
        break;
      case 'idoh':
        value = 1;
        label = "DoH :";
        break;
      default:
        if (!!label) {
          value = obj[key];
        }
    }
    return { label, value, valueClassName };
  }
  const { label: tempLabel, value, valueClassName } = matching(key, label, className);
  if (!!tempLabel) label = tempLabel;
  if (!key1) return getShowProductComp({ key, value, label, details, valueClassName, detailIsCol });
  // label1, value1
  const { label: tempLabel1, value: value1, valueClassName: valueClassName1 } = matching(key1, label1, className1);
  if (!!tempLabel1) label1 = tempLabel1;
  return getShowProductComp({ key, value, label, details, valueClassName, label1, value1, valueClassName1, detailIsCol });
};

export const defaultListPattern = [
  {
    key: 'sepudi', key1: 'sepproducttype', details: 'Product Details', detailIsCol: false,
  },
  {
    key: 'sepreferencenumber', key1: 'sepproductwaste',
  },
  {
    key: 'sepproductmanufacturer', key1: 'sepownership',
  },
  {
    key: 'sepdistributor', key1: 'sepproductside',
  },
  {
    key: 'sepproductname', key1: 'sepuom',
  },
  {
    key: 'sepproductdescription', key1: 'sepproductprice',
  },
  {
    key: 'seplotcode',
  },
  {
    key: 'sepproductexpiration',
  },
];

const getShortDesc = (obj) => {
  const MAX_LEN = 43;
  const len = MAX_LEN - `${obj.sepreferencenumber}${obj.seplotcode}${obj.sepproductprice}`.length;
  return obj.sepproductdescription?.slice(0, Math.max(len, 0));
};

export const defaultAccordionPattern = [
  {
    label: 'Ref:',
    value: (obj) => obj.sepreferencenumber,
  },
  {
    label: 'Lot:',
    value: (obj) => obj.seplotcode,
  },
  {
    label: 'Description:',
    value: (obj) => obj.sepproductdescription,
    tooltip: getShortDesc,
  },
  {
    label: 'Price:',
    value: (obj) => `$${obj.sepproductprice}`,
  },
];

/**
 * Custom Accordion with item list
 * Instead of Component, ComponentProps, render={(obj) => <Compoent />}
 * @param {Array} itemsList 
 * @param {Object} classes 
 * @param {Function} handleEditOpen 
 * @param {Function} handleDeleteOpen 
 * @param {Object} isEnableCollapse 
 * @param {Function} renderSummary 
 * @param {Function} renderDetails
 * @returns 
 */
const CustomAccordion = ({
  itemsList = [], classes = {}, handleEditOpen, handleDeleteOpen, isEnableCollapse = true, showEditIcon = true,
  renderSummary, renderDetails,
}) => {

  itemsList = itemsList.map(obj => {
    // Check out "Off Contract"
    // Old case: checkChangedPrice(obj)
    if ((!obj?.units || obj?.units.length === 0) && (!obj.sepproductesprice && obj.sepproductesprice !== "0")) {
      if (obj.classes && typeof obj.classes === 'object') obj.classes.summary = "red";
      else obj.classes = { summary: "red" };
    }
    return obj;
  });

  const [expanded, setExpanded] = useState(-1);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    if (isEnableCollapse) {
      if (event.target.tagName.toLowerCase() === 'img' || event.target.tagName.toLowerCase() === 'button') return;
      setExpanded(isExpanded ? panel : false);
      const parentElm = event.target.closest(`div.MuiAccordion-root`);
      if (parentElm && parentElm.children && parentElm.children.length > 1) {
        const collapseElm = parentElm.children[1];
        const transitionDuration = collapseElm.style['transition-duration'];
        setTimeout(() => {
          collapseElm && collapseElm.scrollIntoView();
        }, (transitionDuration ? parseInt(transitionDuration) + 30 : 390));
      }
    }
  };


  return (
    <>
      {
        itemsList.map((obj, index) => (
          <Accordion
            key={index}
            expanded={expanded === index}
            onChange={handleChangeAccordion(index)}
            className="main-accordion"
          >
            <AccordionSummary className={obj.classes?.summary}>
              <Typography className="accordion-header-index">{index + 1}</Typography>
              {
                renderSummary && typeof renderSummary === 'function' &&
                renderSummary(obj)
              }
              {
                handleDeleteOpen && typeof handleDeleteOpen === 'function' &&
                <IconButton onClick={() => handleDeleteOpen(index)}>
                  <img src={deleteIcon} alt="" />
                </IconButton>
              }
            </AccordionSummary>
            <AccordionDetails>
              {
                renderDetails && typeof renderDetails === 'function' &&
                renderDetails(obj)
              }
              {
                handleEditOpen && typeof handleEditOpen === 'function' && showEditIcon &&
                <IconButton className="edit-btn" onClick={() => handleEditOpen(index)}>
                  <img src={editIcon} alt="" />
                </IconButton>
              }
            </AccordionDetails>
          </Accordion>
        ))
      }
    </>
  )
};

/**
 * Product Accordion Summary
 * @param {Array} accordionPattern 
 * @param {Object} obj 
 * @param {Object} classes 
 * @returns 
 */
const ProductAccordionSummary = ({
  accordionPattern = [], obj = {}, classes = {},
}) => {
  return (
    <>
      {
        accordionPattern.map(({ label, value, tooltip, className }, i) => {
          const val = (typeof value === 'function') ? value(obj) : value;
          const strTooltip = (tooltip && typeof tooltip === 'function') ? tooltip(obj) : null;
          const cls = (className !== undefined) ? (
            (typeof className === 'function') ? className(obj) : className
          ) : "";
          return (
            <Fragment key={`accordion-${i}`}>
              <Typography className="accordion-header-label">{label}</Typography>
              {
                strTooltip
                  ?
                  <CustomTooltip title={val} classes={{ tooltip: classes.descTooltip }}>
                    <Typography className={`accordion-header-text ${cls}`}>{strTooltip}</Typography>
                  </CustomTooltip>
                  :
                  <Typography className={`accordion-header-text ${cls}`}>{val}</Typography>
              }
            </Fragment>
          );
        })
      }
    </>
  );
};

/**
 * Added Product List
 * @param {Object} className 
 * @param {Object} options 
 * @param {Component} HeaderComponent 
 * @param {Array} listPattern 
 * @param {Array} accordionPattern 
 * @returns 
 */
export const AddedProductList = ({
  className, options = {}, HeaderComponent = AddedProductHeader,
  listPattern = defaultListPattern, accordionPattern = defaultAccordionPattern,
}) => {


  const [isChangedPrice, setIsChangedPrice] = useState(false);

  options = {
    isShowFooter: false,
    isEnableCollapse: true,
    isEnableEditProduct: true,
    isEnableDeleteProduct: true,
    keyProduct: 'product',              // Key of Detail for Product, e.g. product, iproduct, invdproduct, podproduct, etc.
    keyDetail: null,                    // Key of Detail with Product in List, it's used for deep accordion
    keySummary: null,                   // Key of Summary in List, it works only if keyDetail is not null.
    summaryPattern: [],                 // Pattern of Summary in List, it also works only if keyDetail is not null.
    isEnableCompliance: true,
    ...options,
    edit: {
      isRequireConfirm: true,
      isChangedPrice: isChangedPrice,
      setIsChangedPrice: setIsChangedPrice,
      ...options.edit,
    },
    header: {
      isShow: false,
      ...options.header,
    },
  };


  const classes = useStyles();
  const { clearProduct: setProductItems } = useProduct(true);
  const { surgicalevent: surgicalEventData } = useSurgicalEvent();
  const { details: productDetailsList, setItemIndex } = useItemDetails();
  const { event, status, onEventStart } = useEvent();
  const { t } = useTranslation();
  const productItemsList = productDetailsList.map(p => ({ ...p, ...p[options.keyProduct] }));
  const detailsList = (options.keyDetail && options.keySummary) ?
    productDetailsList.map(p => ({ ...p, ...p[options.keySummary] })) : [];
  // Edit Product Modal
  const [openEditModal, setOpenEditModal] = useState(false);
  // Delete Product Toast
  const [openToast, setOpenToast] = useState(false);
  const [openToastModal, setOpenToastModal] = useState(false);
  const [msg, setMsg] = useState('');

  const handleEditOpen = (index) => {
    if (!options.isEnableEditProduct) return;
    setProductItems({ ...productDetailsList[index], ...productDetailsList[index][options.keyProduct] });
    setItemIndex(index);
    setOpenEditModal(true);
  };

  const handleDeleteOpen = (index) => {
    if (!options.isEnableDeleteProduct) return;
    setItemIndex(index);
    // toast
    setOpenToast(true);
  };

  useEffect(() => {
    if (event === PRODUCT_UPDATE_EVENT && (status === EVENT_FINISHED || !status)) {
      isChangedPrice ? setMsg('Price successfully changed') : setMsg('Changes successfully saved');
      setOpenToastModal(true);
      setOpenEditModal(false);
    }
  }, [event, status, isChangedPrice]);


  return (
    <>
      <Grid className={(className ? classNames(classes.addedProductPanel, className) : classes.addedProductPanel)}>
        <HeaderComponent
          productItemsList={productItemsList}
          options={options.header}
        />
        {
          options.keyDetail === null
            ?
            <CustomAccordion
              itemsList={productItemsList}
              classes={classes}
              handleEditOpen={handleEditOpen}
              handleDeleteOpen={handleDeleteOpen}
              showEditIcon={options.isEnableEditProduct}
              isEnableCollapse={options.isEnableCollapse}
              renderSummary={(obj) => (
                <ProductAccordionSummary
                  obj={obj}
                  classes={classes}
                  accordionPattern={accordionPattern}
                />
              )}
              renderDetails={(obj) => (
                <ProductDetailTable
                  classes={classes}
                  listPattern={listPattern}
                  product={obj}
                  surgicalEventData={surgicalEventData}
                  isEnableCompliance={options.isEnableCompliance}
                />
              )}
            />
            :
            <CustomAccordion
              itemsList={detailsList}
              classes={classes}
              isEnableCollapse={options.isEnableCollapse}
              showEditIcon={options.isEnableEditProduct}
              renderSummary={(obj) => (
                <ProductAccordionSummary
                  obj={obj}
                  classes={classes}
                  accordionPattern={options.summaryPattern}
                />
              )}
              renderDetails={(obj) => (
                <CustomAccordion
                  classes={classes}
                  itemsList={obj[options.keyDetail]}
                  handleEditOpen={handleEditOpen}
                  showEditIcon={options.isEnableEditProduct}
                  handleDeleteOpen={handleDeleteOpen}
                  isEnableCollapse={options.isEnableCollapse}
                  renderSummary={(obj1) => (
                    <ProductAccordionSummary
                      obj={obj1[options.keyProduct]}
                      classes={classes}
                      accordionPattern={accordionPattern}
                    />
                  )}
                  renderDetails={(obj1) => (
                    <ProductDetailTable
                      classes={classes}
                      listPattern={listPattern}
                      product={{ ...obj1, ...obj1[options.keyProduct] }}
                      surgicalEventData={surgicalEventData}
                      isEnableCompliance={options.isEnableCompliance}
                    />
                  )}
                />
              )}
            />
        }
        <EditProductModal
          open={openEditModal}
          setOpen={setOpenEditModal}
          options={options.edit}
        />

      <SnackbarModal
        open={openToastModal}
        setOpen={setOpenToastModal}
        title=""
        content={msg}
        width={330}
        height={88}
        className={classes.toastMedium}
      />

        <SnackbarModal
          open={openToast}
          setOpen={setOpenToast}
          type="warning"
          title=""
          content={t('warnings.surgicalevent.deleteseproduct')}
          vertical="center"
          horizontal="center"
          width={345}
          height={150}
          transition="fade"
          className={classes.toastLarge}
          options={{
            validateULE: true,
          }}
          actions={[
            {
              label: 'Acknowledge',
              handle: () => onEventStart(PRODUCT_DELETE_EVENT),
            },
            {
              label: 'Decline',
            }
          ]}
        />
      </Grid>
      {
        options.isShowFooter &&
        <Grid item>
          <AddedProductHeader1
            productItemsList={productItemsList}
            options={options.header}
          />
        </Grid>
      }
    </>
  );
};

const PrefCardDetails = () => {
  const { t } = useTranslation();
  const { surgicalevent: surgicalEventData, prefdata, setPrefData, setPrefDataLoad } = useSurgicalEvent();
  const classes = useStyles();
  const { token } = useUserState();
  const { role } = useDamlState();
  const party = useParty();

  const [openCard, setOpenCard] = useState(false);
  const [detailData, setDetailData] = useState([]);
  const { onEventStart } = useEvent();
const [selectedRow,setSelectedRow] = useState(null);


  const handlePrefCardCaseUsage = (prefDetailData) => {
    setSelectedRow(prefDetailData.contractid);
    setDetailData(prefDetailData)
    setOpenCard(true);
  };

  useEffect(() => {
    const searchPrefCards = async () => {
      const newprefdata = await searchPrefCardsContracts({
        seid: surgicalEventData.seid,
        token,
        hospital: role === 'Hospital' ? party : null,
      });
      setPrefDataLoad(newprefdata);
    };
    searchPrefCards();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    

  return (<>{
    prefdata?.length > 0
      ?
      (<Table className={classNames(classes.rowWithBorder, classes.prefCardTable)} >
        <TableHead>
          <TableRow>
            <TableCell className={classNames(classes.cellWithBorder, classes.cellHeaderBold)} width={360}>{t('surgicalEvent.createSE.pref.table.procedure')}</TableCell>
            <TableCell className={classNames(classes.cellWithBorder, classes.cellHeaderBold)} width={70}>{t('surgicalEvent.createSE.pref.table.prefcard')}</TableCell>
            <TableCell className={classNames(classes.cellWithBorder, classes.cellHeaderBold)} width={60}>{t('surgicalEvent.createSE.pref.table.action')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            prefdata?.map((rowElement, index) => (
              <TableRow key={rowElement.contractid} className={ classNames (classes.highlightedRow, (selectedRow===rowElement.contractid ? classes.prefCardSelected : null)) }>
                <TableCell>{rowElement.cptCodes[0]} {rowElement?.name}</TableCell>
                <TableCell align="center"> <img src={prefCardLogo} alt="" onClick={() => handlePrefCardCaseUsage(rowElement)} /></TableCell>
                <TableCell align="center"><img src={trash_icon} alt=""
                  onClick={() => {
                    if (rowElement.contractid) {
                      onEventStart(PREF_DELETE_EVENT, rowElement.contractid)
                    }
                    prefdata.splice(index, 1)
                    setPrefData([...prefdata])
                  }}
                /></TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table >)
      :
      null
  }
    {
      openCard
        ?
        <PrefCaseUsage
          open={openCard}
          setOpen={setOpenCard}
          detailData={detailData}
        />
        :
        null
    }
  </>)
}


/**
 * Product Detail Table when expending accordion
 * @param {Array} listPattern
      * @param {Object} product
      * @param {Object} surgicalEventData
      * @param {Boolean} isEnableCompliance
      * @returns
      */
export const ProductDetailTable = ({
  listPattern = defaultListPattern, product = {}, surgicalEventData = {}, isEnableCompliance = true,
}) => {
  // let surgicalEventId = surgicalEventData.id;
  const detailIsCol = listPattern.reduce((flag, { detailIsCol }) => {
    if (detailIsCol !== undefined) return detailIsCol;
    return flag;
  }, true);
  return (
    <Table className="accordion-table" >
      <TableBody>
        {
          listPattern.map(({
            key, label, details, key1, label1, className, className1
          }) => matchListProduct({
            key, obj: product, label, details, key1, label1, className, className1, detailIsCol, 
          }))
        }
        {
          isEnableCompliance &&
          <TableRow>
            <TableCell colSpan={5}>
              <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
              >
                {
                  checkComplianceStatusExpiration(surgicalEventData, product) &&
                  <Grid item>
                    <ComplianceWarning
                      type="expiration"
                      size="sm"
                    />
                  </Grid>
                }
                {
                  checkComplianceStatusSide(surgicalEventData, product) &&
                  <Grid item>
                    <ComplianceWarning
                      type="side"
                      content={surgicalEventData.surgicalside}
                      size="sm"
                    />
                  </Grid>
                }
              </Grid>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    </Table>
  );
};

/**
 * Add or Edit Product
 * It has Barcode Scanner & Retrieve ES, GUDID
 * @param {Object} options
      * @returns
      */
export default function AddEditProduct({
  options = {},
}) {


  options = {
    isEditable: true,
    isEnableEditProduct: true,
    isEnableDeleteProduct: true,
    ...options,
    edit: {
      ...options.edit,
    },
    update: {
      ...options.update,
    },
    keyProduct: 'product',
  };

  const classes = useStyles();


  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Grid item>
        <EditProduct
          options={{
            isEditable: options.isEditable,
            isEnableCompliance: false,
            isEnableAdd: true,
            isContractUpdate: true,
            isCreateEditPoSE: true,
            ...options.edit,
          }}
        />
      </Grid>
      <Grid item>
        <AddedProductList
          options={{
            isEnableEditProduct: (options.isEditable && options.isEnableEditProduct),
            isEnableDeleteProduct: (options.isEditable && options.isEnableDeleteProduct),
            edit: {
              ...options.edit,
              ...options.update, // Options only for Update Product
            },
          }}
          className={classNames(classes.editProductPanel2, classes.editProductPanel)}
        />
        <Grid item className={classes.prefTablePanel}>
          <PrefCardDetails />
        </Grid>
      </Grid>
    </Grid>

  )
};

/**
 * Search PrefCards contracts
 * @param {String} token 
 * @param {String} seid 
 * @param {String} hospital 
 * @returns {Array}
 */
export const searchPrefCardsContracts = async ({ 
  token, seid, hospital, 
}) => {
  const contracts = await damlFetchPrefCards({
    seid,
    token,
    hospital,
  });
  let newprefdata = contracts.map(item => {
    let detail = JSON.parse(item.payload.prefcarddetails);
    return { ...detail, contractid: item.contractId };
  });
  return newprefdata;
};
