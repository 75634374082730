import React, { useEffect, useState } from "react";
import classNames from "classnames";
import {
  Avatar,
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Divider,
  Switch,
  Button,
  Box,
} from "@material-ui/core";
import useStyles from "./styles";
import useStyles1 from "../surgicalevent/styles";
import useStyles2 from "../purchaseorder/styles";
import FileUploadDropzone from "../../components/FileUpload/FileUploadDropzone";
import { CustomTextField, CustomSelect } from "../../components/Inputs";
import { useDamlState } from "../../context/DamlContext";
import Download_Vector from "../../icons/Download_Vector.svg";
import { UserListModel } from "../../models/HospitalDetail";
// import { onChangeNumber } from "../../components/Modals/handles";
import { setByPath } from "../../components/Contracts/functions";
import { NODE_SERVER_ENDPOINT } from "../../config";
import {
  UserAccessLevelVendor,
  UserAccessLevelHospital,
} from "../../models/UserAccessLevel";
import { CustomLoading } from "../../hoc/withLoading";
import csvIcon from "../../icons/iwwa_file-csv.svg";
import { useLedger, useStreamQueries } from "@daml/react";
import { UserList as UserListTemplate } from "../../services/daml-modules1";
import { updateUser, getUserList } from "../user/handle";
import { requiredUser } from "../../models/User";
import { useTranslation } from "react-i18next";
import {
  useLayoutDispatch,
  setLoading,
  unsetLoading,
} from "../../context/LayoutContext";
import SnackbarModal from "../../components/Modals/SnackbarModal";
import { changePasswordEmailSend } from "../../services/axios/auth-api";

const ProfileInfo = ({ setOpenToast, setToastContent, setSnackType }) => {
  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();
  const { t } = useTranslation();

  // states
  const [checked, setChecked] = useState(false);
  const [file, setFile] = useState(null);
  const [userFname, setUserFname] = useState("");
  const [userLname, setUserLname] = useState("");
  const [docFile, setDocFile] = useState();
  const [userlist, setUserlist] = useState({ ...UserListModel });
  const [ContractId, setContractId] = useState("");
  const [userError, setUserError] = useState();
  const [authUsers, setAuthUsers] = useState([]);
  const [openresetwarning, setopenresetwarning] = useState(false);
  const { role, roleCid, userlistPayload, rolePayload } = useDamlState();
  const ledger = useLedger();
  const layoutDispatch = useLayoutDispatch();

  console.clear();
  console.log("userlistPayload", userlistPayload);

  const handleCancel = () => {
    setUserlist(userlistPayload);
    handleName();
  };

  const { contracts, loading } = useStreamQueries(UserListTemplate, () => [
    { email: userlistPayload.email },
  ]);

  // handle when click "Update" button
  const handleSave = async (temp) => {
    let res;
    let authUserId = null;
    let authUserList = authUsers;
    let found = null;
    // update authUsers everytime
    authUserList = await getUserList();
    setAuthUsers(authUserList);
    // find a user in auth user list
    if (authUserList.length > 0) {
      found = authUserList.find(
        (a) =>
          a.email.toLocaleLowerCase() === userlist.email.toLocaleLowerCase()
      );
      if (found) authUserId = found.user_id;
    }
    if (authUserId && temp) {
      let newErrors = requiredUser(temp);
      if (Object.keys(newErrors).length > 0) {
        setUserError({ ...newErrors });
        console.log(userError);
        setSnackType("warning");
        setToastContent("Information is not sufficient");
        setOpenToast(true);
        return false;
      }
    } else {
      let newErrors = requiredUser(userlist);
      if (Object.keys(newErrors).length > 0) {
        setSnackType("warning");
        setToastContent("Information is not sufficient");
        setOpenToast(true);
        return false;
      }
    }
    if (authUserId && temp) {
      res = await updateUser({
        ledger,
        newuser: temp,
        ContractId,
        authUserId,
        roleCid,
        rolePayload,
        role: role,
        oldUser: found,
      });
      console.log(res);
    }
    if (authUserId && !temp) {
      res = await updateUser({
        ledger,
        newuser: userlist,
        ContractId,
        authUserId,
        roleCid,
        rolePayload,
        role: role,
        oldUser: found,
      });
    }
    await fetchData(temp || updateUser);
    unsetLoading(layoutDispatch);
    setSnackType("success");
    setToastContent("Saved succesfully");
    setOpenToast(true);
  };

  const handleName = () => {
    if (Object.keys(userlistPayload).length) {
      setUserlist(userlistPayload);
      setChecked(userlistPayload.isdarkenable);
      if (userlistPayload.name !== "" && userlistPayload.name.includes(" ")) {
        setUserLname(userlistPayload.name.split(" ")[1]);
        setUserFname(userlistPayload.name.split(" ")[0]);
      } else {
        setUserFname(userlistPayload.name);
      }
    }
  };
  const handleChangePasswordRequest = async () => {
    // console.log("[SettingPassword] handleRequest", checked);

    let res = null;
    try {
      // spinner
      setLoading(layoutDispatch);

      res = await changePasswordEmailSend({
        email: userlistPayload.email,
      });
      console.log("[SettingPassword] handleRequest", res);
    } catch (e) { }

    unsetLoading(layoutDispatch);

    if (!!res) {
      setSnackType("success");
      setToastContent(
        "Please check your email,Password reset instructions are sent."
      );
      setOpenToast(true);
    } else {
      setSnackType("error");
      setToastContent(`<p>Request Email sent was unsuccessful.</p>
            <p>Please contact <a href="mailto:info@tydei.io">info@tydei.io</a> for more information</p>`);
      setOpenToast(true);
    }
  };

  const getInput = (item) => {
    let view = null;

    switch (item.type) {
      case "dropdown":
        view = (
          <CustomSelect
            placeholder={item.placeholder}
            width="70%"
            value={item.value}
            items={item.items}
            options={{ disabled: item.isDisable }}
            onChange={item.onChange}
          />
        );
        break;
      case "switchBtn":
        view = (
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  color="default"
                  checked={item.checked}
                  onChange={item.onChange}
                  name="checked"
                />
              }
              // control={<CustomCheckbox
              //     color="default"
              //     checked={item.checked}
              //     setChecked={item.onChange}
              //     onChange={item.onChange}
              //     name="checked"
              // />}
              label="Dark Mode"
              color="secondary"
              className={classNames(classes.switchLeft1)}
            />
          </FormGroup>
        );
        break;

      default:
        view = (
          <CustomTextField
            placeholder={item.placeholder}
            value={item.value}
            type={item.types || "text"}
            options={{ disabled: item.isDisable }}
            width="70%"
            onChange={item.onChange}
          />
        );
        break;
    }
    return view;
  };

  function handleUpload(file, callback) {
    setFile(file);
  }

  const handleUploader = async () => {
    let formData = new FormData();
    formData.append("file", file);
    const response = await fetch(
      NODE_SERVER_ENDPOINT + "api/user-profile-picture/upload",
      {
        method: "POST",
        body: formData,
      }
    );
    return response;
  };

  const handleSaveBtn = async () => {
    setLoading(layoutDispatch);
    if (file) {
      const fileResponse = await handleUploader();
      if (fileResponse.status === 200) {
        const temp = { ...userlist, profileimg: file.name };
        handleSave(temp);
        unsetLoading(layoutDispatch);
      } else {
        unsetLoading(layoutDispatch);
        setToastContent(`${t("admin.myprofile.profile_upload_warning")}`);
        setOpenToast(true);
      }
    } else {
      handleSave();
      unsetLoading(layoutDispatch);
    }
  };

  // handle to update all fields
  const updateField =
    (key, isNumber = false) =>
      (e) => {
        if (key === "phone") {
          if (e.length > 12) {
            return;
          }
        }
        setUserlist(setByPath(e, key, userlist));
      };

  const handleUpdateName =
    (index = 0) =>
      (val) => {
        if (val.includes(" ")) {
          if (index === 0) {
            let [userFnames, userLnames] = val.split(" ", 2);
            setUserFname(userFnames);
            setUserLname(userLnames);
            setUserlist({ ...userlist, name: `${userFnames} ${userLnames}` });
          } else {
            setUserLname(val);
            setUserlist({ ...userlist, name: `${userFname} ${userLname}` });
          }
        } else {
          if (index === 0) {
            setUserFname(val);
            setUserlist({ ...userlist, name: `${val} ${userLname}` });
          } else {
            setUserLname(val);
            setUserlist({ ...userlist, name: `${userFname} ${val}` });
          }
        }
      };

  // preview icon of uploader
  const handlePreviewIcon = (fileObject, classes) => {
    const { name } = fileObject.file;

    return (
      <Box className="preview-item">
        <img src={csvIcon} className={classes.image} alt="" />
        {name}
      </Box>
    );
  };

  async function fetchData(userData) {
    let newUserData;
    if (userData) newUserData = userData?.profileimg;
    else newUserData = userlistPayload?.profileimg;
    try {
      setLoading(layoutDispatch);
      if (newUserData) {
        const response = await fetch(
          `${NODE_SERVER_ENDPOINT}api/sap-download/${newUserData}`,
          {
            method: "GET",
          }
        );
        if (response.status === 200) {
          const { data } = await response.json();
          setDocFile(data);
        } else {
          setToastContent(`${t("admin.myprofile.profile_upload_warning")}`);
          setOpenToast(true);
        }
      }
      unsetLoading(layoutDispatch);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    setUserlist(setByPath(checked, "isdarkenable", userlist));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  useEffect(() => {
    handleName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userlistPayload]);

  useEffect(() => {
    fetchData(userlistPayload);
    unsetLoading(layoutDispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading) {
      let newContract = contracts.map((p) => {
        setUserlist(p.payload);
        setContractId(p.contractId);
        return null;
      });
      console.log(newContract);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contracts, loading, userlistPayload]);

  useEffect(() => {
    if (!loading) {
      let newContract1 = contracts.map((p) => {
        setUserlist(p.payload);
        setContractId(p.contractId);
        return null;
      });
      console.log(newContract1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <CustomLoading />
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid container>
            <Grid xs={4} item className={classes.paddingAll}>
              <Grid sx={12}>
                <Typography className={classes.primaryText}>
                  Your Photo
                </Typography>
                <Typography className={classes.secondaryText}>
                  This will displayed on your profile
                </Typography>
                <Avatar
                  alt="Profile Picture"
                  src={docFile ? docFile : ""}
                  sx={{ width: 100, height: 100 }}
                  className={classes.profileAvatar}
                />
              </Grid>
              <Grid sx={12} className={classes.positionRelative}>
                <FileUploadDropzone
                  style={{ border: "1px solid black", position: "relative" }}
                  className={classNames(
                    classes2.uploadFile,
                    classes2.uploadFileNew,
                    classes2.uploadFile2,
                    classes2.profilePictureDropDown
                  )}
                  title=""
                  uploadLabel={
                    <>
                      <div className={classes2.uploadImg}>
                        <img src={Download_Vector} alt="vector-arrow" />
                      </div>
                      <span className="blueTitle">Click to upload </span>or drag
                      and drop PNG,JPG or GIF (max. 800 X 400px )
                    </>
                  }
                  changeFile={handleUpload}
                  isNonePadding={true}
                  isEnableUpload={false}
                  handlePreviewIcon={handlePreviewIcon}
                  maxFileSize={8000000}
                />
              </Grid>
              <Grid sx={12} className={classes.resetPasswordPosition}>
                <Button
                  variant="contained"
                  color="primary"
                  //onClick={handleChangePasswordRequest}
                  onClick={()=>setopenresetwarning(true)}
                  className={classNames(
                    classes.newCompleteBtnResetPassword,
                    "primary-color"
                  )}
                  title={t('warnings.myprofile.resetpasswordmessage')}
                >
                  Reset Password
                </Button>
              </Grid>

            </Grid>
            <Grid xs={8} item className={classes.paddingAll2}>
              <Grid container style={{ padding: "0px 0px 11px 0px" }}>
                <Grid item xs={4} className={classes.formLabel}>
                  <label>Name</label>
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField
                    value={userlist.name.split(" ")[0]}
                    onChange={handleUpdateName()}
                    width={"90%"}
                    placeholder="First Name"
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField
                    value={userlist.name.split(" ")[1]}
                    onChange={handleUpdateName(1)}
                    width={"90%"}
                    placeholder="Last Name"
                  />
                </Grid>
              </Grid>
              <Divider
                component="div"
                role="presentation"
                className={classes.dividerCss}
              />
              <Grid xs={12} item>
                {[
                  {
                    label: "User Id",
                    placeholder: "olivia@tydeihealth.com",
                    value: userlist.userid,
                    divider: false,
                    onChange: updateField("userid"),
                    isDisable: true,
                  },
                  {
                    label: "Email",
                    placeholder: "olivia@tydeihealth.com",
                    value: userlist.email,
                    divider: false,
                    onChange: updateField("email"),
                    isDisable: true,
                  },
                  {
                    label: "Mobile Number",
                    placeholder: "eg.123-456-7890",
                    value: userlist.phone,
                    divider: false,
                    onChange: updateField("phone"),
                    types: "number",
                  },
                  {
                    label: "Gender",
                    placeholder: "Select One",
                    type: "dropdown",
                    value: userlist.gender,
                    divider: true,
                    onChange: updateField("gender"),
                    items: ["Male", "Female"],
                  }, //check once
                  {
                    label: "Job Title",
                    placeholder: "Type here...",
                    value: userlist.jobtitle,
                    divider: false,
                    onChange: updateField("jobtitle"),
                  },
                  {
                    label: "Access Level",
                    placeholder: "Select one",
                    type: "dropdown",
                    value: userlist.accesslevel,
                    divider: true,
                    onChange: updateField("accesslevel"),
                    items:
                      role === "Hospital"
                        ? UserAccessLevelHospital
                        : role === "Vendor"
                          ? UserAccessLevelVendor
                          : [],
                    isDisable: !(userlist.accesslevel === "HManager" || userlist.accesslevel === "HFinanceAssociate"),
                  },
                  {
                    label: "Preference",
                    placeholder: "",
                    type: "switchBtn",
                    divider: false,
                    onChange: (e) => {
                      setChecked(e.target.checked);
                    },
                    checked: checked,
                  },
                ].map((items, index) => (
                  <>
                    <Grid container key={index} style={{ padding: "3px 0px" }}>
                      <Grid item xs={4} className={classes.formLabel}>
                        <label>{items.label}</label>
                      </Grid>
                      <Grid item xs={8}>
                        {getInput(items)}
                      </Grid>
                    </Grid>
                    {items.divider ? (
                      <Divider
                        component="div"
                        role="presentation"
                        className={classNames(
                          classes.dividerCss1,
                          classes.dividerCss,
                          items.addedClasses
                        )}
                      />
                    ) : null}
                  </>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Divider
            component="div"
            role="presentation"
            className={classNames(classes.dividerCss3, classes.dividerCss)}
          />
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            className={classes.actionBtn1}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleCancel}
              className={classNames(classes1.newCompleteBtn, "secondary-color")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveBtn}
              className={classNames(classes1.newCompleteBtn, "primary-color")}
            >
              Save
            </Button>
          </Grid>
          <Divider
            component="div"
            role="presentation"
            className={classNames(classes.dividerCss3, classes.dividerCss)}
          />
        </Grid>
      )}


      <SnackbarModal
        open={openresetwarning}
        setOpen={setopenresetwarning}
        type="warning"
        title=""
        content={t('warnings.myprofile.resetpassword')}
        vertical="center"
        horizontal="center"
        width={360}
        height={140}
        transition="fade"
        options={{
          isClose: false,
          delay: 0,
          validateULE: true,
        }}
        actions={[
          {
            label: 'Ok',
            handle: handleChangePasswordRequest,
          },
          {
            label: 'Cancel',
          },
        ]}
      />
    </>
  );
};

export default ProfileInfo;
