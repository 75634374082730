import React from "react";
import { TOGGLE_SIDEBAR, TOGGLE_CHAT, TOAST_OPEN, SET_LOADING, UNSET_LOADING, SET_DARK, 
  SET_LIGHT } from "../store/actions/constants";

var LayoutStateContext = React.createContext();
var LayoutDispatchContext = React.createContext();

function layoutReducer(state, action) {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return { ...state, isSidebarOpened: !state.isSidebarOpened };
    case TOGGLE_CHAT:
      return { ...state, isChatOpened: !state.isChatOpened };
    case TOAST_OPEN:
      return { ...state, toast: action.payload };
    case SET_LOADING:
      return { ...state, isLoading: true };
    case UNSET_LOADING:
      return { ...state, isLoading: false };
    case SET_DARK:
      return { ...state, darkMode: true };
    case SET_LIGHT:
      return { ...state, darkMode: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function LayoutProvider({ children }) {
  var [state, dispatch] = React.useReducer(layoutReducer, {
    isSidebarOpened: true,
    isChatOpened: false,
    toast: {},
    isLoading: false,
    darkMode: false,        // Dark Mode
  });
  return (
    <LayoutStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>
        {children}
      </LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  );
}

function useLayoutState() {
  var context = React.useContext(LayoutStateContext);
  if (context === undefined) {
    throw new Error("useLayoutState must be used within a LayoutProvider");
  }
  return context;
}

function useLayoutDispatch() {
  var context = React.useContext(LayoutDispatchContext);
  if (context === undefined) {
    throw new Error("useLayoutDispatch must be used within a LayoutProvider");
  }
  return context;
}

function toggleSidebar(dispatch) {
  dispatch({
    type: TOGGLE_SIDEBAR,
  });
}

function toggleChat(dispatch) {
  dispatch({
    type: TOGGLE_CHAT,
  });
}

function toastOpen(dispatch, msg) {
  dispatch({
    type: TOAST_OPEN,
    payload: msg,
  });
}

function setLoading(dispatch) {
  dispatch({
    type: SET_LOADING,
  });
}

function unsetLoading(dispatch) {
  dispatch({
    type: UNSET_LOADING,
  });
}

function setDarkMode(dispatch) {
  dispatch({
    type: SET_DARK,
  });
}

function setLightMode(dispatch) {
  dispatch({
    type: SET_LIGHT,
  });
}

export { LayoutProvider, useLayoutState, useLayoutDispatch, toggleSidebar, toggleChat, toastOpen, 
  setLoading, unsetLoading, setDarkMode, setLightMode };
