import { ContactModel, validateContact, getContact } from './Contact';
import { AddressModel, validateAddress, getAddress } from './Address';
import { validateArray, getArray } from './handles';

export const VendorACHDetail = {
  accountnumber: '',
  routingnumber: '',
  bankname: '',
  typeaccount: '',
  networkid: '',
  providerid: '',
  emailid: '',
};

export const validateVendorACH = (ach) => {
  return (ach.accountnumber !== ""
  || ach.routingnumber !== ""
  || ach.bankname !== ""
  || ach.typeaccount !== "");
};

export const getVendorACH = (ach) => {
  let newACH = {};
  if (typeof ach.accountnumber === "string" && ach.accountnumber !== "") newACH.accountnumber = ach.accountnumber;
  if (typeof ach.routingnumber === "string" && ach.routingnumber !== "") newACH.routingnumber = ach.routingnumber;
  if (typeof ach.bankname === "string" && ach.bankname !== "") newACH.bankname = ach.bankname;
  if (typeof ach.typeaccount === "string" && ach.typeaccount !== "") newACH.typeaccount = ach.typeaccount;
  if (typeof ach.networkid === "string" && ach.networkid !== "") newACH.networkid = ach.networkid;
  if (typeof ach.providerid === "string" && ach.providerid !== "") newACH.providerid = ach.providerid;
  if (typeof ach.emailid === "string" && ach.emailid !== "") newACH.emailid = ach.emailid;
  return newACH;
};

export const VendorDetailModel = {
  vendorname: '',
  vendordivision: '',
  vendoraddress: {...AddressModel},
  vendorcontact: [
    {...ContactModel},
  ],
  vendorcontactaddress: {...AddressModel},
  taxid: '',
  remittocontact: [
    {...ContactModel},
  ],
  remittoaddress: {...AddressModel},
  customerservicecontact: [
    {...ContactModel},
  ],
  customerservicedaddress: {...AddressModel},
  ach_info: {...VendorACHDetail},
};

export const validateVendorDetail = (vendor) => {
  return (vendor.vendorname !== ""
    || vendor.vendordivision !== ""
    || vendor.taxid !== ""
    || (validateAddress(vendor.vendoraddress)
    && validateArray(vendor.vendorcontact, validateContact)
    && validateAddress(vendor.vendorcontactaddress)
    && validateArray(vendor.remittocontact, validateContact)
    && validateAddress(vendor.remittoaddress)
    && validateArray(vendor.customerservicecontact, validateContact)
    && validateAddress(vendor.customerservicedaddress))
    // && validateACH(vendor.ach_info)
  );
};

export const getVendorDetail = (vendor) => {
  let newVendor = {};
  newVendor.vendorname = vendor.vendorname || "";
  newVendor.vendordivision = vendor.vendordivision || "";
  newVendor.vendoraddress = validateAddress(vendor.vendoraddress) ? getAddress(vendor.vendoraddress) : {...AddressModel};
  newVendor.vendorcontact = getArray(vendor.vendorcontact, validateContact, getContact);
  newVendor.vendorcontactaddress = validateAddress(vendor.vendorcontactaddress) ? getAddress(vendor.vendorcontactaddress) : {...AddressModel};
  newVendor.taxid = vendor.taxid || "";
  newVendor.remittocontact = getArray(vendor.remittocontact, validateContact, getContact);
  newVendor.remittoaddress = validateAddress(vendor.remittoaddress) ? getAddress(vendor.remittoaddress) : {...AddressModel};
  newVendor.customerservicecontact = getArray(vendor.customerservicecontact, validateContact, getContact);
  newVendor.customerservicedaddress = validateAddress(vendor.customerservicedaddress) ? getAddress(vendor.customerservicedaddress) : {...AddressModel};
  if (validateVendorACH(vendor.ach_info)) newVendor.ach_info = getVendorACH(vendor.ach_info);
  return newVendor;
};