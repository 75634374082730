import { PO_SET, PO_SET_ALL, PO_SET_DATA } from "../actions/constants";

const default_state = {
  purchaseOrderData: {},
  purchaseOrderErrors: {},
  comments: [],
  comment: {},
  vendor: null
};

export default function poReducer (state=default_state, action) {
  const { type, payload } = action;
  if (type === PO_SET) { 
    if (payload && typeof payload === 'object') return { ...state, ...payload };
  }
  else if (type === PO_SET_ALL) { 
    if (payload && typeof payload === 'object') {
      let newpayload = { ...payload };
      return { ...default_state, ...newpayload };
    }
  }
  else if (type === PO_SET_DATA) { 
    if (payload && typeof payload === 'object') {
      for (let key in payload) {
        if (payload[key] && state.purchaseOrderErrors[key]) delete state.purchaseOrderErrors[key];
      }
      return { ...state, purchaseOrderData: { ...state.purchaseOrderData, ...payload }};
    }
  }
  return state;
}