import { useParty, useLedger, useStreamQueries } from "@daml/react";
import classNames from "classnames";
import { Button, Grid, Typography } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import React, { useState } from "react";
import SnackbarModal from "../../components/Modals/SnackbarModal";
import { useDamlState } from "../../context/DamlContext";
import { DiscoverPayment } from "../../services/daml-modules1";
import useStyles1 from "../surgicalevent/styles";
import CreatePayment from "./createPayment";
import EditPayment from "./editPayment";
import { CancelPayment, manualPayment, ModifyPaymentDate } from "./handle";
import InvoicePopUp from "./invoice-view-popup";
import useStyles from "./styles";
import PaymentsListContracts from './paymentlistcomponent';
import { useLayoutState } from "../../context/LayoutContext";
import { useTranslation } from 'react-i18next';

export default function SentPaymentsList() {
  const [contractId, setContractId] = useState();
  const [openCreatePayment, setOpenCreatePayment] = useState(false);

  const classes = useStyles();
  const classes1 = useStyles1();
  const ledger = useLedger();
  const { t } = useTranslation();

  const { roleCid, userlistPayload } = useDamlState();
  const [sendModalContract, setSendModalContract] = useState({});
  const [invoiceDetailViewer, setInvoiceDetailViewer] = useState(false);
  const [invoiceDetail, setInvoiceDetail] = useState({});
  const [sendModalOpen, setSendModalOpen] = useState(false);
  const [openEditPayment, setOpenEditPayment] = useState(false);
  const [paymentType, setPaymentType] = useState('');
  const [openToast, setOpenToast] = useState(false);
  const [openToastMsg, setOpenToastMsg] = useState('');
  const [readOnlyData, setReadOnlyData] = useState();
  const [readOnlyFlow, setReadOnlyFlow] = useState(false);
  const [openToastNotification, setOpenToastNotification] = useState(false);
  const [toastContent, setToastContent] = useState("");
  const [msgType, setmsgType] = useState("success")
  const hospital = useParty();
  const layoutState = useLayoutState();
  let { loading, contracts: paymentContracts } = useStreamQueries(DiscoverPayment, () => [{ hospital }]);
  paymentContracts = paymentContracts
    .map(c => {
      c.payload.purchaseProductCount = c.payload.purchaseorderdetaildata.reduce((s, invd) => (s + parseInt(invd.podquantity)), 0);
      return c;
    });

    console.log(paymentContracts);

  /**
   * If it's based on DAML Financial.Payments, this should be true.
   * If it's based on Node Server API, this should be false.
   */
  const isDAMLnotAPI = true;
  const openCancelPayment = (c) => {
    setSendModalContract(c);
    setSendModalOpen(true);
  };

  const openEditPayments = (c) => {
    setInvoiceDetail(c.payload);
    setPaymentType(c.payload.payemnttype)
    setOpenEditPayment(true);
    setContractId(c.contractId);
  };

  const openCreatePayments = () => {
    setReadOnlyFlow(false);
    setOpenCreatePayment(true);
  };

  const handleRowClicked = (c) => {
    setReadOnlyFlow(true);
    setOpenCreatePayment(true);
    setReadOnlyData(c);
    setContractId(c.contractId);
  };

  const handleExtraRowClicks = (val) => {
    debugger
    setOpenCreatePayment(false)
  };

  const handleCancelPayment = async () => {
    await CancelPayment({
      ledger, roleCid,
      vendor: sendModalContract.payload.vendor,
      invinvoicenumber: sendModalContract.payload.invoicedata.invinvoicenumber,
      dpcid: sendModalContract.contractId,
    });
    setSendModalOpen(false);
    setOpenToastNotification(true)
    setmsgType("success");
    setToastContent("Payment cancelled successfully");
  };

  return (
    <>

      <Grid>
        <Grid container className={classes1.newContainer} justifyContent="space-between" >
          <Grid item xs>
            <Typography variant="h4" className={layoutState.darkMode?classes1.newPageTitleDM:classes1.newPageTitle}>Payments</Typography>
          </Grid>
          <Grid item>
            <Button
              className={layoutState.darkMode?classes1.newCreateButtonDM:classes1.newCreateButton}
              variant="contained"
              onClick={openCreatePayments}
              startIcon={<AddIcon />}
            >
              Create Payment
            </Button>
          </Grid>
        </Grid>
        <PaymentsListContracts
          allContracts={isDAMLnotAPI ? paymentContracts : []}
          loading={loading}
          contractactions={[[
            "Actions",
            [
              [["Edit"],
              (c) => openEditPayments(c)],
              [["Cancel"],
                openCancelPayment],
            ],
            {
              headerAlign: 'center',
              checkVisible: (c) => c.payload.status === "Ready for payment"
            }
          ]
          ]}
          handleRowClicked={(c) => handleRowClicked(c)}
          handleExtraRowClicks={handleExtraRowClicks}
        />
      </Grid>
      <InvoicePopUp
        state={invoiceDetailViewer}
        setInvoiceDetailViewer={setInvoiceDetailViewer}
        toggleDrawer={(open) => setInvoiceDetailViewer(open)}
        invoice={invoiceDetail}
      />

      <SnackbarModal
        open={sendModalOpen}
        setOpen={setSendModalOpen}
        type="warning"
        title=""
        content={t('warnings.payment.cancelpayment')}
        vertical="center"
        horizontal="center"
        width={345}
        height={130}
        transition="fade"
        className={classNames(layoutState.darkMode?classes.cancelNotificationDM:classes.cancelNotification, classes1.toastLarge,)}
        options={{
          delay: 500,
          validateULE: true,
        }}
        actions={[
          {
            label: 'Yes',
            handle: () => {
              handleCancelPayment();
            },
          },
          {
            label: 'No',
            handle: () => {
              setSendModalOpen(false);
            },
          }
        ]}
      />

      <EditPayment
        openModal={openEditPayment}
        setOpenModal={setOpenEditPayment}
        paymentDetail={invoiceDetail}
        type={paymentType}
        options={{
          setmsgType:setmsgType,
          setOpenToastNotification:setOpenToastNotification,
          setToastContent:setToastContent,
          paymentContracts:paymentContracts,
          layoutState:layoutState
        }}
        handleUpdate={async (update,invoiceId, note = "",deletedFiles,uploadedFiles,remainingFiles) => {
          setOpenToastNotification(true)
          setmsgType("success");
          setToastContent("Changes saved successfully");
          setOpenEditPayment(false);
          await ModifyPaymentDate({
            ledger,
            vendor: update?.vendor,
            roleCid,
            userlistPayload,
            dpcid: contractId,
            newPaymentinitiationdate: update?.paymentinitiationdate,
            newPaymentdate: update?.paymentdate,
            newPaymentamount: update?.paymentamount,
            newPaymentType: update?.paymenttype,
            invoicenumber: invoiceId,
            note: note,
            deletedFiles,
            uploadedFiles,
            remainingFiles
          })
        }}
      />
      <CreatePayment
        openModal={openCreatePayment}
        setOpenModal={setOpenCreatePayment}
        options={{
          readOnly: readOnlyFlow,
          className: classes.padDown,
          setmsgType:setmsgType,
          setOpenToastNotification:setOpenToastNotification,
          setToastContent:setToastContent,
          paymentContracts:paymentContracts,
          layoutState:layoutState
        }}
        handleUpdate={async (update) => {
          setOpenCreatePayment(false);
          setOpenToastNotification(true)
          setmsgType("success");
          setToastContent("Payment created successfully");
          await manualPayment({
            ledger,
            roleCid,
            userlistPayload,
            ...update
          });
        }}
        handleModifyNote={async (update, note = "") => {
          setOpenCreatePayment(false);
          setOpenToastNotification(true)
          setmsgType("success");
          setToastContent("Note saved successfully");
          await ModifyPaymentDate({
            ledger,
            vendor: update?.vendor,
            roleCid,
            userlistPayload,
            dpcid: contractId,
            newPaymentinitiationdate: update?.paymentinitiationdate,
            newPaymentdate: update?.paymentdate,
            newPaymentamount: update?.paymentamount,
            newPaymentType: update?.paymenttype,
            invoicenumber: update?.invoicedata?.invinvoicenumber,
            note: note,
            deletedFiles:[],
            uploadedFiles:[],
            remainingFiles:[...update.fileName],
          })
        }}
        readOnlyData={readOnlyData}
        setReadOnlyFlow={setReadOnlyFlow}
      />

      <SnackbarModal
        open={openToast}
        setOpen={setOpenToastMsg}
        type="warning"
        title=""
        content={openToastMsg}
        vertical="center"
        horizontal="center"
        width={500}
        height={117}
        transition="fade"
        className={classes.toastSmall}
        actions={[
          {
            label: 'OK',
            handle: () => {
              setOpenToast(false);
            },
          },

        ]}
      />
      <SnackbarModal
        type={msgType}
        open={openToastNotification}
        setOpen={setOpenToastNotification}
        title=""
        content={toastContent}
        width={350}
        height={88}
        className={classes1.toastMedium}
      />
    </>
  )
}