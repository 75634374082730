import React, { useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import { useLedger } from "@daml/react";
import { Button, Grid, Typography, Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import { createInventoryData } from "../../models/InventoryData";
import { Ownerships } from "../../models/Ownership";
import { useDamlState, useDamlLocation } from '../../context/DamlContext';
import { useLayoutDispatch, setLoading, unsetLoading } from "../../context/LayoutContext";
import ModalDialog from '../../components/Modals/NewGeneralModal';
import { DateInput, CustomTextField, CustomTextArea, CustomSelect } from "../../components/Inputs";
import useProduct, { useItemDetails } from "../../hooks/useProduct";
import { useAccountInventory } from "../../hooks/useInventory";
import EditProduct  from "../surgicalevent/edit-product1";
import { updateInventoryData } from "./handle";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "./styles";
import { useTranslation } from 'react-i18next';


const InvTitle = () => {
  
  const classes = useStyles();
  const classes1 = useStyles1();

  const { accountInventoryData } = useAccountInventory();
  // const { common } = useItemDetails();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classNames(classes.newSETitlePanel, classes1.newTitlePanel)}
    >
      <Typography variant="inherit" component="span" className="label" >
        Reference Number: 
      </Typography>
      <Typography variant="inherit" component="span" className="value" >
        {accountInventoryData.ireferencenumber}
      </Typography>
      {/* <Typography variant="inherit" component="span" className="label" >
        Lot Code: 
      </Typography>
      <Typography variant="inherit" component="span" className="value" >
        {common.iproduct?.seplotcode}
      </Typography> */}
    </Grid>
  );
};

const designPattern = (data, isQOH=false, t) => [
  {
    label: 'Product Details',
  },
  {
    key: 'sepudi', width: 143, style: { width: '149px' }, disabled: true,
  },
  {
    key: 'sepproductmanufacturer', width: 143, disabled: !('sepproductmanufacturer' in data),
    key1: 'sepdistributor', width1: 143, disabled1: !('sepdistributor' in data), colSpan1: 2, style1: { width: '185px' }, label1: 'Distributor', 
  },
  {
    key: 'seplotcode', width: 143, disabled: !('seplotcode' in data),
    key1: 'sepproductexpiration', width1: 143, disabled1: !('sepproductexpiration' in data), colSpan1: 2,
  },
  {
    key: 'sepproductdescription', width: 293, disabled: !('sepproductdescription' in data), colSpan: 3, label: 'Description', 
  },
  {
    key: 'sepproducttype', width: 143, disabled: !('sepproducttype' in data),
    key1: 'sepproductside', width1: 88, disabled1: !('sepproductside' in data),
  },
  {
    key: 'sepproductprice', width: 143, disabled: !('sepproductprice' in data),
    key1: 'sepuom', width1: 88, disabled1: !('sepuom' in data),
    ...(isQOH ? {key2: 'iquantity', label2: `${t('main.quantityonhand.lable')}`} : {}), 
    width2: 88, disabled2: !('iquantity' in data), type2: 'number', 
  },
];

const InvPanel = ({
  data, setData,
}) => {
  
  const classes = useStyles();
  const classes1 = useStyles1();
  const { t } = useTranslation();
  const { product: productItems } = useProduct();
  const { locations } = useDamlLocation();

  const isQOH = (productItems?.sepuom === 'Case' || productItems?.sepuom === 'Box');

  // console.log("[EditInventory]", isQOH, productItems);

  const handleChange = (value, key) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  return (
    <Grid container className={classes1.newEditPanel}>
      <Grid item style={{width: '100%'}}>
        <Table className={classes.newSEForm} style={{width: '253px'}}>
          <TableBody>
            <TableRow>
              <TableCell className="new-subtitle" colSpan={2}>Inventory Details</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                <CustomTextField
                  label="PO Number"
                  value={data.ipurchaseordernumber || ""}
                  onChange={(val) => handleChange(val, 'ipurchaseordernumber')}
                  width={115}
                  options={{
                    // labelLeft: true,
                    // labelWidth: 108,
                    disabled: !('ipurchaseordernumber' in data),
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <CustomSelect
                  label="Ownership"
                  value={data.sepownership || ""}
                  onChange={(val) => handleChange(val, 'sepownership')}
                  items={Ownerships}
                  placeholder="Select One"
                  width={115}
                  options={{
                    disabled: !('sepownership' in data),
                  }}
                />
              </TableCell>
              <TableCell>
                <CustomSelect
                  label="Location"
                  value={data.seplocation || ""}
                  onChange={(val) => handleChange(val, 'seplocation')}
                  items={locations}
                  placeholder="Select One"
                  width={115}
                  options={{
                    disabled: !('seplocation' in data),
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <DateInput
                  label="Shipping Date"
                  value={data.ishipmentdate || ""}
                  onChange={(val) => handleChange(val, 'ishipmentdate')}
                  width={115}
                  options={{
                    // labelLeft: true,
                    // labelWidth: 108,
                    disabled: !('ishipmentdate' in data),
                  }}
                />
              </TableCell>
              <TableCell>
                <DateInput
                  label="Received Date"
                  value={data.ireceiveddate || ""}
                  onChange={(val) => handleChange(val, 'ireceiveddate')}
                  width={115}
                  options={{
                    // labelLeft: true,
                    // labelWidth: 108,
                    disabled: !('ireceiveddate' in data),
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>

      <Grid item>
        <EditProduct
          options={{
            isEnableAutoScan: false,
            isEnableCompliance: false,
            isComplianceWarning: false,
            isSecondary: false,
            className: classes1.newEditProduct,
          }}
          designPattern={designPattern(data, isQOH , t)}
        />
      </Grid>

      <Grid item>
        <CustomTextArea
          label="Note:"
          placeholder="Write note here..."
          value={data.itemprequestmessage || ""}
          onChange={(val) => handleChange(val, 'itemprequestmessage')}
          minRows={3}
          className={classes1.txtArea}
          options={{
            labelLeft: true,
            labelWidth: 40,
            maxHeight: 120,
            disabled: !('itemprequestmessage' in data),
          }}
        />
      </Grid>
    </Grid>
  );
};

/**
 * Edit Inventory Dialog New
 * @param {Boolean} open
 * @param {Function} setOpen
 */
export default function EditInventory ({
  open, setOpen, 
}) {
    
  const classes = useStyles();
  const ledger = useLedger();
  const { roleCid, party, userParty, userlistPayload } = useDamlState();

  const { accountInventoryData, setAccountInventoryData } = useAccountInventory();
  const { product: productItems, clearProduct } = useProduct();
  const { details, common } = useItemDetails();

  const layoutDispatch = useLayoutDispatch();

  const [data, setData] = useState({});

  const handleCancel = () => {
    setOpen(false);
    unsetLoading(layoutDispatch);
  };

  // handle to edit
  const handleEditClick = useCallback(async () => {
    /**
     * If it needs to check out required fields, then it should create new EVENT.
     * But it's unnecessary to require fields for now.
     */
    console.log("[EditInventory] handleEditClick", data, productItems);

    // spinner
    setLoading(layoutDispatch);

    if (details.length) {
      const defaultUser = userParty ? userParty : party;
      let ContractId = accountInventoryData.contractId, res;

      for (let i in details) {
        const inventorydata = createInventoryData({
          ...details[i],
          ...data, 
          id: details[i].id, // set id of inventory data
          iproduct: {
            ...details[i].iproduct,
            ...productItems, 
            // sepproductmanufacturer: data.sepproductmanufacturer,
            seplocation: data.seplocation ?? details[i].iproduct.seplocation,
            sepownership: data.sepownership ?? details[i].iproduct.sepownership,
          },
          itemprequestuser: defaultUser,
          iquantity: productItems.iquantity ?? details[i].iquantity,
        });
        console.log("[EditInventory] handleEditClick", details[i], inventorydata);

        res = await updateInventoryData({
          ledger, 
          ContractId: ContractId, 
          updateinventorydata: inventorydata,
          inventoryindex: details[i].index,
          newitemprequestuser: defaultUser,
          newitemprequestmessage: data.itemprequestmessage,
          newitempstatus: 'Edit',
          roleCid,
          userlistPayload,
        });

        if (res) {
          ContractId = res.contractId;
        }
      }

      setAccountInventoryData({
        ...accountInventoryData,
        ...res?.inventoryproduct,
        contractId: ContractId,
        inventorydata: res?.inventorydata,
      });

    }

    unsetLoading(layoutDispatch);

    setOpen(false);

  }, [data, userParty, party, productItems, layoutDispatch, setOpen, details, ledger, roleCid, userlistPayload, 
    accountInventoryData, setAccountInventoryData]);

  useEffect(() => {
    if (open) {
      console.log("[EditInventory] Open EditInventory dialog.", common);
      if (details.length) {
        clearProduct({ ...common.iproduct, iquantity: common.iquantity });
        setData({ ...common.iproduct, ...common, 'potype': '' });
      }
    }
    // eslint-disable-next-line
  }, [open]);
 

  return (
    <>
      <ModalDialog
        open={open}
        setOpen={setOpen}
        title={(
          <InvTitle />
        )}
        content={(
          <InvPanel 
            data={data}
            setData={setData}
          />
        )}
        actions={(
          <>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleCancel} 
              className={classNames(classes.newCompleteBtn, "secondary-color")} 
            >
              Cancel
            </Button>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleEditClick} 
              className={classNames(classes.newCompleteBtn)} 
            >
              Notify Vendor
            </Button>
          </>
        )}
        width={717}
        height={587}
      /> 
    </>
  );
}