import React, { useState } from 'react';
import { useParty } from "@daml/react";
import classNames from "classnames";
import { Chip } from '@material-ui/core';
import { /* Inventory, */ SurgicalEvent, PurchaseOrder } from "../../services/daml-modules1";
import Contracts from '../../components/Contracts/Contracts';
import AdaptiveSearch from '../../components/Search/adaptivesearch';
import DateSearch from '../../components/Search/datesearch';
import { compareProduct, getProduct } from "../../models/Product";
import { ReturnStatus } from "../../models/InventoryData";
import { requiredFields } from "../../models/handles";
import rotateIcon from "../../icons/rotate-ccw.svg";
import rotateDisableIcon from "../../icons/rotate-ccw-dis.svg";
import editIcon from "../../icons/edit.svg";
import editDisableIcon from "../../icons/edit-disable.svg";
import deleteIcon from "../../icons/delete.svg";
import deleteDisableIcon from "../../icons/delete-disable.svg";
import NewRequest from './newrequest';
import { createReturnRequest, updateInventoryData, removeInventoryData } from "./handle";
import { getInventoryDataStatus } from "./inventorylistcontractshospital";
import DetailViewDrawer from "./detail-view-drawer-account";
import EditInventory from "./edit-inventory";
import { useDamlState } from "../../context/DamlContext";
import ModalDialogBox from "../../components/Modals/GeneralModal/modalDialogBox";
import StreamQueries from "../../components/Daml/StreamQueries";
import DetailViewDrawerSE from "../surgicalevent/detail-view-drawer";
import DetailViewDrawerPO from "../purchaseorder/detail-view-drawer";
import useStyles from "../surgicalevent/styles";


/**
 * Account Inventory in House Contracts in Hospital
 * @param {Object} searchInv 
 * @param {Object} searchParams 
 * @param {Function} handleChangeSearchValue 
 * @param {Object} ledger 
 * @param {Array} allContracts 
 * @returns 
 */
export default function AccountInventoryActiveContractsHospital({
  searchInv = {},
  searchParams = {},
  handleChangeSearchValue = () => { },
  ledger,
  allContracts = [],
}) {

  const classes = useStyles();
  const hospital = useParty();
  const [requestError, setRequestError] = useState({newireturnmessage:'',newireturntype:''});
  const [request, setRequest] = useState({newireturnmessage:'',newireturntype:''});
  const [openWarning, setOpenWarning] = useState(false);

  // Detail Viewer
  const [state, setState] = useState(false);
  const [selectedInventory, setSelectedInventory] = useState({});

  // Edit Modal
  const [editOpen, setEditOpen] = useState(false);
  const [editInventory, setEditInventory] = useState({});

  // Delete Modal
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteInventory, setDeleteInventory] = useState({});

  // load SE
  const [se, setSE] = useState([]);
  const [stateSE, setStateSE] = useState(false);
  const [selectedEventData, setSelectedEventData] = useState({});
  const [productItemsList, setProductItemsList] = useState([]);

  // load PO
  const [po, setPO] = useState([]);
  const [statePO, setStatePO] = useState(false);
  const [poData, setPOData] = useState({});
  const [productList, setProductList] = useState([]);
  const [commentList, setCommentList] = useState([]);
  

  let contracts = [], searchSE = [], searchPO = [];

  // const { contracts: contractsInv } = useStreamQueries(Inventory, () => [searchInv]);
  let contractsInv = allContracts;
  const { roleCid, party, userParty, userlistPayload } = useDamlState();


  // click "Return Request" in list
  const handleReturnRequest = (inventorydata) => {
    // console.log("[AccountInventoryActiveContractsHospital] handleReturnRequest", inventorydata);
    setRequest({
      ContractId: inventorydata.ContractId,
      inventorydata,
      newireturnuser: hospital,
    });
    setOpenWarning(true);
  };

  // click "Edit" in list
  const handleEdit = (inventorydata) => {
    // Open modal to edit InventoryData
    // Set default itempponumber as same as ipurchaseordernumber
    setEditInventory({
      ...inventorydata,
      itempponumber: ((!!inventorydata.ipurchaseordernumber && !inventorydata.itempponumber) 
      ? inventorydata.ipurchaseordernumber : inventorydata.itempponumber),
    });
    setEditOpen(true);
  };

  // click "Delete" in list
  const handleDelete = (inventorydata) => {
    // Open modal to delete InventoryData
    setDeleteInventory(inventorydata);
    setDeleteOpen(true);
  };

  // enable to return request
  const enableReturnRequest = (inventorydata) => {
    // console.log("[AccountInventoryActiveContractsHospital] enableReturnRequest", inventorydata);
    return ((!inventorydata.ireturnstatus && inventorydata.iproductstatus === 'Received')
      || (!!inventorydata.ireturnstatus && inventorydata.ireturnrequestdecision === false
        && inventorydata.iproductstatus === 'Received'));
  };

  // enable to edit
  const enableEdit = (inventorydata) => {
    // console.log("[AccountInventoryActiveContractsHospital] enableEdit", inventorydata);
    return (inventorydata.iproductstatus === 'Received' 
      && !(inventorydata.itempstatus && typeof inventorydata.itempresponsedecision !== 'boolean'));
  };

  // click Yes in NewRequest modal
  const handleModalYes = async () => {
    console.log("[AccountInventoryActiveContractsHospital] handleModalYes", request);
    let newErrors = requiredRequests(request);
    console.log('newErrors',newErrors);
    if ((Object.keys(newErrors).length > 0)) {
      setRequestError({ ...newErrors });
      return false;
    }
    else {
      setRequestError({newireturnmessage:'',newireturntype:''})
    }

    // use inventorydata instead of purchaseordernumber
    await createReturnRequest({
      ...request,
      ledger, 
      ContractId: request.ContractId,
      roleCid,
      userlistPayload,
    });

    setOpenWarning(false);
  };

  // click Send in EditInventory modal
  const handleEditInventory = async (newinventorydata) => {
    console.log("[AccountInventoryActiveContractsHospital] handleEditInventory", editInventory, newinventorydata);
    // compare Product
    const cmpProduct = compareProduct(getProduct({...editInventory?.iproduct}), 
      getProduct({...newinventorydata?.iproduct}), true);
    // compare PO number (old is empty, new is not empty)
    // const cmpPO = !(!editInventory.ipurchaseordernumber && !!newinventorydata.ipurchaseordernumber); // Old case
    const cmpPO = ((!editInventory.itempponumber && !newinventorydata.itempponumber)
      || editInventory.itempponumber === newinventorydata.itempponumber
      || editInventory.ipurchaseordernumber === newinventorydata.itempponumber); // New case
    console.log("[AccountInventoryActiveContractsHospital] handleEditInventory", cmpProduct, cmpPO);
    if (cmpProduct && cmpPO) return ;
    
    await updateInventoryData({
      ledger, 
      ContractId: editInventory.ContractId, 
      updateinventorydata: newinventorydata,
      inventoryindex: editInventory.index,
      newitemprequestuser: newinventorydata.itemprequestuser,
      newitemprequestmessage: newinventorydata.itemprequestmessage,
      newitempstatus: 'Edit',
      roleCid,
      userlistPayload,
    });
    
    setEditOpen(false);
  };

  // click Send in DeleteInventory modal
  const handleDeleteInventory = async () => {
    console.log("[AccountInventoryActiveContractsHospital] handleDeleteInventory", deleteInventory);

    await removeInventoryData({
      ledger, 
      ContractId: deleteInventory.ContractId, 
      inventorydata: deleteInventory,
      inventoryindex: deleteInventory.index,
      newitemprequestuser: userParty ? userParty : party,
      newitemprequestmessage: "",
      roleCid,
      userlistPayload
    });
    
    setDeleteOpen(false);
  };

  const toggleDrawer = (open) => {
    setState(open);
  };

  if (contractsInv) {
    contractsInv.forEach(c => {
      for (let i in c.payload.inventorydata) {
        let invData = c.payload.inventorydata[i];

        if (invData.iproductstatus === "Opens") continue;

        // search
        if ('ishipmentdate' in searchParams && !!searchParams.ishipmentdate) {
          if (invData.ishipmentdate !== searchParams.ishipmentdate) continue;
        }

        contracts.push({
          ...invData,
          index: i,
          ContractId: c.contractId,
          iproductreturnstatus: getReturnStatus(invData),
          inventoryproduct: c.payload.inventoryproduct,
        });

        // search PO
        if (!!invData.iseid) {
          searchSE.push({
            hospital: c.payload.hospital,
            surgicaleventdata: {
              seid: invData.iseid,
            }
          });
        }

        // search PO
        if (!!invData.ipurchaseordernumber) {
          searchPO.push({
            hospital: c.payload.hospital,
            vendor: c.payload.vendor,
            purchaseorderdata: {
              popurchaseorderid: invData.ipurchaseordernumber,
            }
          });
        }
      }
    });
  }

  // console.log("[AccountInventoryActiveContractsHospital]", contracts, searchPO);


  return (
    <>
      <Contracts
        className={classes.customRoot}
        contracts={contracts}
        columns={[
          ["Lot Code", "iproduct.seplotcode", {
            options: {
              minWidth: 110,
              flex: 1,
              sortable: false,
            },
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.seplotcode}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'seplotcode')}
                  defaultValue={searchParams.seplotcode}
                  label="Search Lot Code"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchParams.seplotcode,
            handleClear: () => handleChangeSearchValue('', 'seplotcode'),
            type: "link",
            onClick: (contractId, data) => {
              // detail page
              console.log("[AccountInventoryActiveContractsHospital]", contractId, data);
              if (!!data && 'contract' in data) {
                setSelectedInventory(data.contract);
                toggleDrawer(true);
              }
            },
          }],
          ["Manufacturer", "iproduct.sepproductmanufacturer", {
            options: {
              minWidth: 120,
              sortable: false,
            }
          }],
          ["Distributor", "iproduct.sepdistributor", {
            options: {
              minWidth: 100,
              sortable: false,
            }
          }],
          ["Price", "iproduct.sepproductprice", {
            options: {
              minWidth: 70,
              flex: 1,
              sortable: false,
              beforeText: '$'
            }
          }],
          ["Expiration", "iproduct.sepproductexpiration", {
            search: {
              component:
                <DateSearch
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'sepproductexpiration')}
                  defaultValue={searchParams.sepproductexpiration}
                  label="Expiration Date :"
                  isStatic={true}
                />
            },
            isSearched: searchParams.sepproductexpiration,
            handleClear: () => handleChangeSearchValue('', 'sepproductexpiration'),
            options: {
              minWidth: 130,
              sortable: false,
              cellClassName: classes.centerCell,
            },
          }],
          ["Status", "iproductstatus", {
            type: "render",
            render: (param) => {
              // console.log("[AccountInventoryActiveContractsHospital] iproductstatus", param.row);
              const status = getInventoryDataStatus([param.row.contract]);
              return (status.length ?
                status.map((s, i) => (<Chip
                  label={s}
                  key={i}
                  size="small"
                  className={classNames(classes.smallChip, (s === 'Received' ? classes.backGreen : (
                    s === 'Recall' ? classes.backPink : (
                      s === 'Expiring' ? classes.backYellow : (
                        s === 'Edited' ? classes.backLightGreen : (
                        (s === 'Back-order' || s === 'Expired') ? classes.backRed : classes.backBlue
                        )
                      )
                    )
                  )))}
                />))
                : <></>);
            },
            options: {
              // cellClassName: classes.centerCell,
              width: 110,
              sortable: false
            },
          }],
          ["Shipment Date", "ishipmentdate", {
            search: {
              component:
                <DateSearch
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'ishipmentdate')}
                  defaultValue={searchParams.ishipmentdate}
                  label="Shipment Date :"
                  isStatic={true}
                />
            },
            isSearched: searchParams.ishipmentdate,
            handleClear: () => handleChangeSearchValue('', 'ishipmentdate'),
            options: {
              width: 160,
              sortable: false,
              cellClassName: classes.centerCell,
            },
          }],
          ["Received Date", "ireceiveddate", {
            search: {
              component:
                <DateSearch
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'ireceiveddate')}
                  defaultValue={searchParams.ireceiveddate}
                  label="Received Date :"
                  isStatic={true}
                />
            },
            isSearched: searchParams.ireceiveddate,
            handleClear: () => handleChangeSearchValue('', 'ireceiveddate'),
            options: {
              width: 160,
              sortable: false,
              cellClassName: classes.centerCell,
            },
          }],
          ["Return Status", "iproductreturnstatus", {
            type: "render",
            render: (param) => (param.value &&
              <Chip
                label={param.value}
                size="small"
                className={classNames(classes.smallChip, (param.value === 'Accepted' ? classes.backGreen : (
                  param.value === 'Declined' ? classes.backRed : classes.backYellow
                )))}
              />),
            options: {
              // cellClassName: classes.centerCell,
              width: 110,
              sortable: false
            },
          }],
          ["SE Id", "iseid", {
            type: "link",
            onClick: (contractId, data) => {
              // detail page of PO
              const seid = data['SE Id'];
              if (se && se.length) {
                const selectedSE = se.find(s => s.payload.surgicaleventdata.seid === seid);
                console.log("[AccountInventoryActiveContractsHospital] SE Id", seid, selectedSE);
                if (selectedSE) {
                  // open detailviewer for selected se
                  setSelectedEventData(selectedSE.payload.surgicaleventdata);
                  setProductItemsList(selectedSE.payload.surgicaleventproductdata);
                  setStateSE(true);
                }
              }
            },
            options: {
              width: 80,
              sortable: false
            },
          }],
          ["PO Number", "ipurchaseordernumber", {
            type: "link",
            onClick: (contractId, data) => {
              // detail page of PO
              const ponumber = data['PO Number'];
              if (po && po.length) {
                const selectedPO = po.find(p => p.payload.purchaseorderdata.popurchaseorderid === ponumber);
                console.log("[AccountInventoryActiveContractsHospital] PO Number", ponumber, selectedPO);
                if (selectedPO) {
                  // open detailviewer for selected po
                  setPOData(selectedPO.payload.purchaseorderdata);
                  setProductList(selectedPO.payload.purchaseorderdetaildata);
                  setCommentList((selectedPO.payload.purchaseordercomment));
                  setStatePO(true);
                }
              }
            },
            options: {
              width: 80,
              sortable: false
            },
          }],
          ["PO Date", "ipurchaseorderdate", {
            options: {
              cellClassName: classes.centerCell,
              width: 80,
              sortable: false
            },
          }],
          /* ["Reference Number", "iproduct.sepreferencenumber",{
            options:{
              width:180
            }
          }],*/
        ]}
        actions={[
          [
            "Actions",
            [
              [["Return Request", [<img alt="" src={rotateIcon} />, <img alt="" src={rotateDisableIcon} />],
                (c) => enableReturnRequest(c)],
                handleReturnRequest],
              [["Edit", [<img alt="" src={editIcon} />, <img alt="" src={editDisableIcon} />],
                (c) => enableEdit(c)],
                handleEdit],
              [["Delete", [<img alt="" src={deleteIcon} />, <img alt="" src={deleteDisableIcon} />],
                (c) => enableEdit(c)],
                handleDelete],
            ],
            {
              width: 120,
              sortable: false,
              flex: 1,
            }
          ]
        ]}
        options={{
          statusEmpty: (allContracts.length === 0),
          sortKey: 'id',
        }}
      />

      <NewRequest
        openWarning={openWarning}
        handleModalYes={handleModalYes}
        setOpenWarning={setOpenWarning}
        request={request}
        setRequest={setRequest}
        requestError={requestError}
      />

      <DetailViewDrawer
        state={state}
        toggleDrawer={toggleDrawer}
        selectedInventory={selectedInventory}
      />

      <DetailViewDrawerSE
        state={stateSE}
        toggleDrawer={setStateSE}
        selectedEventData={selectedEventData}
        productItemsList={productItemsList}
        isPrint={false}
      />

      <DetailViewDrawerPO
        state={statePO}
        toggleDrawer={setStatePO}
        selectedPOData={poData}
        productItemsList={productList}
        commentsList={commentList}
      />

      <EditInventory
        openModal={editOpen}
        setOpenModal={setEditOpen}
        inventoryData={editInventory}
        addProductItem={handleEditInventory}
        hospital={hospital}
        btnEdit="Send"
      />

      <ModalDialogBox
        open={deleteOpen}
        setOpen={setDeleteOpen}
        heading={"Delete Inventory?"} 
        type="warning"
        text={`<p>Would you delete this Inventory?</p>`}
        handleRequest={handleDeleteInventory}
        firstBtn={'Send'}
        secondBtn={'No'}
        handleSecRequest={() => setDeleteOpen(false)}
      />

      {
        (searchSE && searchSE.length) ?
        <StreamQueries
          template={SurgicalEvent}
          searchParams={searchSE}
          setValues={(data) => {
            setSE(data.contracts);
            // console.log("[AccountInventoryActiveContractsHospital] SE", searchSE, data);
          }}
        />
        : null
      }

      {
        (searchPO && searchPO.length) ?
        <StreamQueries
          template={PurchaseOrder}
          searchParams={searchPO}
          setValues={(data) => {
            setPO(data.contracts);
            // console.log("[AccountInventoryActiveContractsHospital] PO", searchPO, data);
          }}
        />
        : null
      }

    </>
  );
}

// get return status by inventorydata
export const getReturnStatus = (inventorydata) => {
  return inventorydata?.ireturncomments &&
    inventorydata?.ireturncomments?.length > 0
    ? ReturnStatus[inventorydata?.ireturncomments?.[inventorydata?.ireturncomments?.length-1]?.status]
    : null ;
};

/**
* require fields for request object
* @param {Object} request 
* @returns {Boolean}
*/
export const requiredRequests = (request) => requiredFields(request, {
 'newireturntype': 'Return type', 
 'newireturnmessage': 'Return message', 
});
