import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useParty, useLedger } from "@daml/react";
import {
  Typography, Button, FormControlLabel, StepLabel, Step, Stepper, TextField, Chip,
  Accordion, AccordionDetails, AccordionSummary, TableContainer, Paper, Checkbox, Badge
} from "@material-ui/core";
import { ExpandMore } from '@material-ui/icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MuiAlert from '@material-ui/lab/Alert';
import { useDamlState } from "../../context/DamlContext";
import { useLayoutDispatch, setLoading, unsetLoading, toastOpen } from "../../context/LayoutContext";
import { getState, dispatch } from "../../store";
import { surgicalEventStatus } from "../../store/actions/generalActions";
import {
  createSurgicalEvent, updateSurgicalEvent, handleAddNewProductData, confirmSurgicalEvent, handleRemoveProduct,
  completeSurgicalEvent, handleUpdateProductData, setSurgicalStatus
} from "./handle";
import {
  SurgicalEventModel, requiredSurgicalEvent, compareSurgicalEvent,
  getSurgicalEvent
} from "../../models/SurgicalEvent";
import { SurgicalEventProductModel, requiredProduct, isEmptyProduct, getTotalPrice, Sides } from "../../models/Product";
import {
  checkComplianceStatus, checkComplianceStatusExpiration,
  checkComplianceStatusSide
} from "../../models/SurgicalEventCompliance";
// import PromptModal from "../../components/Modals/PromptModal";
import AddEditProduct from "./add-edit-product";
import AddEditEvent from "./add-edit-eventform";
import { getCompleteMessage } from "./complete-dialog";
import ModalDialogBox from '../../components/Modals/GeneralModal/modalDialogBox';
import { formatDate, getTodayString } from "../../services/util";
import ProductDetailViewTable from "./product-detail-view-table";
import useStyles from "./styles";



function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/**
 * Create SE page
 */
export function CreateSurgicalEvent() {
  return CreateEditSurgicalEvent({ isCreate: true });
}

/**
 * Edit SE page
 */
export function EditSurgicalEvent() {
  return CreateEditSurgicalEvent({ isCreate: false });
}

/**
 * Create or Edit SurgicalEvent page
 * @param {Boolean} isCreate
 */
function CreateEditSurgicalEvent({
  isCreate
}) {

  const classes = useStyles();

  const { role, party, userParty, roleCid, rolePayload: { admindetails } } = useDamlState();
  console.log("[CreateEditSurgicalEvent] admindetails", admindetails);

  const layoutDispatch = useLayoutDispatch();
  const history = useHistory();

  const hospital = useParty();
  const ledger = useLedger();

  // get current status
  const currentSurgicalEvent = getState().surgical.detail;
  const currentStatus = getState().surgical.current_status;

  // check if it should be redirected to List page
  const checkRedirectToList = () => {
    if (!isCreate) {
      return (!!currentSurgicalEvent && 'contractId' in currentSurgicalEvent && 'payload' in currentSurgicalEvent);
    }
    return true;
  }

  // some old variables
  let oldStep = 0;
  let oldContractId = '';
  let oldSurgicalEvent = {};
  let oldProductItems = {};
  let oldProductList = [];
  let oldDetailList = [];
  if (isCreate && (!!currentStatus && 'new' in currentStatus)) {
    oldStep = currentStatus.new.step;
    oldContractId = currentStatus.new.contractId;
    oldSurgicalEvent = { ...currentStatus.new.data };
    oldProductItems = currentStatus.new.product;
    oldProductList = currentStatus.new.products;
  }
  else if (!isCreate && checkRedirectToList()) {
    oldContractId = currentSurgicalEvent.contractId;
    oldSurgicalEvent = getSurgicalEvent(currentSurgicalEvent.payload.surgicaleventdata);
    if (Array.isArray(currentSurgicalEvent.payload.surgicaleventproductdata)) {
      oldDetailList = currentSurgicalEvent.payload.surgicaleventproductdata;
      oldProductList = oldDetailList.map(detail => detail.product);
    }
    if (!!currentStatus && 'edit' in currentStatus && oldContractId in currentStatus.edit) {
      oldStep = currentStatus.edit[oldContractId].step;
      oldProductItems = currentStatus.edit[oldContractId].product;
    }
  }

  const defaultSurgicalEvent = {
    ...SurgicalEventModel,
    ...oldSurgicalEvent,
  };
  const defaultProductItems = {
    ...SurgicalEventProductModel,
    acknowledge: '',
    acknowledgeProduct: '',
  };
  const defaultUser = (userParty ? userParty : party);

  // ContractId is contractId of SurgicalEvent
  const [ContractId, setContractId] = useState(oldContractId);
  // console.log("[CreateEditSurgicalEvent] ContractId", ContractId);

  const [activeStep, setActiveStep] = useState(oldStep);
  const stepsTop = isCreate ?
    ['Add a Surgical Event', 'Add Products', 'Finalize and Complete'] :
    ['Update Surgical Event', 'Add/Update Products', 'Finalize and Complete'];
  const steps = ['Step', 'Step', 'Step'];
  const [expanded, setExpanded] = useState(0);

  // const [sucessStep1, setSucessStep1] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  // const [loginUser, setLoginUser] = useState(defaultSurgicalEvent.checked ? defaultUser : '');
  const [loginUser, setLoginUser] = useState(defaultUser); // always checked as default user
  const [openModal, setOpenModal] = useState(false);
  const [surgicalEventData, SetSurgicalEventData] = useState(defaultSurgicalEvent);
  const [surgicalError, setSurgicalError] = useState({});
  const [productItemsList, SetProductItemsList] = useState(oldProductList);
  const [productDetailsList, SetDetailItemsList] = useState(oldDetailList);
  const [productItems, setProductItems] = useState({ ...defaultProductItems, ...oldProductItems }); // restore from old product
  const [productError, setProductError] = useState({});
  const [nextClicked, setNextClicked] = useState(false);
  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // click checkbox to accept Terms and Condition
  const acceptTermsandCondtn = (e) => {
    if (e.target.checked === true) {
      setLoginUser(defaultUser);
    } else {
      setLoginUser("");
    }
  };

  // create or update Surgical Event
  const createUpdateSurgicalEvent = async () => {
    if (!!ContractId) {
      if (!compareSurgicalEvent(oldSurgicalEvent, surgicalEventData, true)) {
        console.log("[CreateEditSurgicalEvent] update Surgical Event");
        console.log(surgicalEventData);
        // update
        await updateSurgicalEvent({
          surgicalEvent: surgicalEventData,
          ledger,
          hospital,
          ContractId,
          setContractId,
          roleCid,
        });
      }
    }
    else {
      console.log("[CreateEditSurgicalEvent] create Surgical Event");
      console.log(surgicalEventData);
      // create
      await createSurgicalEvent({
        surgicalEvent: surgicalEventData,
        ledger,
        hospital,
        ContractId,
        setContractId,
        roleCid,
      });
    }
  };

  const handleValueFill = (key, type = "SE") => {
    if (type === "SE") {
      surgicalError[key] = false;
      setSurgicalError(surgicalError);
    } else {
      productError[key] = false;
      setProductError(productError);
    }
  };
  /* const handlePurchaseStep = () => {
    // history.push("/app/purchaseorder-list");
    history.push("/app/surgicalevent");
  }; */

  // click Next(Continue or Complete) button
  const handleNext = async () => {
    let newErrors = requiredSurgicalEvent(surgicalEventData);
    if ((Object.keys(newErrors).length > 0)) {
      setSurgicalError({ ...newErrors });
      return false;
    }

    if (activeStep === 0) {
      // set default sepproductside as surgicalside of SE data
      // productItems.sepproductside = productItems.sepproductside ? productItems.sepproductside : surgicalEventData.surgicalside;
      // set default sepproductside as NotApplicable
      productItems.sepproductside = productItems.sepproductside ? productItems.sepproductside : "NotApplicable";

      // spinner
      setLoading(layoutDispatch);

      // create or update Surgical Event
      await createUpdateSurgicalEvent();

      unsetLoading(layoutDispatch);
    }

    // check if productItems is empty or not
    if (activeStep === 1 && !isEmptyProduct({ ...productItems, sepproductside: "" })) {

      // spinner
      setLoading(layoutDispatch);

      const resAdd = await handleAddMoreProduct();

      unsetLoading(layoutDispatch);

      if (!resAdd) {
        setNextClicked(true);
        return false;
      }
      else if (nextClicked) setNextClicked(false);
    }

    // final step
    if (steps.length - 1 === activeStep) {
      // check if accept Terms and Condition
      if (!!loginUser && loginUser !== '') setOpenWarning(true);
      return false;
    }

    // go to next step
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // select old steps
  const handleBack = (index) => {
    if (activeStep <= 0) {
      return false;
    }
    // console.log(activeStep);
    // console.log(index);
    if (activeStep <= index) {
      return false;
    }
    // go to preiew step
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const handleBackSteps = () => {
    if (activeStep <= 0) {
      return false;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  /**
   * go to SurgicalEvent List page
   * click "Cancel" button
   */
  const handleCancelExist = async (event) => {
    await storeStatus(true);
    history.push("/app/surgicalevent");
  }


  // store current status
  const storeStatus = async (isClear = false) => {
    // let newStatus = {};
    let editStatus = (currentStatus ? currentStatus.edit : {});

    if (isClear) {
      if (!!ContractId && ContractId in editStatus) delete editStatus[ContractId];
    }
    else {
      // newStatus = {
      //   step: activeStep,
      //   contractId: ContractId,
      //   data: surgicalEventData,
      //   product: productItems,
      //   products: productItemsList,
      // };
      if (!!ContractId) editStatus = {
        ...editStatus,
        [ContractId]: {
          step: activeStep,
          contractId: ContractId,
          data: surgicalEventData,
          product: productItems,
          products: productItemsList,
        }
      };
    }

    // if (isCreate) {
    //   dispatch(surgicalEventStatus({
    //     ...currentStatus,
    //     // new: newStatus,
    //     edit: editStatus,
    //   }));
    // }
    // else {
    dispatch(surgicalEventStatus({
      ...currentStatus,
      edit: editStatus,
    }));
    // }
  };

  // click "Save & Exit" button
  const handleSaveExist = async (event) => {

    if (surgicalEventData.surgicalside === "") surgicalEventData.surgicalside = "NotApplicable";
    if (surgicalEventData.eventdate === "") surgicalEventData.eventdate = getTodayString();

    // disable to check requirement SurgicalEvent
    /* let newErrors = requiredSurgicalEvent(surgicalEventData);
    if ((Object.keys(newErrors).length > 0)) {
      setSurgicalError({ ...newErrors });
      return false;
    } */
    console.log('[handleSaveExist]', surgicalEventData);

    if (activeStep === 0) {
      // spinner
      setLoading(layoutDispatch);

      // create or update Surgical Event
      await createUpdateSurgicalEvent();

      unsetLoading(layoutDispatch);
    }
    else if (activeStep === 2 || activeStep === 1) {
      // setSurgicalStatus by loginUser
      const newsestatus = "Scheduled";
      const newsechecked = (loginUser === '') ? false : true;

      if (newsechecked !== surgicalEventData.checked) {
        surgicalEventData.checked = newsechecked;

        // spinner
        setLoading(layoutDispatch);

        await setSurgicalStatus({
          contractId: ContractId,
          ledger,
          newstatus: newsestatus,
          newchecked: newsechecked,
          roleCid,
        });

        unsetLoading(layoutDispatch);
      }

    }

    await storeStatus();

    history.push("/app/surgicalevent");
  }

  // add new Product
  const addProduct = async () => {
    console.log("[CreateEditSurgicalEvent] add a Product", productItems);
    // defaultProductItems.sepproductside = defaultProductItems.sepproductside ? defaultProductItems.sepproductside : surgicalEventData.surgicalside;
    // set default sepproductside as NotApplicable
    defaultProductItems.sepproductside = defaultProductItems.sepproductside ? defaultProductItems.sepproductside : "NotApplicable";

    // add a Product
    if (await handleAddNewProductData({
      newProductData: productItems,
      ledger,
      ContractId,
      setContractId,
      name: userParty ? userParty : party,
      role: role,
      expirationdate: productItems.acknowledge,
      surgicalside: productItems.acknowledgeProduct,
      setProductList: SetProductItemsList,
      setDetailList: SetDetailItemsList,
      roleCid,
    })) {
      // after add a Product
      const tempList = [...productItemsList];
      tempList.push(productItems);
      // SetProductItemsList(tempList);
      setProductItems(defaultProductItems);
    }
    else {
      // after not add a Product
      setProductItems(defaultProductItems);
    }
  };

  // click "Add more Product" button
  const handleAddMoreProduct = async (event) => {
    let newErrors = requiredProduct(productItems, { introReqs: ['sepproductprice'] });
    if ((Object.keys(newErrors).length > 0)) {
      setProductError({ ...newErrors });
      return false;
    }
    const complianceExpiration = checkComplianceStatusExpiration(surgicalEventData, productItems);
    if (complianceExpiration && !productItems.acknowledge) return false;
    const complianceSide = checkComplianceStatusSide(surgicalEventData, productItems);
    if (complianceSide && !productItems.acknowledgeProduct) return false;

    setProductError({});
    setNextClicked(false);

    console.log("[CreateEditSurgicalEvent] ", productItems);
    if ((complianceExpiration && productItems.acknowledge === 'Declined') ||
      (complianceSide && productItems.acknowledgeProduct === 'Declined')) {
      // open decline confirm modal
      setOpenModal(true);
      return false;
    }
    else {
      // spinner
      setLoading(layoutDispatch);

      await addProduct();

      unsetLoading(layoutDispatch);

      return true;
    }
  };

  // delete old Product
  const handleDeleteProduct = async (itemIndex) => {
    // check if it's removed or not
    // const removeproductdata = productItemsList[itemIndex]; // old case
    console.log("[CreateEditSurgicalEvent] handleDeleteProduct", JSON.stringify(productDetailsList),
      JSON.stringify(productItemsList), itemIndex);

    const removeproductdata = productDetailsList[itemIndex];
    const tempList = [...productItemsList];
    tempList.splice(itemIndex, 1);
    const tempDetailList = [...productDetailsList];
    tempDetailList.splice(itemIndex, 1);

    console.log("[CreateEditSurgicalEvent] remove a Product");

    // spinner
    setLoading(layoutDispatch);

    const resRem = await handleRemoveProduct({
      removeproductdata,
      ledger,
      ContractId,
      setContractId,
      name: userParty ? userParty : party,
      role: role,
      roleCid,
      productindex: itemIndex,
    });

    unsetLoading(layoutDispatch);

    // remove a Product
    if (resRem) {
      // after remove a Product
      SetProductItemsList(tempList);
      SetDetailItemsList(tempDetailList);
    }
  }

  // update old Product
  const handleUpdateProduct = async (updateProduct, updateIndex) => {

    console.log("[CreateEditSurgicalEvent] update a Product", updateProduct, updateIndex);

    productItemsList[updateIndex] = updateProduct;
    productDetailsList[updateIndex].product = updateProduct;
    SetProductItemsList(productItemsList);
    SetDetailItemsList(productDetailsList);

    // spinner
    setLoading(layoutDispatch);

    // update a Product
    await handleUpdateProductData({
      updateProductData: productDetailsList[updateIndex],
      ledger,
      ContractId,
      setContractId,
      name: userParty ? userParty : party,
      role: role,
      expirationdate: updateProduct.acknowledge,
      surgicalside: updateProduct.acknowledgeProduct,
      setProductList: SetProductItemsList,
      roleCid,
      productindex: updateIndex,
    });

    unsetLoading(layoutDispatch);
  }

  // click "I Confirm" button on modal
  const handleDeclineProductConfirmed = async () => {
    // setOpenModal(false);
    await addProduct();
    if (nextClicked) {
      // go to next step
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setNextClicked(false);
    }
  }

  // finish all edits
  // click "Yes" button of Warning Popup
  const handleNextStep = async () => {
    setOpenWarning(false);

    // spinner
    setLoading(layoutDispatch);

    const resCon = await confirmSurgicalEvent({
      ledger,
      name: userParty ? userParty : party,
      ContractId,
      roleCid,
    });

    // confirm SurgicalEvent
    if (resCon) {
      // after confirm SurgicalEvent
      // complete SurgicalEvent with certifcate
      await completeSurgicalEvent({
        contractId: ContractId,
        ledger,
        isCertificate: true,
        roleCid,
        handleAfter: function (res) {
          // after complete SurgicalEvent, open Complete Dialog
          const result = getCompleteMessage(res, (admindetails ? admindetails.auto_po_vendor : false));
          // setSucessStep1(results);
          toastOpen(layoutDispatch, result);
          history.push("/app/surgicalevent");
          // setSucessStep1(true);
        },
        name: userParty ? userParty : party,
        role: role,
      });

    }

    unsetLoading(layoutDispatch);

  }
  const handleSecRequestBtn = () => {
    setOpenWarning(false);
  }
  /* const setSucessStep = (status) => {
    // setSucessStep1(status);
    history.push("/app/surgicalevent");
  } */

  return (
    <>
      {
        checkRedirectToList()
          ?

          <div className={classes.customRoot}>

            <Typography variant="h4" className={classes.pageTitle} style={{ textAlign: 'center' }}>
              {isCreate ? "Create New Surgical Event" : "Edit Surgical Event"}
            </Typography>

            <div className={classes.formRoot}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  labelProps.optional = <Typography className={classes.stepperHeader} variant="caption">{stepsTop[index]}</Typography>;

                  return (
                    <Step onClick={(e) => { handleBack(index) }} key={`parentStep.(${label}).(${index})`}  {...stepProps} className={(activeStep === index) ? 'current' : ''}>
                      <StepLabel className={classes.stepperMain} {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>

              <div className={classes.stepDetails}>
                <div className={classes.step1} style={{ width: (activeStep === 0 ? 3 : activeStep * 33.35) + '%' }}>&nbsp;</div>
                {activeStep === 0 &&
                  <AddEditEvent
                    isCreate={isCreate}
                    surgicalError={surgicalError}
                    surgicalEventData={surgicalEventData}
                    SetSurgicalEventData={SetSurgicalEventData}
                    handleValueFill={handleValueFill}
                  />
                }
                {activeStep === 1 &&
                  <AddEditProduct
                    isCreate={isCreate}
                    productItemsList={productItemsList}
                    productItems={productItems}
                    productError={productError}
                    setProductError={setProductError}
                    setProductItems={setProductItems}
                    surgicalEventData={surgicalEventData}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    handleDeclineProductConfirmed={handleDeclineProductConfirmed}
                    handleDeleteProduct={handleDeleteProduct}
                    handleUpdateProduct={handleUpdateProduct}
                    handleValueFill={handleValueFill}
                    hospital={hospital}
                    options={{
                      // isFetchInventory: true,
                      edit: {
                        isSurgicalEvent: true, // if Implanted then true, else disable
                        isInventory: false,
                        isEnableAutoScan: true,
                      },
                    }}
                  />
                }
                {activeStep === 2 &&
                  <div className="formsteps">  <h1 className={classes.formHeading}>Finalize and Complete</h1>

                    <div className={classes.mainFormSteps}>
                      <h2 className={classes.formSubHeading}>Patient Details</h2>
                      <div className="grid-list grid-list-5">
                        <div className="list-item">
                          <div className="label">MRN</div>
                          <div className="value">{surgicalEventData.mrn}</div>
                        </div>
                        <div className="list-item">
                          <div className="label">First Name</div>
                          <div className="value">{surgicalEventData.patientfirstname}</div>
                        </div>
                        <div className="list-item">
                          <div className="label">Last Name</div>
                          <div className="value">{surgicalEventData.patientlastname}</div>
                        </div>
                        <div className="list-item">
                          <div className="label">Gender</div>
                          <div className="value">{surgicalEventData.patientgender}</div>
                        </div>
                        <div className="list-item">
                          <div className="label">DOB</div>
                          <div className="value">{formatDate(surgicalEventData.patientdob)}</div>
                        </div>
                      </div>

                      <h2 className={classes.formSubHeading}>Event Details</h2>
                      <div className="grid-list grid-list-5">
                        <div className="list-item">
                          <div className="label">Date</div>
                          <div className="value">{formatDate(surgicalEventData.eventdate)}</div>
                        </div>
                        <div className="list-item">
                          <div className="label">Procedure</div>
                          <div className="value">{surgicalEventData.surgicalprocedure}</div>
                        </div>
                        <div className="list-item">
                          <div className="label">Side</div>
                          <div className="value">{surgicalEventData.surgicalside && Sides[surgicalEventData.surgicalside]}</div>
                        </div>
                      </div>

                      <h2 className={classes.formSubHeading}>Physician Details</h2>
                      <div className="grid-list grid-list-5">
                        <div className="list-item">
                          <div className="label">Salutation</div>
                          <div className="value">{surgicalEventData.physiciansalutation}</div>
                        </div>
                        <div className="list-item">
                          <div className="label">First Name</div>
                          <div className="value">{surgicalEventData.physicianfirstname}</div>
                        </div>
                        <div className="list-item">
                          <div className="label">Last Name</div>
                          <div className="value">{surgicalEventData.physicianlastname}</div>
                        </div>
                      </div>

                      <div className={classes.productDetails}>
                        <h2 className={classes.formSubHeading}>
                          <Typography variant="inherit" style={{ marginRight: 15 }}>Total Added Products</Typography>
                          <Badge className={classes.badgeColor} badgeContent={productItemsList.length} color="primary" overlap="rectangular"></Badge>
                          <span className={classes.productTableHeading}><span className="price-label">Total Price of Products:</span> ${getTotalPrice(productItemsList)}</span>
                        </h2>
                      </div>
                      {productItemsList.length !== 0 &&
                        <div className={classes.productAccordion}>
                          {productItemsList.map((obj, index) => (
                            <Accordion
                              key={index}
                              expanded={expanded === (index + 1)}
                              onChange={handleChangeAccordion((index + 1))}
                              className={classes.productMainAccordion}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                className="product-detail-accordion-head"
                              >
                                <Typography className={classes.accordionHeading}>
                                  <span className="text-inactive">Reference Number :</span> <span className="text-active">{obj.sepreferencenumber}   </span>
                                  <span className="text-inactive" style={{ marginLeft: 25 }}>Lot Code :</span> <span className="text-active">{obj.seplotcode}</span>
                                </Typography>
                                {
                                  checkComplianceStatus({ surgicalevent: surgicalEventData, auditedproductdata: obj }) !== ''
                                  && <Chip label="Compliance" className="bg-info-light" />
                                }
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography component={'div'}>
                                  <TableContainer className={classes.productTableContainer} component={Paper}>
                                    {
                                      checkComplianceStatusSide(surgicalEventData, obj) &&
                                      <Alert severity="info"><strong>Compliance</strong>: Product is the incorrect Side based on the Surgical Procedure</Alert>
                                    }
                                    {
                                      checkComplianceStatusExpiration(surgicalEventData, obj) &&
                                      <Alert severity="info"><strong>Compliance</strong>: Product is past expiration date</Alert>
                                    }
                                    <ProductDetailViewTable
                                      obj={obj}
                                    />
                                  </TableContainer>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                          <FormControlLabel
                            className={classes.customCheckbox}
                            value="accept"
                            onChange={(e) => { acceptTermsandCondtn(e) }}
                            control={<Checkbox color="primary" checked={(!!loginUser && loginUser !== '')} />}
                            label="I certify that the added products correctly reflect the surgery performed"
                            labelPlacement="end"
                          />
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full-width"
                              label="User Name"
                              style={{ margin: 8 }}
                              placeholder=" "
                              className={classes.customFullWidth}
                              margin="normal"
                              value={loginUser}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                            />
                          </div>
                        </div>
                      }
                    </div>
                    <ModalDialogBox
                      open={openWarning}
                      setOpen={setOpenWarning}
                      heading="Warning"
                      type="warning"
                      text={'<p>After completing this Surgical event you will no longer be able to edit products that have been added and the record will be sent to the Manufacturer and Purchasing.</p><div className="btnPopGroup"><p>Do you wish to complete?</p></div>'}
                      handleRequest={handleNextStep}
                      secondBtn={"No"}
                      handleSecRequest={handleSecRequestBtn}
                    />
                  </div>
                }

                <div className="form-footer">
                  {activeStep >= 1 &&
                    <>
                      <Button variant="text" className={classes.arrowBtn}>
                        <ArrowBackIcon className={classes.backarraobtn} onClick={(e) => { handleBackSteps() }} />
                      </Button>
                    </>
                  }
                  {activeStep === 1 &&
                    <Button variant="contained" color="primary" onClick={handleAddMoreProduct}>Add more Product</Button>
                  }
                  <div className="right-buttons">
                    <Button variant="text" color="primary" onClick={handleCancelExist}>Cancel</Button>
                    <Button variant="outlined" color="primary" onClick={handleSaveExist}>Save & Exit</Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                    >
                      {activeStep === steps.length - 1 ? 'Complete' : 'Continue'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            {/* <PromptModal
              modalText="You have not completed the Surgical Event. Do you want to Cancel or Continue ?"
              isBlock={!isEmptySurgicalEvent(surgicalEventData)}
            /> */}

          </div>
          :
          <Redirect to="/app/surgicalevent" />
      }
    </>
  );
}

/**
 * Dialog to ask Are you sure to delete?
 */


/**
 *  Dialog to ask Do you wish to complete?
 */
