import React, { useEffect, useRef, useState } from "react";
import { useParty, useStreamQueries } from "@daml/react";
import { Button, Grid, Typography } from "@material-ui/core";
import { SurgicalEventCompliance } from "../../services/daml-modules1";
import ComplianceListContracts from "./compliancelistcontracts1";
// import useStyles from "./styles";
import useStyles from "../surgicalevent/styles";
import CompliancePopup from "./compliancedeatilpopup"
import exportIcon from "../../icons/new-export-icon.svg";
import printIcon from "../../icons/new-print-icon.svg";
import classNames from "classnames";
import ExportPopover from "../surgicalevent/export-popover";
import { pdf } from '@react-pdf/renderer';
import CompliancePDF from "./compliance-pdf";
import { blobToDownload, downloadCSV } from "../../services/download";
import CompliancePrint from "./compliance-print";
import { useReactToPrint } from "react-to-print";
import CSVFormat from "./CSVFormat";

export default function ComplianceList() {

  const classes = useStyles();

  const hospital = useParty();

  const { contracts, loading } = useStreamQueries(SurgicalEventCompliance, () => [{ hospital }]);
  // console.log("[ComplianceList]", contracts);

  const [open, setOpen] = useState(false);
  const [isHistory, setHistory] = useState(false);
  const [contract, setContract] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedComplianceID, setSelectedComplianceID] = useState([]);
  const [selectedComplianceWithDetail, setSelectedComplianceWithDetail] = useState([]);
  const componentRef = useRef();

  const handlePrintClick = () => {
    if (selectedComplianceID.length > 0)
    handlePrint();
  }
  useEffect(() => {
    setSelectedComplianceWithDetail(contracts.filter(c => selectedComplianceID.includes(c.contractId)));
  }, [selectedComplianceID, contracts]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handleOpenPopover = (event) => {
    if (selectedComplianceID.length > 0)
      setAnchorEl(event.currentTarget);
  }
  const handleExportCSV = () => {
    let format = "SEID,Date,User,Role,Event Date,MRN,Rule Type Level,Rule Type,Rule Outcome\n";
    for (let i = 0; i < selectedComplianceWithDetail.length; i++) {
      format = format + CSVFormat({ data: selectedComplianceWithDetail[i], index: i });
    }
    downloadCSV(format, `${selectedComplianceWithDetail[0].payload.surgicaleventdata.seid} ${selectedComplianceWithDetail.length > 1 ? "(+" + (selectedComplianceWithDetail.length - 1) + ")" : ""}`);
  }
  const handleExportPDF = async () => {
    const blob = await pdf((
      <CompliancePDF data={selectedComplianceWithDetail} />
    )).toBlob();
    blobToDownload(blob, `${selectedComplianceWithDetail[0].payload.surgicaleventdata.seid} ${selectedComplianceWithDetail.length > 1 ? "(+" + (selectedComplianceWithDetail.length - 1) + ")" : ""}.pdf`);
  }
  const handleDetail = (c) => {
    setContract(c);
    setOpen(true);
  }

  return (
    <>
      <Grid>
        <Grid container className={classes.newContainer} justifyContent="space-between" >
          <Grid item xs>
            <Typography variant="h4" className={classes.newPageTitle}>Compliance</Typography>
          </Grid>
          <Grid item>
            <Button
              className={classNames(classes.newCreateButton, classes.newIconButton)}
              variant="contained"
              onClick={handlePrintClick}
              startIcon={<img alt="" src={printIcon} />}
            />
            <Button
              className={classNames(classes.newCreateButton, classes.newIconButton)}
              variant="contained"
              onClick={handleOpenPopover}
              startIcon={<img alt="" src={exportIcon} />}
            />
          </Grid>
          <ExportPopover
            anchor={anchorEl}
            setAnchor={setAnchorEl}
            handleExportCSV={handleExportCSV}
            handleExportPDF={handleExportPDF}
          />
        </Grid>
        <ComplianceListContracts
          isIncludeHistory={isHistory}
          allContracts={contracts}
          loading={loading}
          handleDetail={handleDetail}
          setArchived={setHistory}
          setSelectedComplianceID={setSelectedComplianceID}
        />
      </Grid>
      <CompliancePopup
        open={open}
        setOpen={setOpen}
        contract={contract}
      />
      <div style={{ display: "none" }}><CompliancePrint data={selectedComplianceWithDetail} ref={componentRef} /></div>
    </>
  );
}