import React from "react";
import { Chip } from "@material-ui/core";
import classNames from "classnames";
import TreeContracts from "../../components/Contracts/TreeContracts";
import { POStatus } from "../../models/POStatus";
import useStyles from "./styles";


/**
 * PO Comments Grid
 * Detail View Drawer of SE in PO
 * @param {Array} comments 
 * @param {Array} allContracts 
 * @returns 
 */
export default function POCommentsGrid ({
    comments,
    allContracts = [],
}) {
    const classes = useStyles();
   
    return (  
        <div className={classes.poCommentsGrid}>
            <h2 className={classes.poCommentHeading}>Po Comments</h2>
            <div className={classes.poCommentsGridTable}>   
                <TreeContracts
                    className={classes.customRoot}
                    contracts={comments}
                    columns={[
                        ["Date", "date"],
                        ["Name", "user"],
                        ["PO Status", "status", {
                            type: "render",
                            render: (param) => (POStatus[param.value] && <Chip 
                                label={POStatus[param.value]} 
                                size="small" 
                                className={classNames(classes.smallChip, (param.value === 'Accepted' ? classes.backGreen : (
                                    param.value === 'Rejected' ? classes.backRed : classes.backYellow
                                )))} 
                            />),
                        }],
                        ["Comments", "note",{
                            options: {
                                tooltip: true, 
                            },
                        }],
                    ]}

                    actions={[]}

                    options={{
                        isFooter: false,
                        isPagination: false,
                        statusEmpty: (allContracts.length === 0)
                    }}
                /> 
            </div>
        </div>
    );
  }  