import { validateUserJobTitle } from "./UserAccessLevel";
import { getModelMenu, validateModelItem } from "./handles";

export const ContactModel = {
  lastname: '',
  firstname: '',
  gender: '',
  // salutation: '',
  customerservicename: '',
  phone: '',
  mobile: '',
  email: '',
  fax: '',
  jobtitle: '',
  department: '',
};

export const W9FormModel = {
  filetype: '',
  filename: '',
};

export const validateW9Form = (file) => {
  return (file.filename !== "" || file.filetype !== "");
};


export const getW9Form = (file) => {
  let fileData = {};
  if (typeof file.filename === "string" && file.filename !== "") fileData.filename = file.filename;
  if (typeof file.filetype === "string" && file.filetype !== "") fileData.filetype = file.filetype;
  return fileData;
};

export const validateContact = (contact) => {
  return (contact.lastname !== ""
    || contact.firstname !== ""
    || validateGender(contact.gender)
    // || validateSalutation(contact.salutation)
    || contact.customerservicename !== ""
    || contact.phone !== ""
    || contact.mobile !== ""
    || contact.email !== ""
    || contact.fax !== ""
    || validateUserJobTitle(contact.jobtitle)
    || contact.department !== "");
};

export const getContact = (contact) => {
  let newContact = {};
  if (typeof contact.lastname === "string" && contact.lastname !== "") newContact.lastname = contact.lastname;
  if (typeof contact.firstname === "string" && contact.firstname !== "") newContact.firstname = contact.firstname;
  if (validateGender(contact.gender)) newContact.gender = contact.gender;
  // if (validateSalutation(contact.salutation)) newContact.salutation = contact.salutation;
  if (typeof contact.customerservicename === "string" && contact.customerservicename !== "") 
    newContact.customerservicename = contact.customerservicename;
  newContact.phone = contact.phone || "";
  newContact.mobile = contact.mobile || "";
  newContact.email = contact.email || "";
  newContact.fax = contact.fax || "";
  newContact.jobtitle = contact.jobtitle || "";
  newContact.department = contact.department || "";
  return newContact;
};

export const Genders = {
  Male: 'Male',
  Female: 'Female',
};
export const validateGender = validateModelItem(Genders);
export const getGenders = getModelMenu(Genders);

export const Salutations = {
  MD: 'MD',
  DPM: 'DPM',
  DO : 'DO',
  PA : 'PA',
  APRN : 'APRN',
  NP : 'NP'
};
export const validateSalutation = validateModelItem(Salutations);
export const getSalutations = getModelMenu(Salutations);