import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  setupButton: {
    marginBottom: '20px',
  },
  endStart: {
    display : 'flex',
    justifyContent: 'flex-end'
  },
  topGrid: {
    marginTop: '10px',
    marginBottom: '20px',
  },
  comment: {
    marginTop: '7px',
    display: 'block',
  },
  pageTitle: {
    display: 'inline-block',
    marginBottom: '15px',
  },
  centerCell: {
    justifyContent: 'center !important',
    textAlign: 'center',
  },
  backGreen: {
    backgroundColor: 'rgba(76, 175, 80, 0.38);',
    border: '1px solid #4CAF50;'
  },
  backBlue: {
    backgroundColor: 'rgba(33, 150, 243, 0.38)',
    border: '1px solid #2196F3'
  },
  backBlue1: {
    backgroundColor: '#FFFFFF',
    "&:hover": {
      borderBottomColor:" rgba(224, 224, 224, 1) !important",
    },
  },
  newCheckbox: {
    float: 'right',
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '16px',
    '& .MuiCheckbox-root': {
      padding: '4px 9px',
    },
    '& .MuiCheckbox-colorPrimary.Mui-checked': {
      color: '#183C73'
    }
  },
  smallChip: {
    fontSize: '0.65rem !important;',
    fontWeight: 600,
  },
  datepicker: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '14px'
  },
  paddingHR10: {
    paddingLeft: '10px',
    paddingRight: '10px'
  },
  marginHR5: {
    marginLeft: '5px',
    marginRight: '5px'
  },

  // new styles added in payment -> invoice popup (table to grid switched)
  paddingZero: {
    padding: '0px !important',
  },
  gridContainer: {
    height: 'calc(100% - 64px);',
  },
  gridBottomBorder: {
    borderBottom: '1px solid #CCCCCC;'
  },
  gridHead: {
    color: 'rgba(0, 0, 0, 0.87);',
    fontWeight: '500;',
    fontSize: '14px;',
    lineHeight: '16px;',
    padding: '0px !important;',
    display: 'flex;',
    justifyContent: 'center;',
    alignItems: 'center;',
    height: '30px;',
  },
  gridColumn: {
    height: '100%;',
    overflowY: 'auto;',
  },
  scrollit: {
    overflow: 'scroll;',
    height: '100px;',
  },
  scrollit1: {
    overflow: 'scroll',
    height: '15vh',
  },
  scrollit2: {
    overflow: 'scroll',
    height: '20vh',
  },
  gridCellCenter: {
    color: 'rgba(0, 0, 0, 0.87) !important',
    fontWeight: '400;',
    fontSize: '12px;',
    lineHeight: '14px;',
    display: 'flex;',
    justifyContent: 'center;',
    alignItems: 'center;',
    height: "34px",
  },

  gridCell: {
    color: 'rgba(0, 0, 0, 0.87) !important',
    fontWeight: '400;',
    fontSize: '12px;',
    lineHeight: '14px;',
    display: 'flex;',
    alignItems: 'center;',
    height: "34px",
  },
  gridWidth: {
    justifyContent: 'center',
    minWidth: '25%',
    overflow: 'hidden',
  },
  gridMinWidth: {
    minWidth: '12%',
    overflow: 'hidden',
    marginRight: '22px',
  },
  gridMinWidth1: {
    marginRight: '0px !important',
  },
  gridTopBorder: {
    borderTop: '1px solid #CCCCCC;'
  },
  gridFooterComments: {
    padding: '26px 0 0 31px;',
    fontSize: '14px;',
    lineHeight: '16px;',
  },
  gridSeriesWidth: {
    maxWidth: '4%',
    marginRight: '12px',
  },
  mleft2: {
    marginLeft: '2%',
  },
  gridSeriesWidth1: {
    maxWidth: '9%',
  },
  gridSeriesWidth2: {
    maxWidth: '3%',
  },
  gridCellAlign: {
    textAlign: 'center',
  },
  gridFooterDetails1: {
    padding: '12px 0 0 31px;',
    fontWeight: '500;',
    fontSize: '14px;',
    lineHeight: '16px;',
    color: 'rgba(0, 0, 0, 0.87);'
  },
  gridFooterDetails: {
    padding: '13px 0 0 31px;',
    fontWeight: '400;',
    fontSize: '14px;',
    lineHeight: '16px;',
  },
  amountInvoice: {
    marginLeft: '141px;',
    fontWeight: '400;',
    fontSize: '14px;',
    lineHeight: '16px;',
  },
  // detail viewer of invoice
  customRootInvoice: {
    '&  .MuiDrawer-paperAnchorRight': {
      width: '100% !important;',
      height: '100% !important;',
      '@media (min-width:1024px)': {
        maxWidth: '97vw !important;',
        maxHeight: '90vh !important;',
        marginTop: '5vh !important',
        marginRight: '1vw !important',
      },
    },
    '&  .MuiDialogContentText-root': {
      margin: 0,
      minHeight: '100%',
      '& .MuiGrid-container': {
        height: '100%',
      },
    },
    '& .left-panel': {
      height: '100%',
      overflowY: 'scroll',
      borderRight: '#183C73 solid 3px',
      '@media (max-width:1024px)': {
        borderRight: '0px',
        height: 'auto',
      },
    },
    '& .new-panel': {
      height: '101%',
      overflowY: 'scroll',
      '@media (max-width:1024px)': {
        borderRight: '0px',
        height: 'auto',
      },
    },
    // '& .borderSection': {
    //   border : "1px solid rgba(204, 204, 204, 1)",
    // },
    '& .header-section': {
      height: '8%',
      borderBottom: "1px solid rgba(204, 204, 204, 1)",
    },
    '& .right-panel': {
      height: '100%',
      overflowY: 'scroll',
      '@media (max-width:1024px)': {
        height: 'auto',
      },
    },
    '& .view-heading': {
      borderBottom: '0px !important',
    },
    '& .left-card': {
      margin: 10,
      border: "1px solid rgba(204, 204, 204, 1)",
    },
  },
  skipUnderline: {
    textDecoration: "none",
    color: "#1C3C74",
    weight: 700,
    "&:hover": {
      borderBottomColor: `${theme.palette.primary.light} !important`,
      // color: "red",
      textDecoration: "underline"
    },
  },
  customRootInvoice2: {
    '&  .MuiDrawer-paperAnchorRight': {
      width: '100% !important;',
      height: '92% !important;',
      background: '#FFFFFF;',
      border: '1px solid #CCCCCC;',
      borderRadius: '10px;',
      '@media (min-width:1024px)': {
        maxWidth: '67vw !important;',
        maxHeight: '94vh !important;',
        marginTop: '2vh !important',
        marginRight: '16vw !important',
      },

      '& .view-heading5': {
        padding: '8px 33px 12px 44px;',
      },
    },
    '&  .MuiDialogContentText-root': {
      margin: 0,
      minHeight: '100%',
      '& .MuiGrid-container': {
        height: '96%',
      },
    },
    '& .left-card': {
      margin: 5,
      border: "1px solid rgba(204, 204, 204, 1)",
    },
    '& .left-panel': {
      height: '100%',
      overflowY: 'scroll',
      borderRight: '#183C73 solid 0px',
      '@media (max-width:1024px)': {
        borderRight: '0px',
        height: 'auto',
      },
    },
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '0px;'
  },
  navigationBtn: {
    display: 'flex;',
    alignContent: 'center;',
    justifyContent: 'flex-end;',
  },
  ptypeLabel: {
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '18px',
    color: '#5C5C5C',
    marginRight: '7px',
    minWidth: "40px",
    float: 'left',
    top: "-5px",
  },
  marginInBetween: {
    margin: '5px'
  },
  secondarybtn: {
    background: "#5C5C5C",
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    color: "#fff",
    borderRadius: '5px',
  },
  primarybtn: {
    background: "#1C3C74",
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    color: "#fff",
    borderRadius: '5px',
  },
  invoicePopupWidth: {
    '& .MuiTypography-root': {
      width: '99%',
    },
  },
  hideOverflow: {
    overflow: 'hidden'
  },
  paddingBottom: {
    paddingBottom: '15px !important'
  },
}), { index: 1 });
