
/**
 * get string for today, format: xxxx-xx-xx
 * @param {Boolean} timestamp
 * @returns {String}
 */
export const getTodayString = (timestamp = false) => {
  const datetime = new Date();
  if (timestamp) return datetime.toISOString();
  return convertDateToString(datetime);
};

/**
 * get string for yesterday, format: xxxx-xx-xx
 * @returns {String}
 */
export const getYesterdayString = () => {
  let datetime = new Date();
  datetime.setDate(datetime.getDate() - 1);
  return convertDateToString(datetime);
};

/**
 * get string for yesterday, format: xxxx-xx-xx
 * @returns {String}
 */
const convertDateToString = (datetime) => {
  const pad = (n) => (n < 10 ? "0" + n : n);
  return (
    datetime.getFullYear() +
    "-" +
    pad(datetime.getMonth() + 1) +
    "-" +
    pad(datetime.getDate())
  );
};

/**
 * convert json to string for Elastic Search
 * @param {Object} json
 * @param {String|Number} index
 * @param {String|Number} id
 * @returns {String}
 */
export const jsonToERP = (json, index, id) => {
  let result = "";
  try {
    let str = JSON.stringify(json);
    result = `{"index":{"_index":"${index}","_id":"${id}"}}\n${str}\n`;
  } catch (e) {
    console.warn("[jsonToERP]", e);
  }
  return result;
};

/**
 * validate email
 * @param {String} data
 * @returns {Boolean}
 */
export const validateEmail = (data) => {
  let dateRegexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return dateRegexp.test(data.toLowerCase());
};

/**
 * validate password
 * @param {String} data
 * @returns {Boolean|String}
 */
export const validatePassword = (data) => {
  let dateRegexp = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])");
  if (data.length < 8) return "Password must have 8 characters in length";
  if (!dateRegexp.test(data))
    return "Password must have Lower case, upper case and numbers";
  dateRegexp = new RegExp("([^A-Za-z0-9])");
  if (!dateRegexp.test(data)) return "Password must have Special characters";
  return true;
};

/**
 * validate password to return unvalid error list
 * @param {String} data
 * @returns {Array}
 */
export const validatePasswordErrors = (data) => {
  let result = [];
  let dateRegexp = new RegExp();
  if (data.length < 8) result.push("Minimum 8 characters");
  dateRegexp = new RegExp("(?=.*[a-z])");
  if (!dateRegexp.test(data)) result.push("1 Lowercase letter");
  dateRegexp = new RegExp("(?=.*[A-Z])");
  if (!dateRegexp.test(data)) result.push("1 Upercase letter");
  dateRegexp = new RegExp("(?=.*[0-9])");
  if (!dateRegexp.test(data)) result.push("1 Number");
  dateRegexp = new RegExp("([^A-Za-z0-9])");
  if (!dateRegexp.test(data)) result.push("1 Special character");
  return result;
};

/**
 * validate date
 * @param {String} data
 * @returns {Boolean}
 */
export const validateDate = (data) => {
  const dateRegexp = /^\d{4}-\d{2}-\d{2}$/;
  return dateRegexp.test(data);
  // return Date.parse(data);
};

/**
 * validate datetime
 * yyyy-mm-ddThh:mm:ss(\.s+)?Z
 * @param {String} data
 * @returns {Boolean}
 */
export const validateDateTime = (data) => {
  // const dateRegexp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/
  // const dateRegexp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d+Z$/
  // const dateRegexp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d+Z$|^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/
  const dateRegexp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.*)(\d*)Z$/;
  return dateRegexp.test(data);
  // return Date.parse(data);
};

/**
 * validate time
 * @param {String} data
 * @returns {Boolean}
 */
export const validateTime = (data) => {
  const dateRegexp = /^\d{2}:\d{2}:\d{2}$|^\d{2}:\d{2}$/;
  return dateRegexp.test(data);
};

/**
 * format date
 * @param {String} data
 * @returns {String}
 */
export const formatDate = (data) => {
  if (validateDate(data)) {
    const date = new Date(data);
    date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    const str = date.toDateString();
    return str.substring(4, 10) + "," + str.substring(10, 15);
  }
  if (validateDateTime(data)) {
    const date = new Date(data);
    const str = date.toDateString();
    return str.substring(4, 10) + "," + str.substring(10, 15);
  }
  return data;
};

/**
 * format time
 * @param {String} data
 * @returns {String}
 */
export const formatTime = (data) => {
  if (validateDateTime(data)) {
    const date = new Date(data);
    return date.toString().substring(16, 24);
  }
  return data;
};

/**
 * format datetime
 * @param {String} data
 * @returns {String}
 */
export const formatDateTime = (data) => {
  if (validateDateTime(data)) {
    const date = new Date(data);
    return date.toString().substring(4, 24);
  }
  return data;
};

/**
 * sort for contracts by created_at
 * @param {Array} contracts
 * @param {String} sortKey
 * @param {Function} getValue
 * @returns {Array}
 */
export const sortContracts = (contracts, sortKey, getValue) => {
  if (typeof contracts === "object" && contracts.length) {
    if (contracts[0].payload) {
      if (typeof getValue !== "function") getValue = (data, key) => data[key];
      const val = getValue(contracts[0].payload, sortKey, { isString: true });
      let getVal = getValue;
      if (validateDateTime(val))
        getVal = (data, key) =>
          new Date(getValue(data, key, { isString: true }));
      // console.log("[sortContracts]", val, validateDateTime(val), getVal(contracts[0].payload, sortKey));
      return contracts.sort(
        (a, b) => getVal(b.payload, sortKey) - getVal(a.payload, sortKey)
      );
    }
    return contracts;
  }
  return [];
};

/**
 * get template from contract
 * @param {Object} contract
 * @returns {String}
 */
export const getTemplate = (contract) =>
  typeof contract.templateId === "string"
    ? contract.templateId.split(":")[contract.templateId.split(":").length - 1]
    : "";

/**
 * get list from payload of response by Template
 * @param {Array} res
 * @param {String} template
 * @returns {Array}
 */
export const getTemplateListFromResponse = (res, template) => {
  let result = [],
    contractIds = [];
  res.forEach((rr) => {
    if (!!rr && typeof rr === "object" && rr.length)
      rr.forEach((r) => {
        if ("created" in r && getTemplate(r["created"]) === template) {
          result.push({
            ...r["created"]["payload"],
            contractId: r["created"]["contractId"],
          });
          contractIds.push(r["created"]["contractId"]);
        } else if ("archived" in r && getTemplate(r["archived"]) === template) {
          const index = contractIds.indexOf(r["archived"]["contractId"]);
          if (index >= 0) {
            result.splice(index, 1);
            contractIds.splice(index, 1);
          }
        }
      });
  });
  return result;
};

/**
 * get UDI, Expiration Date, Lotcode, ... from BarCode
 * 01 - udi
 * 17 - expiration
 * 10 - lotcode
 * e.g. (01)0801741148767(17)251212(10)EADT7 => {udi: "0801741148767", expiration: "2025-12-12", lotcode: "EADT7"}
 * e.g. 010761332702693117231028100079555D => {udi: "7613327026931", expiration: "2023-10-28", lotcode: "0079555D"}
 * e.g. 01076131530715941726021710T226341AC => {udi: "7613153071594", expiration: "2026-02-17", lotcode: "T226341AC"}
 * new BarCode with sepmanufacturingdate, sepserialnumber
 * 11 - manufacturingdate
 * 21 - serialnumber
 * Now, serialnumber is same as lotcode
 * 91 - unknown, it's not used
 * e.g. 01050504747481701724080511210805212952562131
 * e.g. (01)05050474748170(17)240805(11)210805(21)2952562131
 * e.g. 01076131530715941726021721T226341AC
 * e.g. 0104546540175366112010261725093010K0CC738
 * e.g. 0107613327260885172603261026032103
 * e.g. 0107613327256024172602241024022102
 * e.g. 01076133270409681726100810NTR2C1918338480002000
 * e.g. 01076133270415071726092410CTD66435918309090003000
 * e.g. 01076133270135591726093010TPJA1918804190010048000
 * e.g. 010761332733671917261102103V2NRP913V2NRP0001000
 * 20 - unknown number, it's not used
 * 240 - reference number, it's not used
 * e.g. (01)05050474731745(17)261212(21)2384842350(20)12(11)231212(240)DIB00U0195
 * e.g. (01)05050474731745(20)12(17)261212(11)231212(21)2384842350(240)DIB00U0195
 * @param {String} code
 * @returns {Object}
 */
export const getDataFromCode = (code) => {
  let udi = "",
    expiration = "",
    lotcode = "",
    manufacturingdate = "",
    serialnumber = "",
    found;

  const getdatestring = (str) => {
    let year = `20${str.substr(0, 2)}`;
    let month = str.substr(2, 2);
    if (month < "01") month = "01";
    else if (month > "12") month = "12";
    let date = str.substr(4, 2);
    if (date < "01" || date > "31") date = "01";
    // validate date
    if (date > "28") {
      let listofDays = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      let yy = parseInt(year);
      let mm = parseInt(month);
      let dd = parseInt(date);
      if ((!(yy % 4) && yy % 100) || !(yy % 400)) listofDays[2] = 29;
      if (dd > listofDays[mm]) {
        // invalid
        date = "01";
      }
    }
    return year + "-" + month + "-" + date;
  };

  if (typeof code === "string") {
    // In the case with brackets
    let regex = /\(([\d]+)\)([\w]+)/gm;
    const reg01 = /01(?:([\d]{14})|([\d]{13}))/; // Old case : 01(?:.*([\d]*[\d]{14})|.*([\d]*[\d]{13}))
    const reg11 = /11([\d]{6})/;
    const reg17 = /17([\d]{6})/;
    const reg10 = /10([\w]+)/;
    const reg21 = /21([\w]+)/;
    const reg91 = /91([\w]+)/;
    const reg20 = /20([\d]+)/;
    const reg240 = /240([\w]+)/;
    while (true) {
      found = regex.exec(code);
      if (!found) break;
      if (found[1] === "01") udi = found[2];
      else if (found[1] === "17" && found[2].length >= 6)
        expiration = getdatestring(found[2]);
      else if (found[1] === "10") lotcode = found[2];
      else if (found[1] === "11" && found[2].length >= 6)
        manufacturingdate = getdatestring(found[2]);
      else if (found[1] === "21") {
        serialnumber = found[2];
        lotcode = serialnumber;
      }
    }
    // If there is no brackets
    if (udi === "" || expiration === "" || lotcode === "") {
      // 1. (01)(11)(17)(10)
      regex = new RegExp(reg01.source + reg11.source + reg17.source + reg10.source, 'gm');
      found = regex.exec(code);
      if (found && found.length > 5) {
        udi = found[1] || found[2];
        manufacturingdate = getdatestring(found[3]);
        expiration = getdatestring(found[4]);
        lotcode = found[5];
      } else {
        // 2. (01)(17)(11)(21)
        regex = new RegExp(reg01.source + reg17.source + reg11.source + reg21.source, 'gm');
        found = regex.exec(code);
        if (found && found.length > 5) {
          udi = found[1] || found[2];
          expiration = getdatestring(found[3]);
          manufacturingdate = getdatestring(found[4]);
          serialnumber = found[5];
          lotcode = serialnumber;
        } else {
          // 3. (01)(17)(10)(91)
          regex = new RegExp(reg01.source + reg17.source + reg10.source + reg91.source, 'gm');
          found = regex.exec(code);
          if (found && found.length > 5) {
            udi = found[1] || found[2];
            expiration = getdatestring(found[3]);
            lotcode = found[4];
          } else {
            // 4. (01)(17)(10)
            regex = new RegExp(reg01.source + reg17.source + reg10.source, 'gm');
            found = regex.exec(code);
            if (found && found.length > 4) {
              udi = found[1] || found[2];
              expiration = getdatestring(found[3]);
              lotcode = found[4];
            } else {
              // 5. (01)(17)(21)(20)(11)(240)
              regex = new RegExp(reg01.source + reg17.source + reg21.source + reg20.source + reg11.source + reg240.source, 'gm');
              found = regex.exec(code);
              if (found && found.length > 7) {
                udi = found[1] || found[2];
                expiration = getdatestring(found[3]);
                serialnumber = found[4];
                lotcode = serialnumber;
                manufacturingdate = getdatestring(found[6]);
              } else {
                // 6. (01)(17)(21)
                regex = new RegExp(reg01.source + reg17.source + reg21.source, 'gm');
                found = regex.exec(code);
                if (found && found.length > 4) {
                  udi = found[1] || found[2];
                  expiration = getdatestring(found[3]);
                  serialnumber = found[4];
                  lotcode = serialnumber;
                } else {
                  // 7. (01)(20)(17)(11)(21)(240)
                  regex = new RegExp(reg01.source + reg20.source + reg17.source + reg11.source + reg21.source + reg240.source, 'gm');
                  found = regex.exec(code);
                  if (found && found.length > 7) {
                    udi = found[1] || found[2];
                    expiration = getdatestring(found[4]);
                    manufacturingdate = getdatestring(found[5]);
                    serialnumber = found[6];
                    lotcode = serialnumber;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return { udi, expiration, lotcode, manufacturingdate, serialnumber };
};

/**
 * Check out UDI
 * e.g. 7613327344073
 * e.g. 07613327344073
 * @param {String} udi
 * @returns {Object}
 */
export const checkUdi = (udi) => {
  let di = udi,
    original = udi;
  if (!!udi) {
    if (udi.length > 13) udi = udi.slice(udi.length - 13, udi.length);
    if (di.length > 14) di = di.slice(di.length - 14, di.length);
    else if (di.length < 14) di = `1${di}`;
  }
  return { udi, di, original };
};

/**
 * Check if UDI is the same from Product & Inventory Data
 * @param {Object} productItems
 * @param {Object} inventoryData
 * @returns {Boolean}
 */
export const sameUdi = (productItems, inventoryData) => {
  if (!productItems || typeof productItems !== "object") return false;
  if (
    !inventoryData ||
    typeof inventoryData !== "object" ||
    typeof inventoryData.iproduct !== "object"
  )
    return false;
  if (inventoryData.iproduct && !inventoryData.iproduct.sepudi) return true;
  const checkedProductUdi = checkUdi(productItems.sepudi);
  const checkedInventoryUdi = checkUdi(inventoryData.iproduct.sepudi);
  if (checkedProductUdi.original === checkedInventoryUdi.original) return true;
  if (checkedProductUdi.udi === checkedInventoryUdi.udi) return true;
  return false;
};

/**
 * get query to search timestamp
 * @param {String} date
 * @returns {Query}
 */
export const searchQueryTimestamp = (date) => ({
  "%lte": `${date}T23:59:59.999999Z`,
  "%gte": `${date}T00:00:00.000000Z`,
});

/**
 * check if object is empty
 * @param {Object} obj
 * @returns {Boolean}
 */
export const isEmptyObject = (obj) => {
  return Object.keys(obj).every((k) => {
    if (typeof obj[k] === "string") return obj[k].length === 0;
    else if (typeof obj[k] === "object") return isEmptyObject(obj[k]);
    return !obj[k];
  });
};

/**
 * get common(same) object from both of them
 * @param {Object} obj1
 * @param {Object} obj2
 * @returns {Object}
 */
export const getCommonObject = (obj1, obj2) => {
  if (!obj1 || !obj2 || typeof obj1 !== "object" || typeof obj2 !== "object")
    return {};
  return Object.keys(obj1).reduce((obj3, key) => {
    if (key in obj2) {
      if (!obj1[key] && !obj2[key]) return { ...obj3, [key]: obj1[key] };
      if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
        let result = getCommonObject(obj1[key], obj2[key]);
        if (obj1[key].length >= 0 && obj2[key].length >= 0)
          result = Object.values(result);
        return { ...obj3, [key]: result };
      }
      if (obj1[key] === obj2[key]) return { ...obj3, [key]: obj1[key] };
    }
    return obj3;
  }, {});
};

/**
 * get common(same) object from list of objects
 * @param {Array} objs
 * @returns {Object}
 */
export const getCommonObj = (objs) => {
  if (objs.length) {
    // get common data
    return objs.reduce((obj1, obj2) => getCommonObject(obj1, obj2), objs[0]);
  }
  return {};
};

/**
 * hash password
 * @param {String} password
 * @returns {Number}
 */
export const hashPassword = (password) => {
  var hash = 0,
    i,
    chr;
  if (password.length === 0) return hash;
  for (i = 0; i < password.length; i++) {
    chr = password.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

/**
 * delay
 * @param {Number} time
 * @returns
 */
export const delay = async (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

/**
 * check if function is async or not
 * @param {Function} fn
 * @returns
 */
export const isAsync = (fn) => {
  return fn.constructor.name === "AsyncFunction";
};

/**
 * get string for date, format: mm/dd/yyyy
 * @param {*} date new Date()
 * @returns String mm/dd/yyyy format
 */
export const getFormattedLastLoginDate = (date) => {
  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return month + "/" + day + "/" + year;
};

/**
 * Password generator
 * @param {*} len
 * @returns
 */
export const passwordGenerator = (len) => {
  const length = len ? len : 10; // Defaualt length check
  const string = "abcdefghijklmnopqrstuvwxyz"; // Alpha characters to lowerCase and UpperCase
  const numeric = "0123456789"; //Numeric defaults
  //NOSONAR
  const punctuation = "!@#$%^&*()_+~`|}{[]:;?><,./-="; //Special characters included
  let password = "";
  let character = "";
  let entity1 = 0;
  let entity2 = 0;
  let entity3 = 0;
  let hold = ""; //Default varibale declaration
  //While loop to generate the password
  while (password.length < length) {
    entity1 = Math.ceil(string.length * Math.random() * Math.random());
    entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
    entity3 = Math.ceil(punctuation.length * Math.random() * Math.random());
    hold = string.charAt(entity1);
    hold = password.length % 2 === 0 ? hold.toUpperCase() : hold;
    character += hold;
    character += numeric.charAt(entity2);
    character += punctuation.charAt(entity3);
    password = character;
  }
  password = password
    .split("")
    .sort(function () {
      return 0.5 - Math.random();
    })
    .join("");
  return password.substring(0, length);
};

/**
 * Get Items Days on Hand
 * @param {*} recDate
 * @returns Days Number
 */
export const getDaysByRecievedDate = (recDate) => {
  let days = 0;
  if(recDate) {
    const currentDate = new Date();
    const recievedDate = new Date(recDate);
    const currentDateTime = currentDate?.getTime();
    const recievedDateTime = recievedDate?.getTime();
    const timeDiff = recievedDateTime <  currentDateTime ? currentDateTime - recievedDateTime : recievedDateTime - currentDateTime ;
    const dayCalc = 1000 * 60 * 60 * 24; //seconds*minute*hour*day
    if (isNaN(timeDiff)) return 0;
    days = Math.round(timeDiff / dayCalc)?.toFixed(0);
  }
  return days;
};

/**
 * get quantity, price, unit from package string array
 * @param {Array} packageStrings 
 * @param {String} sepuom 
 * @param {Number} basePrice 
 * @returns 
 */
export const getPriceOnUOM = (packageStrings, sepuom, basePrice=0) => {
  if (packageStrings?.length === 0) {
    return [1, basePrice, {}];
  } else {
    const packageUoM = packageStrings.find((n) => n.packageuom === sepuom);
    const hasPrice = packageStrings.find((n) => n.price !== undefined && n.quantity !== undefined && n.quantity && n.price);
    const hasQuantity = packageStrings.find((n) => n.quantity !== undefined &&  n.quantity);
    // console.log('packageUoM', packageUoM)
    // console.log('hasPrice', hasPrice)
    // console.log('hasQuantity', hasQuantity)
    let uomPrice, eachPrice;
    if (hasPrice) {
      const p = hasPrice;
      const price = p.price;
      if (p.baseuom === p.packageuom) {
        uomPrice = price / p.multiplier;
      } else {
        uomPrice = price * p.multiplier;
      }
      eachPrice = price / parseInt(p.quantity);
    } else {
      uomPrice = basePrice;
      eachPrice = basePrice;
    }

    const calcQuantity = (x) => {
      // console.log('uantitycalQ', x)
      if (hasQuantity) {
        const q = hasQuantity;
        return Math.round(parseInt(q.quantity) * q.multiplier / x);
      } else {
        return 1;
      }
    };

    // console.log('[getPriceOnUOM] packageStrings', packageStrings, 'packageUoM', packageUoM);
    if (packageUoM) {
      const n = packageUoM;
      const quantity = calcQuantity(n.multiplier);
      return [isNaN(quantity) ? 1 : quantity, uomPrice / n.multiplier, packageUoM];
    } else {
      return [1, eachPrice, {}];
    }
  }
}


export const getQuantityPriceOnUoM = (product, quantity=1, isUnit=false) => {
  const [q, p] = getPriceOnUOM(product?.units || [], product?.sepuom, product?.sepproductprice);
  console.log('getQuantityPriceOnUoM []', product, quantity);
  console.log('q []', q);
  console.log('p []', p);
  if (isUnit) {
    return [quantity / q, p * (quantity / q)];
  } else {
    return [q * quantity, p * quantity];
  }
}


/**
 * get QOO or QOH, calculate quantity based on UOM & Units
 * QOH: Quantity on Hand
 * QOO: Quantity on Order
 * @param {InventoryDataModel} inventorydetail
 * @param {Object} options
 * @returns {Number}
 */
export const getQuantityOnOrder = (c, options = {}) => {
  // console.log('[getQuantityOnOrder] c', c, "options", options);
  options = {
    iquantityunit: false, // To calculate iquantityunit
    isfloor: true, // Floor or Round Number
    isunit: false, // To get quantity value of unit
    isobject: false, // To get object value of unit
    ...options,
  };

  let iquantityunit,
    result,
    quantiyPerCaseBoxEach = 1,
    unit;
  const units = c.iproduct?.units;
  const sepuom = c.iproduct?.sepuom;
  if (units && units.length) {
    const [q, , u] = getPriceOnUOM(units, sepuom);
    // console.log("[getQuantityOnOrder] q", q, "u", u);
    quantiyPerCaseBoxEach = q;
    unit = u;
  }
  if (
    options.iquantityunit ||
    c.iquantityunit === undefined ||
    c.iquantityunit === null
  ) {
    // get iquantityunit from iquantity
    iquantityunit = Math.floor(Number(c.iquantity) / quantiyPerCaseBoxEach);
  } else iquantityunit = parseInt(c.iquantityunit);
  result = iquantityunit * quantiyPerCaseBoxEach;
  if (options.isunit) result = quantiyPerCaseBoxEach;
  else if (options.isobject) {
    result = unit ? { ...unit } : {};
    result.quantity = quantiyPerCaseBoxEach;
  } else if (!options.isfloor && result < Number(c.iquantity))
    result = result + quantiyPerCaseBoxEach;
  if(isNaN(result) && options.iquantityunit) {
    result = quantiyPerCaseBoxEach;
  }
  // console.log("[getQuantityOnOrder] iquantityunit", iquantityunit, "quantiyPerCaseBoxEach", quantiyPerCaseBoxEach, units);
  return result;
};

export const getQuantityOnOrderOld = (c, options = {}) => {
  options = {
    iquantityunit: false, // To calculate iquantityunit
    isfloor: true, // Floor or Round Number
    isunit: false, // To get quantity value of unit
    isobject: false, // To get object value of unit
    ...options,
  };
  // let iquantityunit = parseInt(c.iquantityunit || "1");
  let iquantityunit,
    result,
    quantiyPerCaseBoxEach = 1,
    unit;
  const units = c.iproduct?.units;
  const sepuom = c.iproduct?.sepuom;
  if (units && units.length) {
    unit = units.find(({ uom }) => uom === sepuom);
    if (unit?.uom === "Box") {
      quantiyPerCaseBoxEach = Number(unit.quantity);
    } else if (unit?.uom === "Case") {
      const boxesPerCase = units.find(({ uom }) => uom === "Box");
      quantiyPerCaseBoxEach =
        Number(boxesPerCase?.quantity) * Number(unit.quantity);
    }
  }
  if (
    options.iquantityunit ||
    c.iquantityunit === undefined ||
    c.iquantityunit === null
  ) {
    // get iquantityunit from iquantity
    iquantityunit = Math.floor(Number(c.iquantity) / quantiyPerCaseBoxEach);
  } else iquantityunit = parseInt(c.iquantityunit);
  result = iquantityunit * quantiyPerCaseBoxEach;
  if (options.isunit) result = quantiyPerCaseBoxEach;
  else if (options.isobject) {
    result = unit ? { ...unit } : {};
    result.quantity = quantiyPerCaseBoxEach;
  } else if (!options.isfloor && result < Number(c.iquantity))
    result = result + quantiyPerCaseBoxEach;
  console.log(
    "[getQuantityOnOrder] iquantityunit",
    iquantityunit,
    "quantiyPerCaseBoxEach",
    quantiyPerCaseBoxEach,
    units
  );
  return result;
};


/**
 * Parse CPT code
 * @param {*} cptString
 * @returns
 */
export const parseCptCode = (cptString) => {
  if (cptString) {
    const [cptCode, ...cptTextArray] = cptString.split(" ");
    const cptText = cptTextArray.join(" ");
    return { cptcode: cptCode, cpttext: cptString, cptname: cptText };
  }
};

/**
 * Check if passed string is zero or not
 * returns true
 */

export const isInputZero = (input) => {
  return /^0+(\.0+)?$/.test(input);
};

