import React from "react";
import { /* Link, */ Redirect, useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import Contracts from "../../components/Contracts/Contracts";
import VerticalContracts from "../../components/Contracts/VerticalContracts";
import VHContracts from "../../components/Contracts/VHContracts";
import { getState } from "../../store";


export default function PurchaseOrderDetail({
  allContracts = [],
}) {

  const history = useHistory();

  const contract = [getState().purchase.detail];
  console.log("[PurchaseOrderDetail]", contract);

  /**
   * For vertical contracts, this should be 'Vertical'.
   * For horizontal contracts, this should be 'Horizontal'.
   * For horizontal-vertical contracts, this should be 'VH'.
   */
  const verticalHorizontal = 'VH';


  return (
    <>
      {
        contract.length && contract[0] && 'contractId' in contract[0]
          ?
          <>
            <Button
              variant="contained"
              color="primary"
              size="small"
              // component={Link}
              // to="/app/purchaseorder"
              onClick={() => history.goBack()}
            >
              Back
        </Button>

            {
              verticalHorizontal === 'Vertical'
                ?
                <VerticalContracts
                  contracts={contract}
                  rows={[
                    ["PO ID", "payload.purchaseorderdata.popurchaseorderid"],
                    ["SE ID", "payload.purchaseorderdata.poseid"],
                    ["PO Number", "payload.purchaseorderdata.popurchaseorder"],
                    ["Reference Id", "payload.purchaseorderdata.poreferenceid"],
                    ["Expenditurecode", "payload.purchaseorderdata.poexpenditurecode"],
                    ["PO Date", "payload.purchaseorderdata.podate"],
                    ["PO Type", "payload.purchaseorderdata.popurchaseordertype"],
                    ["PO Vendor", "payload.purchaseorderdata.povendorid"],
                    ["PO Requisitioned By", "payload.purchaseorderdata.porequisitionedby"],
                    ["PO Promise Date", "payload.purchaseorderdata.popromisedate"],
                    ["PO Total Amount", "payload.purchaseorderdata.pototalamount"],
                    ["PO Freighthandeling", "payload.purchaseorderdata.pofreighthandeling"],
                    ["Status", "payload.purchaseorderdata.postatus"],
                    ["Comment", "payload.purchaseordercomment.0.note"],
                    ["Name", "payload.purchaseordercomment.0.user"],
                    ["Date", "payload.purchaseordercomment.0.date"],
                    ["Po Details", "payload.purchaseorderdetaildata"]
                  ]}
                  more={[
                    ["Po Detail", "payload.purchaseorderdetaildata", {
                      rows: [
                        ["Quantity", "podquantity"],
                        ["Reference Number", "podproduct.sepreferencenumber"],
                        ["Inventory Id", "podinventoryid"],
                        ["Product Description", "poddescription"],
                        ["Product Unit Price", "podunitprice"],
                        ["Reference", "podreference"],
                        ["Lotcode", "podproduct.seplotcode"],
                        ["Udi", "podproduct.sepudi"],
                        ["Ownership", "podproduct.sepownership"],
                        ["Product Side", "podproduct.sepproductside"],
                      ],
                    }],
                    ["Po Comments", "payload.purchaseordercomment", {
                      rows: [
                        ["Comment", "note"],
                        ["Name", "user"],
                        ["Date", "date"],
                      ],
                    }]
                  ]}
                  options={{ collapseFixed: true }}
                />
                :
                (
                  verticalHorizontal === 'VH'
                    ?
                    <VHContracts
                      contracts={contract}
                      rows={[
                        ["PO ID", "payload.purchaseorderdata.popurchaseorderid"],
                        ["SE ID", "payload.purchaseorderdata.poseid"],
                        ["PO Number", "payload.purchaseorderdata.popurchaseorder"],
                        ["Reference Id", "payload.purchaseorderdata.poreferenceid"],
                        ["Expenditurecode", "payload.purchaseorderdata.poexpenditurecode"],
                        ["PO Date", "payload.purchaseorderdata.podate"],
                        ["PO Type", "payload.purchaseorderdata.popurchaseordertype"],
                        ["PO Vendor", "payload.purchaseorderdata.povendorid"],
                        ["PO Requisitioned By", "payload.purchaseorderdata.porequisitionedby"],
                        ["PO Promise Date", "payload.purchaseorderdata.popromisedate"],
                        ["PO Total Amount", "payload.purchaseorderdata.pototalamount"],
                        ["PO Freighthandeling", "payload.purchaseorderdata.pofreighthandeling"],
                        ["Status", "payload.purchaseorderdata.postatus"],
                        ["Comment", "payload.purchaseordercomment.0.note"],
                        ["Name", "payload.purchaseordercomment.0.user"],
                        ["Date", "payload.purchaseordercomment.0.date"],
                        ["Po Details", "payload.purchaseorderdetaildata"]
                      ]}
                      more={[
                        ["Po Detail", "payload.purchaseorderdetaildata", {
                          columns: [
                            ["Quantity", "podquantity"],
                            ["Reference Number", "podproduct.sepreferencenumber"],
                            ["Inventory Id", "podinventoryid"],
                            ["Product Description", "poddescription"],
                            ["Product Unit Price", "podunitprice"],
                            ["Reference", "podreference"],
                            ["Lotcode", "podproduct.seplotcode"],
                            ["Udi", "podproduct.sepudi"],
                            ["Ownership", "podproduct.sepownership"],
                            ["Product Side", "podproduct.sepproductside"],
                          ],
                        }],
                        ["Po Comments", "payload.purchaseordercomment", {
                          columns: [
                            ["Comment", "note"],
                            ["Name", "user"],
                            ["Date", "date"],
                          ],
                        }]
                      ]}
                      options={{ collapseFixed: true }}
                    />
                    :
                    <Contracts
                      contracts={contract}
                      columns={[
                        ["PO ID", "payload.purchaseorderdata.popurchaseorderid"],
                        ["SE ID", "payload.purchaseorderdata.poseid"],
                        ["PO Number", "payload.purchaseorderdata.popurchaseorder"],
                        ["Reference Id", "payload.purchaseorderdata.poreferenceid"],
                        ["Expenditurecode", "payload.purchaseorderdata.poexpenditurecode"],
                        ["PO Date", "payload.purchaseorderdata.podate"],
                        ["PO Type", "payload.purchaseorderdata.popurchaseordertype"],
                        ["PO Vendor", "payload.purchaseorderdata.povendorid"],
                        ["PO Requisitioned By", "payload.purchaseorderdata.porequisitionedby"],
                        ["PO Promise Date", "payload.purchaseorderdata.popromisedate"],
                        ["PO Total Amount", "payload.purchaseorderdata.pototalamount"],
                        ["PO Freighthandeling", "payload.purchaseorderdata.pofreighthandeling"],
                        ["Status", "payload.purchaseorderdata.postatus"],
                        ["Comment", "payload.purchaseordercomment.0.note"],
                        ["Name", "payload.purchaseordercomment.0.user"],
                        ["Date", "payload.purchaseordercomment.0.date"],
                        ["Po Details", "payload.purchaseorderdetaildata", {
                          type: "object",
                          columns: [
                            ["Quantity", "podquantity"],
                            ["Reference Number", "podproduct.sepreferencenumber"],
                            ["Inventory Id", "podinventoryid"],
                            ["Product Description", "poddescription"],
                            ["Product Unit Price", "podunitprice"],
                            ["Reference", "podreference"],
                            ["Lotcode", "podproduct.seplotcode"],
                            ["Udi", "podproduct.sepudi"],
                            ["Ownership", "podproduct.sepownership"],
                            ["Product Side", "podproduct.sepproductside"],
                          ],
                        }]
                      ]}
                      options={{ collapseFixed: true , statusEmpty: (allContracts.length === 0)}}
                    />
                )
            }

          </>
          :
          <Redirect to="/app/purchaseorder-list" />
      }



    </>
  );
}
