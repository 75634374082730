import React, { useState } from "react";
import { Select, MenuItem, FormControl, InputLabel, List, ListItem, ListItemText } from "@material-ui/core";
import useStyles from "./styles";


export default function SelectSearch({
  handleChangeSearch = (v) => v,
  label = "",
  options = [],
  defaultValue = "",
  isStatic = false,
}) {

  const classes = useStyles();


  const [value, setValue] = useState(defaultValue);


  const getOptions = () => {
    return [<MenuItem key="All" value="All">All</MenuItem>].concat(options.map((each) => {
      return <MenuItem key={each.value} value={each.value}>{each.label}</MenuItem>
    }));
  };

  const getItems = () => {
    return [<ListItem key="All" button onClick={() => handleChange("All")} selected={value === "All"}>
              <ListItemText primary="All" />
            </ListItem>].concat(options.map((each) => {
      return <ListItem key={each.value} button onClick={() => handleChange(each.value)} selected={value === each.value}>
              <ListItemText primary={each.label} />
            </ListItem>
    }));
  };

  const handleChange = (val) => {
    // console.log("[SelectSearch] handleChange", val);
    setValue(val);
    if (val === "All") val = "";
    handleChangeSearch(val || "");
  };



  return (
    <>

      {
        !isStatic
        ?
          <FormControl 
            variant="outlined" 
            className={classes.dialogField}
          >
            <InputLabel>{label}</InputLabel>
            <Select
              className={classes.dialogField}
              label={label}
              variant="outlined"
              value={value}
              onChange={(e) => handleChange(e.target.value)}
            >
              {getOptions()}
            </Select>
          </FormControl>
        :
          <List
            component="nav"
            aria-labelledby="nested-list-selectsearch"
            className={classes.selectList}
          >
            {getItems()}
          </List>
      }


    </>
  );
}
