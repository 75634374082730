import { validateState } from "./State";

export const AddressModel = {
  adddresstype: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipcode: '',
  country: '',
  location: '',
  nickname: '',
};

export const validateAddress = (address) => {
  return ((address.address1 !== ""
    || address.address2 !== ""
    || address.city !== ""
    || address.zipcode !== ""
    || address.country !== ""
    || address.location !== ""
    || address.nickname !== ""
    || address.adddresstype !== "")
    && validateState(address.state));
};

export const getAddress = (address) => {
  let newAddress = {};
  newAddress.address1 = address.address1 || "";
  newAddress.address2 = address.address2 || "";
  newAddress.state = address.state || "";
  newAddress.city = address.city || "";
  newAddress.zipcode = address.zipcode || "";
  newAddress.country = address.country || "";
  newAddress.nickname = address.nickname || "";
  newAddress.adddresstype = address.adddresstype || "";
  if (typeof address.location === "string" && address.location !== "") newAddress.location = address.location;
  return newAddress;
};