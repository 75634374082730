import { API_IndexModel, validateAPI_Index, getAPI_Index, requiredAPI_Index } from "./API_Index";
import { API_InvoiceModel, validateAPI_Invoice, getAPI_Invoice, requiredAPI_Invoice,
  API_Invoice_AttachmentModel, validateAPI_Invoice_Attachment, getAPI_Invoice_Attachment, requiredAPI_Invoice_Attachment,
  API_Invoice_InstallmentModel, validateAPI_Invoice_Installment, getAPI_Invoice_Installment, requiredAPI_Invoice_Installment,
  API_Invoice_LinesModel, validateAPI_Invoice_Lines, getAPI_Invoice_Lines, requiredAPI_Invoice_Lines 
} from "./API_Invoice";
import { validateArray, getArray } from './handles';
// import { INDEX_ERP } from "../config";

export const HospitalTemplateModel = {
  api_index1: {
    ...API_IndexModel,
    // _index: INDEX_ERP.Inv_H,
    // _id: 'loci_inv'
  },
  api_invoice: {
    ...API_InvoiceModel
  },
  api_index2: {
    ...API_IndexModel,
    // _index: INDEX_ERP.InvAttach_H,
    // _id: 'loci_inv_attachment'
  },
  api_invoice_attachment: [
    { ...API_Invoice_AttachmentModel },
  ],
  api_index3: {
    ...API_IndexModel,
    // _index: INDEX_ERP.InvInstall_H,
    // _id: 'loci_inv_installment'
  },
  api_invoice_installments: [
    { ...API_Invoice_InstallmentModel },
  ],
  api_index4: {
    ...API_IndexModel,
    // _index: INDEX_ERP.InvLine_H,
    // _id: 'loci_inv_lines'
  },
  api_invoice_lines: [
    { ...API_Invoice_LinesModel },
  ],
};
export const validateHospitalTemplate = (hospitaltemplate) => {
  return ((hospitaltemplate.api_index1
    && validateAPI_Index(hospitaltemplate.api_index1))
    && (hospitaltemplate.api_invoice)
    && validateAPI_Invoice(hospitaltemplate.api_invoice)
    && (hospitaltemplate.api_index2)
    && validateAPI_Index(hospitaltemplate.api_index2)
    && validateArray(hospitaltemplate.api_invoice_attachment, validateAPI_Invoice_Attachment)
    && validateAPI_Index(hospitaltemplate.api_index3)
    && validateArray(hospitaltemplate.api_invoice_installments, validateAPI_Invoice_Installment)
    && validateAPI_Index(hospitaltemplate.api_index4)
    && validateArray(hospitaltemplate.api_invoice_lines, validateAPI_Invoice_Lines)
  );
};
export const getHospitalTemplate = (hospitaltemplate) => {
  let newHospitalTemplate = {};
  newHospitalTemplate.api_index1 = validateAPI_Index(hospitaltemplate.api_index1) ? getAPI_Index(hospitaltemplate.api_index1) : {...API_IndexModel};
  newHospitalTemplate.api_invoice = validateAPI_Invoice(hospitaltemplate.api_invoice) ? getAPI_Invoice(hospitaltemplate.api_invoice) : {...API_InvoiceModel};
  newHospitalTemplate.api_index2 = validateAPI_Index(hospitaltemplate.api_index2) ? getAPI_Index(hospitaltemplate.api_index2) : {...API_IndexModel};
  newHospitalTemplate.api_invoice_attachment = getArray(hospitaltemplate.api_invoice_attachment, validateAPI_Invoice_Attachment, getAPI_Invoice_Attachment);
  newHospitalTemplate.api_index3 = validateAPI_Index(hospitaltemplate.api_index3) ? getAPI_Index(hospitaltemplate.api_index3) : {...API_IndexModel};
  newHospitalTemplate.api_invoice_installments = getArray(hospitaltemplate.api_invoice_installments, validateAPI_Invoice_Installment, getAPI_Invoice_Installment);
  newHospitalTemplate.api_index4 = validateAPI_Index(hospitaltemplate.api_index4) ? getAPI_Index(hospitaltemplate.api_index4) : {...API_IndexModel};
  newHospitalTemplate.api_invoice_lines = getArray(hospitaltemplate.api_invoice_lines, validateAPI_Invoice_Lines, getAPI_Invoice_Lines);
  return newHospitalTemplate;
};
export const requiredHospitalTemplate = (hospitaltemplate) => ({
  'api_index1': requiredAPI_Index(hospitaltemplate.api_index1),
  'api_invoice': requiredAPI_Invoice(hospitaltemplate.api_invoice),
  'api_index2': requiredAPI_Index(hospitaltemplate.api_index2),
  'api_invoice_attachment': hospitaltemplate.api_invoice_attachment.map(requiredAPI_Invoice_Attachment),
  'api_index3': requiredAPI_Index(hospitaltemplate.api_index3),
  'api_invoice_installments': hospitaltemplate.api_invoice_installments.map(requiredAPI_Invoice_Installment),
  'api_index4': requiredAPI_Index(hospitaltemplate.api_index4),
  'api_invoice_lines': hospitaltemplate.api_invoice_lines.map(requiredAPI_Invoice_Lines),
});
