import React from "react";
import classNames from "classnames";
import { useParty } from "@daml/react";
import { Chip } from "@material-ui/core";
import { SurgicalComplianceHistory } from "../../services/daml-modules1";
import Contracts from "../../components/Contracts/Contracts1";
import { contractFilter1 } from "../../services/daml-filters";
import { Outcomes, RuleTypes, RuleTypeLevels } from "../../models/SurgicalEventCompliance";
import { useDamlStream } from "../../hooks/useDAML";
import useStyles from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";
import withFilters from "../../hoc/withFilters";
import { handleClickedContract } from '../surgicalevent/surgicaleventlistcontracts1';


function ComplianceListContracts({
  searchParams = {},
  isIncludeHistory = false,
  allContracts = [],
  handleDetail = null,
  setArchived = null,
  setSelectedComplianceID,
}) {


  const classes = useStyles();
  const hospital = useParty();


  const assetsHistory = useDamlStream(SurgicalComplianceHistory, [{ hospital }], isIncludeHistory);

  allContracts = allContracts.concat(isIncludeHistory && assetsHistory.contracts ?
    assetsHistory.contracts.map(h => ({ ...h, payload: h.payload.surgicaleventcompliance })) : [])
    .map(c => {
      if (c.payload.surgicaleventcompliancedata.ruletype === 'OffContractUsage')
        c.payload.surgicaleventcompliancedata.ruleoutcome = '';
      return c;
    });
  let contracts = contractFilter1(allContracts, searchParams);

  const getColorCode = (value) => {
    switch (value) {
      case 'Critical':
        return classes.backRed;
      case 'High':
        return classes.backOrange;
      case 'Medium':
        return classes.backLightYellow;
      default:
        return classes.backBlue;
    }
  }

  return (
    <>

      <Contracts
        className={classes.customRoot}
        contracts={contracts}
        columns={[

          ["SE Id", "payload.surgicaleventdata.seid", {
            type: "link",
            options: {
              width: 120,
              cellClassName: classNames(classes.primaryColor),
            },
            search: {
              type: "AdaptiveSearch",
              name: "surgicaleventdata.seid",
              getValue: (c) => c.payload.surgicaleventdata.seid,
              contracts: allContracts,
            },  
          }],
          ["Date", "payload.surgicaleventcompliancedata.date", {
            search: {
              type: "DateSearch",
              name: "surgicaleventcompliancedata.date",
            },
            options: {
              width: 160,
            },
          }],
          ["User", "payload.surgicaleventcompliancedata.user", {
            search: {
              type: "AdaptiveSearch",
              name: "surgicaleventcompliancedata.user",
              getValue: (c) => c.payload.surgicaleventcompliancedata.user,
              contracts: allContracts,
            },
          }],
          ["Role", "payload.surgicaleventcompliancedata.role"],
          ["Event Date", "payload.surgicaleventdata.eventdate", {
            search: {
              type: "DateSearch",
              name: "surgicaleventdata.eventdate",
            },
            options: {
              width: 150,
            },
          }],
          ["MRN", "payload.surgicaleventdata.mrn", {
            search: {
              type: "AdaptiveSearch",
              name: "surgicaleventdata.mrn",
              getValue: (c) => c.payload.surgicaleventdata.mrn,
              contracts: allContracts,
            },
            options: {
              width: 150,
            },
          }],
          ["Rule Type Level", "payload.surgicaleventcompliancedata.ruletypelevel", {
            type: "chip",
            className: (value) => getColorCode(value),
            search: {
              type: "SelectSearch",
              name: "surgicaleventcompliancedata.ruletypelevel",
              options: Object.keys(RuleTypeLevels).map(value => ({ label: RuleTypeLevels[value], value: value })),
            },
            options: {
              width: 170,
            },
          }],
          ["Rule Type", "payload.surgicaleventcompliancedata.ruletype", {
            type: "render",
            render: (param) => RuleTypes[param.value],    
            search: {
              type: "SelectSearch",
              name: "surgicaleventcompliancedata.ruletype",
              options: Object.keys(RuleTypes).map(value => ({ label: RuleTypes[value], value: value })),
              maxColCnt: 5,
            },
            options: {
              width: 140,
            },
          }],
          ["Rule Message", "payload.surgicaleventcompliancedata.rulemessage", {
            options: { 
              width: 300,
            },
          }],
          ["Rule Outcome", "payload.surgicaleventcompliancedata", {
            type: "render",
            render: (param) => (Outcomes[param.value.ruleoutcome] ? <Chip
              label={Outcomes[param.value.ruleoutcome]}
              size="small"
              className={classNames(classes.smallChip, (
                (param.value.ruleoutcome === 'Acknowledged') ? classes.backGreen : classes.backRed)
              )}
            /> : ''),
            search: {
              type: "SelectSearch",
              name: "surgicaleventcompliancedata.ruleoutcome",
              options: Object.keys(Outcomes).map(value => ({ label: Outcomes[value], value: value })),
            },
            options: {
              width: 150,
              headerAlign: 'center',
              align: 'center',
            },
          }],
        ]}
        options={{
          statusEmpty: (allContracts.length === 0),
          checkbox: true
        }}
        handleClicked={(e) => handleClickedContract(e, handleDetail)}
        handleArchived={setArchived}
        handleChecked={setSelectedComplianceID}
      />

    </>
  );
}

const ComplianceListContractsWithLoading = withLoading(ComplianceListContracts);
export default withFilters(ComplianceListContractsWithLoading)([
  { name: "surgicaleventdata.seid", label: "SE ID" },
  { name: "surgicaleventcompliancedata.date", label: "Date", type: "date" },
  { name: "surgicaleventcompliancedata.user", label: "User" },
  { name: "surgicaleventdata.eventdate", label: "Event Date", type: "date" },
  { name: "surgicaleventdata.mrn", label: "MRN" },
  { name: "surgicaleventcompliancedata.ruletypelevel", label: "Rule Type Level" },
  { name: "surgicaleventcompliancedata.ruletype", label: "Rule Type" },
  { name: "surgicaleventcompliancedata.ruleoutcome", label: "Rule Outcome" },
]);