import { HospitalRole, VendorRole } from "../../services/daml-modules1";
import { getTemplateListFromResponse } from "../../services/util";


/**
 * create PO
 * exercise HPPHSetPO choice of HospitalRole
 * @param {String} contractId 
 * @param {Object} payload 
 * @param {Object} ledger 
 * @param {Function} setPODatalist 
 * @param {Function} setConfirmModalOpen 
 * @param {Function} setConfirmDialogOpen 
 * @param {String} roleCid
 */
export async function createpo({
  contractId, payload, ledger, setPODatalist, setConfirmModalOpen, setConfirmDialogOpen, roleCid,
}) {

  let res = await ledger.exercise(HospitalRole.HPPHSetPO, roleCid, {
    pphcid: contractId,
  });
  console.log("[createpo]", res);

  let createdPOlist = getTemplateListFromResponse(res, "PurchaseOrder");

  if (typeof setConfirmDialogOpen === 'function') setConfirmDialogOpen(true);

  if (typeof setPODatalist === 'function') setPODatalist(createdPOlist.map(po => {
    return {
      "PO ID": po.purchaseorderdata.popurchaseorderid,
      "PO Number": po.purchaseorderdata.popurchaseorder,
      "Po Date": po.purchaseorderdata.podate,
      "Vendor": po.purchaseorderdata.povendorid,
      "Total Amount": po.purchaseorderdata.pototalamount,
      "Po Details": "Total: " + po.purchaseorderdetaildata.length,
    };
  }));

}

/**
 * create PostSurgeryForm
 * exercise HPPHReleaseVendor choice of HospitalRole
 * @param {String} contractId 
 * @param {Object} payload 
 * @param {Object} ledger 
 * @param {Function} setRvDatalist 
 * @param {Function} setConfirmRVDialogOpen 
 * @param {String} roleCid
 */
export async function createVendorPostSurgeryForm({
  contractId, payload, ledger, setRvDatalist = null, setConfirmRVDialogOpen = null, roleCid,
}) {
  console.log("[createVendorPostSurgeryForm] start PostSurgeryFromProcess", contractId, payload);

  let res = await ledger.exercise(HospitalRole.HPPHReleaseVendor, roleCid, {
    pphcid: contractId,
  });
  console.log("[createVendorPostSurgeryForm]", res);

  if (res[1] && typeof res[1] === "object" && res[1].length) {
    if (typeof setRvDatalist === "function") setRvDatalist(res[1].map(r => r['created']['payload']));
    if (typeof setConfirmRVDialogOpen === "function") setConfirmRVDialogOpen(true);
  }
}


/**
 * Archive PostProcedureHospital 
 * exercise HPPHArchive choice of HospitalRole
 * @param {String} contractId 
 * @param {Object} ledger 
 * @param {Object} payload 
 * @param {String} roleCid
 */
export const archivePPH = async ({
  contractId = ' ', ledger, payload, roleCid,
}) => {
  try {
    await ledger.exercise(HospitalRole.HPPHArchive, roleCid, {
      pphcid: contractId,
    });
  }
  catch (e) {
    console.log(e);
  }
}

/**
 * Archive PostProcedureVendor 
 * exercise VPPVArchive choice of VendorRole
 * @param {String} contractId 
 * @param {Object} payload 
 * @param {Object} ledger 
 * @param {String} roleCid
 */
export async function archivePPV({
  contractId = ' ', payload = ' ', ledger, roleCid,
}) {
  try {
    await ledger.exercise(VendorRole.VPPVArchive, roleCid, {
      ppvcid: contractId,
    });
  }
  catch (e) {
    console.log(e);
  }
}