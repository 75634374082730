import React, { useState } from "react";
import classNames from "classnames";
import { Grid, Table, TableBody, TableRow, TableCell, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import { AddedProductList } from "./add-edit-product1";
import patientIcon from "../../icons/new-se-patient-icon.svg";
import detailIcon from "../../icons/new-se-detail-icon.svg";
import physicianIcon from "../../icons/new-se-physician-icon.svg";
import avatarIcon from "../../icons/new-avatar-icon.svg";
import { useDamlState } from "../../context/DamlContext";
import { formatDate } from "../../services/util";
import { useSurgicalEvent, useHospitalUser } from "../../hooks/useProduct";
import useStyles from "./styles";
import PrefCardPickSetup from "./pickupsetup/prefPickSetup";
import PrefCaseUsage from "./caseusage/caseUsage";
import PrefDropDownView from "./prefphysiciansection/pref-dropdown-view"
import PrefListView from "./prefphysiciansection/pref-list"
import SnackbarModal from "../../components/Modals/SnackbarModal";

const PatientDetails = ({
  type, isColumnsType, colSpan,
}) => {
  return (
    <>
      <TableCell className="details" colSpan={colSpan}>
        {!isColumnsType && <img src={patientIcon} alt="" />}
        <Typography variant="inherit">Patient Details</Typography>
      </TableCell>
      {
        type === 'normal' && <TableCell style={{ width: '200px' }}></TableCell>
      }
    </>
  );
};

const EventDetails = ({
  isColumnsType, colSpan,
}) => {
  return (
    <>
      <TableCell className="details" colSpan={colSpan}>
        {!isColumnsType && <img src={detailIcon} alt="" />}
        <Typography variant="inherit">Event Details</Typography>
      </TableCell>
    </>
  );
};

const PhysicianDetails = ({
  isColumnsType, colSpan,
}) => {
  return (
    <>
      <TableCell className="details" colSpan={colSpan}>
        {!isColumnsType && <img src={physicianIcon} alt="" />}
        <Typography variant="inherit">Physician Details</Typography>
      </TableCell>
    </>
  );
};

/**
 * Overview Surgical Event
 * @param {String} type normal|narrow|twocolumns|threecolumns
 * @param {Object} className 
 * @param {Object} options 
 * @returns 
 */
export const OverviewSurgicalEvent = ({
  type = "normal", className, options = {},
}) => {

  options = {
    isPatient: true,
    ...options,
  };

  const classes = useStyles();
  const classNameList = [classes.newOverview];
  if (className) classNameList.push(className);

  const { surgicalevent: surgicalEventData } = useSurgicalEvent();
  const [openCardPick, setOpenCardPick] = useState(false);
  const [openCard, setOpenCard] = useState(false);
  const [detailData, setDetailData] = useState([]);

  const { rolePayload } = useDamlState();
  const { hospitaldetails } = (rolePayload ? rolePayload : {});
  const [openPickedToast, setOpenPickedToast] = useState(false);
  const isColumnsType = type.endsWith("columns");
  const detailsColSpan = (type === 'threecolumns' ? 1 : (type === 'twocolumns' || type === 'narrow' ? 2 : 6));

  const handlePrefCardPickSetup = (prefDetailData) => {
    setDetailData(prefDetailData)
    setOpenCardPick(true);
  };

  const handlePrefCardCaseUsage = (prefDetailData) => {
    setDetailData(prefDetailData)
    setOpenCard(true);
  };

  return (
    <>
      <Grid
        className={classNames(classNameList)}
      >
        <Table>
          <TableBody>
            {
              (type !== 'threecolumns' && options.isPatient) &&
              <TableRow>
                <PatientDetails
                  type={type}
                  isColumnsType={isColumnsType}
                  colSpan={detailsColSpan}
                />
              </TableRow>
            }
            {
              options.isPatient &&
              (
                type === 'narrow'
                  ?
                  <>
                    <TableRow>
                      <TableCell className="label">MRN :</TableCell>
                      <TableCell className="value">{surgicalEventData.mrn}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="label">Last Name :</TableCell>
                      <TableCell className="value">{surgicalEventData.patientlastname}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="label">First Name :</TableCell>
                      <TableCell className="value">{surgicalEventData.patientfirstname}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="label">Gender :</TableCell>
                      <TableCell className="value">{surgicalEventData.patientgender}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="label">DOB :</TableCell>
                      <TableCell className="value">
                        {surgicalEventData.patientdob && formatDate(surgicalEventData.patientdob)}
                      </TableCell>
                    </TableRow>
                  </>
                  :
                  (
                    isColumnsType
                      ?
                      <>
                        <TableRow className="details-row">
                          {
                            type === 'threecolumns' &&
                            <PatientDetails
                              type={type}
                              isColumnsType={isColumnsType}
                              colSpan={detailsColSpan}
                            />
                          }
                          <TableCell className="label">MRN :</TableCell>
                          <TableCell className="label">SE ID :</TableCell>
                        </TableRow>
                        <TableRow>
                          {type === 'threecolumns' && <TableCell></TableCell>}
                          <TableCell className="value">{surgicalEventData.mrn}</TableCell>
                          <TableCell className="value">{surgicalEventData.seid}</TableCell>
                        </TableRow>
                        <TableRow>
                          {type === 'threecolumns' && <TableCell></TableCell>}
                          <TableCell className="label">Last Name :</TableCell>
                          <TableCell className="label">First Name :</TableCell>
                        </TableRow>
                        <TableRow>
                          {type === 'threecolumns' && <TableCell></TableCell>}
                          <TableCell className="value">{surgicalEventData.patientlastname}</TableCell>
                          <TableCell className="value">{surgicalEventData.patientfirstname}</TableCell>
                        </TableRow>
                        <TableRow>
                          {type === 'threecolumns' && <TableCell></TableCell>}
                          <TableCell className="label">Gender :</TableCell>
                          <TableCell className="label">DOB :</TableCell>
                        </TableRow>
                        <TableRow>
                          {type === 'threecolumns' && <TableCell></TableCell>}
                          <TableCell className="value">{surgicalEventData.patientgender}</TableCell>
                          <TableCell className="value">
                            {surgicalEventData.patientdob && formatDate(surgicalEventData.patientdob)}
                          </TableCell>
                        </TableRow>
                      </>
                      :
                      <>
                        <TableRow>
                          <TableCell className="label">MRN :</TableCell>
                          <TableCell className="value">{surgicalEventData.mrn}</TableCell>
                          <TableCell className="label">SE ID :</TableCell>
                          <TableCell className="value">{surgicalEventData.seid}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="label">Last Name :</TableCell>
                          <TableCell className="value">{surgicalEventData.patientlastname}</TableCell>
                          <TableCell className="label">First Name :</TableCell>
                          <TableCell className="value">{surgicalEventData.patientfirstname}</TableCell>
                        </TableRow>
                        <TableRow className="border-row">
                          <TableCell className="label">Gender :</TableCell>
                          <TableCell className="value">{surgicalEventData.patientgender}</TableCell>
                          <TableCell className="label">DOB :</TableCell>
                          <TableCell className="value">
                            {surgicalEventData.patientdob && formatDate(surgicalEventData.patientdob)}
                          </TableCell>
                        </TableRow>
                      </>
                  )
              )
            }

            {
              type !== 'threecolumns' &&
              <TableRow>
                <EventDetails
                  isColumnsType={isColumnsType}
                  colSpan={detailsColSpan}
                />
              </TableRow>
            }
            {
              type === 'narrow'
                ?
                <>
                  <TableRow>
                    <TableCell className="label">Event Date :</TableCell>
                    <TableCell className="value">
                      {surgicalEventData.eventdate && formatDate(surgicalEventData.eventdate)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="label">Procedure :</TableCell>
                    <TableCell className="value">{surgicalEventData.surgicalprocedure}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="label">Side :</TableCell>
                    <TableCell className="value">{surgicalEventData.surgicalside}</TableCell>
                  </TableRow>
                </>
                :
                (
                  isColumnsType
                    ?
                    <>
                      <TableRow className="details-row">
                        {
                          type === 'threecolumns' &&
                          <EventDetails
                            isColumnsType={isColumnsType}
                            colSpan={detailsColSpan}
                          />
                        }
                        <TableCell className="label">Event Date :</TableCell>
                        <TableCell className="label">Side :</TableCell>
                      </TableRow>
                      <TableRow>
                        {type === 'threecolumns' && <TableCell></TableCell>}
                        <TableCell className="value">
                          {surgicalEventData.eventdate && formatDate(surgicalEventData.eventdate)}
                        </TableCell>
                        <TableCell className="value">{surgicalEventData.surgicalside}</TableCell>
                      </TableRow>
                      <TableRow>
                        {type === 'threecolumns' && <TableCell></TableCell>}
                        <TableCell className="label">Procedure :</TableCell>
                      </TableRow>
                      <TableRow>
                        {type === 'threecolumns' && <TableCell></TableCell>}
                        <TableCell className="myValue value" colSpan={2}>{surgicalEventData.surgicalprocedure}</TableCell>
                      </TableRow>
                    </>
                    :
                    <>
                      <TableRow className="border-row">
                        <TableCell className="label">Event Date :</TableCell>
                        <TableCell className="value">
                          {surgicalEventData.eventdate && formatDate(surgicalEventData.eventdate)}
                        </TableCell>
                        <TableCell className="label">Procedure :</TableCell>
                        <TableCell className="value">{surgicalEventData.surgicalprocedure}</TableCell>
                        <TableCell className="label">Side :</TableCell>
                        <TableCell className="value" colSpan={2}>{surgicalEventData.surgicalside}</TableCell>
                      </TableRow>
                    </>
                )
            }
            {hospitaldetails?.pref_facilityid ? <>
              {
                options.hidePhysicianDetails ? null :
                  <>
                    {
                      type !== 'threecolumns' &&
                      <TableRow>
                        <PhysicianDetails
                          isColumnsType={isColumnsType}
                          colSpan={detailsColSpan}
                        />
                      </TableRow>
                    }
                    {
                      type === 'narrow'
                        ?
                        <>
                          <TableRow>
                            <TableCell className="label">Physician Name:</TableCell>
                            <TableCell className="value">
                              {surgicalEventData.physicianfirstname} {surgicalEventData.physicianlastname} {surgicalEventData.physiciansalutation}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell className="label">Preference Card:</TableCell>
                            <PrefDropDownView
                              surgonid={surgicalEventData.surgonid}  />

                          </TableRow>
                          <TableRow>

                            <PrefListView
                              onPrefView={(detail) => handlePrefCardCaseUsage(detail)}
                            />
                          </TableRow>

                        </>
                        :
                        (
                          isColumnsType
                            ?
                            <>
                              <TableRow className="details-row">
                                {
                                  type === 'threecolumns' &&
                                  <PhysicianDetails
                                    isColumnsType={isColumnsType}
                                    colSpan={detailsColSpan}
                                  />
                                }
                                <TableCell className="label">Salutation :</TableCell>
                              </TableRow>
                              <TableRow>
                                {type === 'threecolumns' && <TableCell></TableCell>}
                                <TableCell className="value">{surgicalEventData.physiciansalutation}</TableCell>
                              </TableRow>
                              <TableRow>
                                {type === 'threecolumns' && <TableCell></TableCell>}
                                <TableCell className="label">Last Name :</TableCell>
                                <TableCell className="label">First Name :</TableCell>
                              </TableRow>
                              <TableRow>
                                {type === 'threecolumns' && <TableCell></TableCell>}
                                <TableCell className="value">{surgicalEventData.physicianlastname}</TableCell>
                                <TableCell className="value">{surgicalEventData.physicianfirstname}</TableCell>
                              </TableRow>
                            </>
                            :
                            <>
                              <TableRow>
                                <TableCell className="label">Physician Name:</TableCell>
                                <TableCell className="value">
                                  {surgicalEventData.physicianfirstname} {surgicalEventData.physicianlastname} {surgicalEventData.physiciansalutation}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="label">Preference Card:</TableCell>
                                <PrefDropDownView
                                  surgonid={surgicalEventData.surgonid} />
                              </TableRow>
                              <TableRow>
                                <PrefListView
                                  onPrefView={(detailData) => handlePrefCardPickSetup(detailData)}
                                />
                              </TableRow>
                            </>
                        )
                    }
                  </>
              }
            </>
              :
              <>
                {
                  options.hidePhysicianDetails ? null :
                    <>
                      {
                        type !== 'threecolumns' &&
                        <TableRow>
                          <PhysicianDetails
                            isColumnsType={isColumnsType}
                            colSpan={detailsColSpan}
                          />
                        </TableRow>
                      }
                      {
                        type === 'narrow'
                          ?
                          <>
                            <TableRow>
                              <TableCell className="label">Salutation :</TableCell>
                              <TableCell className="value">{surgicalEventData.physiciansalutation || ""}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="label">Last Name :</TableCell>
                              <TableCell className="value">{surgicalEventData.physicianlastname}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="label">First Name :</TableCell>
                              <TableCell className="value">{surgicalEventData.physicianfirstname}</TableCell>
                            </TableRow>
                          </>
                          :
                          (
                            isColumnsType
                              ?
                              <>
                                <TableRow className="details-row">
                                  {
                                    type === 'threecolumns' &&
                                    <PhysicianDetails
                                      isColumnsType={isColumnsType}
                                      colSpan={detailsColSpan}
                                    />
                                  }
                                  <TableCell className="label">Salutation :</TableCell>
                                </TableRow>
                                <TableRow>
                                  {type === 'threecolumns' && <TableCell></TableCell>}
                                  <TableCell className="value">{surgicalEventData.physiciansalutation}</TableCell>
                                </TableRow>
                                <TableRow>
                                  {type === 'threecolumns' && <TableCell></TableCell>}
                                  <TableCell className="label">Last Name :</TableCell>
                                  <TableCell className="label">First Name :</TableCell>
                                </TableRow>
                                <TableRow>
                                  {type === 'threecolumns' && <TableCell></TableCell>}
                                  <TableCell className="value">{surgicalEventData.physicianlastname}</TableCell>
                                  <TableCell className="value">{surgicalEventData.physicianfirstname}</TableCell>
                                </TableRow>
                              </>
                              :
                              <>
                                <TableRow>
                                  <TableCell className="label">Salutation :</TableCell>
                                  <TableCell className="value">{surgicalEventData.physiciansalutation}</TableCell>
                                  <TableCell className="label">Last Name :</TableCell>
                                  <TableCell className="value">{surgicalEventData.physicianlastname}</TableCell>
                                  <TableCell className="label">First Name :</TableCell>
                                  <TableCell className="value">{surgicalEventData.physicianfirstname}</TableCell>
                                </TableRow>
                              </>
                          )
                      }
                    </>
                }

              </>
            }
          </TableBody>
        </Table>
      </Grid>

      {
        openCardPick
          ?
          <PrefCardPickSetup
            open={openCardPick}
            setOpen={setOpenCardPick}
            detailData={detailData}
            setOpenPickedToast={setOpenPickedToast}
          />
          :
          null
      }
      {
        openCard
          ?
          <PrefCaseUsage
            open={openCard}
            setOpen={setOpenCard}
            detailData={detailData}
          />
          :
          null
      }
      <SnackbarModal
        open={openPickedToast}
        options={{
          delay: 500,
        }}
        setOpen={setOpenPickedToast}
        title=""
        content="All items have been picked"
        width={330}
        height={88}
        className={classes.toastMedium}
      />
    </>
  );
};

/**
 * Surgical Event Complete Panel Component
 * @param {Object} options 
 * @returns 
 */
export default function CompleteSurgicalEvent({
  options = {},
}) {

  options = {
    isEditable: true,
    isEnableEditProduct: true,
    isEnableDeleteProduct: true,
    ...options,
  };

  const classes = useStyles();
  const { party, userParty } = useDamlState();

  const { user: loginUser, setUser: setLoginUser } = useHospitalUser();

  const defaultUser = userParty ? userParty : party;


  // click checkbox to accept Terms and Condition
  const acceptTermsandCondtn = (e) => {
    if (!options.isEditable) return;
    if (e.target.checked === true) {
      setLoginUser(defaultUser);
    } else {
      setLoginUser("");
    }
  };


  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Grid item xs={5}>
        <OverviewSurgicalEvent
          type="narrow"
          className={classes.newOverviewcompletePanel}
        />
      </Grid>
      <Grid item xs={7}>
        <AddedProductList
          className={classNames(classes.completePanel, classes.editProductPanel1)}
          options={{
            isEnableCollapse: true,
            isEnableEditProduct: (options.isEditable && options.isEnableEditProduct),
            isEnableDeleteProduct: (options.isEditable && options.isEnableDeleteProduct),
          }}
        />
        <FormControlLabel
          control={<Checkbox color="primary" checked={(!!loginUser && loginUser !== '')} />}
          label="I certify that the added products correctly reflect the surgery performed."
          value="accept"
          className={classes.customCheckbox}
          onChange={acceptTermsandCondtn}
          labelPlacement="end"
        />
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.certifyUsername}
        >
          <Grid item>
            <img src={avatarIcon} alt="" />
          </Grid>
          <Grid item>
            <Typography>{loginUser}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}


