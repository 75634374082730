import React, { useState } from "react";
import { useParty, useStreamQueries } from "@daml/react";
import { Grid, Typography } from "@material-ui/core";
import { RestockInventory } from "../../services/daml-modules1";
import ScanInventoryOperatorContracts from "./inventoryscanoperatorcontracts";
import useStyles from "./styles";


/**
 * Restock Inventory Page for Operator
 * @returns 
 */
export default function ScanInventoryOperator() {

  const classes = useStyles();
  // const history = useHistory();
  // const scanRef = useRef();
  const operator = useParty();
  // const ledger = useLedger();
  // const { roleCid } = useDamlState();


  const { contracts } = useStreamQueries(RestockInventory, () => [{ operator }]);

  // console.log("[ScanInventoryOperator]", operator, roleCid);

  const [searchParams,] = useState({});
  const [searchParamsSurgical,] = useState({});
  const [loading,] = useState(true);

  const handleChangeSearchValue = (val, key) => {
  };


  return (
    <>

      <Grid>

        <Typography variant="h4" className={classes.pageTitle}>Restock Inventory by SEID</Typography>

        <ScanInventoryOperatorContracts
          searchParams={searchParams}
          handleChangeSearchValue={handleChangeSearchValue}
          allContracts={contracts}
          searchParamsSurgical={searchParamsSurgical}
          loading={loading}
        />

      </Grid>

    </>
  );
}