import { LogglyTracker } from 'loggly-jslogger';
import { LOGGLY_TOKEN } from '../config';

const logger = new LogglyTracker();

logger.push({ 'logglyKey': LOGGLY_TOKEN });

export default logger;

export const LOGGER_INFO = "info";
export const LOGGER_ERROR = "error";

/**
 * 
 * @param {String} type 
 * @param {String} action 
 * @param {Object} response 
 * @param {any} payload 
 * @param {String|Object} params 
 */
export function loggerPush({ type=LOGGER_INFO, action, response, payload, params }) {
    if (!action) return;
    if (type === LOGGER_INFO || type === LOGGER_ERROR) {
        let message = { action };
        if (response) message.response = response;
        if (payload) message.payload = payload;
        if (params) message.params = params;
        logger.push({ [type]: message });
    }
}
