import React, { useState, useEffect } from "react";
import {  Typography, Accordion,AccordionDetails,AccordionSummary,
  TableContainer, Paper, Badge, Chip, DialogContentText, Drawer,
} from "@material-ui/core";
import { ExpandMore } from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';
import { formatDate } from "../../services/util";
import { checkComplianceStatus, checkComplianceStatusExpiration, 
  checkComplianceStatusSide } from "../../models/SurgicalEventCompliance";
import ProductDetailViewTable from "../surgicalevent/product-detail-view-table";
import useStyles from "../surgicalevent/styles";


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/**
 * Detail View Slide Drawer 
 */
export default function DetailViewDrawer ({
  state, toggleDrawer, selectedEventData, productItemsList, isPPH=false,
}) {
  // console.log("[DetailViewDrawer]", selectedEventData, productItemsList);

  const classes = useStyles();

  const [expanded, setExpanded] = useState(0);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  useEffect(() => {
    if (state) {
      // console.log("[DetailViewDrawer] is opened.");
      setExpanded(0);
    }
  }, [state]);


  return (
    <React.Fragment  key={'right'} >
      <Drawer className={classes.customRoot} anchor={'right'} open={state} onClose={()=>{toggleDrawer(false)}}>           
        <div className="view-heading"><span>SE Id:</span> {selectedEventData && selectedEventData.seid}</div>

       <DialogContentText component={'div'} className="modelStepDetai">
          <div className="viewDetailsSe">

        {
          isPPH &&
          <>
          <h2 className={classes.formSubHeading}>Patient Details</h2>
           <div className="grid-list grid-list-5">
            <div className="list-item">
              <div className="label">MRN</div>
              <div className="value">{selectedEventData && selectedEventData.mrn}</div>
            </div>
            <div className="list-item">
              <div className="label">First Name</div>
              <div className="value">{selectedEventData && selectedEventData.patientfirstname}</div>
            </div>
            <div className="list-item">
              <div className="label">Last Name</div>
              <div className="value">{selectedEventData && selectedEventData.patientlastname}</div>
            </div>
            <div className="list-item">
              <div className="label">Gender</div>
              <div className="value">{selectedEventData && selectedEventData.patientgender}</div>
            </div>
            <div className="list-item">
              <div className="label">DOB</div>
              <div className="value">{selectedEventData && formatDate(selectedEventData.patientdob)}</div>
            </div>
           </div>
          </>
        }

        <h2 className={classes.formSubHeading}>Event Details</h2>
         <div className="grid-list grid-list-4">
          <div className="list-item">
            <div className="label">Date</div>
            <div className="value">{selectedEventData && formatDate(selectedEventData.eventdate)}</div>
          </div>
          <div className="list-item">
            <div className="label">Procedure</div>
            <div className="value">{selectedEventData && selectedEventData.surgicalprocedure}</div>
          </div>
          <div className="list-item">
            <div className="label">Side</div>
            <div className="value">{selectedEventData && selectedEventData.surgicalside}</div>
          </div>
          <div className="list-item">
            <div className="label">Sent Date</div>
            <div className="value">{selectedEventData && formatDate(selectedEventData.created_at)}</div>
          </div>
         </div>
        <h2 className={classes.formSubHeading}>Physician Details</h2>
         <div className="grid-list grid-list-4">
          <div className="list-item">
            <div className="label">Salutation</div>
            <div className="value">{selectedEventData && selectedEventData.physiciansalutation}</div>
          </div>
          <div className="list-item">
            <div className="label">First Name</div>
            <div className="value">{selectedEventData && selectedEventData.physicianfirstname}</div>
          </div>
          <div className="list-item">
            <div className="label">Last Name</div>
            <div className="value">{selectedEventData && selectedEventData.physicianlastname}</div>
          </div>
        </div>

        {productItemsList.length !== 0 && 
          <div> 
            <div className={classes.productDetails}>
              <h2 className={classes.formSubHeading}>{productItemsList.length > 1 ? 'Total Added Products' : 'Total Added Product'}  
                <Badge badgeContent={productItemsList.length} color="primary" overlap="rectangular"></Badge>
                <span className={classes.productTableHeading}><span className="price-label">Total Price of Products:</span> ${selectedEventData.price}</span>
              </h2>
            </div>
            
            <div className={classes.productAccordion}>
              {productItemsList.map((obj,index)=>(
                <Accordion 
                  key={index} 
                  expanded={expanded === (index+1)} 
                  onChange={handleChangeAccordion((index+1))} 
                  className={classes.productMainAccordion}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className={`product-detail-accordion-head ${obj.sepownership === 'Stock' ? 'yellow-accordion' : ''}`}
                  >
                    <Typography className={classes.accordionHeading}>
                      <span className="text-inactive">Reference Number :</span> <span className="text-active">{obj.sepreferencenumber}</span>
                    </Typography>
                    <Typography className={classes.accordionHeading}>
                      <span className="text-inactive">Lot Code :</span> <span className="text-active">{obj.seplotcode}</span> 
                    </Typography>
                    {
                      checkComplianceStatus({surgicalevent: selectedEventData, auditedproductdata: obj}) !== ''
                      && <Chip label="Compliance" className="bg-info-light" />
                    }
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography component={'div'}>
                      <TableContainer  className={classes.productTableContainer} component={Paper}>
                        {
                          checkComplianceStatusSide(selectedEventData, obj) &&  
                          <Alert severity="info"><strong>Compliance</strong>: Product is the incorrect Side based on the Surgical Procedure</Alert> 
                        }
                        {
                          checkComplianceStatusExpiration(selectedEventData, obj) &&  
                          <Alert severity="info"><strong>Compliance</strong>: Product is past expiration date</Alert> 
                        }
                        <ProductDetailViewTable 
                          obj={obj}
                          options={{
                            hiddenParams: (isPPH ? [] :['sepdistributor']), // removed 'sepdistributor' in vendor side
                            extraParams: (isPPH ? [] :[
                              {label: 'Hospital', value: selectedEventData.hospital}
                            ]), // added hospital in vendor side
                          }}
                        />
                      </TableContainer>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
                 
            </div> 
          </div>
        }
        </div>
        </DialogContentText>
      </Drawer>
    </React.Fragment>
  );
}
