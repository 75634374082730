import React, { useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import { useParty, useLedger } from "@daml/react";
import { AppBar, Tabs, Tab, Button, Typography, Grid } from '@material-ui/core';
import { checkEditableSE, compareSurgicalEvent } from "../../models/SurgicalEvent";
import { checkComplianceStatusExpiration, checkComplianceStatusSide } from "../../models/SurgicalEventCompliance";
import { convertprefData } from "../../models/PrefCard";
import { useDamlState } from "../../context/DamlContext";
import { useLayoutDispatch, setLoading, unsetLoading } from "../../context/LayoutContext";
import TabPanel, { a11yProps } from "../../components/Modals/MultiTabsModals/TabPanel";
import ModalDialog from '../../components/Modals/NewGeneralModal';
import SnackbarModal from "../../components/Modals/SnackbarModal";
import patientIcon from "../../icons/new-se-patient-icon.svg";
import {
  PRODUCT_CREATE_EVENT, PRODUCT_ADD_EVENT, PRODUCT_UPDATE_EVENT, PRODUCT_DELETE_EVENT, PRODUCT_COMPLETE_EVENT,
  EVENT_REQUIRED, EVENT_VERIFIED, EVENT_FINISHED, EVENT_REJECTED, PREF_DELETE_EVENT, PREF_LOAD_EVENT, PREF_ADD_EVENT, PREF_DELETE_ALL_EVENT
} from "../../store/actions/constants";
import useProduct, { useSurgicalEvent, useHospitalUser, useItemDetails } from "../../hooks/useProduct";
import useEvent from "../../hooks/useEvent";
import { ComplianceWarning, defaultProduct } from "./edit-product1";
import AddEditEvent from "./add-edit-eventform1";
import AddEditProduct, { searchPrefCardsContracts } from "./add-edit-product1";
import CompleteSurgicalEvent, { OverviewSurgicalEvent } from "./complete-surgicalevent1";
import { getCompleteMessage } from "./complete-dialog";
import {
  createSurgicalEvent, updateSurgicalEvent, handleRemoveProduct, handleUpdateProductData, handleAddNewProductData,
  confirmSurgicalEvent, completeSurgicalEvent, removePrefCard, addPrefCard
} from "./handle";
import useStyles from "./styles";
import { useUserState } from "../../context/UserContext";
import { useTranslation } from 'react-i18next';

/**
 * Surgical Event Title Component
 * @param {Object} classes 
 * @param {Number} tab 
 * @param {Boolean} isCreate 
 * @returns 
 */
const SETitle = ({
  classes, tab, isCreate,
}) => {

  const { surgicalevent: surgicalEventData } = useSurgicalEvent();

  const { seid, mrn, patientlastname, patientfirstname, patientdob } = surgicalEventData;

  return (
    <>
      {
        tab === 1
          ?
          (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              className={classes.newSETitlePanel}
            >
              <img src={patientIcon} alt="" />
              <Typography variant="inherit" component="span" className="name" >
                {patientfirstname} {patientlastname}
              </Typography>
              <Typography variant="inherit" component="span" className="label" >
                DOB:
              </Typography>
              <Typography variant="inherit" component="span" className="value" >
                {patientdob}
              </Typography>
              <Typography variant="inherit" component="span" className="label" >
                MRN:
              </Typography>
              <Typography variant="inherit" component="span" className="value" >
                {mrn}
              </Typography>
            </Grid>
          )
          :
          (
            (isCreate && tab === 0)
              ?
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={classes.newSETitlePanel}
              >
                <Typography variant="inherit" component="span" className="name" >
                  Create Surgical Event
                </Typography>
              </Grid>
              :
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.newSETitlePanel}
              >
                <img src={patientIcon} alt="" />
                <Typography variant="inherit" component="span" className="name" >
                  {patientfirstname} {patientlastname}
                </Typography>
                <Typography variant="inherit" component="span" className="label" >
                  SE ID:
                </Typography>
                <Typography variant="inherit" component="span" className="value" >
                  {seid}
                </Typography>
              </Grid>
          )
      }
    </>
  );
};

/**
 * Surgical Event Tab Panels Component
 * @param {Object} classes 
 * @param {Number} tab 
 * @param {Function} handleChangeTab 
 * @param {Boolean} isEditable 
 * @param {Boolean} isCreate 
 * @returns 
 */
const SETabPanels = ({
  classes, tab, handleChangeTab, isEditable = false, isCreate = false, 
}) => {

  const handleChangeValue = (event, newValue) => {
    if (typeof handleChangeTab === 'function') handleChangeTab(newValue);
  };
  const { onEventStart } = useEvent();
  const { surgicalevent: { seid } } = useSurgicalEvent();

  useEffect(() => {
    if (seid) {
      onEventStart(PREF_LOAD_EVENT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seid]);


  return (
    <>
      <AppBar position="static" className={classNames(classes.tabPanelMenu, classes.newTabPanelMenu)}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          // variant="fullWidth"
          value={tab}
          onChange={handleChangeValue}
        >
          <Tab label="Overview" {...a11yProps(0)} />
          <Tab label="Add Product" {...a11yProps(1)} />
          <Tab label="Complete Surgical Event" {...a11yProps(2)} />
        </Tabs>
      </AppBar>

      <TabPanel value={tab} index={0} className={classes.newTabPanelbody}>
        {
          isEditable
            ?
            <AddEditEvent
              options={{ isCreate }}
            />
            :
            <OverviewSurgicalEvent />
        }
      </TabPanel>

      <TabPanel value={tab} index={1} className={classNames(classes.newTabPanelbody, classes.newTabPanelbody2)}>
        <AddEditProduct
          options={{
            isEditable,
            edit: {
              isFetchInventory: false, // No need to update ownership, lotcode from AccountInventory
              isWarningExp: true,
            },
          }}
        />
      </TabPanel>

      <TabPanel value={tab} index={2} className={classNames(classes.newTabPanelbody)}>
        <CompleteSurgicalEvent
          options={{
            isEditable,
          }}
        />
      </TabPanel>
    </>
  );
};

/**
 * Surgical Event Action Buttons Component
 * @param {Object} classes 
 * @param {Number} tab 
 * @param {Function} handleChangeTab 
 * @param {Boolean} isEditable 
 * @returns 
 */
const SEActionButtons = ({
  classes, tab, handleChangeTab, isEditable = false, handleCloseRequest,
}) => {

  const { product: productItems } = useProduct();
  const { surgicalevent: surgicalEventData } = useSurgicalEvent();
  const { user: loginUser } = useHospitalUser();
  const { event, status, onEventStart, onEventClear } = useEvent();
  const { t } = useTranslation();
  const [btn, setBtn] = useState(null);
  const [open, setOpen] = useState(false);

  // handle to perform for each step
  const handlePerform = () => {
    if (!isEditable) return;
    if (tab === 0) {
      onEventStart(PRODUCT_CREATE_EVENT);
    }
    else if (tab === 1) {
      onEventStart(PRODUCT_ADD_EVENT);
    }
    else if (tab === 2) {
      handleConfirm();
    }
  };

  // handle to confirm before completing
  const handleConfirm = () => {
    if (!loginUser) {
      onEventClear();
      return;
    }

    // toast
    setOpen(true);
  };

  // handle to next
  const handleNext = () => {
    handlePerform();
    setBtn('next');
  };

  const handleSave = () => {
    handlePerform();
    handleCloseRequest(true);
  };

  useEffect(() => {
    if (btn && event) {
      if (btn === 'save') {
        // handle to close
        // handleChangeTab(-1, true);
      }
      else if (btn === 'next') {
        if ((status === EVENT_FINISHED || !status)) {
          // handle to go to next tab
          handleChangeTab(tab + 1);
          setBtn(null);
        }
        else if (status === EVENT_REJECTED) {
          // reject to remain current tab
          setBtn(null);
        }
      }
    }
  }, [btn, handleChangeTab, tab, event, status]);


  if (!isEditable) return <></>;
  return (
    <>
      {
        tab === 1 && checkComplianceStatusSide(surgicalEventData, productItems) &&
        <ComplianceWarning
          type="side"
          content={surgicalEventData.surgicalside}
          size="md"
          className={checkComplianceStatusExpiration(surgicalEventData, productItems) ? classNames(classes.complianceWarnings1) : classNames(classes.complianceWarnings3)}
        />
      }
      {
        tab === 1 && checkComplianceStatusExpiration(surgicalEventData, productItems) &&
        <ComplianceWarning
          type="expiration"
          size="md"
          className={classNames(classes.complianceWarnings2)}
        />
      }
      {tab < 2 &&
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSave}
          className={classNames(classes.newCompleteBtn, "secondary-color")}
        >
          Save
        </Button>
      }
      <Button
        variant="contained"
        color="primary"
        onClick={handleNext}
        className={classes.newCompleteBtn}
      >
        {tab === 2 ? 'Complete' : 'Next'}
      </Button>

      <SnackbarModal
        open={open}
        setOpen={setOpen}
        type="warning"
        content={t('warnings.surgicalevent.completesurgicalevent')}
        vertical="center"
        horizontal="center"
        width={520}
        height={160}
        className={classes.toastSmall}
        transition="fade"
        options={{
          validateULE: true,
           }}
        actions={[
          {
            label: 'Acknowledge',
            handle: () => onEventStart(PRODUCT_COMPLETE_EVENT),
          },
          {
            label: 'Decline',
          }
        ]}
      />
    </>
  );
};

/**
 * New Create or Edit SurgicalEvent Dialog
 * @param {Boolean} open
 * @param {Function} setOpen
 * @param {Object} contract
 * @param {Boolean} isEdit
 */
export default function CreateEditSurgicalEvent({
  open, setOpen, contract = {}, isEdit = false,
}) {

  const classes = useStyles();
  const { t } = useTranslation();
  const { contractId, payload } = contract;
  const { role, party, userParty, roleCid, rolePayload: { admindetails }, userlistPayload } = useDamlState();
  const defaultUser = userParty ? userParty : party;
  const layoutDispatch = useLayoutDispatch();
  const { token } = useUserState();
  const hospital = useParty();
  const ledger = useLedger();
  const { product: productItems, clearProduct, setAll: setDefault } = useProduct();
  const { product: productEditItems } = useProduct(true);
  const { surgicalevent: surgicalEventData, setSurgicalEvent: setSurgicalEventData, prefdata, isChanged, 
    setPrefData, setPrefDataLoad } = useSurgicalEvent();
  const { user: loginUser } = useHospitalUser();
  const { details, itemIndex, setDetails: setDetailItemsList } = useItemDetails();
  const { event, status, data, onEventRun, onEventEnd, onEventClear } = useEvent();

  // Create|Edit
  const isCreate = !contractId;
  // Editable
  const [isEditable, setEditable] = useState(false);

  const [tab, setTab] = useState(0);

  // ContractId is contractId of SurgicalEvent
  const [ContractId, setContractId] = useState(null);
  // Old surgicaleventdata of SurgicalEvent
  const [oldSEdata, setOldSEdata] = useState({});
  // Toast
  const [openToast, setOpenToast] = useState(false);
  const [snackbarModel, setSnackbarModel] = useState(false);
  const [toastContent, setToastContent] = useState('');
  const [completeErrorModel, setCompleteErrorModel] = useState(false);

  // handle to change tab
  const handleChangeTab = (val, isClose = false) => {
    if (val >= 0 && val < 3) {
      // check if the tab is possible while creating
      if (isCreate) {
        if (!ContractId && val > 0) return;
      }
      setTab(val);
    }
    else if (isClose) setOpen(false);
  };

  const handleCloseRequest = (isSaved = false) => {
    // console.log("[CreateEditSurgicalEvent] handleCloseRequest", isEditable, isSaved, isChanged);
    if (isEditable && !isSaved && isChanged) {
      setSnackbarModel(true);
    }
    else {
      handleClose();
    }
  }

  // handle to close
  const handleClose = () => {
    setSnackbarModel(false);
    setCompleteErrorModel(false);
    setOpen(false);
    // clear
    setDefault({});
  };


  // create or update Surgical Event
  const handleCreateUpdateSE = useCallback(async () => {
    if (!isEditable) return;
    if (!!ContractId) {
      if (!compareSurgicalEvent(oldSEdata, surgicalEventData, true)) {
        console.log("[CreateEditSurgicalEvent] update Surgical Event");
        console.log(surgicalEventData);

        // spinner
        setLoading(layoutDispatch);
        onEventRun();

        // update
        const { contractId, ...payload } = await updateSurgicalEvent({
          surgicalEvent: surgicalEventData,
          ledger,
          hospital,
          ContractId,
          roleCid
        });

        if (contractId) {
          setContractId(contractId);
          setSurgicalEventData(payload.surgicaleventdata);
          setOldSEdata(payload.surgicaleventdata);

          // toast
          setToastContent('Surgical Event Data is updated successfully');
          setOpenToast(true);
        }
      }
    }
    else {
      console.log("[CreateEditSurgicalEvent] create Surgical Event");
      console.log(surgicalEventData);

      // spinner
      setLoading(layoutDispatch);
      onEventRun();

      // create
      const { contractId, ...payload } = await createSurgicalEvent({
        surgicalEvent: surgicalEventData,
        ledger,
        hospital,
        ContractId,
        roleCid,
        prefdata: convertprefData(prefdata)
      });

      if (contractId) {
        setSurgicalEventData(payload.surgicaleventdata);
        setContractId(contractId);
        setOldSEdata(payload.surgicaleventdata);
        // toast
        setToastContent('Surgical Event Data is created successfully');
        setOpenToast(true);
      }
    }

    unsetLoading(layoutDispatch);
    onEventEnd();

  }, [ContractId, hospital, isEditable, layoutDispatch, ledger, onEventRun, onEventEnd, oldSEdata,
    roleCid, setSurgicalEventData, surgicalEventData, prefdata]);

  // add new Product
  const addProduct = useCallback(async () => {
    if (!isEditable) return;
    console.log("[CreateEditSurgicalEvent] add a Product", productItems);

    // spinner
    setLoading(layoutDispatch);
    onEventRun();

    // add a Product
    const { contractId, ...payload } = await handleAddNewProductData({
      newProductData: productItems,
      ledger,
      ContractId,
      name: defaultUser,
      role: role,
      expirationdate: productItems.acknowledge,
      surgicalside: productItems.acknowledgeProduct,
      roleCid,
    });

    unsetLoading(layoutDispatch);
    onEventEnd();

    if (contractId) {
      // after add a Product
      setContractId(contractId);
      setDetailItemsList(payload.surgicaleventproductdata);

      // toast
      setToastContent('Product added successfully');
      setOpenToast(true);
    }

    // default Waste, default Side
    clearProduct(defaultProduct);

  }, [ContractId, clearProduct, defaultUser, isEditable, layoutDispatch, productItems,
    ledger, onEventRun, onEventEnd, role, roleCid, setDetailItemsList]);

  // delete old Product
  const handleDeleteProduct = useCallback(async () => {
    if (!isEditable) return;

    const removeProduct = details[itemIndex];
    console.log("[CreateEditSurgicalEvent] remove a Product", removeProduct, itemIndex);

    // spinner
    setLoading(layoutDispatch);
    onEventRun();

    const { contractId, ...payload } = await handleRemoveProduct({
      removeproductdata: removeProduct,
      ledger,
      ContractId,
      name: defaultUser,
      role: role,
      roleCid,
      productindex: itemIndex,
    });

    unsetLoading(layoutDispatch);
    onEventEnd();

    // remove a Product
    if (contractId) {
      // after remove a Product
      setContractId(contractId);
      setDetailItemsList(payload.surgicaleventproductdata);
    }

  }, [ContractId, defaultUser, isEditable, layoutDispatch, ledger, onEventRun, onEventEnd, role, details, itemIndex,
    roleCid, setDetailItemsList]);

  // update old Product
  const handleUpdateProduct = useCallback(async () => {
    if (!isEditable) return;

    const updateDetail = details[itemIndex];
    const updateProduct = productEditItems;
    const updateIndex = itemIndex;
    console.log("[CreateEditSurgicalEvent] update a Product", updateDetail, updateProduct, updateIndex);
    if (!updateProduct || !updateDetail) return;

    // spinner
    setLoading(layoutDispatch);
    onEventRun();

    // update a Product
    const { contractId, ...payload } = await handleUpdateProductData({
      updateProductData: { ...updateDetail, product: updateProduct },
      ledger,
      ContractId,
      name: defaultUser,
      role: role,
      expirationdate: updateProduct.acknowledge,
      surgicalside: updateProduct.acknowledgeProduct,
      roleCid,
      productindex: updateIndex,
    });

    unsetLoading(layoutDispatch);
    onEventEnd();

    if (contractId) {
      // console.log("[CreateEditSurgicalEvent] handleUpdateProduct", resUpdate);
      setContractId(contractId);
      setDetailItemsList(payload.surgicaleventproductdata);
    }

  }, [ContractId, defaultUser, isEditable, layoutDispatch, ledger, onEventRun, onEventEnd,
    details, itemIndex, productEditItems, role, roleCid, setDetailItemsList]);

  // finish all edits
  const handleCompleteSE = useCallback(async () => {
    const email = userlistPayload?.email;
    const username = userlistPayload?.name;
    if (!isEditable || !loginUser) return;

    // spinner
    setLoading(layoutDispatch);
    onEventRun();

    let errorDetected = false;

    // confirm SurgicalEvent
    const resCon = await confirmSurgicalEvent({
      ledger,
      name: loginUser,
      ContractId,
      roleCid,
    });
    if (!resCon) errorDetected = true;
    else {

      // after confirm SurgicalEvent
      // complete SurgicalEvent with certifcate
      const resCom = await completeSurgicalEvent({
        contractId: ContractId,
        ledger,
        isCertificate: true,
        roleCid,
        name: loginUser,
        role: role,
        email: email,
        username: username,
      });
      if (!resCom) errorDetected = true;
      else {

        const [res, resTemplate] = resCom;
        const { contractId, ...payload } = resTemplate;

        // after complete SurgicalEvent, open Complete Dialog
        const result = getCompleteMessage(res, (admindetails ? admindetails.auto_po_vendor : false));
        console.log("[CreateEditSurgicalEvent] handleNextStep", result);

        if (contractId) {
          setContractId(contractId);
          setSurgicalEventData(payload.surgicaleventdata);
          // toast
          setToastContent(result.content);
          setOpenToast(true);
        }
        setOpen(false);
      }
    }

    if (errorDetected) {
      // error when confirming SE or completing SE
      // toast
      setCompleteErrorModel(true);
    }

    unsetLoading(layoutDispatch);
    onEventEnd();

  }, [ContractId, admindetails, isEditable, layoutDispatch, ledger, loginUser, setSurgicalEventData,
    onEventRun, onEventEnd, role, roleCid, userlistPayload, setOpen]);

  // delete old Prefcard
  const handleDeletePref = useCallback(async () => {

    // spinner
    setLoading(layoutDispatch);
    onEventRun();

    const res = await removePrefCard({
      ledger,
      contractid: data,
      roleCid,
    });
    if (res) {
      // toast
      setToastContent('Preference Card has been deleted.');
      setOpenToast(true);
    }
    unsetLoading(layoutDispatch);
    onEventEnd();

  }, [data, layoutDispatch, ledger, onEventRun, onEventEnd,
    roleCid]);


  // delete All old Prefcard
  const handleDeleteAllPrefCards = useCallback(async () => {
    // spinner
    onEventRun();
    setLoading(layoutDispatch);
    if (prefdata.length > 0) {
      let res = true;
      for (let index = 0; index < prefdata.length; index++) {
        const currentValue = prefdata[index];
        if (currentValue.contractid) {
          const _res = await removePrefCard({
            ledger,
            contractid: currentValue.contractid,
            roleCid,
          });
          res = res && _res;
        }
      }

      if (res) {
        // toast
        setPrefData([])
        setToastContent('Preference Card has been deleted.');
        setOpenToast(true);
      }
    }
    unsetLoading(layoutDispatch);
    onEventEnd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefdata, layoutDispatch, ledger, onEventRun, onEventEnd,
    roleCid]);


  // Reload Prefcard
  const handleLoadPref = useCallback(async () => {
    // spinner
    onEventRun();
    setLoading(layoutDispatch);
    const newprefdata = await searchPrefCardsContracts({
      seid: surgicalEventData.seid,
      token,
      hospital,
    });
    setPrefDataLoad(newprefdata);
    unsetLoading(layoutDispatch);
    onEventEnd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surgicalEventData.seid, token, hospital, layoutDispatch, onEventRun, onEventEnd]);


  // Add Prefcard
  const handleAddPref = useCallback(async () => {
    // spinner
    onEventRun();
    if (surgicalEventData.seid) {
      setLoading(layoutDispatch);
      const res = await addPrefCard({
        seid: surgicalEventData.seid,
        ledger,
        roleCid,
        prefData: convertprefData([data])
      });
      unsetLoading(layoutDispatch);
      if (res) {
        handleLoadPref()
      }
    } else {
      setPrefData([...prefdata, data])
    }
    onEventEnd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, surgicalEventData.seid, ledger, roleCid, layoutDispatch, onEventRun, onEventEnd, handleLoadPref]);


  useEffect(() => {
    if (open) {
      // open dialog
      console.log("[CreateEditSurgicalEvent] is opened.");
      // initial tab
      let initTab = 0;
      if (!isEditable) initTab = 2;
      setTab(initTab);
      onEventClear();
    }
  }, [open, onEventClear, isEditable]);

  useEffect(() => {
    console.log("[CreateEditSurgicalEvent] set default data.");
    if (!open) return;
    if (payload) {
      setDefault({
        surgicalevent: payload.surgicaleventdata,
        details: payload.surgicaleventproductdata,
        hospital,
        user: defaultUser, // always checked as default user
        product: defaultProduct,
      });
      setOldSEdata(payload.surgicaleventdata);
      setContractId(contractId);
    }
    else {
      setDefault({
        surgicalevent: {},
        details: [],
        hospital,
        user: defaultUser, // always checked as default user
        product: defaultProduct,
      });
      setOldSEdata({});
      setContractId(null);
    }
  }, [payload, setDefault, hospital, open, defaultUser, contractId]);

  useEffect(() => {
    const isNewEditable = checkEditableSE({ payload: { surgicaleventdata: surgicalEventData } });
    setEditable(!ContractId || isNewEditable);
  }, [ContractId, surgicalEventData]);

  useEffect(() => {
    if (event) {
      if (status === EVENT_REQUIRED || status === EVENT_VERIFIED) {
        if (isEditable) {
          console.log(" ", event, status);
          if (event === PRODUCT_CREATE_EVENT && status === EVENT_VERIFIED) {
            handleCreateUpdateSE();
          }
          else if (event === PRODUCT_ADD_EVENT && status === EVENT_VERIFIED) {
            addProduct();
          }
          else if (event === PRODUCT_UPDATE_EVENT && status === EVENT_VERIFIED) {
            handleUpdateProduct();
          }
          else if (event === PRODUCT_DELETE_EVENT && status === EVENT_REQUIRED) {
            handleDeleteProduct();
          }
          else if (event === PRODUCT_COMPLETE_EVENT && status === EVENT_REQUIRED) {
            handleCompleteSE();
          }
        }
        if (event === PREF_LOAD_EVENT && status === EVENT_REQUIRED) {
          handleLoadPref();
        }
        else if (event === PREF_ADD_EVENT && status === EVENT_REQUIRED) {
          handleAddPref();
        }
        else if (event === PREF_DELETE_ALL_EVENT && status === EVENT_REQUIRED) {
          handleDeleteAllPrefCards();
        }
        else if (event === PREF_DELETE_EVENT && status === EVENT_REQUIRED) {
          handleDeletePref();
        }

      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, status, handleCreateUpdateSE, addProduct, handleDeleteProduct,
    handleUpdateProduct, handleCompleteSE, handleDeletePref, handleLoadPref, handleAddPref, isEditable]);


  return (
    <>
      <ModalDialog
        open={open}
        handleCloseRequest={handleCloseRequest}
        setOpen={setOpen}
        height={660}
        title={(
          <SETitle
            classes={classes}
            tab={tab}
            isCreate={isCreate}
          />
        )}
        content={(
          <SETabPanels
            classes={classes}
            tab={tab}
            handleChangeTab={handleChangeTab}
            isEditable={isEdit && isEditable}
            isCreate={isCreate}
          />
        )}
        actions={(
          <SEActionButtons
            classes={classes}
            tab={tab}
            handleChangeTab={handleChangeTab}
            isEditable={isEdit && isEditable}
            handleCloseRequest={handleCloseRequest}
          />
        )}
      />

      <SnackbarModal
        open={openToast}
        setOpen={setOpenToast}
        title=""
        content={toastContent}
        width={330}
        height={88}
        className={classes.toastMedium}
      />

      <SnackbarModal
        open={snackbarModel}
        setOpen={setSnackbarModel}
        type="warning"
        title={'Warning'}
        content={t('warnings.surgicalevent.exitmessage')}
        vertical="center"
        horizontal="center"
        width={345}
        height={130}
        transition="fade"
        className={classNames(classes.toastLarge, classes.toastWarning)}
        options={{
          delay: 500,
          validateULE: true,
        }}
        actions={[
          {
            label: 'Yes',
            handle: () => {
              handleClose();
            },
          },
          {
            label: 'No',
            handle: () => {
              setSnackbarModel(false);
            },
          }
        ]}
      />

      <SnackbarModal
        open={completeErrorModel}
        setOpen={setCompleteErrorModel}
        type="warning"
        title={'Warning'}
        content={'The Surgical Event cannot be completed as information has recently changed'} // This transaction is not valid.
        vertical="center"
        horizontal="center"
        width={500}
        height={130}
        transition="fade"
        className={classNames(classes.toastLarge, classes.toastWarning)}
        options={{
          delay: 0,
        }}
        actions={[
          {
            label: 'Yes',
            handle: () => {
              handleClose();
            },
          },
        ]}
      />
    </>
  );
}