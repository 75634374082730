import React, { useState } from "react";
import { useLedger } from "@daml/react";
import { Grid } from "@material-ui/core";
import { contractFilter1 } from "../../services/daml-filters";
import { getTemplate } from "../../services/util";
import { sendInvoice, ArchiveInvoice, setUpdateInv } from "./handle";
import ModalDialogBox from '../../components/Modals/GeneralModal/modalDialogBox';
import Contracts from "../../components/Contracts/Contracts1";
import DetailViewDrawer from "./detailviewdrawer";
import DetailViewDrawerPO from "./detailviewdrawerpo";
import { useDamlState } from "../../context/DamlContext";
import useStyles from "./styles";
import useStyles1 from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";
import withFilters from "../../hoc/withFilters";
import UpdateInvoice from "./updateInvoice";
import SnackbarModal from "../../components/Modals/SnackbarModal";

/**
 * InvoiceList Contracts New
 * @param {Object} searchParams 
 * @param {Boolean} isIncludeHistory 
 * @param {Array} allContracts 
 * @param {Array} historyContracts 
 * @param {Function} setArchived
 * @param {Function} setChecked
 * @param {Object} createOptions
 * @returns 
 */
function InvoiceListContracts({
  searchParams = {},
  isIncludeHistory = false,
  allContracts = [],
  historyContracts = [],
  setArchived = null,
  setChecked = null,
  createOptions,
}) {

  allContracts = allContracts.concat(isIncludeHistory && historyContracts.length ?
    historyContracts.map(h => ({ ...h, payload: h.payload.invoice })) : []);
  let contracts = contractFilter1(allContracts, searchParams);
  contracts = contracts
    .map(c => {
      c.payload.invoicedata.totalitemscount = c.payload.invoicedetail.reduce((s, invd) => (s + parseInt(invd.invdproductquantity)), 0);
      return c;
    });

  const classes = useStyles();
  const classes1 = useStyles1();
  const ledger = useLedger();
  const { roleCid, userlistPayload } = useDamlState();


  const [openModal, setOpenModal] = useState(false);
  const [sendModalContract, setSendModalContract] = useState({});
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [archiveModalContract, setArchiveModalContract] = useState({});
  const [confirmToast, setconfirmToast] = useState(false);

  const [contractId, setContractId] = useState();
  const [payload, setPayload] = useState();

  // Click "Edit Invoice" in list
  const handleEditInvoice = (invoice) => {
    console.log("[invoicelistcontracts] invoice", invoice);
    let { contractId, payload } = invoice
    setContractId(contractId);
    setPayload(payload)
    setOpenModal(true);
  };

  const checkNotInvHistory = (c) => (getTemplate(c) !== "InvoiceHistory");

  // DetailViewDrawer
  const [invoiceData, setInvoiceData] = useState({});
  const [invoiceList, setInvoiceList] = useState([]);
  const [detailViewer, setDetailViewer] = useState(false);
  // DetailViewDrawer for Invoice detail
  const [invoice, setInvoice] = useState({});
  const [detailViewerInvoice, setDetailViewerInvoice] = useState(false);

  const openSendInv = (c) => {
    setSendModalContract(c);
    setconfirmToast(true);
  };


  const cancelsendInv = () => {
    setconfirmToast(false)
  }

  const handleSendInv = async () => {
    await sendInvoice({
      ledger,
      cid: sendModalContract.contractId,
      payload: sendModalContract.payload,
      roleCid,
      userlistPayload,
    });
  };

  const openArchiveInv = (c) => {
    setArchiveModalContract(c);
    setArchiveModalOpen(true);
  };

  const handleArchiveInv = async () => {
    await ArchiveInvoice({
      ledger,
      cid: archiveModalContract.contractId,
      payload: archiveModalContract.payload,
      roleCid,
    });
    setArchiveModalOpen(false);
  };

  const getColorCode = (value) => {
    switch (value) {
      case 'Sent':
        return "#4CAF50";
      case 'Ready for payment':
        return "#2196F3";
      case 'Complete':
        return "#4CAF50";
      case 'Cancelled':
        return "#F95A05";
      default:
        return "#FF9800";
    }
  }
  return (
    <>
      <Contracts
        className={classes1.customRoot}
        contracts={contracts}
        columns={[
          ["Invoice Number", "payload.invoicedata.invinvoicenumber", {
            type: "link",
            onClick: (contractId, contract) => {
              const clickedContractId = (!!contractId) ? contractId : contract.contractId;
              const data = contracts.find(c => c.contractId === clickedContractId);
              console.log("[invoicedetail] data", data);
              setInvoice(data.payload);
              setDetailViewerInvoice(true);
            },
            options: {
              width: 180,
            },
            search: {
              type: "AdaptiveSearch",
              name: "invoicedata.invinvoicenumber",
              getValue: (c) => c.payload.invoicedata.invinvoicenumber,
              contracts: allContracts,
            },
          }],
          ["Purchase Order", "payload.invoicedata.invpurchaseorderid", {
            type: "link",
            onClick: (contractId, contract) => {
              const clickedContractId = (!!contractId) ? contractId : contract.contractId;
              const data = contracts.find(c => c.contractId === clickedContractId);
              console.log("[invoicedetail] data", data);
              setInvoiceData({
                ...data.payload.invoicedata,
                is_sent_hospital: data.payload.is_sent_hospital,
                hospital: data.payload.hospital,
              });
              setInvoiceList(data.payload.invoicedetail);
              setDetailViewer(true);
            },
            options: {
              width: 180,
            },
            search: {
              type: "AdaptiveSearch",
              name: "invoicedata.invpurchaseorderid",
              getValue: (c) => c.payload.invoicedata.invpurchaseorderid,
              contracts: allContracts,
            },
          }],

          ["Invoice Date", "payload.invoicedata.invinvoicedate", {
            options: {
              width: 180,
            },
            search: {
              type: "DateSearch",
              name: "invoicedata.invinvoicedate",
            },
          }],
          ["Hospital", "payload.hospital", {
            options: {
              width: 180,
            },
            search: {
              type: "AdaptiveSearch",
              name: "hospital",
              getValue: (c) => c.payload.hospital,
              contracts: allContracts,
            },
          }],
          ["Invoice Amount", "payload.invoicedata.invinvoicetotalamount", {
            options: {
              width: 150,
              beforeText: '$'
            },
          }],
          ["Invoice Items", "payload.invoicedata.totalitemscount", {
            options: {
              width: 150,
              headerClassName: classes.centerCell,
              cellClassName: classes.centerCell,
            },
          }],
          ///// Commented this because these statuses are not functional yet ////////
          // ["Status", "payload.status", {
          //   type: "render",
          //   render: (param) => (<Chip
          //     label={param.value}
          //     size="small"
          //     className={classNames(classes.smallChip, (
          //       param.value === 'Updated Invoice' || param.value === 'Created Invoice' ? classes.backBlue : (
          //         param.value === 'Declined' ? classes.backLightOrange : (
          //           param.value === 'Sent' || param.value === 'Accepted' ? classes.backGreen : classes.backOrange
          //         )
          //       )
          //     ))}
          //   />),

          //   options: {
          //     flex: 1.5,
          //     alignItems: 'center',
          //   },
          // }],

          //////// Added the old statuses ////////
          ["Invoice Status", "payload.is_sent_hospital", {
            type: "render",
            render: (param) =>
              <Grid container alignItems="center">
                <Grid item xs={2} style={{ marginTop: "2px" }}>
                  <svg height="10" width="10">
                    <circle cx="5" cy="5" r="5" fill={param.value === 'True' ? "#4CAF50" : "#2196F3"} />
                  </svg>
                </Grid>
                <Grid item xs={10}>
                  {param.value === 'True' ? "Invoice Sent" : "Created"}
                </Grid>
              </Grid>,
            // search: {
            //   type: "SelectSearch",
            //   name: "is_sent_hospital",
            //   options: Object.keys(SurgicalStatus).map(value => ({ label: SurgicalStatus[value], value: value })),
            // },
            options: {
              flex: 1,
              alignItems: 'center',
            },
          }],
          ["Payment Status", "payload.payment_status", {
            type: "render",
            render: (param) =>

              param.value === null ? "" :
                (<Grid container alignItems="center">
                  <Grid item xs={2} style={{ marginTop: "2px" }}>
                    <svg height="10" width="10">
                      <circle cx="5" cy="5" r="5" fill={getColorCode((param.value === "Sent to Bank" || param.value === "Processing by Bank" || param.value === "Received by Bank ") ? "Payment Processing" : param.value)} />
                    </svg>
                  </Grid>
                  <Grid item xs={10}>
                    {(param.value === "Sent to Bank" || param.value === "Processing by Bank" || param.value === "Received by Bank ") ? "Payment Processing" : param.value}
                  </Grid>
                </Grid>),
            // search: {
            //   type: "SelectSearch",
            //   name: "payment_status",
            //   options: Object.keys(SurgicalStatus).map(value => ({ label: SurgicalStatus[value], value: value })),
            // },
            options: {
              flex: 1,
              alignItems: 'center',
            },
          }],
        ]}

        actions={[
          [
            "Actions",
            [[["Edit Invoice",
              (c) => checkNotInvHistory(c) && !c.payload.is_sent_hospital
            ],
              handleEditInvoice
            ],
            [["Send Invoice",
              (c) => checkNotInvHistory(c) && !c.payload.is_sent_hospital],
              openSendInv],
            [["Archive",
              (c) => (checkNotInvHistory(c) && c.payload.is_sent_hospital && c.payload.status === 'Available for payment')],
              openArchiveInv],
            ],
            {
              width: 120,
            }
          ]
        ]}
        options={{
          statusEmpty: (allContracts.length === 0),
          checkbox: true,
        }}

        handleArchived={setArchived}
      />


      <DetailViewDrawerPO
        state={detailViewer}
        toggleDrawer={(open) => setDetailViewer(open)}
        selectedInvoiceData={invoiceData}
        productItemsList={invoiceList}
      />

      <DetailViewDrawer
        state={detailViewerInvoice}
        toggleDrawer={(open) => setDetailViewerInvoice(open)}
        invoice={invoice}
      />

      <ModalDialogBox
        open={archiveModalOpen}
        setOpen={setArchiveModalOpen}
        heading="Confirm"
        type="warning"
        text={'<p>Do you want to archive Invoice now ?</p>'}
        handleRequest={handleArchiveInv}
        secondBtn={'No'}
        handleSecRequest={() => setArchiveModalOpen(false)}
      />

      <SnackbarModal
        open={confirmToast}
        setOpen={setconfirmToast}
        type="warning"
        title=""
        content="Do you want to send to Hospital now ?"
        vertical="center"
        horizontal="center"
        width={500}
        height={117}
        transition="fade"
        className={classes.toastMedium}
        options={{
          delay: 500,
        }}
        actions={[
          {
            label: 'Yes',
            handle: handleSendInv,
          },
          {
            label: 'No',
            handle: cancelsendInv,
          }
        ]}
      />

      <UpdateInvoice
        openModal={openModal}
        setOpenModal={setOpenModal}
        invoice={payload}
        handleUpdate={async (update) => {
          setOpenModal(false);
          await setUpdateInv({
            ledger,
            hospital: update?.hospital,
            cid: contractId,
            newinvoicedate: update?.invoicedata?.invinvoicedate,
            newinvoiceamount: update?.invoicedata?.invinvoicetotalamount,
            roleCid,
            userlistPayload,
          });
        }}
      />

    </>
  );
}

const InvoiceListContractsWithLoading = withLoading(InvoiceListContracts);
export default withFilters(InvoiceListContractsWithLoading)([
  { name: "invoicedata.invpurchaseorderid", label: "Purchase Order" },
  { name: "invoicedata.invinvoicenumber", label: "Invoice Number" },
  { name: "invoicedata.invinvoicedate", label: "Date Range", type: "date" },
  { name: "hospital", label: "Hospital" },
]);
