import React, { useState, useEffect } from "react";
import { Typography, Accordion,AccordionDetails,AccordionSummary,
  TableContainer, Paper, Badge, Chip, DialogContentText, Drawer } from "@material-ui/core";
import { ExpandMore } from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';
import { checkComplianceStatus, checkComplianceStatusExpiration, 
  checkComplianceStatusSide } from "../../models/SurgicalEventCompliance";
import { InventoryPOStatus } from '../../models/Po';
import ProductDetailViewTable from "../surgicalevent/product-detail-view-table";
import { PoType } from "../../models/Po";
import useStyles from "../surgicalevent/styles";


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/**
 * Detail View Slide Drawer 
 */
export default function DetailViewDrawer ({
  state, toggleDrawer, selectedPOData={}, inventoryList=[],
}) {
  //  console.log("[DetailViewDrawer]", selectedPOData);

  if (!state) {
    inventoryList=[]; 
  }
  
  const classes = useStyles();

  const [expanded, setExpanded] = useState(0);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }


  useEffect(() => {
    if (state) {
      // console.log("[DetailViewDrawer] is opened.");
      setExpanded(0);
    }
  }, [state]);


  return (
    <React.Fragment  key={'right'} >
      <Drawer className={classes.customRoot} anchor={'right'} open={state} onClose={()=>{toggleDrawer(false)}}>           
        <div className="view-heading">
          <span>Purchase Order:</span> {selectedPOData && selectedPOData.popurchaseorderid}
          {selectedPOData && selectedPOData.poseid && (<>
            <span style={{marginLeft: 10}}>SE Id:</span> {selectedPOData.poseid}
          </>)}
        </div>
        
       <DialogContentText component={'div'} className="modelStepDetai">
        <div className="viewDetailsSe">

          <h2 className={classes.formSubHeading}>PO Details</h2>
          <div className="grid-list grid-list-3">
            <div className="list-item">
              <div className="label">Distributor</div>
              <div className="value">{selectedPOData && selectedPOData.povendorid}</div>
            </div>
            <div className="list-item">
              <div className="label">Status</div>
              <div className="value">{InventoryPOStatus[selectedPOData?.poinventorystatus]}</div>
            </div>
            <div className="list-item">
              <div className="label">PO Type</div>
              <div className="value">{selectedPOData && PoType[selectedPOData?.potype]}</div>
            </div>
          </div>

          {inventoryList.length !== 0 && 
            <div> 
              <div className={classes.productDetails}>
                <h2 className={classes.formSubHeading}>Total Added Product 
                  <Badge badgeContent={inventoryList.length} color="primary" overlap="rectangular"></Badge>
                  <span className={classes.productTableHeading}><span className="price-label">Total Price of Products:</span> ${selectedPOData.pototalamount}</span>
                </h2>
              </div>
            
              <div className={classes.productAccordion}>
                {inventoryList.map(({iproduct: obj, iquantity}, index) => (
                <Accordion key={index} expanded={expanded === (index+1)} onChange={handleChangeAccordion((index+1))} className={classes.productMainAccordion}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className="product-detail-accordion-head"
                  >
                    <Typography className={classes.accordionHeading}>
                      <span className="text-inactive">Reference Number :</span> <span className="text-active">{obj.sepreferencenumber}</span> 
                    </Typography>
                    <Typography className={classes.accordionHeading}>
                      <span className="text-inactive">Lot Code :</span> <span className="text-active">{obj.seplotcode}</span> 
                    </Typography>
                    <Typography className={classes.accordionHeading}>
                      <span className="text-inactive">Qty :</span> <span className="text-active">{iquantity}</span> 
                    </Typography>
                    {
                      checkComplianceStatus({surgicalevent: selectedPOData, auditedproductdata: obj}) !== ''
                      && <Chip label="Compliance" className="bg-info-light" />
                    }
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography component={'div'}>
                      <TableContainer  className={classes.productTableContainer} component={Paper}>
                        {
                          checkComplianceStatusSide(selectedPOData, obj) &&  
                          <Alert severity="info"><strong>Compliance</strong>: Product is incorrect Side based on Surgical Procedure</Alert> 
                        }
                        {
                          checkComplianceStatusExpiration(selectedPOData, obj) &&  
                          <Alert severity="info"><strong>Compliance</strong>: Product is past expiration date</Alert> 
                        }
                        <ProductDetailViewTable 
                          obj={obj}
                          options={{
                            hiddenParams: [],
                          }}
                        />
                      </TableContainer>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                ))}
                 
              </div> 
            </div>
          }
        </div>
        </DialogContentText>
      </Drawer>
    </React.Fragment>
  );
}
