import React, { useEffect, useState } from "react";
import { Select, InputLabel, FormControl, Button, DialogContentText, 
  Dialog, DialogContent, DialogTitle, DialogActions, TextareaAutosize } from "@material-ui/core";
import { useLayoutDispatch, setLoading, unsetLoading } from "../../context/LayoutContext";
import { getReturnType } from "../../models/InventoryData";
import FileUploadDropzone from "../../components/FileUpload/FileUploadDropzone";
import useStyles from "../surgicalevent/styles";


/**
 * Send Return Request Dialog
 * Account Inventory in House of Hospital
 * @param {Boolean} openWarning 
 * @param {Function} setOpenWarning 
 * @param {Function} handleModalYes 
 * @param {Object} request 
 * @param {Function} setRequest 
 * @param {Object} requestError
 * @returns 
 */
export default function NewRequest ({
  openWarning, setOpenWarning, handleModalYes, 
  request, setRequest, requestError={}, 
}) {
  
  const classes = useStyles();

  const layoutDispatch = useLayoutDispatch();

  const [,setFilePathData] = useState("");


  const handleSend = async () => {
    // spinner
    setLoading(layoutDispatch);
    await handleModalYes();
    unsetLoading(layoutDispatch);
  };

  const handleCancel = () => {
    setOpenWarning(false);
    unsetLoading(layoutDispatch);
  };


  useEffect(() => {
    if (openWarning) {
      console.log("[NewRequest] Open NewRequest dialog.");
    }
  }, [openWarning]);
  

  return (
    <>
      <Dialog
        open={openWarning}
        className={classes.customRoot}
        aria-labelledby="draggable-dialog-title"
      >
        <div className={classes.purchaseContentModal}> 
          <DialogTitle style={{ cursor: 'move', borderBottom: '1px solid #cccccc' }} id="draggable-dialog-title">
            <div className="view-heading">Send Return Request</div> 
          </DialogTitle>
          <DialogContent className={classes.poContentModal}>
            <DialogContentText  component={'div'}>
            
              <div className={classes.marginBM30}></div>
              <div className={classes.marginBM30}>
                <div className={classes.popperList}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel className={classes.customLabel} shrink={true}>
                      Request Type
                    </InputLabel>
                    <Select
                      // native
                      displayEmpty
                      error={!!requestError.newireturntype}
                      
                      className={classes.customFullWidth}
                      value={request.newireturntype || ""}
                      onChange={(e) => {
                        setRequest({
                          ...request,
                          newireturntype: e.target.value,
                        });
                      }}
                    >
                      {getReturnType()}
                    </Select>
                  </FormControl>   
                </div>
              </div>
              <div className={classes.marginBM30}>
                <FileUploadDropzone 
                  title={'Drag & Drop PO CSV/PDF to Upload'} 
                  subtitle={'You can upload only valid CSV/PDF here.'}
                  setFilePathData={setFilePathData} 
                  isNonePadding={true}
                />
              </div>
              <div className={classes.marginBM30}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <div className={ requestError.newireturnmessage !=='' ? classes.requiredField:''}>
                    <InputLabel className={classes.customLabel} shrink={true}>
                      Request Description
                    </InputLabel>
                    <TextareaAutosize
                      id="input-with-icon-adornment1"
                      label="Request Description"
                      placeholder="This product is requested to be returned , please accept request."
                      value={request.newireturnmessage || ""}
                      className={classes.customFullWidthTextarea}
                      margin="normal"
                      onChange={(e) => {
                        setRequest({
                          ...request,
                          newireturnmessage: e.target.value,
                        });
                      }}
                      variant="outlined"
                    />
                  </div> 
                </FormControl>   
              </div>
              
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <div className="form-footer">
                
            <div className="right-buttons">
              <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
              <Button variant="contained" color="primary" onClick={handleSend} >Send</Button> 
            </div>
            </div>
            
          </DialogActions>
        </div>

      </Dialog>
          
    </>
  );
}
           
