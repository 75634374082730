import React, { useState } from "react";
import classNames from "classnames";
import { useParty } from "@daml/react";
import { Chip } from "@material-ui/core";
import { /* SurgicalEventCompliance, */ SurgicalComplianceHistory } from "../../services/daml-modules1";
import AdaptiveSearch from "../../components/Search/adaptivesearch";
import DateSearch from "../../components/Search/datesearch";
import Contracts from "../../components/Contracts/Contracts";
import { contractFilter } from "../../services/daml-filters";
// import archiveIcon from "../../icons/folder-plus.svg";
// import archiveDisableIcon from "../../icons/folder-plus-disable.svg";
// import { ArchiveCompliance } from "./handle";
import DetailViewDrawer from "../surgicalevent/detail-view-drawer";
import { Outcomes, RuleTypeLevels, RuleTypes } from "../../models/SurgicalEventCompliance";
import { useDamlStream } from "../../hooks/useDAML";
import useStyles from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";


function ComplianceListContracts({
  searchParams = {},
  searchHistoryParams = {},
  isIncludeHistory = false,
  handleChangeSearchValue = null,
  searchCompliance = {},
  allContracts = [],
}) {


  const classes = useStyles();
  const hospital = useParty();

  // const assets = useStreamQueries(SurgicalEventCompliance, () => [searchParams]);

  const assetsHistory = useDamlStream(SurgicalComplianceHistory, [{ hospital }], isIncludeHistory);

  allContracts = allContracts.concat(isIncludeHistory && assetsHistory.contracts ? 
    assetsHistory.contracts.map(h => ({ ...h, payload: h.payload.surgicaleventcompliance })) : [])
    .map(c => {
      if (c.payload.surgicaleventcompliancedata.ruletype === 'OffContractUsage') 
        c.payload.surgicaleventcompliancedata.ruleoutcome = '';
      return c;
    });
  let contracts = contractFilter(allContracts, searchParams);

  console.log("[ComplianceListContracts]", contracts, "searchParams:", searchParams);
  // console.log("assetsHistory",assetsHistory);

  // SEDetailViewDrawer
  const [seData, setSEData] = useState({});
  const [poList, setPoList] = useState([]);
  const [sestate, setSEState] = useState(false);


  return (
    <>

      <Contracts
        contracts={contracts}
        columns={[

          ["SE Id", "payload.surgicaleventdata.seid", {
            type: "link",
            onClick: (contractId, contract) => {
              const clickedContractId = (!!contractId) ? contractId : contract.contractId;
              const data = contracts.find(c => c.contractId === clickedContractId);

              setSEData({ ...data.payload.surgicaleventdata, created_at: data.payload.created_at });
              setPoList([data.payload.auditedproductdata]);
              setSEState(true);
            },
            options: {
              width: 120,
              cellClassName: classNames(classes.primaryColor),
            },
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.surgicaleventdata.seid}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'seid')}
                  defaultValue={searchCompliance.seid}
                  label="Search SE ID"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchCompliance.seid,
            handleClear: () => handleChangeSearchValue('', 'seid'),
          }],
          ["Date", "payload.surgicaleventcompliancedata.date", {
            search: {
              component:
                <DateSearch
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'date')}
                  defaultValue={searchCompliance.date}
                  label="Search Event Date :"
                  isStatic={true}
                />
            },
            isSearched: searchCompliance.date,
            handleClear: () => handleChangeSearchValue('', 'date'),
            options: {
              width: 160,
            },
          }],
          ["User", "payload.surgicaleventcompliancedata.user", {
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.surgicaleventcompliancedata.user}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'user')}
                  defaultValue={searchCompliance.user}
                  label="Search User"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchCompliance.user,
            handleClear: () => handleChangeSearchValue('', 'user'),
          }],
          ["Role", "payload.surgicaleventcompliancedata.role"],
          ["Event Date", "payload.surgicaleventdata.eventdate", {
            search: {
              component:
                <DateSearch
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'eventdate')}
                  defaultValue={searchCompliance.eventdate}
                  label="Search Event Date :"
                  isStatic={true}
                />
            },
            isSearched: searchCompliance.eventdate,
            handleClear: () => handleChangeSearchValue('', 'eventdate'),
            options: {
              width: 150,
            },
          }],
          ["MRN", "payload.surgicaleventdata.mrn", {
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.surgicaleventdata.mrn}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'mrn')}
                  defaultValue={searchCompliance.mrn}
                  label="Search MRN"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchCompliance.mrn,
            handleClear: () => handleChangeSearchValue('', 'mrn'),
            options: {
              width: 150,
            },
          }],
          ["Rule Type Level", "payload.surgicaleventcompliancedata.ruletypelevel", {
            type: "render",
            render: (param) => (RuleTypeLevels[param.value] && <Chip
              label={RuleTypeLevels[param.value]}
              size="small"
              className={classNames(classes.smallChip, (
                param.value === 'Critical' ? classes.backRed : (
                  param.value === 'High' ? classes.backOrange : (
                    (param.value === 'Medium' ? classes.backLightYellow : classes.backBlue)
                  )
                ) 
              ))}
            />),
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.surgicaleventcompliancedata.ruletypelevel}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'ruletypelevel')}
                  defaultValue={searchCompliance.ruletypelevel}
                  label="Search Rule Type Level"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchCompliance.ruletypelevel,
            handleClear: () => handleChangeSearchValue('', 'ruletypelevel'),
            options: {
              width: 170,
            },
          }],
          ["Rule Type", "payload.surgicaleventcompliancedata.ruletype", {
            type: "render",
            render: (param) => RuleTypes[param.value],
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.surgicaleventcompliancedata.ruletype}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'ruletype')}
                  defaultValue={searchCompliance.ruletype}
                  label="Search RuleType"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchCompliance.ruletype,
            handleClear: () => handleChangeSearchValue('', 'ruletype'),
            options: {
              width: 140,
            },
          }],
          ["Rule Message", "payload.surgicaleventcompliancedata.rulemessage", {
            options: {
              // flex: 1,
              width: 300,
            },
          }],
          ["Rule Outcome", "payload.surgicaleventcompliancedata", {
            type: "render",
            render: (param) => (Outcomes[param.value.ruleoutcome] ? <Chip
              label={Outcomes[param.value.ruleoutcome]}
              size="small"
              className={classNames(classes.smallChip, (
                (param.value.ruleoutcome === 'Acknowledged') ? classes.backGreen : classes.backRed)
              )}
            /> : ''),
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.surgicaleventcompliancedata.ruleoutcome}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'ruleoutcome')}
                  defaultValue={searchCompliance.ruleoutcome}
                  label="Search Rule Outcome"
                  contracts={allContracts}
                  isShrink={true}
                />
            }, 
            isSearched: searchCompliance.ruleoutcome,
            handleClear: () => handleChangeSearchValue('', 'ruleoutcome'),
            options: {
              width: 150,
            },
          }],

        ]}
        options={{
          statusEmpty: (allContracts.length === 0)
        }}
      />

      <DetailViewDrawer
        state={sestate}
        toggleDrawer={(open) => setSEState(open)}
        selectedEventData={seData}
        productItemsList={poList}
      />

    </>
  );
}

export default withLoading(ComplianceListContracts);