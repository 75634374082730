import { HospitalRole } from "../../services/daml-modules1";
import { getTemplateListFromResponse } from "../../services/util";
import { loggerPush, LOGGER_INFO, LOGGER_ERROR } from '../../services/logger';
import { validateProduct, getProduct } from "../../models/Product";
import { getSurgicalEvent, validateSurgicalEvent, validateSurgicalStatus } from "../../models/SurgicalEvent";
import { validateSurgicalEventDetail, getSurgicalEventDetail } from "../../models/SurgicalEventDetail";
import { validateOutcome } from "../../models/SurgicalEventCompliance";
import { validateSurgicalEventProcedure, getSurgicalEventProcedure, validateSurgicalEventProcedurePrice,
  getSurgicalEventProcedurePrice, validateSurgicalEventProcedureDisPrice,
  getSurgicalEventProcedureDisPrice } from "../../models/SurgicalEventProcedure";
import { isImpossibleInventory } from "../../models/Ownership";
import { getArray } from "../../models/handles";



/**
 * handle to add new Product Data
 * exercise HSEAddProduct choice of HospitalRole
 * @param {Object} newProductData 
 * @param {Object} ledger 
 * @param {String} ContractId 
 * @param {Function} setContractId 
 * @param {String} name 
 * @param {String} role 
 * @param {String} expirationdate 
 * @param {String} surgicalside 
 * @param {String} producttype
 * @param {Function} setProductList 
 * @param {String} roleCid
 * @returns {Boolean}
 */
export async function handleAddNewProductData({
  newProductData, ledger, ContractId, setContractId, name, role, expirationdate, surgicalside, setProductList, roleCid,
  setDetailList,
}) {
  console.log("[handleAddNewProductData]", newProductData, name, role, expirationdate, surgicalside);

  newProductData = getProduct(newProductData, {
    isCreatePartyFromName: true,  // create party name
    sepproductesprice: true,
  });

  /**
   * If Ownership is impossible to create Inventory, it should be Owned as default.
   * Because it needs to find out the matched Account Inventory.
   */
  if (isImpossibleInventory(newProductData.sepownership)) newProductData.sepownership = 'Owned';

  if (!validateProduct(newProductData)) return false;

  // console.log("SurgicalEvent ContractId :" + ContractId, newProductData);
  if (!roleCid || !ContractId) return false;

  try {
    console.log("start Ledger Exercise");

    let res = await ledger.exercise(HospitalRole.HSEAddProduct, roleCid, {
      secid: ContractId,
      newproductdata: newProductData,
      user: name || "",
      role: role,
      complianceexpirationdate: validateOutcome(expirationdate) ? expirationdate : undefined,
      compliancesurgicalside: validateOutcome(surgicalside) ? surgicalside : undefined,
    });

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'SE: AddNewProductData', response: res });

    // get contractId of the updated SurgicalEvent
    let templateSE = getTemplateListFromResponse(res, "SurgicalEvent");
    if (templateSE.length) {
      if (typeof setContractId === 'function') setContractId(templateSE[0].contractId);
      if (typeof setProductList === 'function') setProductList(templateSE[0].surgicaleventproductdata.map(d => d.product));
      if (typeof setDetailList === 'function') setDetailList(templateSE[0].surgicaleventproductdata);
      // const newContract = {contractId: templateSE[0].contractId, payload: templateSE[0]};
      // dispatch(surgicalEventSetDetail(newContract));
      
      // return true; // Old case
      return templateSE[0];
    }

    // let templateSEC = getTemplateListFromResponse(res, "SurgicalEventCompliance");
    // if (templateSE.length || templateSEC.length) return true;

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'SE: AddNewProductData', payload: e, params: newProductData });
    console.log(e);
  }

  return false;
}

/**
 * handle to remove product
 * exercise HSERemoveProduct choice of HospitalRole
 * @param {Array} removeproductdata SurgicalEventDetaildata
 * @param {Object} ledger 
 * @param {String} ContractId 
 * @param {Function} setContractId 
 * @param {Number} productindex
 * @param {String} roleCid
 * @returns {Boolean}
 */
export async function handleRemoveProduct({ 
  removeproductdata, ledger, ContractId, setContractId, name, role, productindex, roleCid,
}) {
  console.log("[handleRemoveProduct]", removeproductdata, ContractId, productindex);

  // if (!(Array.isArray(newProductDatas))) return false;
  // newProductDatas = newProductDatas.filter(p => validateProduct(p)).map(p => getProduct(p));
  // if (!(newProductDatas.length > 0)) return false;

  if (!validateSurgicalEventDetail(removeproductdata)) return false;
  removeproductdata = getSurgicalEventDetail(removeproductdata);

  if (!ContractId) return false;

  try {
    console.log("start Ledger Exercise");

    let res = await ledger.exercise(HospitalRole.HSERemoveProduct, roleCid, {
      secid: ContractId,
      removeproductdata,
      removeproductindex: productindex,
      user: name || "",
      role,
    });

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'SE: RemoveProduct', response: res });

    // get contractId of the updated SurgicalEvent
    let templateSE = getTemplateListFromResponse(res, "SurgicalEvent");
    if (templateSE.length) {
      if (typeof setContractId === 'function') setContractId(templateSE[0].contractId);
      // const newContract = {contractId: templateSE[0].contractId, payload: templateSE[0]};
      // dispatch(surgicalEventSetDetail(newContract));

      // return true; // Old case
      return templateSE[0];
    }
  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'SE: RemoveProduct', payload: e, params: removeproductdata });
    console.log(e);
  }

  return false;
}

/**
 * handle to update a Product Data
 * exercise HSEUpdateProduct choice of HospitalRole
 * @param {Object} updateProductData SE Detail instead of Product
 * @param {Object} ledger 
 * @param {String} ContractId 
 * @param {Function} setContractId 
 * @param {String} name 
 * @param {String} role 
 * @param {String} expirationdate 
 * @param {String} surgicalside 
 * @param {Function} setProductList 
 * @param {Number} productindex
 * @param {String} roleCid
 * @returns {Boolean}
 */
export async function handleUpdateProductData({
  updateProductData, ledger, ContractId, setContractId, name, role, expirationdate, surgicalside, setProductList, 
  productindex, roleCid,
}) {
  console.log("[handleUpdateProductData]", updateProductData, ContractId, productindex);

  if (!validateSurgicalEventDetail(updateProductData)) return false;
  const updatedProduct = getProduct(updateProductData.product, {
    isCreatePartyFromName: true,  // create party name
    sepproductesprice: true,
  });
  updateProductData = getSurgicalEventDetail({
    ...updateProductData,
    product: updatedProduct,
  });

  if (!ContractId) return false;

  try {
    console.log("start Ledger Exercise");

    let res = await ledger.exercise(HospitalRole.HSEUpdateProduct, roleCid, {
      secid: ContractId,
      updateproductdata: updateProductData,
      updateproductindex: productindex,
      user: name || "",
      role: role,
      complianceexpirationdate: expirationdate,
      compliancesurgicalside: surgicalside,
    });

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'SE: UpdateProductData', response: res });

    // get contractId of the updated SurgicalEvent
    let templateSE = getTemplateListFromResponse(res, "SurgicalEvent");
    if (templateSE.length) {
      if (typeof setContractId === 'function') setContractId(templateSE[0].contractId);
      if (typeof setProductList === 'function') setProductList(templateSE[0].surgicaleventproductdata.map(d => d.product));
      
      // return true; // Old case
      return templateSE[0];
    }
  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'SE: UpdateProductData', payload: e, params: updateProductData });
    console.log(e);
  }

  return false;
}

/**
 * create a SurgicalEvent
 * exercise SetSurgicalEventPref choice of HospitalRole
 * @param {Object} surgicalEvent
 * @param {Object} ledger
 * @param {String} hospital
 * @param {String} ContractId
 * @param {Function} setContractId
 * @param {String} roleCid
 * @returns {Boolean}
 */
export async function createSurgicalEvent({ 
  surgicalEvent, ledger, hospital,  setContractId, roleCid,prefdata
}) {
  console.log("[createSurgicalEvent]");
  // console.log(surgicalEvent, hospital, ContractId, setContractId, roleCid);

  console.log("hospital : " + hospital);
  // console.log("cid: " + ContractId);
  // const hospital = surgicaleventdata.hospital;
  console.log("surgicaleventdata : ", surgicalEvent);

  if (!validateSurgicalEvent(surgicalEvent)) return false;
  surgicalEvent = getSurgicalEvent(surgicalEvent);

  // const surgicaleventproductdata = [];
  // const status = surgicalEvent.status;
  // const status = "Scheduled";


  /**
   * if you want to use ledger.exercise, this should be true.
   * if you want to use ledger.create, this should be false.
   */
  // use exercise

  if (!roleCid) {
    console.log("query Result has no contracts.");
    return false;
  }

  try {
    console.log("start Ledger Exercise");
    // use SetSurgicalEventWithLog choice in the next version
    const choice = HospitalRole.SetSurgicalEventPref; // Old case : HospitalRole.SetSurgicalEvent
    let res = await ledger.exercise(choice, roleCid, {
      surgicaleventdata: surgicalEvent,
      prefData: prefdata
    });

   

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'SE: CreateSurgicalEvent', response: res });

    // get contractId of the created SurgicalEvent
    let templateSE = getTemplateListFromResponse(res, "SurgicalEvent");
    if (templateSE.length) {
      if (typeof setContractId === 'function') setContractId(templateSE[0].contractId);
      // const newContract = {contractId: templateSE[0].contractId, payload: templateSE[0]};
      // dispatch(surgicalEventSetDetail(newContract));

      console.log(res);

      // return true; // Old case
      return templateSE[0];
    }

  } catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'SE: CreateSurgicalEvent', payload: e, params: surgicalEvent });
    console.log("error caught");
    console.error(e);

  }

  return false;
}

/**
 * update the SurgicalEvent detail
 * exercise HSEUpdate choice of HospitalRole
 * @param {Object} surgicalEvent
 * @param {Object} ledger
 * @param {String} hospital
 * @param {String} ContractId
 * @param {Function} setContractId
 * @param {String} roleCid
 * @returns {Boolean}
 */
export async function updateSurgicalEvent({ 
  surgicalEvent, ledger, hospital, ContractId, setContractId, roleCid,prefData
}) {
  console.log("[updateSurgicalEvent]");

  console.log("hospital : " + hospital);
  console.log("surgicaleventdata : ", surgicalEvent);

  if (!validateSurgicalEvent(surgicalEvent)) return false;
  surgicalEvent = getSurgicalEvent(surgicalEvent);

  // use exercise
  console.log("SurgicalEvent ContractId :" + ContractId);
  if (!ContractId) return false;

  try {
    console.log("start Ledger Exercise");

    let res = await ledger.exercise(HospitalRole.HSEUpdate, roleCid, {
      secid: ContractId,
      newsurgicaleventdata: surgicalEvent
    });
    console.log(res);

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'SE: UpdateSurgicalEvent', response: res });

    // get contractId of the updated SurgicalEvent
    let templateSE = getTemplateListFromResponse(res, "SurgicalEvent");
    if (templateSE.length) {
      if (typeof setContractId === 'function') setContractId(templateSE[0].contractId);
      // return true; // Old case
      return templateSE[0];
    }

  } catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'SE: UpdateSurgicalEvent', payload: e, params: surgicalEvent });
    console.error(e);
  }

  return false;
}

/**
 * confirm SurgicalEvent
 * exercise HSEConfirm choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} name 
 * @param {String} ContractId 
 * @param {String} roleCid
 * @returns {Boolean}
 */
export async function confirmSurgicalEvent({
  ledger, name, ContractId, roleCid, 
}) {

  console.log('[confirmSurgicalEvent]', name, ContractId);

  try {
    let res = await ledger.exercise(HospitalRole.HSEConfirm, roleCid, {
      secid: ContractId,
      user: name,
    });

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'SE: ConfirmSurgicalEvent', response: res });

    // get contractId of the updated SurgicalEventConfirmation
    let templateSE = getTemplateListFromResponse(res, "SurgicalEventConfirmation");
    if (templateSE.length) return true;
  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'SE: ConfirmSurgicalEvent', payload: e, params: {ContractId, name} });
    console.log(e);
  }

  return false;
}

/**
 * complete a SurgicalEvent
 * exercise HSEComplete choice of HospitalRole
 * @param {String} contractId 
 * @param {Object} payload 
 * @param {Object} ledger 
 * @param {Boolean} isCertificate 
 * @param {Function} handleAfter 
 * @param {String} roleCid
 * @param {String} name 
 * @param {String} role 
 * @param {String} email
 * @param {String} username
 * @returns {Array|Boolean}
 */
export async function completeSurgicalEvent({
  contractId, payload, ledger, isCertificate = false, handleAfter = null, roleCid, name, role,email,username,
}) {

  try {
    let res = await ledger.exercise(HospitalRole.HSECompleteWithLog, roleCid, {
      secid: contractId,
      certificate: isCertificate,
      useremail: email,
      username: username,
      // user: name || "",
      // role: role,
    });
    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'SE: CompleteSurgicalEvent', response: res });

    let templateSE = getTemplateListFromResponse(res, "SurgicalEvent");
    if (templateSE.length) {
      if (typeof handleAfter === 'function') handleAfter(res);
      // return true; // Old case
      return [res, templateSE[0]];
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'SE: CompleteSurgicalEvent', payload: e, params: contractId });
    console.log(e);
  }

  return false;
}

/**
 * cancel a SurgicalEvent
 * exercise HSECancel choice of HospitalRole
 * @param {String} contractId 
 * @param {Object} payload 
 * @param {Object} ledger 
 * @param {String} roleCid
 * @returns {Boolean}
 */
export async function cancelSurgicalEvent({
  contractId = ' ', payload = ' ', ledger, roleCid,
}) {
  // console.log("[cancelSurgicalEvent]", payload);

  try {

    let res = await ledger.exercise(HospitalRole.HSECancel, roleCid, {
      secid: contractId,
    });

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'SE: CancelSurgicalEvent', response: res });

    let templateSE = getTemplateListFromResponse(res, "SurgicalEvent");
    if (templateSE.length) return true;
  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'SE: CancelSurgicalEvent', payload: e, params: contractId });
    console.log(e);
  }

  return false;
}

/**
 * archive a SurgicalEvent
 * exercise HSEArchive choice of HospitalRole
 * @param {String} contractId 
 * @param {Object} payload 
 * @param {Object} ledger 
 * @param {String} roleCid
 * @returns {Boolean}
 */
export async function archiveSurgicalEvent({
  contractId = ' ', payload = ' ', ledger, roleCid,
}) {
  console.log("[archiveSurgicalEvent]", payload);

  try {

    let res = await ledger.exercise(HospitalRole.HSEArchive, roleCid, {
      secid: contractId,
    });

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'SE: ArchiveSurgicalEvent', response: res });

    let templateSE = getTemplateListFromResponse(res, "SurgicalEventHistory");
    if (templateSE.length) return true;
  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'SE: ArchiveSurgicalEvent', payload: e, params: contractId });
    console.log(e);
  }

  return false;
}

/**
 * set Status of SurgicalEvent
 * exercise HSEChangeStatus choice of HospitalRole
 * @param {String} contractId 
 * @param {Object} payload 
 * @param {Object} ledger 
 * @param {String} newstatus 
 * @param {String} newchecked 
 * @param {String} roleCid
 * @returns {Boolean}
 */
export async function setSurgicalStatus({
  contractId = ' ', payload = ' ', ledger, newstatus, newchecked, roleCid,
}) {
  console.log("[setSurgicalStatus]", payload, newstatus, newchecked);

  if (!validateSurgicalStatus(newstatus)) return;
  let params = {};

  try {

    params = (typeof newchecked === 'boolean') ? {
      secid: contractId,
      newstatus,
      newchecked,
    } : {
      secid: contractId,
      newstatus,
    };

    let res = await ledger.exercise(HospitalRole.HSEChangeStatus, roleCid, params);

    // log the response to loggly
    loggerPush({ type: LOGGER_INFO, action: 'SE: SetSurgicalStatus', response: res });

    let templateSE = getTemplateListFromResponse(res, "SurgicalEvent");
    if (templateSE.length) return true;
  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'SE: SetSurgicalStatus', payload: e, params: params });
    console.log(e);
  }

  return false;
}

/**
 * update products of Contract Price
 * exercise HMakeContractPrice choice of HospitalRole
 * @param {Object} ledger 
 * @param {Array} products 
 * @param {Object} user 
 * @param {String} roleCid 
 */
export async function updateProductsContractPrice ({
  ledger, products, user, roleCid,
}) {

  console.log("[updateContractPrice]", products, user);
  
  const newproducts = getArray(products, validateProduct, getProduct);
  if (ledger && roleCid && user && newproducts.length > 0) {
    let res = await ledger.exercise(HospitalRole.HMakeContractPrice, roleCid, {
      products: newproducts,
      user,
    });
    console.log("[updateContractPrice]", res);
  }
  
}

/**
 * Update Physician Details In PO
 * exercise HSEUpdatePhysicianDetails choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} roleCid
 * @param {String} physiciansalutation 
 * @param {String} physicianfirstname 
 * @param {String} physicianlastname 
 * @param {String} contractId 
 */
export const updatePhysicianDetails=async({
  roleCid,ledger,contractId,physiciansalutation,physicianfirstname,physicianlastname
})=>{

  try {

    if (roleCid && contractId) {
      let res =await ledger.exercise(HospitalRole.HSEUpdatePhysicianDetails,roleCid, {
        seid: contractId,
        physiciansalutation :physiciansalutation,
        physicianfirstname :physicianfirstname ,
        physicianlastname : physicianlastname,
      });

      // log the response to loggly
      loggerPush({ type: LOGGER_INFO, action: 'SE: HSEUpdatePhysicianDetails', response: res });

      return true;
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'SE: HSEUpdatePhysicianDetails', payload: e, params: {physiciansalutation,physicianfirstname,physicianlastname} });
    console.warn("[updatePhysicianDetails]", e);
  }

  return false;
}

/**
 * create SurgicalEventProcedure list
 * exercise HSEProceduresCreate choice of HospitalRole
 * @param {Object} ledger 
 * @param {Array} procedures
 * @param {String} roleCid 
 */
export async function createSurgicalEventProcedure ({
  ledger, procedures, roleCid,
}) {

  try {
    
    const newprocedures = getArray(procedures, validateSurgicalEventProcedure, getSurgicalEventProcedure);
    console.log("[createSurgicalEventProcedure]", procedures);

    if (ledger && roleCid && newprocedures.length > 0) {
      let res = await ledger.exercise(HospitalRole.HSEProceduresCreate, roleCid, {
        procedures: newprocedures,
      });
      console.log("[createSurgicalEventProcedure]", res);
    }

  }
  catch (e) {
    console.log("[createSurgicalEventProcedure]", e);
  }
  
}

/**
 * update SurgicalEventProcedure
 * exercise HSEProcedureUpdate choice of HospitalRole
 * @param {Object} ledger 
 * @param {Object} newprocedure
 * @param {String} roleCid 
 * @param {String} sepcid 
 */
export async function updateSurgicalEventProcedure ({
  ledger, newprocedure, roleCid, sepcid, 
}) {

  try {
    
    if (validateSurgicalEventProcedure(newprocedure)) {
      let res = await ledger.exercise(HospitalRole.HSEProcedureUpdate, roleCid, {
        newprocedure: getSurgicalEventProcedure(newprocedure),
        sepcid,
      });
      console.log("[updateSurgicalEventProcedure]", res);
    }

  }
  catch (e) {
    console.log("[updateSurgicalEventProcedure]", e);
  }
  
}

/**
 * remove SurgicalEventProcedure
 * exercise HSEProcedureArchive choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} roleCid 
 * @param {String} sepcid 
 */
export async function removeSurgicalEventProcedure ({
  ledger, roleCid, sepcid, 
}) {

  try {
    
    let res = await ledger.exercise(HospitalRole.HSEProcedureArchive, roleCid, {
      sepcid,
    });
    console.log("[removeSurgicalEventProcedure]", res);

  }
  catch (e) {
    console.log("[removeSurgicalEventProcedure]", e);
  }
  
}

/**
 * create SurgicalEventProcedureThresholdAmount
 * exercise HSEPThresholdAmountCreate choice of HospitalRole
 * @param {Object} ledger 
 * @param {Object} procedure
 * @param {String} roleCid 
 */
export async function createSurgicalEventProcedureThresholdAmount ({
  ledger, procedure, roleCid,
}) {

  try {
    
    if (validateSurgicalEventProcedurePrice(procedure)) {
      let res = await ledger.exercise(HospitalRole.HSEPThresholdAmountCreate, roleCid, {
        procedure: getSurgicalEventProcedurePrice(procedure),
      });
      console.log("[createSurgicalEventProcedureThresholdAmount]", res);
    }

  }
  catch (e) {
    console.log("[createSurgicalEventProcedureThresholdAmount]", e);
  }
  
}

/**
 * update SurgicalEventProcedureThresholdAmount
 * exercise HSEPThresholdAmountUpdate choice of HospitalRole
 * @param {Object} ledger 
 * @param {Number} newprice
 * @param {String} roleCid 
 * @param {String} sepcid 
 */
export async function updateSurgicalEventProcedureThresholdAmount ({
  ledger, newprice, roleCid, sepcid, 
}) {

  try {
    
    if (newprice) {
      let res = await ledger.exercise(HospitalRole.HSEPThresholdAmountUpdate, roleCid, {
        newprice,
        sepcid,
      });
      console.log("[updateSurgicalEventProcedureThresholdAmount]", res);
    }

  }
  catch (e) {
    console.log("[updateSurgicalEventProcedureThresholdAmount]", e);
  }
  
}

/**
 * remove SurgicalEventProcedureThresholdAmount
 * exercise HSEPThresholdAmountArchive choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} roleCid 
 * @param {String} sepcid 
 */
export async function removeSurgicalEventProcedureThresholdAmount ({
  ledger, roleCid, sepcid, 
}) {

  try {
    
    let res = await ledger.exercise(HospitalRole.HSEPThresholdAmountArchive, roleCid, {
      sepcid,
    });
    console.log("[removeSurgicalEventProcedureThresholdAmount]", res);

  }
  catch (e) {
    console.log("[removeSurgicalEventProcedureThresholdAmount]", e);
  }
  
}

/**
 * create SurgicalEventProcedureVendorAmount
 * exercise HSEPVendorAmountCreate choice of HospitalRole
 * @param {Object} ledger 
 * @param {Object} procedure
 * @param {String} roleCid 
 */
export async function createSurgicalEventProcedureVendorAmount ({
  ledger, procedure, roleCid,
}) {

  try {
    
    if (validateSurgicalEventProcedureDisPrice(procedure)) {
      let res = await ledger.exercise(HospitalRole.HSEPVendorAmountCreate, roleCid, {
        procedure: getSurgicalEventProcedureDisPrice(procedure),
      });
      console.log("[createSurgicalEventProcedureVendorAmount]", res);
    }

  }
  catch (e) {
    console.log("[createSurgicalEventProcedureVendorAmount]", e);
  }
  
}

/**
 * update SurgicalEventProcedureVendorAmount
 * exercise HSEPVendorAmountUpdate choice of HospitalRole
 * @param {Object} ledger 
 * @param {Number} newprice
 * @param {String} roleCid 
 * @param {String} sepcid 
 */
export async function updateSurgicalEventProcedureVendorAmount ({
  ledger, newprice, roleCid, sepcid, 
}) {

  try {
    
    if (newprice) {
      let res = await ledger.exercise(HospitalRole.HSEPVendorAmountUpdate, roleCid, {
        newprice,
        sepcid,
      });
      console.log("[updateSurgicalEventProcedureVendorAmount]", res);
    }

  }
  catch (e) {
    console.log("[updateSurgicalEventProcedureVendorAmount]", e);
  }
  
}

/**
 * remove SurgicalEventProcedureVendorAmount
 * exercise HSEPVendorAmountArchive choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} roleCid 
 * @param {String} sepcid 
 */
export async function removeSurgicalEventProcedureVendorAmount ({
  ledger, roleCid, sepcid, 
}) {

  try {
    
    let res = await ledger.exercise(HospitalRole.HSEPVendorAmountArchive, roleCid, {
      sepcid,
    });
    console.log("[removeSurgicalEventProcedureVendorAmount]", res);

  }
  catch (e) {
    console.log("[removeSurgicalEventProcedureVendorAmount]", e);
  }
  
}

/**
 * Remove PrefCard
 * exercise RemovePrefCard choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} roleCid 
 * @param {String} contractid 
 */
export const removePrefCard = async ({
  ledger, roleCid, contractid
}) => {
  try {
      let res = await ledger.exercise(HospitalRole.RemovePrefCard, roleCid, {
        prefid: contractid
      });

      console.log("[Remove Pref Card]", res);
      return true;
  } catch (e) {
      console.error(e);
  }
  return false;
};


/**
 * Update PrefCardDetail
 * exercise UpdatePrefCardDetail choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} roleCid 
 * @param {String} prefId 
 * @param {Object} detailData 
 * @param {Array} totalproducts 
 * @returns 
 */
export async function updatePrefCardDetails({ledger,roleCid,prefId,detailData, totalproducts}){
  try{
    await ledger.exercise(HospitalRole.UpdatePrefCardDetail,roleCid,{
      prefid:prefId,
      prefcarddetails:JSON.stringify(detailData),
      newconsumedproduct: totalproducts
    })
    return true
  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'SE: UpdateCaseUsage', payload: e, params: detailData });
    console.log("error caught");
    console.error(e);
    return false
  }
}

/**
 * This choice will add new pref entry
 * exercise HSEAddPrefCard choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} roleCid 
 * @param {String} seid 
 * @param {Object} prefData 
 */

export async function addPrefCard({ledger,roleCid,seid, prefData}){
  try{
    await ledger.exercise(HospitalRole.HSEAddPrefCard,roleCid,{
      seid,
      prefData
    })
    return true
  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'SE: ADD PrefCard', payload: e, params: prefData });
    console.log("error caught");
    console.error(e);
    return false
  }
}