import React, { useState } from "react";
import classNames from "classnames";
import { useParty, useLedger } from "@daml/react";
import { /* PostProcedureHospital, */ PostProcedureHospitalHistory } from "../../services/daml-modules1";
import Contracts from "../../components/Contracts/Contracts1";
import { contractFilter1 } from "../../services/daml-filters";
import { getTemplate } from "../../services/util";
import ConfirmSimpleModal from "../../components/Modals/ConfirmSimpleModal";
import { archivePPH } from "./handle";
import archiveIcon from "../../icons/folder-plus.svg";
import archiveDisableIcon from "../../icons/folder-plus-disable.svg";
import DetailViewDrawer from "./detail-view-drawer";
import ModalDialogBox from '../../components/Modals/GeneralModal/modalDialogBox';
import { useDamlStream } from "../../hooks/useDAML";
import { useDamlState } from "../../context/DamlContext";
import useStyles from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";
import withFilters from "../../hoc/withFilters";


/**
 * PPF List Contracts in Hospital
 * @param {Boolean} isDetailModalOrPage 
 * @param {Function} setDetailModalOpen 
 * @param {Function} setDetailContract 
 * @param {Object} searchParams 
 * @param {Object} searchHistoryParams 
 * @param {Boolean} isIncludeHistory 
 * @param {Boolean} isAutoVendor 
 * @param {Object} searchProcedure 
 * @param {Array} allContracts 
 * @returns 
 */
function PostProcedureListContracts({
    isDetailModalOrPage = false,
    setDetailModalOpen = null,
    setDetailContract = null,
    searchParams = {},
    searchHistoryParams = {},
    isIncludeHistory = false,
    isAutoVendor = false,
    searchProcedure = {},
    allContracts = [],
}) {

    const classes = useStyles();

    const ledger = useLedger();
    const hospital = useParty();

    const { roleCid } = useDamlState();

    const assetsHistory = useDamlStream(PostProcedureHospitalHistory, [{ hospital }], isIncludeHistory);

    allContracts = allContracts.concat(isIncludeHistory && assetsHistory.contracts ?
        assetsHistory.contracts.map(h => ({ ...h, payload: h.payload.postprocedurehospital })) : []);
    let contracts = contractFilter1(allContracts, searchParams);


    const [poDatalist, setPODatalist] = useState([]);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [confirmRVDialogOpen, setConfirmRVDialogOpen] = useState(false);
    const [rvDatalist, setRvDatalist] = useState([]);
    const [pphData, setPPHData] = useState({});
    const [productList, setProductList] = useState([]);
    const [detailViewer, setDetailViewer] = useState(false);
    const [archiveModalOpen, setArchiveModalOpen] = useState(false);
    const [archiveModalContract, setArchiveModalContract] = useState({});

    const checkNotPPHHistory = (c) => (getTemplate(c) !== "PostProcedureHospitalHistory");


    /**
     * If Release to PostProcedureVendor manually, it should be true
     * If it's impossible to Release to PostProcedureVendor manually, it should be false
     */

    contracts = contracts
        .map(c => {
            // add visual_status into every contract
            // c.payload.surgicaleventdata.visual_status = SurgicalStatus[c.payload.surgicaleventdata.status];
            // /* if (currentStatus && 'edit' in currentStatus && c.contractId in currentStatus.edit) {
            //   c.payload.surgicaleventdata.visual_status = 'Pending';
            // } */
            // if (c.payload.surgicaleventdata.status === 'Scheduled' 
            //   && c.payload.surgicaleventproductdata.length
            //   // && !!c.payload.surgicaleventdata.checked
            //   ) {
            //   c.payload.surgicaleventdata.visual_status = 'Pending';
            // }
            // add Physician Name, Patient Name
            c.payload.surgicaleventdata.patientname = `${c.payload.surgicaleventdata.patientfirstname} ${c.payload.surgicaleventdata.patientlastname}`;
            c.payload.surgicaleventdata.physicianname = `${c.payload.surgicaleventdata.physicianfirstname} ${c.payload.surgicaleventdata.physicianlastname}, ${c.payload.surgicaleventdata.physiciansalutation || ""}`;
            return c;
        });


    // Click "Archive PostProcedure" in list
    const openArchivePPH = (c) => {
        setArchiveModalContract(c);
        setArchiveModalOpen(true);
    };

    const handleArchivePPH = async () => {
        await archivePPH({
            ledger,
            contractId: archiveModalContract.contractId,
            payload: archiveModalContract.payload,
            roleCid,
        });
        setArchiveModalOpen(false);
    };


    return (
        <>
            <Contracts
                contracts={contracts}
                columns={[

                    ["SE Id", "payload.surgicaleventdata.seid", {
                        type: "link",
                        onClick: (contractId) => {
                            const data = contracts.find(c => c.contractId === contractId);
                            setPPHData({ ...data.payload.surgicaleventdata, created_at: data.payload.created_at });
                            setProductList(data.payload.surgicaleventproductdata.map(detail => detail.product));
                            setDetailViewer(true);
                        },
                        search: {
                            type: "AdaptiveSearch",
                            name: "surgicaleventdata.seid",
                            getValue: (c) => c.payload.surgicaleventdata.seid,
                            contracts: allContracts,
                        },
                        options: {
                            flex: 1,
                            headerAlign: 'center',
                            align: 'center',
                            cellClassName: classNames(classes.staticCellelement, classes.primaryColor),
                            headerClassName: classes.staticCellelement,
                        },
                    }],
                    ["MRN", "payload.surgicaleventdata.mrn", {
                        search: {
                            type: "AdaptiveSearch",
                            name: "surgicaleventdata.mrn",
                            getValue: (c) => c.payload.surgicaleventdata.mrn,
                            contracts: allContracts,
                        },
                        options: {
                            width: 150,
                        },
                    }],
                    ["Event Date", "payload.surgicaleventdata.eventdate", {
                        search: {
                            type: "DateSearch",
                            name: "surgicaleventdata.eventdate",
                        },
                        options: {
                            width: 160,
                        },
                    }],
                    ["Patient Name", ["payload.surgicaleventdata.patientfirstname", "payload.surgicaleventdata.patientlastname"], {
                        search: {
                            type: "AdaptiveSearch",
                            name: "surgicaleventdata.patientname",
                            getValue: (c) => c.payload.surgicaleventdata.patientname,
                            contracts: allContracts,
                        },
                        options: {
                            flex: 1,
                            tooltip: true,
                        },
                    }],
                    ["Physician Name", ["payload.surgicaleventdata.physiciansalutation", "payload.surgicaleventdata.physicianfirstname", "payload.surgicaleventdata.physicianlastname"], {
                        search: {
                            type: "AdaptiveSearch",
                            name: "surgicaleventdata.physicianname",
                            getValue: (c) => c.payload.surgicaleventdata.physicianname,
                            contracts: allContracts,
                        },
                        options: {
                            flex: 1,
                        },
                    }],
                    ["Procedure", "payload.surgicaleventdata.surgicalprocedure", {
                        search: {
                            type: "AdaptiveSearch",
                            name: "surgicaleventdata.surgicalprocedure",
                            getValue: (c) => c.payload.surgicaleventdata.surgicalprocedure,
                            contracts: allContracts,
                        },
                        options: {
                            flex: 1,
                        },
                    }],
                    ["Products", "payload.surgicaleventproductdata", {
                        type: "object",
                        options: {
                            cellClassName: classes.centerCell,
                            width: 80,
                        },
                    }],
                    ["Sent Date", "payload.created_at", {
                        options: {
                            width: 150,
                        },
                    }],
                ]}
                actions={[
                    [
                        "Archive",
                        [
                            [["Archive", [<img alt="" src={archiveIcon} />, <img alt="" src={archiveDisableIcon} />],
                                (c) => checkNotPPHHistory(c)],
                                openArchivePPH],
                        ],
                        { width: 100 }
                    ],
                ]}

                options={{
                    statusEmpty: (allContracts.length === 0),
                    checkbox: true,
                }}
            />
            <ConfirmSimpleModal
                modaltitle="Purchase Order Products are created"
                contractList={poDatalist}
                setContractList={setPODatalist}
                textFunction={(d) => `You have created ${d} new Purchase Order(s).`}
                mapFunction={(c) => `${c["PO Number"]} for Vendor ${c["Vendor"]}`}
                confirmModalOpen={confirmModalOpen}
                setConfirmModalOpen={setConfirmModalOpen}
                confirmDialogOpen={confirmDialogOpen}
                setConfirmDialogOpen={setConfirmDialogOpen}
            />

            <ConfirmSimpleModal
                modaltitle="PostSurgeryForms are created"
                contractList={rvDatalist}
                setContractList={setRvDatalist}
                textFunction={(d) => `You have created ${d} new PostSurgeryForm(s).`}
                mapFunction={(c) => `${c["surgicaleventproduct"].length} products for MRN ${c["mrn"]}`}
                confirmDialogOpen={confirmRVDialogOpen}
                setConfirmDialogOpen={setConfirmRVDialogOpen}
            />

            <DetailViewDrawer
                state={detailViewer}
                toggleDrawer={(open) => setDetailViewer(open)}
                selectedEventData={pphData}
                productItemsList={productList}
                isPPH={true}
            />

            <ModalDialogBox
                open={archiveModalOpen}
                setOpen={setArchiveModalOpen}
                heading="Confirm"
                type="warning"
                text={'<p>Do you want to archive Post Procedure Form now ?</p>'}
                handleRequest={handleArchivePPH}
                secondBtn={'No'}
                handleSecRequest={() => setArchiveModalOpen(false)}
            />

        </>
    );
}

const PostProcedureListContractsWithLoading = withLoading(PostProcedureListContracts);
export default withFilters(PostProcedureListContractsWithLoading)([
    { name: "surgicaleventdata.seid", label: "SE ID" },
    { name: "surgicaleventdata.mrn", label: "MRN" },
    { name: "surgicaleventdata.eventdate", label: "Date Range", type: "date" },
    { name: "surgicaleventdata.patientname", label: "Patient Name" },
    { name: "surgicaleventdata.physicianname", label: "Physician Name" },
    { name: "surgicaleventdata.surgicalprocedure", label: "Procedure" },
    { name: "surgicaleventproductdata", label: "Products" },
    { name: "created_at", label: "Sent Date", type: "date" },
    { name: "surgicaleventdata.status", label: "Status" },
]);