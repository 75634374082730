import React, { useState } from "react";
import { Grid, CircularProgress, Typography, Button, TextField, Fade, Checkbox, Container, FormControlLabel } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { useUserDispatch, useUserState, loginUser, loginDablUser } from "../../context/UserContext";
import { isProjectDabl, auth0 } from "../../config";
import AlertModal from "../../components/Modals/AlertModal";
import logotydei from "./logotydei.png";
import { useCookies } from 'react-cookie';
import AuthLoginButton from './AuthLoginButton';
import useStyles from "./styles";
import { useAuth0 } from "@auth0/auth0-react";
function Login(props) {
  var classes = useStyles();
  const [cookies, setCookie] = useCookies(['welcomeModal']);
  // global
  var userDispatch = useUserDispatch();
  const user = useUserState(); 
  const { logout } = useAuth0();
  /**
   * show only Auth Login Button
   * if it's true, then shows only AuthLogin
   * else, then shows all components
   */
  const isOnlyAuthLogin = auth0.isOnlyAuthLogin;
  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(false);
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [temporaryPasswordValue, setTemporaryPasswordValue] = useState("");
  var [isLoginAsNewUser, setLoginAsNewUser] = useState(false);
  const [errorMsg, setErrorMessage] = useState(user.errorMessage);
  setCookie('welcomeModal', "false", { path: '/' });
  const forgotPassword = () => {
    console.log("[forgotPassword]", cookies);
  };

  const logoutHandler = () => {
    logout();
  }
    return (
    <Container  className={classes.container} p={5} sx={{ justifyContent: 'center' }}>
      <Grid container spacing={2} p={0} sx={{ mx: "auto", width: 800 }}>
        <Grid item md={6} xs={12}>
        <div className={classes.logotypeContainer}>
            <img src={logotydei} alt="logo" className={classes.logotypeImage} />
            <Typography className={classes.logotypeText}>TYDEi Health Login</Typography>
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
        <div className={classes.formContainer}>
            <div className={classes.form}>
              <React.Fragment>
                <Fade in={!!error && !isLoading}>
                  <Typography color="secondary" className={classes.errorMessage}>
                    {(typeof error === 'string') ? error : "Something is wrong with your login or password :("}
                  </Typography>
                </Fade>
                <Fade in={!!error && isLoading}>
                  <Typography color="secondary" className={classes.errorMessage}>
                    {error}
                    {' '}
                    <Button className={classes.dablLoginButton} variant="contained" color="primary" size="large"  onClick={logoutHandler}>Click here to login again</Button>
                  </Typography>
                </Fade>
                {isProjectDabl &&
                  <>
                    <Button className={classes.dablLoginButton} variant="contained" color="primary" size="large" onClick={loginDablUser}>
                      Log in with DABL
                    </Button>
                    <Typography>
                      OR
                    </Typography>
                  </>}
                {!isOnlyAuthLogin &&
                  <>
                    <TextField
                      id="email"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={loginValue}
                      onChange={e => setLoginValue(e.target.value)}
                      onKeyDown={e => {
                        if (e.key === "Enter") {
                          loginUser({
                            dispatch: userDispatch,
                            party: loginValue,
                            password: passwordValue,
                            oldPassword: temporaryPasswordValue,
                            history: props.history,
                            setLoading: setIsLoading,
                            setError,
                          });
                        }
                      }}
                      margin="normal"
                      placeholder="Username"
                      type="email"
                      fullWidth
                    />
                    {
                      isLoginAsNewUser &&
                      <TextField
                        id="temporarypassword"
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={temporaryPasswordValue}
                        onChange={e => setTemporaryPasswordValue(e.target.value)}
                        margin="normal"
                        placeholder="Temporary Password"
                        type="password"
                        fullWidth
                      />
                    }
                    <TextField
                      id="password"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={passwordValue}
                      onChange={e => setPasswordValue(e.target.value)}
                      onKeyDown={e => {
                        if (e.key === "Enter") {
                          loginUser({
                            dispatch: userDispatch,
                            party: loginValue,
                            password: passwordValue,
                            oldPassword: temporaryPasswordValue,
                            history: props.history,
                            setLoading: setIsLoading,
                            setError,
                          });
                        }
                      }}
                      margin="normal"
                      placeholder="Password"
                      type="password"
                      fullWidth
                    />
                    <FormControlLabel
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          checked={isLoginAsNewUser}
                          onChange={(e) => {
                            setLoginAsNewUser(e.target.checked);
                            setTemporaryPasswordValue("");
                          }}
                          name="checkloginasnewuser"
                          color="primary"
                        />
                      }
                      label="Log in as new user"
                    />
                  </>}
                <div className={classes.formButtons}>
                  {isLoading &&
                    <CircularProgress size={26} className={classes.loginLoader} />
                  }
                  <Grid container spacing={5}>
                    {!isOnlyAuthLogin && !isLoading &&
                      <Grid item xs={12}>
                        <Button
                          disabled={loginValue.length === 0}
                          onClick={() =>
                            loginUser({
                              dispatch: userDispatch,
                              party: loginValue,
                              password: passwordValue,
                              oldPassword: temporaryPasswordValue,
                              history: props.history,
                              setLoading: setIsLoading,
                              setError,
                            })
                          }
                          variant="contained"
                          color="primary"
                          size="large"
                          className={classes.btn}
                        >
                          Login
                        </Button>
                        <Button
                          onClick={forgotPassword}
                          variant="text"
                          color="secondary"
                          className={classes.btnText}
                        >
                          Forgot Password
                        </Button>
                      </Grid>
                    }
                    <Grid item xs={12}>
                      <AuthLoginButton
                        history={props.history}
                        classes={classes}
                        setLoading={setIsLoading}
                        setError={setError}
                      />
                    </Grid>
                  </Grid>
                </div>
              </React.Fragment>
            </div>
          </div>
        </Grid>
        <AlertModal
          modaltitle="Error"
          modalText={errorMsg}
          setDetailValue={() => setErrorMessage('')}
          modalOpenController={errorMsg !== ''}
          cancelHandler={() => setErrorMessage('')}
          isButtonVisible={false}
        />
              </Grid>
    </Container>
  );
}
export default withRouter(Login);
