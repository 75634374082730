import React, { useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import { useLedger } from "@daml/react";
import { Button, Grid, Typography, Box } from "@material-ui/core";
import { useDamlState } from "../../context/DamlContext";
import { useLayoutDispatch, setLoading, unsetLoading } from "../../context/LayoutContext";
import { contractFilter1 } from "../../services/daml-filters";
import { getTodayString, getQuantityOnOrder } from "../../services/util";
import { uploadFileCreatePO } from "../../services/axios/api-node_server";
import { requiredPurData } from "../../models/Po";
import { isEnableEdit } from "../../models/POStatus";
import { createPODetail } from "../../models/PODetail";
import { requiredProduct } from "../../models/Product";
import { CustomTextArea } from "../../components/Inputs";
import ModalDialog from '../../components/Modals/NewGeneralModal';
import SnackbarModal from "../../components/Modals/SnackbarModal";
import FileUploadDropzone from "../../components/FileUpload/FileUploadDropzone";
import {
  PRODUCT_ADD_EVENT, PRODUCT_UPDATE_EVENT, PRODUCT_DELETE_EVENT, PO_CREATE_EVENT, PO_SEND_EVENT, PO_INVOICE_EVENT,
  EVENT_REQUIRED, EVENT_VERIFIED, EVENT_FINISHED, PO_NOTE_EVENT, PO_COMMENT_EVENT
} from "../../store/actions/constants";
import useProduct, { useHospitalUser, useItemDetails, useSurgicalEvent } from "../../hooks/useProduct";
import usePO, { useComment } from "../../hooks/usePO";
import useEvent from "../../hooks/useEvent";
import patientIcon from "../../icons/new-se-patient-icon.svg";
import folderIcon from "../../icons/mdi_folder-upload.svg";
import csvIcon from "../../icons/iwwa_file-csv.svg";
import warningCircleIcon from "../../icons/warning-circle-mark.svg";
import useStyles from "../surgicalevent/styles";
import { defaultProduct } from "../surgicalevent/edit-product1";
import AddPO from './add-po';
import EditPO from './edit-po';
import ProductsList from "./products-list";
import { createUpdatePO } from "./purchaseorderlist";
import { handleToChangeOwnership } from "./purchaseorderlistcontracts";
import {
  removePODetailData, updatePODetailData, handlePOStatus, createInvoice, SendPov, updatePONotesHospital,
  addPOCommentHospital, addPOCommentVendor, updatePONotesVendor,
} from "./handle";
import useStyles1 from "./styles";
import { searchSE } from "./se-details-modal";
import { useUserState } from "../../context/UserContext";
import { updatePhysicianDetails } from "../surgicalevent/handle";
import closeIcon from '../../icons/new-close-icon.svg'
import { useTranslation } from 'react-i18next';


const DeclineWarning = () => {
  const classes = useStyles1();

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      className={classes.declineWarning}
    >
      <Grid item>
        <Typography>Purchase Order</Typography>
        <Typography>DECLINED</Typography>
      </Grid>
      <img src={warningCircleIcon} alt="" />
    </Grid>
  );
};

/**
 * Purchase Order Title Component
 * @param {Object} classes 
 * @param {Object} classes1 
 * @param {Boolean} isCreate 
 * @returns 
 */
const POTitle = ({
  classes, classes1, isCreate,
}) => {

  const { role } = useDamlState();
  const { purchaseOrderData } = usePO();

  return (
    isCreate
      ?
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.newSETitlePanel}
      >
        <Typography variant="inherit" component="span" className="name" >
          Create PO
        </Typography>
      </Grid>
      :
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        className={classNames(classes.newSETitlePanel, classes1.newPOTitlePanel)}
      >
        <img src={patientIcon} alt="" />
        <Typography variant="inherit" component="span" className="label" >
          Purchase Order
        </Typography>
        <Typography variant="inherit" component="span" className="value" >
          {purchaseOrderData.popurchaseorderid}
        </Typography>
        {
          role === 'Hospital' && (purchaseOrderData.postatus === 'Rejected' || purchaseOrderData.postatus === 'Updated') &&
          <DeclineWarning />
        }
      </Grid>
  );
};

// preview icon of uploader
export const handlePreviewIcon = (fileObject, classes) => {
  const { type, name } = fileObject.file;
  console.log("[handlePreviewIcon]", type, name, classes);

  return (
    <Box className="preview-item">
      <img src={csvIcon} className={classes.image} alt="" />
      {name}
    </Box>
  );
};

/**
 * Purchase Order Body Panel Component
 * @param {Object} options 
 * @returns 
 */
export const POPanel = ({
  options = {},
}) => {

  options = {
    isAddProduct: false,
    isEditable: false,
    isCreate: false,
    isSend: false,
    showFile: false,
    isremovePodetails: false,
    setShowFile: () => { },
    isUpload: true,
    ...options,
  };

  const classes = useStyles1();
  const classes1 = useStyles();

  const { purchaseOrderData, setPurchaseOrder, setPurchaseOrderErrors } = usePO();
  const [disableFileUpload, setDisableFileUpload] = useState(true);
  const { product, setProduct } = useProduct();
  const { onEventStart } = useEvent();
  const [openToast, setOpenToast] = useState(false);
  useEffect(() => {
    if (purchaseOrderData.popurchaseordertype && purchaseOrderData.povendorid) setDisableFileUpload(false);
  }, [purchaseOrderData]);

  // create PO by uploading csv
  const handleUpload = (file, callback) => {
    setPurchaseOrder({
      file,
    });

    // here old workflow is we need to click on add button to populate product, but below code will populate products as soon as you upload csv
    if (file) setOpenToast(true);
  };

  const handleFileUpload = () => {
    // if distributor or owner is not seletced, then this will display error on their respective text field
    if (disableFileUpload) {
      const errors = requiredPurData(purchaseOrderData);
      if (Object.entries(errors).length > 0) setPurchaseOrderErrors(errors);
    }
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        className={classes.createPanel}
      >
        <Grid item>
          {
            options.isCreate
              ?
              <AddPO
                options={{
                }}
              />
              :
              purchaseOrderData.sideAddPanel ?
                <>
                  <img onClick={() => {
                    setPurchaseOrder({ ...purchaseOrderData, sideAddPanel: !purchaseOrderData.sideAddPanel })
                  }} src={closeIcon} alt="" className={classNames(classes1.addProductclosebtn)} />
                  <AddPO
                    options={{
                      isremovePodetails: true
                    }}
                  />
                  <POActionButtons
                    classes={classes1}
                    classes1={classes}
                    isEditable={options.isEditable}
                    isAddProduct={true}
                    isCreate={options.isCreate}
                    isSend={options.isSend}
                  />
                </> :
                <EditPO
                  options={options}
                />
          }
        </Grid>

        <Grid item>
          <ProductsList
            isEditable={options.isEditable}
            isSend={options.isSend}
            isCreate={options.isCreate}
          />

          {
            options.isUpload &&
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <CustomTextArea
                  label="Note:"
                  placeholder="Type here..."
                  value={purchaseOrderData.ponotes || ""}
                  onChange={(val) => setPurchaseOrder({
                    ponotes: val,
                  })}
                  minRows={3}
                  options={{
                    // disabled: !isEditable,
                    labelLeft: true,
                    labelWidth: 40,
                    maxHeight: 120,
                  }}
                />
              </Grid>

              <Grid item>
                {
                  options.isCreate
                    ?
                    <Grid onClick={handleFileUpload}>
                      <FileUploadDropzone
                        className={classes.uploadFile}
                        title=""
                        uploadLabel={(
                          <Box className="drag-drop-btn">
                            Drag and Drop file to upload
                            <img src={folderIcon} alt="" />
                          </Box>
                        )}
                        changeFile={handleUpload}
                        isNonePadding={true}
                        isEnableUpload={false}
                        handlePreviewIcon={handlePreviewIcon}
                        disableFileUpload={disableFileUpload}
                        showFile={options.showFile}
                        setShowFile={options.setShowFile}
                      />
                    </Grid>
                    :
                    <Box style={{ height: '120px' }}></Box>
                }
              </Grid>

            </Grid>
          }
        </Grid>
      </Grid>

      <SnackbarModal
        open={openToast}
        setOpen={setOpenToast}
        type="warning"
        title="Are you sure ? you want to upload File"
        content=" "
        vertical="center"
        horizontal="center"
        width={500}
        height={117}
        transition="fade"
        className={classes.toastLarge}
        options={{
          delay: 500,
        }}
        actions={[
          {
            label: 'Yes',
            handle: () => {
              setProduct({
                ...product,
                "sepreferencenumber": "1",
                "sepproducttype": "Implant",
                "sepownership": "Owned"
              });
              onEventStart(PRODUCT_ADD_EVENT);
            },
          },
          {
            label: 'No',
            handle: () => {
              setOpenToast(false)
            },
          }
        ]}
      />
    </>
  );
};

/**
 * Purchase Order Action Buttons Component
 * @param {Object} classes 
 * @param {Object} classes1 
 * @param {Boolean} isEditable 
 * @param {Boolean} isCreate 
 * @param {Boolean} isSend 
 * @param {Boolean} isNew 
 * @param {Boolean} isAddProduct
 * @returns 
 */
const POActionButtons = ({
  classes, classes1, isEditable = true, isAddProduct = false, isCreate = false, isSend = false, isNew = false, isPhysicianUpdated = false
}) => {

  const { role, roleCid } = useDamlState();
  const { clearProduct, setProduct } = useProduct();
  const { purchaseOrderData } = usePO();
  const { comment: poComment, setComment: setPoComment } = useComment();

  const ledger = useLedger();
  const { surgicalevent } = useSurgicalEvent();
  const layoutDispatch = useLayoutDispatch();
  const { onEventRun, onEventEnd } = useEvent();

  const { hospital } = useHospitalUser();
  const { token } = useUserState();
  const [openToast, setOpenToast] = useState(false);
  const [openToastContent, setOpenToastContent] = useState("");

  const isSave = (isEditable || isSend || isCreate);
  const { event, status, onEventStart } = useEvent();

  // handle to click Clear button
  const handleClearClick = () => {
    // default Product from PO
    clearProduct(defaultProduct);
  };

  // handle to click Add button
  const handleAddClick = () => {
    setProduct({
      sepownership: purchaseOrderData.popurchaseordertype,
      sepdistributor: purchaseOrderData.povendorid,
    });
    /* if (isNew) {
      // Always isCreate is true
      onEventStart(PO_CREATE_EVENT);
    }
    else {
      onEventStart(PRODUCT_ADD_EVENT);
    } */
    onEventStart(PRODUCT_ADD_EVENT);
  };

  // handle to click Save button
  const handleSaveClick = async () => {
    // console.log("[handleSaveClick] isSave", isSave);
    // let isPhysicianUpdated=false;
    if (isSave) {
      //checking if first name and last name is not empty
      if (surgicalevent.physicianfirstname !== "" && surgicalevent.physicianlastname !== "") {
        //finding contract id using SE ID
        const contract = await searchSE({
          seid: surgicalevent.seid, token, hospital,
        });
        //checking if any new data is entered or not, if user enters old data then it won't call DAML Choice
        if (contract && (contract.payload.surgicaleventdata.physiciansalutation !== surgicalevent.physiciansalutation
          || contract.payload.surgicaleventdata.physicianfirstname !== surgicalevent.physicianfirstname
          || contract.payload.surgicaleventdata.physicianlastname !== surgicalevent.physicianlastname
        )) {
          setLoading(layoutDispatch);
          onEventRun();
          const result = await updatePhysicianDetails({
            roleCid,
            ledger,
            contractId: contract.contractId,
            physiciansalutation: surgicalevent.physiciansalutation,
            physicianfirstname: surgicalevent.physicianfirstname,
            physicianlastname: surgicalevent.physicianlastname,
          })
          if (result) {
            isPhysicianUpdated = true;
            setOpenToastContent("Physician Details Updated")
            setOpenToast(true);
            unsetLoading(layoutDispatch);
            onEventEnd();
          }
        }
      }

      // Create or Update PO
      onEventStart(PO_CREATE_EVENT);
      // if(!isPhysicianUpdated)
      // {
      //   setOpenToastContent("The PO successfully saved")
      //   setOpenToast(true);
      // }

    }
    else {
      // Update only PO Notes
      onEventStart(PO_NOTE_EVENT);
    }
  };

  useEffect(() => {
    if (event === PO_CREATE_EVENT && !isPhysicianUpdated && (status === EVENT_FINISHED)) {
      setOpenToastContent("The PO successfully saved")
      setOpenToast(true);
    }
  }, [event, status, isPhysicianUpdated]);

  // handle to click Send button
  const handleSendClick = () => {
    onEventStart(PO_SEND_EVENT);
  };

  // handle to click Accept button
  const handleAcceptClick = () => {
    setPoComment({
      ...poComment,
      status: 'Accepted'
    });
    onEventStart(PO_SEND_EVENT);
  };

  // handle to click Decline button
  const handleDeclineClick = () => {
    setPoComment({
      ...poComment,
      status: 'Rejected'
    });
    onEventStart(PO_SEND_EVENT);
  };


  // if (!isEditable && !isSend && !isCreate) return <></>;

  return (
    <>
      {isAddProduct ?
        (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddClick}
              className={classNames(
                classes.addProduct,
                classes.newCompleteBtn,
                classes.newInsideAddBtn,
                classes1.completeBtn,
                classes1.addBtn
              )}
            >Add
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClearClick}
              className={classNames(
                classes.newCompleteBtn,
                classes.newInsideAddBtn,
                "secondary-color",
                classes1.completeBtn
              )}
            >
              Clear
            </Button>
          </>
        ) :
        (

          <>
            {
              isCreate &&
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClearClick}
                  className={classNames(classes.newCompleteBtn, classes.newInsideAddBtn, "secondary-color", classes1.completeBtn)}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddClick}
                  className={classNames(classes.newCompleteBtn, classes.newInsideAddBtn, classes1.completeBtn, classes1.addBtn)}
                >
                  Add
                </Button>
              </>
            }
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveClick}
              className={classNames(classes.newCompleteBtn, "secondary-color")}
            >
              Save
            </Button>
            {
              role === 'Hospital'
                ?
                (
                  // (isSend || isCreate) &&
                  isSave &&
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSendClick}
                    className={classes.newCompleteBtn}
                  >
                    Send PO
                  </Button>
                )
                :
                (
                  isSend &&
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleDeclineClick}
                      className={classNames(classes.newCompleteBtn, "secondary-color")}
                    >
                      Decline
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAcceptClick}
                      className={classes.newCompleteBtn}
                    >
                      Accept
                    </Button>
                  </>
                )
            }
          </>
        )}
      <SnackbarModal
        open={openToast}
        setOpen={setOpenToast}
        title=""
        content={openToastContent}
        width={330}
        height={88}
        className={classes.toastMedium}
      />
    </>
  );
};

/**
 * Dialog when Create or Edit Purchase Order
 * @param {Boolean} open
      * @param {Function} setOpen
      * @param {Object} contract
      * @param {Array} contracts
      * @param {Boolean} isSend
      */
export default function CreatePurchaseOrder({
  open, setOpen, contract = {}, contracts = [], isSend = false,
}) {
  // console.log("[CreatePurchaseOrder]", contract, contracts);

  const classes = useStyles();
  const classes1 = useStyles1();

  const { role, party, userParty, roleCid, userlistPayload } = useDamlState();
  // const hospital = (role === 'Hospital' ? party : null);
  const defaultUser = userParty ? userParty : party;

  const layoutDispatch = useLayoutDispatch();

  const ledger = useLedger();

  const { t } = useTranslation();

  const { setAll: setDefault, product: productItems, clearProduct, isChanged } = useProduct();
  const { product: productEditItems } = useProduct(true);
  const { setAll: setDefaultPO, purchaseOrderData } = usePO();
  const { comment: poComment, setComment: setPoComment } = useComment();
  const { details, itemIndex, setDetails } = useItemDetails();
  const { event, status, onEventRun, onEventEnd, onEventClear } = useEvent();

  const { contractId, payload } = contract;

  // Create|Edit
  const isCreate = !contractId;
  // Hospital
  const hospital = (role === 'Hospital' ? party : (isCreate ? null : payload.hospital));
  // Editable
  const [isEditable, setEditable] = useState(false);

  // ContractId is contractId of Purchase Order
  const [ContractId, setContractId] = useState(null);
  // payload of Purchase Order dynamically
  const [dynamicPayload, setDynamicPayload] = useState({});
  // Old Contracts PO number list
  const [oldponumbers, setOldponumbers] = useState([]);
  // Error result after choice is called
  const [errorRes, setErrorRes] = useState(false);

  const [showFile, setShowFile] = useState(false);
  const [toastMsgType, setToastMsgType] = useState("success");

  // Toast
  const [openToast, setOpenToast] = useState(false);
  const [toastContent, setToastContent] = useState('');
  const [snackbar, setSnackbar] = useState(false);

  // handle to close
  const handleClose = () => {
    setSnackbar(false);
    setOpen(false);
  };

  const handleCloseRequest = () => {
    if (isEditable && isChanged) {
      setSnackbar(true);
    }
    else {
      handleClose();
    }
  }


  // get updatedetail & ownership of it
  const getOwnershipOfDetail = useCallback(() => {
    const updateDetail = {
      ...details[itemIndex],
      podproduct: productEditItems,
      // poduom: productEditItems.poduom,
      podquantity: productEditItems.podquantity,
    };
    let ownership = null;
    if (productEditItems?.sepownership !== details[itemIndex]?.podproduct?.sepownership) {
      ownership = productEditItems?.sepownership;
    }
    // console.log("[CreatePurchaseOrder] getOwnershipOfDetail", updateDetail, ownership);
    return { updateDetail, ownership };
  }, [details, itemIndex, productEditItems]);

  // Update ContractId & payload
  const updateContract = useCallback(() => {
    let { popurchaseorderid } = purchaseOrderData;
    let msg = '';
    if (!popurchaseorderid) {
      // created - search new po number
      if (contracts.length > oldponumbers.length) {
        for (let i in contracts) {
          const newponumber = contracts[i]?.payload?.purchaseorderdata?.popurchaseorderid;
          if (oldponumbers.indexOf(newponumber) < 0) {
            popurchaseorderid = newponumber;
            break;
          }
        }
      }
    }
    console.log("[CreatePurchaseOrder] updateContract", popurchaseorderid, event);
    if (!popurchaseorderid && toastMsgType === "success") {
      // added a product in "Create PO"
      msg = "PO successfully created";
    }
    if (popurchaseorderid) {

      // find out the PO contract by PO number
      const found = contractFilter1(contracts, {
        'list.purchaseorderdata.popurchaseorderid': [popurchaseorderid],
      });

      if (found && found.length) {
        const c = found[0];
        console.log("[CreatePurchaseOrder] updateContract", c);

        if (ContractId !== c.contractId) {
          // When the ownership of detail is changed,
          if (event === PRODUCT_UPDATE_EVENT) {
            const { updateDetail, ownership } = getOwnershipOfDetail();
            if (ownership) {
              const targetFound = contractFilter1(contracts, {
                'list.purchaseorderdetaildata.id': [updateDetail.id],
              });
              const targetPOId = (targetFound && targetFound.length) ?
                targetFound[0].payload.purchaseorderdata.popurchaseorderid : "";
              console.log("[CreatePurchaseOrder] updateContract", targetFound, targetPOId);
              if (popurchaseorderid !== targetPOId) msg = `The item is moved from ${popurchaseorderid} to ${targetPOId}`;
            }
          }

          if (!!msg || errorRes) {
            // message is already filled out
          }
          else if (!ContractId) {
            msg = 'PO successfully created';
          }
          else if (purchaseOrderData.popurchaseordertype !== c.payload.purchaseorderdata.popurchaseordertype) {
            msg = 'Ownership successfully changed';
          }
          else if (details.length < c.payload.purchaseorderdetaildata.length) {
            msg = 'Product successfully added';
          }
          else if (details.length > c.payload.purchaseorderdetaildata.length) {
            msg = 'Product successfully removed';
          }
          else if (purchaseOrderData.postatus !== c.payload.purchaseorderdata.postatus) {
            if (c.payload.purchaseorderdata.postatus === 'SentVendor' || c.payload.purchaseorderdata.postatus === 'SentUpdate') {
              msg = 'The PO has been successfully sent to the Vendor';
            }
            else if (c.payload.purchaseorderdata.postatus === 'Accepted') {
              msg = 'You have successfully accepted the Purchase Order';
            }
            else if (c.payload.purchaseorderdata.postatus === 'Rejected') {
              msg = 'You have successfully declined the Purchase Order';
            }
            else if (c.payload.purchaseorderdata.postatus === 'InvoiceCreated') {
              msg = 'Invoice successfully created. The facility has been alerted.';
            }
            else {
              msg = 'Changes successfully saved';
            }
          }
          else {
            msg = 'Changes successfully saved';
            // msg = 'Price successfully changed';
          }

          setContractId(c.contractId);
          setDefaultPO({
            purchaseOrderData: {
              ...purchaseOrderData,
              ...c.payload.purchaseorderdata,
            },
            comments: c.payload.purchaseordercomment,
          });
          setDetails(getPODetailQoO(c.payload.purchaseorderdetaildata));
          setDynamicPayload(c.payload);
          if(!ContractId) defaultProduct.sepdistributor = c?.payload?.vendor;
          clearProduct(defaultProduct);
          onEventClear();

        }
      }
    }
    if (errorRes === 500) {
      msg = "Something went wrong";
      setErrorRes(false);
    }
    // toast
    if (msg) {
      setToastContent(msg);
      setOpenToast(true);
    }

  }, [toastMsgType, purchaseOrderData, details, contracts, setContractId, oldponumbers, ContractId, onEventClear,
    setDefaultPO, setDetails, clearProduct, event, getOwnershipOfDetail, errorRes]);

  // Click "Create" or "Save Update"
  const addPurchaseOrder = useCallback(async (isAdd = true) => {
    if (!isEditable) return;
    console.log("[addPurchaseOrder]", dynamicPayload);

    // spinner
    setLoading(layoutDispatch);
    onEventRun();

    // store old contracts
    setOldponumbers(contracts.map(c => c.payload.purchaseorderdata.popurchaseorderid));

    // check out file
    if (purchaseOrderData.file && !ContractId) {
      const res = await uploadFileCreatePO(purchaseOrderData.file, {
        hospital,
        vendor: purchaseOrderData.povendorid,
        ownership: purchaseOrderData.popurchaseordertype,
        potype: purchaseOrderData.potype,
        notes: purchaseOrderData.ponotes,
      });
      if (res.error) {
        setErrorRes(500);
        setToastMsgType("error")
      }
      else {
        setErrorRes(false);
        setShowFile(true);
        setToastMsgType("success")
      }
    }
    else {

      // old po details + new po detail
      let newpodetails = [...details];
      const newErrors = requiredProduct(productItems);
      if (Object.keys(newErrors).length === 0) {
        const newDetail = createPODetail({
          // podetail: {podproduct: productItems, poduom: productItems.poduom},
          podetail: { podproduct: productItems },
          notes: productItems.podnotes,
          quantity: productItems.podquantity,
        });
        newpodetails.push(newDetail);
      }

      // Always create a new PO even if the same PO already exists.
      await createUpdatePO({
        ledger, ContractId, contracts, hospital, roleCid, isCreate: !ContractId,
        purchaseOrderData, purchaseOrderDetailData: newpodetails,
        surgicalEventData: { seid: purchaseOrderData.poseid }, setOpenModal: () => { },
        user: defaultUser, role, payload: dynamicPayload, userlistPayload, isAddInsteadCreate: false,
      });
      setErrorRes(false);
    }

    unsetLoading(layoutDispatch);
    onEventEnd();

    if (!isAdd) setOpen(false);

  }, [isEditable, layoutDispatch, onEventRun, onEventEnd, ContractId, contracts, hospital, ledger, defaultUser,
    role, roleCid, purchaseOrderData, details, productItems, userlistPayload, dynamicPayload, setOpen]);

  // Click Delete Icon in PO modal to remove PO Detail
  const handleDeleteProduct = useCallback(async () => {
    if (!isEditable) return;

    const removeProduct = details[itemIndex];

    // spinner
    setLoading(layoutDispatch);
    onEventRun();

    const res = await removePODetailData({
      ledger,
      contractId: ContractId,
      podetail: removeProduct,
      podetailindex: itemIndex,
      roleCid,
      user: defaultUser,
      role,
      payload: dynamicPayload,
    });
    if (!res) setErrorRes(true);
    else setErrorRes(false);

    unsetLoading(layoutDispatch);
    onEventEnd();

  }, [isEditable, layoutDispatch, onEventRun, onEventEnd, ContractId, ledger, defaultUser,
    role, roleCid, details, itemIndex, dynamicPayload]);

  // Click "Update" in PO modal to update PO Detail
  const handleUpdateProduct = useCallback(async () => {
    if (!isEditable) return;

    const { updateDetail, ownership } = getOwnershipOfDetail();

    // spinner
    setLoading(layoutDispatch);
    onEventRun();

    let res = false;
    const newpayload = await updatePODetailData({
      ledger,
      contractId: ContractId,
      podetail: updateDetail,
      podetailindex: itemIndex,
      roleCid,
      user: defaultUser,
      role,
      payload: dynamicPayload,
    });

    if (newpayload && newpayload.contractId && ownership) {
      // Change ownership of Product
      res = await handleToChangeOwnership({
        ledger, hospital,
        roleCid,
        contracts: contracts,
        ownership,
        product: {
          contractId: newpayload.contractId,
          purchaseorderdata: newpayload.purchaseorderdata,
          ...newpayload.purchaseorderdetaildata[itemIndex],
        },
        podetailindex: itemIndex,
        payload: newpayload,
        user: defaultUser,
        role,
      });
    }
    if (!res) setErrorRes(true);
    else setErrorRes(false);

    unsetLoading(layoutDispatch);
    onEventEnd();

  }, [isEditable, layoutDispatch, onEventRun, onEventEnd, ContractId, ledger, defaultUser, contracts, hospital,
    role, roleCid, itemIndex, getOwnershipOfDetail, dynamicPayload]);

  // Click "Send" button to Send PO
  const handleSendPO = useCallback(async () => {
    if (!(isSend || isEditable) || !ContractId) return;

    // spinner
    setLoading(layoutDispatch);
    onEventRun();
    let res = false;
    if (role === 'Hospital') {
      // send PO to Vendor
      res = await SendPov({
        ledger,
        ContractId,
        roleCid,
        email: userlistPayload?.email,
        username: userlistPayload?.name,
        pocomment: {
          ...poComment,
          date: getTodayString(),
          user: defaultUser,
        },
      });
    }
    else if (role === 'Vendor') {
      // send PO to Hospital
      res = await handlePOStatus({
        pocomment: {
          ...poComment,
          date: getTodayString(),
          user: defaultUser,
        },
        postatus: poComment.status,
        poHospital: hospital,
        ledger,
        ContractId,
        roleCid,
        email: userlistPayload?.email,
        username: userlistPayload?.name,
      });
    }
    if (!res) setErrorRes(true);
    else setErrorRes(false);

    unsetLoading(layoutDispatch);
    onEventEnd();

    setOpen(false);

  }, [layoutDispatch, onEventRun, onEventEnd, ContractId, ledger, defaultUser, role, roleCid, poComment, setOpen, userlistPayload,
    hospital, isEditable, isSend]);

  // Click "Create Invoice" button to create invoice
  const handleCreateInvoice = useCallback(async () => {
    if (!ContractId || role !== 'Vendor') return;

    // spinner
    setLoading(layoutDispatch);
    onEventRun();

    const res = await createInvoice({
      contractId: ContractId,
      payload: dynamicPayload,
      ledger,
      roleCid,
      userlistPayload,
    });
    if (!res) setErrorRes(true);
    else setErrorRes(false);

    unsetLoading(layoutDispatch);
    onEventEnd();

    setOpen(false);

  }, [layoutDispatch, onEventRun, onEventEnd, ContractId, ledger, role, roleCid, userlistPayload, setOpen, dynamicPayload]);

  // handle to send PO Comment
  const handleSendComment = useCallback(async () => {
    // console.log("[handleSendComment]", poComment);

    onEventEnd();

    if (!ContractId) return;

    // spinner
    setLoading(layoutDispatch);
    onEventRun();

    let res = false;
    if (role === 'Hospital') {
      res = await addPOCommentHospital({
        ledger, roleCid, pocid: ContractId, newpocomment: poComment,
      });
    }
    else if (role === 'Vendor') {
      res = await addPOCommentVendor({
        ledger, roleCid, povcid: ContractId, newpocomment: poComment,
      });
    }
    if (!res) setErrorRes(true);
    else setErrorRes(false);

    setPoComment({});

    unsetLoading(layoutDispatch);
    onEventEnd();

  }, [layoutDispatch, onEventRun, onEventEnd, ContractId, ledger, role, roleCid, poComment, setPoComment]);

  // handler to update PO Notes
  const handleUpdateNotes = useCallback(async () => {
    // console.log("[handleUpdateNotes]", purchaseOrderData);
    if (!ContractId) return;

    // spinner
    setLoading(layoutDispatch);
    onEventRun();

    // compare old ponotes and current ponotes
    if (purchaseOrderData.oldponotes !== purchaseOrderData.ponotes) {
      let res = false;
      if (role === 'Hospital') {
        res = await updatePONotesHospital({
          ledger, roleCid, pocid: ContractId, newponotes: purchaseOrderData.ponotes,
        });
      }
      else if (role === 'Vendor') {
        res = await updatePONotesVendor({
          ledger, roleCid, povcid: ContractId, newponotes: purchaseOrderData.ponotes,
        });
      }
      if (!res) setErrorRes(true);
      else setErrorRes(false);
    }

    unsetLoading(layoutDispatch);
    onEventEnd();

    setOpen(false);

  }, [layoutDispatch, onEventRun, onEventEnd, ContractId, ledger, role, roleCid, purchaseOrderData, setOpen]);

  useEffect(() => {
    console.log("[CreatePurchaseOrder] set default data.");
    if (!open) return;
    if (payload) {
      setDefault({
        details: getPODetailQoO(payload.purchaseorderdetaildata),
        hospital,
        product: defaultProduct,
      });
      setDefaultPO({
        purchaseOrderData: {
          ...payload.purchaseorderdata,
          oldponotes: payload.purchaseorderdata?.ponotes,
        },
        comments: payload.purchaseordercomment,
        comment: {},
        vendor: payload.purchaseorderdata.povendorid,
      });
      setContractId(contractId);
      setDynamicPayload(payload);
    }
    else {
      setDefault({
        details: [],
        hospital,
        product: defaultProduct,
      });
      setDefaultPO({
        purchaseOrderData: {},
        comments: [],
        comment: {},
        vendor: null,
      });
      setContractId(null);
      setDynamicPayload({});
    }
  }, [payload, setDefault, setDefaultPO, hospital, open, contractId]);

  useEffect(() => {
    const isNewEditable = isEnableEdit(purchaseOrderData.postatus);
    setEditable((role === 'Hospital') && (!ContractId || (!(!isCreate && isSend) && isNewEditable)));
  }, [ContractId, purchaseOrderData, isCreate, isSend, role]);

  useEffect(() => {
    if (!open) return;
    console.log("[CreatePurchaseOrder] On Start Event", event, status);
    if (event === PO_CREATE_EVENT && status === EVENT_VERIFIED && isEditable) {
      addPurchaseOrder(false);
    }
    else if (event === PRODUCT_ADD_EVENT && status === EVENT_VERIFIED && isEditable) {
      addPurchaseOrder(true);
    }
    else if (event === PRODUCT_UPDATE_EVENT && status === EVENT_VERIFIED && isEditable) {
      handleUpdateProduct();
    }
    else if (event === PRODUCT_DELETE_EVENT && status === EVENT_REQUIRED && isEditable) {
      handleDeleteProduct();
    }
    else if (event === PO_SEND_EVENT && status === EVENT_REQUIRED && (isSend || isEditable)) {
      handleSendPO();
    }
    else if (event === PO_INVOICE_EVENT && status === EVENT_REQUIRED) {
      handleCreateInvoice();
    }
    if (event === PO_COMMENT_EVENT && status === EVENT_VERIFIED) {
      handleSendComment();
    }
    else if (event === PO_NOTE_EVENT && status === EVENT_REQUIRED) {
      handleUpdateNotes();
    }
    else if (!!event && status === EVENT_FINISHED) {
      updateContract();
    }
  }, [open, event, status, isEditable, isSend, handleSendComment, handleUpdateNotes,
    addPurchaseOrder, updateContract, handleUpdateProduct, handleDeleteProduct, handleSendPO, handleCreateInvoice]);
  
  useEffect(() => {
    return () => {
      defaultProduct.sepdistributor = null;
      clearProduct(defaultProduct);
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <>
      <ModalDialog
        open={open}
        setOpen={setOpen}
        handleCloseRequest={handleCloseRequest}
        title={(
          <POTitle
            classes={classes}
            classes1={classes1}
            isCreate={isCreate}
          />
        )}
        content={(
          <POPanel
            options={{
              isEditable: isEditable,
              isCreate: isCreate,
              isSend: isSend,
              showFile: showFile,
              setShowFile: setShowFile,
            }}
          />
        )}
        actions={(
          <POActionButtons
            classes={classes}
            classes1={classes1}
            isEditable={isEditable}
            isCreate={isCreate}
            isSend={isSend}
            isNew={!ContractId}
          />
        )}
      />

      <SnackbarModal
        type={toastMsgType}
        open={openToast}
        setOpen={setOpenToast}
        title=""
        content={toastContent}
        width={330}
        height={88}
        className={classes.toastMedium}
      />
      <SnackbarModal
        type={'warning'}
        open={snackbar}
        setOpen={setSnackbar}
        title=""
        content={t('warnings.surgicalevent.exitmessage')}
        width={345}
        height={130}
        vertical="center"
        horizontal="center"
        className={classes.toastMedium}
        options={{
          delay: 500,
          validateULE: true,
        }}
        actions={[
          {
            label: 'Yes',
            handle: () => {
              handleClose();
            },
          },
          {
            label: 'No',
            handle: () => {
              setSnackbar(false);
            },
          }
        ]}
      />
    </>
  );
}

/**
 * Get QoO field (iquantity) in PO Detail Array
 * @param {Array} purchaseorderdetaildata 
 * @returns 
 */
const getPODetailQoO = (purchaseorderdetaildata = []) => {
  return purchaseorderdetaildata.map(d => ({
    ...d,
    iquantity: getQuantityOnOrder({ iproduct: d.podproduct, iquantityunit: d.podquantity }),
  }));
};

