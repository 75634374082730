import { API_IndexModel, validateAPI_Index, getAPI_Index, requiredAPI_Index } from "./API_Index";
import { API_PayloadModel, validateAPI_Payload, getAPI_Payload, requiredAPI_Payload,
  API_Payload_LinesModel, validateAPI_Payload_Lines, getAPI_Payload_Lines, requiredAPI_Payload_Lines 
} from "./API_Payload";
import { validateArray, getArray } from "./handles";
// import { INDEX_ERP } from "../config";

export const VendorTemplateModel = {
  api_index1: {
    ...API_IndexModel,
    // _index: INDEX_ERP.Pay_V,
    // _id: 'loci_po2'
  },
  api_payload: {
    ...API_PayloadModel
  },
  api_index2: {
    ...API_IndexModel,
    // _index: INDEX_ERP.PayLine_V,
    // _id: 'loci_po_details'
  },
  api_payloadline: [
    {...API_Payload_LinesModel},
  ],
};
export const validateVendorTemplate = (vendortemplate) => {
  return ((vendortemplate.api_index1
    && validateAPI_Index(vendortemplate.api_index1))
    && (vendortemplate.api_payload)
    && validateAPI_Payload(vendortemplate.api_payload)
    && (vendortemplate.api_index2)
    && validateAPI_Index(vendortemplate.api_index2)
    && validateArray(vendortemplate.api_payloadline, validateAPI_Payload_Lines)
  );
};
export const getVendorTemplate = (vendortemplate) => {
  let newVendorTemplate = {};
  newVendorTemplate.api_index1 = validateAPI_Index(vendortemplate.api_index1) ? getAPI_Index(vendortemplate.api_index1) : {...API_IndexModel};
  newVendorTemplate.api_payload = validateAPI_Payload(vendortemplate.api_payload) ? getAPI_Payload(vendortemplate.api_payload) : {...API_PayloadModel};
  newVendorTemplate.api_index2 = validateAPI_Index(vendortemplate.api_index1) ? getAPI_Index(vendortemplate.api_index2) : {...API_IndexModel};
  newVendorTemplate.api_payloadline = getArray(vendortemplate.api_payloadline, validateAPI_Payload_Lines, getAPI_Payload_Lines);
  return newVendorTemplate;
};
export const requiredVendorTemplate = (vendortemplate) => ({
  'api_index1': requiredAPI_Index(vendortemplate.api_index1),
  'api_payload': requiredAPI_Payload(vendortemplate.api_payload),
  'api_index2': requiredAPI_Index(vendortemplate.api_index2),
  'api_payloadline': vendortemplate.api_payloadline.map(requiredAPI_Payload_Lines),
});
