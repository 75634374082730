import React, { useCallback , useEffect} from "react";
import classNames from "classnames";
import { Table, TableBody, TableRow, TableCell, Grid } from "@material-ui/core";
import { useAccountInventory } from "../../hooks/useInventory";
import { Ownerships } from "../../models/Ownership";
import { filterModel } from "../../models/handles";
import { useDamlLocation } from "../../context/DamlContext";
import { CustomSelect, DateInput, CustomTextField } from "../../components/Inputs";
import EditProduct, { InputDistributor } from "../surgicalevent/edit-product1";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "./styles";
import useProduct from "../../hooks/useProduct";

const productPattern = [
  {
    label: 'Product Details', style: {paddingTop: 0},
  },
  {
    key: 'barcode', width: 288, colSpan: 3,
  },
  {
    key: 'sepudi', width: 288, colSpan: 3,
  },
  {
    key: 'sepreferencenumber', width: 288, colSpan: 3,
  },
  {
    key: 'idistributornumber', width: 288, colSpan: 2, label: "Distributor Number", type: 'text'
  },
  {
    key: 'seplotcode', width: 128, style: { width: '144px' },
    key1: 'sepproductexpiration', width1: 128,
  },
  {
    key: 'sepproductmanufacturer', width: 128,
  },
  {
    key: 'sepproductname', width: 333, colSpan: 3,
  },
  {
    key: 'sepproductdescription', width: 333, colSpan: 3,
  },
  {
    key: 'sepproducttype', width: 128, 
    key1: 'sepproductside', width1: 128,
  },
  {
    key: 'sepuom', label: "UoM", width: 128, 
    key1: 'sepproductprice',  label1: "Price", width1: 128,  type1: 'number',
    key2: 'iquantity',  label2: "QoH", width2: 128,  type2: 'number', style2 : {position : 'relative' , right : '10px'}
  },
];


/**
 * Add Inventory Form
 * @returns 
 */
export default function AddInventory () {
  
  const classes = useStyles();
  const classes1 = useStyles1();

  const { locations } = useDamlLocation();

  const { accountInventoryData, setAccountInventoryData, accountInventoryErrors } = useAccountInventory();
  const {  setProduct: setProductItems } = useProduct();  
  
  const handleChange = useCallback((value, key) => {
    setAccountInventoryData({
      [key]: value,
    });

    //For the ES search
    if(key === 'sepdistributor') {
      setProductItems ({
        [key]: value,
      })
    }
  }, [setAccountInventoryData, setProductItems]);
  
  // Reset the AddInventory details on component mount.
  const resetValuesToNull = () => {
    setAccountInventoryData({
      sepdistributor : null,
      iownership :null,
      ipurchaseordernumber: null,
      ireceiveddate: null,
      ilocation: null,
      ibin: null
    });
    setProductItems ({
      sepdistributor: null,
    });
  }

  useEffect (()=>{
    resetValuesToNull();
    return () => {
      resetValuesToNull();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container>
      <Grid item>
        
        <Table className={classNames(classes.newSEForm, classes1.newPOForm)}>
          <TableBody>
            <TableRow>
              <TableCell colSpan={3} className="new-subtitle" style={{paddingTop: 0}}>Inventory Details</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <InputDistributor
                  label="Distributor"
                  distributor={accountInventoryData.sepdistributor}
                  setDistributor={(val) => handleChange(val, 'sepdistributor')}
                  placeholder="Select One"
                  warning={accountInventoryErrors.sepdistributor}
                  width={128}
                />
              </TableCell>
              <TableCell>
                <CustomSelect
                  label="Ownership"
                  value={accountInventoryData.iownership || ""}
                  onChange={(val) => handleChange(val, 'iownership')}
                  items={filterModel(Ownerships, ['Unknown'])}
                  placeholder="Select One"
                  warning={accountInventoryErrors.iownership}
                  width={128}
                  options={{
                  }}
                />
              </TableCell>
              <TableCell style={{width: 128}}>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <CustomTextField
                  label="PO Number"
                  value={accountInventoryData.ipurchaseordernumber || ""}
                  onChange={(val) => handleChange(val, 'ipurchaseordernumber')}
                  warning={accountInventoryErrors.ipurchaseordernumber}
                  width={128}
                  options={{
                  }}
                />
              </TableCell>
              <TableCell>
                <DateInput
                  label="Recevied Date"
                  value={accountInventoryData.ireceiveddate || ""}
                  onChange={(val) => handleChange(val, 'ireceiveddate')}
                  warning={accountInventoryErrors.ireceiveddate}
                  width={128}
                  options={{ 
                  }}
                />
              </TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell>
                <DateInput
                  label="Shipment Date"
                  value={accountInventoryData.ishipmentdate || ""}
                  onChange={(val) => handleChange(val, 'ishipmentdate')}
                  warning={accountInventoryErrors.ishipmentdate}
                  width={128}
                  options={{ 
                  }}
                />
              </TableCell>
            </TableRow> */}

            <TableRow>
              <TableCell>
                <CustomSelect
                  label="Location"
                  value={accountInventoryData.ilocation || ""}
                  onChange={(val) => handleChange(val, 'ilocation')}
                  items={locations}
                  placeholder="Select One"
                  warning={accountInventoryErrors.ilocation}
                  width={128}
                  options={{
                  }}
                />
              </TableCell>
              <TableCell>
                <CustomTextField
                  label="Bin#"
                  value={accountInventoryData.ibin || ""}
                  onChange={(val) => handleChange(val, 'ibin')}
                  warning={accountInventoryErrors.ibin}
                  width={128}
                  options={{
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <EditProduct
          options={{
            isEnableCompliance: false,
            isComplianceWarning: false,
            isContractUpdate: true,
          }}
          designPattern={productPattern}
        />
          
      </Grid>
    </Grid>
  );
}
