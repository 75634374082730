import React from "react";
import classNames from "classnames";
import { Typography, Grid, Table, TableBody, TableRow, TableCell, TableHead } from "@material-ui/core";
import ModalDialog from '../../components/Modals/NewGeneralModal';
import { InventoryProductStatus } from "../../models/InventoryData";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "../inventoryrestock/styles";
import useStyles2 from "./styles";


const InvPanel = ({
  contract={},
}) => {

  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();

  console.log("[OpenOrdersModal]", contract);

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      className={classNames(classes.overview, classes2.openordersPanel)}
    >
      <Grid item className={classNames(classes1.details, classes2.newHeaderPanel)}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className="header-label non-pr">Ref Number:</TableCell>
              <TableCell className="header-label">Distributor:</TableCell>
              <TableCell className="header-label">Ownership:</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="header-value non-pr">{contract.inventoryproduct.ireferencenumber}</TableCell>
              <TableCell className="header-value">{contract.vendor}</TableCell>
              <TableCell className="header-value">{contract.inventoryproduct.iownership}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>

      <Grid item>
        <Table className={classes2.openordersTable}>
          <TableHead>
            <TableRow>
              <TableCell>PO Number</TableCell>
              <TableCell>Qty Open</TableCell>
              <TableCell>PO Type</TableCell>
              <TableCell>PO Date</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              contract.inventorydata.map((invd, index) => (
                <TableRow key={index}>
                  <TableCell>{invd.ipurchaseordernumber}</TableCell>
                  <TableCell style={{textAlign: 'center'}}>{invd.iquantityunit}</TableCell>
                  <TableCell>{invd.potype}</TableCell>
                  <TableCell>{invd.ipurchaseorderdate}</TableCell>
                  <TableCell>{InventoryProductStatus[invd.iproductstatus]}</TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

/**
 * Open-Orders Dialog
 * @param {Boolean} open 
 * @param {Function} setOpen 
 * @param {Object} contract 
 * @returns 
 */
export default function OpenOrdersModal({
  open, setOpen, contract={}, 
}) {

  const classes1 = useStyles1();

  return (
    <>
      <ModalDialog
        open={open}
        setOpen={setOpen}
        title={(
          <Typography variant="inherit" component="span" className={classes1.newInvTitleText} >
            Open Orders
          </Typography>
        )}
        content={(
          <InvPanel
            contract={contract}
          />
        )}
        width={652}
        height={480}
      />
    </>
  );
}

