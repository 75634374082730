import React, { useState } from "react";
import { useParty } from "@daml/react";
import { UserList, Relationship, OperatorRole, HospitalRole, VendorRole, InventoryLocation, SurgicalEventProcedure, 
  Holidays, Surgeons } from "../services/daml-modules1";
import { useUserState } from "./UserContext";
import { DAML_USERLIST, DAML_SET_ROLE_CONTRACT, DAML_CLEAR_ROLE, DAML_RELATIONSHIP, DAML_LOCATION, DAML_LOAD_LOCATION, DAML_LOADING_PROCEDURE, 
  DAML_LOAD_PROCEDURE, DAML_PROCEDURE, DAML_HOLIDAY, DAML_LOAD_HOLIDAY, DAML_SURGEON, DAML_LOAD_SURGEON } from "../store/actions/constants";
import Query from "../components/Daml/Query";
import StreamQueries from "../components/Daml/StreamQueries";
import ErrorDialog from "../components/Modals/GeneralModal/error-dialog";
import useStyles from "../pages/surgicalevent/styles";
import withLoading from "../hoc/withLoading";
import { getHolidays } from "../pages/discoverpayment/HolidayCalendar";


var DamlStateContext = React.createContext();
var DamlDispatchContext = React.createContext();

const Properties = [
  {
    key: 'location',
    template: InventoryLocation,
    loaded: false,                                  // InventoryLocation Loaded
    toLoad: true,                                   // To Load InventoryLocation
    setType: DAML_LOCATION,
    loadType: DAML_LOAD_LOCATION,
    role: 'Hospital',
    mapList: (c => c.payload.iinventorylocation),
  },
  {
    key: 'procedure',
    template: SurgicalEventProcedure,
    loaded: false,                                    // SurgicalEventProcedure Loaded
    toLoad: false,                                    // To Load SurgicalEventProcedure
    setType: DAML_PROCEDURE,
    loadType: DAML_LOAD_PROCEDURE,
    role: 'Hospital',
    mapList: (c => c.payload.procedure.cptname),
    queryType: 'stream',                              // Type of Query, default is 'query'. If wants StreamQuery, then 'stream'.
    loadingType: DAML_LOADING_PROCEDURE,
  },
  {
    key: 'holiday',
    template: Holidays,
    loaded: false,
    toLoad: false,
    setType: DAML_HOLIDAY,
    loadType: DAML_LOAD_HOLIDAY,
    reduceList: getHolidays,
  },
  {
    key: 'surgeon',
    template: Surgeons,
    loaded: false,
    toLoad: false,
    setType: DAML_SURGEON,
    loadType: DAML_LOAD_SURGEON,
    role: 'Hospital',
  },
];

const getProperties = ({ properties=Properties, operator, party }) => (
  properties.reduce(([list, obj], detail) => {
    const query = { operator }; // query for property
    if (detail.role === 'Hospital') query.hospital = party;
    else if (detail.role === 'Vendor') query.vendor = party;
    list.push({
      ...detail,
      query,
      setList: (assets, dispatch) => {
        // console.log("[getProperties] setList", detail.key, Date.now());
        if (assets && assets.contracts && assets.contracts.length >= 0) {
          if (assets.loading) {
            dispatch({
              type: detail.loadingType,
              payload: assets.contracts,
            });
          }
          else {
            dispatch({
              type: detail.setType,
              payload: assets.contracts,
            });
          }
        }
      },
      isEnabled: state => state.roleCid && (!detail.role || state.role === detail.role) && state[`${detail.key}ToLoad`],
    });
    return [list, {
      ...obj, [detail.key]: [], [`${detail.key}Loaded`]: detail.loaded, [`${detail.key}ToLoad`]: detail.toLoad
    }];
  }, [[], {}])
);

const useList = (detail, useDamlState, useDamlDispatch) => {
  const { role, [detail.key]: list,  [`${detail.key}Loaded`]: loaded } = useDamlState();
  const dispatch = useDamlDispatch();
  const load = () => {
    // console.log("[useList] load", detail.key, Date.now());
    dispatch({
      type: detail.loadType,
    });
  };
  const setContracts = (contracts) => {
    dispatch({
      type: detail.setType,
      payload: contracts,
    });
  };
  const details = (typeof detail.mapList === 'function') ? list.map(detail.mapList) 
    : ((typeof detail.reduceList === 'function') ? list.reduce((s, c) => s.concat(detail.reduceList(c)), []) : []);
  return {
    details,
    contracts: list,
    loaded: (loaded || (!!detail.role && role !== detail.role)),
    load,
    setContracts,
  };
};

function damlReducer(state, action) {
  switch (action.type) {
    case DAML_USERLIST:
      if (action.payload && action.payload.payload && action.payload.payload.role) {
        const { role, userstatus } = action.payload.payload;

        if (userstatus === 'Active') {

          if (typeof action.setTemplate === 'function') action.setTemplate(
            role === 'Hospital' ? HospitalRole 
            : (role === 'Vendor' ? VendorRole
              : (role === 'Operator' ? OperatorRole : null)));
          if (typeof action.setQuery === 'function') action.setQuery(
            role === 'Hospital' ?  {operator: state.operator, hospital: state.party}
            : (role === 'Vendor' ? {operator: state.operator, vendor: state.party}
              : (role === 'Operator' ? {operator: state.operator, party: state.party} : null)));
          if (typeof action.setQueryRelationship === 'function') action.setQueryRelationship(
            role === 'Hospital' ?  {operator: state.operator, hospital: state.party}
            : (role === 'Vendor' ? {operator: state.operator, vendor: state.party} : null));

          return { 
            ...state, 
            userlistCid: action.payload.contractId, 
            userlistPayload: action.payload.payload, 
            role,
          };

        }
        else {
          // error when disactive UserList
          console.log("[DamlProvider] error when disactive UserList");
          if (typeof action.setOpenModal === 'function') action.setOpenModal(true);
        }
      }
      else {
        // error when UserList has not role
        console.log("[DamlProvider] error when UserList has not role");
        if (typeof action.setOpenModal === 'function') action.setOpenModal(true);
      }
      return state;
    case DAML_SET_ROLE_CONTRACT:
      return { ...state, roleCid: action.payload.contractId, rolePayload: action.payload.payload };
    case DAML_CLEAR_ROLE:
      return { ...state, roleCid: null, rolePayload: {} };
    case DAML_RELATIONSHIP:
      return { ...state, relHospitals: action.hospitals, relVendors: action.vendors, relLoaded: true };
    default: {
      // Check out types of properties
      for (let i in Properties) {
        const { key, setType, loadingType, loadType } = Properties[i];
        if (action.type === setType) {
          return { ...state, [key]: action.payload, [`${key}Loaded`]: true, [`${key}ToLoad`]: false };
        }
        if (action.type === loadingType) {
          return { ...state, [key]: action.payload, [`${key}Loaded`]: false, [`${key}ToLoad`]: true };
        }
        else if (action.type === loadType) {
          return { ...state, [`${key}ToLoad`]: true };
        }
      }
      return state;
    }
  }
}

function DamlProviderContent ({ children, state, dispatch }) {
  return (
    <DamlStateContext.Provider value={state}>
      <DamlDispatchContext.Provider value={dispatch}>
        {children}
      </DamlDispatchContext.Provider>
    </DamlStateContext.Provider>
  );
}

const DamlProviderWithLoading = withLoading(DamlProviderContent);

function DamlProvider({ children }) {
  
  const user = useUserState();
  const party = useParty();

  const operator = user.operator;

  const classes = useStyles();

  // property list
  const [properties, initState] = getProperties({operator, party});

  // In order to switch Hospital, organization should be gotten from other party in useUserState
  // e.g. user.organization
  const queryUserList = { operator, organization: user.organization, party: user.userParty };

  // console.log("[DamlProvider]", party, user);

  const [template, setTemplate] = useState(null);
  const [query, setQuery] = useState(null);
  const [queryRelationship, setQueryRelationship] = useState(null);

  // error dialog
  const [openModal, setOpenModal] = useState(false);


  var [state, dispatch] = React.useReducer(damlReducer, {
    operator,
    party,
    userParty: user.userParty,        // userParty of Userlist
    organization: user.organization,  // organization of Userlist
    userlistCid: null,                // Contract Id of Userlist
    userlistPayload: {},              // Payload of Userlist
    role: null,                       // HospitalRole, VendorRole, OperatorRole, etc.
    roleCid: null,                    // Contract Id of Role
    rolePayload: {},                  // Payload of Role
    relHospitals: [],                 // Relationship Hospital list
    relVendors: [],                   // Relationship Vendor list
    relLoaded: false,                 // Relationship Loaded
    // teammates: [],                 // All parties in the same organization
    ...initState,
  });
  // console.log("[DamlProvider] state", state, "party", party);


  // after loading userlist
  const setUserList = (assets) => {
    console.log("[DamlProvider] setUserList", assets);

    if (assets && !assets.loading && assets.contracts && assets.contracts.length) {
      /* const payload = assets.contracts.reduce((s, c) => {
        const party = c.payload.party;
        s.teammates.push(party);
        if (party === user.userParty) s = {...s, ...c};
        return s;
      }, {teammates: []}); */
      const payload = assets.contracts[0];
      dispatch({
        type: DAML_USERLIST,
        payload: payload,
        setTemplate,
        setQuery,
        setQueryRelationship,
        setOpenModal,
      });
    }
    else {
      // error when loading UserList
      console.log("[DamlProvider] error when loading UserList");
      setOpenModal(true);
    }
  };

  // after loading roles
  const setRoleAssets = (assets) => {
    console.log("[DamlProvider] setRoleAssets", assets);

    if (assets && !assets.loading && assets.contracts && assets.contracts.length) {
      dispatch({
        type: DAML_SET_ROLE_CONTRACT,
        payload: assets.contracts[0],
      });
    }
  };

  // after loading relationship
  const setRelationships = (assets) => {
    console.log("[DamlProvider] setRelationships", assets);

    if (assets && !assets.loading && assets.contracts && assets.contracts.length >= 0) {
      if (!!queryRelationship.hospital) {
        dispatch({
          type: DAML_RELATIONSHIP,
          hospitals: [party], 
          vendors: assets.contracts.map(c => c.payload.vendor),
        });
      }
      else if (!!queryRelationship.vendor) {
        dispatch({
          type: DAML_RELATIONSHIP,
          vendors: [party], 
          hospitals: assets.contracts.map(c => c.payload.hospital),
        });
      }
    }
  };


  return (
    <>
      
      <DamlProviderWithLoading
        loading={!state.userlistCid}
        loadingClass={classes.loadingSpinner}
        children={children}
        state={state}
        dispatch={dispatch}
      />

      {
        queryUserList && !state.userlistCid &&
        <Query
          template={UserList}
          searchParams={queryUserList}
          setValues={setUserList}
        />
      }

      {
        template && query && !state.roleCid &&
        <Query
          template={template}
          searchParams={query}
          setValues={setRoleAssets}
        />
      }

      {
        queryRelationship && !state.relLoaded && state.roleCid &&
        <Query
          template={Relationship}
          searchParams={queryRelationship}
          setValues={setRelationships}
        />
      }

      {
        properties.map((detail, index) => (
          detail.isEnabled(state) &&
          (
            detail.queryType === 'stream' 
            ?
            <StreamQueries
              key={index}
              template={detail.template}
              searchParams={[detail.query]}
              setValues={(assets) => detail.setList(assets, dispatch)}
            />
            :
            <Query
              key={index}
              template={detail.template}
              searchParams={detail.query}
              setValues={(assets) => detail.setList(assets, dispatch)}
            />
          )
        ))
      }

      <ErrorDialog
        openModal={openModal}
        setOpenModal={setOpenModal}
        text={`<p>Unable to login.</p>
          <p>Please Check your User Id and  Password and Try Again.</p>`}
        firstBtn="OK"
      />

    </>
  );
}

function useDamlState() {
  var context = React.useContext(DamlStateContext);
  if (context === undefined) {
    throw new Error("useDamlState must be used within a DamlProvider");
  }
  return context;
}

function useDamlDispatch() {
  var context = React.useContext(DamlDispatchContext);
  if (context === undefined) {
    throw new Error("useDamlDispatch must be used within a DamlProvider");
  }
  return context;
}

function useDamlLocation() {
  const detail = Properties.find(({key}) => key === 'location');
  const {details, contracts, loaded, load, setContracts} = useList(detail, useDamlState, useDamlDispatch);
  return {
    locations: details,
    locationContracts: contracts,
    loaded,
    loadLocation: load,
    setLocation: setContracts,
  };
}

function useDamlProcedure() {
  const detail = Properties.find(({key}) => key === 'procedure');
  const {details, contracts, loaded, load, setContracts} = useList(detail, useDamlState, useDamlDispatch);
  // console.log("[useDamlProcedure]", detail, details, contracts, loaded, load, setContracts);
  return {
    procedures: details,
    procedureContracts: contracts,
    loaded,
    loadProcedure: load,
    setProcedure: setContracts,
  };
}

function useDamlHoliday() {
  const detail = Properties.find(({key}) => key === 'holiday');
  const {details, contracts, loaded, load, setContracts} = useList(detail, useDamlState, useDamlDispatch);
  return {
    holidays: details,
    holidayContracts: contracts,
    loaded,
    loadHoliday: load,
    setHoliday: setContracts,
  };
}

function useDamlSurgeon() {
  const detail = Properties.find(({key}) => key === 'surgeon');
  const {details, contracts, loaded, load, setContracts} = useList(detail, useDamlState, useDamlDispatch);
  return {
    surgeons: details,
    surgeonContracts: contracts,
    loaded,
    loadSurgeon: load,
    setSurgeon: setContracts,
  };
}


export { DamlProvider, useDamlState, useDamlDispatch, useDamlLocation, useDamlProcedure, useDamlHoliday, useDamlSurgeon };