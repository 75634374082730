import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  newAddedTooltip1: {
    '& .MuiButtonGroup-root': {
      '& .MuiButton-root': {
        '&:disabled': {
          color: '#5C5C5C',
        }
      }
    }
  },
  information: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#000000',
    padding: '10px',
    background: '#FFFFFF',
    border: '1px solid #CCCCCC',
    borderRadius: '5px',
  },
  badgeInfo: {
    position: 'relative',
    top: '-8px',
    right: '73px',
    '& .MuiBadge-colorPrimary': {
      backgroundColor: '#5C5C5C',
      left: '-20px',
      width: '15px',
      height: '15px',
      minWidth: 'inherit',
      fontSize: '12px',
    }
  },
  badgeInfo1: {
    position: 'relative',
    top: '-8px',
    right: '80px',
  },
  pageTitle: {
    display: 'inline-block',
    marginBottom: '21px',
    //  '@media (max-width:1024px)':{
    //   display: 'block',
    // },
  },
  newPageTitle: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '29px',
    color: '#1C3C74',
    marginBottom: '24px',
  },
  newPageTitleDM: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '29px',
    color: '#FFFFFF',
    marginBottom: '24px',
  },
  newContainer: {
    marginBottom: '20px',
  },
  itemData: {
    marginTop: 30,
  },
  redColor: {
    // color: '#F44336',
    color: '#f00',
  },
  btnPopGroup: {
    borderTop: '1px solid #ccc;',
    marginTop: '30px;',
    paddingTop: '30px;',
    marginBottom: '16px;',
    '&  p': {
      fontWeight: '400;',
      fontSize: '16px;',
      lineHeight: '24px;',
      margin: '0px !important;'
    }
  },
  backLink: {
    color: '#183C73',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    marginBottom: 20,
    '& svg.MuiSvgIcon-root': {
      marginRight: '8px'
    }
  },
  nobreak: {
    whiteSpace: 'nowrap;'
  },
  staticCellRight: {
    /*'@media (max-width:1024px)':{
      position: 'sticky',
      right:0,
      background:'#ffffff',
      boxShadow: '4px 0px 0px rgba(0, 0, 0, 0.1)',
    },*/
  },
  staticCellelement: {
    /*'@media (max-width:1024px)':{
      position: 'sticky',
      left:0,
      background:'#ffffff',
      boxShadow: '4px 0px 0px rgba(0, 0, 0, 0.1)',
    },*/
    padding: '0 16px !important',
    '& .MuiTypography-colorPrimary.MuiTypography-body2': {
      // fontSize: ((theme.version && theme.version.new) ? '16px' : '0.875rem'),
      fontSize: '16px',
    },
  },
  staticCell: {
    /*'@media (max-width:1024px)':{
      position: 'sticky',
      left:0,
      background:'#ffffff',
      boxShadow: '4px 0px 0px rgba(0, 0, 0, 0.1)',
    },*/
    padding: '0 20px !important',
  },
  tabPanelMenu: {
    boxShadow: 'none',
    background: '#ffffff',
    position: 'relative',
    '& .MuiTab-root': {
      textTransform: 'capitalize',
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: '#183C73',
      fontWeight: 500,
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#183C73'
    }
  },
  tabPanelbody: {
    '& .MuiBox-root': {
      padding: '0px'
    }
  },
  tabPanelbody1: {
    '& .MuiBox-root': {
      padding: '0px',
      background: '#ffffff'
    }
  },
  backarraobtn: {
    cursor: 'pointer',
    color: '#183C73'
  },
  badgeColor: {
    '& .MuiBadge-colorPrimary': {
      background: '#2196F3 !important'
    }
  },
  badgeGreenColor: {
    '& .MuiBadge-colorPrimary': {
      background: '#4CAF50 !important'
    }
  },
  arrowBtn: {
    padding: '0px',
    maxWidth: '10px',
    minWidth: '0px',
    marginRight: '16px'
  },
  setupButton: {
    marginBottom: '20px',
  },
  topGrid: {
    marginTop: '10px',
    marginBottom: '20px',
  },
  listTitle: {
    margin: '20px',
  },
  dialogTitle: {
    padding: '16px 24px 0'
  },
  bottomGrid: {
    marginTop: 25,
    marginBottom: 30,
  },
  scanButton: {
    marginLeft: 15,
    marginRight: 15,
  },
  tab: {
    backgroundColor: '#fff',
  },
  smallChip: {
    fontSize: '0.65rem !important;',
    fontWeight: 600,
  },
  chipSuccess: {
    backgroundColor: 'rgba(76, 175, 80, 0.38) !important;',
    marginTop: '5px',
    marginRight: '6px',
    fontWeight: 400,
  },
  chipFailed: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    marginTop: '5px',
    marginRight: '6px',
    fontWeight: 400,
  },
  backYellow: {
    backgroundColor: 'rgba(255, 152, 0, 0.38) !important;',
    border: '1px solid #FF9800 !important;'
  },
  backGreen: {
    backgroundColor: 'rgba(76, 175, 80, 0.38) !important;',
    border: '1px solid #4CAF50 !important;',
    fontFamily: 'Helvetica',
    fontStyle: 'bold',
    fontSize: '16px'
  },
  backRed: {
    backgroundColor: 'rgba(244, 67, 54, 0.38) !important;',
    border: '1px solid #F44336 !important;'
  },
  backBlue: {
    backgroundColor: 'rgba(33, 150, 243, 0.38) !important;',
    border: '1px solid #2196F3 !important;'
  },
  backPink: {
    backgroundColor: 'rgba(156, 39, 176, 0.38) !important;',
    border: '1px solid #9C27B0 !important;'
  },
  backPurple: {
    backgroundColor: 'rgba(103, 58, 183, 0.38) !important;',
    border: '1px solid #673AB7 !important;'
  },
  backLightGreen: {
    backgroundColor: 'rgba(6, 255, 0, 0.38) !important;',
    border: '1px solid #0A9A06 !important;'
  },
  backOrange: {
    backgroundColor: 'rgba(247, 110, 17, 0.38) !important;',
    border: '1px solid #F95A05 !important;'
  },
  backLightOrange: {
    backgroundColor: 'rgba(242, 78, 30, 0.38) !important;',
    border: '1px solid #F24E1E !important;'
  },
  backLightYellow: {
    backgroundColor: 'rgba(244, 208, 19, 0.38) !important;',
    border: '1px solid #F7CD37 !important;'
  },
  backTeal: {
    backgroundColor: 'rgba(12, 165, 165, 0.38) !important;',
    border: '1px solid #0CA5A5 !important;'
  },
  centerCell: {
    justifyContent: 'center !important',
    textAlign: 'center',
  },
  arrow: {
    color: theme.palette.common.black,
  },
  newSetupButton: {
    padding: '6px 15px',
    color: '#ffffff',
    backgroundColor: '#183C73',
    float: 'right',
    '&:hover': {
      backgroundColor: '#183c73d9',
    },
    '&.MuiButton-root': {
      textTransform: 'capitalize',
      fontWeight: '500',
    },
    '@media (max-width:1024px)': {
      fontSize: '0px',
      minWidth: '36px',
      padding: '6px',
    },
    '& .desktop': {
      '@media (max-width:1024px)': {
        display: "none",
      },
    },
    '& .mobile': {
      display: "none",
      '@media (max-width:1024px)': {
        display: "block",
      },
    },
  },
  newCreateButton: {
    background: '#1C3C74',
    borderRadius: '5px',
    color: '#FFFFFF',
    padding: '9px 12px',
    '&:hover': {
      backgroundColor: '#1C3C74d9',
    },
    '&.MuiButton-root': {
      textTransform: 'capitalize',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '16px',
      '& .MuiSvgIcon-root': {
        margin: '-4px 0px',
      }
    },
  },
  newCreateButtonDM: {
    background: '#F7CD37',
    borderRadius: '5px',
    color: '#1C3C74',
    padding: '9px 12px',
    '&:hover': {
      backgroundColor: '#F7CD37d9',
    },
    '&.MuiButton-root': {
      textTransform: 'capitalize',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '16px',
      '& .MuiSvgIcon-root': {
        margin: '-4px 0px',
      }
    },
  },
  newIconButton: {
    background: '#5C5C5C',
    padding: '6px',
    marginRight: '5px',
    '&:hover': {
      backgroundColor: '#5C5C5Cd9',
    },
    '&.MuiButton-root': {
      minWidth: 16,
      '& .MuiButton-label': {
        '& .MuiButton-startIcon': {
          margin: 0,
        },
      },
    },
  },
  cancelbtn: {
    color: "#5C5C5C",
  },
  filterBtn: {
    marginLeft: 10,
    padding: '0px',
    color: '#ffffff',
    backgroundColor: '#183C73',
    float: 'right',
    minWidth: '36px',
    height: '36px',
    '&:hover': {
      backgroundColor: '#183c73d9',
    },
    '&.MuiButton-root': {
      textTransform: 'capitalize',
      fontWeight: '500',
    },
  },
  newCheckbox: {
    float: 'right',
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '16px',
    marginRight: 26,
    '@media (max-width:1024px)': {
      float: 'left',
    },
    '& .MuiCheckbox-root': {
      padding: '4px 9px',
    },
    '& .MuiCheckbox-colorPrimary.Mui-checked': {
      color: '#183C73'
    },
    '& .MuiFormControlLabel-label': {
      color: "#000000",
      fontWeight: 700,
    }
  },
  formRoot: {
    background: '#ffffff',
    maxWidth: '100%',
    width: '700px',
    margin: '0 auto',
    boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
  },
  formRootNew: {
    background: '#ffffff',
    margin: '-24px',
    minHeight: '100vh',
    '& .formsteps': {
      maxHeight: 'inherit !important',
      height: 'calc(100vh - 126px)',
    },
  },
  overflowHidden: {
    overflow: 'hidden',
  },
  formFooters1: {
    padding: '10px 30px;',
    display: 'inline-block;',
    width: '100%;',
    '@media (max-width:1024px)': {
      padding: '10px 6px;',
    },
  },
  alertRed: {
    color: '#f44336',
    margin: 0,
  },
  primaryColor: {
    '& .MuiTypography-colorPrimary': {
      // color:"#183C73", // [NewPrimaryColor] if it's enable, all clickable items' color will be changed
    },
  },

  customRootMsg: {
    '& .MuiDialog-paperWidthLg': {
      minWidth: '94%'
    }
  },
  /** Start of CSS */
  topBorders: {
    borderTop: '1px solid #CCCCCC !important',
  },
  valuesCss: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontweight: '700',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#000000',
  },
  customRoot: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important',

    '& .MuiButtonBase-root': {
      '& .Mui-disabled': {
        color: 'red !important'
      },
    },
    /*App colors*/
    '& *': {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important',
    },
    '& .text-active': {
      color: 'rgba(0,0,0,0.87) !important;',
      fontWeight: 500,
      fontSize: 16,
    },
    '& .formsteps': {
      maxHeight: 'calc(100vh - 220px)',
      overflow: 'auto'
    },
    '&.text-inactive': {
      color: 'rgba(0,0,0,0.54) !important;',
    },
    '& .text-disabled': {
      color: 'rgba(0,0,0,0.38) !important;'
    },
    '& .text-help': {
      color: 'rgba(0,0,0,0.60) !important;'
    },
    '& .bg-primary': {
      backgroundColor: '#183C73 !important;'
    },
    '& .bg-info': {
      backgroundColor: '#2196F3 !important;'
    },
    '& .bg-warning': {
      backgroundColor: '#FF9800 !important;'
    },
    '& .bg-danger': {
      backgroundColor: '#F44336 !important;'
    },
    '& .bg-success': {
      backgroundColor: '#4CAF50 !important;'
    },
    '& .bg-info-light': {
      backgroundColor: 'rgba(33,150,243,0.38) !important;',
      border: '1px solid #2196F3 !important;',
      marginLeft: 5,
    },
    '& .bg-info-price-change': {
      backgroundColor: ' rgba(244, 67, 54, 0.38) !important;',
      border: '1px solid #F44336 !important;'
    },
    '& .bg-warning-light': {
      backgroundColor: 'rgba(255,152,0,0.38) !important;',
      border: '1px solid #FF9800 !important;'
    },
    '& .bg-danger-light': {
      backgroundColor: 'rgba(244,67,54,0.38) !important;',
      border: '1px solid #F44336 !important;'
    },
    '& .bg-success-light': {
      backgroundColor: 'rgba(76,175,80,0.38) !important;',
      border: '1px solid #4CAF50 !important;'
    },
    '& .bg-light-color': {
      color: '#F4F6F8 !important;'
    },
    '& .MuiStepConnector-line.MuiStepConnector-lineHorizontal': {
      display: 'none !important;'
    },
    '& .MuiStepper-root': {
      padding: '0px !important;',
      flexWrap: 'wrap;'
    },
    '& .MuiStep-root.MuiStep-horizontal': {
      borderRight: 'solid 1px #cccccc;',
      padding: '8px 20px !important;',
      background: '#F9F9F9;'
    },
    '& .MuiAccordionSummary-root1': {
      minHeight: '33px',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    '& .MuiStep-root.MuiStep-horizontal.current, .MuiStep-root.MuiStep-horizontal.MuiStep-completed': {
      background: '#ffffff;'
    },
    '& .Mui-disabled .MuiStepLabel-label, .Mui-disabled .MuiTypography-caption': {
      color: 'rgba(0, 0, 0, 0.38);'
    },
    '& .MuiStep-completed .MuiStepIcon-root.MuiStepIcon-completed': {
      color: '#4CAF50;'
    },
    '& .makeStyles-step1-72': {
      background: '#2196F3 !important;',
      top: '-4px !important;',
      height: '4px !important;'
    },
    '& .makeStyles-stepDetails-73': {
      borderColor: '#DEF3FB !important;'
    },
    '& .MuiStep-root.MuiStep-horizontal:last-child': {
      border: 'none;'
    },
    '& .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-active': {
      display: 'none;'
    },
    '& .MuiStepIcon-root.MuiStepIcon-active': {
      color: '#183C73 !important;' // [NewPrimaryColor]
    },
    '& .MuiStepConnector-root.MuiStepConnector-horizontal.Mui-disabled, .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-completed': {
      display: 'none;'
    },
    '& .MuiStep-horizontal': {
      '-ms-flex-preferred-size': 0,
      flexBasis: 0,
      '-webkit-box-flex': 1,
      '-ms-flex-positive': 1,
      flexGrow: 1,
      maxWidth: '100%;'
    },
    '& .MuiTypography-root': {
      width: '100%',
    },
    '& .MuiTypography-body2': {
      fontSize: '0.75rem !important;'
    },
    '& .MuiTypography-caption': {
      fontSize: '0.875rem !important;',
      fontWeight: '500 !important;'
    },
    '& .MuiStepLabel-label.MuiStepLabel-active, .MuiStepLabel-label.MuiStepLabel-active + span': {
      // color: '#183C73 !important;' // [NewPrimaryColor]
    },
    // '& .makeStyles-formHeading-75': {
    //     margin: '0 0 6px 0 !important;',
    //     padding: '16px 30px',
    //     color: 'rgba(0, 0, 0, 0.87) !important;'
    // },
    // '& .makeStyles-formSubHeading-76': {
    //     color: 'rgba(0, 0, 0, 0.87) !important;',
    //     fontSize: '16px !important;',
    //     fontWeight: '600 !important;',
    //     margin: '0px;',
    //     padding: '10px 0 20px 0 !important;'
    // },
    '& .MuiFormControl-root': {
      margin: '0px !important;'
    },
    '& .MuiInputBase-input': {
      height: '20px !important;',
      lineHeight: '20px',
      padding: '10px 12px !important;'
    },
    // '& .MuiInputBase-formControl': {
    //     margin: '0 0 30px 0 !important;' 
    // },
    '& .MuiFormLabel-root.Mui-focused': {
      // color: '#183C73 !important;' // [NewPrimaryColor]
    },
    '& .MuiFormLabel-root.Mui-focused.Mui-error': {
      color: '#F44336 !important;'
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#183C73 !important;'
    },
    '& .Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#F44336 !important;'
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: '#ffffff !important;'
    },
    '& .form-footer': {
      padding: '10px 30px;',
      borderTop: 'solid 1px #cccccc;',
      display: 'inline-block;',
      width: '100%;',
      '@media (max-width:1024px)': {
        padding: '10px 6px;',
      },
    },
    '& .form-footer1': {
      padding: '10px 30px;',
      display: 'inline-block;',
      width: '100%;',
      '@media (max-width:1024px)': {
        padding: '10px 6px;',
      },
    },
    '& .bottom-fixed': {
      position: 'fixed',
      bottom: 0,
    },
    '& .MuiButton-containedPrimary': {
      backgroundColor: '#183C73 !important;'
    },
    '& .MuiButton-outlinedPrimary, .MuiButton-textPrimary': {
      color: '#183C73 !important;', // [NewPrimaryColor]
      borderColor: '#183C73 !important;' // [NewPrimaryColor]
    },
    '& .MuiButton-root': {
      fontSize: '1rem !important;',
      textTransform: 'capitalize !important;'
    },
    '& .right-buttons': {
      float: 'right;'
    },
    '& .right-buttons .MuiButton-root': {
      margin: '0 5px;'
    },
    '& .right-buttons .MuiButton-root:last-child': {
      marginRight: '0px;'
    },
    '& .price-change .MuiAlert-filledInfo': {
      backgroundColor: 'rgba(244, 67, 54, 0.38) !important;',
      border: '1px solid #F44336 !important;',
      color: 'rgba(0,0,0,0.87) !important;'
    },
    '& .MuiAlert-filledInfo': {
      backgroundColor: 'rgba(33,150,243,0.38) !important;',
      border: 'solid 1px #2196F3;',
      color: 'rgba(0,0,0,0.87) !important;'
    },
    '& .MuiAlert-filledWarning': {
      backgroundColor: 'rgba(255,152,0,0.38) !important;',
      border: 'solid 1px #FF9800;',
      color: 'rgba(0,0,0,0.87) !important;',
      textAlign: 'left;'
    },
    '& .MuiAlert-root': {
      padding: '1px 16px !important;',
      margin: '20px 0;'
    },
    '& .MuiPaper-elevation6': {
      boxShadow: 'none !important;'
    },
    '& .msg-alert': {
      marginTop: '-42px !important;',
      paddingLeft: '12px !important;',
      marginBottom: '30px !important;'
    },
    '& .msg-alert-0': {
      paddingLeft: '12px !important;',
    },
    '& .grid-list': {
      width: '100%;',
      display: 'inline-block;',
      marginLeft: '-8px;',
      marginRight: '-8px;',
      marginBottom: '20px;'
    },
    '& .grid-list .list-item': {
      float: 'left;',
      padding: '0 8px;'
    },
    '& .grid-list .list-item .label': {
      width: '100%;',
      float: 'left;',
      fontSize: '12px;',
      color: 'rgba(0,0,0,0.54);'
    },
    '& .grid-list .list-item .value': {
      width: '100%;',
      float: 'left;',
      fontSize: '16px;',
      color: 'rgba(0,0,0,0.87);',
      marginTop: '8px;'
    },
    '& .table-list': {
      tableLayout: 'fixed'
    },
    '& .table-list .table-list-item .label': {
      fontSize: '12px;',
      color: 'rgba(0,0,0,0.54);'
    },
    '& .table-list .table-list-item .value': {
      fontSize: '16px;',
      color: 'rgba(0,0,0,0.87);',
      marginTop: '8px;'
    },
    '& .grid-list .list-item .downloadlink': {
      color: '#183C73 !important',
    },
    '& .grid-list-5 .list-item': {
      width: '20%;',
      '@media (max-width:1024px)': {
        width: '50%;',
        marginBottom: 20
      }
    },
    '& .grid-list-4 .list-item': {
      width: '25%;',
      '@media (max-width:1024px)': {
        width: '50%;',
        marginBottom: 20
      },
    },
    '& .grid-list-3 .list-item': {
      width: '33%;',
      '@media (max-width:1024px)': {
        width: '50%;',
        marginBottom: 20
      },
    },
    '& .newgrid-list-3 .list-item': {
      width: '99%;',
    },
    '& .grid-list-flex': {
      display: 'flex;',
      '@media (max-width:1024px)': {
        width: '50%;',
        display: 'inline-block',
        marginBottom: 20
      },
    },
    '& .price-label': {
      fontSize: '12px;',
      color: 'rgba(0,0,0,0.54);'
    },
    '& .MuiAccordionSummary-root': {
      '@media (max-width:1024px)': {
        width: '100%',
      },
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '48px !important;',
      borderBottom: 'solid 1px #cccccc;'
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0 !important;'
    },
    '& .MuiAccordion-root': {
      marginBottom: '16px !important;'
    },
    '& .MuiAccordion-root1': {
      marginBottom: '-11px !important;'
    },
    '& .product-detail-data .MuiTableCell-root': {
      padding: '8px 32px 8px 0px !important;',
      fontSize: '1rem !important;'
    },
    '& .MuiAccordionSummary-content': {
      justifyContent: 'space-between !important',
    },
    '& .MuiSvgIcon-root': {
      // display: 'none',
      display: 'block',
    },
    '& .product-detail-accordion-head .MuiAccordionSummary-content': {
      alignItems: 'center !important;',
      justifyContent: 'flex-start !important;',
      '& .MuiTypography-root': {
        width: 'auto',
        marginRight: 10,
      },
      '& .MuiChip-root': {
        marginLeft: 'auto',
      },
    },
    '& .product-detail-accordion-head1 .MuiAccordionSummary-content': {
      alignItems: 'center',
      '& .MuiAccordionSummary-content': {
        paddingBottom: '13px'
      }
    },
    '& .product-detail-accordion-head.yellow-accordion': {
      backgroundColor: 'rgba(249, 225, 145, 1)',
    },
    /** surgical-event-drawer */
    '&  .MuiDrawer-paperAnchorRight': {
      width: '100% !important;',
      maxWidth: '700px !important;',
      '& .view-heading': {
        padding: '14px 30px;',
        fontSize: '20px;',
        fontWeight: '600;',
        borderBottom: 'solid 1px #cccccc;',
        height: '56px;',
        '& span': {
          fontWeight: '400;'
        },
        '& .detailTitle': {
          fontWeight: '700',
          color: '#183C73'
        }
      },
      '& .view-heading2': {
        padding: '14px 30px;',
        fontSize: '20px;',
        fontWeight: '600;',
        borderBottom: 'solid 1px #cccccc;',
        height: '56px;',
        '& span': {
          fontWeight: '400;'
        },
      },
      '& .view-heading3': {
        padding: '14px 30px;',
        height: '30px;',
        '& span': {
          fontWeight: '400;'
        },
      },
      '& .view-heading4': {
        borderBottom: 'solid 1px #cccccc;',
        padding: '0 33px 12px 44px;',
        fontStyle: 'normal;',
        fontWeight: '300',
        fontSize: '17px;',
        lineHeight: '20px;',
        color: '#000000;',
        '& span': {
          fontWeight: '400;'
        },
      },

      '& .viewDetailsSe .view-heading': {
        padding: '14px 0px;',
      },
      '& .viewDetailsSe': {
        padding: '20px 30px;'
      },
      // '& .makeStyles-formSubHeading-77': {
      //   padding: '0px;',
      //   fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif;'
      // },
      '& .MuiBadge-root': {
        marginLeft: '16px;',
        // display: 'inline-block;'
      },
    },
    '& .MuiButton-outlinedPrimary': {
      boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)'
    },
    /** customModalPopup */
    '&  .MuiBackdrop-root': {
      backgroundColor: 'rgba(24,60,115,0.5) !important;'
    },
    '&  .MuiDialogContentText-root .noBtn': {
      margin: '0 5px;'
    },
    '&  .MuiDialogContentText-root .yesBtn': {
      margin: '0 5px;'
    },
    '&  span.warningHeading': {
      lineHeight: '28px;',
      fontSize: '24px;',
      fontWeight: '500;',
      color: '#000000;',
      marginTop: '10px;',
      display: 'block;'
    },
    '&  .MuiDialogContentText-root': {
      fontSize: '16px;',
      fontWeight: '400;',
      lineHeight: '24px;',
      marginBottom: '30px;'
    },
    '&  .makeStyles-btnPopGroup-62': {
      borderTop: '1px solid #ccc;',
      marginTop: '30px;',
      paddingTop: '30px;',
      marginBottom: '16px;'
    },
    '&  .makeStyles-btnPopGroup-62 p': {
      fontWeight: '400;',
      fontSize: '16px;',
      lineHeight: '24px;',
      margin: '0px !important;'
    },
    '& .MuiDialogTitle-root .alert-icon': {
      marginTop: '14px;'
    },
    '& .MuiDialogTitle-root .alert-icon.red': {
      color: '#F44336'
    },
    '&  .MuiDialogActions-root.MuiDialogActions-spacing': {
      textAlign: 'center;',
      justifyContent: 'center;',
      marginBottom: '30px;'
    },
    '& .customClose': {
      position: 'absolute;',
      right: '7px;',
      top: '7px;'
    },
    '& .customClose svg.MuiSvgIcon-root': {
      width: '24px;',
      height: '24px;',
      cursor: 'pointer;'
    },
    '& .makeStyles-actionCellDisabled-140': {
      opacity: '.04'
    },
    '& .modalContentManage': {
      textAlign: 'center'
    },
    '& .sub-forms': {
      minWidth: '565px'
    },
    '& .MuiRadio-colorPrimary.Mui-checked': {
      color: '#183c73;'
    },
    '& .purchaseOrderModal div#draggable-dialog-title': {
      /*borderBottom: '1px solid #ccc',*/
      marginBottom: '10px',
      textAlign: 'left'
    },
    '& .purchaseOrderModal .form-footer': {
      padding: '10px 14px',
      textAlign: 'left'
    },
    '& .purchaseOrderModal .MuiDialogActions-root.MuiDialogActions-spacing': {
      marginBottom: '5px'
    },
    '& .welcome-footer': {
      textAlign: 'left'
    },
    '& .MuiDialog-paperWidthSm': {
      width: '600px'
    },
    '& .cancelevent .btnPopGroup': {
      borderTop: 0,
      marginTop: 0,
      paddingTop: 0,
    },
    '& #draggable-dialog-title': {
      /*borderBottom: '1px solid #CCCCCC'*/
    },
    '& .view-heading': {
      textAlign: 'left',
      fontSize: '20px',
    },
    '& .poDetailsHeading': {
      fontSize: '16px',
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 500,
      textAlign: 'left',
      margin: '7px 0 18px'
    },
    '& .close-icon': {
      position: 'absolute',
      right: '6px',
      top: '6px',
      cursor: 'pointer',
    },
    '& .MuiDialog-paper': {
      '@media (max-width:1024px)': {
        margin: '8px',
      },
    }
  },
  containerSize: {
    width: '87% !important;',
  },

  //Add-Edit-Modal

  modalContentManagebox: {
    '& *': {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important',
    },
    '& .MuiDialog-paper': {
      overflow: 'hidden;',
      margin: 18,
      '@media (max-width:1024px)': {
        margin: '16px',
      },
    },
    '& .modalContentManage-edit': {
      overflow: 'auto;'
    },
    '& .MuiDialog-paperWidthSm': {
      width: '600px',
      maxWidth: '100%',
    },
    '& .MuiDialogContent-root': {
      padding: '0px;',
    },
    '& .makeStyles-mainFormSteps-85': {
      padding: '0px;'
    },
    '& .Form-content': {
      padding: '0px'
    },
    '& .MuiOutlinedInput-input': {
      height: '20px;',
      padding: '10px 12px;',
      lineHeight: '20px;'
    },
    '& .MuiFormControl-marginNormal': {
      margin: '0px;'
    },
    '& .makeStyles-formHeading-83': {
      margin: '0px 0px 30px 0px;',
      padding: '17px 30px;'
    },
    '& .makeStyles-formControl-87': {
      margin: '0px;'
    },
    '& .MuiDialogTitle-root.dialog-title-borderbm': {
      borderBottom: '1px solid #ccc;',
    },
    '& .MuiDialogActions-root': {
      padding: '10px 30px;',
      borderTop: '1px solid #ccc'
    },
    '& .MuiButton-containedPrimary': {
      //  backgroundColor:'#183C73', // [NewPrimaryColor]
      textTransform: 'capitalize;'
    },
    '& .MuiButton-textPrimary': {
      // color:'#183C73', // [NewPrimaryColor]
      textTransform: 'capitalize;'
    },
    '& .MuiFormControl-root.msg-alert': {
      marginTop: '-28px;',
      paddingLeft: '10px;',
      marginBottom: '30px',
    },
    '& .MuiFormControl-root.msg-alert p': {
      margin: '0px 0px 8px 0px;'
    },
    '& .MuiRadio-colorPrimary.Mui-checked': {
      color: '#183c73;'
    },
    '& .MuiDialogActions-root .noBtn': {
      margin: '0 5px;',
      fontSize: '1rem !important;',
      textTransform: 'capitalize !important;',
    },
    '& .MuiDialogActions-root .yesBtn': {
      margin: '0 5px;',
      fontSize: '1rem !important;',
      textTransform: 'capitalize !important;',
      backgroundColor: '#183C73 !important;',
    },
  },

  /** End of CSS */

  pointer: {
    cursor: 'pointer'
  },
  step1: {
    position: "absolute",
    width: '4px',
    background: '#2196F3',
    height: '6px',
    top: '-5px',
    borderRadius: '1px'
  },
  stepDetails: {
    borderLeft: '0px',
    borderRight: '0px',
    borderBottom: '0px',
    borderTop: '4px',
    borderStyle: 'solid',
    borderColor: '#DEF3FB',
    position: "relative"

  },
  stepperHeader: {
    display: "table-header-group",
    '@media (max-width:1024px)': {
      display: 'none'
    },
  },
  iconblock2: {
    top: '19px',
    position: 'relative',
  },
  formHeading: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '23px',
    color: '#000000',
    padding: '6px 30px 15px 30px',
    border: '1px',
    borderLeft: 0,
    borderRight: 0,
    borderTop: 0,
    borderStyle: 'solid',
    borderColor: '#cccccc',
    '@media (max-width:1024px)': {
      padding: '6px 16px 15px 16px',
    },
  },
  formSubHeading: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '21px',
    color: '#000000',
    marginBottom: 20,
    // padding:'6px 30px 15px 10px',
    // color:'#cccccc',
    '& .MuiBadge-root': {
      marginLeft: '16px;',
    },
    '& .downloadlink': {
      color: '#183C73'
    },
    '&.border-none': {
      border: 0
    },
  },
  displayflex: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: '-10px'
  },
  leftPad: {
    paddingLeft: '6%'
  },
  mainFormSteps: {
    padding: '0px 30px',
    '& div[role="tabpanel"]': {
      minHeight: '450px'
    },
    '@media (max-width:1024px)': {
      padding: '0px 16px',
    },
  },
  mainFormStepsNew: {
    padding: '0px 100px',
    '& div[role="tabpanel"]': {
      minHeight: '450px'
    },
    '@media (max-width:1024px)': {
      padding: '0px 32px',
    },
    '@media (max-width:768px)': {
      padding: '0px 16px',
    },
  },
  userFormSteps: {
    padding: '10px 30px 0',
    maxHeight: 'calc(79vh - 164px);',
    overflow: 'auto;',
    minHeight: '486px',
    '& div[role="tabpanel"]': {
      minHeight: '450px'
    },
    '@media (max-width:1024px)': {
      padding: '0px 16px',
    },
  },
  mainFormStepsPanel: {
    padding: '0px 30px',
    height: 'calc(72vh - 120px)',
    overflow: 'auto'
  },
  customFullWidthTextarea: {
    width: '100%',
    minWidth: '100%',
    minHeight: '100px',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    padding: '10px 12px',
    fontSize: '16px',
    fontWeight: 400,
    borderRadius: '4px'
  },
  customFullWidth: {
    width: '100%',
    minWidth: '100%',
    "& .MuiOutlinedInput-adornedEnd": {
      height: "32px",
    }
  },
  divContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    marginTop: "-20px",
  },

  divContainer2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1px",
  },

  formControl: {
    width: '100%',
    margin: '10px 0px'
  },
  form: {
    margin: '10px 0px',
    width: '100%',
  },
  form2: {
    margin: '10px 0px',
    width: '100%',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  halfWidth: {
    width: '100%',
    maxWidth: '254px',
    '@media (max-width:1024px)': {
      maxWidth: '100%',
    },
  },
  datehalfWidth: {
    width: '100%',
    maxWidth: '254px',
    '@media (max-width:1024px)': {
      maxWidth: '100%',
    },
    '& .MuiFormLabel-filled + div .MuiInputBase-input': {
      color: '#000'
    },
    '& .MuiInputBase-input': {
      color: '#acacac'
    },
  },
  maxWidthAuto: {
    maxWidth: 'inherit !important',
  },
  datepickerBox: {
    width: '100%',
    maxWidth: '254px',
    '& .MuiFormLabel-filled + div .MuiInputBase-input': {
      color: '#000'
    },
    '& .MuiInputBase-input': {
      color: '#acacac'
    },
    '& .MuiInput-underline:before,& .MuiInput-underline:hover:before,.MuiInput-underline:after,.MuiInput-underline:hover:after': {
      border: 0
    },
    '&  .MuiInput-formControl': {
      marginTop: 0,
      border: '1px solid #c4c4c4',
      borderRadius: '4px'
    },
    '& .MuiInputLabel-formControl': {
      margin: '-6px 13px',
      background: '#fff',
      position: 'absolute',
      zIndex: '99',
      width: 'auto',
      padding: '0px 3px',
      display: 'initial'
    }
  },
  customLabel: {
    background: '#fff'
  },
  // btnGroup:{
  //   padding:'20px 20px 40px 0',
  //   textAlign:'right',
  //   marginTop:'20px',
  //   border:'1px',
  //   borderLeft:0,
  //   borderRight:0,
  //   borderBottom:0,
  //   borderStyle:'solid',
  //   borderColor:'#cccccc',
  // },
  // button:{
  //   color: '#252525',
  //   boxShadow: 'none',
  //   margin: '5px',
  //   padding: '10px 25px',
  //   background:'#eaeaea',
  //   borderColor:'#CCCCCC',
  //   border:'1px',
  //   borderStyle:'solid',
  //   borderRadius: '4px'
  // },
  // defaultBtn:{
  //   color: '#252525',
  //   boxShadow: 'none',
  //   margin: '5px',
  //   padding: '10px 25px',
  //   background:'#FFFFFF',
  //   borderColor:'#CCCCCC',
  //   border:'1px',
  //   borderStyle:'solid',
  //   borderRadius: '4px'
  // },
  // addMoreBtn:{
  //   color: '#252525',
  //   boxShadow: 'none',
  //   margin: '5px',
  //   padding: '10px 25px',
  //   background:'#eaeaea',
  //   borderColor:'#CCCCCC',
  //   border:'1px',
  //   borderStyle:'solid',
  //   borderRadius: '4px',
  //   float:'left',
  //   marginLeft:'40px'
  // },
  // continueButton:{
  //   color: '#ffffff',
  //   boxShadow: 'none',
  //   margin: '5px',
  //   padding: '10px 25px',
  //   background:'#252525',
  //   borderColor:'#CCCCCC',
  //   border:'1px',
  //   borderStyle:'solid',
  //   borderRadius: '4px'
  // },
  tableContainer: {
    boxShadow: 'none'
  },
  productTableContainer: {
    boxShadow: 'none',
    background: "transparent"
  },
  customRadio: {
    borderColor: '#d4d4d4',
    border: '1px',
    borderStyle: 'solid',
    marginLeft: '0px',
    padding: '3px 30px 3px 1px',
    // borderRadius: '4px',
    minWidth: '185px'
  },
  tableCell: {
    border: '0px !important;',
    color: 'rgba(0,0,0,0.87) !important;',
  },
  tableCellRed: {
    border: '0px !important;',
    color: '#f00 !important;'
  },
  tableCellHead: {
    border: '0px !important;',
    color: 'rgba(0,0,0,0.54) !important;',
    minWidth: 190
  },
  tableCellHead1: {
    border: '0px !important;',
    color: 'rgba(0,0,0,0.54) !important;',
    minWidth: 100
  },
  tableCellFixWidth: {
    width: 190,
  },
  tableHeadCell: {
    border: '0px !important;',
    color: 'rgba(0,0,0,0.54) !important;'
  },
  tableRowBorderTop: {
    borderTop: '1px solid rgba(204, 204, 204, 1)',
  },
  BorderTop: {
    borderTop: '1px solid rgba(204, 204, 204, 1)',
  },
  ContainerBorder: {
    border: "1px solid rgba(204, 204, 204, 1)",
  },
  ContainerTopBorder: {
    borderTop: "1px solid rgba(204, 204, 204, 1)",
  },
  ContainerBottomBorder: {
    borderBottom: "1px solid rgba(204, 204, 204, 1)",
  },
  ContainerRightBorder: {
    borderRight: "1px solid rgba(204, 204, 204, 1)",
  },
  ContainerLeftBorder: {
    borderLeft: "1px solid rgba(204, 204, 204, 1)",
  },
  mainAccordion: {
    boxShadow: 'none',
    borderColor: '#d4d4d4',
    border: '1px',
    borderStyle: 'solid',
    background: '#F4F6F8',
  },
  productMainAccordion: {
    boxShadow: 'none',
    borderColor: '#d4d4d4',
    border: '1px',
    borderStyle: 'solid',
    background: '#F4F6F8',
    borderRadius: 4
  },
  accordionHeading: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#333333',
    '& .text-inactive': {
      fontSize: '12px',
      fontWeight: 400,
    },
    '& .lotcode': {
      marginLeft: '20px'
    }
  },
  accordionParentHeading: {
    borderColor: '#d4d4d4',
    border: '1px',
    borderLeft: 0,
    borderRight: 0,
    borderTop: 0,
    borderStyle: 'solid',
  },
  productItemList: {
    background: '#183C73',
    color: '#ffffff',
    float: 'none',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: '7px 0px',
    padding: '8px 16px',
    borderRadius: '5px',
    '& .list-title': {
      width: '100%;'
    }
  },
  itemList: {
    display: "block",
    width: '100%'
  },
  deleteIcon: {
    float: 'right',
    cursor: 'pointer'
  },
  editIcon: {
    float: 'right',
    cursor: 'pointer',
    paddingRight: '10px'
  },
  productTableHeading: {
    float: 'right',
    fontSize: '16px',
    color: 'rgba(0,0,0,0.87)',
    fontWeight: 400
  },
  label: {
    fontSize: '16px',
    color: '#000000',
    fontWeight: 500,
    marginTop: "17px"
  },
  customCheckbox_radio: {
    marginTop: '12px',
    '& label.MuiFormControlLabel-root': {
      display: 'block'
    },
    '& p': {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '19px',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  },
  customRadio_link: {
    display: 'block',
    width: '100%'
  },
  customCheckbox: {
    margin: '0 0 6px 0',
    '& .MuiCheckbox-colorPrimary.Mui-checked': {
      color: '#183C73'
    }
  },
  marginBM20: {
    marginBottom: 20,
  },
  marginBM30: {
    marginBottom: 30,
  },
  marginTop20: {
    marginTop: 20,
  },
  poContentModal: {
    minWidth: '570px',
    maxHeight: 'calc(82vh - 88px)',
    '@media (max-width:1024px)': {
      minWidth: '100%',
    },
  },
  poContentModal2: {
    display: "flex",
    minWidth: '570px',
    borderTop: '1px solid #CCCCCC',
    maxHeight: 'calc(82vh - 88px)',
    '@media (max-width:1024px)': {
      minWidth: '100%',
    },
  },
  subformHeading: {
    fontSize: '1em',
    textAlign: 'left',
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
  },
  purchaseContentModal: {
    '& .formsteps': {
      overflow: 'unset',
      maxHeight: '100%'
    },
    '& .formsteps h1': {
      display: 'none'
    },
    '& .formsteps > div': {
      padding: '0px'
    },
    '& .MuiDialogContent-root': {
      maxHeight: 'calc(80vh - 64px)',
      minWidth: '570px',
      '@media (max-width:1024px)': {
        minWidth: '100%',
        padding: '8px 16px'
      },
    },
    '& .MuiDialogActions-root ': {
      marginBottom: '0px !important',
      textAlign: 'left !important'
    },
    '& .MuiDialogActions-root': {
      padding: 0
    },
    '@media (max-width:1024px)': {
      minWidth: '100%',
    },
  },

  slickslider: {
    overflow: 'hidden',
    paddingTop: '0px;',
    '& h2': {
      marginTop: '0px;',
      color: 'rgba(0, 0, 0, 0.87)'
    },
    '& p': {
      color: 'rgba(0, 0, 0, 0.87)'
    },
    '& .slick-dots li': {
      width: '8px;',
      height: '8px;',
    },
    '& .slick-dots li button': {
      width: '8px;',
      height: '8px;',
    },
    '& .slick-dots li button:before': {
      background: 'rgba(0,0,0,0.38)',
      borderRadius: '10px;',
      width: '8px;',
      height: '8px;',
      opacity: '1',
    },
    '& .slick-dots li.slick-active button:before': {
      background: '#183C73;',
    },
    '& .slick-list .slick-slide img': {
      display: 'inline-block;',
    },
    '& .slick-list': {
      position: 'relative',
      display: 'block',
      overflow: 'hidden',
      margin: 0,
      padding: 0,
    },
    '& .slick-list:focus': {
      outline: 'none'
    },
    '& .slick-list.dragging': {
      // cursor: 'pointer',
      cursor: 'hand'
    },
    '& .slick-track': {
      position: 'relative',
      top: 0,
      left: 0,
      display: 'block'
    },
    '& .slick-track:before,&  .slick-track:after': {
      display: 'table',
      content: ''
    },
    '& .slick-track:after': {
      clear: 'both'
    },
    '& .slick-loading .slick-track': {
      visibility: 'hidden'
    },
    '& .slick-slide': {
      display: 'none',
      float: 'left',
      height: '100%',
      minHeight: '1px'
    },
    '&  [dir=\'rtl\'] .slick-slide': {
      float: 'right'
    },
    '& .slick-slide img': {
      display: 'block'
    },
    '&  .slick-slide.slick-loading img': {
      display: 'none'
    },
    '&  .slick-slide.dragging img': {
      pointerEvents: 'none'
    },
    '&  .slick-initialized .slick-slide': {
      display: 'block'
    },
    '&  .slick-loading .slick-slide': {
      visibility: 'hidden'
    },
    '&   .slick-vertical .slick-slide': {
      display: 'block',
      height: 'auto',
      border: '1px solid transparent'
    }
  },

  retrieveWrapper: {
    '& .MuiButton-root': {
      textTransform: 'none !important',
      fontSize: '0.9rem !important',
    },
    '& .btn-retrieve-disable': {
      backgroundColor: 'rgba(0, 0, 0, 0.38) !important',
    },
    '& .btn-retrieve-enable': {
      backgroundColor: 'rgba(24, 60, 115, 1) !important',
    },
    '& .btn-retrieve-loading': {
      backgroundColor: 'rgba(24, 60, 115, 1) !important',
    },
    '& .btn-retrieve-finished': {
      backgroundColor: 'rgba(76, 175, 80, 1) !important',
    },
  },
  refreshIconBtn: {
    width: 40,
    height: 40,
    minWidth: 'inherit',
    '& .MuiButton-startIcon': {
      margin: 0,
    },
  },
  WarningText: {
    marginBottom: '0px !important',

  },

  poformSubHeading: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '23px',
    borderBottom: '1px solid #CCCCCC',
    padding: '0 0 15px 24px',
    /* Active-color */

    color: 'rgba(0, 0, 0, 0.87)'
  },
  recInventory: {
    '& .formsteps': {
      maxHeight: '100%'
    },
    '& h1': {
      display: 'none'
    },
    '& .makeStyles-mainFormSteps-93': {
      padding: 0,
      overflow: 'hidden'
    }
  },
  formUpload: {
    background: '#FFFFFF',
    border: '1px dashed rgba(0, 0, 0, 0.38)',
    boxSizing: 'border-box',
    borderRadius: '4px',
    padding: '50px 0',
    marginBottom: '30px',
    textAlign: 'center',
    '& .fileTitle': {
      fontSize: '16px',
      lineHeight: '19px',
      color: 'rgba(0, 0, 0, 0.87)'
    },
    '& .fileSubTitle': {
      fontSize: '14px',
      lineHeight: '16px',
      color: 'rgba(0, 0, 0, 0.87)',
      marginTop: '8px !important',
    },
    '& label.fileLabelBtn input[type="file"]': {
      position: 'absolute',
      top: '-1000px',
    },
    '& .fileLabelBtn': {
      background: '#FFFFFF',
      border: '1px solid #183C73',
      boxSizing: 'border-box',
      boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
      borderRadius: '4px',
      padding: '9px 16px',
      marginTop: '16px',
      display: 'inline-block',
    },
    '& .fileLabelBtn:hover': {
      background: '#ffffff',
    },
    '&  .fileLabelBtn:active': {
      background: '#ffffff',
    },
    '&  .fileLabelBtn :invalid + span': {
      color: '#183C73',
    },
    '& .fileLabelBtn :valid + span': {
      color: '#183C73',
    }
  },
  editFlicker: {
    display: 'inline-block',
    verticalAlign: 'middle',
    color: '#183C73',
    marginLeft: 5,
    '& svg': {
      cursor: 'pointer'
    },
    '& svg.MuiSvgIcon-root': {
      position: 'absolute',
      // margin: '-5px 0px 0px 2px',
      bottom: '13px',
      // right: '-10px',
      color: '#183C73',
      cursor: 'pointer'
    },
  },
  editFlickerPopover: {
    '& .edit-flick-item': {
      // position: 'absolute',
      width: '180px',
      height: '40px',
      background: '#FFFFFF',
      boxShadow: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
      borderRadius: '4px',
    },
    '& .MuiOutlinedInput-input': {
      height: '20px',
      padding: '10px 12px',
      lineHeight: '20px'
    }
  },
  fieldGrp: {
    display: 'flex',
    '& .MuiButton-root': {
      maxWidth: '40px',
      minWidth: '40px',
      borderRadius: '0 4px 4px 0',
      background: '#183C73',
      color: '#ffffff'
    }
  },
  searchFilter: {
    overflow: 'hidden',
    display: 'block',
    zIndex: '9',
    '& .customFullWidth': {
      margin: 0
    }
  },
  searchFilterOperator: {
    overflow: 'hidden',
    display: 'block',
    zIndex: '9',
    '& .customFullWidth': {
      margin: 0
    },
    '& .MuiButton-text': {
      marginTop: 1
    }
  },
  marginTP30: {
    marginTop: 15
  },
  filterField: {
    width: '50%',
    paddingRight: '16px',
    float: 'left',
    '& .MuiInputBase-input': {
      height: '20px',
      padding: '10px 12px',
      lineHeight: '20px'
    },
  },
  filterFields: {
    width: '100%',
    paddingRight: '16px',
    float: 'left',
    '& .MuiInputBase-input': {
      height: '20px',
      padding: '10px 12px',
      lineHeight: '20px'
    },
  },
  searchbtn: {
    background: '#183C73',
    borderRadius: '4px',
    color: '#ffffff',
    width: '40px',
    height: '40px',
    minWidth: '40px',
    marginTop: '15px',
    float: 'right',
    '&:hover': {
      background: '#183c73d9'
    }
  },
  searchFilterPoper: {
    '& .MuiPopover-paper': {
      width: '700px',
      background: '#FFFFFF',
      boxShadow: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
      borderRadius: '4px',
      padding: '3px 16px 5px 16px',
    }
  },
  searchFilterPoperOp: {
    '& .MuiPopover-paper': {
      width: '390px',
      background: '#FFFFFF',
      boxShadow: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
      borderRadius: '4px',
      padding: '9px 16px 16px 16px',
    }
  },
  editFlickerPopper: {
    '& .edit-flick-item .MuiOutlinedInput-root': {
      borderRadius: '4px 0px 0px 4px',
    }
  },
  changePrice: {
    color: '#f00',
    fontWeight: 'bold'
  },
  minWdt165: {
    minWidth: 165
  },
  minWdt130: {
    minWidth: 130,
    padding: '0 8px !important',
  },
  editPriceItem: {
    position: 'relative',
  },
  returnInventoryForm: {
    '& .makeStyles-mainFormSteps-105': {
      height: 'calc(100vh - 230px)',
      overflow: 'auto'
    }
  },
  AddEditVendorForm: {
    height: 'calc(85vh - 230px)',
    overflow: 'auto',
    padding: '0 30px',
    '& .makeStyles-customRoot-97 .MuiFormControl-root': {
      margin: '7px 0 0 0 !important;'
    }
  },
  progressroot: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiLinearProgress-root': {
      height: '4px',
      width: '100%',
    }
  },
  progressLabel: {
    marginLeft: '8px',
  },
  progressLabel1: {
    marginLeft: '0px',
    width: '22%',
    display: 'flex',
    justifyContent: 'center',
  },
  tabSteps: {
    padding: 0,
    marginTop: 35
  },
  tabHeader: {
    boxShadow: 'none',
    background: '#fff',
    border: '1px solid #ccc',
    '& .MuiTab-root': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
      textTransform: 'capitalize',
      /* Inactive-color */
      color: 'rgba(0, 0, 0, 0.54)'
    }
  },
  shippingItem: {
    float: 'right',
    marginBottom: 40
  },
  filterOperatorBtn: {
    border: 0,
    background: 'none',
    boxShadow: 'none',
    '&:hover': {
      border: 0,
      background: 'none',
      boxShadow: 'none',
    },
    '& svg.MuiSvgIcon-root': {
      color: '#183C73',
      padding: '0px 2px',
      marginLeft: '2px'
    }
  },
  closeModal: {
    float: 'right',
    margin: '0 8px 0 0',
    cursor: 'pointer'
  },
  productDetail: {
    marginBottom: 30
  },
  confirmDatafield: {
    marginTop: 30
  },
  columnHeader: {
    padding: '0 20px !important',
  },
  headerCell: {
    padding: '0 8px !important',
  },
  tooltip: {
    overflow: 'hidden;',
    textOverflow: 'ellipsis;',
    whiteSpace: 'nowrap;',
    maxWidth: '170px;',
  },
  requiredField: {
    '& textarea': {
      borderColor: '#f00'
    }
  },
  ReferenceTabHead: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    /* Active-color */
    color: 'rgba(0, 0, 0, 0.87)',
    position: 'absolute',
    right: '14px',
    top: '15px',
    wordSpacing: '4px'
  },
  iconReset: {
    position: 'absolute;',
    right: '-25px;',
    top: '7px;',
    cursor: 'pointer;'
  },
  productAccordion: {
  },
  gridSpaceEven: {
    display: 'flex',
    justifyContent: 'space-around',
    height: '30px',
    '& grid': {
      border: '1px solid #CCCCCC',
      width: '25%',
      textAlign: 'center'
    }
  },
  borderAround: {
    border: '1px solid black'
  },
  accordingPrint: {
    paddingBottom: 16,
    '& .MuiAccordion-root': {
      marginBottom: '0px !important;'
    },
  },
  printButton: {
    background: 'none',
    border: '0px',
    cursor: 'pointer',
    float: 'right',
    fontSize: 16,
    color: '#183C73',
    fontWeight: 500,
  },
  leftButton: {
    float: 'left',
  },
  /** Invoice Detail page */
  rightBorder: {
    borderRight: '1px solid rgba(204, 204, 204, 1) !important',
  },
  cellWithoutBorderPadding: {
    padding: 1,
    border: 0,
  },
  cellWithoutBorder: {
    border: 0,
  },
  cellHeaderBold: {
    fontWeight: '600',
    fontSize: 14,
    lineHeight: '16.41px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  /** Spinner for Uploading, Sending, etc */
  loadingSpinner: {
    position: 'absolute',
    height: '100%',
  },
  smallFontSize: {
    fontSize: 14
  },
  newTabPanelMenu: {
    borderBottom: '1px solid #CCCCCC',
    '& .MuiTabs-root': {
      minHeight: 'inherit',
    },
    '& .MuiButtonBase-root.MuiTab-root': {
      textTransform: 'capitalize',
      minHeight: '30px',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#1C3C74',
      height: '3px',
    },
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-around',
    },
    '& .MuiTab-textColorPrimary': {
      fontWeight: 700,
      fontSize: '17px',
      lineHeight: '20px',
      color: '#CCCCCC',
      '&.Mui-selected': {
        fontWeight: 700,
        color: '#1C3C74',
      },
    },
  },
  newTabPanelbody: {
    '& .MuiBox-root': {
      padding: 0,
      '& > div > .MuiGrid-root.MuiGrid-container': {
        '& > .MuiGrid-root.MuiGrid-item': {
          paddingTop: '10px',
        },
        '& > .MuiGrid-root.MuiGrid-item:first-child': {
          paddingLeft: '20px',
        },
        '& > .MuiGrid-root.MuiGrid-item:last-child': {
          paddingRight: '30px',
        },
        '& > .MuiGrid-root.MuiGrid-item.no-padding': {
          padding: '0px',
        },
      },
    },
  },
  newTabPanelbody2: {
    '& .MuiBox-root': {
      '& > div > .MuiGrid-root.MuiGrid-container': {
        '& > .MuiGrid-root.MuiGrid-item:first-child': {
          paddingLeft: '13px !important',
          paddingRight: '0px',
          width: '505px'
        },
        '& > .MuiGrid-root.MuiGrid-item:last-child': {
          paddingRight: '0px',
          width: '547px'
        },
      },
    },
  },
  tableSize: {
    '& .MuiTable-root': {
      width: '100% !important'
    },
  },
  newTabPanelbodyAdded: {
    '& .MuiBox-root': {
      padding: 0,
      '& > div > .MuiGrid-root.MuiGrid-container': {
        '& > .MuiGrid-root.MuiGrid-item': {
          paddingTop: '10px',
        },
        '& > .MuiGrid-root.MuiGrid-item:first-child': {
          width: '450px',
          paddingLeft: '0px',
        },
        '& > .MuiGrid-root.MuiGrid-item:last-child': {
          paddingRight: '30px',
        },
        '& > .MuiGrid-root.MuiGrid-item.no-padding': {
          padding: '0px',
        },
      },
    },
  },
  newNextBtn: {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    // marginTop: '5px',
  },
  newCompleteBtn: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '22px',
    letterSpacing: '0em',
    backgroundColor: '#1C3C74',
    textTransform: 'capitalize',
    padding: '11px 27px 11px 27px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    marginLeft: '11px',
    '&:hover': {
      backgroundColor: '#1C3C74d9',
    },
    '&.secondary-color': {
      backgroundColor: '#5C5C5C',
      '&:hover': {
        backgroundColor: '#5C5C5Cd9',
      },
    },
  },
  newInsideAddBtn: {
    fontSize: '16px',
    lineHeight: '18px',
    margin: '12px 14px 0px 0px',
    padding: '8px 7px 8px 7px',
  },
  newComplianceAction: {
    position: 'absolute !important',
    right: '340px',
    bottom: '58px',
    '&.top': {
      bottom: '105px',
    }
  },
  complianceWarnings: {
    right: '344px',
  },
  complianceWarnings2: {
    bottom: '186px',
    right: '251px',
  },
  complianceWarnings1: {
    bottom: '143px',
    right: '20px'
  },
  complianceWarnings3: {
    right: '255px',
    bottom: '141px',
  },
  newSETitlePanel: {
    '& .name': {
      fontWeight: 700,
      fontSize: '25px',
      lineHeight: '30px',
      paddingLeft: '12px',
      minWidth: '170px',
      maxWidth: '600px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '& .refName': {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      // fontWeight: '400',
      fontSize: '25px',
      lineHeight: '30px',
      color: '#000000',
      paddingLeft: '12px',
      minWidth: '170px',
      maxWidth: '600px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '& .label': {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '22px',
      color: '#1C3C74',
      '&:not(:first-child)': {
        marginLeft: '55px',
      },
    },
    '& .value': {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '24px',
      marginLeft: '13px',
    },
  },
  newSEBodyPanel1: {
    padding: '10px 50px 0px !important',
  },
  newSEBodyPanel: {
    padding: '10px 100px 0px',
  },
  newSEForm: {
    // marginLeft: '16px',
    '& .MuiTableHead-root': {
      display: 'none',
    },
    '& .MuiTableCell-root': {
      padding: '3px 0px 3px 0px',
      borderWidth: '0px',
      verticalAlign: 'top',
    },
    '& .new-subtitle': {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '22px',
      color: '#5C5C5C',
      paddingTop: '12px',
    },
  },
  newProductForm: {
    '& .MuiTableCell-root': {
      paddingTop: '2px',
      paddingBottom: '2px',
      verticalAlign: 'top',
    },
    '& .retrieve-btn': {
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '0px',
      marginLeft: '10px',
      marginTop: '22px',
    },
  },
  addedProductPanel: {
    width: '630px',
    height: '441px',
    overflowY: 'scroll',
    '& .product-title': {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '21px',
      color: '#000000',
    },
    '& .product-subtitle': {
      fontWeight: 700,
      fontSize: '15px',
      lineHeight: '17px',
      color: '#000000',
      padding: '10px 22px 9px 0px',
      '& .MuiBadge-root': {
        '& .MuiBadge-colorPrimary': {
          backgroundColor: '#5C5C5C',
          fontSize: '8px',
          width: '11px',
          height: '11px',
          minWidth: 'inherit',
          right: '-5px',
          top: '1px',
        },
      },
      '& .MuiChip-root': {
        marginLeft: '4px',
        backgroundColor: '#1C3C74',
        height: '30px',
        borderRadius: '15px',
        '& .MuiChip-label': {
          lineHeight: '18px',
          color: '#FFFFFF',
        },
      },
    },
    "& .main-accordion": {
      backgroundColor: '#F5F5F5',
      border: '2px solid #FFFFFF',
      color: '#000000',
      boxShadow: 'none',
      '&.Mui-expanded': {
        margin: '0px',
      },
      '& .MuiAccordionSummary-root': {
        padding: '0px 7px',
        minHeight: '35px',
        '&.Mui-expanded': {
          minHeight: '35px',
        },
        '&.red': {
        },
        '& .MuiAccordionSummary-content': {
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '0px',
          '& .accordion-header-index': {
            fontWeight: 400,
            fontSize: '8px',
            lineHeight: '9px',
          },
          '& .accordion-header-label': {
            fontWeight: 700,
            fontSize: '12px',
            lineHeight: '14px',
          },
          '& .accordion-header-text': {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '16px',
            // maxWidth: '160px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            '&.red': {
              color: '#f00',
            },
          },
          '& .MuiIconButton-root': {
            padding: '5px',
          },
        },
      },
      '& .MuiCollapse-root': {
        '& .MuiAccordionDetails-root': {
          display: 'block',
          padding: '3px 0px 3px 3px',
          borderTop: '3px solid #FFFFFF',
          marginBottom: '-10px',
          '& .accordion-table': {
            '& .MuiTableCell-body': {
              padding: '2px',
              borderWidth: '0px',
              '&.details': {
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '14px',
                width: '170px',
                textAlign: 'right',
                paddingRight: '18px',
                '&.left': {
                  textAlign: 'left',
                },
              },
              '&.label': {
                fontWeight: 800,
                fontSize: '14px',
                lineHeight: '17px',
                width: '120px',
              },
              '&.value': {
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '19px',
                wordBreak: 'break-word',
                minWidth: '160px',
                '&.red': {
                  color: '#f00',
                },
              },
            },
          },
          '& .edit-btn': {
            float: "right",
            marginRight: "10px",
            marginBottom: "10px",
            display: 'none',
          },
        },
        '&.MuiCollapse-entered .edit-btn': {
          display: 'block !important',
          padding: '0px 6px 0px 6px !important'
        },
      },
    },
  },
  completePanel: {
    // height: '441px',
    width: '570px',
    '& .product-title': {
      // display: 'none',
    },
    '& .product-subtitle': {
      fontSize: '18px',
      lineHeight: '21px',
      '& .MuiChip-root': {
        height: '37px',
        borderRadius: '15px',
        '& .MuiChip-label': {
          fontSize: '20px',
          lineHeight: '23px',
        },
      },
    },
  },
  editProductPanel1: {
    width: '601px !important',
  },
  editProductPanel2: {
    width: '539px !important',
  },
  editProductPanel: {
    width: '570px',
  },
  overview: {
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        borderWidth: '0px',
        padding: '9px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '&:first-child': {
          paddingLeft: '73px',
          minWidth: '163px',
        },
        '&.details': {
          fontWeight: 700,
          fontSize: '20px',
          lineHeight: '23px',
          color: '#000000',
          padding: '40px 28px 10px',
          '& img': {
            marginRight: '11px',
          },
          '& span.MuiTypography-root': {
            verticalAlign: 'super',
          },
        },
        '&.label': {
          fontWeight: 300,
          fontSize: '14px',
          lineHeight: '16px',
          color: '#5C5C5C',
          width: '105px',
        },
        '&.value': {
          fontWeight: 700,
          fontSize: '17px',
          lineHeight: '20px',
          color: '#000000',
        },
      },
      '&.border-row': {
        borderBottom: '1px solid #CCCCCC',
        '& .MuiTableCell-root': {
          paddingBottom: '50px',
        },
      },
    },
  },

  newOverview: {
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        borderWidth: '0px',
        padding: '9px',
        // overflow: 'hidden',
        // textOverflow: 'ellipsis',
        // whiteSpace: 'nowrap',
        '&:first-child': {
          paddingLeft: '73px',
          minWidth: '163px',
        },
        '&.details': {
          fontWeight: 700,
          fontSize: '20px',
          lineHeight: '23px',
          color: '#000000',
          padding: '40px 28px 10px',
          '& img': {
            marginRight: '11px',
          },
          '& span.MuiTypography-root': {
            verticalAlign: 'super',
          },
        },
        '&.label': {
          fontWeight: 300,
          fontSize: '14px',
          lineHeight: '16px',
          color: '#5C5C5C',
          width: '105px',
          verticalAlign: 'text-top;',
        },
        '&.value': {
          fontWeight: 700,
          fontSize: '17px',
          lineHeight: '20px',
          color: '#000000',
          wordBreak: 'break-all !important;',
          verticalAlign: 'text-top;'
        },
        '&.myValue': {
          overflow: 'hidden',
          textOverflow: 'clip',
          whiteSpace: 'pre-wrap;'
        },
      },
      '&.border-row': {
        borderBottom: '1px solid #CCCCCC',
        '& .MuiTableCell-root': {
          paddingBottom: '50px',
        },
      },
    },
  },
  overviewcompletePanel: {
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        padding: '5px',
        maxWidth: '190px',
        '&:first-child': {
          paddingLeft: '67px',
          minWidth: '183px',
        },
        '&.details': {
          padding: '22px 0px 7px 22px',
          '& img': {
            width: '12%'
          }
        },
      },
    },
  },
  newOverviewcompletePanel: {
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        padding: '5px',
        maxWidth: '170px',
        '&:first-child': {
          paddingLeft: '67px',
          minWidth: '175px',
        },
        '&.details': {
          padding: '22px 0px 7px 22px',
          '& img': {
            width: '8%'
          }
        },
      },
    },
  },
  overviewEdit: {
    padding: 'inherit !important',
    width: '100%',
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        paddingTop: '2px',
        paddingBottom: '2px',
        paddingRight: '0px',
        "& input[readonly], & .MuiInputBase-input.MuiSelect-select": {
          fontWeight: 700,
          fontSize: '17px',
          lineHeight: '20px',
          color: '#000000',
        },
      },
    },
  },

  certifyUsername: {
    paddingLeft: '70px',
    '& .MuiTypography-root': {
      width: '409px',
      height: '41px',
      background: 'rgba(196, 196, 196, 0.18)',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      marginLeft: '14px',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '21px',
      color: '#000000',
      paddingTop: '12px',
      paddingLeft: '20px',
    },
  },
  newCompliance: {
    marginLeft: '29px',
  },
  compliance: {
    position: 'relative',
    background: 'rgba(255, 169, 169, 0.76)',
    borderRadius: '10px',
    paddingLeft: '28px !important',
    width: '222px !important',
    height: '36px',
    '& .MuiTypography-root': {
      fontWeight: 700,
      fontSize: '10px',
      lineHeight: '11px',
      color: '#000000',
    },
    '& img': {
      position: 'absolute',
      top: '0px',
      left: '-13px',
    },
    '&.compliance-lg': {
      width: '210px !important',
      height: '50px',
      paddingLeft: '46px !important',
      '& .MuiTypography-root': {
        fontSize: '12px',
        lineHeight: '14px',
      },
      '& img': {
        width: '54px',
        top: '-2px',
        left: '-13px',
      },
    },
    '&.compliance-sm': {
      width: '177px',
      height: '26px',
      paddingLeft: '23px !important',
      '& .MuiTypography-root': {
        fontSize: '8px',
        lineHeight: '9px',
      },
      '& img': {
        width: '29px',
        top: '-2px',
        left: '-9px',
      },
    },
  },
  toastLarge: {
    '& .MuiAlert-message .MuiTypography-root': {
      fontWeight: '700 !important',
      fontSize: '20px !important',
      lineHeight: '23px !important',
    },
  },
  toastMedium: {
    '& .MuiAlert-message .MuiTypography-root': {
      fontWeight: '700 !important',
      fontSize: '18px !important',
      lineHeight: '21px !important',
    },
  },
  toastSmall: {
    '& .MuiAlert-message .MuiTypography-root': {
      fontWeight: '700 !important',
      fontSize: '14px !important',
      lineHeight: '14px !important',
    },
  },
  toastWarning: {
    '& .MuiAlert-message .MuiTypography-root.content': {
      fontWeight: '400 !important',
      fontSize: '14px !important',
      lineHeight: '16px !important',
      color: '#000000 !important'
    },
    '& .MuiAlert-message .MuiTypography-root.MuiAlertTitle-root': {
      // paddingTop :'27px'
      paddingBottom: '0px',
      // marginBottom  :'-8px',
    },
  },
  newPrint: {
    display: 'none',
    '@media print': {
      display: 'block',
      pageBreakBefore: 'always',
    },
    padding: '15px',
    color: '#000000',
    '& .title': {
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '17px',
      borderBottom: '1px solid #CCCCCC',
      padding: '6px',
      '& .label': {
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '14px',
      },
    },
    '& .sub-heading': {
      fontWeight: 500,
      fontSize: '10px',
      lineHeight: '12px',
      marginTop: '1px',
    },
    '& .details-form': {
      marginBottom: '10px',
      '& .MuiTableCell-root': {
        borderWidth: 0,
        padding: '2px 0px 2px',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '14px',
        '&.MuiTableCell-head': {
          fontWeight: 300,
          fontSize: '9px',
          lineHeight: '11px',
        },
      },
      '&.col-5 .MuiTableCell-root.MuiTableCell-head': {
        width: '20%',
      },
      '&.col-3 .MuiTableCell-root.MuiTableCell-head': {
        width: '33%',
      },
    },
    '& .sub-title': {
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '14px',
      '& .label': {
        fontSize: '10px',
        lineHeight: '12px',
        marginRight: '10px',
      },
    },
    '& .product-box': {
      border: '1px solid #CCCCCC',
      borderRadius: '4px',
      width: '365px',
      '& .head': {
        padding: '13px 5px 10px',
        borderBottom: '1px solid #CCCCCC',
        '& .label': {
          fontWeight: 300,
          fontSize: '9px',
          lineHeight: '11px',
          marginRight: '3px',
        },
        '& .text': {
          fontWeight: 500,
          fontSize: '10px',
          lineHeight: '12px',
          marginRight: '8px',
        },
      },
      '& .box-table': {
        marginTop: '8px',
        marginBottom: '10px',
        '& .MuiTableCell-root': {
          borderWidth: 0,
          padding: '1px 5px 1px',
          fontSize: '8px',
          lineHeight: '10px',
          '&.label': {
            fontWeight: 300,
          },
          '&.text': {
            fontWeight: 500,
          },
        },
      },
    },
  },
  avoidBreakInside: {
    '@media print': {
      display: 'block',
      pageBreakBefore: 'always',
    },
  },
  exportTitle: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '24px',
    padding: '4px 0px 0px 19px'
  },
  exportModalWidth :{
    minWidth : '222px !important'
  },
  exportPopover: {
    width: '350px !important',
    '& .MuiFormControlLabel-root': {
      minWidth: '165px'
    }
  },
  newExportPopover: {
    width: '249px',
    background: '#FFFFFF',
    border: '1px solid #CCCCCC',
    borderRadius: '4px',
    color: '#000000',
    '& .MuiGrid-item:first-child': {
      borderBottom: '1px solid #CCCCCC',
      '& .MuiTypography-root': {
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '16px',
        paddingTop: '10px',
        paddingLeft: '17px',
        paddingBottom: '7px',
      },
      '& .MuiButtonBase-root.MuiIconButton-root': {
        position: 'absolute',
        top: '1px',
        right: '3px',
        padding: '3px',
      },
    },
    '& .MuiGrid-item:last-child': {
      borderTop: '1px solid #CCCCCC',
      '& .MuiButton-root': {
        float: 'right',
        marginTop: '5px',
        marginRight: '12px',
        marginBottom: '9px',
        background: '#1C3C74',
        borderRadius: '4px',
        padding: '6px 12px',
        '& .MuiButton-label': {
          color: '#FFFFFF',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '14px',
        },
      },
      '& .MuiButton-root:last-child':{
        background: '#5C5C5C',
        margin: '5px -5px'
      },
    },
    '& .MuiBox-root': {
      width: '100%',
      border: '4px solid #EBEBE9',
      borderRadius: '15px',
    },
    '& .MuiLinearProgress-root': {
      height: '6px',
      borderRadius: '5px',
      '&.MuiLinearProgress-colorPrimary': {
        backgroundColor: 'rgba(76, 175, 80, 0.38)',
      },
      '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: '#4CAF50',
        borderRadius: '5px',
      },
    },
    '& .MuiFormControlLabel-root': {
      marginLeft: '-5px',
      '& .MuiFormControlLabel-label': {
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '24px',
      },
    },
    '& .MuiCheckbox-root': {
      padding: '5px',
      color: '#1C3C74',
      '& .MuiSvgIcon-root': {
        width: 20,
        height: 20,
      }
    },
    '& .MuiList-padding': {
      padding: '5px',
      '& .MuiListItem-root.MuiListItem-gutters': {
        padding: '4px 12px 4px',
      },
    },
  },
  productTooltip: {
    '&.MuiTooltip-tooltip': {
      backgroundColor: '#E1DDDD',
      color: '#000000',
      padding: '0px',
      marginTop: '5px',
      '& .MuiTooltip-arrow': {
        color: '#E1DDDD',
      },
      '& .MuiList-root.MuiList-padding': {
        padding: '0px',
        '& .MuiListItem-container': {
          '& .MuiListItem-root': {
            paddingTop: '8px',
            paddingBottom: '8px',
            '& .MuiTypography-body1': {
              fontWeight: 700,
              fontSize: '12px',
              lineHeight: '14px',
            },
          },
          '& .MuiListItem-secondaryAction': {
            paddingRight: '180px',
          },
          '& .MuiListItemSecondaryAction-root': {
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '17px',
          },
          '&:nth-child(even)': {
            backgroundColor: '#5C5C5C',
            color: '#FFFFFF',
          },
        },
      },
    },
  },

  // adding new styles related to invoice-drawer-2

  // cellWithoutBorderComment: {
  //   border: 0,
  //   fontWeight: 500,
  //   fontSize: '14px;',
  //   lineHeight: "16.41px;",
  //   color : 'color: rgba(0, 0, 0, 0.87);',
  // },


  cellwithpaddings: {
    padding: "15px 0px 0px 0px;",
    border: 0,
  },
  userInputs: {
    padding: 1,
    border: 0,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px;',
    lineHeight: '16px;',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  tabletitle: {
    color: "rgba(0, 0, 0, 0.54)",
    padding: 1,
    border: 0,
    // position: 'absolute;',
    width: '89px;',
    height: '16px;',
    // left: 346px;
    // top: 117px;
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px;',
    lineHeight: '16px;',
  },
  tabletitle2: {
    color: "rgba(0, 0, 0, 0.54);",
    padding: 5,
    border: 0,
    // position: 'absolute;',
    width: '89px;',
    height: '16px;',
    // left: 346px;
    // top: 117px;
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px;',
    lineHeight: '16px;',
  },

  printButton2: {
    background: 'none',
    border: '0px',
    cursor: 'pointer',
    float: 'right',
    fontSize: 16,
    color: '#183C73',
    fontWeight: 500,
    minWidth: "40px",
    top: "-5px",
  },
  closeButton: {
    background: 'none',
    cursor: 'pointer',
    float: 'right',
    color: '#183C73',
    fontWeight: 500,
    position: 'absolute',
    right: '6px',
    top: '3px',
  },
  tablecelldata: {
    fontWeight: '400;',
    fontSize: '12px;',
    lineHeight: '14px;',
  },
  tableColumn: {
    padding: '0 !important',
  },
  descTooltip: {
    '&.MuiTooltip-tooltip': {
      backgroundColor: '#E1DDDD',
      color: '#000000',
    },
  },
  relativeTextPosition: {
    position: 'relative',
    right: '105px',
  },
  absoluteTextPosition: {
    position: 'absolute',
  },
  tableRow: {
    '& .MuiTableRow-root': {
      width: 'max-content',
    }
  },
  // pref card styles
  iconblock1: {
    position: 'relative',
  },
  overflowBoxPref: {
    border: "0.5px solid #CCCCCC",
    borderRadius: '5px',
    width: "440px",
    maxHeight: "65px",
    marginBottom:"25px",
    overflowY: "scroll",
    position: "relative",
    '& .MuiListItem-root': {
      padding: '0px 10px 0px 10px !important'
    }
  },
  dialogSubheading: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  prefTablePanel: {
    height: '75px',
    marginTop: '20px',
    overflowY: 'scroll',
    width: '539px !important',
  },
  prefCardTable: {
    '& .weightedText': {
      fontWeight: '600;',
      fontSize: '14px;',
      lineHeight: '16px;',
    },
    '& .MuiTableCell-root': {
      border: '1px solid #CCCCCC',
      padding: '10',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '18px',
      color: '#000000',
      '&.MuiTableCell-head': {
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '22px',
        color: '#5C5C5C',
      },
    },
    '& .product-title': {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '21px',
      color: '#000000',
    },
  },
  newLabel: {
    color: "#CCCCCC",
    fontSize: "15px",
    transform: 'translate(0, 0px) scale(1)',
    fontWeight: 400,
    inlineSize: 'max-content',
    lineHeight: '18px',
    display: 'inline',
  },
  prefCategoryTable: {
    padding: '20px',
  },
  boldCount: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '22px',
    color: '#000000',
  },
  newTitlePanel: {
    '& .label': {
      fontWeight: 700,
      fontSize: '30px',
      lineHeight: '36px',
      color: '#1C3C74',
    },
    '& .detailLabel': {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '20px',
      lineHeight: '24px',
    },
    '& .details': {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '17px',
      lineHeight: '20px',
      color: '#000000',
    },
    '& .rightContent': {
      paddingRight: '30px',
      display: 'flex',
      flexDirection: 'row-reverse',
    },
  },
  newPopoverBox: {
    // width: '100%',
    height: '85%',
    border: '1px solid #CCCCCC',
    boxSizing: 'border-box',
    borderRadius: 4,
    margin: '20px',
    overflowY: "scroll",
  },
  pickTable: {
    // marginLeft: '16px',
    '& .MuiTableHead-root': {
      display: 'none',
    },
    '& .MuiTableCell-root': {
      padding: '3px 0px 3px 0px',
      borderWidth: '0px',
      verticalAlign: 'top',
    },
    '& .new-subtitle': {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '22px',
      color: '#5C5C5C',
      paddingTop: '12px',
    },
  },
  newtableTitle: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '22px',
    color: '#000000',
    padding: '8px 0px 8px 0px',
  },
  paddingLeftRight: {
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  cellWithBorder: {
    border: '1px solid rgba(204, 204, 204, 1) !important',
  },
  rowWithBorder: {

    borderRight: '1px solid rgba(204, 204, 204, 1) !important',
    borderLeft: '1px solid rgba(204, 204, 204, 1) !important',
    background: 'rgba(245, 245, 245, 0.49)',
    '& .MuiTableCell-root': {
      padding: '0px 0px 0px 8px',
    }
  },
  highlightedRow:{
    '&:hover': {
      backgroundColor: '#d8d9da',
    },
  },
  prefCardSelected:{
    backgroundColor: '#d8d9da',
  },
  overviewGrid: {
    '&.MuiGrid-grid-xs-7': {
      padding: '16px',
    },
  },
  detailGrid: {
    padding: '10px',
    borderLeft: '1px dashed #CCCCCC',
    '& .textlable': {
      color: '#5C5C5C',
      fontSize: '15px',
      lineHeight: '20px'
    }
  },
  divider: {
    height: '100%',
  },
  imgstyle: {
    maxWidth: '100%',
    maxHeight: '100%',
    display: 'block', /* remove extra space below image */
  },
  newCheckboxPrimary: {

    color: '#1C3C74 !important',
  },
  checkboxSelected: {
    color: '#009933 !important',
  },
  useAllBtn: {
    float: 'right',
    margin: '5px',
    fontWeight: 700,
    lineHeight: '22px',
    backgroundColor: '#1C3C74',
    textTransform: 'capitalize',
    padding: '0px 10px 0px 0px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#1C3C74d9',
    },
    '&.secondary-color': {
      backgroundColor: '#5C5C5C',
      '&:hover': {
        backgroundColor: '#5C5C5Cd9',
      },
    },
  },
  //isAddProduct
  addProduct: {
    position: 'relative',
    left: "383px",
  },

  footerPrice: {
    fontFamily: 'Helvetica',
    fontWeight: '700',
    fontSize: '18px',
    padding: '10px 90px 10px 0px'
  },
  productCount: {
    fontFamily: 'Helvetica',
    fontSize: '15px',
    padding: '0px 0px 10px 0px'
  },
  //Errormsg
  errorMessage: {
    "& .content":{
      fontWeight: '700 !important',
      fontSize: '20px !important',
      width:'20rem',
      lineHeight: "23px !important",
    }
  },
  adaptiveSearchWrapper: {
    position: 'relative',
    left: '0px',
    '& .MuiInputBase-root': {
      borderRadius: '0px',
      backgroundColor: 'rgba(196, 196, 196, 0.18) !important',
      border: '0px solid #cccccc',
      '&:hover': { 
        border: '0px solid #cccccc'
      }
    },
    '& .MuiAutocomplete-endAdornment': {
      right: '5px !important'
    },
  },
  adaptiveSearchLabel: {
    // height: '20px',
    // marginTop: '-4px !important',
  },
   //Addproduct closebtn PO
   addProductclosebtn:{
    marginLeft: '296px',
    cursor:"pointer",
  }
}), { index: 1 });