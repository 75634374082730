
import { useEffect } from "react";
import moment from "moment";
import { useDamlHoliday } from "../../context/DamlContext";


export const usePaymentDateCalculator =()=>{

  const { holidays, loaded, loadHoliday } = useDamlHoliday();

  useEffect(() => {
    if (!loaded) loadHoliday();
  }, [loaded, loadHoliday]);

  const addDays = (initdate, days) => {
    let date = new Date(initdate);
    return new Date(date.setDate(date.getDate() + days));
  }

  const tydeiDateCalculator = (paymentDueDate, offset, next) => {
    let previousDate = new Date(paymentDueDate);
    previousDate.setDate(next ? paymentDueDate.getDate() + 1 : paymentDueDate.getDate() - 1);
    if (isOffDay(paymentDueDate)) {
      return tydeiDateCalculator((previousDate), offset, next)
    } else if (offset === 0) {
      return paymentDueDate
    } else {
      return tydeiDateCalculator((previousDate), --offset, next)
    }
  }

  const isOffDay = (date) => {
    return (date.getDay() === 0 || date.getDay() === 6 || holidays?.includes(moment(date).format('YYYY-MM-DD')))
  }

  return { addDays, tydeiDateCalculator, isOffDay }

}