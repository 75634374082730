import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, 
  Grid, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { useStyles } from "../styles";


export default function DetailModal({
  modaltitle,
  buttontitle,
  cancelButtontitle = "Back",
  contract = {},
  isButtonVisible = true,
  isDefaultVisible = false,
  cancelHandler = null,
  modalOpenController = false }) {

  const classes = useStyles();

  const [modalOpenState, setModalOpenState] = useState(false);

  React.useEffect(() => {
    if (isDefaultVisible) setModalOpenState(true);
  }, [isDefaultVisible]);

  const handleConductModalClose = () => {
    setModalOpenState(false);
  };
  

  return (
    <>
      <Dialog
        open={modalOpenState || modalOpenController}
        onClose={handleConductModalClose}
        classes={{ paper: classes.dialogPaperLg }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle} >{modaltitle}</DialogTitle>
        <DialogContent className={classes.dialogContent} >

          <Grid item xs={12}>
            <Table size="small">

              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.tableCell} >Key</TableCell>
                  <TableCell className={classes.tableCell} >Value</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Object.keys(contract).map((key, i) => {
                  const val = (typeof contract[key] === "string") ? contract[key] : null;
                  return (
                    <TableRow key={"row" + i} className={classes.tableRow}>
                      <TableCell className={classes.tableCell}>
                        {key}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {val}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>

            </Table>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            handleConductModalClose();
            if (typeof cancelHandler === "function" && cancelHandler) cancelHandler();
          }} color="primary">
            {cancelButtontitle}
          </Button>
        </DialogActions>
      </Dialog>

      {isButtonVisible && (<Button
        color="secondary"
        size="small"
        className={classes.button}
        variant="contained"
        onClick={() => { setModalOpenState(true); }}
      >
        {buttontitle}
      </Button>)}
    </>
  );
}