import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Typography, 
  TableContainer, Paper, FormControl, TextField } from "@material-ui/core";
import { useLayoutDispatch, setLoading, unsetLoading } from "../../context/LayoutContext";
import ProductDetailViewTable from "../surgicalevent/product-detail-view-table";
import useStyles from "../surgicalevent/styles";


/**
 * Dialog when Confirm Back-Order
 * Restock Inventory Contracts in Vendor
 * @param {Boolean} openModal
 * @param {Function} setOpenModal
 * @param {Object} inventoryData
 * @param {Function} setInventoryData
 * @param {Function} addProductItem
 * @param {Array} clearProductParams
 * @param {Boolean} isQuantiy
 * @param {Number|null} defaultQuantity If null, then total count of inventory data
 * @returns 
 */
export default function ConfirmBackOrder ({
  openModal, setOpenModal, inventoryData, setInventoryData,
  addProductItem, clearProductParams=[], isQuantiy=false, defaultQuantity=1,
}) {

  // console.log("[ConfirmBackOrder]");
    
  const classes = useStyles();

  const layoutDispatch = useLayoutDispatch();

  // quantity
  const [quantity, setQuantity] = useState(1);
  const maxQuantity = (inventoryData.datas && typeof inventoryData === 'object' && inventoryData.datas.length)
      ? inventoryData.datas.length : 1;
  defaultQuantity = (inventoryData.datas && typeof inventoryData === 'object' && inventoryData.datas.length)
      ? ((defaultQuantity === null || defaultQuantity > inventoryData.datas.length) ? inventoryData.datas.length : (
        (defaultQuantity < 1) ? 1 : defaultQuantity
      )) : 1;


  // handle to change shipmentdate
  const handleChangeShipmentdate = (val) => {
    let tempInvData = {
      ...inventoryData,
      ishipmentdate: val,
    };
    if (inventoryData.datas && typeof inventoryData === 'object' && inventoryData.datas.length) {
      const count = isQuantiy ? quantity : defaultQuantity;
      tempInvData.datas = inventoryData.datas.slice(0, count)
      .map(inv => ({...inv, ishipmentdate: val}));
    }
    setInventoryData(tempInvData);
  };

  // Click "Confirm"
  const confirmInventory = async () => {
    // spinner
    setLoading(layoutDispatch);

    await addProductItem(inventoryData);

    unsetLoading(layoutDispatch);
  };

  const handleCancel = () => {
    setOpenModal(false);
    unsetLoading(layoutDispatch);
  };
 

  useEffect(() => {
    if (!!openModal) {
      console.log("[ConfirmBackOrder] Open ConfirmInventory dialog.");

      setQuantity(1);
    }
  }, [openModal]);


  return (
    <Dialog
      open={openModal}
      className={classes.customRoot}
      aria-labelledby="draggable-dialog-title"
      classes={{paper: classes.overflowHidden}}
    >
      <div className={classes.purchaseContentModal}> 
        <DialogTitle style={{ cursor: 'move', borderBottom: '1px solid #cccccc' }} id="draggable-dialog-title">
          <div className="view-heading">Confirm Back-order</div> 
        </DialogTitle>

        <DialogContent className={classes.poContentModal}>
          <DialogContentText  component={'div'}>
            <div className="poModel"> 

              <h3  className={classes.subformHeading}> Product Details </h3>

              <Typography component={'div'}>
                <TableContainer  className={classes.productTableContainer} component={Paper}>
                  <ProductDetailViewTable 
                    obj={inventoryData.iproduct}
                    options={{
                      enableParams: ['sepreferencenumber'],
                      hiddenParams: ['seplotcode', 'sepdistributor', 'sepproductwaste','sepproductexpiration'],
                    }}
                  />
                </TableContainer>
              </Typography>

              <div className={classes.confirmDatafield}>
                <div className={classes.marginBM30}>
                  <FormControl variant="outlined" className={classes.formControl}>     
                    <TextField
                      id="eventdate"
                      label={"Expected Ship Date"}
                      type="date"
                      value={inventoryData.ishipmentdate || ""}
                      onChange={(e) => handleChangeShipmentdate(e.target.value)}
                      className={classes.datehalfWidth}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"  
                    />
                  </FormControl>
                </div>

                {
                  isQuantiy &&
                  <div className={classes.marginBM30}>
                    <TextField
                      id="input-with-icon-adornment123"
                      style={{ margin: 8 }}
                      label="Quantity"
                      placeholder=""
                      value={quantity}
                      className={classes.customFullWidth}
                      margin="normal"
                      // InputProps={{ 
                      //   inputProps: { min: 1, max: maxQuantity } 
                      // }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        const val = e.target.value;
                        if (val >= 1 && val <= maxQuantity) setQuantity(val);
                      }}
                      variant="outlined"
                      type="number"
                    />
                  </div>
                }
              </div>
          
            </div>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <div className="form-footer">
            <div className="right-buttons">
              <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
              <Button variant="contained" color="primary" onClick={confirmInventory} >Confirm</Button> 
            </div>
          </div>
        </DialogActions>
      </div>

    </Dialog>
  );
}