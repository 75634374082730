import React, { useState, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton, AppBar, Tabs, Tab } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { getValue } from "../../Contracts/functions";
import { a11yProps } from "../MultiTabsModals/TabPanel";
import { AdminDetailModel, validateAdminDetail } from "../../../models/AdminDetail";
import AdminDetailContents from "./contents";
import { useStyles } from "../styles";



export default function AdminDetailModal({ 
  modaltitle, 
  buttontitle = null, 
  buttonicon = <Edit />,
  okButtontitle = "Ok", 
  cancelButtontitle = "Cancel", 
  setDetailValue, 
  setDefaultValue = null, 
  defaultDetail = {},
  isButtonVisible = true,
  isDefaultVisible = false,
  cancelHandler = null,
  adminDetailPath }) {

  const classes = useStyles();

  const [modalOpenState, setModalOpenState] = useState(false);
  const [modalOpened, setModalOpened] = React.useState(false);
  const [value, setValue] = useState(0);
  const [oldDefaultDetail, setOldDefaultDetail] = useState({});
  const [adminDetail, setConductForm] = useState(getValue(defaultDetail, adminDetailPath, { defaultValue: AdminDetailModel }));

  // console.log("[AdminDetailModal]", adminDetail);


  useEffect(() => {
    if (isDefaultVisible) handleConductModalOpen();
  }, [isDefaultVisible]);

  useEffect(() => {
    // console.log("[AdminDetailModal] on changed");
    if (JSON.stringify(oldDefaultDetail) !== JSON.stringify(defaultDetail)) {
      // console.log("[AdminDetailModal]", defaultDetail);
      setOldDefaultDetail(defaultDetail);
      setConductForm(getValue(defaultDetail, adminDetailPath, { defaultValue: AdminDetailModel }));
    }
  }, [defaultDetail, oldDefaultDetail, adminDetail, adminDetailPath]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleConductModalOpen = () => {
    setModalOpenState(true);
    setValue(0);
  };

  const handleConductModalClose = () => {
    setModalOpenState(false);
  };

  const handleConductModalAccept = () => {
    console.log("[AdminDetailModal]", adminDetail);
    setDetailValue(adminDetail);
    setModalOpenState(false);
    if (validateAdminDetail(adminDetail))
      setModalOpened(true);
    else 
      setModalOpened(false);
  };


  return (
    <>

      <Dialog
        open={modalOpenState}
        onClose={handleConductModalClose}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" >{modaltitle}</DialogTitle>
        <DialogContent >

          <div className={classes.multiTabs}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable force tabs example"
              >

                <Tab key={0} label="Automated PO" {...a11yProps(0)} />
                <Tab key={1} label="Approval Amount" {...a11yProps(1)} />
                
              </Tabs>
            </AppBar>
            
            <AdminDetailContents
              adminDetail={adminDetail}
              setConductForm={setConductForm}
              value={value}
            />
          
          </div>

        </DialogContent>
        <DialogActions className={classes.dialogTabsAction}>
          <Button onClick={handleConductModalAccept} color="primary" autoFocus>
            {okButtontitle}
          </Button>
          <Button onClick={() => {
              handleConductModalClose();
              if (typeof cancelHandler === "function" && cancelHandler) cancelHandler();
            }} color="primary">
            {cancelButtontitle}
          </Button>
        </DialogActions>
      </Dialog>

      {isButtonVisible && (
        buttontitle
        ?
        <Button
          color={modalOpened ? "secondary" : "primary"}
          size="small"
          className={classes.button}
          variant="contained"
          onClick={() => {
            if (typeof setDefaultValue === "function" && setDefaultValue) setDefaultValue(defaultDetail);
            handleConductModalOpen();
          }}
        >
          {buttontitle}
        </Button>
        :
        <IconButton
          color={modalOpened?"secondary":"primary"}
          size="small"
          className={classes.button}
          variant="contained"
          onClick={() => {
            if (typeof setDefaultValue === "function" && setDefaultValue) setDefaultValue(defaultDetail);
            handleConductModalOpen();
          }}
        >
          {buttonicon}
        </IconButton>
      )}

    </>
  );
}