import { useCallback } from "react";
import { useSelector } from "react-redux";
import { dispatch } from "../store";
import { setPO, setPOAll, setPOData } from "../store/actions/poAction";


/**
 * Purchase Order Hook
 * @returns 
 */
export default function usePO() {
  const purchaseOrderData = useSelector(state => state.po.purchaseOrderData);
  const purchaseOrderErrors = useSelector(state => state.po.purchaseOrderErrors);
  const setAll = useCallback((data) => dispatch(setPOAll(data)), []);
  const setPurchaseOrder = useCallback((data) => dispatch(setPOData(data)), []);
  const setPurchaseOrderErrors = useCallback((data) => dispatch(setPO({purchaseOrderErrors: data})), []);

  return { purchaseOrderData, purchaseOrderErrors, setPurchaseOrder, setPurchaseOrderErrors, setAll};
}

/**
 * Purchase Order Comment Hook
 * @returns 
 */
export function useComment () {
  const comments = useSelector(state => state.po.comments);
  const comment = useSelector(state => state.po.comment);

  const setComments = useCallback((data) => dispatch(setPO({comments: data})), []);
  const setComment = useCallback((data) => dispatch(setPO({comment: data})), []);

  return { comments, comment, setComments, setComment };
}

/**
 * Vendor User Hook
 * @returns 
 */
export function useVendorUser () {
  const vendor = useSelector(state => state.po.vendor);

  const setVendor = useCallback((data) => dispatch(setPO({vendor: data})), []);

  return { vendor, setVendor };
}
