// import uuidv4 from "uuid/v4";
import * as jwt from "jsonwebtoken";
import packageJson from '../package.json';


export const VERSION = packageJson.version;

export const isLocalDev = process.env.NODE_ENV === 'development';
export const isProjectDabl = (window.location.hostname.indexOf("projectdabl") > 0);
export const isSextant = process.env.REACT_APP_SEXTANT === 'true';

let host = window.location.host.split('.');

export const ledgerId = isSextant ? process.env.REACT_APP_LEDGERID_SEXTANT : (isLocalDev ? "tydei-dev" : host[0]);

let apiUrl = host.slice(1);
apiUrl.unshift('api');

const SEXTANT_HOST = `https://${process.env.REACT_APP_SEXTANT_DOMAIN}/`;
export const httpBaseUrl = isSextant ? SEXTANT_HOST : 
    (isLocalDev ? undefined : 
    ('https://' + apiUrl.join('.') + (window.location.port ? ':' + window.location.port : '') + '/data/' + ledgerId + '/'));

// Unfortunately, the development server of `create-react-app` does not proxy
// websockets properly. Thus, we need to bypass it and talk to the JSON API
// directly in development mode.
//`wss://${process.env.REACT_APP_SEXTANT_DOMAIN}/`
export const wsBaseUrl = (isLocalDev && !isSextant) ? 'ws://localhost:7575/' : undefined;

// const applicationId = uuidv4();
const applicationId = "foobar";

export const ledgerApiIdentify = "https://daml.com/ledger-api";

export const createToken = party => jwt.sign({ ledgerApiIdentify: { ledgerId, applicationId, admin: true, actAs: [party], readAs: [party] } }, "secret");

let loginUrl = host.slice(1);
loginUrl.unshift('login');

export const dablLoginUrl = loginUrl.join('.') + (window.location.port ? ':' + window.location.port : '') + '/auth/login?ledgerId=' + ledgerId;

export const damlPartyKey = applicationId + ".daml.party";
export const damlTokenKey = applicationId + ".daml.token";
export const damlRoleKey = applicationId + ".daml.role";
export const damlTokenDateKey = applicationId + ".daml.token_date";
export const damlOperatorKey = applicationId + ".daml.operator";
export const darkModeKey = applicationId + "tydeiDarkMode";
// export const damlPartyKey = "daml.party";
// export const damlTokenKey = "daml.token";
// export const damlRoleKey = "daml.role";
// export const damlTokenDateKey = "daml.token_date";
// export const damlOperatorKey = "daml.operator";
export const damlFlagAuth = applicationId + ".daml.auth0";
export const damlLastPath = applicationId + ".daml.lastpath";
export const damlReloadTime = applicationId + ".daml.reload_time";
export const bearerTokenKey = "bearerToken";

export const isUserPoolAuth = (process.env.REACT_APP_USERPOOL_AUTH === 'true');
export const POOL_DATA = {
    UserPoolId: process.env.REACT_APP_USERPOOL_ID || "",
    ClientId: process.env.REACT_APP_USERPOOL_CLIENT || "",
};

export const DefaultOperatorParty = process.env.REACT_APP_OPERATOR || "Operator";

export const DefaultPassword = process.env.REACT_APP_DEFAULT_PASSWORD || "";

// chat endpoint
export const CHAT_ENDPOINT = (process.env.REACT_APP_CHAT_HOST || "http://localhost:3001") + "/";

/**
 * node server endpoint
 */
export const NODE_SERVER_ENDPOINT = (process.env.REACT_APP_NODESERVER_HOST || "http://localhost:3003") + "/";
export const NODE_SERVER_AUTH_NAME = process.env.REACT_APP_NODESERVER_NAME || "";
export const NODE_SERVER_AUTH_PASS = process.env.REACT_APP_NODESERVER_PASS || "";

/**
 * Elastic Search
 */
// basic endpoint of server
// For Old Rethink Ledger Or OpenSearch
export const BASIC_SERVER_ENDPOINT = NODE_SERVER_ENDPOINT || process.env.REACT_APP_ES_ENDPOINT;

// Elastic Search server endpoint
export const ES_SERVER_ENDPOINT = BASIC_SERVER_ENDPOINT + "/_bulk";

// If use Elastic Search, true, 
// If use node server, false
export const IS_USE_ERP = true;
// _index for Elastic Search
export const INDEX_ERP = {
    Po_V: 'test_product_po',
    PoD_V: 'test_product_po_detail',
    Pay_V: 'loci_po2',
    PayLine_V: 'loci_po_detail',
    Iv_H: 'test_product_invoice',
    IvD_H: 'test_product_invoice_detail',
    Inv_H: 'loci_invoice',
    InvAttach_H: 'loci_invoice_attachment',
    InvInstall_H: 'loci_invoice_installment',
    InvLine_H: 'loci_invoice_lines',
};

// retrieve endpoint for barcode scan
export const RETRIEVE_ENDPOINT = BASIC_SERVER_ENDPOINT + "/test_product/_search";
export const RETRIEVE_ENDPOINT_2 = BASIC_SERVER_ENDPOINT + "/test_product2/_search";
export const RETRIEVE_ENDPOINT_NEW = BASIC_SERVER_ENDPOINT + "/product/_search";
export const RETRIEVE_ENDPOINT_3 = BASIC_SERVER_ENDPOINT + "api/es/get-contract-price";
export const LOTCODE_ENDPOINT = BASIC_SERVER_ENDPOINT + "/test_product/_search";
export const UPDATE_ES_ENDPOINT = BASIC_SERVER_ENDPOINT + "api/es/update-contract-price";

// use DAML Contract Price instead of Elastic Search
export const IS_CONTRACT_NOT_ES = false; // Old case : (VERSION === '0.9.5')
// To update Contract Price in UI
// export const IS_CONTRACT_UPDATE = true;

// endpoint to upload aws s3 bucket
export const AWS_BUCKET_ENDPOINT = process.env.REACT_APP_AWS_ENDPOINT;

// If use service account, true, 
// If use token jwt directly, false
export const IS_PROJECTDABL_SA = false;

// endpoint to retrieve accessgudid
// To solve corsdemo, we need to remove cors and use new endpoint in the node_server
export const ACCESS_GUDID_ENDPOINT = (isLocalDev && window.location.port === '3000') ?
    process.env.REACT_APP_GUDID_ENDPOINT : 
    // `https://cors-anywhere.herokuapp.com/${process.env.REACT_APP_GUDID_ENDPOINT}`; // cors-heroku
    `${NODE_SERVER_ENDPOINT}api/gudid/lookup/`; // node_server

/**
 * If it's true, Use GUDID,
 * If it's false, Don't use GUDID
 * @param {Boolean} isUseGUDID
 */
export const isUseGUDID = (process.env.REACT_APP_GUDID === 'true');

/**
 * Configures for Auth0
 */
export const auth0 = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    host: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/`,
    clientId: process.env.REACT_APP_AUTH0_CLIENTID,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE || ledgerApiIdentify,
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID, // to Use get access token
    client_secret: process.env.REACT_APP_AUTH0_CLIENT_SECRET, // to Use get access token
    token: process.env.REACT_APP_AUTH0_TOKEN,
    isOnlyAuthLogin: true, // show only Auth Login Button
};

/**
 * Extension of files to upload
 * e.g. create PO with Bulk
 * ext maybe one of .csv, .txt
 */
export const UploadFileExt = '.csv';
export const UploadFileExtList = ['.csv', '.pdf', '.txt']; // validate file as csv, pdf, txt
export const CSVDelimiter = ','; // ',', ';'

// CUSTOMER TOKEN of LOGGLY
export const LOGGLY_TOKEN = process.env.REACT_APP_LOGGLY_TOKEN;


// ZENDESK
export const ZENDESK_KEY = "cd164817-c467-49fb-a0d6-dd07ecde07a9";

// ! New Design
export const isNewDesign = process.env.REACT_APP_DESIGN === 'NEW';

// MUI X Pro license key
export const MUI_LICENSE_KEY = process.env.REACT_APP_MUI_LICENSE;

// Company Name to print or download as pdf
export const COMPANY_NAME = "TYDEi Health";

// Ideal session timeout default 30mins
export const IDLE_SESSION_TIMEOUT = process.env.REACT_IDLE_SESSION_TIMEOUT;

// Session timeout prompt before Ideal
export const SESSION_TIMEOUT_BEFORE_IDLE_PROMPT = process.env.REACT_IDLE_SESSION_BEFORE_IDLE_PROMPT;

// Websocket Default Reconnect Threshold
export const WS_RECONNECT_THRESHOLD = process.env.REACT_WEBSOCKET_RECONNECT_THRESHOLD || 60000;