import React from "react";
// import classNames from "classnames";
// import { useLedger } from "@daml/react";
// import { Grid, LinearProgress } from "@material-ui/core";
// import { useDamlState } from '../../context/DamlContext';
import Contracts from "../../components/Contracts/Contracts1";
// import SnackbarModal from "../../components/Modals/SnackbarModal";
import { contractFilter1 } from "../../services/daml-filters";
import { handleCheckedContract, handleClickedContract } from '../surgicalevent/surgicaleventlistcontracts1';
import withLoading from "../../hoc/withLoading";
import withFilters from "../../hoc/withFilters";
import useStyles from "../surgicalevent/styles";
// import useStyles1 from "./styles";


/**
 * Contract Management Contracts in Hospital
 * @param {Object} searchParams 
 * @param {Boolean} isIncludeHistory 
 * @param {Array} allContracts 
 * @param {Array} historyContracts 
 * @param {Function} setArchived
 * @param {Function} setChecked
 * @param {Function} handleEditContract
 * @returns 
 */
function ContractManagementListContracts({
  searchParams = {},
  isIncludeHistory = false,
  allContracts = [],
  historyContracts = [],
  setArchived = null,
  setChecked = null,
  handleEditContract = null,
}) {

  const classes = useStyles();
  // const classes1 = useStyles1();

  // const ledger = useLedger();

  // const { role, party, userParty, roleCid, userlistPayload } = useDamlState();


  allContracts = allContracts.concat(isIncludeHistory && historyContracts.length ?
    historyContracts.map(h => ({ ...h, payload: h.payload.contracteetail })) : []);
  let contracts = contractFilter1(allContracts, searchParams);
  console.log("[ContractManagementListContracts]", searchParams, contracts);


  return (
    <>
      <Contracts
        className={classes.customRoot}
        contracts={contracts}
        columns={[
          ["Distributor", "payload.vendor", {
            search: {
              type: "AdaptiveSearch",
              name: "vendor",
              getValue: (c) => c.payload.vendor,
              contracts: allContracts,
            },
            options: {
              flex: 0.8,
            }
          }],
          ["Excution Date", "payload.detail.contractdateexecuted", {
            search: {
              type: "DateSearch",
              name: "detail.contractdateexecuted",
            },
            options: {
              flex: 1,
            }
          }],
          ["Expiration Date", "payload.detail.contractexpirationdate", {
            search: {
              type: "DateSearch",
              name: "detail.contractexpirationdate",
            },
            options: {
              flex: 1,
            }
          }],
          ["Total Spend", "payload.detail.contracttotal", {
            search: {
              type: "AdaptiveSearch",
              name: "detail.contracttotal",
              getValue: (c) => c.payload.detail.contracttotal,
              contracts: allContracts,
            },
            options: {
              beforeText: '$',
              flex: 0.8,
            }
          }],
          ["Last 90 days", "payload.detail.contract90days", {
            search: {
              type: "AdaptiveSearch",
              name: "detail.contract90days",
              getValue: (c) => c.payload.detail.contract90days,
              contracts: allContracts,
            },
            options: {
              beforeText: '$',
              flex: 0.8,
            }
          }],
          ["Expected Rebate", "payload.detail.contractrebate", {
            search: {
              type: "AdaptiveSearch",
              name: "detail.contractrebate",
              getValue: (c) => c.payload.detail.contractrebate,
              contracts: allContracts,
            },
            options: {
              beforeText: '$',
              flex: 0.8,
            }
          }],
          ["Description", "payload.detail.description", {
            search: {
              type: "AdaptiveSearch",
              name: "detail.description",
              getValue: (c) => c.payload.detail.description,
              contracts: allContracts,
            },
            options: {
              flex: 1.2,
            }
          }],
          ["Contact", "payload.detail.contactemail", {
            search: {
              type: "AdaptiveSearch",
              name: "detail.contactemail",
              getValue: (c) => c.payload.detail.contactemail,
              contracts: allContracts,
            },
            options: {
              flex: 1.2,
            }
          }],
        ]}

        options={{
          statusEmpty: (allContracts.length === 0),
          checkbox: true,
        }}

        handleArchived={setArchived}
        handleClicked={(e) => handleClickedContract(e, handleEditContract)}
        handleChecked={(e) => handleCheckedContract(e, contracts, setChecked)}
      />

    </>
  );
}

const ContractManagementListContractsWithLoading = withLoading(ContractManagementListContracts);
export default withFilters(ContractManagementListContractsWithLoading)([
  { name: "vendor", label: "Distributor" },
  { name: "detail.contractdateexecuted", label: "Excution Date", type: "date" },
  { name: "detail.contractexpirationdate", label: "Expiration Date", type: "date" },
  { name: "detail.contracttotal", label: "Total Spend" },
  { name: "detail.contract90days", label: "Last 90 days" },
  { name: "detail.contractrebate", label: "Expected Rebate" },
  { name: "detail.description", label: "Description" },
  { name: "detail.contactemail", label: "Contact" },
]);
