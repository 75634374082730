import React, { useState, useEffect } from "react";
import { FormControl, Dialog, DialogContentText, Button, Typography, InputLabel, Select,
  TableContainer, Paper, TextField } from "@material-ui/core";
import { sameUdi } from "../../services/util";
import EditProduct from "../surgicalevent/edit-product";
import ImportFile from "../import_file/Importfile";
import { getCourierCompany } from "../../models/Courier";
import ProductDetailViewTable from "../surgicalevent/product-detail-view-table";
import useStyles from "../surgicalevent/styles";
import ModalDialogBox from "../../components/Modals/GeneralModal/modalDialogBox";


/**
 * Confirm Shipment Dialog
 * 1. Restock Inventory Contracts in Vendor
 * 2. Return Inventory Contracts in Hospital
 * @param {Boolean} open 
 * @param {Function} setOpen 
 * @param {Function} handleRequest 
 * @param {Object} inventoryData 
 * @param {Function} setInventoryData 
 * @param {String} confirmStatus 
 * @param {Array} clearProductParams 
 * @param {Boolean} isHospital 
 * @param {Boolean} isQuantiy
 * @param {Number|null} defaultQuantity If null, then total count of inventory data
 * @returns 
 */
export default function ConfirmShipment({
  open, setOpen, handleRequest, inventoryData = {}, setInventoryData,
  confirmStatus = "", clearProductParams = [], isHospital = false, isQuantiy = false, defaultQuantity = 1, isIreceivedStockForFacility, setIsIreceivedStockForFacility
}) {


  const classes = useStyles();

  const [, setFilePathData] = useState({});
  const [productItems, setProductItems] = useState({});
  const [invData, setInvData] = useState({});
  // quantity
  const [quantity, setQuantity] = useState(1);
  const maxQuantity = (inventoryData.datas && typeof inventoryData === 'object' && inventoryData.datas.length)
    ? inventoryData.datas.length : 1;
  defaultQuantity = (inventoryData.datas && typeof inventoryData === 'object' && inventoryData.datas.length)
    ? ((defaultQuantity === null || defaultQuantity > inventoryData.datas.length) ? inventoryData.datas.length : (
      (defaultQuantity < 1) ? 1 : defaultQuantity
    )) : 1;

  const [confirmOpen, setConfirmOpen] = useState(false)

  // Click "Confirm"
  const confirmInventory = async () => {
    const tempInventory = {
      ...invData,
      iproduct: {
        ...inventoryData.iproduct,
        seplotcode: productItems.seplotcode,
        sepproductexpiration: productItems.sepproductexpiration,
      },
      ireceivedstockforfacility : isIreceivedStockForFacility
    };

    if (inventoryData.datas && typeof inventoryData === 'object' && inventoryData.datas.length) {
      const count = isQuantiy ? quantity : defaultQuantity;
      tempInventory.datas = inventoryData.datas.slice(0, count)
        .map(inv => ({
          ...inv,
          ishipmentdate: invData.ishipmentdate,
          icourier: invData.icourier,
          itrackingstatus: invData.itrackingstatus,
          iproduct: {
            ...inv.iproduct,
            seplotcode: productItems.seplotcode,
            sepproductexpiration: productItems.sepproductexpiration,
          },
          ireceivedstockforfacility : isIreceivedStockForFacility
        }));
    }

    if (typeof handleRequest === 'function') await handleRequest(tempInventory);
  };

  const confirmInventoryCheckUdi = async () => {
    if (typeof setIsIreceivedStockForFacility === 'function') setIsIreceivedStockForFacility(false);
    if (productItems) {
      if (sameUdi(productItems, inventoryData)) {
        await confirmInventory(productItems);
      }
      else {
        setConfirmOpen(true);
      }
    }
  };

  useEffect(() => {
    if (!!open) {

      // clear product parameters
      if ('iproduct' in inventoryData && clearProductParams.length > 0) {
        clearProductParams.forEach(p => {
          if (p in inventoryData.iproduct) inventoryData.iproduct[p] = "";
        });
      }

      if ('iproduct' in inventoryData) setProductItems({ ...inventoryData.iproduct });
      else setProductItems({});
      setInvData({ ...inventoryData });
      setQuantity(1);
    }
  }, [open, inventoryData, clearProductParams]);

  return (
    <Dialog
      className={classes.customRoot}
      open={open}
      // onClose={()=>{setOpen(false)}}
      aria-labelledby="draggable-dialog-title"
    >
      {
        isIreceivedStockForFacility
          ? <h2 className={classes.poformSubHeading}>Stocks for Facility</h2>
          : <h2 className={classes.poformSubHeading}>Confirm Shipment</h2>
      }

      <div className={classes.mainFormSteps}>

        <DialogContentText component={'div'} className="modelStepDetai">

          {
            inventoryData && inventoryData.iproduct &&

            <div className="viewDetailsSe">

              <h2 className={classes.formSubHeading}>Product Details</h2>

              <div>

                <div className={classes.productAccordion}>

                  <Typography component={'div'}>
                    <TableContainer className={classes.productTableContainer} component={Paper}>
                      <ProductDetailViewTable
                        obj={inventoryData.iproduct}
                        options={{
                          enableParams: ['sepreferencenumber'],
                          hiddenParams: ['seplotcode', 'sepproductwaste', 'sepproductexpiration'],
                          extraParams: isHospital ? [] : [{
                            label: 'Hospital', value: inventoryData.hospital,
                          }],
                        }}
                      />
                    </TableContainer>
                  </Typography>

                  <div className={classes.confirmDatafield}>
                    <EditProduct
                      productItems={productItems}
                      sameUdi={sameUdi(productItems, inventoryData) ? 1: 0}
                      setProductItems={setProductItems}
                      options={{
                        isEnableRetrieve: false,
                        isEnableCompliance: false,
                        isInventory: true,
                        hiddenParams: ['sepudi', 'sepreferencenumber', 'sepdistributor', 'sepproductmanufacturer',
                          'sepproductname', 'sepproductdescription', 'sepproductprice', 'sepproducttype', 'sepproductwaste',
                          'sepownership', 'sepproductside'],
                      }}
                    />

                    <div className={classes.marginBM30}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                          id="eventdate"
                          label={confirmStatus === "Shipped" ? "Shipment Date" : "Shipping Date"}
                          type="date"
                          value={invData.ishipmentdate || ""}
                          onChange={(e) => {
                            setInvData({
                              ...invData,
                              ishipmentdate: e.target.value,
                            });
                          }}
                          className={classes.datehalfWidth}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </FormControl>
                    </div>

                    <div className={classes.marginBM30}>
                      <ImportFile
                        setFilePathData={setFilePathData}
                        title='Drag & Drop Packing Slip to Upload'
                        addtionalBtn={false}
                        subtitle={'You can upload only valid PDF here.'}
                      />
                    </div>

                    <div className={classes.marginBM30}>
                      <div className={classes.popperList}>
                        <FormControl variant="outlined" className={classes.formControl}>
                          <InputLabel className={classes.customLabel} shrink={true}>
                            Courier Company
                          </InputLabel>
                          <Select
                            // native
                            displayEmpty
                            className={classes.customFullWidth}
                            value={invData.icourier || ""}
                            onChange={(e) => {
                              setInvData({
                                ...invData,
                                icourier: e.target.value,
                              });
                            }}
                          >
                            {getCourierCompany("Select One", ['Unknown'])}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className={classes.marginBM30}>
                      <TextField
                        id="input-with-icon-adornment12"
                        style={{ margin: 8 }}
                        label="Tracking Number"
                        placeholder="e.g. 12345"
                        value={invData.itrackingstatus || ""}
                        className={classes.customFullWidth}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => {
                          setInvData({
                            ...invData,
                            itrackingstatus: e.target.value,
                          });
                        }}
                        variant="outlined"
                      />
                    </div>

                    {
                      isQuantiy &&
                      <div className={classes.marginBM30}>
                        <TextField
                          id="input-with-icon-adornment123"
                          style={{ margin: 8 }}
                          label="Quantity"
                          placeholder=""
                          value={quantity}
                          className={classes.customFullWidth}
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            const val = e.target.value;
                            if (val >= 1 && val <= maxQuantity) setQuantity(val);
                          }}
                          variant="outlined"
                          type="number"
                        />
                      </div>
                    }

                  </div>
                </div>
              </div>

            </div>
          }

        </DialogContentText>
      </div>
      <ModalDialogBox
        open={confirmOpen}
        setOpen={setConfirmOpen}
        text={"The UDI Scanned DOES NOT match what is scanned for shipping"}
        type="warning"
        firstBtn={'OK'}
        handleRequest={() => setConfirmOpen(false)}
      />
      <div className="form-footer">
        <div className="right-buttons">
          <Button autoFocus onClick={() => {
             setOpen(false) ;
             setIsIreceivedStockForFacility(false);
             }} className="noBtn" variant="outlined" color="primary">
            Cancel
          </Button>
          <Button onClick={async () => await confirmInventoryCheckUdi()} className="yesBtn" variant="contained" color="primary">
            Confirm
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
