import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  halfGrid: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  topGrid: {
    marginTop: 5,
    marginBottom: 8,
  },
  middleGrid: {
    marginTop: 12,
    marginBottom: 8,
  },
  bottomGrid: {
    marginTop: 5,
    marginBottom: 40,
  },
  formControl: {
    minWidth: 200,
    marginLeft: 10,
    marginRight: 10,
  },
  buttonRetrieve: {
    marginTop: 20,
    marginRight: 20,
  },
  buttonLeft: {
    marginRight: 15,
  },
  buttonRight: {
    marginLeft: 15,
  },
  divider: {
    height: 20,
    marginLeft: 10,
  },
  dialogField: {
    width: '200px',
  },
  pageTitle: {
    marginBottom: '15px',
  },
  iconButton: {
    padding: '5px',
    height: '28px',
  },
  newTabPanelMenu: {
    marginTop: '10px',
    zIndex: '0',
    '& .MuiTab-root.MuiTab-textColorPrimary': {
      padding: '4px 12px',
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '24px',
    },
    '& .badge-title': {
      marginRight: '8px',
      textTransform: 'initial',
    },
    '& .MuiBadge-root .MuiBadge-anchorOriginTopRightRectangular': {
      top: '-12px',
    },
  },
  detailTabPanelMenu: {
    marginTop: '-5px',
    background: 'none',
    width: '985px',
    '& .MuiTab-root.MuiTab-textColorPrimary': {
      padding: '0px 12px',
      fontWeight: 700,
      fontSize: '17px',
      lineHeight: '20px',
    },
  },
  newHeaderPanel: {
    margin: 'auto',
    marginTop: '8px',
    marginBottom: '10px',
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        paddingTop: '3px',
        paddingBottom: '3px',
        '&:first-child': {
          paddingLeft: '9px',
          paddingRight: '0px',
          minWidth: 'inherit',
          '*&.non-pr': {
            paddingRight: '9px',
          },
        },
      },
    },
    '& .empty-box': {
      height: '65px',
    },
    '& .settings-table': {
      marginTop: '7px', 
      marginBottom: '10px',
      width: '505px',
    },
  },
  newHeaderPanel1: {
    margin: 'auto',
    marginBottom: '20px',
    '& .MuiTypography-body1': {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '22px',
      color: '#000000',
    },
  },
  newHeaderPanel2: {
    marginTop: '18px',
    '& .label': {
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '17px',
      color: '#5C5C5C',
      marginLeft: '10px',
    },
    '& .value': {
      fontSize: '15px',
      lineHeight: '17px',
      marginLeft: '10px',
    },
  },
  returnPanel: {
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        '&:first-child': {
          paddingLeft: '0px',
          minWidth: 'inherit',
        },
      },
    },
  },
  middlePanel: {
    padding: '0px 15px',
    '& .contract-price-item': {
      padding: '0px 10px',
    },
  },
  descLongTxt: {
    maxWidth: '225px',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    verticalAlign: 'middle',
    textOverflow: 'ellipsis',
  },
  newCheckbox: {
    color: '#000000',
    '& .MuiCheckbox-colorPrimary.Mui-checked': {
      color: '#5C5C5C',
    },
    '& .MuiBadge-root': {
      '& .MuiBadge-colorPrimary': {
        backgroundColor: '#5C5C5C',
        fontSize: '8px',
        width: '11px',
        height: '11px',
        minWidth: 'inherit',
        right: '-5px',
        top: '1px',
      },
    },
  },
  addIcon: {
    color: '#F7CD37',
    backgroundColor: '#1C3C74',
    width: '30px',
    minWidth: '30px',
    height: '30px',
    padding: '6px 15px',
    borderRadius: '0px',
    marginTop: '16px',
    '&:hover': {
      backgroundColor: '#1C3C74CC',
    },
    '& .MuiButton-startIcon': {
      margin: 'auto',
    },
  },
  newSmallBtn: {
    padding: '6px 14px',
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '17px',
  },
  newPopoverBox: {
    '& .MuiTable-root': {
      '& .MuiTableHead-root': {
        background: '#5C5C5C',
        '& .MuiTableCell-root': {
          fontWeight: 700,
          color: '#FFFFFF',
          padding: '5px 12px',
        }
      },
      '& .MuiTableCell-root': {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '14px',
        padding: '3px 12px',
        '& .MuiIconButton-root': {
          padding: '3px',
        },
      },
    },
    '& .icon-cell': {
      padding: '3px 6px !important',
    },
  },
  createInventory : {
    '& .MuiDialogActions-root ' : {
      bottom : '24px !important',
      right : '16px !important',
    },

    '& .MuiDialogContent-root': {
      height: '800px !important'
    },
  },
  addLocationText: {
    '& .MuiFormLabel-root': {
      paddingTop: '3px !important',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
      color: '#000000',
    },
    '& .MuiInputBase-root': {
      height: '20px !important',
      backgroundColor: '#FFFFFF !important',
    },
    '& .MuiInputBase-input': {
      padding: '3px 0px 3px',
      fontSize: '12px !important',
      lineHeight: '14px !important',
    },
  },
  newPopoverBoxSettings: {
    width: '305px',
    height: '195px',
    '& .MuiGrid-item': {
      padding: '10px 8px 6px 27px',
      '& .MuiFormControlLabel-root': {
        margin: '21px 14px 0px 11px',
      },
      '& .MuiFormLabel-root': {
        marginLeft: '-17px',
        marginBottom: '6px',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '14px',
        color: '#000000',
      },
      '& .MuiInputBase-root': {
        height: '25px !important',
        backgroundColor: '#FFFFFF !important',
      },
      '& .MuiInputBase-input': {
        // padding: '3px 0px 3px',
        fontSize: '14px !important',
        lineHeight: '16px !important',
      },
    },
    '& .header': {
      padding: '10px 8px 6px 11px',
      borderBottom: '1px solid #CCCCCC',
      '& .MuiTypography-root': {
        fontWeight: 800,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#1C3C74',
      },
    },
    '& .actions': {
      padding: '8px',
      marginLeft: 'auto',
    },
  },
  newSettingBtn: {
    padding: '3px 11px 4px 12px',
    fontSize: '15px',
    lineHeight: '18px',
  },
  newSwitchCheck: {
    width: 32,
    height: 14,
    '& .MuiSwitch-thumb': {
      width: 14,
      height: 14,
    },
    '& .MuiSwitch-track': {
      borderRadius: 7,
    },
  },
  newSwitchCheck1: {
    width: 34,
    height: 16,
    '& .MuiSwitch-thumb': {
      width: 16,
      height: 16,
    },
    '& .MuiSwitch-track': {
      borderRadius: 8,
    },
  },
  newSwitchLabel: {
    flexDirection: 'column-reverse',
    margin: '5px 0px 0px !important',
    '& .MuiTypography-body1': {
      width: '60px',
      fontWeight: 400,
      fontSize: '7px',
      lineHeight: '8px',
      textAlign: 'center',
    },
  },
  newSwitchLabelRow: {
    flexDirection: 'row-reverse',
    margin: '0px !important',
    '& .MuiTypography-body1': {
      width: 'auto',
      marginRight: '10px',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '18px',
      color: '#000000',
    },
    '& .MuiSwitch-track': {
      backgroundColor: '#F44336',
    },
  },
  newSwitchLabelTop: {
    marginTop: '13px !important',
  },
  newActionCell: {
    padding: '0px',
    minWidth: 'inherit',
    '& .MuiButton-startIcon': {
      margin: '0px',
      background: '#1C3C74',
      borderRadius: '5px',
      '& .MuiSvgIcon-root': {
        color: '#FFFFFF',
      },
    },
  },
  newAddedTooltip: {
    background: '#CCCCCC',
    border: '1px solid #CCCCCC',
    boxSizing: 'border-box',
    borderRadius: '5px',
    padding: 0,
    marginTop: 0,
    '& .MuiButtonGroup-root': {
      paddingTop: '3px',
      paddingBottom: '2px',
      '& .MuiButton-root': {
        borderWidth: 0,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
        paddingTop: '1px',
        paddingBottom: '1px',
        textTransform: 'capitalize',
        color: '#000000',
        '&:hover': {
          background: '#5C5C5C',
          color: '#FFFFFF',
        },
      },
    },
  },
  newTooltipArroww: {
    color: '#CCCCCC',
    borderColor: '#CCCCCC',
  },
  completeBtn: {
    marginTop: '-46px',
  },
  addBtn: {
    marginRight: '206px',
  },
  btnCenter: {
    marginRight: '340px',
  },
  newPOForm: {
    width: '432px',
  },
  newAddedProductPanel: {
    width: '570px',
    '& .MuiCollapse-root .MuiAccordionDetails-root .accordion-table .MuiTableCell-body': {
      '&.value': {
        minWidth: '122px !important',
      },
    },
  },
  newInvTitlePanel: {
    '& .label': {
      fontWeight: 700,
      fontSize: '25px',
      lineHeight: '30px',
      color: '#1C3C74',
    },
  },
  newTitlePanel: {
    '& .label': {
      fontWeight: 300,
      fontSize: '17px',
      lineHeight: '20px',
      color: '#000000',
      '&.top-label': {
        fontWeight: 700,
        fontSize: '25px',
        lineHeight: '30px',
        color: '#1C3C74',
      },
      '&:not(:first-child)': {
        marginLeft: '30px',
      },
    },
    '& .value': {
      marginLeft: '2px',
      fontWeight: 700,
      fontSize: '17px',
      lineHeight: '20px',
      color: '#1C3C74',
    },
    '& .MuiButtonBase-root.MuiIconButton-root': {
      marginRight: '55px',
    },
  },
  requestPanel: {
    padding: '3px 86px 0px 90px',
    '& .MuiTableRow-root .MuiTableCell-root': {
      padding: '3px',
      '&.details': {
        padding: '0px',
        fontSize: '18px',
        lineHeight: '22px',
      },
      '&.value': {
        '& .MuiInputBase-root': {
          backgroundColor: '#FFFFFF !important',
        },
      },
      '&:first-child': {
        minWidth: 'inherit',
        paddingLeft: '0px',
      },
      '&.label': {
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '21px',
      },
    },
    '& .MuiTypography-root': {
      '&.header-label': {
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '18px',
        color: '#5C5C5C',
      },
      '&.header-value': {
        fontWeight: 700,
        fontSize: '15px',
        lineHeight: '17px',
        color: '#000000',
        marginRight: '20px',
      },
    },
  },
  requestPanel1: {
    paddingLeft: '210px',
  },
  addedProductNewPanel: {
    height: '330px',
  },
  newEditProduct: {
    '& .new-subtitle': {
      paddingTop: '10px',
    },
  },
  newEditPanel: {
    padding: '5px 33px 0px 33px',
  },
  txtArea: {
    marginTop: '144px',
    marginLeft: '72px',
    '& textarea': {
      border: '1px solid #CCCCCC',
      borderRadius: '10px',
    },
  },
  subTabPanel: {
    background: '#ffffff',
    borderTop: '1px solid #CCCCCC',
    borderBottom: '1px solid #CCCCCC',
    padding: '3px',
    display: 'flex',
    '& .MuiTypography-root': {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '29px',
      textAlign: 'center',
      color: '#1C3C74',
      width: '100%',
      paddingTop: '1px',
      paddingBottom: '5px',
    },
    '& .MuiButton-root': {
      width: '200px',
      padding: '1px 3px',
    },
  },
  btnGroupTop: {
    position: 'absolute',
    top: '15px',
    right: '10px',
    borderRadius: '10px',
    '&.left': {
      right: 'auto',
      left: '10px',
      '& .MuiFormLabel-root': {
        color: '#000000',
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 700,
        paddingTop: '4px',
      },
      '& .MuiInputBase-root': {
        background: '#FFFFFF',
        height: '26px',
        '& .MuiInputBase-input': {
          fontSize: '14px',
          lineHeight: '16px',
          fontWeight: 300,
          padding: '4px 25px 4px 0px',
        },
      },
    },
    '& .MuiButton-root': {
      background: 'rgba(255, 255, 255, 0.38)',
      border: '1px solid #CCCCCC',
      '&:first-child': {
        borderRadius: '10px 0px 0px 10px',
      },
      '&:last-child': {
        borderRadius: '0px 10px 10px 0px',
      },
      '&.active': {
        background: 'rgba(76, 175, 80, 0.38)',
        border: '1px solid #4CAF50',
      },
      '& .MuiButton-label': {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '14px',
        color: '#000000',
        textTransform: 'capitalize',
      },
    },
  },
  dialogNoBorder: {
    '& .MuiDialogContent-dividers': {
      borderWidth: '0px',
    },
  },
  editReturnTitle: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#1C3C74',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  confirmTopBody: {
    padding: '5px 23px',
    borderBottom: '1px solid #CCCCCC',
  },
  newUploadFile: {
    height: '100px !important',
  },
  newInvTitleText: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '28px',
    color: '#1C3C74',
  },
  parlevelPanel: {
    borderBottom: '1px solid #CCCCCC',
    '& .MuiTabs-root': {
      minHeight: 'inherit',
      '& .MuiTab-root': {
        padding: '7px 0px',
        minHeight: 'inherit',
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '17px',
        color: '#CCCCCC',
        textTransform: 'inherit',
        '&.Mui-selected': {
          fontWeight: 700,
          color: '#1C3C74',
        },
      },
    },
  },
  parlevelBodyPanel: {
    marginLeft: '43px',
    width: 'auto',
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        padding: '5px 0px',
        '&:first-child': {
          minWidth: 'inherit',
          paddingLeft: '0px',
        },
      },
    },
  },
  orderingBox: {
    marginLeft: '41px',
    width: 'auto',
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        padding: '6px 0px',
        '&:first-child': {
          minWidth: 'inherit',
          paddingLeft: '0px',
        },
        '& .MuiFormControlLabel-root': {
          margin: '0px !important',
        },
      },
    },
  },
  locationsBox: {
    marginTop: '15px',
    border: '1px solid #CCCCCC',
    width: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& .header-table': {
      position: 'sticky', 
      top: 0,
    },
    '& .body-table': {
      maxHeight: '152px', 
      overflow: 'auto',
    },
    '& .MuiTable-root': {
      '& .MuiTableRow-root': {
        '& .MuiTableCell-root': {
          padding: '2px 10px',
          minWidth: '140px',
          '& .MuiIconButton-root': {
            padding: '0px 0px 0px 10px',
          },
          '&.MuiTableCell-head:not(:first-child)': {
            borderLeft: '1px solid #CCCCCC',
          },
        },
      },
      '& .MuiTableBody-root': {
        '& .MuiTableRow-root:last-child': {
          '& .MuiTableCell-root': {
            borderBottomWidth: 0,
          },
        },
      },
    },
  },
  settingsPanel: {
    marginLeft: '42px',
    width: 'auto',
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        padding: '20px 0px',
        '&:first-child': {
          minWidth: 'inherit',
          paddingLeft: '0px',
        },
      },
    },
    '& .MuiFormLabel-root': {
      color: '#000000',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '23px',
    },
  },
  displaySettingBadgeInfo: {
    position: 'relative',
    top: '-8px',
    left: '10px',
    '& .MuiBadge-colorPrimary': {
      backgroundColor: '#5C5C5C',
      left: '-13px',
      width: '15px',
      height: '15px',
      minWidth: 'inherit',
      fontSize: '12px',
    }
  },
  openordersPanel: {
    padding: '0px 70px',
  },
  openordersTable: {
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        '&:first-child': {
          minWidth: 'inherit',
          paddingLeft: '9px',
        },
        '&.MuiTableCell-head': {
          border: '1px solid #CCCCCC',
          color: '#939292',
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '16px',
        },
        '&.MuiTableCell-body': {
          borderBottom: '2px solid #FFFFFF',
          backgroundColor: '#F5F5F5',
          color: '#000000',
          fontWeight: 700,
          fontSize: '15px',
          lineHeight: '17px',
        },
      },
    },
  },
}), { index: 1 });
