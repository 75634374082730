import { AuditLog } from "@daml2js/tydei-dev-auditlog";
import { Auth } from "@daml2js/tydei-dev-auth";
import { Chat } from "@daml2js/tydei-dev-chat";
import { ContractManagement as CM } from "@daml2js/tydei-dev-contractmanagement";
import { ContractPrice as CP } from "@daml2js/tydei-dev-contractprice";
import { Core } from "@daml2js/tydei-dev-core";
import { DiscoverPayment as DP } from "@daml2js/tydei-dev-discoverpayment";
import { Inventory } from "@daml2js/tydei-dev-inventory";
import { Invoice as Invoi } from "@daml2js/tydei-dev-invoice";
import { Log } from "@daml2js/tydei-dev-log";
import { PrefCard } from "@daml2js/tydei-dev-prefcard";
import { PurchaseOrder as PO } from "@daml2js/tydei-dev-purchaseorder";
import { SurgicalEvent as SE } from "@daml2js/tydei-dev-surgicalevent";
import { User } from "@daml2js/tydei-dev-user";
// import { Util } from "@daml2js/tydei-dev-util";


/**
 * Daml Modules Name is Static.
 * Daml Templates for new structure of multiple packages of Daml
 */
const OnboardNetwork = Core.Auth.Networks.OnboardNetwork;
const ManagerInvitation = Core.Auth.Invitation.ManagerInvitation;
const HospitalInvitation = Core.Auth.Invitation.HospitalInvitation;
const VendorInvitation = Core.Auth.Invitation.VendorInvitation;
const UserList = User.Main.Users.UserList;
const Relationship = Auth.Main.Relationship.Relationship;
const RelationshipOffer = Auth.Main.Relationship.RelationshipOffer;
const OperatorRole = Core.Roles.OperatorRole.OperatorRole;
const HospitalRole = Core.Roles.HospitalRole.HospitalRole;
const VendorRole = Core.Roles.VendorRole.VendorRole;
const SurgicalEvent = SE.Main.SurgicalEvent.SurgicalEvent;
const SurgicalEventHistory = SE.Main.SurgicalEvent.SurgicalEventHistory;
const SurgicalEventConfirmation = SE.Main.SurgicalEvent.SurgicalEventConfirmation;
const SurgicalEventCompliance = SE.Main.Compliance.SurgicalEventCompliance;
const SurgicalComplianceHistory = SE.Main.Compliance.SurgicalComplianceHistory;
const SurgicalEventProcedure = SE.Main.Procedure.SurgicalEventProcedure;
const SurgicalEventProcedureThresholdAmount = SE.Main.Procedure.SurgicalEventProcedureThresholdAmount;
const SurgicalEventProcedureVendorAmount = SE.Main.Procedure.SurgicalEventProcedureVendorAmount;
const Surgeons = PrefCard.Main.Surgeon.Surgeons;
const PrefCards = PrefCard.Main.SECards.SECard;
const PostProcedureHospital = SE.Main.PostProcedure.PostProcedureHospital;
const PostProcedureHospitalHistory = SE.Main.PostProcedure.PostProcedureHospitalHistory;
const PostProcedureVendor = SE.Main.PostProcedure.PostProcedureVendor;
const PostProcedureVendorHistory = SE.Main.PostProcedure.PostProcedureVendorHistory;
const AccountInventory = Inventory.Main.Inventory.AccountInventory;
const AccountInventoryHistory = Inventory.Main.Inventory.AccountInventoryHistory;
const InventoryProductHistory = Inventory.Main.Inventory.InventoryProductHistory;
const InventoryRequestHistory = Inventory.Main.Inventory.InventoryRequestHistory;
const RestockInventory = Inventory.Main.Inventory.RestockInventory;
const RestockInventoryHistory = Inventory.Main.Inventory.RestockInventoryHistory;
const ReturnInventory = Inventory.Main.Inventory.ReturnInventory;
const ReturnInventoryHistory = Inventory.Main.Inventory.ReturnInventoryHistory;
const RecallInventory = Inventory.Main.RecallInventory.RecallInventory;
const RecallInventoryHistory = Inventory.Main.RecallInventory.RecallInventoryHistory;
const InventoryLocation = Inventory.Main.InventoryLocation.InventoryLocation;
const ContractPrice = CP.Main.ContractPrice.ContractPrice;
const ContractPriceHistory = CP.Main.ContractPrice.ContractPriceHistory;
const PurchaseOrder = PO.Main.PurchaseOrder.PurchaseOrder;
const PurchaseOrderHistory = PO.Main.PurchaseOrder.PurchaseOrderHistory;
const PurchaseOrderVendor = PO.Main.PurchaseOrder.PurchaseOrderVendor;
const PurchaseOrderVendorHistory = PO.Main.PurchaseOrder.PurchaseOrderVendorHistory;
const Invoice = Invoi.Main.Invoice.Invoice;
const InvoiceHistory = Invoi.Main.Invoice.InvoiceHistory;
const InvoiceHospital = Invoi.Main.Invoice.InvoiceHospital;
const InvoiceHospitalHistory = Invoi.Main.Invoice.InvoiceHospitalHistory;
const HospitalTemplate = Invoi.Main.Financial.HospitalTemplate;
const VendorTemplate = Invoi.Main.Financial.VendorTemplate;
const Payment = Invoi.Main.Financial.Payment;
const FHIRProcedure = Core.Main.FHIRProcedure.FHIRProcedure;
const Holidays = Core.Main.Calendar.BankHolidays;
const DashboardCards = Core.Main.DashboardDataCards.DashboardDataCards;
const DashboardCardsVendor = Core.Main.DashboardDataCards.DashboardDataCardsVendor;
const ChatTemplate = Chat.Main.Chat.Chat;
const LogPartyTwo = Log.Main.Logs.LogPartyTwo;
const AuditLogs = AuditLog.Main.AuditLogs.LogEvents;
const PaymentLogsEvents = AuditLog.Main.AuditLogs.PaymentsLogEvents;
const DiscoverPayment = DP.Main.DiscoverPayment.DiscoverPayment;
const DiscoverPaymentHistory = DP.Main.DiscoverPayment.DiscoverPaymentHistory;
const PaymentLog = DP.Main.DiscoverPayment.PaymentLog;
const ContractManagement = CM.Main.ContractManagement.ContractDetail;
const ContractManagementHistory = CM.Main.ContractManagement.ContractDetailHistory;
const ContractTotals = CM.Main.ContractTotals.ContractTotals;
const ContractTotalsHistory = CM.Main.ContractTotals.ContractTotalsHistory;

export {
    OnboardNetwork,
    ManagerInvitation,
    HospitalInvitation,
    VendorInvitation,
    UserList,
    Relationship,
    RelationshipOffer,
    OperatorRole,
    HospitalRole,
    VendorRole,
    SurgicalEvent,
    SurgicalEventHistory,
    SurgicalEventConfirmation,
    SurgicalEventCompliance,
    SurgicalComplianceHistory,
    SurgicalEventProcedure,
    SurgicalEventProcedureThresholdAmount,
    SurgicalEventProcedureVendorAmount,
    PostProcedureHospital,
    PostProcedureHospitalHistory,
    PostProcedureVendor,
    PostProcedureVendorHistory,
    AccountInventory,
    InventoryProductHistory,
    InventoryRequestHistory,
    AccountInventoryHistory,
    RestockInventory,
    RestockInventoryHistory,
    ReturnInventory,
    ReturnInventoryHistory,
    RecallInventory,
    RecallInventoryHistory,
    ContractPrice,
    ContractPriceHistory,
    InventoryLocation,
    PurchaseOrder,
    PurchaseOrderHistory,
    PurchaseOrderVendor,
    PurchaseOrderVendorHistory,
    Invoice,
    InvoiceHistory,
    InvoiceHospital,
    InvoiceHospitalHistory,
    HospitalTemplate,
    VendorTemplate,
    Payment,
    FHIRProcedure,
    AuditLogs,
    PaymentLogsEvents,
    ChatTemplate as Chat,
    DiscoverPayment,
    DiscoverPaymentHistory,
    Holidays,
    DashboardCards,
    DashboardCardsVendor,
    PaymentLog,
    LogPartyTwo,
    Surgeons,
    PrefCards,
    ContractManagement,
    ContractManagementHistory,
    ContractTotals,
    ContractTotalsHistory,
};
