import { createTheme } from "@material-ui/core";
import defaultTheme from "./default";
import oneTheme from "./one";
import twoTheme from "./two";
import threeTheme from "./three";
import fourTheme from "./four";
import operatorTheme from "./operator";


export default {
  default: createTheme({ ...defaultTheme }),
  one: createTheme({ ...defaultTheme, ...oneTheme }),
  two: createTheme({ ...defaultTheme, ...twoTheme }),
  three: createTheme({ ...defaultTheme, ...threeTheme }),
  four: createTheme({ ...defaultTheme, ...fourTheme }),
  operator: createTheme({ ...defaultTheme, ...operatorTheme })
};
