// Actions Type for UserContext
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SET_FHIR = 'SET_FHIR';
export const CHANGE_LASTPATH = 'CHANGE_LASTPATH';

// Actions Type for LayoutContext
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const TOGGLE_CHAT = 'TOGGLE_CHAT';
export const TOAST_OPEN = 'TOAST_OPEN';
export const SET_LOADING = 'SET_LOADING';
export const UNSET_LOADING = 'UNSET_LOADING';
export const SET_DARK = 'SET_DARK';
export const SET_LIGHT = 'SET_LIGHT';

// Actions Type for DamlContext
export const DAML_USERLIST = 'DAML_USERLIST';
export const DAML_SET_ROLE_CONTRACT = 'DAML_SET_ROLE_CONTRACT';
export const DAML_CLEAR_ROLE = 'DAML_CLEAR_ROLE';
export const DAML_RELATIONSHIP = 'DAML_RELATIONSHIP';
export const DAML_LOCATION = 'DAML_LOCATION';
export const DAML_LOAD_LOCATION = 'DAML_LOAD_LOCATION';
export const DAML_PROCEDURE = 'DAML_PROCEDURE';
export const DAML_LOAD_PROCEDURE = 'DAML_LOAD_PROCEDURE';
export const DAML_LOADING_PROCEDURE = 'DAML_LOADING_PROCEDURE';
export const DAML_HOLIDAY = 'DAML_HOLIDAY';
export const DAML_LOAD_HOLIDAY = 'DAML_LOAD_HOLIDAY';
export const DAML_SURGEON = 'DAML_SURGEON';
export const DAML_LOAD_SURGEON = 'DAML_LOAD_SURGEON';

// Actions Type for SurgicalEvent
export const SURGICAL_SET_DETAIL = 'SURGICAL_SET_DETAIL';
export const SURGICAL_SEARCH = 'SURGICAL_SEARCH';
export const SURGICAL_STATUS = 'SURGICAL_STATUS';
// Actions Type for PurchaseOrder
export const PURCHASE_ORDER_SET_DETAIL = 'PURCHASE_ORDER_SET_DETAIL';
export const PURCHASE_ORDER_SET_LAST_ID = 'PURCHASE_ORDER_SET_LAST_ID';
export const PURCHASE_ORDER_ADD = 'PURCHASE_ORDER_ADD';
export const PURCHASE_ORDER_SEARCH = 'PURCHASE_ORDER_SEARCH';
// Actions Type for ReceivedPOEvent
export const RECEIVEDPO_SET_DETAIL = 'RECEIVEDPO_SET_DETAIL';
export const RECEIVEDPO_SEARCH = 'RECEIVEDPO_SEARCH';
// Actions Type for Invoice
export const INVOICE_SET_DETAIL = 'INVOICE_SET_DETAIL';
export const INVOICE_SET_LAST_ID = 'PURCHASE_ORDER_SET_LAST_ID';
export const INVOICE_ADD = 'INVOICE_ADD';
export const INVOICE_SEARCH = 'INVOICE_SEARCH';
export const PAYMENT_SEARCH = 'PAYMENT_SEARCH';
// Actions Type for Received Invoice
export const RECEIVEDINV_SEARCH = 'RECEIVEDINV_SEARCH';
// Actions Type for Invoice Detail
export const INVD_SET_DETAIL = 'INVD_SET_DETAIL';
export const INVD_SET_LAST_ID = 'INVD_SET_LAST_ID';
export const INVD_ADD = 'INVD_ADD';
// Actions Type for Inventory
export const INVENTORY_SET_DETAIL = 'INVENTORY_SET_DETAIL';
export const INVENTORY_SEARCH = 'INVENTORY_SEARCH';
// Actions Type for Compliance
export const COMPLIANCE_SET_DETAIL = 'COMPLIANCE_SET_DETAIL';
export const COMPLIANCE_SEARCH = 'COMPLIANCE_SEARCH';
// Actions Type for PostProcedure
export const PROCEDURE_SET_DETAIL = 'PROCEDURE_SET_DETAIL';
export const PROCEDURE_SEARCH = 'PROCEDURE_SEARCH';
// Actions Type for SurgicalEventHistory
export const SURGICALEVENTHISTORY_SET_DETAIL = 'SURGICALEVENTHISTORY_SET_DETAIL';
export const SURGICALEVENTHISTORY_SEARCH = 'SURGICALEVENTHISTORY_SEARCH';
// Actions Type for Template
export const TEMPLATE_SEARCH = 'TEMPLATE_SEARCH';
// Actions Type for Invite Vendor
export const INVITE_SEARCH = 'INVITE_SEARCH';
// Actions Type for Accept Vendor
export const VENDOR_ACCEPT_SEARCH = 'VENDOR_ACCEPT_SEARCH';
// Actions Type for Received Invite Vendor
export const RECEIVED_INVITE_SEARCH = 'RECEIVED_INVITE_SEARCH';
// Actions Type for Accept Hospital
export const HOSPITAL_ACCEPT_SEARCH = 'HOSPITAL_ACCEPT_SEARCH';
// Actions Type for User
export const USER_SEARCH = 'USER_SEARCH';
// Actions Type for Toggle Stage
export const SET_TOGGLE_STAGE = 'SET_TOGGLE_STAGE';
// Actions Type for Retrieve ES
export const RETRIEVE_ES_SEARCH = 'RETRIEVE_ES_SEARCH';
// Actions Type for DAML Query
export const DAML_SEARCH = 'DAML_SEARCH';
// Actions Type for Callback Key
export const CALLBACK_KEY_SEARCH = 'CALLBACK_KEY_SEARCH';
// Actions Type for New Search Filters
export const SEARCH_FILTERS = 'SEARCH_FILTERS';
export const SEARCH_FILTERS_CLEAR = 'SEARCH_FILTERS_CLEAR';

// Event Status
export const EVENT_REQUIRED = 'EVENT_REQUIRED';
export const EVENT_VERIFIED = 'EVENT_VERIFIED';
export const EVENT_REJECTED = 'EVENT_REJECTED';
export const EVENT_RUNNING = 'EVENT_RUNNING';
export const EVENT_FINISHED = 'EVENT_FINISHED';

// Actions Type for Product
export const PRODUCT_SET = 'PRODUCT_SET';
export const PRODUCT_SET_ALL = 'PRODUCT_SET_ALL';
export const PRODUCT_SET_DETAIL = 'PRODUCT_SET_DETAIL';
export const PRODUCT_SET_SECONDARY = 'PRODUCT_SET_SECONDARY';
export const PRODUCT_SET_EVENT_DETAIL = 'PRODUCT_SET_EVENT_DETAIL';
export const PREF_SET_EVENT_DETAIL = 'PREF_SET_EVENT_DETAIL';
export const PRODUCT_CLEAR = 'PRODUCT_CLEAR';
// Actions Type for Event
export const EVENT_SET_ALL = 'EVENT_SET_ALL';
export const EVENT_SET_STATUS = 'EVENT_SET_STATUS';
export const EVENT_CLEAR_ALL = 'EVENT_CLEAR_ALL';
// Events Type for Product
export const PRODUCT_CREATE_EVENT = 'PRODUCT_CREATE_EVENT';
export const PRODUCT_ADD_EVENT = 'PRODUCT_ADD_EVENT';
export const PRODUCT_UPDATE_EVENT = 'PRODUCT_UPDATE_EVENT';
export const PRODUCT_DELETE_EVENT = 'PRODUCT_DELETE_EVENT';
export const PRODUCT_COMPLETE_EVENT = 'PRODUCT_COMPLETE_EVENT';
export const PREF_PRODUCT_COMPLETE_EVENT = 'PREF_PRODUCT_COMPLETE_EVENT';

export const PREF_DELETE_EVENT = 'PREF_DELETE_EVENT';
export const PREF_LOAD_EVENT = 'PREF_LOAD_EVENT';
export const PREF_ADD_EVENT = 'PREF_ADD_EVENT';
export const PREF_DELETE_ALL_EVENT = 'PREF_DELETE_ALL_EVENT';

// Actions Type for PO
export const PO_SET = 'PO_SET';
export const PO_SET_ALL = 'PO_SET_ALL';
export const PO_SET_DATA = 'PO_SET_DATA';
// Events Type for PO
export const PO_CREATE_EVENT = 'PO_CREATE_EVENT';
export const PO_SEND_EVENT = 'PO_SEND_EVENT';
export const PO_DELETE_EVENT = 'PO_DELETE_EVENT';
export const PO_INVOICE_EVENT = 'PO_INVOICE_EVENT';
export const PO_COMMENT_EVENT = 'PO_COMMENT_EVENT';
export const PO_NOTE_EVENT = 'PO_NOTE_EVENT';

// Actions Type for Inventory
export const INVENTORY_SET = 'INVENTORY_SET';
export const INVENTORY_SET_ALL = 'INVENTORY_SET_ALL';
export const INVENTORY_SET_RESTOCK = 'INVENTORY_SET_RESTOCK';
export const INVENTORY_SET_ACCOUNT = 'INVENTORY_SET_ACCOUNT';
// Events Type for Inventory
export const INVENTORY_CONFIRM_EVENT = 'INVENTORY_CONFIRM_EVENT';
export const INVENTORY_NOTE_EVENT = 'INVENTORY_NOTE_EVENT';
export const INVENTORY_REQUEST_EVENT = 'INVENTORY_REQUEST_EVENT';
export const INVENTORY_UPDATE_EVENT = 'INVENTORY_UPDATE_EVENT';
export const INVENTORY_DELETE_EVENT = 'INVENTORY_DELETE_EVENT';

// Actions Type for Contract Management
export const CONTRACT_SET = 'CONTRACT_SET';
export const CONTRACT_SET_ALL = 'CONTRACT_SET_ALL';
export const CONTRACT_SET_DATA = 'CONTRACT_SET_DATA';
// Events Type for Contract Management
export const CONTRACT_CREATE_EVENT = 'CONTRACT_CREATE_EVENT';
export const CONTRACT_UPDATE_EVENT = 'CONTRACT_UPDATE_EVENT';
export const CONTRACT_DELETE_EVENT = 'CONTRACT_DELETE_EVENT';


//Refresh bearer token
export const REFRESH_BEARER_TOKEN = 'REFRESH_BEARER_TOKEN';