import React from "react";
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@material-ui/core";
import { getPartyLabel } from "../../models/handles";
import useStyles from "./styles";


/**
 * PO Comments List
 * Detail View Drawer in PO
 * @param {Array} comments 
 * @returns 
 */
export default function InventoryCommentsList ({
   
    
    comments
    
}) {
    
    const classes = useStyles();

     console.log("[POCommentsList]", comments);

    // const isRightSide = (obj) => (obj.status === 'Accepted' || obj.status === 'Rejected')
    

    return (  
        <div className={classes.poCommentsGrid}>
            <h2 className={classes.poCommentHeading}>Comments</h2>
            <div className={classes.poCommentsGridTable}>   
                <List className={classes.customRootComment} >
                   
                        <ListItem 
                            className={"leftComment"}
                        >
                            {
                            <ListItemAvatar>
                                <Avatar>{getPartyLabel(comments.ireturnuser)}</Avatar>
                            </ListItemAvatar>}
                            <div className= {"you"}>
                                <ListItemText primary={comments.ireturnuser} secondary={comments.ireturndate} />
                                <Typography className="msg">{comments.ireturnmessage}</Typography>
                            </div>
                            
                        </ListItem>   
                        <ListItem 
                            className={"rightComment"}
                        >
                            {
                            <ListItemAvatar>
                                <Avatar>{getPartyLabel(comments.ireturnrequestdecisionuser)}</Avatar>
                            </ListItemAvatar>}
                            <div className= {"me"}>
                                <ListItemText primary={comments.ireturnrequestdecisionuser} secondary={comments.ireturnrequestdecisiondate} />
                                <Typography className="msg">{comments.ireturnrequestcomment}</Typography>
                            </div>
                            
                        </ListItem>  
                </List>
            </div>
        </div>
    );
  }  