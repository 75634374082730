import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import React from "react";
const InvoicePDF = ({ data, or }) => {
    const styles = StyleSheet.create({
        page: {
            fontSize: or === "landscape" ? 11 : 8,
            lineHeight: 1.4,
        },
        heading: {
            color: "gray"
        },
        value: {
            fontSize: or === "landscape" ? 9 : 7,
        }
    });
    console.clear();
    console.log("data", data, or);
    data.forEach(d => console.log(d.payload.invoicedetail));
    return (
        <Document>
            {
                data.map(d =>
                    <Page key={d.contractId} size="A4" style={styles.page} orientation={or}>
                        <View fixed style={{ fontSize: 13, flexDirection: "row", borderBottom: "1pt solid #CCCCCC", padding: 5 }}>
                            <Text>
                                Invoice number:
                            </Text>
                            <Text style={{ color: "#1C3C74" }}>
                                {d.payload.invoicedata.invinvoicenumber}
                            </Text>
                        </View>
                        <View fixed style={{ flexDirection: "row", padding: 5 }}>
                            <View style={{ flex: "0 0 50%", margin: 2, flexDirection: "row", border: "1pt solid #CCCCCC" }}>
                                <View style={{ flex: "0 0 33%", padding: 5, borderRight: "1pt solid #CCCCCC" }}>
                                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                                        <Text style={styles.heading}>Hospital: </Text>
                                        <Text style={styles.value}>{d.payload.hospital}</Text>
                                    </View>
                                    <Text style={styles.value}>hospital address</Text>
                                </View>
                                <View style={{ flex: "0 0 33%", padding: 5, borderRight: "1pt solid #CCCCCC" }}>
                                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                                        <Text style={styles.heading}>Vendor: </Text>
                                        <Text style={styles.value}>{d.payload.vendor}</Text>
                                    </View>
                                    <Text style={styles.value}>{d.payload.vendoraddress.address1}</Text>
                                    <Text style={styles.value}>{d.payload.vendoraddress.city},
                                        {d.payload.vendoraddress.zipcode},
                                        {d.payload.vendoraddress.state},
                                        {d.payload.vendoraddress.country}</Text>
                                </View>
                                <View style={{ flex: "0 0 33%", padding: 5 }}>
                                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                                        <Text style={styles.heading}>Date: </Text>
                                        <Text style={styles.value}>{d.payload.invoicedata.invoicesentdate}</Text>
                                    </View>
                                    <View style={{ flexDirection: "row" }}>
                                        <Text style={styles.heading}>Buyer</Text>
                                        <Text style={styles.value}></Text>
                                    </View>
                                    <View style={{ flexDirection: "row" }}>
                                        <Text style={styles.heading}>Type</Text>
                                        <Text style={styles.value}>{d.payload.paymentdetaildata.paymenttype}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ flex: "0 0 50%", margin: 2, flexDirection: "row", border: "1pt solid #CCCCCC" }}>
                                <View style={{ flex: "0 0 33%", padding: 5, borderRight: "1pt solid #CCCCCC" }}>
                                    <Text style={styles.heading}>Ship To:</Text>
                                    <Text style={styles.value}>hospital address</Text>
                                </View>
                                <View style={{ flex: "0 0 30%", padding: 5, borderRight: "1pt solid #CCCCCC" }}>
                                    <Text style={styles.heading}>Invoice To:</Text>
                                    <Text style={styles.heading}>Operator</Text>
                                    <Text style={styles.value}>{d.payload.operator}</Text>
                                    <Text style={styles.heading}>Contract ID</Text>
                                    <Text style={styles.value}>{d.contractId.slice(0,17)}</Text>
                                    <Text style={styles.value}>{d.contractId.slice(17,34)}</Text>
                                    <Text style={styles.value}>{d.contractId.slice(34,51)}</Text>
                                    <Text style={styles.value}>{d.contractId.slice(51)}</Text>
                                </View>
                                <View style={{ flex: "0 0 37%", padding: 5 }}>
                                    {
                                        or === "landscape" ?
                                            <>
                                                <View style={{ flexDirection: "row" }}>
                                                    <View style={{ flex: "0 0 50%" }}>
                                                        <Text style={styles.heading}>Terms</Text>
                                                        <Text style={styles.value}>{d.payload.paymentdetaildata.payment_term}</Text>
                                                    </View>
                                                    <View style={{ flex: "0 0 50%" }}>
                                                        <Text style={styles.heading}>FOB Contact</Text>
                                                        <Text style={styles.value}></Text>
                                                    </View>
                                                </View>
                                                <Text style={styles.heading}>MA EXEMPT PURCH CERT</Text>
                                                <Text style={styles.value}></Text>
                                            </> :
                                            <>
                                                <Text style={styles.heading}>Terms</Text>
                                                <Text style={styles.value}>{d.payload.paymentdetaildata.payment_term}</Text>
                                                <Text style={styles.heading}>FOB Contact</Text>
                                                <Text style={styles.value}></Text>
                                                <Text style={styles.heading}>MA EXEMPT PURCH CERT</Text>
                                                <Text style={styles.value}></Text>
                                            </>
                                    }

                                </View>
                            </View>
                        </View>
                        <View fixed style={{ flexDirection: "row", border: "1pt solid #CCCCCC", margin:"0 5pt 0 5pt", padding: 2 }}>
                            <Text style={{ flex: "0 0 5%" }}>
                                Line
                            </Text>
                            <Text style={{ flex: "0 0 10%" }}>
                                Item
                            </Text>
                            <Text style={{ flex: "0 0 10%" }}>
                                Vend’s Catlg
                            </Text>
                            <Text style={{ flex: "0 0 20%" }}>
                                Description
                            </Text>
                            <Text style={{ flex: "0 0 10%" }}>
                                Manufacturer
                            </Text>
                            <Text style={{ flex: "0 0 10%" }}>
                                Quantity
                            </Text>
                            <Text style={{ flex: "0 0 5%" }}>
                                Price
                            </Text>
                            <Text style={{ flex: "0 0 10%" }}>
                                EXT Cost
                            </Text>
                            <Text style={{ flex: "0 0 10%" }}>
                                DEPT or INVEN
                            </Text>
                            <Text style={{ flex: "0 0 10%", textAlign: "right" }}>
                                G/L Account
                            </Text>
                        </View>

                        {
                            d.payload.invoicedetail.map((e, i) =>
                                <View key={e.id} style={{ ...styles.value, flexDirection: "row", marginLeft: 5, marginRight: 5, padding: 2, borderRight: "1pt solid #CCCCCC", borderLeft: "1pt solid #CCCCCC", }}>
                                    <Text style={{ flex: "0 0 5%" }}>
                                        {i + 1}
                                    </Text>
                                    <Text style={{ flex: "0 0 10%" }}>
                                        {e.invdorderitem}
                                    </Text>
                                    <Text style={{ flex: "0 0 10%" }}>
                                        {e.invdproduct.sepreferencenumber}
                                    </Text>
                                    <Text style={{ flex: "0 0 20%" }}>
                                        {e.invdproduct.sepproductdescription}
                                    </Text>
                                    <Text style={{ flex: "0 0 10%" }}>
                                        {e.invdproduct.sepproductmanufacturer}
                                    </Text>
                                    <Text style={{ flex: "0 0 10%" }}>
                                        {e.invdproductquantity}
                                    </Text>
                                    <Text style={{ flex: "0 0 5%" }}>
                                        {e.invdproductprice}
                                    </Text>
                                    <Text style={{ flex: "0 0 10%" }}>
                                        cost
                                    </Text>
                                    <Text style={{ flex: "0 0 10%" }}>
                                        inventory location
                                    </Text>
                                    <Text style={{ flex: "0 0 10%", textAlign: "right" }}>
                                        {e.invdproductprice * e.invdproductquantity}
                                    </Text>
                                </View>
                            )
                        }
                        <View fixed style={{ flexDirection: "row", border: "1pt solid #CCCCCC", marginLeft: 5, marginRight: 5 }}>
                            <View style={{ padding: 5, flex: "0 0 40%", borderRight: "1pt solid #CCCCCC" }}>
                                <Text>Reference: </Text>

                                <View style={{ flexDirection: "row" }}>
                                    <View>
                                        <Text>Purchase Order Number</Text>
                                        <Text style={styles.value}>{d.payload.invoicedata.invpurchaseorderid}</Text>
                                    </View>
                                    <View style={{ marginLeft: 10 }}>
                                        <Text>Surgical Event ID</Text>
                                        <Text style={styles.value}>{d.payload.invoicedata.invseid}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ padding: 5, flex: "0 0 60%", textAlign: "right" }}>
                                <Text>Total: $ {d.payload.invoicedetail.reduce((a, c) => { return a + Number(c.invdproductprice * c.invdproductquantity) }, 0)}</Text>
                            </View>
                        </View>
                        <View fixed style={{ padding: 5, borderBottom: "1pt solid #CCCCCC", borderLeft: "1pt solid #CCCCCC", borderRight: "1pt solid #CCCCCC", marginLeft: 5, marginRight: 5 }}>
                            <Text>
                                Comments:
                            </Text>
                            <Text style={styles.value}>

                            </Text>
                        </View>
                    </Page>
                )
            }
        </Document>)
}
export default InvoicePDF;