import React, { useState } from "react";
import { DiscoverPaymentHistory, DiscoverPayment } from "../../services/daml-modules1";
import { Grid, Typography, Chip } from "@material-ui/core";
import Contracts from "../../components/Contracts/Contracts1";
import AdaptiveSearch from "../../components/Search/adaptivesearch";
import classNames from "classnames";
import useStyles from "./styles";
import useStyles1 from "../surgicalevent/styles";
import { useParty, useStreamQueries } from "@daml/react";
import { useDamlStream } from "../../hooks/useDAML";
import { dispatch, getState } from "../../store";
import { paymentSearch } from "../../store/actions/generalActions";
import PaymentLogDetail from "./paymentLogDetail";

export default function PaymentLogs({
  allContracts = []
}) {
  const [searchInv, setSearchInv] = useState({
    hospital: '',
    invseid: '',
    invinvoicenumber: '',
    invinvoicedate: '',
    ...getState().invoice,
  });
  const handleChangeSearchValue = (val, key) => {
    setSearchInv({ ...searchInv, [key]: val });
    dispatch(paymentSearch({ key, value: val }));
  };
  const classes = useStyles();
  const classes1 = useStyles1();
  const hospital = useParty();

  const [paymentDetailOpen, setPaymentDetailOpen] = useState(false);
  const [paymentData, setPaymentData] = useState(null);

  const assetsHistory = useDamlStream(DiscoverPaymentHistory, [{ hospital }], true);

  let { contracts: paymentContracts } = useStreamQueries(DiscoverPayment);
  paymentContracts = paymentContracts?.concat(assetsHistory.contracts ?
    assetsHistory.contracts.map(h => ({ ...h, payload: h.payload.discoverpayment })) : []);
  paymentContracts = paymentContracts
    .map(c => {
      c.payload.purchaseProductCount = c.payload.purchaseorderdetaildata.reduce((s, invd) => (s + parseInt(invd.podquantity)), 0);
      return c;
    });


  /**
   * If it's based on DAML Financial.Payments, this should be true.
   * If it's based on Node Server API, this should be false.
   */
  const isDAMLnotAPI = true;
  const handleClicked = (e) => {
    const contract = ('row' in e && 'contract' in e.row) ? e.row.contract : {};
    setPaymentData(contract.payload);
    setPaymentDetailOpen(true);
  };

  const getColorCode = (value) => {
    switch (value) {
      case 'Sent':
        return classes1.backGreen;
      case 'Ready for payment':
        return classes1.backBlue;
      case 'Complete':
        return classes1.backGreen;
      case 'Available for payment':
        return classes1.backBlue;
      case 'Cancelled':
        return classes1.backOrange;
      default:
        return classes1.backYellow;
    }
  }
  return (
    <>
      <Grid>
        <Grid container className={classes1.newContainer} justifyContent="space-between" >
          <Grid item xs>
            <Typography variant="h4" className={classes1.newPageTitle}>Payments</Typography>
          </Grid>
        </Grid>

        <Contracts
          className={classes.customRoot}
          contracts={isDAMLnotAPI ? paymentContracts : []}
          columns={
            [
              ["Payment No", "payload.paymentnumber", {
                type: 'link',
                onClick: (_contractId, _contract) => {
                  const data = (!!_contract) ? _contract : paymentContracts.find(c => c.contractId === _contractId);
                  setPaymentData(data.contract.payload);
                  setPaymentDetailOpen(true);
                },
                options: {
                  width: 150,
                },
              }],
              ["Distributor", "payload.vendor", {
                options: {
                  width: 90,
                }
              }],

              ["Invoice ID", "payload.invoicedata.invinvoicenumber", {
                type: 'link',
                onClick: (_contractId, _contract) => {

                },
                search: {
                  component:
                    <AdaptiveSearch
                      getValue={(c) => c.payload.invoicedata.invinvoicenumber}
                      handleChangeSearch={(v) => handleChangeSearchValue(v, 'invinvoicenumber')}
                      defaultValue={searchInv.invinvoicenumber}
                      label="Search ID"
                      contracts={paymentContracts}
                      isShrink={true}
                    />
                },
                options: {
                  width: 120,
                },
                isSearched: searchInv.invinvoicenumber,
                handleClear: () => handleChangeSearchValue('', 'invinvoicenumber'),
              }],
              ["PO Number", "payload.purchaseorderdata.popurchaseorderid", {
                options: {
                  width: 140,
                },
              }],
              ["Payment Sent", "payload.paymentinitiationdate", {
                options: {
                  width: 140,
                },
              }],
              ["Payment Due", "payload.paymentdate", {
                options: {
                  width: 140,
                },
              }],

              ["Type", "payload.payemnttype", {
                options: {
                  width: 130,
                }
              }],
              ["Amount", "payload.invoicedata.invinvoicetotalamount", {
                options: {
                  width: 100,
                  beforeText: '$'
                },
              }],
              ["Status", "payload.status", {
                type: "render",
                render: (param) => (<Chip
                  label={param.value}
                  size="small"
                  className={classNames(classes1.smallChip, getColorCode(param.value))}
                />),
                options: {
                  width: 150,
                  headerClassName: classes.centerCell,
                  cellClassName: classes.centerCell,
                },
              }],
            ]}
          actions={[
            [

              "Actions",
              [
                [["Archive"]],
                [["Cancel"]],

              ],
            ],
            {
              flex: 1,
              headerAlign: 'center',
            }
          ]
          }
          options={{
            statusEmpty: (allContracts.length === 0),
            checkbox: true,
          }}
          handleClicked={handleClicked}
        />

      </Grid>

      {
        paymentData
          ?
          <PaymentLogDetail
            open={paymentDetailOpen}
            setOpen={(isOpen) => {
              setPaymentDetailOpen(isOpen);
              if (!isOpen) {
                setPaymentData(null)
              }
            }}
            contract={paymentData}
          /> :
          null}
    </>
  )
}

