import React, { useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import { Box } from "@material-ui/core";
// import { DataGrid } from '@material-ui/data-grid';
import { DataGridPro as DataGrid, useGridApiRef, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import { getValueByCol, renderCell, sortContracts } from "./functions";
import { ToolTipCell } from "./ActionCell";
import { renderActionCells1 } from "./ActionCell1";
import { renderHeaderCell, withCheckboxHeaderCell } from "./HeaderCell1";
import { useStyles } from "./styles";
import { CustomPagination1 } from "./CustomPagination";
import { useLayoutState } from "../../context/LayoutContext";

/**
 * Contracts based on DataGrid
 * @param {Array} contracts 
 * @param {Array} columns 
 * @param {Array} actions 
 * @param {Object} options 
 * @param {Function} handleArchived 
 * @param {Function} handleClicked 
 * @param {Function} handleChecked 
 * @param {Function} handleOver 
 * @returns 
 */
export default function Contracts({
  contracts, columns, actions = [], options = {},
  handleArchived = null, handleClicked = null, handleChecked = null, handleOver = null
}) {

  actions = actions ? actions : [];
  options = {
    checkbox: false,
    collapseFixed: false,
    checkboxSelectHandler: null,
    sortKey: 'payload.created_at',              // old version: 'created_at',
    loading: false,
    isFooter: true,
    isPagination: true,
    rowsPerPage: 10,
    statusEmpty: true,                          // if empty page, then it's true
    height: 'calc(100vh - 230px) !important',   // height of Contracts
    headerHeight: 56,
    rowHeight: 55,
    getDetailPanelHeight: ({ row }) => 'auto',  // Height of Detail based on the content
    isAddCheckboxHeader: true,                  // add header cell with Checkbox
    checkboxCellWidth: 50,
    fieldsToDisableClick: ['Actions'],          // field list to make click action disabled
    ...options
  };
  // console.log("[Contracts] options", options);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(options.rowsPerPage);

  const isDefault = !columns;
  columns = columns ? columns : [["TemplateId", "templateId"], ["ContractId", "contractId"]];

  contracts = sortContracts(contracts, options.sortKey);
  // console.log("[Contracts]", contracts, columns);

  const classes = useStyles();

  const apiRef = useGridApiRef();


  // columns of DataGrid
  let cols = columns.map(col => {
    const sortable = !col[2] || typeof col[2].search === 'undefined';
    const options = (!!col[2] && !!col[2].options) ? col[2].options : {};
    return {
      field: col[0],
      headerName: col[0],
      description: col[0],
      headerClassName: classNames(classes.headerCell, col[2]?.options?.headerClassName),
      cellClassName: classNames(classes.tableCell, col[2]?.options?.cellClassName),
      disableColumnMenu: true,
      sortable,
      renderHeader: renderHeaderCell(col[2]),
      renderCell: renderCell(col[2]),
      ...options,
    };
  });
  if (options.checkbox && typeof options.renderCheckboxHeaderCell === 'function') {
    // To customize Checkbox Header Cell,
    cols.unshift({
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      type: 'string',
      // resizable: true,
      width: options.checkboxCellWidth,
      renderHeader: withCheckboxHeaderCell(options.renderCheckboxHeaderCell,
        GRID_CHECKBOX_SELECTION_COL_DEF.renderHeader, options.isAddCheckboxHeader),
    });
  }
  if (isDefault) cols.push({
    field: 'payload',
    headerName: 'Payload',
  });
  if (actions.length) cols = cols.concat(actions.map(act => {
    const options = (!!act[2] && typeof act[2] === 'object') ? act[2] : {};
    return {
      field: act[0],
      headerName: act[0],
      description: act[0],
      sortable: false,
      // flex: 1,
      cellClassName: classes.actionCellWrap,
      disableColumnMenu: true,
      renderCell: renderActionCells1(act[1], options),
      ...options,
    };
  }));
  // console.log("[Contracts] cols", cols);


  // rows of DataGrid
  const rows = contracts.map((c, i) => {
    let values = {
      id: c.id ? c.id : (c.contractId ? c.contractId : i), // Key of each row
      contract: c,
    };

    columns.forEach(col => {
      let val = getValueByCol(c, col[1], col[2]);
      val = typeof val === "boolean" ? (val ? "True" : "False") : val;

      if (!!val && typeof val === "object" && val.length >= 0 && typeof col[2] === "object"
        // && 'columns' in col[2]
      ) {
        val = val.length;
      }
      if (col[2] && typeof col[2].options !== 'undefined' && typeof col[2].options.tooltip !== 'undefined' && col[2].options.tooltip) {
        val = <ToolTipCell cellData={val} />
      }
      //console.log("[Contracts] cell", col[2]);
      // if (!!val && typeof val === "object") val = "";

      values[col[0]] = val;

    });


    actions.forEach(act => {
      values[act[0]] = c;
    });

    return values;
  });
  // console.log("[Contracts] rows", rows);


  const handleClickCell = useCallback((params, e) => {
    // console.log("[Contracts] handleClickCell", params, e.target, e.target.tagName);
    const cellNode = e.target;
    if (cellNode) {
      const rowNode = cellNode.parentNode;
      if (rowNode) {
        const gridNode = rowNode.parentNode;
        if (gridNode) {
          gridNode.childNodes.forEach(e => e.classList.remove('custom-clicked'));
          rowNode.classList.add('custom-clicked');
        }
      }
    }
    if (options.fieldsToDisableClick.indexOf(params.field) !== -1 || e.target.tagName === 'INPUT') return;
    if (typeof handleClicked === 'function') handleClicked(params);
  }, [handleClicked, options.fieldsToDisableClick]);

  const handleCheckRow = useCallback((params) => {
    if (typeof handleChecked === 'function') handleChecked(params);
  }, [handleChecked]);


  useEffect(() => {
    return apiRef.current.subscribeEvent('rowMouseEnter', (params, event) => {
      if (typeof handleOver === 'function') handleOver(params, event);
    });
  }, [apiRef, handleOver]);

  useEffect(() => {
    return apiRef.current.subscribeEvent('rowMouseLeave', (params, event) => {
      if (typeof handleOver === 'function') handleOver(null, event);
    });
  }, [apiRef, handleOver]);

  const layoutState = useLayoutState()
  return (
    <Box sx={{ height: options.height }}>
      <DataGrid
        className={layoutState.darkMode?classes.datagridDM:classes.datagrid}
        rows={rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
        columns={cols}
        checkboxSelection={options.checkbox}
        // autoHeight={true}
        loading={options.loading}
        hideFooter={!options.isFooter}
        hideFooterPagination={!options.isPagination}
        hideFooterSelectedRowCount={true}
        // hideFooterRowCount={true}
        pagination={true}
        pageSize={rowsPerPage}
        // modifires // why ???
        components={{
          Pagination: CustomPagination1,
        }}
        componentsProps={{
          pagination: {
            count: rows.length,
            page: page,
            setPage: setPage,
            rowsPerPage: rowsPerPage,
            handleCheckbox: handleArchived,
            setRowsPerPage: setRowsPerPage,
          },
        }}
        headerHeight={options.headerHeight}
        rowHeight={options.rowHeight}
        onCellClick={handleClickCell}
        disableSelectionOnClick
        onSelectionModelChange={handleCheckRow}
        apiRef={apiRef}
        getDetailPanelHeight={options.getDetailPanelHeight}
        getDetailPanelContent={options.getDetailPanelContent}
      />
    </Box>
  );
}
