import { ContactModel, W9FormModel, validateContact, getContact ,validateW9Form , getW9Form} from './Contact';
import { AddressModel, validateAddress, getAddress } from './Address';
import { validateArray, getArray, getModelMenu, validateModelItem } from './handles';

export const Paytype = {
  ACH: 'ACH',
  Check: 'Check',
  VCard: 'Prefunded Virtual Debit Card',
};

export const validatePaytype = validateModelItem(Paytype);

export const getPaytypes = getModelMenu(Paytype);

export const HospitalACHDetails = {
  discover_payment: false,
  straight_through_payment: false,
  payernetwork_id: '',
  partnernetwork_id: '',
  sender_id: '',
  partnername_id: '',
  user_agent: '',
  accountnumber: '',
  routingnumber: '',
  bankname: '',
  typeaccount: '',
  paymethod: [],
  cardnumber: '',
  cardexpiry: '',
};

export const validateHospitalACH = (ach) => {
  return ((ach.accountnumber !== ""
  || ach.routingnumber !== ""
  || ach.bankname !== ""
  || ach.typeaccount !== "")
  && validateArray(ach.paymethod, validatePaytype));
};

export const getHospitalACH = (ach) => {
  let newACH = {};
  if (typeof ach.discover_payment === "boolean") newACH.discover_payment = ach.discover_payment;
  if (typeof ach.straight_through_payment === "boolean") newACH.straight_through_payment = ach.straight_through_payment;
  if (typeof ach.payernetwork_id === "string" && ach.payernetwork_id !== "") newACH.payernetwork_id = ach.payernetwork_id;
  if (typeof ach.partnernetwork_id === "string" && ach.partnernetwork_id !== "") newACH.partnernetwork_id = ach.partnernetwork_id;
  if (typeof ach.sender_id === "string" && ach.sender_id !== "") newACH.sender_id = ach.sender_id;
  if (typeof ach.partnername_id === "string" && ach.partnername_id !== "") newACH.partnername_id = ach.partnername_id;
  if (typeof ach.user_agent === "string" && ach.user_agent !== "") newACH.user_agent = ach.user_agent;
  if (typeof ach.accountnumber === "string" && ach.accountnumber !== "") newACH.accountnumber = ach.accountnumber;
  if (typeof ach.routingnumber === "string" && ach.routingnumber !== "") newACH.routingnumber = ach.routingnumber;
  if (typeof ach.bankname === "string" && ach.bankname !== "") newACH.bankname = ach.bankname;
  if (typeof ach.typeaccount === "string" && ach.typeaccount !== "") newACH.typeaccount = ach.typeaccount;
  newACH.paymethod = getArray(ach.paymethod, validatePaytype, getPaytypes);
  if (typeof ach.cardnumber === "string" && ach.cardnumber !== "") newACH.cardnumber = ach.cardnumber;
  if (typeof ach.cardexpiry === "string" && ach.cardexpiry !== "") newACH.cardexpiry = ach.cardexpiry;
  return newACH;
};

export const HospitalDetailModel = {
  facilityname: '',
  physicaladdress: {...AddressModel},
  shippingaddress: [
    {...AddressModel},
  ],
  billingaddress:  [
    {...AddressModel},
  ],
  point_contact: [
    {...ContactModel},
  ],
  ach_info: {...HospitalACHDetails},
  billinfocontact: [
    {...ContactModel},
  ],
  inventorycontact: [
    {...ContactModel},
  ],
  surgicalsuitecontact: [
    {...ContactModel},
  ],
  purchinfocontact: [
    {...ContactModel},
  ],
  w9Form :  [
    {...W9FormModel},
  ],
  taxid: '',
};

export const UserListModel = {
  accesslevel : '',
  email : '',
  gender : '',
  jobtitle : '',
  name :'',
  officephone : null ,
  userid : '',
  isdarkenable : false,
  profileimg : ''
};


export const validateHospitalDetail = (hospital) => {
  return (hospital.facilityname !== ""
    || hospital.taxid !== ""
    || validateArray(hospital.w9Form, validateW9Form)
    || (validateAddress(hospital.physicaladdress)
    && validateArray(hospital.shippingaddress, validateAddress)
    && validateAddress(hospital.billingaddress)
    && validateArray(hospital.point_contact, validateContact)
    // && validateHospitalACH(hospital.ach_info)
    && validateArray(hospital.billinfocontact, validateContact)
    && validateArray(hospital.inventorycontact, validateContact)
    && validateArray(hospital.surgicalsuitecontact, validateContact)
    && validateArray(hospital.purchinfocontact, validateContact))
  );
};

export const getHospitalDetail = (hospital) => {
  let newHospital = {};
  newHospital.facilityname = hospital.facilityname || "";
  newHospital.physicaladdress = validateAddress(hospital.physicaladdress) ? getAddress(hospital.physicaladdress) : {...AddressModel};
  newHospital.shippingaddress = getArray(hospital.shippingaddress, validateAddress, getAddress);
  newHospital.billingaddress = getArray(hospital.billingaddress, validateAddress ,getAddress );
  newHospital.point_contact = getArray(hospital.point_contact, validateContact, getContact);
  if (validateHospitalACH(hospital.ach_info)) newHospital.ach_info = getHospitalACH(hospital.ach_info);
  newHospital.billinfocontact = getArray(hospital.billinfocontact, validateContact, getContact);
  newHospital.inventorycontact = getArray(hospital.inventorycontact, validateContact, getContact);
  newHospital.surgicalsuitecontact = getArray(hospital.surgicalsuitecontact, validateContact, getContact);
  newHospital.purchinfocontact = getArray(hospital.purchinfocontact, validateContact, getContact);
  newHospital.taxid = hospital.taxid || "";
  newHospital.w9Form = getArray(hospital.w9Form, validateW9Form, getW9Form);
  return newHospital;
};