import { validateModelItem, getSubModel } from "./handles";
import { validateRole } from "./Role";

export const POCommentModel = {
  date: '',
  user: '',
  note: '',
  status: '',
  role: '',
};
export const validatePOComment = (pos) => {
  return (pos.date !== ""
    || pos.user !== ""
    || pos.note !== ""
    || validatePOStatus(pos.status)
  );
};
export const getPOComment = (pos) => {
  let newPos = {};
  if (typeof pos.date === "string" && pos.date !== "") newPos.date = pos.date;
  if (typeof pos.user === "string" && pos.user !== "") newPos.user = pos.user;
  if (typeof pos.note === "string" && pos.note !== "") newPos.note = pos.note;
  if (validatePOStatus(pos.status)) newPos.status = pos.status;
  if (validateRole(pos.role)) newPos.role = pos.role;
  return newPos;
};

export const POStatus = {
  Initiated: 'Initiated',
  SentVendor: 'Sent Vendor',
  Accepted: 'Accepted',
  Rejected: 'Decline', // TODO : It should be Decline or Rejected?
  InvoiceCreated: 'Invoice Created',
  InvoiceSent: 'Invoice Sent',
  InvoiceReceived: 'Invoice Received',
  Updated: 'Updated',
  SentUpdate: 'Sent Update',
  InitiateReceived: 'Initiate Received',
  UpdateReceived: 'Update Received',
  MissingInfo: 'Missing Info',
};
export const validatePOStatus = validateModelItem(POStatus);

// POStatus for Purchase Order Hospital
export const POHStatus = getSubModel(POStatus, [
  'Initiated', 'Accepted', 'SentVendor', 'SentUpdate', 'InvoiceCreated', 'InvoiceSent', 'Rejected', 'Updated', 'MissingInfo', 'InvoiceReceived'
  // 'InitiateReceived', // not sure.
]);

// POStatus for Purchase Order Vendor
export const POVStatus = getSubModel(POStatus, [
  'Accepted', 'Rejected', 'InvoiceCreated', 'InitiateReceived', 'UpdateReceived',"InvoiceSent" ,'InvoiceReceived'
]);

// check if purchase order can be added or removed
export const isEnableEdit = (status) => (
  status === "Initiated" || status === "Rejected" || status === "Updated" || status === "MissingInfo"
);

// check if purchase order can change status
export const isEnableChangeStatus = (status) => (
  status === "SentVendor" || status === "SentUpdate"
);

// check if purchase order can send po to vendor
export const isEnableSendVendor = (status) => (
  status === "Initiated" || status === "Rejected" || status === "Updated"
);

// check if purchase order can Accept / Decline
export const isEnableAcceptDecline = (status) => (
  status === "InitiateReceived" || status === "UpdateReceived"
);

// check if purchase order can send po to Elastic Search
export const isEnableSendES = (status) => (
  // status === "Accepted" || status === "InvoiceCreated" 
  !status
);

// check if purchase order can create invoice by status
export const isEnableCreateInvoice = (status) => (
  status === "Accepted" 
);

// check if purchase order can be archived by postatus
export const isEnableArchive = (status) => (
  // !(status === "SentVendor" || status === "InitiateReceived" || status === "UpdateReceived" || status ==="Initiated")
  status === "InvoiceCreated" || status === "Rejected" ||  status ==="Initiated" || status === "SentVendor"
);

// check if purchase order can be archived by poinventorystatus
export const isEnableArchivePO = (status) => (
  !status || status === "AllReceived"
);


// check if a Sub product of a purchase order can be deleted by status
export const isEnableDeleteSubProduct = (status) => (
  status === "Initiated" || status === "Rejected" || status === "Updated" || status === "MissingInfo"
);

// check if a sub product of a purchase order can be deleted by status
export const isEnableChangeOwnership = (status) => (
  status === "Initiated" || status === "Rejected" || status === "Updated" || status === "MissingInfo" 
);

export const getColorCode = (value) => {
  switch (value) {
      case 'InvoiceCreated':
          return "rgba(103, 58, 183)";
      case 'SentVendor' :
      case 'SentUpdate' :
          return 'rgba(156, 39, 176)';
      case 'InvoiceReceived':
          return "rgba(76, 175, 80 )";
      case 'Accepted':
          return "rgba(76, 175, 80)";
      case 'Rejected':
          return "rgba(244, 67, 54 )";
      case 'MissingInfo':
          return "rgba(255, 152, 0)";
      default:
          return "rgba(33, 150, 243 )";
  }
}
