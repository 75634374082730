import React, { useEffect, useState } from 'react';
import { Button, ClickAwayListener, MenuItem, MenuList, Popper, Select, Typography } from '@material-ui/core';
import { useLedger, useStreamQueries } from '@daml/react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import DraftsIcon from '@material-ui/icons/Drafts';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import { OperatorRole, HospitalRole, VendorRole } from "../../services/daml-modules1";
import { getPartyLabel } from "../../models/handles";
import GroupChatForm from './group';
import { useDamlState } from '../../context/DamlContext';
import MessageList from './messageList';
import useStyles from "./styles";


export default function MessageInbox() {

  var classes = useStyles();
  const { role, party } = useDamlState();
  const ledger = useLedger();

  const isUsersOrRooms = false;

  const [slideToggle,setSlideToggle] = useState(false);
  const [groupOpen,setGroupOpen] = useState(false);
  
  const [rooms, setRooms] = useState([]);
  const [users, setUsers] = useState([]);
  const [message,setMessage]= useState('');
  const [lastMessage,setLastMessage]= useState('new');
  const [selectUsers, setSelectedUsers] = useState([]);
  const [usersList,setUsersList] = useState(users);
  const [selectionFilter,setSelectionFilter] = useState('All');
  const [anchorEl, setAnchorEl] = useState(false);
  const [searchOpen,setSearchOpen] = useState(false);
  const [selectedIndex,setSelectedIndex] = useState(0);

  const open = Boolean(anchorEl);
  const defaultRooms = [
    { name: 'Hospital', desc: 'This is the first test room.', icon: '' },
    { name: 'Vendor', desc: 'This is the second test room.', icon: '' },
    { name: 'Operator', desc: 'This is the third test room.', icon: '' },
  ];
  const [room, setRoom] = useState(defaultRooms[selectedIndex].name);  

  let templateRole = OperatorRole, choiceRole = null, searchParams = {};
  if (role === "Hospital") {
      templateRole = HospitalRole;
      choiceRole = HospitalRole.SetChatHospital;
      searchParams = { hospital: party };
  }
  else if (role === "Vendor") {
      templateRole = VendorRole;
      choiceRole = VendorRole.SetChatVendor;
      searchParams = { vendor: party };
  }
  else if (role === "Operator") {
      templateRole = OperatorRole;
      choiceRole = OperatorRole.SetChat;
      searchParams = { party: party };
  }
  const assets = useStreamQueries(templateRole, () => [searchParams]);
  
  const userSelection = (e,index) => {
    if(e.target.checked === true){
        users[index].checked = true;
    }else{
        users[index].checked = false;
    }
    
    setSelectedUsers(users);
    setUsersList(users);
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? false : event.currentTarget);
  };

  const handleCloseNot = (event) => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }

    setAnchorEl(false);
  };

  const handClose = ()=>{
    setAnchorEl(false);
  };

  useEffect(() => {

    if (isUsersOrRooms) {
      // get users

      const defaultUsers = [];
      setUsers(defaultUsers);
    }
    else {
      // get rooms

      // TODO
      // use DAML to get default rooms
      
      setRooms(defaultRooms);
    }

  }, [isUsersOrRooms, defaultRooms]);

  const sendMessage = async (event) => {
    event.preventDefault();

    if (message && room) {

      const receiver = [];
      // const datetime = getTodayString();
      // const sender = user.party;


      /**
       * if you want to use ledger.exercise, this should be true.
       * if you want to use ledger.create, this should be false.
       */
      const contractId = assets.contracts[0].contractId;

      ledger.exercise(choiceRole, contractId, { receiver, content: message, room });

      setMessage('');
      setLastMessage('');
      
    }
  };

  const onSelectRole = (index,name)=>{
      setSelectedIndex(index);
      setRoom(defaultRooms[index].name);    
  };

  
  return (
    <div key="formChildStep" className={classes.MessageInbox}>
      <Typography variant="h4" className={classes.pageTitle}>Messenger</Typography>

      <Button
        size="small"
        className={classes.newSetupButton}
        onClick={()=>{setGroupOpen(true)}}
        variant="contained"
      >
        <span className="desktop">Create Group</span> 
        <span className="mobile"><GroupAddIcon /></span>
      </Button>

      <GroupChatForm 
        open = {groupOpen}
        setOpen = {setGroupOpen}
        selectUsers={selectUsers}
        setSelectedUsers ={setSelectedUsers}
        userSelection={userSelection}
        usersList={usersList}
      />

      <div className={classes.messanger}>
      
        <Grid container component={Paper} className={classes.chatSection}>
          <Grid item xs={3} className={ slideToggle === false ? 'borderRight500' : ' borderRight500 open'}>
              <PeopleAltIcon className={"onlineUsersOpen"} onClick={()=>{setSlideToggle(!slideToggle)}}/>

              <Grid item xs={12} className={classes.searchFilter}>
                <Select
                  labelId="demo-simple-select-label"
                  id="filter-simple-select"
                    value={selectionFilter}
                  onChange={(e)=>{setSelectionFilter(e.target.value)}}
                >
                  <MenuItem value={'All'}>All Conversations</MenuItem>
                  <MenuItem value={'Vendors'}>Vendors</MenuItem>
                  <MenuItem value={'Operators'}>Operators</MenuItem>
                </Select>

                <IconButton aria-label="find" className={classes.margin} onClick={(e)=>{ setSearchOpen(true)}}>
                  <SearchIcon className={classes.searchIcon} />
                </IconButton>
                  
              </Grid>

              {searchOpen === true && 
                <Grid item xs={12} className={classes.searchForm}>
                  <TextField id="outlined-basic-email-search" label="" placeholder="Search" variant="outlined" fullWidth />
                  <CloseIcon className={classes.closeSearch} onClick={(e)=>{ setSearchOpen(false)}} />
                </Grid>
              }

              <Divider />

              <List className={classes.multilistmenu}>
                {
                  rooms && rooms.length ?
                  rooms.map(({ name, desc, icon }, i) => (
                    <ListItem  key={i} className={ i === selectedIndex ? classes.active_tab : ''} 
                      onClick={()=>{onSelectRole(i,name)}}
                    >
                      <ListItemIcon>
                        <Avatar>{getPartyLabel(name)} <span className={classes.online}>.</span></Avatar>
                      </ListItemIcon>
                      <ListItemText primary={name}  secondary={desc}>{name}</ListItemText>
                      <ListItemText secondary="" align="right">
                        <span className={classes.duration}>2d</span>
                        <DraftsIcon/>
                      </ListItemText>
                    </ListItem>
                  ))
                    :
                    <ListItem  key="RemySharp" className={classes.active_tab}>
                      <Avatar className={classes.emptyIcon}> <HourglassEmptyIcon /></Avatar>
                      <ListItemText 
                        primary="Empty" 
                        secondary="There is no rooms."
                      />
                    </ListItem> 
                }
              </List>
            </Grid>

            <Grid item xs={9} className={"messagengerScreen"}>
              <Grid item xs={12} className={classes.OpenChatHeader}>
                <List className={classes.messagetab}>
                  {rooms && rooms.length  && 
                    <ListItem button key="RemySharp">
                      <PeopleAltIcon className={"onlineUsersSwap"} onClick={()=>{setSlideToggle(!slideToggle)}}/>
                      <ListItemIcon className={classes.onlineTabBar}>
                        <Avatar><span className={classes.online}>.</span></Avatar>
                      </ListItemIcon>
                        <ListItemText primary={rooms[selectedIndex].name}  secondary={rooms[selectedIndex].desc}></ListItemText>
                        <ListItemText secondary="" align="right" className={classes.righticon}> 
                          <Avatar className={classes.iconBtn}>BM</Avatar> 
                          <Avatar  className={classes.iconBtn}>BM</Avatar> 
                          <Avatar className={classes.iconBtn}>BM</Avatar> 
                          <span className={classes.countplus}>+2 </span>
                          <MoreVertIcon className={classes.iconBtn}  onClick={handleClick}/>
                        </ListItemText>
                    </ListItem>
                  }
                  </List>
                  
                  <Popper open={open} anchorEl={anchorEl} className={classes.dropdownmenupoper}>
                    <ClickAwayListener onClickAway={handleCloseNot}>
                      <MenuList  className={classes.dropdownmenu} autoFocusItem={open} id="menu-list-grow" >
                        <MenuItem onClick={handClose}>Mark as Unread</MenuItem>
                        <MenuItem onClick={handClose}>Delete</MenuItem>
                        <MenuItem onClick={handClose}>Archive</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Popper>
              </Grid>

              <List className={classes.messageArea}>

                <MessageList room={room} lastMessage = {lastMessage} setLastMessage={setLastMessage}/>
              
              </List>
              
              <Grid container style={{padding: '20px'}}>
                <Grid item xs={12}>
                  <TextField id="outlined-basic-email" placeholder="Type Something" 
                    className={classes.messageBox} fullWidth 
                    onChange={(e)=>{setMessage(e.target.value)}} 
                    value={message}
                  />
                </Grid>
                <Grid item xs={12} align="right" className={classes.sendMessage}>
                  <AttachFileIcon />   
                  <Button size="small" className={classes.newSetupButton} variant="contained" onClick={sendMessage}>
                    Send
                  </Button>
                </Grid>
              </Grid>
          </Grid>
      </Grid>
    </div>
    </div>
  );

};