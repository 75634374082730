import React, { useState } from "react";
import { useStreamQueries, useParty } from "@daml/react";
import { Grid, Typography } from "@material-ui/core";
import { Invoice, InvoiceHistory } from "../../services/daml-modules1";
import ConfirmSimpleModal from "../../components/Modals/ConfirmSimpleModal";
import InvoiceListContracts from "./invoicelistcontracts1";
import useStyles from "./styles";
import useStyle1 from "../surgicalevent/styles";
import classNames from "classnames";


/**
 * Invoice List page in Vendor
 * @returns 
 */
export default function InvoiceList() {

  const classes = useStyles();
  const classes1 = useStyle1();
  const [invoiceDetailData, setInvoiceDetailData] = useState();
  const [invDatalist, setInvDatalist] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);


  const vendor = useParty();
  const { contracts, loading } = useStreamQueries(Invoice, () => [{ vendor }]);
  const { contracts: contractsHistory } = useStreamQueries(InvoiceHistory, () => [{ vendor }]);

  const [isHistory, setHistory] = useState(false);


  // handler to Open Create Purchase Order
  const handleOpenCreateInvoice = ({
    invoiceData = {}, invoiceDetail = [], seData = {}, isCreate = true, contractId = '', payload = {},
  }) => {
    // setPurchaseOrderData({ ...PoModel, ...invoiceData });
    setInvoiceDetailData(invoiceDetail);
    console.log("invoiceDetailData", invoiceDetailData);
    console.log("payload", payload);
    // setSurgicalEventData({ ...SurgicalEventModel, ...seData });
    // setCreate(isCreate);
    // setContractId(contractId);
    // setPayload(payload);
    // // open create PO modal
    // setOpenModal(true);
  };

  const createOptions = {
    handleOpenCreateInvoice,
  };


  return (
    <>

      <Grid>

        <Typography variant="h4" className={classNames(classes.pageTitle, classes1.newPageTitle)}>Invoice</Typography>

        <InvoiceListContracts
          allContracts={contracts}
          historyContracts={contractsHistory}
          isIncludeHistory={isHistory}
          setArchived={setHistory}
          loading={loading}
          createOptions={createOptions}
        />

        <ConfirmSimpleModal
          modaltitle="Sent Invoice"
          contractList={invDatalist}
          setContractList={setInvDatalist}
          textFunction={(d) => `Total Count: ${d}`}
          mapFunction={(c) => `${c["index"]["result"]} : ${c["index"]["_id"]} (${c["index"]["_index"]})`}
          confirmDialogOpen={confirmDialogOpen}
          setConfirmDialogOpen={setConfirmDialogOpen}
        />

      </Grid>
    </>
  );
}