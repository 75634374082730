import React from "react";
import classNames from "classnames";
import {
  Grid, Card, CardContent, Button,Paper,Chip,
  Table, TableBody,TableRow, TableCell, DialogContentText, Drawer, Typography,
} from "@material-ui/core";
import printerIcon from '../../icons/printer.svg';
import { useDamlState } from "../../context/DamlContext";
import { formatDate } from "../../services/util";
import deleteIcon from "../../icons/delete.svg";
import closeIcon from "../../icons/new-close-icon.svg";
import useStyles2 from "../surgicalevent/styles";
import useStyles from "../invoice/styles";

/**
 * Detail View Slide Drawer For Invoice
 * @param {Boolean} state
 * @param {Function} toggleDrawer
 * @param {Object} invoice
 * @param {Object} vendortemplate
 */
const InvoicePopUp = ({
  state, toggleDrawer, invoice = {}, vendortemplate = null, setInvoiceDetailViewer
}) => {
  const classes2 = useStyles2();
  const classes = useStyles();

  const { rolePayload } = useDamlState();

  const { hospital, vendor, invoicedata, invoicedetail, payeeaddress, purchaseordercomment } = invoice;
  const { hospitaldetails } = (rolePayload ? rolePayload : {});

  // handle to download detail of invoice
  // const handleDownload = () => { };

  return (
    <React.Fragment key={'right'} >
      <Drawer className={classNames(classes2.customRoot, classes.customRootInvoice, classes.customRootInvoice2, classes2.mainCompletePanel)}
        anchor={'right'} open={state} onClose={() => { toggleDrawer(false) }}>

        <DialogContentText component={'div'} className="modelStepDetai">
          <div className="view-heading3">
            <Button
              className={classes2.closeButton}
              onClick={() => setInvoiceDetailViewer(false)}
            >
              <img src={closeIcon} alt="" />
            </Button>
          </div>
          <Grid container
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={0}
          >
            <Grid item md={12} sm={12} className="left-panel">

              <div className="view-heading4">
                Invoice number: <span className={classes.skipUnderline}>{invoicedata && invoicedata.invinvoicenumber}</span>

                <Button
                  className={classes2.printButton2}
                // onClick={handleDownload}
                >
                  <img src={printerIcon} alt="" />
                </Button>
                <Button
                  className={classes2.printButton2}
                // onClick={handleDownload}
                >
                  <img src={deleteIcon} alt="" />
                </Button>
              </div>

              <Grid className="left-card">

                <Card>
                  <CardContent>
                    <Grid container spacing={3}>

                      <Grid item xs={2} sm={4} md={4} className={(classes2.rightBorder)}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.tabletitle)}>Hospital:</TableCell>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.userInputs)}>
                                {hospital}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.userInputs)}>
                                {hospitaldetails ? hospitaldetails.physicaladdress?.address1 : null}
                              </TableCell>
                            </TableRow>
                            {/* <TableRow>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.userInputs)}>
                                {hospitaldetails ? hospitaldetails.billingaddress.address2 : null}
                              </TableCell>
                            </TableRow> */}
                            <TableRow>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.userInputs)}>
                                {hospitaldetails ? `${hospitaldetails.physicaladdress?.city} ${hospitaldetails.physicaladdress?.state} ${hospitaldetails.physicaladdress?.zipcode}` : null}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>

                      <Grid item xs={2} sm={4} md={4} className={(classes2.rightBorder)}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.tabletitle)}>Vendor:</TableCell>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.userInputs)}>
                                {vendor}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.userInputs)}>{payeeaddress ? payeeaddress.address1 : null}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.userInputs)}>{payeeaddress ? payeeaddress.address2 : null}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.userInputs)}>{payeeaddress ? `${payeeaddress.city} ${payeeaddress.state} ${payeeaddress.zipcode}` : null}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>

                      <Grid item xs={2} sm={4} md={4}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.tabletitle2)}>Date:</TableCell>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.userInputs)}>
                                {formatDate(invoicedata && invoicedata.invinvoicedate)}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.tabletitle2)}>Status:</TableCell>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.userInputs)}>
                                <div className="value">
                                  {invoice.status && (
                                    <Chip
                                      // label={selectedInvoiceData.is_sent_hospital === true ? "Sent Invoice" : "Created Invoice"}
                                      label={invoice.status}
                                      size="small"
                                      className={classNames(classes2.smallChip, (
                                        invoice.status === 'Ready for payment' ? classes2.backBlue : (
                                          invoice.status === 'Sent' ? classes2.backGreen : (
                                            invoice.status === 'Complete' ? classes2.backGreen : (
                                              invoice.status === 'Available for payment' ? classes2.backBlue : (
                                                invoice.status === 'Cancelled' ? classes2.backOrange : classes2.backYellow)
                                            )
                                          )
                                        )))}
                                    />)}
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.tabletitle2)}>Buyer:</TableCell>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.userInputs)}>
                                {vendortemplate && vendortemplate.api_payload.buyer}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.tabletitle2)}>Type:</TableCell>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.userInputs)}>
                                {invoice && invoice.payemnttype}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>

                    </Grid>
                  </CardContent>
                </Card>

              </Grid>

              <Grid className="left-card">

                <Card>
                  <CardContent>
                    <Grid container spacing={3}>

                      <Grid item xs={2} sm={4} md={4} className={classes2.rightBorder}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.tabletitle)}>Ship To:</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.userInputs)}>{hospitaldetails && hospitaldetails.shippingaddress[0]?.address1}
                              </TableCell>
                            </TableRow>
                            {/* <TableRow>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.userInputs)}>{hospitaldetails && hospitaldetails.shippingaddress[0].address2}
                              </TableCell>
                            </TableRow> */}
                            <TableRow>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.userInputs)}>{hospitaldetails && hospitaldetails.shippingaddress[0]?.city} {hospitaldetails && hospitaldetails.shippingaddress[0]?.location}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.userInputs)}>{hospitaldetails && hospitaldetails.shippingaddress[0]?.state} {hospitaldetails && hospitaldetails.shippingaddress[0]?.zipcode}
                              </TableCell>
                            </TableRow>

                          </TableBody>
                        </Table>
                      </Grid>

                      <Grid item xs={2} sm={4} md={4} className={(classes2.rightBorder)}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.tabletitle)}>Invoice To:</TableCell>
                              <TableCell className={classes2.cellWithoutBorderPadding}>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.userInputs)}>{invoice.operator}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.userInputs)}>{hospital}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes2.cellWithoutBorderPadding}></TableCell>
                              <TableCell className={classes2.cellWithoutBorderPadding}>
                                {hospitaldetails && hospitaldetails.billinfocontact.length ? `${hospitaldetails.billinfocontact[0].fax}` : null}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.tabletitle)}>Contract ID:</TableCell>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.userInputs)}></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.userInputs)}>
                                {/* enter contractId here */}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>

                      <Grid item xs={2} sm={4} md={4}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.tabletitle)}>Terms:</TableCell>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.tabletitle)}>FOB Contact:</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes2.cellWithoutBorderPadding}>
                                {vendortemplate && vendortemplate.api_payload.paymentTerms}
                              </TableCell>
                              <TableCell className={classes2.cellWithoutBorderPadding}>
                                {hospitaldetails && hospitaldetails.billingaddress.location}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={(classes2.cellWithoutBorderPadding, classes2.cellwithpaddings)} colSpan={2}>
                                MA EXEMPT PURCH CERT #: {/* Now, blank */}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>

                    </Grid>
                  </CardContent>
                </Card>

              </Grid>

              <Grid className="left-card">

                <Card>
                  <CardContent className={classes.paddingZero}>
                    <Grid container
                      className={classes.gridContainer}
                      // direction="row"
                      // justifyContent="space-between"
                      // alignItems="stretch"
                      spacing={0}>
                      <Grid container className={classes.gridBottomBorder}>
                        <Grid item md={1} sm={1} className={classNames(classes.gridHead,classes.gridSeriesWidth2)}>Line</Grid>
                        <Grid item md={2} sm={1} className={classes.gridHead}>Item</Grid>
                        {/* <Grid item md={1} sm={1} className={classes.gridHead}></Grid> */}
                        <Grid item md={3} sm={1} className={classes.gridHead}>Description</Grid>
                        <Grid item md={1} sm={1} className={classNames(classes.gridHead,classes.mleft2)}>Manufacturer</Grid>
                        <Grid item md={1} sm={1} className={classes.gridHead}>Quantity</Grid>
                        <Grid item md={1} sm={1} className={classes.gridHead}>Price</Grid>
                        <Grid item md={1} sm={1} className={classes.gridHead}>EXT Cost</Grid>
                        <Grid item md={1} sm={1} className={classes.gridHead}>DEPT or INVEN</Grid>
                        <Grid item md={1} sm={1} className={classNames(classes.gridHead,classes.gridCellAlign)}>G/L Account</Grid>
                      </Grid>
                      <Grid container
                        className={classes.gridColumn}
                      >

                        {/* <Table> */}
                        <Grid className={classes.scrollit} item md={12} sm={12}  >
                          {
                            (invoicedetail && invoicedetail.length > 0)
                              ?
                              invoicedetail.map((invDetail, index) => (
                                // <TableRow>
                                //   <TableCell colspan='12'>
                                <Grid container style={{ height: "30px" }} key={index}>
                                  <Grid item md={1} sm={1} className={classNames(classes.gridCellCenter,classes.gridSeriesWidth1 ,classes.gridSeriesWidth2)}>{index + 1}</Grid>
                                  <Grid item md={2} sm={1} className={classNames(classes.gridCell,classes.gridMinWidth,classes.hideOverflow)}>{invDetail.invdproductitem}</Grid>
                                  {/* <Grid item md={1} sm={1} className={classes.gridCellCenter}>Now, blank</Grid> */}
                                  <Grid item md={3} sm={1} className={classNames(classes.gridCell,classes.gridWidth,classes.hideOverflow)}>{invDetail.invddescription}</Grid>
                                  <Grid item md={1} sm={1} className={classes.gridCellCenter}>{invDetail.invdproduct.sepproductmanufacturer}</Grid>
                                  <Grid item md={1} sm={1} className={classes.gridCellCenter}>{invDetail.invdproductquantity}</Grid>
                                  <Grid item md={1} sm={1} className={classes.gridCellCenter}>${invDetail.invdproductprice}</Grid>
                                  <Grid item md={1} sm={1} className={classes.gridCellCenter}>${invDetail.invdtotalcost}</Grid>
                                  <Grid item md={1} sm={1} className={classes.gridCellCenter}>{/* Now, blank */}</Grid>
                                  <Grid item md={1} sm={1} className={classes.gridCellCenter}>${invDetail.invdtotalcost}</Grid>
                                </Grid>
                                //   </TableCell>
                                // </TableRow>
                                // <TableRow key={index} classsName={classes2.tablecelldata}>
                              ))
                              :
                              null
                          }
                        </Grid>
                        {/* </Table> */}
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="center" className={classes.gridTopBorder}>
                      <Grid item container direction="column" xs={12} sm={8} className={classes2.rightBorder}>
                        <Grid item style={{ height: "30px" }} >
                          <Typography
                            className={classes.gridFooterDetails1}
                          >Comments :</Typography>
                        </Grid>
                        <Grid item >
                          <Paper style={{ height: "130px" }}>
                            <Typography
                              className={classes.gridFooterComments}>
                              {purchaseordercomment && purchaseordercomment[0]?.note}
                            </Typography>
                          </Paper>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Paper className={classes.gridFooterDetails} style={{ height: "160px", }}>
                          <span>Total :</span>
                          <span className={classes.amountInvoice}>${invoicedata && invoicedata.invinvoicetotalamount}</span>
                        </Paper>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

        </DialogContentText>
      </Drawer>
    </React.Fragment >
  );
}

export default InvoicePopUp;