import React from "react";
import { Button, IconButton, } from "@material-ui/core";
import { Add } from "@material-ui/icons";


export default function MultipleButton({
  multipleButtontitle = null,
  conductdetail,
  setConductForm,
  conductList,
  setConductList,
  defaultConduct = {},
  addConduct = null,
  validateConduct = null,
  ...props }) {

  const addNewConductDetail = (
    typeof addConduct === "function"
    ?
    addConduct
    :
    (newConductdetail) => {
      if (!validateConduct(newConductdetail)) return;

      const tempDatas = conductList;
      tempDatas.push(newConductdetail);
      // console.log("[MultipleButton] addNewConductdetail", tempDatas);

      if (typeof setConductList === "function") setConductList(tempDatas);

      if (typeof setConductForm === "function") setConductForm(defaultConduct);
    }
  );

  return (
    multipleButtontitle
    ?
    <Button
      color="primary"
      onClick={() => {
        addNewConductDetail(conductdetail);
      }}
      endIcon={<Add />}
      {...props}
    >
      {multipleButtontitle}
    </Button>
    :
    <IconButton
      color="primary"
      onClick={() => {
        addNewConductDetail(conductdetail);
      }}
      {...props}
    >
      <Add />
    </IconButton>
  );
}