import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  customRoot:{
    '& .MuiDialog-paperWidthSm':{
      minWidth:700,
    }
   
  },
  formHeading:{
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '23px',
    color: 'rgba(0, 0, 0, 0.87)',
    borderBottom: '1px solid #CCCCCC',
    padding:'16px  30px',
    marginTop:0,
    marginBottom:10,
  },
  AddEditMessageForm:{
    '& .headerForm':{
      padding: '0px 30px'
    }
    
  },
  GroupsearchForm:{
    position:'relative',
    '& .MuiOutlinedInput-input':{
      padding:'13px 14px',
      background: '#F4F6F8'
    }
  },
  searchIconForm:{
    color: 'rgba(0, 0, 0, 0.12)',
    position: 'absolute',
    right: '10px',
    top: '24px',
    zIndex:99,
    cursor:'pointer'
  },
  formFooter:{
    borderTop: '1px solid #CCCCCC;',
    padding: '15px 20px;',
    textAlign: 'right;',
    '& .noBtn':{
      color: '#183C73',
      paddingRight:20,
      fontWeight: 500,
      fontSize: '16px',
      textTransform:'initial',
    },
    '& .yesBtn':{
      background: '#183C73',
      color:'#fff',
      textTransform:'initial',
    }
  },
  customCheckbox:{
  
      color:'#183C73',
    '& .MuiSvgIcon-root':{
      color:'#183C73'
    }
  },
  sendMessage:{
    '& .MuiSvgIcon-root':{
      color: '#183C73',
      margin:'5px 14px -1px'
    }
  },
  userList:{
    overflow:'auto',
    maxHeight:'calc(70vh - 120px)',
    padding:'0 5px 0 13px',
    '& .makeStyles-online-82':{
       display:'none',
    },
  },
  pageTitle: {
    display: 'inline-block',
    marginBottom: '15px',
    fontSize:'20px',
    fontWeight:'500',
    lineHeight: '23px',
    marginLeft:'15px',
  },
  newSetupButton: {
    padding:'6px 15px',
    color: '#ffffff',
    backgroundColor: '#183C73',
    float: 'right',
    marginBottom:16,
    '&:hover': {
      backgroundColor: '#183c73d9',
    },
    '&.MuiButton-root': {
      textTransform: 'capitalize',
      fontWeight: '500',
    },
    '@media (max-width:1024px)':{
      fontSize:'0px',
      minWidth:'36px',
      padding:'6px',
    },
    '& .desktop':{
      
      '@media (max-width:1024px)':{
        display:"none",
      },
    },
    '& .mobile':{
      display:"none",
      '@media (max-width:1024px)':{
        display:"block",
      },
    },
  },
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: '100%',
    height: 'auto',
    '& .messagengerScreen':{
      '@media (max-width:1024px)':{
        maxWidth: '100%;',
        flexBasis: '100%;'
      },
    },
    '& .borderRight500': {
      borderRight: '1px solid #e0e0e0',
      position: 'relative',
      '@media (max-width:1024px)':{
        display:'none',
        position: 'absolute;',
        maxWidth: '100%;',
        flexBasis: '100%;',
        zIndex: '999;',
        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
        background: '#fff;',
        height: '85vh;',
        overflow: 'scroll;',
        transition: '1s;',
        left: 0,
        animation: 'slide 0.5s forwards;',
       animationDelay: '2s;'
      }
  },
  '& .borderRight500.open': {
    display:'block'
  },
  
  '& .onlineUsersOpen':{
    position: 'absolute;',
    right:'0px;',
    top: '0px;',
    zIndex: '999;',
    width: '27px;',
    background: '#183c73;',
    color: '#fff;',
    height: '27px;',
    padding: '3px 4px;',
    '@media (min-width:1024px)':{
      display:'none'
    }

  }
    
  },
  searchFilter:{
    width:'100%',
    height:'56px',
    padding:'8px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiInput-underline:before':{
      display:'none',
    },
    '& .MuiInput-underline':{
      fontSize:'16px',
      fontWeight:'400',
      lineHeight: '19px',
      color:'rgba(0, 0, 0, 0.87);',
    },
    '& .MuiIconButton-root':{
      padding: '5px',
      float: 'right',
      color: '#183C73',
    },
    '& .MuiPopover-paper':{
        background:'#FFFFFF',
        boxShadow:'0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
        borderRadius:'4px',
    },
    '& .MuiMenu-list':{
      width:'180px',
    },
    '& .Mui-selected':{
      background:'#DEF3FB',
    }
  },
 
  headBG: {
      backgroundColor: '#e0e0e0'
  },
 
  searchForm:{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      background: '#ffffff',
      zIndex: 99,
      '& .MuiOutlinedInput-input':{
        paddingRight: '48px'
      },
      '& .MuiOutlinedInput-notchedOutline':{
        border: 'none',
        borderRadius: 0,
      },
      
  },
  closeSearch:{
    position: 'absolute',
    top: '16px',
    right: '16px',
  },
  iconBtn:{
    display:'inline-block'
  },
  online:{
    background: '#4CAF50',
    border: '2px solid #FFFFFF',
    position:'absolute',
    bottom:'-3px',
    borderRadius:'50%',
    width: '15px',
    height: '15px',
    right:'0px',
    
  },
  multilistmenu:{
    paddingTop:'0px',
    paddingBottom:'0px',
    '& .MuiListItem-gutters':{
      height:'72px',
      cursor:'pointer'
      
    },
    '& .MuiAvatar-colorDefault':{
      background:'#0EC4D5',
      overflow:'inherit',
      '& .makeStyles-online-73':{
        border:'2px solid #FFFFFF',
        background:'#4CAF50',
        position:'absolute',
        bottom:'-3px',
        borderRadius:'50%',
        width: '15px',
        height: '15px',
        right:'0px',
      }
      

    },
     
      '& .MuiTypography-body1':{
        
        fontStyle:' normal',
        fontWeight:'500',
        fontSize:'14px',
        lineHeight:'16px',
        color:'rgba(0, 0, 0, 0.87)',
     },
     '& .MuiTypography-body2':{
      fontStyle: 'normal',
      fontWeight:'normal',
      fontSize:'14px',
      lineHeight:'16px',
      color:'rgba(0, 0, 0, 0.87)',
      paddingTop:'6px',
      textOverflow:'ellipsis',
      overflow:'hidden',
      whiteSpace:'nowrap',
     },
     '& .MuiListItemText-primary span':{
      fontStyle:'normal',
      fontWeight:'normal',
      fontSize:'14px',
      lineHeight:'16px',
      color:'rgba(0, 0, 0, 0.54)',
     },
     '& .MuiSvgIcon-root':{
      fill:'rgba(0, 0, 0, 0.38)',
      marginTop: '10px',
      display:'block',
     }

    
  },
   offline:{
    border:'2px solid #FFFFFF',
    background:'#828282',
    position:'absolute',
    bottom:'-3px',
    borderRadius:'50%',
    width: '15px',
    height: '15px',
    right:'0px',
  },
  active_tab:{
    background:'#DEF3FB !important',
  },
  messagetab:{
    padding:'0px 10px',
    borderBottom:'1px solid #ccc',
    margin:'0px 0px',
    '@media (max-width:1024px)':{
        margin:'0px',
        padding:'0px',
    },
    '& .MuiListItemIcon-root':{
      minWidth:'0px', 
    },
    '& .MuiAvatar-root':{
      width:'15px',
      height:'15px',
      marginTop:'-18px',
      marginRight:'10px',
    },
    '& .MuiListItem-gutters':{
      padding:'0px',
  },
    '& .makeStyles-online-73':{
      border:'2px solid #FFFFFF',
    background:'#4CAF50',
    borderRadius:'50%',
    width: '15px',
    height: '15px',
    right:'0px',
    },
    '& .MuiTypography-body2':{
      marginLeft:'-20px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight:'16px',
      marginBottom: '4px',
  },
  '& .MuiTypography-body1':{
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight:'21px',
    marginBottom:'2px',
    color:'rgba(0, 0, 0, 0.87);',
},
'& .makeStyles-online-72':{
  bottom:0,
}
,
  '& .onlineUsersSwap':{
    padding: '5px 0px 5px 0px;',
    width: '33px;',
    margin: '-4px -9px 0 5px;',
    cursor: 'pointer;',
    background: '#183c73;',
    color: '#fff;',
    height: '33px;',
    display: 'none;',
      
    '@media (max-width:1024px)':{
      display:"block",
    },
  }
  },
  '& .makeStyles-content-2':{
background:'#fff',
  },
  righticon:{
    '& .MuiTypography-displayBlock':{
      color: '#183C73',
    fontSize:'14px',
    lineHeight:'18px',
    fontWeight:'500',
    position:'relative',
    },
    '& .MuiAvatar-colorDefault:first-child':{
      right:50,
      background:'#2196F3',
    },
    '& .makeStyles-iconBtn-81:nth-child(2)':{
      right:60,
      background:'#F7CD37',
    },
    '& .MuiAvatar-circular':{
      position: 'absolute',
      right: 70,
      top: 14,
      border:'1px solid #FFFFFF',
      background:'#34c4d5',
    },
    '& .MuiAvatar-root':{
      width: '30px',
      height: '30px',
      marginRight:'10px',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight:'18px',
      padding:'6px 4px',
      marginBottom:'-4px',
      
  },
  '& .iconBtn':{
marginLeft:'10px',
  },
  '& .iconBtn2':{
marginLeft:'10px',
  },
  },
  messageArea:{
    height: 'calc(70vh - 70px)',
    overflowY: 'auto',
    padding:'10px',
  '& .MuiAvatar-colorDefault':{
    background:'#0EC4D5',
    float: 'left',
    marginRight:'14px',
    marginTop:'6px',
   '& .makeStyles-online-73':{
     display:'none',
   }
  },
  '& .MuiListItemText-multiline':{
    display:'flex',
    marginBottom:'0px',
    '& .MuiTypography-body2':{
      fontStyle:'normal',
      fontWeight:'normal',
      fontSize:'14px',
      lineHeight:'16px',
      color:'rgba(0, 0, 0, 0.38)',
      marginTop:'4px',
      marginLeft:'10px',
  },
  },
  

  },
  chatList:{
    position:'relative',
    '& .makeStyles-online-83':{
      display:'none',
    },
    '& .MuiAvatar-colorDefault':{
      background:'#0EC4D5',
    },
  },
  countplus:{
       position:'relative',
       top:'-8px',
  },
  contentShift:{
    background:'#fff',
  },
  
  dropdownmenupoper:{
    background: '#FFFFFF',
    boxShadow: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    '& .MuiListItem-root:hover':{
      background: '#DEF3FB'
    }
  },
  messageBox:{
    background: '#FFFFFF',
/* Input-border-color */
    border: '1px solid rgba(0, 0, 0, 0.12)',
    boxSizing: 'border-box',
    borderRadius: '4px',
    padding: '20px 10px',
    marginBottom: '15px',
    '& .MuiInput-underline:before':{
      border:0
    },
    '& .MuiInput-underline:after':{
      border:0
    },
    '& .MuiInput-underline:hover':{
      border:0
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before':{
      border:0,
    },
  },
  onlineTabBar:{
    '& .MuiAvatar-colorDefault':{
      background:'transparent',
      marginLeft: 16,
      marginRight: 8,
    },
    '& .makeStyles-online-83':{
      top: 0,
    },
   },
   MessageInbox:{
     '& .MuiTypography-h4':{
       marginLeft:0,
     },
     '& .MuiListItemText-multiline':{
       marginTop:8,
       marginBottom:8,
     },
     '& .MuiListItem-root.Mui-focusVisible':{
       background:'#fff',
     },
   },
   customFullWidth:{
    width:'100%',
    '& .MuiOutlinedInput-input':{
      padding:'10px 12px',
  
    lineHeight:40,
    color:'#000',
    },
    '& .MuiSvgIcon-root':{
      top: 25,
    },
  },
  selectedAvatar:{
    borderBottom:'1px solid #CCCCCC',
    paddingBottom:16,
    paddingTop:16,
   '& .MuiAvatar-colorDefault':{
    background:'#34c4d5',
    position:'relative',
    overflow:'visible',
   },
   '& span':{
    position:'absolute',
    width:'15px',
    height:'15px',
    background:'#183C73',
    fontSize:'13px',
    textAlign:'center',
    borderRadius:'50%',
    top:'1px',
    right:'-4px',
    fontWeight:'bold',
   },
  },
  emptyIcon:{
    marginRight:15,
    '& svg.MuiSvgIcon-root': {
      color: '#fff',
      margin: 0,
      fill:'#fff'
     }
  }
}));
