import React, { useEffect, useState, useCallback } from "react";
import classNames from "classnames";
import { useLedger } from "@daml/react";
import { Grid, Typography, AppBar, Tabs, Tab, Button } from "@material-ui/core";
import { getPartialReceivedInventoryData } from "../../models/InventoryData";
import { damlFetchPO, damlFetchPOV } from "../../services/axios/daml-json-api";
import { useDamlState } from "../../context/DamlContext";
import { useUserState } from "../../context/UserContext";
import { useLayoutDispatch, setLoading, unsetLoading } from "../../context/LayoutContext";
import { EVENT_FINISHED, INVENTORY_NOTE_EVENT, EVENT_REQUIRED } from "../../store/actions/constants";
import useEvent from "../../hooks/useEvent";
import useInventory from "../../hooks/useInventory";
import useProduct, { useSurgicalEvent, useItemDetails } from "../../hooks/useProduct";
import usePO from "../../hooks/usePO";
import TabPanel, { a11yProps } from "../../components/Modals/MultiTabsModals/TabPanel";
import ModalDialog from '../../components/Modals/NewGeneralModal';
import IncomingInventoryDetail from "./incoming-inventory-detail";
import { searchRestock } from "./confirm-inventory";
import { updateRestockNotesHospital, updateRestockNotesVendor } from "./handle";
import CompleteSurgicalEvent from "../surgicalevent/complete-surgicalevent1";
import { POPanel } from "../purchaseorder/create-purchaseorder1";
import { searchSE } from "../purchaseorder/se-details-modal";
import useStyles from "../surgicalevent/styles";
import useStyles2 from "../purchaseorder/styles";
import useStyles1 from "../inventoryaccount/styles";



const IncomingInventoryTitle = ({
  value, setValue, poDetails, seDetails, title, 
}) => {
  
  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();

  const { role } = useDamlState();
  const { restockInventoryData } = useInventory();
  const { setDetails } = useItemDetails();
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 1) {
      setDetails(poDetails);
    }
    else if (newValue === 2) {
      setDetails(seDetails);
    }
  };

  return (
    role === 'Hospital'
    ?
    <AppBar position="static" className={classNames(classes.tabPanelMenu, classes1.detailTabPanelMenu)}>
      <Tabs indicatorColor="primary" textColor="primary" value={value} onChange={handleChange} 
        aria-label="simple tabs example" centered variant="fullWidth"
      >
        <Tab label="Incoming Inventory" {...a11yProps(0)} />
        <Tab label="PO Details" {...a11yProps(1)} />
        {
          restockInventoryData.poseid && <Tab label="SEID Usage" {...a11yProps(2)} />
        }
      </Tabs>
    </AppBar>
    :
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      className={classNames(classes.newSETitlePanel, classes2.newPOTitlePanel)}
    >
      <Typography variant="inherit" component="span" className="label" >
        {title}
      </Typography>
    </Grid>
  );
};

/**
 * Dialog of Incoming Inventory / Outgoing Inventory
 * @param {Boolean} open
 * @param {Function} setOpen
 * @param {Object} contract
 * @param {Array} contracts
 * @param {String} title
 * @param {Object} options 
 */
export default function IncomingInventory({
  open, setOpen, contract={}, contracts=[], title="Incoming Inventory", options={}, 
}) {

  options = {
    isNotes: true,            // Visible Notes
    isNotesDisabled: false,   // Editable Notes
    btnTitle: "Save",         // Button Title
    isCancel: true,           // Display Cancel button
    ...options,
  };

  const classes = useStyles();

  const layoutDispatch = useLayoutDispatch();
  const { role, roleCid, party } = useDamlState();
  const hospital = (role === 'Hospital' ? party : null);
  const vendor = (role === 'Vendor' ? party : null);

  const { token } = useUserState();

  const ledger = useLedger();

  const { event, status, onEventStart, onEventRun, onEventEnd } = useEvent();
  const { restockInventoryData, setRestockInventoryData } = useInventory();
  const { setAll: setDefault } = useProduct();
  const { setSurgicalEvent } = useSurgicalEvent();
  const { setAll: setDefaultPO, setPurchaseOrder } = usePO();

  const [value, setValue] = useState(0);
  const [inventorydata, setInventorydata] = useState([]);
  const [seDetails, setSEDetails] = useState([]);
  const [poDetails, setPODetails] = useState([]);
  // contractId of Restock Inventory
  const [ContractId, setContractId] = useState(null);

  const popurchaseorderid = contract.payload?.ipurchaseorderdata?.popurchaseorderid;
  // console.log("[IncomingInventory]", contract);



  // handle to click "Save" button
  const handleSaveClick = () => {
    // check out if ponotes is changed or not
    if (contract.payload?.ipurchaseorderdata?.ponotes !== restockInventoryData.ponotes) onEventStart(INVENTORY_NOTE_EVENT);
    else setOpen(false);
  };

  // Handle click of "Cancel" button and  callback if any
  const handleCancelClick = () => {
    setOpen(false);
  };

  // Update Contract
  const updateContract = useCallback(() => {
    // find out the Restock Inventory contract by PO number
    const found = searchRestock({
      ponumber: popurchaseorderid, contracts
    });
    console.log("[IncomingInventory] updateContract", found);
    if (found) {
      const newinventorydata = getPartialReceivedInventoryData(found.payload?.inventorydata.map((inv, index) => ({
        ...inv, 
        index,
        contractId: found.contractId,
        vendor: found.payload.vendor,
        hospital: found.payload.hospital,
      })), {isVendor: (role === 'Vendor')});
      setInventorydata(newinventorydata);
    }
  }, [contracts, popurchaseorderid, role]);

  // handler to update Restock Inventory Notes
  const handleUpdateNotes = useCallback(async () => {
    // console.log("[handleUpdateNotes]", restockInventoryData);
    if (!ContractId) return;

    // spinner
    setLoading(layoutDispatch);
    onEventRun();

    if (role === 'Hospital') {
      await updateRestockNotesHospital({
        ledger, roleCid, inventorycid: ContractId, newnotes: restockInventoryData.ponotes, 
      });
    }
    else if (role === 'Vendor') {
      await updateRestockNotesVendor({
        ledger, roleCid, inventorycid: ContractId, newnotes: restockInventoryData.ponotes,
      });
    }

    unsetLoading(layoutDispatch);
    onEventEnd();
    
    setOpen(false);
  }, [layoutDispatch, onEventRun, onEventEnd, ContractId, ledger, role, roleCid, restockInventoryData, setOpen]);

  const searchSEPO = useCallback(async () => {
    // poseid of purchaseOrderData as null
    if (contract.payload?.ipurchaseorderdata.poseid) {
      const secontract = await searchSE({
        seid: contract.payload?.ipurchaseorderdata.poseid,
        token,
        hospital,
        vendor,
      });
      if (secontract && secontract.payload) {
        setSurgicalEvent(secontract.payload.surgicaleventdata);
        setSEDetails(secontract.payload.surgicaleventproductdata);
      }
    }
    if (contract.payload?.ipurchaseorderdata.popurchaseorderid) {
      const pocontract = await searchPO({
        popurchaseorderid: contract.payload?.ipurchaseorderdata.popurchaseorderid,
        token,
        hospital,
        vendor,
      });
      if (pocontract && pocontract.payload) {
        setPurchaseOrder(pocontract.payload.purchaseorderdata);
        setPODetails(pocontract.payload.purchaseorderdetaildata);
      }
    }
  }, [token, hospital, vendor, contract, setSurgicalEvent, setPurchaseOrder]);


  useEffect(() => {
    console.log("[IncomingInventory] set default data.");
    if (!open) return;
    if (contract.payload) {
      setRestockInventoryData(contract.payload?.ipurchaseorderdata);
      setInventorydata(getPartialReceivedInventoryData(contract.payload?.inventorydata, {isVendor: (role === 'Vendor')}));
      setContractId(contract.contractId);
    }
    else {
      setRestockInventoryData({});
      setInventorydata([]);
      setContractId(null);
    }
    setValue(0);
    // Set PO, SE
    setDefault({
      surgicalevent: {},
      details: [],
      hospital,
    });
    setDefaultPO({
      purchaseOrderData: {},
      comments: [],
      comment: {},
      vendor,
    });
    searchSEPO();
    // eslint-disable-next-line
  }, [open, contract]);

  useEffect(() => {
    if (!open) return;
    if (event) {
      console.log("[IncomingInventory] On Start Event", event);
      if (event === INVENTORY_NOTE_EVENT && status === EVENT_REQUIRED) {
        handleUpdateNotes();
      }
      else if (status === EVENT_FINISHED) {
        updateContract();
      }
    }
  }, [open, event, status, updateContract, handleUpdateNotes]);


  return (
    <>

      <ModalDialog
        open={open}
        setOpen={setOpen}
        title={(
          <IncomingInventoryTitle 
            value={value}
            setValue={setValue}
            poDetails={poDetails}
            seDetails={seDetails}
            title={title}
          />
        )}
        content={(
          <>
            <TabPanel value={value} index={0} className={classes.tabPanelbody}>
              <IncomingInventoryDetail
                inventorydata={inventorydata}
                options={options}
              />
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tabPanelbody}>
              <POPanel
                options={{
                  isEditable: false,
                  isUpload: false,
                  isPONumber: true,
                  showSE: false,
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={2} className={classes.tabPanelbody}>
              <CompleteSurgicalEvent
                options={{
                  isEditable: false,
                }}
              />
            </TabPanel>
          </>
        )}
        actions={(<>
          {
            options.isCancel &&
            <Button
              variant="contained"
              color="primary"
              onClick={handleCancelClick}
              className={classNames(classes.newCompleteBtn, "secondary-color")}
            >
              Cancel
            </Button>
          }
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleSaveClick} 
            className={classNames(classes.newCompleteBtn)} 
          >
            {options.btnTitle}
          </Button>
          </>
        )}
      />

    </>
  );
}

/**
 * Search Purchase Order with PO Number
 * @param {String} token 
 * @param {String} popurchaseorderid 
 * @param {String|null} hospital 
 * @param {String|null} vendor 
 * @returns {Object}
 */
export const searchPO = async ({ 
  token, popurchaseorderid, hospital, vendor, 
}) => {
  const query = { popurchaseorderid, token };
  if (vendor) {
    query.vendor = vendor;
    return await damlFetchPOV(query);
  }
  else {
    query.hospital = hospital;
    return await damlFetchPO(query);
  }
};
