import { getArray, requiredFields, validateModelItem, validateText, validateDecimal } from "./handles";

export const TimeLine = {
  Hours24: '24 hours',
  Hours48: '48 hours',
  Hours72: '72 hours',
  Days7: '7 days',
};

export const validateTimeLine = validateModelItem(TimeLine);

export const ContractDetailDataModel = {
  id: '',
  contractmanagementnumber: '',
  contractdateexecuted: '',
  contractexpirationdate: '',
  description: '',
  contactname: '',
  contactemail: '',
  contacttitle: '',
  contactphone: '',
  contractuploadpath: [],
};

export const validateContractDetailData = (cdata) => {
  return (cdata.contractmanagementnumber !== "");
};

export const getContractDetailData = (cdata) => {
  let newcdata = {};
  if (validateText(cdata.id === "string")) newcdata.id = cdata.id;
  newcdata.contractmanagementnumber = cdata.contractmanagementnumber || "";
  if (validateText(cdata.contractdateexecuted)) newcdata.contractdateexecuted = cdata.contractdateexecuted;
  if (validateText(cdata.contractexpirationdate)) newcdata.contractexpirationdate = cdata.contractexpirationdate;
  if (validateText(cdata.description)) newcdata.description = cdata.description;
  if (validateText(cdata.contactname)) newcdata.contactname = cdata.contactname;
  if (validateText(cdata.contactemail)) newcdata.contactemail = cdata.contactemail;
  if (validateText(cdata.contacttitle)) newcdata.contacttitle = cdata.contacttitle;
  if (validateText(cdata.contactphone)) newcdata.contactphone = cdata.contactphone;
  newcdata.contractuploadpath = getArray(cdata.contractuploadpath, validateText);
  return newcdata;
};

export const requiredContractDetailData = (cdata) => requiredFields(cdata, {
  'contractmanagementnumber': 'Contract Number', 
});

export const ServiceTermData = {
  id: '',
  servicetermshippingfee: '',
  servicetermfreightfee: '',
  servicetermtimeline: '',
};

export const validateServiceTermData = (cdata) => {
  return (validateDecimal(cdata.servicetermshippingfee)
    && validateDecimal(cdata.servicetermfreightfee)
    && validateTimeLine(cdata.servicetermtimeline)
  );
};

export const getServiceTermData = (cdata) => {
  let newcdata = {};
  if (validateText(cdata.id)) newcdata.id = cdata.id;
  newcdata.servicetermshippingfee = validateDecimal(cdata.servicetermshippingfee) ? cdata.servicetermshippingfee : 0;
  newcdata.servicetermfreightfee = validateDecimal(cdata.servicetermfreightfee) ? cdata.servicetermfreightfee : 0;
  if (validateTimeLine(cdata.servicetermtimeline)) newcdata.servicetermtimeline = cdata.servicetermtimeline;
  return newcdata;
};

export const requiredServiceTermData = (cdata) => requiredFields(cdata, {
  'servicetermshippingfee': 'Shipping fee', 
  'servicetermfreightfee': 'Service Fee(Freight/Handling)', 
  'servicetermtimeline': 'Time Line', 
});

export const PaymentRebateData = {
  id: '',
  pmtrebateposent: '',
  pmtrebatepopct: '',
  pmtrebatepovalue: '',
  pmtrebatepaymentsent: '',
  pmtrebatepaymentpct: '',
  pmtrebatepaymentvalue: '',
};

export const validatePaymentRebateData = (cdata) => {
  return (validateTimeLine(cdata.pmtrebateposent)
    && validateDecimal(cdata.pmtrebatepopct)
    && validateTimeLine(cdata.pmtrebatepaymentsent)
    && validateDecimal(cdata.pmtrebatepaymentpct)
  );
};

export const getPaymentRebateData = (cdata) => {
  let newcdata = {};
  if (validateText(cdata.id)) newcdata.id = cdata.id;
  if (validateTimeLine(cdata.pmtrebateposent)) newcdata.pmtrebateposent = cdata.pmtrebateposent;
  newcdata.pmtrebatepopct = validateDecimal(cdata.pmtrebatepopct) ? cdata.pmtrebatepopct : 0;
  newcdata.pmtrebatepovalue = validateDecimal(cdata.pmtrebatepovalue) ? cdata.pmtrebatepovalue : 0;
  if (validateTimeLine(cdata.pmtrebatepaymentsent)) newcdata.pmtrebatepaymentsent = cdata.pmtrebatepaymentsent;
  newcdata.pmtrebatepaymentpct = validateDecimal(cdata.pmtrebatepaymentpct) ? cdata.pmtrebatepaymentpct : 0;
  newcdata.pmtrebatepaymentvalue = validateDecimal(cdata.pmtrebatepaymentvalue) ? cdata.pmtrebatepaymentvalue : 0;
  return newcdata;
};

export const requiredPaymentRebateData = (cdata) => requiredFields(cdata, {
  'pmtrebateposent': 'PO sent in', 
  'pmtrebatepopct': 'Rebate % of Spend', 
  'pmtrebatepaymentsent': 'Payment sent in', 
  'pmtrebatepaymentpct': 'Rebate % of Spend', 
});

export const SpendRebateData = {
  id: '',
  spendrebatespendneeded: '',
  spendrebatepct: '',
  spendtotal: '',
};

export const validateSpendRebateData = (cdata) => {
  return (validateDecimal(cdata.spendrebatespendneeded)
    && validateDecimal(cdata.spendrebatepct)
  );
};

export const getSpendRebateData = (cdata) => {
  let newcdata = {};
  if (validateText(cdata.id)) newcdata.id = cdata.id;
  newcdata.spendrebatespendneeded = validateDecimal(cdata.spendrebatespendneeded) ? cdata.spendrebatespendneeded : 0;
  newcdata.spendrebatepct = validateDecimal(cdata.spendrebatepct) ? cdata.spendrebatepct : 0;
  newcdata.spendtotal = validateDecimal(cdata.spendtotal) ? cdata.spendtotal : 0;
  return newcdata;
};

export const requiredSpendRebateData = (cdata) => requiredFields(cdata, {
  'spendrebatespendneeded': 'Spend needed for Rebate (USD)', 
  'spendrebatepct': 'Percent of total spend Rebated', 
});

export const VolumeRebateData = {
  id: '',
  cptcode: '',
  currentvolume: '',
  volumeneeded: '',
  volumepctrebate: '',
  volumerebatevalue: '',
};

export const validateVolumeRebateData = (cdata) => {
  return (validateText(cdata.cptcode)
    && validateDecimal(cdata.volumeneeded)
    && validateDecimal(cdata.volumepctrebate));
};

export const getVolumeRebateData = (cdata) => {
  let newcdata = {};
  if (validateText(cdata.id)) newcdata.id = cdata.id;
  newcdata.cptcode = validateText(cdata.cptcode) ? cdata.cptcode : "";
  newcdata.currentvolume = validateDecimal(cdata.currentvolume) ? cdata.currentvolume : 0;
  newcdata.volumeneeded = validateDecimal(cdata.volumeneeded) ? cdata.volumeneeded : 0;
  newcdata.volumepctrebate = validateDecimal(cdata.volumepctrebate) ? cdata.volumepctrebate : 0;
  newcdata.volumerebatevalue = validateDecimal(cdata.volumerebatevalue) ? cdata.volumerebatevalue : 0;
  return newcdata;
};

export const requiredVolumeRebateData = (cdata) => requiredFields(cdata, {
  'cptcode': 'CPT Code', 
  'volumeneeded': 'Volume needed for Rebate', 
  'volumepctrebate': '% Rebated', 
});

export const ContractTotalDataModel = {
  id: '',
  contractnumber: '',
  date: '',
  contracttotal: '',
  contractrebate: '',
  contract90days: '',
};

export const validateContractTotalData = (cdata) => {
  return (cdata.contractnumber !== ""
    // && validateDecimal(cdata.contracttotal)
    // && validateDecimal(cdata.contractrebate)
  );
};

export const getContractTotalData = (cdata) => {
  let newcdata = {};
  if (validateText(cdata.id)) newcdata.id = cdata.id;
  newcdata.contractnumber = cdata.contractnumber || "";
  if (validateText(cdata.date)) newcdata.date = cdata.date;
  newcdata.contracttotal = validateDecimal(cdata.contracttotal) ? cdata.contracttotal : 0;
  newcdata.contractrebate = validateDecimal(cdata.contractrebate) ? cdata.contractrebate : 0;
  newcdata.contract90days = validateDecimal(cdata.contract90days) ? cdata.contract90days : 0;
  return newcdata;
};

export const CodeTotalData = {
  id: '',
  cptcode: '',
  cpttotal: '',
  cptrebate: '',
};

export const validateCodeTotalData = (cdata) => {
  return (cdata.cptcode !== ""
    && validateDecimal(cdata.cpttotal)
    && validateDecimal(cdata.cptrebate)
  );
};

export const getCodeTotalData = (cdata) => {
  let newcdata = {};
  if (validateText(cdata.id)) newcdata.id = cdata.id;
  newcdata.cptcode = cdata.cptcode || "";
  newcdata.cpttotal = validateDecimal(cdata.cpttotal) ? cdata.cpttotal : 0;
  newcdata.cptrebate = validateDecimal(cdata.cptrebate) ? cdata.cptrebate : 0;
  return newcdata;
};

