import React, { useState } from 'react';
import { Redirect } from "react-router-dom";
import { useLedger, useParty } from "@daml/react";
import { AppBar, Tabs, Tab, Button, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { AccountInventory as AccountInventoryTemplate, InventoryProductHistory } from "../../services/daml-modules1";
import { useDamlState } from '../../context/DamlContext';
import { getState } from "../../store";
import { useHistory } from "react-router-dom";
import TabPanel, { a11yProps } from "../../components/Modals/MultiTabsModals/TabPanel";
import AccountInventoryActiveContractsHospital from "./accountinventoryactivecontractshospital";
import AccountInventoryActiveContractsVendor from "./accountinventoryactivecontractsvendor";
import AccountInventoryHistoricContracts from "./accountinventoryhistoriccontracts";
import CreateInventory from './create-inventory';
import StreamQueries from "../../components/Daml/StreamQueries";
import { addInventoryData } from "./handle";
import useStyles from "../surgicalevent/styles";


/**
 * Account Inventory Page
 * @returns 
 */
export default function AccountInventory () {

  const classes = useStyles();
  const { role, roleCid, party, userParty } = useDamlState();
  const ledger = useLedger();
  const history = useHistory();
  const hospital = useParty();

  const [value, setValue] = useState(0);
  const [searchInventory, setSearchInventory] = useState({});
  // add inventory
  const [inventoryDataList, setInventoryDataList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [staticValues, setStaticValues] = useState({});
  const [allContracts, setAllContracts] = useState([]);

  const contract = getState().inventory.detail;

  let searchInv = {};

  if (!!contract && 'inventorydata' in contract) {
    searchInv = {
      hospital: contract.hospital,
      vendor: contract.vendor,
      inventoryproduct: {
        ireferencenumber: contract.inventoryproduct.ireferencenumber,
        iownership: contract.inventoryproduct.iownership,
        // iproductdescription: contract.inventoryproduct.iproductdescription,
      },
    };
  }

  console.log("[AccountInventory]", searchInv, contract);


  const handleChange = (event, newValue) => {
    setAllContracts([]);
    setValue(newValue);
  };

  const handleChangeSearchValue = (val, key) => {
    setSearchInventory({ ...searchInventory, [key]: val });
  };

  // handle when click "Add New Inventory" button
  const openCreateInventoryModal = (e) => {
    // get vendor, ownership, referencenumber
    if (contract) {
      let values = {};
      if ('vendor' in contract) values.sepdistributor = contract.vendor;
      if ('inventoryproduct' in contract) {
        values.iownership = contract.inventoryproduct.iownership;
        values.ireferencenumber = contract.inventoryproduct.ireferencenumber;
        values.iproductdescription = contract.inventoryproduct.iproductdescription;
      }
      setStaticValues(values);
    }
    else {
      setStaticValues({});
    }
    setInventoryDataList([]);
    setOpenModal(true);
  };

  // handle to add new Inventory
  const addProductItem = async () => {
    console.log("[AccountInventory] addProductItem", inventoryDataList);

    let cid = contract.contractId;

    if (inventoryDataList && inventoryDataList.length > 0) {
      for (let i in inventoryDataList) {

        const invdata = inventoryDataList[i];

        cid = await addInventoryData({
          ledger,
          ContractId: cid,
          inventorydata: invdata,
          newitemprequestuser: userParty ? userParty : party,
          newitemprequestmessage: "",
          roleCid,
        });
      }
    }

    setOpenModal(false);
  };

  // handle to add more Product in Inventory
  const handleAddMoreProduct = async (product) => {
    // Nothing
  };
  const styleObj = {
    fontSize: 16,
    textTransform: "uppercase",
    fontWeight: "bold",
  };

  return (
    <>
      {
        (!!contract && 'inventorydata' in contract)
          ?
          <div className={classes.root}>

            <Button className={classes.backLink} onClick={(e)=>{history.push('/app/inventorylist')}}>
              <ArrowBackIcon />  Back to Account Inventory
            </Button>

            {
              (role === "Hospital" && value === 0) && false && 
              <Button
                size="small"
                className={classes.newSetupButton}
                variant="contained"
                onClick={openCreateInventoryModal}
              >
                Add Inventory
              </Button>
            }

            <div>
              <Typography className={classes.pageTitle}>
                REF-
                <span style={styleObj}>
                  {(contract.inventoryproduct) && contract.inventoryproduct.ireferencenumber }
                </span> &nbsp; | &nbsp;
                <b>{(contract.inventoryproduct) && contract.vendor} </b> &nbsp; | &nbsp;
                {(contract.inventoryproduct) && contract.inventoryproduct.iproductdescription } &nbsp; | &nbsp; 
                <span style={styleObj}>
                  <b>{(contract.inventoryproduct) && contract.inventoryproduct.iownership }</b>
                </span>
              </Typography>
            </div>
            
            <AppBar position="static" className={classes.tabPanelMenu}>
              <Tabs indicatorColor="primary" textColor="primary" value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="In House" {...a11yProps(0)} />
                <Tab label="Prior Use" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            
            <TabPanel value={value} index={0} className={classes.tabPanelbody}>
              {
                (role === "Hospital") ?
                  <>
                    <AccountInventoryActiveContractsHospital
                      searchInv={searchInv}
                      searchParams={searchInventory}
                      handleChangeSearchValue={handleChangeSearchValue}
                      ledger={ledger}
                      allContracts={allContracts}
                    />
                    <StreamQueries
                      template={AccountInventoryTemplate}
                      searchParams={[searchInv]}
                      setValues={(data) => {
                        setAllContracts(data.contracts);
                      }}
                    />
                  </>
                :
                  <>
                    <AccountInventoryActiveContractsVendor
                      searchInv={searchInv}
                      searchParams={searchInventory}
                      handleChangeSearchValue={handleChangeSearchValue}
                      ledger={ledger}
                      allContracts={allContracts}
                    />
                    <StreamQueries
                      template={AccountInventoryTemplate}
                      searchParams={[searchInv]}
                      setValues={(data) => {
                        setAllContracts(data.contracts);
                      }}
                    />
                  </>
              }
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tabPanelbody}>
              <>
                <AccountInventoryHistoricContracts
                  searchInv={searchInv}
                  role={role}
                  allContracts={allContracts}
                />
                <StreamQueries
                  template={InventoryProductHistory}
                  searchParams={[searchInv]}
                  setValues={(data) => {
                    setAllContracts(data.contracts);
                  }}
                />
              </>
            </TabPanel>

            <CreateInventory
              openModal={openModal}
              setOpenModal={setOpenModal}
              addProductItem={addProductItem}
              handleAddMoreProduct={handleAddMoreProduct}
              hospital={(role === "Hospital" ? hospital : "")}
              title="Add Inventory"
              inventoryDataList={inventoryDataList}
              setInventoryDataList={setInventoryDataList}
              btnCreate="Add"
              staticValues={staticValues}
            />
          
          </div>
        :
        <Redirect to="/app/inventorylist" />
      }
    </>
  );
}
