import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  comment: {
    marginTop: '7px',
    display: 'block',
  },
  pageTitle: {
    display: 'inline-block',
    marginBottom: '15px',
  },
  newCheckbox: {
    float: 'right',
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '16px',
    '& .MuiCheckbox-root': {
      padding: '4px 9px',
    },
    '& .MuiCheckbox-colorPrimary.Mui-checked':{
      color:'#183C73'
    }
  },
  newOverview: {
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        borderWidth: '0px',
        padding: '9px',
        '&:first-child': {
          paddingLeft: '20px',
          // minWidth: '163px',
        },
        '&.details': {
          fontWeight: 600,
          fontSize: '15px',
          lineHeight: '18px',
          color: '#000000',
          padding: '10px 15px 10px',
          '& img': {
            marginRight: '11px',
          },
          '& span.MuiTypography-root': {
            verticalAlign: 'super',
          },
        },
        '&.label': {
          fontWeight: 300,
          fontSize: '14px',
          lineHeight: '16px',
          color: '#5C5C5C',
          width: '105px',
          verticalAlign: 'text-top;',
        },
        '&.value': {
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '17px',
          color: '#000000',
          // wordBreak:'break-all !important;',
          verticalAlign: 'text-top;',
        },
        '&.myValue': {
          overflow: 'hidden',
          textOverflow: 'clip',
          whiteSpace: 'pre-wrap;'
        },
      },
      '&.border-row': {
        borderBottom: '1px solid #CCCCCC',
        '& .MuiTableCell-root': {
          paddingBottom: '50px',
        },
      },
    },
  },
  productSubtitle: {
      fontWeight: 700,
      fontSize: '15px',
      lineHeight: '17px',
      color: '#000000',
      padding: '15px 17px 15px',
      '&.label': {
        fontWeight: 300,
        fontSize: '14px',
        lineHeight: '16px',
        color: '#5C5C5C',
        width: '105px',
        verticalAlign: 'text-top;',
      },
      '&.value': {
        fontWeight: 600,
        fontSize: '15px',
        lineHeight: '18px',
        color: '#000000',
        // wordBreak:'break-all !important;',
        verticalAlign: 'text-top;',
      },
  },
  cellHeaderBold: {
    fontWeight: '600',
    fontSize: 14,
    lineHeight: '16.41px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  paddingLeftRight: {
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  cellWithBorder: {
    border: '1px solid rgba(204, 204, 204, 1) !important',
  },
  rowWithBorder: {
    borderRight: '1px solid rgba(204, 204, 204, 1) !important',
    borderLeft: '1px solid rgba(204, 204, 204, 1) !important',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  newSETitlePanel: {
    '& .name': {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '25px',
      paddingLeft: '12px',
      minWidth: '170px',
      maxWidth: '500px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '& .label': {
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '22px',
      color: '#1C3C74',
      '&:not(:first-child)': {
        marginLeft: '55px',
      },
    },
    '& .value': {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '24px',
      marginLeft: '13px',
    },
  },
}));
