import React, { useState } from "react";
import { useLedger, useParty } from "@daml/react";
import classNames from "classnames";
import { Chip } from "@material-ui/core";
import { /* Invoice, */ InvoiceHistory } from "../../services/daml-modules1";
import AdaptiveSearch from "../../components/Search/adaptivesearch";
import DateSearch from "../../components/Search/datesearch";
import { contractFilter } from "../../services/daml-filters";
import { getTemplate } from "../../services/util";
import sendIcon from "../../icons/send.svg";
import sendDisableIcon from "../../icons/send-disable.svg";
import archiveIcon from "../../icons/archive.svg";
import archiveDisableIcon from "../../icons/archive-disable.svg";
import { sendInvoice, ArchiveInvoice, setUpdateInv } from "./handle";
import ModalDialogBox from '../../components/Modals/GeneralModal/modalDialogBox';
import TreeContracts from "../../components/Contracts/TreeContracts";
import DetailViewDrawer from "./detailviewdrawer";
import DetailViewDrawerPO from "./detailviewdrawerpo";
import { useDamlStream } from "../../hooks/useDAML";
import { useDamlState } from "../../context/DamlContext";
import useStyles from "./styles";
import withLoading from "../../hoc/withLoading";
import editIcon from "../../icons/edit.svg";
import editDisableIcon from "../../icons/edit-disable.svg";
import UpdateInvoice from "./updateInvoice";
import SnackbarModal from "../../components/Modals/SnackbarModal";

/**
 * 
 * InvoiceList Contracts
 * @param {Object} searchParams 
 * @param {Object} searchHistoryParams 
 * @param {Boolean} isIncludeHistory 
 * @param {Object} searchInv 
 * @param {Function} handleChangeSearchValue 
 * @param {Array} allContracts 
 * @returns 
 */
function InvoiceListContracts({
  searchParams = {},
  isIncludeHistory = false,
  searchInv = {},
  handleChangeSearchValue,
  allContracts = [],
}) {

  const classes = useStyles();

  const vendor = useParty();
  const ledger = useLedger();
  const { roleCid, rolePayload, userlistPayload } = useDamlState();
  console.log("rolePayload", rolePayload);
  const assetsHistory = useDamlStream(InvoiceHistory, [{ vendor }], isIncludeHistory);

  allContracts = allContracts.concat(isIncludeHistory && assetsHistory.contracts ?
    assetsHistory.contracts.map(h => ({ ...h, payload: h.payload.invoice })) : []);
  let contracts = contractFilter(allContracts, searchParams);

  contracts = contracts
    .map(c => {
      c.payload.invoicedata.totalitemscount = c.payload.invoicedetail.reduce((s, invd) => (s + parseInt(invd.invdproductquantity)), 0);
      return c;
    });

  const [openModal, setOpenModal] = useState(false);
  // const [sendModalOpen, setSendModalOpen] = useState(false);
  const [sendModalContract, setSendModalContract] = useState({});
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [archiveModalContract, setArchiveModalContract] = useState({});
  // const [sendModalconfirm, setSendModalconfirm] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [confirmToast, setconfirmToast] = useState(false);

  // const {
  //   handleOpenCreateInvoice,
  // } = createOptions;


  const [contractId, setContractId] = useState();
  const [payload, setPayload] = useState();

  // Click "Edit Invoice" in list
  const handleEditInvoice = (invoice) => {
    console.log("[invoicelistcontracts] invoice", invoice);
    let { contractId, payload } = invoice
    setContractId(contractId);
    setPayload(payload)
    setOpenModal(true);
  };



  const checkNotInvHistory = (c) => (getTemplate(c) !== "InvoiceHistory");

  // DetailViewDrawer
  const [invoiceData, setInvoiceData] = useState({});
  const [invoiceList, setInvoiceList] = useState([]);
  const [detailViewer, setDetailViewer] = useState(false);
  // DetailViewDrawer for Invoice detail
  const [invoice, setInvoice] = useState({});
  const [detailViewerInvoice, setDetailViewerInvoice] = useState(false);


  const openSendInv = (c) => {
    setSendModalContract(c);

    if (rolePayload?.vendordetails?.ach_info?.accountnumber === "" || rolePayload?.vendordetails?.ach_info?.networkid === "" || rolePayload?.vendordetails?.ach_info?.providerid === "" || rolePayload?.vendordetails?.ach_info?.routingnumber === "" ||
      rolePayload?.vendordetails?.vendoraddress === "") {
      setOpenToast(true);
    }
    else {
      setconfirmToast(true);

    }


  };
  const cancelsendInv = () => {
    setconfirmToast(false)
  }

  const handleSendInv = async () => {
    await sendInvoice({
      ledger,
      cid: sendModalContract.contractId,
      payload: sendModalContract.payload,
      roleCid,
      userlistPayload,
    });
    // setSendModalOpen(false);
  };

  const openArchiveInv = (c) => {
    setArchiveModalContract(c);
    setArchiveModalOpen(true);
  };

  const handleArchiveInv = async () => {
    await ArchiveInvoice({
      ledger,
      cid: archiveModalContract.contractId,
      payload: archiveModalContract.payload,
      roleCid,
    });
    setArchiveModalOpen(false);
  };

  const getPaymentStatus = (status) => {
    let statusCode = ''
    switch (status) {
      case 'Error Received':
        statusCode = classes.backRed
        break;

      case 'Payment Complete':
        statusCode = classes.backGreen
        break;

      case 'Available for payment':
        statusCode = classes.backBlue
        break;

      case 'Ready for payment':
        statusCode = classes.backBlue
        break;

      case 'Cancelled':
        statusCode = classes.backYellow
        break;
      case 'Processing by Bank':
        statusCode = classes.backBlue
        break;
      case 'Sent to Bank':
      case 'Confirmed by Bank':
        statusCode = classes.backTeal
        break;


      default:
        statusCode = classes.backBlue
        break;
    }

    return statusCode;
  }

  return (
    <>

      <TreeContracts
        contracts={contracts}
        columns={[
          // ["ContractId", "contractId"],
          ["Purchase Order", "payload.invoicedata.invpurchaseorderid", {
            type: "link",
            onClick: (contractId, contract) => {
              const clickedContractId = (!!contractId) ? contractId : contract.contractId;
              const data = contracts.find(c => c.contractId === clickedContractId);
              console.log("[invoicedetail] data", data);
              setInvoiceData({
                ...data.payload.invoicedata,
                is_sent_hospital: data.payload.is_sent_hospital,
                hospital: data.payload.hospital,
              });
              setInvoiceList(data.payload.invoicedetail);
              setDetailViewer(true);
            },
            options: {
              width: 250,
            },
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.invoicedata.invpurchaseorderid}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'invpurchaseorderid')}
                  defaultValue={searchInv.invpurchaseorderid}
                  label="Search PO Number"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchInv.invpurchaseorderid,
            handleClear: () => handleChangeSearchValue('', 'invpurchaseorderid'),
          }],
          ["Invoice Number", "payload.invoicedata.invinvoicenumber", {
            type: "link",
            onClick: (contractId, contract) => {
              const clickedContractId = (!!contractId) ? contractId : contract.contractId;
              const data = contracts.find(c => c.contractId === clickedContractId);
              console.log("[invoicedetail] data", data);
              setInvoice(data.payload);
              setDetailViewerInvoice(true);
            },
            options: {
              width: 250,
            },
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.invoicedata.invinvoicenumber}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'invinvoicenumber')}
                  defaultValue={searchInv.invinvoicenumber}
                  label="Search SE ID"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchInv.invinvoicenumber,
            handleClear: () => handleChangeSearchValue('', 'invinvoicenumber'),
          }],
          ["Invoice Date", "payload.invoicedata.invinvoicedate", {
            options: {
              width: 160,
            },
            search: {
              component:
                <DateSearch
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'invinvoicedate')}
                  defaultValue={searchInv.invinvoicedate}
                  label="Search Invoice Date :"
                  isStatic={true}
                />
            },
            isSearched: searchInv.invinvoicedate,
            handleClear: () => handleChangeSearchValue('', 'invinvoicedate'),
          }],
          ["Hospital", "payload.hospital"],
          ["Invoice Amount", "payload.invoicedata.invinvoicetotalamount", {
            options: {
              width: 130,
              beforeText: '$'
            },
          }],
          ["Invoice Items", "payload.invoicedata.totalitemscount", {
            options: {
              width: 130,
              headerClassName: classes.centerCell,
              cellClassName: classes.centerCell,
            },
          }],
          ///// Commented this because these statuses are not functional yet ////////
          // ["Status", "payload.status", {
          //   type: "render",
          //   render: (param) => (<Chip
          //     label={param.value}
          //     size="small"
          //     className={classNames(classes.smallChip, (
          //       param.value === 'Updated Invoice' || param.value === 'Created Invoice' ? classes.backBlue : (
          //         param.value === 'Declined' ? classes.backLightOrange : (
          //           param.value === 'Sent' || param.value === 'Accepted' ? classes.backGreen : classes.backOrange
          //         )
          //       )
          //     ))}
          //   />),

          //   options: {
          //     flex: 1.5,
          //     alignItems: 'center',
          //   },
          // }],

          //////// Added the old statuses ////////
          ["Status", "payload.is_sent_hospital", {
            type: "render",
            render: (param) => (
              <Chip
                label={param.value === 'True' ? "Sent" : "Created"}
                size="small"
                className={classNames(classes.smallChip, (param.value === 'True' ? classes.backBlue : classes.backGreen))}
              />),
              options: {
                flex: 1.5,
                alignItems: 'center',
              },
          }],
          ["Payement Status", "payload.payment_status", {
            type: "render",
            render: (param) => (<Chip
              label={param.value}
              size="small"
              className={
                classNames(classes.smallChip, getPaymentStatus(param.value))
              }
            />),

            options: {
              flex: 1.5,
              alignItems: 'center',
            },
          }],
        ]}

        actions={[
          [
            "Actions",
            [[["Edit Invoice", [<img alt="" src={editIcon} />, <img alt="" src={editDisableIcon} />],
              (c) => checkNotInvHistory(c) && !c.payload.is_sent_hospital
            ],
              handleEditInvoice
            ],
            [["Send Invoice", [<img alt="" src={sendIcon} />, <img alt="" src={sendDisableIcon} />],
              (c) => checkNotInvHistory(c) && !c.payload.is_sent_hospital],
              openSendInv],
            [["Archive", [<img alt="" src={archiveIcon} />, <img alt="" src={archiveDisableIcon} />],
              (c) => (checkNotInvHistory(c) && c.payload.is_sent_hospital && c.payload.status === 'Available for payment')],
              openArchiveInv],
            ],
            {
              width: 120,
            }
          ]
        ]}
        options={{
          statusEmpty: (allContracts.length === 0)
        }}
      />


      <DetailViewDrawerPO
        state={detailViewer}
        toggleDrawer={(open) => setDetailViewer(open)}
        selectedInvoiceData={invoiceData}
        productItemsList={invoiceList}
      />

      <DetailViewDrawer
        state={detailViewerInvoice}
        toggleDrawer={(open) => setDetailViewerInvoice(open)}
        invoice={invoice}
        options = {{
          isHospital: false
        }}
      />



      <ModalDialogBox
        open={archiveModalOpen}
        setOpen={setArchiveModalOpen}
        heading="Confirm"
        type="warning"
        text={'<p>Do you want to archive Invoice now ?</p>'}
        handleRequest={handleArchiveInv}
        secondBtn={'No'}
        handleSecRequest={() => setArchiveModalOpen(false)}
      />

      <SnackbarModal
        open={confirmToast}
        setOpen={setconfirmToast}
        type="warning"
        title=""
        content="Do you want to send to Hospital now ?"
        vertical="center"
        horizontal="center"
        width={500}
        height={117}
        transition="fade"
        className={classes.toastMedium}
        options={{
          delay: 500,
        }}
        actions={[
          {
            label: 'Yes',
            handle: handleSendInv,
          },
          {
            label: 'No',
            handle: cancelsendInv,
          }
        ]}
      />
      <SnackbarModal
        open={openToast}
        setOpen={setOpenToast}
        type="warning"
        title=""
        content="We don\'t have your Bank details or Conact details Please connect with  your Operator"
        vertical="center"
        horizontal="center"
        width={500}
        height={117}
        transition="fade"
        className={classes.toastMedium}
        options={{
          delay: 500,
        }}
        actions={[

          {
            label: 'OK',
          }
        ]}
      />


      <UpdateInvoice
        openModal={openModal}
        setOpenModal={setOpenModal}
        invoice={payload}
        handleUpdate={async (update) => {
          console.log(update)
          setOpenModal(false);
          await setUpdateInv({
            ledger,
            hospital: update?.hospital,
            cid: contractId,
            newinvoicedate: update?.invoicedata?.invinvoicedate,
            newinvoiceamount: update?.invoicedata?.invinvoicetotalamount,
            roleCid,
            userlistPayload,
          });
        }}
      />

    </>
  );
}

export default withLoading(InvoiceListContracts);
