import * as React from 'react';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Button, ButtonGroup, Grid, Typography } from '@material-ui/core';
import { useLayoutState, useLayoutDispatch, setDarkMode, setLightMode } from "../../context/LayoutContext";
import { useUserState } from '../../context/UserContext';
import CustomCheckbox from '../../components/CheckBoxList/CustomCheckbox';
import useStyle from "./styles";
import { useState } from 'react';
import refreshIcon from "../../icons/refresh-1.svg";
import { useParty, useStreamQueries } from '@daml/react';
import { DiscoverPayment } from '../../services/daml-modules1';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { darkModeKey } from '../../config';

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    background: {
      ...(mode === 'light'
        ? {
          primary: "#FFFFFF",
        }
        : {
          primary: '#1C3C74',
        }),
    },
    background1: {
      ...(mode === 'light'
        ? {
          primary: "#EDEDED",
        }
        : {
          primary: '#1C3C74',
        }),
    },
    text: {
      ...(mode === 'light'
        ? {
          primary: "#1C3C74",
        }
        : {
          primary: '#FFFFFF',
        }),
    },
    text1: {
      ...(mode === 'light'
        ? {
          primary: "#5C5C5C",
        }
        : {
          primary: '#CCCCCC',
        }),
    },
    text2: {
      ...(mode === 'light'
        ? {
          primary: "#1C3C74",
        }
        : {
          primary: '#F7CD37',
        }),
    },
  },
});
const PaymentStatus = ({color}) => {
  const getColorCode = (value) => {
    switch (value) {
        case 'Sent':
            return "#4CAF50";
        case 'Ready for payment':
            return "#2196F3";
        case 'Complete':
            return "#4CAF50";
        case 'Available for payment':
            return "#2196F3";
        case 'Cancelled':
            return "#F95A05";
        default:
            return "#FF9800";
    }
}
  return (
    <svg height="10" width="10">
      <circle cx="5" cy="5" r="5" fill={getColorCode(color)} />
    </svg>
  )
}
export default function SAPDashboard() {
  const moment = extendMoment(Moment);
  const hospital = useParty();
  const date30 = moment().add(-29, 'days').toISOString();
  const date7 = moment().add(-6, 'days').toISOString();
  const todaysDate = moment().toISOString();
  let { contracts: _30DaysEvent } = useStreamQueries(DiscoverPayment, () => [{
    hospital, updated_at: { "%gte": date30,"%lte":moment().add(1, 'days').toISOString() }
  }]);  
  let { contracts: next30Days } = useStreamQueries(DiscoverPayment, () => [{
    hospital, paymentdate: { "%gte": moment().format("YYYY-MM-DD") }
  }]); 

  const [todaysEvent, setTodaysEvent] = useState([]);
  const [_7DaysEvent, set_7DaysEvent] = useState([]);
  const [paymentCompleted,setPaymentCompleted]=useState(0);
  const [upcomingPayment,setUpcomingPayment]=useState(0);
  const [recentActivity, setRecentActivity] = useState([]);
  console.clear();
  console.log(_30DaysEvent);
  const formatPrice=(price)=>{
    return price.toLocaleString('en-US', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
  }
  React.useEffect(() => {
    if (recentActivity.length === 0)
      setRecentActivity(_30DaysEvent)
    if (_30DaysEvent.length > 0) {
      const start = moment(date7, 'YYYY-MM-DD');
      const end = moment(todaysDate, 'YYYY-MM-DD');
      const range = moment.range(start, end);
      setTodaysEvent(_30DaysEvent.filter(c => c.payload.updated_at.slice(0,10) === todaysDate.slice(0,10)));
      set_7DaysEvent(_30DaysEvent.filter(c => moment(c.payload.updated_at, "YYYY-MM-DD").within(range)))
    }
    const temp=_30DaysEvent?.filter(c=>c.payload.status==="Complete");
    setPaymentCompleted(temp.reduce((a,c)=>a+Number(c.payload.paymentamount),0))
    // eslint-disable-next-line
  }, [_30DaysEvent]);
  React.useEffect(()=>{
    const temp=next30Days?.filter(c=>(c.payload.status==="Ready for payment" && c.payload.status!=="Cancelled"));
    setUpcomingPayment(temp.reduce((a,c)=>a+Number(c.payload.paymentamount),0))
  },[next30Days])
  const classes = useStyle();
  const userState = useUserState();
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const darkModeTheme = createTheme(getDesignTokens(layoutState.darkMode ? 'dark' : "light"));
  const [days, setDays] = useState(30);

  React.useEffect(() => {
    setRecentActivity(days === 7 ? _7DaysEvent : _30DaysEvent)
    // eslint-disable-next-line
  }, [days,_30DaysEvent,_7DaysEvent])


  return (
    <ThemeProvider theme={darkModeTheme}>
      <Box
        sx={{
          color: 'text.primary',
        }}
      >
        <Grid container direction="column">
          <Grid item>
            <Grid container justifyContent='space-between'>
              <Grid item>
                <Typography variant="h2" style={{ fontWeight: 700 }}>
                  Payment Dashboard
                </Typography>
              </Grid>
              <Grid item>
                <Grid container alignItems='center'>
                  <Grid item>
                    <Typography variant="h6">
                      Welcome, {userState.userParty}
                    </Typography>

                  </Grid>
                  <Grid item>
                    <img
                      src={refreshIcon}
                      alt="Click to refresh the Dashboard data"
                      style={{
                        cursor: "pointer",
                      }}
                      title="Click to refresh the Dashboard data"
                    //   onClick={dashboardData}
                    />
                  </Grid>
                  <Grid item>
                    <div style={{ position: 'relative', left: 18 }}>
                      <CustomCheckbox checked={layoutState.darkMode?layoutState.darkMode:false} setChecked={(props) => {
                        if (!props) setLightMode(layoutDispatch);
                        else setDarkMode(layoutDispatch);
                        localStorage.setItem(darkModeKey,String(props));
                      }} />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: "1rem" }}>
            <Grid container>
              <Grid item style={{ marginRight: 10,width:"18rem" }}>
                <Box
                  sx={{
                    bgcolor: 'background.primary',
                    padding: 2,
                    borderRadius: 2
                  }}
                >
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="h4" style={{ fontWeight: 700 }}>
                        <Box
                          sx={{
                            color: 'text2.primary',
                          }}
                        >
                          Payment Completed
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Box
                        sx={{
                          color: 'text1.primary',
                        }}
                      >
                        Last 30 Days
                      </Box>
                    </Grid>
                    <Grid item>
                      <Typography variant="h3" style={{ fontWeight: 700 }}>
                        <Box
                          sx={{
                            color: 'text2.primary',
                          }}
                        >
                          ${formatPrice(paymentCompleted)}
                        </Box>

                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item style={{ marginRight: 10,width:"18rem" }}>
                <Box
                  sx={{
                    bgcolor: 'background.primary',
                    padding: 2,
                    borderRadius: 2
                  }}
                >
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="h4" style={{ fontWeight: 700 }}>
                        <Box
                          sx={{
                            color: 'text2.primary',
                          }}
                        >
                          Upcoming Payment
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Box
                        sx={{
                          color: 'text1.primary',
                        }}
                      >
                        Next 30 Days
                      </Box>
                    </Grid>
                    <Grid item>
                      <Typography variant="h3" style={{ fontWeight: 700 }}>
                        <Box
                          sx={{
                            color: 'text2.primary',
                          }}
                        >
                          ${formatPrice(upcomingPayment)}
                        </Box>

                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item style={{ marginRight: 10,width:"18rem" }}>
                <Box
                  sx={{
                    bgcolor: 'background.primary',
                    padding: 2,
                    borderRadius: 2
                  }}
                >
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="h4" style={{ fontWeight: 700 }}>
                        <Box
                          sx={{
                            color: 'text2.primary',
                          }}
                        >
                          Rebate
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Box
                        sx={{
                          color: 'text1.primary',
                        }}
                      >
                        This Quarter
                      </Box>
                    </Grid>
                    <Grid item>
                      <Typography variant="h3" style={{ fontWeight: 700 }}>
                        <Box
                          sx={{
                            color: 'text2.primary',
                          }}
                        >
                          ${formatPrice(31000)}
                        </Box>

                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: "1rem" }}>
            <Grid container>
              <Grid item style={{ marginRight: 5,marginTop:10, flexGrow: 1, }}>
                <Box
                  sx={{
                    bgcolor: 'background.primary',
                    borderRadius: 2,
                    height:"52vh",
                  }}
                >
                  <Grid container direction='column' style={{ padding: 5 }}>
                    <Grid item>
                      <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item>
                          <Typography variant="h5" style={{ fontWeight: 700 }}>
                            Today Events
                          </Typography>
                        </Grid>
                        {/* <Grid item style={{ fontWeight: 700, fontSize: 12 }}>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                              <img
                                src={layoutState.darkMode ? refreshDark : refreshLight}
                                alt="Click to refresh the Dashboard data"
                                style={{
                                  cursor: "pointer",
                                }}
                                title="Click to refresh the Dashboard data"
                              />
                            </Grid>
                          </Grid>
                        </Grid> */}
                      </Grid>
                    </Grid>
                    <Grid item className={classes.dashboardTable}>
                      <TableContainer sx={{ maxHeight: "45vh" }}>
                        <Table stickyHeader className={layoutState.darkMode ? "darkModeTable" : "lightModeTable"}>
                          <TableHead className={layoutState.darkMode ? "tableHeaderDark" : "tableHeaderLight"}>
                            <TableRow>
                              <TableCell>
                                Vendor
                              </TableCell>
                              <TableCell>
                                Payment No
                              </TableCell>
                              <TableCell>
                                Invoice Id
                              </TableCell>
                              <TableCell>
                                Amount
                              </TableCell>
                              <TableCell>
                                Amount Date
                              </TableCell>
                              <TableCell>
                                Status
                              </TableCell>
                              <TableCell>
                                Due Date
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              todaysEvent.map((e) =>
                                <TableRow key={e.contractId}>
                                  <TableCell>
                                    {e.payload?.vendor}
                                  </TableCell>
                                  <TableCell>
                                    {e.payload?.paymentnumber}
                                  </TableCell>
                                  <TableCell>
                                    {e.payload?.invoicedata?.invinvoicenumber}
                                  </TableCell>
                                  <TableCell style={{ color: layoutState.darkMode ? "#F7CD37" : "" }}>
                                    ${e.payload?.paymentamount}
                                  </TableCell>
                                  <TableCell>
                                    {e.payload?.paymentdate}
                                  </TableCell>
                                  <TableCell>
                                    <Grid container>
                                      <Grid item style={{ padding: "0 5px 0 0" }}>
                                        <PaymentStatus color={(e.payload.status === "Sent to Bank" || e.payload.status === "Processing by Bank" || e.payload.status === "Received by Bank ") ? "Payment Processing" : e.payload.status} />
                                      </Grid>
                                      <Grid item>
                                        {(e.payload.status === "Sent to Bank" || e.payload.status === "Processing by Bank" || e.payload.status === "Received by Bank ") ? "Payment Processing" : e.payload.status}
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                  <TableCell>
                                    {e.payload?.paymentdate}
                                  </TableCell>
                                </TableRow>
                              )
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item style={{ marginRight: 5,marginTop:10, flexGrow: 1, }}>
                <Box
                  sx={{
                    bgcolor: 'background.primary',
                    borderRadius: 2,
                    height:"52vh",
                  }}
                >
                  <Grid container direction='column' style={{ padding: 5 }}>
                    <Grid item>
                      <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item>
                          <Typography variant="h5" style={{ fontWeight: 700 }}>
                            Recent Activity
                          </Typography>
                        </Grid>
                        <Grid item style={{ fontWeight: 700, fontSize: 12 }}>
                          <ButtonGroup>
                            <Button onClick={() => setDays(7)} style={{ border: `2px solid ${layoutState.darkMode ? "#FFFFFF" : "#1C3C74"}`, borderRadius: "2rem 0 0 2rem", padding: "0 1rem 0 1rem", color: days === 7 ? "#FFFFFF" : "#1C3C74", backgroundColor: days === 7 ? "#1C3C74" : "#FFFFFF" }}>
                              7 Day
                            </Button>
                            <Button onClick={() => setDays(30)} style={{ border: `2px solid ${layoutState.darkMode ? "#FFFFFF" : "#1C3C74"}`, borderRadius: "0 2rem 2rem 0", padding: "0 1rem 0 1rem", color: days === 30 ? "#FFFFFF" : "#1C3C74", backgroundColor: days === 30 ? "#1C3C74" : "#FFFFFF" }}>
                              30 Day
                            </Button>
                          </ButtonGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item className={classes.dashboardTable}>
                    <TableContainer sx={{ maxHeight: "45vh" }}>
                        <Table stickyHeader className={layoutState.darkMode ? "darkModeTable" : "lightModeTable"}>
                        <TableHead className={layoutState.darkMode ? "tableHeaderDark" : "tableHeaderLight"}>
                          <TableRow>
                            <TableCell>
                              Vendor
                            </TableCell>
                            <TableCell>
                              Payment No
                            </TableCell>
                            <TableCell>
                              Invoice Id
                            </TableCell>
                            <TableCell>
                              Amount
                            </TableCell>
                            <TableCell>
                              Payment Date
                            </TableCell>
                            <TableCell>
                              Status
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            recentActivity.map((e) =>
                              <TableRow key={e.contractId}>
                                <TableCell>
                                  {e.payload?.vendor}
                                </TableCell>
                                <TableCell>
                                  {e.payload?.paymentnumber}
                                </TableCell>
                                <TableCell>
                                  {e.payload?.invoicedata?.invinvoicenumber}
                                </TableCell>
                                <TableCell style={{ color: layoutState.darkMode ? "#F7CD37" : "" }}>
                                  ${e.payload?.paymentamount}
                                </TableCell>
                                <TableCell>
                                  {e.payload?.paymentdate}
                                </TableCell>
                                <TableCell>
                                  <Grid container>
                                    <Grid item style={{ padding: "0 5px 0 0" }}>
                                    <PaymentStatus color={(e.payload.status === "Sent to Bank" || e.payload.status === "Processing by Bank" || e.payload.status === "Received by Bank ") ? "Payment Processing" : e.payload.status} />                                   
                                    </Grid>
                                    <Grid item>
                                      {(e.payload.status === "Sent to Bank" || e.payload.status === "Processing by Bank" || e.payload.status === "Received by Bank ") ? "Payment Processing" : e.payload.status}
                                    </Grid>
                                  </Grid>
                                </TableCell>
                              </TableRow>
                            )
                          }
                        </TableBody>
                      </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}