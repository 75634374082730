import React from "react";
import classNames from "classnames";
import { Chip } from "@material-ui/core";
import { MyInventoryProductStatus, InventoryProductStatus } from "../../models/InventoryData";
import Contracts from "../../components/Contracts/Contracts1";
import { contractFilter1 } from "../../services/daml-filters";
import { handleCheckedContract, handleClickedContract } from '../surgicalevent/surgicaleventlistcontracts1';
import { getReturnStatus } from "./returninventorycontracts";
import useStyles from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";
import withFilters from "../../hoc/withFilters";


/**
 * Received Inventory Contracts in Vendor Side
 * @param {Object} searchParams 
 * @param {Boolean} isIncludeHistory 
 * @param {Array} allContracts 
 * @param {Array} historyContracts 
 * @param {Function} setArchived
 * @param {Function} setChecked
 * @param {Function} handleEditInventory
 * @returns 
 */
function ReceivedInventoryContracts({
  searchParams = {},
  isIncludeHistory = false,
  allContracts = [],
  historyContracts = [],
  setArchived = null,
  setChecked = null,
  handleEditInventory = null,
}) {

  const classes = useStyles();

  allContracts = allContracts.concat(isIncludeHistory && historyContracts.length ?
    historyContracts.map(h => ({ ...h, payload: h.payload.inventory })) : [])
    .map(c => {
      c.payload.itemcount = "1";
      c.payload.iproductreturnstatus = getReturnStatus(c.payload.inventorydata);
      return c;
    });
  let contracts = contractFilter1(allContracts, searchParams);
  // console.log("[ReceivedInventoryContracts]", searchParams, contracts);


  return (
    <>
      <Contracts
        className={classes.customRoot}
        contracts={contracts}
        columns={[
          ["Reference Number", "payload.inventorydata.iproduct.sepreferencenumber", {
            type: "link",
            search: {
              type: "AdaptiveSearch",
              name: "inventorydata.iproduct.sepreferencenumber",
              getValue: (c) => c.payload.inventorydata.iproduct.sepreferencenumber,
              contracts: allContracts,
            },
            options: {
              width: 200,
              cellClassName: classNames(classes.primaryColor),
            }
          }],
          ["Lot/SN", "payload.inventorydata.iproduct.seplotcode", {
            search: {
              type: "AdaptiveSearch",
              name: "inventorydata.iproduct.seplotcode",
              getValue: (c) => c.payload.inventorydata.iproduct.seplotcode,
              contracts: allContracts,
            },
            options: {
              width: 150,
            },
          }],
          ["Manufacturer", "payload.inventorydata.iproduct.sepproductmanufacturer", {
            search: {
              type: "AdaptiveSearch",
              name: "inventorydata.iproduct.sepproductmanufacturer",
              getValue: (c) => c.payload.inventorydata.iproduct.sepproductmanufacturer,
              contracts: allContracts,
            },
            options: {
              width: 180,
              flex: 1
            },
          }],
          ["Hospital", "payload.hospital", {
            search: {
              type: "AdaptiveSearch",
              name: "payload.hospital",
              getValue: (c) => c.payload.hospital,
              contracts: allContracts,
            },
            options: {
              width: 180,
            },
          }],
          ["Expiration", "payload.inventorydata.iproduct.sepproductexpiration", {
            search: {
              type: "DateSearch",
              name: "inventorydata.iproduct.sepproductexpiration",
            },
            options: {
              width: 150,
            },
          }],
          ["Product Name", "payload.inventorydata.iproduct.sepproductname", {
            search: {
              type: "AdaptiveSearch",
              name: "inventorydata.iproduct.sepproductname",
              getValue: (c) => c.payload.inventorydata.iproduct.sepproductname,
              contracts: allContracts,
            },
            options: {
              tooltip:true,
              width: 180,
              flex: 1
            },
          }],
          ["Shipment Date", "payload.inventorydata.ishipmentdate", {
            search: {
              type: "DateSearch",
              name: "inventorydata.ishipmentdate",
            },
            options: {
              width: 180,
            },
          }],
          ["Status", "payload.inventorydata.iproductstatus", {
            type: "render",
            render: (param) => (InventoryProductStatus[param.value] && <Chip
              label={InventoryProductStatus[param.value]}
              size="small"
              className={classNames(classes.smallChip, (
                param.value === 'ShippedVendor' ? classes.backBlue : classes.backGreen
              ))}
            />),
            search: {
              type: "SelectSearch",
              name: "inventorydata.iproductstatus",
              options: Object.keys(MyInventoryProductStatus).map(value => ({ label: MyInventoryProductStatus[value], value: value })),
            },
          }],
        ]}

        options={{
          statusEmpty: (allContracts.length === 0),
          checkbox: true,  
        }}
        
        handleArchived={setArchived}
        handleClicked={(e) => handleClickedContract(e, handleEditInventory)}
        handleChecked={(e) => handleCheckedContract(e, contracts, setChecked)}
      />
      
      {/* <ConfirmShipment
        open={open}
        setOpen={setOpen}
        handleRequest={handleShipped}
        inventoryData={confirmInventoryData}
        setInventoryData={setConfirmInventoryData}
        clearProductParams={[]}
        isHospital={true}
      /> */}

    </>
  );
}

const ReceivedInventoryContractsWithLoading = withLoading(ReceivedInventoryContracts);
export default withFilters(ReceivedInventoryContractsWithLoading)([
  { name: "inventorydata.iproduct.sepreferencenumber", label: "Reference Number" },
  { name: "itemcount", label: "Quantity" },
  { name: "inventorydata.iproduct.sepproductdescription", label: "Product Description" },
  { name: "inventorydata.iproduct.sepproductmanufacturer", label: "Manufacturer" },
  { name: "payload.hospital", label: "Hospital" },
  { name: "inventorydata.iproduct.sepownership", label: "Ownership" },
  { name: "inventorydata.iproduct.sepproductprice", label: "Price" },
  { name: "inventorydata.iproduct.sepuom", label: "UoM" },
  { name: "iproductreturnstatus", label: "Product Status" },
]);
