import React from "react";
import classNames from "classnames";
import { Table,TableBody,TableCell,TableHead, TableRow, DialogContentText, Drawer } from "@material-ui/core";
import { formatDate } from "../../services/util";
import ProductDetailViewTable from "../surgicalevent/product-detail-view-table";
import useStyles from "../surgicalevent/styles";


/**
 * Detail View Slide Drawer 
 * @param {Boolean} state
 * @param {Function} toggleDrawer
 * @param {Object} selectedInventory inventorydata of ReturnInventory
 */
export default function DetailViewDrawer ({
  state, toggleDrawer, selectedInventory,
}) {

  const classes = useStyles();

  // console.log("[DetailViewDrawer]", selectedInventory);


  return (
    <React.Fragment  key={'right'} >
      <Drawer className={classes.customRoot} anchor={'right'} open={state} onClose={()=>{toggleDrawer(false)}}>           
        <div className="view-heading">
          <span>Reference Number:</span> {selectedInventory && selectedInventory.iproduct && selectedInventory.iproduct.sepreferencenumber}
          <span style={{marginLeft: 25}}>Lot Code:</span> {selectedInventory && selectedInventory.iproduct && selectedInventory.iproduct.seplotcode}
        </div>
        
       <DialogContentText component={'div'} className="modelStepDetai">
 
        {selectedInventory && 
          <div className="viewDetailsSe">

            <h2 className={classes.formSubHeading}>Inventory Details</h2>
            <div>
              <Table className="product-detail-data" aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell  className={classes.tableCellHead}>PO Number</TableCell>
                    <TableCell  className={classes.tableCell}>{selectedInventory.ipurchaseordernumber}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell  className={classes.tableCellHead}>Shipment Date</TableCell>
                    <TableCell  className={classes.tableCell}>{formatDate(selectedInventory.ishipmentdate)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell  className={classes.tableCellHead}>Received Date</TableCell>
                    <TableCell  className={classes.tableCell}>{formatDate(selectedInventory.ireceiveddate)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>

          </div>
        }

        {selectedInventory && selectedInventory.iproduct &&
          <div className="viewDetailsSe">

            <h2 className={classes.formSubHeading}>Product Details</h2>
            <div>
              <ProductDetailViewTable 
                obj={selectedInventory.iproduct}
                options={{
                  hiddenParams: ['sepreferencenumber', 'sepproductwaste'],
                  extraParams: [
                    {label: 'Quantity', value: selectedInventory.iquantity},
                  ],
                }}
              />
            </div>
          
          </div>
        }

        {selectedInventory && 
          <div className="viewDetailsSe">

            <h2 className={classes.formSubHeading}>Return Request Comment</h2>
            <div>
              <Table className="product-detail-data" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell  className={classNames(classes.formSubHeading, 'border-none')}>Date</TableCell>
                    <TableCell  className={classNames(classes.formSubHeading, 'border-none')}>Name</TableCell>
                    <TableCell  className={classNames(classes.formSubHeading, 'border-none')}>Comment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell  className={classes.tableCell}>{formatDate(selectedInventory.ireturndate)}</TableCell>
                    <TableCell  className={classes.tableCell}>{selectedInventory.ireturnuser}</TableCell>
                    <TableCell  className={classes.tableCell}>{selectedInventory.ireturnmessage}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell  className={classes.tableCell}>{formatDate(selectedInventory.ireturnrequestdecisiondate)}</TableCell>
                    <TableCell  className={classes.tableCell}>{selectedInventory.ireturnrequestdecisionuser}</TableCell>
                    <TableCell  className={classes.tableCell}>{selectedInventory.ireturnrequestcomment}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          
          </div>
        }

        </DialogContentText>
      </Drawer>
    </React.Fragment>
  );
}
