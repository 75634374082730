import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { DateInput } from "../Inputs";
import useStyles from "./styles";


/**
 * Date Search with start & end date
 * @param {Array} params 
 * @param {Function} setParams
 * @returns 
 */
export default function DateSearch({
  params = [],
  setParams = null,
}) {

  const classes = useStyles();


  const [start, setStart] = useState((params.length > 0 && params[0]) ? params[0] : '');
  const [end, setEnd] = useState((params.length > 1 && params[1]) ? params[1] : '');

  // console.log("[DateSearch]", params);


  const handleChangeStartDate = (val) => {
    setStart(val);
    const temp = [...params];
    temp[0] = val;
    setParams(temp);
  };

  const handleChangeEndDate = (val) => {
    setEnd(val);
    const temp = [...params];
    temp[1] = val;
    setParams(temp);
  };


  return (
    <>

      <Grid container justifyContent="space-between" style={{marginTop: 10}}>
        <Grid item>
          <DateInput
            label="Start Date"
            className={classes.dateSearch}
            value={start}
            onChange={handleChangeStartDate}
            width={92}
          />
          {/* <FormControl variant="standard">
            <InputLabel 
              shrink 
              htmlFor="bootstrap-input"
              className={classes.dateSearchLabel}
            >
              Start Date
            </InputLabel>
            <InputBase 
              type="date"
              id="bootstrap-input"
              variant="outlined"
              value={start}
              onChange={(e) => handleChangeStartDate(e.target.value)}
              className={classes.dateSearchInput}
            />
          </FormControl> */}
        </Grid>
        <Grid item>
          <DateInput
            label="End Date"
            className={classes.dateSearch}
            value={end}
            onChange={handleChangeEndDate}
            width={92}
          />
          {/* <FormControl variant="standard">
            <InputLabel 
              shrink 
              htmlFor="bootstrap-input1"
              className={classes.dateSearchLabel}
            >
              End Date
            </InputLabel>
            <InputBase 
              type="date"
              id="bootstrap-input1"
              variant="outlined"
              value={end}
              onChange={(e) => handleChangeEndDate(e.target.value)}
              className={classes.dateSearchInput}
            />
          </FormControl> */}
        </Grid>
      </Grid>

    </>
  );
}
