import React, { useState } from "react";
import { useLedger, useStreamQueries, useParty } from "@daml/react";
import { Button, Typography, Grid } from "@material-ui/core";
import classNames from "classnames";
import AddSurgeon from "./addSurgeon";
import { useDamlState } from "../../context/DamlContext";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "../showlist/styles";
import Contracts from "../../components/Contracts/Contracts1";
import { Surgeons } from "../../services/daml-modules1";
import { createSurgeon, removeSurgeon } from "./handle";
import SnackbarModal from "../../components/Modals/SnackbarModal";

/**
 * User Management Page in Hospital, Vendor, Operator
 * @returns
 */
export default function SurgeonList() {
  const classes = useStyles();
  const classes1 = useStyles1();

  const { role, roleCid } = useDamlState();

  const ledger = useLedger();
  const hospital = useParty();
  const { contracts } = useStreamQueries(Surgeons, () => [
    {hospital},
  ]);
  // AddSurgeon
  const [open, setOpen] = useState(false);
  const [contract, setContract] = useState(null);
  const [openToast, setOpenToast] = useState({ isVisible: false, msg: '' });
  const [openResultToast, setOpenResultToast] = useState({ isVisible: false, msg: '' });

  // handle when click "Add" button in Add surgeon modal
  const handleRequest = async (newuser) => {
    // // email is required
    // if (!newuser.email) return false;

    // close modal
    setOpen(false);

    if (
      role === "Hospital"
    ) {
      let res = await createSurgeon({
        ledger,
        hospital,
        newuser,
        roleCid
      });
      setOpenResultToast({ isVisible: true, msg: res ? 'We are not abe to delete surgeon now please try after some time.' : 'A new surgeon has been added to your list.' })
    }
  };

  const handleDelete = async (c) => {
    setContract(c);
    setOpenToast({ isVisible: true, msg: "Are you sure you want to Delete this Surgeon?" })
  }

  return (
    <>
      <div key="formChildStep" className="formsteps">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography variant="h5" className={classes1.sectionDetailTitle}>
              Surgeon Onboarding
            </Typography>
          </Grid>
          <Grid item>
            <Button
              size="small"
              className={classes.newSetupButton}
              onClick={() => {
                setOpen(true);
              }}
              variant="contained"
            >
              <span className="desktop">Add Surgeon</span>
            </Button>
          </Grid>

        </Grid>
          <Contracts
            contracts={contracts}
            columns={[
              ["Salutation", "payload.salutation", {
                options: {
                  flex: 1,
                },
              }],
              ["First Name", "payload.firstname", {
                options: {
                  flex: 1,
                },
                search: {
                  type: "AdaptiveSearch",
                  name: "name"
                },
              }],
              ["Last Name", "payload.lastname", {
                type: "render",
                options: {
                  flex: 1,
                },
              }],
                ["Pref Card Status", "payload.status", {
                  type: "render",
                  render: (param) =>
                    <Grid container alignItems="center">
                      <Grid item xs={2} style={{ marginTop: "2px" }}>
                        <svg height="10" width="10">
                          <circle cx="5" cy="5" r="5" fill={param.value === 'Connected' ? "#4CAF50" : "#F95A05"} />
                        </svg>
                      </Grid>
                      <Grid item xs={10}>
                        {param.value === 'Connected' ? "Connected" : "Disconnected"}
                      </Grid>
                    </Grid>,
                  options: {
                    flex: 1,
                    alignItems: 'center',
                  },
                }],

            ]}

            actions={[
              [
                "Actions",
                [
                  [["Delete",
                  ],
                  (c) => handleDelete(c)],
                ],
                {
                  width: 150,
                  flex: 1,
                  headerAlign: 'center',
                  // checkVisible: checkEditableSE,
                }
              ]
            ]}

            options={{
              height: 330
            }}
          />

        {open ? <AddSurgeon
          open={open}
          setOpen={setOpen}
          className={classes.pageTitle}
          title={"Add Surgeon"}
          handleRequest={handleRequest}
          // defaultValue={selectedUser}
          role={role}
        /> : null}

        {openToast.isVisible ?
          <SnackbarModal
            open={openToast.isVisible}
            setOpen={(isVisible) => setOpenToast({ isVisible })}
            type="warning"
            title=""
            content={openToast.msg}
            vertical="center"
            horizontal="center"
            width={345}
            height={130}
            transition="fade"
            className={classNames(classes.toastLarge, classes.toastWarning)}
            options={{
              validateULE: true,
            }}
            actions={[
              {
                label: 'Yes',
                handle: async () => {
                  setOpenToast({ isVisible: false });
                  let res = await removeSurgeon({
                    ledger, roleCid, surgeonId: contract?.contractId
                  })
                  setOpenResultToast({ isVisible: true, msg: res ? 'Surgeon has been deleted.' : 'We are not abe to delete surgeon now please try after some time.' })
                },
              },
              {
                label: 'No',
                handle: async () => {
                  setOpenToast({ isVisible: false });
                },
              },
            ]}
          /> :
          null
        }

        {openResultToast.isVisible ? <SnackbarModal
          open={openResultToast.isVisible}
          setOpen={(isVisible) => { setOpenResultToast({ isVisible }) }}
          title=""
          content={openResultToast.msg}
          width={330}
          height={88}
          className={classes.toastMedium}
        /> : null}

      </div>
    </>
  );
}