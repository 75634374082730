import React, { useState } from "react";
import classNames from "classnames";
import { Chip } from "@material-ui/core";
// import { Inventory } from "../../services/daml-modules1";
import TreeContracts from "../../components/Contracts/TreeContracts";
import AdaptiveSearch from "../../components/Search/adaptivesearch";
import DateSearch from "../../components/Search/datesearch";
import { contractFilter } from "../../services/daml-filters";
import completeIcon from "../../icons/check1.svg";
import completeDisableIcon from "../../icons/check6.svg";
import { InventoryProductStatus } from "../../models/InventoryData";
import DetailViewDrawer from "./detail-view-drawer";
import useStyles from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";


/**
 * Received Inventory Contracts in Vendor Side
 * @param {Object} searchParams 
 * @param {Function} handleChangeSearchValue 
 * @param {Array} allContracts 
 * @param {Object} searchInventory 
 * @param {String} vendor 
 * @param {Object} ledger 
 * @param {Function} handleConfirm 
 * @returns 
 */
function ReceivedInventoryContracts({
  searchParams = {},
  handleChangeSearchValue = null,
  allContracts = [],
  searchInventory = {},
  vendor,
  ledger,
  handleConfirm = () => { }, // handle to Received
}) {

  const classes = useStyles();
  // const assets = useStreamQueries(Inventory, () => [searchParams]);
  let assetsContracts = contractFilter(allContracts, searchParams);
  // Detail Viewer
  const [state, setState] = useState(false);
  const [selectedInventory, setSelectedInventory] = useState({});


  let contracts = [];
  if (assetsContracts.length) {
    assetsContracts.forEach(c => {
      if (c.payload.inventorydata.iproductstatus === 'ShippedVendor' 
        || c.payload.inventorydata.iproductstatus === 'ReceivedVendor') {
        contracts.push({
          ...c, 
          ContractId: c.contractId,
          hospital: c.payload.hospital,
        });
      }
    });
  }
  console.log("[ReceivedInventoryContracts]", searchParams, contracts);

  const toggleDrawer = (open) => {
    setState(open);
  };


  return (
    <>
      <TreeContracts
        className={classes.customRoot}
        contracts={contracts}
        columns={[
          // ["PO Number", "ipurchaseordernumber", {
          //   options: {
          //     width: 180,
          //   }, 
          // }],
          ["Reference Number", "payload.inventorydata.iproduct.sepreferencenumber", {
            type: "link",
            onClick: (contractId, data) => {
              // detail page
              console.log("[ReceivedInventoryContracts]", data);
              if (!!data && 'payload' in data) {
                setSelectedInventory({
                  // inventoryproduct: data.inventoryproduct,
                  // inventorydata: [data.payload.inventorydata],
                  ...data.payload.inventorydata,
                });
                toggleDrawer(true);
              }
            },
            options: {
              width: 200,
              cellClassName: classNames(classes.primaryColor),
            }
          }],
          ["Lot Code", "payload.inventorydata.iproduct.seplotcode", {
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.inventorydata.iproduct.seplotcode}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'ilotcode')}
                  defaultValue={searchInventory.ilotcode}
                  label="Search Lot Code"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchInventory.ilotcode,
            handleClear: () => handleChangeSearchValue('', 'ilotcode'),
            options: {
              width: 150,
            },
          }],
          ["Manufacturer", "payload.inventorydata.iproduct.sepproductmanufacturer", {
            options: {
              width: 180,
              flex: 1
            },
          }],
          ["Hospital", "hospital", {
            options: {
              width: 180,
            },
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.hospital}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'hospital')}
                  defaultValue={searchInventory.hospital}
                  label="Search Hospital"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchInventory.hospital,
            handleClear: () => handleChangeSearchValue('', 'hospital'),
          }],
          ["Expiration", "payload.inventorydata.iproduct.sepproductexpiration", {
            search: {
              component:
                <DateSearch
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'iexpirationdate')}
                  defaultValue={searchInventory.iexpirationdate}
                  label="Search Event Date :"
                  isStatic={true}
                />
            },
            isSearched: searchInventory.iexpirationdate,
            handleClear: () => handleChangeSearchValue('', 'iexpirationdate'),
            options: {
              width: 150,
            },
          }],
          ["Product Name", "payload.inventorydata.iproduct.sepproductname", {
            options: {
              tooltip:true,
              width: 180,
              flex: 1
            },
          }],
          ["Shipment Date", "payload.inventorydata.ishipmentdate", {
            options: {
              width: 180,
            },
            search: {
              component:
                <DateSearch
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'ishipmentdate')}
                  defaultValue={searchInventory.ishipmentdate}
                  label="Search Shipment Date :"
                  isStatic={true}
                />
            },
            isSearched: searchInventory.ishipmentdate,
            handleClear: () => handleChangeSearchValue('', 'ishipmentdate'),
          }],
          ["Status", "payload.inventorydata.iproductstatus", {
            type: "render",
            render: (param) => (InventoryProductStatus[param.value] && <Chip
              label={InventoryProductStatus[param.value]}
              size="small"
              className={classNames(classes.smallChip, (
                param.value === 'ShippedVendor' ? classes.backBlue : classes.backGreen
              ))}
            />),
          }],
        ]}
        actions={[
          [
            "Confirmation",
            [
              [["Confirmation", [<img alt="" src={completeIcon} />, <img alt="" src={completeDisableIcon} />],
                (c) => (c.payload.inventorydata.iproductstatus === 'ShippedVendor')],
                handleConfirm],
            ],
            {
              width: 130,
            }
          ]
        ]}
        options={{
          statusEmpty: (allContracts.length === 0)
        }}
      />

      <DetailViewDrawer
        state={state}
        toggleDrawer={toggleDrawer}
        selectedInventory={selectedInventory}
      />

    </>
  );
}

export default withLoading(ReceivedInventoryContracts);
