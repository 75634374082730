import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    welcomeCircle: {
        background: '#1C3C74',
        borderRadius: '50%',
        width: '175px',
        height: '175px',
        textAlign:'center'
      },
    completedIcon: {
        textAlign:'center',
    },
    imageCheck :{
        paddingTop: '10px'
    },
    circelText: {

    },
    circelTextGreen: {
        color: '#4CAF50',
        fontSize: '25px'

    },
    circelTextRed: {
        color: '#FF9800',
        fontSize: '25px'
    },
    circelSecondaryText: {
        color: '#A6A0A0',
        lineHeight:'14px'
    },
    cardContentPaddingBottom: {
        paddingBottom:'10vh'
    },
    loadingSpinner: {
        position: 'fixed',
        left: '50%',
        top: '30%',
        width: '100%',
        height: '100%',
        zIndex: 9999,
    },
    userName: {
        textTransform:'capitalize',
    },
    welcomeBack: {
        color:'#1C3C74',
    },
    noDataCardPrimary: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh'
    },
    noDataCardSecondary: {
        width: '80vh',
    }
}));