import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

const GridContent = (props) => {
  const color = props.color;
  const fontWeight = props.fontWeight;
  const fontSize = props.fontSize;
  const lineHeight = props.lineHeight;

  return (
    <>
      <Box
        sx={{
          color: { color },
          display: "flex",
          justifyContent: "center",
          borderBottom: 2,
          marginBottom: '2px'
        }}
      >
        {props.currency ? (
          <Typography
            sx={{
              color: { color },
              fontStyle: "normal",
              fontWeight: { fontWeight },
              fontSize: { fontSize },
              lineHeight: { lineHeight },
            }}
          >
            ${props.value}
          </Typography>
        ) : (
          <Typography
            sx={{
              color: { color },
              fontStyle: "normal",
              fontWeight: { fontWeight },
              fontSize: { fontSize },
              lineHeight: { lineHeight },
            }}
          >
            {props.value}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default GridContent;
