import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { useAuth0 } from "@auth0/auth0-react";
import { AppBar, Toolbar, IconButton, Typography } from "@material-ui/core";
import { /* Menu, */  ArrowBack } from "@material-ui/icons";
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import Badge from '@material-ui/core/Badge';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { useLayoutState, useLayoutDispatch, toggleSidebar } from "../../context/LayoutContext";
import { useUserDispatch, signOut, useUserState, loginAuth } from "../../context/UserContext";
import { useDamlState } from '../../context/DamlContext';
import { getPartyLabel } from "../../models/handles";
import { switchUser } from "../../pages/user/handle";
import menuIcon from '../../icons/menuicon.svg';
import logoIcon from '../../icons/newlogo.svg';
import printerIcon from '../../icons/printer.svg';
import useAuthLogout from "../../hooks/useAuthLogout";
import useStyles from "./styles";
import SwitchOrganizationModal  from "../../components/Modals/SwitchOrganizationModal";
/**
 * Top Header of Window
 * TODO
 * 1. Open Message List page
 * 2. Complete Notification List
 * 3. Build Print Handler
 * @param {Object} history 
 * @returns 
 */
function Header({ history }) {

  // global
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const user = useUserState();
  const { party, userParty, userlistPayload } = useDamlState();
  const userDispatch = useUserDispatch();

  const {
    // loginWithRedirect,
    loginWithPopup,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();
  
  const authLogout = useAuthLogout({history});
  const defaultOrganization = party;
  const invitedOrgnizationList = user.organizations;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [openNot, setOpenNot] = useState(false);

  const anchorRef = useRef(null);
  const anchorRefNot = useRef(null);

  const handleToggleNot = () => {
    setOpenNot((prevOpenN) => !prevOpenN);
  };
  // const prevOpenN = useRef(openNot);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const handleCloseNot = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenNot(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // handle to click "Settings"
  const handleSettings = (event) => {
    handleClose(event);
    // open message page
    history.push('/app/setting-password');
  };

  // handle to logout
  const handleLogOut = async (event) => {
    // Prevents React from resetting its properties:
    event.persist();

    // console.log("[handleLogOut] Login Auth", isLoginAuth());
    
    if (user.isAuth0) {
      // If login using Auth0, then Auth0 logout
      await authLogout(event);
    }
    else {
      signOut(event, userDispatch, history);
    }
    
    handleClose(event);
  };


  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const onSelectHospital = async (selectedInput) => {
    console.log('selectedInput', selectedInput);

    // swith user by using Auth0
    const res = await switchUser({
      email: userlistPayload.email, 
      targetOrg: selectedInput, 
      currentOrg: defaultOrganization,
    });
    
    if (res === true) {
      // login again to get new token from Auth0
      await loginAuth({
        // loginWithRedirect: () => {},
        loginWithRedirect: loginWithPopup,
        loginWithPopup,
        getAccessTokenSilently,
        getIdTokenClaims,
        userDispatch,
        history,
      });
    }
  }

  // useEffect(()=>{
  //  //searchInvitedOrganizationList();
  // },[])
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>

        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(classes.headerMenuButton, classes.headerMenuButtonCollapse)}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBack
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
             
            />
          ) : (
            <img
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
              alt=""
              src={menuIcon}
            />
          )}
        </IconButton>

        <Typography variant="h6" weight="medium" className={classes.logotype}>
          <img src={logoIcon} alt="Logo"/>
        </Typography>
        <div className={classes.grow} />

        <Typography variant="h6" weight="medium">
          <Button className="notiBtn">
            <img src={printerIcon} alt=""/>
          </Button>

          <Button className="notiBtn" 
            onClick={() => {
              // TODO
              // open message page
              // history.push('/app/message/inbox');
            }}
          >
            <MailOutlineIcon />
            <Badge badgeContent={0} color="error" overlap="rectangular"></Badge>
          </Button>

          <Button className="notiBtn"
            ref={anchorRefNot}
            aria-controls={openNot ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggleNot}
          >
            <NotificationsNoneIcon />
            <Badge badgeContent={0} color="error" overlap="rectangular"></Badge>
          </Button>

          <Popper 
            className={classes.notifications} 
            open={openNot} 
            anchorEl={anchorRefNot.current} 
            role={undefined} 
            transition 
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleCloseNot}>
                    <MenuList 
                      className={classes.notificationList} 
                      autoFocusItem={openNot} 
                      id="menu-list-grow" 
                      onKeyDown={handleListKeyDown}
                    >
                      {/* <MenuItem onClick={handleCloseNot}>
                        <p>Adobe XD to the Material-UI design assets family. 600+ Material-UI symbols, handcrafted in Adobe XD, ready to help turn your imagination into reality!</p>
                      </MenuItem>
                      <MenuItem onClick={handleCloseNot}>
                        <p>Adobe XD to the Material-UI design assets family. 600+ Material-UI symbols, handcrafted in Adobe XD, ready to help turn your imagination into reality!</p>
                      </MenuItem> */}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

          <Button
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <div className="avtar">{party !== null ? getPartyLabel(party) : ''}</div>
            <div className="user">
              {!!userParty ? userParty : "Welcome"} 
              <p> {party}</p> 
            </div>
          </Button>
          
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList  
                      className={classes.profilemenu} 
                      autoFocusItem={open} 
                      id="menu-list-grow" 
                      onKeyDown={handleListKeyDown}
                    >
                      {invitedOrgnizationList && invitedOrgnizationList.slice(0,10).map((list, index)=>{
                          return (
                            <MenuItem onClick={()=>{onSelectHospital(list)}} value={list} key={index}>
                              <div className="avtarSwitchOrg">{list !== null ? getPartyLabel(list) : ''} </div>{list}
                            </MenuItem>
                         )
                        })
                      }
                      {invitedOrgnizationList && invitedOrgnizationList.length > 1 && (
                        <SwitchOrganizationModal
                          modaltitle='Please choose a Facility'
                          buttontitle='See More'
                          organizationList={invitedOrgnizationList}
                          onChangeHandler={(selectedOrg)=>onSelectHospital(selectedOrg)}
                        />
                      )}
                      <MenuItem onClick={handleSettings}>Settings</MenuItem>
                      <MenuItem onClick={handleLogOut}>Logout</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Typography>

      </Toolbar>
    </AppBar>
  );
}

export default withRouter(Header);