import React, { useState } from "react";
import { Button, Typography, Card, CardContent } from "@material-ui/core";
import ImportFile from './Importfile';
import useStyles from "../showlist/styles";
import useStyles1 from "../surgicalevent/styles";


/**
 * Upload Product Data Page
 * @returns 
 */
export default function UploadProductData() {

  const classes = useStyles();
  const classes1 = useStyles1();

  const [filePathData, setFilePathData] = useState("");


  // handle when click "Download" button
  const handleDownload = async () => {
    console.log("[UploadProductData] handleDownload", filePathData);
  };


  return (
    <>
      <div className={classes.customRoot}>

        <Typography variant="h4" className={classes.pageTitle}>
          Upload Product Data
          <Button
            size="small"
            className={classes1.newSetupButton}
            variant="contained"
            onClick={handleDownload}
          >
            Download Product Sample CSV
          </Button>
        </Typography>

        <Card className={classes.cardItem}>
          <CardContent>

            <ImportFile 
              setFilePathData={setFilePathData} 
              addtionalBtn={true} 
            />

          </CardContent>
        </Card>

      </div>
    </>
  );
};
