import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { TextField, FormHelperText, InputBase, FormControl, InputLabel } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import useStyles1 from "./styles";
import useStyles2 from "../Inputs/styles";


/**
 * Adaptive Search with autocomplete
 * @param {Function} getValue 
 * @param {Array} contracts 
 * @param {Array} params 
 * @param {Function} setParams
 * @param {Object} classes 
 * @param {String} defaultValue 
 * @param {Boolean} isChangable 
 * @param {String} warning 
 * @param {Object} popupIcon 
 * @param {String} label 
 * @param {Boolean} required 
 * @param {String} placeholder 
 * @param {Boolean} isReadOnly
 * @param {Number} width 
 * @param {Object} options 
 * @returns 
 */
export default function AdaptiveSearch({
  getValue = (c) => c,
  contracts = [],
  params = [],
  setParams = null,
  classes = {},
  defaultValue = null,
  isChangable = true,
  warning = "",
  popupIcon = null,
  label = null,
  required = false,
  placeholder = '',
  isReadOnly = false,
  width,
  options={},
}) {

  options = {
    labelLeft: false,       // Label position Left
    labelWidth: null,       // Label width {Number|null}
    disableHelper: false,   // Disable to show Helper Text
    ...options,
  };

  const classProps = {
    labelWidth: (typeof options.labelWidth === 'number' ? options.labelWidth : 110),
  };
  const classes1 = useStyles1();
  const classes2 = useStyles2(classProps);


  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [searchOptions, setSearchOptions] = useState([]);
  const [helper, setHelper] = useState(warning);
  const [readOnly, setReadOnly] = useState(isReadOnly);

  // class name list
  const classnames = [classes2.input]; 
  if (classes.container) classnames.push(classes.container);
  if (readOnly) classnames.push(classes2.readOnly);
  if (options.labelLeft) classnames.push("label-left");
  // width
  const containerwidth = options.labelLeft ? classProps.labelWidth + width : width;

  // console.log("[AdaptiveSearch]", loading, options, params, classnames);
  useEffect(() => {
    setHelper(warning);
  }, [warning]);

  const handleChange = (val) => {
    // console.log("[AdaptiveSearch] handleChange", val);
    setInputValue(val);
  };

  const handleSearch = (val) => {
    // console.log("[AdaptiveSearch] handleSearch", val, params);
    if (!isChangable) return;
    let temp = [...params];
    if (val === null) {
      // remove
      const pos = temp.indexOf(value);
      if (pos > -1) {
        temp.splice(pos, 1);
      }
      // clear out the input field
      setInputValue('');
    } else {
      // add
      if (!temp.includes(val)) {
        temp.push(val);
      }
    }
    setValue(val);
    if (typeof setParams === 'function') setParams(temp);
  };


  useEffect(() => {
    if (contracts !== null && typeof contracts === 'object' && contracts.length > 0) {
      // console.log("[AdaptiveSearch]", contracts);
      const tempOptions = contracts.map(getValue)
        .filter((itm, idx, arr) => !!itm && arr.indexOf(itm) === idx)
        .sort();
      setLoading(false);
      setSearchOptions(tempOptions);
    }
    // eslint-disable-next-line
  }, [contracts]);

  useEffect(() => {
    // console.log("[AdaptiveSearch] defaultValue", value, defaultValue);
    if (defaultValue !== value) {
      setValue(defaultValue);
    }
    // eslint-disable-next-line
  }, [defaultValue]);

  const handleSwitch = (value = null) => {
    if (isReadOnly)
      setReadOnly(typeof value === 'boolean' ? value : !readOnly);
  };

  
  return (
    <FormControl 
      className={classNames(classnames)}
      style={{
        paddingTop: (label ? 16 : 0),
        width: containerwidth,
      }}
    >
      {
        !!label &&
        <InputLabel
          shrink
          htmlFor="custom-input"
          className={classes.label}
        >
          {required ? <>{label}<span className={classes2.asteriskStyle}>*</span></> : label }
        </InputLabel>
      }
      {
        readOnly ?
          <InputBase
            value={value || ""}
            onChange={() => { }}
            fullWidth
            disabled={true}
            readOnly={readOnly}
            onDoubleClick={handleSwitch}
            onBlur={() => handleSwitch(true)}
            className={classNames(classes1.newSearchInput, classes.wrapper)}
          />
          :
          <Autocomplete
            className={classNames(classes1.newSearchInput, classes.wrapper)}
            style={(width ? { width: width } : {})}
            classes={{ popper: classNames(classes1.newSearchPopper, classes.popper) }}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              // console.log('Adaptive Search close');
              setOpen(false);
            }}
            value={value}
            onChange={(_, newValue) => handleSearch(newValue)}
            inputValue={inputValue}
            popupIcon={popupIcon}
            onInputChange={(_, newInputValue) => handleChange(newInputValue)}
            options={searchOptions}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={placeholder}
              />
            )}
            onBlur={() => handleSwitch(true)}
            clearOnBlur={false}
          />
      }
      <FormHelperText error>
        {options.disableHelper?null:helper}
      </FormHelperText>
    </FormControl>
  );
}