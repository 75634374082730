import React from "react";
import { useHistory } from "react-router-dom";
import { useUserDispatch, signOut, useUserState, /* isLoginAuth */ } from "../../../context/UserContext";
import useAuthLogout from "../../../hooks/useAuthLogout";
import ModalDialogBox from './modalDialogBox';


/**
 * Error Dialog
 * @param {Boolean} openModal
 * @param {Function} setOpenModal
 * @param {Boolean} isLogout
 * @param {String} text
 * @param {String} firstBtn
 * @param {Boolean} isRedIcon
 * @returns 
 */
export default function ErrorDialog ({
  openModal, setOpenModal, isLogout=true, text='', firstBtn='Close', isRedIcon=false,
}) {

  const user = useUserState();
  const userDispatch = useUserDispatch();

  const history = useHistory();

  const authLogout = useAuthLogout({history});

  /**
   * handle to close an error modal
   * @param {Function} setOpenModal
   * @param {Boolean} isLogout
   */
  const handleCloseError = async (event) => {
  
    if (isLogout) {
      // log out system
      // same logic as call handleLogOut of Header
      if (user.isAuth0) {
        // If login using Auth0, then Auth0 logout
        await authLogout(event);
      }
      else {
        signOut(event, userDispatch, history);
      }
    }
  
    // close modal
    setOpenModal(false);
  };


  return (
    <ModalDialogBox
      open={openModal}
      setOpen={setOpenModal}
      type="warning"
      text={!text ? `<p>We encountered a System Issues.</p>
        <p>We are working hard to resolve the Problem.</p>
        <p>Please contact <a href="mailto:info@tydei.io">info@tydei.io</a>
        if you have any further question.</p>`: text}
      firstBtn={firstBtn}
      handleRequest={(e) => handleCloseError(e)}
      isRedIcon={isRedIcon}
    />
  );
}
