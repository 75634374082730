import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { FormControl, InputLabel, InputBase, FormHelperText, Popover, MenuList, MenuItem } from "@material-ui/core";
import searchSmIcon from '../../icons/search-sm-icon.svg';
import searchNewIcon from '../../icons/icon-park_drop-down-list.svg';
import refreshIcon from '../../icons/rotate-system-uicons_reset.svg';
import useStyles from "./styles";
import { useLayoutState } from "../../context/LayoutContext";

/**
 * New Custom TextField
 * @param {String} type "text" | "number"
 * @param {String} value 
 * @param {Function} onChange 
 * @param {Function} onSubmit 
 * @param {String} label 
 * @param {String} placeholder 
 * @param {Object} className 
 * @param {Component} startAdornment 
 * @param {Component} endAdornment 
 * @param {Object} options 
 * @param {String} warning 
 * @param {Function} checkWarnings 
 * @param {Number} width 
 * @param {Object} style 
 * @param {Boolean} required 
 * @returns 
 */
export default function CustomTextField ({
  type="text", value="", onChange, onSubmit, label, placeholder, className, startAdornment, endAdornment, options={}, 
  warning="", checkWarnings, width=200, style={}, required, 
}) {

  options = {
    disabled: false,
    labelLeft: false,       // Label position Left
    labelWidth: null,       // Label width {Number|null}
    readOnly: false,        // Read Only
    disableHelper: false,   // Disable to show Helper Text
    ...options,
  };

  const classProps = {
    labelWidth: (typeof options.labelWidth === 'number' ? options.labelWidth : 110),
  };
  const classes = useStyles(classProps);

  const [helper, setHelper] = useState(warning);
  const [readOnly, setReadOnly] = useState(options.readOnly);

  // class name list
  const layoutState = useLayoutState()
  const classnames = [layoutState.darkMode?classes.inputDM:classes.input]; 
  if (className) classnames.push(className);
  if (readOnly) classnames.push(classes.readOnly);
  if (options.labelLeft) classnames.push("label-left");
  // width
  width = options.labelLeft ? classProps.labelWidth + width : width;


  const handleChange = (event) => {
    const newVal = event.target.value;
    if (typeof onChange === 'function') {
      onChange(newVal);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (typeof onSubmit === 'function') {
        onSubmit();
      }
      if (typeof checkWarnings === 'function') {
        const warning = checkWarnings(value);
        // helper
        setHelper(warning);
      }
    }
  };

  const handleSwitch = (value=null) => {
    if (options.readOnly && !options.disabled) {
      setReadOnly(typeof value === 'boolean' ? value : !readOnly);
    }
  };


  useEffect(() => {
    setHelper(warning);
  }, [warning]);


  return (
    <FormControl 
      className={classNames(classnames)}
      style={{
        paddingTop: (label ? 16 : 0),
        ...style,
        width: width,
      }}
    >
      <InputLabel
        shrink
        htmlFor="custom-input"
      >
        {(!readOnly && required) ? <>{label}<span className={classes.asteriskStyle}>*</span></> : label }
      </InputLabel>
      <InputBase
        type={type}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        fullWidth
        disabled={(options.disabled && readOnly)?false:options.disabled}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        readOnly={readOnly}
        onDoubleClick={handleSwitch}
        onBlur={() => handleSwitch(true)}
        className={warning !== "" ? classes.errorBoxOutline : null}
      />
      <FormHelperText>
        {options.disableHelper?null:helper}
      </FormHelperText>
    </FormControl>
  );
}

/**
 * New Search TextField
 * @param {String} value 
 * @param {Function} onChange 
 * @param {Function} onSubmit 
 * @param {Object} className 
 * @param {Object} style 
 * @param {String} category 
 * @param {Function} setCategory 
 * @returns 
 */
export function SearchTextUniField({
  value, onChange, onSubmit, className, style={}, category, setCategory, 
}) {

  const classes = useStyles();

  // class name list
  const classnames = [classes.search];
  if (className) classnames.push(className);

  const [anchorEl, setAnchorEl] = useState(false);

  const onSelectCategory = (categ) => {
    if (typeof setCategory === 'function') setCategory(categ);
    setAnchorEl(null);
  };

  const clearCategory = () => {
    if (typeof onChange === 'function') onChange("");
  };


  return (
    <>
      <CustomTextField
        className={classNames(classnames)}
        value={value}
        placeholder="Search..."
        startAdornment={<img src={searchNewIcon} alt="" onClick={(event) => setAnchorEl(event.currentTarget)} />}
        endAdornment={<img src={refreshIcon} alt="" onClick={clearCategory} />}
        onChange={onChange}
        onSubmit={onSubmit}
        width={408}
        style={style}
      />

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuList className={classes.categMenu}>
          <MenuItem onClick={() => onSelectCategory('desc')} className={category === 'desc' ? 'active' : ''}>
            Description
          </MenuItem>
          <MenuItem onClick={() => onSelectCategory('lot')} className={category === 'lot' ? 'active' : ''}>
            Lot code
          </MenuItem>
          <MenuItem onClick={() => onSelectCategory('ref')} className={category === 'ref' ? 'active' : ''}>
            Ref number
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  );
}

/**
 * New Small Search TextField
 * @param {String} value 
 * @param {Function} onChange 
 * @param {Function} onSubmit 
 * @param {Object} className 
 * @param {Object} style 
 * @param {String} placeholder 
 * @param {Number} width 
 * @returns 
 */
export function SearchTextSmField({
  value, onChange, onSubmit=()=>{}, className, style={}, placeholder="Search SEID...", width=173, 
}) {

  const classes = useStyles();

  // class name list
  const classnames = [classes.search, classes.searchSm];
  if (className) classnames.push(className);

  return (
    <CustomTextField
      className={classNames(classnames)}
      value={value}
      placeholder={placeholder}
      endAdornment={<img src={searchSmIcon} onClick={onSubmit} alt=""/>}
      onChange={onChange}
      onSubmit={onSubmit}
      width={width}
      style={style}
    />
  );
}
