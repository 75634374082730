import React from "react";
import { TextField } from "@material-ui/core";
import { onChangeNumber } from "../handles";
import { useStyles } from "../styles";

export default function ACHDetailContents({
  achdetail,
  setConductForm }) {

  const classes = useStyles();
  
  // console.log("[ACHDetailContents]", achdetail);

  return (
    <>

    <div>
      <TextField
        label="Account Number"
        placeholder="Account Number"
        className={ classes.dialogField }
        value={achdetail.accountnumber || ""}
        onChange={(e) => {
          if (onChangeNumber(e)) setConductForm({ ...achdetail, accountnumber: onChangeNumber(e) })
        }}
      />
    </div>
    <div>
      <TextField
        label="Routing Number"
        placeholder="Routing Number"
        className={ classes.dialogField }
        value={achdetail.routingnumber || ""}
        onChange={(e) => {
          if (onChangeNumber(e)) setConductForm({ ...achdetail, routingnumber: onChangeNumber(e) })
        }}
      />
    </div>
    <div>
      <TextField
        label="Bank"
        placeholder="Bank Name"
        className={ classes.dialogField }
        value={achdetail.bankname || ""}
        onChange={(e) => setConductForm({ ...achdetail, bankname: e.target.value })}
      />
    </div>
    <div>
      <TextField
        label="Type"
        placeholder="Type Account"
        className={ classes.dialogField }
        value={achdetail.typeaccount || ""}
        onChange={(e) => setConductForm({ ...achdetail, typeaccount: e.target.value })}
      />
    </div>

    </>
  );
}