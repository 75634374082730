import { SurgicalEventProductModel, validateProduct, getProduct } from "./Product";
import { validateInventoryProductStatus, validateEditStatus } from './InventoryData';
// import { getArray } from "./handles";
import { isNewDesign } from "../config";



export const POInventoryData = {
  id: '',
  status: '',
  productkey: '',
  quantity: '',
};
export const validatePOInventoryData = (poinvdata) => {
  return ( poinvdata.id !== ""
    || validateInventoryProductStatus(poinvdata.status)
    || poinvdata.productkey !== ""
  );
};
export const getPOInventoryData = (poinvdata) => {
  let newpoinvdata = {};
  if (typeof poinvdata.id === "string" && poinvdata.id !== "") newpoinvdata.id = poinvdata.id;
  if (validateInventoryProductStatus(poinvdata.status)) newpoinvdata.status = poinvdata.status;
  if (typeof poinvdata.productkey === "string" && poinvdata.productkey !== "") newpoinvdata.productkey = poinvdata.productkey;
  newpoinvdata.quantity = poinvdata.quantity || "1";
  return newpoinvdata;
};

export const PODetailModel = {
  id: '',
  // podpurchaseorderid : '',
  podquantity : '',
  podproductitem : '',
  // podinventorylist : [],
  // podinvids : [],
  poddescription : '',
  podunitprice: '',
  pricechange: false,
  podreference : '',
  podcomplete : '',
  podproduct : {...SurgicalEventProductModel},
  podnotes : '',
  podtempstatus : '',
};

export const validatePODetail = (podetail) => {
  return (validateProduct(podetail.podproduct) && 
    (
      // podetail.podpurchaseorderid !== "" || 
    podetail.podquantity !== ""
    || podetail.podproductitem !== ""
    || podetail.poddescription !== ""
    || podetail.podunitprice !== ""
    || podetail.podreference !== ""
    || podetail.podcomplete !== ""
    || podetail.podnotes !== ""
    || validateEditStatus(podetail.podtempstatus))
  );
};

/**
 * get PODetailModel Object
 * @param {PODetailModel} podetail 
 * @param {Object} options 
 * @returns 
 */
export const getPODetail = (podetail, options={}) => {
  options = {
    vendor: null,
    ownership: null,
    sepproductesprice: false,
    ...options,
  };
  let newPODetail = {};
  if (typeof podetail.id === "string" && podetail.id !== "") newPODetail.id = podetail.id;
  // if (typeof podetail.podpurchaseorderid === "string" && podetail.podpurchaseorderid !== "") newPODetail.podpurchaseorderid = podetail.podpurchaseorderid;
  newPODetail.podquantity = podetail.podquantity || "1";
  if (typeof podetail.podproductitem === "string" && podetail.podproductitem !== "") newPODetail.podproductitem = podetail.podproductitem;
  // newPODetail.podinventorylist = getArray(podetail.podinventorylist, validatePOInventoryData, getPOInventoryData);
  if (typeof podetail.poddescription === "string" && podetail.poddescription !== "") newPODetail.poddescription = podetail.poddescription;
  newPODetail.podunitprice = podetail.podunitprice || "0";
  newPODetail.pricechange = !!podetail.pricechange;
  if (typeof podetail.podreference === "string" && podetail.podreference !== "") newPODetail.podreference = podetail.podreference;
  if (typeof podetail.podcomplete === "string" && podetail.podcomplete !== "") newPODetail.podcomplete = podetail.podcomplete;
  const tempproduct = getProduct(podetail.podproduct, options);
  if (validateProduct(tempproduct)) newPODetail.podproduct = tempproduct;
  if (typeof podetail.podnotes === "string" && podetail.podnotes !== "") newPODetail.podnotes = podetail.podnotes;
  if (validateEditStatus(podetail.podtempstatus)) newPODetail.podtempstatus = podetail.podtempstatus;
  return newPODetail;
};

/**
 * compare two PO details
 * quantity, price, ownership maybe different.
 * @param {Object} podetail1 
 * @param {Object} podetail2 
 * @returns {Boolean}
 */
export const comparePODetail = (podetail1, podetail2) => {
  return (
    podetail1.id === podetail2.id
    && podetail1.podquantity === podetail2.podquantity
    && (podetail1.podproduct && typeof podetail1.podproduct === 'object')
    && (podetail2.podproduct && typeof podetail2.podproduct === 'object')
    && podetail1.podproduct.sepproductprice === podetail2.podproduct.sepproductprice
    && podetail1.podproduct.sepownership === podetail2.podproduct.sepownership
  );
};

export const createPODetail = ({
  podetail, vendor=null, ownership=null, seid="", notes="", quantity=1,
}) => {
  if (!isNaN(quantity) && quantity > 1) podetail.podquantity = quantity;
  else if (!podetail.podquantity) podetail.podquantity = 1;
  if (podetail.podproduct) {
    if (!podetail.podproductitem && !!podetail.podproduct.sepreferencenumber) podetail.podproductitem = podetail.podproduct.sepreferencenumber;
    if (!podetail.poddescription && !!podetail.podproduct.sepproductdescription) podetail.poddescription = podetail.podproduct.sepproductdescription;
    if (!podetail.podunitprice && !!podetail.podproduct.sepproductprice) podetail.podunitprice = podetail.podproduct.sepproductprice;
  }
  if (!podetail.podreference && !!seid) podetail.podreference = seid;
  if (!podetail.podcomplete) podetail.podcomplete = "No";
  if (!!notes) podetail.podnotes = notes;
  return getPODetail(podetail, {vendor, ownership});
};

export const convertPODetailToCSV = (podetails=[]) => {
  

  const header = (
    isNewDesign
    ?
    [
      { label: "Ref No", key: "sepreferencenumber" },
      { label: "Lot/SN", key: "seplotcode" },
      { label: "Name", key: "sepproductname" },
      { label: "Description", key: "sepproductdescription" },
      { label: "Distributor", key: "sepdistributor" },
      { label: "Type", key: "sepproducttype" },
      { label: "Price", key: "sepproductprice" },
      { label: "UoM", key: "sepuom" },
      { label: "Quantity", key: "podquantity" },
    ]
    :
    [
      { label: "ReferenceNumber", key: "sepreferencenumber" },
      { label: "Manufacturer", key: "sepproductmanufacturer" },
      { label: "Lot/SN", key: "seplotcode" },
      { label: "ProductType", key: "sepproducttype" },
      { label: "Product Description", key: "sepproductdescription" },
      { label: "Price", key: "sepproductprice" },
      { label: "Quantity", key: "podquantity" },
    ]
  );
  const data = podetails.map(podetail => {
    return header.reduce((d, h) => {
      d[h.key] = (h.key in podetail) ? (podetail[h.key] ? podetail[h.key] : '') 
        : ((h.key in podetail.podproduct && podetail.podproduct[h.key]) ? podetail.podproduct[h.key] : '');
      return d;
    }, {});
  });
  return {header, data ,};
};
