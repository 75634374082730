import React from "react";
import { Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import { formatDate } from "../../services/util";
import { ProductTypes, Sides, WasteTypes } from "../../models/Product";
import { Ownerships } from "../../models/Ownership";
import useStyles from "./styles";
import classNames from 'classnames';


/**
 * Product Detail View Table 
 * @param {Object} obj
 * @param {Object} options
 */
export default function ProductDetailViewTable({
  obj = {}, options = {},
}) {

  let objList = [];
  // check if obj is list of Products
  if (typeof obj === 'object' && obj.length > 0) {
    objList = obj;
    obj = obj[0];
  }
  else {
    objList = [obj];
  }
  console.log("[ProductDetailViewTable]", obj, objList);

  options = {
    hiddenParams: [], // e.g. 'sepdistributor'
    enableParams: [], // e.g. 'sepreferencenumber'
    extraParams: [], // e.g. {label: '', value: '', className: undefined, position: 'top|bottom'}
    redParams: [], // show cell with red color, e.g. 'sepproductprice'
    ...options,
  };

  const classes = useStyles();

  return (
    <Table className="product-detail-data" aria-label="simple table">
      <TableBody>
        {(options.extraParams.length > 0) && options.extraParams.filter(({ position }) => (position === 'top'))
          .map(({ label, value, className }, index) => (
            <TableRow key={index} className={className}>
              <TableCell className={classes.tableCellHead}>{label}</TableCell>
              <TableCell
                className={options.redParams.indexOf(label) !== -1 ? classes.tableCellRed : classes.tableCell}
              >
                {value}
              </TableCell>
            </TableRow>
          ))}
        {(options.hiddenParams.indexOf('sepudi') === -1) &&
          <TableRow>
            <TableCell className={classNames(classes.tableCellHead)}>UDI</TableCell>
            <TableCell
              className={classNames(options.redParams.indexOf('sepudi') !== -1 ? (classes.tableCellRed) : (classes.tableCell))}>
              {obj.sepudi}
            </TableCell>
          </TableRow>
        }
        {(options.enableParams.indexOf('sepreferencenumber') !== -1) &&
          <TableRow>
            <TableCell className={classNames(classes.tableCellHead)}>Reference Number</TableCell>
            <TableCell
              className={options.redParams.indexOf('sepreferencenumber') !== -1 ? classes.tableCellRed : classes.tableCell}
            >
              {obj.sepreferencenumber}
            </TableCell>
          </TableRow>
        }
        {(options.hiddenParams.indexOf('sepproductmanufacturer') === -1) &&
          <TableRow>
            <TableCell className={classNames(classes.tableCellHead)}>Product Manufacturer</TableCell>
            <TableCell
              className={options.redParams.indexOf('sepproductmanufacturer') !== -1 ? classes.tableCellRed : classes.tableCell}
            >
              {obj.sepproductmanufacturer}
            </TableCell>
          </TableRow>
        }
        {(options.hiddenParams.indexOf('sepdistributor') === -1) &&
          <TableRow>
            <TableCell className={classes.tableCellHead}>Product Distributor</TableCell>
            <TableCell
              className={options.redParams.indexOf('sepdistributor') !== -1 ? classes.tableCellRed : classes.tableCell}
            >
              {obj.sepdistributor}
            </TableCell>
          </TableRow>
        }
        {(options.hiddenParams.indexOf('sepproductname') === -1) &&
          <TableRow>
            <TableCell className={classes.tableCellHead}>Product Name</TableCell>
            <TableCell
              className={options.redParams.indexOf('sepproductname') !== -1 ? classes.tableCellRed : classes.tableCell}
            >
              {obj.sepproductname}
            </TableCell>
          </TableRow>
        }
        {(options.hiddenParams.indexOf('sepproductdescription') === -1) &&
          <TableRow>
            <TableCell className={classes.tableCellHead}>Product Description</TableCell>
            <TableCell
              className={options.redParams.indexOf('sepproductdescription') !== -1 ? classes.tableCellRed : classes.tableCell}
            >
              {obj.sepproductdescription}
            </TableCell>
          </TableRow>
        }
        {(options.hiddenParams.indexOf('sepproductexpiration') === -1) &&
          <TableRow>
            <TableCell className={classes.tableCellHead}>Expiration</TableCell>
            <TableCell
              className={options.redParams.indexOf('sepproductexpiration') !== -1 ? classes.tableCellRed : classes.tableCell}
            >
              {formatDate(obj.sepproductexpiration)}
            </TableCell>
          </TableRow>
        }
        {(options.hiddenParams.indexOf('sepproductprice') === -1) &&
          <TableRow>
            <TableCell className={classes.tableCellHead}>Price</TableCell>
            <TableCell
              className={options.redParams.indexOf('sepproductprice') !== -1 ? classes.tableCellRed : classes.tableCell}
            >
              ${obj.sepproductprice}
            </TableCell>
          </TableRow>
        }
        {(options.hiddenParams.indexOf('seplotcode') === -1) &&
          <TableRow>
            <TableCell className={classes.tableCellHead}>Lot/SN</TableCell>
            <TableCell
              className={options.redParams.indexOf('seplotcode') !== -1 ? classes.tableCellRed : classes.tableCell}
            >
              {
                objList.length === 1
                  ?
                  obj.seplotcode
                  :
                  objList.map((o, i) => <p key={i} style={{ margin: '10px 0' }}>{o.seplotcode}</p>)
              }
            </TableCell>
          </TableRow>
        }
        {(options.hiddenParams.indexOf('sepproducttype') === -1) &&
          <TableRow>
            <TableCell className={classes.tableCellHead}>Product Type</TableCell>
            <TableCell
              className={options.redParams.indexOf('sepproducttype') !== -1 ? classes.tableCellRed : classes.tableCell}
            >
              {ProductTypes[obj.sepproducttype]}
            </TableCell>
          </TableRow>
        }
        {(options.hiddenParams.indexOf('sepproductwaste') === -1) &&
          <TableRow>
            <TableCell className={classes.tableCellHead}>Waste</TableCell>
            <TableCell
              className={options.redParams.indexOf('sepproductwaste') !== -1 ? classes.tableCellRed : classes.tableCell}
            >
              {WasteTypes[obj.sepproductwaste]}
            </TableCell>
          </TableRow>
        }
        {(options.hiddenParams.indexOf('sepownership') === -1) &&
          <TableRow>
            <TableCell className={classes.tableCellHead}>Ownership</TableCell>
            <TableCell
              className={options.redParams.indexOf('sepownership') !== -1 ? classes.tableCellRed : classes.tableCell}
            >
              {Ownerships[obj.sepownership]}
            </TableCell>
          </TableRow>
        }
        {(options.hiddenParams.indexOf('sepproductside') === -1) &&
          <TableRow>
            <TableCell className={classes.tableCellHead}>Product Side</TableCell>
            <TableCell
              className={options.redParams.indexOf('sepproductside') !== -1 ? classes.tableCellRed : classes.tableCell}
            >
              {Sides[obj.sepproductside]}
            </TableCell>
          </TableRow>
        }
        {(options.extraParams.length > 0) && options.extraParams.filter(({ position }) => (position !== 'top'))
          .map(({ label, value, className }, index) => (
            <TableRow key={index} className={className}>
              <TableCell className={classes.tableCellHead}>{label}</TableCell>
              <TableCell
                className={options.redParams.indexOf(label) !== -1 ? classes.tableCellRed : classes.tableCell}
              >
                {value}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}
