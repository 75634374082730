import React, { useState } from "react";
import classNames from "classnames";
import { Grid, Typography, AppBar, Tabs, Tab } from "@material-ui/core";
import TabPanel, { a11yProps } from "../../components/Modals/MultiTabsModals/TabPanel";
import useStyles from "../showlist/styles";
import useStyles1 from "../surgicalevent/styles";
import ProfileInfo from "./profileInfo";
import SnackbarModal from "../../components/Modals/SnackbarModal";


export default function UserProfile() {
    // for states
    const [value, setValue] = useState(0);
    const [openToast, setOpenToast] = useState(false);
    const [toastContent, setToastContent] = useState(false);
    const [snackType, setSnackType] = useState('');
    // for styles
    const classes = useStyles();
    const classes1 = useStyles1();

    // for tabs
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Grid>
                <Grid container className={classes.newContainer} justifyContent="space-between" >
                    <Grid item xs={12}>
                        <Typography variant="h4" className={classes.activeProfileHeadline}>My Profile</Typography>
                    </Grid>
                    {
                        <>
                            <Grid item xs={12}
                                className={classes.centerPanel}
                            >
                                <AppBar position="static"
                                    style={{ borderBottom: "#D0D5DD 1px solid" }}
                                >
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        className={classes.profileTab}>
                                        <Tab label="My Info" className={classes.profileTabTitle}
                                            {...a11yProps(0)}
                                        />
                                        <Tab label="Notifications" className={classes.profileTabTitle}
                                            {...a11yProps(2)}
                                        />
                                    </Tabs>
                                </AppBar>
                                <TabPanel value={value} index={0} className={classNames(classes1.tabPanelbody1)}>
                                    <Grid container item sx={12} style={{ height: "70vh" }}>
                                        <ProfileInfo
                                            setOpenToast={setOpenToast}
                                            setToastContent={setToastContent}
                                            setSnackType={setSnackType}
                                        />
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={2} className={classNames(classes1.tabPanelbody1)}>
                                    <Grid container item sx={12} style={{ height: "70vh" }}>
                                        {/* <UserNotificationPanel /> */}
                                    </Grid>
                                </TabPanel>
                            </Grid>
                        </>
                    }

                </Grid>
            </Grid>
            <SnackbarModal
                type={snackType}
                open={openToast}
                setOpen={setOpenToast}
                title=""
                content={toastContent}
                width={400}
                height={88}
                className={classes1.toastMedium}
            />
        </>
    );
}