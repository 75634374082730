import React, { useState, useEffect } from "react";
import { Grid, Stepper, Step, StepLabel, StepContent, Button, Typography } from '@material-ui/core';
import { useLedger, useParty } from "@daml/react";
import { OnboardNetwork, ManagerInvitation, OperatorRole } from "../../services/daml-modules1";
import { getOperatorParty } from "../../services/axios/daml-json-api";
import OnboardNetworkContents from "../../components/Modals/OnboardNetworkModal/contents";
import { OnboardNetworkModel, validateOnboardNetwork } from "../../models/OnboardNetwork";
import { DefaultOperatorParty } from "../../config";
import DamlQuery from "../../components/Daml/DamlQuery";
import { getTodayString } from "../../services/util";
import useStyles from "./styles";


export default function Manager() {


  const classes = useStyles();

  const ledger = useLedger();
  const party = useParty();

  const [queryResult, setQueryResult] = useState({});
  const [queryResultInvitations, setQueryResultInvitations] = useState({});
  const [queryResultRoles, setQueryResultRoles] = useState({});

  const [operator, setOperator] = useState(null);
  const [networkdetail, setNetworkDetail] = useState({ ...OnboardNetworkModel, companytype: 'Operator' });
  const [networkId, setNetwork] = useState(null);
  const [operatorInvitation, setOperatorInvitation] = useState(null);
  const [operatorRole, setOperatorRole] = useState(null);
  const [activeStep, setActiveStep] = useState(0);


  const getSteps = () => {
    return ['Load Operator Party', 'Create OnboardNetwork', 'Create Operator PartyInvitation', 'Create Operator Role'];
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <Typography>Operator Identifier : {operator}</Typography>
            <Typography variant="overline">This party is loaded from DAML.</Typography>
            <Grid item sm={8} md={6}>
              <OnboardNetworkContents
                networkdetail={networkdetail}
                setConductForm={setNetworkDetail}
                isTypeEditable={false}
                isOperatorEnable={true}
              />
            </Grid>
          </>);
      case 1:
        return (
          <>
            <Typography>OnboardNetwork ID : {networkId}</Typography>
            <Typography variant="overline">This contract is created by Party:{operator}.</Typography>
          </>);
      case 2:
        return (
          <>
            <Typography>Operator PartyInvitation : {operatorInvitation}</Typography>
            <Typography variant="overline">This Operator PartyInvitation is created by Network:{networkId}.</Typography>
          </>);
      case 3:
        return (
          <>
            <Typography>Operator Role : {operatorRole}</Typography>
            <Typography variant="overline">This Operator Role is created by Party:{operator}, Network:{networkId}, PartyInvitation:{operatorInvitation}.</Typography>
            <Typography variant="overline">This Operator Role is used for manager & operator.</Typography>
          </>);
      default:
        return null;
    }
  };

  const steps = getSteps();

  const validateStep = (step) => {
    switch (step) {
      case 0:
        return !!operator;
      case 1:
        return !!networkId;
      case 2:
        return !!operatorInvitation;
      case 3:
        return !!operatorRole;
      default:
        return true;
    }
  };

  const createOnboardNetwork = async () => {
    console.log("[Manager] createOnboardNetwork");
    if (!!!(queryResult.contracts && queryResult.contracts.length)) {
      if (validateOnboardNetwork(networkdetail)) {
        let res = await ledger.create(OnboardNetwork, {
          party,
          operator,
          name: networkdetail.name,
          email: networkdetail.email,
          company: networkdetail.company,
          companytype: networkdetail.companytype,
          created_at: getTodayString(true),
        });

        console.log("[Manager] createOnboardNetwork", res);

        setNetwork(res.contractId);
      }
    }
    else {
      setNetwork(queryResult.contracts[0].contractId);
    }
  };

  const createOperatorInvitation = async () => {
    console.log("[Manager] createOperatorInvitation");
    if (!!!(queryResultInvitations.contracts && queryResultInvitations.contracts.length)) {
      let res = await ledger.exercise(OnboardNetwork.InviteManager, networkId, {
        party: DefaultOperatorParty,
        roletype: 'Operator'
      });
      console.log("[Manager] createOperatorInvitation", res);
      setOperatorInvitation(res[0]);
    }
    else {
      setOperatorInvitation(queryResultInvitations.contracts[0].contractId);
    }
  };

  const createOperatorRole = async () => {
    console.log("[Manager] createOperatorRole");
    if (!!!(queryResultRoles.contracts && queryResultRoles.contracts.length)) {
      let res = await ledger.exercise(ManagerInvitation.AcceptOperatorInvitation, operatorInvitation, {});
      console.log("[Manager] createOperatorRole", res);
      setOperatorRole(res[0]);
    }
    else {
      setOperatorRole(queryResultRoles.contracts[0].contractId);
    }
  };

  const handleNext = () => {
    if (activeStep === 0) createOnboardNetwork();
    else if (activeStep === 1) createOperatorInvitation();
    else if (activeStep === 2) createOperatorRole();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };


  useEffect(() => {
    let isMounted = true;
    Promise.all([getOperatorParty()]).then(data => {
      if (isMounted) setOperator(data[0]);
    })
    return () => { isMounted = false };
  }, []);



  return (
    <>
      <Grid container>
        <Stepper activeStep={activeStep} orientation="vertical" className={classes.fullPaper}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {getStepContent(index)}
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                      disabled={!validateStep(activeStep)}
                    >
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <div className={classes.resetContainer}>
            <Typography>Install Success</Typography>
            <Typography>Please log out and login again.</Typography>
          </div>
        )}
      </Grid>

      <DamlQuery
        template={OnboardNetwork}
        indexKey={'OnboardNetwork'}
        setValues={setQueryResult}
      />
      <DamlQuery
        template={ManagerInvitation}
        indexKey={'ManagerInvitation'}
        setValues={setQueryResultInvitations}
      />
      <DamlQuery
        template={OperatorRole}
        indexKey={'OperatorRole'}
        setValues={setQueryResultRoles}
      />

    </>
  );
};
