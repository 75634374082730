import { HospitalRole, VendorRole } from "../../services/daml-modules1";
import { getTemplateListFromResponse } from "../../services/util";
import { loggerPush, LOGGER_INFO, LOGGER_ERROR } from '../../services/logger';
import { validateInventoryData, getInventoryData } from "../../models/InventoryData";



/**
 * create Return Inventory
 * exercise CreateReturnInventory choice of VendorRole
 * It's called when it can't find out matched Return Inventory, so it will create new Return Inventory.
 * @param {Object} ledger 
 * @param {String} hospital 
 * @param {Object} inventorydata 
 * @param {String} roleCid 
 * @returns {Object|null}
 */
export const createReturnInventoryVendor = async ({
  ledger, hospital, inventorydata = {}, roleCid,
}) => {

  console.log("[createReturnInventoryVendor]", roleCid, hospital, inventorydata);

  try {

    if (roleCid) {

      let res = await ledger.exercise(VendorRole.CreateReturnInventory, roleCid, {
        hospital,
        inventorydata: getInventoryData(inventorydata),
      });
      console.log("[createReturnInventoryVendor]", res);

      // log the response to loggly
      loggerPush({ type: LOGGER_INFO, action: 'Inven: CreateReturnInventory', response: res });

      return res;
    }

  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Inven: CreateReturnInventory', payload: e, params: inventorydata });
    console.log("[createReturnInventoryVendor]", e);
  }

  return null;

};

/**
 * confirm Return InventoryData in Hospital
 * exercise ChangeReturnInventoryStatus choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} inventoryCid 
 * @param {Object} inventorydata 
 * @param {String} updateddate 
 * @param {String} roleCid 
 * @returns {Boolean}
 */
export const confirmReturnInventoryHospital = async ({
  ledger, inventoryCid, inventorydata, updateddate=null, roleCid, 
}) => {

  console.log("[confirmReturnInventoryHospital] ", inventoryCid, inventorydata);

  try {
    if (roleCid && validateInventoryData(inventorydata)) {

      let res = await ledger.exercise(HospitalRole.ChangeReturnInventoryStatus, roleCid, {
        inventorycid: inventoryCid,
        newinventorydata: getInventoryData(inventorydata),
        updateddate,
      });
      console.log("[confirmReturnInventoryHospital]", res);

      // log the response to loggly
      loggerPush({ type: LOGGER_INFO, action: 'Inven: ChangeReturnInventoryStatus', response: res });

      // get contractId of the created Inventory
      let templatePO = getTemplateListFromResponse(res, "ReturnInventory");
      if (templatePO.length) {
        return templatePO[0].contractId;
      }
    }
  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Inven: ChangeReturnInventoryStatus', payload: e, params: inventorydata });
    console.log("[confirmReturnInventoryHospital] ", e);
  }

  return false;

};

/**
 * receive Return Inventory in Vendor with selected InventoryData
 * exercise VInvenReceived choice of VendorRole
 * @param {Object} ledger 
 * @param {String} hospital 
 * @param {String} inventoryCid 
 * @param {Object} inventorydata 
 * @param {String} updateddate
 * @param {String} roleCid 
 * @returns {Boolean}
 */
export const receiveInventoryVendor = async ({
  ledger, hospital, inventoryCid, inventorydata, updateddate=null, roleCid,
}) => {

  console.log("[receiveInventoryVendor] ", hospital, inventorydata);

  try {
    if (roleCid && validateInventoryData(inventorydata)) {
      
      let res = await ledger.exercise(VendorRole.VInvenReceived, roleCid, {
        hospital,
        inventorycid: inventoryCid,
        newinventorydata: getInventoryData(inventorydata),
        updateddate,
      });
      console.log("[receiveInventoryVendor]", res);

      // log the response to loggly
      loggerPush({ type: LOGGER_INFO, action: 'Inven: VInvenReceived', response: res });

      // get contractId of the created Inventory
      let templatePO = getTemplateListFromResponse(res, "ReturnInventory");
      if (templatePO.length) {
        return true;
      }
    }
  }
  catch (e) {
    // log the error to loggly
    loggerPush({ type: LOGGER_ERROR, action: 'Inven: VInvenReceived', payload: e, params: inventorydata });
    console.log("[receiveInventoryVendor] ", e);
  }

  return false;

};
