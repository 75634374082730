import React from "react";
import classNames from "classnames";
import { Table, TableBody, TableRow, TableCell, Grid } from "@material-ui/core";
import { AddedProductList } from "../showlist/surgicalevent-details";
import useStyles from "../showlist/styles";
import moment from 'moment-timezone';
import AuditDetails from "./auditDetails";


/**
 * Overview Purchase Order
 * @param {Object} options 
 * @param {Object} poData 
 * @returns 
 */
export default function AuditDetailsProduct({
  options= {}, poData,
}) {

  options = {
    isEditable: false,
    ...options,
  };

  const classes = useStyles();
 

  const poDetails = poData.logEvents.value.poData;

  const createProductListFromPO = (poData) => {
    return poData.map((obj) => ({ 'sepreferencenumber': obj.podproduct.sepreferencenumber, 'seplotcode': obj.podproduct.seplotcode, 'sepproductdescription': obj.podproduct.sepproductdescription, 'sepproductprice': obj.podproduct.sepproductprice, 'sepudi': obj.podproduct.sepudi, 'sepproductmanufacturer': obj.podproduct.sepproductmanufacturer, 'sepdistributor': obj.podproduct.sepdistributor, 'sepproductname': obj.podproduct.sepproductname, 'sepproductexpiration': obj.podproduct.sepproductexpiration, 'sepproducttype': obj.podproduct.sepproducttype, 'sepproductwaste': obj.podproduct.sepproductwaste, 'sepownership': obj.podproduct.sepownership, 'sepproductside': obj.podproduct.sepproductside }))
  }
  const time = moment(poData.created_at).tz(moment.tz.guess()).format('LT z');


  return (
    <Grid container>
      <Grid container direction="row" alignItems="center" className={classNames(classes.overviewAuditLog, classes.auditLogPanel)}>
        <Grid item xs={6} className={classes.overviewAuditLogPO} >
          <Table className={classes.auditLogForm}>
            <TableBody>
              <TableRow>
                <TableCell colSpan={2} className="new-subtitle" style={{ paddingTop: 0 }}>PO Details</TableCell>
              </TableRow>

              <>
              <TableRow>
                  <TableCell className="label">PO Number</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="value">{poDetails.purchaseorderdata.popurchaseorderid}</TableCell>
               
                </TableRow>
                <TableRow>
                  <TableCell className="label">PO Type</TableCell>
                  <TableCell className="label">Distributor</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="value">{poDetails.purchaseorderdata.potype}</TableCell>
                  <TableCell className="value">{poDetails.purchaseorderdata.povendorid}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">PO Date</TableCell>
                  <TableCell className="label">Ownership</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="value">{poDetails.purchaseorderdata.podate}</TableCell>
                  <TableCell className="value">{poDetails.purchaseorderdata.popurchaseordertype}</TableCell>
                </TableRow>
              </>
            </TableBody>
          </Table>

          <AuditDetails
            poData={poData}
            time={time}
          />


        </Grid>
        <Grid item xs={6}>
          <AddedProductList
            className={classes.completePanel}
            seData={createProductListFromPO(poDetails.purchaseorderdetaildata)}
            options={{
              isEnableCollapse: true,
              isEnableEditProduct: false,
              isEnableDeleteProduct: false,
            }}
          />
        </Grid>

      </Grid>
    </Grid>
  );
}
