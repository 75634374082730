import { useCallback } from "react";
import { useSelector } from "react-redux";
import { dispatch } from "../store";
import { setInventory, setInventoryAll, setRestockInventory, setAccountInventory } from "../store/actions/inventoryAction";


/**
 * Restock Inventory Hook
 * @returns 
 */
export default function useInventory () {
  const restockInventoryData = useSelector(state => state.newInventory.restockInventoryData);
  const restockInventoryErrors = useSelector(state => state.newInventory.restockInventoryErrors);

  const setAll = useCallback((data) => dispatch(setInventoryAll(data)), []);
  const setInventoryData = useCallback((data) => dispatch(setInventory(data)), []);
  const setRestockInventoryData = useCallback((data) => dispatch(setRestockInventory(data)), []);
  const setRestockInventoryErrors = useCallback((data) => dispatch(setInventory({restockInventoryErrors: data})), []);

  return { restockInventoryData, setAll, setInventoryData, setRestockInventoryData, restockInventoryErrors,
    setRestockInventoryErrors };
}

/**
 * Account Inventory Hook
 * @returns 
 */
export function useAccountInventory () {
  const accountInventoryData = useSelector(state => state.newInventory.accountInventoryData);
  const accountInventoryErrors = useSelector(state => state.newInventory.accountInventoryErrors);

  const setAccountInventoryData = useCallback((data) => dispatch(setAccountInventory(data)), []);
  const setAccountInventoryErrors = useCallback((data) => dispatch(setInventory({accountInventoryErrors: data})), []);

  return { accountInventoryData, setAccountInventoryData, accountInventoryErrors, setAccountInventoryErrors };
}
