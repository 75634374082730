import React from "react";
import { Grid, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import CheckBoxList from "../../CheckBoxList";
import { useStyles } from "../styles";


export default function ConfirmListContents({
  contractList = [],
  contractKeys = [],
  contractKeyLabels = [],
  isCheckBoxVisible = true,
  isCheckBoxLeft = true,
  isCheckNotSwitch = true,
  isHeaderVisible = false,
  switchLabels = [],
  setSelectedList = null,
  selected = [],
  setSelected = null }) {

  const classes = useStyles();

  // console.log("[ConfirmListContents] selected", selected);


  const CheckBoxCell = ({isHead=false, isLeft=true, ...props}) => {
    return (
      isCheckBoxVisible && !(isLeft ^ isCheckBoxLeft)
      ?
      <TableCell className={classes.tableCell} key='check'>
        {
          isHead
          ?
          null
          :
          <CheckBoxList
            {...props}
            selected={selected}
            contracts={contractList}
            isCheckNotSwitch={isCheckNotSwitch}
            labelOn={switchLabels[0] || ""}
            labelOff={switchLabels[1] || ""}
            setSelected={(selected, contracts) => {
              setSelected(selected);
              setSelectedList(contracts);
            }}
          />
        }
      </TableCell>
      :
      null
    );
  };
  

  return (
    <>
      <Grid item xs={12}>
        <Table size="small">

          <TableHead>
            {
              isHeaderVisible && contractList.length > 0
              ?
              <TableRow className={classes.tableRow}>
                <CheckBoxCell isHead={true} />
                {contractKeys.map((key, j) => (
                  <TableCell key={"cell" + j} className={classes.tableCell}>
                    {contractKeyLabels[j] || key}
                  </TableCell>
                ))}
                <CheckBoxCell isHead={true} isLeft={false} />
              </TableRow>
              :
              null
            }
          </TableHead>

          <TableBody>
            {
              contractList.length > 0
              ?
              contractList.map((c, i) => (
                <TableRow key={"row" + i} className={classes.tableRow}>

                  <CheckBoxCell index={i} />
                  
                  {contractKeys.map((key, j) => {
                    let val = null;
                    try {
                      val = c[key].toString();
                    }
                    catch(e) {
                    }
                    return (
                      <TableCell key={"cell" + j} className={classes.tableCell}>
                        {val}
                      </TableCell>
                    );
                  })}

                  <CheckBoxCell index={i} isLeft={false} />

                </TableRow>
              ))
              :
              <TableRow>
                <TableCell className={classes.tableCell}>
                  There is nothing to confirm. Try again.
                </TableCell>
              </TableRow>
            }
          </TableBody>

        </Table>
      </Grid>
    </>
  );
}