import React, { useState } from "react";
import { Avatar, Button, Checkbox, Dialog, Grid,List, ListItem, ListItemIcon, ListItemText, 
  TextField } from "@material-ui/core";
import { GridSearchIcon } from "@material-ui/data-grid";
import useStyles from "./styles";


export default function GroupChatForm ({
  open,
  setOpen,
  userSelection,
  selectUsers,
  setSelectedUsers,
  usersList
}) {
  
  const classes = useStyles();
  const[userItems, setUserItems] = useState({});
  // disable validation once input each field
  // handleValueFill = () => {};
  console.log("selectUsers",selectUsers);

  return (
    <Dialog
        className={classes.customRoot}
        open={open}
        onClose={()=>{setOpen(false)}}
        aria-labelledby="draggable-dialog-title"
      >
      <div className="formsteps">
        <h1  className={classes.formHeading}>
            Create Group
        </h1>
        
        <div className={classes.AddEditMessageForm}>
        <div className="headerForm">
        <div className={classes.marginBM30}>
            <TextField
                id="input-with-icon-adornment1"
                label="Name"
                  placeholder="BioMed Group"
                className={classes.customFullWidth}
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(e) => {
                    setUserItems({
                      ...userItems,
                      api_index: e.target.value,
                    });
                  }}
                variant="outlined"
                />  
          </div>
          <div className={classes.marginBM30}>
                <Grid item xs={12} className={classes.GroupsearchForm}>
                <TextField
                id="input-with-icon-adornment2"
                  placeholder="Search by name"
                className={classes.customFullWidth}
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
                
                variant="outlined"
                />  
                  <GridSearchIcon className={classes.searchIconForm}/>
              </Grid>
          </div>
          <Grid item xs={12} className={classes.selectedAvatar}>
              {selectUsers.map((obj,index)=>(
                  <div key={index} className={classes.usersAdded}><Avatar  >{obj.shortName} <span className={classes.removeSelection}>x</span></Avatar></div>
              ))}
              
          </Grid>
          </div> 
              <div className={classes.userList}>
                      <List className={classes.userLists}>
                          { usersList.map((obj,index)=>(
                              <ListItem button key={index}>
                              <ListItemIcon>
                              <div className={classes.chatList}><Avatar>H</Avatar> <span className={classes.online}>.</span></div>
                                  </ListItemIcon>
                                  <ListItemText primary={obj.name}  secondary={obj.desgination}>{obj.name}</ListItemText>
                                  <ListItemText secondary="" align="right">
                                  <Checkbox
                                  value = {obj.shortName}
                                  checked={obj.checked}
                                  color="primary"
                                  className={classes.customCheckbox}
                                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                                  onChange={(e)=>{userSelection(e,index)}}
                                  />
                              </ListItemText>
                              </ListItem>
                          ))}
                          
                  </List>         
                      
              </div> 
          
        </div>
      </div>
        <div className={classes.formFooter}>
        <div className="right-buttons">
          <Button autoFocus onClick={()=>{setOpen(false)}} className="noBtn" variant="text"  color="primary">
          Cancel
          </Button>
          <Button onClick={(e)=>{}} className="yesBtn" variant="contained" color="primary">
          Create
          </Button>
        </div>
      </div>
    
    </Dialog>
  );
}
           