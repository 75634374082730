import { HospitalRole } from "../../services/daml-modules1";
import { getTemplateListFromResponse } from "../../services/util";


/**
 * create new RelationshipOffer
 * exercise CreateVendorInvite choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} vendor
 * @param {String} roleCid 
 * @returns {Boolean}
 */
export const createRelationshipOffer = async ({
    ledger, vendor, roleCid,
}) => {
    console.log("[createRelationshipOffer]", vendor);

    if (!vendor) return false;

    if (!roleCid) {
        console.log("query Result has not contracts.");
        return false;
    }

    try {
        console.log("start Ledger Exercise");
    
        let res = await ledger.exercise(HospitalRole.CreateVendorInvite, roleCid, { 
            vendor,
        });

        console.log("[createRelationshipOffer]", res);

        // get contractId of the created RelationshipOffer
        let templateRelationshipOffer = getTemplateListFromResponse(res, "RelationshipOffer");

        return (templateRelationshipOffer.length > 0);
    } catch (e) {
        console.log("error caught");
        console.error(e);
    }

    return false;
};

/**
 * update old RelationshipOffer
 * exercise HRelationOfferUpdate choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} contractId
 * @param {String} vendor
 * @param {String} roleCid 
 * @returns {Boolean}
 */
export const updateRelationshipOffer = async ({
    ledger, contractId, vendor, roleCid,
}) => {
    console.log("[updateRelationshipOffer]", vendor);

    if (!vendor || !contractId) return false;

    try {
        console.log("start Ledger Exercise");
    
        let res = await ledger.exercise(HospitalRole.HRelationOfferUpdate, roleCid, { 
            relationcid: contractId,
            newvendor: vendor,
        });

        console.log("[updateRelationshipOffer]", res);

        // get contractId of the created RelationshipOffer
        let templateRelationshipOffer = getTemplateListFromResponse(res, "RelationshipOffer");

        return (templateRelationshipOffer.length > 0);
    } catch (e) {
        console.log("error caught");
        console.error(e);
    }

    return false;
};
