import React, { useState } from "react";
import { TextField, IconButton, Typography, Grid, Select, FormControl, InputLabel } from "@material-ui/core";
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";
import { ContactModel, validateContact, getGenders } from "../../../models/Contact";
import { resetConductForm, increaseIndexOfList, decreaseIndexOfList, addConduct } from "../handles";
import MultipleButton from "../MultipleButton";
import { useStyles } from "../styles";


export default function ContactDetailContents({
  contactdetail = {},
  setConductForm = null,
  isMultiple = false,
  multipleButtontitle = "Add Additional User",
  contactList = [],
  setContactList = null,
  defaultContact = {},
  isCustomerService = false }) {

  const classes = useStyles();

  const [index, setIndex] = useState(0);

  // multiple contact list
  if (isMultiple) {
    if (index < contactList.length) contactdetail = contactList[index];

    setConductForm = (newContact) => resetConductForm(newContact, contactList, setContactList, index);
  }


  const increaseIndex = () => increaseIndexOfList(index, setIndex, contactList);

  const decreaseIndex = () => decreaseIndexOfList(index, setIndex, contactList);

  // add new contact detail to contact list
  const addContact = (data) => addConduct(data, contactList, setContactList, {...ContactModel, ...defaultContact}, increaseIndex);

  // console.log("[ContactDetailContents]", contactList, contactdetail, index);


  return (
    <>

      {
        isMultiple && 
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.topBar}
        >
          <Grid item>
            <MultipleButton 
              multipleButtontitle={multipleButtontitle}
              conductdetail={contactdetail}
              setConductForm={setConductForm}
              conductList={contactList}
              setConductList={setContactList}
              defaultConduct={{ ...ContactModel, ...defaultContact }}
              addConduct={addContact}
              validateConduct={validateContact}
            />
          </Grid>

          <Grid item>
            <Typography variant="inherit" className={classes.contactPagination}>
              [ {index+1} / {contactList.length} ]
            </Typography>
            <IconButton
              color="primary"
              onClick={decreaseIndex}
            >
              <KeyboardArrowUp />
            </IconButton>
            <IconButton
              color="primary"
              onClick={increaseIndex}
            >
              <KeyboardArrowDown />
            </IconButton>
          </Grid>
        </Grid>
      }

      {
        isCustomerService
        ?
        <div>
          <TextField
            label="Customer Service Name"
            placeholder="Customer Service Name"
            className={ classes.dialogField }
            value={contactdetail.customerservicename || ""}
            onChange={(e) => setConductForm({ ...contactdetail, customerservicename: e.target.value })}
          />
        </div>
        :
        <>
          <div>
            <TextField
              label="Last Name"
              placeholder="Last Name"
              className={ classes.dialogField }
              value={contactdetail.lastname}
              onChange={(e) => setConductForm({ ...contactdetail, lastname: e.target.value })}
            />
          </div>
          <div>
            <TextField
              label="First Name"
              placeholder="First Name"
              className={ classes.dialogField }
              value={contactdetail.firstname}
              onChange={(e) => setConductForm({ ...contactdetail, firstname: e.target.value })}
            />
          </div>
          <div>
            <FormControl className={classes.dialogField}>
              <InputLabel >Gender</InputLabel>
              <Select
                value={contactdetail.gender}
                onChange={(e) => setConductForm({ ...contactdetail, gender: e.target.value })}>
                {getGenders()}
              </Select>
            </FormControl>
          </div>
          {/* <div>
            <FormControl className={classes.dialogField}>
              <InputLabel >Salutation</InputLabel>
              <Select
                value={contactdetail.salutation}
                onChange={(e) => setConductForm({ ...contactdetail, salutation: e.target.value })}>
                {getSalutations()}
              </Select>
            </FormControl>
          </div> */}
        </>
      }

      <div>
        <TextField
          label="Phone"
          placeholder="Phone Number"
          className={ classes.dialogField }
          value={contactdetail.phone}
          onChange={(e) => setConductForm({ ...contactdetail, phone: e.target.value })}
        />
      </div>
      <div>
        <TextField
          label="Email"
          placeholder="Email Address"
          className={ classes.dialogField }
          value={contactdetail.email}
          onChange={(e) => setConductForm({ ...contactdetail, email: e.target.value })}
        />
      </div>
      <div>
        <TextField
          label="Fax"
          placeholder="Fax"
          className={ classes.dialogField }
          value={contactdetail.fax}
          onChange={(e) => setConductForm({ ...contactdetail, fax: e.target.value })}
        />
      </div>
      <div>
        <TextField
          label="Job Title"
          placeholder="Job Title"
          className={ classes.dialogField }
          value={contactdetail.jobtitle}
          onChange={(e) => setConductForm({ ...contactdetail, jobtitle: e.target.value })}
        />
      </div>

    </>
  );
}