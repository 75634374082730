import React, { useState } from "react";
import classNames from "classnames";
import { IconButton, Popover, Box, Grid, Link } from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AdaptiveSearch from "../Search/adaptivesearch1";
import DateSearch from "../Search/datesearch1";
import SelectSearch from "../Search/selectsearch1";
import TimeSearch from "../Search/timesearch1";
import useSearchFilters, { setSearchFilters } from "../../hooks/useSearchFilters";
import searchFilteredIcon from "../../icons/clarity_filter-grid-circle-solid.svg";
import closeIcon from "../../icons/close-icon.svg";
import { useStyles } from "./styles";


/**
 * Header Cell in Contracts, TreeContracts
 * For new Search Filters, it's array
 * And it's necessary to get state and to dispatch reducers here
 * @param {String} title 
 * @param {Object} search 
 * @param {Component} WrappedComponent 
 * @returns 
 */
const HeaderCell = ({ 
  title, search={}, WrappedComponent=null,
}) => {
  // console.log("[HeaderCell]", search);

  const { name, classes: classList } = search;
  const filterValues = useSearchFilters(name);
  
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  // search filters parameter
  const [filterParams, setFilterParams] = useState([]);


  const handleClear = () => {
    setFilterParams([]);
    setSearchFilters(name, []);
    handleClose();
  };

  const handleApply = () => {
    setSearchFilters(name, filterParams);
    handleClose();
  };

  const handleOpen = (event) => {
    setFilterParams(filterValues);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClearClick = (event) => {
    event.preventDefault();
    handleClear();
  };

  const handleApplyClick = (event) => {
    event.preventDefault();
    handleApply();
  };


  return (
    <>
      {title}
      {WrappedComponent === DateSearch && 
        <IconButton 
          className={classes.searchIcon}
          onClick={handleOpen}
        >
          <CalendarTodayIcon className={classes.calendarIcon}/>
        </IconButton>
      }
      <IconButton 
        className={classes.searchIcon}
        onClick={handleOpen}
      >
        <ArrowDropDownIcon /> 
      </IconButton>
      {(filterValues && filterValues.length > 0) && 
        <IconButton 
          className={classes.searchIcon}
          onClick={handleClear}
        >
          <img  alt="" src={searchFilteredIcon} /> 
        </IconButton>
      }
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className={classNames(classes.newPopoverBox, (classList ? classList.popoverBox : null))} >
          <Grid className={classNames(classes.innerPopper, (classList ? classList.popoverInner : null))} >
            <WrappedComponent 
              {...search}
              params={filterParams}
              setParams={setFilterParams}
            />
          </Grid>
          <IconButton 
            className={classes.closeIcon}
            onClick={handleClose}
          >
            <img  alt="" src={closeIcon} /> 
          </IconButton>
          <Link href="#" variant="body2" className={classes.clearLink}
            onClick={handleClearClick}
          >
            Clear
          </Link>
          <Link href="#" variant="body2" className={classNames(classes.clearLink, classes.applyLink)}
            onClick={handleApplyClick}
          >
            Apply
          </Link>
        </Box>
      </Popover>
    </>
  );

};

/**
 * type -> WrappedComponent with search
 * @param {Component} WrappedComponent 
 * @param {Object} search 
 * @returns 
 */
const withSearchCell = (WrappedComponent, search) => {
  return (param) => (
    <HeaderCell 
      title={param.field} 
      search={search}
      WrappedComponent={WrappedComponent}
    />
  );
};

/**
 * render Header Cell
 * @param {Object} option 
 * @returns 
 */
export const renderHeaderCell = (option) => {
  if (option) {
    if (option.search && typeof option.search === 'object') {
      const { type } = option.search;
      if (type === 'AdaptiveSearch') return withSearchCell(AdaptiveSearch, option.search);
      if (type === 'DateSearch') return withSearchCell(DateSearch, option.search);
      if (type === 'SelectSearch') return withSearchCell(SelectSearch, option.search);
      if (type === 'TimeSearch') return withSearchCell(TimeSearch, option.search);
    }
  }
  return (param) => (param.field);
};

/**
 * render Header Cell with Checkbox
 * @param {Function} renderNew 
 * @param {Function} renderOriginal 
 * @param {Boolean} isAdd 
 * @returns 
 */
export const withCheckboxHeaderCell = (renderNew, renderOriginal, isAdd=true) => {
  const HeaderCellNew = renderNew;
  const HeaderCellOriginal = renderOriginal;
  return ({...props}) => (<>
    <HeaderCellNew {...props} />
    {
      isAdd && 
      <HeaderCellOriginal {...props} />
    }
  </>);
};
