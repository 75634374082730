import React, { useEffect, useState } from "react";
import { Grid, Typography, IconButton, Popover, MenuList, MenuItem, Button } from "@material-ui/core";
import { useDamlState } from "../../context/DamlContext";
import { isEnableCreateInvoice } from '../../models/POStatus';
import { PRODUCT_ADD_EVENT, EVENT_FINISHED, PO_INVOICE_EVENT } from "../../store/actions/constants";
import usePO from "../../hooks/usePO";
import useProduct from "../../hooks/useProduct";
import useEvent from "../../hooks/useEvent";
import { AddedProductList, EditProductModal, DefaultProductHeader } from "../surgicalevent/add-edit-product1";
import { defaultProduct, checkChangedPrice } from "../surgicalevent/edit-product1";
import commentsIcon from '../../icons/new-comments-icon.svg';
import POCommentPopover from "./pocomment-popover";
import { useSearchSEWithModal, SearchSEWithModal } from "./se-details-modal";
import useStyles from "./styles";


/**
 * product design pattern in Edit Product in Create PO modal
 * @param {Boolean} isNew 
 * @param {Boolean} isSeid 
 * @param {Function} handleChangeOwnership 
 * @returns 
 */
const productPattern = (isNew = false, isSeid = false, handleChangeOwnership) => ([
  {
    label: 'Product Code', style: { paddingTop: 0 },
  },
  (
    isNew
      ?
      {
        key: 'barcode', width: 360, colSpan: 2,
      }
      :
      undefined
  ),
  {
    key: 'sepudi', width: 306, colSpan: 2,
  },
  {
    key: 'sepreferencenumber', width: 306, colSpan: 2,
  },
  {
    key: 'seplotcode', width: 222, colSpan: 2,
  },
  {
    label: 'Product Details',
  },
  {
    key: 'sepproductmanufacturer', width: 152, style: { width: '167px' },
    key1: 'sepdistributor', width1: 152, disabled1: true,
  },
  (
    isNew
      ?
      {
        key: 'sepproductexpiration', width: 152,
        key1: 'sepownership', width1: 152, disabled1: true,
      }
      :
      {
        key: 'sepproductexpiration', width: 152,
        key1: 'sepownership', width1: 152, onChange1: (val, key) => handleChangeOwnership(val),
      }
  ),
  {
    key: 'sepproductname', width: 333, colSpan: 2,
  },
  {
    key: 'sepproductdescription', width: 333, colSpan: 2,
  },
  {
    key: 'sepproductwaste', width: 152,
    key1: 'sepuom', width1: 143,
  },
  {
    key: 'sepproductprice', width: 152,
    key1: 'sepproductside', width1: 152,
  },
  (
    isSeid
      ?
      {
        key: 'sepproducttype', width: 152,
      }
      :
      {
        key: 'sepproducttype', width: 152,
        key1: 'podquantity', width1: 152, type1: 'number', label1: 'Quantity',
      }
  ),
]);

const listPattern = [
  {
    key: 'sepudi', key1: 'sepuom', details: 'Product Details', detailIsCol: false,
  },
  {
    key: 'sepreferencenumber', key1: 'sepproducttype',
  },
  {
    key: 'sepproductmanufacturer', key1: 'sepproductwaste',
  },
  {
    key: 'sepdistributor', key1: 'sepownership',
  },
  {
    key: 'sepproductname', key1: 'sepproductside',
  },
  {
    key: 'sepproductdescription', key1: 'sepproductprice',
  },
  {
    key: 'sepproductexpiration', key1: 'podquantity', label1: 'Quantity :',
  },
  {
    key: 'seplotcode', key1: 'iquantity', label1: 'QoO :'
  },
];

/**
 * hook to change ownership
 * @param {Boolean} stockWarning 
 * @param {Boolean} isSecondary 
 */
const useChangeOwnership = (stockWarning = true, isSecondary = false) => {
  // stock confirm notification 
  const [stockConfirm, setStockConfirm] = useState(false);
  const [loanConfirm, setLoanConfirm] = useState(false);
  const [ownershipValue,setOwnershipValue]=useState("");
  const { setProduct: setProductItems, product } = useProduct(isSecondary);
  const { purchaseOrderData } = usePO();
  const handleChange = (value, key) => {
     setProductItems({
      [key]: value,
    });
  };
  const handleChangeOwnership = (val) => {
    setOwnershipValue(val);
    if (stockWarning) {
      if (val === "Stock" && purchaseOrderData.poseid) setStockConfirm(true);
      else if (val === "Loaned" && product.podproduct.sepownership === "Owned" && purchaseOrderData.poseid) setLoanConfirm(true);//Owned to Loaned and 
      else if (val === "Loaned" && product.podproduct.sepownership === "Consignment" && purchaseOrderData.poseid) setLoanConfirm(true);//Consignment to Loaned
      else if (val === "Owned" && product.sepownership === "Loaned" && purchaseOrderData.poseid) setLoanConfirm(true);
      else if (val === "Owned" && product.sepownership === "Consignment" && purchaseOrderData.poseid) setLoanConfirm(true);
      else if (val === "Consignment" && product.sepownership === "Loaned" && purchaseOrderData.poseid) setLoanConfirm(true);
      else if (val === "Consignment" && product.sepownership === "Owned" && purchaseOrderData.poseid) setLoanConfirm(true);
      else handleChange(val, 'sepownership');
    }
    else handleChange(val, 'sepownership');
  };
  return {
    stockConfirm,
    setStockConfirm,
    loanConfirm,
    setLoanConfirm,
    handleChange,
    handleChangeOwnership,
    ownershipValue
  };
};

/**
 * Added Product List Header Component
 * @param {Array} productItemsList 
 * @param {Object} options 
 * @returns 
 */
const AddedProductHeader = ({
  productItemsList = [], options = {},
}) => {

  options = {
    isEditable: true,
    isAddableSE: true,
    isAddableProduct: true,
    isCommentable: true,
    isSESearch: false,
    isCreate: false,
    ...options,
  };

  const classes = useStyles();

  const { role } = useDamlState();

  const { purchaseOrderData,setPurchaseOrder } = usePO();
  const { clearProduct: setProductItems } = useProduct();
  const { event, status, onEventStart } = useEvent();

  // Popover when clicking Comment button
  const [anchorEl, setAnchorEl] = useState(null);
  // Popover when clicking Add button
  const [anchorElAdd, setAnchorElAdd] = useState(null);
  // Add Product to PO
  const [openEditModal, setOpenEditModal] = useState(false);

  // New Header Pattern
  const newHeaderPattern = [
    {
      key: 'popurchaseorderid', label: '', className: 'name ref', value: purchaseOrderData.popurchaseorderid,
    },
    {
      key: 'sepreferencenumber', label: 'Ref: ', className: 'name ref',
    },
  ];

  const {
    searchValue,
    handleChangeSearch,
    handleOpenAddSEModal,
    openSEModal,
    setOpenSEModal,
  } = useSearchSEWithModal(options.isAddableSE);

  const handleOpenAddModal = () => {
    if (!options.isAddableProduct) return;
    // default Product from PO
    setProductItems({
      ...defaultProduct,
      sepownership: purchaseOrderData.popurchaseordertype,
      sepdistributor: purchaseOrderData.povendorid,
    });
    setPurchaseOrder({...purchaseOrderData,sideAddPanel:!purchaseOrderData.sideAddPanel})
  };

  const handleOpenCommentPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCreateInvoice = () => {
    onEventStart(PO_INVOICE_EVENT);
  };


  useEffect(() => {
    // Close EditProductModal automatically when action finish
    if (event === PRODUCT_ADD_EVENT && status === EVENT_FINISHED) {
      setOpenEditModal(false);
    }
  }, [event, status]);


  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography className="product-title">
            Added Products
          </Typography>
        </Grid>
        <Grid item>
          {
            options.isSESearch &&
            <SearchSEWithModal
              searchValue={searchValue}
              handleChangeSearch={handleChangeSearch}
              handleOpenAddSEModal={handleOpenAddSEModal}
              openSEModal={openSEModal}
              setOpenSEModal={setOpenSEModal}
              style={{ marginRight: '5px' }}
            />
          }
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="product-subtitle"
      >
        <DefaultProductHeader productItemsList={productItemsList} />
        <Grid item>
          <Typography variant="inherit">Total Price:{' '}</Typography>
          <Typography variant="inherit">${purchaseOrderData.pototalamount}</Typography>
        </Grid>
        <Grid item style={{ width: (role === 'Vendor' ? '170px' : '150px'), display: 'flex', justifyContent: 'flex-end' }}>
          {
            (options.isAddableSE || options.isAddableProduct) && !options.isCreate &&
            <Button
            className="create-invoice-btn add-product-btn"
            variant="contained"
            onClick={!options.isAddableSE ? handleOpenAddModal : handleOpenAddModal}
            >Add Product</Button>
          }
          {
            (role === 'Vendor') &&
            <Button
              className="create-invoice-btn"
              variant="contained"
              disabled={!isEnableCreateInvoice(purchaseOrderData.postatus)}
              onClick={handleCreateInvoice}
            >
              Create Invoice
            </Button>
          }
          {
            !options.isCreate && 
            <IconButton onClick={handleOpenCommentPopover}>
              <img alt="" src={commentsIcon} />
            </IconButton>
          }

          <Popover
            open={Boolean(anchorElAdd)}
            anchorEl={anchorElAdd}
            onClose={() => setAnchorElAdd(null)}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuList className={classes.addMenu}>
              <MenuItem onClick={handleOpenAddModal}>Add Product to PO</MenuItem>
              {
                options.isAddableSE &&
                <MenuItem onClick={async () => { await handleOpenAddSEModal(); setAnchorElAdd(null); }}>
                  Add PO to SEID
                </MenuItem>
              }
            </MenuList>
          </Popover>
          <EditProductModal
            open={openEditModal}
            setOpen={setOpenEditModal}
            options={{
              isSecondary: false,
              isEnableCompliance: false,
              designPattern: productPattern(true, !!purchaseOrderData.poseid),
              headerPattern: newHeaderPattern,
            }}
            classnames={{
              title: classes.newProductTitlePanel,
            }}
          />

          <POCommentPopover
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            isSendable={options.isCommentable}
          />
        </Grid>
      </Grid>
    </>
  );
};

const getShortDesc = (obj) => {
  const MAX_LEN = 40;
  const len = MAX_LEN - `${obj.podquantity}${obj.sepreferencenumber}${obj.seplotcode}${obj.podunitprice}`.length;
  return obj.sepproductdescription?.slice(0, Math.max(len, 0));
};

const accordionPattern = [
  {
    label: 'Qty:',
    value: (obj) => obj.podquantity,
  },
  {
    label: 'Ref:',
    value: (obj) => obj.sepreferencenumber,
  },
  {
    label: 'Lot/SN:',
    value: (obj) => obj.seplotcode,
  },
  {
    label: 'Description:',
    value: (obj) => obj.sepproductdescription,
    tooltip: getShortDesc,
  },
  {
    label: 'Price:',
    value: (obj) => `$${obj.podunitprice}`,
    className: (obj) => checkChangedPrice(obj) ? "red" : "",
  },
];

/**
 * Product List in Purchase Order
 * @param {Boolean} isEditable 
 * @param {Boolean} isSend 
 * @param {Boolean} isCreate 
 * @returns 
 */
const ProductsList = ({
  isEditable = false, isSend = true, isCreate = true,
}) => {

  const classes = useStyles();

  const { purchaseOrderData } = usePO();  
  const {
    stockConfirm,
    setStockConfirm,
    loanConfirm,
    setLoanConfirm,
    handleChange,
    handleChangeOwnership,
    ownershipValue
  } = useChangeOwnership(true, true);

  return (
    <>
      <AddedProductList
        className={classes.newAddedProductPanel}
        options={{
          keyProduct: 'podproduct',
          isEnableEditProduct: isEditable,
          isEnableDeleteProduct: isEditable,
          isEnableCompliance: false,
          edit: {
            isEnableCompliance: false,
            designPattern: productPattern(false, !!purchaseOrderData.poseid, handleChangeOwnership),
            isExtraSnackbar: true,
            extraSnackbarContent: stockConfirm ? `You are declaring this product as Hospital Stock it will be removed from the PO and will not be able to be undone`
              :
              `Do you confirm that the product will be removed from ${purchaseOrderData.popurchaseorderid} and added to another PO ?`,
            extraSnackbarActions: [
              {
                label: 'Acknowledge',
                handle: () => {
                  if(ownershipValue){
                    handleChange(ownershipValue, 'sepownership');
                    setLoanConfirm(false);
                  }
                  else if (stockConfirm) handleChange("Stock", 'sepownership');
                  else {
                    handleChange("Loaned", 'sepownership');
                    setLoanConfirm(false);
                  }
                },
              },
              {
                label: 'Decline',
                handle: () => {
                  if (loanConfirm)
                    setLoanConfirm(false);
                }
              }
            ],
            extraSnackbarOpen: stockConfirm || loanConfirm, // Stock Ownership Change Notification
            extraSnackbarSetOpen: setStockConfirm || setLoanConfirm,
          },
          header: {
            isEditable: isEditable,
            isAddableSE: isCreate,
            isAddableProduct: (!isCreate && isEditable && !isSend),
            // isCommentable: isSend,
            // isSESearch: isCreate,
            isCreate: isCreate,
          },
        }}
        HeaderComponent={AddedProductHeader}
        listPattern={listPattern}
        accordionPattern={accordionPattern}
      />
    </>
  );
};

export default ProductsList;
