import React from "react";
import { InputLabel, TextField, Checkbox, FormControlLabel, FormControl, Select } from "@material-ui/core";
import { onChangeNumber } from "../handles";
import TabPanel from "../MultiTabsModals/TabPanel";
import { getLevels } from "../../../models/AdminDetail";
import { useStyles } from "../styles";


export default function AdminDetailContents({
  adminDetail,
  setConductForm,
  value }) {

  const classes = useStyles();
  
  // console.log("[AdminDetailContents]", adminDetail);


  return (
    <>

      <TabPanel key={0} value={value} index={0} className={classes.tabPanel}>
        <FormControlLabel
          control={
            <Checkbox
              className={classes.checkbox}
              color="primary"
              checked={adminDetail.auto_po}
              onChange={(e) => setConductForm({ ...adminDetail, auto_po: e.target.checked })}
            />
          }
          label="Automate Purchase Order"
          className={classes.fullWidth}
        />

        <FormControlLabel
          control={
            <Checkbox
              className={classes.checkbox}
              color="primary"
              checked={adminDetail.auto_po_vendor}
              onChange={(e) => setConductForm({ ...adminDetail, auto_po_vendor: e.target.checked })}
            />
          }
          label="Automate Send Purchase Order to Vendor"
          className={classes.fullWidth}
        />
        
      </TabPanel>

      <TabPanel key={1} value={value} index={1} className={classes.tabPanel}>
        <div>
          <FormControl className={classes.dialogField}>
            <InputLabel >Approval Level</InputLabel>
            <Select
              value={adminDetail.approval_level}
              onChange={(e) => setConductForm({ ...adminDetail, approval_level: e.target.value })}>
              {getLevels()}
            </Select>
          </FormControl>
        </div>

        <div>
          <TextField
            label="Approval Amount Level One"
            placeholder="Approval Amount Level One"
            className={ classes.dialogField }
            value={adminDetail.approval_amount_levelone}
            onChange={(e) => {
              if (onChangeNumber(e)) setConductForm({ ...adminDetail, approval_amount_levelone: onChangeNumber(e) })
            }}
          />
        </div>

        <div>
          <TextField
            label="Approval Amount Level Two"
            placeholder="Approval Amount Level Two"
            className={ classes.dialogField }
            value={adminDetail.approval_amount_leveltwo}
            onChange={(e) => {
              if (onChangeNumber(e)) setConductForm({ ...adminDetail, approval_amount_leveltwo: onChangeNumber(e) })
            }}
          />
        </div>
      </TabPanel>

    </>
  );
}