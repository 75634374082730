import { getTodayString } from "../../services/util";

/**
 * create default reducer
 * @param {String} key If key is not "", then it may get localStorage
 * @param {String|null} type_detail Set detail value
 * @param {String|null} type_last Set last_id value
 * @param {String|null} type_add Increase last_id, today_id and set today as last_date
 * @param {String|null} type_search Set object as variable with key of payload
 * @param {String|null} type_status Set current_status value
 * @param {String|null} type_clear Clear value
 * @returns 
 */
const createReducer = (
  key="", type_detail=null, type_last=null, type_add=null, type_search=null, type_status=null, type_clear=null,
) => {
  const key_last = `daml.${key}_last_id`;
  const key_today = `daml.${key}_today_id`;
  const key_date = `daml.${key}_last_date`;
  const default_state = !!key ? {
    last_id: localStorage.getItem(key_last) || 0,
    today_id: localStorage.getItem(key_today) || 0,
    last_date: localStorage.getItem(key_date),
  } : {};

  return (state = default_state, action) => {

    if (action.type) {

      if(action.type === type_detail) {
        return { ...state, detail: action.payload };
      }
      else if(action.type === type_last) {
        if (!!key) localStorage.setItem(key_last, action.payload);
        return { ...state, last_id: action.payload };
      }
      else if(action.type === type_add) {
        let last_id = parseInt(state.last_id) + 1;
        let today_id = parseInt(state.today_id) + 1;
        let last_date = state.last_date;
        const today_date = getTodayString();
        if (today_date !== last_date) {
          today_id = 1;
          last_date = today_date;
        }
        if (!!key) {
          localStorage.setItem(key_last, last_id);
          localStorage.setItem(key_today, today_id);
          localStorage.setItem(key_date, last_date);
        }
        return { ...state, last_id: last_id, today_id: today_id, last_date: last_date };
      }
      else if(action.type === type_search) {
        return { ...state, [action.payload.key]: action.payload.value };
      }
      else if(action.type === type_status) {
        return { ...state, current_status: action.payload };
      }
      else if(action.type === type_clear) {
        return default_state;
      }

    }
    
    return state;
  };
};

export default createReducer;
