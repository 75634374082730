// import React from "react";
import {
    AuthenticationDetails,
    CognitoUserPool,
    CognitoUserAttribute,
    CognitoUser,
    // CognitoUserSession,
} from "amazon-cognito-identity-js";
import { POOL_DATA, isUserPoolAuth } from "./../config";


function authenticateUser(username, password, callback) {
    const userPool = new CognitoUserPool(POOL_DATA);

    var authenticationData = {
        Username: username,
        Password: password,
    };
    var authenticationDetails = new AuthenticationDetails(
        authenticationData
    );

    var userData = {
        Username: username,
        Pool: userPool,
    };
    var cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function(result) {
            var accessToken = result.getAccessToken().getJwtToken();
            console.log("[cognitoLogIn] success", accessToken);
            callback("onSuccess", cognitoUser, accessToken);
        },
        onFailure: function(err) {
            console.log("[cognitoLogIn] failed", err.message);
            callback("onFailure", cognitoUser, err.message);
        },
        mfaRequired: function(codeDeliveryDetails) {
            console.log("[cognitoLogIn] mfaRequired", codeDeliveryDetails);
            // MFA is required to complete user authentication.
            // Get the code from user and call
            // cognitoUser.sendMFACode(mfaCode, this);
            callback("mfaRequired", cognitoUser, codeDeliveryDetails);
        },
        newPasswordRequired: function(userAttributes) {
            console.log("[cognitoLogIn] newPasswordRequired", userAttributes);
            callback("newPasswordRequired", cognitoUser, userAttributes);
        }
    });
}

async function cognitoLogIn(username, password) {

    if (!isUserPoolAuth) return "skip coginto";

    return await new Promise((resolve, reject) => {
        authenticateUser(username, password, function(event, user, result) {
            console.log("[cognitoLogIn] user", user);
            if (event === "onFailure") resolve(null);
            else resolve(result);
        });
    });
}


function cognitoSignUp(username, password, callback) {

    if (!isUserPoolAuth) return callback("skip coginto");

    const userPool = new CognitoUserPool(POOL_DATA);

    var attributeList = [];
    var dataEmail = {
        Name: 'email',
        Value: 'test@email.com',
    };
    // var dataPhoneNumber = {
    //     Name: 'phone_number',
    //     Value: '+15555555555',
    // };
    var attributeEmail = new CognitoUserAttribute(dataEmail);
    // var attributePhoneNumber = new CognitoUserAttribute(dataPhoneNumber);
    attributeList.push(attributeEmail);
    // attributeList.push(attributePhoneNumber);

    userPool.signUp(username, password, attributeList, null, function(
        err,
        result
    ) {
        if (err) {
            console.log(err.message || JSON.stringify(err));
            return callback(null);
        }
        var cognitoUser = result.user;
        console.log('[cognitoSignUp] user name is ' + cognitoUser.getUsername());
        callback(cognitoUser);
    });
}


function getUserGroup(user) {
    let userGroup = null;
    try {
        userGroup = user.signInUserSession.accessToken.payload['cognito:groups'][0];
        console.log("user group is " + userGroup);
    }
    catch(e) {
    }
    return userGroup;
}


function changePassword(username, oldPassword, newPassword, callback) {

    if (!isUserPoolAuth) return callback("skip coginto");

    authenticateUser(username, oldPassword, function(event, user, result) {
        if (event === "onFailure") {
            console.log(result);
            return callback(null);
        }
        else if (event === "newPasswordRequired") {
            delete result.email_verified;

            user.completeNewPasswordChallenge(newPassword, result, {
                onSuccess: function(result) {
                    console.log("[changePassword] completeNewPasswordChallenge", result);
                    return callback(getUserGroup(user));
                }
            });
        }
        else {
            user.changePassword(oldPassword, newPassword, function(err, result) {
                if (err) {
                    console.log(err.message || JSON.stringify(err));
                    return callback(null);
                }
    
                console.log('[changePassword]' + result);
                callback(getUserGroup(user));
            });
        }
    });
}

async function asyncChangePassword(username, oldPassword, newPassword) {
    return new Promise((resolve, reject) => {
        changePassword(username, oldPassword, newPassword, function(result) {
            console.log("[asyncChangePassword]", result);
            resolve(result);
        });
    });
}


export { cognitoLogIn, cognitoSignUp, changePassword, asyncChangePassword };