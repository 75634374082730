import React, { useState } from "react";
import classNames from "classnames";
import { Grid, Typography, IconButton, Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import { useAccountInventory } from "../../hooks/useInventory";
import { useDamlState } from "../../context/DamlContext";
import EditReturnInventoryContracts from "./edit-return-inventory-contracts";
import commentsIcon from '../../icons/new-comments-icon.svg';
import POCommentPopover from "../purchaseorder/pocomment-popover";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "../inventoryrestock/styles";
import { ContractPrice } from "./house-inventory-detail";
import useStyles2 from "./styles";


/**
 * Edits Inventory Detail
 * @param {Object} options 
 * @param {Object} props 
 * @returns 
 */
export function EditInventoryDetail({
  options = {}, ...props
}) {

  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();

  const { accountInventoryData } = useAccountInventory();
  const inventorydata = accountInventoryData.inventorydata ?? [];

  // console.log("[EditInventoryDetail]", inventorydata, options, props, accountInventoryData);

  // Popover when clicking Comment button
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenCommentPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };


  return (
    <Grid container
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      className={classNames(classes.overview, classes1.incomingPanel)}
    >
      <Grid item className={classNames(classes1.details, classes2.newHeaderPanel1)}>
        <Typography>
          {accountInventoryData.ireferencenumber} | 
          {accountInventoryData.iproductdescription} | 
          {accountInventoryData.iownership} | 
          {accountInventoryData.vendor}
        </Typography>
      </Grid>

      <Grid item className={classNames(classes1.details, classes2.middlePanel)}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="inherit" className="header-value">Total Products: </Typography>
            <Typography variant="inherit" className="header-value">{inventorydata?.length}</Typography>
          </Grid>
          <Grid item>
            <ContractPrice 
              isQuantity={false}
            />
          </Grid>
          <Grid item>
            <IconButton onClick={handleOpenCommentPopover}>
              <img alt="" src={commentsIcon} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      <Grid item className={classes1.contractsPanel}>
        <EditReturnInventoryContracts
          allContracts={inventorydata}
          loading={inventorydata.length === 0 && props.loadingStreamQueries === true}
          options={options}
        />
      </Grid>

      <POCommentPopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        isSendable={false}
      />
    </Grid>
  );
}

/**
 * Returns Inventory Detail
 * @param {Object} options 
 * @param {Object} props 
 * @returns 
 */
export function ReturnInventoryDetail({
 options = {}, ...props
}) {

 const classes = useStyles();
 const classes1 = useStyles1();
 const classes2 = useStyles2();

 const { role } = useDamlState();
 const { accountInventoryData } = useAccountInventory();
 const inventorydata = accountInventoryData.inventorydata ?? [];

 // console.log("[ReturnInventoryDetail]", inventorydata, options, props, accountInventoryData);

 // Popover when clicking Comment button
 const [anchorEl, setAnchorEl] = useState(null);

 const handleOpenCommentPopover = (event) => {
   setAnchorEl(event.currentTarget);
 };


 return (
   <Grid container
     direction="column"
     justifyContent="space-between"
     alignItems="stretch"
     className={classNames(classes.overview, classes1.incomingPanel, classes2.returnPanel)}
   >
    <Grid item className={classNames(classes1.details, classes2.newHeaderPanel1)}>
      <Table>
        {
          role === 'Hospital'
          ?
          <TableBody>
            <TableRow>
              <TableCell className="header-label">Ref: </TableCell>
              <TableCell className="header-label">Ownership:</TableCell>
              <TableCell className="header-label">Distributor:</TableCell>
              <TableCell className="header-label">Description:</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="header-value">{inventorydata[0]?.iproduct?.sepreferencenumber}</TableCell>
              <TableCell className="header-value">{inventorydata[0]?.iproduct?.sepownership}</TableCell>
              <TableCell className="header-value">{accountInventoryData.vendor}</TableCell>
              <TableCell className="header-value">{inventorydata[0]?.iproduct?.sepproductdescription}</TableCell>
            </TableRow>
          </TableBody>
          :
          <TableBody>
            <TableRow>
              <TableCell className="header-label">Ref: </TableCell>
              <TableCell className="header-label">UDI:</TableCell>
              <TableCell className="header-label">Ownership:</TableCell>
              <TableCell className="header-label">Facility:</TableCell>
              <TableCell className="header-label">Description:</TableCell>
              <TableCell className="header-label">Nickname:</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="header-value">{accountInventoryData.ireferencenumber}</TableCell>
              <TableCell className="header-value">{inventorydata[0]?.iproduct?.sepudi}</TableCell>
              <TableCell className="header-value">{accountInventoryData.iownership}</TableCell>
              <TableCell className="header-value">{accountInventoryData.hospital}</TableCell>
              <TableCell className="header-value">{accountInventoryData.iproductdescription}</TableCell>
              <TableCell className="header-value">{accountInventoryData.iproductname}</TableCell>
            </TableRow>
          </TableBody>
        }
      </Table>
    </Grid>

     <Grid item className={classNames(classes1.details, classes2.middlePanel)}>
       <Grid container justifyContent="space-between" alignItems="center">
         <Grid item>
           <Typography variant="inherit" className="header-value">Total Products: </Typography>
           <Typography variant="inherit" className="header-value">{inventorydata?.length}</Typography>
         </Grid>
         <Grid item>
           <ContractPrice 
           />
         </Grid>
         <Grid item>
           <IconButton onClick={handleOpenCommentPopover}>
             <img alt="" src={commentsIcon} />
           </IconButton>
         </Grid>
       </Grid>
     </Grid>

     <Grid item className={classes1.contractsPanel}>
       <EditReturnInventoryContracts
         allContracts={inventorydata}
         loading={inventorydata.length === 0 && props.loadingStreamQueries === true}
         options={options}
       />
     </Grid>

     <POCommentPopover
       anchorEl={anchorEl}
       setAnchorEl={setAnchorEl}
       isSendable={false}
     />
   </Grid>
 );
}
