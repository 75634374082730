import React from "react";
import classNames from "classnames";
import { contractFilter } from "../../services/daml-filters";
import AdaptiveSearch from "../../components/Search/adaptivesearch";
import TreeContracts from "../../components/Contracts/TreeContracts";
import useStyles from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";


function RecallInventoryContracts({
  searchParams = {},
  handleChangeSearchValue = null,
  allContracts = [],
  searchInventory = {},
}) {

  const classes = useStyles();

  let assetsContracts = contractFilter(allContracts, searchParams);

  return (
    <>
      <TreeContracts
        className={classes.customRoot}
        contracts={assetsContracts}
        columns={[
          // ["ContractId", "contractId"],
          ["Reference Number", "referencenumber", {
            options: {
              width: 160
            }
          }],
          ["Lot Code", "lotcode", {
            type: "link",
            options: {
              cellClassName: classNames(classes.primaryColor),
              width: 140
            },
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.surgicaleventdata.lotcode}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'lotcode')}
                  defaultValue={searchParams.lotcode}
                  label="Search Lot Code"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchParams.lotcode,
            handleClear: () => handleChangeSearchValue('', 'lotcode'),
          }],

          ["Manufacturer", "manufacturer", {
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.surgicaleventdata.manufacturer}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'manufacturer')}
                  defaultValue={searchParams.manufacturer}
                  label="Search Manufacturer"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchParams.manufacturer,
            handleClear: () => handleChangeSearchValue('', 'manufacturer'),
          }],
          ["Distributor", "distributor"],
          ["Recall Date", "recalldate", {
            options: {
              width: 160
            }
          }],
          ["SE Details", "payload.purchaseorders", {
            type: "collapse",
            columns: [
              ["SE Id", "seid"],
              ["MRN", "mrn"],
              ["Event Date", "eventdate"],
              ["Patient Name", "patientname"],
              ["Physician Name", "physicianname"],
              ["Procedure", "procedure"],

            ],
            actions: [],

          }],
        ]}

        actions={[]}
        options={{
          statusEmpty: (allContracts.length === 0)
        }}
      />

    </>
  );
}

export default withLoading(RecallInventoryContracts);
