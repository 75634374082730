import React,{useState} from "react";
import { Typography } from "@material-ui/core";
import editIcon from "../../icons/edit.svg";
import editDisableIcon from "../../icons/edit-disable.svg";
import {CustomizedProgressBars} from './Progress';
import VendorProfileUpdate from "./OperatorEdit";
import TreeContracts from "../../components/Contracts/TreeContracts";
import useStyles from "../surgicalevent/styles";
import { VendorRole } from "../../services/daml-modules1";
import { useStreamQueries } from "@daml/react";

export default function OperatorVendorList() {

  const assets = useStreamQueries(VendorRole, () => [{}]);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const[open,setOpen] = useState(false);
  const classes = useStyles();
 
  const handleRequest = (c) =>{
    setSelectedProfile(c);
    setOpen(true)
  } 


  //<BorderLinearProgress variant="determinate" value={50} />
  return (
    <>  
     <div key="formChildStep" className="formsteps">
     <Typography variant="h4" className={classes.pageTitle}>Vendors </Typography>
      
      <TreeContracts
        className={classes.customRoot}
        contracts={assets.contracts}
        columns={[
          // ["ContractId", "contractId"],
          ["Name", "payload.vendor", {
            options: {
              width: 120,
            },
                   
          }],
          ["Address", "payload.vendor",{
            type:'render',
            render: (param) => {
              let {payload:{
                vendordetails:{
                  vendoraddress:address
                }
              }} = param.row
              // let {vendordetails}= payload
              console.log(address)
              return (
              <p>{address.address1},{address.address2},{address.city},{address.state},{address.zipcode}</p>
          )},
          }],
          ["No. of Contacts", "payload.vendordetails.customerservicecontact.length",{
            options:{
                width:180,
                cellClassName:classes.centerCell,
                headerClassName:classes.centerCell
              }
          }],
          ["Profile Completion", "profilecompletion",{
            type: "render",
            render: (param) => (
                <CustomizedProgressBars  completePercent={70}/>
            ),  
            options:{
              width:180
            }
          }]
        ]}

        actions={[
         
          [
            "Actions",
            [
              [["Edit", [<img  alt="" src={editIcon} />, <img  alt="" src={editDisableIcon} />], 
                (c) =>true], 
              handleRequest],
              
            ],
            {
              width: 110,
              cellClassName: classes.centerCell,
              headerClassName: classes.centerCell,
            }
          ]
        ]}
        options={{
          // statusEmpty: (allContracts.length === 0)
        }}
      />
      
      <VendorProfileUpdate 
        isCreate={true}
        open={open}
        setOpen={setOpen}
        handleRequest ={()=>{}}
        vendorProfile={selectedProfile}
      />
      </div>
    </>
  );
}



