import React from "react";
import classNames from "classnames";
import CustomTextField from "./TextFields";
import useStyles from "./styles";


/**
 * New Date Input
 * @param {String} value 
 * @param {Function} onChange 
 * @param {String} label 
 * @param {Object} className 
 * @param {Object} options 
 * @param {String} warning 
 * @param {Function} checkWarnings 
 * @param {Number} width 
 * @param {Object} style 
 * @param {Boolean} required 
 * @returns 
 */
export default function DateInput ({
  value, onChange, label, className, options={}, warning, checkWarnings, width=213, style={}, required,
}) {

  const classes = useStyles();

  // class name list
  const classnames = [classes.date];
  if (className) classnames.push(className);

  return (
    <CustomTextField
      type="date"
      required={required}
      className={classNames(classnames)}
      value={value}
      label={label}
      onChange={onChange}
      warning={warning}
      checkWarnings={checkWarnings}
      width={width}
      style={style}
      options={options}
    />
  );
}

/**
 * New Time Input
 * @param {String} value 
 * @param {Function} onChange 
 * @param {String} label 
 * @param {Object} className 
 * @param {Object} options 
 * @param {String} warning 
 * @param {Function} checkWarnings 
 * @param {Number} width 
 * @param {Object} style 
 * @param {Boolean} required 
 * @returns 
 */
export function TimeInput ({
  value, onChange, label, className, options={}, warning, checkWarnings, width=213, style={}, required,
}) {

  const classes = useStyles();

  // class name list
  const classnames = [classes.date];
  if (className) classnames.push(className);

  return (
    <CustomTextField
      type="time"
      required={required}
      className={classNames(classnames)}
      value={value}
      label={label}
      onChange={onChange}
      warning={warning}
      checkWarnings={checkWarnings}
      width={width}
      style={style}
      options={options}
    />
  );
}
