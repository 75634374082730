import { validateDecimal } from "./handles";

export const SurgicalEventProceduredata = {
  cptname: '',
  cptcode: '',
  cpttext: '',
};
export const validateSurgicalEventProcedure = (procedure) => {
  return (
    procedure.cptname !== ""
  );
};
export const getSurgicalEventProcedure = (procedure) => {
  let newProcedure = {};
  newProcedure.cptname = procedure.cptname || "";
  if (validateDecimal(procedure.cptcode)) newProcedure.cptcode = procedure.cptcode;
  if (typeof procedure.cpttext === "string" && procedure.cpttext !== "") newProcedure.cpttext = procedure.cpttext;
  return newProcedure;
};

export const SurgicalEventProcedurePrice = {
  cptname: '',
  price: '',
};
export const validateSurgicalEventProcedurePrice = (procedure) => {
  return (
    procedure.cptname !== ""
    && procedure.price !== ""
  );
};
export const getSurgicalEventProcedurePrice = (procedure) => {
  let newProcedure = {};
  newProcedure.cptname = procedure.cptname || "";
  newProcedure.price = procedure.price || "0";
  return newProcedure;
};
export const compareSurgicalEventProcedurePrice = (procedure1, procedure2) => {
  const price1 = parseFloat(procedure1.price);
  const price2 = parseFloat(procedure2.price);
  return (procedure1.cptname === procedure2.cptname
    && (procedure1.price === procedure2.price
      || (!isNaN(price1) && !isNaN(price2) && price1 === price2)));
};

export const SurgicalEventProcedureDisPrice = {
  cptname: '',
  distributor: '',
  price: '',
};
export const validateSurgicalEventProcedureDisPrice = (procedure) => {
  return (
    procedure.cptname !== ""
    && procedure.distributor !== ""
    && procedure.price !== ""
  );
};
export const getSurgicalEventProcedureDisPrice = (procedure) => {
  let newProcedure = {};
  newProcedure.cptname = procedure.cptname || "";
  newProcedure.distributor = procedure.distributor || "";
  newProcedure.price = procedure.price || "0";
  return newProcedure;
};
export const compareSurgicalEventProcedureDisPrice = (procedure1, procedure2) => {
  const price1 = parseFloat(procedure1.price);
  const price2 = parseFloat(procedure2.price);
  return (procedure1.cptname === procedure2.cptname
    && procedure1.distributor === procedure2.distributor
    && (procedure1.price === procedure2.price
      || (!isNaN(price1) && !isNaN(price2) && price1 === price2)));
};
