import React, { useState, useRef } from "react";
import classNames from "classnames";
import { useReactToPrint } from 'react-to-print';
import { useParty, useStreamQueries } from "@daml/react";
import { Grid, Typography, Button } from '@material-ui/core';
import { PurchaseOrderVendor, PurchaseOrderVendorHistory } from "../../services/daml-modules1";
import { useUserState } from "../../context/UserContext";
import { checkNotPOHistory } from '../../models/Po';
import { isEnableAcceptDecline } from '../../models/POStatus';
import exportIcon from "../../icons/new-export-icon.svg";
import printIcon from "../../icons/new-print-icon.svg";
// import groupIcon from "../../icons/group-icon.svg";
import CustomPrints from "../surgicalevent/surgicalevent-prints";
import ExportPopover from "../surgicalevent/export-popover";
import useStyles from "../surgicalevent/styles";
import ReceivedPoListContracts from "./receivedpolistcontracts1";
import CreatePurchaseOrder from "./create-purchaseorder1";
import PurchaseOrderPrint from "./purchaseorder-prints";
import { handleExportCSV, handleExportPDF, searchRestockForQoO } from "./purchaseorderlist1";
// import useStyles1 from "./styles";


/**
 * Received Purchase Orders page in Vendor
 * If accesslevel is VManager, then both of visible & editable.
 * If accesslevel is VAssociate, then only visible.
 * @returns 
 */
export default function ReceivedPoList() {

  const classes = useStyles();
  // const classes1 = useStyles1();

  const vendor = useParty();
  const { token } = useUserState();

  const componentRef = useRef();

  const { contracts, loading } = useStreamQueries(PurchaseOrderVendor, () => [{ vendor }]);
  const { contracts: contractsHistory } = useStreamQueries(PurchaseOrderVendorHistory, () => [{ vendor }]);

  const [isHistory, setHistory] = useState(false);
  // CreatePurchaseOrder
  const [contract, setContract] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [isSend, setIsSend] = useState(false);
  // checked POs
  const [checkedPO, setCheckedPO] = useState([]);
  // Popover when clicking Export button
  const [anchorEl, setAnchorEl] = useState(null); 


  const handleEditPO = async (c) => {
    c = await searchRestockForQoO({
      contract: c, token, vendor, 
    });
    setContract(c);
    setIsSend(isEnableAcceptDecline(c.payload.purchaseorderdata.postatus) && checkNotPOHistory(c));
    setOpenModal(true);
  };

  /* const handleGroupClick = () => {
    console.log("[ReceivedPoList] handleGroupClick");
  }; */

  const handlePrintClick = () => {
    console.log("[ReceivedPoList] handlePrintClick", checkedPO);
    if (!checkedPO || checkedPO.length === 0) return;
    handlePrint();
  };

  const handleOpenPopover = (event) => {
    if (!checkedPO || checkedPO.length === 0) return;
    setAnchorEl(event.currentTarget);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  }); 


  return (
    <>

      <Grid>

        <Grid container justifyContent="space-between">
          <Grid item xs>
            <Typography variant="h4" className={classes.newPageTitle}>Purchase Order</Typography>
          </Grid>
          <Grid item>
            {/* <Button
              className={classNames(classes.newCreateButton, classes.newIconButton, classes1.newIconButton)}
              variant="contained"
              onClick={handleGroupClick}
              startIcon={<img  alt="" src={groupIcon} />}
            /> */}
            <Button
              className={classNames(classes.newCreateButton, classes.newIconButton)}
              variant="contained"
              onClick={handlePrintClick}
              startIcon={<img  alt="" src={printIcon} />}
            />
            <Button
              className={classNames(classes.newCreateButton, classes.newIconButton)}
              variant="contained"
              onClick={handleOpenPopover}
              startIcon={<img  alt="" src={exportIcon} />}
            />

            <ExportPopover
              anchor={anchorEl}
              setAnchor={setAnchorEl}
              handleExportCSV={() => handleExportCSV(checkedPO)}
              handleExportPDF={() => handleExportPDF(checkedPO)}
            />
          </Grid>
        </Grid>

        <ReceivedPoListContracts
          allContracts={contracts}
          historyContracts={contractsHistory}
          isIncludeHistory={isHistory}
          setArchived={setHistory}
          setChecked={setCheckedPO}
          handleEditPO={handleEditPO}
          loading={(loading)}
        />

      </Grid>

      <CreatePurchaseOrder
        open={openModal}
        setOpen={setOpenModal}
        contract={contract}
        contracts={contracts}
        isSend={isSend}
      />

      <CustomPrints
        ref={componentRef}
        contracts={checkedPO}
        Component={PurchaseOrderPrint}
      />

    </>
  );
}
