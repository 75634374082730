import React, { useState, useEffect } from "react";
import { useStreamQueries, useParty, useLedger } from "@daml/react";
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, Chip, FormControl, InputLabel, List,
  ListItem, Paper, Select, TableContainer, TextField, Typography } from "@material-ui/core";
import { ExpandMore } from '@material-ui/icons';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { VendorRole } from "../../services/daml-modules1";
import { useDamlState, useDamlDispatch } from '../../context/DamlContext';
import { getOperatorParty } from "../../services/axios/daml-json-api";
import { getStates } from "../../models/State";
import { ContactModel, getGenders } from "../../models/Contact";
import { VendorDetailModel } from "../../models/VendorDetail";
import { AddressModel } from "../../models/Address";
import { onChangeNumber } from "../../components/Modals/handles";
import { setByPath } from "../../components/Contracts/functions";
import editIcon from '../../icons/edit.svg';
import folderIcon from '../../icons/folder.svg';
import { exerciseRoleOneAccept } from "./handle";
import { DAML_CLEAR_ROLE } from "../../store/actions/constants";
import useStyles from "./styles";
import { CustomLoading } from "../../hoc/withLoading";


/**
 * View Vendor Data page
 * @returns 
 */
export default function VendorList() {

  const classes = useStyles();
  const { role } = useDamlState();
  const party = useParty();
  const operator = getOperatorParty();
  const ledger = useLedger();
  const dispatch = useDamlDispatch();

  let searchParams = {};
  if (role === "Operator") searchParams = { operator: operator };
  else if (role === "Vendor") searchParams = { vendor: party };
  const assets = useStreamQueries(VendorRole, () => [searchParams]);

  const [contractId, setContractId] = useState('');
  const [vendordetails, setVendorDetails] = useState({ ...VendorDetailModel });
  const [editData, setEditData] = useState(0);
  const [expanded, setExpanded] = useState(0);
  const [selected, setSelected] = useState(0);
  const [count, setCount] = useState(0);


  // handle to update all fields
  const updateField = (key, isNumber = false) => (e) => {
    if (!isNumber || (isNumber && onChangeNumber(e))) setVendorDetails(setByPath(e.target.value, key, vendordetails));
  };

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const selectLess = () => (e) => {
    if (selected > 0) setSelected(selected - 1);
  };
  const selectMore = () => (e) => {
    if (selected < count - 1) setSelected(selected + 1);
  };

  // handle when click Edit button
  const handleEditDetail = (index, key) => {
    setSelected(0);
    setCount(vendordetails[key].length);
    setEditData(index);
  };

  // handle when click "Add More ..." button
  const handleAddMore = (key, defaultValue = AddressModel) => {
    setVendorDetails(setByPath({ ...defaultValue }, `${key}.${count}`, vendordetails));
    setSelected(count);
    setCount(count + 1);
  };

  // handle when click "Update" button
  const handleRequest = async () => {
    let res = await exerciseRoleOneAccept(vendordetails, ledger, contractId);
    if (res) dispatch({
      type: DAML_CLEAR_ROLE,
    });
    setEditData(0);
  };

  // handle when click "Cancel" button
  const handleCancel = () => {
    setVendorDetails({ ...assets.contracts[0].payload.vendordetails });
    setEditData(0);
  };

  useEffect(() => {
    if (!assets.loading && assets.contracts.length) {
      setContractId(assets.contracts[0].contractId);
      setVendorDetails({ ...assets.contracts[0].payload.vendordetails });
    }
  }, [assets]);

  console.log("[VendorList]", party, assets);


  const CustomExpandLessIcon = () => <ExpandLessIcon onClick={selectLess()} style={{ cursor: 'pointer' }} />;
  const CustomExpandMoreIcon = () => <ExpandMoreIcon onClick={selectMore()} style={{ cursor: 'pointer' }} />;


  return (
    <>
      <Typography variant="h4" className={classes.pageTitle}>View Vendor Data</Typography>
      {
        assets.loading
          ?
          <CustomLoading />
          :

          <Card className={classes.cardItem}>
            <CardContent>

              <List className={classes.listItem}>
                <ListItem>
                  <div className="item">
                    <Typography variant="h5" >
                      Vendor Name   {editData !== 1 && <img src={editIcon} alt="edit" onClick={() => { setEditData(1) }} />}
                    </Typography>
                    <Typography component={'div'}>
                      {editData === 1 &&
                        <TextField
                          id="outlined-full-width1-mrn"
                          label="Vendor Name"
                          placeholder="eg: BioMed"
                          className={classes.customFullWidth}
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          value={vendordetails.vendorname}
                          onChange={updateField('vendorname')}
                        />
                      }

                      {editData !== 1 && <span className="label">{vendordetails.vendorname}</span>}
                    </Typography>
                  </div>

                  {editData === 1 &&
                    <div className="form-footer">
                      <div className="right-buttons">
                        <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
                        <Button variant="outlined" color="primary" onClick={handleRequest}>Update</Button>
                      </div>
                    </div>
                  }
                </ListItem>

                <ListItem>
                  <div className="item">
                    <Typography variant="h5" >
                      Division Name   {editData !== 2 && <img src={editIcon} alt="edit" onClick={() => { setEditData(2) }} />}
                    </Typography>
                    <Typography component={'div'}>
                      {editData === 2 &&
                        <div className={classes.marginBM30}>
                          <TextField
                            id="outlined-full2-width-mrn"
                            label="Division Name"
                            placeholder="eg: BioMed"
                            className={classes.customFullWidth}
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            value={vendordetails.vendordivision}
                            onChange={updateField('vendordivision')}
                          />
                        </div>
                      }

                      {editData !== 2 && <span className="label">{vendordetails.vendordivision}</span>}
                    </Typography>
                  </div>

                  {editData === 2 &&
                    <div className="form-footer">
                      <div className="right-buttons">
                        <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
                        <Button variant="outlined" color="primary" onClick={handleRequest}>Update</Button>
                      </div>
                    </div>
                  }
                </ListItem>

                <ListItem>
                  <div className="item">
                    <Typography variant="h5" >
                      Vendor Address  {editData !== 3 && <img src={editIcon} alt="edit" onClick={() => { setEditData(3) }} />}
                    </Typography>
                    <Typography component={'div'}>
                      {editData === 3 &&
                        <>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined1"
                              label="Vendor Address1"
                              placeholder="47 Anyville Rd Nw #2"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.vendoraddress.address1 || ""}
                              onChange={updateField('vendoraddress.address1')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-2"
                              label="Vendor Address2"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.vendoraddress.address2 || ""}
                              onChange={updateField('vendoraddress.address2')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-f3"
                              label="City"
                              placeholder="Phoenix"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.vendoraddress.city || ""}
                              onChange={updateField('vendoraddress.city')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <FormControl variant="outlined" className={classes.tableCell} >
                              <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
                                State
                              </InputLabel>
                              <Select
                                // native
                                displayEmpty
                                inputProps={{
                                  name: 'state',
                                  id: 'outlined-age-native-simple1',
                                }}
                                value={vendordetails.vendoraddress.state || ""}
                                onChange={updateField('vendoraddress.state')}
                              >
                                {getStates()}
                              </Select>
                            </FormControl>
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-4n"
                              label="Zip Code"
                              placeholder="123456"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.vendoraddress.zipcode || ""}
                              onChange={updateField('vendoraddress.zipcode')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-5"
                              label="Country"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.vendoraddress.country || ""}
                              onChange={updateField('vendoraddress.country')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-6"
                              label="Location"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.vendoraddress.location || ""}
                              onChange={updateField('vendoraddress.location')}
                            />
                          </div>
                        </>
                      }

                      {editData !== 3 && <>
                        <span className="label">{vendordetails.vendoraddress.address1}</span>
                        <br />
                        <span className="label">{vendordetails.vendoraddress.city} {vendordetails.vendoraddress.state} {vendordetails.vendoraddress.zipcode}</span>
                      </>}
                    </Typography>
                  </div>

                  {editData === 3 &&
                    <div className="form-footer">
                      <div className="right-buttons">
                        <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
                        <Button variant="outlined" color="primary" onClick={handleRequest}>Update</Button>
                      </div>
                    </div>
                  }
                </ListItem>

                <ListItem>
                  <div className="item">
                    <Typography variant="h5" >
                      Vendor Contact  {editData !== 5 && <img src={editIcon} alt="edit" onClick={() => { handleEditDetail(5, 'vendorcontact') }} />}
                    </Typography>
                    <Typography component={'div'}>
                      {editData === 5 &&
                        <>
                          <Button variant="contained" color="primary" className={classes.textFormation}
                            onClick={() => handleAddMore('vendorcontact', ContactModel)}
                          >
                            Add More User
                          </Button>
                          <div className={classes.shippingItem}>
                            <Chip label={`${selected + 1}/${count}`} className="bg-info-light" />
                            <span><CustomExpandLessIcon /> <CustomExpandMoreIcon /> </span>
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full3-width-mrn"
                              label="First Name"
                              placeholder="eg: name"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.vendorcontact[selected].firstname || ""}
                              onChange={updateField(`vendorcontact.${selected}.firstname`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full-width88-mrn"
                              label="Last Name"
                              placeholder="eg: Smith"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.vendorcontact[selected].lastname || ""}
                              onChange={updateField(`vendorcontact.${selected}.lastname`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <FormControl variant="outlined" className={classes.formControl}>
                              <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
                                Gender
                              </InputLabel>
                              <Select
                                // native
                                displayEmpty
                                className={classes.halfWidth}
                                inputProps={{
                                  name: 'age',
                                  id: 'outlined-age-native-simple1',
                                }}
                                value={vendordetails.vendorcontact[selected].gender || ""}
                                onChange={updateField(`vendorcontact.${selected}.gender`)}
                              >
                                {getGenders()}
                              </Select>
                            </FormControl>
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full4-width-mrn"
                              label="Phone"
                              placeholder="eg: 0123456789"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.vendorcontact[selected].phone || ""}
                              onChange={updateField(`vendorcontact.${selected}.phone`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full-5width-mrn"
                              label="Email"
                              placeholder="eg: info@email.com"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.vendorcontact[selected].email || ""}
                              onChange={updateField(`vendorcontact.${selected}.email`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full6-width-mrn"
                              label="Fax"
                              placeholder="eg: 1234567"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.vendorcontact[selected].fax || ""}
                              onChange={updateField(`vendorcontact.${selected}.fax`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full7-width-mrn"
                              label="Job"
                              placeholder="eg: job title"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.vendorcontact[selected].jobtitle || ""}
                              onChange={updateField(`vendorcontact.${selected}.jobtitle`)}
                            />
                          </div>
                        </>}

                      {editData !== 5 && <>
                        <div className={classes.productAccordion}>
                          {vendordetails.vendorcontact.map((obj, index) => (
                            <Accordion key={index} expanded={expanded === (index + 1)}
                              onChange={handleChangeAccordion((index + 1))}
                              className={classes.generalMainAccordion}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                className="product-detail-accordion-head"
                              >
                                <Typography className={classes.accordionHeading}>{obj.firstname} {obj.lastname}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography component={'div'}>
                                  <TableContainer className={classes.productTableContainer} component={Paper}>
                                    <span className="label label-width">Name</span>
                                    <span className="value">{obj.firstname} {obj.lastname}</span> <br />
                                    <span className="label label-width">Gender</span>
                                    <span className="value ">{obj.gender}</span>  <br />
                                    <span className="label label-width">Phone</span>
                                    <span className="value">{obj.phone}</span> <br />
                                    <span className="label label-width">Email</span>
                                    <span className="value">{obj.email}</span> <br />
                                    <span className="label label-width">Fax</span>
                                    <span className="value ">{obj.fax}</span> <br />
                                    <span className="label label-width">Job Title</span>
                                    <span className="value">{obj.jobtitle}</span>
                                  </TableContainer>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                        </div>
                      </>}

                    </Typography>
                  </div>

                  {editData === 5 &&
                    <div className="form-footer">
                      <div className="right-buttons">
                        <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
                        <Button variant="outlined" color="primary" onClick={handleRequest}>Update</Button>
                      </div>
                    </div>
                  }
                </ListItem>

                <ListItem>
                  <div className="item">
                    <Typography variant="h5" >
                      Vendor Contact Address  {editData !== 4 && <img src={editIcon} alt="edit" onClick={() => { setEditData(4) }} />}
                    </Typography>
                    <Typography component={'div'}>
                      {editData === 4 &&
                        <>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full8-width-mrn"
                              label="Vendor Contact Address1"
                              placeholder="eg: 47 Anyville Rd"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.vendorcontactaddress.address1 || ""}
                              onChange={updateField('vendorcontactaddress.address1')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-2"
                              label="Vendor Contact Address2"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.vendorcontactaddress.address2 || ""}
                              onChange={updateField('vendorcontactaddress.address2')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-f3"
                              label="City"
                              placeholder="Phoenix"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.vendorcontactaddress.city || ""}
                              onChange={updateField('vendorcontactaddress.city')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <FormControl variant="outlined" className={classes.tableCell} >
                              <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
                                State
                              </InputLabel>
                              <Select
                                // native
                                displayEmpty
                                inputProps={{
                                  name: 'state',
                                  id: 'outlined-age-native-simple1',
                                }}
                                value={vendordetails.vendorcontactaddress.state || ""}
                                onChange={updateField('vendorcontactaddress.state')}
                              >
                                {getStates()}
                              </Select>
                            </FormControl>
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-4n"
                              label="Zip Code"
                              placeholder="123456"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.vendorcontactaddress.zipcode || ""}
                              onChange={updateField('vendorcontactaddress.zipcode')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-5"
                              label="Country"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.vendorcontactaddress.country || ""}
                              onChange={updateField('vendorcontactaddress.country')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-6"
                              label="Location"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.vendorcontactaddress.location || ""}
                              onChange={updateField('vendorcontactaddress.location')}
                            />
                          </div>
                        </>
                      }

                      {editData !== 4 && <>
                        <span className="label">{vendordetails.vendorcontactaddress.address1}</span>
                        <br />
                        <span className="label">{vendordetails.vendorcontactaddress.city} {vendordetails.vendorcontactaddress.state} {vendordetails.vendorcontactaddress.zipcode}</span>
                      </>}
                    </Typography>
                  </div>

                  {editData === 4 &&
                    <div className="form-footer">
                      <div className="right-buttons">
                        <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
                        <Button variant="outlined" color="primary" onClick={handleRequest}>Update</Button>
                      </div>
                    </div>
                  }
                </ListItem>

                <ListItem>
                  <div className="item">
                    <Typography variant="h5" >
                      Tax ID   {editData !== 9 && <img src={editIcon} alt="edit" onClick={() => { setEditData(9) }} />}
                    </Typography>
                    <Typography component={'div'}>
                      {editData === 9 &&
                        <div className={classes.marginBM30}>
                          <TextField
                            id="outlined-full9-width-mrn"
                            label="Tax ID"
                            placeholder="eg: 456-785-1496"
                            className={classes.customFullWidth}
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            value={vendordetails.taxid || ""}
                            onChange={updateField('taxid')}
                          />
                        </div>
                      }

                      {editData !== 9 && <span className="label">{vendordetails.taxid}</span>}
                    </Typography>
                  </div>

                  {editData === 9 &&
                    <div className="form-footer">
                      <div className="right-buttons">
                        <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
                        <Button variant="outlined" color="primary" onClick={handleRequest}>Update</Button>
                      </div>
                    </div>
                  }
                </ListItem>

                <ListItem>
                  <div className="item">
                    <Typography variant="h5" >
                      Remit Contact   {editData !== 6 && <img src={editIcon} alt="edit" onClick={() => { handleEditDetail(6, 'remittocontact') }} />}
                    </Typography>
                    <Typography component={'div'} >
                      {editData === 6 &&
                        <>
                          <Button variant="contained" color="primary" className={classes.textFormation}
                            onClick={() => handleAddMore('remittocontact', ContactModel)}
                          >
                            Add More User
                          </Button>
                          <div className={classes.shippingItem}>
                            <Chip label={`${selected + 1}/${count}`} className="bg-info-light" />
                            <span> <CustomExpandLessIcon /> <CustomExpandMoreIcon /> </span>
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined1"
                              label="First Name"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.remittocontact[selected].firstname || ""}
                              onChange={updateField(`remittocontact.${selected}.firstname`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-2"
                              label="Last Name"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.remittocontact[selected].lastname || ""}
                              onChange={updateField(`remittocontact.${selected}.lastname`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <FormControl variant="outlined" className={classes.formControl}>
                              <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
                                Gender
                              </InputLabel>
                              <Select
                                // native
                                displayEmpty
                                className={classes.halfWidth}
                                inputProps={{
                                  name: 'age',
                                  id: 'outlined-age-native-simple1',
                                }}
                                value={vendordetails.remittocontact[selected].gender || ""}
                                onChange={updateField(`remittocontact.${selected}.gender`)}
                              >
                                {getGenders()}
                              </Select>
                            </FormControl>
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-4n"
                              label="Phone"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.remittocontact[selected].phone || ""}
                              onChange={updateField(`remittocontact.${selected}.phone`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-5"
                              label="Email"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.remittocontact[selected].email || ""}
                              onChange={updateField(`remittocontact.${selected}.email`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-6"
                              label="Fax"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.remittocontact[selected].fax || ""}
                              onChange={updateField(`remittocontact.${selected}.fax`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-6"
                              label="Job Title"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.remittocontact[selected].jobtitle || ""}
                              onChange={updateField(`remittocontact.${selected}.jobtitle`)}
                            />
                          </div>
                        </>
                      }

                      {editData !== 6 && <Typography className={classes.folderItem} component={'div'}>
                        <img src={folderIcon} alt="" />
                        <Button className={classes.textFormation} variant="outlined" color="primary"
                          onClick={() => { handleEditDetail(6, 'remittocontact') }}
                        >
                          Add Contact
                        </Button>
                      </Typography>}
                    </Typography>
                  </div>

                  {editData === 6 &&
                    <div className="form-footer">
                      <div className="right-buttons">
                        <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
                        <Button variant="outlined" color="primary" onClick={handleRequest}>Update</Button>
                      </div>
                    </div>
                  }
                </ListItem>

                <ListItem>
                  <div className="item">
                    <Typography variant="h5" >
                      Remit Contact Address  {editData !== 10 && <img src={editIcon} alt="edit" onClick={() => { setEditData(10) }} />}
                    </Typography>
                    <Typography component={'div'}>
                      {editData === 10 &&
                        <>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full10-width-mrn"
                              label="Remit Contact Address1"
                              placeholder="eg: 47 Anyville Rd"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.remittoaddress.address1 || ""}
                              onChange={updateField('remittoaddress.address1')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-32"
                              label="Remit Contact Address2"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.remittoaddress.address2 || ""}
                              onChange={updateField('remittoaddress.address2')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-f6"
                              label="City"
                              placeholder="Phoenix"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.remittoaddress.city || ""}
                              onChange={updateField('remittoaddress.city')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <FormControl variant="outlined" className={classes.tableCell} >
                              <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
                                State
                              </InputLabel>
                              <Select
                                // native
                                displayEmpty
                                inputProps={{
                                  name: 'state',
                                  id: 'outlined-age-native-simple1',
                                }}
                                value={vendordetails.remittoaddress.state || ""}
                                onChange={updateField('remittoaddress.state')}
                              >
                                {getStates()}
                              </Select>
                            </FormControl>
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-7n"
                              label="Zip Code"
                              placeholder="123456"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.remittoaddress.zipcode || ""}
                              onChange={updateField('remittoaddress.zipcode')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-35"
                              label="Country"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.remittoaddress.country || ""}
                              onChange={updateField('remittoaddress.country')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-36"
                              label="Location"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.remittoaddress.location || ""}
                              onChange={updateField('remittoaddress.location')}
                            />
                          </div>
                        </>
                      }

                      {editData !== 10 && <>
                        <span className="label">{vendordetails.remittoaddress.address1}</span>
                        <br />
                        <span className="label">{vendordetails.remittoaddress.city} {vendordetails.remittoaddress.state} {vendordetails.remittoaddress.zipcode}</span>
                      </>}
                    </Typography>
                  </div>

                  {editData === 10 &&
                    <div className="form-footer">
                      <div className="right-buttons">
                        <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
                        <Button variant="outlined" color="primary" onClick={handleRequest}>Update</Button>
                      </div>
                    </div>
                  }
                </ListItem>

                <ListItem>
                  <div className="item">
                    <Typography variant="h5" >
                      Customer Service Contact  {editData !== 8 && <img src={editIcon} alt="edit" onClick={() => { handleEditDetail(8, 'customerservicecontact') }} />}
                    </Typography>
                    <Typography component={'div'}>
                      {editData === 8 &&
                        <>
                          <Button variant="contained" color="primary" className={classes.textFormation}
                            onClick={() => handleAddMore('customerservicecontact', ContactModel)}
                          >
                            Add More User
                          </Button>
                          <div className={classes.shippingItem}>
                            <Chip label={`${selected + 1}/${count}`} className="bg-info-light" />
                            <span><CustomExpandLessIcon /> <CustomExpandMoreIcon /> </span>
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full11-width-mrn"
                              label="First Name"
                              placeholder="eg: name"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.customerservicecontact[selected].firstname || ""}
                              onChange={updateField(`customerservicecontact.${selected}.firstname`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full-width77-mrn1"
                              label="Last Name"
                              placeholder="eg: Smith"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.customerservicecontact[selected].lastname || ""}
                              onChange={updateField(`customerservicecontact.${selected}.lastname`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <FormControl variant="outlined" className={classes.formControl}>
                              <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
                                Gender
                              </InputLabel>
                              <Select
                                // native
                                displayEmpty
                                className={classes.halfWidth}
                                inputProps={{
                                  name: 'age',
                                  id: 'outlined-age-native-simple1',
                                }}
                                value={vendordetails.customerservicecontact[selected].gender || ""}
                                onChange={updateField(`customerservicecontact.${selected}.gender`)}
                              >
                                {getGenders()}
                              </Select>
                            </FormControl>
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full12-width-mrn"
                              label="Phone"
                              placeholder="eg: 0123456789"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.customerservicecontact[selected].phone || ""}
                              onChange={updateField(`customerservicecontact.${selected}.phone`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full13-width-mrn"
                              label="Email"
                              placeholder="eg: info@email.com"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.customerservicecontact[selected].email || ""}
                              onChange={updateField(`customerservicecontact.${selected}.email`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full14-width-mrn"
                              label="Fax"
                              placeholder="eg: 1234567"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.customerservicecontact[selected].fax || ""}
                              onChange={updateField(`customerservicecontact.${selected}.fax`)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full15-width-mrn"
                              label="Job"
                              placeholder="eg: job title"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.customerservicecontact[selected].jobtitle || ""}
                              onChange={updateField(`customerservicecontact.${selected}.jobtitle`)}
                            />
                          </div>
                        </>
                      }

                      {editData !== 8 && <>
                        <div className={classes.productAccordion}>
                          {vendordetails.customerservicecontact.map((obj, index) => (
                            <Accordion key={index} expanded={expanded === (index + 'pic')}
                              onChange={handleChangeAccordion((index + 'pic'))}
                              className={classes.generalMainAccordion}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                className="product-detail-accordion-head"
                              >
                                <Typography className={classes.accordionHeading}>{obj.firstname} {obj.lastname}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography component={'div'}>
                                  <TableContainer className={classes.productTableContainer} component={Paper}>
                                    <span className="label label-width">Name</span>
                                    <span className="value">{obj.firstname} {obj.lastname}</span> <br />
                                    <span className="label label-width">Gender</span>
                                    <span className="value ">{obj.gender}</span>  <br />
                                    <span className="label label-width">Phone</span>
                                    <span className="value">{obj.phone}</span> <br />
                                    <span className="label label-width">Email</span>
                                    <span className="value">{obj.email}</span> <br />
                                    <span className="label label-width">Fax</span>
                                    <span className="value ">{obj.fax}</span> <br />
                                    <span className="label label-width">Job Title</span>
                                    <span className="value">{obj.jobtitle}</span>
                                  </TableContainer>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                        </div>
                      </>}
                    </Typography>
                  </div>

                  {editData === 8 &&
                    <div className="form-footer">
                      <div className="right-buttons">
                        <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
                        <Button variant="outlined" color="primary" onClick={handleRequest}>Update</Button>
                      </div>
                    </div>
                  }
                </ListItem>

                <ListItem>
                  <div className="item">
                    <Typography variant="h5" >
                      Customer Service Contact Address   {editData !== 7 && <img src={editIcon} alt="edit" onClick={() => { setEditData(7) }} />}
                    </Typography>
                    <Typography component={"div"}>
                      {editData === 7 &&
                        <>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined1"
                              label="Customer Service Contact Address1"
                              placeholder="47 Anyville Rd Nw #2"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.customerservicedaddress.address1 || ""}
                              onChange={updateField('customerservicedaddress.address1')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-32"
                              label="Customer Service Contact Address2"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.customerservicedaddress.address2 || ""}
                              onChange={updateField('customerservicedaddress.address2')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-f6"
                              label="City"
                              placeholder="Phoenix"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.customerservicedaddress.city || ""}
                              onChange={updateField('customerservicedaddress.city')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <FormControl variant="outlined" className={classes.tableCell} >
                              <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
                                State
                              </InputLabel>
                              <Select
                                // native
                                displayEmpty
                                inputProps={{
                                  name: 'state',
                                  id: 'outlined-age-native-simple1',
                                }}
                                value={vendordetails.customerservicedaddress.state || ""}
                                onChange={updateField('customerservicedaddress.state')}
                              >
                                {getStates()}
                              </Select>
                            </FormControl>
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-7n"
                              label="Zip Code"
                              placeholder="123456"
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.customerservicedaddress.zipcode || ""}
                              onChange={updateField('customerservicedaddress.zipcode')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-35"
                              label="Country"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.customerservicedaddress.country || ""}
                              onChange={updateField('customerservicedaddress.country')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-36"
                              label="Location"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={vendordetails.customerservicedaddress.location || ""}
                              onChange={updateField('customerservicedaddress.location')}
                            />
                          </div>
                        </>
                      }

                      {editData !== 7 && <Typography className={classes.folderItem} component={'div'}>
                        <img src={folderIcon} alt="" />
                        <Button variant="outlined" color="primary" className={classes.textFormation}
                          onClick={() => { setEditData(7) }}
                        >
                          Add Billing Info Contact
                        </Button>
                      </Typography>}
                    </Typography>
                  </div>

                  {editData === 7 &&
                    <div className="form-footer">
                      <div className="right-buttons">
                        <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
                        <Button variant="outlined" color="primary" onClick={handleRequest}>Update</Button>
                      </div>
                    </div>
                  }
                </ListItem>

                <ListItem>
                  <div className="item">
                    <Typography variant="h5" >
                      ACH Information   {editData !== 11 && <img src={editIcon} alt="edit" onClick={() => { setEditData(11) }} />}
                    </Typography>
                    <Typography className={classes.folderItem} component={"div"}>
                      {editData === 11 &&
                        <>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full16-width-mrn"
                              label="Account Number"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              onChange={updateField('ach_info.accountnumber', true)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full17-width-mrn"
                              label="Routing Number"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              onChange={updateField('ach_info.routingnumber', true)}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full18-width-mrn"
                              label="Bank"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              onChange={updateField('ach_info.bankname')}
                            />
                          </div>
                          <div className={classes.marginBM30}>
                            <TextField
                              id="outlined-full19-width-mrn"
                              label="Type"
                              placeholder=""
                              className={classes.customFullWidth}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              onChange={updateField('ach_info.typeaccount')}
                            />
                          </div>
                        </>
                      }

                      {editData !== 11 && <>
                        <img src={folderIcon} alt="" />
                        <Button variant="outlined" color="primary" className={classes.textFormation}
                          onClick={() => { setEditData(11) }}
                        >
                          Add ACH Information
                        </Button>
                      </>}
                    </Typography>
                  </div>

                  {editData === 11 &&
                    <div className="form-footer">
                      <div className="right-buttons">
                        <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
                        <Button variant="outlined" color="primary" onClick={handleRequest}>Update</Button>
                      </div>
                    </div>
                  }
                </ListItem>
              </List>

            </CardContent>
          </Card>

      }
    </>
  );
};