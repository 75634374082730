import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { Chip } from "@material-ui/core";
import Replay from "@material-ui/icons/Replay";
// import { Inventory } from "../../services/daml-modules1";
import Contracts from "../../components/Contracts/Contracts";
import AdaptiveSearch from "../../components/Search/adaptivesearch";
import SelectSearch from "../../components/Search/selectsearch";
import { Ownerships, InventoryOwnerships } from "../../models/Ownership";
import { getTotalPrice } from "../../models/InventoryData";
import { contractFilter } from "../../services/daml-filters";
import DetailViewDrawer from "./detail-view-drawer";
import { /* getState, */ dispatch } from "../../store";
import { inventorySetDetail, /* callbackKeySearch */ } from "../../store/actions/generalActions";
// import { retrieveESRedux } from "../../services/axios/retrieves";
import EditPrice from '../purchaseorder/EditPrice';
import { updateInventoryProduct } from './handle';
import { useDamlState } from "../../context/DamlContext";
import useStyles from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";


/**
 * Account Inventoy List Contracts in Hospital
 * @param {Object} searchParams 
 * @param {Object} searchParamsInternal 
 * @param {Function} handleChangeSearchValue 
 * @param {Array} allContracts 
 * @param {Object} searchInventory 
 * @param {String} hospital 
 * @param {Object} searchParams 
 * @returns 
 */
function InventoryListContractsHospital({
  searchParams = {},
  searchParamsInternal = {},
  handleChangeSearchValue = null,
  allContracts = [],
  searchInventory = {},
  hospital,
  ledger,
}) {
  const classes = useStyles();
  const history = useHistory();
  // const assets = useStreamQueries(Inventory, () => [searchParams]);
  let contracts = contractFilter(allContracts, searchParams);
  const { roleCid } = useDamlState();

  // Detail Viewer
  const [state, setState] = useState(false);
  const [selectedInventory, setSelectedInventory] = useState({});

  // let contracts = [];
  /* if (assets && !assets.loading && assets.contracts && assets.contracts.length) {
    // split by PO Number (ipurchaseordernumber)
    assets.contracts.forEach(c => {
      if (c.payload.inventorydata && c.payload.inventorydata.length) {
        let rows = {}, poids = [];
        c.payload.inventorydata.forEach((invData, index) => {
          // Disable to ignore no-Opens
          // if (invData.iproductstatus !== "Opens") {
            if (poids.indexOf(invData.ipurchaseordernumber) >= 0) {
              rows[invData.ipurchaseordernumber].payload.inventorydata.push(invData);
              rows[invData.ipurchaseordernumber].payload.inventorylocation.push(c.payload.inventorylocation[index]);
            }
            else {
              rows[invData.ipurchaseordernumber] = {};
              rows[invData.ipurchaseordernumber].payload = {...c.payload};
              rows[invData.ipurchaseordernumber].payload.contractId = c.contractId;
              rows[invData.ipurchaseordernumber].payload.inventorydata = [invData];
              rows[invData.ipurchaseordernumber].payload.inventorylocation = [c.payload.inventorylocation[index]];
              rows[invData.ipurchaseordernumber].ipurchaseordernumber = invData.ipurchaseordernumber;
              poids.push(invData.ipurchaseordernumber);
            }
          // }
        });
        if (poids.length) contracts = [...contracts, ...Object.values(rows)];
      }
    });
  } */
  // no split ipurchaseordernumber
  contracts = contracts
    .map(c => {
      c.payload.inventorydata = c.payload.inventorydata.filter(invData => invData.iproductstatus !== "Opens");
      c.payload.price = getTotalPrice(c.payload.inventorydata);

      /* c.payload.retrieveKey = {};
      if (!!c.payload.inventoryproduct.ireferencenumber) {
        // c.payload.retrieveKey = { result: c.payload.inventoryproduct.ireferencenumber, field: 'ReferenceNumber' };
        c.payload.retrieveKey = { query: {
          ReferenceNumber: c.payload.inventoryproduct.ireferencenumber, 
          Hospital: hospital} 
        };
      } */

      // new sortKey
      c.newsortkey = getInventorySortKey(c);

      // status
      c.payload.status = getInventoryDataStatus(c.payload.inventorydata);
      // return status
      c.payload.returnstatus = getInventoryReturnStatus(c.payload.inventorydata);

      return c;
    })
    .filter(c => {
      let result = true;
      if (searchParamsInternal && searchParamsInternal.sepproductmanufacturer && searchParamsInternal.sepproductmanufacturer !== '') {
        // search sepproductmanufacturer
        result = result && (c.payload.inventorydata.length && c.payload.inventorydata[0].iproduct.sepproductmanufacturer === searchParamsInternal.sepproductmanufacturer);
      }
      if (searchParamsInternal && searchParamsInternal.status && searchParamsInternal.status !== '') {
        // search status
        result = result && (c.payload.status.indexOf(searchParamsInternal.status) !== -1);
      }
      return result;
    });
  console.log("[InventoryListContractsHospital]", searchParams, contracts);


  const toggleDrawer = (open) => {
    setState(open);
  };


  // handle to change par level
  const handleChangeParlevel = async (parlevel, product) => {
    console.log("[handleChangeParlevel]", parlevel, product);

    await updateInventoryProduct({
      ledger,
      ContractId: product.contract.contractId,
      newinventoryproduct: { ...product.contract.payload.inventoryproduct, parlevel },
      roleCid,
    });

  };


  return (
    <>
      <Contracts
        className={classes.customRoot}
        contracts={contracts}
        columns={[
          // ["ContractId", "contractId"],
          ["Reference Number", "payload.inventoryproduct.ireferencenumber", {
            type: "link",
            onClick: (contractId, data) => {
              if (!!data && 'contract' in data) {
                setSelectedInventory({
                  ...data.contract.payload,
                });
                toggleDrawer(true);
              }
            },
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.inventoryproduct.ireferencenumber}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'ireferencenumber')}
                  defaultValue={searchInventory.ireferencenumber}
                  label="Search Reference Number"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchInventory.ireferencenumber,
            handleClear: () => handleChangeSearchValue('', 'ireferencenumber'),
            options: {
              width: 160,
              cellClassName: classNames(classes.primaryColor),
            },
          }],
          ["Quantity", "payload.inventoryproduct.itemcount", {
            type: "link",
            onClick: (contractId, data) => {
              if (!!data && 'contract' in data) {
                dispatch(inventorySetDetail({
                  ...data.contract.payload,
                  contractId: data.contract.contractId,
                }));
                history.push("/app/inventorylist-pos");
              }
            },
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.inventoryproduct.itemcount}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'itemcount')}
                  defaultValue={searchInventory.itemcount}
                  label="Search Quantity"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchInventory.itemcount,
            handleClear: () => handleChangeSearchValue('', 'itemcount'),
            options: {
              width: 100,
              cellClassName: classNames(classes.primaryColor, classes.centerCell),
            },
          }],
          ["Par Level", "payload.inventoryproduct.parlevel", {
            type: "render",
            render: (param) => (
              <>
                <span>
                  {param.value}
                </span>
                {
                  <EditPrice
                    priceValue={param.value}
                    contract={param.row}
                    setPriceValue={handleChangeParlevel}
                    placeholder="Par Level"
                  />
                }
              </>
            ),
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.inventoryproduct.parlevel}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'parlevel')}
                  defaultValue={searchInventory.parlevel}
                  label="Search Par level"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchInventory.parlevel,
            handleClear: () => handleChangeSearchValue('', 'parlevel'),
            options: {
              width: 100,
              cellClassName: classes.editPriceItem //Price change color based on condition
            }
          }],
          ["Product Description", "payload.inventoryproduct.iproductdescription", {
            options: {
              width: 170,
              tooltip: true,
            },
          }],
          ["Distributor", "payload.vendor", {
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.vendor}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'vendor')}
                  defaultValue={searchInventory.vendor}
                  label="Search Distributor"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchInventory.vendor,
            handleClear: () => handleChangeSearchValue('', 'vendor'),
            options: {
              width: 100,
            },
          }],
          ["Manufacturer", "payload.inventorydata.0.iproduct.sepproductmanufacturer", {
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.inventorydata.length && 
                    c.payload.inventorydata[0].iproduct.sepproductmanufacturer}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'sepproductmanufacturer')}
                  defaultValue={searchParamsInternal.sepproductmanufacturer}
                  label="Search Manufacturer"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchParamsInternal.sepproductmanufacturer,
            handleClear: () => handleChangeSearchValue('', 'sepproductmanufacturer'),
            options: {
              width: 130,
            },
          }],
          ["Price", "payload.inventoryproduct.iprice", {
            type: "render",
            render: (param) => (param.value ? `$${param.value}` : 'No Contract Price'),
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.inventoryproduct.iprice ? c.payload.inventoryproduct.iprice: 'No Contract Price'}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'iprice')}
                  defaultValue={searchInventory.iprice===null ? 'No Contract Price' : searchInventory.iprice}
                  label="Search Price"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchInventory.iprice,
            handleClear: () => handleChangeSearchValue('', 'iprice'),
            options: {
              width: 140,
            },
          }],
          /* ["Price", "payload.retrieveKey", {
            type: "callback",
            callback: (param, cb) => {
              const keys = getState().callbackKey;
              const paramKey = JSON.stringify(param.query);
              // console.log("[InventoryListContractsHospital] callback retrieveKey", param, keys);
              if (paramKey in keys) {
                cb(keys[paramKey] ? `${keys[paramKey]}` : 'No Contract Price');
              }
              else {
                retrieveESRedux(param, result => {
                  const res = (result && 'sepproductprice' in result && result.sepproductprice) 
                    ? `$${result.sepproductprice}` : 'No Contract Price';
                  cb(res);
                  dispatch(callbackKeySearch({
                    key: paramKey,
                    value: res,
                  }));
                });
              }
            },
            options: {
              width: 150,
            },
          }], */
          ["Ownership", "payload.inventoryproduct.iownership", {
            type: "render",
            render: (param) => (param.value in Ownerships ? Ownerships[param.value] : null),
            search: {
              component:
                <SelectSearch
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'iownership')}
                  label="Search Ownership"
                  options={Object.keys(InventoryOwnerships).map(value => ({ label: InventoryOwnerships[value], value: value }))}
                  defaultValue={searchInventory.iownership}
                  isStatic={true}
                />
            },
            isSearched: searchInventory.iownership,
            handleClear: () => handleChangeSearchValue('', 'iownership'),
            options: {
              width: 130,
            },
          }],
          ["Status", "payload.status", {
            type: "render",
            render: (param) => {
              const status = param.row.contract.payload.status;
              return (status.length ?
                status.map((s, i) => (<Chip
                  label={s}
                  key={i}
                  size="small"
                  className={classNames(classes.smallChip, (s === 'Received' ? classes.backGreen : (
                    s === 'Recall' ? classes.backPink : (
                      s === 'Edited' ? classes.backLightGreen : (
                        s === 'Expiring' ? classes.backYellow : (
                          (s === 'Back-order' || s === 'Expired') ? classes.backRed : classes.backBlue
                        )
                      )
                    )
                  )))}
                  style={{ marginLeft: 5 }}
                />))
                : <></>);
            },
            search: {
              component:
                <SelectSearch
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'status')}
                  label="Search Status"
                  options={[
                    { label: 'Expired', value: 'Expired' },
                    { label: 'Expiring', value: 'Expiring' },
                    { label: 'Received', value: 'Received' },
                    { label: 'Shipped', value: 'Shipped' },
                    { label: 'Back-order', value: 'BackOrder' },
                    { label: 'Recall', value: 'Recall' },
                  ]}
                  defaultValue={searchParamsInternal.status}
                  isStatic={true}
                />
            },
            isSearched: searchParamsInternal.status,
            handleClear: () => handleChangeSearchValue('', 'status'),
            options: {
              flex: 1,
              width: 150,
            },
          }],
        ]}

        actions={[
          [
            "",
            [
              [["", [
                <Replay style={{color: "red"}} />,
                null,
              ],
                (c) => (c.payload.returnstatus ? 1 : 0)
              ],
                () => {}],
            ],
          ]
        ]}
        options={{
          statusEmpty: (allContracts.length === 0),
          rowsPerPage: 100,
          sortKey: 'newsortkey',
        }}
      />

      <DetailViewDrawer
        state={state}
        toggleDrawer={toggleDrawer}
        selectedInventory={selectedInventory}
      />

    </>
  );
}

export default withLoading(InventoryListContractsHospital);

/**
 * get status by inventorydata list
 * get all stauts of every inventory data in list,
 * Received, Shipped, Back-order, Recall, Expiring, Expired
 * @param {Array} inventorydatas
 * @param {Object} options 
 * @returns {Array}
 */
export const getInventoryDataStatus = (inventorydatas, options={}) => {
  // console.log("[InventoryListContractsHospital] getInventoryDataStatus", inventorydatas, options);
  let result = [];
  if (inventorydatas.length > 0) {
    inventorydatas.forEach(inventorydata => {
      if (inventorydata.iproductstatus === 'BackOrder') result.push('Back-order');
      else if (inventorydata.iproductstatus === 'Shipped') result.push('Shipped');
      // else if (inventorydata.iproductstatus === 'Received') result.push('Received');
      if (!!inventorydata.iexpirationstatus) result.push(inventorydata.iexpirationstatus);
      if (!!inventorydata.irecallstatus) result.push('Recall');
      if (!!inventorydata.itempstatus && typeof inventorydata.itempresponsedecision !== 'boolean') result.push('Edited');
    });
  }
  result = result.filter((value, index, self) => (self.indexOf(value) === index));
  return result;
};

/**
 * check if includes Inventory Data with Returned
 * @param {Array} inventorydatas 
 * @returns {Boolean}
 */
export const getInventoryReturnStatus = (inventorydatas) => {
  if (inventorydatas.length > 0) {
    for (let invd of inventorydatas) {
      if (!!invd.ireturnstatus) return true;
    }
  }
  return false;
};

/**
 * Inventory Sort Key
 * Distributor asec, ItemCount desc
 * @param {Object} c 
 * @returns 
 */
export const getInventorySortKey = (c) => {
  let newsortkey;
  // newsortkey = `${c.payload.created_at}${JSON.stringify(c.key)}`; // created_at, key desc
  // newsortkey = `${c.payload.vendor} ${c.payload.inventoryproduct.itemcount}`; // vendor, itemcout desc
  // new logic (8 letters in vendor & itemcount)
  newsortkey = '';
  for (let i = 0; i < 8; i++) {
    const ch = 36 - (c.payload.vendor.length > i ? parseInt(c.payload.vendor[i], 36) : 0);
    newsortkey = `${newsortkey}${ch.toString().padStart(2, '0')}`;
    // console.log("[getInventorySortKey]", c.payload.vendor, newsortkey);
  }
  const ch = c.payload.inventoryproduct.itemcount ? c.payload.inventoryproduct.itemcount : '0';
  newsortkey = `${newsortkey}${ch.padStart(4, '0')}`;
  // console.log("[getInventorySortKey]", c.payload.inventoryproduct.itemcount, newsortkey);
  return newsortkey;
};

/**
 * Get Product contract price by checking idisplaySettings 
 * @param {*} c 
 * @returns 
 */
export const getProductDisplayContractPrice = (c) => {
  
  const unitUom = c?.payload?.inventoryproduct?.iuom || c?.payload?.iproduct_sepuom;
 
  if(unitUom) {
    const unit = c?.payload?.inventoryproduct?.iunits?.find(({ uom }) => uom === unitUom);
    return unit !== undefined ? unit.price : "";
  }
  // return c?.payload?.inventoryproduct?.iprice !== undefined ? c?.payload?.inventoryproduct?.iprice : "";
  return "";

}

/**
 * Get Product display UOM by ] idisplaySettings 
 * @param {*} c 
 * @returns 
 */
export const getProductDisplayUOM = (c) => {
  let iuom = c?.payload?.inventoryproduct?.iuom || c?.payload?.iproduct_sepuom;
  if(!iuom) {
    iuom = c?.payload?.inventorydata[0]?.iproduct?.sepuom;
  }
  return iuom || "N/A";
}