import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { DataGrid } from '@material-ui/data-grid';
// import { XGrid } from '@material-ui/x-grid';
import { getValueByCol, renderCell, sortContracts } from "./functions";
import { renderActionCells, ToolTipCell } from "./ActionCell";
import { renderHeaderCell } from "./HeaderCell";
import { CustomPagination } from "./CustomPagination";
//import icon from '../../icons/blank.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStyles } from "./styles";


/**
 * Contracts based on DataGrid
 * @param {Array} contracts 
 * @param {Array} columns 
 * @param {Array} actions 
 * @param {Object} options 
 * @returns 
 */
export default function Contracts({ 
  contracts, columns, actions = [], options = {},
}) {

  actions = actions ? actions : [];
  options = {
    checkbox: false,
    collapseFixed: false,
    checkboxSelectHandler: null,
    sortKey: 'payload.created_at', // old version: 'created_at',
    loading: false,
    isFooter: true,
    isPagination: true,
    rowsPerPage: 10,
    statusEmpty: true, // if empty page, then it's true
    ...options
  };
  // console.log("[Contracts] options", options);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(options.rowsPerPage);

  const isDefault = !columns;
  columns = columns ? columns : [["TemplateId", "templateId"], ["ContractId", "contractId"]];

  contracts = sortContracts(contracts, options.sortKey);
  // console.log("[Contracts]", contracts, columns);

  const classes = useStyles();

  
  // columns of DataGrid
  let cols = columns.map(col => ({
    field: col[0],
    headerName: col[0],
    description: col[0],
    headerClassName: classes.headerCell,
    cellClassName: classes.tableCell,
    disableColumnMenu: true,
    sortable: (!col[2] || typeof col[2].search === 'undefined'),
    renderHeader: renderHeaderCell(col[2]),
    renderCell: renderCell(col[2]),
    ...((!!col[2] && !!col[2].options) ? col[2].options : {})
  }));
  // console.log("columns",cols);
  if (isDefault) cols.push({
    field: 'payload',
    headerName: 'Payload',
  });
  if (actions.length) cols = cols.concat(actions.map(act => ({
    field: act[0],
    headerName: act[0],
    description: act[0],
    sortable: false,
    // flex: 1,
    cellClassName: classes.actionCellWrap,
    disableColumnMenu: true,
    renderCell: renderActionCells(act[1]),
    ...((!!act[2] && typeof act[2] === 'object') ? act[2] : {})
  })));
  // console.log("[Contracts] cols", cols);


  // rows of DataGrid
  const rows = contracts.map((c, i) => {
    let values = {
      id: c.contractId ? c.contractId : i,
      contract: c,
    };
    
    columns.forEach(col => {
      let val = getValueByCol(c, col[1], col[2]);
      val = typeof val === "boolean" ? (val ? "True" : "False") : val;

      if (!!val && typeof val === "object" && val.length >= 0 && typeof col[2] === "object"
        // && 'columns' in col[2]
        ) {
          val = val.length;
      }
      if (col[2] && typeof col[2].options !=='undefined' && typeof col[2].options.tooltip !=='undefined' && col[2].options.tooltip){
        val = <ToolTipCell cellData={val}/>
      }
      //console.log("[Contracts] cell", col[2]);
      // if (!!val && typeof val === "object") val = "";

      values[col[0]] = val;
      
    });
    

    actions.forEach(act => {
      values[act[0]] = c;
    });
    
    return values;
  });
  // console.log("[Contracts] rows", rows);
  
 
  return (
    <>
      <Grid className={classes.gridView} container spacing={4}>
        <Grid item xs={12} className={classes.tableData}>

          <DataGrid 
            className={classes.datagrid}
            rows={rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)} 
            columns={cols} 
            checkboxSelection={options.checkbox} 
            autoHeight={true}
            loading={options.loading}
            hideFooter={!options.isFooter}
            hideFooterPagination={!options.isPagination}
            // modifires // why ???
            components={{
              Pagination: CustomPagination,
            }}
            componentsProps={{
              pagination: {
                count: rows.length,
                page: page,
                setPage: setPage,
                rowsPerPage: rowsPerPage,
                setRowsPerPage: setRowsPerPage,
              },
            }}
          />
       
          { options.statusEmpty && rows.length === 0 &&
            <>
              <div className={classes.dataLoading}>
                <CircularProgress />
              </div>
            </>
          }

        </Grid>
      </Grid>
    </>
  );
}

