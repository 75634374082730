import React from "react";
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@material-ui/core";
import { getPartyLabel } from "../../models/handles";
import useStyles from "./styles";


/**
 * PO Comments List
 * Detail View Drawer in PO
 * @param {Array} comments 
 * @returns 
 */
export default function POCommentsList ({
    comments
}) {
    
    const classes = useStyles();

    // console.log("[POCommentsList]", comments);

    const isRightSide = (obj) => (obj.status === 'Accepted' || obj.status === 'Rejected');

    return (  
        <div className={classes.poCommentsGrid}>
            <h2 className={classes.poCommentHeading}>Po Comments</h2>
            <div className={classes.poCommentsGridTable}>   
                <List className={classes.customRootComment} >
                    { comments.map((obj,index) => (
                        <ListItem 
                            key={index} 
                            className={isRightSide(obj) ? "rightComment" : "leftComment"}
                        >
                            {!isRightSide(obj) && 
                            <ListItemAvatar>
                                <Avatar>{getPartyLabel(obj.user)}</Avatar>
                            </ListItemAvatar>}
                            <div className= {isRightSide(obj) ? "me" : "you"}>
                                <ListItemText primary={obj.user} secondary={obj.date} />
                                <Typography className="msg">{obj.note}</Typography>
                            </div>
                            {isRightSide(obj) &&  
                            <ListItemAvatar>
                                <Avatar>{getPartyLabel(obj.user)}</Avatar>
                            </ListItemAvatar>}
                        </ListItem>   
                    ))}         
                </List>
            </div>
        </div>
    );
  }  