import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { TimeInput } from "../Inputs";
import useStyles from "./styles";


/**
 * Time Search with start & end Time
 * @param {Array} params 
 * @param {Function} setParams
 * @returns 
 */
export default function TimeSearch({
  params = [],
  setParams = null,
}) {

  const classes = useStyles();


  const [start, setStart] = useState((params.length > 0 && params[0]) ? params[0] : '');
  const [end, setEnd] = useState((params.length > 1 && params[1]) ? params[1] : '');

  // console.log("[TimeSearch]", params);


  const handleChangeStartTime = (val) => {
    setStart(val);
    const temp = [...params];
    temp[0] = val;
    setParams(temp);
  };

  const handleChangeEndTime = (val) => {
    setEnd(val);
    const temp = [...params];
    temp[1] = val;
    setParams(temp);
  };


  return (
    <>

      <Grid container justifyContent="space-between" style={{marginTop: 10}}>
        <Grid item>
          <TimeInput
            label="From"
            className={classes.dateSearch}
            value={start}
            onChange={handleChangeStartTime}
            width={92}
          />
        </Grid>
        <Grid item>
          <TimeInput
            label="To"
            className={classes.dateSearch}
            value={end}
            onChange={handleChangeEndTime}
            width={92}
          />
        </Grid>
      </Grid>

    </>
  );
}
