import React, { useState, useEffect } from "react";
import { useStreamQueries } from "@daml/react";
import WelcomeCard from "./welcomeCard";
import { getUsersLastLoginDate } from "../../services/axios/auth-api";
import { useUserState } from "../../context/UserContext";
import { useDamlState } from "../../context/DamlContext";
import { Box, Grid, Typography } from "@material-ui/core";
import refreshIcon from "../../icons/refresh-dashboard.svg";
import {
  DashboardCards,
  DashboardCardsVendor,
} from "../../services/daml-modules1";
import { DashboardCardsAccess } from "../../components/DashboardVariations/DashboardCardsAccess";
import NoDataCard from "./noDataCard";
import { getFormattedLastLoginDate } from "../../services/util";
import { isNewDesign } from "../../config";
import banner from "../login/banner.png";
import loader from "../../icons/uiw_loading.svg";
import useStyles from "./styles";
import { dashboardDataRefresh, dashboardVendorDataRefresh } from "../../services/axios/api-node_server";
import { UserAccessLevelVendor } from "../../models/UserAccessLevel";
import SAPDashboard from "../payments/dashboard";
import { CustomLoading } from "../../hoc/withLoading";

export default function Welcome() {
  const user = useUserState();
  const [userInformation, setUserInformation] = useState({});
  const hospital = user.current_organization
    ? user.current_organization
    : user.organization;

  const { userlistPayload, rolePayload } = useDamlState();
  const classess = useStyles();
  const standalone_payment = rolePayload?.hospitaldetails?.standalone_payment;
  /**
   * validate user is vendor or hospital
   * @returns true/false
   */
  const [SAP, setSAP] = useState(undefined);
  useEffect(() => {
    if (user.role === "Operator" || user.role === "Vendor")
      setSAP(false);
    else
      setSAP(standalone_payment);
  }, [standalone_payment, user])
  const isValidVendor = () => {
    return Object.keys(UserAccessLevelVendor).includes(
      userlistPayload?.accesslevel
    );
  };

  const { contracts, loading } = useStreamQueries(
    isValidVendor() ? DashboardCardsVendor : DashboardCards,
    () => [isValidVendor() ? { vendor: hospital } : { hospital }]
  );
  const payload = contracts[0]?.payload;

  useEffect(() => {
    const getuserData = async () => {
      const queryParam = user?.party || "";
      const [userinfo] = await getUsersLastLoginDate(queryParam);
      setUserInformation(userinfo);
    };
    if (!userInformation?.last_login) {
      getuserData();
    }
  }, [user, userInformation]);

  const handleRefreshClick = () => {
    const queryParam = userInformation?.app_metadata?.daml_ledger_api
      ?.current_organization
      ? userInformation?.app_metadata?.daml_ledger_api?.current_organization
      : userInformation?.app_metadata?.daml_ledger_api?.organization;
    if (isValidVendor()) {
      dashboardVendorDataRefresh(queryParam);
    } else {
      dashboardDataRefresh(queryParam);
    }
  };

  //function to get hours from date
  const updatedAsOf = (date) => {
    if (date) {
      const today = new Date();
      const last = new Date(date);
      const diff = Math.abs(today - last);
      const hours = Math.ceil(diff / (1000 * 60 * 60));
      //const minutes = parseInt(Math.abs(last.getTime() - today.getTime()) / (1000 * 60) % 60);
      //(minutes < 60 && minutes !== 0) ? `${minutes} minute(s) ago`:
      return hours > 48
        ? getFormattedLastLoginDate(last)
        : `${hours} hour(s) ago`;
    }
  };
  return (SAP === undefined ? <CustomLoading/> : (SAP ?
    <SAPDashboard /> :
    <div>
      <div>
        {loading && (
          <div className={classess.loadingSpinner}>
            <img src={loader} alt="Loading dashboard data" />
          </div>
        )}
        {!isNewDesign ? (
          <img src={banner} alt="banner" style={{ width: "100%" }}></img>
        ) : (
          <>
            <WelcomeCard
              lastLogin={userInformation?.last_login}
              userName={userlistPayload?.name}
            />

            <Box marginTop={"2vh"} paddingLeft={"2vh"}>
              <Grid container spacing={2} direction={"column"}>
                <Grid item xs={8}>
                  <Typography
                    gutterBottom
                    variant="h4"
                    component="div"
                    style={{
                      color: "#1C3C74",
                      fontWeight: "bold",
                    }}
                  >
                    Dashboard{" "}
                    <img
                      onClick={handleRefreshClick}
                      src={refreshIcon}
                      alt="Click to refresh the Dashboard data"
                      style={{
                        cursor: "pointer",
                      }}
                      title="Click to refresh the Dashboard data"
                    />{" "}
                    {contracts[0]?.payload.last_refreshed_at && (
                      <span
                        style={{
                          color: "#726C6C",
                          fontSize: "14px",
                        }}
                      >
                        Updated as of: {updatedAsOf(payload.last_refreshed_at)}{" "}
                      </span>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {payload && payload?.length !== 0 ? (
              <DashboardCardsAccess
                role={userlistPayload?.accesslevel}
                contracts={payload}
                isVendor={isValidVendor()}
              />
            ) : null}

            {payload?.length === 0 || payload === undefined ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
                className={classess.NoDataCardPrimary}
              >
                <div
                  style={{
                    width: "80vh",
                  }}
                  className={classess.NoDataCardSecondary}
                >
                  <NoDataCard />
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>))
}
