import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Typography, Accordion, AccordionDetails, AccordionSummary,
  TableContainer, Paper, Badge, Chip, DialogContentText, Drawer } from "@material-ui/core";
import { ExpandMore } from '@material-ui/icons';
import { formatDate } from "../../services/util";
import ProductDetailViewTable from "../surgicalevent/product-detail-view-table";
import useStyles from "../surgicalevent/styles";


/**
 * Detail View Slide Drawer 
 * @param {Boolean} state
 * @param {Function} toggleDrawer
 * @param {Object} selectedInvoiceData
 * @param {Array} productItemsList
 */
export default function DetailViewDrawer({
  state, toggleDrawer, selectedInvoiceData = {}, productItemsList = [],
}) {
  // console.log("[DetailViewDrawer]", selectedInvoiceData, productItemsList);

  if (!state) {
    productItemsList = [];
  }

  const classes = useStyles();

  const [expanded, setExpanded] = useState(0);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }


  useEffect(() => {
    if (state) {
      console.log("[DetailViewDrawer] is opened.");
      setExpanded(0);
    }
  }, [state]);

  const getPOAmount = ()=>{
    if(productItemsList?.length>0){
      return productItemsList?.reduce((total, product)=> total + parseInt(product?.invdtotalcost), 0)
    }
    return 0;
  }

  return (
    <React.Fragment key={'right'} >
      <Drawer className={classes.customRoot} anchor={'right'} open={state} onClose={() => { toggleDrawer(false) }}>
        <div className="view-heading">
          <span>Purchase Order:</span> {selectedInvoiceData && selectedInvoiceData.invpurchaseorderid}
        </div>


        <DialogContentText component={'div'} className="modelStepDetai">
          <div className="viewDetailsSe">

            <h2 className={classes.formSubHeading}>Product Details</h2>
            <div className={`grid-list grid-list-3`}>
              <div className="list-item">
                <div className="label">Invoice Date</div>
                <div className="value">{selectedInvoiceData && formatDate(selectedInvoiceData.invinvoicedate)}</div>
              </div>
              <div className="list-item">
                <div className="label">Hospital</div>
                <div className="value">{selectedInvoiceData && selectedInvoiceData.hospital}</div>
              </div>
              <div className="list-item">
                <div className="label">Status</div>
                <div className="value">{selectedInvoiceData && (
                  <Chip
                    label={selectedInvoiceData.is_sent_hospital === true ? "Sent Invoice" : "Created Invoice"}
                    size="small"
                    className={classNames(classes.smallChip, (selectedInvoiceData.is_sent_hospital === true ? classes.backBlue : classes.backGreen))}
                  />)}
                </div>
              </div>
            </div>

            {productItemsList.length !== 0 &&
              <div> <div className={classes.productDetails}>
                <h2 className={classes.formSubHeading}>Total Added Product
                  <Badge badgeContent={productItemsList.length} color="primary" overlap="rectangular"></Badge>
                  <span className={classes.productTableHeading}><span className="price-label">Total Price of Products:</span> {getPOAmount()}</span>
                </h2>
              </div>

                <div className={classes.productAccordion}>
                  {productItemsList.map(({ invdproduct: obj, invdproductquantity }, index) => (
                    <Accordion 
                      key={index} 
                      expanded={expanded === (index + 1)} 
                      onChange={handleChangeAccordion((index + 1))} 
                      className={classes.productMainAccordion}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className="product-detail-accordion-head"
                      >
                        <Typography className={classes.accordionHeading}>
                          <span className="text-inactive">Reference Number :</span> <span className="text-active">{obj.sepreferencenumber}</span> 
                        </Typography>
                        <Typography className={classes.accordionHeading}>
                          <span className="text-inactive">Lot Code :</span> <span className="text-active">{obj.seplotcode}</span> 
                        </Typography>
                        <Typography className={classes.accordionHeading}>
                          <span className="text-inactive">Qty: </span> <span className="text-active">{invdproductquantity}</span> 
                        </Typography>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Typography component={'div'}>
                          <TableContainer className={classes.productTableContainer} component={Paper}>
                            <ProductDetailViewTable 
                              obj={obj}
                              options={{
                                hiddenParams: ['sepdistributor'],
                              }}
                            />
                          </TableContainer>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}

                </div>
              </div>
            }
          </div>
        </DialogContentText>
      </Drawer>
    </React.Fragment>
  );
}
