import React, { useState, useEffect } from "react";
import classNames from "classnames";
import {
  Button, Dialog, Grid, Typography, DialogContent, DialogTitle,
  TextField, InputAdornment, IconButton, Chip
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Check from "@material-ui/icons/Check";

import { CustomSelect,CustomTextField } from "../../components/Inputs";
import { UserStatus, UserAccessLevelVendor, UserAccessLevelHospital
} from '../../models/UserAccessLevel';
import { UserModel, requiredUser } from "../../models/User";
import { validateEmail, validatePassword, validatePasswordErrors, passwordGenerator } from "../../services/util";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "../payments/styles";
import closeIcon from "../../icons/new-close-icon.svg";
import { Genders } from "../../models/Contact";
import { setByPath } from "../../components/Contracts/functions";
import { useDamlState } from "../../context/DamlContext";
import { NewCustomizedProgressBars } from '../operator/Progress';


/**
 * Create User Dialog
 * @param {Boolean} isCreate 
 * @param {Boolean} open 
 * @param {Function} setOpen 
 * @param {Function} handleRequest 
 * @param {String} title 
 * @param {String} editTitle 
 * @param {Object} defaultValue 
 * @param {String} role
 * @returns 
 */
export default function AddEditUser({
  isCreate,
  open,
  setOpen,
  handleRequest,
  defaultValue = {},
  role,
  authUsersList,
  inviteOrgazationHandler
}) {

  const classes = useStyles();
  const classes1 = useStyles1();
  const [userItems, setUserItems] = useState({});
  const [userError, setUserError] = useState({});
  const [password, setPassword] = useState("");
  const [inviteUser, setInviteUser] = useState(false);
  const [existUserInfo, setExistUserInfo] = useState({});
  const [isDisable, setDisable] = useState(false);
  const [checkAccessLevel, setCheckAccessLevel] = useState(false);
  const { userlistPayload } = useDamlState();
  const accesslevel = userlistPayload ? userlistPayload.accesslevel : null;

  const handleChange = (value, key) => {
    setUserItems({
      ...userItems,
      [key]: value,
    });
  };


  // check if user is already exist in auth0 db
  const isUserAlreadyExist = (inputEmailId) => {
    let found = null;
    if (authUsersList.length > 0 && isCreate) {
      found = authUsersList.find(a => a.email.toLocaleLowerCase() === inputEmailId.toLocaleLowerCase());
      if (found) {
        setUserError({ ...UserModel, email: `User is already exist, would you like to invite? ` });
        setInviteUser(true);
        setExistUserInfo(found);
        return true;
      } else {
        setUserError({ ...UserModel, email: `` });
        setInviteUser(false);
        setExistUserInfo({});
        return false;
      }
    }
    setExistUserInfo({});
    setInviteUser(false);
    return false;
  }

  // handle when click "Submit" or "Update" button
  const handleSave = async () => {
    let newErrors = requiredUser(userItems);
    console.log("[AddEditUser] handleSave", newErrors);
    if ((Object.keys(newErrors).length > 0)) {
      setUserError({ ...newErrors });
      return false;
    }

    if (!validateEmail(userItems.email)) {
      setUserError({ ...UserModel, email: 'Please enter a valid email address' });
      return false;
    }

    if (isUserAlreadyExist(userItems.email)) {
      return false;
    }

    await handleRequest(userItems, isCreate ? passwordGenerator(8) : password);
  };

  useEffect(() => {
    if (open) {
      console.log("[AddEditUser] Open AddEditUser dialog.");
      setUserItems({ ...UserModel, ...defaultValue });
      setUserError({ ...UserModel });
      setPassword("");
      setInviteUser(false);
    }
  }, [open, defaultValue]);

useEffect(() => {
  if (isCreate)
  {
    setDisable(false)
  }
  else{
    setDisable(true)
  }
}, [isCreate]);

useEffect(() => {
  if (accesslevel === 'HManager' || accesslevel === 'VManager' )
  {
    setCheckAccessLevel(false)
  }
  else{
    setCheckAccessLevel(true)
  }
},[accesslevel]);

  const onChangeEmailHandler = (e) => {
    // e.preventDefault();
    if (isCreate) {
      if (validateEmail(e)) {
        isUserAlreadyExist(e);
      }
      setUserItems({
        ...userItems,
        email: e,
      });
    }
  }

  return (

    <Dialog
      open={open}
      onClose= {()=>{setOpen(false)}}
      className={classes.customRoot}
      disableEnforceFocus
      aria-labelledby="draggable-dialog-title"
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >

        <Button
          className={classes.closeButton}
          onClick={() => { setOpen(false) }}
        >
          <img src={closeIcon} alt="" />
        </Button>
      </Grid>
      <DialogTitle className='MuiDialogTitleRoot'>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes1.newDIATitlePanel}
        >
            {
            (isCreate && role==="Hospital") && 
              <Typography variant="inherit" component="span" className={classes1.popUpTitle}>
                Add Facility User
              </Typography>
            }
            {
            (isCreate && role==="Vendor") && 
              <Typography variant="inherit" component="span" className={classes1.popUpTitle}>
                Create New User
              </Typography>
            }
            {
              (!isCreate && role==="Hospital") && 
              <Typography variant="inherit" component="span" className={classes1.popUpTitle}>
                Edit Facility User
              </Typography>
            }
            {
              (!isCreate && role==="Vendor") && 
              <Typography variant="inherit" component="span" className={classes1.popUpTitle}>
                Edit User
              </Typography>
            }
        </Grid>
      </DialogTitle>
      <DialogContent
        dividers={true}
      >
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={2}>
            <label className={classes.noteTitle}>Name</label>
          </Grid>
          <Grid item xs={9}>
            <CustomTextField
              value={userItems?.name || ""}
              warning={userError.name}
              placeholder="Type here..."
              options={{ disabled: isDisable }}
              width={'100%'}
              onChange={(val) => handleChange(val, 'name')}
            />
          </Grid>
          <Grid item xs={2}>
            <label className={classes.noteTitle}>User ID</label>
          </Grid>
          <Grid item xs={9}>
            <CustomTextField
              value={userItems?.userid || ""}
              placeholder="Type here..."
              warning={userError.userid}
              options={{ disabled: isDisable }}
              width={'100%'}
              onChange={(val) => handleChange(val, 'userid')}
            />
          </Grid>
          <Grid item xs={2}>
            <label className={classes.noteTitle}>Email</label>
          </Grid>
          <Grid item xs={9}>
             <CustomTextField
              value={userItems?.email || ""}
              warning={userError.email}
              placeholder="Type here..."
              options={{ disabled: isDisable }}
              width={'100%'}
              onChange={(e) => onChangeEmailHandler(e)}
            />
            {inviteUser && (
            <Button onClick={()=>inviteOrgazationHandler(existUserInfo)} className="yesBtn" variant="contained" color="primary">
              {'Click here to Invite'}
            </Button>)}
          </Grid>
          <Grid item xs={2}>
            <label className={classes.noteTitle}>Job Title</label>
          </Grid>
          <Grid item xs={9}>
            <CustomTextField
              value={userItems?.jobtitle || ""}
              warning={userError.jobtitle}
              placeholder="Type here..."
              options={{ disabled: isDisable }}
              width={'100%'}
              onChange={(val) => handleChange(val, 'jobtitle')}
            />
          </Grid>
          <Grid item xs={2}>
            <label className={classes.noteTitle}>Office Phone</label>
          </Grid>
          <Grid item xs={9}>
            <CustomTextField
              value={userItems?.officephone || ""}
              placeholder="Type here..."
              warning={userError.officephone}
              options={{ disabled: isDisable }}
              width={'100%'}
              onChange={(val) => handleChange(val, 'officephone')}
            />
          </Grid>
          <Grid item xs={2}>
            <label className={classes.noteTitle}>Mobile Phone</label>
          </Grid>
          <Grid item xs={9}>
            <CustomTextField
              value={userItems?.phone || ""}
              warning={userError.phone}
              placeholder="Type here..."
              options={{ disabled: isDisable }}
              width={'100%'}
              onChange={(val) => handleChange(val, 'phone')}
            />
          </Grid>
          <Grid item xs={2}>
            <label className={classes.noteTitle}>Gender</label>
          </Grid>
          <Grid item xs={5}>
            <CustomSelect
              items={Genders}
              value={userItems?.gender || ""}
              warning={userError.gender}
              options={{ disabled: isDisable }}
              placeholder="Select One"
              width={'100%'}
              onChange={(e) => {
                setUserItems(setByPath(e, 'gender', userItems))
              }}
            />
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={2}>
            <label className={classes.noteTitle}>Access Level</label>
          </Grid>
          <Grid item xs={5}>
            <CustomSelect
              items={role === 'Hospital' ? UserAccessLevelHospital : (
                role === 'Vendor' ? UserAccessLevelVendor : []
              )}
              value={userItems?.accesslevel || ""}
              options={{ disabled: checkAccessLevel }}
              warning={userError.accesslevel}
              placeholder="Select One"
              width={'100%'}
              onChange={(e) => {
                setUserItems(setByPath(e, 'accesslevel', userItems))
              }}
            />
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={2}>
            <label className={classes.noteTitle}>User Status</label>
          </Grid>
          <Grid item xs={5}>
            <CustomSelect
              items={UserStatus}
              value={userItems?.userstatus || ""}
              warning={userError.userstatus}
              placeholder="Select One"
              width={'100%'}
              onChange={(e) => {
                setUserItems(setByPath(e, 'userstatus', userItems))
              }}
            />
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
        <div className="right-buttons">
          <Button autoFocus onClick={() => { setOpen(false) }} className="noBtn" variant="outlined" color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} className="yesBtn" variant="contained" color="primary">
            {isCreate ? 'Save' : 'Update'}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}


/**
 * 
 * @param {String} password 
 * @param {Function} setPassword 
 * @param {String} errorType 'text', 'chip', 'none'
 * @param {String} label
 * @param {String} errMsg
 * @param {Boolean} disabled
 * @returns 
 */
export function InputPassword({
  password, setPassword, errorType = 'text', label = 'Password', errMsg = '', disabled = false, placeholder = ""
}) {

  const classes = useStyles();

  const [passShow, setPassShow] = useState(false);
  const [passError, setPassError] = useState("");
  const [passErrorClasses, setPassErrorClasses] = useState({});

  const errorList = [
    '1 Upercase letter',
    '1 Lowercase letter',
    '1 Number',
    '1 Special character',
    'Minimum 8 characters',
  ];


  const handleClickShowPassword = () => {
    setPassShow(!passShow);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangePassword = (e) => {
    const value = e.target.value;
    setPassword(value);
    if (errorType === 'text') {
      const validatePass = validatePassword(value);
      if (validatePass === true) {
        setPassError("");
      }
      else {
        setPassError(validatePass);
      }
    }
    else if (errorType === 'chip') {
      const validatePassErrors = validatePasswordErrors(value);
      let errCls = errorList.reduce((cls, err) => {
        cls[err] = classNames(classes.smallChip, (
          (validatePassErrors.indexOf(err) >= 0) ? classes.chipFailed : classes.chipSuccess));
        return cls;
      }, {});
      // console.log("[InputPassword] handleChangePassword", validatePassErrors, errCls);
      setPassErrorClasses(errCls);
    }
  };


  useEffect(() => {
    //
  }, []);


  return (
    <>
      <TextField
        type={passShow ? 'text' : 'password'}
        value={password}
        autoComplete={'new-password'}
        error={!!passError || !!errMsg}
        helperText={(((errorType === 'text' && !!passError) ? passError : (!!errMsg ? errMsg : '')))}
        onChange={handleChangePassword}
        className={classes.customFullWidth}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment:
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {passShow ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
        }}
        label={label}
        placeholder={placeholder}
        margin="normal"
        variant="outlined"
        disabled={disabled}
      />

      {
        errorType === 'chip' &&
        errorList.map((err, index) => (
          <Chip
            label={err}
            key={index}
            size="small"
            icon={((err in passErrorClasses
              && passErrorClasses[err] === classNames(classes.smallChip, classes.chipSuccess)) ? <Check />
              : null)}
            className={err in passErrorClasses ? passErrorClasses[err]
              : classNames(classes.smallChip, classes.chipFailed)}
          />
        ))
      }
    </>
  );
}



export function NewInputPassword({
  password, setPassword, errorType = 'text', label = 'Password', errMsg = '', disabled = false, placeholder = "", isShowPasswordEnable = true , classname
}) {

  const classes = useStyles();
  const [passShow, setPassShow] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [passError, setPassError] = useState("");
  const [passErrorClasses, setPassErrorClasses] = useState({});
  const [percentages , setPercentages ] = useState(0);
  const [progressWarnings , setProgressWarnings ] = useState('Very Weak');

  useEffect(()=>{
    let successCount = 0;
    for (let key in passErrorClasses) {
      if (passErrorClasses[key].includes("chipSuccess")) {
        successCount++;
      }
    }
    setPercentages(successCount*20);

  },[passErrorClasses])

  const getColorCode = (value) => {
    switch (value) {
        case 0:
            return "Very Weak";
        case 20:
            return "Weak";
        case 40:
            return "So-so";
        case 60:
            return "Good";
        case 80:
            return "Great!";
        case 100:
            return "Excellent";
        default:
            return "Weak";
    }
  }

  useEffect(()=>{
    setProgressWarnings(getColorCode(percentages));
  },[percentages])

  const errorList = [
    '1 Upercase letter',
    '1 Lowercase letter',
    '1 Number',
    '1 Special character',
    'Minimum 8 characters',
  ];


  const handleClickShowPassword = () => {
    setPassShow(!passShow);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangePassword = (e) => {
    const value = e.target.value;
    setShowSuggestion(true);
    setPassword(value);
    if (errorType === 'text') {
      const validatePass = validatePassword(value);
      if (validatePass === true) {
        setPassError("");
      }
      else {
        setPassError(validatePass);
      }
    }
    else if (errorType === 'chip') {
      const validatePassErrors = validatePasswordErrors(value);
      let errCls = errorList.reduce((cls, err) => {
        cls[err] = classNames(classes.smallChip, (
          (validatePassErrors.indexOf(err) >= 0) ? classes.chipFailed : classes.chipSuccess));
        return cls;
      }, {});
      // console.log("[InputPassword] handleChangePassword", validatePassErrors, errCls);
      setPassErrorClasses(errCls);
    }
    else if (errorType === 'circle') {
      const validatePassErrors = validatePasswordErrors(value);
      let errCls = errorList.reduce((cls, err) => {
        cls[err] = classNames(classes.smallChip, (
          (validatePassErrors.indexOf(err) >= 0) ? classes.chipFailed : classes.chipSuccess));
        return cls;
      }, {});
      // console.log("[InputPassword] handleChangePassword", validatePassErrors, errCls);
      setPassErrorClasses(errCls);
    }
  };

  const handleCircle = (color) => {
    const circle = <circle cx="7.5" cy="7.5" r="4" fill={color} />;

    return circle;
  }


  useEffect(() => {
    //
  }, []);


  return (
    <>
      <TextField
        type={passShow ? 'text' : 'password'}
        value={password}
        autoComplete={'new-password'}
        error={!!passError || !!errMsg}
        helperText={(((errorType === 'text' && !!passError) ? passError : (!!errMsg ? errMsg : '')))}
        onChange={handleChangePassword}
        className={classNames(classes.customFullWidth,classname)}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment:
            isShowPasswordEnable &&
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {passShow ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
        }}
        label={label}
        placeholder={placeholder}
        margin="normal"
        variant="outlined"
        disabled={disabled}
      />

      {
        errorType === 'chip' &&
        errorList.map((err, index) => (
          <Chip
            label={err}
            key={index}
            size="small"
            icon={((err in passErrorClasses
              && passErrorClasses[err] === classNames(classes.smallChip, classes.chipSuccess)) ? <Check />
              : null)}
            className={err in passErrorClasses ? passErrorClasses[err]
              : classNames(classes.smallChip, classes.chipFailed)}
          />
        ))
      }
      {
        showSuggestion && errorType === 'circle' &&
        <NewCustomizedProgressBars
          completePercent={percentages}
          showLabel={progressWarnings}
          showIcon={true}
        />
      }
      <div style={{
        display: "flex",
        flexWrap: "wrap"
      }}>
        {
          showSuggestion && errorType === 'circle' &&
          errorList.map((err, index) => (
            <div key={index} style={{
              width: '160px', height: "22px",
              display: 'flex',
              alignItems: 'center'
            }}>
              <svg height="15" width="15">
                {
                  (err in passErrorClasses
                    && passErrorClasses[err] === classNames(classes.smallChip, classes.chipSuccess)) ?
                    handleCircle("#4CAF50") :
                    handleCircle("#D9D9D9") 
                }
              </svg>
              {err}
            </div>
          ))
        }
      </div>
    </>
  );
}
