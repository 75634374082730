import { getModelMenu, validateModelItem, getSubModel } from "./handles";

export const Ownerships = {
    Owned: 'Owned',
    Loaned: 'Loaned',
    Consignment: 'Consignment',
    // BillOnly: 'Bill Only',
    Stock: 'Stock',
    Unknown: 'Unknown',
};

export default Object.keys(Ownerships).map(value => ({
    value,
    label: Ownerships[value]
}));

export const validateOwnership = validateModelItem(Ownerships);

export const getOwnerships = getModelMenu(Ownerships);

// POStatus for Purchase Order
export const POOwnerships = getSubModel(Ownerships, [
    'Owned', 'Loaned', 'Consignment', 'Stock', 'Unknown', 
]);
export const isImpossiblePO = (status) => (
    !(status in POOwnerships)
);

// POStatus for Inventory
export const InventoryOwnerships = getSubModel(Ownerships, [
    'Owned', 'Consignment', 'Stock',
]);
export const isImpossibleInventory = (status) => (
    !(status in InventoryOwnerships)
);
