import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useLedger, useParty, useStreamQueries, useQuery } from "@daml/react";
import { Grid, Button, AppBar, Tabs, Tab, Typography, ButtonGroup, Badge } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { AccountInventory, AccountInventoryHistory, ReturnInventory, ReturnInventoryHistory, 
  InventoryRequestHistory, RestockInventory } from "../../services/daml-modules1";
import TabPanel, { a11yProps } from "../../components/Modals/MultiTabsModals/TabPanel";
import ModalDialogBox from "../../components/Modals/GeneralModal/modalDialogBox";
import { CustomSelect } from "../../components/Inputs";
import { getPartialReceivedInventoryData, isOpenOrder } from "../../models/InventoryData";
import { getInvenKey as getProductKey } from "../../models/Product";
import { getInvenKey } from "../../models/InventoryProduct";
import { useDamlState, useDamlLocation } from "../../context/DamlContext";
import { setSearchFilters, clearSearchFilters } from "../../hooks/useSearchFilters";
import InventoryListContractsHospital from './inventorylistcontractshospital1';
import InventoryListHistoricContracts from './inventorylisthistoriccontracts';
import ReturnInventoryContracts from "../inventoryreturn/returninventorycontracts1";
import HouseInventory from "./house-inventory";
import EditReturnInventory from "./edit-return-inventory";
import CreateInventory from "./create-inventory1";
import OpenOrdersModal from "./open-orders";
import { handleAddNewInventory } from "./inventorylisthospital";
import barcodeIcon from "../../icons/new-barcode-reader.svg";
import deleteIcon from "../../icons/new-delete1.svg";
import exportIcon from "../../icons/new-export-icon.svg";
import { matchInventoryData, updateOpenOrders as updateOpenOrdersChoice } from './handle';
import useStyles from "../surgicalevent/styles";
import useStyles1 from "./styles";


/**
 * My Inventory
 * @returns 
 */
export default function InventoryListHospital() {

  const classes = useStyles();
  const classes1 = useStyles1();

  const ledger = useLedger();
  const hospital = useParty();
  const { contracts, loading } = useStreamQueries(AccountInventory, () => [{ hospital }]);
  const { contracts: contractsHistory } = useStreamQueries(AccountInventoryHistory, () => [{ hospital }]);
  const { contracts: contracts1, loading: loading1 } = useStreamQueries(ReturnInventory, () => [{ hospital }]);
  const { contracts: contractsHistory1 } = useStreamQueries(ReturnInventoryHistory, () => [{ hospital }]);
  const { contracts: contracts2, loading: loading2 } = useStreamQueries(InventoryRequestHistory, () => [{ hospital }]);
  const { contracts: contracts3, loading: loading3 } = useQuery(RestockInventory, () => ({ hospital }));
  const { roleCid, userlistPayload } = useDamlState();
  let { locations } = useDamlLocation();
  locations = ["All", ...locations];
  // Adjust Count
  const adjustCount = contracts2.reduce((s, c) => s + c.payload.inventorydata.length, 0);
  // console.log("[InventoryListHospital]", locations);

  const [value, setValue] = useState(0);
  const [isHistory, setHistory] = useState(false);
  const [isHistory1, setHistory1] = useState(false);
  const [inventoryDataList, setInventoryDataList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [delOpen, setDelOpen] = useState(false);
  const [poInvData, setPoInvData] = useState({});
  // House Inventory in "My Inventory" Tab
  const [contract, setContract] = useState({});
  const [openModal4, setOpenModal4] = useState(false);
  // EditReturn Inventory in "Returns" Tab
  const [contract1, setContract1] = useState({});
  const [openModal1, setOpenModal1] = useState(false);
  // EditReturn Inventory in "Edits" Tab
  const [contract2, setContract2] = useState({});
  const [openModal2, setOpenModal2] = useState(false);
  // Edit / Return Tab
  const [isEditTab, setEditTab] = useState(true);
  // OpenOrdersModal in "My Inventory" Tab
  const [contract3, setContract3] = useState({});
  const [openModal3, setOpenModal3] = useState(false);
  // location filter
  const [location, setLocation] = useState('All');
  // map to restock for open orders
  const [restock, setRestock] = useState({});
  const [updatingAccount, setUpdatingAccount] = useState(false);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleLocationFilter = (val) => {
    setLocation(val);
    // store redux search filters
    clearSearchFilters();
    setSearchFilters('inventorydata.iproduct.seplocation', (val === 'All' ? [] : [val]));
  };

  // handle to add new Inventory
  const addProductItem = async () => {

    await handleAddNewInventory({
      inventoryDataList,
      ledger,
      roleCid,
      userlistPayload,
      setOpenModal,
      match: false,
      openAlert: (totalres) => {
        setPoInvData(totalres);
        // disable to open confirm PO dialog now.
        // setDelOpen(true);
      },
      isRestockNotAccount: false,
    });

  };

  // handle to add more Product in Inventory
  const handleAddMoreProduct = async (product) => {
    // Nothing
  };

  // handle to match Inventory & PO
  const handleRequestDelOpen = async () => {

    if (!!poInvData && typeof poInvData === 'object' && 'oldInventorydata' in poInvData) {

      // Match Inventory & PO
      await matchInventoryData({
        ledger,
        roleCid,
        ContractId: poInvData.contractId,
        oldinventorydata: poInvData.oldInventorydata,
        newinventorydata: poInvData.newInventorydata,
      });
    }
    setDelOpen(false);
  };

  // handle when click "Add Inventory" button
  const openCreateInventoryModal = (e) => {
    setInventoryDataList([]);
    setOpenModal(true);
  };

  const handleEditInventory = (c) => {
    setContract(c);
    setOpenModal4(true);
    // onEventClear();
  };

  const handleEditInventory1 = (c) => {
    setContract1(c);
    setOpenModal1(true);
    // onEventClear();
  };

  const handleEditInventory2 = (c) => {
    setContract2(c);
    setOpenModal2(true);
  };

  const handleReturnUnknown = () => {
  };

  const handleOpenOrder = (c) => {
    // console.log("[InventoryListHospital] handleOpenOrder", c);
    setContract3({
      vendor: c.payload.vendor,
      inventoryproduct: c.payload.inventoryproduct,
      inventorydata : restock[c.contractId] ?? [],
    });
    setOpenModal3(true);
  };
  

  useEffect(() => {
    if (!loading && !loading3) {
      // console.log("[InventoryListHospital]", contracts, contracts3);
      const updateRestock = async () => {
        const result = await updateOpenOrders(contracts, contracts3, ledger, roleCid);
        setRestock(result);
        //setUpdatingAccount(false);
      };
      if (updatingAccount === false) {
        setUpdatingAccount(true);
        updateRestock();
      }
    }
  }, [loading, loading3, contracts, contracts3, ledger, roleCid, updatingAccount]);


  return (
    <>
      <Grid>

        <Grid container justifyContent="space-between">
          <Grid item></Grid>
          <Grid item>
            <Button
              className={classNames(classes.newCreateButton, classes.newIconButton, classes1.iconButton)}
              variant="contained"
              onClick={openCreateInventoryModal}
              startIcon={<AddIcon />}
            />
            <Button
              className={classNames(classes.newIconButton, classes1.iconButton)}
              variant="contained"
              // onClick={handleBarcodeClick}
              startIcon={<img  alt="" src={barcodeIcon} />}
            />
            <Button
              className={classNames(classes.newIconButton, classes1.iconButton)}
              variant="contained"
              // onClick={handleOpenPopover}
              startIcon={<img  alt="" src={exportIcon} />}
            />
            <Button
              className={classNames(classes.newIconButton, classes1.iconButton)}
              variant="contained"
              // onClick={handleDeleteClick}
              startIcon={<img  alt="" src={deleteIcon} />}
            />

            {/* <ExportPopover
              anchor={anchorEl}
              setAnchor={setAnchorEl}
              handleExportCSV={() => handleExportCSV(checkedInv)}
              handleExportPDF={() => handleExportPDF(checkedInv)}
            /> */}
          </Grid>
        </Grid>

        <AppBar position="static" className={classNames(classes.tabPanelMenu, classes1.newTabPanelMenu)}>
          <Tabs indicatorColor="primary" textColor="primary" value={value} onChange={handleChange} 
            aria-label="simple tabs example" centered
          >
            <Tab label="My Inventory" {...a11yProps(0)} />
            <Tab 
              label={<div>
                  <span className="badge-title">Returns and Edits</span>
                  <Badge badgeContent={adjustCount} color="primary" overlap="rectangular" className={classes.badgeGreenColor}></Badge>
                </div>} 
              {...a11yProps(1)} 
            />
          </Tabs>
          <CustomSelect
            label="Location"
            value={location}
            onChange={handleLocationFilter}
            items={locations}
            className={classNames(classes1.btnGroupTop, "left")}
            width={88}
            options={{
              labelLeft: true,
              labelWidth: 63,
            }}
          />
          {
            value === 1 &&
            <ButtonGroup variant="contained" size="small" color="primary" className={classes1.btnGroupTop}>
              <Button onClick={() => setEditTab(true)} className={isEditTab ? "active" : ""}>Edit</Button>
              <Button onClick={() => setEditTab(false)} className={isEditTab ? "" : "active"}>Return</Button>
            </ButtonGroup>
          }
        </AppBar>
        
        <TabPanel value={value} index={0} className={classes.tabPanelbody}>
          <InventoryListContractsHospital
            allContracts={contracts}
            historyContracts={contractsHistory}
            isIncludeHistory={isHistory}
            setArchived={setHistory}
            // setChecked={setCheckedInv}
            handleEditInventory={handleEditInventory}
            handleOpenOrder={handleOpenOrder}
            loading={loading}
          />
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.tabPanelbody}>
          <Grid 
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
          >
            <Grid item className={classes1.subTabPanel}>
              <Typography style={{paddingLeft: isEditTab ? '0px' : '200px'}}>
                {isEditTab ? "Edits" : "Returns"}
              </Typography>
              {
                !isEditTab && 
                <Button
                  className={classes.newCreateButton}
                  variant="contained"
                  onClick={handleReturnUnknown}
                  startIcon={<AddIcon />}
                >
                  Return Unknown 
                </Button>
              }
            </Grid>
            <Grid item>
              {
                isEditTab
                ?
                <InventoryListHistoricContracts
                  allContracts={contracts2}
                  handleEditInventory={handleEditInventory2}
                  loading={loading2}
                />
                :
                <ReturnInventoryContracts
                  allContracts={contracts1}
                  historyContracts={contractsHistory1}
                  isIncludeHistory={isHistory1}
                  setArchived={setHistory1}
                  // setChecked={setCheckedInv}
                  handleEditInventory={handleEditInventory1}
                  loading={loading1}
                />
              }
            </Grid>
          </Grid>
        </TabPanel>
        
        <HouseInventory
          open={openModal4}
          setOpen={setOpenModal4}
          contract={contract}
        />

        <EditReturnInventory
          open={openModal1}
          setOpen={setOpenModal1}
          contract={contract1}
          options={{
            title: 'Returns',
            isReturn: true,
          }}
        />

        <EditReturnInventory
          open={openModal2}
          setOpen={setOpenModal2}
          contract={contract2}
        />

        <ModalDialogBox
          open={delOpen}
          setOpen={setDelOpen}
          heading="Alert!"
          type="warning"
          text={`<p>There is a recent PO (${('popurchaseorderid' in poInvData ? poInvData.popurchaseorderid : '')}) 
            that this Product matches.  Would you like to Add this Product to that PO?.</p>`}
          handleRequest={handleRequestDelOpen}
          firstBtn={"Yes"}
          secondBtn={"No"}
          handleSecRequest={() => setDelOpen(false)}
        />

        <CreateInventory
          open={openModal}
          setOpen={setOpenModal}
          addProductItem={addProductItem}
          handleAddMoreProduct={handleAddMoreProduct}
          hospital={hospital}
          title={'Receive Inventory'}
          inventoryDataList={inventoryDataList}
          setInventoryDataList={setInventoryDataList}
          btnCreate="Add"
        />

        <OpenOrdersModal
          open={openModal3}
          setOpen={setOpenModal3}
          contract={contract3}
        />

      </Grid>
    </>
  );
}

/**
 * update Open Orders of Account Inventory if they're different to calculate in Restock Inventory
 * @param {Array} account AccountInventory contracts
 * @param {Array} restock RestockInventory contracts
 * @param {Object} ledger 
 * @param {String} roleCid 
 * @returns {Object}
 */
export const updateOpenOrders = async (account=[], restock=[], ledger, roleCid) => {
  // console.log("[updateOpenOrders]", account, restock);
  // calculate Open Orders by using Restock Inventory
  const openorders = restock.reduce((s, c) => {
    const inventorydata = getPartialReceivedInventoryData(c.payload.inventorydata);
    return inventorydata.reduce((ss, invd) => {
      if (isOpenOrder(invd.iproductstatus)) {
        invd.potype = c.payload.ipurchaseorderdata.potype;
        const acckey = `${c.payload.vendor}${getProductKey(invd.iproduct)}`;
        if (acckey in ss) {
          ss[acckey].iquantityunit += parseInt(invd.iquantityunit);
          ss[acckey].inventorydata.push(invd);
        }
        else ss[acckey] = {
          iquantityunit: parseInt(invd.iquantityunit),
          inventorydata: [invd],
        };
      }
      return ss;
    }, s);
  }, {});
  // console.log("[updateOpenOrders]", openorders);
  // Update Open Orders in Account Inventory if they're different
  let result = {};
  for (let i in account) {
    const c = account[i];
    const acckey = `${c.payload.vendor}${getInvenKey(c.payload.inventoryproduct)}`;
    result[c.contractId] = [];
    if (acckey in openorders) {
      result[c.contractId] = openorders[acckey].inventorydata;
      if (!!openorders[acckey].iquantityunit && openorders[acckey].iquantityunit !== parseInt(c.payload.inventoryproduct.iopenorders)) {
        // update Account Inventory contract
        const updatedAccount = await updateOpenOrdersChoice({
          newopenorders: openorders[acckey].iquantityunit,
          ledger,
          roleCid,
          ContractId: c.contractId,
        });
        if (updatedAccount) result[updatedAccount.contractId] = openorders[acckey].inventorydata;
      }
    }
  }
  // console.log("[updateOpenOrders]", result);
  return result;
};
