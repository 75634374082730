import React, { useState } from "react";
// import { SurgicalEventHistory } from "../../services/daml-modules1";
import AdaptiveSearch from "../../components/Search/adaptivesearch";
// import DateSearch from "../../components/Search/datesearch";
import SelectSearch from "../../components/Search/selectsearch";
import { contractFilter } from "../../services/daml-filters";
import DetailViewDrawer from "./detail-view-drawer";
import Contracts from "../../components/Contracts/Contracts";
// import { formatDateTime } from "../../services/util";
import { Genders } from "../../models/Contact";
import { Sides } from "../../models/Product";
// import { SurgicalStatus } from "../../models/SurgicalEvent";
import classNames from "classnames";
import useStyles from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";


function SurgicalEventHistoryListContracts({
  searchParams = {},
  handleChangeSearchValue = null,
  searchSurgicalHistory = {},
  allContracts = [],
}) {


  // const { contracts } = useStreamQueries(SurgicalEventHistory, () => [searchParams]);
  let contracts = contractFilter(allContracts, searchParams);
  const [selectedEventData, setSelectedEventData] = useState([]);
  const [productItemsList, setProductItemsList] = useState([]);

  console.log("[SurgicalEventHistoryListContracts]", searchParams, contracts, allContracts);

  const [state, setState] = React.useState(false);
  const classes = useStyles();
  const toggleDrawer = (open) => {
    setState(open);
  };


  return (
    <>

      <Contracts
        contracts={contracts}
        columns={[
          ["SE Id", "payload.surgicalevent.surgicaleventdata.seid", {
            options: {
              width: 120,
              cellClassName: classNames(classes.primaryColor)
            },
            type: "link",
            onClick: (contractId, contract) => {
              const data = contracts.find(c => c.contractId === contractId);
              // console.log("[SurgicalEventHistoryListContracts]", contractId, contract, data);
              setSelectedEventData(data.payload.surgicalevent.surgicaleventdata);
              setProductItemsList(data.payload.surgicalevent.surgicaleventproductdata);
              toggleDrawer(true);
            },
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.surgicalevent.surgicaleventdata.seid}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'seid')}
                  defaultValue={searchSurgicalHistory.seid}
                  label="Search SE ID"
                  contracts={allContracts}
                  isShrink={true}

                />
            },
            isSearched: searchSurgicalHistory.seid,
            handleClear: () => handleChangeSearchValue('', 'seid'),
          }],
          ["Patient Name", ["payload.surgicalevent.surgicaleventdata.patientfirstname", "payload.surgicalevent.surgicaleventdata.patientlastname"], {
            search: [
              {
                label: "First Name",
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.surgicalevent.surgicaleventdata.patientfirstname}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'patientfirstname')}
                    defaultValue={searchSurgicalHistory.patientfirstname}
                    label="Search Patient Name"
                    contracts={allContracts}
                    isShrink={true}

                  />
              },
              {
                label: "Last Name",
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.surgicalevent.surgicaleventdata.patientlastname}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'patientlastname')}
                    defaultValue={searchSurgicalHistory.patientlastname}
                    label="Search Patient Name"
                    isShrink={true}
                    contracts={allContracts}
                  />
              }
            ],
            isSearched: (!!searchSurgicalHistory.patientfirstname || !!searchSurgicalHistory.patientlastname),
            handleClear: () => {
              if (!!searchSurgicalHistory.patientfirstname && !!searchSurgicalHistory.patientlastname) {
                handleChangeSearchValue('', ['patientfirstname', 'patientlastname']);
              }
              else if (!!searchSurgicalHistory.patientfirstname) handleChangeSearchValue('', 'patientfirstname');
              else if (!!searchSurgicalHistory.patientlastname) handleChangeSearchValue('', 'patientlastname');
            },
            options: {
              width: 200,
            },
          }],
          ["Gender", "payload.surgicalevent.surgicaleventdata.patientgender", {
            search: {
              component:
                <SelectSearch
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'patientgender')}
                  label="Search Patient Gender"
                  options={Object.keys(Genders).map(value => ({ label: Genders[value], value: value }))}
                  defaultValue={searchSurgicalHistory.patientgender}
                  isStatic={true}
                />
            },
            isSearched: searchSurgicalHistory.patientgender,
            handleClear: () => handleChangeSearchValue('', 'patientgender'),
            options: {
              width: 150,
            },
          }],
          ["Physician Name", ["payload.surgicalevent.surgicaleventdata.physiciansalutation",
            "payload.surgicalevent.surgicaleventdata.physicianfirstname",
            "payload.surgicalevent.surgicaleventdata.physicianlastname"], {
              search: [
                {
                  label: "First Name",
                  component:
                    <AdaptiveSearch
                      getValue={(c) => c.payload.surgicalevent.surgicaleventdata.physicianfirstname}
                      handleChangeSearch={(v) => handleChangeSearchValue(v, 'physicianfirstname')}
                      defaultValue={searchSurgicalHistory.physicianfirstname}
                      label="Search Physician Name"
                      isShrink={true}
                      contracts={allContracts}
                    />
                },
                {
                  label: "Last Name",
                  component:
                    <AdaptiveSearch
                      getValue={(c) => c.payload.surgicalevent.surgicaleventdata.physicianlastname}
                      handleChangeSearch={(v) => handleChangeSearchValue(v, 'physicianlastname')}
                      defaultValue={searchSurgicalHistory.physicianlastname}
                      label="Search Physician Name"
                      isShrink={true}
                      contracts={allContracts}
                    />
                }
              ],
              isSearched: (!!searchSurgicalHistory.physicianfirstname || !!searchSurgicalHistory.physicianlastname),
              handleClear: () => {
                if (!!searchSurgicalHistory.physicianfirstname && !!searchSurgicalHistory.physicianlastname) {
                  handleChangeSearchValue('', ['physicianfirstname', 'physicianlastname']);
                }
                else if (!!searchSurgicalHistory.physicianfirstname) handleChangeSearchValue('', 'physicianfirstname');
                else if (!!searchSurgicalHistory.physicianlastname) handleChangeSearchValue('', 'physicianlastname');
              },
              options: {
                width: 180,
              },
            }],
          ["Procedure", "payload.surgicalevent.surgicaleventdata.surgicalprocedure", {
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.surgicalevent.surgicaleventdata.surgicalside}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'surgicalprocedure')}
                  defaultValue={searchSurgicalHistory.surgicalprocedure}
                  label="Search Surgical Procedure"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchSurgicalHistory.surgicalprocedure,
            handleClear: () => handleChangeSearchValue('', 'surgicalprocedure'),
            options: {
              width: 150,
            },
          }],
          ["Side", "payload.surgicalevent.surgicaleventdata.surgicalside", {
            search: {
              component:
                <SelectSearch
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'surgicalside')}
                  label="Search Surgicalside"
                  options={Object.keys(Sides).map(value => ({ label: Sides[value], value: value }))}
                  defaultValue={searchSurgicalHistory.surgicalside}
                  isStatic={true}
                />
            },
            isSearched: searchSurgicalHistory.surgicalside,
            handleClear: () => handleChangeSearchValue('', 'surgicalside'),
            options: {
              width: 150,
            },
          }],
          ["Price", "payload.surgicalevent.surgicaleventdata.price", {
            search: {
              component:
                <AdaptiveSearch
                  getValue={(c) => c.payload.surgicalevent.surgicaleventdata.price}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'price')}
                  defaultValue={searchSurgicalHistory.price}
                  label="Search Price"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchSurgicalHistory.price,
            handleClear: () => handleChangeSearchValue('', 'price'),
            options: {
              width: 150,
            },
          }],
          /* ["Status", "payload.surgicalevent.surgicaleventdata.status", {
             options: {
               width: 150,
             },
           }],
           ["Archived At", "payload.created_at", {
             type: "render",
             render: (param) => (formatDateTime(param.value)),
             options: {
               width: 180,
             },
           }],*/
        ]}

        options={{
          statusEmpty: (allContracts.length === 0)
        }}
      />


      <DetailViewDrawer
        state={state}
        toggleDrawer={toggleDrawer}
        selectedEventData={selectedEventData}
        productItemsList={productItemsList}
      />

    </>
  );
}

export default withLoading(SurgicalEventHistoryListContracts);
