import React, { useState, useEffect, useRef } from "react";
import { Button } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserDispatch, loginAuth } from "../../context/UserContext";


/**
 * Auth0 Login Button
 * @param {Object} history 
 * @param {Object} classes 
 * @param {Function} setLoading 
 * @param {Function} setError 
 * @returns 
 */
export default function AuthLoginButton({
  history, classes, setLoading, setError,
}) {
  // global
  var userDispatch = useUserDispatch();

  const {
    loginWithRedirect,
    loginWithPopup,
    getAccessTokenSilently,
    getIdTokenClaims,
    isLoading, 
    error,
  } = useAuth0();

  
  // login started
  const [started, setStart] = useState(false);

  // display login button
  const [displayLoginButton, setDisplayLoginButton] = useState(false);

  const mounted = useRef(false);


  // This function substitues the loginUser function,
  // see in UserContext.tsx
  const handleLoginClick = async () => {
    setStart(true);

    await loginAuth({
      loginWithRedirect,
      loginWithPopup,
      getAccessTokenSilently,
      getIdTokenClaims,
      userDispatch,
      history,
    });

    setStart(false);
  };


  useEffect(() => {
    if (typeof setLoading === 'function') setLoading(isLoading || started);
    if (typeof setError === 'function') setError(((error && typeof error === 'object' && error.error_description) ? error.error_description: false));
    
    const urlSearchParams = new URL(window.location.toString())?.searchParams;
    if(urlSearchParams?.get('error') ) {
      console.log('Auht login button url', urlSearchParams?.get('error'));
      setLoading(false);
      setError(urlSearchParams?.get('error_description'));
    }
  
  }, [isLoading, setLoading, setError, started, error]);

  /**
   * TYD-1820
   * To hide the login button and redirect the user directly to the auth0 Provider to login when user hit the instance URI.
   * 
   */

  useEffect((redirectUserToAuth0LoginCallback = loginWithRedirect) => {
    setLoading(true);

    setTimeout(() => {
      if (!mounted.current) return;
      const checkAuth0LocalStorage = Object.keys(window.localStorage);
      const validateUserLocalStorage = checkAuth0LocalStorage.filter(function(e){ if(e.indexOf('@@auth0spajs@@:') !== -1) { return e } else { return ""}});
      if(validateUserLocalStorage.length < 1 &&
      (window.location.href.search("error") === -1)) {
        redirectUserToAuth0LoginCallback();
      } else if (validateLocalStorageSPAExpiry(validateUserLocalStorage)) {
        redirectUserToAuth0LoginCallback();
      }
      else {
      setDisplayLoginButton(false);
      }
      
    }, 1000);

  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  /**
   * 
   * @param {*} localId 
   * @returns 
   */
  const validateLocalStorageSPAExpiry = (localId) => {
    if (localId) {
      const getExpiryTime = JSON.parse(localStorage.getItem(localId[0]));
      if (getExpiryTime?.expiresAt && getExpiryTime.expiresAt < Math.floor(Date.now() / 1000)) {
        return true;
      }
    }
  
    return false;
  };
  

  return (
    <>
      {
        (isLoading || started) ?
        <></>
        :
        displayLoginButton && (<Button
          onClick={handleLoginClick}
          variant="contained"
          color="primary"
          size="large"
          className={classes.btn}
        >
          Login by Auth0
        </Button>)
      }
    </>
  );
}
