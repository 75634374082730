import { Checkbox,Button } from "@material-ui/core";
import React from "react";
import useStyles from "../styles";
import PickSetupSubCategories from "./pickSetupSubCategories";
import { createHashID } from "../../../services/crypto-util";

export default function PrefPickSetupCategories({ detailData,dispatch }) {
  const classes = useStyles();
  return (
    
    <>
      <Button
              variant="contained"
              color="primary"
             
              className={classes.useAllBtn}
            >
               <Checkbox
       checked={!!detailData.isChecked}
        onChange={(event) => {
          const newSelected = !!event.target.checked;
          dispatch({
            type: "select_page",
            payload: {
              isChecked: newSelected,
            },
          });
        }}
        className={classes.checkboxSelected}
      />
              Pick All
            </Button>
     
      {detailData?.items?.map((element, index) => {
        return (
          <PickSetupSubCategories
           dispatch={dispatch}
            key={createHashID({ index })}
            element={element}
          
          />
        );
      })}
    </>
  );
}
