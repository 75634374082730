import React, { Component } from 'react';
import classNames from "classnames";
import { DropzoneArea } from 'material-ui-dropzone';
import { Button, Typography , Box} from "@material-ui/core";
import * as styles from "./FileUpload.module.css";
import { UploadFileExt } from '../../config';
import { readCSV, uploadFile } from "./handle";
import csvIcon  from "../../icons/iwwa_file-csv.svg";


export const FileEmpty = "FileEmpty";
export const FileSelected = "FileSelected";
export const FileUploaded = "FileUploaded";


// preview icon of uploader
export const handlePreviewIcon = (fileObject, classes) => {
  const { /* type, */ name } = fileObject.file;
  // console.log("[handlePreviewIcon]", type, name, classes);

  return (
    <Box className="preview-item">
      <img src={csvIcon} className={classes.image} alt="" />
      {name}
    </Box>
  );
};


class FileUploadDropzone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            uploadStatus: FileEmpty,
            uploadSuccess: undefined,
            error: undefined,
        };
    }

    handleChange(files) {
        if(this.props.setUploadedFiles) this.props.setUploadedFiles(files);
        if(this.props.setShowFile) this.props.setShowFile(false);
        // console.log("[FileUploadDropzone] handleChange", files);
        this.setState({
            files: files
        });
        if (files.length > 0) {
            this.setState({
                uploadStatus: FileSelected
            });
            const file = files[0];
            if (file && (file.name).includes(UploadFileExt)) {
                readCSV(file, this.props.setFilePathData);
            }
            if (typeof this.props.changeFile === 'function') {
                this.props.changeFile(file, (d) => this.setState({uploadStatus: d}));
            }
        }
        else {
            this.setState({
                uploadStatus: FileEmpty
            });
            if (typeof this.props.changeFile === 'function') {
                this.props.changeFile(null, (d) => this.setState({uploadStatus: d}));
            }
        }
    }

    handleBtnClick() {
        // console.log("[FileUploadDropzone] handleBtnClick", this.state.files);
        if (this.state.files.length > 0) {
            const file = this.state.files[0];
            // upload
            if (typeof this.props.uploadFile === 'function') {
                this.props.uploadFile(file, (d) => {
                    this.setState(d);
                    if (!d.error) this.setState({
                        uploadStatus: FileUploaded
                    });
                });
            }
            else {
                uploadFile(file, (d) => {
                    this.setState(d);
                    if (!d.error) this.setState({
                        uploadStatus: FileUploaded
                    });
                });
            }
        }
    }
    
    render() {
        const { 
            uploadLabel="Browse Files", 
            uploadIcon=null,
            uploadSuccess="File Upload Successfully", 
            uploadBtn="Upload your file",
            isEnableUpload=true
        } = this.props;
        return (
            <div className={classNames(styles.root, this.props.className)}>
                <div className={!!this.props.isNonePadding ? styles.dropzoneContainerNoPadding : styles.dropzoneContainer}>
                    <DropzoneArea
                        dropzoneProps={ { disabled: Boolean(this.props.disableFileUpload)} }
                        onChange={this.handleChange.bind(this)}
                        showPreviewsInDropzone={this.props.showFile}
                        showAlerts={this.props.showFile}
                        useChipsForPreview={this.props.useChipsForPreview}
                        filesLimit={this.props.filesLimit?this.props.filesLimit:1}
                        maxFileSize={this.props.maxFileSize?this.props.maxFileSize:8000000}
                        classes={{
                            root: styles.dropzoneRoot,
                            textContainer: styles.fileTitle,
                        }}
                        Icon={() => (
                            <>
                              {
                                uploadIcon
                                ?
                                uploadIcon
                                :
                                (
                                  <>
                                    <Typography className={styles.fileSubTitle}>{this.props.subtitle}</Typography>
                                    {
                                      (!isEnableUpload || this.state.uploadStatus === FileEmpty) && 
                                      <label className={styles.fileLabelBtn} style={{marginTop: 10}} >
                                        <span>{uploadLabel}</span>
                                      </label>
                                    }
                                  </>
                                )
                              }
                            </>
                        )}
                        dropzoneText={this.props.title}
                        getPreviewIcon={this.props.handlePreviewIcon}
                    />
                    {
                        (isEnableUpload && this.state.uploadStatus === FileUploaded) &&
                        <Typography>{uploadSuccess}</Typography>
                    }
                    {
                        (isEnableUpload && this.state.uploadStatus === FileSelected) && 
                        <Button 
                            variant="outlined"
                            onClick={this.props.handleUpload ? this.props.handleUpload() : this.handleBtnClick.bind(this)}
                        >
                            {uploadBtn}
                        </Button>
                    }
                </div>
            </div>
        )
    }
}
 
export default FileUploadDropzone;