import React, { useState, useCallback } from "react";
import { useLedger } from "@daml/react";
import {
  Grid, LinearProgress, Link, Popper, Box, Typography,
  List, ListItem, ListItemIcon, ListItemText
} from "@material-ui/core";
import { useDamlState } from '../../context/DamlContext';
import Contracts from "../../components/Contracts/Contracts1";
import SnackbarModal from "../../components/Modals/SnackbarModal";
import { isEnableSendVendor, POHStatus } from '../../models/POStatus';
import { POOwnerships } from "../../models/Ownership";
import { InventoryPOStatus, checkNotPOHistory } from "../../models/Po";
import { contractFilter1 } from "../../services/daml-filters";
import { getTodayString } from "../../services/util";
import { ArchivePo, SendPov } from "./handle";
import { handleCheckedContract, handleClickedContract } from '../surgicalevent/surgicaleventlistcontracts1';
import { isEnableArchivePurchaseOrder } from './purchaseorderlistcontracts';
import withLoading from "../../hoc/withLoading";
import withFilters from "../../hoc/withFilters";
import useStyles from "../surgicalevent/styles";
import useStyles1 from "./styles";
import { useTranslation } from 'react-i18next';



/**
 * Purchase Order Contracts in Hospital
 * @param {Object} searchParams 
 * @param {Boolean} isIncludeHistory 
 * @param {Array} allContracts 
 * @param {Array} historyContracts 
 * @param {Function} setArchived
 * @param {Function} setChecked
 * @param {Function} handleEditPO
 * @param {Function} handleSendPO
 * @returns 
 */
function PurchaseOrderListContracts({
  searchParams = {},
  isIncludeHistory = false,
  allContracts = [],
  historyContracts = [],
  setArchived = null,
  setChecked = null,
  handleEditPO = null,
  handleSendPO = null,
}) {

  const classes = useStyles();
  const classes1 = useStyles1();
  const { t } = useTranslation();

  const ledger = useLedger();

  const { role, party, userParty, roleCid, userlistPayload } = useDamlState();


  allContracts = allContracts.concat(isIncludeHistory && historyContracts.length ?
    historyContracts.map(h => ({ ...h, payload: h.payload.purchaseorder })) : []);
  let contracts = contractFilter1(allContracts, searchParams);
  console.log("[PurchaseOrderListContracts]", searchParams, contracts);


  const [ContractId, setContractId] = useState('');
  const [payload, setPayload] = useState({});
  // Archive PO
  const [openWarning, setOpenWarning] = useState(false);
  // Send PO
  const [openToastWarning, setOpenToastWarning] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  // Popover when roll over
  const [anchorType, setAnchorType] = useState(null);
  const [overContract, setOverContract] = useState(null);
  const [coords, setCoords] = useState({ x: 0, y: 0 });


  // Click "Archive PO" in list
  const handleArchivePO = (po) => {
    console.log('[PurchaseOrderListContracts] handleArchivePO', po);
    setContractId(po.contractId);
    setPayload(po.payload);
    setOpenWarning(true);
  };

  const handleClickSendPO = (po) => {
    console.log('[PurchaseOrderListContracts] handleClickSendPO', po);
    setContractId(po.contractId);
    setPayload(po.payload);
    // setSendPOData(po.payload)
    setOpenToastWarning(true);
  };

  // Click "Send PO" button in PO list
  const setSendPOData = async () => {
    // Old case - Open Edit/Send Modal
    /* if (typeof handleSendPO === 'function') {
      handleSendPO(data);
    } */
    // New case - Send PO to Vendor directly
    await SendPov({
      ledger,
      ContractId: ContractId,
      roleCid,
      email: userlistPayload?.email,
      username: userlistPayload?.name,
      pocomment: {
        date: getTodayString(),
        user: (userParty ? userParty : party),
      },
    });
    // toast
    setOpenToast(true);
  };

  // Click "Yes" in "Archive Purchase Order" modal
  const handleModalYes = async () => {
    console.log("[PurchaseOrderListContracts] handleModalYes to archive Purchase Order", ContractId);
    // archive PO
    await ArchivePo({
      ledger, cid: ContractId, roleCid,
      payload, user: (userParty ? userParty : party), role,
    });
    setOpenWarning(false);
  };

  const handleOver = useCallback((e, event) => {
    const contract = (e && 'row' in e && 'contract' in e.row) ? e.row.contract : {};
    let postatus = null;
    if ('contractId' in contract) {
      postatus = contract.payload.purchaseorderdata.postatus;
      if (postatus === 'MissingInfo' || postatus === 'Rejected') {
        setCoords({ x: event.pageX, y: event.pageY });
        if (postatus === 'Rejected') {
          setOverContract(contract.payload.purchaseorderdata.popurchaseorderid);
        }
      }
      else {
        postatus = null;
      }
    }
    setAnchorType(postatus);
  }, []);



  return (
    <>
      <Contracts
        className={classes.customRoot}
        contracts={contracts}
        columns={[
          ["PO Number", "payload.purchaseorderdata.popurchaseorderid", {
            type: 'link',
            search: {
              type: "AdaptiveSearch",
              name: "purchaseorderdata.popurchaseorderid",
              getValue: (c) => c.payload.purchaseorderdata.popurchaseorderid,
              contracts: allContracts,
            },
            options: {
              // width: 170,
              flex: 0.8,
              cellClassName: classes.nobreak
            }
          }],
          ["SE ID", "payload.purchaseorderdata.poseid", {
            type: 'render',
            render: (param) => (
              param.value
                ?
                <Link component="button" variant="body2">{param.value}</Link>
                :
                "N/A"
            ),
            search: {
              type: "AdaptiveSearch",
              name: "purchaseorderdata.poseid",
              getValue: (c) => c.payload.purchaseorderdata.poseid,
              contracts: allContracts,
            },
            options: {
              flex: 1.1,
              cellClassName: classes.nobreak
            }
          }],
          ["PO Date", "payload.purchaseorderdata.podate", {
            search: {
              type: "DateSearch",
              name: "purchaseorderdata.podate",
            },
            options: {
              // width: 150,
              flex: 1,
            }
          }],
          ["Ownership", "payload.purchaseorderdata.popurchaseordertype", {
            search: {
              type: "SelectSearch",
              name: "purchaseorderdata.popurchaseordertype",
              options: Object.keys(POOwnerships).map(value => ({ label: POOwnerships[value], value: value })),
            },
            options: {
              flex: 1,
            }
          }],
          ["Distributor", "payload.purchaseorderdata.povendorid", {
            search: {
              type: "AdaptiveSearch",
              name: "purchaseorderdata.povendorid",
              getValue: (c) => c.payload.purchaseorderdata.povendorid,
              contracts: allContracts,
            },
            options: {
              // width: 175,
              flex: 1,
            }
          }],
          ["PO Status", "payload.purchaseorderdata.postatus", {
            type: "render",
            render: (param) =>
              <Grid container alignItems="center">
                <Grid item xs={2} style={{ marginTop: "2px" }}>
                  <svg height="10" width="10">
                    <circle cx="5" cy="5" r="5"
                      fill={(param.value === 'InvoiceCreated')
                        ? '#2196F3'
                        : (param.value === 'Accepted' ? '#4CAF50'
                          : (param.value === 'Rejected' ? '#F44336'
                            : (param.value === 'MissingInfo' ? '#F7CD37'
                              : (param.value === 'SentVendor' ? '#2196F3' : 'rgba(76, 175, 80)'))))} />
                  </svg>
                </Grid>
                <Grid item xs={10}>
                {(param.value === "InvoiceSent") ? POHStatus["InvoiceReceived"] : POHStatus[param.value]}
                </Grid>
              </Grid>
            ,
            search: {
              type: "SelectSearch",
              name: "purchaseorderdata.postatus",
              options: Object.keys(POHStatus).map(value => ({ label: POHStatus[value], value: value })),
              maxColCnt: 5,
              classes: {
                popoverInner: classes1.newInnerPopper1,
              },
            },
            options: {
              // width: 122,
              flex: 1,
            }
          }],
          ["Product Status", "payload.purchaseorderdata.poinventorystatus", {
            type: "render",
            render: (param) => (<ProductStatusComp value={param.value} classes={classes1} />),
            search: {
              type: "SelectSearch",
              name: "purchaseorderdata.poinventorystatus",
              options: Object.keys(InventoryPOStatus).map(value => ({ label: InventoryPOStatus[value], value: value })),
              maxColCnt: 5,
              classes: {
                popoverBox: classes1.newPopoverBox,
                popoverInner: classes1.newInnerPopper,
              },
            },
            options: {
              flex: 1,
            }
          }],
          ["Total", "payload.purchaseorderdata.pototalamount", {
            options: {
              beforeText: '$',
              // width: 175,
              flex: 0.8,
            }
          }],
        ]}

        actions={[
          [
            "Actions",
            [
              [["Send PO",
                (c) => isEnableSendVendor(c.payload.purchaseorderdata.postatus) && checkNotPOHistory(c)],
                handleClickSendPO],
              //  /*setSendPOData*/
              [["Delete PO",
                (c) => isEnableArchivePurchaseOrder(c)],
                handleArchivePO],
            ],
            {
              flex: 1,
              headerAlign: 'center',
              checkVisible: (c) => ((isEnableSendVendor(c.payload.purchaseorderdata.postatus) && checkNotPOHistory(c))
                || isEnableArchivePurchaseOrder(c)),
            }
          ]
        ]}
        options={{
          statusEmpty: (allContracts.length === 0),
          checkbox: true,
        }}

        handleArchived={setArchived}
        handleClicked={(e) => handleClickedContract(e, handleEditPO)}
        handleChecked={(e) => handleCheckedContract(e, contracts, setChecked)}
        handleOver={handleOver}
      />


      <SnackbarModal
        open={openWarning}
        setOpen={setOpenWarning}
        type="warning"
        title=""
        content={t('warnings.purchaseorder.deletepo')}
        vertical="center"
        horizontal="center"
        width={345}
        height={150}
        transition="fade"
        className={classes.toastMedium}
        options={{
          validateULE: true,
        }}
        actions={[
          {
            label: 'Yes',
            handle: handleModalYes,
          },
          {
            label: 'No',
          }
        ]}
      />

      <SnackbarModal
        open={openToastWarning}
        setOpen={setOpenToastWarning}
        type="warning"
        title=""
        content={t('warnings.purchaseorder.sendpotovendor')}
        vertical="center"
        horizontal="center"
        width={345}
        height={150}
        transition="fade"
        className={classes.toastMedium}
        options={{
          validateULE: true,
        }}
        actions={[
          {
            label: 'Yes',
            handle: setSendPOData,
          },
          {
            label: 'No',
          }
        ]}
      />

      <SnackbarModal
        open={openToast}
        setOpen={setOpenToast}
        title=""
        content="The PO has been successfully sent to the Vendor."
        width={360}
        height={100}
        className={classes.toastMedium}
      />

      <Popper
        open={!!anchorType}
        anchorEl={{
          clientHeight: 0,
          clientWidth: 0,
          getBoundingClientRect: () => ({
            top: coords.y,
            left: coords.x,
            right: coords.x,
            bottom: coords.y,
            width: 0,
            height: 0,
          })
        }}
        className={classes1.overPopper}
      >
        <Box>
          <Grid container direction="column">
            <Grid item className="header">
              {
                anchorType === 'MissingInfo'
                  ?
                  <Typography className="missing">Information Missing</Typography>
                  :
                  <Typography>Purchase Order Declined</Typography>
              }
            </Grid>
            <Grid item className="body">
              {
                anchorType === 'MissingInfo'
                  ?
                  <List disablePadding>
                    <ListItem>
                      <ListItemIcon className="missing-icon">1</ListItemIcon>
                      <ListItemText primary="Product Information" secondary={null} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className="missing-icon">2</ListItemIcon>
                      <ListItemText primary="Ownership" secondary={null} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className="missing-icon">3</ListItemIcon>
                      <ListItemText primary="Price" secondary={null} />
                    </ListItem>
                  </List>
                  :
                  <List disablePadding>
                    <ListItem>
                      <ListItemIcon className="declined-icon"></ListItemIcon>
                      <ListItemText primary={`PO Number ${overContract} has been Declined`} secondary={null} />
                    </ListItem>
                  </List>
              }
            </Grid>
          </Grid>
        </Box>
      </Popper>

    </>
  );
}

/**
 * Product Status Component
 * with progress line
 * @param {String} value
      * @param {Object} classes
      * @returns
      */
export const ProductStatusComp = ({
  value, classes,
}) => {
  let percent = 0, color = '';
  if (value === 'Open') {
    color = 'dark-gray';
  }
  else if (value === 'AllShipped') {
    percent = 75;
  }
  else if (value === 'SomeShipped') {
    percent = 25;
  }
  else if (value === 'SomeReceived') {
    percent = 50;
  }
  else if (value === 'AllReceived') {
    percent = 100;
  }
  else return null;
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-around"
      alignItems="center"
      className={classes.newProgressWrapper}
    >
      <Grid item>
        <LinearProgress
          variant="determinate"
          value={percent}
          className={color}
        />
      </Grid>
      <Grid item>
        {InventoryPOStatus[value]}
      </Grid>
    </Grid>
  );
};

const POListContractsWithLoading = withLoading(PurchaseOrderListContracts);
export default withFilters(POListContractsWithLoading)([
  { name: "purchaseorderdata.poseid", label: "SE ID" },
  { name: "purchaseorderdata.popurchaseorderid", label: "PO Number" },
  { name: "purchaseorderdata.podate", label: "PO Date", type: "date" },
  { name: "purchaseorderdata.popurchaseordertype", label: "Ownership" },
  { name: "purchaseorderdata.povendorid", label: "Distributor" },
  { name: "purchaseorderdata.postatus", label: "PO Status" },
  { name: "purchaseorderdata.poinventorystatus", label: "Product Status" },
]);
