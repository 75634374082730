import { OperatorRole, HospitalRole, VendorRole } from "../../services/daml-modules1";
import { validateUser, getUser } from "../../models/User";
import { allocateParty } from "../../services/axios/daml-json-api";
import {
    createUser, getUsers, updateUser as updateAuthUser, searchUsers,
    deleteUser as deleteAuthUser, changePasswordEmailSend
} from "../../services/axios/auth-api";
import { getTemplateListFromResponse } from "../../services/util";

import { createPartyFromName } from '../../models/handles';
import { vendorInviteEmailSend } from '../../services/axios/api-node_server';
/**
 * TODO
 * 
 * There are some requirements to implement these handles
 * with invited_organization, current_organization
 */

/**
 * create new User in Vendor
 * exercise CreateUser choice of VendorRole
 * exercise AddUser choice of HospitalRole
 * exercise MakeUser choice of OperatorRole
 * @param {Object} ledger 
 * @param {Object} newuser
 * @param {String} password
 * @param {String} roleCid
 * @param {Object} rolePayload 
 * @param {String} role
 * @returns {Boolean|String}
 */
export const createUserList = async ({
    ledger, newuser, password, roleCid, rolePayload, role, token
}) => {
    console.log("[createUserList]", newuser);

    if (!validateUser(newuser)) return "The user doesn't have enough informations.";

    newuser = getUser(newuser);

    const { contractid, newRole, organization, newParty, choiceCreate } = getParamsFromQueryResult(roleCid, rolePayload, role);

    if (!contractid) return "The organization doesn't exist in the ledger.";

    // before we submit the new user to daml we need to also submit the user to the 
    // DAML API call for allocating a party: https://docs.daml.com/json-api/index.html#allocate-a-new-party

    try {
        const resAllocate = await allocateParty(newuser.party, token);
        if (!resAllocate) return "Allocate Party error occurs.";

        // create user in Auth0
        const resAuth = await createUser({
            email: newuser.email,
            name: newuser.name,
            party: createPartyFromName(newParty),
            password,
            role: newRole,
            organization,
            userParty: newuser.party,
            userid: newuser.userid,
            gender: newuser.gender,
            officephone: newuser.officephone,
            phone: newuser.phone,
            accesslevel: newuser.accesslevel,
            jobtitle: newuser.jobtitle,
            userstatus: newuser.userstatus,
            invited_organization: [],
            current_organization: organization,
        });
        if (resAuth && resAuth.user_id) {
            // send verification email
            // await sendVerificationEmail({
            //     id: resAuth.user_id,
            // });

            /**
             * TYD-2016 - On creation force user to set the password
             */
            // send a password change ticket
            await changePasswordEmailSend({
                email: resAuth.email,
            });
        }
        else return "Auth0 Create error occurs.";

        console.log("start Ledger Exercise");

        // create userlist in ledger
        let res = await ledger.exercise(choiceCreate, contractid, {
            party: newuser.party,
            userid : newuser.userid,
            role : newRole,
            name: newuser.name,
            gender: newuser.gender,
            officephone: newuser.officephone,
            phone: newuser.phone,
            email: newuser.email,
            accesslevel : newuser.accesslevel,
            jobtitle : newuser.jobtitle,
            userstatus: newuser.userstatus,
        });

        console.log("[createUserList]", res);

        // get contractId of the created UserList
        let templateUser = getTemplateListFromResponse(res, "UserList");

        return (templateUser.length > 0);
    } catch (e) {
        console.log("error caught");
        console.error(e);
    }

    return false;
};

/**
 * update User
 * exercise UpdateUser choice of UserList
 * @param {Object} ledger
 * @param {Object} newuser
 * @param {String} ContractId
 * @param {String} authUserId
 * @param {String} roleCid
 * @param {Object} rolePayload 
 * @param {String} role
 * @param {Object} oldUser 
 * @returns {Boolean|String}
 */
export const updateUser = async ({
    ledger, newuser, ContractId, authUserId, roleCid, rolePayload, role, oldUser, 
}) => {
    console.log("[updateUser]", ContractId, newuser, authUserId, oldUser);

    if (!validateUser(newuser)) return "The user doesn't have enough informations.";

    newuser = getUser(newuser);

    const { contractid, newRole, organization, newParty, choiceUpdate } = getParamsFromQueryResult(roleCid, rolePayload, role);

    if (!contractid) return "The organization doesn't exist in the ledger.";

    try {
        if (authUserId) {
            const resAuth = await updateAuthUser({
                id: authUserId,
                email: newuser.email,
                name: newuser.name,
                party: createPartyFromName(newParty),
                role: newRole,
                organization,
                userParty: newuser.party,
                userid: newuser.userid,
                gender: newuser.gender,
                officephone: newuser.officephone,
                phone: newuser.phone,
                accesslevel: newuser.accesslevel,
                jobtitle: newuser.jobtitle,
                userstatus: newuser.userstatus,
                passwordHash: (oldUser && oldUser.app_metadata.daml_ledger_api) ? oldUser.app_metadata.daml_ledger_api.passwordHash : 0,
                invited_organization: (oldUser && oldUser.app_metadata.daml_ledger_api) ? oldUser.app_metadata.daml_ledger_api.invited_organization : [],
                current_organization: (oldUser && oldUser.app_metadata.daml_ledger_api) ? oldUser.app_metadata.daml_ledger_api.current_organization : organization,
            });
            if (!resAuth && !resAuth.user_id) {
                // send verification email
                // await sendVerificationEmail({
                //     id: resAuth.user_id,
                // });
                return "Auth0 Update error occurs.";
            }
            //else return "Auth0 Update error occurs.";
        }

        console.log("start Ledger Exercise");

        const res = await ledger.exercise(choiceUpdate, contractid, {
            usercid: ContractId,
            newparty: newuser.party,
            newuserid: newuser.userid,
            newname: newuser.name,
            newrole: newuser.role,
            newgender: newuser.gender,
            newofficephone: newuser.officephone,
            newphone: newuser.phone,
            newemail: newuser.email,
            newaccesslevel: newuser.accesslevel,
            newjobtitle: newuser.jobtitle,
            newuserstatus: newuser.userstatus,
            is_darkenable : newuser.isdarkenable ,
            newprofileimg : newuser.profileimg
        });

        // get contractId of the updated UserList
        let templateUser = getTemplateListFromResponse(res, "UserList");

        return (templateUser.length > 0);

    } catch (e) {
        console.log("error caught");
        console.error(e);
    }

    return false;
};


export const inviteVendorUserOrg = async ({ rolePayload, oldUser }) => {
    if (!oldUser) return false;
    try {
        const organization = rolePayload.hospital;
        const email = oldUser.email;
        const userName = oldUser.name;
        const vendormail = oldUser.vendorEmail;

        // send invitation email
        let res = await vendorInviteEmailSend({
            facility: organization,
            email: email,
            userName: userName,
            vendormail: vendormail,
        });
        if (res) {
            return true;
        }
    }

    catch (e) {
        console.log("[inviteUserOrg]", e);
    }

    return false;

}

/**
 * get Users in Auth0 and make list
 * @returns {Array}
 */
export const getUserList = async () => {
    // console.log("[getUserList]");
    let result = [];

    try {

        const resAuth = await getUsers();
        console.log("[getUserList] response", resAuth);

        if (resAuth && typeof resAuth === 'object' && resAuth.length) result = resAuth;

    } catch (e) {
        console.log("error caught");
        console.error(e);
    }

    return result;
};

/**
 * search Users in Auth0 and make list
 * @param {Array} emails 
 * @returns {Array}
 */
export const searchUserList = async (emails = []) => {
    console.log("[searchUserList]");
    let result = [];

    try {

        const resAuth = await searchUsers({ emails });
        console.log("[searchUserList] response", resAuth);

        if (resAuth && typeof resAuth === 'object' && resAuth.length) result = resAuth;

    } catch (e) {
        console.log("error caught");
        console.error(e);
    }

    return result;
};

/**
 * get Users in Auth0 and make list
 * @param {Object} queryResult
 * @param {String} role
 * @returns {Object}
 */
const getParamsFromQueryResult = (roleCid, rolePayload, role) => {
    // console.log("[getParamsFromQueryResult]", roleCid, rolePayload);

    if (!roleCid) {
        console.log("query Result has not contracts.");
        return {};
    }

    const contractid = roleCid;
    const newRole = role;
    const organization = (role === "Hospital") ? rolePayload.hospital : (
        (role === "Vendor") ? rolePayload.vendor : rolePayload.party
    ); // e.g. FirstHealth, BioMed, Operator
    const newParty = "Operator"; // TODO : change to be same as organization

    const choiceCreate = (role === "Hospital") ? HospitalRole.AddUser : (
        (role === "Vendor") ? VendorRole.CreateUser
            : OperatorRole.MakeUser
    );
    const choiceUpdate = (role === "Hospital") ? HospitalRole.HUserUpdate : (
        (role === "Vendor") ? VendorRole.VUserUpdate
            : OperatorRole.OUserUpdate
    );
    const choiceDelete = (role === "Hospital") ? HospitalRole.HDeleteUser : (
        (role === "Vendor") ? VendorRole.VDeleteUser
            : OperatorRole.ODeleteUser
    );

    return { contractid, newRole, organization, newParty, choiceCreate, choiceUpdate, choiceDelete };

};

/**
 * delete User
 * exercise DeleteUser choice of UserList
 * @param {Object} ledger
 * @param {String} ContractId
 * @param {String} authUserId
 * @param {String} roleCid
 * @param {Object} rolePayload 
 * @param {String} role
 * @returns {Boolean|String}
 */
export const deleteUser = async ({
    ledger, ContractId, authUserId, roleCid, rolePayload, role,
}) => {
    console.log("[deleteUser]", ContractId, authUserId);


    const { contractid, choiceDelete } = getParamsFromQueryResult(roleCid, rolePayload, role);

    if (!contractid) return "The organization doesn't exist in the ledger.";

    try {
        if (authUserId) {
            await deleteAuthUser({
                id: authUserId,
            });
        }

        console.log("start Ledger Exercise");

        const res = await ledger.exercise(choiceDelete, contractid, {
            usercid: ContractId,

        });

        // get contractId of the deleted UserList
        //let templateUser = getTemplateListFromResponse(res, "UserList");

        return (res.length > 0);

    } catch (e) {
        console.log("error caught");
        console.error(e);
    }

    return false;
};

/**
 * invite User to the organization
 * exercise HInviteUser choice of HospitalRole
 * @param {Object} ledger 
 * @param {Object} newuser 
 * @param {string} roleCid 
 * @param {Object} rolePayload 
 * @param {Object} oldUser 
 * @returns {Boolean}
 */
export const inviteUserOrg = async ({
    ledger, newuser, roleCid, rolePayload, oldUser
}) => {
    if (!oldUser || !oldUser.app_metadata.daml_ledger_api) return false;
    try {
        const organization = rolePayload.hospital;
        const currentOrg = oldUser.app_metadata.daml_ledger_api.organization;
        const currentInviteOrgs = oldUser.app_metadata.daml_ledger_api.invited_organization ?
            oldUser.app_metadata.daml_ledger_api.invited_organization : [];
        const party = oldUser.app_metadata.daml_ledger_api.party;
        const authUserId = oldUser.user_id;

        if (authUserId) {
            // check out if the user is enabled to invite
            if (organization !== currentOrg && currentInviteOrgs.indexOf(organization) === -1) {
                console.log("start Update Auth User");

                const resAuth = await updateAuthUser({
                    id: authUserId,
                    email: oldUser.email,
                    name: oldUser.name,
                    role: oldUser.app_metadata.daml_ledger_api.role,
                    organization: currentOrg,
                    userParty: party,
                    userid: oldUser.app_metadata.daml_ledger_api.userid,
                    gender: oldUser.app_metadata.daml_ledger_api.gender,
                    officephone: oldUser.app_metadata.daml_ledger_api.officephone,
                    phone: oldUser.app_metadata.daml_ledger_api.phone,
                    accesslevel: oldUser.app_metadata.daml_ledger_api.accesslevel,
                    jobtitle: oldUser.app_metadata.daml_ledger_api.jobtitle,
                    userstatus: oldUser.app_metadata.daml_ledger_api.userstatus,
                    passwordHash: oldUser.app_metadata.daml_ledger_api.passwordHash,
                    invited_organization: [...currentInviteOrgs, organization],
                    current_organization: oldUser.app_metadata.daml_ledger_api.current_organization,
                });

                if (resAuth && resAuth.user_id) {
                    // succeed to update invited_organization of Auth user
                }
                else return "Auth0 Update error occurs.";
            }
        }

        if (roleCid) {
            console.log("start Ledger Exercise");

            // exercie by using UserList contract with the Party, Organization
            let res = await ledger.exercise(HospitalRole.HInviteUser, roleCid, {
                usercid: undefined,//oldUser.app_metadata.daml_ledger_api.userid,
                organization: currentOrg,
                userparty: party,
            });
            console.log("[inviteUserOrg] HInviteUser", res);

            // get contractId of the updated UserList
            let templateUser = getTemplateListFromResponse(res, "UserList");

            return (templateUser.length > 0);

        }

    }
    catch (e) {
        console.log("[inviteUserOrg]", e);
    }

    return false;
};

/**
 * Switch User
 * @param {String} email 
 * @param {String} targetOrg 
 * @param {String} currentOrg 
 * @returns {Boolean}
 */
export const switchUser = async ({
    email, targetOrg, currentOrg,
}) => {
    console.log("[switchUser]", email, targetOrg, currentOrg);
    if (targetOrg === currentOrg) return false;

    try {
        // get user info from Auth
        const [oldUser] = await searchUserList([email]);
        if (!oldUser || !oldUser.app_metadata.daml_ledger_api) return false;

        const org = oldUser.app_metadata.daml_ledger_api.organization;
        const currentInviteOrgs = oldUser.app_metadata.daml_ledger_api.invited_organization ?
            oldUser.app_metadata.daml_ledger_api.invited_organization : [];
        const enableOrgs = [...currentInviteOrgs, org];
        const party = oldUser.app_metadata.daml_ledger_api.party;
        const authUserId = oldUser.user_id;

        if (authUserId) {
            // check out if the user is enabled to switch
            if (enableOrgs.indexOf(targetOrg) !== -1) {
                console.log("start Update Auth User");

                const resAuth = await updateAuthUser({
                    id: authUserId,
                    email: oldUser.email,
                    name: oldUser.name,
                    role: oldUser.app_metadata.daml_ledger_api.role,
                    organization: org,
                    userParty: party,
                    userid: oldUser.app_metadata.daml_ledger_api.userid,
                    gender: oldUser.app_metadata.daml_ledger_api.gender,
                    officephone: oldUser.app_metadata.daml_ledger_api.officephone,
                    phone: oldUser.app_metadata.daml_ledger_api.phone,
                    accesslevel: oldUser.app_metadata.daml_ledger_api.accesslevel,
                    jobtitle: oldUser.app_metadata.daml_ledger_api.jobtitle,
                    userstatus: oldUser.app_metadata.daml_ledger_api.userstatus,
                    passwordHash: oldUser.app_metadata.daml_ledger_api.passwordHash,
                    invited_organization: currentInviteOrgs,
                    current_organization: targetOrg,
                });

                if (resAuth && resAuth.user_id) {
                    // succeed to update current_organization of Auth user
                    return true;
                }
                else return "Auth0 Update error occurs.";
            }
        }
    }
    catch (e) {
        console.log("[inviteUserOrg]", e);
    }

    return false;
};