import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Grid, Typography, Button, Box } from "@material-ui/core";
import exportIcon from "../../../icons/new-export-icon.svg";
import useStyles from "../styles";
import ModalDialog from "../../../components/Modals/NewGeneralModal";
import scanIcon from "../../../icons/scan_icon.svg";
import PrefPickSetupCategories from "./pickSetupCategories";
import SnackbarModal from "../../../components/Modals/SnackbarModal";
import { createHashID } from "../../../services/crypto-util";
import usePrefReducer from "../../../hooks/usePrefReducer";
import { updatePrefCardDetails } from "../handle";
import useEvent from "../../../hooks/useEvent";
import { PREF_LOAD_EVENT } from "../../../store/actions/constants";
import { useTranslation } from 'react-i18next';
import { useLedger } from "@daml/react";
import { useDamlState } from "../../../context/DamlContext";

/**
 * @returns
 * @param {Boolean} open
 * @param {Function} setOpen
 * @param {Object} detailData
 */

export default function PrefCardPickSetup({
  open,
  setOpen,
  detailData,
  setOpenPickedToast,
}) {
  const { state, dispatch } = usePrefReducer(detailData);
  const [openCost, setOpenCost] = useState(0);
  const [pulledCost, setPulledCost] = useState(0);
  const { t } = useTranslation();
  const classes = useStyles();
  const ledger = useLedger();
  const { roleCid } = useDamlState();
  const [snackbarModel, setSnackbarModel] = useState(false);
  const { onEventStart } = useEvent();

  const handleNext = async () => {
    if (state.isChecked) {
      setOpenPickedToast(true);
      await handleSave();
    } else {
      setSnackbarModel(true);
    }
  };

  const handleSave = async () => {
    if (state?.contractid) {
      let totalproducts = state.items.reduce((accumulator, currentCategory) => {
        let products = currentCategory.items.reduce(
          (consumedproduct, currentproduct) => {
            if (currentproduct.isChecked) {
              if (
                currentproduct.pseudoItem.vendor &&
                currentproduct.pseudoItem.referenceNumber
              ) {
                consumedproduct.push({
                  vendor: currentproduct.pseudoItem.vendor,
                  reference: currentproduct.pseudoItem.referenceNumber,
                  quantity: parseInt(currentproduct.used),
                  price: parseFloat(currentproduct.pseudoItem.cost),
                });
              }
            }
            return consumedproduct;
          },
          []
        );
        return [...accumulator, ...products];
      }, []);
      let detailData = JSON.parse(JSON.stringify(state));
      delete detailData.contractid;

      await updatePrefCardDetails({
        ledger,
        roleCid,
        prefId: state.contractid,
        detailData,
        totalproducts,
      });
      onEventStart(PREF_LOAD_EVENT);
    }

    setSnackbarModel(false);
    setOpen(false);
  };
  const handleClose = () => {
    setSnackbarModel(false);
  };

  useEffect(() => {
    const calculateCost = () => {
      const itemList = state?.items;

      return itemList?.reduce(
        (acc, currentValue) => {
          let openSum = 0;
          let pulledSum = 0;
          currentValue.items.forEach((item) => {
            openSum = openSum + parseInt(item.open) * item?.pseudoItem.cost;
            pulledSum =
              pulledSum +
              (parseInt(item.closed) + parseInt(item.closed)) *
              item?.pseudoItem.cost;
          });
          return {
            openCost: acc.openCost + openSum.toFixed(2),
            pulledCost: acc.pulledCost + pulledSum.toFixed(2),
          };
        },
        { openCost: 0, pulledCost: 0 }
      );
    };
    const costs = calculateCost();
    setOpenCost(costs?.openCost);
    setPulledCost(costs?.pulledCost);
  }, [state]);
  return (
    <>
      <ModalDialog
        open={open}
        setOpen={setOpen}
        title={
          <>
            <Grid container className={classes.newTitlePanel}>
              <Grid item xs={3}>
                <Typography
                  variant="inherit"
                  component="span"
                  className="label"
                >
                  Pick And Setup
                </Typography>
              </Grid>

              <Grid item xs={6} className={classes.dialogSubheading}>
                <Typography
                  variant="inherit"
                  component="span"
                  className="detailLabel"
                >
                  {state.name}{" "}
                  <span className="details">
                    Open: ${parseFloat(openCost)} | Pulled: ${parseFloat(pulledCost)}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={3} className="rightContent">
                <Button
                  className={classNames(
                    classes.newCreateButton,
                    classes.newIconButton
                  )}
                  variant="contained"
                  // onClick={handleOpenPopover}
                  startIcon={<img alt="" src={scanIcon} />}
                />
                <Button
                  className={classNames(
                    classes.newCreateButton,
                    classes.newIconButton
                  )}
                  variant="contained"
                  // onClick={handleOpenPopover}
                  startIcon={<img alt="" src={exportIcon} />}
                />
              </Grid>
            </Grid>
          </>
        }
        content={
          <>
            <Box className={classes.newPopoverBox}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                className={classes.newTitlePanel}
              >
                <Grid
                  item
                  xs={7}
                  className={classNames(
                    classes.overviewGrid,
                    classes.prefCategoryTable
                  )}
                >
                  <PrefPickSetupCategories
                    detailData={state}
                    dispatch={dispatch}
                  />
                </Grid>

                <Grid item xs={5} className={classes.detailGrid}>
                  {state?.notes?.map((noteElement, index) => {
                    return (
                      <div key={createHashID({ index })}>
                        <Grid container spacing={2}>
                          <Grid item xs={8}>
                            <Typography className="textlable">
                              {noteElement?.title}:
                            </Typography>
                            <Typography className={classes.cellHeaderBold}>
                              {noteElement?.note}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <img
                              className={classes.imgstyle}
                              src={noteElement?.imageUrl}
                              alt=""
                            />
                          </Grid>
                        </Grid>
                      </div>
                    );
                  })}
                </Grid>
              </Grid>
            </Box>
            <SnackbarModal
              open={snackbarModel}
              setOpen={setSnackbarModel}
              type="warning"
              content={t('warnings.prefcard.prefcaredpicksetup.message')}
              vertical="center"
              horizontal="center"
              width={345}
              height={160}
              transition="fade"
              className={classNames(classes.toastLarge, classes.toastWarning)}
              options={{
                delay: 500,
                validateULE: true,
              }}
              actions={[
                {
                  label: "Yes",
                  handle: () => {
                    handleSave();
                  },
                },
                {
                  label: "No",
                  handle: () => {
                    handleClose();
                  },
                },
              ]}
            />
          </>
        }
        actions={
          <>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(false);
              }}
              className={classNames(classes.newCompleteBtn, "secondary-color")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              className={classes.newCompleteBtn}
            >
              Save
            </Button>
          </>
        }
        width={1203}
        height={689}
      />
    </>
  );
}
