import { HospitalRole, VendorRole } from "../../services/daml-modules1";
import { validateVendorTemplate, getVendorTemplate } from "../../models/VendorTemplate";
import { validateHospitalTemplate, getHospitalTemplate } from "../../models/HospitalTemplate";


/**
 * create new VendorTemplate
 * exercise CreateVendorAPIData choice of HospitalRole
 * @param {Object} ledger 
 * @param {Object} newvendor    
 * @param {String} vendor    
 * @param {Function} setContractId   
 * @param {String} roleCid 
 * @returns {Boolean}
 */
export const createVendorTemplate = async ({
    ledger, newvendor, vendor, setContractId, roleCid,
}) => {
    console.log("[VendorTemplateList] createVendorTemplate", newvendor);

    if (!validateVendorTemplate(newvendor)) return;

    newvendor = getVendorTemplate(newvendor);

    if (!roleCid) {
        console.log("query Result has not contracts.");
        return;
    }

    if (typeof setContractId === 'function') setContractId(roleCid);

    try {

        console.log("start Ledger Exercise");

        await ledger.exercise(HospitalRole.CreateVendorAPIData, roleCid, {
            vendor,
            api_index1: newvendor.api_index1,
            api_payload: newvendor.api_payload,
            api_index2: newvendor.api_index2,
            api_payloadline: newvendor.api_payloadline,
        });

    } catch (e) {
        console.log("error caught");
        console.error(e);
    }

};

/**
 * update VendorTemplate
 * exercise HVTUpdate choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} ContractId 
 * @param {Object} newvendor    
 * @param {String} vendor   
 * @param {String} roleCid 
 * @returns
 */
export const updateVendorTemplate = async ({
    ledger, ContractId, newvendor, vendor, roleCid,
}) => {
    console.log("[VendorTemplateList] updateVendorTemplate", ContractId, newvendor);

    if (!validateVendorTemplate(newvendor)) return;

    newvendor = getVendorTemplate(newvendor);


    try {

        console.log("start Ledger Exercise");

        await ledger.exercise(HospitalRole.HVTUpdate, roleCid, {
            vendortemplatecid: ContractId,
            new_api_index1: newvendor.api_index1,
            new_api_payload: newvendor.api_payload,
            new_api_index2: newvendor.api_index2,
            new_api_payloadline: newvendor.api_payloadline,
        });

    } catch (e) {
        console.log("error caught");
        console.error(e);
    }

};

/**
 * create new HospitalTemplate
 * exercise CreateHospitalAPIData choice of VendorRole
 * @param {Object} ledger 
 * @param {Object} newhospital    
 * @param {String} hospital    
 * @param {Function} setContractId   
 * @param {String} roleCid 
 * @returns {Boolean}
 */
export const createHospitalTemplate = async ({
    ledger, newhospital, hospital, setContractId, roleCid,
}) => {
    console.log("[createHospitalTemplate]", newhospital);

    if (!validateHospitalTemplate(newhospital)) return;

    newhospital = getHospitalTemplate(newhospital);

    if (!roleCid) {
        console.log("query Result has not contracts.");
        return;
    }

    setContractId(roleCid);

    try {

        console.log("start Ledger Exercise");

        await ledger.exercise(VendorRole.CreateHospitalAPIData, roleCid, {
            hospital,
            api_index1: newhospital.api_index1,
            api_invoice: newhospital.api_invoice,
            api_index2: newhospital.api_index2,
            api_invoice_attachment: newhospital.api_invoice_attachment,
            api_index3: newhospital.api_index3,
            api_invoice_installments: newhospital.api_invoice_installments,
            api_index4: newhospital.api_index4,
            api_invoice_lines: newhospital.api_invoice_lines,
        });

    } catch (e) {
        console.log("error caught");
        console.error(e);
    }

};

/**
 * update HospitalTemplate
 * exercise VHTUpdate choice of VendorRole
 * @param {Object} ledger 
 * @param {String} ContractId 
 * @param {Object} newhospital    
 * @param {String} hospital
 * @param {String} roleCid 
 * @returns
 */
export const updateHospitalTemplate = async ({
    ledger, ContractId, newhospital, hospital, roleCid,
}) => {
    console.log("[updateHospitalTemplate]", ContractId, newhospital);

    if (!validateHospitalTemplate(newhospital)) return;

    newhospital = getHospitalTemplate(newhospital);


    try {

        console.log("start Ledger Exercise");

        await ledger.exercise(VendorRole.VHTUpdate, roleCid, {
            hospitaltemplatecid: ContractId,
            new_api_index1: newhospital.api_index1,
            new_api_invoice: newhospital.api_invoice,
            new_api_index2: newhospital.api_index2,
            new_api_invoice_attachment: newhospital.api_invoice_attachment,
            new_api_index3: newhospital.api_index3,
            new_api_invoice_installments: newhospital.api_invoice_installments,
            new_api_index4: newhospital.api_index4,
            new_api_invoice_lines: newhospital.api_invoice_lines,
        });

    } catch (e) {
        console.log("error caught");
        console.error(e);
    }

};
