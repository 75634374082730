import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { AddedProductList } from "../surgicalevent/add-edit-product1";
import useStyles from "./styles";



const productPattern = [
  {
    label: 'Product Code', style: {paddingTop: 0},
  },
  {
    key: 'sepudi', width: 306, colSpan: 2,
  },
  {
    key: 'sepreferencenumber', width: 306, colSpan: 2,
  },
  {
    key: 'seplotcode', width: 222, colSpan: 2,
  },
  {
    label: 'Product Details',
  },
  {
    key: 'sepproductmanufacturer', width: 152, style: {width: '167px'},
    key1: 'sepdistributor', width1: 152, disabled1: true,
  },
  {
    key: 'sepproductexpiration', width: 152,
    key1: 'sepownership', width1: 152, 
  },
  {
    key: 'sepproductname', width: 333, colSpan: 2,
  },
  {
    key: 'sepproductdescription', width: 333, colSpan: 2,
  },
  {
    key: 'sepproductwaste', width: 152,
    key1: 'sepuom', width1: 143, 
  },
  {
    key: 'sepproductprice', width: 152,
    key1: 'sepproductside', width1: 152,
  },
  {
    key: 'sepproducttype', width: 152,
    key1: 'iquantityunit', width1: 152, type1: 'number', label1: 'Quantity',
  },
];

const listPattern = [
  {
    key: 'sepudi', key1: 'sepproducttype', details: 'Product Details', detailIsCol: false, 
  },
  {
    key: 'sepreferencenumber', key1: 'sepownership',
  },
  {
    key: 'sepproductmanufacturer', key1: 'sepproductside',
  },
  {
    key: 'sepdistributor', key1: 'sepuom', 
  },
  {
    key: 'sepproductname', key1: 'sepproductprice',
  },
  {
    key: 'sepproductdescription', key1: 'iquantity', label1: 'Quantity :',
  },
  {
    key: 'seplotcode', 
  },
  {
    key: 'sepproductexpiration', 
  },
];

const summaryPattern = [
  {
    label: 'PO Num:',
    value: (obj) => obj.popurchaseorderid,
  },
  {
    label: 'PO Type:',
    value: (obj) => obj.potype,
  },
  {
    label: 'PO Date:',
    value: (obj) => obj.podate,
  },
  // {
  //   label: 'Distributor:',
  //   value: (obj) => obj.povendorid,
  // },
  // {
  //   label: 'Ownership:',
  //   value: (obj) => obj.popurchaseordertype,
  // },
];

/**
 * Added Product List Header Component
 * Matched Purchase Orders
 * @returns 
 */
const AddedProductHeader = () => {

  return (
    <>
      <Grid 
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography className="product-title">
            Matched Purchase Orders
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

/**
 * Product List in Purchase Order
 * @param {Boolean} isEditable 
 * @returns 
 */
const MatchedProducts = ({
  isEditable=false, 
}) => {
  
  const classes = useStyles();

  return (
    <>
      <AddedProductList
        className={classes.newAddedProductPanel}
        options={{
          keyProduct: 'iproduct', 
          keyDetail: 'inventorydata',
          keySummary: 'ipurchaseorderdata',
          summaryPattern: summaryPattern,
          isEnableEditProduct: true,
          isEnableDeleteProduct: isEditable,
          isEnableCompliance: false,
          edit: {
            isEnableCompliance: false,
            designPattern: productPattern,

          },
        }}
        HeaderComponent={AddedProductHeader}
        listPattern={listPattern}
      />
      </>
  );
};

export default MatchedProducts;
