import React, { useState } from "react";
import { InventoryProductModel } from "../../models/InventoryProduct";
import classNames from "classnames";
import { Chip} from "@material-ui/core";
import AdaptiveSearch from "../../components/Search/adaptivesearch";
import Contracts from "../../components/Contracts/Contracts";
import { dispatch, getState } from "../../store";
import { inventorySearch } from "../../store/actions/generalActions";
import HospitalFilter from './HospitalFilter';
import useStyles from "../surgicalevent/styles";


export default function InventoryListOperator({
  searchSurgical = {},
  allContracts = [],
 
}) {

  const contracts = [
    {
        sepreferencenumber:'Ref001',
        uid:'07613327040975',
        quantity:3,
        par_level:10,
        manufacturer:'BioMed',
        lotcode:'0223325',
        ownership:'Owner',
        product_name:'Biolox delta taper liner',
        status:'Complete',
        
    },
      {
        sepreferencenumber:'Ref001',
        uid:'07613327040975',
        quantity:3,
        par_level:10,
        manufacturer:'BioMed',
        lotcode:'0223325',
        ownership:'Owner',
        product_name:'Biolox delta taper liner',
        status:'Complete',
        
    }
  ];
  const classes = useStyles();


  const [searchInventory, setSearchInventory] = useState({
      vendor: '',
      hospital: '',
      ...InventoryProductModel, 
      ...getState().inventory,
  });

  
  const handleChangeSearchValue = (val, key, subkey=null) => {
      const value = (subkey === null) ? val : {...searchInventory[key], [subkey]: val};
      setSearchInventory({...searchInventory, [key]: value});
      dispatch(inventorySearch({key, value: value}));
  };


  //<BorderLinearProgress variant="determinate" value={50} />
  return (
    <>  
     <div key="formChildStep" className="formsteps">
         <HospitalFilter title="Account Inventory" />

      <Contracts
        className={classes.customRoot}
        contracts={contracts}
        columns={[
          // ["ContractId", "contractId"],
          ["Reference Number", "sepreferencenumber", {
            options: {
              width: 200,
            },     
          }],
          ["UDI", "uid",{
              options:{
                  width:180
              }
          }],
          ["Quantity", "quantity",{
            options:{
                width:120,
                headerClassName: classes.centerCell,
                cellClassName:classes.centerCell
              }
          }],
          ["Par  Level", "par_level",{
            options:{
                width:150,
                cellClassName:classes.centerCell,
                headerClassName:classes.centerCell
              }
          }],
          ["Manufacturer", "manufacturer", {
            search: {
              component: 
                <AdaptiveSearch
                  getValue={(c) => c.payload.manufacturer}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'manufacturer')}
                  defaultValue={searchSurgical.manufacturer}
                  label="Search Manufacturer"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchSurgical.manufacturer,
            handleClear: () => handleChangeSearchValue('', 'manufacturer'),
            options: {
              width: 150,
            },
          }],
          ["Lot Code", "lotcode", {
            options: {
              width: 150,
            },
            search: {
              component: 
                <AdaptiveSearch
                  getValue={(c) => c.payload.lotcode}
                  handleChangeSearch={(v) => handleChangeSearchValue(v, 'lotcode')}
                  defaultValue={searchSurgical.lotcode}
                  label="Search Lot Code"
                  contracts={allContracts}
                  isShrink={true}
                />
            },
            isSearched: searchSurgical.lotcode,
            handleClear: () => handleChangeSearchValue('', 'lotcode'),
          }],
          ["Ownership", "ownership",{
            options:{
                width:150
              }
          }],
          ["Product Name", "product_name",{
            options:{
                width:150
              }
          }],
          ["Status", "status",{
            type: "render",
            render: (param) => (<Chip 
                label={param.value} 
                size="small" 
                className={classNames(classes.smallChip, (param.value === 'Complete' ? classes.backGreen : (
                  param.value === 'Cancelled' ? classes.backRed : classes.backYellow
                )))} 
              />), 
            options:{
              width:180
            }
          }]
        ]}

        actions={[]}
        options={{
          statusEmpty: (allContracts.length === 0)
        }}
      />
      
     
      </div>
    </>
  );
}

/**
 * SE List View Modal Dialog Handle 
 */

