import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
tabTitles: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '10px',
    lineHeight: '18px',
    color: '#000000',
    '& active': {
      fontWeight: '800 !important ',
      color: '#1C3C74',
    }
  },
  completeTabPanel: {
    padding: '3px',
  },
  subtabsPanel: {
    bgcolor: '#5C5C5C',
    display: "flex",
    justifyContent: "center",
    height: '50px',
    /* Grey Tydei official */
    background: '#5C5C5C',
  },
  mainBox: {
    background: '#FFFFFF',
    height: '73vh',
    padding: '2px',
  },
  subtabsPanel1: {
    background: '#FFF000',
    border: '1px solid #CCCCCC',
    display: "flex",
    justifyContent: "right",
    height: '20vh',
  },
  subTitles: {
    textTransform: 'capitalize',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '18px;',
    /* TYDEi Yellow */
    color: '#FFFFFF;',
    '&:focus': {
      /* TYDEi Yellow */
      color: '#F7CD37;',
      fontWeight: 800,
    },
  },
  newPageTitle: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#1C3C74',
    marginBottom: '20px',
    marginLeft: '30px',
    marginTop: '20px',
  },
  formControl: {
    width: '50%',
    margin: '10px 0px'
  },
  popUpTitle: {
    fontStyle: 'normal;',
    fontWeight: 700,
    fontSize: '25px;',
    lineHeight: '30px;',
    color: '#1C3C74'
  },
  newDIATitlePanel: {
    '& .name': {
      fontWeight: 700,
      fontSize: '20px',
      minWidth: '170px',
      maxWidth: '600px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      color: '#1C3C74',
    },
    '& .labels': {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
      color: '#000000',
      '&:not(:first-child)': {
        marginLeft: '55px',
      },
    },
    '& .value': {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '24px',
      marginLeft: '13px',
    },
  },
  actionbtns: {
    paddingTop: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end !important',
  },
  newCreateButton: {
    background: '#1C3C74',
    borderRadius: '5px',
    color: '#FFFFFF',
    padding: '9px 12px',
    '&:hover': {
      backgroundColor: '#1C3C74d9',
    },
    '&.MuiButton-root': {
      textTransform: 'capitalize',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '18px',
      '& .MuiSvgIcon-root': {
        margin: '-4px 0px',
      }
    },
  },

}));