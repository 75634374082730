import React, { useState, Fragment } from "react";
import { Grid, Table, TableHead, TableRow, TableCell, TableBody, Typography } from "@material-ui/core";
import classNames from "classnames";
import { getValueByCol } from "./functions";
import { useStyles } from "./styles";


// Vertical - Horizontal Contract
export default function VHContracts({ contracts, rows, more = [], options = {} }) {

  more = more ? more : [];
  options = {
    header: false,
    ...options
  };
  rows = rows ? rows : [["TemplateId", "templateId"], ["ContractId", "contractId"]];

  const classes = useStyles();

  var [index] = useState(0);

  // const numColumns = 1 + contracts.length;


  return (
    <>
      <Grid 
        container
        direction="column"
        className={classes.grid}
        spacing={3}
      >
        <Grid 
          item 
          sm={12} 
          md={4}
        >
    
          <Table size="small">

            {
              options.header && 
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell 
                    className={classNames(classes.tableCell, classes.tableCellFullWidth, classes.tableCellFixed)}
                    variant="head"
                  >
                    Key
                  </TableCell>
                  {contracts.map((c, i) => (
                    <TableCell className={classes.tableCell} key={i}></TableCell>
                  ))}
                </TableRow>
              </TableHead>
            }

            <TableBody>
              {rows.map((row, i) => {

                return (
                  <TableRow key={"row" + i} className={classes.tableRow}>

                    <TableCell 
                      className={classNames(classes.tableCell, classes.tableCellFullWidth, classes.tableCellFixed)}
                      variant="head"
                    >
                      {row[0]}
                    </TableCell>

                    {contracts.map((c, j) => {
                      let val = getValueByCol(c, row[1], row[2]);

                      if (!!val && typeof val === "object" && val.length >= 0) {
                        val = "Total: " + val.length;
                      }

                      return (
                        <TableCell 
                          key={"cell_" + i + "_" + j} 
                          className={classNames(classes.tableCell, classes.tableCellFullWidth)}
                        >
                          {val}
                        </TableCell>
                      );
                    })}

                  </TableRow>
                );
                
              })}
            </TableBody>

          </Table>

        </Grid>

        <Grid 
          item 
          sm={12} 
          md={12}
        >
        
          {
            more.length > 0 && contracts.length > index &&
            more.map((m, k) => {
              let val = getValueByCol(contracts[index], m[1]);

              // console.log("[VHContracts] more", val);
              if (typeof val === "object" && val.length === undefined) val = [val];
              if (typeof val === "object" && val.length >= 0 && typeof val[0] === "object") {

                return (
                  <Fragment key={k}>
                    <Grid container className={classes.grid}>
                      <Typography variant="h6" component="div">
                        {m[0]}
                      </Typography>
                    </Grid>

                    <Grid container className={classes.grid}>

                      <Table size="small">
                        <TableHead>
                          <TableRow className={classes.tableRow}>
                            {m[2].columns.map((col, i) => (
                              <TableCell 
                                key={"col_head" + i} 
                                className={classNames(classes.tableCell, classes.tableCellFullWidth, classes.tableCellFixed)}
                              >
                                {col[0]}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {val.map((vv, j) => {
        
                            return (
                              <TableRow key={"row" + j} className={classes.tableRow}>
        
                                {m[2].columns.map((col, i) => (
                                  <TableCell 
                                    key={"cell_" + i + "_" + j} 
                                    className={classNames(classes.tableCell, classes.tableCellFullWidth)}
                                  >
                                    {getValueByCol(vv, col[1])}
                                  </TableCell>
                                ))}
        
                              </TableRow>
                            );
                            
                          })}
                        </TableBody>
                      </Table>
        
                    </Grid>
                  </Fragment>
                );

              }
              else {
                return (<Fragment key={k}></Fragment>);
              }

            })
          }

        </Grid>
      </Grid>
    </>
  );
}

