import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  outerContainer: {
    width: '350px',
    height: '480px',
    borderWidth: '0 1px 1px 1px',
    borderColor: '#183C73',
    borderStyle: 'solid',
    backgroundColor: '#fff',
  },
  inputMessage:{
    
  }
}));
