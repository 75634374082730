import React, { Fragment, useState, useEffect } from "react";
import {
  Button, Dialog, DialogContent, DialogTitle, DialogActions, Typography, Grid
} from "@material-ui/core";
import ErrorDialog from '../../components/Modals/GeneralModal/error-dialog';
import useStyles from "../payments/styles";
import { CustomSelect, CustomTextField } from "../../components/Inputs";
import closeIcon from "../../icons/new-close-icon.svg";
import { getUsStates } from "../../models/State";

export default function ShipAddressPopUp({
  openModal, isAddNew, data,
  handleCancel, hospitaldetails, setHospitalDetails, handleSave,
  setOpenToast, setToastContent
}) {

  const classes = useStyles();

  const [errorModal, setErrorModal] = useState(false);

  const GetStateList = getUsStates && getUsStates.map(s => {
    return { label: s[1], value: s[0] };
  });

  const GetCountryList = {
    US: 'US',
    CA: 'Canada'
  }

  // handle to update all fields
  const getDefaultValue = (input, defaultValue) => {
    return input ? input : defaultValue
  }
  const [contact, setContact] = useState({
    nickname: '', adddresstype: '', address1: '', address2: 'NA', location: '', city: '', zipcode: '', country: '', state: ''
  });
  useEffect(() => {
    if (data)
      setContact(data);

    return () => {
      setContact({
        nickname: '', adddresstype: '', address1: '', address2: 'NA', location: '', city: '', zipcode: '', country: '', state: ''
      })
    }
  }, [openModal, data])
  const handleChange = (value, key) => {
    let _contact = { ...contact };
    _contact[key] = value;
    setContact(_contact);
  }

  console.log("_contact :", contact);


  return (
    <>
      <Dialog
        open={openModal}
        className={classes.customRoot}
        disableEnforceFocus
        aria-labelledby="draggable-dialog-title"
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >

          <Button
            className={classes.closeButton}
            onClick={handleCancel}
          >
            <img src={closeIcon} alt="" />
          </Button>
        </Grid>
        <DialogTitle className='MuiDialogTitleRoot'>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography variant="inherit" component="span" className={classes.popUpTitle1}>
              {
                (isAddNew.new && isAddNew.ship) ? 'Add Shipping Address' :
                  (isAddNew.new && !isAddNew.ship) ? 'Add Billing Address' :
                    (((!isAddNew.new && isAddNew.ship) ? 'Edit Shipping Address' : 'Edit Billing Address'))
              }
            </Typography>
          </Grid>

        </DialogTitle>
        <DialogContent
          dividers={true}
        >
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="center"
          >
            {
              [
                {
                  label: 'Nickname',
                  value: contact?.nickname,
                  onChange: (e) => handleChange(e, 'nickname'),
                  width: '100%'
                },
                {
                  label: 'Address Type',
                  value: contact?.adddresstype,
                  onChange: (e) => handleChange(e, 'adddresstype'),
                  width: '100%'
                },
                {
                  label: 'Address',
                  value: contact?.address1,
                  onChange: (e) => handleChange(e, 'address1'),
                  width: '100%'
                },
                {
                  label: 'Location',
                  value: contact?.location,
                  onChange: (e) => handleChange(e, 'location'),
                  width: '100%'
                },
                {
                  label: 'City',
                  value: contact?.city,
                  onChange: (e) => handleChange(e, 'city'),
                  width: '100%'
                },
                {
                  label: 'Zipcode',
                  value: contact?.zipcode,
                  onChange: (e) => handleChange(e, 'zipcode'),
                  width: '100%'
                },
              ].map((item, index) => (<Fragment key={index}>
                <Grid item xs={4}>
                  <label className={classes.popUpLabel1}>{item.label}</label>
                </Grid>
                <Grid item xs={8}>
                  <CustomTextField
                    value={item.value === null ? '' : item.value}
                    options={{ disabled: false }}
                    width={item.width}
                    onChange={item.onChange}
                  />
                </Grid>
              </Fragment >))
            }
            <Grid item xs={4} className={classes.tabLabel}><label>Country</label></Grid>
            <Grid item xs={8}>
              <CustomSelect
                placeholder="Select One"
                width={'100%'}
                value={getDefaultValue(contact?.country)}
                items={GetCountryList}
                onChange={(e) => handleChange(e, 'country')}
              />
            </Grid>
            <Grid item xs={4} className={classes.tabLabel}><label>State</label></Grid>
            <Grid item xs={8}>
              <CustomSelect
                placeholder="Select One"
                width={'100%'}
                items={GetStateList}
                value={getDefaultValue(contact?.state)}
                onChange={(e) => handleChange(e, 'state')}
              />
            </Grid>
          </Grid>
          <DialogActions className={classes.actionbtns}>
            <Button variant="contained" className={classes.primarybtn} onClick={() => {
              if ((contact.adddresstype !== '' || contact.address1 !== '' ||
                contact.city !== '' ||
                contact.location !== '' || contact.nickname !== '' ||
                contact.zipcode !== '') && (contact.country !=='' && contact.state !== '')) {
                handleSave(contact);
                setToastContent("Address successfully edited");
                if (!data) setToastContent("Address successfully added");
                setOpenToast(true);
              } else { handleCancel(false) }
            }}>
              Save
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <ErrorDialog
        openModal={errorModal}
        setOpenModal={setErrorModal}
      />
    </>
  );
}