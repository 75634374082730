import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import { useReactToPrint } from 'react-to-print';
import { useParty, useStreamQueries } from "@daml/react";
import { Grid, Typography, Button } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { SurgicalEvent, SurgicalEventHistory, PrefCards, SurgicalEventProcedure, Surgeons } from "../../services/daml-modules1";
import { arrayToCsv, downloadCSV } from "../../services/download";
import useEvent from "../../hooks/useEvent";
import { convertSEDataToCSV } from "../../models/SurgicalEvent";
import { convertSEDetailToCSV } from "../../models/SurgicalEventDetail";
import { useUserState } from "../../context/UserContext";
import { useDamlProcedure, useDamlSurgeon } from '../../context/DamlContext';
import SurgicalEventListContracts from "./surgicaleventlistcontracts1";
import exportIcon from "../../icons/new-export-icon.svg";
import CreateEditSurgicalEvent from "./create_edit_surgicalevent1";
import SurgicalEventPrints from "./surgicalevent-prints";
import ExportPopover from "./export-popover";
import { handlePDFGenerator } from "./surgicalevent-pdf";
import useStyles from "./styles";
import { damlFetch } from "../../services/axios/daml-json-api";

/**
 * Surgial Event List Page
 * @returns 
 */
export default function SurgicalEventList() {

  const classes = useStyles();

  const hospital = useParty();

  const { contracts, loading } = useStreamQueries(SurgicalEvent, () => [{ hospital }]);
  const { contracts: contractsHistory } = useStreamQueries(SurgicalEventHistory, () => [{ hospital }]);
  const { contracts: contractsProcedure, loading: loadingProcedure } = useStreamQueries(SurgicalEventProcedure, () => [{ hospital }]);
  const { contracts: contractsSurgeons, loading: loadingSurgeons } = useStreamQueries(Surgeons, () => [{ hospital }]);

  // console.log("[SurgicalEventList]", hospital, contracts, contractsHistory);

  const { onEventClear } = useEvent();
  const { setProcedure: setProcedureContext } = useDamlProcedure();
  const { setSurgeon: setSurgeonContext } = useDamlSurgeon();

  // Component used for React-to-Print
  const componentRef = useRef();

  // const [detailContract, setDetailContract] = useState({});
  // const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [isHistory, setHistory] = useState(false);
  // Create/Edit Dialog
  const [open, setOpen] = useState(false);
  const [contract, setContract] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  // checked SEs
  const [checkedSE, setCheckedSE] = useState([]);

  // Popover when clicking Export button
  const [anchorEl, setAnchorEl] = useState(null);
  const { token } = useUserState();

  const handleCreateSE = () => {
    console.log("[SurgicalEventList] handleCreateSE");
    setContract({});
    setIsEdit(true);
    setOpen(true);
    onEventClear();
  };



  const handleEditSE = (c) => {
    console.log("[SurgicalEventList] handleEditSE");
    setContract(c);
    setIsEdit(true);
    setOpen(true);
    onEventClear();
  };

  const handlePrintClick = () => {
    if (!checkedSE || checkedSE.length === 0) return;
    handlePrint();
  };

  const handleRollOver = (c) => {
    console.log("[SurgicalEventList] handleRollOver", c);
    if (!open && c) {
      setContract(c);
      setIsEdit(false);
      setOpen(true);
    }
  };

  const getExportData = () => {
    if (!checkedSE || !checkedSE.length) return {};
    const filename = `${checkedSE[0].surgicaleventdata.seid}${(checkedSE.length > 1) ? `(+${checkedSE.length - 1})` : ''}`;
    const runningSEs = [...checkedSE];
    return { filename, runningSEs };
  };

  const getExportPrefData = async () => {
    let mergeResponse = [];
    let responses = await Promise.all(checkedSE.map(async (entry, index) => {
      const response = await damlFetch({
        token,
        template: PrefCards,
        searchParams: {
          hospital, seid: checkedSE[index].surgicaleventdata.seid
        },
      });
      return response;
    }));
    mergeResponse.push(...responses);

    const patientDetails = checkedSE.map(p => {
      const patientDetails = { firstname: p.surgicaleventdata.patientfirstname, lastname: p.surgicaleventdata.patientlastname, dob: p.surgicaleventdata.patientdob };
      return patientDetails;
    });

    if (!mergeResponse || !mergeResponse.length) return {};
    const filename = `${mergeResponse[0][0].payload.prefcardid}${(mergeResponse.length > 1) ? `(+${mergeResponse.length - 1})` : ''}`;
    const runningPrefSEs = mergeResponse;
    return { filename, runningPrefSEs, patientDetails };
  };



  const handleExportCSV = async () => {
    const { filename, runningSEs } = getExportData();
    let header, data, csvData = '';
    runningSEs.forEach(({ surgicaleventdata, surgicaleventproductdata }) => {
      ({ header, data } = convertSEDataToCSV(surgicaleventdata));
      if (csvData)
        csvData = csvData + '\n' + arrayToCsv(header, data);
      else
        csvData = arrayToCsv(header, data);
      ({ header, data } = convertSEDetailToCSV(surgicaleventproductdata));
      csvData = csvData + '\n' + arrayToCsv(header, data);
    });
    await downloadCSV(csvData, filename);
  };


  const handleExportPDF = async () => {
    const { filename, runningSEs } = getExportData();
    await handlePDFGenerator({ contracts: runningSEs, fileName: filename });
  };

  const handleExportPrefPDF = async () => {
    const { filename, runningPrefSEs, patientDetails } = await getExportPrefData();
    await handlePDFGenerator({ contracts: runningPrefSEs, fileName: filename, isPrefPdfGenerator: true, patientDetails });
  };

  const handleOpenPopover = (event) => {
    if (!checkedSE || checkedSE.length === 0) return;
    setAnchorEl(event.currentTarget);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  useEffect(() => {
    if (!loadingProcedure) setProcedureContext(contractsProcedure);
    // eslint-disable-next-line
  }, [loadingProcedure]);

  useEffect(() => {
    if (!loadingSurgeons) setSurgeonContext(contractsSurgeons);
    // eslint-disable-next-line
  }, [loadingSurgeons]);


  return (
    <>
      <Grid>

        <Grid container className={classes.newContainer} justifyContent="space-between" >
          <Grid item xs>
            <Typography variant="h4" className={classes.newPageTitle}>Surgical Event</Typography>
          </Grid>
          <Grid item>
            {/* <Button
              className={classNames(classes.newCreateButton, classes.newIconButton)}
              variant="contained"
              onClick={handlePrintClick}
              startIcon={<img alt="" src={printIcon} />}
            /> */}
            <Button
              className={classNames(classes.newCreateButton, classes.newIconButton)}
              variant="contained"
              onClick={handleOpenPopover}
              startIcon={<img alt="" src={exportIcon} />}
            />
            <Button
              className={classes.newCreateButton}
              variant="contained"
              onClick={handleCreateSE}
              startIcon={<AddIcon />}
            >
              Create Surgical Event
            </Button>

            <ExportPopover
              anchor={anchorEl}
              setAnchor={setAnchorEl}
              handleExportCSV={handleExportCSV}
              handleExportPDF={handleExportPDF}
              handleExportPrefPDF={handleExportPrefPDF}
              handlePrintClick={handlePrintClick}
              isSEscreen={true}
            />
          </Grid>
        </Grid>

        <SurgicalEventListContracts
          isIncludeHistory={isHistory}
          allContracts={contracts}
          historyContracts={contractsHistory}
          setArchived={setHistory}
          handleEditSE={handleEditSE}
          loading={loading}
          setChecked={setCheckedSE}
          handleRollOver={handleRollOver}
        />
      </Grid>

      <CreateEditSurgicalEvent
        open={open}
        setOpen={setOpen}
        contract={contract}
        isEdit={isEdit}
      />

      <SurgicalEventPrints
        ref={componentRef}
        contracts={checkedSE}
      />
    </>
  );
}
