import React, { useState , useEffect} from "react";
import {
    Grid, Button, Dialog, DialogContent, DialogTitle, Typography
} from "@material-ui/core";
import useStyles from "../surgicalevent/styles";
import closeIcon from "../../icons/new-close-icon.svg";
import { CustomSelect, CustomTextField } from "../../components/Inputs";
import { Salutations } from "../../models/Contact";

/**
 * Create User Dialog
 * @param {Boolean} open 
 * @param {Function} setOpen
 * @param {String} title 
 * @param {String} role
 * @returns 
 */
export default function EditSurgeon({
    open,
    setOpen,
    surgeonData={},
    handleUpdate,
    role,
}) {

    const classes = useStyles();
    const [dataItems, setDataItems] = useState({});
    const handleChange = (value, key) => {

        setDataItems({
            ...dataItems,
            [key]: value,
        });
        console.log(dataItems,"dataItems")
    };

    // handle when click "Submit" or "Update" button
    const handleSave = async () => {
        await handleUpdate(dataItems);
    };


useEffect(() => {
    if (open) {
      console.log("[AddEditUser] Open AddEditUser dialog.");
      setDataItems(surgeonData);
    }
  }, [open, surgeonData]);

    return (
        <Dialog
            open={open}
            onClose={() => { setOpen(false) }}
            className={classes.customRoot}
            disableEnforceFocus
            aria-labelledby="draggable-dialog-title"
            maxWidth='xs'
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >

                <Button
                    className={classes.closeButton}
                    onClick={() => { setOpen(false) }}
                >
                    <img src={closeIcon} alt="" />
                </Button>
            </Grid>
            <DialogTitle className='MuiDialogTitleRoot'>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Typography variant="inherit" component="span" >
                        Edit
                    </Typography>
                </Grid>

            </DialogTitle>
            <DialogContent
                dividers={true}
            >
                <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={4}>
                        <label className={classes.popUpLabel}>Salutation</label>
                    </Grid>
                    <Grid item xs={8}>
                        <CustomSelect
                            required={true}
                            value={dataItems.salutation || ""}
                            onChange={(val) => handleChange(val, 'salutation')}
                            items={Salutations}
                            placeholder="Select One"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <label className={classes.noteTitle}>First Name</label>
                    </Grid>
                    <Grid item xs={8}>
                        <CustomTextField
                            value={dataItems?.firstname || ""}
                            placeholder="Type here..."
                            onChange={(e) => handleChange(e, 'firstname')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <label className={classes.noteTitle}>Last Name</label>
                    </Grid>
                    <Grid item xs={8}>
                        <CustomTextField
                            id="input-with-icon-adornment5"
                            value={dataItems?.lastname || ""}
                            placeholder="Type here..."
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => handleChange(e, 'lastname')}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <label className={classes.noteTitle}>Pref Card Id</label>
                    </Grid>
                    <Grid item xs={8}>
                        <CustomTextField
                            id="input-with-icon-adornment5"
                            value={dataItems?.pref_card_id || ""}
                            placeholder="Type here..."
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => handleChange(e, 'pref_card_id')}
                            variant="outlined"
                        />
                    </Grid>

                </Grid>
                <br></br>
                <div className="right-buttons">
                    <Button autoFocus onClick={() => { setOpen(false) }} className="noBtn" variant="outlined" color="primary">
                        Cancel
                    </Button>

                    <Button onClick={handleSave} className="yesBtn" variant="contained" color="primary">
                        Save
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}