import React from "react";
import { TextField, Select, FormControl, InputLabel } from "@material-ui/core";
import { getRoles } from "../../../models/Role";
import { useStyles } from "../styles";

export default function OnboardNetworkContents({
  networkdetail,
  setConductForm,
  isTypeEditable=true,
  isOperatorEnable=false }) {

  const classes = useStyles();
  
  
  return (
    <>

    <div>
      <TextField
        label="Name"
        placeholder="User Name"
        className={ classes.dialogField }
        value={networkdetail.name || ""}
        onChange={(e) => setConductForm({ ...networkdetail, name: e.target.value })}
      />
    </div>

    <div>
      <TextField
        label="Email"
        placeholder="Email Address"
        className={ classes.dialogField }
        value={networkdetail.email || ""}
        onChange={(e) => setConductForm({ ...networkdetail, email: e.target.value })}
      />
    </div>

    <div>
      <TextField
        label="Company"
        placeholder="Company Name"
        className={ classes.dialogField }
        value={networkdetail.company || ""}
        onChange={(e) => setConductForm({ ...networkdetail, company: e.target.value })}
      />
    </div>

    <div>
      <FormControl className={classes.dialogField} disabled={!isTypeEditable}>
        <InputLabel >Company Type</InputLabel>
        <Select
          value={networkdetail.companytype}
          onChange={(e) => setConductForm({ ...networkdetail, companytype: e.target.value })}>
          {isOperatorEnable ? getRoles(null, []) : getRoles()}
        </Select>
      </FormControl>
    </div>

    </>
  );
}