// import { saveAs } from 'file-saver';

/**
 * Function to convert the JSON(Array of objects) to CSV.
 * @param {Array} headers 
 * @param {Array} data 
 * @returns {String}
 */
 export const arrayToCsv = (headers, data) => {
    const csvRows = [];
    // getting headers. 
    const headerValues = headers.map(header => header.label);
    csvRows.push(headerValues.join(',')); // Push into array as comma separated values
    // Getting rows. 
    for (const row of data) {
        const rowValues = headers.map(header => {
            const escaped = ('' + row[header.key]).replace(/"/g, '\\"'); // To replace the unwanted quotes.
            return `"${escaped}"`; // To escape the comma in a address like string.
        });
        csvRows.push(rowValues.join(',')); // Push into array as comma separated values. 
    }
    return csvRows.join('\n'); // To enter the next rows in the new line '\n' 
};

/**
 * Download from blob to file
 * @param {Object} blob 
 * @param {String} file 
 */
export const blobToDownload = (blob, file) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', file);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

/**
 * Download from blog to file by using saveAs of file-saver
 * If you want to use this funtion, please install file-saver package
 * @param {Object} blob 
 * @param {String} file 
 */
export const saveAsBlob = (blob, file) => {
    // saveAs(blob, file);
};

/**
 * Function to download the generated CSV as a .csv file.
 * @param {String} data 
 * @param {String} fileName 
 */
export const downloadCSV = (data, fileName) => {
    const blob = new Blob([data], {
        type: 'text/csv'
    });
    blobToDownload(blob, fileName + '.csv');
};

/**
 * generate and download CSV
 * @param {Array} header 
 * @param {Array} data 
 * @param {String} filename 
 */
export const generateCSV = (header, data, filename) => {
    const csvData = arrayToCsv(header, data);
    downloadCSV(csvData, filename);
};