import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import {
  AppBar, Toolbar, Typography, Menu, MenuItem, MenuList, Grow, Paper, Popper, Button, IconButton, Box,
  ClickAwayListener, Collapse, List, ListItem, ListItemText, Card, Grid
} from "@material-ui/core";
import Divider from '@mui/material/Divider';
import MenuIcon from "@material-ui/icons/Menu";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useLayoutState, useLayoutDispatch, setLightMode, setDarkMode } from "../../context/LayoutContext";
import { useUserDispatch, signOut, useUserState, loginAuth } from "../../context/UserContext";
import { useDamlState } from "../../context/DamlContext";
import { SearchTextUniField } from "../Inputs";
import { getPartyLabel } from "../../models/handles";
import logoIcon from "../../icons/newlogo.svg";
import darkModeLogo from "../../icons/darkModeLogo.svg";
import useAuthLogout from "../../hooks/useAuthLogout";
import { checkEnableRoute } from "../Routes/handle";
import { PagesTable, SAPHeader, universalSearch, searchPageRedux } from "./handle";
import useStyles from "./styles";
import { useAuth0 } from "@auth0/auth0-react";
import { switchUser } from "../../pages/user/handle";
// import SwitchOrganizationModal from "../../components/Modals/SwitchOrganizationModal";
import LockIcon from "../../icons/icons-lock.svg";
import Support from "../../icons/support.svg";
import Logout from "../../icons/logout.svg";
import RightArrow from "../../icons/RightArrow.svg";
import { darkModeKey } from "../../config";
import { useTranslation } from 'react-i18next';



/**
 * Top Header of Window
 * TODO
 * 1. Open Message List page
 * 2. Complete Notification List
 * @param {Object} history
 * @returns
 */
function Header({ history }) {
  // constant delay time to keep opened tooltip
  const TOOLTIP_DELAY = 30;

  // global
  const user = useUserState();
  const userDispatch = useUserDispatch();
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const { role, rolePayload, userParty, userlistPayload, party } = useDamlState();
  const standalone_payment = rolePayload?.hospitaldetails?.standalone_payment;
  const accesslevel = userlistPayload ? userlistPayload.accesslevel : null;
  const isDiscoverPay = (rolePayload?.hospitaldetails?.ach_info?.discover_payment);
  const uidetaills = rolePayload?.uidetaills || [];
  // console.log("[Header]", role, rolePayload, userParty, userlistPayload, party, standalone_payment, accesslevel, uidetaills);

  const authLogout = useAuthLogout({ history });

  const {
    // loginWithRedirect,
    loginWithPopup,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [selectedMenu, setSelectedMenu] = useState("");
  const [overMenu, setOverMenu] = useState(false);
  const [newOpen, setNewOpen] = useState(false);
  // Category for Universal Search
  const [category, setCategory] = useState("seid");

  const anchorRef = useRef(null);
  const menuRef = useRef(null);
  const prevOpen = useRef(open);
  const mounted = useRef(false);

  const defaultOrganization = party;
  const invitedOrgnizationList = user.organizations;
  const [headerMenus, setHeaderMenus] = useState();


  useEffect(() => {
    setLightMode(layoutDispatch)
    if (user.role === "Operator" || user.role === "Vendor")
    {
      setHeaderMenus(PagesTable);
    }
    else if (standalone_payment === undefined)
    {
      setHeaderMenus([]);
    }
    else
    {
      setHeaderMenus(standalone_payment ? SAPHeader : PagesTable);
      if(standalone_payment)
      {
        if(localStorage.getItem(darkModeKey)==="true")
        setDarkMode(layoutDispatch)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [standalone_payment, user])
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
    setNewOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);

      setAnchorElNav(null);
      setSelectedMenu("");
    }
  }

  // handle to click "My Profile"
  /* const handleProfile = (event) => {
    handleClose(event);
  }; */

  // handle to click "Settings"
  // const handleSettings = (event) => {
  //   handleClose(event);
  //   // open setting password page
  //   history.push("/app/setting-password");
  // };

  // handle to click "Admin Hospital Data", "Admin Vendor Data"
  const handleAdmin = (event) => {
    handleClose(event);
    if (role === "Hospital") {
      history.push("/app/adminhospitallist");
    } else if (role === "Vendor") {
      history.push("/app/adminvendorlist");
    }
  };

  const handleUserProfile = (event) => {
    handleClose(event);
    history.push("/app/user-profile");
  };

  // handle to click "User Management"
  // const handleManagement = (event) => {
  //   handleClose(event);
  //   history.push("/app/users-list");
  // };

  // handle to click "Audit Log"
  const handleAudit = (event) => {
    setLightMode(layoutDispatch);
    handleClose(event);
    history.push("/app/audit-report");
  };

  // handle to click "handle HospitalDetailData"
  /* const handleHospitalDetailData = (event) => {
    handleClose(event);
    history.push('/app/viewhospitaldata');
  }; */

  // handle to click "handle Vendor List"
  const handleVendorList = (event) => {
    handleClose(event);
    history.push("/app/viewvendordata");
  };

  // handle to click "handle Hospital List"
  const handleHospitalList = (event) => {
    handleClose(event);
    history.push("/app/viewhospitaldata");
  };

  // handle to click "Admin Settings"
  const handleAdminSettings = (event, sub) => {
    setLightMode(layoutDispatch);
    handleClose(event);
    history.push(`/app/admin-settings${sub ? ('-' + sub) : ''}`);
  };

  // handle to click "handle Hospital Detail"
  // const handleHospitalDetail = (event) => {
  //   handleClose(event);
  //   history.push('/app/viewhospitaldata');
  // };

  // handle to click "handle Vendor Detail"
  // const handleVendorDetail = (event) => {
  //   handleClose(event);
  //   history.push('/app/hospital-detail-data');
  // };

  // handle holiday calendar

  // handle holiday calendar
  // const handlePaymentLog = (event) => {
  //   handleClose(event);
  //   history.push('/app/viewpaymentlogs');
  // };

  // handle to click "Support Center"
  const handleSupport = (event) => {
    handleClose(event);
  };

  // handle to click "Support Center"
  const handleVendorAdminSettings = (event) => {
    setAnchorElNav(event.currentTarget);
    setNewOpen(true);
  };
  const handleVendorAdminSettingsSubmenu = (event, sub) => {
    handleClose(event);
    history.push(`/app/admin-settings${sub ? ('-' + sub) : ''}`);
  };
  // handle to logout
  const handleLogOut = async (event) => {
    // Prevents React from resetting its properties:
    event.persist();

    // console.log("[handleLogOut] Login Auth", isLoginAuth());

    if (user.isAuth0) {
      // If login using Auth0, then Auth0 logout
      await authLogout(event);
    } else {
      signOut(event, userDispatch, history);
    }

    // Only if mounted, then close
    if (mounted.current) handleClose(event);
  };

  const handleClickNavMenu = ({ event, link = null, target = null }) => {
    // console.log("[Header] handleClickNavMenu", link, target);
    if (target) {
      if (event) setAnchorElNav(event.currentTarget);
      setSelectedMenu(target);
    } else {
      // setAnchorElNav(null);  // To fix the error: The `anchorEl` prop provided to the component is invalid.
      setSelectedMenu("");
    }
    setOverMenu(true);
    if (link) history.push(link);
  };

  const handleMenuClick = (e, p, i) => {
    handleClickNavMenu({ ...p, event: e, target: `submenu-${i}` });
  };

  const handleMenuOver = (e, p, i) => {
    if (p.menus && p.menus.length) {
      handleClickNavMenu({ ...p, event: e, target: `submenu-${i}` });
    }
  };

  const handleMenuClose = () => {
    handleClickNavMenu({});
    setOverMenu(false);
  };

  const handleMenuLeave = () => {
    setOverMenu(false);
    setTimeout(checkMouseOver, TOOLTIP_DELAY);
  };

  const checkMouseOver = () => {
    // console.log("[Header] checkMouseOver", menuRef.current);
    if (!!selectedMenu && !menuRef.current) handleClickNavMenu({});
  };

  const handleChangeSearch = (val) => {
    val = val.match(/[a-zA-Z0-9-]+/g);
    val = val ? val.join("") : "";
    // val = val.toUpperCase();
    setSearchValue(val);
  };

  // handle on Universal Search
  const handleSubmitSearch = () => {
    // console.log("[Header] handleSubmitSearch", searchValue, category);
    if (!searchValue) return;
    const opt = { category };
    if (role === "Hospital") {
      opt.po_prefixes = [rolePayload.admindetails.purchaseorderid_prefix];
    }
    const label = universalSearch(searchValue, opt);
    // console.log("[Header] handleSubmitSearch", label);

    // redirect to target page & update searchFilters redux
    let link = null;
    if (label) {
      link = searchPageRedux(label, searchValue, role, accesslevel, category, uidetaills);
      // console.log("[Header] handleSubmitSearch", link);
      if (link) {
        setSearchValue("");
        history.push(link);
      }
    }
    // display error message saying <Value is Not Found>
    if (!link) {
      //
    }
  };

  const onSelectHospital = async (selectedInput) => {
    // swith user by using Auth0
    const res = await switchUser({
      email: userlistPayload.email,
      targetOrg: selectedInput,
      currentOrg: defaultOrganization,
    });

    if (res === true) {
      // login again to get new token from Auth0
      await loginAuth({
        // loginWithRedirect: () => {},
        loginWithRedirect: loginWithPopup,
        loginWithPopup,
        getAccessTokenSilently,
        getIdTokenClaims,
        userDispatch,
        history,
        switchOrganization: true,
      });
    }
  };

  // const DarkerDisabledMenuItem = withStyles({
  //   root: {

  //     color: 'black !important;'
  //   }
  // })(MenuItem);


  // return focus to the button when we transitioned from !open -> open
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    menuRef.current = overMenu;
  }, [overMenu]);


  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);
  const { t } = useTranslation();
  const handleMouseOver = (event) => {
    setAnchorElNav(event.currentTarget);
    setNewOpen(true);
  };

  const handleMouseOut = (event) => {
    setNewOpen(false);
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar} style={{ backgroundColor: layoutState.darkMode ? "#1C3C74" : "" }}>
        <Typography variant="h6" weight="medium" className={classes.logotype}>
          <img src={layoutState.darkMode ? darkModeLogo : logoIcon} alt="Logo" />
        </Typography>

        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="medium"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={(e) =>
              handleClickNavMenu({ event: e, target: "mobile-menus" })
            }
            // color="inherit"
            className={classes.headerMenuButton}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorElNav}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={selectedMenu.includes("mobile-menus")}
            onClose={handleMenuClose}
            sx={{
              display: { xs: "block", md: "none" },
            }}
            className={classes.mobileMenu}
          // MenuListProps={{ onMouseLeave: handleMenuClose }}
          >
            {headerMenus?.filter(
              (p) => !p.link || checkEnableRoute(p.link, role, accesslevel, uidetaills)
            ).reduce((s, p, i) => {
              const menu = (
                <MenuItem
                  key={`menu-${i}`}
                  onClick={() => standalone_payment ? p.handleClick(history, () => setLightMode(layoutDispatch)) :
                    handleClickNavMenu({
                      ...p,
                      target:
                        p.menus && p.menus.length ? `mobile-menus-${i}` : "",
                    })
                  }
                >
                  <Typography textalign="center">{p.label}</Typography>
                  {p.menus && p.menus.length ? (
                    selectedMenu === `mobile-menus-${i}` ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <ChevronRightIcon />
                    )
                  ) : null}
                </MenuItem>
              );
              const subMenus = p.menus && p.menus.length && (
                <Collapse
                  key={`collapse-${i}`}
                  in={selectedMenu === `mobile-menus-${i}`}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {p.menus
                      .filter((m) =>
                        checkEnableRoute(m.link, role, accesslevel, uidetaills)
                      )
                      .map((m, j) => (
                        <MenuItem key={j} onClick={() => handleClickNavMenu(m)}>
                          {m.label}
                        </MenuItem>
                      ))}
                  </List>
                </Collapse>
              );
              return s.concat([menu, subMenus]);
            }, [])}
          </Menu>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {headerMenus?.filter(
            (p) => !p.link || checkEnableRoute(p.link, role, accesslevel, uidetaills)
          ).map((p, i) => (
            <React.Fragment key={i}>
              <Button
                style={{ color: layoutState.darkMode ? "#FFFFFF" : "" }}
                onClick={(e) => standalone_payment ? p.handleClick(history) : handleMenuClick(e, p, i)}
                onMouseOver={(e) => handleMenuOver(e, p, i)}
                onMouseLeave={handleMenuLeave}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {p.label}
              </Button>
              {p.menus && p.menus.length ? (
                <Popper
                  open={selectedMenu === `submenu-${i}`}
                  anchorEl={anchorElNav}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleMenuClose}>
                          <MenuList
                            className={classNames(classes.profilemenu, classes.newheadermenu)}
                            autoFocusItem={open}
                            onKeyDown={handleListKeyDown}
                            onMouseLeave={handleMenuClose}
                            onMouseEnter={() => setOverMenu(true)}
                          >
                            {p.menus
                              .filter((m) =>
                                checkEnableRoute(m.link, role, accesslevel, uidetaills)
                              )
                              .filter((m) => m.link === '/app/sentpayments' ? isDiscoverPay : true)
                              .map((m, j) =>
                                <MenuItem
                                  key={j}
                                  onClick={() => handleClickNavMenu(m)}>
                                  {m.label}
                                </MenuItem>
                              )
                            }
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              ) : null}
            </React.Fragment>
          ))}
        </Box>

        <div className={classes.grow} />

        <SearchTextUniField
          value={searchValue}
          className={classes.search}
          onChange={handleChangeSearch}
          onSubmit={handleSubmitSearch}
          category={category}
          setCategory={setCategory}
        />

        <Typography variant="h6" weight="medium">
          {/* <Button
            className="notiBtn"
            onClick={() => {
              // TODO
              // open message page
              // history.push('/app/message/inbox');
            }}
          >
            <MailOutlineIcon />
            <Badge badgeContent={0} color="error" overlap="rectangular"></Badge>
          </Button> */}

          {/* <Button
            className="notiBtn"
            ref={anchorRefNot}
            aria-controls={openNot ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggleNot}
          >
            <NotificationsNoneIcon />
            <Badge badgeContent={0} color="error" overlap="rectangular"></Badge>
          </Button> */}

          {/* <Popper
            className={classes.notifications}
            open={openNot}
            anchorEl={anchorRefNot.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleCloseNot}>
                    <MenuList
                      className={classes.notificationList}
                      autoFocusItem={openNot}
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem onClick={handleCloseNot}>
                        <p>Adobe XD to the Material-UI design assets family. 600+ Material-UI symbols, handcrafted in Adobe XD, ready to help turn your imagination into reality!</p>
                      </MenuItem>
                      <MenuItem onClick={handleCloseNot}>
                        <p>Adobe XD to the Material-UI design assets family. 600+ Material-UI symbols, handcrafted in Adobe XD, ready to help turn your imagination into reality!</p>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper> */}

          <Button
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <div className="avtar">
              {userParty !== null ? getPartyLabel(userParty) : ""}
            </div>
          </Button>

          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      className={classNames(layoutState.darkMode ? classes.profilemenuDM:classes.profilemenu)}
                      autoFocusItem={open}
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem onClick={handleUserProfile}>My Profile</MenuItem>
                      {/* <MenuItem className={classes.profileUser}>{userlistPayload.name}</MenuItem>
                      <MenuItem className={classes.profileEmailId}>{userlistPayload.email}</MenuItem> */}

                      {/* {invitedOrgnizationList &&
                        invitedOrgnizationList
                          .slice(0, 10)
                          .map((list, index) => {
                            return (
                              <MenuItem
                                onClick={() => {
                                  onSelectHospital(list);
                                }}

                                value={list}
                                key={index}
                                className={`${list === user?.current_organization
                                  ? "currentOrganizationActive"
                                  : ""
                                  }`}
                              >
                                <div
                                  className={`avtarSwitchOrg ${list ===
                                    userlistPayload?.current_organization
                                    ? "currentOrganizationActive"
                                    : ""
                                    }`}
                                >
                                  {list !== null ? getPartyLabel(list) : ""}{" "}
                                </div>
                                {list}
                              </MenuItem>
                            );
                          })}
                      {invitedOrgnizationList &&
                        invitedOrgnizationList.length > 1 && (
                          <SwitchOrganizationModal
                            modaltitle="Please choose a Facility"
                            buttontitle="See More"
                            organizationList={invitedOrgnizationList}
                            onChangeHandler={(selectedOrg) =>
                              onSelectHospital(selectedOrg)
                            }
                            user={user}
                          />
                        )} */}

                      {/* <MenuItem onClick={handleProfile}>My Profile</MenuItem> */}


                      {/* <MenuItem onClick={handlePaymentLog}>Payment Log</MenuItem> */}
                      {/*
                        (accesslevel === 'VManager') &&
                        <MenuItem
                          onClick={handleManagement}
                          onMouseOver={handleMouseOut}
                        >
                          User Management
                        </MenuItem>
                        */
                      }

                      {

                        (role === 'Vendor')
                          ?
                          <MenuItem onClick={handleVendorAdminSettings}>Admin Settings</MenuItem>
                          :
                          (
                            role === 'Hospital' && (accesslevel === 'HFinanceAssociate' || accesslevel === 'HManager')
                              ?
                              <MenuItem onClick={handleMouseOver}>Admin Settings</MenuItem>
                              :
                              <MenuItem disabled={true} onClick={handleMouseOver}>Admin Settings  <img alt="" src={LockIcon} /></MenuItem>
                          )

                      }

                      {
                        (accesslevel === 'HFinanceAssociate' || accesslevel === 'HManager' || role === 'Vendor') ?
                          <MenuItem onClick={handleAudit}
                            onMouseOver={handleMouseOut}
                          >Audit Log </MenuItem>
                          : <MenuItem disabled={true} onClick={handleAudit}
                            onMouseOver={handleMouseOut}
                          >Audit Log <img alt="" src={LockIcon} /></MenuItem>



                      }

                      {
                        role === 'Hospital' && (accesslevel === 'HFinanceAssociate' || accesslevel === 'HManager') && !standalone_payment
                          ?
                          <MenuItem onClick={handleAdmin}>Admin Hospital Data</MenuItem>
                          :
                          (
                            role === 'Vendor'
                              ?
                              <MenuItem onClick={handleAdmin}>Admin Vendor Data</MenuItem>
                              :
                              null
                          )
                      }
                      {/* {
                        role === 'Hospital'
                        ?
                        <MenuItem onClick={handleHospitalDetailData}>View Hospital Data</MenuItem>
                        :
                        (
                          role === 'Vendor'
                          ?
                          <MenuItem onClick={handleVendorList}>View Vendor Data</MenuItem>
                          :
                          null
                        )
                      } */}
                      {
                        role === 'Operator' && <MenuItem onClick={handleVendorList}>Vendor List</MenuItem>
                      }
                      {
                        role === 'Operator' && <MenuItem onClick={handleHospitalList}>Hospital List</MenuItem>
                      }
                      {/* commented for now as per figma design*/}
                      {/* <MenuItem onClick={handleSettings}>Account Settings</MenuItem> */}
                      <Divider />
                      <p className="plainText">Facility</p>
                      {invitedOrgnizationList &&
                        invitedOrgnizationList
                          .slice(0, 10)
                          .map((list, index) => {
                            return (
                              <MenuItem
                                onClick={() => {
                                  onSelectHospital(list);
                                }}

                                value={list}
                                key={index}
                                className={`${list === user?.current_organization
                                  ? (layoutState.darkMode ?"currentOrganizationActiveDM":"currentOrganizationActive")
                                  : ""
                                  }`}
                              >
                                <div
                                  className={`avtarSwitchOrg ${list ===
                                    userlistPayload?.current_organization
                                    ? (layoutState.darkMode ?"currentOrganizationActiveDM":"currentOrganizationActive")
                                    : ""
                                    }`}
                                >
                                  {list !== null ? getPartyLabel(list) : ""}{" "}
                                </div>
                                {list}
                                {list === user?.current_organization ? <img style={{ marginLeft: "15px" }} alt="" src={RightArrow} /> : ""}
                              </MenuItem>

                            );
                          })}
                      {/* {invitedOrgnizationList &&
                        invitedOrgnizationList.length > 1 && (
                          <SwitchOrganizationModal
                            modaltitle="Please choose a Facility"
                            buttontitle="See More"
                            organizationList={invitedOrgnizationList}
                            onChangeHandler={(selectedOrg) =>
                              onSelectHospital(selectedOrg)
                            }
                            user={user}
                          />
                        )} */}
                      <Divider />
                      <Grid container>
                        <Grid item xs={6}>
                          <MenuItem onClick={handleSupport} className={classes.menuIcon}><img alt="" src={Support} />Support</MenuItem>
                        </Grid>
                        <Grid item xs={6}>
                          <MenuItem onClick={handleLogOut} className={classes.menuIcon}><img alt="" src={Logout} />Logout</MenuItem>
                        </Grid>
                      </Grid>
                    </MenuList>
                  </ClickAwayListener>

                  {
                    (role === 'Hospital' || role === 'Vendor') &&
                    <Popper
                      open={newOpen}
                      anchorEl={anchorRef.current}
                      // role={undefined}
                      transition
                      // style={{ willChange: 'transform', transform: 'translate3d(1095px, 142px, 0px) !important' }}
                      placement={placement}
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transform: 'translate3d(-158px, 27px, 10px)'
                          }}
                        >
                          <Card className={classes.profilemenu}>
                            {
                              role === 'Hospital' &&
                              <List disablePadding={true}>
                                <ListItem
                                  className={classNames(layoutState.darkMode ? classes.profilemenuDM:classes.profilemenu, classes.menuText)}
                                  onClick={(e) => handleAdminSettings(e, 'hospital-profile')}
                                >
                                  <ListItemText className={classNames(classes.menuText)} >
                                  {t('admin.settings.tabs.hospital')}
                                  </ListItemText>
                                </ListItem>
                                {
                                  standalone_payment ? null :
                                    <>
                                      <ListItem
                                        className={classNames(classes.profilemenu)}
                                        onClick={(e) => handleAdminSettings(e, 'facility-settings')}
                                      >
                                        <ListItemText className={classNames(classes.menuText)} >
                                          Facility Settings
                                        </ListItemText>
                                      </ListItem>
                                      <ListItem
                                        className={classNames(classes.profilemenu)}
                                        onClick={(e) => handleAdminSettings(e, 'vendor-management')}
                                      >
                                        <ListItemText className={classNames(classes.menuText)} >
                                          Vendor Contact Upload
                                        </ListItemText>
                                      </ListItem>
                                      <ListItem
                                        className={classNames(classes.profilemenu)}
                                        onClick={(e) => handleAdminSettings(e, 'vendor-integration')}
                                      >
                                        <ListItemText className={classNames(classes.menuText)} >
                                          Vendor Integration
                                        </ListItemText>
                                      </ListItem>
                                    </>
                                }
                                <ListItem
                                  className={classNames(layoutState.darkMode ? classes.profilemenuDM:classes.profilemenu)}
                                  onClick={(e) => handleAdminSettings(e, "user-management")}
                                // onMouseOver={handleMouseOut}
                                >
                                  <ListItemText className={classNames(classes.menuText)} >
                                    User Management
                                  </ListItemText>
                                </ListItem>
                              </List>
                            }
                            {
                              role === 'Vendor' &&
                              <List disablePadding={true}>
                                <ListItem
                                  className={classNames(classes.profilemenu, classes.menuText)}
                                  onClick={(e) => handleVendorAdminSettingsSubmenu(e, "vendor-profile")}
                                >
                                  <ListItemText className={classNames(classes.menuText)} >
                                    Vendor Profile
                                  </ListItemText>
                                </ListItem>
                                <ListItem
                                  className={classNames(classes.profilemenu)}
                                  onClick={(e) => handleVendorAdminSettingsSubmenu(e, "vendor-settings")}
                                >
                                  <ListItemText className={classNames(classes.menuText)} >
                                    Vendor Settings
                                  </ListItemText>
                                </ListItem>
                                <ListItem
                                  className={classNames(classes.profilemenu)}
                                  onClick={(e) => handleVendorAdminSettingsSubmenu(e, "hospital-integration")}
                                >
                                  <ListItemText className={classNames(classes.menuText)} >
                                    Hospital Integration
                                  </ListItemText>
                                </ListItem>
                                <ListItem
                                  className={classNames(classes.profilemenu)}
                                  onClick={(e) => handleVendorAdminSettingsSubmenu(e, "vendor-user-management")}
                                >
                                  <ListItemText className={classNames(classes.menuText)} >
                                    User Management
                                  </ListItemText>
                                </ListItem>
                              </List>
                            }
                          </Card>
                        </Grow>)}
                    </Popper>
                  }
                </Paper>
              </Grow>
            )}
          </Popper>
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

export default withRouter(Header);