import React, { useState } from "react";
import { TextField, FormControl, InputLabel, Select, IconButton, Typography, Grid } from "@material-ui/core";
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";
import { AddressModel, validateAddress } from "../../../models/Address";
import { getStates } from "../../../models/State";
import { resetConductForm, increaseIndexOfList, decreaseIndexOfList, addConduct } from "../handles";
import MultipleButton from "../MultipleButton";
import { useStyles } from "../styles";


export default function AddressDetailContents({
  addressdetail = {},
  setConductForm = null,
  isMultiple = false,
  multipleButtontitle = "Add additional Address",
  addressList = [],
  setAddressList = null,
  defaultAddress = {},
  isShippingaddress = false }) {

  const classes = useStyles();

  const [index, setIndex] = useState(0);

  // multiple address list
  if (isMultiple) {
    if (index < addressList.length) addressdetail = addressList[index];

    setConductForm = (newAddress) => resetConductForm(newAddress, addressList, setAddressList, index);
  }

  const increaseIndex = () => increaseIndexOfList(index, setIndex, addressList);

  const decreaseIndex = () => decreaseIndexOfList(index, setIndex, addressList);

  // add new address detail to address list
  const addAddress = (data) => addConduct(data, addressList, setAddressList, {...AddressModel, ...defaultAddress}, increaseIndex);


  // console.log("[AddressDetailContents]", addressdetail, addressList);

  return (
    <>

    {
      isMultiple && 
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.topBar}
      >
        <Grid item>
          <MultipleButton 
            multipleButtontitle={multipleButtontitle}
            conductdetail={addressdetail}
            setConductForm={setConductForm}
            conductList={addressList}
            setConductList={setAddressList}
            defaultConduct={{ ...AddressModel, ...defaultAddress }}
            addConduct={addAddress}
            validateConduct={validateAddress}
          />
        </Grid>

        <Grid item>
          <Typography variant="inherit" className={classes.contactPagination}>
            [ {index+1} / {addressList.length} ]
          </Typography>
          <IconButton
            color="primary"
            onClick={decreaseIndex}
          >
            <KeyboardArrowUp />
          </IconButton>
          <IconButton
            color="primary"
            onClick={increaseIndex}
          >
            <KeyboardArrowDown />
          </IconButton>
        </Grid>
      </Grid>
    }

    <div>
      <TextField
        label="Address 1"
        placeholder="Address 1"
        className={ classes.dialogField }
        value={addressdetail.address1}
        onChange={(e) => setConductForm({ ...addressdetail, address1: e.target.value })}
      />
    </div>
    <div>
      <TextField
        label="Address 2"
        placeholder="Address 2"
        className={ classes.dialogField }
        value={addressdetail.address2}
        onChange={(e) => setConductForm({ ...addressdetail, address2: e.target.value })}
      />
    </div>
    <div>
      <TextField
        label="City"
        placeholder="City"
        className={ classes.dialogField }
        value={addressdetail.city}
        onChange={(e) => setConductForm({ ...addressdetail, city: e.target.value })}
      />
    </div>
    <FormControl className={classes.dialogField}>
      <InputLabel >State</InputLabel>
      <Select
        label="State"
        autoWidth={true}
        value={addressdetail.state}
        onChange={(e) => setConductForm({ ...addressdetail, state: e.target.value })}
      >
        {getStates()}
      </Select>
    </FormControl>
    <div>
      <TextField
        label="Zipcode"
        placeholder="Zipcode"
        className={ classes.dialogField }
        value={addressdetail.zipcode}
        onChange={(e) => setConductForm({ ...addressdetail, zipcode: e.target.value })}
      />
    </div>
    <div>
      <TextField
        label="Country"
        placeholder="Country"
        className={ classes.dialogField }
        value={addressdetail.country}
        onChange={(e) => setConductForm({ ...addressdetail, country: e.target.value })}
      />
    </div>
    {
      isShippingaddress
      ?
        <div>
          <TextField
            label="Location"
            placeholder="Location"
            className={ classes.dialogField }
            value={addressdetail.location || ""}
            onChange={(e) => setConductForm({ ...addressdetail, location: e.target.value })}
          />
        </div>
      :
        <></>
    }

    </>
  );
}