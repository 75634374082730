import React, { useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import Contracts from "../../components/Contracts/Contracts";
import { useParty, useLedger, useStreamQueries } from "@daml/react";
import { Typography, CircularProgress } from '@material-ui/core';
import { Edit, Add } from "@material-ui/icons";
import { HospitalInvitation, /* HospitalRole */ } from "../../services/daml-modules1";
import { useDamlState } from "../../context/DamlContext";
import { HospitalDetailModel, validateHospitalDetail, getHospitalDetail } from '../../models/HospitalDetail';
import { AdminDetailModel, validateAdminDetail, getAdminDetail } from '../../models/AdminDetail';
import AlertModal from "../../components/Modals/AlertModal";
import MultiTabsModals from "../../components/Modals/MultiTabsModals";
import AdminDetailModal from "../../components/Modals/AdminDetailModal";
import useStyles from "../surgicalevent/styles";


/**
 * Hospital Invite Page
 * @returns 
 */
export default function HospitalInvite() {

  const history = useHistory();
  const classes = useStyles();
  const hospital = useParty();
  const ledger = useLedger();
  const assets = useStreamQueries(HospitalInvitation, () => [{ party: hospital }]);
  // const assetsHospitalRole = useStreamQueries(HospitalRole, () => [{ hospital }]);
  const { roleCid } = useDamlState();

  console.log("[HospitalInvite]", assets.contracts);


  const [conductModalOpen, setConductModalOpen] = useState(false);
  const [curContractId, setContractId] = useState('');
  const [curContract, setContract] = useState({});
  const [detailChange, setDetailChange] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openAdminModal, setOpenAdminModal] = useState(false);
  const [twodetails, setConductForm] = useState({ ...HospitalDetailModel });
  const [twoAdmindetails, setAdminConductForm] = useState({ ...AdminDetailModel });

  const checkTwoDetailCompleted = useCallback(() => {
    return validateHospitalDetail(twodetails) && validateAdminDetail(twoAdmindetails);
  }, [twodetails, twoAdmindetails]);


  const handleConductModalClose = () => {
    setConductModalOpen(false);
  };

  const handleConductChange = (idx, data) => {
    setDetailChange(true);
    if (idx === "hospitaldetails") {
      setConductForm({
        ...twodetails,
        ...data
      });
    }
    else if (idx === "admindetails") {
      setAdminConductForm({
        ...twoAdmindetails,
        ...data
      });
    }
  };



  const exerciseHospitalAccept = useCallback(() => {

    setConductModalOpen(false);

    console.log("hospital : " + hospital);
    console.log("cid: " + curContractId);

    const hospitaldetails = getHospitalDetail(twodetails);
    const admindetails = getAdminDetail(twoAdmindetails);
    console.log("twodetails: ", twodetails, JSON.stringify(hospitaldetails));

    if (!checkTwoDetailCompleted()) {
      console.log("You must input all fields.");
      return;
    }

    const successResponseHandler = (res) => {
      console.log(res);
    };

    ledger.exercise(HospitalInvitation.AcceptHospitalInvitation, curContractId, {
      // hospital, 
      hospitaldetails,
      admindetails,
    })
      .then((res) => {
        successResponseHandler(res);
      })
      .catch(events => {
        if (events.status === 200) {
          successResponseHandler(events);
          return;
        }

        let message = '';

        console.log(events.errors);

        if (events.errors.length) events.errors.forEach(msg => {
          console.log(msg)
          message += msg;
          message += '\n';
        });
        alert("Something went wrong!\n\nMessage: " + message);

        return false;
      });

    // eslint-disable-next-line
  }, [curContractId, ledger, twodetails, hospital, checkTwoDetailCompleted]);


  useEffect(() => {
    if (detailChange === true) {
      exerciseHospitalAccept();
    }
    setDetailChange(false);
  }, [twodetails, detailChange, exerciseHospitalAccept]);


  return (
    !assets.contracts || !assets.contracts.length
      ?
      <div>
        <CircularProgress />
        <Typography variant="h4">Please wait until accept your AccountRequested.</Typography>
      </div>
      :
      (
        roleCid
          ?
          <Contracts
            contracts={assets.contracts}

            columns={[
              ["ContractId", "contractId"],
              ["Operator", "payload.operator"],
              ["Party", "payload.party"],
              ["Actions", "actions-created", {
                type: "link",
                options: {
                  cellClassName: classNames(classes.primaryColor),
                },
                onClick: () => history.push('/app/viewhospitaldata'),
                defaultValue: "Created",
              }],
            ]}
            options={{
              // statusEmpty: (allContracts.length === 0)
            }}
          />
          :
          <>
            <Contracts
              contracts={assets.contracts}

              columns={[
                ["ContractId", "contractId"],
                ["Operator", "payload.operator"],
                ["Party", "payload.party"],
              ]}

              actions={[
                [
                  "Actions",
                  [
                    [["Hospital Detail", <Edit />], (c) => {
                      setOpenModal(true);
                      setContract(c);
                      setContractId(c.contractId);
                    }],
                    [["Admin Detail", <Add />], (c) => {
                      setOpenAdminModal(true);
                      setContract(c);
                      setContractId(c.contractId);
                    }],
                  ]
                ]
              ]}
              options={{
                // statusEmpty: (allContracts.length === 0)
              }}
            />

            <MultiTabsModals
              modaltitle={"Edit Hospital Detail"}
              modals={[
                ["customdialog", "Name of Facility", "payload.hospitaldetails.facilityname", "facilityname"],
                ["addressdialog", "Physical Address", "payload.hospitaldetails.physicaladdress", "physicaladdress"],
                ["addressdialog", "Shipping Address", "payload.hospitaldetails.shippingaddress", "shippingaddress", "list", "shippingaddress"],
                ["addressdialog", "Billing Address", "payload.hospitaldetails.billingaddress", "billingaddress"],
                ["contactdialog", "Point(s) of Contact", "payload.hospitaldetails.point_contact", "point_contact", "list"],
                ["achinfodialog", "ACH Information", "payload.hospitaldetails.ach_info", "ach_info"],
                ["contactdialog", "Billing Information Contact", "payload.hospitaldetails.billinfocontact", "billinfocontact", "list"],
                ["contactdialog", "Purchase Information Contact", "payload.hospitaldetails.purchinfocontact", "purchinfocontact", "list"],
                ["customdialog", "TAX ID", "payload.hospitaldetails.taxid", "taxid"],
              ]}
              setDetailValue={(data) => { setOpenModal(false); handleConductChange("hospitaldetails", data); }}
              isButtonVisible={false}
              isDefaultVisible={openModal}
              defaultDetails={curContract}
              cancelHandler={() => setOpenModal(false)}
            />

            <AdminDetailModal
              modaltitle={"Edit Admin Detail"}
              setDetailValue={(data) => { setOpenAdminModal(false); handleConductChange("admindetails", data); }}
              isButtonVisible={false}
              isDefaultVisible={openAdminModal}
              defaultDetails={curContract}
              cancelHandler={() => setOpenAdminModal(false)}
              adminDetailPath={"payload.admindetails"}
            />

            <AlertModal
              modaltitle="Health Clinic Registration"
              modalText="Do you want to register this hospital?"
              setDetailValue={exerciseHospitalAccept}
              modalOpenController={conductModalOpen}
              cancelHandler={handleConductModalClose}
              isButtonVisible={false}
              okButtontitle="Register"
            />

          </>
      )

  );
}