import { makeStyles } from "@material-ui/styles";
import calendarIcon from "../../icons/search-calendar-icon.svg";

export default makeStyles(theme => ({
  divider: {
    height: 20,
    marginLeft: 10,
  },
  searchLabel: {
    marginRight: 10,
  },
  searchInput: {
    width: 300,
    "& div.MuiFormControl-root.MuiFormControl-marginNormal": {
      margin: 0,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':{
      borderColor:'#183C73'
    },
  },
  searchPaper: {
    width: 340,
    marginTop: '10px !important',
    marginLeft: '-20px !important',
    '& .MuiAutocomplete-listbox li:hover':{
      background:'#f00'
    }
  },
  '& .MuiAutocomplete-popper':{
    width: 200,
  },
  newSearchInput: {
    position: 'absolute',
    width: 135,
    left: 23,
    '& .MuiAutocomplete-inputRoot': {
      padding: '1px 21px 1px 1px !important',
    },
    '& .MuiAutocomplete-endAdornment': {
      right: '1px !important',
    },
    '& .MuiInputBase-root': {
      backgroundColor: '#FFFFFF !important',
    },
    '& .MuiAutocomplete-input': {
      padding: '5px 2px !important',
    },
    '& .MuiInputBase-input': {
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '17px',
      color: '#000000',
    },
    '& .MuiAutocomplete-clearIndicator': {
      marginRight: '-12px',
    },
  },
  newSearchPopper: {
    '& .MuiAutocomplete-listbox': {
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '17px',
      maxHeight: 88,
      padding: '0px !important',
      '& :hover': {
        color: '#FFFFFF',
        backgroundColor: '#1C3C74 !important',
      },
      '& li': {
        padding: '1px 8px !important',
      },
    },
  },
  dialogField: {
    width: 200,
  },
  dialogFieldMd: {
    width: 250,
  },
  clearButton: {
    width: 'auto',
  },
  selectList: {
    width: '100%',
    padding: 0,
    '& .MuiListItem-button': {
      padding: '3px 10px',
    }
  },
  resetCalendar:{
    position:'absolute',
    right: '10px',
    bottom: '25px',
    cursor: 'pointer'
  },
  setInput:{
    borderBottom:'2px solid #4A4A4A',
    '& .MuiInput-underline:before':{
      borderBottom:0
    }
  },
  dateInputBox:{
    width:'88%',
    '& :hover .MuiInput-underline:after':{
      borderBottom:0
    },
    '& .MuiInput-underline:before,.MuiInput-underline:hover':{
      borderBottom:0
    }
  },
  datepicker:{
    '& .MuiPickersDay-daySelected':{
      background: '#2196F3', // old #183C73
    } 
  },
  dateSearch: {
    '& .MuiInputBase-input': {
      height: '24px',
      fontSize: '12px !important',
      lineHeight: '14px !important',
      padding: '6px 0px',
      backgroundColor: '#DFDFDF',
      border: '1px solid #DFDFDF',
      boxSizing: 'border-box',
      borderRadius: '5px',
      color: '#000000 !important',
    },
    '& .MuiInputBase-root': {
      height: '24px !important',
      borderWidth: '0px !important',
    },
    '& .MuiFormLabel-root': {
      lineHeight: '17px !important',
      color: '#DFDFDF !important',
    },
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      backgroundImage: `url(${calendarIcon}) !important`,
    },
  },
  /* dateSearchLabel: {
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '17px',
    color: '#F7CD37',
    '&.Mui-focused': {
      color: '#F7CD37',
    }
  },
  dateSearchInput: {
    marginTop: '15px !important',
    '& .MuiInputBase-input': {
      position: 'relative',
      width: '92px',
      height: '24px',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
      padding: '6px 2px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      backgroundColor: '#F7CD37',
      border: '1px solid #CCCCCC',
      boxSizing: 'border-box',
      borderRadius: '5px',
      color: '#1C3C74',
    },
  }, */
  searchCheckbox: {
    margin: 0,
    display: 'block',
    '& .MuiFormControlLabel-label': {
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '17px',
      color: '#FFFFFF',
    },
    '& .MuiCheckbox-root': {
      padding: '0px 3px !important',
      color: '#00000057',
      '&.Mui-checked': {
        color: '#E1DDDD !important',
      },
      '& .MuiSvgIcon-root': {
        width: 15,
        height: 15,
      }
    },
  },
}));
