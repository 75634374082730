import { HospitalRole } from "../../services/daml-modules1";
import { getTemplateListFromResponse } from "../../services/util";
import { validateContractDetailData, getContractDetailData, validateServiceTermData, getServiceTermData, 
  validatePaymentRebateData, getPaymentRebateData, validateSpendRebateData, getSpendRebateData, validateVolumeRebateData, 
  getVolumeRebateData, validateContractTotalData, getContractTotalData, validateCodeTotalData, 
  getCodeTotalData } from "../../models/ContractManagement";
import { getArray } from "../../models/handles";


/**
 * Create ContractManagement by Hospital
 * exercise HCreateContractManagement choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} roleCid
 * @param {String} vendor 
 * @param {Object} detail 
 * @param {Array} serviceterm 
 * @param {Array} pmtrebate 
 * @param {Array} spendrebate 
 * @param {Array} volumerebate 
 */
export const createContractManagement = async ({
  ledger, roleCid, vendor, detail, serviceterm, pmtrebate, spendrebate, volumerebate, 
}) => {
  // console.log("[createContractManagement]", roleCid, vendor, detail, serviceterm, pmtrebate, spendrebate, volumerebate);

  try {

    if (roleCid && validateContractDetailData(detail)) {
      let res = await ledger.exercise(HospitalRole.HCreateContractManagement, roleCid, {
        vendor,
        detail: getContractDetailData(detail),
        serviceterm: getArray(serviceterm, validateServiceTermData, getServiceTermData),
        pmtrebate: getArray(pmtrebate, validatePaymentRebateData, getPaymentRebateData),
        spendrebate: getArray(spendrebate, validateSpendRebateData, getSpendRebateData),
        volumerebate: getArray(volumerebate, validateVolumeRebateData, getVolumeRebateData),
      });
      console.log("[createContractManagement]", res);

      let templatePO = getTemplateListFromResponse(res, "ContractDetail");
      if (templatePO.length) {
        return templatePO[0];
      }
    }

  }
  catch (e) {
    console.warn("[createContractManagement]", e);
  }

  return false;
};

/**
 * Update ContractManagement by Hospital
 * exercise HUpdateContractManagement choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} roleCid
 * @param {String} contractCid 
 * @param {Object} detail 
 * @param {Array} serviceterm 
 * @param {Array} pmtrebate 
 * @param {Array} spendrebate 
 * @param {Array} volumerebate
 */
export const updateContractManagement = async ({
  ledger, roleCid, contractCid, detail, serviceterm, pmtrebate, spendrebate, volumerebate, 
}) => {
  // console.log("[updateContractManagement]", roleCid, contractCid, detail, serviceterm, pmtrebate, spendrebate, volumerebate);

  try {

    if (roleCid && contractCid && validateContractDetailData(detail)) {
      let res = await ledger.exercise(HospitalRole.HUpdateContractManagement, roleCid, {
        contractCid,
        newdetail: getContractDetailData(detail),
        newserviceterm: getArray(serviceterm, validateServiceTermData, getServiceTermData),
        newpmtrebate: getArray(pmtrebate, validatePaymentRebateData, getPaymentRebateData),
        newspendrebate: getArray(spendrebate, validateSpendRebateData, getSpendRebateData),
        newvolumerebate: getArray(volumerebate, validateVolumeRebateData, getVolumeRebateData),
      });
      console.log("[updateContractManagement]", res);

      let templatePO = getTemplateListFromResponse(res, "ContractDetail");
      if (templatePO.length) {
        return templatePO[0];
      }
    }

  }
  catch (e) {
    console.warn("[updateContractManagement]", e);
  }

  return false;
};

/**
 * Remove ContractManagement by Hospital
 * exercise HArchiveContractManagement choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} roleCid
 * @param {String} contractCid
 */
export const removeContractManagement = async ({
  ledger, roleCid, contractCid, 
}) => {

  try {

    if (roleCid && contractCid) {
      let res = await ledger.exercise(HospitalRole.HArchiveContractManagement, roleCid, {
        contractCid,
      });
      console.log("[removeContractManagement]", res);

      return true;
    }

  }
  catch (e) {
    console.warn("[removeContractManagement]", e);
  }

  return false;
};

/**
 * Create ContractTotals by Hospital
 * exercise HCreateContractTotals choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} roleCid
 * @param {String} vendor 
 * @param {Object} detail 
 * @param {Array} cptcodetotal
 */
export const createContractTotals = async ({
  ledger, roleCid, vendor, detail, cptcodetotal, 
}) => {

  try {

    if (roleCid && validateContractTotalData(detail)) {
      let res = await ledger.exercise(HospitalRole.HCreateContractTotals, roleCid, {
        vendor,
        detail: getContractTotalData(detail),
        cptcodetotal: getArray(cptcodetotal, validateCodeTotalData, getCodeTotalData),
      });
      console.log("[createContractTotals]", res);

      let templatePO = getTemplateListFromResponse(res, "ContractTotals");
      if (templatePO.length) {
        return templatePO[0];
      }
    }

  }
  catch (e) {
    console.warn("[createContractTotals]", e);
  }

  return false;
};

/**
 * Update ContractTotals by Hospital
 * exercise HUpdateContractTotals choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} roleCid
 * @param {String} contractCid 
 * @param {Object} detail 
 * @param {Array} cptcodetotal
 */
export const updateContractTotals = async ({
  ledger, roleCid, contractCid, detail, cptcodetotal, 
}) => {

  try {

    if (roleCid && contractCid && validateContractTotalData(detail)) {
      let res = await ledger.exercise(HospitalRole.HUpdateContractTotals, roleCid, {
        contractCid,
        newdetail: getContractTotalData(detail),
        newcptcodetotal: getArray(cptcodetotal, validateCodeTotalData, getCodeTotalData),
      });
      console.log("[updateContractTotals]", res);

      let templatePO = getTemplateListFromResponse(res, "ContractTotals");
      if (templatePO.length) {
        return templatePO[0];
      }
    }

  }
  catch (e) {
    console.warn("[updateContractTotals]", e);
  }

  return false;
};

/**
 * Remove ContractTotals by Hospital
 * exercise HArchiveContractTotals choice of HospitalRole
 * @param {Object} ledger 
 * @param {String} roleCid
 * @param {String} contractCid
 */
export const removeContractTotals = async ({
  ledger, roleCid, contractCid, 
}) => {

  try {

    if (roleCid && contractCid) {
      let res = await ledger.exercise(HospitalRole.HArchiveContractTotals, roleCid, {
        contractCid,
      });
      console.log("[removeContractTotals]", res);

      return true;
    }

  }
  catch (e) {
    console.warn("[removeContractTotals]", e);
  }

  return false;
};

