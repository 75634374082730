import { INVENTORY_SET, INVENTORY_SET_ALL, INVENTORY_SET_RESTOCK, INVENTORY_SET_ACCOUNT } from "../actions/constants";

const default_state = {
  restockInventoryData: {},
  restockInventoryErrors: {},
  accountInventoryData: {},
  accountInventoryErrors: {},
};

export default function inventoryReducer (state=default_state, action) {
  const { type, payload } = action;
  if (type === INVENTORY_SET) { 
    if (payload && typeof payload === 'object') return { ...state, ...payload };
  }
  else if (type === INVENTORY_SET_ALL) { 
    if (payload && typeof payload === 'object') {
      return { ...default_state, ...payload };
    }
  }
  else if (type === INVENTORY_SET_RESTOCK) { 
    if (payload && typeof payload === 'object') {
      for (let key in payload) {
        if (payload[key] && state.restockInventoryErrors[key]) delete state.restockInventoryErrors[key];
      }
      return { ...state, restockInventoryData: { ...state.restockInventoryData, ...payload } };
    }
  }
  else if (type === INVENTORY_SET_ACCOUNT) { 
    if (payload && typeof payload === 'object') {
      for (let key in payload) {
        if (payload[key] && state.accountInventoryErrors[key]) delete state.accountInventoryErrors[key];
      }
      return { ...state, accountInventoryData: { ...state.accountInventoryData, ...payload } };
    }
  }
  return state;
}