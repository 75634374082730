import React, { useState } from "react";
import { TextField, InputAdornment, IconButton, Divider, FormControl, /* Button */ } from "@material-ui/core";
import { Backspace } from '@material-ui/icons';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import useStyles from "./styles";

export default function DateSearch({
  handleChangeSearch = (v) => v,
  label = "",
  defaultValue = "",
  isStatic = false,
}) {

  const classes = useStyles();


  const [value, setValue] = useState(defaultValue);


  const handleChange = (val) => {
    // console.log("[DateSearch] handleChange", val);
    setValue(val);
    handleChangeSearch(val || "");
  };
  const resetValue = ()=>{
    let val ="";
    setValue(val);
    
    handleChangeSearch(val || "");
  };
  const setInputVal = (e) => {
    let val = e.target.value;
    setValue(val);
  };
  const changeInputValue = (e)=>{
    setValue(value);
    handleChangeSearch(value || "");
  };

  return (
    <>

      {
        !isStatic
        ?
          <FormControl className={classes.dialogFieldMd}>
            <TextField
              type="date"
              variant="outlined"
              label={label}
              InputLabelProps={{ shrink: true }}
              value={value}
              onChange={(e) => handleChange(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <Divider className={classes.divider} orientation="vertical" />
                  <IconButton
                    aria-label="delete icon"
                    edge="end"
                    onClick={(e) => handleChange('')}
                    color={value===""?"default":"secondary"}
                  >
                    <Backspace />
                  </IconButton>
                </InputAdornment>,
              }}
            />
          </FormControl>
        :
        <> 
        <div  className={classes.datepicker}>
          <div className={classes.setInput}>
            <TextField className={classes.dateInputBox} placeholder={"yyyy-mm-dd"} onChange={(e)=>{setInputVal(e)}}/> 
            <ExitToAppIcon  onMouseOut={(e)=>{changeInputValue(e)}}/>
          </div>    
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              autoOk
              // orientation="landscape"
              variant="static"
              // openTo="date"
              // format="yyyy-MM-dd"
              clearable
              ToolbarComponent={() => (<>
                {/* <Button
                  aria-label="delete icon"
                  size="small"
                  className={classes.clearButton}
                  onClick={(e) => handleChange('')}
                  color={value===""?"default":"secondary"}
                >
                  clear
                </Button> */}
              </>)}
              // label={label}
              value={value}
              onChange={(val) => {
                // handleChange(val.toISOString().split('T')[0]);
                const pad = (n) => (n < 10 ? "0"+n : n);
                const localDate = val.getFullYear() + "-" + pad(val.getMonth() + 1) + "-" + pad(val.getDate());
                handleChange(localDate);
              }}
            /> 
            <RotateLeftIcon className={classes.resetCalendar} onClick={resetValue}/>
          </MuiPickersUtilsProvider> 
          </div>
        </>
      }

    </>
  );
}
