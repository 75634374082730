import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { FormControl, InputLabel, TextareaAutosize, FormHelperText } from "@material-ui/core";
import useStyles from "./styles";


/**
 * New Custom TextArea
 * @param {String} value 
 * @param {Function} onChange 
 * @param {String} label 
 * @param {String} placeholder 
 * @param {Object} className 
 * @param {Object} options 
 * @param {String} warning 
 * @param {Number} width  
 * @param {Object} style 
 * @param {Number} minRows 
 * @param {Number} maxRows
 * @returns 
 */
export default function CustomTextArea ({
  value="", onChange, label, placeholder, className, options={}, 
  warning="", width=200, style={}, minRows=3, maxRows=3, 
}) {

  options = {
    disabled: false,
    labelLeft: false,   // Label position Left
    labelWidth: null,   // Label width {Number|null}
    readOnly: false,
    maxHeight: 100,
    ...options,
  };

  const classProps = {
    labelWidth: (typeof options.labelWidth === 'number' ? options.labelWidth : 110),
    maxHeight: options.maxHeight,
  };
  const classes = useStyles(classProps);

  const [helper, setHelper] = useState(warning);
  const [readOnly, setReadOnly] = useState(options.readOnly);

  // class name list
  const classnames = [classes.input, classes.textArea]; 
  if (className) classnames.push(className);
  if (readOnly) classnames.push(classes.readOnly);
  if (options.labelLeft) classnames.push("label-left");
  // width
  width = options.labelLeft ? classProps.labelWidth + width : width;
  // maxRows
  if (maxRows < minRows) maxRows = minRows;


  const handleChange = (event) => {
    const newVal = event.target.value;
    if (typeof onChange === 'function') {
      onChange(newVal);
    }
  };

  const handleSwitch = (value=null) => {
    if (options.readOnly) {
      setReadOnly(typeof value === 'boolean' ? value : !readOnly);
    }
  };


  useEffect(() => {
    setHelper(warning);
  }, [warning]);


  return (
    <FormControl 
      className={classNames(classnames)}
      style={{
        paddingTop: (label ? 16 : 0),
        ...style,
        width: width,
      }}
    >
      <InputLabel
        shrink
        htmlFor="custom-input"
      >
        {label}
      </InputLabel>
      <TextareaAutosize
        placeholder={placeholder}
        value={value}
        margin="normal"
        onChange={handleChange}
        minRows={minRows}
        maxRows={maxRows}
        disabled={options.disabled}
        readOnly={readOnly}
        onDoubleClick={handleSwitch}
        onBlur={() => handleSwitch(true)}
        style={{ minWidth: '100px' , minHeight : '30px' }}
      />
      <FormHelperText>
        {helper}
      </FormHelperText>
    </FormControl>
  );
}
