import React, { useState, useEffect } from "react";
import ModalDialog from '../../components/Modals/NewGeneralModal';
import { PaymentLog } from "../../services/daml-modules1";
import useStyles from "../surgicalevent/styles";
import { useStreamQueries } from "@daml/react";
import {
  Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography
} from "@material-ui/core";
import moment from "moment";
import { JSONTree } from "react-json-tree";
import XMLViewer from "react-xml-viewer";
import classNames from "classnames";

// newly added codes
import { Pagination } from "@material-ui/lab";

const customXMLTheme = {
  "attributeKeyColor": "#FF0000",
  "attributeValueColor": "#000FF"
}

const theme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#272822',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633',
};


function usePagination(store, itemsPerPage) {
  const [currentPage, setCurrentPage] = useState(1);
  let maxPage = 1;
  if (store) {
    maxPage = Math.ceil(store.length / itemsPerPage);
  }
  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return store.slice(begin, end);
  }

  function next() {
    setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
  }

  function jump(page) {
    const pageNumber = Math.max(1, page);
    setCurrentPage(currentPage => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentData, currentPage, maxPage };
}

export default function MessageLog({
  open, setOpen, contract
}) {
  let [store, setStore] = useState();
  let json = "";
  let xml = "";
  let extraction = "";
  let input_details = [];
  const [page, setPage] = useState(1);
  const PER_PAGE = 1;
  let count = store?.length | 0;
  const _DATA = usePagination(store, PER_PAGE);

  const classes = useStyles();


  let { hospital, vendor, paymentnumber } = contract;
  const { contracts: asset } = useStreamQueries(PaymentLog, () => [{ vendor, hospital, paymentnumber }]);

  useEffect(() => {
    debugger
    if (asset.length) {
      setStore(asset);
    }
  }, [asset]);



  const getXMLVIEW = (_xml) => {
    if (_xml) {
      return <XMLViewer xml={_xml} theme={customXMLTheme} collapsible={true} />
    } else {
      return null
    }
  }


  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };


  if (store && store.length) {
    extraction = store[page - 1]?.payload?.created_at;
    input_details = store[page - 1]?.payload?.input_details;
  }


  return (
    <>
      <ModalDialog
        width={"1300px"}
        className={classNames(classes.customRoot, classes.customRootMsg)}
        open={open}
        setOpen={setOpen}
        title={(
          <Typography variant="inherit" component="span" className={classes.newPageTitle}>
            Message Logs
          </Typography>
        )}
        content={(
          <Grid container
            style={{
              maxHeight: '85%',
              overflow: 'scroll'
            }}
          >
            <Grid item xs={8}>
              <Table>
                <TableHead>
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>Date/Time</TableCell>
                    <TableCell className={classes.tableCell}>Type</TableCell>
                    <TableCell className={classes.tableCell}>Actual Message</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    input_details.map((input) => {
                      const formatted = JSON.parse(input);
                      xml = formatted.xml;
                      json = formatted.json;
                      let name = formatted.name.slice(0, 13);

                      return (
                        <>
                          <TableRow className={classes.tableRow}>
                            <TableCell className={classes.tableCell}>
                              {moment(extraction).format('MMM DD, YYYY/ h:mm a')}
                            </TableCell>
                            <TableCell className={classes.tableCell} style={{ width: '10%' }}>
                              {`${name}[JSON]`}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <JSONTree
                                theme={{
                                  extend: theme,
                                  valueLabel: {
                                    textDecoration: 'underline',
                                  },
                                  nestedNodeLabel: ({ style }, keyPath, nodeType, expanded) => ({
                                    style: {
                                      ...style,
                                      textTransform: expanded ? 'uppercase' : style.textTransform,
                                    },
                                  }),
                                }}
                                data={json} />
                            </TableCell>
                          </TableRow>
                          <TableRow className={classes.tableRow}>
                            <TableCell className={classes.tableCell}>
                              {moment(extraction).format('MMM DD, YYYY/ h:mm a')}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {`${name}[XML]`}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {
                                getXMLVIEW(xml)
                              }
                            </TableCell>
                          </TableRow>
                        </>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </Grid>


          </Grid>
        )}
        actions={
          (<Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="circle"
            onChange={handleChange}
          />)
        }
      />
    </>
  )
}




