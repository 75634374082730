import { Button, Chip, Dialog, FormControl, InputLabel, Select, TextField, MenuItem } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useStyles from "../surgicalevent/styles";
import { useLedger } from "@daml/react";
import { getCanadaStates, getUsStates } from "../../models/State";
import { onChangeNumber } from "../../components/Modals/handles";
import { setByPath } from "../../components/Contracts/functions";
import { exerciseRoleOneAccept } from "./handle";
import TabPanel, { a11yProps } from "./TabPanel";



export default function VendorProfileUpdate({
    // isCreate,
    open,
    setOpen,
    // handleRequest,
    // setUserItems,
    vendorProfile
}) {
    console.log(vendorProfile)

    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [vendordetails, setVendorDetails] = useState();
    const ledger = useLedger();
    const [states, setStates] = useState(getUsStates);
    // const dispatch = useDamlDispatch()
    const [selected] = useState(0);
    const [count] = useState(1);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleCountry = (e) => {
        console.clear(e.target.value);
        updateField('vendorcontactaddress.country')(e);
        updateField('vendoraddress.country')(e);
        if (e.target.value === "US")
            setStates(getUsStates);
        if (e.target.value === "CA")
            setStates(getCanadaStates);
        console.log(e.target.value, getUsStates, getCanadaStates);
    }

    const handleRequest = async () => {
        debugger
        await exerciseRoleOneAccept(vendordetails, ledger, vendorProfile.contractId);
        setOpen(false);
    };

    const updateField = (key, isNumber = false) => (e) => {
        if (!isNumber || (isNumber && onChangeNumber(e))) setVendorDetails(setByPath(e.target.value, key, vendordetails));
    };


    useEffect(() => {
        // console.log(hospitalProfile?.payload.hospitaldetails)
        setVendorDetails({ ...vendorProfile?.payload.vendordetails });
    }, [vendorProfile]);

    return (
        <Dialog
            className={classes.customRoot}
            open={open}
            onClose={() => { setOpen(false) }}
            aria-labelledby="draggable-dialog-title"
        >
            <div className="formsteps">
                <AppBar position="static" color="default" className={classes.tabHeader}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab label="Vendor Name" {...a11yProps(0)} />
                        <Tab label="Division Name" {...a11yProps(1)} />
                        <Tab label="Vendor Address" {...a11yProps(2)} />
                        <Tab label="Vendor Contact" {...a11yProps(3)} />
                        <Tab label="Vendor Contact Address" {...a11yProps(4)} />
                        <Tab label="Tax ID" {...a11yProps(5)} />

                        <Tab label="Bank Information" {...a11yProps(6)} />
                        <Tab label="Payment" {...a11yProps(7)} />
                        <Tab label="Virtual Card" {...a11yProps(8)} />
                    </Tabs>
                </AppBar>

                <div className={classes.mainFormStepsPanel}>

                    <TabPanel value={value} index={0}>
                        <div className={classes.marginBM30}>
                            <TextField
                                id="input-with-icon-adornment2"
                                label="Vendor Name"
                                placeholder=""
                                className={classes.customFullWidth}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={vendordetails?.vendorname}
                                onChange={updateField('vendorname')}
                                variant="outlined"
                            />
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <div className={classes.marginBM30}>
                            <TextField
                                id="input-with-icon-adornment2"
                                label="Division Name"

                                placeholder=""
                                className={classes.customFullWidth}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={vendordetails?.vendordivision}
                                onChange={updateField('vendordivision')}
                                variant="outlined"
                            />
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <>
                            <div className={classes.marginBM30}>
                                <TextField
                                    id="outlined-full-width-mrn"
                                    label="Address 1"

                                    placeholder=""
                                    className={classes.customFullWidth}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={vendordetails?.vendoraddress?.address1 || ""}
                                    onChange={updateField('vendoraddress.address1')}
                                    variant="outlined"
                                />
                            </div>
                            <div className={classes.marginBM30}>
                                <TextField
                                    id="outlined-full-width-mrn"
                                    label="Address 2"

                                    placeholder=""
                                    className={classes.customFullWidth}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={vendordetails?.vendoraddress?.address2 || ""}
                                    onChange={updateField('vendoraddress.address2')}
                                    variant="outlined"
                                />
                            </div>
                            <div className={classes.marginBM30}>
                                <TextField
                                    id="outlined-full-width-mrn"
                                    label="City"

                                    placeholder=""
                                    className={classes.customFullWidth}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={vendordetails?.vendoraddress?.city || ""}
                                    onChange={updateField('vendoraddress.city')}
                                    variant="outlined"
                                />
                            </div>
                            <div className={classes.marginBM30}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
                                        Country
                                    </InputLabel>
                                    <Select
                                        className={classes.customFullWidth}
                                        onChange={handleCountry}
                                        value={vendordetails?.vendoraddress?.country || ""}
                                    >
                                        <MenuItem value={"US"}>US</MenuItem>
                                        <MenuItem value={"CA"}>Canada</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={classes.marginBM30}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
                                        State
                                    </InputLabel>
                                    <Select
                                        // native
                                        className={classes.customFullWidth}
                                        value={vendordetails?.vendoraddress?.state || ""}
                                        onChange={updateField('vendoraddress.state')}
                                    >
                                        {
                                            states.map(s => <MenuItem key={s[0]} value={s[0]}>{s[1]}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={classes.marginBM30}>
                                <TextField
                                    id="outlined-full-width-mrn"
                                    label="Zip Code"

                                    placeholder=""
                                    className={classes.halfWidth}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                    variant="outlined"
                                    value={vendordetails?.vendoraddress?.zipcode || ""}
                                    onChange={updateField('vendoraddress.zipcode')}
                                />
                            </div>
                            <div className={classes.marginBM30}>
                                <TextField
                                    id="outlined-full-width-mrn"
                                    label="Location"
                                    placeholder=""
                                    className={classes.customFullWidth}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={vendordetails?.vendoraddress?.location || ""}
                                    onChange={updateField('vendoraddress.location')}
                                />
                            </div>

                        </>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <>
                            <Button variant="contained" color="primary" className={classes.textFormation}>Add More </Button>
                            <div className={classes.shippingItem}>
                                <Chip label={`${selected + 1}/${count}`} className="bg-info-light" />
                            </div>
                            {
                                vendordetails?.vendorcontact?.reverse().map((contact, selected) => (
                                    <>
                                        <div className={classes.marginBM30}>
                                            <TextField
                                                id="outlined1"
                                                label="First Name"

                                                placeholder=""
                                                className={classes.customFullWidth}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}

                                                variant="outlined"
                                                value={vendordetails.vendorcontact[selected].firstname}
                                                onChange={updateField(`vendorcontact.${selected}.firstname`)}
                                            />
                                        </div>
                                        <div className={classes.marginBM30}>
                                            <TextField
                                                id="outlined-2"
                                                label="Last Name"

                                                placeholder=""
                                                className={classes.customFullWidth}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={vendordetails.vendorcontact[selected].lastname}
                                                onChange={updateField(`vendorcontact.${selected}.lastname`)}
                                            />
                                        </div>
                                        <div className={classes.marginBM30}>
                                            <TextField
                                                id="outlined-f3"
                                                label="Gender"

                                                placeholder=""
                                                className={classes.customFullWidth}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}

                                                variant="outlined"
                                                value={vendordetails.vendorcontact[selected].gender}
                                                onChange={updateField(`vendorcontact.${selected}.gender`)}
                                            />
                                        </div>

                                        <div className={classes.marginBM30}>
                                            <TextField
                                                id="outlined-4n"
                                                label="Phone"

                                                placeholder=""
                                                className={classes.customFullWidth}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}

                                                variant="outlined"
                                                value={vendordetails.vendorcontact[selected].phone}
                                                onChange={updateField(`vendorcontact.${selected}.phone`)}
                                            />
                                        </div>
                                        <div className={classes.marginBM30}>
                                            <TextField
                                                id="outlined-5"
                                                label="Email"

                                                placeholder=""
                                                className={classes.customFullWidth}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}

                                                variant="outlined"
                                                value={vendordetails.vendorcontact[selected].email}
                                                onChange={updateField(`vendorcontact.${selected}.email`)}
                                            />
                                        </div>
                                        <div className={classes.marginBM30}>
                                            <TextField
                                                id="outlined-6"
                                                label="Fax"

                                                placeholder=""
                                                className={classes.customFullWidth}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}

                                                variant="outlined"
                                                value={vendordetails.vendorcontact[selected].fax}
                                                onChange={updateField(`vendorcontact.${selected}.fax`)}
                                            />
                                        </div>
                                        <div className={classes.marginBM30}>
                                            <TextField
                                                id="outlined-6"
                                                label="Job Title"

                                                placeholder=""
                                                className={classes.customFullWidth}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}

                                                variant="outlined"
                                                value={vendordetails.vendorcontact[selected].jobtitle}
                                                onChange={updateField(`vendorcontact.${selected}.jobtitle`)}
                                            />
                                        </div>
                                        <hr />
                                    </>
                                ))

                            }

                        </>
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <>
                            <div className={classes.marginBM30}>
                                <TextField
                                    id="outlined-full8-width-mrn"
                                    label="Vendor Contact Address1"
                                    placeholder="eg: 47 Anyville Rd"
                                    className={classes.customFullWidth}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={vendordetails?.vendorcontactaddress?.address1 || ""}
                                    onChange={updateField('vendorcontactaddress.address1')}
                                />
                            </div>
                            <div className={classes.marginBM30}>
                                <TextField
                                    id="outlined-2"
                                    label="Vendor Contact Address2"
                                    placeholder=""
                                    className={classes.customFullWidth}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={vendordetails?.vendorcontactaddress?.address2 || ""}
                                    onChange={updateField('vendorcontactaddress.address2')}
                                />
                            </div>
                            <div className={classes.marginBM30}>
                                <TextField
                                    id="outlined-f3"
                                    label="City"
                                    placeholder="Phoenix"
                                    className={classes.customFullWidth}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={vendordetails?.vendorcontactaddress?.city || ""}
                                    onChange={updateField('vendorcontactaddress.city')}
                                />
                            </div>
                            <div className={classes.marginBM30}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
                                        Country
                                    </InputLabel>
                                    <Select
                                        className={classes.customFullWidth}
                                        onChange={handleCountry}
                                        value={vendordetails?.vendorcontactaddress?.country || ""}
                                    >
                                        <MenuItem value={"US"}>US</MenuItem>
                                        <MenuItem value={"CA"}>Canada</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={classes.marginBM30}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel htmlFor="outlined-age-native-simple1" className={classes.customLabel} shrink={true}>
                                        State
                                    </InputLabel>
                                    <Select
                                        // native
                                        className={classes.customFullWidth}
                                        value={vendordetails?.vendorcontactaddress?.state || ""}
                                        onChange={updateField('vendorcontactaddress.state')}
                                    >
                                        {
                                            states.map(s => <MenuItem key={s[0]} value={s[0]}>{s[1]}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={classes.marginBM30}>
                                <TextField
                                    id="outlined-4n"
                                    label="Zip Code"
                                    placeholder="123456"
                                    className={classes.customFullWidth}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={vendordetails?.vendorcontactaddress?.zipcode || ""}
                                    onChange={updateField('vendorcontactaddress.zipcode')}
                                />
                            </div>
                            <div className={classes.marginBM30}>
                                <TextField
                                    id="outlined-6"
                                    label="Location"
                                    placeholder=""
                                    className={classes.customFullWidth}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={vendordetails?.vendorcontactaddress?.location || ""}
                                    onChange={updateField('vendorcontactaddress.location')}
                                />
                            </div>
                        </>
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        <div className={classes.marginBM30}>
                            <TextField
                                id="outlined-full-width-mrn"
                                label="Tax ID"
                                placeholder=""
                                className={classes.customFullWidth}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                value={vendordetails?.taxid || ""}
                                onChange={updateField('taxid')}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={6}>

                        <div className={classes.marginBM30}>
                            <TextField
                                id="outlined-full-width-mrn"
                                label="Bank ABA Number"
                                placeholder=""
                                className={classes.customFullWidth}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                value={vendordetails?.ach_info?.routingnumber || ""}
                                onChange={updateField('ach_info.routingnumber')}
                            />
                        </div>
                        <div className={classes.marginBM30}>
                            <TextField
                                id="outlined-full-width-mrn"
                                label="Bank Account Number"
                                placeholder=""
                                className={classes.customFullWidth}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                value={vendordetails?.ach_info?.accountnumber || ""}
                                onChange={updateField('ach_info.accountnumber')}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={7}>

                        <div className={classes.marginBM30}>
                            <TextField
                                id="outlined-full-width-mrn"
                                label="PayeeNetworkID"
                                placeholder=""
                                className={classes.customFullWidth}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                value={vendordetails?.ach_info?.networkid || ""}
                                onChange={updateField('ach_info.networkid')}
                            />
                        </div>
                        <div className={classes.marginBM30}>
                            <TextField
                                id="outlined-full-width-mrn"
                                label="PayeeProviderID"
                                placeholder=""
                                className={classes.customFullWidth}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                value={vendordetails?.ach_info?.providerid || ""}
                                onChange={updateField('ach_info.providerid')}
                            />
                        </div>
                    </TabPanel>

                    {getVirtualCardPanel()}

                </div>
            </div>
            <div className="form-footer">
                <div className="right-buttons">
                    <Button autoFocus onClick={() => { setOpen(false) }} className="noBtn" variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleRequest} className="yesBtn" variant="contained" color="primary">
                        Update
                    </Button>
                </div>
            </div>
        </Dialog>
    );

    function getVirtualCardPanel() {
        return <TabPanel value={value} index={8}>

            <div className={classes.marginBM30}>
                <TextField
                    id="outlined-full-width-mrn"
                    label="Email"
                    placeholder=""
                    className={classes.customFullWidth}
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={vendordetails?.ach_info?.emailid || ""}
                    onChange={updateField('ach_info.emailid')} />
            </div>

        </TabPanel>;
    }
}

