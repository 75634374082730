import React, { useState, useEffect } from "react";
import { Button, Typography, Card, CardContent, List, ListItem, FormControlLabel, Checkbox } from "@material-ui/core";
import { useLayoutDispatch, setLoading, unsetLoading } from "../../context/LayoutContext";
import { searchUserList } from "./handle";
import { useDamlState } from "../../context/DamlContext";
import { InputPassword } from './addedituser';
import { changePassword, updateUser, changePasswordEmailSend } from '../../services/axios/auth-api';
import { validatePassword, hashPassword } from "../../services/util";
import useAuthToken from "../../hooks/useAuthToken";
import ModalDialogBox from "../../components/Modals/GeneralModal/modalDialogBox";
import useStyles from "../showlist/styles";


/**
 * Settings / Change Password Page
 * @returns 
 */
export default function SettingPassword() {

  const classes = useStyles();

  const { userlistPayload: user } = useDamlState();
  const layoutDispatch = useLayoutDispatch();

  const {
    index, 
    loading, 
  } = useAuthToken();

  console.log("[SettingPassword]", loading, index, user);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentErrMsg, setCurrentErrMsg] = useState("");
  const [newErrMsg, setNewErrMsg] = useState("");
  const [confirmErrMsg, setConfirmErrMsg] = useState("");
  const [oldUser, setOldUser] = useState({});
  // alerts
  const [openSuccess, setOpenSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [openError, setOpenError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  // check request
  const [checked, setCheck] = useState(false);


  // handle when click check
  const checkedRequestHandler = (e) => {
    if (e.target.checked === true) {
      setCheck(true);
    } else {
      setCheck(false);
    }
  };

  // handle when click "Request" button
  const handleRequest = async () => {
    // console.log("[SettingPassword] handleRequest", checked);

    if (!checked) {
      setOpenError(true);
      setErrorMsg(`<p>Checkbox should be checked.</p>`);
      return;
    }

    if (!loading && index) {

      let res = null;
      try {
        // spinner
        setLoading(layoutDispatch);

        res = await changePasswordEmailSend({
          email: oldUser.email,
        });
        console.log("[SettingPassword] handleRequest", res);

      }
      catch (e) {}

      unsetLoading(layoutDispatch);

      if (!!res) {
        setOpenSuccess(true);
        setSuccessMsg('Request Email sent success.');
      }
      else {
        setOpenError(true);
        setErrorMsg(`<p>Request Email sent was unsuccessful.</p>
        <p>Please contact <a href="mailto:info@tydei.io">info@tydei.io</a> for more information</p>`);
      }

    }
  };

  // handle when click "Update" button
  const handleUpdate = async () => {
    // console.log("[SettingPassword] handleUpdate", currentPassword, newPassword, confirmPassword);

    if (checked) {
      setOpenError(true);
      setErrorMsg(`<p>Checkbox should be unchecked.</p>`);
      return;
    }

    if (!currentPassword) {
      setCurrentErrMsg("Current Password is requied.");
      return;
    }
    else setCurrentErrMsg("");

    const validatePass = validatePassword(newPassword);
    if (validatePass !== true) {
      setNewErrMsg("New Password is requied.");
      return false;
    }
    else setNewErrMsg("");

    if (newPassword !== confirmPassword) {
      setConfirmErrMsg("Confirm Password should be same as New Password.");
      return;
    }
    else setConfirmErrMsg("");

    if (!loading && index) {

      let res = null;
      try {

        // check passwordHash
        // if it used email request of auth0, then it's not correct.
        // So, it's disabled now.
        /* if (oldUser && oldUser.passwordHash) {
          console.log("[SettingPassword] handleUpdate", oldUser.passwordHash);
          if (hashPassword(currentPassword) !== oldUser.passwordHash) {
            setCurrentErrMsg("Current Password is wrong.");
            return;
          }
        } */

        // check old & new password are same
        if (oldUser && oldUser.passwordHash) {
          if (hashPassword(newPassword) === oldUser.passwordHash) {
            setNewErrMsg("This Password was used in the last 5 passwords please choose another.");
            return;
          }
        }

        // spinner
        setLoading(layoutDispatch);

        // update password
        res = await changePassword({
          id: index,
          newpassword: newPassword,
        });
        console.log("[SettingPassword] handleUpdate", res);

        // update passwordHash
        if (res && res.user_id) {
          res = await updateUser({
            id: res.user_id,
            email : oldUser.email,
            name : oldUser.name,
            party : oldUser.partyIdentifier,
            role: oldUser.role,
            organization: oldUser.organization,
            userParty: oldUser.party,
            userid : oldUser.userid,
            gender : oldUser.gender,
            officephone : oldUser.officephone,
            phone : oldUser.phone,
            accesslevel : oldUser.accesslevel,
            jobtitle : oldUser.jobtitle,
            userstatus: oldUser.userstatus,
            passwordHash: hashPassword(newPassword),
          });
          console.log("[SettingPassword] handleUpdate", res);

        }

      }
      catch (e) {}

      unsetLoading(layoutDispatch);

      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");

      if (!!res && Object.keys(res).length && 'app_metadata' in res) {
        setOpenSuccess(true);
        setSuccessMsg('You have successfully changed your password.');
        // update user info
        if ('daml_ledger_api' in res.app_metadata) {
          setOldUser({
            ...oldUser,
            ...res.app_metadata.daml_ledger_api,
          });
        }
      }
      else {
        setOpenError(true);
        setErrorMsg(`<p>Password reset was unsuccessful.</p>
        <p>Please contact <a href="mailto:info@tydei.io">info@tydei.io</a> for more information</p>`);
      }

    }
  };


  // get a user from auth0
  useEffect(() => {
    const getuser = async () => {
      if (user && user.email) {
        const [userinfo] = await searchUserList([user.email]);
        console.log("[SettingPassword] user", userinfo);
        if (userinfo && userinfo.app_metadata) setOldUser({
          ...userinfo.app_metadata.daml_ledger_api,
          email: userinfo.email,
          name: userinfo.name,
        });
      }
    }
    getuser();
  }, [user]);


  return (
    <>
      <div className={classes.customRoot}>

        <Typography variant="h4" className={classes.pageTitle}>
          Settings
        </Typography>

        <Card className={classes.cardItem}>
          <CardContent>

            <List className={classes.listItem}>
              <ListItem>
                <div className="item">
                  <Typography variant="h5" >
                    Change Password  
                  </Typography>
                  
                  <div className={classes.marginBM30}>
                    <InputPassword 
                      password={currentPassword} 
                      setPassword={setCurrentPassword}
                      errorType='none'
                      label='Current Password'
                      errMsg={currentErrMsg}
                      disabled={checked}
                    />
                  </div>

                  <div className={classes.marginBM30}>
                    <InputPassword 
                      password={newPassword} 
                      setPassword={setNewPassword}
                      errorType='chip'
                      label='New Password'
                      errMsg={newErrMsg}
                      disabled={checked}
                    />
                  </div>

                  <div className={classes.marginBM30}>
                    <InputPassword 
                      password={confirmPassword} 
                      setPassword={setConfirmPassword}
                      errorType='none'
                      label='Confirm Password'
                      errMsg={confirmErrMsg}
                      disabled={checked}
                    />
                  </div>

                  <div className={classes.marginBM30}>
                    <FormControlLabel
                      className={classes.customCheckbox}
                      value={true}
                      onChange={checkedRequestHandler}
                      control={<Checkbox color="primary" checked={checked} />}
                      label="Use Auth0 Password Reset Email instead of directly."
                      labelPlacement="end"
                    />
                  </div>
                </div>
              </ListItem>
            </List>

            <div className="form-footer">
              <div className="right-buttons">
                <Button variant="contained" color="primary" onClick={handleRequest}>Request</Button>
                <Button variant="outlined" color="primary" onClick={handleUpdate}>Update</Button>
              </div>
            </div>

          </CardContent>
        </Card>

      </div>

      <ModalDialogBox
        open={openSuccess}
        setOpen={setOpenSuccess}
        heading="Success!"
        text={successMsg}
        firstBtn={"Yes"}
        handleRequest={() => {
          setOpenSuccess(false);
        }}
      />

      <ModalDialogBox
        open={openError}
        setOpen={setOpenError}
        heading="Error!"
        type="warning"
        text={errorMsg}
        firstBtn={"Ok"}
        handleRequest={() => {
          setOpenError(false);
        }}
      />
    </>
  );
};
