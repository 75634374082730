import React from "react";
import classNames from "classnames";
import { Chip } from "@material-ui/core";
import Contracts from "../../components/Contracts/Contracts1";
import { UOM } from "../../models/Product";
import { Ownerships } from "../../models/Ownership";
import { MyInventoryProductStatus } from "../../models/InventoryData";
import { contractFilter1 } from "../../services/daml-filters";
// import { getQuantityOnOrder } from "../../services/util";
// import useSearchFilters from "../../hooks/useSearchFilters";
import { handleCheckedContract, handleClickedContract } from '../surgicalevent/surgicaleventlistcontracts1';
import { getInventoryDataStatus, getInventorySortKey, getProductDisplayUOM } from "./inventorylistcontractshospital";
import useStyles from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";
import withFilters from "../../hoc/withFilters";
import { useTranslation } from 'react-i18next';

/**
 * Account Inventoy List Contracts in Hospital
 * @param {Object} searchParams clear
 * @param {Boolean} isIncludeHistory 
 * @param {Array} allContracts 
 * @param {Array} historyContracts 
 * @param {Function} setArchived
 * @param {Function} setChecked
 * @param {Function} handleEditInventory
 * @returns 
 */
function InventoryListContractsHospital({
  searchParams = {},
  isIncludeHistory = false,
  allContracts = [],
  historyContracts = [],
  setArchived = null,
  setChecked = null,
  handleEditInventory = null,
  handleOpenOrder = null,
}) {

  const classes = useStyles();
  const { t } = useTranslation();
  // const locationFilter = useSearchFilters('inventorydata.iproduct.seplocation');
  const locationFilter = searchParams['filter.inventorydata.iproduct.seplocation'];
  

  allContracts = allContracts.concat(isIncludeHistory && historyContracts.length ?
    historyContracts.map(h => ({ ...h, payload: h.payload.inventory })) : []);

  let contracts = contractFilter1(allContracts, searchParams);

  contracts = contracts
    .map(c => {
      c.payload.iproduct_sepproductmanufacturer = "";
      // c.payload.iproduct_sepuom = "";
      // c.payload.iproduct_sepudi = "";
      c.payload.qoh = 0;
      // c.payload.inventoryproduct.iunits = [];
      // c.payload.location = undefined;
      let tempinvdata = [];
      c.payload.inventorydata.forEach(invData => {
        if (invData.iproduct.sepproductmanufacturer) c.payload.iproduct_sepproductmanufacturer = invData.iproduct.sepproductmanufacturer;
        // if (invData.iproduct.sepuom) c.payload.iproduct_sepuom = invData.iproduct.sepuom;
        // if (invData.iproduct.sepudi) c.payload.iproduct_sepudi = invData.iproduct.sepudi;
        if (invData.iproductstatus === "Opens") return;
        // c.payload.qoh += getQuantityOnOrder(invData);
        c.payload.qoh += Number(invData.iquantity);
        // c.payload.inventoryproduct.iunits = invData.iproduct.units;
        // if (c.payload.location === undefined) c.payload.location = invData.iproduct.seplocation;
        // else if (invData.iproduct.seplocation !== c.payload.location && c.payload.location !== null) c.payload.location = null;
        tempinvdata.push(invData);
      });
      c.payload.inventorydata = tempinvdata;
      // c.payload.price = getTotalPrice(c.payload.inventorydata);

      c.newsortkey = getInventorySortKey(c);

      c.payload.status = getInventoryDataStatus(c.payload.inventorydata);

      // c.payload.inventoryproduct.iprice = getProductDisplayContractPrice(c);
      // c.payload.iproduct_sepuom = c.payload.iproduct_sepuom ? getProductDisplayUOM(c) : "";
         c.payload.inventoryproduct.iuom = getProductDisplayUOM(c);
      // new itemcount & parlevel
      if (locationFilter === undefined || (typeof locationFilter === 'object' && locationFilter.length === 0)) {
        c.payload.itemcount_parlevel = `${c.payload.inventoryproduct.itemcount}/${c.payload.inventoryproduct.parlevel ?? ""}`;
      }
      else {
        const found = c.payload.inventoryproduct.iparlevels.find(({_1: loc}) => locationFilter.indexOf(loc) !== -1);
        c.payload.itemcount_parlevel = `${c.payload.inventorydata.length}/${found ? found._2 : ""}`;
      }

      return c;
    });
  console.log("[InventoryListContractsHospital]", contracts, searchParams, locationFilter);


  const getColorCode =(value) => {
    switch (value) {
      case 'Received':
        return classes.backGreen;
      case 'Recall':
        return classes.backPink;
      case 'Edited':
        return classes.backLightGreen;
      case 'Expiring':
        return classes.backYellow;
      case 'Back-order':
        return classes.backRed;
      case 'Expired':
        return classes.backRed;
      default:
        return classes.backBlue;
    }
  }

  return (
    <>
      <Contracts
        className={classes.customRoot}
        contracts={contracts}
        columns={[
          ["Reference Number", "payload.inventoryproduct.ireferencenumber", {
            type: "link",
            search: {
              type: "AdaptiveSearch",
              name: "inventoryproduct.ireferencenumber",
              getValue: (c) => c.payload.inventoryproduct.ireferencenumber,
              contracts: allContracts,
            },
            options: {
              flex: 1,
              headerAlign: 'center',
              align: 'center',
              cellClassName: classNames(classes.staticCellelement, classes.primaryColor),
              headerClassName: classes.staticCellelement,
            },
          }],
          [`${t('main.quantityonhand.lable')}`, "payload.qoh", {
            options: {
              flex: 0.5,
              cellClassName: classNames(classes.centerCell),
            },
          }],
          ["Qty/Par", "payload.itemcount_parlevel", {
            search: {
              type: "AdaptiveSearch",
              name: "itemcount_parlevel",
              getValue: (c) => c.payload.itemcount_parlevel,
              contracts: allContracts,
            },
            options: {
              flex: 0.8,
              cellClassName: classNames(classes.primaryColor, classes.centerCell),
            },
          }],
          ["Open", "payload.inventoryproduct.iopenorders", {
            type: "link",
            onClick: (contractId, contract) => {
              if (handleOpenOrder && typeof handleOpenOrder === 'function') handleOpenOrder(contract.contract);
            },
            options: {
              flex: 0.5,
              cellClassName: classNames(classes.centerCell),
            },
          }],
          ["Product Description", "payload.inventoryproduct.iproductdescription", {
            search: {
              type: "AdaptiveSearch",
              name: "inventoryproduct.iproductdescription",
              getValue: (c) => c.payload.inventoryproduct.iproductdescription,
              contracts: allContracts,
            },
            options: {
              flex: 2,
              tooltip: true,
            },
          }],
          ["Distributor", "payload.vendor", {
            search: {
              type: "AdaptiveSearch",
              name: "vendor",
              getValue: (c) => c.payload.vendor,
              contracts: allContracts,
            },
            options: {
              flex: 1,
            },
          }],
          ["Manufacturer", "payload.iproduct_sepproductmanufacturer", {
            search: {
              type: "AdaptiveSearch",
              name: "iproduct_sepproductmanufacturer",
              getValue: (c) => c.payload.iproduct_sepproductmanufacturer,
              contracts: allContracts,
            },
            options: {
              flex: 1,
            },
          }],
          ["Ownership", "payload.inventoryproduct.iownership", {
            search: {
              type: "SelectSearch",
              name: "inventoryproduct.iownership",
              options: Object.keys(Ownerships).map(value => ({ label: Ownerships[value], value: value })),
            },
            options: {
              flex: 0.9,
            },
          }],
          ["Price", "payload.inventoryproduct.iprice", {
            type: "render",
            render: (param) => (param.value ? `$${param.value}` : 'No Contract Price'),
            search: {
              type: "AdaptiveSearch",
              name: "inventoryproduct.iprice",
              getValue: (c) => c.payload.inventoryproduct.iprice,
              contracts: allContracts,
            },
            options: {
              flex: 0.8,
            },
          }],
          [`${t('main.uom.lable')}`, "payload.inventoryproduct.iuom", {
            search: {
              type: "SelectSearch",
              name: "inventoryproduct.iuom",
              options: Object.keys(UOM).map(value => ({ label: UOM[value], value: value })),
            },
            options: {
              flex: 0.7,
            },
          }],
          ["Product Status", "payload.status", {
            type: "render",
            render: (param) => {
              const status = param.row.contract.payload.status;
              return (status.length ?
                status.map((s, i) => (<Chip
                  label={s}
                  key={i}
                  size="small"
                  className={getColorCode(s)}
                  style={{ marginLeft: 5 }}
                />))
                : <></>);
            },
            search: {
              type: "SelectSearch",
              name: "status",
              options: Object.keys(MyInventoryProductStatus).map(value => ({ label: MyInventoryProductStatus[value], value: value })),
            },
            options: {
              flex: 1,
              headerAlign: 'center',
              align: 'center',
            },
          }],
        ]}
        options={{
          statusEmpty: (allContracts.length === 0),
          rowsPerPage: 100,
          sortKey: 'newsortkey',
          checkbox: true,
          fieldsToDisableClick: ['Open'],
        }}

        handleArchived={setArchived}
        handleClicked={(e) => handleClickedContract(e, handleEditInventory)}
        handleChecked={(e) => handleCheckedContract(e, contracts, setChecked)}
      />

    </>
  );
}

const InventoryHospitalListContractsWithLoading = withLoading(InventoryListContractsHospital);
export default withFilters(InventoryHospitalListContractsWithLoading)([
  { name: "inventoryproduct.ireferencenumber", label: "Reference Number" },
  { name: "itemcount_parlevel", label: "Quantity/Par Level" },
  { name: "inventoryproduct.iproductdescription", label: "Product Description" },
  { name: "vendor", label: "Distributor" },
  { name: "iproduct_sepproductmanufacturer", label: "Manufacturer" },
  { name: "inventoryproduct.iownership", label: "Ownership" },
  { name: "inventoryproduct.iprice", label: "Price" },
  { name: "inventoryproduct.iuom", label: "UoM" },
  { name: "status", label: "Product Status" },
  { name: "inventorydata.iproduct.seplocation", label: "Location", type: "filter", disabled: true },
]);
