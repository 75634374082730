import { makeStyles } from "@material-ui/styles";
import calendarIcon from "../../icons/calendar.svg";

export default makeStyles(theme => ({
  menuDM:{
    backgroundColor:"#1c3c74",
    color:"#FFFFFF",
  },
  inputDM: {
    '& .MuiInputBase-root': {
      height: '30px',
      background: 'rgba(196, 196, 196, 0.18)',
      border: '1px solid #CCCCCC',
      backgroundColor:"#1c3c74",
      '& .MuiInputBase-input': {
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '17px',
        paddingLeft: '3px',
        color:"#FFFFFF",
        '&.Mui-disabled': {
          color: '#CCCCCC',
        },
      },
    },
    '& .MuiFormLabel-root': {
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '18px',
      color: '#CCCCCC',
      transform: 'translate(0, 0px) scale(1)',
      inlineSize: 'max-content',
    },
    '& .MuiFormHelperText-root': {
      color: '#ff0000',
    },
    "&.label-left": {
      paddingTop: '0px !important',
      paddingLeft: ({labelWidth}) => `${labelWidth}px`,
      '& .MuiFormLabel-root': {
        width: ({labelWidth}) => `${labelWidth}px`,
        paddingTop: '6px',
      },
    },
  },
  input: {
    '& .MuiInputBase-root': {
      height: '30px',
      background: 'rgba(196, 196, 196, 0.18)',
      border: '1px solid #CCCCCC',
      '& .MuiInputBase-input': {
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '17px',
        color: '#000000',
        paddingLeft: '3px',
        '&.Mui-disabled': {
          color: '#CCCCCC',
        },
      },
    },
    '& .MuiFormLabel-root': {
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '18px',
      color: '#CCCCCC',
      transform: 'translate(0, 0px) scale(1)',
      inlineSize: 'max-content',
    },
    '& .MuiFormHelperText-root': {
      color: '#ff0000',
    },
    "&.label-left": {
      paddingTop: '0px !important',
      paddingLeft: ({labelWidth}) => `${labelWidth}px`,
      '& .MuiFormLabel-root': {
        width: ({labelWidth}) => `${labelWidth}px`,
        paddingTop: '6px',
      },
    },
  },
  search: {
    '& .MuiInputBase-root': {
      height: '32px',
      background: '#F6F9FA',
      border: '1px solid rgba(208, 205, 205, 0.75)',
      borderRadius: '5px',
      '& .MuiInputBase-input': {
        fontWeight: 300,
        fontSize: '12px',
        lineHeight: '14px',
      },
      '&.MuiInputBase-adornedStart > img, &.MuiInputBase-adornedEnd > img': {
        cursor: 'pointer',
      },
      '&.MuiInputBase-adornedStart > img': {
        paddingLeft: '5px',
        paddingRight: '4px',
      },
    },
  },
  searchSm: {
    '& .MuiInputBase-root': {
      height: '25px',
      background: '#FFFFFF',
      border: '1px solid #CCCCCC',
      borderRadius: '6px',
      '& .MuiInputBase-input': {
        fontWeight: 400,
        fontSize: '11px',
        lineHeight: '13px',
      },
      '&.MuiInputBase-adornedEnd > img': {
        paddingLeft: '9px',
        paddingRight: '7px',
      },
    },
  },
  date: {
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      backgroundImage: `url(${calendarIcon})`,
    },
  },
  select: {
    '& .MuiInputBase-root': {
      '& .MuiSelect-select': {
        paddingRight: '30px',
      },
      '& .MuiSelect-icon': {
        top: 'calc(50% - 10px)',
        right: '5px',
      },
      '&.MuiInput-underline:before': {
        borderBottomWidth: 0,
      },
      '&.MuiInput-underline:after': {
        borderBottomWidth: 0,
      },
    },
    "&.label-left": {
      paddingLeft: '0px !important',
      '& .MuiInputBase-root': {
        marginTop: 0,
        marginLeft: ({labelWidth}) => `${labelWidth}px`,
      },
      '& .MuiFormHelperText-root': {
        marginLeft: ({labelWidth}) => `${labelWidth}px`,
      },
    },
  },
  readOnly: {
    '& .MuiInputBase-root': {
      borderWidth: 0,
      backgroundColor: 'inherit',
      '& .MuiSelect-icon': {
        display: 'none',
      },
    }
  },
  categMenu: {
    padding: 0,
    width: '144px',
    background: '#E1DDDD',
    borderRadius: '0px 0px 4px 4px',
    '& .MuiMenuItem-root': {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      color: '#000000',
      '&:hover': {
        fontWeight: 700,
        color: '#FFFFFF',
        background: '#5C5C5C',
      },
      '&.active': {
        color: '#FFFFFF',
        background: '#5C5C5C',
      },
    },
  },
  textArea: {
    maxHeight: ({maxHeight}) => `${maxHeight}px`,
  },
  asteriskStyle: {
    color: "#000000",
    position: "absolute",
    bottom: "2px",
    fontSize: "17px",
  },
  errorBoxOutline: {
    border: '1px solid red !important',
  },
}));