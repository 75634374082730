import axios from 'axios';
import { NODE_SERVER_ENDPOINT, UploadFileExt } from "../../config";


/**
 * get access token in local node_server
 * @returns {String}
 */
export const getToken = async () => {
    const url = `${NODE_SERVER_ENDPOINT}api/auth0/token/`;
    
    const response = await axios.get(url);

    console.log("[getToken]", response);

    return response.data;
};

/**
 * upload file to create PurchaseOrder
 * @param {File} file 
 * @param {Object} options It must include hospital, vendor, ownership
 */
export const uploadFileCreatePO = async (file, options={}) => {
    console.log("[uploadFileCreatePO]", file, options);
    // get parameters
    const { hospital, vendor, ownership, potype, notes } = options;

    if (!hospital || !vendor || !ownership) return {error: "Parameters are required"};
    
    // url to upload csv or txt
    const url = (UploadFileExt === '.csv') ? `${NODE_SERVER_ENDPOINT}api/purchaseorder/create-csv` 
                : `${NODE_SERVER_ENDPOINT}api/purchaseorder/create-txt`;

    // params
    const params = { hospital, vendor, ownership, potype, notes };

    // form data
    const formData = new FormData();
    formData.append("file", file);

    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: url,
            params,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" }
        })
        .then(res => {
            console.log("[uploadFileCreatePO] success", res);
            resolve({
                uploadSuccess: "File upload successfull",
                error: undefined
            });
        })
        .catch(err => {
            console.log("[uploadFileCreatePO] error", err);
            resolve({
                error: "Error Occured while uploading the file",
                uploadSuccess: undefined
            });
        });
    });
};


/**
 * Dashboard Data Refresh trigger to update the data from Node Server
 * @param {String} hospitalName 
 * @returns {Object}
 */

export const dashboardDataRefresh = async (hospitalName) => {
    const url = `${NODE_SERVER_ENDPOINT}api/dashboard/refreshDashboardData?hospital=${hospitalName}`;
    
    const response = await axios.get(url);

    console.log("[dashboardDataRefresh]", response);

    return response.data;
}

/**
 * get vendor detail from node_server
 * @param {String} vendor 
 * @returns {Object}
 */
 export const vendorDetail = async (vendor) => {
    const url = `${NODE_SERVER_ENDPOINT}api/role/vendor-detail?vendor=${vendor}`;
    
    const response = await axios.get(url);

    console.log("[vendorDetail]", response);

    return response.data;
};


/**
 * Dashboard Data Refresh trigger to update the data from Node Server for the vendor
 * @param {String} vendorName 
 * @returns {Object}
 */

 export const dashboardVendorDataRefresh = async (vendorName) => {
    const url = `${NODE_SERVER_ENDPOINT}api/dashboard/refreshVendorDashboardData?vendor=${vendorName}`;
    
    const response = await axios.get(url);

    console.log("[dashboardDataRefresh]", response);

    return response.data;
}

/**
 * Send an email to invite vendor
 * @param {String} facility 
 * @param {String} email 
 * @param {String} userName 
 * @returns {Object}
 */
 export const vendorInviteEmailSend = async ({email,facility,userName,vendormail}) =>  {
    const url = `${NODE_SERVER_ENDPOINT}api/send-email`;
    const body = {
        "facility": facility,
        "email": email,
        "userName": userName,
        "vendormail": vendormail,
    };
    // const token = await getLocalToken();
    
    const response = await axios.post(url, body, {
    });

    console.log("[vendorinviteEmailSend]", response);
    return response.data ? response.data : null;
};

/**
 * Send an email to invite vendor
 * @param {String} surgeonIds 
 * @param {String} facilityId
 * @returns {Object}
 */
export const showPrefCardList = async ({surgeonIds,facilityId}) =>  {
    const url = `${NODE_SERVER_ENDPOINT}api/prefcard/getcards`;
    const body = {
        "surgeonIds": surgeonIds,
        "facilityId": facilityId,
    };

    const response = await axios.post(url, body, {
    });

    console.log("[showPrefCardList]", response);

    return response.data ? response.data : null;
};

/**
 * Send an email to invite vendor
 * @param {String} cardId
 * @returns {Object}
 */
export const showPrefCardDataById = async ({cardId, hospital}) =>  {
    const url = `${NODE_SERVER_ENDPOINT}api/prefcard/getcarddetails`;
    const body = {
        "cardId": cardId,
        hospital
    };

    const response = await axios.post(url, body, {
    });

    console.log("[showPrefCardDataById]", response);
    
    return response.data ? response.data : null;
};