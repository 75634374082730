import React, { useState, useEffect } from "react";
import { Button, TextField, Popover } from "@material-ui/core";
// import { onChangeNumber } from "../../components/Modals/handles";
import CreateIcon from '@material-ui/icons/Create';
import DoneIcon from '@material-ui/icons/Done';
import useStyles from "../surgicalevent/styles";


/**
 * Edit Price Pop
 * @param {String} priceValue 
 * @param {Function} setPriceValue 
 * @param {Object} contract 
 * @param {String} placeholder 
 * @returns 
 */
export default function EditPrice ({
  priceValue, setPriceValue, contract, placeholder="Price",
}) {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleSetPrice = () => {
    if (typeof setPriceValue === 'function') setPriceValue(value, contract);
    handleClose();
  };


  useEffect(() => {
    setValue(priceValue || "");
  }, [priceValue]);


  return (
    <div className={classes.editFlicker} >
      <CreateIcon onClick={handleClick}/> 
      <Popover
      className={classes.editFlickerPopper} 
        id={`simple-popover-${contract ? contract.id : ''}`}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="edit-flick-item">
          <div className={classes.fieldGrp}>
            <TextField
              id="input-with-icon-adornment7"
              placeholder={placeholder}
              // className={classes.customFullWidth}
              value={value}
              InputLabelProps={{
                  shrink: true,
              }}
              onChange={(e) => {
                // if (onChangeNumber(e)) setValue(onChangeNumber(e));
                setValue(e.target.value);
              }}
              variant="outlined"
              type="number"
            />  
            <Button className={classes.doneIcon}  onClick={handleSetPrice}><DoneIcon /></Button> 
          </div>
        </div>
      </Popover>
    </div>
  );
}
  