import { validateModelItem } from "./handles";

export const SurgicalEventComplianceModel = {
  id: '',
  date: '',
  user: '',
  role: '',
  ruletypelevel: '',
  ruletype: '',
  rulemessage: '',
  ruleoutcome: '',
};
export const validateSurgicalEventCompliance = (surgicalEventCompliance) => {
  return (surgicalEventCompliance.date !== ""
  || surgicalEventCompliance.user !== ""
  || surgicalEventCompliance.role !== ""
  || validateRuleTypeLevel(surgicalEventCompliance.ruletypelevel)
  || validateRuleType(surgicalEventCompliance.ruletype)
  || surgicalEventCompliance.rulemessage !== ""
  || validateOutcome(surgicalEventCompliance.ruleoutcome)
  );
};
export const getSurgicalEventCompliance = (surgicalEventCompliance) => {
  let newSurgicalEventCompliance = {};
  if (typeof surgicalEventCompliance.id === "string" && surgicalEventCompliance.id !== "") newSurgicalEventCompliance.id = surgicalEventCompliance.id;
  newSurgicalEventCompliance.date = surgicalEventCompliance.date || "";
  newSurgicalEventCompliance.user = surgicalEventCompliance.user || "";
  newSurgicalEventCompliance.role = surgicalEventCompliance.role || "";
  newSurgicalEventCompliance.ruletypelevel = surgicalEventCompliance.ruletypelevel;
  newSurgicalEventCompliance.ruletype = surgicalEventCompliance.ruletype;
  newSurgicalEventCompliance.rulemessage = surgicalEventCompliance.rulemessage || "";
  newSurgicalEventCompliance.ruleoutcome = surgicalEventCompliance.ruleoutcome;
  return newSurgicalEventCompliance;
};
export const checkComplianceStatus = (surgicalEventCompliance) => {
  let status = "";
  try {
    const {surgicalevent, auditedproductdata} = surgicalEventCompliance;
    if ((surgicalevent.surgicalside !== 'NotApplicable' && !!surgicalevent.surgicalside)
      && (auditedproductdata.sepproductside !== 'NotApplicable' && !!auditedproductdata.sepproductside)
      && surgicalevent.surgicalside !== auditedproductdata.sepproductside) status = status + 'ProcedureSide';
    // if ((new Date(surgicalevent.eventdate).getTime()) >= (new Date(auditedproductdata.sepproductexpiration).getTime())) 
    if (!!surgicalevent.eventdate
      && !!auditedproductdata.sepproductexpiration
      && surgicalevent.eventdate >= auditedproductdata.sepproductexpiration) 
      status = status + 'ExpirationDate';
    if (auditedproductdata.sepproductwaste === 'Waste')  status = status + 'ProductWaste';
    if (auditedproductdata.sepproductwaste === 'ImplantRemoved')  status = status + 'ProductImplantRemoved';
  }
  catch(e) {
  }
  return status;
};
/**
 * check compliance for Expiration Date with surgicalevent, productdata
 * @param {Object} surgicalevent 
 * @param {Object} productdata 
 * @param {Boolean} isEnable 
 */
export function checkComplianceStatusExpiration(surgicalevent, productdata, isEnable=true) {
  return isEnable && 
          (checkComplianceStatus({surgicalevent: surgicalevent, auditedproductdata: productdata})
            .includes('ExpirationDate'));
}
/**
 * check compliance for Surgical Side with surgicalevent, productdata
 * @param {Object} surgicalevent 
 * @param {Object} productdata 
 * @param {Boolean} isEnable 
 */
export function checkComplianceStatusSide(surgicalevent, productdata, isEnable=true) {
  return isEnable && 
          (checkComplianceStatus({surgicalevent: surgicalevent, auditedproductdata: productdata})
            .includes('ProcedureSide'));
}
/**
 * check compliance for ProductWaste with surgicalevent, productdata
 * @param {Object} surgicalevent 
 * @param {Object} productdata 
 * @param {Boolean} isEnable 
 */
export function checkComplianceProductWaste(surgicalevent, productdata, isEnable=true) {
  return isEnable && 
          (checkComplianceStatus({surgicalevent: surgicalevent, auditedproductdata: productdata})
            .includes('ProductWaste'));
}
/**
 * check compliance for ProductImplantRemoved with surgicalevent, productdata
 * @param {Object} surgicalevent 
 * @param {Object} productdata 
 * @param {Boolean} isEnable 
 */
export function checkComplianceProductImplantRemoved(surgicalevent, productdata, isEnable=true) {
  return isEnable && 
          (checkComplianceStatus({surgicalevent: surgicalevent, auditedproductdata: productdata})
            .includes('ProductImplantRemoved'));
}

export const Outcomes = {
  Acknowledged: 'Acknowledged',
  Declined: 'Declined',
};
export const validateOutcome = validateModelItem(Outcomes);

export const RuleTypeLevels = {
  Critical: 'Critical',
  High: 'High',
  Medium: 'Medium',
  Low: 'Low',
};
export const validateRuleTypeLevel = validateModelItem(RuleTypeLevels);

export const RuleTypes = {
  ExpirationDate: 'Expiration Date',
  ProcedureSide: 'Procedure Side',
  ProductWaste: 'Product Waste',
  ProductImplantRemoved: 'Implanted and removed',
  OffContractUsage: 'Off Contract Usage',
};
export const validateRuleType = validateModelItem(RuleTypes);
