import { PO_SET, PO_SET_ALL, PO_SET_DATA } from "./constants";

export const setPO = (payload) => ({
  type: PO_SET,
  payload
});

export const setPOAll = (payload) => ({
  type: PO_SET_ALL,
  payload
});

export const setPOData = (payload) => ({
  type: PO_SET_DATA,
  payload
});
