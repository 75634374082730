import React from "react";
import classNames from "classnames";
import { useParty } from "@daml/react";
import { Button, Chip } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import HospitalFilter from '../inventoryaccount/HospitalFilter';
import TreeContracts from "../../components/Contracts/TreeContracts";
import AdaptiveSearch from "../../components/Search/adaptivesearch";
import archiveIcon from "../../icons/folder-plus.svg";
import greenConfirm from "../../icons/check6.svg";
import blueConfirm from "../../icons/check1.svg";
import archiveIconDis from "../../icons/folder-plus-disable.svg";
import DateSearch from "../../components/Search/datesearch";
import { useDamlState } from "../../context/DamlContext";
import useStyles from "./styles";
import withLoading from "../../hoc/withLoading";


/**
 * Restock Inventory Contracts for Operator
 * @param {Object} searchParams 
 * @param {Object} searchParamsSurgical 
 * @param {Function} handleChangeSearchValue 
 * @param {Array} allContracts 
 * @returns 
 */
function ScanInventoryOperatorContracts({
  searchParams = {},
  handleChangeSearchValue = null,
  allContracts = [],
  searchParamsSurgical = {},
}) {

  const classes = useStyles();
  // const history = useHistory();
  // const scanRef = useRef();
  const operator = useParty();
  // const ledger = useLedger();
  const { roleCid } = useDamlState();

  console.log("[ScanInventoryOperatorContracts]", operator, roleCid);
  // const status = "Shipped";
  /* 
  const getVendors = () => {
      return assetsVendor.contracts.map(c => {
          return <MenuItem key={c.contractId} value={c.payload.vendor}>{c.payload.vendor}</MenuItem>;
      });
  };

  const getHospitals = () => {
      return assetsHospital.contracts.map(c => {
          return <MenuItem key={c.contractId} value={c.payload.hospital}>{c.payload.hospital}</MenuItem>;
      });
  }; */


  const handleDeleteSubProduct = (product) => {


  }
  const handleManageProductModal = (c) => {

  }

  const handleRequest = () => {

  }

  return (
    <>
      <div key="formChildStep" className="formsteps">
        <HospitalFilter title="Restock Inventory" />
        <TreeContracts
          className={classes.customRoot}
          contracts={allContracts}
          columns={[
            // ["ContractId", "contractId"],
            ["SE Id", "seid", {
              type: "link",
              onClick: (contractId, contract) => {
              },
              search: {
                component:
                  <AdaptiveSearch
                    getValue={(c) => c.payload.seid}
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'seid')}
                    defaultValue={searchParamsSurgical.surgicaleventdata.seid}
                    label="Search SE ID"
                    contracts={[]}
                    isShrink={true}
                  />
              },
              isSearched: searchParamsSurgical.surgicaleventdata.seid,
              handleClear: () => handleChangeSearchValue('', 'seid'),
              options: {
                cellClassName: classNames(classes.primaryColor),
                headerClassName: classes.staticCell,
                width: 120
              }
            }],
            ["Event Date", "eventdate", {
              search: {
                component:
                  <DateSearch
                    handleChangeSearch={(v) => handleChangeSearchValue(v, 'eventdate')}
                    defaultValue={''}
                    label="Search Event Date :"
                    isStatic={true}
                  />
              },
              // isSearched: searchSurgical.eventdate,
              // handleClear: () => handleChangeSearchValue('', 'eventdate'),
              options: {
                width: 160,
              },
            }],
            ["Procedure", "procedure"],
            ["Products", "products"],
            ["PO Details", "purchaseorders", {
              type: "collapse",
              columns: [
                ["PO Number", "purchaseorders.popurchaseorderid", {
                  type: 'link',
                  onClick: (contractId, contract) => {
                    // const data = (!!contract) ? contract : contracts.find(c => c.contractId === contractId);
                  },
                  search: {
                    component:
                      <AdaptiveSearch
                        getValue={(c) => c.popurchaseorderid}
                        handleChangeSearch={(v) => handleChangeSearchValue(v, 'popurchaseorderid')}
                        defaultValue={''}
                        label="Search PO Number"
                        contracts={allContracts}
                        isShrink={true}
                      />
                  },
                  // isSearched: searchSurgical.eventdate,
                  // handleClear: () => handleChangeSearchValue('', 'eventdate'),
                  options: {
                    width: 170,
                    cellClassName: classes.nobreak
                  }
                }],
                ["Status", "purchaseorders.status", {
                  type: "render",
                  render: (param) => (<Chip
                    label={param.value}
                    size="small"
                    className={classNames(classes.smallChip, (param.value === 'Complete' ? classes.backGreen : (
                      param.value === 'Cancelled' ? classes.backRed : classes.backYellow
                    )))}
                  />),
                  search: {
                    component:
                      <AdaptiveSearch
                        getValue={(c) => c.payload.status}
                        handleChangeSearch={(v) => handleChangeSearchValue(v, 'status')}
                        defaultValue={''}
                        label="Search Status"
                        contracts={[]}
                        isShrink={true}
                      />
                  },
                  // isSearched: searchSurgical.eventdate,
                  // handleClear: () => handleChangeSearchValue('', 'eventdate'),
                  options: {
                    width: 125
                  }
                }],
                ["Manufacturer", "manufacturer", {
                  search: {
                    component:
                      <AdaptiveSearch
                        getValue={(c) => c.payload.manufacturer}
                        handleChangeSearch={(v) => handleChangeSearchValue(v, 'manufacturer')}
                        defaultValue={''}
                        label="Search Manufacturer"
                        contracts={[]}
                        isShrink={true}
                      />
                  },
                  // isSearched: searchSurgical.eventdate,
                  // handleClear: () => handleChangeSearchValue('', 'eventdate'),
                  options: {
                    width: 170
                  }
                }],
                ["Ownership", "purchaseorders.ownership"],
                ["Received Products", "purchaseorders.received_products", {
                  options: {
                    width: 190
                  }
                }],
                ["Total Amount", "purchaseorders.total_amount", {
                  options: {
                    beforeText: '$',
                    width: 180
                  }
                }],
                ["PO Lines", "purchaseorderdetaildata", {
                  type: "collapse",
                  columns: [
                    ["Reference Number", "purchaseorders.purchaseorderdetaildata.sepreferencenumber", {
                      options: {
                        headerClassName: classes.minWdt165
                      }
                    }],
                    ["Product name", "purchaseorderdetaildata.product_name"],
                    ["Expiration", "purchaseorderdetaildata.expiration"],
                    ["Price", "purchaseorderdetaildata.price"],
                    ["Lot Code", "purchaseorderdetaildata.lot_code"],
                    ["Product Type", "purchaseorderdetaildata.product_type"],
                    ["Receipt Status", "purchaseorderdetaildata.Receipt Status", {
                      type: "render",
                      render: (param) => (<Chip
                        label={param.value}
                        size="small"
                        className={classNames(classes.smallChip, (param.value === 'Complete' ? classes.backGreen : (
                          param.value === 'Cancelled' ? classes.backRed : classes.backYellow
                        )))}
                      />),
                    }],
                  ],
                  actions: [
                    [
                      "Action",
                      [
                        [["Delete", [<img alt="" src={greenConfirm} />, <img alt="" src={blueConfirm} />],
                          (product) => ''],
                          handleDeleteSubProduct],
                      ],
                    ],
                  ],
                }],
              ],
              actions: [

              ],
              options: {
                renderHeading: (
                  <Button
                    // className="yesBtn"   isEnableSendVendor(c.payload.purchaseorderdata.postatus) &&
                    className={classes.newSetupButton}
                    variant="contained"
                    color="primary"
                    onClick={(e, c) => {
                      // get selected contract(parentContract of TreeContracts)
                      // console.log("[PurchaseOrderListContracts] clicked", c);
                      handleManageProductModal(c);
                    }}
                  >
                    <span className="desktop">Add PO</span>
                    <span className="mobile"><AddIcon /></span>

                  </Button>
                )
              },
            }],
          ]}

          actions={[[
            "Actions",
            [
              [["Send PO", [<img alt="" src={archiveIcon} />, <img alt="" src={archiveIconDis} />],
                (c) => ''],
                handleRequest],
              /* [["Archive PO", [<img alt="" src={archiveIcon} />, <img alt="" src={archiveDisableIcon} />],
                (c) => isEnableArchive(c.payload.purchaseorderdata.postatus) && checkNotPOHistory(c)],
                handleArchivePO], */
            ],
          ]]}
          options={{
            statusEmpty: (allContracts.length === 0)
          }}
        />
      </div>

    </>
  );
}

export default withLoading(ScanInventoryOperatorContracts);
