import React from "react";
import classNames from "classnames";
import { Grid, Typography} from "@material-ui/core";
import ModalDialog from '../../components/Modals/NewGeneralModal';
import patientIcon  from "../../icons/new-se-patient-icon.svg";
import useStyles from "../surgicalevent/styles";
import AuditPO from './auditDetails-product';
import useStyles1 from "../purchaseorder/styles";



/**
 * Purchase Order Title Component
 * @param {Object} classes 
 * @param {Object} classes1 
 * @param {Object} poData 
 * @returns 
 */
 
/**
 * Dialog when Create or Edit Purchase Order
 * @param {Boolean} open
 * @param {Function} setOpen
 * @param {Object} contract
 */
export default function AuditDetailPO ({
  open, setOpen, contract={}, 
}) {

  const classes = useStyles();
  const classes1 = useStyles1();
  const poData = contract.payload;
 

  return (
    <>

      <ModalDialog
        open={open}
        setOpen={setOpen}
        title={(
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              className={classNames(classes.newSETitlePanel, classes1.newPOTitlePanel)}
            >
              <img src={patientIcon} alt="" />
              <Typography variant="inherit" component="span" className="label" >
                Audit Log Details -
              </Typography>
              {(poData?.event === 'CreatePO') ?
                <Typography variant="inherit" component="span" className="label" >
                Created PO
                </Typography> : <Typography variant="inherit" component="span" className="label" >
                Sent PO
                </Typography>
              }
              <Typography variant="inherit" component="span" className="value" >
              </Typography>
            </Grid>
  
          )}

        content={(
          <Grid 
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        className={classes1.createPanel}
      >
          <Grid item>
           {
          <AuditPO
          poData = {poData}
          />
        }
        </Grid>
        </Grid>
        )}
      />

    </>
  );
}
