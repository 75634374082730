import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  detailTabPanelMenu: {
    marginTop: '-5px',
    background: 'none',
    width: '985px',
    '& .MuiTab-root.MuiTab-textColorPrimary': {
      padding: '0px 12px',
      fontWeight: 700,
      fontSize: '25px',
      lineHeight: '30px',
    },
  },
  overviewForm: {
    padding: '18px 50px',
    '& .label': {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '23px',
      color: '#000000',
    },
  },
  detailForm: {
    '& .MuiTableCell-root': {
      padding: '6px 0px 7px 0px',
    },
  },
  formField: {
    '& .MuiFormLabel-root': {
      color: '#000000',
      fontSize: '14px',
      lineHeight: '16px',
    },
    '& .MuiInputBase-root': {
      background: '#FFFFFF !important',
    },
    '&.lg': {
      '& .MuiFormLabel-root': {
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '22px',
      },
      '& .MuiInputBase-root': {
        height: '38px',
      },
    },
  },
  newBtn: {
    padding: '6px 17px 6px 17px',
    borderRadius: '3px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
  },
  newUploadFile: {
    marginLeft: 'auto',
    marginRight: '48px',
    width: '344px',
    '& .MuiDropzoneArea-root': {
      border: '2px dashed #CCCCCC',
      borderRadius: '10px',
      marginBottom: '0px',
      minHeight: '195px',
      '& .MuiDropzoneArea-text': {
        margin: '0px',
        background: '#1C3C74',
        fontWeight: 800,
        fontSize: '15px',
        lineHeight: '18px',
        color: '#FFFFFF',
        padding: '10px',
      },
      '& .newPdfLogos': {
        marginTop: '12px',
        marginBottom: '10px',
      },
      '& .MuiDropzonePreviewList-root': {
        '& .MuiDropzonePreviewList-imageContainer': {
          '&.MuiGrid-grid-xs-4': {
            maxWidth: 'inherit',
            flexBasis: 'inherit',
          },
          '& .MuiDropzonePreviewList-removeButton': {
            display: 'none',
          },
          '& .preview-item': {
            background: '#1C3C74',
            display: 'flex',
            // width: '344px',
            marginTop: '5px',
            placeItems: 'center',
            placeContent: 'space-around',
            paddingLeft: '20px',
            '& .MuiTypography-root': {
              width: '226px',
              fontWeight: 600,
              fontSize: '15px',
              lineHeight: '18px',
              color: '#FFFFFF',
              overflow: 'hidden',
            },
          },
        },
      },
    },
  },
  contractDetail: {
    '& .contract-grid': {
      maxHeight: '490px',
      overflowY: 'scroll',
    },
    '& .button-panel': {
      marginTop: '11px',
      marginBottom: '13px',
      marginRight: '17px',
      textAlign: 'right',
      padding: 0,
      '& .MuiButtonBase-root.MuiButton-containedPrimary': {
        marginLeft: '9px',
        padding: '9px 17px 9px 17px',
        fontSize: '15px',
        lineHeight: '18px',
      },
    },
    '& .contract-panel': {
      marginLeft: '177px',
      width: '700px',
      // maxHeight: '170px',
      // overflowY: 'scroll',
      padding: 0,
      marginBottom: '30px',
    },
    '& .contract-header': {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '21px',
      color: '#1C3C74',
      marginTop: '16px',
      marginBottom: '10px',
    },
    '& .contract-table': {
      borderTop: '1px solid #CCCCCC',
      borderBottom: '1px solid #CCCCCC',
      '& .MuiTableCell-root': {
        border: '1px solid #CCCCCC',
        padding: '4px 18px',
        fontWeight: 700,
        fontSize: '15px',
        lineHeight: '18px',
        color: '#000000',
        borderWidth: '0px 1px 0px 1px',
        '&.MuiTableCell-head': {
          background: '#F5F5F5',
          // borderWidth: '1px 0px 1px 0px',
          borderWidth: 0,
          position: 'sticky',
          top: 0,
          zIndex: 1,
          '&:first-child': {
            borderLeftWidth: 1,
          },
          '&:last-child': {
            borderRightWidth: 1,
          },
        },
        '& .MuiIconButton-root': {
          padding: '2px',
          float: 'right',
        },
      },
    },
  },
  contentPanel: {
    padding: '12px 54px 12px 54px',
  },
}), { index: 1 });
