import React from "react";
import { useLedger, useParty } from "@daml/react";
// import { PublicLedger, WellKnownPartiesProvider } from "@daml/dabl-react";
// import { CreateEvent } from "@daml/ledger";
import {useOperator} from "../../components/common/common";
import { getTokenProjectDabl } from "../../services/axios/daml-json-api";

export default function OnboardDAContents() {
  const party = useParty();
  const ledger = useLedger();
  const operator = useOperator();
  
  console.log("party : ", party);
  console.log("ledger : ", ledger);
  console.log("operator : ", operator);
  
  getTokenProjectDabl();

  return (
    <>
    </>
  );
}

