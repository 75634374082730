import React, { useCallback, /* useEffect, useState */ } from "react";
import classNames from "classnames";
// import { useParty, useStreamQueries } from "@daml/react";
import { contractFilter1 } from "../../services/daml-filters";
import Contracts from "../../components/Contracts/Contracts1";
import useStyles from "./styles";
import ProductDetailViewTable from "../surgicalevent/product-details-view-newtable";
// import { RestockInventory } from "../../services/daml-modules1";
// import { useDamlState } from "../../context/DamlContext";

/**
 * Incoming Inventory Contracts
 * @param {Object} searchParams 
 * @param {Array} allcontracts 
 * @param {Object} options 
 * @returns 
 */
function PoContract1({
    searchParams = {},
    allcontracts = [],
    invoicedata,
    options = {},
    contractData,
}) {

    const classes = useStyles();
    // allcontracts = allcontracts.map(obj => ({ invdproduct: obj }));
    let contracts = contractFilter1(allcontracts, searchParams);
    console.log("contracts", contracts);

    // Detail Panel
    const getDetailPanelContent = useCallback(({ row }) => (
        <ProductDetailViewTable
            obj={row.contract.iproduct}
            options={{
                extraParams: [{ className: classes.productClass, className2: classes.rowClass1 }],
            }}
        />
    ), [classes.productClass, classes.rowClass1]);

    /* 
    // This is not good solution to get QoO
    // Instead, damlFetchRestockInventory is used in DetailViewDrawer
    const hospital = useParty();
    const { role } = useDamlState();
    let query = (role === "Vendor") ? ({ vendor: hospital }) : { hospital }
    const { contracts: newContracts } = useStreamQueries(RestockInventory, () => [query]);
    const [contractData, setContractData] = useState();

    // Update Contract
    const updateContract = useCallback(() => {
        // find out the Restock Inventory contract by PO number
        const found = newContracts.find(c => c.payload.ipurchaseorderdata.popurchaseorderid === invoicedata.invpurchaseorderid)
        // console.clear();
        // console.log("[IncomingInventory] updateContract", found);
        setContractData(found);
    }, [newContracts, invoicedata]);

    useEffect(() => {
        updateContract();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newContracts]);
    */


    return (
        <>
            <Contracts
                contracts={contractData?.payload?.inventorydata}
                columns={[
                    ["Reference Number", "iproduct.sepreferencenumber", {
                        options: {
                            width: 90,
                            cellClassName: classNames(classes.customText),
                            headerClassName: classes.customText,
                        },
                    }],
                    ["Lot Code", "iproduct.seplotcode", {
                        options: {
                            width: 90,
                            cellClassName: classNames(classes.customText),
                            headerClassName: classes.customText,
                        }
                    }],
                    ["Description", "iproduct.sepproductdescription", {
                        options: {
                            width: 100,
                            cellClassName: classNames(classes.customText),
                            headerClassName: classes.customText,
                        },
                    }],
                    ["Price", "iproduct.sepproductprice", {
                        options: {
                            flex: 1,
                            beforeText: '$',    
                            cellClassName: classNames(classes.customText),
                            headerClassName: classes.customText,
                        }
                    }],
                    ["Rec Status", "iproductstatus", {
                        options: {
                            width: 80,
                            cellClassName: classNames(classes.customText),
                            headerClassName: classes.customText
                        }
                    }]
                ]}

                options={{
                    checkbox: false,
                    height: '340px',
                    headerHeight: 38,
                    rowHeight: 38,
                    getDetailPanelContent: getDetailPanelContent,
                    isFooter: false,
                    isPagination: false
                }}

            />
        </>
    );
}

export default PoContract1;