import React, { useEffect } from "react";
import classNames from "classnames";
import { Popover, Box, Grid, Typography, IconButton, List, ListItem, Avatar, Badge, Chip } from "@material-ui/core";
import { styled } from '@material-ui/styles';
import { MoreVert, Send } from '@material-ui/icons';
import { validatePOComment } from "../../models/POStatus";
import { getPartyLabel } from "../../models/handles";
import { useDamlState } from '../../context/DamlContext';
import { PO_COMMENT_EVENT, EVENT_REQUIRED } from "../../store/actions/constants";
import { useComment, useVendorUser } from "../../hooks/usePO";
import useEvent from "../../hooks/useEvent";
import { useHospitalUser } from "../../hooks/useProduct";
import { getTodayString, getYesterdayString, formatDate } from "../../services/util";
import useStyles from "./styles";
import { CustomTextField } from "../../components/Inputs";


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#4CAF50',
    color: '#4CAF50',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
}));

/**
 * PO Comments Popover
 * Show All PO Comments
 * @param {Object} anchorEl 
 * @param {Function} setAnchorEl 
 * @param {Boolean} isSendable 
 * @returns 
 */
export default function POCommentPopover ({
  anchorEl, setAnchorEl, isSendable=true, 
}) {

  const { role, party, userParty } = useDamlState();
  const { hospital } = useHospitalUser();
  const { comments: purchaseordercomment, comment: poComment, setComment: setPoComment } = useComment();
  const { event, status, onEventStart, onEventVerify, onEventReject } = useEvent();
  const { vendor } = useVendorUser();

  const classes = useStyles();
  const defaultUser = userParty ? userParty : party; // Use the user party instead of organization party

  
  useEffect(() => {
    if (event === PO_COMMENT_EVENT && status === EVENT_REQUIRED) {
      // console.log("[POCommentPopover], PO_COMMENT_EVENT & EVENT_REQUIRED", poComment);
      if (validatePOComment(poComment) && !!poComment.note) {
        onEventVerify();
      }
      else {
        onEventReject();
      }
    }
  }, [event, status, poComment, onEventVerify, onEventReject]);


  // Check out if it's Hospital or Vendor
  const otherParty = (role === 'Hospital') ? vendor : hospital;
  // Check out if it's Today, Yesterday, etc.
  const today = getTodayString();
  const yesterday = getYesterdayString();
  let comments = [], tempDate;
  for (let i in purchaseordercomment) {
    const comment = { ...purchaseordercomment[i] };
    if (comment.date !== tempDate) {
      tempDate = comment.date;
      const strDate = (tempDate === today) ? "Today" : (tempDate === yesterday ? "Yesterday" : formatDate(tempDate));
      comments.push({date: strDate});
    }
    comment.isMe = (comment.user === defaultUser);
    // Add role if it's InventoryRequestComment
    if (!comment.role && comment.status && comment.returntype !== undefined) {
      if (comment.status === 'RequestSent') comment.role = 'Hospital';
      else comment.role = 'Vendor';
    }
    // Old case: Check status & Detect Hospital/Vendor Side
    // const isVendorSide = (comment.status === 'Accepted' || comment.status === 'Rejected');
    // Other case: Check the same organization
    // get all parties in the same organization -> teammates
    // comment.isRightSide = (teammates.indexOf(comment.user) >= 0);
    // New case: Check the role
    const isVendorSide = (comment.role === 'Vendor');
    comment.isRightSide = (role === 'Hospital' ^ isVendorSide);
    comments.push({comment});
  }
  // console.log("[POCommentPopover]", role, defaultUser, hospital, otherParty, purchaseordercomment, comments);

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  // handle to click Send button
  const handleSendClick = () => {
    // add a user in pocomment
    setPoComment({ ...poComment, user: defaultUser });
    // event start to add a new pocomment
    onEventStart(PO_COMMENT_EVENT);
    // setAnchorEl(null);
  };


  if (!otherParty) return null;
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box className={classes.newPOComments}>
        <Grid container direction="column">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className="pocomments-header"
          >
            <Grid item style={{marginLeft: '10px'}}>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                variant="dot"
              >
                <Avatar className={classes.newAvatar}>
                  {getPartyLabel(otherParty)}
                </Avatar>
              </StyledBadge>
            </Grid>
            <Grid item>
              <Typography className="party-name">{otherParty}</Typography>
              <Chip label="Online" className="online-chip"/>
            </Grid>
            <Grid item>
              <IconButton onClick={() => {}}>
                <MoreVert />
              </IconButton>
            </Grid>
          </Grid>

          <Grid item className="pocomments-list">
            <List className={classNames(classes.customRootComment, classes.newCommentList)} >
              { comments.map(({comment: obj, date},index) => (
                <ListItem 
                  key={index} 
                  className={(obj ? (obj.isRightSide ? "rightComment" : "leftComment") : "datecomment")}
                >
                  {
                    obj && 
                    <Box>
                      <Box className={classNames((obj.isRightSide ? "me" : "you"), "message")}>
                        <Typography className="note">{obj.note}</Typography>
                        <Typography className="date">{obj.date}</Typography>
                      </Box>
                      {
                        !obj.isMe && 
                        <Typography className="user">{obj.user}</Typography>
                      }
                    </Box>
                  }
                  {
                    date &&
                    <Chip label={date} className="date-chip"/>
                  }
                </ListItem>
              ))}
            </List>
          </Grid>

          {
            isSendable &&
            <Grid item className="pocomments-bottom">
              <CustomTextField
                value={poComment.note || ""}
                onChange={(val) => setPoComment({ ...poComment, note: val })}
                placeholder="Type message here..."
                width={172}
                className="message-box"
              />
              <IconButton onClick={handleSendClick}>
                <Send />
              </IconButton>
            </Grid>
          }
        </Grid>
      </Box>
    </Popover>
  );
}
