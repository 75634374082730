import { validateGender } from "./Contact";
import { validateRole } from "./Role";
import { validateUserAccessLevel, validateUserStatus } from "./UserAccessLevel";
import { createPartyFromName, requiredFields, getModelMenu } from "./handles";

export const UserModel = {
  party:'',
  userid:'',
  role: '',
  name:'',
  gender:'',
  officephone:'',
  phone:'',
  email:'',
  accesslevel:'',
  jobtitle:'',
  userstatus:'',
};

export const validateUser = (user) => {
  return ((user.party !== ""
    || user.officephone !== ""
    || user.phone !== ""
    || user.profileimg !== ""
    || validateRole(user.role)
    || validateGender(user.gender)
    || validateUserStatus(user.userstatus))
    && user.userid !== ""
    && user.name !== ""
    && user.email !== ""
    && validateUserAccessLevel(user.accesslevel)
    && user.jobtitle !== ""
    // && validateUserJobTitle(user.jobtitle)
  );
};

export const getUser = (user, isCreatePartyFromName=true) => {
  let newUser = {};
  newUser.party = user.party || "";
  if (isCreatePartyFromName && !newUser.party) newUser.party = createPartyFromName(user.name);
  newUser.userid = user.userid || "";
  newUser.name = user.name || "";
  newUser.role = user.role || "";
  newUser.gender = user.gender || "";
  if (typeof user.officephone === "string" && user.officephone !== "") newUser.officephone = user.officephone;
  if (typeof user.phone === "string" && user.phone !== "") newUser.phone = user.phone;
  newUser.email = user.email || "";
  newUser.accesslevel = user.accesslevel || "";
  newUser.jobtitle = user.jobtitle || "";
  newUser.profileimg  = user.profileimg  || "";
  newUser.isdarkenable  = user.isdarkenable  || false ;
  newUser.userstatus = user.userstatus || "";
  return newUser;
};

export const requiredUser = (user) => requiredFields(user, {
  'userid':'User Id',
  'name':'User Name',
  'email':'User Email',
  // 'gender':'Gender',
  'accesslevel':'Access Level',
  'jobtitle':'Job Title',
  // 'userstatus':'User Status',
});

export const requiredUserInvite = (user) => requiredFields(user, {

  // 'userid':'User Id',
  'distributor':'Distributor',
  'name':'User Name',
  'email':'User Email'

});

export const UserStatus = {
  Enabled: 'Enabled',
  Disabled: 'Disabled',
};
export const getUserStatus = getModelMenu(UserStatus);
