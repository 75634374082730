import { checkEnableRoute } from "../Routes/handle";
import { setSearchFilters, clearSearchFilters } from "../../hooks/useSearchFilters";

/**
 * Pages Table in Header
 */
export const PagesTable = [
  {label: 'Home', link: '/app/welcome'},
  {
    label: 'Procedure',
    menus: [
      {label: 'Surgical Events', link: '/app/surgicalevent'},
      {label: 'Post Procedure Forms', link: '/app/postprocedurelist'},        // Hospital
      {label: 'Post Procedure Forms', link: '/app/vendorpostprocedurelist'},  // Vendor
      {label: 'Compliance', link: '/app/compliancelist'},
      // {label: 'Implant Look up', link: '/app/importfile'},
    ]
  },
  {
    label: 'Inventory', 
    menus: [
      {label: 'My Inventory', link: '/app/inventorylist'},                    // Hospital, Operator
      {label: 'Facility Inventory', link: '/app/inventorylist-facility'},     // Vendor
      {label: 'Incoming Inventory', link: '/app/inventoryscan-po'},           // Hospital
      {label: 'Outgoing Inventory', link: '/app/inventoryscan'},              // Vendor, Operator
      // {label: 'Return Inventory', link: '/app/return-inventory'},             // Hospital
      {label: 'Incoming Inventory', link: '/app/received-inventory'},         // Vendor
      // {label: 'Recall', link: '/app/recall-lookup-list'},                     // Hospital
      // {label: 'Recall', link: '/app/recall-lookup'},                          // Vendor
    ]
  },
  {
    label: 'Finance', 
    menus: [
      {label: 'Purchase Order', link: '/app/purchaseorder-list'},             // Hospital
      {label: 'Purchase Order', link: '/app/receivedpo'},                     // Vendor
      {label: 'Invoice', link: '/app/receivedinvoice'},                       // Hospital
      {label: 'Invoice', link: '/app/invoice'},                               // Vendor
      {label: 'Payments', link: '/app/sentpayments'},                         // Hospital
      {label: 'Payments', link: '/app/receivedpayments'},                     // Vendor
      {label: 'Holiday', link: '/app/viewpaymentcalendar'},                   // Operator
      {label: 'Payments Log', link: '/app/viewpaymentlogs'},                  // Operator
      {label: 'Contract Management', link: '/app/contract-management'},       // Hospital
    ]
  },
];

export const SAPHeader=[
  {label:"Home",handleClick:(history)=>{history.push("/app/welcome");}},
  {label:"Payment",handleClick:(history)=>{history.push("/app/sentpayments");}},
];

/**
 * Universal Search
 * SEID, PO ID, Invoice ID, Inventory REF
 * 1. SEID e.g. B21F6D8547
 * 2. PO ID e.g. PO1036, T220000
 * 3. Invoice ID e.g. InvoiBioMed-FirstHealth-2022-02-10-4
 * 4. Inventory REF e.g. 5531-G-512-E
 * @param {String} word 
 * @param {Object} options 
 * @returns {String|null}
 */
export const universalSearch = (word, options={}) => {
  options = {
    po_prefixes: ['PO', 'T'],     // prefix list for PO
    invoice_prefixes: ['Invoi'],  // prefix list for Invoice
    category: null,               // SE: desc, seid, lot, ref
    ...options,
  };
  let regs = [], reg;
  // 1. SEID 
  if (options.category && ['desc', 'lot', 'ref'].includes(options.category)) return 'Surgical Events';
  if (/^[A-Z0-9]{10}$/.test(word)) return 'Surgical Events';
  // 2. PO ID
  regs = options.po_prefixes.map(op => new RegExp(`^${op}[0-9]+$`));
  reg = new RegExp(regs.map(reg => reg.source).join('|'));
  if (reg.test(word)) return 'Purchase Order';
  // 3. Invoice ID
  regs = options.invoice_prefixes.map(op => new RegExp(`^${op}[a-zA-Z0-9-]+$`));
  reg = new RegExp(regs.map(reg => reg.source).join('|'));
  if (reg.test(word)) return 'Invoice';
  // 4. Inventory REF
  if (/^[a-zA-Z0-9-]+$/.test(word)) return 'My Inventory';
  return null;
};

/**
 * Universal Search to Page to redirect and Redux to store
 * @param {String} label 
 * @param {String} value 
 * @param {String} role 
 * @param {String} accesslevel 
 * @param {String} category 
 * @param {Array} uidetaills 
 * @returns {String}
 */
export const searchPageRedux = (label, value, role, accesslevel, category=null, uidetaills=[]) => {
  if (role !== 'Hospital' && role !== 'Vendor') return null;
  
  let key = null, link = null;

  if (label === 'Surgical Events') {
    if (category === 'desc') key = 'surgicaleventproductdata.product.sepproductdescription';
    else if (category === 'lot') key = 'surgicaleventproductdata.product.seplotcode';
    else if (category === 'ref') key = 'surgicaleventproductdata.product.sepreferencenumber';
    else key = 'surgicaleventdata.seid';
    if (role === 'Vendor') label = 'Post Procedure Forms';
  }
  else if (label === 'Purchase Order') {
    //
  }
  else if (label === 'Invoice') {
    //
  }
  else if (label === 'My Inventory') {
    //
  }
  if (!key) return null;

  // get the redirect link
  PagesTable.forEach(p => {
    if (p.menus && p.menus.length) {
      p.menus.forEach(m => {
        if (m.label === label) {
          if (checkEnableRoute(m.link, role, accesslevel, uidetaills)) link = m.link;
        }
      });
    }
  });
  if (!link) return null;

  // store redux search filters
  clearSearchFilters();
  setSearchFilters(key, [value]);

  return link;
};
