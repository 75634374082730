import React, { useEffect, useState } from "react";
import {
  Popover, Box, Checkbox, List, ListItem, FormControlLabel, LinearProgress,
  Grid, Typography, Button
} from "@material-ui/core";
import useStyles from "./styles";
import SnackbarModal from "../../components/Modals/SnackbarModal";
import classNames from "classnames";


/**
 * Export Popover
 * @param {Object} anchor 
 * @param {Function} setAnchor 
 * @param {Function} handleExportCSV 
 * @param {Function} handleExportPDF 
 * @param {Function} handleExportPrefPDF 
 * @returns 
 */
export default function ExportPopover({
  anchor, setAnchor, handleExportCSV, handleExportPDF, handleExportPrefPDF, handlePrintClick,
  isSEscreen = false
}) {

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [checkedCSV, setCheckedCSV] = useState(false);
  const [checkedPDF, setCheckedPDF] = useState(false);
  const [checkedPrefPDF, setCheckedPrefPDF] = useState(false);
  const [checkedSurgicalPrint, setCheckedSurgicalPrint] = useState(false);
  const [checkedPrefCardPrint, setCheckedPrefCardPrint] = useState(false);
  const [progressCSV, setProgressCSV] = useState(0);
  const [progressPDF, setProgressPDF] = useState(0);
  const [progressPrefPDF, setProgressPrefPDF] = useState(0);
  const [progressSurgicalPrint, setProgressSurgicalPrint] = useState(0);
  const [progressPrefCardPrint, setProgressPrefCardPrint] = useState(0);
  // for toast notification after clicking EXPORT
  const [openToast, setOpenToast] = useState(false);
  const [toastContent, setToastContent] = useState('');

  const handleExportClick = async () => {
    setToastContent("Files created successfully");
    setOpenToast(true);
    if (checkedCSV) {
      await setProgressCSV(-1);
      if (typeof handleExportCSV === 'function') await handleExportCSV();
      await setProgressCSV(100);
    }
    if (checkedPDF) {
      await setProgressPDF(-1);
      if (typeof handleExportPDF === 'function') await handleExportPDF();
      await setProgressPDF(100);
    }
    if (checkedPrefPDF) {
      await setProgressPrefPDF(-1);
      if (typeof handleExportPrefPDF === 'function') await handleExportPrefPDF();
      await setProgressPrefPDF(100);
    }
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setAnchor(null);
  };

  const handleChecked = (event, key) => {
    if (key === 'CSV') {
      setCheckedCSV(event.target.checked);
      setProgressCSV(0);
    }
    else if (key === 'PDF') {
      setCheckedPDF(event.target.checked);
      setProgressPDF(0);
    }
    else if (key === 'PrefPDF') {
      setCheckedPrefPDF(event.target.checked);
      setProgressPrefPDF(0);
    }
    else if (key === 'SurgicalEventPrint') {
      setCheckedSurgicalPrint(event.target.checked);
      setProgressSurgicalPrint(0);
    }
    else if (key === 'PrefCardPrint') {
      setCheckedPrefCardPrint(event.target.checked);
      setProgressPrefCardPrint(0);
    }
  };

  useEffect(() => {
    if (anchor && anchorEl === null) {
      setAnchorEl(anchor);
      if (checkedCSV && progressCSV === 100) {
        setCheckedCSV(false);
        setProgressCSV(0);
      }
      if (checkedPDF && progressPDF === 100) {
        setCheckedPDF(false);
        setProgressPDF(0);
      }
      if (checkedPrefPDF && progressPrefPDF === 100) {
        setCheckedPrefPDF(false);
        setProgressPrefPDF(0);
      }
      if (checkedSurgicalPrint && progressSurgicalPrint === 100) {
        setCheckedSurgicalPrint(false);
        setProgressSurgicalPrint(0);
      }
      if (checkedPrefCardPrint && progressPrefCardPrint === 100) {
        setCheckedPrefCardPrint(false);
        setProgressPrefCardPrint(0);
      }
    }
  }, [anchor, anchorEl, checkedCSV, checkedPDF, checkedPrefPDF,checkedSurgicalPrint,checkedPrefCardPrint, progressCSV, progressPDF, progressPrefPDF, progressSurgicalPrint,progressPrefCardPrint]);


  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box className={classNames(classes.exportPopover, classes.newExportPopover)}>
          <Grid container direction="column">
            <Grid item>
              <Typography>Please choose File Format</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.exportTitle}>Export</Typography>
            </Grid>
            <Grid item>
              <List>
                <ListItem>
                  <FormControlLabel
                    control={<Checkbox checked={checkedCSV} />}
                    label="CSV"
                    onChange={(e) => handleChecked(e, 'CSV')}
                    labelPlacement="end"
                  />
                  <Box>
                    <LinearProgress
                      variant={((progressCSV >= 0 && progressCSV <= 100) ? "determinate" : "indeterminate")}
                      value={progressCSV}
                    />
                  </Box>
                </ListItem>
                <ListItem>
                  <FormControlLabel
                    control={<Checkbox checked={checkedPDF} />}
                    label="PDF"
                    onChange={(e) => handleChecked(e, 'PDF')}
                    labelPlacement="end"
                  />
                  <Box>
                    <LinearProgress
                      variant={((progressPDF >= 0 && progressPDF <= 100) ? "determinate" : "indeterminate")}
                      value={progressPDF}
                    />
                  </Box>
                </ListItem>
                {
                  isSEscreen &&
                  <ListItem>
                    <FormControlLabel
                      control={<Checkbox checked={checkedPrefPDF} />}
                      label="PrefCard PDF"
                      onChange={(e) => handleChecked(e, 'PrefPDF')}
                      labelPlacement="end"
                    />
                    <Box>
                      <LinearProgress
                        variant={((progressPrefPDF >= 0 && progressPrefPDF <= 100) ? "determinate" : "indeterminate")}
                        value={progressPrefPDF}
                      />
                    </Box>
                  </ListItem>
                }
              </List>
            </Grid>
            {/* For now ,seperated print feature in export modal is only in SE .  */}
            {
              isSEscreen &&
              <>
                <Grid item style={{ borderTop: '1px solid #CCCCCC' }}>
                  <Typography className={classes.exportTitle}>Print</Typography>
                </Grid>
                <List>
                  <ListItem>
                    <FormControlLabel
                      control={<Checkbox checked={checkedPrefCardPrint}
                      />}
                      label="PrefCard Pick Sheet"
                      // onChange={(e) => handleChecked(e, 'PrefCardPrint')}
                      labelPlacement="end"
                      className={classes.exportModalWidth}
                    />
                    <Box>
                      <LinearProgress
                        variant={((progressPrefCardPrint >= 0 && progressPrefCardPrint <= 100) ? "determinate" : "indeterminate")}
                        value={progressPrefCardPrint}
                      />
                    </Box>
                  </ListItem>
                  <ListItem>
                    <FormControlLabel
                      control={<Checkbox checked={checkedSurgicalPrint}
                      />}
                      label="Surgical Event"
                      onChange={(e) => handleChecked(e, 'SurgicalEventPrint')}
                      labelPlacement="end"
                      className={classes.exportModalWidth}
                    />
                    <Box>
                      <LinearProgress
                        variant={((progressSurgicalPrint >= 0 && progressSurgicalPrint <= 100) ? "determinate" : "indeterminate")}
                        value={progressSurgicalPrint}
                      />
                    </Box>
                  </ListItem>
                </List>
              </>
            }
            <Grid item>
              <Button
                variant="contained"
                onClick={(checkedSurgicalPrint || checkedPrefCardPrint) ? handlePrintClick : handleExportClick}
                className={classNames(classes.newCompleteBtn, "secondary-color")}
              >
                Confirm
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClosePopover}
                className={classNames(classes.newCompleteBtn, "myCancelBtn")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Popover>
      <SnackbarModal
        open={openToast}
        setOpen={setOpenToast}
        title=""
        content={toastContent}
        width={330}
        height={88}
        className={classes.toastMedium}
      />
    </>
  );
}
