import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import classNames from "classnames";
import { Button, Card, Grid, Typography, CardContent, Table, TableBody, TableRow, TableCell,
  TableHead } from "@material-ui/core";
import { useDamlState } from "../../context/DamlContext";
import { getState } from "../../store";
import { formatDate } from "../../services/util";
import { VendorTemplate } from "../../services/daml-modules1";
import { useDamlStream } from "../../hooks/useDAML";
import useStyles from "../surgicalevent/styles";


/**
 * Invoice Detail page in Hospital & Vendor side
 * @returns 
 */
export default function InvoiceDetail() {

  const classes = useStyles();
  const history = useHistory();

  const contract = getState().invoice.detail;

  const { role, rolePayload } = useDamlState();

  // get vendortemplate from VendorTemplate by useStreamQuries
  // because Invoice has no vendortemplate in current version
  // const [vendortemplate, setVendorTemplate] = useState(null);

  const { operator, hospital, vendor, invoicedata, invoicedetail, hospitaltemplate } = 
    (contract ? contract.payload : {});
  const { hospitaldetails, vendordetails } = (rolePayload ? rolePayload : {});
  console.log("[InvoiceDetail] hospitaltemplate", hospitaltemplate);
  console.log("[InvoiceDetail] hospitaldetails", hospitaldetails);
  console.log("[InvoiceDetail] vendordetails", vendordetails);

  const searchVendorTemplate = {operator, hospital, vendor};

  const assetsHistory = useDamlStream(VendorTemplate, [searchVendorTemplate], role === 'Hospital');
  const vendortemplate = (assetsHistory && assetsHistory.contracts.length) ? assetsHistory.contracts[0].payload : null;
  console.log("[InvoiceDetail] vendortemplate", vendortemplate);


  // handle to download detail of invoice
  const handleDownload = async () => {
    history.goBack();
  };


  return (
    <>
    {
      contract && 'contractId' in contract && 'payload' in contract
      ?
      <>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >

          <Typography variant="h4" className={classes.pageTitle}>
            Purchase Order: {invoicedata.invpurchaseorderid}
          </Typography>

          <Button
            size="small"
            className={classes.newSetupButton}
            variant="contained"
            onClick={handleDownload}
          >
            Save as PDF
          </Button>

        </Grid>

        <Grid style={{marginTop: 10}}>

          <Card>
            <CardContent>
              <Grid container spacing={3}>

                <Grid item xs={2} sm={4} md={4} className={classes.rightBorder}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.cellWithoutBorderPadding}>Hospital:</TableCell>
                        <TableCell className={classes.cellWithoutBorderPadding}>
                          {hospital}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.cellWithoutBorderPadding}></TableCell>
                        <TableCell className={classes.cellWithoutBorderPadding}>
                          {hospitaldetails ? hospitaldetails.billingaddress.address1 : ''}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.cellWithoutBorderPadding}></TableCell>
                        <TableCell className={classes.cellWithoutBorderPadding}>
                          {hospitaldetails ? hospitaldetails.billingaddress.address2 : ''}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.cellWithoutBorderPadding}></TableCell>
                        <TableCell className={classes.cellWithoutBorderPadding}>
                          {hospitaldetails ? `${hospitaldetails.billingaddress.city}, ${hospitaldetails.billingaddress.state} ${hospitaldetails.billingaddress.zipcode}` : ''}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>

                <Grid item xs={2} sm={4} md={4} className={classes.rightBorder}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.cellWithoutBorderPadding}>Vendor:</TableCell>
                        <TableCell className={classes.cellWithoutBorderPadding}>
                          {vendor}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.cellWithoutBorderPadding}></TableCell>
                        <TableCell className={classes.cellWithoutBorderPadding}>
                          {vendordetails ? vendordetails.vendoraddress.address1 : ''}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.cellWithoutBorderPadding}></TableCell>
                        <TableCell className={classes.cellWithoutBorderPadding}>
                          {vendordetails ? vendordetails.vendoraddress.address2 : ''}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.cellWithoutBorderPadding}></TableCell>
                        <TableCell className={classes.cellWithoutBorderPadding}>
                          {vendordetails ? `${vendordetails.vendoraddress.city}, ${vendordetails.vendoraddress.state} ${vendordetails.vendoraddress.zipcode}` : ''}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
                
                <Grid item xs={2} sm={4} md={4}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.cellWithoutBorderPadding}>Date:</TableCell>
                        <TableCell className={classes.cellWithoutBorderPadding}>
                          {formatDate(invoicedata.invinvoicedate)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.cellWithoutBorderPadding}>Status:</TableCell>
                        <TableCell className={classes.cellWithoutBorderPadding}>
                          OPEN
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.cellWithoutBorderPadding}>Buyer:</TableCell>
                        <TableCell className={classes.cellWithoutBorderPadding}>
                          {vendortemplate && vendortemplate.api_payload.buyer}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.cellWithoutBorderPadding}>Type:</TableCell>
                        <TableCell className={classes.cellWithoutBorderPadding}>
                          Standard
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>

              </Grid>
            </CardContent>
          </Card>

        </Grid>

        <Grid style={{marginTop: 14}}>

          <Card>
            <CardContent>
              <Grid container spacing={3}>

                <Grid item xs={2} sm={4} md={4} className={classes.rightBorder}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.cellWithoutBorderPadding}>Ship To:</TableCell>
                        <TableCell className={classes.cellWithoutBorderPadding}>
                          {vendortemplate && vendortemplate.api_payload.defaultShipToLocation}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>

                <Grid item xs={2} sm={4} md={4} className={classes.rightBorder}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.cellWithoutBorderPadding}>Invoice To:</TableCell>
                        <TableCell className={classes.cellWithoutBorderPadding}>
                          {hospitaldetails && hospitaldetails.billinfocontact.length ? `${hospitaldetails.billinfocontact[0].firstname} ${hospitaldetails.billinfocontact[0].lastname}` : ''}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.cellWithoutBorderPadding}></TableCell>
                        <TableCell className={classes.cellWithoutBorderPadding}>
                        {hospitaldetails && hospitaldetails.billinfocontact.length ? `${hospitaldetails.billinfocontact[0].fax}` : ''}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.cellWithoutBorderPadding}>Vendor Acct:</TableCell>
                        <TableCell className={classes.cellWithoutBorderPadding}>
                          {/* Now, blank */}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
                
                <Grid item xs={2} sm={4} md={4}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.cellWithoutBorderPadding}>Terms:</TableCell>
                        <TableCell className={classes.cellWithoutBorderPadding}>
                          {vendortemplate && vendortemplate.api_payload.paymentTerms}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.cellWithoutBorderPadding}>FOB Contact:</TableCell>
                        <TableCell className={classes.cellWithoutBorderPadding}>
                          {vendortemplate && vendortemplate.api_payload.fOB}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.cellWithoutBorderPadding} colSpan={2}>
                          MA EXEMPT PURCH CERT #: {/* Now, blank */}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>

              </Grid>
            </CardContent>
          </Card>

        </Grid>

        <Grid style={{marginTop: 14}}>

          <Card>
            <CardContent className={classes.paddingZero}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.cellHeaderBold}>Line</TableCell>
                    <TableCell className={classes.cellHeaderBold}>Item</TableCell>
                    <TableCell className={classes.cellHeaderBold}>Vend's Catlg</TableCell>
                    <TableCell className={classes.cellHeaderBold}>Description</TableCell>
                    <TableCell className={classes.cellHeaderBold}>Manufacturer</TableCell>
                    <TableCell className={classes.cellHeaderBold}>Quantity</TableCell>
                    <TableCell className={classes.cellHeaderBold}>Price</TableCell>
                    <TableCell className={classes.cellHeaderBold}>EXT Cost</TableCell>
                    <TableCell className={classes.cellHeaderBold}>DEPT or INVEN</TableCell>
                    <TableCell className={classes.cellHeaderBold}>G/L Account</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    invoicedetail.map((invDetail, index) => (
                      <TableRow key={index}>
                        <TableCell>{index+1}</TableCell>
                        <TableCell>{invDetail.invdproductitem}</TableCell>
                        <TableCell>{/* Now, blank */}</TableCell>
                        <TableCell>{invDetail.invddescription}</TableCell>
                        <TableCell>{invDetail.invdproduct.sepproductmanufacturer}</TableCell>
                        <TableCell>{invDetail.invdproductquantity}</TableCell>
                        <TableCell>${invDetail.invdproductprice}</TableCell>
                        <TableCell>${invDetail.invdtotalcost}</TableCell>
                        <TableCell>{/* Now, blank */}</TableCell>
                        <TableCell>${invDetail.invdtotalcost}</TableCell>
                      </TableRow>
                    ))
                  }
                  <TableRow>
                    <TableCell className={classes.cellWithoutBorder} colSpan={3}>Comments:</TableCell>
                    <TableCell 
                      className={classNames(classes.cellWithoutBorder, classes.rightBorder)} 
                      colSpan={5}
                    >
                      EQUITY IMPLANT PROGRAM
                    </TableCell>
                    <TableCell className={classes.cellWithoutBorder} colSpan={2}>
                      Total: ${invoicedata.invinvoicetotalamount}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellWithoutBorder} colSpan={3}></TableCell>
                    <TableCell 
                      className={classNames(classes.cellWithoutBorder, classes.rightBorder)} 
                      colSpan={5}
                    >
                      HIP & KNEE PRIMARY ONLY
                    </TableCell>
                    <TableCell className={classes.cellWithoutBorder} colSpan={2}></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellWithoutBorder} colSpan={3}></TableCell>
                    <TableCell 
                      className={classNames(classes.cellWithoutBorder, classes.rightBorder)} 
                      colSpan={5}
                    >
                      PLEASE CONFIRM ORDER BY EMAIL WDEANE@BHS1.ORG, PHONE<br/>
                      (413)447-2795 OR FAX (413)447-2212<br/>
                      PLEASE USE FEDEX ACCOUNT #373048461 FOR ALL SHIPMENTS.For<br/>
                      any order with a combined shipping weight of 150+ lbs, call<br/>
                      1.888.457.5851 for specific carrier instructions prior to<br/>
                      shipping OR EMAIL THEM AT  OptiFreightLTL@cardinalhealth.com
                    </TableCell>
                    <TableCell className={classes.cellWithoutBorder} colSpan={2}></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>

      </>
      :
      <Redirect to={role === 'Hospital' ? "/app/receivedinvoice" : '/app/invoice'} />
    }

    </>
  );
}
