import React from "react";
// import { RelationshipOffer } from "../../services/daml-modules1";
import Contracts from "../../components/Contracts/Contracts";
import AdaptiveSearch from "../../components/Search/adaptivesearch";
import { contractFilter } from "../../services/daml-filters";
import editIcon from "../../icons/edit.svg";
import useStyles from "../surgicalevent/styles";
import withLoading from "../../hoc/withLoading";


function VendorInviteContracts({
  searchParams = {},
  handleChangeSearchValue = null,
  allContracts = [],
  searchVendorInvite = {},
  handleRequest,
}) {


  const classes = useStyles();
  // const { contracts } = useStreamQueries(RelationshipOffer, () => [searchParams]);
  let contracts = contractFilter(allContracts, searchParams);

  console.log("[VendorInviteContracts]", searchParams, contracts);


  return (
    <Contracts
      className={classes.customRoot}
      contracts={contracts}
      columns={[
        // ["ContractId", "contractId"],
        ["Vendor Name", "payload.vendor", {
          options: {
            width: 180,
          },
          search: {
            component:
              <AdaptiveSearch
                getValue={(c) => c.payload.vendor}
                handleChangeSearch={(v) => handleChangeSearchValue(v, 'vendor')}
                defaultValue={searchVendorInvite.vendor}
                label="Search By Vendor Name"
                contracts={allContracts}
                isShrink={true}
              />
          },
          isSearched: searchVendorInvite.vendor,
          handleClear: () => handleChangeSearchValue('', 'vendor'),
        }],
        ["Created at", "payload.created_at"],

      ]}

      actions={[
        [
          "Actions",
          [
            [["Revoke", [<img alt="" src={editIcon} />]],
              handleRequest],
          ],
        ]
      ]}

      options={{
        statusEmpty: (allContracts.length === 0)
      }}
    />
  );
}

export default withLoading(VendorInviteContracts);
