import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  productClass: {
    // '& .product-detail-data1 .MuiTableCell-root': {
    //   padding: '8px 32px 8px 0px !important;',
    //   fontSize: '1rem !important;',
    //   background : 'black',
    // },
  },
  rowClass1: {
    '& :nth-child(2), & :last-child': {
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '18px',
      color: '#000000',
    },
    '& >td': {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      padding: '0px 41px 1px 0px',
    },
  },
  rowClass: {
    '& :last-child': {
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '18px',
      color: '#000000',
    },
    '& >td': {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      padding: '0px 41px 1px 49px',
    },
  },
  toastContents: {
    '& .MuiAlert-message .MuiTypography-root': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      color: '#000000',
    },

  },
  toastContent1: {
    '& .MuiAlert-message .MuiTypography-root': {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '8px',
      color: '#000000',
    },
    '& .MuiAlert-icon': {
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '31px',
      width: '19%',
      color: '#3D7C3F',
    },
  },

  setupButton: {
    marginBottom: '20px',
  },
  topGrid: {
    marginTop: '10px',
    marginBottom: '20px',
  },
  customRoot: {

  },
  comment: {
    marginTop: '7px',
    display: 'block',
  },
  dialogField: {
    width: '200px',
  },
  dateField: {
    display: 'contents',
  },
  divider: {
    height: 20,
    marginLeft: 10,
  },
  pageTitle: {
    display: 'inline-block',
    marginBottom: '15px',
  },
  smallChip: {
    fontSize: '0.65rem',
  },
  centerCell: {
    justifyContent: 'center !important'
  },
  newSetupButton: {
    padding: '6px 15px',
    color: '#ffffff',
    backgroundColor: '#183C73',
    float: 'right',
    '&:hover': {
      backgroundColor: '#183c73d9',
    },
    '&.MuiButton-root': {
      textTransform: 'capitalize',
      fontWeight: '500',
    },
    '@media (max-width:1024px)': {
      fontSize: '0px',
      minWidth: '36px',
      padding: '6px',
    },
    '& .desktop': {

      '@media (max-width:1024px)': {
        display: "none",
      },
    },
    '& .mobile': {
      display: "none",
      '@media (max-width:1024px)': {
        display: "block",
      },
    },

  },
  newCheckbox: {
    float: 'right',
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '16px',
    '& .MuiCheckbox-root': {
      padding: '4px 9px',
    },
    '& .MuiCheckbox-colorPrimary.Mui-checked': {
      color: '#183C73'
    }
  },
  poCommentHeading: {
    fontSize: '16px',
    marginBottom: '25px'
  },
  poCommentsGridTable: {
    marginBottom: '40px',

    background: '#ffffff',

    '& .MuiDataGrid-root.MuiDataGrid-autoHeight': {
      background: '#F4F6F8',
      boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    },
    '& .MuiGrid-spacing-xs-4 > .MuiGrid-item': {
      padding: '0px 16px'
    },
    '& .MuiChip-sizeSmall': {
      background: 'rgba(76, 175, 80, 0.38)',
      border: '1px solid #4CAF50'
    },
    '& [data-value="Rejected"]  .MuiChip-sizeSmall': {
      background: 'rgba(244, 67, 54, 0.38)',
      border: '1px solid #F44336'
    }
  },
  customRootComment: {
    background: '#F4F6F8',
    boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    '& .MuiAvatar-colorDefault': {
      background: '#0EC4D5'
    },
    '& .rightComment': {
      justifyContent: 'flex-end',
      '& .MuiListItemAvatar-root': {
        minWidth: '40px',
        marginLeft: '16px',
      },
      '& .me': {
        background: '#DEF3FB',
        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        padding: '10px 16px',

      }
    },
    '& .leftComment': {
      '& .MuiListItemAvatar-root': {
        minWidth: '40px',
        marginRight: '16px',
      },
      '& .you': {
        background: '#ffffff',
        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        padding: '10px 16px',
      }
    },
    '& .MuiListItemText-primary': {
      width: 'auto',
      float: 'left',
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: '500',
    },
    '& .MuiListItemText-secondary': {
      width: 'auto',
      float: 'right',
      color: 'rgba(0, 0, 0, 0.38)',
      marginLeft: '16px',
    },
    '& .msg': {
      width: '100%',
      display: 'inline-block',
      color: 'rgba(0, 0, 0, 0.87)',
    }
  },
  newIconButton: {
    paddingTop: '3px',
    paddingBottom: '3px',
  },
  newProgressWrapper: {
    '& .MuiLinearProgress-root': {
      width: '80px',
      height: '14px',
      borderRadius: '8px',
      border: '4px solid #EBEBE9',
      '&.MuiLinearProgress-colorPrimary': {
        backgroundColor: 'rgba(76, 175, 80, 0.38)',
      },
      '&.gray': {
        backgroundColor: '#5C5C5C61',
      },
      '&.dark-gray': {
        backgroundColor: '#5C5C5C',
      },
      '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: '#4CAF50',
        borderRadius: '5px',
      },
    },
    '& .MuiGrid-item': {
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '14px',
      color: '#5C5C5C',
    },
  },
  overPopper: {
    '& .MuiBox-root': {
      background: '#FFFFFF',
      border: '1px solid #CCCCCC',
      borderRadius: '5px',
      '& .header': {
        borderBottom: '1px solid #CCCCCC',
        padding: '10px 40px 8px 14px',
        '& .MuiTypography-root': {
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '20px',
          color: '#F44336',
          '&.missing': {
            color: '#F76E11',
          },
        },
      },
      '& .body': {
        '& .MuiListItem-root': {
          padding: '6px 12px 6px',
          '& .MuiListItemIcon-root': {
            minWidth: 'inherit',
            '&.missing-icon': {
              background: '#F76E11',
              width: '19px',
              height: '19px',
              marginRight: '20px',
              borderRadius: '9px',
              color: '#FFFFFF',
              paddingLeft: '6px',
            },
            '&.declined-icon': {
              background: '#F44336',
              width: '12px',
              height: '12px',
              marginRight: '8px',
              borderRadius: '6px',
            },
          },
          '& .MuiListItemText-root': {
            margin: '0px',
            '& .MuiListItemText-primary': {
              fontWeight: 600,
              fontSize: '12px',
              lineHeight: '20px',
              color: '#000000',
            },
          },
        },
      },
    },
  },
  createPanel: {
    padding: '6px 19px 0px 29px',
  },
  completeBtn: {
    marginTop: '-55px',
  },
  addBtn: {
    marginRight: '245px',
    marginLeft: "-55px",
  },
  overviewSEPanel: {
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        padding: '4px',
        width: '143px !important',
        maxWidth: '200px !important',
        '&:first-child': {
          paddingLeft: '0px',
        },
        '&.details': {
          padding: '20px 0px 0px 0px',
          fontSize: '18px',
          lineHeight: '22px',
          color: '#5C5C5C',
        },
        '&.label': {
          fontWeight: 400,
          fontSize: '15px',
          lineHeight: '18px',
        },
      },
    },
  },
  // overviewPODetails:{
  //   '& .MuiTableRow-root': {
  //     '& .MuiTableCell-root': {
  //       padding: '4px',
  //       maxWidth: '200px !important',
  //       '&:first-child': {
  //         paddingLeft: '0px',
  //       },
  //       '&.details': {
  //         padding: '20px 0px 0px 0px',
  //         fontSize: '18px',
  //         lineHeight: '22px',
  //         color: '#5C5C5C',
  //       },
  //       '&.label': {
  //         fontWeight: 400,
  //         fontSize: '15px',
  //         lineHeight: '18px',
  //       },
  //     },
  //   },
  // },
  overviewNewSEPanel: {
    marginTop: '15px',
    '& .MuiTableRow-root': {
      '&.details-row': {
        '& .MuiTableCell-root': {
          paddingTop: '33px',
        },
      },
      '& .MuiTableCell-root': {
        padding: '4px',
        width: '143px !important',
        maxWidth: '143px !important',
        '&.details': {
          padding: '4px 4px 4px 40px',
          fontWeight: 700,
          fontSize: '15px',
          lineHeight: '18px',
          color: '#000000',
        },
        '&.label': {
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '14px',
          color: '#5C5C5C',
        },
        '&.value': {
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '16px',
          color: '#000000',
        },
      },
    },
  },
  newPOTitlePanel: {
    '& .label': {
      fontSize: '25px',
      lineHeight: '30px',
      color: '#000000',
      marginLeft: '12px !important',
    },
  },
  newAddedProductPanel: {
    width: '597px',
    '& .product-title': {
      paddingTop: '20px',
    },
    '& .product-subtitle': {
      padding: '11px 0px 4px',
      '& .MuiIconButton-root': {
        padding: '0px 5px 0px 0px',
      },
    },
    '& .create-invoice-btn': {
      width: '126px',
      marginRight: '12px',
      fontWeight: 700,
      fontSize: '13px',
      lineHeight: '17px',
      padding: '3px 15px',
      textTransform: 'capitalize',
      color: '#FFFFFF',
      backgroundColor: '#1C3C74',
      '&.Mui-disabled': {
        backgroundColor: '#CCCCCC',
      },
    },
    '& .add-product-btn': {
      width: '113px',
      marginRight: '4px'
    },
    '& .main-accordion .MuiAccordionSummary-root.red': {
      backgroundColor: 'rgba(244, 67, 54, 0.38)',
      // color: '#FFFFFF',
    },
  },
  addMenu: {
    padding: 0,
    width: '142px',
    background: '#E1DDDD',
    // borderRadius: '8px',
    '& .MuiMenuItem-root': {
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: 400,
      color: '#000000',
      '&:hover': {
        fontWeight: 700,
        color: '#FFFFFF',
        background: '#5C5C5C',
      },
    },
  },
  certifyUsername: {
    '& .MuiGrid-item > img': {
      width: '21px',
      height: '21px',
    },
    '& .MuiTypography-root': {
      color: '#000000',
      '&.label': {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '14px',
        padding: '0px 10px 6px',
      },
      '&.value': {
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '16px',
        borderBottom: '3px solid #5C5C5C',
        padding: '0px 5px 5px',
      },
    },
  },
  profilePictureDropDown: {
    '& .MuiDropzoneArea-root': {
      '& .MuiDropzoneArea-textContainer': {
        '& > label': {
          width: "280px !important",
          height: "125px !important",
          '& > span': {
            fontFamily: "Lato",
            fontStyle: "medium",
            fontSize: "17px",
            lineHeight: "20px",
            color: "#CCCCCC",
            '& > span': {
              color: "#2196F3"
            },
          }
        }
      }
    }
  },
  uploadImg: {
    padding: "16px"
  },
  uploadFile1: {
    width: '235px',
    height: '211px !important',
  },
  uploadFile2: {
    right: "34px",
    width: "340px !important",
    position: "relative",
    "&.newfile": {
      background: 'red'
    }
  },
  uploadFileNew: {
    '& .MuiDropzoneArea-root': {
      '& .MuiDropzonePreviewList-root': {
        width: '83% !important',
        margin: '0px 25px 0px 30px !important',
      }
    }
  },
  uploadFile3: {
    '& .MuiDropzoneArea-root': {
      '& .MuiDropzonePreviewList-root': {
        '& .MuiDropzonePreviewList-imageContainer': {
          '& .preview-item': {
            height: '35px !important',
          },
          '& .MuiDropzonePreviewList-removeButton': {
            top: "-2px",
            right: "13px"
          }
        }
      }
    }
  },
  uploadFile: {
    margin: '0px 0px 0px auto',
    width: '235px',
    height: '120px',
    background: '#FFFFFF',
    '& .MuiDropzoneArea-root': {
      marginBottom: '0px',
      borderWidth: '0px',
      minHeight: 'inherit',
      '& .MuiDropzoneArea-textContainer': {
        '& > p.MuiTypography-root': {
          margin: '0px !important',
        },
        '& > label': {
          marginTop: '10px !important',
          marginBottom: '10px',
          padding: '0px',
          width: '100%',
          height: '29px',
          background: '#FFFFFF',
          border: '2px solid #CCCCCC',
          borderRadius: '5px',
          '& .drag-drop-btn': {
            fontWeight: 400,
            fontSize: '10px',
            lineHeight: '11px',
            color: '#5C5C5C',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '100%',
            paddingLeft: '10px',
            paddingRight: '9px',
          },
        },
      },
      '& .MuiDropzonePreviewList-root': {
        width: '100%',
        margin: '0px',
        '& .MuiDropzonePreviewList-imageContainer': {
          padding: '0px',
          maxWidth: 'inherit',
          flexBasis: '100%',
          '& .preview-item': {
            height: '25px',
            background: '#FFFFFF',
            border: '1px solid #CCCCCC',
            borderRadius: '4px',
            fontWeight: 400,
            fontSize: '10px',
            lineHeight: '12px',
            color: '#5C5C5C',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingLeft: '10px',
            paddingRight: '9px',
            '& .MuiDropzonePreviewList-image': {
              height: '15px',
              opacity: 1,
              marginRight: '10px',
            },
          },
          '& .MuiDropzonePreviewList-removeButton': {
            opacity: '1 !important',
            backgroundColor: 'inherit',
            boxShadow: 'none',
            '& .MuiSvgIcon-root': {
              width: '0.7em',
              height: '0.7em',
              color: '#F24E1E',
            },
          },
        },
      },
    },
  },
  newPOComments: {
    width: '242px',
    // height: '342px',
    background: '#FFFFFF',
    border: '1px solid #CCCCCC',
    borderRadius: '5px',
    '& .pocomments-header': {
      height: '58px',
      borderBottom: '1px solid #CCCCCC',
      '& .MuiGrid-item': {
        textAlign: 'center',
      },
      '& .party-name': {
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '16px',
        color: '#000000',
        marginTop: '10px',
      },
      '& .online-chip': {
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '11px',
        color: '#4CAF50',
        backgroundColor: '#E5FEE5',
        height: '12px',
        borderRadius: '4px',
        '& .MuiChip-label': {
          paddingLeft: '9px',
          paddingRight: '9px',
        },
      },
    },
    '& .pocomments-list': {
      // height: '234px',
    },
    '& .pocomments-bottom': {
      height: '49px',
      '& .message-box': {
        marginTop: '10px',
        marginLeft: '15px',
        marginRight: '5px',
        '& .MuiInputBase-root': {
          borderRadius: '5px',
        },
      },
      '& button.MuiIconButton-root svg': {
        color: '#1C3C74',
      },
    },
  },
  newCommentList: {
    height: '234px',
    overflowY: 'scroll',
    background: '#FFFFFF',
    boxShadow: 'none',
    '& .MuiListItem-root': {
      padding: '5px 8px 5px 8px',
      '&.datecomment': {
        justifyContent: 'center',
        '& .date-chip': {
          height: '12px',
          background: '#F2F4F2',
          borderRadius: '4px',
          fontWeight: 400,
          fontSize: '7px',
          lineHeight: '8px',
          color: '#5C5C5C',
          '& .MuiChip-label': {
            paddingLeft: '8px',
            paddingRight: '8px',
          },
        },
      },
      '& .message': {
        padding: '8px 6px 2px 10px',
        boxShadow: 'none',
        fontWeight: 400,
        '&.me': {
          background: 'rgba(33, 150, 243, 0.38)',
          borderRadius: '8px 8px 0px 8px',
        },
        '&.you': {
          background: '#ABF5AE',
          borderRadius: '0px 8px 8px 8px',
        },
        '& .note': {
          fontSize: '10px',
          lineHeight: '11px',
          minHeight: '11px',
          color: '#000000',
        },
        '& .date': {
          fontSize: '6px',
          lineHeight: '7px',
          color: '#5C5C5C',
          textAlign: 'right',
          marginTop: '2px',
          marginRight: '5px',
        },
      },
      '& .user': {
        fontWeight: 400,
        fontSize: '9px',
        lineHeight: '11px',
        color: '#5C5C5C',
        margin: '2px 7px',
      },
    },
  },
  newAvatar: {
    color: '#F7CD37',
    backgroundColor: '#5C5C5C',
  },
  newProductTitlePanel: {
    '& .name.lotcode': {
      display: 'none',
    },
  },
  newPrintPO: {
    '& .details-form': {
      margin: '90px 100px 60px',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '17px',
    },
    '& .product-form': {
      '& .MuiTableCell-root': {
        border: '1px solid #000000',
        fontWeight: 400,
        fontSize: '8px',
        lineHeight: '10px',
        '&.MuiTableCell-head': {
          background: 'rgba(196, 196, 196, 0.38)',
          fontWeight: 700,
          fontSize: '12px',
          lineHeight: '14px',
        },
      },
    },
  },
  newPopoverBox: {
    width: '241px',
  },
  newInnerPopper: {
    top: '10px',
    left: '4px',
    width: '230px',
    height: '100px',
    '& span.MuiFormControlLabel-label': {
      fontSize: '14px',
    },
  },
  newInnerPopper1: {
    top: '10px',
    left: '5px',
    height: '100px',
    width: '210px',
    '& span.MuiFormControlLabel-label': {
      fontSize: '14px',
    },
  },
  redText: {
    color: '#F44336',
    fontWeight: 600,
  },
  blueText: {
    color: '#2196F3',
    fontWeight: 600,
  },
  greenText: {
    color: '#4CAF50',
    fontWeight: 600,
  },
  customText: {
    fontWeight: '600 !important',
  },
  customText1: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  newcustomText: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#000000',
  },
  successTitle: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#4CAF50',
    paddingTop: '15px',
    paddingBottom: '0px',
    marginBottom: '-8px',
  },
  declineTitle: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#000000',
    paddingTop: '15px',
    paddingBottom: '0px',
    marginBottom: '-8px',
  },
  declineWarning: {
    position: 'relative',
    background: 'rgba(255, 169, 169, 0.8)',
    borderRadius: '7px',
    paddingLeft: '31px !important',
    width: '129px !important',
    height: '36px !important',
    marginLeft: '16px',
    '& .MuiGrid-item': {
      marginTop: '4px',
    },
    '& .MuiTypography-root': {
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '14px',
      color: '#F44336',
    },
    '& img': {
      position: 'absolute',
      top: '-5px',
      left: '-13px',
    },
  },
  checkBoxTitle: {
    fontWeight: '700',
    fontSize: '15px',
    lineHeight: '17px',
    color: '#000000',
  },
  infoBadges: {
    position: 'relative',
    right: '74px',
    bottom: '10px',
  },
  infoBadges1: {
    right: '83px !important',
  }
}), { index: 1 });
