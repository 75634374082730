import React, { useState, useEffect } from "react";
import { useParty } from "@daml/react";
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, FormControl, 
  InputLabel, Select, TextareaAutosize, Typography } from "@material-ui/core";
import { useLayoutDispatch, setLoading, unsetLoading } from "../../context/LayoutContext";
import  AddEditProduct  from "../surgicalevent/add-edit-product";
import { getOwnerships } from "../../models/Ownership";
import { getPoType } from "../../models/Po";
import { requiredProduct, isEmptyProduct, compareProduct } from "../../models/Product";
import { requiredPurData } from "../../models/Po";
import { createPODetail } from "../../models/PODetail";
// import { onChangeNumber } from "../../components/Modals/handles";
import { InputDistributor } from "../surgicalevent/edit-product";
import FileUploadButton from "../../components/FileUpload/FileUploadButton";
import { uploadFileCreatePO } from "../../services/axios/api-node_server";
import ErrorDialog from '../../components/Modals/GeneralModal/error-dialog';
import useStyles from "../surgicalevent/styles";


/**
 * Dialog when Create or Edit Purchase Order
 * 1. SE PO Page in Hospital
 * 2. New PO Page in Hospital
 * @param {Boolean} isCreate
 * @param {Boolean} openModal
 * @param {Function} setOpenModal
 * @param {Function} addProductItem
 * @param {Function} handleAddMoreProduct
 * @param {Object} surgicalEventData
 * @param {Object} purchaseOrderData
 * @param {Function} setPurchaseOrderData
 * @param {Object} purchaseOrderDetailData
 * @param {Function} setPurchaseOrderDetailData
 * @param {Function} handleDeleteProduct
 * @param {Function} handleUpdateProduct
 */
export default function CreatePurchaseOrder ({
  isCreate, openModal, setOpenModal, addProductItem, handleAddMoreProduct, surgicalEventData, 
  purchaseOrderData, setPurchaseOrderData, purchaseOrderDetailData, setPurchaseOrderDetailData,
  handleDeleteProduct, handleUpdateProduct,
}) {

  // console.log("[CreatePurchaseOrder]", surgicalEventData, purchaseOrderData, purchaseOrderDetailData);

  const classes = useStyles();

  const hospital = useParty();
  const layoutDispatch = useLayoutDispatch();

  const [openConfirmationeModal, setConfirmationOpenModal] = useState(false);
  const [purchaseError, setPurchaseError] = useState({});
  const [productItems, setProductItems] = useState({});
  const [productError, setProductError] = useState({});
  const [productItemsList, setProductItemsList] = useState([]); 
  const [notes, setNotes] = useState(""); 
  const [podnotes, setPodNotes] = useState(""); 
  const [filePathData, setFilePathData] = useState(""); 
  const [file, setFile] = useState(null);
  // error modal
  const [errorModal, setErrorModal] = useState(false);


  // Click "Add more Product"
  const addMoreProduct = async (event) => {
    // check required fields in productItems
    let newErrors = requiredProduct(productItems);
    // to skip 'sepdistributor', 'sepownership' in productItems
    delete newErrors.sepdistributor;
    delete newErrors.sepownership;
    // to skip 'sepproductprice' in productItems if purchaseOrderData.potype === "NoCharge"
    if (purchaseOrderData.potype === "NoCharge") delete newErrors.sepproductprice;
    console.log("[CreatePurchaseOrder] addMoreProduct", productItems, newErrors);
    if ((Object.keys(newErrors).length > 0)) {
      setProductError({ ...newErrors });
      setPurchaseError({});
      return false;
    }

    productItemsList.push(productItems);
    purchaseOrderDetailData.push(createPODetail({
      podetail: {podproduct: productItems},
      notes: podnotes,
      quantity: productItems.podquantity,
    }));

    setProductItems({});
    setPurchaseError({});
    setProductError({});
    setPodNotes("");

    await handleAddMoreProduct(productItems);
  };

  // Click "Create" or "Save Update"
  const addPurchaseOrder = async (event) => {
    // check required fields in purchaseOrderData
    let newErrors = requiredPurData(purchaseOrderData);
    console.log("[CreatePurchaseOrder] addPurchaseOrder", purchaseOrderData, newErrors);
    if ((Object.keys(newErrors).length > 0)) {
      setPurchaseError({ ...newErrors });
      return false;
    }
    setPurchaseError({});

    // In the case of Bulk
    if (isCreate === true && !!file) {
      if (!file) {
        setFilePathData("You must upload csv file for Bulk.");
        return false;
      }
      setFilePathData("");

      // spinner
      setLoading(layoutDispatch);
      
      // upload
      const resUpload = await uploadFileCreatePO(file, {
        hospital, 
        vendor: purchaseOrderData.povendorid, 
        ownership: purchaseOrderData.popurchaseordertype,
        potype: purchaseOrderData.potype,
        notes,
      });
      console.log("[CreatePurchaseOrder] addPurchaseOrder", resUpload);

      // No work
      await addProductItem(false);

      unsetLoading(layoutDispatch);

      // error
      if (!resUpload || resUpload.error) setErrorModal(true);
      return;
    }

    if (!isEmptyProduct(productItems)) {
      // check required fields in productItems
      newErrors = requiredProduct(productItems);
      // to skip 'sepdistributor', 'sepownership' in productItems
      delete newErrors.sepdistributor;
      delete newErrors.sepownership;
      // to skip 'sepproductprice' in productItems if purchaseOrderData.potype === "NoCharge"
      if (purchaseOrderData.potype === "NoCharge") delete newErrors.sepproductprice;
      console.log("[CreatePurchaseOrder] addPurchaseOrder", productItems, newErrors);
      if ((Object.keys(newErrors).length > 0)) {
        setProductError({ ...newErrors });
        return false;
      }
      setProductError({});

      productItemsList.push(productItems);
      purchaseOrderDetailData.push(createPODetail({
        podetail: {podproduct: productItems},
        notes: podnotes,
        quantity: productItems.podquantity,
      }));
    }

    // spinner
    setLoading(layoutDispatch);
    
    setProductItems({});
    setProductError({});
    setPodNotes("");
    setNotes("");
    
    // On the case of NoCharge, set sepproductprice as zero - unnecessary
    /* if (purchaseOrderData.potype === "NoCharge") {
      purchaseOrderDetailData = purchaseOrderDetailData.map(p => {
        let newp = {...p};
        newp.podproduct.sepproductprice = 0;
        newp.podunitprice = 0;
        return newp;
      });
    } */

    // action to add products
    await addProductItem();

    unsetLoading(layoutDispatch);
  };

  // create PO by uploading csv
  const handleUpload = (file, callback) => {
    setFile(file);
  };

  // update PO
  const updatePODetail = async (product, itemIndex) => {
    console.log("[CreatePurchaseOrder] updatePODetail", product, itemIndex);
    // check required fields in product
    let newErrors = requiredProduct(product);
    // to skip 'sepdistributor', 'sepownership' in product
    delete newErrors.sepdistributor;
    delete newErrors.sepownership;
    // to skip 'sepproductprice' in product if purchaseOrderData.potype === "NoCharge"
    if (purchaseOrderData.potype === "NoCharge") delete newErrors.sepproductprice;
    console.log("[CreatePurchaseOrder] updatePODetail", product, newErrors);
    if ((Object.keys(newErrors).length > 0)) {
      setProductError({ ...newErrors });
      return false;
    }

    if (itemIndex >= 0 && itemIndex < purchaseOrderDetailData.length) {
      if (compareProduct(product, purchaseOrderDetailData[itemIndex].podproduct, true) 
        && product.podquantity === purchaseOrderDetailData[itemIndex].podquantity) return; 
      
      const updatedetail = {
        ...purchaseOrderDetailData[itemIndex],
        podproduct: product,
        podquantity: product.podquantity,
      };

      if (typeof handleUpdateProduct === 'function') {
        const result = await handleUpdateProduct(updatedetail, itemIndex);

        if (result) {
          purchaseOrderDetailData[itemIndex].podproduct = product;
          setProductItemsList(purchaseOrderDetailData.map(p => ({...p.podproduct, podquantity: p.podquantity})));
        }
      }
    }
  };

  // delete PO
  const removePODetail = async (itemIndex) => {
    // console.log("[CreatePurchaseOrder] removePODetail", itemIndex);

    if (itemIndex >= 0 && itemIndex < purchaseOrderDetailData.length) {
      const removedetail = purchaseOrderDetailData[itemIndex];
      // console.log("[CreatePurchaseOrder] removePODetail", removedetail);
  
      if (typeof handleDeleteProduct === 'function') {
        const result = await handleDeleteProduct(removedetail, itemIndex);

        if (result) {
          purchaseOrderDetailData.splice(itemIndex, 1);
          setProductItemsList(purchaseOrderDetailData.map(p => ({...p.podproduct, podquantity: p.podquantity})));
        }
      }
    }
  };

  const handleCancel = () => {
    setFile(null);
    setFilePathData("");
    setOpenModal(false);
    unsetLoading(layoutDispatch);
  };


  useEffect(() => {
    if (openModal) {
      console.log("[CreatePurchaseOrder] Open CreatePurchaseOrder dialog.");
      setProductItems({});
      setPurchaseError({});
      setProductError({});
      setProductItemsList(purchaseOrderDetailData.map(p => ({...p.podproduct, podquantity: p.podquantity})));
      setPodNotes("");
      setNotes("");
    }
  }, [openModal, purchaseOrderDetailData]);
  

  return (
    <>

      <Dialog
        open={openModal}
        className={classes.customRoot}
        style={{textAlign: 'left', }}
        aria-labelledby="draggable-dialog-title"
        classes={{paper: classes.overflowHidden}}
      >
        <div className={classes.purchaseContentModal}> 
        
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div className="view-heading">
            {isCreate ? 
            'Create Purchase Order' : 
            'Edit Purchase Order'}
          </div> 
        </DialogTitle>

        <DialogContent className={classes.poContentModal}>
          <DialogContentText  component={'div'}>
            <h3 className="poDetailsHeading">PO Details</h3>

            <div className={classes.marginBM30}>
              <InputDistributor 
                distributor={purchaseOrderData.povendorid}
                error={purchaseError.povendorid}
                setDistributor={(value) => {
                  // if creating, enable to change vendor
                  // if editing, disable to change vendor
                  if (isCreate) setPurchaseOrderData({
                    ...purchaseOrderData,
                    povendorid: value,
                  });
                }}
              />
            </div>

            <div className={classes.marginBM30}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel className={classes.customLabel} shrink={true}>
                  Ownership
                </InputLabel>
                <Select
                  // native
                  displayEmpty
                  className={classes.customFullWidth}
                  value={purchaseOrderData.popurchaseordertype || ""}
                  error={!!purchaseError.popurchaseordertype}
                  onChange={(e) => {
                    setPurchaseOrderData({
                    ...purchaseOrderData,
                    popurchaseordertype: e.target.value,
                    });
                  }} 
                >
                  {
                    getOwnerships("Select One", ['Unknown', 'BillOnly'])
                  }
                </Select>
              </FormControl>   
            </div>
            
            
            <div className={classes.marginBM30}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel className={classes.customLabel} shrink={true}>
                  PO Type
                </InputLabel>
                <Select
                  // native
                  displayEmpty
                  className={classes.customFullWidth}
                  value={purchaseOrderData.potype || ""}
                  error={!!purchaseError.potype}
                  onChange={(e) => {
                    setPurchaseOrderData({
                      ...purchaseOrderData,
                      potype: e.target.value,
                    });
                  }} 
                >
                  {getPoType("Select One")}
                </Select>
              </FormControl>
            </div>
            

            {isCreate === true && 
            <div className={classes.marginBM30}>
              <FileUploadButton 
                setFilePathData={({data, name}) => {
                  console.log("[CreatePurchaseOrder] FileUploadButton", data, name);
                  setFilePathData(`${name}  |  ${data.length} Uploaded products`);
                }}
                uploadFile={handleUpload}
              />
              <Typography variant="inherit" style={{paddingLeft: 10}}>
                You can upload only valid CSV/PDF here.
              </Typography>
              <Typography>
                {filePathData}
              </Typography>
            </div>
            }

            <div className="poModel"> 
            
              { 
                (isCreate === true && !!file) ? 
                  <>
                    <div className={classes.marginBM30}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel className={classes.customLabel} shrink={true}>
                          Notes
                        </InputLabel>
                        <TextareaAutosize
                          id="input-with-icon-adornment111"
                          label="Notes"
                          placeholder="Write notes here"
                          value={notes}
                          className={classes.customFullWidthTextarea}
                          margin="normal"
                          onChange={(e) => {
                            // for ponotes of PurchaseOrderdata
                            setNotes(e.target.value);
                          }}
                          variant="outlined"
                        />
                      </FormControl>   
                    </div>
                  </>
                :
                  <>
                    <h3  className={classes.subformHeading}> Product Details </h3>

                    <AddEditProduct
                      isCreate = {true}
                      productItemsList ={productItemsList}
                      productItems = {productItems}
                      productError= {productError}
                      setProductError={setProductError}
                      setProductItems = {setProductItems}
                      surgicalEventData={surgicalEventData}
                      openModal={openConfirmationeModal}
                      setOpenModal ={setConfirmationOpenModal}
                      handleDeleteProduct={removePODetail}
                      handleUpdateProduct={updatePODetail}
                      hospital={hospital}
                      options={{
                        // isEnableEditProduct: false,
                        // isEnableDeleteProduct: false,
                        edit: {
                          isEnableCompliance: false,
                          isInventory:true,
                          hiddenParams: [
                            'sepdistributor', 
                            'sepownership', 
                            'sepproductexpiration',
                            'sepproductwaste',
                          ].concat(purchaseOrderData.potype === "NoCharge" ? ['sepproductprice'] : [])
                          .concat((isCreate && !surgicalEventData.seid) ? ['seplotcode'] : []),
                          extraParams: [
                            {
                              label: 'Quantity', key: 'podquantity', type: 'number'
                            }
                          ],
                        },
                        update: {
                          hiddenParams: [
                            'sepudi', 
                            'sepreferencenumber', 
                            'sepdistributor',
                            'sepproductexpiration',
                            'sepproductwaste',
                          ],
                          disableParams: [ 
                            'sepownership', 
                            // 'seplotcode',
                            // 'sepproductmanufacturer',
                            // 'sepproductname',
                            // 'sepproductdescription',
                            // 'sepproducttype',
                            // 'sepproductside',
                          ],
                        }
                      }}
                    />
          
                    <div className={classes.marginBM30}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel className={classes.customLabel} shrink={true}>
                          Product Note
                        </InputLabel>
                        <TextareaAutosize
                          id="input-with-icon-adornment1"
                          label="Product Note"
                          placeholder="Write product notes here"
                          value={podnotes}
                          className={classes.customFullWidthTextarea}
                          margin="normal"
                          onChange={(e) => {
                            // for podnotes of PurchaseOrderDetaildata
                            setPodNotes(e.target.value);
                          }}
                          variant="outlined"
                        />
                      </FormControl>   
                    </div>
                  </>
              }

            </div>

          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <div className="form-footer">
            {/* { purchaseOrderData.potype !== "Bulk" &&   */}
              <Button variant="contained" color="primary" onClick={addMoreProduct}>Add more Product</Button> 
            {/* } */}
            <div className="right-buttons">
              <Button variant="text" color="primary" onClick={handleCancel}>Cancel</Button>
              <Button variant="contained" color="primary" onClick={addPurchaseOrder} >
                {isCreate ? 
                'Create' : 
                'Save Update'}
              </Button> 
            </div>
          </div>
        </DialogActions>

        </div>

      </Dialog>

      <ErrorDialog
        openModal={errorModal}
        setOpenModal={setErrorModal}
      />

    </>
  );
}
